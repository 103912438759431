import * as objectTypes from "../../../constants/objectTypes";
import * as privilegesHelper from "../../../helpers/privilegesHelper";

import React, { Component } from "react";

import { TableCheckAllButtonContainer } from "./buttons/TableCheckAllButton";

type Props = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    data: Array<any>;
    loading?: boolean;
};

export class TableActionFooterRow extends Component<Props> {
    getActionRowClassName = (): string => {
        let className = "action-row";

        if (!privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            className += " wrap-row";
        }

        return className;
    };

    getCheckAllCheckboxButton = (): JSX.Element => {
        return (
            <TableCheckAllButtonContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                list={this.props.data}
                editable={!this.props.loading}
                checkAll={true}
            />
        );
    };

    getUncheckAllCheckboxButton = (): JSX.Element => {
        return (
            <TableCheckAllButtonContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                list={this.props.data}
                editable={!this.props.loading}
                checkAll={false}
            />
        );
    };

    render(): JSX.Element | null {
        if (!objectTypes.VISIBLE_ACTION_FOOTER_ROW_OBJECT_TYPES.includes(this.props.objectType)) {
            return null;
        }

        return (
            <div className={this.getActionRowClassName()}>
                <div className="action-part">
                    {this.getCheckAllCheckboxButton()}
                    {this.getUncheckAllCheckboxButton()}
                </div>
            </div>
        );
    }
}
