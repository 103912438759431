import * as generalHelper from "../helpers/generalHelper";

import React, { Component } from "react";

import { AppState } from "../reducers";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

import imgError from "../resources/img/error-image.jpg";

type Props = {
    custom: string | null;
};

export class Error extends Component<Props> {
    render(): JSX.Element {
        return (
            <div>
                <div className="welcome">
                    <div className="welcome-window">
                        <div className="redlike-logo-blue">
                            <ReactSVG className="logo-svg" src={generalHelper.getSoftwareLogo(this.props.custom)} />
                        </div>
                    </div>
                    <div className="error-image">
                        <img src={imgError} alt="error" />
                    </div>
                    <div className="error-info">
                        <div className="error-title">An unexpected error occurred</div>
                        <div className="error-message">
                            Contact our support at DESO or report this error, and we will get back to you as soon as we
                            can.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: AppState): Props => ({
    custom: state.software.custom
});

export const ErrorContainer = connect(mapStateToProps)(Error);
