import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import {
    RedlikeOption,
    generateRedlikeOptionData,
    generateRedlikeOptionHeadings
} from "../../../../types/redlikeOption";

import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<RedlikeOption>;
    activeList: Array<RedlikeOption>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showDefaultValues: boolean;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    totalCount: number | null;
    custom: string | null;
};

export class ZoneRedlikeOptionTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        activeData: RedlikeOption | null = null
    ): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_KEY,
            propertyConstants.PROPERTY_UNIVERSAL_VALUE,
            propertyConstants.PROPERTY_OVERWRITE,
            propertyConstants.PROPERTY_DESCRIPTION
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_KEY];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_KEY];
        const editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_UNIVERSAL_VALUE,
            propertyConstants.PROPERTY_OVERWRITE
        ];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateRedlikeOptionHeadings(
            this.props.objectType,
            modalType !== null ? activeData : null,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateRedlikeOptionData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("options.editOption"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_REDLIKE_OPTIONS_EDIT, activeData[0]),
                undefined,
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            editModalType: modalTypes.MODAL_REDLIKE_OPTIONS_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    headings={this.generateTableHeadings()}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType, this.props.custom)}
                    titleClassName={"main-title"}
                    data={generateRedlikeOptionData(this.props.allList)}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={this.props.allList.length}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={false}
                    showGlobalSearch={this.props.showGlobalSearch}
                    showSpecificValues={this.props.showDefaultValues}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
