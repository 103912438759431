import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { File, mapFiles } from "./file";
import { Price, mapPrice } from "./price";

import { Base } from "./base";
import { Product } from "./product";
import { TableHeading } from "./tableHeading";

export class BaseInProduct {
    id: number;
    baseId: number;
    productId: number;
    baseName: string;
    productName: string;
    parentId?: number | null;
    code?: string;
    codeMaster?: string | null;
    codeSystem?: string | null;
    codeSource?: number;
    coef?: number | null;
    coefMaster?: number | null;
    coefSystem?: number | null;
    coefSource?: number;
    density?: number | null;
    densityMaster?: number | null;
    densitySystem?: number | null;
    densitySource?: number;
    fillProc?: number | null;
    fillProcMaster?: number | null;
    fillProcSystem?: number | null;
    fillProcSource?: number;
    bfs?: number | null;
    bfsMaster?: number | null;
    bfsSystem?: number | null;
    bfsSource?: number;
    cntMaxFill?: number | null;
    cntMaxFillMaster?: number | null;
    cntMaxFillSystem?: number | null;
    cntMaxFillSource?: number;
    cntMinFill?: number | null;
    cntMinFillMaster?: number | null;
    cntMinFillSystem?: number | null;
    cntMinFillSource?: number;
    isGravimetricFill?: boolean | null;
    isGravimetricFillMaster?: boolean | null;
    isGravimetricFillSystem?: boolean | null;
    isGravimetricFillSource?: boolean;
    priority?: number | null;
    priorityMaster?: number | null;
    prioritySystem?: number | null;
    prioritySource?: number;
    ufiCode?: string | null;
    ufiCodeMaster?: string | null;
    ufiCodeSystem?: string | null;
    ufiCodeSource?: string;
    moreInfo?: string | null;
    moreInfoMaster?: string | null;
    moreInfoSystem?: string | null;
    moreInfoSource?: number;
    imageId?: number | null;
    imageIdMaster?: number | null;
    imageIdSystem?: number | null;
    imageIdSource?: number;
    forcedFormulaBaseAmountTypeId?: number | null;
    forcedFormulaBaseAmountTypeIdMaster?: number | null;
    forcedFormulaBaseAmountTypeIdSystem?: number | null;
    forcedFormulaBaseAmountTypeIdSource?: number;
    customFormula?: boolean | null;
    customFormulaMaster?: boolean | null;
    customFormulaSystem?: boolean | null;
    customFormulaSource?: number;
    uuid?: string | null;
    files?: Array<File>;
    // eslint-disable-next-line camelcase
    price_cost_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_vat?: Price | null;

    constructor(
        id: number,
        baseId: number,
        productId: number,
        baseName: string,
        productName: string,
        parentId?: number | null,
        code?: string,
        codeMaster?: string | null,
        codeSystem?: string | null,
        codeSource?: number,
        coef?: number | null,
        coefMaster?: number | null,
        coefSystem?: number | null,
        coefSource?: number,
        density?: number | null,
        densityMaster?: number | null,
        densitySystem?: number | null,
        densitySource?: number,
        fillProc?: number | null,
        fillProcMaster?: number | null,
        fillProcSystem?: number | null,
        fillProcSource?: number,
        bfs?: number | null,
        bfsMaster?: number | null,
        bfsSystem?: number | null,
        bfsSource?: number,
        cntMaxFill?: number | null,
        cntMaxFillMaster?: number | null,
        cntMaxFillSystem?: number | null,
        cntMaxFillSource?: number,
        cntMinFill?: number | null,
        cntMinFillMaster?: number | null,
        cntMinFillSystem?: number | null,
        cntMinFillSource?: number,
        isGravimetricFill?: boolean | null,
        isGravimetricFillMaster?: boolean | null,
        isGravimetricFillSystem?: boolean | null,
        isGravimetricFillSource?: boolean,
        priority?: number | null,
        priorityMaster?: number | null,
        prioritySystem?: number | null,
        prioritySource?: number,
        ufiCode?: string | null,
        ufiCodeMaster?: string | null,
        ufiCodeSystem?: string | null,
        ufiCodeSource?: string,
        moreInfo?: string | null,
        moreInfoMaster?: string | null,
        moreInfoSystem?: string | null,
        moreInfoSource?: number,
        imageId?: number | null,
        imageIdMaster?: number | null,
        imageIdSystem?: number | null,
        imageIdSource?: number,
        forcedFormulaBaseAmountTypeId?: number | null,
        forcedFormulaBaseAmountTypeIdMaster?: number | null,
        forcedFormulaBaseAmountTypeIdSystem?: number | null,
        forcedFormulaBaseAmountTypeIdSource?: number,
        customFormula?: boolean | null,
        customFormulaMaster?: boolean | null,
        customFormulaSystem?: boolean | null,
        customFormulaSource?: number,
        uuid?: string | null,
        files?: Array<File>,
        // eslint-disable-next-line camelcase
        price_cost_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_vat?: Price | null
    ) {
        this.id = id;
        this.baseId = baseId;
        this.productId = productId;
        this.baseName = baseName;
        this.productName = productName;
        this.parentId = parentId;
        this.code = code;
        this.codeMaster = codeMaster;
        this.codeSystem = codeSystem;
        this.codeSource = codeSource;
        this.coef = coef;
        this.coefMaster = coefMaster;
        this.coefSystem = coefSystem;
        this.coefSource = coefSource;
        this.density = density;
        this.densityMaster = densityMaster;
        this.densitySystem = densitySystem;
        this.densitySource = densitySource;
        this.fillProc = fillProc;
        this.fillProcMaster = fillProcMaster;
        this.fillProcSystem = fillProcSystem;
        this.fillProcSource = fillProcSource;
        this.bfs = bfs;
        this.bfsMaster = bfsMaster;
        this.bfsSystem = bfsSystem;
        this.bfsSource = bfsSource;
        this.cntMaxFill = cntMaxFill;
        this.cntMaxFillMaster = cntMaxFillMaster;
        this.cntMaxFillSystem = cntMaxFillSystem;
        this.cntMaxFillSource = cntMaxFillSource;
        this.cntMinFill = cntMinFill;
        this.cntMinFillMaster = cntMinFillMaster;
        this.cntMinFillSystem = cntMinFillSystem;
        this.cntMinFillSource = cntMinFillSource;
        this.isGravimetricFill = isGravimetricFill;
        this.isGravimetricFillMaster = isGravimetricFillMaster;
        this.isGravimetricFillSystem = isGravimetricFillSystem;
        this.isGravimetricFillSource = isGravimetricFillSource;
        this.priority = priority;
        this.priorityMaster = priorityMaster;
        this.prioritySystem = prioritySystem;
        this.prioritySource = prioritySource;
        this.ufiCode = ufiCode;
        this.ufiCodeMaster = ufiCodeMaster;
        this.ufiCodeSystem = ufiCodeSystem;
        this.ufiCodeSource = ufiCodeSource;
        this.moreInfo = moreInfo;
        this.moreInfoMaster = moreInfoMaster;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.imageId = imageId;
        this.imageIdMaster = imageIdMaster;
        this.imageIdSystem = imageIdSystem;
        this.imageIdSource = imageIdSource;
        this.forcedFormulaBaseAmountTypeId = forcedFormulaBaseAmountTypeId;
        this.forcedFormulaBaseAmountTypeIdMaster = forcedFormulaBaseAmountTypeIdMaster;
        this.forcedFormulaBaseAmountTypeIdSystem = forcedFormulaBaseAmountTypeIdSystem;
        this.forcedFormulaBaseAmountTypeIdSource = forcedFormulaBaseAmountTypeIdSource;
        this.customFormula = customFormula;
        this.customFormulaMaster = customFormulaMaster;
        this.customFormulaSystem = customFormulaSystem;
        this.customFormulaSource = customFormulaSource;
        this.uuid = uuid;
        this.files = files;
        // eslint-disable-next-line camelcase
        this.price_cost_per_liter = price_cost_per_liter;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_liter = price_purchase_per_liter;
        // eslint-disable-next-line camelcase
        this.price_sell_per_liter = price_sell_per_liter;
        // eslint-disable-next-line camelcase
        this.price_vat = price_vat;
    }
}

export function mapBaseInProduct(data: Record<string, any>): BaseInProduct | null {
    try {
        return new BaseInProduct(
            data.id,
            data.baseId,
            data.productId,
            data.baseName,
            data.productName,
            data.parentId,
            data.code,
            data.codeMaster,
            data.codeSystem,
            data.codeSource,
            data.coef,
            data.coefMaster,
            data.coefSystem,
            data.coefSource,
            data.density,
            data.densityMaster,
            data.densitySystem,
            data.densitySource,
            data.fillProc,
            data.fillProcMaster,
            data.fillProcSystem,
            data.fillProcSource,
            data.bfs,
            data.bfsMaster,
            data.bfsSystem,
            data.bfsSource,
            data.cntMaxFill,
            data.cntMaxFillMaster,
            data.cntMaxFillSystem,
            data.cntMaxFillSource,
            data.cntMinFill,
            data.cntMinFillMaster,
            data.cntMinFillSystem,
            data.cntMinFillSource,
            data.isGravimetricFill,
            data.isGravimetricFillMaster,
            data.isGravimetricFillSystem,
            data.isGravimetricFillSource,
            data.priority,
            data.priorityMaster,
            data.prioritySystem,
            data.prioritySource,
            data.ufiCode,
            data.ufiCodeMaster,
            data.ufiCodeSystem,
            data.ufiCodeSource,
            data.moreInfo,
            data.moreInfoMaster,
            data.moreInfoSystem,
            data.moreInfoSource,
            data.imageId,
            data.imageIdMaster,
            data.imageIdSystem,
            data.imageIdSource,
            data.forcedFormulaBaseAmountTypeId,
            data.forcedFormulaBaseAmountTypeIdMaster,
            data.forcedFormulaBaseAmountTypeIdSystem,
            data.forcedFormulaBaseAmountTypeIdSource,
            data.customFormula,
            data.customFormulaMaster,
            data.customFormulaSystem,
            data.customFormulaSource,
            data.uuid,
            data.files ? mapFiles(data.files) : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_VAT]
                ? mapPrice(propertyConstants.PROPERTY_PRICE_VAT, data.prices[propertyConstants.PROPERTY_PRICE_VAT])
                : undefined
        );
    } catch (e) {
        return null;
    }
}

export function updateBaseInProduct(
    oldBaseInProduct: BaseInProduct | null,
    updatedData: Record<string, any>
): BaseInProduct | null {
    try {
        if (oldBaseInProduct === null) {
            return null;
        }

        return new BaseInProduct(
            oldBaseInProduct.id,
            oldBaseInProduct.baseId,
            oldBaseInProduct.productId,
            oldBaseInProduct.baseName,
            oldBaseInProduct.productName,
            oldBaseInProduct.parentId,
            updatedData.code ? updatedData.code : oldBaseInProduct.code,
            oldBaseInProduct.codeMaster,
            oldBaseInProduct.codeSystem,
            oldBaseInProduct.codeSource,
            updatedData.coef ? updatedData.coef : oldBaseInProduct.coef,
            oldBaseInProduct.coefMaster,
            oldBaseInProduct.coefSystem,
            oldBaseInProduct.coefSource,
            updatedData.density ? updatedData.density : oldBaseInProduct.density,
            oldBaseInProduct.densityMaster,
            oldBaseInProduct.densitySystem,
            oldBaseInProduct.densitySource,
            updatedData.fillProc ? updatedData.fillProc : oldBaseInProduct.fillProc,
            oldBaseInProduct.fillProcMaster,
            oldBaseInProduct.fillProcSystem,
            oldBaseInProduct.fillProcSource,
            updatedData.bfs ? updatedData.bfs : oldBaseInProduct.bfs,
            oldBaseInProduct.bfsMaster,
            oldBaseInProduct.bfsSystem,
            oldBaseInProduct.bfsSource,
            updatedData.cntMaxFill ? updatedData.cntMaxFill : oldBaseInProduct.cntMaxFill,
            oldBaseInProduct.cntMaxFillMaster,
            oldBaseInProduct.cntMaxFillSystem,
            oldBaseInProduct.cntMaxFillSource,
            updatedData.cntMinFill ? updatedData.cntMinFill : oldBaseInProduct.cntMinFill,
            oldBaseInProduct.cntMinFillMaster,
            oldBaseInProduct.cntMinFillSystem,
            oldBaseInProduct.cntMinFillSource,
            updatedData.isGravimetricFill ? updatedData.isGravimetricFill : oldBaseInProduct.isGravimetricFill,
            oldBaseInProduct.isGravimetricFillMaster,
            oldBaseInProduct.isGravimetricFillSystem,
            oldBaseInProduct.isGravimetricFillSource,
            updatedData.priority ? updatedData.priority : oldBaseInProduct.priority,
            oldBaseInProduct.priorityMaster,
            oldBaseInProduct.prioritySystem,
            oldBaseInProduct.prioritySource,
            updatedData.ufiCode ? updatedData.ufiCode : oldBaseInProduct.ufiCode,
            oldBaseInProduct.ufiCodeMaster,
            oldBaseInProduct.ufiCodeSystem,
            oldBaseInProduct.ufiCodeSource,
            updatedData.moreInfo ? updatedData.moreInfo : oldBaseInProduct.moreInfo,
            oldBaseInProduct.moreInfoMaster,
            oldBaseInProduct.moreInfoSystem,
            oldBaseInProduct.moreInfoSource,
            updatedData.imageId ? updatedData.imageId : oldBaseInProduct.imageId,
            oldBaseInProduct.imageIdMaster,
            oldBaseInProduct.imageIdSystem,
            oldBaseInProduct.imageIdSource,
            updatedData.forcedFormulaBaseAmountTypeId
                ? updatedData.forcedFormulaBaseAmountTypeId
                : oldBaseInProduct.forcedFormulaBaseAmountTypeId,
            oldBaseInProduct.forcedFormulaBaseAmountTypeIdMaster,
            oldBaseInProduct.forcedFormulaBaseAmountTypeIdSystem,
            oldBaseInProduct.forcedFormulaBaseAmountTypeIdSource,
            updatedData.customFormula ? updatedData.customFormula : oldBaseInProduct.customFormula,
            oldBaseInProduct.customFormulaMaster,
            oldBaseInProduct.customFormulaSystem,
            oldBaseInProduct.customFormulaSource,
            updatedData.uuid ? updatedData.uuid : oldBaseInProduct.uuid,
            oldBaseInProduct.files,
            updatedData.price_cost_per_liter ? updatedData.price_cost_per_liter : oldBaseInProduct.price_cost_per_liter,
            updatedData.price_purchase_per_liter
                ? updatedData.price_purchase_per_liter
                : oldBaseInProduct.price_purchase_per_liter,
            updatedData.price_sell_per_liter ? updatedData.price_sell_per_liter : oldBaseInProduct.price_sell_per_liter,
            updatedData.price_vat ? updatedData.price_vat : oldBaseInProduct.price_vat
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyBaseInProduct(product?: Product | null): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_BASE_ID]: "",
        [propertyConstants.PROPERTY_PRODUCT_ID]: product ? product[propertyConstants.PROPERTY_ID] : "",
        [propertyConstants.PROPERTY_BASE_NAME]: "",
        [propertyConstants.PROPERTY_PRODUCT_NAME]: product ? product[propertyConstants.PROPERTY_NAME] : "",
        [propertyConstants.PROPERTY_PARENT_ID]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_COEFFICIENT]: "",
        [propertyConstants.PROPERTY_DENSITY]: "",
        [propertyConstants.PROPERTY_FILL_PROC]: "",
        [propertyConstants.PROPERTY_BFS]: "",
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: "",
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: "",
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_UFI_CODE]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_IMAGE_ID]: "",
        [propertyConstants.PROPERTY_FILES]: [],
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: "",
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: true,
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_VAT]: ""
    };
}

export function generateBaseInProductData(list: Array<BaseInProduct>): Array<any> {
    const data: Array<any> = [];
    let item: BaseInProduct;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_BASE_ID]: item.baseId,
            [propertyConstants.PROPERTY_PRODUCT_ID]: item.productId,
            [propertyConstants.PROPERTY_BASE_NAME]: item.baseName,
            [propertyConstants.PROPERTY_PRODUCT_NAME]: item.productName,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_CODE]: item.code,
            [propertyConstants.PROPERTY_CODE_MASTER]: item.codeMaster,
            [propertyConstants.PROPERTY_CODE_SYSTEM]: item.codeSystem,
            [propertyConstants.PROPERTY_CODE_SOURCE]: item.codeSource,
            [propertyConstants.PROPERTY_COEFFICIENT]: item.coef,
            [propertyConstants.PROPERTY_COEFFICIENT_MASTER]: item.coefMaster,
            [propertyConstants.PROPERTY_COEFFICIENT_SYSTEM]: item.coefSystem,
            [propertyConstants.PROPERTY_COEFFICIENT_SOURCE]: item.coefSource,
            [propertyConstants.PROPERTY_DENSITY]: item.density,
            [propertyConstants.PROPERTY_DENSITY_MASTER]: item.densityMaster,
            [propertyConstants.PROPERTY_DENSITY_SYSTEM]: item.densitySystem,
            [propertyConstants.PROPERTY_DENSITY_SOURCE]: item.densitySource,
            [propertyConstants.PROPERTY_FILL_PROC]: item.fillProc,
            [propertyConstants.PROPERTY_FILL_PROC_MASTER]: item.fillProcMaster,
            [propertyConstants.PROPERTY_FILL_PROC_SYSTEM]: item.fillProcSystem,
            [propertyConstants.PROPERTY_FILL_PROC_SOURCE]: item.fillProcSource,
            [propertyConstants.PROPERTY_BFS]: item.bfs,
            [propertyConstants.PROPERTY_BFS_MASTER]: item.bfsMaster,
            [propertyConstants.PROPERTY_BFS_SYSTEM]: item.bfsSystem,
            [propertyConstants.PROPERTY_BFS_SOURCE]: item.bfsSource,
            [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: item.cntMaxFill,
            [propertyConstants.PROPERTY_COLORANT_MAX_FILL_MASTER]: item.cntMaxFillMaster,
            [propertyConstants.PROPERTY_COLORANT_MAX_FILL_SYSTEM]: item.cntMaxFillSystem,
            [propertyConstants.PROPERTY_COLORANT_MAX_FILL_SOURCE]: item.cntMaxFillSource,
            [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: item.cntMinFill,
            [propertyConstants.PROPERTY_COLORANT_MIN_FILL_MASTER]: item.cntMinFillMaster,
            [propertyConstants.PROPERTY_COLORANT_MIN_FILL_SYSTEM]: item.cntMinFillSystem,
            [propertyConstants.PROPERTY_COLORANT_MIN_FILL_SOURCE]: item.cntMinFillSource,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: item.isGravimetricFill,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_MASTER]: item.isGravimetricFillMaster,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_SYSTEM]: item.isGravimetricFillSystem,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_SOURCE]: item.isGravimetricFillSource,
            [propertyConstants.PROPERTY_UFI_CODE]: item.ufiCode,
            [propertyConstants.PROPERTY_UFI_CODE_MASTER]: item.ufiCodeMaster,
            [propertyConstants.PROPERTY_UFI_CODE_SYSTEM]: item.ufiCodeSystem,
            [propertyConstants.PROPERTY_UFI_CODE_SOURCE]: item.ufiCodeSource,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_MASTER]: item.moreInfoMaster,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_PRIORITY_MASTER]: item.priorityMaster,
            [propertyConstants.PROPERTY_PRIORITY_SYSTEM]: item.prioritySystem,
            [propertyConstants.PROPERTY_PRIORITY_SOURCE]: item.prioritySource,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.imageId,
            [propertyConstants.PROPERTY_IMAGE_ID_MASTER]: item.imageIdMaster,
            [propertyConstants.PROPERTY_IMAGE_ID_SYSTEM]: item.imageIdSystem,
            [propertyConstants.PROPERTY_IMAGE_ID_SOURCE]: item.imageIdSource,
            [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: item.forcedFormulaBaseAmountTypeId,
            [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_MASTER]:
                item.forcedFormulaBaseAmountTypeIdMaster,
            [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SYSTEM]:
                item.forcedFormulaBaseAmountTypeIdSystem,
            [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SOURCE]:
                item.forcedFormulaBaseAmountTypeIdSource,
            [propertyConstants.PROPERTY_CUSTOM_FORMULA]: item.customFormula,
            [propertyConstants.PROPERTY_CUSTOM_FORMULA_MASTER]: item.customFormulaMaster,
            [propertyConstants.PROPERTY_CUSTOM_FORMULA_SYSTEM]: item.customFormulaSystem,
            [propertyConstants.PROPERTY_CUSTOM_FORMULA_SOURCE]: item.customFormulaSource,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_FILES]: item.files,
            [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: item.price_cost_per_liter,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: item.price_purchase_per_liter,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: item.price_sell_per_liter,
            [propertyConstants.PROPERTY_PRICE_VAT]: item.price_vat
        });
    }

    return data;
}

export function generateBaseInProductHeadings(
    objectType: objectTypes.ObjectType,
    baseList: Array<Base>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const forcedFormulaTypeList = [
        {
            [propertyConstants.PROPERTY_ID]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_DEFAULT,
            [propertyConstants.PROPERTY_KEY]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_DEFAULT.toString(),
            [propertyConstants.PROPERTY_NAME]: translationHelper.getForcedFormulaBaseAmountTypeOptionTranslation(
                serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_DEFAULT
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_GRAVIMETRIC,
            [propertyConstants.PROPERTY_KEY]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_GRAVIMETRIC.toString(),
            [propertyConstants.PROPERTY_NAME]: translationHelper.getForcedFormulaBaseAmountTypeOptionTranslation(
                serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_GRAVIMETRIC
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_VOLUMETRIC,
            [propertyConstants.PROPERTY_KEY]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_VOLUMETRIC.toString(),
            [propertyConstants.PROPERTY_NAME]: translationHelper.getForcedFormulaBaseAmountTypeOptionTranslation(
                serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_VOLUMETRIC
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_USE_AS_IT_IS,
            [propertyConstants.PROPERTY_KEY]: serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_USE_AS_IT_IS.toString(),
            [propertyConstants.PROPERTY_NAME]: translationHelper.getForcedFormulaBaseAmountTypeOptionTranslation(
                serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_USE_AS_IT_IS
            )
        }
    ];

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BASE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_BASE_ID),
            editableList.includes(propertyConstants.PROPERTY_BASE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_ID),
            requiredList.includes(propertyConstants.PROPERTY_BASE_ID),
            baseList,
            widthOption?.[propertyConstants.PROPERTY_BASE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_BASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_BASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BFS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BFS),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BFS),
            editableList.includes(propertyConstants.PROPERTY_BFS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BFS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BFS),
            requiredList.includes(propertyConstants.PROPERTY_BFS),
            [],
            widthOption?.[propertyConstants.PROPERTY_BFS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COEFFICIENT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COEFFICIENT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_COEFFICIENT),
            editableList.includes(propertyConstants.PROPERTY_COEFFICIENT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COEFFICIENT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COEFFICIENT),
            requiredList.includes(propertyConstants.PROPERTY_COEFFICIENT),
            [],
            widthOption?.[propertyConstants.PROPERTY_COEFFICIENT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_MAX_FILL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_MAX_FILL),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_MAX_FILL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_MIN_FILL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_MIN_FILL),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_MIN_FILL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CUSTOM_FORMULA,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CUSTOM_FORMULA),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_CUSTOM_FORMULA),
            editableList.includes(propertyConstants.PROPERTY_CUSTOM_FORMULA),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CUSTOM_FORMULA),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CUSTOM_FORMULA),
            requiredList.includes(propertyConstants.PROPERTY_CUSTOM_FORMULA),
            [],
            widthOption?.[propertyConstants.PROPERTY_CUSTOM_FORMULA] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DENSITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DENSITY),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_DENSITY),
            editableList.includes(propertyConstants.PROPERTY_DENSITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DENSITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DENSITY),
            requiredList.includes(propertyConstants.PROPERTY_DENSITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_DENSITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILES),
            tableConstants.TABLE_TYPE_FILE,
            visibleList.includes(propertyConstants.PROPERTY_FILES),
            editableList.includes(propertyConstants.PROPERTY_FILES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILES),
            requiredList.includes(propertyConstants.PROPERTY_FILES),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILL_PROC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            editableList.includes(propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILL_PROC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            requiredList.includes(propertyConstants.PROPERTY_FILL_PROC),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILL_PROC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID
            ),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID),
            forcedFormulaTypeList,
            widthOption?.[propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            editableList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            requiredList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_VAT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_VAT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_VAT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UFI_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UFI_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UFI_CODE),
            editableList.includes(propertyConstants.PROPERTY_UFI_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UFI_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UFI_CODE),
            requiredList.includes(propertyConstants.PROPERTY_UFI_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_UFI_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
