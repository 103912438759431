import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { ColorantBatch, updateColorantBatch } from "../types/colorantBatch";

export type ColorantBatchState = Readonly<{
    masterList: Array<ColorantBatch>;
    masterActiveList: Array<ColorantBatch>;
    masterCount: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
}>;

const initialState: ColorantBatchState = {
    masterList: [],
    masterActiveList: [],
    masterCount: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE
};

export default function colorantBatches(
    state: ColorantBatchState = initialState,
    action: Record<string, any>
): ColorantBatchState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.MASTER_COLORANT_BATCH) {
                const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                return {
                    ...state,
                    masterList: action.payload.list,
                    masterActiveList: newActiveList,
                    masterCount: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.MASTER_COLORANT_BATCH) {
                return {
                    ...state,
                    masterActiveList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.MASTER_COLORANT_BATCH) {
                return {
                    ...state,
                    masterOffset: action.payload.offset,
                    masterPage: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.MASTER_COLORANT_BATCH) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.MASTER_SEARCH,
                    tableConstants.MASTER_SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_COLORANT_BATCH: {
                        const updatedColorantBatchList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_BATCH_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateColorantBatch
                        );

                        if (updatedColorantBatchList.length) {
                            return {
                                ...state,
                                masterList: updatedColorantBatchList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_COLORANT_BATCH: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.DATA_ROLLBACK:
        case actionConstants.EVENT_SAVE_DATABASES:
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
