import * as designConstants from "../../../constants/designConstants";

import React, { Component } from "react";

import { ReactSVG } from "react-svg";
import imgInfo from "../../../resources/img/icons/info.svg";
import imgLoader from "../../../resources/img/mini-loader.svg";

type Props = {
    modalId: number;
    alertTypeId: number;
    caption: string;
};

export class FooterAlertProperty extends Component<Props> {
    getIcon = (): JSX.Element | null => {
        if (this.props.alertTypeId === designConstants.ALERT_LOADING) {
            return <ReactSVG src={imgLoader} className="svg-icon" />;
        }

        if (this.props.alertTypeId === designConstants.ALERT_VALIDATION) {
            return <ReactSVG src={imgInfo} className="svg-icon" />;
        }

        return null;
    };

    render(): JSX.Element | null {
        return (
            <div className="modal-alert">
                {this.getIcon()}
                {this.props.caption}
            </div>
        );
    }
}
