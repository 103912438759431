import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { File } from "../../../types/file";
import { FileCategory } from "../../../types/fileCategory";
import { FileType } from "../../../types/fileType";
import { GenericStaticSqlScene } from "../../../components/scenes/generic/GenericStaticSqlScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    categoryList: Array<FileCategory>;
    fileTypeList: Array<FileType>;
    sceneLayout: string;
    staticSqlTableConstant: string;
    staticSqlList: Array<File>;
    staticSqlActiveList: Array<File>;
    staticSqlLoading: boolean;
    staticSqlButtonLoading: boolean;
    staticSqlColumnOrder: Array<propertyConstants.Property>;
    staticSqlColumnVisibility: Record<propertyConstants.Property, boolean>;
    staticSqlColumnWidth: Record<propertyConstants.Property, number>;
    staticSqlOffset: number;
    staticSqlPage: number;
    staticSqlRowCount: number;
    staticSqlRowCountCustom: boolean;
    staticSqlSceneTableSize: Record<string, any>;
    staticSqlSearch: string | null;
    staticSqlSearchParams: Record<propertyConstants.Property, string>;
    staticSqlShowFilterRow: boolean;
    staticSqlShowGlobalSearch: boolean;
    staticSqlSortingAsc: boolean;
    staticSqlSortingCriterion: propertyConstants.Property | null;
    staticSqlTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_STATIC_SQL
    ),
    categoryList: state.fileCategory.allList,
    fileTypeList: state.file.staticSqlFileTypeList,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_STATIC_SQL
    ),
    staticSqlTableConstant: menuConstants.TABLE_GENERIC_STATIC_SQL,
    staticSqlList: state.file.staticSqlList,
    staticSqlActiveList: state.file.staticSqlActiveList,
    staticSqlLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_STATIC_SQL || request.method === methods.METHOD_DELETE_STATIC_SQL
    ),
    staticSqlButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_STATIC_SQL
    ),
    staticSqlColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlOffset: state.file.staticSqlOffset,
    staticSqlPage: state.file.staticSqlPage,
    staticSqlRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_STATIC_SQL
    ),
    staticSqlSearch: state.file.staticSqlSearch,
    staticSqlSearchParams: state.file.staticSqlSearchParams,
    staticSqlShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_STATIC_SQL
    ),
    staticSqlSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_STATIC_SQL
        )
    ),
    staticSqlSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_STATIC_SQL
        )
    ),
    staticSqlTotalCount: state.file.staticSqlCount
});

export const GenericStaticSqlSceneContainer = connect(mapStateToProps)(GenericStaticSqlScene);
