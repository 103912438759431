import * as fileTypeConstants from "../constants/fileTypes";
import * as translationHelper from "../helpers/translationHelper";

export class FileType {
    id: number;
    key: string;
    name: string;
    category: string;
    active: boolean;

    constructor(id: number, key: string, category: string, active: boolean) {
        this.id = id;
        this.key = key;
        this.name = translationHelper.getFileTypeTranslation(id, key);
        this.category = category;
        this.active = active;
    }
}

export const getDefaultProductSheetFileTypes = (): Array<FileType> => {
    return [
        new FileType(fileTypeConstants.FILE_TYPE_INSTRUCTIONS, "instructions", fileTypeConstants.CATEGORY_FILE, true),
        new FileType(fileTypeConstants.FILE_TYPE_SAFETY_SHEET, "safety_sheet", fileTypeConstants.CATEGORY_FILE, true),
        new FileType(
            fileTypeConstants.FILE_TYPE_TECHNICAL_SHEET,
            "technical_sheet",
            fileTypeConstants.CATEGORY_FILE,
            true
        )
    ];
};

export const getDefaultStaticSqlFileTypes = (): Array<FileType> => {
    return [new FileType(fileTypeConstants.FILE_TYPE_STATIC_SQL, "static_sql", fileTypeConstants.CATEGORY_FILE, true)];
};

export const getDefaultImageFileTypes = (): Array<FileType> => {
    return [
        new FileType(fileTypeConstants.FILE_TYPE_ICON, "icon", fileTypeConstants.CATEGORY_IMAGE, true),
        new FileType(fileTypeConstants.FILE_TYPE_IMAGE, "image", fileTypeConstants.CATEGORY_IMAGE, true)
    ];
};
