import * as serverRestMethods from "../../../../constants/serverRestMethods";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import Iframe from "react-iframe";
import { ReactSVG } from "react-svg";

import { connect } from "react-redux";

import imgFile from "../../../../resources/img/icons/product-file.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = StateType & DispatchType;

type OwnProps = {
    fileId: number | null;
};

type Props = OwnProps & ContainerProps;

export class FilePreview extends Component<Props> {
    getFilePreview = (): JSX.Element => {
        const { sessionUuid, fileId } = this.props;

        if (sessionUuid && fileId !== null) {
            return (
                <Iframe
                    url={`${this.props.urlRest}${serverRestMethods.METHOD_GET_FILE_PREVIEW}?file_id=${fileId}&session_uuid=${sessionUuid}`}
                    height="100%"
                    width="100%"
                    className="i-frame-container"
                />
            );
        }

        return (
            <div className="svg-container">
                <ReactSVG className="no-file-svg svg-icon" src={imgFile} />
                <span>{translate("file.noFilePreview")}</span>
            </div>
        );
    };

    render(): JSX.Element {
        return (
            <div className="file-container">
                <div className="loaded-files height-100">{this.getFilePreview()}</div>
            </div>
        );
    }
}

export type StateType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState): StateType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const FilePreviewContainer = connect(mapStateToProps, mapDispatchToProps)(FilePreview);
