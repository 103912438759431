import * as designConstants from "../../../constants/designConstants";
import * as designHelper from "../../../helpers/designHelper";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as priceHelper from "../../../helpers/priceHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";
import { Currency } from "../../../types/currency";

type Props = {
    objectType: objectTypes.ObjectType;
    className: string;
    inputs?: Array<any>;
    type: tableConstants.TableType;
    property: propertyConstants.Property;
    editable: boolean;
    required: boolean;
    currency?: Currency | null;
    object?: any;
    priceObject?: any;
};

// TODO check whole file

export class ModalConversionInputs extends Component<Props> {
    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        activeProperty: propertyConstants.Property
    ): void => {
        const { type } = this.props;
        const activeInput = (this.props.inputs || []).find((input: any) => input.inputName === activeProperty) || null;
        const activePriceObject = this.props.object?.[activeProperty] || null;
        let validation = false;
        let newValue: any = validationHelper.formatStringFromHtml(event.target.value);

        switch (type) {
            case tableConstants.TABLE_TYPE_PRICE: {
                validation = activePriceObject !== undefined && validationHelper.isDecimalValueValid(newValue);
                break;
            }
            default: {
                break;
            }
        }

        if (activeInput && activeInput.inputCallbackChange && validation) {
            switch (type) {
                case tableConstants.TABLE_TYPE_PRICE: {
                    newValue = {
                        ...activePriceObject,
                        [propertyConstants.PROPERTY_VALUE]: newValue
                    };
                    break;
                }
                default: {
                    break;
                }
            }

            activeInput.inputCallbackChange(newValue);
        }
    };

    handleInputBlur = (event: React.FocusEvent<HTMLInputElement>, activeProperty: propertyConstants.Property): void => {
        const { object, currency } = this.props;

        const allProperties = (this.props.inputs || []).map((input: any) => input.inputName);
        const otherProperty = allProperties.find((item: propertyConstants.Property) => item !== activeProperty) || null;
        const activeInput = (this.props.inputs || []).find((input: any) => input.inputName === activeProperty) || null;
        const otherInput = (this.props.inputs || []).find((input: any) => input.inputName === otherProperty) || null;
        const activePriceObject = object?.[activeProperty] || null;
        const otherPriceObject = object?.[otherProperty] || null;
        const fillVolumeMl = object?.[propertyConstants.PROPERTY_FILL_VOLUME_ML] || null;
        const density = priceHelper.hasCustomDensity(otherProperty)
            ? object?.[propertyConstants.PROPERTY_DENSITY] || 1
            : 1;
        let activeValue: string | number | null = event.target.value;
        let otherValue = null;

        activeValue = validationHelper.formatNumber(this.props.objectType, activeProperty, activeValue);

        if (propertyHelper.isPropertyVat(activeProperty)) {
            activeValue = formattingHelper.formatFromPercent(activeValue);
        }

        activeValue = !isNaN(Number(activeValue)) && activeValue !== null ? Number(activeValue) : null;

        if (!propertyHelper.isPropertyVat(activeProperty)) {
            activeValue = activeValue !== null ? Number(formattingHelper.roundCurrency(activeValue, currency)) : null;
        }

        if ((this.props.inputs || []).length && activeValue !== null && otherProperty) {
            // Editing total price, need to recalculate unit price
            if (this.props.property === activeProperty) {
                otherValue = priceHelper.calculateUnitPrice(activeValue, fillVolumeMl, density);
                otherValue = validationHelper.formatNumber(this.props.objectType, activeProperty, otherValue);
            } else {
                // Editing unit price, need to recalculate total price
                otherValue = priceHelper.calculateTotalPrice(activeValue, fillVolumeMl, density);
                otherValue = validationHelper.formatNumber(this.props.objectType, otherProperty, otherValue);
            }

            otherValue = !isNaN(Number(otherValue)) && otherValue !== null ? Number(otherValue) : null;
            otherValue = otherValue !== null ? Number(formattingHelper.roundCurrency(otherValue, currency)) : null;
        }

        if (activePriceObject && activeInput && activeInput.inputCallbackBlur) {
            activeInput.inputCallbackBlur({
                ...activePriceObject,
                [propertyConstants.PROPERTY_VALUE]: activeValue
            });
        }

        if (otherPriceObject && otherInput && otherInput.inputCallbackBlur) {
            otherInput.inputCallbackBlur({
                ...otherPriceObject,
                [propertyConstants.PROPERTY_VALUE]: otherValue
            });
        }
    };

    getClassName = (): string => {
        let newClassName = this.props.className;

        if ((this.props.inputs || []).length > 1) {
            newClassName += " double-input";
        }

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getUnit = (unit: string | undefined): JSX.Element | null => {
        if (unit) {
            return <span className="unit-input">{unit}</span>;
        }

        return null;
    };

    getInputs = (): Array<JSX.Element> => {
        const { editable, inputs } = this.props;
        const inputList = [];

        for (const input of inputs || []) {
            const index = (inputs || []).indexOf(input) || -1;
            const textWidth = input.inputUnit ? designHelper.getTextWidth(input.inputUnit) : 0;
            const unitPadding = input.inputUnit && textWidth !== 0 ? textWidth : designConstants.UNIT_INPUT_PADDING;

            inputList.push(
                <div
                    className={`input-item-container${index > 0 ? " border-left" : ""}`}
                    key={`input-item${input.inputName}`}
                >
                    <input
                        style={{ paddingRight: unitPadding }}
                        type={"text"}
                        value={input.inputValue}
                        readOnly={!editable}
                        disabled={!editable}
                        onFocus={(event): void => this.handleFocus(event)}
                        onChange={(event): void => this.handleInputChange(event, input.inputName)}
                        onBlur={(event): void => this.handleInputBlur(event, input.inputName)}
                    />
                    {this.getUnit(input.inputUnit)}
                </div>
            );
        }

        return inputList;
    };

    render(): JSX.Element | null {
        if (!this.props.priceObject) {
            return null;
        }

        return <div className={this.getClassName()}>{this.getInputs()}</div>;
    }
}
