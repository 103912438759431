export class ReflectanceData {
    wavelength: number;
    value: number | null;

    constructor(wavelength: number, value: number | null) {
        this.wavelength = wavelength;
        this.value = value;
    }
}

export function mapReflectanceData(wavelength: number, value: number | null): ReflectanceData | null {
    try {
        return new ReflectanceData(wavelength, value);
    } catch (e) {
        return null;
    }
}
