import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { User, cloneUser, updateLoggedUser } from "../types/user";

import { DataItem } from "../types/dataItem";

export type LoginState = Readonly<{
    loggedIn: boolean;
    loggedUser: User | null;
    wrongLogin: boolean;
    dataPrivileges: Array<DataItem>;
    options: any;
    privileges: Record<string, number>;
}>;

const initialState: LoginState = {
    loggedIn: false,
    loggedUser: null,
    wrongLogin: false,
    dataPrivileges: [],
    options: [],
    privileges: {} as Record<string, number>
};

export default function login(state: LoginState = initialState, action: Record<string, any>): LoginState {
    switch (action.type) {
        // TODO nechybi pro wrongLogin nejaky navart do puvodniho stavu? mozna ne
        case actionConstants.LOGIN_SUCCESS: {
            return {
                ...state,
                loggedIn: true,
                loggedUser: updateLoggedUser(
                    action.payload.loggedUser,
                    generalHelper.getObjectByKey(state.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                ),
                wrongLogin: false
            };
        }
        case actionConstants.LOGOUT_SUCCESS: {
            return {
                ...state,
                loggedIn: false,
                loggedUser: null,
                dataPrivileges: [],
                options: [],
                privileges: {}
            };
        }
        case actionConstants.LOGIN_FAILURE: {
            return {
                ...state,
                wrongLogin: action.payload.wrongLogin
            };
        }
        case actionConstants.SAVE_USER_GROUPS_FOR_USER: {
            if (action.payload.loggedUser) {
                const newUser = cloneUser(state.loggedUser);

                if (newUser) {
                    newUser[propertyConstants.PROPERTY_USER_GROUP_LIST] = action.payload.userGroupList;

                    return {
                        ...state,
                        loggedUser: newUser
                    };
                }
            }

            return state;
        }
        case actionConstants.EVENT_SAVE_DATA_PRIVILEGES: {
            return {
                ...state,
                dataPrivileges: action.payload.dataPrivilegesList
            };
        }
        case actionConstants.EVENT_SAVE_OPTIONS: {
            return {
                ...state,
                options: action.payload.optionList,
                loggedUser: updateLoggedUser(
                    state.loggedUser,
                    generalHelper.getObjectByKey(action.payload.optionList, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                )
            };
        }
        case actionConstants.EVENT_SAVE_PRIVILEGES: {
            return {
                ...state,
                privileges: action.payload.privilegesList
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
