import * as generalHelper from "../../../../helpers/generalHelper";
import * as importExportConstants from "../../../../constants/entityConstants/importExportConstants";
import * as modalTypes from "../../../../constants/modalTypes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as requestHelper from "../../../../helpers/requestHelper";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Database } from "../../../../types/database";
import { Dispatch } from "redux";
import { Modal } from "../../../../types/modal";
import { ModalType } from "../../../../constants/modalTypes";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;
// TODO modalType cannot be undef
type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    modalType?: ModalType;
    modalParams: any;
    activeList: Array<any>;
    editable?: boolean;
};

type Props = OwnProps & ContainerProps;

export class TableEditButton extends Component<Props> {
    isVisible = (): boolean => {
        const { dataPrivileges, objectType } = this.props;

        if (objectTypes.HIDDEN_EDIT_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return false;
        }

        if (dataPrivileges?.canUpdate) {
            return true;
        }

        return false;
    };

    canEditAllItems = (items: Array<any>): boolean => {
        return items.some((item) => item[propertyConstants.PROPERTY_CAN_UPDATE] === false) ? false : true;
    };

    /**
     Button is enabled if
     * appropriate database/system/zone is active
     * button is not temporarily disabled mainly because of loading
     * for Units and User groups, each item needs to have permission (canUpdate attribute) in type
     * it has correct dataPrivileges (canUpdate: true)
     * we know what type of modal should be opened and we have its parameters
     */
    isEnabled = (): boolean => {
        const { dataPrivileges, modalType, modalParams, objectType, activeList } = this.props;

        if (
            !objectTypeHelper.isObjectTypeEnabledByHeader(
                this.props.objectType,
                this.props.activeDatabase ?? null,
                this.props.activeSystem ?? null,
                this.props.activeZone ?? null
            )
        ) {
            return false;
        }

        if (this.props.editable === false) {
            return false;
        }

        if (
            (objectType === objectTypes.MASTER_REDLIKE_USER_GROUP ||
                objectType === objectTypes.UNIT ||
                objectType === objectTypes.USER_GROUP ||
                objectTypeHelper.isObjectTypeRedlikeUser(objectType)) &&
            activeList.length &&
            !this.canEditAllItems(activeList)
        ) {
            return false;
        }

        if (
            objectTypeHelper.isObjectTypeSystemZone(objectType) &&
            activeList.length &&
            !this.canEditAllItems(activeList)
        ) {
            return false;
        }

        if (
            modalParams &&
            modalParams[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID] &&
            modalParams[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID] ===
                importExportConstants.EXPORT_TYPE_EMPTY_DATABASE
        ) {
            return false;
        }

        if (dataPrivileges?.canUpdate && modalType && modalParams && activeList.length) {
            return true;
        }

        return false;
    };

    handleOpenEditModal = (): void => {
        const { modalType, modalParams, getItemDetail, openModal } = this.props;

        // TODO remove modalType as it cannot be undefined
        if (this.isVisible() && modalType) {
            if (modalParams) {
                if (generalHelper.updateActiveDataInTable(modalParams.objectType, modalParams.data)) {
                    const modal = new Modal(0, modalType, modalParams, null);
                    let id: propertyConstants.Property;

                    switch (modalParams.objectType) {
                        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
                        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                            id = propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID;
                            break;
                        }
                        case objectTypes.SYSTEM_COLORANT_PACKAGE:
                        case objectTypes.ZONE_COLORANT_PACKAGE: {
                            id = propertyConstants.PROPERTY_COLORANT_PACKAGE_ID;
                            break;
                        }
                        default:
                            id = propertyConstants.PROPERTY_ID;
                            break;
                    }
                    getItemDetail(
                        modalParams.objectType,
                        {
                            [requestHelper.getObjectIdProperty(modalParams.objectType)]: modalParams.data[id]
                        },
                        modal
                    );
                } else {
                    openModal(modalType, modalParams);
                }
            }
        }
    };

    getButtonClassname = (): string => {
        let className = "btn-md btn-info";

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    getEditButtonCaption = (): string => {
        const { modalType } = this.props;
        switch (modalType) {
            case modalTypes.MODAL_BARCODE_EDIT:
                return translate("general.moreInfo");
            case modalTypes.MODAL_DATABASES_EDIT:
            case modalTypes.MODAL_ZONES_EDIT:
                return translate("general.editMetadata");
            case modalTypes.MODAL_CHANGELOG_EDIT:
                return translate("zone.editChangelog");
            case modalTypes.MODAL_VERSION_EDIT:
                return translate("zone.editVersion");
            case modalTypes.MODAL_PRICE_OPTIONS:
                return translate("prices.changeMainSettings");
            default:
                return translate("general.edit");
        }
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button
                    className={this.getButtonClassname()}
                    onClick={(): void => this.handleOpenEditModal()}
                    onMouseDown={(event) => event.preventDefault()}
                >
                    {this.getEditButtonCaption()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getButtonClassname()}>{this.getEditButtonCaption()}</button>;
        }

        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
}>;

export type DispatchType = Readonly<{
    getItemDetail(objectType: objectTypes.ObjectType, params: Record<string, any>, modal: Modal | null): void;
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    getItemDetail: (objectType: objectTypes.ObjectType, params: Record<string, any>, modal: Modal | null): any =>
        dispatch(universalObjectActions.getItemDetail(objectType, params, modal)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TableEditButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableEditButton);
