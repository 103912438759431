import * as historyDatabaseConstants from "../constants/historyDatabaseConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class HistoricalAction {
    id: number | null;
    itemTypeId: historyDatabaseConstants.HistoricalItemType;
    actionTypeId: historyDatabaseConstants.HistoricalActionType;
    actionMethod: string | null;
    requestType: string | null;
    username: string | null;
    ipAddress: string | null;
    databaseName: string | null;
    databaseUuid: string | null;
    systemName: string | null;
    systemUuid: string | null;
    zoneName: string | null;
    zoneUuid: string | null;
    date: Date | null;
    data: string | null;

    constructor(
        id: number | null,
        itemTypeId: historyDatabaseConstants.HistoricalItemType,
        actionTypeId: historyDatabaseConstants.HistoricalActionType,
        actionMethod: string | null,
        requestType: string | null,
        username: string | null,
        ipAddress: string | null,
        databaseName: string | null,
        databaseUuid: string | null,
        systemName: string | null,
        systemUuid: string | null,
        zoneName: string | null,
        zoneUuid: string | null,
        date: Date | null,
        data: string | null
    ) {
        this.id = id;
        this.itemTypeId = itemTypeId;
        this.actionTypeId = actionTypeId;
        this.actionMethod = actionMethod;
        this.requestType = requestType;
        this.username = username;
        this.ipAddress = ipAddress;
        this.databaseName = databaseName;
        this.databaseUuid = databaseUuid;
        this.systemName = systemName;
        this.systemUuid = systemUuid;
        this.zoneName = zoneName;
        this.zoneUuid = zoneUuid;
        this.date = date;
        this.data = data;
    }
}

export function mapHistoricalAction(data: Record<string, any>): HistoricalAction | null {
    try {
        return new HistoricalAction(
            data.id,
            data.itemTypeId,
            data.actionTypeId,
            data.actionMethod,
            data.requestType,
            data.username,
            data.ipAddress,
            data.databaseName,
            data.databaseUuid,
            data.systemName,
            data.systemUuid,
            data.zoneName,
            data.zoneUuid,
            data.date,
            JSON.stringify(data.params)
        );
    } catch (e) {
        return null;
    }
}

export function generateHistoricalActionSections(custom: any): Array<any> {
    return historyDatabaseConstants.HISTORICAL_ITEM_TYPES_LIST.map(
        (item: historyDatabaseConstants.HistoricalItemType) => {
            return {
                [propertyConstants.PROPERTY_ID]: item,
                [propertyConstants.PROPERTY_NAME]: translationHelper.getHistoricalActionSectionTranslation(item, custom)
            };
        }
    );
}

export function generateHistoricalActionTypes(): Array<any> {
    return historyDatabaseConstants.HISTORICAL_ACTION_TYPES_LIST.map(
        (item: historyDatabaseConstants.HistoricalActionType) => {
            return {
                [propertyConstants.PROPERTY_ID]: item,
                [propertyConstants.PROPERTY_NAME]: translationHelper.getHistoricalActionTypeTranslation(item)
            };
        }
    );
}

export function createEmptyHistoricalAction(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: "",
        [propertyConstants.PROPERTY_ACTION_TYPE_ID]: "",
        [propertyConstants.PROPERTY_ACTION_METHOD]: "",
        [propertyConstants.PROPERTY_REQUEST_TYPE]: "",
        [propertyConstants.PROPERTY_USERNAME]: "",
        [propertyConstants.PROPERTY_IP_ADDRESS]: "",
        [propertyConstants.PROPERTY_DATABASE_NAME]: "",
        [propertyConstants.PROPERTY_DATABASE_UUID]: "",
        [propertyConstants.PROPERTY_SYSTEM_NAME]: "",
        [propertyConstants.PROPERTY_SYSTEM_UUID]: "",
        [propertyConstants.PROPERTY_ZONE_NAME]: "",
        [propertyConstants.PROPERTY_ZONE_UUID]: "",
        [propertyConstants.PROPERTY_DATE]: "",
        [propertyConstants.PROPERTY_DATA]: ""
    };
}

export function generateHistoricalActionData(historicalActionsList: Array<HistoricalAction>, custom: any): Array<any> {
    const data: Array<any> = [];
    let historicalAction: HistoricalAction;
    let level = "";

    for (historicalAction of historicalActionsList) {
        if (historicalAction.databaseName) {
            level = historicalAction.databaseName;

            if (historicalAction.systemName && historicalAction.zoneName) {
                level += " (" + historicalAction.zoneName + ")";
            } else if (historicalAction.systemName) {
                level += " (" + historicalAction.systemName + ")";
            }
        }

        data.push({
            [propertyConstants.PROPERTY_ACTION_METHOD]: historicalAction.actionMethod,
            [propertyConstants.PROPERTY_ACTION_TYPE_ID]: translationHelper.getHistoricalActionTypeTranslation(
                historicalAction.actionTypeId
            ),
            [propertyConstants.PROPERTY_DATABASE_NAME]: historicalAction.databaseName,
            [propertyConstants.PROPERTY_DATA]: historicalAction.data,
            [propertyConstants.PROPERTY_DATE]: historicalAction.date,
            [propertyConstants.PROPERTY_ID]: historicalAction.id,
            [propertyConstants.PROPERTY_ITEM_TYPE_ID]: translationHelper.getHistoricalActionSectionTranslation(
                historicalAction.itemTypeId,
                custom
            ),
            [propertyConstants.PROPERTY_LEVEL]: level,
            [propertyConstants.PROPERTY_SYSTEM_NAME]: historicalAction.systemName,
            [propertyConstants.PROPERTY_USERNAME]: historicalAction.username,
            [propertyConstants.PROPERTY_ZONE_NAME]: historicalAction.zoneName
        });
    }

    return data;
}

export function generateHistoricalActionHeadings(
    objectType: objectTypes.ObjectType,
    historicalActionSectionList: Array<Record<string, any>>,
    historicalActionTypeList: Array<Record<string, any>>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number> | null = null
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ACTION_METHOD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION_METHOD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ACTION_METHOD),
            editableList.includes(propertyConstants.PROPERTY_ACTION_METHOD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION_METHOD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION_METHOD),
            requiredList.includes(propertyConstants.PROPERTY_ACTION_METHOD),
            [],
            widthOption?.[propertyConstants.PROPERTY_ACTION_METHOD] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ACTION_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION_TYPE_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ACTION_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_ACTION_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ACTION_TYPE_ID),
            historicalActionTypeList,
            widthOption?.[propertyConstants.PROPERTY_ACTION_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATA,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATA),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_DATA),
            editableList.includes(propertyConstants.PROPERTY_DATA),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATA),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATA),
            requiredList.includes(propertyConstants.PROPERTY_DATA),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATA] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE),
            editableList.includes(propertyConstants.PROPERTY_DATE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE),
            requiredList.includes(propertyConstants.PROPERTY_DATE),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ITEM_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ITEM_TYPE_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            historicalActionSectionList,
            widthOption?.[propertyConstants.PROPERTY_ITEM_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LEVEL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LEVEL),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LEVEL),
            editableList.includes(propertyConstants.PROPERTY_LEVEL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LEVEL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LEVEL),
            requiredList.includes(propertyConstants.PROPERTY_LEVEL),
            [],
            widthOption?.[propertyConstants.PROPERTY_LEVEL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USERNAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USERNAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USERNAME),
            editableList.includes(propertyConstants.PROPERTY_USERNAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USERNAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USERNAME),
            requiredList.includes(propertyConstants.PROPERTY_USERNAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_USERNAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            editableList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_NAME] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
