import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Company } from "../../../types/company";
import { CompanyScene } from "../../../components/scenes/header/CompanyScene";
import { DataItem } from "../../../types/dataItem";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    dataPrivileges: Array<DataItem>;
    companyTableConstant: string;
    companyList: Array<Company>;
    companyActiveList: Array<Company>;
    companyLoading: boolean;
    companyButtonLoading: boolean;
    companyColumnOrder: Array<propertyConstants.Property>;
    companyColumnVisibility: Record<propertyConstants.Property, boolean>;
    companyColumnWidth: Record<propertyConstants.Property, number>;
    companyOffset: number;
    companyPage: number;
    companyRowCount: number;
    companyRowCountCustom: boolean;
    companySearch: string | null;
    companySearchParams: Record<propertyConstants.Property, string>;
    companyShowFilterRow: boolean;
    companyShowGlobalSearch: boolean;
    companySortingAsc: boolean;
    companySortingCriterion: propertyConstants.Property | null;
    companyTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    dataPrivileges: state.login.dataPrivileges,
    companyTableConstant: menuConstants.TABLE_COMPANIES,
    companyList: state.company.list,
    companyActiveList: state.company.activeList,
    companyLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_COMPANIES || request.method === methods.METHOD_DELETE_COMPANY
    ),
    companyButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_COMPANY
    ),
    companyColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_COMPANIES
    ),
    companyColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_COMPANIES
    ),
    companyColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_COMPANIES
    ),
    companyOffset: state.company.offset,
    companyPage: state.company.page,
    companyRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_COMPANIES
    ),
    companyRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_COMPANIES
    ),
    companySearch: state.company.search,
    companySearchParams: state.company.searchParams,
    companyShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_COMPANIES
    ),
    companyShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_COMPANIES
    ),
    companySortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_COMPANIES
        )
    ),
    companySortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_COMPANIES
        )
    ),
    companyTotalCount: state.company.count
});

export const CompanySceneContainer = connect(mapStateToProps)(CompanyScene);
