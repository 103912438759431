import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as priceHelper from "../../../helpers/priceHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import { DispatchType, PropsType } from "../../../containers/scenes/system/SystemPriceSceneContainer";
import React, { FunctionComponent, useEffect, useState } from "react";
import { generatePriceOptionData, generatePriceOptionHeadings } from "../../../types/priceOption";

import { Bookmark } from "../../../types/bookmark";
import { BookmarkTabs } from "../../general/BookmarkTabs";
import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { ModalParams } from "../../../types/modalParams";
import { OptionItem } from "../../../types/optionItem";
import { PriceInfoPanel } from "../../general/scene/PriceInfoPanel";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { SceneCurrencyButtonsContainer } from "../../general/scene/buttons/SceneCurrencyButtons";
import { SceneSaveButtonContainer } from "../../general/scene/buttons/SceneSaveButton";
import { SystemMarginTable } from "./tables/SystemMarginTable";
import { SystemPriceDefaultTable } from "./tables/SystemPriceDefaultTable";
import { SystemPriceGroupTable } from "./tables/SystemPriceGroupTable";
import { SystemPriceSurchargeTable } from "./tables/SystemPriceSurchargeTable";
import { TableEditButtonContainer } from "../../general/table/buttons/TableEditButton";

import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

export const SystemPriceScene: FunctionComponent<Props> = ({ ...props }) => {
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        handleWindowResize();

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const [tablePriceDefault, setTablePriceDefault] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.priceGenericTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePriceDefault(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.priceGenericTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.priceGenericTableConstant]);

    const [tablePriceGroups, setTablePriceGroups] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.priceGroupTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        const optionPriceGroupsEnable = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS
        );

        if (optionPriceGroupsEnable?.[propertyConstants.PROPERTY_VALUE]) {
            setTablePriceGroups(
                (props.menuScene
                    ? props.menuScene.items.find(
                          (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                              item.key === props.priceGroupTableConstant
                      )
                    : null) ?? null
            );
        } else {
            setTablePriceGroups(null);
        }
    }, [props.menuScene, props.priceGroupTableConstant, props.priceOptionList]);

    const [tablePriceMargin, setTablePriceMargin] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.marginTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        const optionDiscount = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_DISCOUNT
        );
        const optionMargin = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARGIN
        );
        const optionMarkup = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARKUP
        );

        if (
            optionDiscount?.[propertyConstants.PROPERTY_VALUE] ||
            optionMargin?.[propertyConstants.PROPERTY_VALUE] ||
            optionMarkup?.[propertyConstants.PROPERTY_VALUE]
        ) {
            setTablePriceMargin(
                (props.menuScene
                    ? props.menuScene.items.find(
                          (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                              item.key === props.marginTableConstant
                      )
                    : null) ?? null
            );
        } else {
            setTablePriceMargin(null);
        }
    }, [props.menuScene, props.marginTableConstant, props.priceOptionList]);

    const [tablePriceOptions, setTablePriceOptions] = useState<any>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.priceOptionTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePriceOptions(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.priceOptionTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.priceOptionTableConstant]);

    const [tablePriceSurcharge, setTablePriceSurcharge] = useState<
        MenuItem | DataItem | OptionItem | PrivilegeItem | null
    >(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.packageTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePriceSurcharge(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.packageTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.packageTableConstant]);

    const [availableMargin, setAvailableMargin] = useState<boolean>(false);
    useEffect(() => {
        const optionMargin = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARGIN
        );

        setAvailableMargin(optionMargin?.[propertyConstants.PROPERTY_VALUE] || false);
    }, [props.priceOptionList]);

    const [availableMarkup, setAvailableMarkup] = useState<boolean>(false);
    useEffect(() => {
        const optionMarkup = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARKUP
        );

        setAvailableMarkup(optionMarkup?.[propertyConstants.PROPERTY_VALUE] || false);
    }, [props.priceOptionList]);

    const [availableDiscount, setAvailableDiscount] = useState<boolean>(false);
    useEffect(() => {
        const optionDiscount = generalHelper.getObjectByKey(
            props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_DISCOUNT
        );

        setAvailableDiscount(optionDiscount?.[propertyConstants.PROPERTY_VALUE] || false);
    }, [props.priceOptionList]);

    const [panelHeight, setPanelHeight] = useState<number>(193);

    const handleWindowResize = (): void => {
        const elementBeforeSize = tablePriceOptions && tablePriceOptions.canUpdate ? 117 : 80;
        const pricePanelEl: any = document.querySelector(".prices-info-panel");
        const pricesLayout = pricePanelEl ? pricePanelEl.offsetHeight + elementBeforeSize : 193;

        setPanelHeight(pricesLayout);
    };

    const getEditSettingsButton = (): JSX.Element => {
        const properties: Array<propertyConstants.Property> = [
            priceConstants.PRICE_OPTION_PRICE_DEFINITION,
            priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST,
            priceConstants.PRICE_OPTION_PRICE_CALCULATION,
            priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS,
            priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS
        ];
        const modalParams = new ModalParams(
            modalTypes.EDIT_MODAL,
            objectTypes.SYSTEM_PRICE_OPTION,
            translate("prices.priceSettings"),
            generatePriceOptionData(objectTypes.SYSTEM_PRICE_OPTION, props.priceOptionList),
            generatePriceOptionHeadings(
                objectTypes.SYSTEM_PRICE_OPTION,
                properties,
                properties,
                properties,
                [],
                [],
                tableConstants.EMPTY_COLUMN_WIDTH
            ),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props.currency
        );

        return (
            <TableEditButtonContainer
                dataPrivileges={tablePriceOptions}
                objectType={objectTypes.SYSTEM_PRICE_OPTION}
                modalType={modalTypes.MODAL_PRICE_OPTIONS}
                modalParams={modalParams}
                activeList={props.priceOptionList}
            />
        );
    };

    const getDefaultPriceTable = (): JSX.Element => {
        return (
            <div className="layout-item" key={props.priceGenericTableConstant}>
                <SystemPriceDefaultTable
                    tableConstant={props.priceGenericTableConstant}
                    objectType={objectTypes.SYSTEM_PRICE_GENERIC}
                    dataPrivileges={tablePriceDefault}
                    loading={props.priceGenericLoading}
                    buttonLoading={props.priceGenericButtonLoading}
                    allList={props.priceGenericList}
                    activeList={props.priceGenericActiveList}
                    columnOrder={props.priceGenericColumnOrder}
                    columnVisibility={props.priceGenericColumnVisibility}
                    columnWidth={props.priceGenericColumnWidth}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.DEFAULT_ROW_COUNT}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={tableConstants.DEFAULT_SEARCH}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={false}
                    sortingCriterion={null}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    currency={props.currency}
                    optionPriceDefinition={props.optionPriceDefinition?.[propertyConstants.PROPERTY_VALUE] || null}
                    optionPriceEnableCompanyCost={
                        props.optionPriceEnableCompanyCost?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                />
            </div>
        );
    };

    const getPriceGroupTable = (): JSX.Element => {
        return (
            <div className="layout-item" key={props.priceGroupTableConstant}>
                <SystemPriceGroupTable
                    tableConstant={props.priceGroupTableConstant}
                    objectType={objectTypes.SYSTEM_PRICE_GROUP}
                    dataPrivileges={tablePriceGroups}
                    loading={props.priceGroupLoading}
                    buttonLoading={props.priceGroupButtonLoading}
                    allList={props.priceGroupList}
                    activeList={props.priceGroupActiveList}
                    columnOrder={props.priceGroupColumnOrder}
                    columnVisibility={props.priceGroupColumnVisibility}
                    columnWidth={props.priceGroupColumnWidth}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.DEFAULT_ROW_COUNT}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={tableConstants.DEFAULT_SEARCH}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={false}
                    sortingCriterion={null}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    currency={props.currency}
                />
            </div>
        );
    };

    const getMarginTable = (): JSX.Element => {
        return (
            <div className="height-100" key={props.marginTableConstant}>
                <SystemMarginTable
                    tableConstant={props.marginTableConstant}
                    objectType={objectTypes.SYSTEM_PRICE_MARGIN}
                    dataPrivileges={tablePriceMargin}
                    loading={props.marginLoading}
                    buttonLoading={props.marginButtonLoading}
                    marginTypeList={props.marginTypeList}
                    allList={priceHelper.getFilteredMarginList(
                        props.marginList,
                        availableMargin,
                        availableMarkup,
                        availableDiscount
                    )}
                    activeList={priceHelper.getFilteredMarginList(
                        props.marginActiveList,
                        availableMargin,
                        availableMarkup,
                        availableDiscount
                    )}
                    totalCount={null}
                    offset={0}
                    page={1}
                    search={props.marginSearch}
                    searchParams={props.marginSearchParams}
                    sortingAsc={props.marginSortingAsc}
                    sortingCriterion={props.marginSortingCriterion}
                    rowCount={0}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    columnOrder={props.marginColumnOrder}
                    columnVisibility={props.marginColumnVisibility}
                    columnWidth={props.marginColumnWidth}
                    showFilterRow={false}
                    showGlobalSearch={props.marginShowGlobalSearch}
                />
            </div>
        );
    };

    const getSurchargeTable = (): JSX.Element => {
        return (
            <div className="height-100" key={props.packageTableConstant}>
                <SystemPriceSurchargeTable
                    tableConstant={props.packageTableConstant}
                    objectType={objectTypes.SYSTEM_PACKAGE}
                    dataPrivileges={tablePriceSurcharge}
                    loading={props.packageLoading}
                    buttonLoading={props.packageButtonLoading}
                    allList={props.packageList}
                    activeList={props.packageActiveList}
                    columnOrder={props.packageColumnOrder}
                    columnVisibility={props.packageColumnVisibility}
                    columnWidth={props.packageColumnWidth}
                    offset={props.packageOffset}
                    page={props.packagePage}
                    rowCount={props.packageRowCount}
                    rowCountCustom={props.packageRowCountCustom}
                    search={props.packageSearch}
                    searchParams={props.packageSearchParams}
                    showFilterRow={false}
                    showGlobalSearch={props.packageShowGlobalSearch}
                    sortingAsc={props.packageSortingAsc}
                    sortingCriterion={props.packageSortingCriterion}
                    totalCount={props.packageTotalCount}
                    currency={props.currency}
                />
            </div>
        );
    };

    const getLeftPart = (): JSX.Element | null => {
        if (tablePriceDefault && tablePriceGroups) {
            return (
                <div className="double-tables">
                    {getDefaultPriceTable()}
                    {getPriceGroupTable()}
                </div>
            );
        }

        if (tablePriceDefault) {
            return getDefaultPriceTable();
        }

        if (tablePriceGroups) {
            return getPriceGroupTable();
        }

        return null;
    };

    const getBookmarksTabs = (): Array<any> => {
        const bookmarks = [];
        const marginTitle = priceHelper.getMarginTitle(availableMargin, availableMarkup, availableDiscount);

        if (marginTitle) {
            bookmarks.push(
                new Bookmark(
                    menuConstants.TABLE_SYSTEM_PRICE_MARGIN,
                    "",
                    marginTitle,
                    [],
                    tablePriceMargin,
                    false,
                    getMarginTable()
                )
            );
        }

        bookmarks.push(
            new Bookmark(
                menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE,
                "",
                translate("prices.packageSurcharge"),
                [],
                tablePriceSurcharge,
                false,
                getSurchargeTable()
            )
        );

        return bookmarks;
    };

    const getRightPart = (): JSX.Element => {
        return (
            <div className="layout-item bookmark-item">
                <div className="height-100">
                    <BookmarkTabs
                        bookmarks={getBookmarksTabs()}
                        activeBookmark={props.activeBookmark}
                        setActiveBookmarkCallback={(key: string | null): void => {
                            props.setActiveBookmark(key);
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="content-layout">
            <div className="layout-item">
                <div className="height-100 system">
                    <SceneSaveButtonContainer />
                    <SceneCurrencyButtonsContainer />
                    <div className="main-title">{translate("prices.prices")}</div>
                    <div className="scene-btn-row">{getEditSettingsButton()}</div>
                    <PriceInfoPanel dataPrivileges={tablePriceOptions} priceOptionList={props.priceOptionList} />
                    <div
                        className="layout-prices"
                        style={{
                            height: "calc(100% - " + panelHeight + "px)"
                        }}
                    >
                        {getLeftPart()}
                        {getRightPart()}
                    </div>
                </div>
            </div>
        </div>
    );
};
