import * as navigationActions from "../actions/navigationActions";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Scene, Wizard } from "../constants/navigationTypes";

import { Dispatch } from "redux";
import { Modal } from "../types/modal";
import { ModalType } from "../constants/modalTypes";
import { Router } from "../components/Router";
import { User } from "../types/user";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    activeScene: Scene;
    activeUser: User | null;
    activeWizard: Wizard | null;
    dataChanged: boolean;
    isJumping: boolean;
    licensed: boolean;
    loggedIn: boolean;
    modalList: Array<Modal>;
    routingPath: string;
    serverConnected: boolean;
}>;

export type DispatchType = Readonly<{
    closeModal: (type: ModalType) => void;
    commit: () => void;
    rollback: () => void;
    setActiveScene: (scene: Scene) => void;
    setJumpScene: (scene: Scene) => void;
}>;

const mapStateToProps = (state: any): PropsType => ({
    activeScene: state.navigation.activeScene,
    activeUser: state.login.loggedUser,
    activeWizard: state.navigation.activeWizard,
    dataChanged: state.navigation.dataChanged,
    isJumping: state.navigation.isJumping,
    licensed: state.license.licensed,
    loggedIn: state.login.loggedIn,
    modalList: state.navigation.modalList,
    routingPath: state.router.location.pathname,
    serverConnected: state.server.connected
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    closeModal: (type: ModalType): any => dispatch(navigationActions.navigationCloseModal(type)),
    commit: (): any => dispatch(universalObjectActions.dataSave()),
    rollback: (): any => dispatch(universalObjectActions.dataRollback()),
    setActiveScene: (scene: Scene): any => dispatch(navigationActions.navigationSetScene(scene)),
    setJumpScene: (scene: Scene): any => dispatch(navigationActions.navigationSetJump(scene))
});

export const RouterContainer = connect(mapStateToProps, mapDispatchToProps)(Router);
