import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    isEditable: boolean;
    callbackBlur: (event: any, value: any, rowId?: number | string, headingId?: propertyConstants.Property) => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellCheckbox extends Component<Props> {
    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (property: propertyConstants.Property): string => {
        return `td ${property}`;
    };

    getLabelClassname = (): string => {
        let className = "checkbox-label";

        if (!this.props.isEditable) {
            className += " read-only";
        }

        return className;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const valueBoolean = value !== null ? value : false;

        return (
            <div className={this.getClassname(property)} key={property}>
                <label className={this.getLabelClassname()}>
                    <input
                        checked={valueBoolean}
                        className={"checkbox"}
                        disabled={this.isDisabled()}
                        name={"show"}
                        readOnly={this.isDisabled()}
                        type={"checkbox"}
                        onChange={(event: any): void => {
                            this.props.callbackBlur(
                                event,
                                !valueBoolean,
                                item[propertyConstants.PROPERTY_ID],
                                property
                            );
                        }}
                    />
                    <span className={"checkmark"}></span>
                </label>
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellCheckbox);
