import * as objectTypes from "../../../../constants/objectTypes";
import * as tableConstants from "../../../../constants/tableConstants";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    modalParams: any;
};

type Props = OwnProps & ContainerProps;

export class TableJumpButton extends Component<Props> {
    isVisible = (): boolean => {
        const { objectType } = this.props;
        if (
            objectType === objectTypes.SYSTEM_COLOR ||
            objectType === objectTypes.ZONE_COLOR ||
            objectType === objectTypes.SYSTEM_FORMULA ||
            objectType === objectTypes.ZONE_FORMULA
        ) {
            return false;
        }
        return true;
    };

    isEnabled = (): boolean => {
        const { modalParams } = this.props;

        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR:
            case objectTypes.SYSTEM_COLOR:
            case objectTypes.ZONE_COLOR: {
                if (modalParams?.colorInFandeckId && modalParams?.fandeckId && modalParams?.productId) {
                    return true;
                }

                return false;
            }
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA: {
                if (
                    modalParams?.baseInProductId &&
                    modalParams?.colorInFandeckId &&
                    modalParams?.fandeckId &&
                    modalParams?.formulaId &&
                    modalParams?.productId
                ) {
                    return true;
                }

                return false;
            }
            default:
                return false;
        }
    };

    handleJumpClick = (): void => {
        const { modalParams, jumpToItem } = this.props;
        const defaultParams = {
            count: modalParams?.count ?? tableConstants.DEFAULT_TOTAL_COUNT,
            sortingAsc: modalParams?.sortingAsc ?? tableConstants.DEFAULT_SORTING_ASC,
            sortingCriterion: modalParams?.sortingCriterion ?? tableConstants.DEFAULT_SORTING_COLUMN,
            search: modalParams?.search ?? tableConstants.DEFAULT_SEARCH,
            searchParams: modalParams?.searchParams ?? tableConstants.DEFAULT_SEARCH_PARAMS,
            offset: modalParams?.offset ?? tableConstants.DEFAULT_OFFSET,
            page: modalParams?.page ?? tableConstants.DEFAULT_PAGE,
            rowCount: modalParams?.rowCount ?? tableConstants.DEFAULT_ROW_COUNT
        };

        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR: {
                jumpToItem(objectTypes.MASTER_FORMULA, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            case objectTypes.SYSTEM_COLOR: {
                jumpToItem(objectTypes.SYSTEM_FORMULA, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            case objectTypes.ZONE_COLOR: {
                jumpToItem(objectTypes.ZONE_FORMULA, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            case objectTypes.MASTER_FORMULA: {
                jumpToItem(objectTypes.MASTER_COLOR, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            case objectTypes.SYSTEM_FORMULA: {
                jumpToItem(objectTypes.SYSTEM_COLOR, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            case objectTypes.ZONE_FORMULA: {
                jumpToItem(objectTypes.ZONE_COLOR, {
                    ...defaultParams,
                    baseInProductId: modalParams?.baseInProductId || null,
                    colorInFandeckId: modalParams?.colorInFandeckId || null,
                    fandeckId: modalParams?.fandeckId || null,
                    formulaId: modalParams?.formulaId || null,
                    productId: modalParams?.productId || null
                });
                break;
            }
            default:
                break;
        }
    };

    getCaption = (): string => {
        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR:
            case objectTypes.SYSTEM_COLOR:
            case objectTypes.ZONE_COLOR:
                return translate("formula.jumpToFormula");
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA:
                return translate("color.jumpToColor");
            default:
                return "";
        }
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className="btn-md btn-info" onClick={(): void => this.handleJumpClick()}>
                    {this.getCaption()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className="btn-md btn-info disabled">{this.getCaption()}</button>;
        }

        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    jumpToItem(objectType: objectTypes.ObjectType, params: any): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    jumpToItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.jumpToItem(objectType, params))
});

export const TableJumpButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableJumpButton);
