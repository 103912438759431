import * as inputConstants from "../constants/inputConstants";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as propertyHelper from "./propertyHelper";
import * as tableConstants from "../constants/tableConstants";
import * as translationConstants from "../constants/translationConstants";

import { ModalType } from "../constants/modalTypes";
import { TableHeading } from "../types/tableHeading";
import { t as translate } from "react-i18nify";

// TODO check while file

export const getButtonCaption = (objectType: objectTypes.ObjectType, property: propertyConstants.Property): string => {
    const replacements = {
        redlikeWebAbbreviation: translationConstants.REDLIKE_WEB_ABBREVIATION
    };

    switch (property) {
        case propertyConstants.PROPERTY_IMAGE_CATEGORY_ID:
        case propertyConstants.PROPERTY_FILE_CATEGORY_ID:
            return "...";
        case propertyConstants.PROPERTY_STATIC_SQL:
            return translate("general.preview");
        case propertyConstants.PROPERTY_PASSWORD:
            if (objectTypes.VALIDATE_PASSWORD_TYPES.includes(objectType)) {
                return translate("validation.validateR2W", replacements);
            }

            return "";
        case propertyConstants.PROPERTY_VERSION:
            return translate("export.showR2WVersion", replacements);
        default:
            return "";
    }
};

export const getButtonEditing = (property: propertyConstants.Property, object: Record<string, any>): boolean => {
    switch (property) {
        case propertyConstants.PROPERTY_STATIC_SQL: {
            if (!object?.[propertyConstants.PROPERTY_STATIC_SQL]) {
                return false;
            }
            return true;
        }
        case propertyConstants.PROPERTY_PASSWORD: {
            if (
                object[propertyConstants.PROPERTY_PASSWORD] === null ||
                object[propertyConstants.PROPERTY_PASSWORD] === "" ||
                object[propertyConstants.PROPERTY_UUID] === null ||
                object[propertyConstants.PROPERTY_UUID] === ""
            ) {
                return false;
            }
            return true;
        }
        default:
            return true;
    }
};

export const getStringButtonVisibility = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property
): boolean => {
    return (propertyHelper.isPropertyPassword(property) && objectTypes.VALIDATE_PASSWORD_TYPES.includes(objectType)) ||
        (property === propertyConstants.PROPERTY_VERSION && objectType === objectTypes.EXPORT_TASK)
        ? true
        : false;
};

export const getStringButtonClassName = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property
): string => {
    let className = "btn-info";

    if (property === propertyConstants.PROPERTY_VERSION && objectType === objectTypes.EXPORT_TASK) {
        className += " button-export-section";
    } else {
        className += " btn-md";
    }

    return className;
};

export const getButtonVisibility = (property: propertyConstants.Property): boolean => {
    switch (property) {
        case propertyConstants.PROPERTY_DATABASE_ID:
        case propertyConstants.PROPERTY_IMAGE_CATEGORY_ID:
        case propertyConstants.PROPERTY_FILE_CATEGORY_ID:
        case propertyConstants.PROPERTY_STATIC_SQL:
        case propertyConstants.PROPERTY_SYSTEM_ID:
        case propertyConstants.PROPERTY_VERSION:
        case propertyConstants.PROPERTY_ZONE_ID:
            return true;
        default:
            return false;
    }
};

export const getParentProperty = (
    heading: TableHeading,
    params: Record<string, any>
): propertyConstants.Property | null => {
    if (params?.type === modalTypes.EDIT_MODAL) {
        if (propertyConstants.PRICE_PROPERTIES.includes(heading[tableConstants.TABLE_HEADING_NAME])) {
            return heading[tableConstants.TABLE_HEADING_NAME];
        }

        if (objectTypes.SYSTEM_OBJECT_TYPES.includes(params?.objectType)) {
            return propertyHelper.getPropertyMaster(heading[tableConstants.TABLE_HEADING_NAME]);
        }

        if (objectTypes.ZONE_OBJECT_TYPES.includes(params?.objectType)) {
            return propertyHelper.getPropertySystem(heading[tableConstants.TABLE_HEADING_NAME]);
        }
    }

    return null;
};

export const getModalType = (property: propertyConstants.Property, editable: boolean): ModalType | null => {
    if (!editable) {
        return null;
    }

    switch (property) {
        case propertyConstants.PROPERTY_FILE_CATEGORY_ID:
            return modalTypes.MODAL_FILE_CATEGORIES_TABLE;
        case propertyConstants.PROPERTY_IMAGE_CATEGORY_ID:
            return modalTypes.MODAL_IMAGE_CATEGORIES_TABLE;
        case propertyConstants.PROPERTY_STATIC_SQL:
            return modalTypes.MODAL_FILE_PREVIEW;
        case propertyConstants.PROPERTY_MORE_INFO_PRINT:
        case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
        case propertyConstants.PROPERTY_MORE_INFO:
            return modalTypes.MODAL_FORMULA_NOTES_TABLE;
        case propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES:
            return modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE;
        case propertyConstants.PROPERTY_VERSION:
            return modalTypes.MODAL_R2W_VERSION_PREVIEW;
        default:
            return null;
    }
};

export const getAddModalProperty = (objectType: objectTypes.ObjectType): propertyConstants.Property | null => {
    switch (objectType) {
        case objectTypes.ICON:
        case objectTypes.IMAGE:
            return propertyConstants.PROPERTY_IMAGE_ID;
        case objectTypes.ZONE:
            return propertyConstants.PROPERTY_ZONE_ID;
        default:
            return null;
    }
};

// TODO string only for ImportModal - unify
export const getTableType = (inputConstant: inputConstants.InputType | string): tableConstants.TableType | null => {
    switch (inputConstant) {
        case inputConstants.PARAMETER_TYPE_CHECK_BOX:
            return tableConstants.TABLE_TYPE_BOOLEAN;
        case inputConstants.PARAMETER_TYPE_RADIO_BUTTON:
            return tableConstants.TABLE_TYPE_STRING;
        case inputConstants.PARAMETER_TYPE_SELECT_BOX:
            return tableConstants.TABLE_TYPE_SELECT;
        case inputConstants.PARAMETER_TYPE_TEXT_INPUT:
        case inputConstants.PARAMETER_TYPE_PASSWORD:
            return tableConstants.TABLE_TYPE_STRING;
        case inputConstants.PARAMETER_TYPE_BUTTON_INPUT:
            return tableConstants.TABLE_TYPE_BUTTON;
        case inputConstants.PARAMETER_TYPE_TEXT_AREA:
            return tableConstants.TABLE_TYPE_TEXT;
        case inputConstants.PARAMETER_TYPE_TEXT_READONLY:
            return tableConstants.TABLE_TYPE_TEXT;
        default:
            return null;
    }
};

export const getFileValidation = (objectType: objectTypes.ObjectType): string => {
    if (objectType === objectTypes.PRODUCT_SHEET) {
        return ".pdf, .txt";
    }

    if (objectType === objectTypes.STATIC_SQL) {
        return ".sql, .txt";
    }

    if (objectTypeHelper.isObjectTypeImage(objectType)) {
        return ".bmp, .jpg, .jpeg, .png, .svg, .tiff";
    }

    return "";
};

export const getFileValidTypes = (objectType: objectTypes.ObjectType): Array<string> => {
    if (objectTypeHelper.isObjectTypeImage(objectType)) {
        return ["image/bmp", "image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/tiff"];
    }

    return [];
};

export const getFileNote = (objectType: objectTypes.ObjectType): string | null => {
    if (objectType === objectTypes.STATIC_SQL) {
        return translate("file.utf8File");
    }

    return null;
};

export const getUploadImageInputType = (objectType: objectTypes.ObjectType): inputConstants.UploadType => {
    switch (objectType) {
        case objectTypes.ICON:
            return inputConstants.UPLOAD_TYPE_ICON;
        case objectTypes.IMAGE_DIRECTORY:
            return inputConstants.UPLOAD_TYPE_IMAGES;
        default:
            return inputConstants.UPLOAD_TYPE_IMAGE;
    }
};

export const isImagePreviewUploadType = (type: inputConstants.UploadType): boolean => {
    switch (type) {
        case inputConstants.UPDATE_TYPE_ICON:
        case inputConstants.UPDATE_TYPE_IMAGE:
        case inputConstants.UPLOAD_TYPE_ICON:
        case inputConstants.UPLOAD_TYPE_IMAGE:
            return true;
        default:
            return false;
    }
};
