export type ValidationType =
    | typeof CLIENT_ERROR_VALIDATION
    | typeof FOOTER_VALIDATION
    | typeof GENERAL_VALIDATION
    | typeof INPUT_VALIDATION
    | typeof REQUIRED_VALIDATION;

export const CLIENT_ERROR_VALIDATION = "clientErrorValidation";
export const FOOTER_VALIDATION = "footerValidation";
export const GENERAL_VALIDATION = "generalValidation";
export const INPUT_VALIDATION = "inputValidation";
export const REQUIRED_VALIDATION = "requiredValidation";
