import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionActions from "../../../actions/optionActions";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as privilegesHelper from "../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as routingHelper from "../../../helpers/routingHelper";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../types/tableHeading";

import { connect } from "react-redux";
import { findDOMNode } from "react-dom";

import imgTableSettings from "../../../resources/img/icons/table-settings.svg";

import { t as translate } from "react-i18nify";

type OwnProps = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    headings: Array<TableHeading>;
    additionalData?: Record<propertyConstants.Property, any>;
    rowCount: number;
    rowCountCustom: boolean;
    showGlobalSearch: boolean;
    showFilterRow: boolean;
    callbackMenuOpened?: (opened: boolean) => void;
};

type ContainerProps = PropsType & DispatchType;
type Props = OwnProps & ContainerProps;

type State = {
    menuIsOpened: boolean;
    submenuColumnsIsOpened: boolean;
    submenuFilterSearchIsOpened: boolean;
    submenuRowCountIsOpened: boolean;
};

export class TableOptions extends Component<Props, State> {
    _dropdownRef = React.createRef<HTMLDivElement>();

    state: State = {
        menuIsOpened: false,
        submenuColumnsIsOpened: false,
        submenuFilterSearchIsOpened: false,
        submenuRowCountIsOpened: false
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeMenuDropdown);
        // window.addEventListener("resize", this.recalculateFitHeightOption);

        // this.recalculateFitHeightOption();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const { callbackMenuOpened } = this.props;
        const newState = {
            ...this.state,
            submenuColumnsIsOpened: false,
            submenuFilterSearchIsOpened: false,
            submenuRowCountIsOpened: false
        };

        if (
            prevState.submenuColumnsIsOpened !== this.state.submenuColumnsIsOpened &&
            this.state.submenuColumnsIsOpened
        ) {
            this.setState({ ...newState, submenuColumnsIsOpened: true });
        }

        if (
            prevState.submenuFilterSearchIsOpened !== this.state.submenuFilterSearchIsOpened &&
            this.state.submenuFilterSearchIsOpened
        ) {
            this.setState({ ...newState, submenuFilterSearchIsOpened: true });
        }

        if (
            prevState.submenuRowCountIsOpened !== this.state.submenuRowCountIsOpened &&
            this.state.submenuRowCountIsOpened
        ) {
            this.setState({ ...newState, submenuRowCountIsOpened: true });
        }

        /*
        if (
            JSON.stringify(prevProps.sceneTableSize) !== JSON.stringify(this.props.sceneTableSize) ||
            this.props.sceneLayout !== prevProps.sceneLayout
        ) {
            this.recalculateFitHeightOption();
        }
        */

        // Keep as last, because it affects other state in CDU
        if (prevState.menuIsOpened !== this.state.menuIsOpened) {
            if (this.state.menuIsOpened && callbackMenuOpened) {
                callbackMenuOpened(true);
            } else {
                this.setState(newState);

                if (callbackMenuOpened) {
                    callbackMenuOpened(false);
                }
            }
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeMenuDropdown);
        // window.removeEventListener("resize", this.recalculateFitHeightOption);
    }

    preventParentClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
    };

    recalculateFitHeightOption = (): void => {
        if (this.props.sceneTableSize !== null && this.props.sceneLayout !== null && this.props.rowCountCustom) {
            this.handleSubmenuRowCountChange(this.getCustomRowCount(), this.props.rowCountCustom);
        }
    };

    closeMenuDropdown = (event: Record<string, any>, forceClose?: boolean): void => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target) || forceClose) {
            if (this.state.submenuColumnsIsOpened) {
                this.handleSubmenuColumnsClick(false);
            }

            if (this.state.submenuRowCountIsOpened) {
                this.handleSubmenuRowCountClick(false);
            }

            if (this.state.submenuFilterSearchIsOpened) {
                this.handleSubmenuFilterSearchClick(false);
            }

            // Keep as last, because it affects state in CDU
            if (this.state.menuIsOpened) {
                this.handleTableMenuClick(false);
            }
        }
    };

    isVisible = (): boolean => {
        if (objectTypes.HIDDEN_OPTION_BUTTON_OBJECT_TYPES.includes(this.props.objectType)) {
            return false;
        }

        return true;
    };

    getOptions = (): Array<string> => {
        const { objectType } = this.props;

        if (objectTypeHelper.isObjectTypeWithoutTableOptions(objectType)) {
            return [];
        }

        if (
            objectTypeHelper.isObjectTypeRedlikeOption(objectType) ||
            objectTypeHelper.isObjectTypeSystemZone(objectType) ||
            objectTypeHelper.isObjectTypeWebZone(objectType)
        ) {
            return [
                optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
                optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
                optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY
            ];
        }

        if (
            objectTypeHelper.isObjectTypePriceMargin(objectType) ||
            objectTypeHelper.isObjectTypeUser(objectType) ||
            objectTypeHelper.isObjectTypeDatabase(objectType) ||
            objectType === objectTypes.COMPANY
        ) {
            return [
                optionsConstants.OPTION_SHOW_FILTER_ROW,
                optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
                optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
                optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY
            ];
        }

        return [
            optionsConstants.OPTION_SHOW_FILTER_ROW,
            optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
            optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
            optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
            optionsConstants.OPTION_TABLE_ROW_COUNT
        ];
    };

    /**
     * Handle actions
     */

    handleCloseMenuDropdown = (event: React.MouseEvent<HTMLUListElement>): void => {
        this.closeMenuDropdown(event, true);
    };

    handleTableMenuClick = (on?: boolean): void => {
        this.setState({
            menuIsOpened: on ?? true
        });

        // User options for table (column order, column visibility, column width) need to be sent at once to server side,
        // otherwise it updates one by one, and table columns are re-rendering three times
        let newOptions: Array<{ key: string; object?: string; value?: any }> = [];

        if (on === false) {
            newOptions = [
                { key: optionsConstants.OPTION_TABLE_COLUMNS_ORDER },
                { key: optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY },
                { key: optionsConstants.OPTION_TABLE_COLUMNS_WIDTH },
                { key: optionsConstants.OPTION_TABLE_ROW_COUNT },
                { key: optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM },
                { key: optionsConstants.OPTION_SHOW_FILTER_ROW },
                { key: optionsConstants.OPTION_SHOW_GLOBAL_SEARCH }
            ];

            this.props.setOptions(this.props.objectType, newOptions, true);
        }
    };

    handleSubmenuColumnsClick = (on?: boolean): void => {
        this.setState({
            submenuColumnsIsOpened: on ?? true
        });
    };

    handleSubmenuColumnPriorityChange = (
        event: React.MouseEvent<HTMLButtonElement>,
        oldIndex: number,
        newIndex: number
    ): void => {
        event.stopPropagation();

        const newHeadings = generalHelper.moveItemInArray(this.props.headings, oldIndex, newIndex);

        const columns = newHeadings.map((item) => item[tableConstants.TABLE_HEADING_NAME]);

        this.props.setColumnOrder(this.props.tableConstant, columns);
    };

    handleSubmenuColumnVisibilityChange = (property: propertyConstants.Property, value: boolean): void => {
        let columns = {};

        for (const heading of this.props.headings) {
            if (heading[tableConstants.TABLE_HEADING_NAME] === property) {
                columns = {
                    ...columns,
                    [heading[tableConstants.TABLE_HEADING_NAME]]: value
                };
            } else {
                columns = {
                    ...columns,
                    [heading[tableConstants.TABLE_HEADING_NAME]]: heading[tableConstants.TABLE_HEADING_VISIBILITY]
                };
            }
        }

        this.props.setColumnVisibility(this.props.tableConstant, columns);
    };

    handleSubmenuFilterSearchClick = (on?: boolean): void => {
        this.setState({
            submenuFilterSearchIsOpened: on ?? true
        });
    };

    handleSubmenuFilterSearchGlobalChange = (value: boolean): void => {
        this.props.setShowGlobalSearch(this.props.tableConstant, value);
    };

    handleSubmenuFilterSearchRowChange = (value: boolean): void => {
        this.props.setShowFilterRow(this.props.tableConstant, value);
    };

    handleSubmenuRowCountChange = (rowCount: number, custom: boolean = this.props.rowCountCustom): void => {
        const newOptions: Array<{ key: string; object: string; value: any }> = [
            { key: optionsConstants.OPTION_TABLE_ROW_COUNT, object: this.props.tableConstant, value: rowCount },
            { key: optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM, object: this.props.tableConstant, value: custom }
        ];

        this.props.setOptions(this.props.objectType, newOptions, false);
    };

    handleSubmenuRowCountClick = (on?: boolean): void => {
        this.setState({
            submenuRowCountIsOpened: on ?? true
        });
    };

    getCustomRowCount = (): number => {
        const table = document.getElementById(this.props.objectType);
        const tableBody = table?.querySelector(".tbody");
        const tableHeader = table?.querySelector(".thead");

        return table && tableBody && tableHeader
            ? Math.floor((table.clientHeight - tableHeader.clientHeight) / 26)
            : this.props.rowCount;
    };

    getFinalVisibleHeadingIndex = (): number => {
        let index = 0;

        for (const heading of this.props.headings) {
            if (
                propertyHelper.isPropertyValidForObjectType(
                    heading[tableConstants.TABLE_HEADING_NAME],
                    this.props.objectType,
                    this.props.privileges,
                    this.props.systemOptionPriceDefinition,
                    this.props.systemOptionPriceEnableCompanyCost,
                    this.props.systemOptionPriceEnablePriceGroups,
                    this.props.zoneOptionPriceDefinition,
                    this.props.zoneOptionPriceEnableCompanyCost,
                    this.props.zoneOptionPriceEnablePriceGroups,
                    this.props.additionalData?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? null
                )
            ) {
                index++;
            }
        }

        return index - 1;
    };

    /**
     * Table submenu options
     */

    getSubmenuColumnsOptionVisibility = (
        heading: TableHeading,
        property: propertyConstants.Property,
        options: Array<string>
    ): JSX.Element | null => {
        const alwaysVisible = heading[tableConstants.TABLE_HEADING_ALWAYS_VISIBLE];
        const isVisible = alwaysVisible ? true : heading[tableConstants.TABLE_HEADING_VISIBILITY];
        const isEditable = !alwaysVisible || (alwaysVisible && !isVisible);

        if (options.includes(optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY)) {
            return (
                <label className={!isEditable ? "checkbox-label no-title disabled" : "checkbox-label no-title"}>
                    <input
                        type="checkbox"
                        name="show"
                        className="checkbox"
                        checked={isVisible}
                        onChange={(): void => this.handleSubmenuColumnVisibilityChange(property, !isVisible)}
                        disabled={!isEditable}
                    />
                    <span className="checkmark"></span>
                </label>
            );
        }

        return null;
    };

    getSubmenuColumnsOptionOrder = (index: number, options: Array<string>): JSX.Element | null => {
        const finalIndex = this.getFinalVisibleHeadingIndex();
        const firstIndex = 0;

        const upPriorityClassName = index === firstIndex ? "btn-info hidden" : "btn-info";
        const downPriorityClassName = index === firstIndex + finalIndex ? "btn-info hidden" : "btn-info";

        for (const heading of this.props.headings) {
            if (propertyConstants.FIXED_COLUMN_PROPERTIES.includes(heading[tableConstants.TABLE_HEADING_NAME])) {
                index++;
            }
        }

        if (options.includes(optionsConstants.OPTION_TABLE_COLUMNS_ORDER)) {
            return (
                <div className="priority-buttons-container">
                    <div className="priority-buttons">
                        <button
                            className={upPriorityClassName}
                            onClick={(event) => this.handleSubmenuColumnPriorityChange(event, index, index - 1)}
                        >
                            <span className="caret caret-up" />
                        </button>
                        <button
                            className={downPriorityClassName}
                            onClick={(event) => this.handleSubmenuColumnPriorityChange(event, index, index + 1)}
                        >
                            <span className="caret caret-down" />
                        </button>
                    </div>
                </div>
            );
        }

        return null;
    };

    getSubmenuFilterSearchGlobalOption = (): JSX.Element | null => {
        const { showGlobalSearch } = this.props;

        return (
            <li className="dropdown-item" key={optionsConstants.OPTION_SHOW_GLOBAL_SEARCH}>
                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        name="show-global-search"
                        className="checkbox"
                        checked={showGlobalSearch}
                        onChange={(): void => this.handleSubmenuFilterSearchGlobalChange(!showGlobalSearch)}
                    />
                    <span className="checkmark"></span>
                    {translate("options.showGlobalSearch")}
                </label>
            </li>
        );
    };

    getSubmenuFilterSearchRowOption = (): JSX.Element | null => {
        const { showFilterRow } = this.props;

        if (!objectTypes.HIDDEN_FILTER_ROW_OBJECT_TYPES.includes(this.props.objectType)) {
            return (
                <li className="dropdown-item" key={optionsConstants.OPTION_SHOW_FILTER_ROW}>
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            name="show-filter-row"
                            className="checkbox"
                            checked={showFilterRow}
                            onChange={(): void => this.handleSubmenuFilterSearchRowChange(!showFilterRow)}
                        />
                        <span className="checkmark"></span>
                        {translate("options.showFilterRow")}
                    </label>
                </li>
            );
        }

        return null;
    };

    /**
     * Table submenus - main menu options dropdowns
     */

    getSubmenuColumnsDropdown = (menuOptions: Array<string>): JSX.Element | null => {
        const data: Array<JSX.Element> = [];
        let index = 0;

        for (const heading of this.props.headings) {
            const property = heading[tableConstants.TABLE_HEADING_NAME];

            if (
                propertyHelper.isPropertyValidForObjectType(
                    property,
                    this.props.objectType,
                    this.props.privileges,
                    this.props.systemOptionPriceDefinition,
                    this.props.systemOptionPriceEnableCompanyCost,
                    this.props.systemOptionPriceEnablePriceGroups,
                    this.props.zoneOptionPriceDefinition,
                    this.props.zoneOptionPriceEnableCompanyCost,
                    this.props.zoneOptionPriceEnablePriceGroups,
                    this.props.additionalData?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? null
                )
            ) {
                data.push(
                    <li className="dropdown-item" key={property}>
                        <span className="column-title ellipsis">
                            {heading[tableConstants.TABLE_HEADING_TRANSLATION]}
                        </span>
                        {this.getSubmenuColumnsOptionVisibility(heading, property, menuOptions)}
                        {this.getSubmenuColumnsOptionOrder(index, menuOptions)}
                    </li>
                );

                index++;
            }
        }

        if (this.state.submenuColumnsIsOpened) {
            return (
                <ul className="slim-scroll" onClick={(event) => this.preventParentClick(event)}>
                    {data}
                </ul>
            );
        }

        return null;
    };

    getSubmenuFilterSearchDropdown = (): JSX.Element | null => {
        if (this.state.submenuFilterSearchIsOpened) {
            return (
                <ul onClick={(event) => this.preventParentClick(event)}>
                    {this.getSubmenuFilterSearchRowOption()}
                    {this.getSubmenuFilterSearchGlobalOption()}
                </ul>
            );
        }

        return null;
    };

    getSubmenuRowCountDropdown = (): JSX.Element | null => {
        const data = [];

        for (const value of tableConstants.ROWS_IN_TABLE) {
            data.push(
                <li className="dropdown-item" key={value}>
                    <label className="radio-label">
                        <input
                            type="radio"
                            checked={value === this.props.rowCount && !this.props.rowCountCustom}
                            onChange={(): void => this.handleSubmenuRowCountChange(value, false)}
                        />
                        <span className="checkmark"></span>
                        {value}
                    </label>
                </li>
            );
        }

        data.push(
            <li className="dropdown-item" key={"custom-rowcount"}>
                <label className="radio-label">
                    <input
                        type="radio"
                        checked={this.props.rowCountCustom}
                        readOnly
                        onClick={(): void => this.handleSubmenuRowCountChange(this.getCustomRowCount(), true)}
                    />
                    <span className="checkmark"></span>
                    {translate("general.fitToHeight")}
                </label>
            </li>
        );

        if (this.state.submenuRowCountIsOpened) {
            return (
                <ul className="slim-scroll" onClick={(event) => this.preventParentClick(event)}>
                    {data}
                </ul>
            );
        }

        return null;
    };

    /**
     * Table main menu options
     */

    getMenuColumnsOption = (menuOptions: Array<string>): JSX.Element | null => {
        if (
            menuOptions.includes(optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY) ||
            menuOptions.includes(optionsConstants.OPTION_TABLE_COLUMNS_ORDER)
        ) {
            return (
                <li
                    className="dropdown-item"
                    onClick={(): void => this.handleSubmenuColumnsClick(!this.state.submenuColumnsIsOpened)}
                >
                    <span className="item-title">{translate("general.showHideColumns")}</span>
                    {this.getSubmenuColumnsDropdown(menuOptions)}
                </li>
            );
        }

        return null;
    };

    getMenuFilterSearchOption = (menuOptions: Array<string>): JSX.Element | null => {
        if (
            menuOptions.includes(optionsConstants.OPTION_SHOW_GLOBAL_SEARCH) ||
            menuOptions.includes(optionsConstants.OPTION_SHOW_FILTER_ROW)
        ) {
            return (
                <li
                    className="dropdown-item"
                    onClick={(): void => this.handleSubmenuFilterSearchClick(!this.state.submenuFilterSearchIsOpened)}
                >
                    <span className="item-title">{translate("general.filter")}</span>
                    {this.getSubmenuFilterSearchDropdown()}
                </li>
            );
        }

        return null;
    };

    getMenuRowCountOption = (menuOptions: Array<string>): JSX.Element | null => {
        if (menuOptions.includes(optionsConstants.OPTION_TABLE_ROW_COUNT)) {
            return (
                <li
                    className="dropdown-item"
                    onClick={(): void => this.handleSubmenuRowCountClick(!this.state.submenuRowCountIsOpened)}
                >
                    <span className="item-title">{translate("general.itemsOnPage")}</span>
                    {this.getSubmenuRowCountDropdown()}
                </li>
            );
        }

        return null;
    };

    /**
     * Table main menu
     */

    getMenuDropdown = (menuOptions: Array<string>): JSX.Element | null => {
        if (this.state.menuIsOpened) {
            return (
                <ul
                    id="main-table-dropdown"
                    className="table-dropdown-container"
                    onClick={(event) => this.handleCloseMenuDropdown(event)}
                >
                    <div
                        className="table-dropdown-content slim-scroll"
                        ref={this._dropdownRef}
                        onClick={(event) => this.preventParentClick(event)}
                    >
                        {this.getMenuColumnsOption(menuOptions)}
                        {this.getMenuRowCountOption(menuOptions)}
                        {this.getMenuFilterSearchOption(menuOptions)}
                    </div>
                </ul>
            );
        }

        return null;
    };

    getClassName = (): string => {
        const newClassName = "table-menu";
        if (
            privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges) &&
            this.props.objectType === objectTypes.ZONE
        ) {
            return newClassName + " margin-left-3";
        }

        if (!this.props.showGlobalSearch) {
            if (
                privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges) &&
                objectTypes.READONLY_TABLES_WITHOUT_MARGIN_LEFT_TABLE_MENU.includes(this.props.objectType)
            ) {
                return newClassName;
            }

            if (objectTypes.TABLES_WITHOUT_MARGIN_LEFT_TABLE_MENU.includes(this.props.objectType)) {
                return newClassName + " button-margin-0";
            }

            return newClassName + " margin-left";
        }

        return newClassName;
    };

    render(): JSX.Element | null {
        const menuOptions = this.getOptions();

        if (menuOptions.length && this.isVisible()) {
            return (
                <div className={this.getClassName()}>
                    <button
                        className="btn-md btn-info btn-option"
                        onClick={(): void => {
                            this.handleTableMenuClick(!this.state.menuIsOpened);
                        }}
                    >
                        <ReactSVG className="table-options-icon" src={imgTableSettings} />
                    </button>
                    {this.getMenuDropdown(menuOptions)}
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    privileges: Record<string, number>;
    sceneLayout: string | null;
    sceneTableSize: Record<string, any> | null;
    systemOptionPriceDefinition: string | null;
    systemOptionPriceEnableCompanyCost: boolean;
    systemOptionPriceEnablePriceGroups: boolean;
    zoneOptionPriceDefinition: string | null;
    zoneOptionPriceEnableCompanyCost: boolean;
    zoneOptionPriceEnablePriceGroups: boolean;
}>;

export type DispatchType = Readonly<{
    setOptions(
        objectType: objectTypes.ObjectType,
        options: Array<{ key: string; object?: string; value?: any }>,
        save: boolean
    ): any;
    setColumnOrder(tableConstant: string, columns: Array<string>): any;
    setColumnVisibility(tableConstant: string, columns: Record<string, boolean>): any;
    setShowFilterRow(tableConstant: string, showFilterRow: boolean): any;
    setShowGlobalSearch(tableConstant: string, showGlobalSearch: boolean): any;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    privileges: state.login.privileges,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        routingHelper.getPageByScene(state.navigation.activeScene)
    ),
    sceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        routingHelper.getPageByScene(state.navigation.activeScene)
    ),
    systemOptionPriceDefinition:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_PRICE_DEFINITION)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? null,
    systemOptionPriceEnableCompanyCost:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    systemOptionPriceEnablePriceGroups:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    zoneOptionPriceDefinition:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_PRICE_DEFINITION)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? null,
    zoneOptionPriceEnableCompanyCost:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    zoneOptionPriceEnablePriceGroups:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setOptions: (
        objectType: objectTypes.ObjectType,
        options: Array<{ key: string; object?: string; value?: any }>,
        save: boolean
    ): any => dispatch(optionActions.setDictionaryOptions(objectType, options, save)),
    setColumnOrder: (tableConstant: string, columns: Array<string>): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_TABLE_COLUMNS_ORDER, tableConstant, columns)
        ),
    setColumnVisibility: (tableConstant: string, columns: Record<string, boolean>): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY, tableConstant, columns)
        ),
    setShowFilterRow: (tableConstant: string, showFilterRow: boolean): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_SHOW_FILTER_ROW, tableConstant, showFilterRow)
        ),
    setShowGlobalSearch: (tableConstant: string, showGlobalSearch: boolean): any =>
        dispatch(
            optionActions.setDictionaryOption(
                optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
                tableConstant,
                showGlobalSearch
            )
        )
});

export const TableOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(TableOptions);
