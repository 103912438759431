import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Zone, updateZone } from "../types/zone";

import { WebZone } from "../types/web/webZone";

export type ZoneState = Readonly<{
    allList: Array<Zone>;
    list: Array<Zone>;
    activeList: Array<Zone>;
    active: Zone | null;
    duplicateAllList: Array<Zone>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
    webAllList: Array<WebZone>;
    webList: Array<WebZone>;
    webActiveList: Array<WebZone>;
    webSearch: string | null;
    webSearchParams: Record<propertyConstants.Property, string>;
}>;

const initialState: ZoneState = {
    allList: [],
    list: [],
    activeList: [],
    active: null,
    duplicateAllList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE,
    webAllList: [],
    webList: [],
    webActiveList: [],
    webSearch: null,
    webSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS
};

export default function zones(state: ZoneState = initialState, action: Record<string, any>): ZoneState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.DATABASE:
                case objectTypes.SYSTEM: {
                    if (!action.payload.list.length) {
                        return {
                            ...state,
                            allList: initialState.allList,
                            active: initialState.active
                        };
                    }

                    return state;
                }
                case objectTypes.WEB_ZONE: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        webAllList: action.payload.list,
                        webActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE: {
                    const newActive = generalHelper.updateActiveLists(
                        state.active ? [state.active] : [],
                        action.payload.list
                    );

                    return {
                        ...state,
                        allList: action.payload.list,
                        active: newActive.length ? newActive[0] : null
                    };
                }
                case objectTypes.ZONE_DUPLICATE: {
                    return {
                        ...state,
                        duplicateAllList: action.payload.list
                    };
                }
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.WEB_ZONE: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        webList: action.payload.list,
                        webActiveList: newActiveList,
                        count: action.payload.totalCount
                    };
                }
                case objectTypes.ZONE: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        list: action.payload.list,
                        activeList: newActiveList,
                        count: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM: {
                    if (!action.payload.list.length) {
                        return {
                            ...state,
                            list: initialState.list,
                            activeList: initialState.activeList,
                            count: initialState.count,
                            offset: initialState.offset,
                            page: initialState.page
                        };
                    }

                    return state;
                }
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM: {
                    return {
                        ...state,
                        list: initialState.list,
                        activeList: initialState.activeList,
                        count: initialState.count,
                        offset: initialState.offset,
                        page: initialState.page
                    };
                }
                case objectTypes.WEB_ZONE: {
                    return {
                        ...state,
                        webActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE: {
                    return {
                        ...state,
                        activeList: action.payload.items
                    };
                }
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.ZONE) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.WEB_ZONE) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.WEB_SEARCH,
                    tableConstants.WEB_SEARCH_PARAMS
                );
            }

            if (action.payload.objectType === objectTypes.ZONE) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.ZONE: {
                        const updatedZoneList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_ZONE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateZone
                        );

                        if (updatedZoneList.length) {
                            return {
                                ...state,
                                list: updatedZoneList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.ZONE: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                allList: state.allList,
                active: state.active
            };
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload.type === modalTypes.MODAL_DATA_EXPORT ||
                action.payload.type === modalTypes.MODAL_DATA_EXPORT_ALL ||
                action.payload.type === modalTypes.MODAL_ZONES_EDIT ||
                action.payload.type === modalTypes.MODAL_ZONES_ADD
            ) {
                return {
                    ...state,
                    webAllList: initialState.webAllList,
                    webActiveList: initialState.webActiveList,
                    webSearch: initialState.webSearch,
                    webSearchParams: initialState.webSearchParams
                };
            }
            if (action.payload.type === modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE) {
                return {
                    ...state,
                    webActiveList: initialState.webActiveList,
                    webSearch: initialState.webSearch,
                    webSearchParams: initialState.webSearchParams
                };
            }
            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES:
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            return {
                ...state,
                allList: action.payload.zoneList,
                active: action.payload.active
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
