import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import {
    ImageCategory,
    createEmptyImageCategory,
    generateImageCategoryData,
    generateImageCategoryHeadings
} from "../../../types/imageCategory";
import React, { Component } from "react";
import { ModalProperties } from "../../../types/modalProperties";
import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    modalId?: number;
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<ImageCategory>;
    activeList: Array<ImageCategory>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    totalCount: number | null;
};

export class ImageCategoryTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const tableVisibleColumns = optionHelper.handleTableHeadingVisibility(
            [propertyConstants.PROPERTY_NAME],
            this.props.columnVisibility
        );

        return generateImageCategoryHeadings(
            this.props.objectType,
            tableVisibleColumns,
            tableVisibleColumns,
            [],
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateAllTableHeadings = (): Array<TableHeading> => {
        return generateImageCategoryHeadings(
            this.props.objectType,
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            [],
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateAddModalParams = (): any => {
        return {
            title: translate("image.addImageCategory"),
            dataPrivileges: this.props.dataPrivileges,
            objectType: objectTypes.IMAGE_CATEGORY, // TODO proc je tu specifikovany objectType? neni z this.props.objectType?
            type: modalTypes.ADD_MODAL,
            data: createEmptyImageCategory(),
            headings: generateImageCategoryHeadings(
                objectTypes.IMAGE_CATEGORY,
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                tableConstants.EMPTY_COLUMN_WIDTH
            )
        };
    };

    generateEditModalParams = (): any => {
        const { activeList, objectType } = this.props;

        const activeData = generateImageCategoryData(activeList);

        if (activeData.length) {
            return {
                title: translate("image.editImageCategory"),
                type: modalTypes.EDIT_MODAL,
                objectType: objectType,
                data: activeData[0],
                headings: generateImageCategoryHeadings(
                    objectType,
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    tableConstants.EMPTY_COLUMN_WIDTH
                )
            };
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_IMAGE_CATEGORIES_ADD,
            addModalParams: this.generateAddModalParams(),
            editModalType: modalTypes.MODAL_IMAGE_CATEGORIES_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    modalId={this.props.modalId}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings()}
                    data={generateImageCategoryData(this.props.allList)}
                    activeData={this.props.activeList}
                    disableColumnResizing={true}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={false}
                    showGlobalSearch={true}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
