import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { TableHeading } from "../tableHeading";

export class ContactPerson {
    personName: string;
    phone: string;
    email: string;

    constructor(personName: string, phone: string, email: string) {
        this.personName = personName;
        this.phone = phone;
        this.email = email;
    }
}

export function mapContactPerson(data: Record<string, any>): ContactPerson | null {
    try {
        return new ContactPerson(data.name, data.phone, data.email);
    } catch (e) {
        return null;
    }
}

export function updateContactPerson(data: Record<string, any>): ContactPerson | null {
    try {
        return new ContactPerson(data.personName, data.phone, data.email);
    } catch (e) {
        return null;
    }
}

export function createEmptyContactPerson(): any {
    return {
        [propertyConstants.PROPERTY_PERSON_NAME]: "",
        [propertyConstants.PROPERTY_PHONE]: "",
        [propertyConstants.PROPERTY_EMAIL]: ""
    };
}

export function generateContactPersonData(contactPerson: ContactPerson | null): Record<string, any> | null {
    if (contactPerson === null) {
        return createEmptyContactPerson();
    }

    return {
        [propertyConstants.PROPERTY_PERSON_NAME]: contactPerson.personName,
        [propertyConstants.PROPERTY_PHONE]: contactPerson.phone,
        [propertyConstants.PROPERTY_EMAIL]: contactPerson.email
    };
}

export function generateContactPersonHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CONTACT_PERSON,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CONTACT_PERSON),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_CONTACT_PERSON),
            editableList.includes(propertyConstants.PROPERTY_CONTACT_PERSON),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CONTACT_PERSON),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CONTACT_PERSON),
            requiredList.includes(propertyConstants.PROPERTY_CONTACT_PERSON),
            [],
            widthOption?.[propertyConstants.PROPERTY_CONTACT_PERSON] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_EMAIL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_EMAIL),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_EMAIL),
            editableList.includes(propertyConstants.PROPERTY_EMAIL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_EMAIL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_EMAIL),
            requiredList.includes(propertyConstants.PROPERTY_EMAIL),
            [],
            widthOption?.[propertyConstants.PROPERTY_EMAIL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PERSON_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PERSON_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PERSON_NAME),
            editableList.includes(propertyConstants.PROPERTY_PERSON_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PERSON_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PERSON_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PERSON_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PERSON_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PHONE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PHONE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PHONE),
            editableList.includes(propertyConstants.PROPERTY_PHONE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PHONE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PHONE),
            requiredList.includes(propertyConstants.PROPERTY_PHONE),
            [],
            widthOption?.[propertyConstants.PROPERTY_PHONE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
