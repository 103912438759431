import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class SystemZone {
    id: number;
    systemId: number | null;
    systemName: string | null;
    zoneId: number;
    zoneName: string;
    uuid: string;
    version: string;
    versionName: string;
    companyKey: string;
    zonePassword: string;
    changelog: string | null;
    priority: number;
    isChecked: boolean;
    colorsWithoutFormulas: boolean;

    constructor(
        id: number,
        systemId: number | null,
        systemName: string | null,
        zoneId: number,
        zoneName: string,
        uuid: string,
        version: string,
        versionName: string,
        companyKey: string,
        zonePassword: string,
        changelog: string,
        priority: number,
        isChecked: boolean,
        colorsWithoutFormulas: boolean
    ) {
        this.id = id;
        this.systemId = systemId;
        this.systemName = systemName;
        this.zoneId = zoneId;
        this.zoneName = zoneName;
        this.uuid = uuid;
        this.version = version;
        this.versionName = versionName;
        this.companyKey = companyKey;
        this.zonePassword = zonePassword;
        this.changelog = changelog;
        this.priority = priority;
        this.isChecked = isChecked;
        this.colorsWithoutFormulas = colorsWithoutFormulas;
    }
}

export function mapSystemZone(data: Record<string, any>): SystemZone | null {
    try {
        return new SystemZone(
            data.id,
            data.system ? data.system.id : null,
            data.system ? data.system.name : null,
            data.id,
            data.name,
            data.uuid,
            data.version ? data.version.version : "",
            data.version ? data.version.versionName : "",
            "",
            data.password,
            data.version ? data.version.changelog : "",
            0,
            false,
            importExportConstants.DEFAULT_COLORS_WITHOUT_FORMULAS
        );
    } catch (e) {
        return null;
    }
}

export function mapSystemZones(data: Array<any>): Array<SystemZone> {
    const systemZones = [];

    for (const item of data) {
        const systemZone = mapSystemZone(item);

        if (systemZone !== null) {
            systemZones.push(systemZone);
        }
    }

    return systemZones;
}

export function updateSystemZoneIsChecked(data: Record<string, any>, isChecked: boolean): SystemZone | null {
    try {
        return new SystemZone(
            data.id,
            data.systemId,
            data.systemName,
            data.zoneId,
            data.zoneName,
            data.uuid,
            data.version,
            data.versionName,
            data.companyKey,
            data.zonePassword,
            data.changelog,
            data.priority,
            isChecked,
            data.colorsWithoutFormulas
        );
    } catch (e) {
        return null;
    }
}

export function cloneSystemZone(data: Record<string, any>): SystemZone | null {
    try {
        return new SystemZone(
            data.id,
            data.systemId,
            data.systemName,
            data.zoneId,
            data.zoneName,
            data.uuid,
            data.version,
            data.versionName,
            data.companyKey,
            data.zonePassword,
            data.changelog,
            data.priority,
            data.isChecked,
            data.colorsWithoutFormulas
        );
    } catch (e) {
        return null;
    }
}

export function generateSystemZoneData(systemZoneList: Array<SystemZone>): Array<any> {
    const data: Array<any> = [];
    let systemZone: SystemZone;

    for (systemZone of systemZoneList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: systemZone.id,
            [propertyConstants.PROPERTY_SYSTEM_ID]: systemZone.systemId,
            [propertyConstants.PROPERTY_SYSTEM_NAME]: systemZone.systemName,
            [propertyConstants.PROPERTY_ZONE_ID]: systemZone.zoneId,
            [propertyConstants.PROPERTY_ZONE_NAME]: systemZone.zoneName,
            [propertyConstants.PROPERTY_UUID]: systemZone.uuid,
            [propertyConstants.PROPERTY_VERSION]: systemZone.version,
            [propertyConstants.PROPERTY_VERSION_NAME]: systemZone.versionName,
            [propertyConstants.PROPERTY_COMPANY_ID]: systemZone.companyKey,
            [propertyConstants.PROPERTY_ZONE_PASSWORD]: systemZone.zonePassword,
            [propertyConstants.PROPERTY_CHANGELOG]: systemZone.changelog,
            [propertyConstants.PROPERTY_PRIORITY]: systemZone.priority,
            [propertyConstants.PROPERTY_IS_CHECKED]: systemZone.isChecked,
            [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]: systemZone.colorsWithoutFormulas
        });
    }

    return data;
}

export function generateSystemZoneHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CHANGELOG,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            editableList.includes(propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CHANGELOG),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            requiredList.includes(propertyConstants.PROPERTY_CHANGELOG),
            [],
            widthOption?.[propertyConstants.PROPERTY_CHANGELOG] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            editableList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            requiredList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_CHECKED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            editableList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_CHECKED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            requiredList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_CHECKED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION),
            editableList.includes(propertyConstants.PROPERTY_VERSION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION),
            requiredList.includes(propertyConstants.PROPERTY_VERSION),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            editableList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            requiredList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            editableList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_PASSWORD] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
