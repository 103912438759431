import * as keyCodes from "../../../constants/keyCodes";
import * as objectTypes from "../../../constants/objectTypes";
import * as tableConstants from "../../../constants/tableConstants";
import * as tableHelper from "../../../helpers/tableHelper";
import * as universalObjectActions from "../../../actions/universalObjectActions";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    offset: number;
    page: number;
    rowCount: number;
    totalCount: number | null;
};

type Props = OwnProps & ContainerProps;

type State = {
    paginationInput: string;
    pageCount: number;
};

export class TablePagination extends Component<Props, State> {
    state: State = {
        paginationInput: "",
        pageCount: 0
    };

    componentDidMount(): void {
        const { totalCount, rowCount } = this.props;
        let stateUpdate = { ...this.state, paginationInput: this.props.page.toString() };

        if (totalCount !== null && rowCount !== 0) {
            stateUpdate = { ...stateUpdate, pageCount: tableHelper.getNewTablePage(totalCount, rowCount) };
        }

        this.setState(stateUpdate);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.props.totalCount !== prevProps.totalCount || this.props.rowCount !== prevProps.rowCount) {
            if (this.props.totalCount !== null && this.props.rowCount !== 0) {
                this.setState({
                    pageCount: tableHelper.getNewTablePage(this.props.totalCount, this.props.rowCount)
                });
            } else {
                this.setState({
                    pageCount: 0
                });
            }

            if (
                prevProps.totalCount !== null &&
                this.props.rowCount !== prevProps.rowCount &&
                !objectTypes.WITHOUT_OFFSET_OBJECT_TYPES.includes(this.props.objectType) &&
                (prevProps.offset !== tableConstants.DEFAULT_OFFSET ||
                    prevProps.page !== tableConstants.DEFAULT_PAGE ||
                    this.props.rowCount !== prevProps.rowCount)
            ) {
                this.props.setOffset(this.props.objectType, tableConstants.DEFAULT_OFFSET, tableConstants.DEFAULT_PAGE);
            }
        }

        if (this.props.page !== prevProps.page) {
            this.setState({
                paginationInput: this.props.page.toString()
            });
        }
    }

    // TODO: specify event type & fix handleGoToPageInputBlur & blur is called twice
    handleInputKeyDown = (event: any): void => {
        if (event.key === keyCodes.ENTER) {
            this.handleGoToPageInputBlur(event);
            event.currentTarget.blur();
        }
    };

    handleGoToPageFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleGoToPageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (validationHelper.isNumberValueValid(event.target.value)) {
            this.setState({
                paginationInput: event.target.value
            });
        }

        event.currentTarget.style.width = event.target.value.length * 8 + "px";
    };

    handleGoToPageInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { objectType, rowCount, setOffset } = this.props;
        const newPage = Number(event.target.value) || null;

        if (newPage === null || newPage > this.state.pageCount) {
            this.setState({ paginationInput: tableConstants.DEFAULT_PAGE.toString() });
            setOffset(objectType, tableConstants.DEFAULT_OFFSET, tableConstants.DEFAULT_PAGE);
            event.currentTarget.style.width = "20px";
        } else {
            const newOffset = tableHelper.getNewTableOffset(newPage, rowCount);

            if (this.props.page !== newPage && newOffset >= 0) {
                this.setState({ paginationInput: newPage.toString() });
                setOffset(objectType, newOffset, newPage);
            }
        }
    };

    handleLeftArrowClick = (): void => {
        const { page } = this.props;
        const newOffset = this.props.offset - this.props.rowCount;

        if (page > 1 && newOffset >= 0) {
            this.props.setOffset(this.props.objectType, newOffset, page - 1);
        }
    };

    handleRightArrowClick = (): void => {
        const { page } = this.props;
        const newOffset = this.props.offset + this.props.rowCount;

        if (page < this.state.pageCount && newOffset >= 0) {
            this.props.setOffset(this.props.objectType, newOffset, page + 1);
        }
    };

    getPaginationClassName = (): string => {
        let className = "pagination-buttons";

        if (this.state.pageCount <= 1) {
            className += " hidden";
        }

        return className;
    };

    getLeftArrowClassname = (): string => {
        let className = "input-mini-button caret-left btn-info";

        if (this.props.page === 1) {
            className += " disabled";
        }

        return className;
    };

    getRightArrowClassname = (): string => {
        let className = "input-mini-button caret-right btn-info";

        if (this.props.page >= this.state.pageCount) {
            className += " disabled";
        }

        return className;
    };

    getPaginationContent = (): JSX.Element | null => {
        const { totalCount } = this.props;

        if (totalCount === null) {
            return null;
        }

        return (
            <div className="pagination-content">
                <div className="pagination-info">
                    <span className="section-color">{translate("general.itemsCount")}:</span> {totalCount}
                </div>
                <div className={this.getPaginationClassName()}>
                    <div className="input-buttons">
                        <button
                            className={this.getLeftArrowClassname()}
                            onClick={(): void => {
                                this.handleLeftArrowClick();
                            }}
                        >
                            <span className="caret"></span>
                        </button>
                        <input
                            className="pagination-input"
                            type="number"
                            value={this.state.paginationInput}
                            onChange={(event): any => this.handleGoToPageInputChange(event)}
                            onBlur={(event): any => this.handleGoToPageInputBlur(event)}
                            onFocus={(event): any => this.handleGoToPageFocus(event)}
                            onKeyDown={(event): any => this.handleInputKeyDown(event)}
                        />
                        <span>{`/ ${this.state.pageCount}`}</span>
                        <button
                            className={this.getRightArrowClassname()}
                            onClick={(): void => {
                                this.handleRightArrowClick();
                            }}
                        >
                            <span className="caret"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    render(): JSX.Element {
        return (
            <div className="pagination">
                <div className="pagination-row">{this.getPaginationContent()}</div>
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    setOffset(objectType: objectTypes.ObjectType, offset: number, page: number): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setOffset: (objectType: objectTypes.ObjectType, offset: number, page: number): any =>
        dispatch(universalObjectActions.setOffset(objectType, offset, page))
});

export const TablePaginationContainer = connect(mapStateToProps, mapDispatchToProps)(TablePagination);
