import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";

import { Epic, ofType } from "redux-observable";

import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const setPosition: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.SET_POSITION),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                case objectTypes.MASTER_FORMULA: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_SET_MASTER_FORMULA_POSITION,
                            methods.METHOD_SET_MASTER_FORMULA_POSITION,
                            payload.params
                        )
                    );
                    break;
                }
                default: {
                    requests.push(notificationActions.saveClientFlashMessage());
                }
            }

            return requests;
        })
    );
