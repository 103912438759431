import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Privilege, updateRedlikePrivilege } from "../types/privilege";

export type PrivilegesState = Readonly<{
    allList: Array<Privilege>;
    list: Array<Privilege>;
    activeList: Array<Privilege>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    offset: number;
    page: number;
    changedOnly: boolean;
}>;

const initialState: PrivilegesState = {
    allList: [],
    list: [],
    activeList: [],
    count: tableConstants.DEFAULT_TOTAL_COUNT,
    search: tableConstants.DEFAULT_SEARCH,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    sortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    sortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE,
    changedOnly: tableConstants.DEFAULT_CHANGED_ONLY
};

export default function privileges(
    state: PrivilegesState = initialState,
    action: Record<string, any>
): PrivilegesState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return {
                        ...state,
                        allList: action.payload.list
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        list: action.payload.list,
                        activeList: newActiveList,
                        count: action.payload.totalCount
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return {
                        ...state,
                        activeList: action.payload.items
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return {
                        ...state,
                        offset: action.payload.offset,
                        page: action.payload.page
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SEARCH,
                        tableConstants.SEARCH_PARAMS
                    );
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return {
                        ...state,
                        sortingAsc: action.payload.asc,
                        sortingCriterion: action.payload.criterion || tableConstants.DEFAULT_SORTING_COLUMN
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SHOW_SPECIFIC_VALUES: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    return {
                        ...state,
                        changedOnly: action.payload.showSpecificValues
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                        const updatedPrivilegeList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_KEY],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateRedlikePrivilege
                        );

                        if (updatedPrivilegeList.length) {
                            return {
                                ...state,
                                list: updatedPrivilegeList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
