export type ObjectType =
    | typeof BARCODE
    | typeof CHANGELOG
    | typeof COMPANY
    | typeof CURRENCY
    | typeof DATA_ERROR
    | typeof DATA_EXPORT
    | typeof DATA_SIZE
    | typeof DATA_VACUUMING
    | typeof DATABASE
    | typeof DATABASE_MONITORING
    | typeof DATABASE_SYSTEM_ZONE
    | typeof DEFAULT_MASTER_REDLIKE_USER_GROUP
    | typeof EXPORT_STATUS
    | typeof EXPORT_TASK
    | typeof EXPORT_TASK_DELETE
    | typeof EXPORT_TYPE
    | typeof FILE
    | typeof FILE_CATEGORY
    | typeof FORMULA_COLORANT_UNIT
    | typeof GENERIC_IMAGE
    | typeof GLOBAL_OPTION
    | typeof HEADER_SETTINGS
    | typeof HELP
    | typeof ICON
    | typeof IMAGE
    | typeof IMAGE_CATEGORY
    | typeof IMAGE_DIRECTORY
    | typeof IMPORT_STATUS
    | typeof IMPORT_TASK
    | typeof IMPORT_TASK_DELETE
    | typeof IMPORT_TYPE
    | typeof LICENSE
    | typeof LICENSE_PRODUCT
    | typeof LOCKED_DATABASE
    | typeof MASTER_BASE
    | typeof MASTER_BASE_IN_PRODUCT
    | typeof MASTER_COLORANT
    | typeof MASTER_COLORANT_BATCH
    | typeof MASTER_COLOR
    | typeof MASTER_COLOR_DATA
    | typeof MASTER_COLOR_MODAL
    | typeof MASTER_COLOR_SPECTRO_TYPE
    | typeof MASTER_COLOR_WITH_FORMULA_INFO
    | typeof MASTER_DEFAULT_REDLIKE_USER_GROUP
    | typeof MASTER_FANDECK
    | typeof MASTER_FANDECK_IN_COLOR
    | typeof MASTER_FORMULA
    | typeof MASTER_FORMULA_COLORANT
    | typeof MASTER_FORMULA_MODAL
    | typeof MASTER_FORMULA_NOTE
    | typeof MASTER_PRODUCT
    | typeof MASTER_PRODUCT_DUPLICATE
    | typeof MASTER_PRODUCT_IN_COLOR
    | typeof MASTER_REDLIKE_OPTION
    | typeof MASTER_REDLIKE_PRIVILEGE
    | typeof MASTER_REDLIKE_USER
    | typeof MASTER_REDLIKE_USER_GROUP
    | typeof MASTER_REDLIKE_USER_GROUP_DUPLICATE
    | typeof MASTER_REDLIKE_USER_PRIVILEGES
    | typeof MASTER_REDLIKE_USER_WITH_USER_GROUPS
    | typeof MASTER_SYSTEM_BASE
    | typeof MASTER_SYSTEM_BASE_IN_PRODUCT
    | typeof MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE
    | typeof MASTER_SYSTEM_COLORANT
    | typeof MASTER_SYSTEM_COLORANT_PACKAGE
    | typeof MASTER_SYSTEM_FANDECK
    | typeof MASTER_SYSTEM_PRODUCT
    | typeof MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS
    | typeof MASTER_SYSTEM_PRODUCT_GROUP
    | typeof MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS
    | typeof MASTER_SYSTEM_REDLIKE_USER_GROUP
    | typeof MESSAGE
    | typeof ONE_TIME_ACCESS_PASSWORD
    | typeof PACKAGE
    | typeof PRODUCT_FILE
    | typeof PRODUCT_SHEET
    | typeof PRODUCT_SHEET_PREVIEW
    | typeof REDLIKE_USER_GROUP
    | typeof SERVER_SCRIPTS
    | typeof SPECTRO_TYPE
    | typeof STATIC_SQL
    | typeof STATIC_SQL_PREVIEW
    | typeof STATIC_SQL_RESULT
    | typeof SYSTEM
    | typeof SYSTEM_BASE
    | typeof SYSTEM_BASE_IN_PRODUCT
    | typeof SYSTEM_BASE_IN_PRODUCT_PACKAGE
    | typeof SYSTEM_COLOR
    | typeof SYSTEM_COLOR_DATA
    | typeof SYSTEM_COLOR_WITH_FORMULA_INFO
    | typeof SYSTEM_COLORANT
    | typeof SYSTEM_COLORANT_PACKAGE
    | typeof SYSTEM_CURRENCY
    | typeof SYSTEM_DUPLICATE
    | typeof SYSTEM_FANDECK
    | typeof SYSTEM_FANDECK_IN_COLOR
    | typeof SYSTEM_FORMULA
    | typeof SYSTEM_FORMULA_COLORANT
    | typeof SYSTEM_FORMULA_NOTE
    | typeof SYSTEM_MARGIN_TYPE
    | typeof SYSTEM_MASTER_BASE
    | typeof SYSTEM_MASTER_BASE_IN_PRODUCT
    | typeof SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE
    | typeof SYSTEM_MASTER_COLORANT
    | typeof SYSTEM_MASTER_COLORANT_PACKAGE
    | typeof SYSTEM_MASTER_FANDECK
    | typeof SYSTEM_MASTER_PRODUCT
    | typeof SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS
    | typeof SYSTEM_MASTER_PRODUCT_GROUP
    | typeof SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS
    | typeof SYSTEM_MASTER_REDLIKE_USER_GROUP
    | typeof SYSTEM_PACKAGE
    | typeof SYSTEM_PRICE_GENERIC
    | typeof SYSTEM_PRICE_GROUP
    | typeof SYSTEM_PRICE_MARGIN
    | typeof SYSTEM_PRICE_OPTION
    | typeof SYSTEM_PRODUCT
    | typeof SYSTEM_PRODUCT_DUPLICATE
    | typeof SYSTEM_PRODUCT_FOR_GROUP
    | typeof SYSTEM_PRODUCT_GROUP
    | typeof SYSTEM_PRODUCT_IN_COLOR
    | typeof SYSTEM_REDLIKE_OPTION
    | typeof SYSTEM_REDLIKE_USER
    | typeof SYSTEM_REDLIKE_USER_WITH_USER_GROUPS
    | typeof SYSTEM_ZONE
    | typeof SYSTEM_ZONE_BASE
    | typeof SYSTEM_ZONE_BASE_IN_PRODUCT
    | typeof SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE
    | typeof SYSTEM_ZONE_COLORANT
    | typeof SYSTEM_ZONE_COLORANT_PACKAGE
    | typeof SYSTEM_ZONE_FANDECK
    | typeof SYSTEM_ZONE_PRODUCT
    | typeof SYSTEM_ZONE_PRODUCT_GROUP
    | typeof SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS
    | typeof SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS
    | typeof SYSTEM_ZONE_REDLIKE_USER_GROUP
    | typeof TRANSLATION
    | typeof UNIT
    | typeof USER
    | typeof USER_ACTION_HISTORY
    | typeof USER_GROUP
    | typeof USER_GROUP_PRIVILEGE
    | typeof USER_OPTION
    | typeof USER_PASSWORD
    | typeof VERSION
    | typeof WAVELENGTH
    | typeof WEB_ZONE
    | typeof ZONE
    | typeof ZONE_BASE
    | typeof ZONE_BASE_IN_PRODUCT
    | typeof ZONE_BASE_IN_PRODUCT_PACKAGE
    | typeof ZONE_COLOR
    | typeof ZONE_COLOR_DATA
    | typeof ZONE_COLOR_WITH_FORMULA_INFO
    | typeof ZONE_COLORANT
    | typeof ZONE_COLORANT_PACKAGE
    | typeof ZONE_CURRENCY
    | typeof ZONE_DUPLICATE
    | typeof ZONE_FANDECK
    | typeof ZONE_FANDECK_IN_COLOR
    | typeof ZONE_FORMULA
    | typeof ZONE_FORMULA_COLORANT
    | typeof ZONE_FORMULA_NOTE
    | typeof ZONE_MARGIN_TYPE
    | typeof ZONE_PACKAGE
    | typeof ZONE_PRICE_GENERIC
    | typeof ZONE_PRICE_GROUP
    | typeof ZONE_PRICE_MARGIN
    | typeof ZONE_PRICE_OPTION
    | typeof ZONE_PRODUCT
    | typeof ZONE_PRODUCT_DUPLICATE
    | typeof ZONE_PRODUCT_GROUP
    | typeof ZONE_PRODUCT_IN_COLOR
    | typeof ZONE_REDLIKE_OPTION
    | typeof ZONE_REDLIKE_USER
    | typeof ZONE_REDLIKE_USER_WITH_USER_GROUPS
    | typeof ZONE_SYSTEM_BASE
    | typeof ZONE_SYSTEM_BASE_IN_PRODUCT
    | typeof ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE
    | typeof ZONE_SYSTEM_COLORANT
    | typeof ZONE_SYSTEM_COLORANT_PACKAGE
    | typeof ZONE_SYSTEM_FANDECK
    | typeof ZONE_SYSTEM_PRODUCT
    | typeof ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS
    | typeof ZONE_SYSTEM_PRODUCT_GROUP
    | typeof ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS
    | typeof ZONE_SYSTEM_REDLIKE_USER_GROUP;

export const BARCODE = "barcode";
export const CHANGELOG = "changelog";
export const COMPANY = "company";
export const CURRENCY = "currency";
export const DATA_ERROR = "data_error";
export const DATA_EXPORT = "data_export";
export const DATA_SIZE = "data_size";
export const DATA_VACUUMING = "data_vacuuming";
export const DATABASE = "database";
export const DATABASE_MONITORING = "database_monitoring";
export const DATABASE_SYSTEM_ZONE = "database_system_zone";
export const DEFAULT_MASTER_REDLIKE_USER_GROUP = "default_master_redlike_user_group";
export const EXPORT_STATUS = "export_status";
export const EXPORT_TASK = "export_task";
export const EXPORT_TASK_DELETE = "export_task_delete";
export const EXPORT_TYPE = "export_type";
export const FILE = "file";
export const FILE_CATEGORY = "file_category";
export const FORMULA_COLORANT_UNIT = "formula_colorant_unit";
export const GENERIC_IMAGE = "generic_image";
export const GLOBAL_OPTION = "global_option";
export const HEADER_SETTINGS = "header_settings";
export const HELP = "help";
export const ICON = "icon";
export const IMAGE = "image";
export const IMAGE_CATEGORY = "image_category";
export const IMAGE_DIRECTORY = "image_directory";
export const IMPORT_STATUS = "import_status";
export const IMPORT_TASK = "import_task";
export const IMPORT_TASK_DELETE = "import_task_delete";
export const IMPORT_TYPE = "import_type";
export const LICENSE = "license";
export const LICENSE_PRODUCT = "license_product";
export const LOCKED_DATABASE = "locked_database";
export const MASTER_BASE = "master_base";
export const MASTER_BASE_IN_PRODUCT = "master_base_in_product";
export const MASTER_COLORANT = "master_colorant";
export const MASTER_COLORANT_BATCH = "master_colorant_batch";
export const MASTER_COLOR = "master_color";
export const MASTER_COLOR_DATA = "master_color_data";
export const MASTER_COLOR_MODAL = "master_color_modal";
export const MASTER_COLOR_SPECTRO_TYPE = "master_color_spectro_type";
export const MASTER_COLOR_WITH_FORMULA_INFO = "master_color_with_formula_info";
export const MASTER_DEFAULT_REDLIKE_USER_GROUP = "master_default_redlike_user_group";
export const MASTER_FANDECK = "master_fandeck";
export const MASTER_FANDECK_IN_COLOR = "master_fandeck_in_color";
export const MASTER_FORMULA = "master_formula";
export const MASTER_FORMULA_COLORANT = "master_formula_colorant";
export const MASTER_FORMULA_MODAL = "master_formula_modal";
export const MASTER_FORMULA_NOTE = "master_formula_note";
export const MASTER_PRODUCT = "master_product";
export const MASTER_PRODUCT_DUPLICATE = "master_product_duplicate";
export const MASTER_PRODUCT_IN_COLOR = "master_product_in_color";
export const MASTER_REDLIKE_OPTION = "master_redlike_option";
export const MASTER_REDLIKE_PRIVILEGE = "master_redlike_privilege";
export const MASTER_REDLIKE_USER = "master_redlike_user";
export const MASTER_REDLIKE_USER_GROUP = "master_redlike_user_group";
export const MASTER_REDLIKE_USER_GROUP_DUPLICATE = "master_redlike_user_group_duplicate";
export const MASTER_REDLIKE_USER_PRIVILEGES = "master_redlike_user_privileges";
export const MASTER_REDLIKE_USER_WITH_USER_GROUPS = "master_redlike_user_with_user_groups";
export const MASTER_SYSTEM_BASE = "master_system_base";
export const MASTER_SYSTEM_BASE_IN_PRODUCT = "master_system_base_in_product";
export const MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE = "master_system_base_in_product_package";
export const MASTER_SYSTEM_COLORANT = "master_system_colorant";
export const MASTER_SYSTEM_COLORANT_PACKAGE = "master_system_colorant_package";
export const MASTER_SYSTEM_FANDECK = "master_system_fandeck";
export const MASTER_SYSTEM_PRODUCT = "master_system_product";
export const MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS = "master_system_product_with_product_groups";
export const MASTER_SYSTEM_PRODUCT_GROUP = "master_system_product_group";
export const MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS = "master_system_product_group_with_products";
export const MASTER_SYSTEM_REDLIKE_USER_GROUP = "master_system_redlike_user_group";
export const MESSAGE = "message";
export const ONE_TIME_ACCESS_PASSWORD = "one_time_access_password";
export const PACKAGE = "package";
export const PRODUCT_FILE = "product_file";
export const PRODUCT_SHEET = "product_sheet";
export const PRODUCT_SHEET_PREVIEW = "product_sheet_preview";
export const REDLIKE_USER_GROUP = "redlike_user_group";
export const SERVER_SCRIPTS = "server_scripts";
export const SPECTRO_TYPE = "spectro_type";
export const STATIC_SQL = "static_sql";
export const STATIC_SQL_PREVIEW = "static_sql_preview";
export const STATIC_SQL_RESULT = "static_sql_result";
export const SYSTEM = "system";
export const SYSTEM_BASE = "system_base";
export const SYSTEM_BASE_IN_PRODUCT = "system_base_in_product";
export const SYSTEM_BASE_IN_PRODUCT_PACKAGE = "system_base_in_product_package";
export const SYSTEM_COLOR = "system_color";
export const SYSTEM_COLOR_DATA = "system_color_data";
export const SYSTEM_COLOR_WITH_FORMULA_INFO = "system_color_with_formula_info";
export const SYSTEM_COLORANT = "system_colorant";
export const SYSTEM_COLORANT_PACKAGE = "system_colorant_package";
export const SYSTEM_CURRENCY = "system_currency";
export const SYSTEM_DUPLICATE = "system_duplicate";
export const SYSTEM_FANDECK = "system_fandeck";
export const SYSTEM_FANDECK_IN_COLOR = "system_fandeck_in_color";
export const SYSTEM_FORMULA = "system_formula";
export const SYSTEM_FORMULA_COLORANT = "system_formula_colorant";
export const SYSTEM_FORMULA_NOTE = "system_formula_note";
export const SYSTEM_MARGIN_TYPE = "system_margin_type";
export const SYSTEM_MASTER_BASE = "system_master_base";
export const SYSTEM_MASTER_BASE_IN_PRODUCT = "system_master_base_in_product";
export const SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE = "system_master_base_in_product_package";
export const SYSTEM_MASTER_COLORANT = "system_master_colorant";
export const SYSTEM_MASTER_COLORANT_PACKAGE = "system_master_colorant_package";
export const SYSTEM_MASTER_FANDECK = "system_master_fandeck";
export const SYSTEM_MASTER_PRODUCT = "system_master_product";
export const SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS = "system_master_product_with_product_groups";
export const SYSTEM_MASTER_PRODUCT_GROUP = "system_master_product_group";
export const SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS = "system_master_product_group_with_products";
export const SYSTEM_MASTER_REDLIKE_USER_GROUP = "system_master_redlike_user_group";
export const SYSTEM_PACKAGE = "system_package";
export const SYSTEM_PRICE_GENERIC = "system_price_generic";
export const SYSTEM_PRICE_GROUP = "system_price_group";
export const SYSTEM_PRICE_MARGIN = "system_price_margin";
export const SYSTEM_PRICE_OPTION = "system_price_option";
export const SYSTEM_PRODUCT = "system_product";
export const SYSTEM_PRODUCT_DUPLICATE = "system_product_duplicate";
export const SYSTEM_PRODUCT_FOR_GROUP = "system_product_for_group";
export const SYSTEM_PRODUCT_GROUP = "system_product_group";
export const SYSTEM_PRODUCT_IN_COLOR = "system_product_in_color";
export const SYSTEM_REDLIKE_OPTION = "system_redlike_option";
export const SYSTEM_REDLIKE_USER = "system_redlike_user";
export const SYSTEM_REDLIKE_USER_WITH_USER_GROUPS = "system_redlike_user_with_user_groups";
export const SYSTEM_ZONE = "system_zone";
export const SYSTEM_ZONE_BASE = "system_zone_base";
export const SYSTEM_ZONE_BASE_IN_PRODUCT = "system_zone_base_in_product";
export const SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE = "system_zone_base_in_product_package";
export const SYSTEM_ZONE_COLORANT = "system_zone_colorant";
export const SYSTEM_ZONE_COLORANT_PACKAGE = "system_zone_colorant_package";
export const SYSTEM_ZONE_FANDECK = "system_zone_fandeck";
export const SYSTEM_ZONE_PRODUCT = "system_zone_product";
export const SYSTEM_ZONE_PRODUCT_GROUP = "system_zone_product_group";
export const SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS = "system_zone_product_with_product_groups";
export const SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS = "system_zone_product_group_with_products";
export const SYSTEM_ZONE_REDLIKE_USER_GROUP = "system_zone_redlike_user_group";
export const TRANSLATION = "translation";
export const UNIT = "unit";
export const USER = "user";
export const USER_ACTION_HISTORY = "user_action_history";
export const USER_GROUP = "user_group";
export const USER_GROUP_PRIVILEGE = "user_group_privilege";
export const USER_OPTION = "user_option";
export const USER_PASSWORD = "user_password";
export const VERSION = "version";
export const WAVELENGTH = "wavelength";
export const WEB_ZONE = "webZone";
export const ZONE = "zone";
export const ZONE_BASE = "zone_base";
export const ZONE_BASE_IN_PRODUCT = "zone_base_in_product";
export const ZONE_BASE_IN_PRODUCT_PACKAGE = "zone_base_in_product_package";
export const ZONE_COLOR = "zone_color";
export const ZONE_COLOR_DATA = "zone_color_data";
export const ZONE_COLOR_WITH_FORMULA_INFO = "zone_color_with_formula_info";
export const ZONE_COLORANT = "zone_colorant";
export const ZONE_COLORANT_PACKAGE = "zone_colorant_package";
export const ZONE_CURRENCY = "zone_currency";
export const ZONE_DUPLICATE = "zone_duplicate";
export const ZONE_FANDECK = "zone_fandeck";
export const ZONE_FANDECK_IN_COLOR = "zone_fandeck_in_color";
export const ZONE_FORMULA = "zone_formula";
export const ZONE_FORMULA_COLORANT = "zone_formula_colorant";
export const ZONE_FORMULA_NOTE = "zone_formula_note";
export const ZONE_MARGIN_TYPE = "zone_margin_type";
export const ZONE_PACKAGE = "zone_package";
export const ZONE_PRICE_GENERIC = "zone_price_generic";
export const ZONE_PRICE_GROUP = "zone_price_group";
export const ZONE_PRICE_MARGIN = "zone_price_margin";
export const ZONE_PRICE_OPTION = "zone_price_option";
export const ZONE_PRODUCT = "zone_product";
export const ZONE_PRODUCT_DUPLICATE = "zone_product_duplicate";
export const ZONE_PRODUCT_GROUP = "zone_product_group";
export const ZONE_PRODUCT_IN_COLOR = "zone_product_in_color";
export const ZONE_REDLIKE_OPTION = "zone_redlike_option";
export const ZONE_REDLIKE_USER = "zone_redlike_user";
export const ZONE_REDLIKE_USER_WITH_USER_GROUPS = "zone_redlike_user_with_user_groups";
export const ZONE_SYSTEM_BASE = "zone_system_base";
export const ZONE_SYSTEM_BASE_IN_PRODUCT = "zone_system_base_in_product";
export const ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE = "zone_system_base_in_product_package";
export const ZONE_SYSTEM_COLORANT = "zone_system_colorant";
export const ZONE_SYSTEM_COLORANT_PACKAGE = "zone_system_colorant_package";
export const ZONE_SYSTEM_FANDECK = "zone_system_fandeck";
export const ZONE_SYSTEM_PRODUCT = "zone_system_product";
export const ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS = "zone_system_product_with_product_groups";
export const ZONE_SYSTEM_PRODUCT_GROUP = "zone_system_product_group";
export const ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS = "zone_system_product_group_with_products";
export const ZONE_SYSTEM_REDLIKE_USER_GROUP = "zone_system_redlike_user_group";

export const GENERIC_OBJECT_TYPES = [
    BARCODE,
    CURRENCY,
    FILE_CATEGORY,
    GENERIC_IMAGE,
    IMAGE_CATEGORY,
    PACKAGE,
    PRODUCT_SHEET,
    SPECTRO_TYPE,
    STATIC_SQL,
    SYSTEM,
    UNIT,
    ZONE
];

export const MASTER_OBJECT_TYPES = [
    MASTER_BASE,
    MASTER_BASE_IN_PRODUCT,
    MASTER_COLOR,
    MASTER_COLOR_DATA,
    MASTER_COLORANT,
    MASTER_FANDECK,
    MASTER_FANDECK_IN_COLOR,
    MASTER_FORMULA_NOTE,
    MASTER_FORMULA,
    MASTER_PRODUCT,
    MASTER_PRODUCT_IN_COLOR,
    MASTER_REDLIKE_OPTION,
    MASTER_REDLIKE_PRIVILEGE
];

export const MASTER_SYSTEM_OBJECT_TYPES = [
    MASTER_SYSTEM_BASE,
    MASTER_SYSTEM_BASE_IN_PRODUCT,
    MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    MASTER_SYSTEM_COLORANT,
    MASTER_SYSTEM_COLORANT_PACKAGE,
    MASTER_SYSTEM_FANDECK,
    MASTER_SYSTEM_PRODUCT,
    MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
    MASTER_SYSTEM_PRODUCT_GROUP,
    MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS
];

export const SYSTEM_MASTER_OBJECT_TYPES = [
    SYSTEM_MASTER_BASE,
    SYSTEM_MASTER_BASE_IN_PRODUCT,
    SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_MASTER_COLORANT,
    SYSTEM_MASTER_COLORANT_PACKAGE,
    SYSTEM_MASTER_FANDECK,
    SYSTEM_MASTER_PRODUCT,
    SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS,
    SYSTEM_MASTER_PRODUCT_GROUP,
    SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS
];

export const SYSTEM_OBJECT_TYPES = [
    SYSTEM_BASE,
    SYSTEM_BASE_IN_PRODUCT,
    SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_COLOR,
    SYSTEM_COLOR_DATA,
    SYSTEM_COLORANT,
    SYSTEM_COLORANT_PACKAGE,
    SYSTEM_FANDECK,
    SYSTEM_FANDECK_IN_COLOR,
    SYSTEM_FORMULA,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_PACKAGE,
    SYSTEM_PRICE_GENERIC,
    SYSTEM_PRICE_GROUP,
    SYSTEM_PRICE_MARGIN,
    SYSTEM_PRICE_OPTION,
    SYSTEM_PRODUCT,
    SYSTEM_PRODUCT_GROUP,
    SYSTEM_PRODUCT_IN_COLOR,
    SYSTEM_REDLIKE_OPTION
];

export const SYSTEM_ZONE_OBJECT_TYPES = [
    SYSTEM_ZONE_BASE,
    SYSTEM_ZONE_BASE_IN_PRODUCT,
    SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_ZONE_COLORANT,
    SYSTEM_ZONE_COLORANT_PACKAGE,
    SYSTEM_ZONE_FANDECK,
    SYSTEM_ZONE_PRODUCT,
    SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS,
    SYSTEM_ZONE_PRODUCT_GROUP,
    SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS
];

export const ZONE_SYSTEM_OBJECT_TYPES = [
    ZONE_SYSTEM_BASE,
    ZONE_SYSTEM_BASE_IN_PRODUCT,
    ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    ZONE_SYSTEM_COLORANT,
    ZONE_SYSTEM_COLORANT_PACKAGE,
    ZONE_SYSTEM_FANDECK,
    ZONE_SYSTEM_PRODUCT,
    ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
    ZONE_SYSTEM_PRODUCT_GROUP,
    ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS
];

export const ZONE_OBJECT_TYPES = [
    ZONE_BASE,
    ZONE_BASE_IN_PRODUCT,
    ZONE_BASE_IN_PRODUCT_PACKAGE,
    ZONE_COLOR,
    ZONE_COLOR_DATA,
    ZONE_COLORANT,
    ZONE_COLORANT_PACKAGE,
    ZONE_CURRENCY,
    ZONE_FANDECK,
    ZONE_FANDECK_IN_COLOR,
    ZONE_FORMULA,
    ZONE_FORMULA_NOTE,
    ZONE_PACKAGE,
    ZONE_PRICE_GENERIC,
    ZONE_PRICE_GROUP,
    ZONE_PRICE_MARGIN,
    ZONE_PRICE_OPTION,
    ZONE_PRODUCT,
    ZONE_PRODUCT_GROUP,
    ZONE_PRODUCT_IN_COLOR,
    ZONE_REDLIKE_OPTION
];

export const SYSTEM_IMAGES_OBJECT_TYPES = [
    SYSTEM_BASE_IN_PRODUCT,
    SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_COLORANT_PACKAGE,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_PRODUCT
];

export const ZONE_IMAGES_OBJECT_TYPES = [
    ZONE_BASE_IN_PRODUCT,
    ZONE_BASE_IN_PRODUCT_PACKAGE,
    ZONE_COLORANT_PACKAGE,
    ZONE_FORMULA_NOTE,
    ZONE_PRODUCT
];

export const COMPARE_DATA_OBJECT_TYPES = [
    SYSTEM_BASE,
    SYSTEM_BASE_IN_PRODUCT,
    SYSTEM_COLORANT,
    SYSTEM_FANDECK,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_PRODUCT,
    ZONE_BASE,
    ZONE_BASE_IN_PRODUCT,
    ZONE_BASE_IN_PRODUCT_PACKAGE,
    ZONE_COLORANT,
    ZONE_COLORANT_PACKAGE,
    ZONE_FANDECK,
    ZONE_FORMULA_NOTE,
    ZONE_PRODUCT
];

export const PROPAGATE_INHERIT_DATA_OBJECT_TYPES = [MASTER_SYSTEM_PRODUCT, SYSTEM_ZONE_PRODUCT];

export const DELETE_WARNING_OBJECT_TYPES = [
    MASTER_BASE,
    MASTER_BASE_IN_PRODUCT,
    MASTER_COLORANT,
    MASTER_FANDECK,
    MASTER_FORMULA_NOTE,
    MASTER_PRODUCT
];

export const CUSTOM_SEARCH_OBJECT_TYPES = [
    MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
    MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
    SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS,
    SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS
];

export const DISABLED_SORTING_OBJECT_TYPES = [
    DEFAULT_MASTER_REDLIKE_USER_GROUP,
    MASTER_REDLIKE_USER_WITH_USER_GROUPS,
    MASTER_DEFAULT_REDLIKE_USER_GROUP,
    MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
    MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
    MASTER_SYSTEM_REDLIKE_USER_GROUP,
    PRODUCT_FILE,
    SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS,
    SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS,
    SYSTEM_MASTER_REDLIKE_USER_GROUP,
    SYSTEM_PRICE_GENERIC,
    SYSTEM_PRICE_GROUP,
    SYSTEM_PRODUCT_DUPLICATE,
    SYSTEM_REDLIKE_USER_WITH_USER_GROUPS,
    SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS,
    SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS,
    SYSTEM_ZONE_REDLIKE_USER_GROUP,
    USER_GROUP_PRIVILEGE,
    ZONE_REDLIKE_USER_WITH_USER_GROUPS,
    ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
    ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
    ZONE_SYSTEM_REDLIKE_USER_GROUP,
    ZONE_PRICE_GENERIC,
    ZONE_PRICE_GROUP,
    ZONE_PRODUCT_DUPLICATE
];

export const HIDDEN_ACTION_ROW_OBJECT_TYPES = [
    MASTER_COLOR_SPECTRO_TYPE,
    MASTER_FORMULA_COLORANT,
    SYSTEM_FORMULA_COLORANT,
    ZONE_FORMULA_COLORANT,
    SYSTEM_PRODUCT_DUPLICATE,
    ZONE_PRODUCT_DUPLICATE
];

export const VISIBLE_ACTION_FOOTER_ROW_OBJECT_TYPES = [EXPORT_TASK, IMPORT_TASK, SYSTEM_ZONE];

export const READONLY_TABLES_WITHOUT_MARGIN_LEFT_SEARCH = [
    DATABASE,
    GENERIC_IMAGE,
    MASTER_PRODUCT,
    PRODUCT_SHEET,
    SYSTEM,
    SYSTEM_PRODUCT,
    ZONE,
    ZONE_PRODUCT
];

export const READONLY_TABLES_WITHOUT_MARGIN_LEFT_TABLE_MENU = [
    DATABASE,
    GENERIC_IMAGE,
    PRODUCT_SHEET,
    SYSTEM_PRODUCT,
    ZONE_PRODUCT
];

export const TABLES_WITHOUT_MARGIN_LEFT_TABLE_MENU = [
    EXPORT_TASK,
    IMPORT_TASK,
    MASTER_REDLIKE_OPTION,
    SYSTEM_REDLIKE_OPTION,
    ZONE_REDLIKE_OPTION
];

export const HIDDEN_ADD_BUTTON_OBJECT_TYPES = [
    IMAGE,
    MASTER_COLOR_DATA,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_PACKAGE,
    SYSTEM_PRICE_GENERIC,
    SYSTEM_PRICE_GROUP,
    ZONE_PRICE_GENERIC,
    ZONE_PRICE_GROUP,
    ZONE_FORMULA_NOTE,
    ZONE_PACKAGE
];

export const HIDDEN_DELETE_BUTTON_OBJECT_TYPES = [
    BARCODE,
    DATABASE_MONITORING,
    IMAGE,
    MASTER_COLOR,
    MASTER_COLOR_SPECTRO_TYPE,
    MASTER_COLOR_WITH_FORMULA_INFO,
    MASTER_FORMULA_COLORANT,
    MASTER_REDLIKE_OPTION,
    MASTER_REDLIKE_PRIVILEGE,
    MASTER_REDLIKE_USER_WITH_USER_GROUPS,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_PACKAGE,
    SYSTEM_PRICE_GENERIC,
    SYSTEM_PRICE_GROUP,
    SYSTEM_PRODUCT_DUPLICATE,
    SYSTEM_REDLIKE_OPTION,
    SYSTEM_REDLIKE_USER_WITH_USER_GROUPS,
    SYSTEM_ZONE,
    USER_ACTION_HISTORY,
    WEB_ZONE,
    ZONE_DUPLICATE,
    ZONE_FORMULA_NOTE,
    ZONE_PACKAGE,
    ZONE_PRICE_GENERIC,
    ZONE_PRICE_GROUP,
    ZONE_PRODUCT_DUPLICATE,
    ZONE_REDLIKE_OPTION,
    ZONE_REDLIKE_USER_WITH_USER_GROUPS
];

export const HIDDEN_EDIT_BUTTON_OBJECT_TYPES = [EXPORT_TASK, FILE, IMAGE, IMPORT_TASK];

export const HIDDEN_FILTER_ROW_OBJECT_TYPES = [
    MASTER_REDLIKE_OPTION,
    MASTER_REDLIKE_PRIVILEGE,
    SYSTEM_PACKAGE,
    SYSTEM_PRICE_MARGIN,
    SYSTEM_REDLIKE_OPTION,
    SYSTEM_ZONE,
    ZONE_PACKAGE,
    ZONE_PRICE_MARGIN,
    ZONE_REDLIKE_OPTION
];

export const HIDDEN_OPTION_BUTTON_OBJECT_TYPES = [
    MASTER_COLOR_DATA,
    MASTER_FORMULA,
    MASTER_FORMULA_COLORANT,
    MASTER_REDLIKE_USER_GROUP,
    MASTER_REDLIKE_USER_WITH_USER_GROUPS,
    PRODUCT_FILE,
    SYSTEM_COLOR_DATA,
    SYSTEM_FORMULA,
    SYSTEM_FORMULA_COLORANT,
    SYSTEM_PRODUCT_DUPLICATE,
    SYSTEM_REDLIKE_USER_WITH_USER_GROUPS,
    ZONE_COLOR_DATA,
    ZONE_FORMULA,
    ZONE_FORMULA_COLORANT,
    ZONE_DUPLICATE,
    ZONE_PRODUCT_DUPLICATE,
    ZONE_REDLIKE_USER_WITH_USER_GROUPS
];

export const PREVIEW_BUTTON_OBJECT_TYPES = [FILE, USER_ACTION_HISTORY];

export const DATE_PICKER_OBJECT_TYPES = [EXPORT_TASK_DELETE, IMPORT_TASK_DELETE, MASTER_COLORANT_BATCH];

export const PRICES_OBJECT_TYPES = [
    SYSTEM_BASE_IN_PRODUCT,
    SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_COLORANT,
    SYSTEM_PACKAGE,
    SYSTEM_PRODUCT,
    ZONE_BASE_IN_PRODUCT,
    ZONE_BASE_IN_PRODUCT_PACKAGE,
    ZONE_COLORANT,
    ZONE_PACKAGE,
    ZONE_PRODUCT
];

export const RESTORE_BUTTON_OBJECT_TYPES = [
    MASTER_REDLIKE_OPTION,
    MASTER_REDLIKE_PRIVILEGE,
    SYSTEM_FORMULA_NOTE,
    SYSTEM_REDLIKE_OPTION,
    ZONE_FORMULA_NOTE,
    ZONE_REDLIKE_OPTION
];

export const VISIBLE_MORE_ACTION_BUTTON_OBJECT_TYPES = [
    DATABASE,
    GENERIC_IMAGE,
    MASTER_PRODUCT,
    PRODUCT_SHEET,
    SYSTEM,
    SYSTEM_PRODUCT,
    ZONE,
    ZONE_PRODUCT
];

export const VISIBLE_TABLE_CHECKBOX_OBJECT_TYPES = [
    EXPORT_TASK,
    IMPORT_TASK,
    MASTER_REDLIKE_OPTION,
    MASTER_REDLIKE_PRIVILEGE,
    SYSTEM_REDLIKE_OPTION,
    ZONE_REDLIKE_OPTION
];

export const HIDDEN_TABLE_HEADER_OBJECT_TYPES = [ZONE_DUPLICATE];

export const HIDE_BUTTON_OBJECT_TYPES = [EXPORT_TASK, IMPORT_TASK];

export const CHECK_ALL_BUTTON_OBJECT_TYPES = [EXPORT_TASK, IMPORT_TASK, SYSTEM_ZONE];

export const WITHOUT_OFFSET_OBJECT_TYPES = [MASTER_REDLIKE_USER_GROUP];

export const RGB_MINI_PREVIEW_HIDDEN = [MASTER_COLOR_SPECTRO_TYPE];

export const SHOW_DATE_PICKER_OBJECT_TYPES = [USER_ACTION_HISTORY];

export const VISIBLE_ADDITIONAL_BUTTON_OBJECT_TYPES = [EXPORT_TASK_DELETE, IMPORT_TASK_DELETE];

export const SORTING_OBJECT_TYPES_ON_CLIENT_SIDE = [
    FILE,
    IMAGE,
    MASTER_FORMULA_COLORANT,
    MASTER_REDLIKE_OPTION,
    SYSTEM_MASTER_BASE,
    SYSTEM_MASTER_BASE_IN_PRODUCT,
    SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE,
    SYSTEM_MASTER_COLORANT,
    SYSTEM_MASTER_COLORANT_PACKAGE,
    SYSTEM_MASTER_FANDECK,
    SYSTEM_MASTER_PRODUCT,
    SYSTEM_MASTER_PRODUCT_GROUP,
    SYSTEM_REDLIKE_OPTION,
    SYSTEM_ZONE,
    ZONE_REDLIKE_OPTION,
    ZONE_SYSTEM_BASE,
    ZONE_SYSTEM_BASE_IN_PRODUCT,
    ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    ZONE_SYSTEM_COLORANT,
    ZONE_SYSTEM_COLORANT_PACKAGE,
    ZONE_SYSTEM_FANDECK,
    ZONE_SYSTEM_PRODUCT,
    ZONE_SYSTEM_PRODUCT_GROUP
];

export const VALIDATE_PASSWORD_TYPES: Array<ObjectType | never> = [];

export const COLOR_TYPES_FOR_PROPERTY = [
    MASTER_COLOR,
    SYSTEM_COLOR,
    ZONE_COLOR,
    MASTER_COLOR_WITH_FORMULA_INFO,
    SYSTEM_COLOR_WITH_FORMULA_INFO,
    ZONE_COLOR_WITH_FORMULA_INFO
];

export const COLOR_TYPES = [MASTER_COLOR, SYSTEM_COLOR, ZONE_COLOR];
