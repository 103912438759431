import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ProductGroup, mapProductGroup } from "../../types/productGroup";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";
import { mapProduct } from "../../types/product";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_PRODUCT_GROUP,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_PRODUCT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item.productGroup);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_PRODUCT_GROUP,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_ADD_SYSTEM_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCT_GROUPS_ADD)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT_GROUP));
                requests.push(
                    navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE)
                );
            } else {
                const productGroup = mapProduct(response.data);

                if (productGroup) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PRODUCT_GROUP, [productGroup])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT_GROUP),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_PRODUCT_GROUP, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_PRODUCT_GROUP,
                    productGroupList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_FOR_PRODUCT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productGroupList: Array<ProductGroup> = [];
            let productGroup: ProductGroup | null;

            for (const item of response.data.data) {
                productGroup = mapProductGroup(item.productGroup);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
                    productGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_ADD_ZONE_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCT_GROUPS_ADD)
            ];
        }
        case methods.METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT_GROUP));
                requests.push(
                    navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE)
                );
            } else {
                const productGroup = mapProduct(response.data);

                if (productGroup) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.ZONE_PRODUCT_GROUP, [productGroup])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_PRODUCT_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT_GROUP),
                universalObjectActions.setActiveItems(objectTypes.ZONE_PRODUCT_GROUP, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
