// init
export const METHOD_INIT = "/api/v1/init";

// files
export const METHOD_BACKUP_DATABASE = "/api/v1/backup_database";
export const METHOD_DOWNLOAD_FILE = "/api/v1/data_download_file_by_id";
export const METHOD_DOWNLOAD_IMPORT_TASK_FILES = "/api/v1/download_import_task_files";
export const METHOD_GET_EXPORT_TASK_FILE = "/api/v1/get_export_task_file_by_id";
export const METHOD_GET_FILE_PREVIEW = "/api/v1/data_get_file_preview_by_id";
export const METHOD_UPLOAD_DIRECTORY = "/api/v1/upload_directory";
export const METHOD_UPLOAD_FILE = "/api/v1/upload_file";
export const METHOD_UPLOAD_FILES = "/api/v1/upload_multiple_files";

// images
export const METHOD_GET_THUMBNAIL_BY_ID = "/api/v1/data_get_thumbnail_by_id";
export const METHOD_UPDATE_IMAGE = "/api/v1/data_upload_update_image_by_id";
export const METHOD_UPLOAD_IMAGE = "/api/v1/data_upload_insert_image";
export const METHOD_UPLOAD_IMAGE_DIRECTORY = "/api/v1/data_upload_insert_image_directory";
