import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { BaseInProduct, updateBaseInProduct } from "../types/baseInProduct";

export type BaseInProductState = Readonly<{
    masterAllList: Array<BaseInProduct>;
    masterList: Array<BaseInProduct>;
    masterActiveList: Array<BaseInProduct>;
    masterCountTotal: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    masterSystemList: Array<BaseInProduct>;
    masterSystemActiveList: Array<BaseInProduct>;
    masterSystemSearch: string | null;
    masterSystemSearchParams: Record<propertyConstants.Property, string>;
    masterSystemOffset: number;
    masterSystemPage: number;
    systemMasterList: Array<BaseInProduct>;
    systemMasterActiveList: Array<BaseInProduct>;
    systemMasterSortingAsc: boolean;
    systemMasterSortingCriterion: propertyConstants.Property;
    systemMasterSearch: string | null;
    systemMasterSearchParams: Record<propertyConstants.Property, string>;
    systemMasterOffset: number;
    systemMasterPage: number;
    systemList: Array<BaseInProduct>;
    systemActiveList: Array<BaseInProduct>;
    systemCountAvailable: number | null;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    systemZoneList: Array<BaseInProduct>;
    systemZoneActiveList: Array<BaseInProduct>;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneOffset: number;
    systemZonePage: number;
    zoneSystemList: Array<BaseInProduct>;
    zoneSystemActiveList: Array<BaseInProduct>;
    zoneSystemSortingAsc: boolean;
    zoneSystemSortingCriterion: propertyConstants.Property;
    zoneSystemSearch: string | null;
    zoneSystemSearchParams: Record<propertyConstants.Property, string>;
    zoneSystemOffset: number;
    zoneSystemPage: number;
    zoneList: Array<BaseInProduct>;
    zoneActiveList: Array<BaseInProduct>;
    zoneCountAvailable: number | null;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
}>;

const initialState: BaseInProductState = {
    masterAllList: [],
    masterList: [],
    masterActiveList: [],
    masterCountTotal: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE,
    masterSystemList: [],
    masterSystemActiveList: [],
    masterSystemSearch: null,
    masterSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterSystemOffset: tableConstants.DEFAULT_OFFSET,
    masterSystemPage: tableConstants.DEFAULT_PAGE,
    systemMasterList: [],
    systemMasterActiveList: [],
    systemMasterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemMasterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemMasterSearch: null,
    systemMasterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemMasterOffset: tableConstants.DEFAULT_OFFSET,
    systemMasterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCountAvailable: null,
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSearch: null,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneOffset: tableConstants.DEFAULT_OFFSET,
    systemZonePage: tableConstants.DEFAULT_PAGE,
    zoneSystemList: [],
    zoneSystemActiveList: [],
    zoneSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSystemSearch: null,
    zoneSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneSystemOffset: tableConstants.DEFAULT_OFFSET,
    zoneSystemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCountAvailable: null,
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE
};

export default function basesInProduct(
    state: BaseInProductState = initialState,
    action: Record<string, any>
): BaseInProductState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterAllList: action.payload.list
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_PRODUCT: {
                    if (action.payload.list.length === 0) {
                        return {
                            ...state,
                            masterList: initialState.masterList,
                            masterActiveList: initialState.masterActiveList,
                            masterCountTotal: initialState.masterCountTotal,
                            masterOffset: initialState.masterOffset,
                            masterPage: initialState.masterPage
                        };
                    }
                    return state;
                }
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterSystemList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemMasterList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    if (action.payload.list.length === 0) {
                        return {
                            ...state,
                            systemList: initialState.systemList,
                            systemActiveList: initialState.systemActiveList,
                            systemCountTotal: initialState.systemCountTotal,
                            systemOffset: initialState.systemOffset,
                            systemPage: initialState.systemPage
                        };
                    }
                    return state;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountAvailable: action.payload.availableCount,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemCountTotal: action.payload.totalCount,
                        systemZoneList: action.payload.list
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneSystemList: action.payload.list
                    };
                }
                case objectTypes.ZONE_PRODUCT: {
                    if (action.payload.list.length === 0) {
                        return {
                            ...state,
                            zoneList: initialState.zoneList,
                            zoneActiveList: initialState.zoneActiveList,
                            zoneCountTotal: initialState.zoneCountTotal,
                            zoneOffset: initialState.zoneOffset,
                            zonePage: initialState.zonePage
                        };
                    }
                    return state;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountAvailable: action.payload.availableCount,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_PRODUCT: {
                    return {
                        ...state,
                        masterList: initialState.masterList,
                        masterActiveList: initialState.masterActiveList,
                        masterCountTotal: initialState.masterCountTotal,
                        masterOffset: initialState.masterOffset,
                        masterPage: initialState.masterPage
                    };
                }
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterSystemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT: {
                    const list = generalHelper.updateLists(state.systemMasterList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        systemMasterList: list,
                        systemMasterActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    return {
                        ...state,
                        systemList: initialState.systemList,
                        systemActiveList: initialState.systemActiveList,
                        systemCountTotal: initialState.systemCountTotal,
                        systemOffset: initialState.systemOffset,
                        systemPage: initialState.systemPage
                    };
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemZoneActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT: {
                    const list = generalHelper.updateLists(state.zoneSystemList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        zoneSystemList: list,
                        zoneSystemActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRODUCT: {
                    return {
                        ...state,
                        zoneList: initialState.zoneList,
                        zoneActiveList: initialState.zoneActiveList,
                        zoneCountTotal: initialState.zoneCountTotal,
                        zoneOffset: initialState.zoneOffset,
                        zonePage: initialState.zonePage
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.UPDATE_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        masterSystemOffset: action.payload.offset,
                        masterSystemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemZoneOffset: action.payload.offset,
                        systemZonePage: action.payload.page
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SYSTEM_SEARCH,
                        tableConstants.MASTER_SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_ZONE_SEARCH,
                        tableConstants.SYSTEM_ZONE_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemMasterList: generalHelper.sortItemsByProperty(
                            state.systemMasterList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        systemMasterSortingAsc: action.payload.asc,
                        systemMasterSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneSystemList: generalHelper.sortItemsByProperty(
                            state.zoneSystemList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        zoneSystemSortingAsc: action.payload.asc,
                        zoneSystemSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_BASE_IN_PRODUCT: {
                        const updatedBaseInProductList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateBaseInProduct
                        );

                        if (updatedBaseInProductList.length) {
                            return {
                                ...state,
                                masterList: updatedBaseInProductList
                            };
                        }

                        return state;
                    }
                    case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                        const updatedBaseInProductList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateBaseInProduct
                        );

                        if (updatedBaseInProductList.length) {
                            return {
                                ...state,
                                systemList: updatedBaseInProductList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_BASE_IN_PRODUCT: {
                        const updatedBaseInProductList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateBaseInProduct
                        );

                        if (updatedBaseInProductList.length) {
                            return {
                                ...state,
                                zoneList: updatedBaseInProductList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_BASE_IN_PRODUCT: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_BASE_IN_PRODUCT: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            if (action.payload.wizard === null || action.payload.scene === navigationTypes.SYSTEM_PRODUCTS_SCENE) {
                return {
                    ...initialState,
                    systemActiveList: state.systemActiveList
                };
            }

            return initialState;
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload.type === modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE ||
                action.payload.type === modalTypes.MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE
            ) {
                return {
                    ...state,
                    masterSystemList: initialState.masterSystemList,
                    masterSystemActiveList: initialState.masterSystemActiveList,
                    masterSystemSearch: initialState.masterSystemSearch,
                    masterSystemSearchParams: initialState.masterSystemSearchParams,
                    systemMasterList: initialState.systemMasterList,
                    systemMasterActiveList: initialState.systemMasterActiveList,
                    systemZoneList: initialState.systemZoneList,
                    systemZoneActiveList: initialState.systemZoneActiveList,
                    systemZoneSearch: initialState.systemZoneSearch,
                    systemZoneSearchParams: initialState.systemZoneSearchParams,
                    zoneSystemList: initialState.zoneSystemList,
                    zoneSystemActiveList: initialState.zoneSystemActiveList
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                masterSystemList: initialState.masterSystemList,
                masterSystemActiveList: initialState.masterSystemActiveList,
                masterSystemSearch: initialState.masterSystemSearch,
                masterSystemSearchParams: initialState.masterSystemSearchParams,
                systemMasterList: initialState.systemMasterList,
                systemMasterActiveList: initialState.systemMasterActiveList,
                systemZoneList: initialState.systemZoneList,
                systemZoneActiveList: initialState.systemZoneActiveList,
                systemZoneSearch: initialState.systemZoneSearch,
                systemZoneSearchParams: initialState.systemZoneSearchParams,
                zoneSystemList: initialState.zoneSystemList,
                zoneSystemActiveList: initialState.zoneSystemActiveList
            };
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return {
                ...initialState,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterCountTotal: state.masterCountTotal,
                masterSearch: state.masterSearch,
                masterSearchParams: state.masterSearchParams,
                masterOffset: state.masterOffset,
                masterPage: state.masterPage
            };
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            // TODO nasledujici zkontroluj ve vsech reducerech
            return {
                ...initialState,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterCountTotal: state.masterCountTotal,
                masterOffset: state.masterOffset,
                masterSearch: state.masterSearch,
                masterSearchParams: state.masterSearchParams,
                // TODO masterOffset
                masterPage: state.masterPage,
                masterSystemList: state.masterSystemList, // TODO other masterSystem props? and systemMaster? and systemAvailableCount?
                systemList: state.systemList,
                systemActiveList: state.systemActiveList,
                systemCountTotal: state.systemCountTotal,
                systemSearch: state.systemSearch,
                systemSearchParams: state.systemSearchParams,
                systemOffset: state.systemOffset,
                systemPage: state.systemPage,
                systemZoneList: state.systemZoneList // TODO tohle zustava?
            };
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
