import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ColorantPackage, mapColorantPackage } from "../../types/colorantPackage";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            let colorantPackage: ColorantPackage | null;

            for (const item of response.data.data) {
                colorantPackage = mapColorantPackage(item.package, item.colorantPackage);

                if (colorantPackage) {
                    colorantPackageList.push(colorantPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_COLORANT_PACKAGE,
                    colorantPackageList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            let colorantPackage: ColorantPackage | null;

            for (const item of response.data.data) {
                colorantPackage = mapColorantPackage(item.package, item.colorantPackage);

                if (colorantPackage) {
                    colorantPackageList.push(colorantPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE,
                    colorantPackageList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_SYSTEM_COLORANT_PACKAGE_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            const colorantPackage = mapColorantPackage(response.data.package, response.data.colorantPackage);
            const requests: Array<any> = [];

            if (colorantPackage) {
                colorantPackageList.push(colorantPackage);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: colorantPackage
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.SYSTEM_COLORANT_PACKAGE, colorantPackageList)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANT_PACKAGES_ADD)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_COLORANT_PACKAGES_EDIT
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANT_PACKAGES_EDIT));
            } else {
                const colorantPackage = mapColorantPackage(response.data.package, response.data.colorantPackage);

                if (colorantPackage) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_COLORANT_PACKAGE, [colorantPackage])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_COLORANT_PACKAGE, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            let colorantPackage: ColorantPackage | null;

            for (const item of response.data.data) {
                colorantPackage = mapColorantPackage(item.package, item.colorantPackage);

                if (colorantPackage) {
                    colorantPackageList.push(colorantPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_COLORANT_PACKAGE,
                    colorantPackageList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            let colorantPackage: ColorantPackage | null;

            for (const item of response.data.data) {
                colorantPackage = mapColorantPackage(item.package, item.colorantPackage);

                if (colorantPackage) {
                    colorantPackageList.push(colorantPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE,
                    colorantPackageList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantPackageList: Array<ColorantPackage> = [];
            const colorantPackage = mapColorantPackage(response.data.package, response.data.colorantPackage);
            const requests: Array<any> = [];

            if (colorantPackage) {
                colorantPackageList.push(colorantPackage);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: colorantPackage
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.ZONE_COLORANT_PACKAGE, colorantPackageList)
            ];
        }
        case methods.METHOD_ADD_ZONE_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT_PACKAGE)
            ];
        }
        case methods.METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT_PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_COLORANT_PACKAGE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_COLORANT_PACKAGES_EDIT
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_COLORANT_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANT_PACKAGES_EDIT));
            } else {
                const colorantPackage = mapColorantPackage(response.data.package, response.data.colorantPackage);

                if (colorantPackage) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.ZONE_COLORANT_PACKAGE, [colorantPackage])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_COLORANT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT_PACKAGE),
                universalObjectActions.setActiveItems(objectTypes.ZONE_COLORANT_PACKAGE, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
