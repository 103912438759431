import * as actionHistoryResponseService from "./serverResponseServices/actionHistoryResponseService";
import * as barcodeResponseService from "./serverResponseServices/barcodeResponseService";
import * as baseInProductPackageResponseService from "./serverResponseServices/baseInProductPackageResponseService";
import * as baseInProductResponseService from "./serverResponseServices/baseInProductResponseService";
import * as baseResponseService from "./serverResponseServices/baseResponseService";
import * as colorResponseService from "./serverResponseServices/colorResponseService";
import * as colorantBatchResponseService from "./serverResponseServices/colorantBatchResponseService";
import * as colorantPackageResponseService from "./serverResponseServices/colorantPackageResponseService";
import * as colorantResponseService from "./serverResponseServices/colorantResponseService";
import * as companyResponseService from "./serverResponseServices/companyResponseService";
import * as currencyResponseService from "./serverResponseServices/currencyResponseService";
import * as databaseResponseService from "./serverResponseServices/databaseResponseService";
import * as exportResponseService from "./serverResponseServices/exportResponseService";
import * as fandeckResponseService from "./serverResponseServices/fandeckResponseService";
import * as fileCategoryResponseService from "./serverResponseServices/fileCategoryResponseService";
import * as fileResponseService from "./serverResponseServices/fileResponseService";
import * as formulaNoteResponseService from "./serverResponseServices/formulaNoteResponseService";
import * as formulaResponseService from "./serverResponseServices/formulaResponseService";
import * as imageCategoryResponseService from "./serverResponseServices/imageCategoryResponseService";
import * as imageResponseService from "./serverResponseServices/imageResponseService";
import * as importResponseService from "./serverResponseServices/importResponseService";
import * as licenseResponseService from "./serverResponseServices/licenseResponseService";
import * as loginResponseService from "./serverResponseServices/loginResponseService";
import * as messageHelper from "../helpers/messageHelper";
import * as methods from "../constants/serverMethods";
import * as optionResponseService from "./serverResponseServices/optionResponseService";
import * as packageResponseService from "./serverResponseServices/packageResponseService";
import * as priceResponseService from "./serverResponseServices/priceResponseService";
import * as privilegeResponseService from "./serverResponseServices/privilegeResponseService";
import * as productGroupResponseService from "./serverResponseServices/productGroupResponseService";
import * as productResponseService from "./serverResponseServices/productResponseService";
import * as redlikeUserGroupResponseService from "./serverResponseServices/redlikeUserGroupResponseService";
import * as redlikeUserResponseService from "./serverResponseServices/redlikeUserResponseService";
import * as responseValidationHelper from "../helpers/responseValidationHelper";
import * as scriptResponseService from "./serverResponseServices/scriptResponseService";
import * as serverMonitoringResponseService from "./serverResponseServices/serverMonitoringResponseService";
import * as serverResponseService from "./serverResponseServices/serverResponseService";
import * as spectroResponseService from "./serverResponseServices/spectroTypeResponseService";
import * as systemResponseService from "./serverResponseServices/systemResponseService";
import * as unitResponseService from "./serverResponseServices/unitResponseService";
import * as userGroupResponseService from "./serverResponseServices/userGroupResponseService";
import * as userResponseService from "./serverResponseServices/userResponseService";
import * as websocketActions from "../actions/websocketActions";
import * as zoneResponseService from "./serverResponseServices/zoneResponseService";

import { AppState } from "../reducers";
import { ServerResponse } from "../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_DATA_COMMIT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_DATA_ROLLBACK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_GET_ALL_USER_HISTORY_ACTIONS: {
            return actionHistoryResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_BARCODES:
        case methods.METHOD_GET_ITEM_BY_BARCODE: {
            return barcodeResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_BASES:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES:
        case methods.METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM:
        case methods.METHOD_ADD_MASTER_BASE:
        case methods.METHOD_EDIT_MASTER_BASE:
        case methods.METHOD_DELETE_MASTER_BASE:
        case methods.METHOD_GET_ALL_SYSTEM_BASES:
        case methods.METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE:
        case methods.METHOD_ADD_SYSTEM_BASE:
        case methods.METHOD_ADD_SYSTEM_BASE_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_BASE:
        case methods.METHOD_DELETE_SYSTEM_BASE:
        case methods.METHOD_GET_ALL_ZONE_BASES:
        case methods.METHOD_ADD_ZONE_BASE:
        case methods.METHOD_ADD_ZONE_BASE_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_BASE:
        case methods.METHOD_DELETE_ZONE_BASE: {
            return baseResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS:
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM:
        case methods.METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID:
        case methods.METHOD_ADD_MASTER_BASE_IN_PRODUCT:
        case methods.METHOD_EDIT_MASTER_BASE_IN_PRODUCT:
        case methods.METHOD_DELETE_MASTER_BASE_IN_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS:
        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE:
        case methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID:
        case methods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT:
        case methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS:
        case methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_BY_ID:
        case methods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT:
        case methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT: {
            return baseInProductResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM:
        case methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE:
        case methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID:
        case methods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case methods.METHOD_GET_ALL_ZONE_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT:
        case methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_PACKAGE_BY_ID:
        case methods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE:
        case methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT_PACKAGE: {
            return baseInProductPackageResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_BY_FANDECK:
        case methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_BY_FANDECK:
        case methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_BY_FANDECK:
        case methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_WITH_FORMULA_INFO:
        case methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_WITH_FORMULA_INFO:
        case methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO:
        case methods.METHOD_GET_MASTER_COLOR_IN_FANDECK:
        case methods.METHOD_GET_SYSTEM_COLOR_IN_FANDECK:
        case methods.METHOD_GET_ZONE_COLOR_IN_FANDECK:
        case methods.METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS:
        case methods.METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS:
        case methods.METHOD_DELETE_MASTER_COLOR_IN_FANDECK:
        case methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK:
        case methods.METHOD_GET_DATA_FOR_SYSTEM_COLOR_IN_FANDECK:
        case methods.METHOD_GET_DATA_FOR_ZONE_COLOR_IN_FANDECK:
        case methods.METHOD_COLOR_CONVERT_LAB_TO_RGB:
        case methods.METHOD_COLOR_CONVERT_REFL_TO_LAB: {
            return colorResponseService.processWebsocketResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_COLORANTS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_COLORANTS:
        case methods.METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM:
        case methods.METHOD_ADD_MASTER_COLORANT:
        case methods.METHOD_EDIT_MASTER_COLORANT:
        case methods.METHOD_DELETE_MASTER_COLORANT:
        case methods.METHOD_GET_ALL_SYSTEM_COLORANTS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_COLORANTS:
        case methods.METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE:
        case methods.METHOD_GET_SYSTEM_COLORANT_BY_ID:
        case methods.METHOD_ADD_SYSTEM_COLORANT:
        case methods.METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_COLORANT:
        case methods.METHOD_DELETE_SYSTEM_COLORANT:
        case methods.METHOD_GET_ALL_ZONE_COLORANTS:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_COLORANTS:
        case methods.METHOD_GET_ZONE_COLORANT_BY_ID:
        case methods.METHOD_ADD_ZONE_COLORANT:
        case methods.METHOD_ADD_ZONE_COLORANT_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_COLORANT:
        case methods.METHOD_DELETE_ZONE_COLORANT: {
            return colorantResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT:
        case methods.METHOD_ADD_MASTER_COLORANT_BATCH:
        case methods.METHOD_EDIT_MASTER_COLORANT_BATCH:
        case methods.METHOD_DELETE_MASTER_COLORANT_BATCH: {
            return colorantBatchResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT:
        case methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM:
        case methods.METHOD_GET_SYSTEM_COLORANT_PACKAGE_BY_ID:
        case methods.METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_COLORANT_PACKAGE:
        case methods.METHOD_DELETE_SYSTEM_COLORANT_PACKAGE:
        case methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT:
        case methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE:
        case methods.METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID:
        case methods.METHOD_ADD_ZONE_COLORANT_PACKAGE:
        case methods.METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_COLORANT_PACKAGE:
        case methods.METHOD_DELETE_ZONE_COLORANT_PACKAGE: {
            return colorantPackageResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_COMPANIES_NO_PARAMS:
        case methods.METHOD_GET_ALL_COMPANIES:
        case methods.METHOD_ADD_COMPANY:
        case methods.METHOD_EDIT_COMPANY:
        case methods.METHOD_DELETE_COMPANY: {
            return companyResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_CURRENCIES_NO_PARAMS:
        case methods.METHOD_GET_ALL_CURRENCIES:
        case methods.METHOD_ADD_CURRENCY:
        case methods.METHOD_EDIT_CURRENCY:
        case methods.METHOD_DELETE_CURRENCY:
        case methods.METHOD_GET_ACTIVE_SYSTEM_CURRENCY:
        case methods.METHOD_SET_ACTIVE_SYSTEM_CURRENCY:
        case methods.METHOD_GET_ACTIVE_ZONE_CURRENCY: {
            return currencyResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES:
        case methods.METHOD_GET_ALL_DATABASES:
        case methods.METHOD_GET_ALL_DATABASES_NO_PARAMS:
        case methods.METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE:
        case methods.METHOD_ADD_DATABASE:
        case methods.METHOD_ADD_EMPTY_DATABASE:
        case methods.METHOD_DUPLICATE_DATABASE:
        case methods.METHOD_EDIT_DATABASE:
        case methods.METHOD_DELETE_DATABASE:
        case methods.METHOD_GET_ACTIVE_DATABASE:
        case methods.METHOD_SET_ACTIVE_DATABASE:
        case methods.METHOD_UPDATE_ACTIVE_DATABASE_STRUCTURE:
        case methods.METHOD_CLOSE_ACTIVE_DATABASE:
        case methods.METHOD_VACUUM_ALL_DATABASES: {
            return databaseResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_EXPORT_TASK_STATUSES:
        case methods.METHOD_GET_ALL_EXPORT_TASK_TYPES:
        case methods.METHOD_GET_ALL_EXPORT_TASKS:
        case methods.METHOD_INSERT_EXPORT_TASK:
        case methods.METHOD_INSERT_EXPORT_TASKS:
        case methods.METHOD_ABORT_EXPORT_TASK:
        case methods.METHOD_DELETE_EXPORT_TASK:
        case methods.METHOD_DELETE_EXPORT_TASKS:
        case methods.METHOD_HIDE_EXPORT_TASK:
        case methods.METHOD_SHOW_EXPORT_TASK: {
            return exportResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_FANDECKS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_FANDECKS:
        case methods.METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM:
        case methods.METHOD_GET_MASTER_FANDECK_BY_ID:
        case methods.METHOD_ADD_MASTER_FANDECK:
        case methods.METHOD_EDIT_MASTER_FANDECK:
        case methods.METHOD_DELETE_MASTER_FANDECK:
        case methods.METHOD_GET_ALL_SYSTEM_FANDECKS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_FANDECKS:
        case methods.METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE:
        case methods.METHOD_GET_SYSTEM_FANDECK_BY_ID:
        case methods.METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_FANDECK:
        case methods.METHOD_DELETE_SYSTEM_FANDECK:
        case methods.METHOD_GET_ALL_ZONE_FANDECKS:
        case methods.METHOD_GET_ZONE_FANDECK_BY_ID:
        case methods.METHOD_ADD_ZONE_FANDECK:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_FANDECKS:
        case methods.METHOD_ADD_ZONE_FANDECK_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_FANDECK:
        case methods.METHOD_DELETE_ZONE_FANDECK: {
            return fandeckResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_FILES:
        case methods.METHOD_GET_ALL_PRODUCT_SHEETS:
        case methods.METHOD_GET_ALL_STATIC_SQL_NO_PARAMS:
        case methods.METHOD_GET_ALL_STATIC_SQL:
        case methods.METHOD_ADD_PRODUCT_SHEET:
        case methods.METHOD_ADD_STATIC_SQL:
        case methods.METHOD_EDIT_PRODUCT_SHEET:
        case methods.METHOD_EDIT_STATIC_SQL:
        case methods.METHOD_DELETE_PRODUCT_SHEET:
        case methods.METHOD_DELETE_STATIC_SQL:
        case methods.METHOD_DELETE_UPLOADED_FILE:
        case methods.METHOD_RUN_STATIC_SQL: {
            return fileResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_FILE_CATEGORIES_NO_PARAMS:
        case methods.METHOD_GET_ALL_FILE_CATEGORIES:
        case methods.METHOD_ADD_FILE_CATEGORY:
        case methods.METHOD_EDIT_FILE_CATEGORY:
        case methods.METHOD_DELETE_FILE_CATEGORY: {
            return fileCategoryResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_MAIN_FORMULAS_FOR_COMBINATION:
        case methods.METHOD_GET_MASTER_FORMULA:
        case methods.METHOD_ADD_MASTER_FORMULA:
        case methods.METHOD_EDIT_MASTER_FORMULA:
        case methods.METHOD_DELETE_MASTER_FORMULA:
        case methods.METHOD_SET_MASTER_FORMULA_POSITION:
        case methods.METHOD_GET_SYSTEM_FORMULA:
        case methods.METHOD_GET_SYSTEM_MAIN_FORMULAS_FOR_COMBINATION:
        case methods.METHOD_GET_ZONE_FORMULA:
        case methods.METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION:
        case methods.METHOD_GET_ALL_FORMULA_UNITS: {
            return formulaResponseService.processWebsocketResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_FORMULA_NOTES:
        case methods.METHOD_ADD_MASTER_FORMULA_NOTE:
        case methods.METHOD_EDIT_MASTER_FORMULA_NOTE:
        case methods.METHOD_DELETE_MASTER_FORMULA_NOTE:
        case methods.METHOD_GET_ALL_SYSTEM_FORMULA_NOTES:
        case methods.METHOD_ADD_SYSTEM_FORMULA_NOTE:
        case methods.METHOD_EDIT_SYSTEM_FORMULA_NOTE:
        case methods.METHOD_GET_ALL_ZONE_FORMULA_NOTES:
        case methods.METHOD_ADD_ZONE_FORMULA_NOTE:
        case methods.METHOD_EDIT_ZONE_FORMULA_NOTE: {
            return formulaNoteResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_IMAGES:
        case methods.METHOD_ADD_IMAGE:
        case methods.METHOD_EDIT_IMAGE:
        case methods.METHOD_DELETE_IMAGE: {
            return imageResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_IMAGE_CATEGORIES_NO_PARAMS:
        case methods.METHOD_GET_ALL_IMAGE_CATEGORIES:
        case methods.METHOD_ADD_IMAGE_CATEGORY:
        case methods.METHOD_EDIT_IMAGE_CATEGORY:
        case methods.METHOD_DELETE_IMAGE_CATEGORY: {
            return imageCategoryResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_IMPORT_TASK_STATUSES:
        case methods.METHOD_GET_ALL_IMPORT_TASK_TYPES:
        case methods.METHOD_GET_ALL_IMPORT_TASKS:
        case methods.METHOD_ADD_IMPORT_TASK:
        case methods.METHOD_ABORT_IMPORT_TASK:
        case methods.METHOD_DELETE_IMPORT_TASK:
        case methods.METHOD_DELETE_IMPORT_TASKS:
        case methods.METHOD_HIDE_IMPORT_TASK:
        case methods.METHOD_SHOW_IMPORT_TASK: {
            return importResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_ACTIVATE_ONLINE_LICENSE:
        case methods.METHOD_GET_LICENSE_AVAILABLE_PRODUCTS:
        case methods.METHOD_GET_LICENSE_INFO:
        case methods.METHOD_GET_LICENSE_ONLINE_ACTIVATION_AVAILABILITY:
        case methods.METHOD_GET_LICENSE_ONLINE_COUNTS:
        case methods.METHOD_GET_LICENSE_STATUS:
        case methods.METHOD_GET_LICENSE_UUID:
        case methods.METHOD_DELETE_LICENSE:
        case methods.METHOD_UPDATE_ONLINE_LICENSE: {
            return licenseResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_LOGIN:
        case methods.METHOD_LOGOUT:
        case methods.METHOD_IS_USER_LOGGED_IN:
        case methods.METHOD_GET_LOGGED_USER: {
            return loginResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_GLOBAL_OPTIONS:
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_OPTIONS:
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS:
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_OPTIONS:
        case methods.METHOD_SET_GLOBAL_OPTION:
        case methods.METHOD_SET_GLOBAL_OPTIONS:
        case methods.METHOD_SET_MASTER_REDLIKE_OPTION:
        case methods.METHOD_SET_MASTER_REDLIKE_OPTION_OVERWRITE:
        case methods.METHOD_SET_SYSTEM_REDLIKE_OPTION:
        case methods.METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE:
        case methods.METHOD_SET_ZONE_REDLIKE_OPTION:
        case methods.METHOD_SET_ZONE_REDLIKE_OPTION_OVERWRITE:
        case methods.METHOD_RESET_ALL_MASTER_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_MASTER_REDLIKE_OPTION:
        case methods.METHOD_RESET_ALL_SYSTEM_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_SYSTEM_REDLIKE_OPTION:
        case methods.METHOD_RESET_ALL_ZONE_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_ZONE_REDLIKE_OPTION:
        case methods.METHOD_GET_ALL_USER_OPTIONS:
        case methods.METHOD_SET_USER_OPTION:
        case methods.METHOD_SET_USER_OPTIONS: {
            return optionResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_PACKAGES:
        case methods.METHOD_ADD_PACKAGE:
        case methods.METHOD_EDIT_PACKAGE:
        case methods.METHOD_DELETE_PACKAGE:
        case methods.METHOD_GET_ALL_SYSTEM_PACKAGES:
        case methods.METHOD_EDIT_SYSTEM_PACKAGE:
        case methods.METHOD_GET_ALL_ZONE_PACKAGES:
        case methods.METHOD_EDIT_ZONE_PACKAGE: {
            return packageResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRICE_MARGINS:
        case methods.METHOD_ADD_SYSTEM_PRICE_MARGIN:
        case methods.METHOD_EDIT_SYSTEM_PRICE_MARGIN:
        case methods.METHOD_DELETE_SYSTEM_PRICE_MARGIN:
        case methods.METHOD_GET_SYSTEM_PRICE_OPTIONS:
        case methods.METHOD_SET_SYSTEM_PRICE_OPTIONS:
        case methods.METHOD_GET_SYSTEM_GENERIC_PRICE_DATA:
        case methods.METHOD_SET_SYSTEM_GENERIC_PRICE_DATA:
        case methods.METHOD_SET_SINGLE_SYSTEM_GENERIC_PRICE_DATA:
        case methods.METHOD_GET_SYSTEM_PRICE_GROUP_DATA:
        case methods.METHOD_SET_SYSTEM_PRICE_GROUP_DATA:
        case methods.METHOD_SET_SINGLE_SYSTEM_PRICE_GROUP_DATA:
        case methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS:
        case methods.METHOD_ADD_ZONE_PRICE_MARGIN:
        case methods.METHOD_EDIT_ZONE_PRICE_MARGIN:
        case methods.METHOD_DELETE_ZONE_PRICE_MARGIN:
        case methods.METHOD_GET_ZONE_PRICE_OPTIONS:
        case methods.METHOD_SET_ZONE_PRICE_OPTIONS:
        case methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA:
        case methods.METHOD_SET_ZONE_GENERIC_PRICE_DATA:
        case methods.METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA:
        case methods.METHOD_GET_ZONE_PRICE_GROUP_DATA:
        case methods.METHOD_SET_ZONE_PRICE_GROUP_DATA:
        case methods.METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA:
            return priceResponseService.processServerResponse(method, response, state);
        case methods.METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP:
        case methods.METHOD_RESET_ALL_REDLIKE_PRIVILEGES:
        case methods.METHOD_SET_REDLIKE_PRIVILEGE: {
            return privilegeResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_PRODUCTS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS:
        case methods.METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM:
        case methods.METHOD_GET_MASTER_PRODUCT_BY_ID:
        case methods.METHOD_ADD_MASTER_PRODUCT:
        case methods.METHOD_ADD_MASTER_PRODUCT_WITH_INDEX_DATA:
        case methods.METHOD_EDIT_MASTER_PRODUCT:
        case methods.METHOD_DELETE_MASTER_PRODUCT:
        case methods.METHOD_DUPLICATE_MASTER_PRODUCT:
        case methods.METHOD_GET_SYSTEM_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUP:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS:
        case methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID:
        case methods.METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_PRODUCT:
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS:
        case methods.METHOD_DELETE_SYSTEM_PRODUCT:
        case methods.METHOD_DUPLICATE_SYSTEM_PRODUCT:
        case methods.METHOD_GET_ZONE_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS:
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUP:
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP:
        case methods.METHOD_GET_ZONE_PRODUCT_BY_ID:
        case methods.METHOD_ADD_ZONE_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS:
        case methods.METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_PRODUCT:
        case methods.METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS:
        case methods.METHOD_DELETE_ZONE_PRODUCT:
        case methods.METHOD_DUPLICATE_ZONE_PRODUCT: {
            return productResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_PRODUCT:
        case methods.METHOD_ADD_SYSTEM_PRODUCT_GROUP:
        case methods.METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP:
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS:
        case methods.METHOD_DELETE_SYSTEM_PRODUCT_GROUP:
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS:
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_FOR_PRODUCT:
        case methods.METHOD_ADD_ZONE_PRODUCT_GROUP:
        case methods.METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_PRODUCT_GROUP:
        case methods.METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS:
        case methods.METHOD_DELETE_ZONE_PRODUCT_GROUP: {
            return productGroupResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS:
        case methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS_NO_PARAMS:
        case methods.METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER:
        case methods.METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER:
        case methods.METHOD_ADD_REDLIKE_USER_GROUP:
        case methods.METHOD_EDIT_REDLIKE_USER_GROUP:
        case methods.METHOD_DUPLICATE_REDLIKE_USER_GROUP:
        case methods.METHOD_DELETE_REDLIKE_USER_GROUP: {
            return redlikeUserGroupResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS:
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS:
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS:
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS_WITH_GROUPS:
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS:
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS_WITH_GROUPS:
        case methods.METHOD_ADD_MASTER_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_ADD_SYSTEM_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_ADD_ZONE_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_EDIT_MASTER_REDLIKE_USER:
        case methods.METHOD_EDIT_MASTER_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_EDIT_SYSTEM_REDLIKE_USER:
        case methods.METHOD_EDIT_SYSTEM_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_EDIT_ZONE_REDLIKE_USER:
        case methods.METHOD_EDIT_ZONE_REDLIKE_USER_WITH_PARAMS:
        case methods.METHOD_DELETE_MASTER_REDLIKE_USER:
        case methods.METHOD_DELETE_SYSTEM_REDLIKE_USER:
        case methods.METHOD_DELETE_ZONE_REDLIKE_USER:
        case methods.METHOD_GET_GENERATE_REDLIKE_ONE_TIME_ACCESS_PASSWORD: {
            return redlikeUserResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_SCRIPTS:
        case methods.METHOD_GET_ALL_SCRIPTS_NO_PARAMS:
        case methods.METHOD_START_RUN_SCRIPT: {
            return scriptResponseService.processServerResponse(method, response, state);
        }
        // TODO commit and rollback remove to server response service
        case methods.METHOD_SERVER_INIT:
        case methods.METHOD_GET_SESSION_UUID:
        case methods.METHOD_GET_SQLITE_INFO:
        case methods.METHOD_GET_VERSION: {
            return serverResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_DATA_SIZE: {
            return serverMonitoringResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_SPECTRO_TYPES:
        case methods.METHOD_GET_ALL_SPECTRO_TYPES_NO_PARAMS:
        case methods.METHOD_ADD_SPECTRO_TYPE:
        case methods.METHOD_EDIT_SPECTRO_TYPE:
        case methods.METHOD_DELETE_SPECTRO_TYPE: {
            return spectroResponseService.processWebsocketResponse(method, response, state);
        }
        case methods.METHOD_SET_ACTIVE_SYSTEM:
        case methods.METHOD_GET_ALL_SYSTEMS:
        case methods.METHOD_GET_ALL_SYSTEMS_NO_PARAMS:
        case methods.METHOD_ADD_SYSTEM:
        case methods.METHOD_DUPLICATE_SYSTEM:
        case methods.METHOD_EDIT_SYSTEM:
        case methods.METHOD_DELETE_SYSTEM: {
            return systemResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_UNITS_NO_PARAMS:
        case methods.METHOD_GET_ALL_UNITS:
        case methods.METHOD_ADD_UNIT:
        case methods.METHOD_EDIT_UNIT:
        case methods.METHOD_DELETE_UNIT: {
            return unitResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_USERS_WITH_GROUPS:
        case methods.METHOD_ADD_USER:
        case methods.METHOD_ADD_USER_WITH_USER_GROUPS:
        case methods.METHOD_EDIT_USER:
        case methods.METHOD_EDIT_USER_WITH_USER_GROUPS:
        case methods.METHOD_DELETE_USER:
        case methods.METHOD_CHANGE_USER_PASSWORD: {
            return userResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_GET_ALL_USER_GROUPS_NO_PARAMS:
        case methods.METHOD_GET_ALL_USER_GROUPS:
        case methods.METHOD_ADD_USER_GROUP:
        case methods.METHOD_EDIT_USER_GROUP:
        case methods.METHOD_DELETE_USER_GROUP: {
            return userGroupResponseService.processServerResponse(method, response, state);
        }
        case methods.METHOD_ADD_ZONE:
        case methods.METHOD_EDIT_ZONE:
        case methods.METHOD_DELETE_ZONE:
        case methods.METHOD_DUPLICATE_ZONE:
        case methods.METHOD_SET_ACTIVE_ZONE:
        case methods.METHOD_GET_ALL_ZONES_BY_SYSTEM:
        case methods.METHOD_GET_ALL_ZONES_NO_PARAMS:
        case methods.METHOD_GET_ALL_DUPLICATE_ZONES_NO_PARAMS:
        case methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY:
        case methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY_NO_PARAMS: {
            return zoneResponseService.processServerResponse(method, response, state);
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
