import * as actionConstants from "../constants/actionTypes";
import * as licenseActions from "../actions/licenseActions";
import * as licenseConstants from "../constants/license";
import * as loginActions from "../actions/loginActions";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as validationHelper from "../helpers/validationHelper";

import { Epic, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

export const licenseSuccess: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.LICENSE_SUCCESS),
        switchMap(() => {
            return [loginActions.loginIsUserLoggedIn()];
        })
    );

export const updateLicense: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.LICENSE_UPDATE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (state$.value.license.processStep) {
                case licenseConstants.LICENSE_FIRST_PROCESS_STEP: {
                    requests.push(licenseActions.getLicenseAvailableProducts());
                    break;
                }
                case licenseConstants.LICENSE_SECOND_PROCESS_STEP: {
                    requests.push(licenseActions.setStep(licenseConstants.LICENSE_THIRD_PROCESS_STEP));
                    break;
                }
                case licenseConstants.LICENSE_THIRD_PROCESS_STEP: {
                    const { data, validationList } = validationHelper.validateItem(
                        objectTypes.LICENSE,
                        modalTypes.NO_MODAL,
                        null,
                        [],
                        [],
                        state$.value.license.activeLicense,
                        Object.keys(state$.value.license.activeLicense).map((item) => item)
                    );

                    if (validationList.length === 0) {
                        requests.push(licenseActions.activateOnlineLicense(data));
                    }
                    break;
                }
                default:
                    break;
            }

            return requests;
        })
    );
