import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { ReflectanceData } from "./reflectanceData";
import { SpectroType } from "./spectroType";
import { TableHeading } from "./tableHeading";

export class ColorData {
    id: number;
    reflData: Array<ReflectanceData>;
    xyz: Array<number>;
    l: number | null;
    a: number | null;
    b: number | null;
    c: number | null;
    h: number | null;
    spectroTypeId: number;
    spectroTypeName: string;

    constructor(
        id: number,
        reflData: Array<ReflectanceData>,
        xyz: Array<number>,
        l: number | null,
        a: number | null,
        b: number | null,
        c: number | null,
        h: number | null,
        spectroTypeId: number,
        spectroTypeName: string
    ) {
        this.id = id;
        this.reflData = reflData;
        this.xyz = xyz;
        this.l = l;
        this.a = a;
        this.b = b;
        this.c = c;
        this.h = h;
        this.spectroTypeId = spectroTypeId;
        this.spectroTypeName = spectroTypeName;
    }
}

export function mapColorData(
    data: Record<string, any>,
    reflData: Array<ReflectanceData>,
    lchData: Array<number>
): ColorData | null {
    try {
        return new ColorData(
            data.id,
            reflData,
            data.xyz,
            data.l,
            data.a,
            data.b,
            lchData[0],
            lchData[1],
            data.spectroTypeId,
            data.spectroTypeName
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyColorDataFromSpectroType(data: SpectroType, reflData: Array<ReflectanceData>): any {
    return {
        [propertyConstants.PROPERTY_ID]: data[propertyConstants.PROPERTY_ID] ? data[propertyConstants.PROPERTY_ID] : "",
        [propertyConstants.PROPERTY_REFLECTANCE_DATA]: reflData,
        [propertyConstants.PROPERTY_XYZ]: [],
        [propertyConstants.PROPERTY_SPECTRO_L]: null,
        [propertyConstants.PROPERTY_SPECTRO_A]: null,
        [propertyConstants.PROPERTY_SPECTRO_B]: null,
        [propertyConstants.PROPERTY_SPECTRO_C]: null,
        [propertyConstants.PROPERTY_SPECTRO_H]: null,
        [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]: data[propertyConstants.PROPERTY_ID]
            ? data[propertyConstants.PROPERTY_ID]
            : null,
        [propertyConstants.PROPERTY_SPECTRO_TYPE_NAME]: data[propertyConstants.PROPERTY_NAME]
            ? data[propertyConstants.PROPERTY_NAME]
            : ""
    };
}

export function createEmptyColorData(id: any): any {
    return {
        [propertyConstants.PROPERTY_ID]: id ? id : "",
        [propertyConstants.PROPERTY_REFLECTANCE_DATA]: [],
        [propertyConstants.PROPERTY_XYZ]: [],
        [propertyConstants.PROPERTY_SPECTRO_L]: null,
        [propertyConstants.PROPERTY_SPECTRO_A]: null,
        [propertyConstants.PROPERTY_SPECTRO_B]: null,
        [propertyConstants.PROPERTY_SPECTRO_C]: null,
        [propertyConstants.PROPERTY_SPECTRO_H]: null,
        [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]: id ? id : "",
        [propertyConstants.PROPERTY_SPECTRO_TYPE_NAME]: ""
    };
}

export function updateColorDataLab(
    colorData: Record<string, any>,
    labData: Array<number>,
    lchData: Array<number>
): any {
    try {
        return new ColorData(
            colorData.id,
            colorData.reflData,
            colorData.xyz,
            labData[0],
            labData[1],
            labData[2],
            lchData[0],
            lchData[1],
            colorData.spectroTypeId,
            colorData.spectroTypeName
        );
    } catch (e) {
        return null;
    }
}

export function updateColorDataReflData(colorData: Record<string, any>, reflData: Array<ReflectanceData>): any {
    try {
        return new ColorData(
            colorData.id,
            reflData,
            colorData.xyz,
            colorData.l,
            colorData.a,
            colorData.b,
            colorData.c,
            colorData.h,
            colorData.spectroTypeId,
            colorData.spectroTypeName
        );
    } catch (e) {
        return null;
    }
}

// Reflectance + Wavelength table
export function generateReflectanceWavelengthData(reflData: Array<ReflectanceData>): Array<any> {
    const data: Array<any> = [];

    for (const item of reflData) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_WAVELENGTH],
            [propertyConstants.PROPERTY_WAVELENGTH]: item[propertyConstants.PROPERTY_WAVELENGTH],
            [propertyConstants.PROPERTY_REFLECTANCE_DATA]: item[propertyConstants.PROPERTY_VALUE]
        });
    }

    return data;
}

export function generateReflectanceWavelengthDataHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_REFLECTANCE_DATA,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_REFLECTANCE_DATA),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_REFLECTANCE_DATA),
            editableList.includes(propertyConstants.PROPERTY_REFLECTANCE_DATA),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_REFLECTANCE_DATA),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_REFLECTANCE_DATA),
            requiredList.includes(propertyConstants.PROPERTY_REFLECTANCE_DATA),
            [],
            widthOption?.[propertyConstants.PROPERTY_REFLECTANCE_DATA] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_WAVELENGTH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_WAVELENGTH),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_WAVELENGTH),
            editableList.includes(propertyConstants.PROPERTY_WAVELENGTH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_WAVELENGTH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_WAVELENGTH),
            requiredList.includes(propertyConstants.PROPERTY_WAVELENGTH),
            [],
            widthOption?.[propertyConstants.PROPERTY_WAVELENGTH] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
