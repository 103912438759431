import * as dataTypeConstants from "../../constants/dataTypeConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as menuConstants from "../../constants/menuConstants";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as tableHelper from "../../helpers/tableHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Product, generateProductData, mapParentChildProduct, mapProduct } from "../../types/product";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

// TODO proc neni METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUPS
// TODO proc je METHOD_ADD_ZONE_PRODUCT
export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_PRODUCTS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);

                if (product) {
                    productList.push(product);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_PRODUCT, productList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_SYSTEM_PRODUCT, productList, response.data.count)
            ];
        }
        case methods.METHOD_GET_MASTER_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const product = mapProduct(response.data);
            const requests: Array<any> = [];
            let activeData: Array<any> = [];

            if (product && state.navigation.nextModal) {
                if (state.navigation.nextModal.type === modalTypes.MODAL_PRODUCTS_DUPLICATE) {
                    activeData = generateProductData(
                        objectTypes.MASTER_PRODUCT_DUPLICATE,
                        [product],
                        dataTypeConstants.MASTER_PRODUCT_DATA_TYPE_LIST
                    );
                } else {
                    activeData = generateProductData(
                        objectTypes.MASTER_PRODUCT_DUPLICATE,
                        [product],
                        dataTypeConstants.MASTER_PRODUCT_DATA_TYPE_LIST
                    );
                }

                if (activeData) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: activeData[0]
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(
                    state.navigation.activeScene === navigationTypes.MASTER_PRODUCTS_SCENE
                        ? objectTypes.MASTER_PRODUCT
                        : objectTypes.MASTER_PRODUCT_IN_COLOR,
                    product ? [product] : []
                )
            ];
        }
        case methods.METHOD_ADD_MASTER_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_ADD)
            ];
        }
        case methods.METHOD_ADD_MASTER_PRODUCT_WITH_INDEX_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];

            if (response.data.index !== undefined) {
                const product = mapProduct(response.data.data);
                const rowCount = generalHelper.getObjectFromDictionaryByKey(
                    state.login.options,
                    optionsConstants.OPTION_TABLE_ROW_COUNT,
                    menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
                );
                const newPage = tableHelper.getNewTablePage(response.data.index + 1, rowCount);

                requests.push(
                    universalObjectActions.setOffset(
                        objectTypes.MASTER_PRODUCT,
                        tableHelper.getNewTableOffset(newPage, rowCount),
                        newPage
                    ),
                    universalObjectActions.updateActiveItems(objectTypes.MASTER_PRODUCT, product ? [product] : [])
                );
            }

            return [...requests, navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_ADD)];
        }
        case methods.METHOD_EDIT_MASTER_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PRODUCTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_EDIT));
            } else {
                const product = mapProduct(response.data);

                if (product) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_PRODUCT, [product]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.MASTER_PRODUCT, [])
            ];
        }
        case methods.METHOD_GET_SYSTEM_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const product = mapProduct(response.data);

            return [
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_PRODUCT_IN_COLOR, product ? [product] : [])
            ];
        }
        case methods.METHOD_DUPLICATE_MASTER_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.MASTER_PRODUCT, []),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_DUPLICATE)
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_PRODUCT,
                    productList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
                    productList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUP: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item.product);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS,
                    productList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_SYSTEM_PRODUCT, productList, response.data.count)
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapParentChildProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE_PRODUCT, productList, response.data.count)
            ];
        }
        case methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            const product = mapProduct(response.data);
            const requests: Array<any> = [];
            let activeData: Array<any> = [];

            if (product) {
                productList.push(product);

                if (state.navigation.nextModal) {
                    if (state.navigation.nextModal.type === modalTypes.MODAL_PRODUCTS_DUPLICATE) {
                        activeData = generateProductData(
                            objectTypes.SYSTEM_PRODUCT_DUPLICATE,
                            [product],
                            dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST
                        );
                    } else {
                        activeData = generateProductData(
                            objectTypes.SYSTEM_PRODUCT_DUPLICATE,
                            [product],
                            dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST
                        );
                    }

                    if (activeData) {
                        requests.push(
                            navigationActions.navigationOpenModal(
                                state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                                {
                                    ...state.navigation.nextModal.params,
                                    data: activeData[0]
                                }
                            ),
                            navigationActions.setNextModal(null)
                        );
                    }
                }
            }

            return [...requests, universalObjectActions.updateActiveItems(objectTypes.SYSTEM_PRODUCT, productList)];
        }
        case methods.METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DUPLICATE_SYSTEM_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_PRODUCT, []),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_DUPLICATE)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PRODUCTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_EDIT));
            } else {
                const product = mapProduct(response.data);

                if (product) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PRODUCT, [product]));
                }
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_PRODUCT, [])
            ];
        }
        case methods.METHOD_GET_ZONE_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const product = mapProduct(response.data);

            return [universalObjectActions.setActiveItems(objectTypes.ZONE_PRODUCT_IN_COLOR, product ? [product] : [])];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_PRODUCT,
                    productList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS,
                    productList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUP: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            let product: Product | null;

            for (const item of response.data.data) {
                product = mapProduct(item.product);
                if (product) {
                    productList.push(product);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
                    productList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ZONE_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const productList: Array<Product> = [];
            const product = mapProduct(response.data);
            const requests: Array<any> = [];
            let activeData: Array<any> = [];

            if (product) {
                productList.push(product);

                if (state.navigation.nextModal) {
                    if (state.navigation.nextModal.type === modalTypes.MODAL_PRODUCTS_DUPLICATE) {
                        activeData = generateProductData(
                            objectTypes.SYSTEM_PRODUCT_DUPLICATE,
                            [product],
                            dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST
                        );
                    } else {
                        activeData = generateProductData(
                            objectTypes.SYSTEM_PRODUCT_DUPLICATE,
                            [product],
                            dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST
                        );
                    }

                    if (activeData) {
                        requests.push(
                            navigationActions.navigationOpenModal(
                                state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                                {
                                    ...state.navigation.nextModal.params,
                                    data: activeData[0]
                                }
                            ),
                            navigationActions.setNextModal(null)
                        );
                    }
                }
            }

            return [...requests, universalObjectActions.updateActiveItems(objectTypes.ZONE_PRODUCT, productList)];
        }
        case methods.METHOD_ADD_ZONE_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_ADD)
            ];
        }
        case methods.METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DUPLICATE_ZONE_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.ZONE_PRODUCT, []),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_DUPLICATE)
            ];
        }
        case methods.METHOD_EDIT_ZONE_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PRODUCTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PRODUCTS_EDIT));
            } else {
                const product = mapProduct(response.data);

                if (product) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_PRODUCT, [product]));
                }
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.ZONE_PRODUCT, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
