import actionHistoryReducer, { ActionHistoryState } from "./actionHistoryReducer";
import barcodeReducer, { BarcodeState } from "./barcodeReducer";
import baseInProductPackageReducer, { BaseInProductPackageState } from "./baseInProductPackageReducer";
import baseInProductReducer, { BaseInProductState } from "./baseInProductReducer";
import baseReducer, { BaseState } from "./baseReducer";
import colorReducer, { ColorState } from "./colorReducer";
import colorantBatchReducer, { ColorantBatchState } from "./colorantBatchReducer";
import colorantPackageReducer, { ColorantPackageState } from "./colorantPackageReducer";
import colorantReducer, { ColorantState } from "./colorantReducer";
import companyReducer, { CompanyState } from "./companyReducer";
import currencyReducer, { CurrencyState } from "./currencyReducer";
import databaseReducer, { DatabaseState } from "./databaseReducer";
import databaseSystemZoneReducer, { DatabaseSystemZoneState } from "./databaseSystemZoneReducer";
import exportReducer, { ExportState } from "./exportReducer";
import fandeckReducer, { FandeckState } from "./fandeckReducer";
import fileCategoryReducer, { FileCategoryState } from "./fileCategoryReducer";
import fileReducer, { FileState } from "./fileReducer";
import formulaNoteReducer, { FormulaNoteState } from "./formulaNoteReducer";
import imageCategoryReducer, { ImageCategoryState } from "./imageCategoryReducer";
import imageReducer, { ImageState } from "./imageReducer";
import importReducer, { ImportState } from "./importReducer";
import licenseReducer, { LicenseState } from "./licenseReducer";
import loginReducer, { LoginState } from "./loginReducer";
import navigationReducer, { NavigationState } from "./navigationReducer";
import optionsReducer, { OptionsState } from "./optionsReducer";
import packageReducer, { PackageState } from "./packageReducer";
import priceReducer, { PriceState } from "./priceReducer";
import privilegeReducer, { PrivilegesState } from "./privilegeReducer";
import productGroupReducer, { ProductGroupState } from "./productGroupReducer";
import productReducer, { ProductState } from "./productReducer";
import scriptReducer, { ScriptState } from "./scriptReducer";
import serverMonitoringReducer, { ServerMonitoringState } from "./serverMonitoringReducer";
import serverReducer, { ServerState } from "./serverReducer";
import softwareReducer, { SoftwareState } from "./softwareReducer";
import spectroTypeReducer, { SpectroTypeState } from "./spectroTypeReducer";
import systemReducer, { SystemState } from "./systemReducer";
import unitReducer, { UnitState } from "./unitReducer";
import userGroupReducer, { UserGroupState } from "./userGroupReducer";
import userReducer, { UserState } from "./userReducer";
import zoneReducer, { ZoneState } from "./zoneReducer";

export type AppState = {
    license: LicenseState;
    login: LoginState;
    navigation: NavigationState;
    server: ServerState;
    software: SoftwareState;
    actionHistory: ActionHistoryState;
    barcode: BarcodeState;
    base: BaseState;
    baseInProduct: BaseInProductState;
    baseInProductPackage: BaseInProductPackageState;
    color: ColorState;
    colorant: ColorantState;
    colorantBatch: ColorantBatchState;
    colorantPackage: ColorantPackageState;
    company: CompanyState;
    currency: CurrencyState;
    database: DatabaseState;
    databaseSystemZone: DatabaseSystemZoneState;
    export: ExportState;
    fandeck: FandeckState;
    file: FileState;
    fileCategory: FileCategoryState;
    formulaNote: FormulaNoteState;
    image: ImageState;
    imageCategory: ImageCategoryState;
    import: ImportState;
    options: OptionsState;
    package: PackageState;
    price: PriceState;
    privileges: PrivilegesState;
    product: ProductState;
    productGroup: ProductGroupState;
    script: ScriptState;
    serverMonitoring: ServerMonitoringState;
    spectroType: SpectroTypeState;
    system: SystemState;
    unit: UnitState;
    user: UserState;
    userGroup: UserGroupState;
    zone: ZoneState;
};

const reducers = {
    license: licenseReducer,
    login: loginReducer,
    navigation: navigationReducer,
    server: serverReducer,
    software: softwareReducer,
    actionHistory: actionHistoryReducer,
    barcode: barcodeReducer,
    base: baseReducer,
    baseInProduct: baseInProductReducer,
    baseInProductPackage: baseInProductPackageReducer,
    color: colorReducer,
    colorant: colorantReducer,
    colorantBatch: colorantBatchReducer,
    colorantPackage: colorantPackageReducer,
    company: companyReducer,
    currency: currencyReducer,
    database: databaseReducer,
    databaseSystemZone: databaseSystemZoneReducer,
    export: exportReducer,
    fandeck: fandeckReducer,
    file: fileReducer,
    fileCategory: fileCategoryReducer,
    formulaNote: formulaNoteReducer,
    options: optionsReducer,
    image: imageReducer,
    imageCategory: imageCategoryReducer,
    import: importReducer,
    package: packageReducer,
    price: priceReducer,
    privileges: privilegeReducer,
    product: productReducer,
    productGroup: productGroupReducer,
    script: scriptReducer,
    serverMonitoring: serverMonitoringReducer,
    spectroType: spectroTypeReducer,
    system: systemReducer,
    unit: unitReducer,
    user: userReducer,
    userGroup: userGroupReducer,
    zone: zoneReducer
};

export default reducers;
