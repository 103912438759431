import * as generalHelper from "../../../../helpers/generalHelper";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as requestHelper from "../../../../helpers/requestHelper";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
};

type Props = OwnProps & ContainerProps;

export class TableRestoreButton extends Component<Props> {
    handleRestoreClick = (): void => {
        if (this.props.objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) {
            this.restoreAllItems();
        } else {
            this.restoreParentItem();
        }
    };

    isVisible = (): boolean => {
        const { dataPrivileges, objectType } = this.props;

        if (dataPrivileges?.canUpdate && objectTypes.RESTORE_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { activeList, objectType } = this.props;

        if (
            activeList.length &&
            (generalHelper.isValueChangedBasedOnSource(objectType, activeList[0]) ||
                objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE)
        ) {
            return true;
        }

        return false;
    };

    restoreAllItems = (): void => {
        this.props.resetAllValues(this.props.objectType);
    };

    restoreParentItem = (): void => {
        const { objectType, activeList, editItem, resetValue } = this.props;

        const idProperty = requestHelper.getObjectIdProperty(objectType);

        if (idProperty) {
            for (const item of activeList) {
                if (objectTypeHelper.isObjectTypeRedlikeOption(objectType)) {
                    resetValue(objectType, {
                        [idProperty]: item[propertyConstants.PROPERTY_ID]
                    });
                } else {
                    editItem(objectType, {
                        [idProperty]: item[propertyConstants.PROPERTY_ID],
                        data: {
                            value: this.getParentValue(item)
                        }
                    });
                }
            }
        }
    };

    getButtonClassname = (): string => {
        let className = "btn-md btn-info btn-long";

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    getParentValue = (item: Record<string, any>): string => {
        const { objectType } = this.props;

        if (objectType === objectTypes.SYSTEM_FORMULA_NOTE && item[propertyConstants.PROPERTY_VALUE_MASTER]) {
            return item[propertyConstants.PROPERTY_VALUE_MASTER];
        }
        if (objectType === objectTypes.ZONE_FORMULA_NOTE && item[propertyConstants.PROPERTY_VALUE_SYSTEM]) {
            return item[propertyConstants.PROPERTY_VALUE_SYSTEM];
        }

        return item[propertyConstants.PROPERTY_VALUE];
    };

    getButtonTitle = (): string => {
        if (this.props.objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) {
            return translate("general.setAllToDefaultValues");
        }

        return translate("general.restoreSelectedValue");
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className={this.getButtonClassname()} onClick={(): void => this.handleRestoreClick()}>
                    {this.getButtonTitle()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getButtonClassname()}>{this.getButtonTitle()}</button>;
        }

        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    editItem(objectType: objectTypes.ObjectType, params: Record<string, any>): void;
    resetAllValues(objectType: objectTypes.ObjectType): void;
    resetValue(objectType: objectTypes.ObjectType, params: Record<string, any>): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    editItem: (objectType: objectTypes.ObjectType, params: Record<string, any>): any =>
        dispatch(universalObjectActions.editItem(objectType, false, params)),
    resetAllValues: (objectType: objectTypes.ObjectType): any =>
        dispatch(universalObjectActions.resetAllValues(objectType)),
    resetValue: (objectType: objectTypes.ObjectType, params: Record<string, any>): any =>
        dispatch(universalObjectActions.resetValue(objectType, params))
});

export const TableRestoreButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableRestoreButton);
