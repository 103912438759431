import * as actionConstants from "../constants/actionTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverMethods from "../constants/serverMethods";

import { BaseInProduct } from "../types/baseInProduct";
import { BaseInProductPackage } from "../types/baseInProductPackage";
import { Package } from "../types/package";
import { Product } from "../types/product";
import { ServerRequest } from "./websocketActions";

// TODO to be deleted or refactored to universal action when wizards are alive again

export function addSystemBaseWithParams(baseData: Array<any>, zoneIds: Array<number>): ServerRequest {
    const bases = [];
    for (const item of baseData) {
        bases.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_BASE_WITH_PARAMS,
            params: {
                baseData: bases,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_BASE_WITH_PARAMS
    };
}

export function addZoneBaseWithParams(baseData: Array<any>): ServerRequest {
    const bases = [];
    for (const item of baseData) {
        bases.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_BASE_WITH_PARAMS,
            params: {
                baseData: bases
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_BASE_WITH_PARAMS
    };
}

export function addSystemColorantWithParams(
    colorantData: Array<any>,
    packageIds: Array<number>,
    zoneIds: Array<number>
): ServerRequest {
    const colorants = [];
    for (const item of colorantData) {
        colorants.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS,
            params: {
                colorantData: colorants,
                packageIds,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS
    };
}

export function addZoneColorantWithParams(
    colorantData: Array<any>,
    packageIds: Array<number>,
    zoneIds: Array<number>
): ServerRequest {
    const colorants = [];
    for (const item of colorantData) {
        colorants.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_COLORANT_WITH_PARAMS,
            params: {
                colorantData: colorants,
                packageIds,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_COLORANT_WITH_PARAMS
    };
}

export function addSystemColorantPackageWithParams(
    packageData: Array<any>,
    colorantIds: Array<number>,
    zoneIds: Array<number>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS,
            params: {
                packageData,
                colorantIds,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS
    };
}

export function addZoneColorantPackageWithParams(packageData: Array<any>, colorantIds: Array<number>): ServerRequest {
    const packages = [];
    for (const item of packageData) {
        packages.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            [propertyConstants.PROPERTY_PARENT_ID]: item[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID],
            data: {}
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS,
            params: {
                packageData: packages,
                colorantIds
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS
    };
}

export function addSystemFandeckWithParams(fandeckData: Array<any>, zoneIds: Array<number>): ServerRequest {
    const fandecks = [];
    for (const item of fandeckData) {
        fandecks.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS,
            params: {
                fandeckData: fandecks,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS
    };
}

export function addZoneFandeckWithParams(fandeckData: Array<any>, zoneIds: Array<number>): ServerRequest {
    const fandecks = [];
    for (const item of fandeckData) {
        fandecks.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_FANDECK_WITH_PARAMS,
            params: {
                fandeckData: fandecks,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_FANDECK_WITH_PARAMS
    };
}

export function addSystemProductWithParams(
    productData: Array<Product>,
    baseInProductData: Array<BaseInProduct>,
    packageIds: Array<Package>,
    zoneIds: Array<number>,
    params: Record<string, any>
): ServerRequest {
    const products = [];
    for (const item of productData) {
        products.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_PARENT_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    const baseInProducts = [];
    for (const item of baseInProductData) {
        baseInProducts.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {}
        });
    }

    const packages = [];
    for (const item of packageIds) {
        packages.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {}
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS,
            params: {
                productData: products,
                baseInProductData: baseInProducts,
                packageData: packages,
                inheritBases: params.inherited ?? false,
                zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS
    };
}

export function editSystemProductWithParams(productData: Product, productGroupIds: Array<number>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS,
            params: {
                productId: productData[propertyConstants.PROPERTY_ID],
                productGroupIds: productGroupIds,
                data: {
                    [propertyConstants.PROPERTY_NAME]: productData[propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_CODE]: productData[propertyConstants.PROPERTY_CODE],
                    [propertyConstants.PROPERTY_TMC_NAME]: productData[propertyConstants.PROPERTY_TMC_NAME],
                    [propertyConstants.PROPERTY_MORE_INFO]: productData[propertyConstants.PROPERTY_MORE_INFO],
                    [propertyConstants.PROPERTY_PRIORITY]: productData[propertyConstants.PROPERTY_PRIORITY]
                }
            }
        },
        method: serverMethods.METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS
    };
}

export function addZoneProductWithParams(
    productData: Array<Product>,
    baseInProductData: Array<BaseInProduct>,
    packageIds: Array<Package>,
    params: Record<string, any>
): ServerRequest {
    const products = [];
    for (const item of productData) {
        products.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_PARENT_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }

    const baseInProducts = [];
    for (const item of baseInProductData) {
        baseInProducts.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {}
        });
    }

    const packages = [];
    for (const item of packageIds) {
        packages.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {}
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS,
            params: {
                productData: products,
                baseInProductData: baseInProducts,
                packageData: packages,
                inheritBases: params.inherited ?? false,
                inheritPackages: params.inherited ?? false
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS
    };
}

export function editZoneProductWithParams(productData: Product, productGroupIds: Array<number>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS,
            params: {
                productId: productData[propertyConstants.PROPERTY_ID],
                productGroupIds: productGroupIds,
                data: {
                    [propertyConstants.PROPERTY_NAME]: productData[propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_CODE]: productData[propertyConstants.PROPERTY_CODE],
                    [propertyConstants.PROPERTY_TMC_NAME]: productData[propertyConstants.PROPERTY_TMC_NAME],
                    [propertyConstants.PROPERTY_MORE_INFO]: productData[propertyConstants.PROPERTY_MORE_INFO],
                    [propertyConstants.PROPERTY_PRIORITY]: productData[propertyConstants.PROPERTY_PRIORITY]
                }
            }
        },
        method: serverMethods.METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS
    };
}

export function addSystemBaseInProductWithParams(
    baseInProductIds: Array<number>,
    productIds: Array<number>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS,
            params: {
                baseInProductIds: baseInProductIds,
                productIds: productIds,
                autoInsert: true
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS
    };
}

export function addZoneBaseInProductWithParams(
    baseInProductIds: Array<number>,
    productIds: Array<number>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS,
            params: {
                baseInProductIds: baseInProductIds,
                productIds: productIds,
                autoInsert: true
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS
    };
}

export function addSystemBaseInProductPackageWithParams(
    packageData: Array<Package>,
    baseInProductIds: Array<number>,
    zoneIds: Array<number>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS,
            params: {
                packageData: packageData,
                baseInProductIds: baseInProductIds,
                zoneIds: zoneIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS
    };
}

export function addZoneBaseInProductPackageWithParams(
    packageData: Array<BaseInProductPackage>,
    baseInProductIds: Array<number>
): ServerRequest {
    const packages = [];
    for (const item of packageData) {
        packages.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            [propertyConstants.PROPERTY_PARENT_ID]: item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID],
            data: {}
        });
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS,
            params: {
                packageData: packages,
                baseInProductIds: baseInProductIds
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS
    };
}

export function addSystemProductGroupWithParams(productIds: Array<number>, data: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS,
            params: {
                data,
                productIds: productIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS
    };
}

export function editSystemProductGroupWithParams(
    productGroupId: number,
    productIds: Array<number>,
    data: Record<string, any>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS,
            params: {
                productGroupId: productGroupId,
                productIds: productIds,
                data
            }
        },
        method: serverMethods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS
    };
}

export function addZoneProductGroupWithParams(productGroupData: Array<any>): ServerRequest {
    const productGroups = [];
    for (const item of productGroupData) {
        productGroups.push({
            [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
            data: {
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_NAME]
            }
        });
    }
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS,
            params: {
                data: productGroups
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS
    };
}

export function editZoneProductGroupWithParams(
    productGroupId: number,
    productIds: Array<number>,
    data: Record<string, any>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS,
            params: {
                productGroupId: productGroupId,
                productIds: productIds,
                data
            }
        },
        method: serverMethods.METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS
    };
}

export function addMasterRedlikeUserWithParams(userGroupIds: Array<number>, data: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_MASTER_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_ADD_MASTER_REDLIKE_USER_WITH_PARAMS
    };
}

export function editMasterRedlikeUserWithParams(
    userId: number,
    userGroupIds: Array<number>,
    data: Record<string, any>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_MASTER_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userId: userId,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_EDIT_MASTER_REDLIKE_USER_WITH_PARAMS
    };
}

export function addSystemRedlikeUserWithParams(userGroupIds: Array<number>, data: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_SYSTEM_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_ADD_SYSTEM_REDLIKE_USER_WITH_PARAMS
    };
}

export function editSystemRedlikeUserWithParams(
    userId: number,
    userGroupIds: Array<number>,
    data: Record<string, any>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_SYSTEM_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userId: userId,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_EDIT_SYSTEM_REDLIKE_USER_WITH_PARAMS
    };
}

export function addZoneRedlikeUserWithParams(userGroupIds: Array<number>, data: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ADD_ZONE_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_ADD_ZONE_REDLIKE_USER_WITH_PARAMS
    };
}

export function editZoneRedlikeUserWithParams(
    userId: number,
    userGroupIds: Array<number>,
    data: Record<string, any>
): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_EDIT_ZONE_REDLIKE_USER_WITH_PARAMS,
            params: {
                data,
                userId: userId,
                userGroupIds: userGroupIds
            }
        },
        method: serverMethods.METHOD_EDIT_ZONE_REDLIKE_USER_WITH_PARAMS
    };
}
