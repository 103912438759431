import * as actionConstants from "../constants/actionTypes";
import * as serverMethods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export function setActiveSystemCurrency(currencyId: number): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_SET_ACTIVE_SYSTEM_CURRENCY,
            params: {
                currencyId: currencyId
            }
        },
        method: serverMethods.METHOD_SET_ACTIVE_SYSTEM_CURRENCY
    };
}
