import * as colorHelper from "../../../helpers/colorHelper";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";

import { Barcode } from "../../../types/barcode";
import ReactTooltip from "react-tooltip";
import { TableHeading } from "../../../types/tableHeading";

type OwnProps = {
    className: string;
    objectType: objectTypes.ObjectType;
    headings: Array<TableHeading>;
    data: any;
};

type Props = OwnProps;

type State = {
    tooltipOn: boolean;
    tooltipText: string;
};

export class TextPreview extends Component<Props> {
    state: State = {
        tooltipOn: false,
        tooltipText: ""
    };

    handleMouseEnter = (property: propertyConstants.Property): void => {
        const tooltipProperty = propertyHelper.getFormattedMoreInfoProperty(property);
        const text = tooltipProperty && this.props.data?.[tooltipProperty] ? this.props.data[tooltipProperty] : "";

        if (text) {
            this.setState({
                tooltipOn: true,
                tooltipText: text
            });
        }
    };

    handleMouseLeave = (): void => {
        this.setState({
            tooltipOn: false,
            tooltipText: ""
        });
    };

    getTooltip = (): JSX.Element | null => {
        if (this.state.tooltipOn && this.state.tooltipText !== "") {
            return (
                <ReactTooltip id="modal-tooltip" className="tooltip notes-tooltip" delayShow={500} place="top">
                    <span>{this.state.tooltipText}</span>
                </ReactTooltip>
            );
        }

        return null;
    };

    getPropertyText = (data: Record<string, any>, heading: TableHeading): any => {
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const propertyType = heading[tableConstants.TABLE_HEADING_TYPE];

        // Displayed value needs to be formatted
        let propertyValue = data[property] ?? null;

        if (propertyType === tableConstants.TABLE_TYPE_DATE) {
            propertyValue = formattingHelper.formatDateTime(propertyValue);
        }

        if (propertyType === tableConstants.TABLE_TYPE_SELECT && property !== propertyConstants.PROPERTY_STATUS) {
            const option = generalHelper.getObjectByKey(
                heading[tableConstants.TABLE_HEADING_ENUM],
                propertyValue?.toString()
            );
            propertyValue = option?.value ?? null;
        }

        if (propertyHelper.isPropertyBarcodes(property) && Array.isArray(propertyValue)) {
            propertyValue = propertyValue.map((item: Barcode) => item[propertyConstants.PROPERTY_BARCODE]).join(", ");
        }

        // If there is no valid value, do not display it
        if (propertyValue === null || propertyValue === "") {
            return <span className="no-information">[{translationHelper.getEmptyPropertyTranslation(property)}]</span>;
        }

        // Some properties needs to be displayed differently (2 properties together, icons)
        switch (property) {
            case propertyConstants.PROPERTY_BASE_AMOUNT: {
                return (
                    <div key={property} className="">
                        {data[propertyConstants.PROPERTY_BASE_AMOUNT]}
                        {data[propertyConstants.PROPERTY_IS_GRAVIMETRIC] ? " g" : " ml"}
                    </div>
                );
            }
            case propertyConstants.PROPERTY_STATUS: {
                return (
                    <div key={property} className="status-container">
                        {colorHelper.getFormulaStatus(data[propertyConstants.PROPERTY_STATUS], true, true)}
                    </div>
                );
            }
        }

        // Display property value with tooltip
        if (propertyHelper.getFormattedMoreInfoProperty(property) !== null) {
            return <span className="tooltip-information">{propertyValue}</span>;
        }

        if (
            propertyType === tableConstants.TABLE_TYPE_TEXT &&
            this.props.objectType === objectTypes.USER_ACTION_HISTORY
        ) {
            return <textarea className="slim-scroll" value={propertyValue} readOnly />;
        }

        // Display property value without tooltip
        return <span>{propertyValue}</span>;
    };

    getVisibleNoDataRow = (property: propertyConstants.Property): JSX.Element => {
        const { objectType } = this.props;

        switch (property) {
            case propertyConstants.PROPERTY_LICENSE_STATUS:
            case propertyConstants.PROPERTY_LICENSE_IDENTIFICATION: {
                return <h3 key={property}>{translationHelper.getPropertyTranslation(objectType, property)}</h3>;
            }
            case propertyConstants.PROPERTY_DIVIDING_LINE: {
                return <div className="modal-line" key={property} />;
            }
            default:
                return (
                    <div className="row disabled" key={property}>
                        {this.getPropertyTitle(property)}
                    </div>
                );
        }
    };

    getPropertyTitle = (property: propertyConstants.Property): JSX.Element => {
        const { objectType } = this.props;
        let title;

        switch (property) {
            case propertyConstants.PROPERTY_BASE_AMOUNT:
                title = translationHelper.getFormulaAmountTranslation(
                    this.props.data[propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT]
                );
                break;
            default:
                title = translationHelper.getPropertyTranslation(objectType, property);
                break;
        }

        return <span className="property-title">{title}:</span>;
    };

    getRows = (): Array<JSX.Element> => {
        const { headings, data } = this.props;
        const rowList: Array<JSX.Element> = [];

        for (const heading of headings) {
            const property = heading[tableConstants.TABLE_HEADING_NAME];

            if (data?.[property] !== undefined && heading[tableConstants.TABLE_HEADING_VISIBILITY]) {
                rowList.push(
                    <div className="row" key={property}>
                        {this.getPropertyTitle(property)}
                        {this.getTooltip()}
                        <span
                            style={{ whiteSpace: "pre-wrap" }}
                            className="property-text"
                            onMouseEnter={(): void => this.handleMouseEnter(property)}
                            onMouseLeave={(): void => this.handleMouseLeave()}
                            data-for="modal-tooltip"
                            data-tip
                        >
                            {this.getPropertyText(data, heading)}
                        </span>
                    </div>
                );
            } else if (heading[tableConstants.TABLE_HEADING_VISIBILITY]) {
                rowList.push(this.getVisibleNoDataRow(property));
            }
        }

        return rowList;
    };

    render(): JSX.Element {
        return <div className={this.props.className + " slim-scroll"}>{this.getRows()}</div>;
    }
}
