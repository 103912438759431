import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterProductBaseTable } from "./tables/MasterProductBaseTable";
import { MasterProductTable } from "./tables/MasterProductTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterProductSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

export const MasterProductScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableProducts, setTableProducts] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.productTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableProducts(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.productTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.productTableConstant]);

    const [tableBases, setTableBases] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.baseInProductTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableBases(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.baseInProductTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.baseInProductTableConstant]);

    const getProductsTable = (): JSX.Element | null => {
        if (tableProducts) {
            return (
                <MasterProductTable
                    tableConstant={props.productTableConstant}
                    objectType={objectTypes.MASTER_PRODUCT}
                    dataPrivileges={tableProducts}
                    loading={props.productLoading}
                    buttonLoading={props.productButtonLoading}
                    fileCategoryList={props.fileCategoryList}
                    allList={props.productList}
                    activeList={props.productActiveList}
                    columnOrder={props.productColumnOrder}
                    columnVisibility={props.productColumnVisibility}
                    columnWidth={props.productColumnWidth}
                    offset={props.productOffset}
                    page={props.productPage}
                    rowCount={props.productRowCount}
                    rowCountCustom={props.productRowCountCustom}
                    search={props.productSearch}
                    searchParams={props.productSearchParams}
                    showFilterRow={props.productShowFilterRow}
                    showGlobalSearch={props.productShowGlobalSearch}
                    sortingAsc={props.productSortingAsc}
                    sortingCriterion={props.productSortingCriterion}
                    totalCount={props.productTotalCount}
                    useTmcNames={props.optionUseTmcNames || false}
                />
            );
        }

        return null;
    };

    const getBasesTable = (): JSX.Element | null => {
        if (tableBases) {
            return (
                <MasterProductBaseTable
                    tableConstant={props.baseInProductTableConstant}
                    objectType={objectTypes.MASTER_BASE_IN_PRODUCT}
                    dataPrivileges={tableBases}
                    loading={props.baseInProductLoading}
                    buttonLoading={props.baseInProductButtonLoading}
                    baseList={props.baseList}
                    baseInProductAllList={props.baseInProductAllList}
                    productActiveList={props.productActiveList}
                    allList={props.baseInProductList}
                    activeList={props.baseInProductActiveList}
                    columnOrder={props.baseInProductColumnOrder}
                    columnVisibility={props.baseInProductColumnVisibility}
                    columnWidth={props.baseInProductColumnWidth}
                    offset={props.baseInProductOffset}
                    page={props.baseInProductPage}
                    rowCount={props.baseInProductRowCount}
                    rowCountCustom={props.baseInProductRowCountCustom}
                    search={props.baseInProductSearch}
                    searchParams={props.baseInProductSearchParams}
                    showFilterRow={props.baseInProductShowFilterRow}
                    showGlobalSearch={props.baseInProductShowGlobalSearch}
                    sortingAsc={props.baseInProductSortingAsc}
                    sortingCriterion={props.baseInProductSortingCriterion}
                    totalCount={props.baseInProductTotalCount}
                    useTmcNames={props.optionUseTmcNames || false}
                />
            );
        }
        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={[getProductsTable(), getBasesTable()].filter((item) => item !== null) as Array<JSX.Element>}
            layout={props.sceneLayout}
            sceneTableSize={props.productSceneTableSize}
            dataPrivileges={tableProducts}
            sceneConstant={menuConstants.PAGE_MASTER_PRODUCTS}
        />
    );
};
