import * as defaultConstants from "../../../constants/default";
import * as inputConstants from "../../../constants/inputConstants";
import * as messageHelper from "../../../helpers/messageHelper";
import * as modalHelper from "../../../helpers/modalHelper";
import * as notificationActions from "../../../actions/notificationActions";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as responseValidationHelper from "../../../helpers/responseValidationHelper";
import * as serverActions from "../../../actions/serverActions";
import * as serverRestMethods from "../../../constants/serverRestMethods";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { FlashMessage } from "../../../types/flashMessage";
import { ReactSVG } from "react-svg";

import { connect } from "react-redux";

import imgLoader2 from "../../../resources/img/mini-loader.svg";
import { t as translate } from "react-i18nify";

type OwnProps = {
    modalId?: number;
    className: string;
    editable: boolean;
    required: boolean;
    type: inputConstants.UploadType;
    param: propertyConstants.Property;
    fileId: number | null;
    validation: string;
    validTypes?: Array<string>;
    object: any;
    callback: (id: string | number | null, src?: string, name?: string) => any;
    confirm: (id: string | number | null) => any;
    setUploadState?: (isUploading: boolean) => void;
};

type State = {
    progress: number | null;
};

type ContainerProps = PropsType & DispatchType;

type Props = OwnProps & ContainerProps;

class ModalFileInput extends Component<Props, State> {
    state: State = {
        progress: null
    };

    componentDidMount(): void {
        // Handling file upload
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const key = window.jQuery(this)?.[0]?.props?.param || null;

        if (key) {
            window.jQuery(`#${key}`).fileupload({
                // By default, each file of a selection is uploaded using an individual request for XHR type uploads.
                // Set this option to false to upload file selections in one request each.
                singleFileUploads: false,
                dataType: "json",
                add: function (event: any, data: any) {
                    const validFiles = self.props.validTypes?.length
                        ? data.files.filter(
                              (item: any) => (self.props.validTypes ?? []).includes(item.type) && item.type !== ""
                          )
                        : data.files;

                    if (validFiles.length !== data.files.length) {
                        self.props.passRequest(
                            notificationActions.saveFlashMessage(
                                new FlashMessage(
                                    defaultConstants.FLASH_MESSAGE_WARNING,
                                    translate("flashMessage.invalidFileTypeUploading", {
                                        validFormats: self.props.validation
                                    })
                                )
                            )
                        );
                    }

                    const fileText = self.getSelectedFilesName(event?.target?.dataset?.type || null, validFiles || []);

                    if (modalHelper.isImagePreviewUploadType(self.props.type) && validFiles.length) {
                        if (self.props.setUploadState) {
                            self.props.setUploadState(true);
                        }

                        self.props.callback(self.props.fileId, URL.createObjectURL(validFiles?.[0]), fileText);
                    }

                    $(`#uploaded-files-${key}`).empty();

                    data.context = window
                        .jQuery('<p class="file">')
                        .append(window.jQuery('<a target="_blank">').text(fileText))
                        .appendTo(`#uploaded-files-${key}`);

                    if (modalHelper.isImagePreviewUploadType(self.props.type)) {
                        $(`#btn-confirm-modal-${self.props.modalId || ""}`)
                            .off("click")
                            .on("click", function () {
                                data.formData = self.getServerParams(validFiles);
                                data.submit();
                            });
                    } else {
                        data.formData = self.getServerParams(validFiles);
                        data.submit();
                    }
                },
                progress: function (event: any, data: any) {
                    const progress = parseInt(((data.loaded / data.total) * 100).toString(), 10);

                    self.setProgress(progress);
                },
                error: function (event: any, data: any) {
                    self.props.passRequest(
                        notificationActions.saveFlashMessage(
                            new FlashMessage(
                                defaultConstants.FLASH_MESSAGE_ERROR,
                                translate("flashMessage.fileUploadError", { 0: event.status })
                            )
                        )
                    );
                },
                done: function (event: any, data: any) {
                    if (data && data.result && !responseValidationHelper.isResponseValid(data.result)) {
                        self.props.passRequest(messageHelper.getInvalidFormatMessage(data.result));
                    }

                    if (self.props.setUploadState) {
                        self.props.setUploadState(false);
                    }

                    let newUploadId;

                    switch (self.props.type) {
                        case inputConstants.UPDATE_TYPE_ICON:
                        case inputConstants.UPLOAD_TYPE_ICON:
                        case inputConstants.UPDATE_TYPE_IMAGE:
                        case inputConstants.UPLOAD_TYPE_IMAGE:
                        case inputConstants.UPLOAD_TYPE_IMAGES: {
                            newUploadId = data?.result?.data?.id || data?.formData?.image_id || null;
                            break;
                        }
                        case inputConstants.UPLOAD_TYPE_DIRECTORY:
                        case inputConstants.UPLOAD_TYPE_FILE:
                        case inputConstants.UPLOAD_TYPE_FILES: {
                            newUploadId = data?.result?.data || null;
                            break;
                        }
                        default:
                            newUploadId = data?.result?.data?.uuid || null;
                    }

                    data.context.addClass("done").find("a").prop("href", data.files[0].url);
                    self.setProgress(null);

                    if (self.props.type !== inputConstants.UPLOAD_TYPE_IMAGES) {
                        if (modalHelper.isImagePreviewUploadType(self.props.type)) {
                            self.props.confirm(newUploadId);
                        } else {
                            self.props.callback(newUploadId);
                        }
                    }
                }
            });
        }
    }

    setProgress = (progress: number | null) => {
        this.setState({
            progress: progress
        });
    };

    getProgressBar = (): JSX.Element | null => {
        const { progress } = this.state;

        if (progress !== null) {
            return (
                <div className="upload-progress">
                    <ReactSVG src={imgLoader2} className="svg-icon" />
                    <span className="progress-state">{`${progress}%`}</span>
                    <span>{translate("file.uploading")}</span>
                </div>
            );
        }

        return null;
    };

    getServerParams = (validFiles: Array<any>) => {
        const paths: Record<string, string> = {};

        validFiles.forEach((value: any) => (paths[value.name] = value.webkitRelativePath));

        return {
            // eslint-disable-next-line camelcase
            session_uuid: this.props.sessionUuid,
            // eslint-disable-next-line camelcase
            image_id: this.props.fileId || null,
            data: JSON.stringify({
                name: this.props.object?.[propertyConstants.PROPERTY_NAME] || validFiles?.[0].name || null,
                fileTypeId: this.props.object?.[propertyConstants.PROPERTY_FILE_TYPE_ID] ?? null,
                url: this.props.object?.[propertyConstants.PROPERTY_URL] ?? null
            }),
            paths: JSON.stringify(paths)
        };
    };

    getSelectedFilesName = (type: string | null, files: any): string => {
        if (type === inputConstants.UPLOAD_TYPE_DIRECTORY || type === inputConstants.UPLOAD_TYPE_IMAGES) {
            const directoryName = files?.[0]?.webkitRelativePath.match(/[^/]*/g)[0];
            return `${directoryName} (${translate("file.fileCount")}: ${files?.length || 0})`;
        }

        if (type === inputConstants.UPLOAD_TYPE_FILES) {
            return `${translate("file.fileCount")}: ${files?.length || 0}`;
        }

        if (files?.length === 1 && type !== inputConstants.UPLOAD_TYPE_DIRECTORY) {
            return files[0].name;
        }

        return "";
    };

    getUploadTranslation = (): string => {
        switch (this.props.type) {
            case inputConstants.UPDATE_TYPE_ICON:
            case inputConstants.UPLOAD_TYPE_ICON:
                return translate("image.selectIcon");
            case inputConstants.UPDATE_TYPE_IMAGE:
            case inputConstants.UPLOAD_TYPE_IMAGE:
                return translate("image.selectImage");
            case inputConstants.UPLOAD_TYPE_DIRECTORY:
            case inputConstants.UPLOAD_TYPE_IMAGES:
                return translate("file.selectDirectory");
            case inputConstants.UPLOAD_TYPE_FILE:
                return translate("file.selectFile");
            case inputConstants.UPLOAD_TYPE_FILES:
                return translate("file.selectFiles");
            default:
                return "";
        }
    };

    getUploadName = (): string => {
        switch (this.props.type) {
            case inputConstants.UPLOAD_TYPE_FILE:
                return "file";
            case inputConstants.UPDATE_TYPE_ICON:
            case inputConstants.UPLOAD_TYPE_ICON:
            case inputConstants.UPDATE_TYPE_IMAGE:
            case inputConstants.UPLOAD_TYPE_IMAGE:
                return "image";
            case inputConstants.UPLOAD_TYPE_DIRECTORY:
            case inputConstants.UPLOAD_TYPE_FILES:
            case inputConstants.UPLOAD_TYPE_IMAGES:
                return "files[]";
            default:
                return "";
        }
    };

    getUploadInput = (): JSX.Element | null => {
        const { editable, validation, param, type, urlRest, fileId } = this.props;

        switch (type) {
            case inputConstants.UPDATE_TYPE_ICON:
            case inputConstants.UPDATE_TYPE_IMAGE:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        accept={validation}
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPDATE_IMAGE}?image_id=${fileId}`}
                    />
                );
            case inputConstants.UPLOAD_TYPE_ICON:
            case inputConstants.UPLOAD_TYPE_IMAGE:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        accept={validation}
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPLOAD_IMAGE}`}
                    />
                );
            case inputConstants.UPLOAD_TYPE_IMAGES:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        accept={validation}
                        multiple
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPLOAD_IMAGE_DIRECTORY}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        directory=""
                        webkitdirectory=""
                        mozdirectory=""
                    />
                );
            case inputConstants.UPLOAD_TYPE_FILE:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        accept={validation}
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPLOAD_FILE}`}
                    />
                );
            case inputConstants.UPLOAD_TYPE_FILES:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        multiple
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPLOAD_FILES}`}
                    />
                );
            case inputConstants.UPLOAD_TYPE_DIRECTORY:
                return (
                    <input
                        id={param}
                        key={param}
                        name={this.getUploadName()}
                        type={"file"}
                        accept={validation}
                        multiple
                        readOnly={!editable}
                        data-key={param}
                        data-type={type}
                        data-url={`${urlRest}${serverRestMethods.METHOD_UPLOAD_DIRECTORY}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        directory=""
                        webkitdirectory=""
                        mozdirectory=""
                    />
                );
            default:
                return null;
        }
    };

    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <div id="files">
                    <label className="file-input" htmlFor={this.props.param}>
                        {this.getUploadTranslation()}
                    </label>
                    {this.getUploadInput()}
                    <div className="uploaded-files-text" id={`uploaded-files-${this.props.param}`}></div>
                </div>
                {this.getProgressBar()}
            </div>
        );
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

export type DispatchType = Readonly<{
    passRequest(nextAction: any): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    passRequest: (nextAction: any): any => dispatch(serverActions.passRequest(nextAction))
});

export const ModalFileInputContainer = connect(mapStateToProps, mapDispatchToProps)(ModalFileInput);
