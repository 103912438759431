import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { MarginType } from "./marginType";
import { TableHeading } from "./tableHeading";

export class Margin {
    id: number;
    marginTypeId: number;
    name: string;
    procProducts: number | null;
    procColorants: number | null;
    visible: boolean;
    priority: number | null;

    constructor(
        id: number,
        marginTypeId: number,
        name: string,
        procProducts: number | null,
        procColorants: number | null,
        visible: boolean,
        priority: number | null
    ) {
        this.id = id;
        this.marginTypeId = marginTypeId;
        this.name = name;
        this.procProducts = procProducts;
        this.procColorants = procColorants;
        this.visible = visible;
        this.priority = priority;
    }
}

export function mapMargin(data: Record<string, any>): Margin | null {
    try {
        return new Margin(
            data.id,
            data.marginTypeId,
            data.name,
            data.procProducts,
            data.procColorants,
            data.visible,
            data.priority
        );
    } catch (e) {
        return null;
    }
}

export function updateMargin(oldMargin: Margin | null, updatedData: Record<string, any>): Margin | null {
    if (oldMargin === null) {
        return null;
    }

    try {
        return new Margin(
            oldMargin.id,
            updatedData.marginTypeId ? updatedData.marginTypeId : oldMargin.marginTypeId,
            updatedData.name ? updatedData.name : oldMargin.name,
            updatedData.procProducts ? updatedData.procProducts : oldMargin.procProducts,
            updatedData.procColorants ? updatedData.procColorants : oldMargin.procColorants,
            updatedData.visible ? updatedData.visible : oldMargin.visible,
            updatedData.priority ? updatedData.priority : oldMargin.priority
        );
    } catch (e) {
        return null;
    }
}

// TODO proc PROPERTY_MARGIN_TYPE a ne PROPERTY_MARGIN_TYPE_ID?
export function createEmptyMargin(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_MARGIN_TYPE]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PROC_COLORANT]: "",
        [propertyConstants.PROPERTY_PROC_PRODUCT]: "",
        [propertyConstants.PROPERTY_PRIORITY]: ""
    };
}

export function generateMarginData(marginList: Array<Margin>): Array<any> {
    const data: Array<any> = [];
    let margin: Margin;

    for (margin of marginList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: margin.id,
            [propertyConstants.PROPERTY_MARGIN_TYPE]: margin.marginTypeId,
            [propertyConstants.PROPERTY_NAME]: margin.name,
            [propertyConstants.PROPERTY_PROC_PRODUCT]: margin.procProducts,
            [propertyConstants.PROPERTY_PROC_COLORANT]: margin.procColorants,
            [propertyConstants.PROPERTY_PRIORITY]: margin.priority
        });
    }

    return data;
}

export function generateMarginHeadings(
    objectType: objectTypes.ObjectType,
    marginTypeList: Array<MarginType>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MARGIN_TYPE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MARGIN_TYPE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_MARGIN_TYPE),
            editableList.includes(propertyConstants.PROPERTY_MARGIN_TYPE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MARGIN_TYPE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MARGIN_TYPE),
            requiredList.includes(propertyConstants.PROPERTY_MARGIN_TYPE),
            marginTypeList,
            widthOption?.[propertyConstants.PROPERTY_MARGIN_TYPE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PROC_COLORANT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PROC_COLORANT),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_PROC_COLORANT),
            editableList.includes(propertyConstants.PROPERTY_PROC_COLORANT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PROC_COLORANT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PROC_COLORANT),
            requiredList.includes(propertyConstants.PROPERTY_PROC_COLORANT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PROC_COLORANT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PROC_PRODUCT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PROC_PRODUCT),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_PROC_PRODUCT),
            editableList.includes(propertyConstants.PROPERTY_PROC_PRODUCT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PROC_PRODUCT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PROC_PRODUCT),
            requiredList.includes(propertyConstants.PROPERTY_PROC_PRODUCT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PROC_PRODUCT] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
