import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";

import { Privilege, generatePrivilegeData, generatePrivilegeHeadings } from "../../../../types/privilege";
import React, { Component } from "react";

import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<Privilege>;
    activeList: Array<Privilege>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showOnlyNonDefaultValues: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    custom: string | null;
};

export class MasterRedlikePrivilegeTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_KEY,
            propertyConstants.PROPERTY_VALUE,
            propertyConstants.PROPERTY_DESCRIPTION
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_KEY];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_KEY];
        const editableList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_VALUE];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];
        let orderList = [...visibleList];

        orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
        visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);

        const privilegeStatusList = [
            privilegeConstants.PRIVILEGE_STATUS_AVAILABLE,
            privilegeConstants.PRIVILEGE_STATUS_NOT_AVAILABLE,
            privilegeConstants.PRIVILEGE_STATUS_HIDDEN
        ];

        return generatePrivilegeHeadings(
            this.props.objectType,
            privilegeStatusList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={""}
                    titleClassName={"no-title"}
                    headings={this.generateTableHeadings()}
                    data={generatePrivilegeData(this.props.allList)}
                    activeData={this.props.activeList}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showSpecificValues={this.props.showOnlyNonDefaultValues}
                    showFilterRow={false}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={{}}
                />
            </div>
        );
    }
}
