import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { FormulaNote, updateFormulaNote } from "../types/formulaNote";

export type FormulaNoteState = Readonly<{
    masterList: Array<FormulaNote>;
    masterActiveList: Array<FormulaNote>;
    masterCount: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    systemList: Array<FormulaNote>;
    systemActiveList: Array<FormulaNote>;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    zoneList: Array<FormulaNote>;
    zoneActiveList: Array<FormulaNote>;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
}>;

const initialState: FormulaNoteState = {
    masterList: [],
    masterActiveList: [],
    masterCount: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE
};

export default function formulaNotes(
    state: FormulaNoteState = initialState,
    action: Record<string, any>
): FormulaNoteState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_FORMULA_NOTE: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterCount: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_FORMULA_NOTE: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_FORMULA_NOTE: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_FORMULA_NOTE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_FORMULA_NOTE: {
                        const updatedFormulaNoteList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_FORMULA_NOTE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateFormulaNote
                        );

                        if (updatedFormulaNoteList.length) {
                            return {
                                ...state,
                                masterList: updatedFormulaNoteList
                            };
                        }

                        return state;
                    }
                    case objectTypes.SYSTEM_FORMULA_NOTE: {
                        const updatedFormulaNoteList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_FORMULA_NOTE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateFormulaNote
                        );

                        if (updatedFormulaNoteList.length) {
                            return {
                                ...state,
                                systemList: updatedFormulaNoteList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_FORMULA_NOTE: {
                        const updatedFormulaNoteList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_FORMULA_NOTE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateFormulaNote
                        );

                        if (updatedFormulaNoteList.length) {
                            return {
                                ...state,
                                zoneList: updatedFormulaNoteList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_FORMULA_NOTE: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_FORMULA_NOTE: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_FORMULA_NOTE: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (action.payload.type === modalTypes.MODAL_FORMULA_NOTES_TABLE) {
                return {
                    ...state,
                    masterActiveList: initialState.masterActiveList
                };
            }

            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
