import * as generalHelper from "../helpers/generalHelper";

export class DataItem {
    key: string;
    type: string;
    canView: boolean;
    canInsert: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    visible: boolean;
    editable: boolean;

    constructor(
        key: string,
        type: string,
        canView: boolean,
        canInsert: boolean,
        canUpdate: boolean,
        canDelete: boolean,
        visible: boolean,
        editable: boolean
    ) {
        this.key = key;
        this.type = type;
        this.canView = canView;
        this.canInsert = canInsert;
        this.canUpdate = canUpdate;
        this.canDelete = canDelete;
        this.visible = visible;
        this.editable = editable;
    }
}

export function mapDataItem(data: any): DataItem | null {
    try {
        return new DataItem(
            data.key,
            data.type,
            data.canView,
            data.canInsert,
            data.canUpdate,
            data.canDelete,
            data.visible,
            data.editable
        );
    } catch (e) {
        return null;
    }
}

export function mapAllPrivilege(data: any): Array<any> {
    return generalHelper.sortObjectKeys(data);
}

export function mapAllDataPrivilege(data: any): Array<any> {
    const responseDataPrivileges = generalHelper.sortObjectKeys(data);
    const dataPrivileges = [];

    for (const item of Object.values(responseDataPrivileges)) {
        const dataPrivilege = mapDataItem(item);

        if (dataPrivilege !== null) {
            dataPrivileges.push(dataPrivilege);
        }
    }

    return dataPrivileges;
}
