import * as generalHelper from "../../helpers/generalHelper";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionActions from "../../actions/optionActions";
import * as optionsConstants from "../../constants/optionsConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as userActions from "../../actions/userActions";
import * as websocketActions from "../../actions/websocketActions";

import { User, mapUser } from "../../types/user";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_USERS_WITH_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapUser(
                    item.user,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS),
                    item.groups
                );

                if (user) {
                    userList.push(user);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.USER, userList, response.data.count)];
        }
        case methods.METHOD_ADD_USER:
        case methods.METHOD_ADD_USER_WITH_USER_GROUPS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];
            const userId = response?.data?.user?.id ?? null;
            const companyId = state.user.companyId ?? null;

            if (userId) {
                requests.push(
                    optionActions.setDictionaryOption(optionsConstants.OPTION_COMPANIES_FOR_USERS, userId, companyId)
                );
            }

            requests.push(
                userActions.saveUserCompanyId(null),
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.USER),
                navigationActions.navigationCloseModal(modalTypes.MODAL_USERS_ADD)
            );

            return requests;
        }
        case methods.METHOD_EDIT_USER:
        case methods.METHOD_EDIT_USER_WITH_USER_GROUPS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.USER)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_USERS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.USER));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_USERS_EDIT));
            } else {
                const user = mapUser(
                    response.data.user,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS),
                    response.data.groups
                );

                if (user) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.USER, [user]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.USER),
                universalObjectActions.setActiveItems(objectTypes.USER, [])
            ];
        }
        case methods.METHOD_CHANGE_USER_PASSWORD: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.USER),
                navigationActions.navigationCloseModal(modalTypes.MODAL_CHANGE_PASSWORD)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
