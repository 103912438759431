import * as bookmarkConstants from "../../../../constants/bookmarkConstants";
import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";

import { Color, createEmptyColor, generateColorData, generateColorHeadings } from "../../../../types/color";
import React, { Component } from "react";

import { Bookmark } from "../../../../types/bookmark";
import { Fandeck } from "../../../../types/fandeck";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";

import { TableHeading } from "../../../../types/tableHeading";
import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    fandeckActiveList: Array<Fandeck>;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<Color>;
    activeList: Array<Color>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    useLargoNames: boolean;
    useTmcNames: boolean;
};

export class MasterColorTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        bookmark: string | null = null
    ): Array<TableHeading> => {
        const genericPropertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLOR_NAME,
            propertyConstants.PROPERTY_COLOR_SEARCH_NAME,
            propertyConstants.PROPERTY_COLOR_CODE,
            propertyConstants.PROPERTY_COLOR_SEARCH_CODE,
            propertyConstants.PROPERTY_UUID
        ];
        const additionalPropertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_FANDECK_PAGE,
            propertyConstants.PROPERTY_FANDECK_POSITION,
            propertyConstants.PROPERTY_COLOR_MORE_INFO,
            propertyConstants.PROPERTY_COLOR_PRIORITY,
            propertyConstants.PROPERTY_BARCODES
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLOR_CODE,
            propertyConstants.PROPERTY_COLOR_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_COLOR_NAME];
        let editableList: Array<propertyConstants.Property> = [];
        let visibleList: Array<propertyConstants.Property> = [];

        // Table properties
        if (modalType === null) {
            visibleList = [propertyConstants.PROPERTY_COLOR_CODE, propertyConstants.PROPERTY_COLOR_NAME];
            editableList = [];
        }

        if (modalType !== null) {
            switch (bookmark) {
                case bookmarkConstants.BOOKMARK_GENERAL: {
                    visibleList = [...genericPropertyList];
                    editableList = [...genericPropertyList];

                    visibleList.splice(5, 0, propertyConstants.PROPERTY_IMAGE_ID);
                    visibleList.splice(4, 0, propertyConstants.PROPERTY_COLOR_RGB_HEX);
                    visibleList.splice(4, 0, propertyConstants.PROPERTY_COLOR_BLUE);
                    visibleList.splice(4, 0, propertyConstants.PROPERTY_COLOR_GREEN);
                    visibleList.splice(4, 0, propertyConstants.PROPERTY_COLOR_RED);

                    editableList = editableList.concat(
                        propertyConstants.PROPERTY_COLOR_BLUE,
                        propertyConstants.PROPERTY_COLOR_GREEN,
                        propertyConstants.PROPERTY_COLOR_RED,
                        propertyConstants.PROPERTY_IMAGE_ID
                    );

                    if (this.props.useTmcNames) {
                        visibleList.splice(1, 0, propertyConstants.PROPERTY_TMC_NAME);
                        editableList.push(propertyConstants.PROPERTY_TMC_NAME);
                    }

                    if (this.props.useLargoNames) {
                        visibleList.splice(1, 0, propertyConstants.PROPERTY_LARGO_NAME);
                        editableList.push(propertyConstants.PROPERTY_LARGO_NAME);
                    }

                    break;
                }
                case bookmarkConstants.BOOKMARK_PROPERTIES: {
                    visibleList = [...additionalPropertyList];
                    editableList = [...additionalPropertyList];
                    break;
                }
                default:
                    break;
            }
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateColorHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateBookmarks = (modalType: modalTypes.ModalType): Array<Bookmark> => {
        return [
            new Bookmark(
                bookmarkConstants.BOOKMARK_GENERAL,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.general"),
                this.generateTableHeadings(modalType, bookmarkConstants.BOOKMARK_GENERAL),
                this.props.dataPrivileges,
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_PROPERTIES,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.additionalProperties"),
                this.generateTableHeadings(modalType, bookmarkConstants.BOOKMARK_PROPERTIES),
                this.props.dataPrivileges,
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_SPECTRAL_DATA,
                bookmarkConstants.BOOKMARK_SPECTRAL_DATA,
                translate("general.spectralData"),
                [],
                this.props.dataPrivileges,
                false
            )
        ];
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            objectTypes.MASTER_COLOR,
            translate("color.addColor"),
            createEmptyColor(this.props.fandeckActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || null),
            undefined,
            this.generateBookmarks(modalTypes.MODAL_COLORS_ADD)
        );
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_COLORS_ADD,
            addModalParams: this.generateAddModalParams()
        };
        const section: any = document.querySelector(".panel-section");
        const panelLayout = section ? section.offsetHeight + 39 : 0;

        return (
            <div className="height-100 master" style={{ height: "calc(100% - " + panelLayout + "px)" }}>
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings()}
                    data={generateColorData(this.props.allList)}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
