import React, { Component } from "react";

type Props = {
    className: string;
    editable: boolean;
    required: boolean;
    value: string;
    options: Array<{ key: string; value: string }>;
    callback: (value: boolean) => any;
};

export class ModalRadiobutton extends Component<Props> {
    getClassName = (): string => {
        let newClassName = this.props.className;

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getOptions = (): Array<JSX.Element> => {
        const { editable } = this.props;
        const optionList = [];

        for (const option of this.props.options) {
            optionList.push(
                <div key={option.key} className={this.getClassName()}>
                    <label className={"radio-label"}>
                        <input
                            type={"radio"}
                            checked={this.props.value === option.key}
                            value={option.key}
                            readOnly={!editable}
                            disabled={!editable}
                            onChange={(event: any): void => this.props.callback(event.target.value)}
                        />
                        <span className={"checkmark"}></span>
                    </label>
                    <div>
                        <span className="radio-text">{option.value}</span>
                    </div>
                </div>
            );
        }

        return optionList;
    };

    render(): JSX.Element {
        return <React.Fragment>{this.getOptions()}</React.Fragment>;
    }
}
