import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";

// TODO rename properties
export class TableHeading {
    [tableConstants.TABLE_HEADING_NAME]: propertyConstants.Property;
    [tableConstants.TABLE_HEADING_TRANSLATION]: string;
    [tableConstants.TABLE_HEADING_TYPE]: tableConstants.TableType;
    [tableConstants.TABLE_HEADING_VISIBILITY]: boolean;
    [tableConstants.TABLE_HEADING_EDITING]: boolean;
    [tableConstants.TABLE_HEADING_FILTERABLE]: boolean;
    [tableConstants.TABLE_HEADING_ALWAYS_VISIBLE]: boolean;
    [tableConstants.TABLE_HEADING_REQUIRED]: boolean;
    [tableConstants.TABLE_HEADING_ENUM]: Array<any>;
    [tableConstants.TABLE_HEADING_WIDTH]?: number | null;
    [tableConstants.TABLE_HEADING_CLASS]?: string;
    [tableConstants.TABLE_HEADING_GENERATED_KEY]?: number | null;

    constructor(
        key: propertyConstants.Property,
        name: string,
        type: tableConstants.TableType,
        isVisible: boolean,
        isEditable: boolean,
        isFilterable: boolean,
        isAlwaysVisible: boolean,
        isRequired: boolean,
        enumList: Array<any>,
        width?: number | null,
        className?: string,
        generatedKey?: number | null
    ) {
        this[tableConstants.TABLE_HEADING_NAME] = key;
        this[tableConstants.TABLE_HEADING_TRANSLATION] = name;
        this[tableConstants.TABLE_HEADING_TYPE] = type;
        this[tableConstants.TABLE_HEADING_VISIBILITY] = isVisible;
        this[tableConstants.TABLE_HEADING_EDITING] = isEditable;
        this[tableConstants.TABLE_HEADING_FILTERABLE] = isFilterable;
        this[tableConstants.TABLE_HEADING_ALWAYS_VISIBLE] = isAlwaysVisible;
        this[tableConstants.TABLE_HEADING_REQUIRED] = isRequired;
        this[tableConstants.TABLE_HEADING_ENUM] = enumList;
        this[tableConstants.TABLE_HEADING_WIDTH] = width;
        this[tableConstants.TABLE_HEADING_CLASS] = className;
        this[tableConstants.TABLE_HEADING_GENERATED_KEY] = generatedKey;
    }
}

export function updateUniversalValueTableType(
    originalHeading: TableHeading,
    type: tableConstants.TableType,
    editing: boolean,
    enumList: Array<any>
): TableHeading | null {
    try {
        return new TableHeading(
            originalHeading[tableConstants.TABLE_HEADING_NAME],
            originalHeading[tableConstants.TABLE_HEADING_TRANSLATION],
            type ?? originalHeading[tableConstants.TABLE_HEADING_TYPE],
            originalHeading[tableConstants.TABLE_HEADING_VISIBILITY],
            editing ?? originalHeading[tableConstants.TABLE_HEADING_EDITING],
            originalHeading[tableConstants.TABLE_HEADING_FILTERABLE],
            originalHeading[tableConstants.TABLE_HEADING_ALWAYS_VISIBLE],
            originalHeading[tableConstants.TABLE_HEADING_REQUIRED],
            enumList ?? originalHeading[tableConstants.TABLE_HEADING_ENUM],
            originalHeading[tableConstants.TABLE_HEADING_WIDTH],
            originalHeading[tableConstants.TABLE_HEADING_CLASS],
            originalHeading[tableConstants.TABLE_HEADING_GENERATED_KEY]
        );
    } catch (e) {
        return null;
    }
}
