export const ITEM_TYPE_BANNER = 18;
export const ITEM_TYPE_BASE_IN_PRODUCT = 5;
export const ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE = 1;
export const ITEM_TYPE_BUTTON = 7;
export const ITEM_TYPE_COLOR = 8;
export const ITEM_TYPE_COLOR_IN_FANDECK = 13;
export const ITEM_TYPE_COLORANT = 2;
export const ITEM_TYPE_COLORANT_PACKAGE = 6;
export const ITEM_TYPE_CUSTOMER = 16;
export const ITEM_TYPE_FANDECK = 17;
export const ITEM_TYPE_FORMULA_COMBINATION = 9;
export const ITEM_TYPE_FORMULA_COMBINATION_FOR_BIPP = 20;
export const ITEM_TYPE_FORMULA_NOTE = 14;
export const ITEM_TYPE_GENERIC = 12;
export const ITEM_TYPE_ORDER_RECORD = 10;
export const ITEM_TYPE_PACKAGE = 11;
export const ITEM_TYPE_PRODUCT = 4;
export const ITEM_TYPE_PRODUCT_GROUP = 3;
export const ITEM_TYPE_USER = 15;
export const ITEM_TYPE_ZONE = 19;

export const BARCODE_TYPES = [
    ITEM_TYPE_COLOR_IN_FANDECK,
    ITEM_TYPE_COLORANT_PACKAGE,
    ITEM_TYPE_FANDECK,
    ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE
];
