export const CATEGORY_IMAGE = "image";
export const CATEGORY_FILE = "file";

export const FILE_TYPE_PRODUCT_IMAGE = 1;
export const FILE_TYPE_FORMULA_NOTE_IMAGE = 2;
export const FILE_TYPE_GENERIC_IMAGE = 3;
export const FILE_TYPE_INSTRUCTIONS = 4;
export const FILE_TYPE_SAFETY_SHEET = 5;
export const FILE_TYPE_TECHNICAL_SHEET = 6;
export const FILE_TYPE_BANNER_IMAGE = 7;
export const FILE_TYPE_COLORANT_IMAGE = 8;
export const FILE_TYPE_ICON = 9;
export const FILE_TYPE_IMAGE = 10;
export const FILE_TYPE_STATIC_SQL = 11;
