import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as itemsWithParamsActions from "../actions/itemsWithParamsActions";
import * as methods from "../constants/serverMethods";
import * as modalTypes from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as navigationTypes from "../constants/navigationTypes";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as optionActions from "../actions/optionActions";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";
import { ReflectanceData, mapReflectanceData } from "../types/reflectanceData";
import { SystemZone, cloneSystemZone } from "../types/systemZone";

import { ColorData } from "../types/colorData";
import { ExportTask } from "../types/exportTask";
import { ImportTask } from "../types/importTask";
import { Product } from "../types/product";
import { UserGroup } from "../types/userGroup";
import { serverRequest } from "../actions/websocketActions";
import { setActiveItems } from "../actions/universalObjectActions";
import { switchMap } from "rxjs/operators";

export const editItem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.EDIT),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // base
                case objectTypes.MASTER_BASE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_MASTER_BASE, methods.METHOD_EDIT_MASTER_BASE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.base.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_BASE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.SYSTEM_BASE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM_BASE, methods.METHOD_EDIT_SYSTEM_BASE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.base.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_BASE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.ZONE_BASE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_ZONE_BASE, methods.METHOD_EDIT_ZONE_BASE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // color
                case objectTypes.MASTER_COLOR: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
                                methods.METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_COLOR_MODAL: {
                    requests.push(
                        setActiveItems(objectTypes.MASTER_COLOR_MODAL, payload.params ? [payload.params] : [])
                    );
                    break;
                }
                case objectTypes.MASTER_COLOR_SPECTRO_TYPE: {
                    let activeItem = state$.value.color.masterModalActiveList.length
                        ? state$.value.color.masterModalActiveList[0]
                        : null;

                    if (activeItem && activeItem[propertyConstants.PROPERTY_DATA]) {
                        let activeColorData =
                            activeItem[propertyConstants.PROPERTY_DATA].find(
                                (item: ColorData) =>
                                    item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                                    activeItem[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
                            ) || null;

                        // TODO wavelengthId do property konstant?
                        if (activeColorData) {
                            const foundItem =
                                activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].find(
                                    (reflItem: ReflectanceData) =>
                                        reflItem[propertyConstants.PROPERTY_WAVELENGTH] === payload.params.wavelengthId
                                ) || null;

                            let reflDataItem: ReflectanceData | null = null;

                            if (foundItem) {
                                reflDataItem = mapReflectanceData(
                                    payload.params.wavelengthId,
                                    payload.params.data.reflData
                                );
                            } else {
                                reflDataItem = mapReflectanceData(payload.params.wavelengthId, null);
                            }
                            // TODO mapování provést dopředu ať je to přehlednější
                            activeColorData = {
                                ...activeColorData,
                                [propertyConstants.PROPERTY_REFLECTANCE_DATA]: activeColorData[
                                    propertyConstants.PROPERTY_REFLECTANCE_DATA
                                ].map((item: ReflectanceData) =>
                                    reflDataItem &&
                                    item[propertyConstants.PROPERTY_WAVELENGTH] ===
                                        reflDataItem[propertyConstants.PROPERTY_WAVELENGTH]
                                        ? reflDataItem
                                        : item
                                )
                            };

                            activeItem = {
                                ...activeItem,
                                [propertyConstants.PROPERTY_DATA]: activeItem[propertyConstants.PROPERTY_DATA].map(
                                    (item: ColorData) =>
                                        item[propertyConstants.PROPERTY_ID] ===
                                        activeColorData[propertyConstants.PROPERTY_ID]
                                            ? activeColorData
                                            : item
                                )
                            };

                            requests.push(setActiveItems(objectTypes.MASTER_COLOR_MODAL, [activeItem]));
                        }
                    }
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_MASTER_COLORANT, methods.METHOD_EDIT_MASTER_COLORANT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.colorant.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM_COLORANT, methods.METHOD_EDIT_SYSTEM_COLORANT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.colorant.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_ZONE_COLORANT, methods.METHOD_EDIT_ZONE_COLORANT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // colorant batch
                case objectTypes.MASTER_COLORANT_BATCH: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_EDIT_MASTER_COLORANT_BATCH,
                            methods.METHOD_EDIT_MASTER_COLORANT_BATCH,
                            { ...payload.params, returnData: !payload.isModal ? true : undefined }
                        )
                    );
                    break;
                }
                // colorant package
                case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.colorantPackage.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.SYSTEM_COLORANT_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        const activeItem = generalHelper.getObjectById(
                            state$.value.colorantPackage.systemList,
                            payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                        );

                        if (activeItem) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_EDIT_SYSTEM_COLORANT_PACKAGE,
                                    methods.METHOD_EDIT_SYSTEM_COLORANT_PACKAGE,
                                    {
                                        colorantPackageId: activeItem[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID],
                                        data: payload.params.data,
                                        returnData: !payload.isModal ? true : undefined
                                    }
                                )
                            );
                        }
                    }

                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.colorantPackage.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.ZONE_COLORANT_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        const activeItem = generalHelper.getObjectById(
                            state$.value.colorantPackage.zoneList,
                            payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                        );

                        if (activeItem && payload?.params?.data) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_EDIT_ZONE_COLORANT_PACKAGE,
                                    methods.METHOD_EDIT_ZONE_COLORANT_PACKAGE,
                                    {
                                        colorantPackageId: activeItem[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID],
                                        data: payload.params.data,
                                        returnData: !payload.isModal ? true : undefined
                                    }
                                )
                            );
                        }
                    }

                    break;
                }
                // company
                case objectTypes.COMPANY: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_COMPANY, methods.METHOD_EDIT_COMPANY, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // currency
                case objectTypes.CURRENCY: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_CURRENCY, methods.METHOD_EDIT_CURRENCY, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // database
                case objectTypes.DATABASE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_DATABASE, methods.METHOD_EDIT_DATABASE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.DATABASE_MONITORING: {
                    break;
                }
                // export
                case objectTypes.EXPORT_TASK: {
                    if (payload.params && payload.params.data) {
                        let updatedExportTask =
                            generalHelper.getObjectById(
                                state$.value.export.list,
                                payload.params[propertyConstants.PROPERTY_EXPORT_TASK_ID]
                            ) ?? null;

                        if (updatedExportTask) {
                            for (const [key, value] of Object.entries(payload.params.data)) {
                                updatedExportTask = {
                                    ...updatedExportTask,
                                    [key]: value
                                };
                            }
                        }

                        const updatedExportTaskList = state$.value.export.list.map((item: ExportTask) =>
                            item[propertyConstants.PROPERTY_ID] === updatedExportTask?.[propertyConstants.PROPERTY_ID]
                                ? updatedExportTask
                                : item
                        );

                        requests.push(
                            universalObjectActions.saveItems(
                                objectTypes.EXPORT_TASK,
                                updatedExportTaskList,
                                state$.value.export.count
                            )
                        );
                    }
                    break;
                }
                // changelog
                case objectTypes.CHANGELOG: {
                    if (payload.params && payload.params.data) {
                        if (state$.value.navigation.activeScene === navigationTypes.DATA_EXPORT_SCENE) {
                            let updatedSystemZoneList: Array<SystemZone> = [
                                ...state$.value.databaseSystemZone.systemZoneList
                            ];

                            for (const systemZone of state$.value.databaseSystemZone.systemZoneList) {
                                if (systemZone[propertyConstants.PROPERTY_IS_CHECKED]) {
                                    let updatedSystemZone = {
                                        ...systemZone,
                                        [propertyConstants.PROPERTY_CHANGELOG]:
                                            payload.params.data[propertyConstants.PROPERTY_CHANGELOG]
                                    };
                                    updatedSystemZone = cloneSystemZone(updatedSystemZone);

                                    updatedSystemZoneList = updatedSystemZoneList.map((item: SystemZone) =>
                                        item[propertyConstants.PROPERTY_ID] ===
                                        updatedSystemZone?.[propertyConstants.PROPERTY_ID]
                                            ? updatedSystemZone
                                            : item
                                    );
                                }
                            }
                            requests.push(
                                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE, updatedSystemZoneList),
                                navigationActions.navigationCloseModal(modalTypes.MODAL_CHANGELOG_EDIT)
                            );
                        }
                    }
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_MASTER_FANDECK, methods.METHOD_EDIT_MASTER_FANDECK, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_FANDECK: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_FANDECK: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.fandeck.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.SYSTEM_FANDECK: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM_FANDECK, methods.METHOD_EDIT_SYSTEM_FANDECK, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_FANDECK: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_FANDECK: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.fandeck.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.ZONE_FANDECK: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_ZONE_FANDECK, methods.METHOD_EDIT_ZONE_FANDECK, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // file category
                case objectTypes.FILE_CATEGORY: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_FILE_CATEGORY,
                                methods.METHOD_EDIT_FILE_CATEGORY,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_MASTER_FORMULA,
                                methods.METHOD_EDIT_MASTER_FORMULA,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_FORMULA_MODAL: {
                    requests.push(
                        setActiveItems(objectTypes.MASTER_FORMULA_MODAL, payload.params ? [payload.params] : [])
                    );
                    break;
                }
                // formula note
                case objectTypes.MASTER_FORMULA_NOTE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_MASTER_FORMULA_NOTE,
                                methods.METHOD_EDIT_MASTER_FORMULA_NOTE,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_SYSTEM_FORMULA_NOTE,
                                methods.METHOD_EDIT_SYSTEM_FORMULA_NOTE,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_ZONE_FORMULA_NOTE,
                                methods.METHOD_EDIT_ZONE_FORMULA_NOTE,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.GENERIC_IMAGE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_IMAGE, methods.METHOD_EDIT_IMAGE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.IMAGE_CATEGORY: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_IMAGE_CATEGORY,
                                methods.METHOD_EDIT_IMAGE_CATEGORY,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // import task
                case objectTypes.IMPORT_TASK: {
                    if (payload.params && payload.params.data) {
                        let updatedImportTask =
                            generalHelper.getObjectById(
                                state$.value.import.list,
                                payload.params[propertyConstants.PROPERTY_IMPORT_TASK_ID]
                            ) ?? null;

                        if (updatedImportTask) {
                            for (const [key, value] of Object.entries(payload.params.data)) {
                                updatedImportTask = {
                                    ...updatedImportTask,
                                    [key]: value
                                };
                            }
                        }

                        const updatedImportTaskList = state$.value.import.list.map((item: ImportTask) =>
                            item[propertyConstants.PROPERTY_ID] === updatedImportTask?.[propertyConstants.PROPERTY_ID]
                                ? updatedImportTask
                                : item
                        );

                        requests.push(
                            universalObjectActions.saveItems(
                                objectTypes.IMPORT_TASK,
                                updatedImportTaskList,
                                state$.value.import.count
                            )
                        );
                    }
                    break;
                }
                // option
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    if (payload.params) {
                        const data = { ...payload.params[propertyConstants.PROPERTY_DATA] };

                        /*
                        When the overwrite parameter is changed in the Table directly, it falls into this if statement and the value needs to be negated.
                        However, if the overwrite parameter is changed in the Modal, it falls into else and the value was already negated in requestHelper
                         */
                        if (propertyConstants.PROPERTY_OVERWRITE in data && Object.keys(data).length === 1) {
                            const updatedParams = {
                                [propertyConstants.PROPERTY_OVERWRITE]: !data[propertyConstants.PROPERTY_OVERWRITE],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_MASTER_REDLIKE_OPTION_OVERWRITE,
                                    methods.METHOD_SET_MASTER_REDLIKE_OPTION_OVERWRITE,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        } else {
                            const updatedParams = {
                                ...payload.params[propertyConstants.PROPERTY_DATA],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_MASTER_REDLIKE_OPTION,
                                    methods.METHOD_SET_MASTER_REDLIKE_OPTION,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    if (payload.params) {
                        const data = { ...payload.params[propertyConstants.PROPERTY_DATA] };

                        /*
                        When the overwrite parameter is changed in the Table directly, it falls into this if statement and the value needs to be negated.
                        However, if the overwrite parameter is changed in the Modal, it falls into else and the value was already negated in requestHelper
                         */
                        if (propertyConstants.PROPERTY_OVERWRITE in data && Object.keys(data).length === 1) {
                            const updatedParams = {
                                [propertyConstants.PROPERTY_OVERWRITE]: !data[propertyConstants.PROPERTY_OVERWRITE],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE,
                                    methods.METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        } else {
                            const updatedParams = {
                                ...payload.params[propertyConstants.PROPERTY_DATA],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SYSTEM_REDLIKE_OPTION,
                                    methods.METHOD_SET_SYSTEM_REDLIKE_OPTION,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    if (payload.params) {
                        const data = { ...payload.params[propertyConstants.PROPERTY_DATA] };

                        /*
                        When the overwrite parameter is changed in the Table directly, it falls into this if statement and the value needs to be negated.
                        However, if the overwrite parameter is changed in the Modal, it falls into else and the value was already negated in requestHelper
                         */
                        if (propertyConstants.PROPERTY_OVERWRITE in data && Object.keys(data).length === 1) {
                            const updatedParams = {
                                [propertyConstants.PROPERTY_OVERWRITE]: !data[propertyConstants.PROPERTY_OVERWRITE],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_ZONE_REDLIKE_OPTION_OVERWRITE,
                                    methods.METHOD_SET_ZONE_REDLIKE_OPTION_OVERWRITE,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        } else {
                            const updatedParams = {
                                ...payload.params[propertyConstants.PROPERTY_DATA],
                                [propertyConstants.PROPERTY_KEY]: payload.params[propertyConstants.PROPERTY_KEY]
                            };

                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_ZONE_REDLIKE_OPTION,
                                    methods.METHOD_SET_ZONE_REDLIKE_OPTION,
                                    { ...updatedParams, returnData: !payload.isModal ? true : undefined }
                                )
                            );
                        }
                    }
                    break;
                }
                // package
                case objectTypes.PACKAGE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_PACKAGE, methods.METHOD_EDIT_PACKAGE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM_PACKAGE, methods.METHOD_EDIT_SYSTEM_PACKAGE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_ZONE_PACKAGE, methods.METHOD_EDIT_ZONE_PACKAGE, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // price
                case objectTypes.SYSTEM_PRICE_GROUP: {
                    if (payload.params && payload.params.data) {
                        if (payload.isModal) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SYSTEM_PRICE_GROUP_DATA,
                                    methods.METHOD_SET_SYSTEM_PRICE_GROUP_DATA,
                                    { data: payload.params.data }
                                )
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SINGLE_SYSTEM_PRICE_GROUP_DATA,
                                    methods.METHOD_SET_SINGLE_SYSTEM_PRICE_GROUP_DATA,
                                    { data: payload.params.data, returnData: true }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_SYSTEM_PRICE_MARGIN,
                                methods.METHOD_EDIT_SYSTEM_PRICE_MARGIN,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRICE_OPTION: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_SET_SYSTEM_PRICE_OPTIONS,
                                methods.METHOD_SET_SYSTEM_PRICE_OPTIONS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRICE_GENERIC: {
                    if (payload.params && payload.params.data) {
                        if (payload.isModal) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SYSTEM_GENERIC_PRICE_DATA,
                                    methods.METHOD_SET_SYSTEM_GENERIC_PRICE_DATA,
                                    { data: payload.params.data }
                                )
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SINGLE_SYSTEM_GENERIC_PRICE_DATA,
                                    methods.METHOD_SET_SINGLE_SYSTEM_GENERIC_PRICE_DATA,
                                    { data: payload.params.data, returnData: true }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.ZONE_PRICE_GROUP: {
                    if (payload.params && payload.params.data) {
                        if (payload.isModal) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_ZONE_PRICE_GROUP_DATA,
                                    methods.METHOD_SET_ZONE_PRICE_GROUP_DATA,
                                    { data: payload.params.data }
                                )
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA,
                                    methods.METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA,
                                    { data: payload.params.data, returnData: true }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_ZONE_PRICE_MARGIN,
                                methods.METHOD_EDIT_ZONE_PRICE_MARGIN,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_PRICE_OPTION: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_SET_ZONE_PRICE_OPTIONS,
                                methods.METHOD_SET_ZONE_PRICE_OPTIONS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_PRICE_GENERIC: {
                    if (payload.params && payload.params.data) {
                        if (payload.isModal) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_ZONE_GENERIC_PRICE_DATA,
                                    methods.METHOD_SET_ZONE_GENERIC_PRICE_DATA,
                                    { data: payload.params.data }
                                )
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA,
                                    methods.METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA,
                                    { data: payload.params.data, returnData: true }
                                )
                            );
                        }
                    }
                    break;
                }
                // privilege
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    const userGroupId =
                        state$.value.userGroup.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;

                    if (userGroupId && payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_SET_REDLIKE_PRIVILEGE, methods.METHOD_SET_REDLIKE_PRIVILEGE, {
                                userGroupId: userGroupId,
                                key: payload.params.key,
                                value: payload.params.data.value,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_MASTER_PRODUCT, methods.METHOD_EDIT_MASTER_PRODUCT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.product.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM_PRODUCT, methods.METHOD_EDIT_SYSTEM_PRODUCT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
                case objectTypes.ZONE_PRODUCT_DUPLICATE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.product.duplicateAllList,
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }

                        requests.push(
                            universalObjectActions.saveItemsNoParams(
                                payload.objectType,
                                generalHelper.updateLists(state$.value.product.duplicateAllList, [activeItem])
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.product.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.ZONE_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_ZONE_PRODUCT, methods.METHOD_EDIT_ZONE_PRODUCT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_MASTER_BASE_IN_PRODUCT,
                                methods.METHOD_EDIT_MASTER_BASE_IN_PRODUCT,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.baseInProduct.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT,
                                methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.baseInProduct.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT,
                                methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                // product base package
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.baseInProductPackage.systemMasterList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        const activeItem = generalHelper.getObjectById(
                            state$.value.baseInProductPackage.systemList,
                            payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                        );

                        if (activeItem) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                                    methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                                    {
                                        baseInProductPackageId:
                                            activeItem[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID],
                                        data: payload.params.data,
                                        returnData: !payload.isModal ? true : undefined
                                    }
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.baseInProductPackage.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params && payload.params.data) {
                        const activeItem = generalHelper.getObjectById(
                            state$.value.baseInProductPackage.zoneList,
                            payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                        );

                        if (activeItem) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE,
                                    methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE,
                                    {
                                        baseInProductPackageId:
                                            activeItem[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID],
                                        data: payload.params.data,
                                        returnData: !payload.isModal ? true : undefined
                                    }
                                )
                            );
                        }
                    }
                    break;
                }
                // product groups
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    const productIds = state$.value.product.systemMasterList.map((item: Product) => item.id);
                    const productGroupId = state$.value.productGroup.systemActiveList
                        ? state$.value.productGroup.systemActiveList[0].id
                        : 0;
                    requests.push(
                        itemsWithParamsActions.editSystemProductGroupWithParams(
                            productGroupId,
                            productIds,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP,
                                methods.METHOD_EDIT_SYSTEM_PRODUCT_GROUP,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP: {
                    // keep this case to avoid client error message
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS: {
                    const productIds = state$.value.product.zoneSystemList.map((item: Product) => item.id);
                    const productGroupId = state$.value.productGroup.zoneActiveList
                        ? state$.value.productGroup.zoneActiveList[0].id
                        : 0;
                    requests.push(
                        itemsWithParamsActions.editZoneProductGroupWithParams(
                            productGroupId,
                            productIds,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.productGroup.zoneSystemList,
                        payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(setActiveItems(payload.objectType, [activeItem]));
                    }

                    requests.push(setActiveItems(payload.objectType, []));

                    break;
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_ZONE_PRODUCT_GROUP,
                                methods.METHOD_EDIT_ZONE_PRODUCT_GROUP,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                }
                // product sheet
                case objectTypes.PRODUCT_SHEET: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_FILE, methods.METHOD_EDIT_PRODUCT_SHEET, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // spectro types
                case objectTypes.SPECTRO_TYPE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_SPECTRO_TYPE,
                                methods.METHOD_EDIT_SPECTRO_TYPE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // static sql
                case objectTypes.STATIC_SQL: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_FILE, methods.METHOD_EDIT_STATIC_SQL, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_SYSTEM, methods.METHOD_EDIT_SYSTEM, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE: {
                    if (payload.params && payload.params.data) {
                        const systemZoneList = [...state$.value.databaseSystemZone.systemZoneList];
                        const propertyList = Object.keys(payload.params.data);
                        const property = propertyList?.[0] ?? null;

                        const activeSystemZone = generalHelper.getObjectById(
                            systemZoneList,
                            payload.params[propertyConstants.PROPERTY_ID]
                        );

                        if (propertyList.length === 1 && property && activeSystemZone) {
                            let updatedSystemZone = {
                                ...activeSystemZone,
                                [property]: payload.params.data[property]
                            };
                            updatedSystemZone = cloneSystemZone(updatedSystemZone);

                            const updatedSystemZoneList = systemZoneList.map((item: SystemZone) =>
                                item[propertyConstants.PROPERTY_ID] ===
                                updatedSystemZone?.[propertyConstants.PROPERTY_ID]
                                    ? updatedSystemZone
                                    : item
                            );

                            requests.push(
                                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE, updatedSystemZoneList)
                            );
                        }
                    }
                    break;
                }
                // unit
                case objectTypes.UNIT: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_UNIT, methods.METHOD_EDIT_UNIT, {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            })
                        );
                    }
                    break;
                }
                // user
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map(
                        (item: UserGroup) => item[propertyConstants.PROPERTY_ID]
                    );
                    const userId = state$.value.user?.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;

                    if (userId) {
                        requests.push(
                            itemsWithParamsActions.editMasterRedlikeUserWithParams(userId, userGroupIds, payload.params)
                        );
                    }

                    break;
                }
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map(
                        (item: UserGroup) => item[propertyConstants.PROPERTY_ID]
                    );
                    const userId = state$.value.user?.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;

                    if (userId) {
                        requests.push(
                            itemsWithParamsActions.editSystemRedlikeUserWithParams(userId, userGroupIds, payload.params)
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map(
                        (item: UserGroup) => item[propertyConstants.PROPERTY_ID]
                    );
                    const userId = state$.value.user?.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;

                    if (userId) {
                        requests.push(
                            itemsWithParamsActions.editZoneRedlikeUserWithParams(userId, userGroupIds, payload.params)
                        );
                    }

                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_EDIT_MASTER_REDLIKE_USER,
                            methods.METHOD_EDIT_MASTER_REDLIKE_USER,
                            {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            }
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_EDIT_SYSTEM_REDLIKE_USER,
                            methods.METHOD_EDIT_SYSTEM_REDLIKE_USER,
                            {
                                ...payload.params,
                                returnData: !payload.isModal ? true : undefined
                            }
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    requests.push(
                        serverRequest(methods.METHOD_EDIT_ZONE_REDLIKE_USER, methods.METHOD_EDIT_ZONE_REDLIKE_USER, {
                            ...payload.params,
                            returnData: !payload.isModal ? true : undefined
                        })
                    );
                    break;
                }
                case objectTypes.USER: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_USER_WITH_USER_GROUPS,
                                methods.METHOD_EDIT_USER_WITH_USER_GROUPS,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );

                        if (payload.params.data.companyId !== undefined) {
                            requests.push(
                                optionActions.setDictionaryOption(
                                    optionsConstants.OPTION_COMPANIES_FOR_USERS,
                                    payload.params.userId,
                                    payload.params.data.companyId
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.USER_GROUP: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_USER_GROUP,
                                methods.METHOD_EDIT_USER_GROUP,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP:
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_EDIT_REDLIKE_USER_GROUP,
                                methods.METHOD_EDIT_REDLIKE_USER_GROUP,
                                {
                                    ...payload.params,
                                    returnData: !payload.isModal ? true : undefined
                                }
                            )
                        );
                    }
                    break;
                case objectTypes.USER_PASSWORD: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_CHANGE_USER_PASSWORD, methods.METHOD_CHANGE_USER_PASSWORD, {
                                ...payload.params.data,
                                confirmationPassword: undefined
                            })
                        );
                    }
                    break;
                }
                // version
                case objectTypes.VERSION: {
                    if (payload.params && payload.params.data) {
                        if (state$.value.navigation.activeScene === navigationTypes.DATA_EXPORT_SCENE) {
                            let updatedSystemZoneList: Array<SystemZone> = [
                                ...state$.value.databaseSystemZone.systemZoneList
                            ];

                            for (const systemZone of state$.value.databaseSystemZone.systemZoneList) {
                                if (systemZone[propertyConstants.PROPERTY_IS_CHECKED]) {
                                    let updatedSystemZone = {
                                        ...systemZone,
                                        [propertyConstants.PROPERTY_VERSION]:
                                            payload.params.data[propertyConstants.PROPERTY_VERSION],
                                        [propertyConstants.PROPERTY_VERSION_NAME]:
                                            payload.params.data[propertyConstants.PROPERTY_VERSION_NAME]
                                    };
                                    updatedSystemZone = cloneSystemZone(updatedSystemZone);

                                    updatedSystemZoneList = updatedSystemZoneList.map((item: SystemZone) =>
                                        item[propertyConstants.PROPERTY_ID] ===
                                        updatedSystemZone?.[propertyConstants.PROPERTY_ID]
                                            ? updatedSystemZone
                                            : item
                                    );
                                }
                            }
                            requests.push(
                                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE, updatedSystemZoneList),
                                navigationActions.navigationCloseModal(modalTypes.MODAL_VERSION_EDIT)
                            );
                        }
                    }
                    break;
                }
                // zone
                case objectTypes.ZONE: {
                    const params = {
                        ...payload.params,
                        returnData: !payload.isModal ? true : undefined,
                        systemId: state$.value.zone.activeList.length
                            ? state$.value.zone.activeList[0][propertyConstants.PROPERTY_SYSTEM_ID]
                            : null
                    };

                    if (params && params.data && params.systemId) {
                        requests.push(serverRequest(methods.METHOD_EDIT_ZONE, methods.METHOD_EDIT_ZONE, params));
                    }
                    break;
                }
                case objectTypes.ZONE_DUPLICATE: {
                    let activeItem = generalHelper.getObjectById(
                        state$.value.zone.duplicateAllList,
                        payload.params[propertyConstants.PROPERTY_ZONE_ID]
                    );

                    if (activeItem) {
                        for (const [key, value] of Object.entries(payload.params.data)) {
                            activeItem = {
                                ...activeItem,
                                [key]: value
                            };
                        }
                        requests.push(
                            universalObjectActions.saveItemsNoParams(
                                payload.objectType,
                                generalHelper.updateLists(state$.value.zone.duplicateAllList, [activeItem])
                            )
                        );
                    }
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
