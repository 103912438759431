import * as propertyConstants from "../../../constants/propertyConstants";
import * as serverConstants from "../../../constants/serverConstants";

import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { findDOMNode } from "react-dom";

import imgLoader from "../../../resources/img/loader.svg";

import { translate } from "react-i18nify";

/**
 * className: additional classname for the select box
 * editable: parameter which decides whether the item is editable
 * required: decides whether the item is required or not
 * value: selected value from the option list
 * title: stores title which is above the select box (is optional)
 * options: array of options which are displayed in the select box
 * autoselect: decides whether first value from options is automatically selected
 * callback: method when the user clicks on a certain item and selects it from the select box
 * callbackFilter: method used in the filter input which filters options based on the user input
 */

type Props = {
    className: string;
    editable: boolean;
    required: boolean;
    value: number;
    title?: string;
    loading?: boolean;
    options: Array<{ key: string; value: string }>;
    autoselect: boolean;
    callback: (value: string) => any;
    callbackFilter: (value: string) => any;
};

type State = {
    selectOn: boolean;
    filter: string;
    timeoutId: NodeJS.Timeout | null;
};

export class ModalFilterSelectbox extends Component<Props, State> {
    _selectRef = React.createRef<HTMLDivElement>();

    state: State = {
        selectOn: false,
        filter: "",
        timeoutId: null
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeSelect);

        if (this.props.autoselect && !this.state.selectOn && this.props.options.length) {
            if (!this.props.value) {
                const newOption = this.props.options[0];
                this.props.callback(newOption ? newOption[propertyConstants.PROPERTY_KEY] : "");
                this.setState({ filter: newOption ? newOption[propertyConstants.PROPERTY_VALUE] : "" });
            } else {
                const newOption =
                    this.props.options.find((item: any) => item.key === this.props.value.toString()) || null;
                this.setState({
                    filter: newOption ? newOption[propertyConstants.PROPERTY_VALUE] : ""
                });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            this.props.value !== prevProps.value ||
            JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)
        ) {
            if (this.props.autoselect && !this.state.selectOn && this.props.options.length) {
                if (!this.props.value) {
                    const newOption = this.props.options[0];
                    this.props.callback(newOption ? newOption[propertyConstants.PROPERTY_KEY] : "");
                    this.setState({ filter: newOption ? newOption[propertyConstants.PROPERTY_VALUE] : "" });
                } else {
                    const newOption =
                        this.props.options.find((item: any) => item.key === this.props.value.toString()) || null;
                    this.setState({
                        filter: newOption ? newOption[propertyConstants.PROPERTY_VALUE] : ""
                    });
                }
            }
        }

        if (prevState.selectOn !== this.state.selectOn) {
            if (!this.state.selectOn && this.props.options.length === 0) {
                this.props.callbackFilter("");
            }
        }

        if (this.state.selectOn && this.state.filter !== prevState.filter) {
            this.setTimeout();
        }
    }

    componentWillUnmount(): void {
        const { timeoutId } = this.state;

        window.removeEventListener("click", this.closeSelect);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    handleSelectClick = (): void => {
        this.setState({ selectOn: !this.state.selectOn });
    };

    handleSelectClickOpen = (): void => {
        if (!this.state.selectOn) {
            this.setState({ selectOn: true });
        }
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.handleSelectClickOpen();

        this.setState({ filter: event.target.value });
    };

    handleBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.props.options.length) {
            // Set either a new value, or previous value or null
            const option =
                this.props.options.find((item) => item[propertyConstants.PROPERTY_VALUE] === event.target.value) ||
                this.props.options.find(
                    (item) => item[propertyConstants.PROPERTY_KEY] === this.props.value?.toString()
                ) ||
                null;

            this.setState({ filter: option?.[propertyConstants.PROPERTY_VALUE] || "" });
            this.props.callback(option?.[propertyConstants.PROPERTY_KEY] || "");
        }
    };

    handleOptionClick = (key: string): void => {
        this.setState({
            selectOn: false
        });

        this.props.callback(key);
    };

    setTimeout = () => {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
            this.setState({ timeoutId: null });
        }

        const timeout = setTimeout(
            () => this.props.callbackFilter(this.state.filter),
            serverConstants.SERVER_SEARCH_TIMEOUT
        );

        this.setState({ timeoutId: timeout });
    };

    closeSelect = (event: Record<string, any>): void => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                selectOn: false
            });
        }
    };

    getClassName = (): string => {
        let newClassName = "input-type-select filter-select";

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        if (this.state.selectOn) {
            newClassName += " active";
        }

        return newClassName;
    };

    getOptions = (): JSX.Element | null => {
        const { options, loading } = this.props;
        const optionList = [];

        if (loading && this.state.selectOn) {
            return (
                <div className="select-dropdown">
                    <div className="select-options loader">
                        <ReactSVG src={imgLoader} className="loader-svg" />
                    </div>
                </div>
            );
        }

        if (!this.props.required || this.props.value === -1) {
            optionList.push(<div key="">{""}</div>);
        }

        for (const option of options) {
            const className = option.key === this.props.value.toString() ? "select-option active" : "select-option";

            optionList.push(
                <div key={option.key} className={className} onClick={(): void => this.handleOptionClick(option.key)}>
                    {option.value}
                </div>
            );
        }

        if (this.state.selectOn) {
            return (
                <div className="select-dropdown">
                    <div className="slim-scroll select-options">{optionList}</div>
                </div>
            );
        }

        return null;
    };

    getTitle = (): JSX.Element | null => {
        if (this.props.title) {
            return <span className="label-title">{this.props.title}:</span>;
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                {this.getTitle()}
                <div
                    className={this.getClassName()}
                    ref={this._selectRef}
                    onClick={(): void => this.handleSelectClickOpen()}
                >
                    <input
                        placeholder={translate("general.typeHereFilter")}
                        className="selected-value"
                        value={this.state.filter}
                        onChange={(event: any): void => this.handleChange(event)}
                        onBlur={(event: any): void => this.handleBlur(event)}
                    />
                    <div className="filter-select-button" onClick={(): void => this.handleSelectClick()}>
                        <div className="caret" />
                    </div>
                    {this.getOptions()}
                </div>
            </div>
        );
    }
}
