import * as colorHelper from "../../../helpers/colorHelper";
import * as formulaConstants from "../../../constants/entityConstants/formulaConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as universalObjectActions from "../../../actions/universalObjectActions";

import React, { Component } from "react";

import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import { t as translate } from "react-i18nify";

type SelectboxProps = {
    modalId?: number;
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    className: string;
    editable: boolean;
    required: boolean;
    value: number | boolean | string;
    secondValue: number | boolean | string;
    options: Array<any>;
    callback: (value: string, otherValue: string) => any;
};

type OwnProps = PropsType & DispatchType;

type Props = OwnProps & SelectboxProps;

type State = {
    selectOn: boolean;
};

class ComplexSelectbox extends Component<Props, State> {
    _selectRef = React.createRef<HTMLDivElement>();
    state: State = {
        selectOn: false
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeSelect);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeSelect);
    }

    handleOptionClick = (value: string, otherValue: string): void => {
        this.setState({
            selectOn: false
        });

        this.props.callback(value, otherValue);
    };

    handleSelectClick = (): void => {
        this.setState({ selectOn: !this.state.selectOn });
    };

    handlePriorityButtonClick = (event: React.MouseEvent<HTMLButtonElement>, position?: number, params?: any): void => {
        event.stopPropagation();

        if (position !== undefined && position >= 0 && params !== undefined) {
            // Position on server side starts with 1, we work with index until now
            this.props.setItemPosition(this.props.objectType, { ...params, position: position + 1 });
        }
    };

    closeSelect = (event: Record<string, any>): void => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                selectOn: false
            });
        }
    };

    getClassName = (): string => {
        let newClassName = "input-type-select";

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        if (this.state.selectOn) {
            newClassName += " active";
        }

        return newClassName;
    };

    getOptionClassName = (value: Record<string, any>): string => {
        let className = "select-option priority-buttons-container";

        if (
            value[propertyConstants.PROPERTY_ID] === this.props.value &&
            value[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] === this.props.secondValue
        ) {
            className += " active";
        }

        if (
            value?.[propertyConstants.PROPERTY_STATUS] !== formulaConstants.FORMULA_STATUS_ACCEPTED &&
            value?.[propertyConstants.PROPERTY_STATUS] !== formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED
        ) {
            className += " not-accepted";
        }

        return className;
    };

    getStatus = (value: any): JSX.Element => {
        return (
            <span className="status-container option-container">
                <span className="title">{translate("general.status")}:</span>
                {colorHelper.getFormulaStatus(value[propertyConstants.PROPERTY_STATUS], true, true)}
            </span>
        );
    };

    getPriorityIcons = (index: number, value: any): JSX.Element | null => {
        if (this.props.objectType !== objectTypes.MASTER_FORMULA) {
            return null;
        }

        const previousItem = index > 0 ? this.props.options[index - 1] : null;
        const nextItem = index < this.props.options.length ? this.props.options[index + 1] : null;
        const params = {
            [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]:
                value?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null,
            [propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]:
                value?.[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] ?? null,
            [propertyConstants.PROPERTY_FORMULA_ID]: value?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null,
            [propertyConstants.PROPERTY_PRODUCT_ID]: value?.[propertyConstants.PROPERTY_PRODUCT_ID] ?? null
        };
        let upPriorityClassName = "btn-info hidden";
        let downPriorityClassName = "btn-info hidden";

        if (
            value[propertyConstants.PROPERTY_STATUS] === formulaConstants.FORMULA_STATUS_HISTORICAL ||
            value[propertyConstants.PROPERTY_STATUS] === formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED
        ) {
            const firstFormulaIndexByBase =
                this.props.options.findIndex(
                    (item) =>
                        item.value?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ===
                        value[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]
                ) ?? undefined;

            return (
                <div className="priority-buttons">
                    <button
                        className="btn-info double-caret"
                        onClick={(event) => this.handlePriorityButtonClick(event, firstFormulaIndexByBase, params)}
                    >
                        <span className="caret caret-up" />
                        <span className="caret caret-up" />
                    </button>
                </div>
            );
        }

        if (
            (value[propertyConstants.PROPERTY_STATUS] === formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED ||
                value[propertyConstants.PROPERTY_STATUS] === formulaConstants.FORMULA_STATUS_ACCEPTED) &&
            this.props.dataPrivileges?.canUpdate
        ) {
            if (previousItem && previousItem[propertyConstants.PROPERTY_KEY] !== "") {
                upPriorityClassName = "btn-info";
            }

            if (
                nextItem &&
                nextItem[propertyConstants.PROPERTY_VALUE] &&
                (nextItem[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_STATUS] ===
                    formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED ||
                    nextItem[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_STATUS] ===
                        formulaConstants.FORMULA_STATUS_ACCEPTED)
            ) {
                downPriorityClassName = "btn-info";
            }
        }

        return (
            <div className="priority-buttons">
                <button
                    className={upPriorityClassName}
                    onClick={(event) => this.handlePriorityButtonClick(event, index - 1, params)}
                >
                    <span className="caret caret-up" />
                </button>
                <button
                    className={downPriorityClassName}
                    onClick={(event) => this.handlePriorityButtonClick(event, index + 1, params)}
                >
                    <span className="caret caret-down" />
                </button>
            </div>
        );
    };

    getOptions = (): JSX.Element | null => {
        const optionList = [];

        if (!this.props.required) {
            optionList.push(
                <div className="select-option" key="" onClick={(): void => this.handleOptionClick("", "")}>
                    {""}
                </div>
            );
        }

        for (const option of this.props.options) {
            const index = this.props.options.indexOf(option);
            const value = option[propertyConstants.PROPERTY_VALUE];

            optionList.push(
                <div
                    key={option[propertyConstants.PROPERTY_KEY]}
                    className={this.getOptionClassName(value)}
                    onClick={(): void =>
                        this.handleOptionClick(
                            value?.[propertyConstants.PROPERTY_ID]?.toString() || "",
                            value?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]?.toString() || ""
                        )
                    }
                >
                    <div className="more-options">
                        <span className="base-section">
                            <span className="title">{translate("base.base")}:</span>{" "}
                            <span className="ellipsis-span">{value[propertyConstants.PROPERTY_BASE_NAME]}</span>
                        </span>
                        {this.getStatus(value)}
                        <span className="date-section">
                            <span className="title">{translate("general.created")}:</span>{" "}
                            <span className="ellipsis-span">{value[propertyConstants.PROPERTY_DATE_CREATED]}</span>
                        </span>
                    </div>
                    {this.getPriorityIcons(index, value)}
                </div>
            );
        }

        // TODO maxHeight pocitat v designHelperu? je i v ModalSelectboxu
        // max possible height for options in modals and tables
        const modal = document.getElementById(`modal-${this.props.modalId || ""}`);
        const modalEl: any = modal?.querySelector(".modal-content");
        const tableEl: any = document.querySelector(".table");
        const containerHeight = modalEl ? modalEl.offsetHeight : tableEl ? tableEl.offsetHeight - 30 : null;
        const maxHeight =
            containerHeight !== null && this._selectRef.current !== null
                ? containerHeight - this._selectRef.current.offsetTop
                : 100;

        if (this.state.selectOn) {
            return (
                <div className="select-dropdown">
                    <div className="slim-scroll select-options" style={{ maxHeight: maxHeight + "px" }}>
                        {optionList}
                    </div>
                </div>
            );
        }

        return null;
    };

    getNameForSelectBox = (): string | JSX.Element => {
        for (const option of this.props.options) {
            const value: any = option[propertyConstants.PROPERTY_VALUE];

            if (
                value[propertyConstants.PROPERTY_ID] === this.props.value &&
                value[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] === this.props.secondValue
            ) {
                return (
                    <div className="more-options">
                        <span className="base-section">
                            <span className="title">{translate("base.base")}:</span>{" "}
                            <span className="ellipsis-span">{value[propertyConstants.PROPERTY_BASE_NAME]}</span>
                        </span>
                        {this.getStatus(value)}
                        <span className="date-section">
                            <span className="title">{translate("general.created")}:</span>{" "}
                            <span className="ellipsis-span">{value[propertyConstants.PROPERTY_DATE_CREATED]}</span>
                        </span>
                    </div>
                );
            }
        }

        return "";
    };

    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <div
                    className={this.getClassName()}
                    ref={this._selectRef}
                    onClick={(): void => this.handleSelectClick()}
                >
                    <span className="selected-value">{this.getNameForSelectBox()}</span>
                    {this.getOptions()}
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    setItemPosition(objectType: objectTypes.ObjectType, params: any): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setItemPosition: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.setItemPosition(objectType, params))
});

export const ComplexSelectboxContainer = connect(mapStateToProps, mapDispatchToProps)(ComplexSelectbox);
