import * as actionConstants from "../constants/actionTypes";
import * as localConfig from "../constants/localConfig";
import * as serverConstants from "../constants/serverConstants";

import { ServerRequest, updateServerRequest } from "../types/serverRequest";

export type ServerState = Readonly<{
    connectionType: serverConstants.ConnectionType;
    connected: boolean;
    requests: Array<ServerRequest>;
    sequence: number;
    urlWebsocket: string;
    urlRest: string;
    serverVersion: string | null;
    sqliteVersion: string | null;
    sqlcipherVersion: string | null;
}>;

const initialState: ServerState = {
    connectionType:
        localConfig.SERVER_CONNECTION_TYPE === serverConstants.CONNECTION_REST ||
        localConfig.SERVER_CONNECTION_TYPE === serverConstants.CONNECTION_WEBSOCKET
            ? localConfig.SERVER_CONNECTION_TYPE
            : serverConstants.CONNECTION_WEBSOCKET,
    connected: false,
    requests: [],
    sequence: 0,
    urlWebsocket: `${localConfig.SERVER_WEBSOCKET_PROTOCOL}://${localConfig.SERVER_WEBSOCKET_HOST}:${localConfig.SERVER_WEBSOCKET_PORT}`,
    urlRest: `${localConfig.SERVER_REST_PROTOCOL}://${localConfig.SERVER_REST_HOST}:${localConfig.SERVER_REST_PORT}`,
    serverVersion: null,
    sqliteVersion: null,
    sqlcipherVersion: null
};

export default function server(state: ServerState = initialState, action: Record<string, any>): ServerState {
    switch (action.type) {
        // TODO check difference SERVER_WEBSOCKET_OPENED a SERVER_CONNECT
        case actionConstants.SERVER_WEBSOCKET_OPENED: {
            return {
                ...state,
                connected: true
            };
        }
        case actionConstants.SERVER_CONNECT: {
            return {
                ...state,
                connected: false
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return {
                ...state,
                connected: false,
                requests: [],
                sequence: 0
            };
        }
        case actionConstants.SERVER_SAVE_REQUEST: {
            const newRequest = new ServerRequest(action.payload.sequence, action.payload.method);
            const newRequests = [];

            for (const request of state.requests) {
                if (request.method === newRequest.method) {
                    const updatedServerRequest = updateServerRequest(request, {
                        skip: true
                    });

                    if (updatedServerRequest) {
                        newRequests.push(updatedServerRequest);
                    }
                } else {
                    newRequests.push(request);
                }
            }

            newRequests.push(newRequest);

            return {
                ...state,
                requests: newRequests,
                sequence: action.payload.sequence
            };
        }
        case actionConstants.SERVER_REMOVE_REQUEST: {
            const filteredRequests = state.requests.filter((request) => request.id !== action.payload.sequence);

            return {
                ...state,
                requests: filteredRequests
            };
        }
        case actionConstants.SAVE_VERSION: {
            return {
                ...state,
                serverVersion: action.payload.serverVersion
            };
        }
        case actionConstants.SAVE_SQLITE_INFO: {
            return {
                ...state,
                sqlcipherVersion: action.payload.sqlcipherVersion,
                sqliteVersion: action.payload.sqliteVersion
            };
        }
        default: {
            return state;
        }
    }
}
