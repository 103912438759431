import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionActions from "../../../../actions/optionActions";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Database } from "../../../../types/database";
import { Dispatch } from "redux";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    tableConstant: string;
    dataPrivileges: any;
    privileges: any;
    editable: boolean;
    value: boolean;
    isSecond: boolean;
};

type Props = OwnProps & ContainerProps;

class TableCheckbox extends Component<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.value !== prevProps.value &&
            !objectTypeHelper.isObjectTypeRedlikeOption(this.props.objectType)
        ) {
            this.props.reloadData(this.props.objectType);
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { objectType } = this.props;

        const itemVisibility = optionHelper.getTableItemsVisibilityOption(this.props.objectType, this.props.isSecond);

        if (
            objectTypeHelper.isObjectTypeRedlikeOption(objectType) ||
            objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE
        ) {
            this.props.setShowSpecificValues(this.props.objectType, event.target.checked);
        }

        if (itemVisibility) {
            this.props.setValue(itemVisibility, event.target.checked);
        }
    };

    isVisible = (): boolean => {
        const { isSecond, objectType, privileges } = this.props;

        if (
            (objectTypeHelper.isObjectTypeRedlikeOption(objectType) ||
                objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) &&
            !this.props.isSecond
        ) {
            return true;
        }

        if (objectTypeHelper.isObjectTypeExportImport(objectType)) {
            if (isSecond) {
                if (
                    (objectType === objectTypes.EXPORT_TASK &&
                        !optionHelper.isPrivilegeAvailable(
                            privileges?.[privilegeConstants.PRIVILEGE_EXPORT_DELETE_TASK_SHOW]
                        )) ||
                    (objectType === objectTypes.IMPORT_TASK &&
                        !optionHelper.isPrivilegeAvailable(
                            privileges?.[privilegeConstants.PRIVILEGE_IMPORT_DELETE_TASK_SHOW]
                        ))
                ) {
                    return false;
                }
            } else if (
                (objectType === objectTypes.EXPORT_TASK &&
                    !optionHelper.isPrivilegeAvailable(
                        privileges?.[privilegeConstants.PRIVILEGE_EXPORT_HIDE_TASK_SHOW]
                    )) ||
                (objectType === objectTypes.IMPORT_TASK &&
                    !optionHelper.isPrivilegeAvailable(
                        privileges?.[privilegeConstants.PRIVILEGE_IMPORT_HIDE_TASK_SHOW]
                    ))
            ) {
                return false;
            }
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { objectType } = this.props;

        if (
            !objectTypeHelper.isObjectTypeEnabledByHeader(
                this.props.objectType,
                this.props.activeDatabase ?? null,
                this.props.activeSystem ?? null,
                this.props.activeZone ?? null
            )
        ) {
            return false;
        }

        if (objectTypes.VISIBLE_TABLE_CHECKBOX_OBJECT_TYPES.includes(objectType) && this.props.editable) {
            return true;
        }

        return false;
    };

    getClassName = (): string => {
        let newClassName = "checkbox-button btn-info";
        if (!this.props.isSecond) {
            newClassName += " margin-left";
        }

        if (!this.isEnabled()) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getCaption = (): string => {
        const { objectType } = this.props;

        switch (objectType) {
            case objectTypes.EXPORT_TASK:
            case objectTypes.IMPORT_TASK:
                if (this.props.isSecond) {
                    return translate("general.showDeletedValues");
                }
                return translate("general.showHiddenValues");
            case objectTypes.MASTER_REDLIKE_OPTION:
            case objectTypes.SYSTEM_REDLIKE_OPTION:
            case objectTypes.ZONE_REDLIKE_OPTION:
                return translate("options.showDefaultValues");
            case objectTypes.MASTER_REDLIKE_PRIVILEGE:
                return translate("privileges.showOnlyNonDefaultValues");
            default:
                return "";
        }
    };

    render(): JSX.Element | null {
        const { editable } = this.props;

        if (this.isVisible()) {
            return (
                <div className={this.getClassName()}>
                    <label className={"checkbox-label"}>
                        {this.getCaption()}
                        <input
                            type={"checkbox"}
                            name={"show"}
                            className={"checkbox"}
                            checked={this.props.value}
                            readOnly={!editable}
                            disabled={!editable}
                            onChange={(event): void => this.handleChange(event)}
                        />
                        <span className={"checkmark"}></span>
                    </label>
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
}>;

export type DispatchType = Readonly<{
    reloadData(objectType: objectTypes.ObjectType): void;
    setValue(tableConstant: string, value: boolean): void;
    setShowSpecificValues(objectType: objectTypes.ObjectType, showSpecificValues: boolean): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    reloadData: (objectType: objectTypes.ObjectType): any => dispatch(universalObjectActions.reloadData(objectType)),
    setValue: (tableConstant: string, value: boolean): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY, tableConstant, value)
        ),
    setShowSpecificValues: (objectType: objectTypes.ObjectType, showSpecificValues: boolean): any =>
        dispatch(universalObjectActions.setShowSpecificValues(objectType, showSpecificValues))
});

export const TableCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(TableCheckbox);
