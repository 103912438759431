// formula statuses
export type FormulaStatus =
    | typeof FORMULA_STATUS_DISABLED
    | typeof FORMULA_STATUS_CANCELED_REJECTED
    | typeof FORMULA_STATUS_CANCELED
    | typeof FORMULA_STATUS_HISTORICAL_VERIFIED
    | typeof FORMULA_STATUS_HISTORICAL
    | typeof FORMULA_STATUS_ACCEPTED_VERIFIED
    | typeof FORMULA_STATUS_ACCEPTED
    | typeof FORMULA_STATUS_NOT_AVAILABLE;

export const FORMULA_STATUS_DISABLED = "no_formula";
export const FORMULA_STATUS_CANCELED_REJECTED = "rejected";
export const FORMULA_STATUS_CANCELED = "disabled";
export const FORMULA_STATUS_HISTORICAL_VERIFIED = "historicalVerified";
export const FORMULA_STATUS_HISTORICAL = "historical";
export const FORMULA_STATUS_ACCEPTED_VERIFIED = "verified";
export const FORMULA_STATUS_ACCEPTED = "enabled";
export const FORMULA_STATUS_NOT_AVAILABLE = "not_available";

// default formula parameter
export const DEFAULT_FORMULA_UPDATE_ONLY = true;

export const COLOR = "color";
export const FORMULA = "formula";
export const FORMULA_COLORANT = "formulaColorant";
export const PRODUCT_IN_COLOR = "productInColor";
