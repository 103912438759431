import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { SpectroType, mapSpectroType } from "../../types/spectroType";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processWebsocketResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SPECTRO_TYPES_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const spectroTypes: Array<SpectroType> = [];
            let spectroType: SpectroType | null;

            for (const item of response.data.data) {
                spectroType = mapSpectroType(item);

                if (spectroType) {
                    spectroTypes.push(spectroType);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.SPECTRO_TYPE, spectroTypes)];
        }
        case methods.METHOD_GET_ALL_SPECTRO_TYPES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const spectroTypes: Array<SpectroType> = [];
            let spectroType: SpectroType | null;

            for (const item of response.data.data) {
                spectroType = mapSpectroType(item);

                if (spectroType) {
                    spectroTypes.push(spectroType);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SPECTRO_TYPE, spectroTypes, response.data.count)];
        }
        case methods.METHOD_ADD_SPECTRO_TYPE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SPECTRO_TYPE),
                universalObjectActions.getAllItems(objectTypes.SPECTRO_TYPE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SPECTRO_TYPE_ADD)
            ];
        }
        case methods.METHOD_EDIT_SPECTRO_TYPE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SPECTRO_TYPE),
                universalObjectActions.getAllItems(objectTypes.SPECTRO_TYPE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SPECTRO_TYPE_EDIT)
            ];
        }
        case methods.METHOD_DELETE_SPECTRO_TYPE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SPECTRO_TYPE),
                universalObjectActions.getAllItems(objectTypes.SPECTRO_TYPE),
                universalObjectActions.setActiveItems(objectTypes.SPECTRO_TYPE, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
