import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { File } from "../../../types/file";
import { FileCategory } from "../../../types/fileCategory";
import { FileType } from "../../../types/fileType";
import { GenericProductSheetScene } from "../../../components/scenes/generic/GenericProductSheetScene";
import { MenuItem } from "../../../types/menu";

import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    categoryList: Array<FileCategory>;
    fileTypeList: Array<FileType>;
    productSheetTableConstant: string;
    productSheetList: Array<File>;
    productSheetActiveList: Array<File>;
    productSheetLoading: boolean;
    productSheetButtonLoading: boolean;
    productSheetColumnOrder: Array<propertyConstants.Property>;
    productSheetColumnVisibility: Record<propertyConstants.Property, boolean>;
    productSheetColumnWidth: Record<propertyConstants.Property, number>;
    productSheetOffset: number;
    productSheetPage: number;
    productSheetRowCount: number;
    productSheetRowCountCustom: boolean;
    productSheetSceneTableSize: Record<string, any>;
    productSheetSearch: string | null;
    productSheetSearchParams: Record<propertyConstants.Property, string>;
    productSheetShowFilterRow: boolean;
    productSheetShowGlobalSearch: boolean;
    productSheetSortingAsc: boolean;
    productSheetSortingCriterion: propertyConstants.Property | null;
    productSheetTotalCount: number | null;
    sceneLayout: string;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_PRODUCT_SHEETS
    ),
    categoryList: state.fileCategory.allList,
    fileTypeList: state.file.productSheetFileTypeList,
    productSheetTableConstant: menuConstants.TABLE_GENERIC_PRODUCT_SHEETS,
    productSheetList: state.file.productSheetList,
    productSheetActiveList: state.file.productSheetActiveList,
    productSheetLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_PRODUCT_SHEETS ||
            request.method === methods.METHOD_GET_ALL_FILE_CATEGORIES ||
            request.method === methods.METHOD_DELETE_PRODUCT_SHEET
    ),
    productSheetButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_PRODUCT_SHEET
    ),
    productSheetColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetOffset: state.file.productSheetOffset,
    productSheetPage: state.file.productSheetPage,
    productSheetRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetSearch: state.file.productSheetSearch,
    productSheetSearchParams: state.file.productSheetSearchParams,
    productSheetShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    productSheetSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
        )
    ),
    productSheetSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
        )
    ),
    productSheetTotalCount: state.file.productSheetCount,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_PRODUCT_SHEETS
    )
});

export const GenericProductSheetSceneContainer = connect(mapStateToProps)(GenericProductSheetScene);
