import * as defaultConstants from "../constants/default";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { l as localize, t as translate } from "react-i18nify";

import { Currency } from "../types/currency";

// Decimal
/**
 * Formats and rounds decimal value to local. It is rounded up to 'rounding' decimal places.
 * @param value
 * @param rounding
 */
export const formatDecimal = (value: number, rounding: number = defaultConstants.DEFAULT_ROUNDING): string => {
    if (typeof value !== "number") {
        return value;
    }

    const rounded = Number(value.toFixed(rounding));

    return localize(rounded, { style: "decimal" });
};

/**
 * Formats and rounds decimal value to local. It always rounds exactly to 'rounding' decimal places.
 * @param value
 * @param rounding
 */
export const formatDecimalPrecise = (value: number, rounding: number = defaultConstants.DEFAULT_ROUNDING): string => {
    if (typeof value !== "number") {
        return value;
    }

    const rounded = Number(value.toFixed(rounding));

    return localize(rounded, { style: "decimal", minimumFractionDigits: rounding, maximumFractionDigits: rounding });
};

// Currency + prices
/**
 * Rounds currency values according to currency rounding (0.1, 0.01).
 * @param value
 * @param currency
 */
export const roundCurrency = (value: number, currency: Currency | null = null): number => {
    if (typeof value !== "number") {
        return value;
    }

    const rounding = currency?.[propertyConstants.PROPERTY_ROUNDING] || 0;
    const roundingParam = rounding?.toString()?.split(".")?.[1]?.length || 0;
    const roundedValue = roundingParam ? Number(value.toFixed(roundingParam)) : value;

    return roundedValue;
};

/**
 * Rounds and formats currency values according to currency rounding.
 * @param value
 * @param currency
 */
export const formatCurrencyDecimal = (value: number, currency: Currency | null = null): string => {
    return localize(roundCurrency(value, currency), { style: "decimal" });
};

/**
 *
 * @param value
 */
export const formatPriceWithoutUnit = (value: string): string => {
    if (value !== null) {
        value = formatPercentWithoutSign(value);
        // Removes HTML tag from value
        const valueWithoutTag = value.replace(/<[^>]*>/g, "");
        return valueWithoutTag.replace(/\s.*/g, "");
    }

    return value;
};

/**
 * Formats generic percent prices.
 * @param property
 * @param value
 */
export const formatPriceFromUnit = (property: string, value: any): string => {
    if (value === null || value === undefined || isNaN(value)) {
        return "";
    }

    if (
        property === priceConstants.PRICE_GENERIC_PRODUCT_VAT ||
        property === priceConstants.PRICE_GENERIC_COLORANT_VAT
    ) {
        return formatFromPercent(value);
    }

    return value;
};

// Percent
/**
 * Recalculates server percent value 0.6 to 60, and rounds the value
 */
export const formatServerPercentValue = (value: number | null): number | null => {
    return value ? formatRoundedDecimal(value * 100, 2) : null;
};

/**
 * Formats percent values, contains percent sign.
 * @param value
 */
export const formatPercent = (value: any): string => {
    if (typeof value !== "number") {
        return value;
    }

    return localize(value, {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });
};

// TODO should contain formatPercent method?
/**
 * Formats string with percent sign so that it does not contain the sign anymore
 * @param value
 */
export const formatPercentWithoutSign = (value: string | null | undefined): string => {
    if (value === null || value === undefined) {
        return "";
    }

    if (value.charAt(value.length - 1) === "%") {
        value = value.substr(0, value.length - 1);
    }

    return value;
};

/**
 * Formats number percent value (without percent sign) from 90 to 0.9 so that server can accept it.
 * @param value
 */
export const formatFromPercent = (value: any): string => {
    if (value === null || value === undefined || isNaN(value) || value.trim() === "") {
        return "";
    }

    return (Number(value) / 100).toString();
};

// Date + time
/**
 * Formats to date with time string.
 * @param value
 */
export const formatDateTime = (value: string | null): string => {
    const utcTime = formatUTCTime(value);

    return utcTime ? localize(utcTime, { dateFormat: "yyyy/MM/dd HH:mm" }) : "";
};

/**
 * Formats to date without time string.
 * @param value
 */
export const formatDate = (value: string | null): string => {
    const utcTime = formatUTCTime(value);

    return utcTime ? localize(utcTime, { dateFormat: "yyyy/MM/dd" }) : "";
};

/**
 * Transforms date and time from UTC time to the local time.
 * @param value
 */
export const formatUTCTime = (value: string | null): string | null => {
    if (value === null) {
        return null;
    }

    const dateNumeric = Date.parse(value);

    if (!dateNumeric) {
        return null;
    }

    const dateShifted = new Date(dateNumeric - new Date().getTimezoneOffset() * 60000);

    return dateShifted.toString();
};

/**
 * Formats date and time from Date to server string
 * @param date: Date
 */
export const formatDateTimeForServer = (date: Date | null) => {
    if (!date) {
        return null;
    }

    function getTwoDecimalValues(value: number): string {
        return value < 10 ? `0${value.toString()}` : value.toString();
    }

    try {
        return `${date.getFullYear()}-${getTwoDecimalValues(date.getMonth() + 1)}-${getTwoDecimalValues(
            date.getDate()
        )} ${getTwoDecimalValues(date.getHours())}:${getTwoDecimalValues(date.getMinutes())}:${getTwoDecimalValues(
            date.getSeconds()
        )}`;
    } catch {
        return null;
    }
};

/**
 * Converts minutes to milliseconds.
 * @param minutes
 */
export const formatMinuteToMilliseconds = (minutes: number): number => {
    return minutes * 60 * 1000;
};

/**
 * Converts seconds to days.
 * @param minutes
 */
export const formatSecondsToDays = (seconds: number): number => {
    return seconds / 3600 / 24;
};

// Colors
/**
 * Converts R, G, B to hex values
 * @param color
 */
export const convertColorToHex = (color: number | null | undefined): string => {
    const hex = (color || 0).toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts numbers for red, green and blue to single hex value
 * @param red
 * @param green
 * @param blue
 */
export const convertRgbToHex = (
    red: number | null | undefined,
    green: number | null | undefined,
    blue: number | null | undefined
): string | null => {
    if (
        (red === null && green === null && blue === null) ||
        (red === undefined && green === undefined && blue === undefined)
    ) {
        return null;
    }

    return "#" + convertColorToHex(red) + convertColorToHex(green) + convertColorToHex(blue);
};

/**
 * Converts hex color value to single red, green and blue values
 * @param hexColor
 */
export const convertHexToRgb = (hexColor: string): { red: number; green: number; blue: number } | null => {
    if (hexColor === null) {
        return null;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor.toLowerCase());

    if (result) {
        return {
            red: parseInt(result[1], 16),
            green: parseInt(result[2], 16),
            blue: parseInt(result[3], 16)
        };
    }

    return null;
};

/**
 * Updates red, green and blue values to hex value.
 * @param property
 * @param value
 * @param object
 */
export const formatRgbHex = (property: propertyConstants.Property, value: any, object: any): string | null => {
    if (typeof value !== "number" && value !== null) {
        return null;
    }

    if (property === propertyConstants.PROPERTY_COLOR_RED) {
        return convertRgbToHex(
            value,
            object?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? null,
            object?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? null
        );
    }

    if (property === propertyConstants.PROPERTY_COLOR_GREEN) {
        return convertRgbToHex(
            object?.[propertyConstants.PROPERTY_COLOR_RED] ?? null,
            value,
            object?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? null
        );
    }

    if (property === propertyConstants.PROPERTY_COLOR_BLUE) {
        return convertRgbToHex(
            object?.[propertyConstants.PROPERTY_COLOR_RED] ?? null,
            object?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? null,
            value
        );
    }

    return null;
};

export const formatRoundedDecimal = (value: number, rounding: number = defaultConstants.DEFAULT_ROUNDING): number => {
    if (typeof value !== "number") {
        return value;
    }

    return Number(value.toFixed(rounding));
};

// Files
export const formatFileSize = (
    bytes: number | null | undefined,
    forcedUnit: defaultConstants.SizeUnit | null = null
): string | null => {
    if (bytes === null || bytes === undefined) {
        return null;
    }

    if (bytes < defaultConstants.SIZE_KB || forcedUnit === defaultConstants.SIZE_UNIT_B) {
        return `${bytes} ${bytes > 1 ? translate("general.sizeBytes") : translate("general.sizeByte")}`;
    }

    if (
        (defaultConstants.SIZE_KB <= bytes && bytes < defaultConstants.SIZE_MB) ||
        forcedUnit === defaultConstants.SIZE_UNIT_KB
    ) {
        return `${formatDecimal(bytes / defaultConstants.SIZE_KB, 1)} ${translate("general.sizeKb")}`;
    }

    if (
        (defaultConstants.SIZE_MB <= bytes && bytes < defaultConstants.SIZE_GB) ||
        forcedUnit === defaultConstants.SIZE_UNIT_MB
    ) {
        return `${formatDecimal(bytes / defaultConstants.SIZE_MB, 1)} ${translate("general.sizeMb")}`;
    }

    if (
        (defaultConstants.SIZE_GB <= bytes && bytes < defaultConstants.SIZE_TB) ||
        forcedUnit === defaultConstants.SIZE_UNIT_GB
    ) {
        return `${formatDecimal(bytes / defaultConstants.SIZE_GB, 1)} ${translate("general.sizeGb")}`;
    }

    if (defaultConstants.SIZE_TB <= bytes || forcedUnit === defaultConstants.SIZE_UNIT_TB) {
        return `${formatDecimal(bytes / defaultConstants.SIZE_TB, 1)} ${translate("general.sizeTb")}`;
    }

    return null;
};

// Version

/**
 * Adds missing zeros to version.
 * @param version
 */
export const formatVersion = (version: string | null): string => {
    if (version === null) {
        return "";
    }
    const numberOfZerosMissing = 3 - (version.match(/\./g) || []).length;

    return version.padStart(2 * numberOfZerosMissing + version.length, "0.");
};

export const formatJson = (object: any): string => {
    return JSON.stringify(object, null, 4);
};
