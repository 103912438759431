import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export function runScript(scriptId: number): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_START_RUN_SCRIPT,
            params: {
                scriptId: scriptId
            }
        },
        method: methods.METHOD_START_RUN_SCRIPT
    };
}
