import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Database } from "./database";
import { ExportStatus } from "./exportStatus";
import { ImportStatus } from "./importStatus";
import { ModalType } from "../constants/modalTypes";
import { TableHeading } from "./tableHeading";

function getStatusList(
    objectType: objectTypes.ObjectType,
    exportStatusList: Array<ExportStatus>,
    importStatusList: Array<ImportStatus>
): any {
    let statusList: Array<ExportStatus | ImportStatus> = [];

    if (objectType === objectTypes.EXPORT_TASK_DELETE) {
        statusList = exportStatusList.filter(
            (status) =>
                status[propertyConstants.PROPERTY_FINISHED] &&
                status[propertyConstants.PROPERTY_ID] !== importExportConstants.EXPORT_STATUS_DELETED
        );
        statusList.splice(0, 0, new ExportStatus(importExportConstants.EXPORT_STATUS_ALL, "", false, true));
    }

    if (objectType === objectTypes.IMPORT_TASK_DELETE) {
        statusList = importStatusList.filter(
            (status) =>
                status[propertyConstants.PROPERTY_FINISHED] &&
                status[propertyConstants.PROPERTY_KEY] !== importExportConstants.IMPORT_STATUS_DELETED
        );
        statusList.splice(0, 0, new ImportStatus(0, importExportConstants.IMPORT_STATUS_ALL, false, true));
    }

    return statusList;
}

export function createEmptyExportImportObject(
    objectType: objectTypes.ObjectType,
    exportStatusList: Array<ExportStatus>,
    importStatusList: Array<ImportStatus>
): any {
    const currentDate = new Date();
    const fromDate = new Date();
    const toDate = new Date();

    fromDate.setMonth(currentDate.getMonth() - 12);
    toDate.setMonth(currentDate.getMonth() - 3);

    return {
        [propertyConstants.PROPERTY_DATE_FROM]: fromDate.toDateString(),
        [propertyConstants.PROPERTY_DATE_TO]: toDate.toDateString(),
        [propertyConstants.PROPERTY_STATUS_LIST]: getStatusList(objectType, exportStatusList, importStatusList),
        [propertyConstants.PROPERTY_DATABASE_ID]: "",
        [propertyConstants.PROPERTY_SYSTEM_ID]: "",
        [propertyConstants.PROPERTY_ZONE_ID]: ""
    };
}

export function generateDeleteExportImportHeadings(
    objectType: objectTypes.ObjectType,
    modalType: ModalType,
    databaseSystemZoneList: Array<Database>,
    exportStatusList: Array<ExportStatus>,
    importStatusList: Array<ImportStatus>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>
): Array<TableHeading> {
    // Prepare databases, systems and zones
    const databaseList = [];
    const systemList = [];
    const zoneList = [];

    for (const database of databaseSystemZoneList) {
        databaseList.push({
            [propertyConstants.PROPERTY_ID]: `${database[propertyConstants.PROPERTY_ID]}`,
            [propertyConstants.PROPERTY_DATABASE_ID]: database[propertyConstants.PROPERTY_ID],
            [propertyConstants.PROPERTY_NAME]: database[propertyConstants.PROPERTY_NAME]
        });

        for (const system of database.systemList) {
            systemList.push({
                [propertyConstants.PROPERTY_ID]: system[propertyConstants.PROPERTY_ID],
                [propertyConstants.PROPERTY_DATABASE_ID]: database[propertyConstants.PROPERTY_ID],
                [propertyConstants.PROPERTY_SYSTEM_ID]: system[propertyConstants.PROPERTY_ID],
                [propertyConstants.PROPERTY_NAME]: system[propertyConstants.PROPERTY_NAME]
            });

            for (const zone of system.zoneList) {
                zoneList.push({
                    [propertyConstants.PROPERTY_ID]: zone[propertyConstants.PROPERTY_ID],
                    [propertyConstants.PROPERTY_DATABASE_ID]: database[propertyConstants.PROPERTY_ID],
                    [propertyConstants.PROPERTY_SYSTEM_ID]: system[propertyConstants.PROPERTY_ID],
                    [propertyConstants.PROPERTY_ZONE_ID]: zone[propertyConstants.PROPERTY_ID],
                    [propertyConstants.PROPERTY_NAME]: zone[propertyConstants.PROPERTY_NAME]
                });
            }
        }
    }

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_DATE_FROM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_FROM, modalType),
            tableConstants.TABLE_TYPE_DATE_PICKER,
            visibleList.includes(propertyConstants.PROPERTY_DATE_FROM),
            editableList.includes(propertyConstants.PROPERTY_DATE_FROM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_FROM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_FROM),
            requiredList.includes(propertyConstants.PROPERTY_DATE_FROM),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_TO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_TO),
            tableConstants.TABLE_TYPE_DATE_PICKER,
            visibleList.includes(propertyConstants.PROPERTY_DATE_TO),
            editableList.includes(propertyConstants.PROPERTY_DATE_TO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_TO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_TO),
            requiredList.includes(propertyConstants.PROPERTY_DATE_TO),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            databaseList,
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_STATUS_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_STATUS_LIST),
            tableConstants.TABLE_TYPE_LIST,
            visibleList.includes(propertyConstants.PROPERTY_STATUS_LIST),
            editableList.includes(propertyConstants.PROPERTY_STATUS_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_STATUS_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_STATUS_LIST),
            requiredList.includes(propertyConstants.PROPERTY_STATUS_LIST),
            getStatusList(objectType, exportStatusList, importStatusList),
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            systemList,
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_ID),
            zoneList,
            null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
