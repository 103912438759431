import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as navigationActions from "../../../actions/navigationActions";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Currency } from "../../../types/currency";
import { Dispatch } from "redux";
import { Margin } from "../../../types/margin";
import { MarginType } from "../../../types/marginType";
import { MenuItem } from "../../../types/menu";
import { Package } from "../../../types/package";
import { Price } from "../../../types/price";
import { PriceOption } from "../../../types/priceOption";
import { ServerRequest } from "../../../types/serverRequest";
import { ZonePriceScene } from "../../../components/scenes/zone/ZonePriceScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    activeBookmark: string | null;
    currency: Currency | null;
    priceLoading: boolean;
    // price generic
    priceGenericTableConstant: string;
    priceGenericList: Array<Price>;
    priceGenericActiveList: Array<Price>;
    priceGenericLoading: boolean;
    priceGenericButtonLoading: boolean;
    priceGenericColumnOrder: Array<propertyConstants.Property>;
    priceGenericColumnVisibility: Record<propertyConstants.Property, boolean>;
    priceGenericColumnWidth: Record<propertyConstants.Property, number>;
    // price groups
    priceGroupTableConstant: string;
    priceGroupList: Array<Price>;
    priceGroupActiveList: Array<Price>;
    priceGroupLoading: boolean;
    priceGroupButtonLoading: boolean;
    priceGroupColumnOrder: Array<propertyConstants.Property>;
    priceGroupColumnVisibility: Record<propertyConstants.Property, boolean>;
    priceGroupColumnWidth: Record<propertyConstants.Property, number>;
    // price margins
    marginTypeList: Array<MarginType>;
    marginTableConstant: string;
    marginList: Array<Margin>;
    marginActiveList: Array<Margin>;
    marginLoading: boolean;
    marginButtonLoading: boolean;
    marginColumnOrder: Array<propertyConstants.Property>;
    marginColumnVisibility: Record<propertyConstants.Property, boolean>;
    marginColumnWidth: Record<propertyConstants.Property, number>;
    marginSearch: string | null;
    marginSearchParams: any;
    marginShowGlobalSearch: boolean;
    marginSortingAsc: boolean;
    marginSortingCriterion: propertyConstants.Property | null;
    // price options
    priceOptionTableConstant: string;
    priceOptionList: Array<PriceOption>;
    priceOptionLoading: boolean;
    optionPriceDefinition: PriceOption | null;
    optionPriceEnableCompanyCost: PriceOption | null;
    // price surcharge
    packageTableConstant: string;
    packageList: Array<Package>;
    packageActiveList: Array<Package>;
    packageLoading: boolean;
    packageButtonLoading: boolean;
    packageColumnOrder: Array<propertyConstants.Property>;
    packageColumnVisibility: Record<propertyConstants.Property, boolean>;
    packageColumnWidth: Record<propertyConstants.Property, number>;
    packageOffset: number;
    packagePage: number;
    packageRowCount: number;
    packageRowCountCustom: boolean;
    packageTotalCount: number | null;
    packageSearch: string | null;
    packageSearchParams: any;
    packageShowGlobalSearch: boolean;
    packageSortingAsc: boolean;
    packageSortingCriterion: propertyConstants.Property | null;
}>;

export type DispatchType = Readonly<{
    setActiveBookmark(key: string | null): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_PRICES
    ),
    activeBookmark: state.navigation.activeBookmark,
    currency: state.currency.zoneCurrency,
    priceLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS ||
            request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS ||
            request.method === methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA ||
            request.method === methods.METHOD_GET_ZONE_PRICE_GROUP_DATA ||
            request.method === methods.METHOD_GET_ALL_ZONE_PACKAGES
    ),
    // price generic
    priceGenericTableConstant: menuConstants.TABLE_ZONE_PRICE_GENERIC,
    priceGenericList: state.price.zoneGenericList,
    priceGenericActiveList: state.price.zoneGenericActiveList,
    priceGenericLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS ||
            request.method === methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA
    ),
    priceGenericButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA
    ),
    priceGenericColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_PRICE_GENERIC
    ),
    priceGenericColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_PRICE_GENERIC
    ),
    priceGenericColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_PRICE_GENERIC
    ),
    // price groups
    priceGroupTableConstant: menuConstants.TABLE_ZONE_PRICE_GROUPS,
    priceGroupList: state.price.zonePriceGroupList,
    priceGroupActiveList: state.price.zonePriceGroupActiveList,
    priceGroupLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS ||
            request.method === methods.METHOD_GET_ZONE_PRICE_GROUP_DATA
    ),
    priceGroupButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA
    ),
    priceGroupColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_PRICE_GROUPS
    ),
    priceGroupColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_PRICE_GROUPS
    ),
    priceGroupColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_PRICE_GROUPS
    ),
    // price margins
    marginTypeList: state.price.zoneMarginTypeList,
    marginTableConstant: menuConstants.TABLE_ZONE_PRICE_MARGIN,
    marginList: state.price.zoneMarginList,
    marginActiveList: state.price.zoneMarginActiveList,
    marginLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS ||
            request.method === methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS ||
            request.method === methods.METHOD_DELETE_ZONE_PRICE_MARGIN
    ),
    marginButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_ZONE_PRICE_MARGIN
    ),
    marginColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_PRICE_MARGIN
    ),
    marginColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_PRICE_MARGIN
    ),
    marginColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_PRICE_MARGIN
    ),
    marginSearch: state.price.zoneMarginSearch,
    marginSearchParams: state.price.zoneMarginSearchParams,
    marginShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_PRICE_MARGIN
    ),
    marginSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRICE_MARGIN
        )
    ),
    marginSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRICE_MARGIN
        )
    ),
    // price options
    priceOptionTableConstant: menuConstants.TABLE_ZONE_PRICE_OPTIONS,
    priceOptionList: state.price.zoneOptionList,
    priceOptionLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS
    ),
    optionPriceDefinition: generalHelper.getObjectByKey(
        state.price.zoneOptionList,
        priceConstants.PRICE_OPTION_PRICE_DEFINITION
    ),
    optionPriceEnableCompanyCost: generalHelper.getObjectByKey(
        state.price.zoneOptionList,
        priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST
    ),
    // price surcharge
    packageTableConstant: menuConstants.TABLE_ZONE_PRICE_SURCHARGE,
    packageList: state.package.zoneList,
    packageActiveList: state.package.zoneActiveList,
    packageLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_ZONE_PACKAGES
    ),
    packageButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_ZONE_PACKAGE
    ),
    packageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageOffset: state.package.zoneOffset,
    packagePage: state.package.zonePage,
    packageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageSearch: state.package.zoneSearch,
    packageSearchParams: state.package.zoneSearchParams,
    packageShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
    ),
    packageSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRICE_SURCHARGE
        )
    ),
    packageSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRICE_SURCHARGE
        )
    ),
    packageTotalCount: state.package.zoneCountTotal
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setActiveBookmark: (key: string | null): any => dispatch(navigationActions.setActiveBookmark(key))
});
export const ZonePriceSceneContainer = connect(mapStateToProps, mapDispatchToProps)(ZonePriceScene);
