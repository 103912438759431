import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { BaseInProductPackage, updateBaseInProductPackage } from "../types/baseInProductPackage";

// TODO aplikovat object types do switche do ostatnich reduceru + v ostatnich reducerech kontrola TODO co jsou v tomto souboru
export type BaseInProductPackageState = Readonly<{
    masterSystemList: Array<BaseInProductPackage>;
    masterSystemActiveList: Array<BaseInProductPackage>;
    masterSystemSearch: string | null;
    masterSystemSearchParams: Record<propertyConstants.Property, string>;
    masterSystemOffset: number;
    masterSystemPage: number;
    systemMasterList: Array<BaseInProductPackage>;
    systemMasterActiveList: Array<BaseInProductPackage>;
    systemMasterSortingAsc: boolean;
    systemMasterSortingCriterion: propertyConstants.Property;
    systemMasterSearch: string | null;
    systemMasterSearchParams: Record<propertyConstants.Property, string>;
    systemMasterOffset: number;
    systemMasterPage: number;
    systemList: Array<BaseInProductPackage>;
    systemActiveList: Array<BaseInProductPackage>;
    systemCountAvailable: number | null;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    systemZoneList: Array<BaseInProductPackage>;
    systemZoneActiveList: Array<BaseInProductPackage>;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneOffset: number;
    systemZonePage: number;
    zoneSystemList: Array<BaseInProductPackage>;
    zoneSystemActiveList: Array<BaseInProductPackage>;
    zoneSystemSortingAsc: boolean;
    zoneSystemSortingCriterion: propertyConstants.Property;
    zoneSystemSearch: string | null;
    zoneSystemSearchParams: Record<propertyConstants.Property, string>;
    zoneSystemOffset: number;
    zoneSystemPage: number;
    zoneList: Array<BaseInProductPackage>;
    zoneActiveList: Array<BaseInProductPackage>;
    zoneCountAvailable: number | null;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
}>;
// TODO default values in constants for offset, page, search, searchParams
const initialState: BaseInProductPackageState = {
    masterSystemList: [],
    masterSystemActiveList: [],
    masterSystemSearch: null,
    masterSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterSystemOffset: tableConstants.DEFAULT_OFFSET,
    masterSystemPage: tableConstants.DEFAULT_PAGE,
    systemMasterList: [],
    systemMasterActiveList: [],
    systemMasterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemMasterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemMasterSearch: null,
    systemMasterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemMasterOffset: tableConstants.DEFAULT_OFFSET,
    systemMasterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCountAvailable: null,
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSearch: null,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneOffset: tableConstants.DEFAULT_OFFSET,
    systemZonePage: tableConstants.DEFAULT_PAGE,
    zoneSystemList: [],
    zoneSystemActiveList: [],
    zoneSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSystemSearch: null,
    zoneSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneSystemOffset: tableConstants.DEFAULT_OFFSET,
    zoneSystemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCountAvailable: null,
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE
};

export default function baseInProductPackages(
    state: BaseInProductPackageState = initialState,
    action: Record<string, any>
): BaseInProductPackageState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        masterSystemList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemMasterList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_PRODUCT:
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    if (action.payload.list.length === 0) {
                        return {
                            ...state,
                            systemList: initialState.systemList,
                            systemActiveList: initialState.systemActiveList,
                            // TODO systemCountAvailable: 0 / null?
                            systemCountTotal: initialState.systemCountTotal,
                            systemOffset: initialState.systemOffset,
                            systemPage: initialState.systemPage
                        };
                    }

                    return state;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountAvailable: action.payload.availableCount,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemZoneList: action.payload.list
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        zoneSystemList: action.payload.list
                    };
                }
                case objectTypes.ZONE_PRODUCT:
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    if (action.payload.list.length === 0) {
                        return {
                            ...state,
                            zoneList: initialState.zoneList,
                            zoneActiveList: initialState.zoneActiveList,
                            zoneCountTotal: initialState.zoneCountTotal,
                            zoneOffset: initialState.zoneOffset,
                            zonePage: initialState.zonePage
                        };
                    }

                    return state;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountAvailable: action.payload.availableCount,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        masterSystemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    const list = generalHelper.updateLists(state.systemMasterList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        systemMasterList: list,
                        systemMasterActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRODUCT:
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        systemList: initialState.systemList,
                        systemActiveList: initialState.systemActiveList,
                        systemCountTotal: initialState.systemCountTotal,
                        systemOffset: initialState.systemOffset,
                        systemPage: initialState.systemPage
                    };
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.systemList);

                    return {
                        ...state,
                        systemActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemZoneActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    const list = generalHelper.updateLists(state.zoneSystemList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        zoneSystemList: list,
                        zoneSystemActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRODUCT:
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    return {
                        ...state,
                        zoneList: initialState.zoneList,
                        zoneActiveList: initialState.zoneActiveList,
                        zoneCountTotal: initialState.zoneCountTotal,
                        zoneOffset: initialState.zoneOffset,
                        zonePage: initialState.zonePage
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.zoneList);

                    return {
                        ...state,
                        zoneActiveList: newActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.UPDATE_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.systemList);

                    return {
                        ...state,
                        systemActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.zoneList);

                    return {
                        ...state,
                        zoneActiveList: newActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        masterSystemOffset: action.payload.offset,
                        masterSystemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemZoneOffset: action.payload.offset,
                        systemZonePage: action.payload.page
                    };
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SYSTEM_SEARCH,
                        tableConstants.MASTER_SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_ZONE_SEARCH,
                        tableConstants.SYSTEM_ZONE_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        systemMasterList: generalHelper.sortItemsByProperty(
                            state.systemMasterList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        systemMasterSortingAsc: action.payload.asc,
                        systemMasterSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    return {
                        ...state,
                        zoneSystemList: generalHelper.sortItemsByProperty(
                            state.zoneSystemList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        zoneSystemSortingAsc: action.payload.asc,
                        zoneSystemSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                        const updatedBaseInProductPackageList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateBaseInProductPackage
                        );

                        if (updatedBaseInProductPackageList) {
                            return {
                                ...state,
                                systemList: updatedBaseInProductPackageList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                        const updatedBaseInProductPackageList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateBaseInProductPackage
                        );

                        if (updatedBaseInProductPackageList) {
                            return {
                                ...state,
                                zoneList: updatedBaseInProductPackageList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            if (action.payload.wizard === null || action.payload.scene === navigationTypes.SYSTEM_PRODUCTS_SCENE) {
                return {
                    ...initialState,
                    systemActiveList: state.systemActiveList
                };
            }

            return initialState;
        }
        // TODO pujde sjednotit NAVIGATION_MODAL_CLOSE a NAVIGATION_CLOSE_ALL_MODALS? jaky pripady muzou nastat?
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            switch (action.payload.type) {
                case modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE:
                case modalTypes.MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE: {
                    // TODO oddelit casy?
                    // TODO nemelo by se resetovat i sorting, offset a page? ikdyz to ted v propagation modalu nepouzivame, tak resetovat vsechno co se tam nacita
                    return {
                        ...state,
                        masterSystemList: initialState.masterSystemList,
                        masterSystemActiveList: initialState.masterSystemActiveList,
                        masterSystemSearch: initialState.masterSystemSearch,
                        masterSystemSearchParams: initialState.masterSystemSearchParams,
                        systemMasterList: initialState.systemMasterList,
                        systemMasterActiveList: initialState.systemMasterActiveList,
                        systemZoneList: initialState.systemZoneList,
                        systemZoneActiveList: initialState.systemZoneActiveList,
                        systemZoneSearch: initialState.systemZoneSearch,
                        systemZoneSearchParams: initialState.systemZoneSearchParams,
                        zoneSystemList: initialState.zoneSystemList,
                        zoneSystemActiveList: initialState.zoneSystemActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            // TODO to same co nahore - resetovat vse potrebne
            return {
                ...state,
                masterSystemList: initialState.masterSystemList,
                masterSystemActiveList: initialState.masterSystemActiveList,
                masterSystemSearch: initialState.masterSystemSearch,
                masterSystemSearchParams: initialState.masterSystemSearchParams,
                systemMasterList: initialState.systemMasterList,
                systemMasterActiveList: initialState.systemMasterActiveList,
                systemZoneList: initialState.systemZoneList,
                systemZoneActiveList: initialState.systemZoneActiveList,
                systemZoneSearch: initialState.systemZoneSearch,
                systemZoneSearchParams: initialState.systemZoneSearchParams,
                zoneSystemList: initialState.zoneSystemList,
                zoneSystemActiveList: initialState.zoneSystemActiveList
            };
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            // TODO nechybi tu masterSystem + systemMaster + systemCountAvailable?
            return {
                ...initialState,
                systemList: state.systemList,
                systemActiveList: state.systemActiveList,
                systemCountTotal: state.systemCountTotal,
                systemSearch: state.systemSearch,
                systemSearchParams: state.systemSearchParams,
                systemOffset: state.systemOffset,
                systemPage: state.systemPage
            };
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
