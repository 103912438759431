/**
 * See https://gitlab.deso.cz/redlike2/client/-/blob/master/src/constants/settings.js
 * See https://gitlab.deso.cz/redlike2/client/-/blob/master/doc/settings.md
 */

// option description
export const ADDITIONAL_TAB_TYPE = "additional_tab_type";
export const AUTO_CLOSE_TAB = "auto_close_tab";
export const AUTOMATIC_CLIENT_START = "automatic_client_start";
export const AUTOMATIC_LOG_OUT = "automatic_logout";
export const AUTOMATIC_LOG_OUT_TIME_OUT = "automatic_logout_timeout";
export const AUTOMATIC_PRINT = "automatic_print";
export const BARCODES_ENABLED = "barcodes_enabled";
export const COLORANT_PACKAGE_PROC = "colorant_package_proc";
export const COLORANT_PANEL_ORDER_BY = "colorant_panel_order_by";
export const COLOR_PREVIEW_RESIZABLE = "color_preview_resizable";
export const COLOR_TAB_COMPATIBLE_ONLY = "color_tab_compatible_only";
export const COLOR_TAB_FORMULA_CODE = "color_tab_formula_code";
export const COLOR_TAB_FORMULA_NAME = "color_tab_formula_name";
export const COLOR_TAB_NOTE_ICONS = "color_tab_note_icons";
export const COLOR_TAB_ORDER_BY = "color_tab_order_by";
export const COLOR_TAB_ROW_COUNT = "color_tab_row_count";
export const COLOR_TAB_WARNING_ICON = "color_tab_warning_icon";
export const COMPETITIVE_COLOR_SEARCH = "competitive_color_search";
export const CURRENCY_CODE = "currency_code";
export const CUSTOM_BANNER_POSITION = "custom_banner_position";
export const CUSTOM_CAN_OPTION = "custom_can_option";
export const CUSTOM_CAN_SIZE = "custom_can_size";
export const CUSTOM_CAN_UNITS = "custom_can_units";
export const CUSTOMERS_ENABLED = "customers_enabled";
export const CUSTOM_FORMULAS = "custom_formulas";
export const CUSTOM_FORMAT_DATE = "custom_format_date";
export const CUSTOM_FORMAT_NUMBER = "custom_format_number";
export const CUSTOM_FORMAT_TIME = "custom_format_time";
export const DELETE_CONFIRMATION = "delete_confirmation";
export const DISPENSING_ENABLED = "dispensing_enabled";
export const DISPENSING_NOTES = "dispensing_notes";
export const DO_NOT_SHOW_AGAIN_CLOSE_REDLIKE = "do_not_show_again_close_redlike";
export const EDIT_COLORANT_ALLOWANCE = "edit_colorant_allowance";
export const FORMULA_AUTOMATIC_NAMING = "formula_automatic_naming";
export const FORMULA_CODE = "formula_code";
export const FORMULA_CODE_PRIMARY = "formula_code_primary";
export const FORMULA_COLOR_PREVIEW = "formula_color_preview";
export const FORMULA_NAME = "formula_name";
export const FORMULA_NOTES = "formula_notes";
export const FORMULA_PREVIEW = "formula_preview";
export const FORMULA_STATUS_PANEL_ENABLED = "formula_status_panel_enabled";
export const FORMULA_UNITS = "formula_units";
export const FTS = "fts";
export const GENERATE_COLOR_PREVIEW = "generate_color_preview";
export const HELP = "help";
export const HELP_LINK = "help_link";
export const CHECK_MIN_DOSABLE_AMOUNT = "check_min_dosable_amount";
export const CHECK_OVERFILL = "check_overfill";
export const CHECK_TOTAL_CNT_AMOUNTS = "check_total_cnt_amounts";
export const INFO_PANEL_RESIZABLE = "info_panel_resizable";
export const INFO_PANEL_TAB = "info_panel_tab";
export const INFO_TYPES = "info_types";
export const INIT_TAB_TYPE = "init_tab_type";
export const KEYBOARD = "keyboard";
export const KEYBOARD_SHORTCUTS = "keyboard_shortcuts";
export const KIOSK = "kiosk";
export const LANGUAGE = "language";
export const LIGHT_ENABLED = "light_enabled";
export const LOCALE = "locale";
export const LOCK_PC = "lock_pc";
export const MARGIN_MARKUP = "margin_markup";
export const MAX_TOLERANCE_FOR_COMPETITIVE_COLORS = "max_tolerance_for_competitive_colors";
export const NAVIGATION_ITEMS_ORDERED = "navigation_items_ordered";
export const NUMBER_OF_COMPETITIVE_COLORS = "number_of_competitive_colors";
export const NUMBER_OF_TABS = "number_of_tabs";
export const ORDERING_ENABLED = "ordering_enabled";
export const ORDER_MODAL_ENABLED = "order_modal_enabled";
export const ORDER_SEARCH_ITEMS = "order_search_items";
export const OVERWRITE_LAST_TAB = "overwrite_last_tab";
export const OVERWRITE_LOGO = "overwrite_logo";
export const PRICE_TYPES = "price_types";
export const PRICE_VISIBILITY_TYPES = "price_visibility_types";
export const PRICING_ENABLED = "pricing_enabled";
export const PRINT_AFTER_DISPENSING = "print_after_dispensing";
export const PRINT_AS_IMAGE = "print_as_image";
export const PRINT_BEFORE_DISPENSING = "print_before_dispensing";
export const PRINT_ENABLED = "print_enabled";
export const PRINTER = "printer";
export const PRINTER_TEMPLATE = "printer_template";
export const PRINTER_TEMPLATE_QUICK_TINT = "printer_template_quicktint";
export const PRINT_ON_REQUEST = "print_on_request";
export const PRINT_TASKS_AFTER_DISPENSING = "print_tasks_after_dispensing";
export const PRINT_TASKS_BEFORE_DISPENSING = "print_tasks_before_dispensing";
export const PRODUCT_CODE = "product_code";
export const PRODUCT_IMAGE_PREVIEW = "product_image_preview";
export const QUICK_TINT_CLOSE_AFTER_DISPENSING = "quick_tint_close_after_dispensing";
export const QUICK_TINT_SORTING_TYPE = "quick_tint_sorting_type";
export const ROUNDING = "rounding";
export const SCREENSAVER_TIMEOUT = "screensaver_timeout";
export const SEARCH_PANEL_BASE = "search_panel_base";
export const SEARCH_PANEL_BASE_IN_PRODUCT_PACKAGE = "search_panel_bipp";
export const SEARCH_PANEL_ORDER = "search_panel_order";
export const SEARCH_PANEL_PRODUCT = "search_panel_product";
export const SEARCH_PANEL_WITHOUT_CONFIRMATION = "search_panel_without_confirmation";
export const SETTINGS_ACTIVE_FORMULA_UNIT = "settings_active_formula_unit";
export const SETTINGS_BLEND_FORMULA_AND_CODE = "settings_blend_formula_and_code";
export const SETTINGS_CLEANING_TIME = "settings_cleaning_time";
export const SETTINGS_CUSTOM_HOME_BUTTON = "settings_custom_home_button";
export const SETTINGS_INFO_ITEM_IN_RGB_PREVIEW = "settings_info_item_in_rgb_preview";
export const SETTINGS_MANUAL_ADDITION_UNIT = "settings_manual_addition_unit";
export const SETTINGS_RECIRCULATION_SPEED = "settings_recirculation_speed";
export const SETTINGS_RECIRCULATION_TIME = "settings_recirculation_time";
export const SETTINGS_STIRRING_SPEED = "settings_stirring_speed";
export const SETTINGS_STIRRING_TIME = "settings_stirring_time";
export const SHOTS_COEF = "shots_coef";
export const SHOTS_NAME = "shots_name";
export const SOFTWARE_NAME = "software_name";
export const SOFTWARE_NAME_LIGHT = "software_name_light";
export const SOUND_ENABLED = "sound_enabled";
export const SPECTRO_ENABLED = "spectro_enabled";
export const STYLE = "style";
export const TABLE_BASE = "table_base";
export const TABLE_CUSTOMER = "table_customer";
export const TABLE_DATE = "table_date";
export const TABLE_FANDECK = "table_fandeck";
export const TABLE_FORMULA_CODE = "table_formula_code";
export const TABLE_FORMULA_NAME = "table_formula_name";
export const TABLE_MORE_INFO = "table_more_info";
export const TABLE_PACKAGE = "table_package";
export const TABLE_PRODUCT = "table_product";
export const TABLE_QUANTITY = "table_quantity";
export const TABS_ENABLED = "tabs_enabled";
export const UFI_CODE_BASE_VALIDATION = "ufi_code_base_validation";
export const UFI_CODE_LOGIC = "ufi_code_logic";
export const UFI_CODE_MIN_AMOUNT_GRAV_PERCENT = "ufi_code_min_amont_grav_percent";
export const UFI_CODE_MIN_GRAV_PERCENT = "ufi_code_min_grav_percent";
export const USER_IDENTIFICATION = "user_identification";
export const VISIBLE_UNITS = "visible_units";
export const VIRTUAL_KEYBOARD_ENABLED = "virtual_keyboard_enabled";
export const ZOOM = "zoom";

export const HIDDEN_REDLIKE_OPTINS = [LANGUAGE];
