import * as actionConstants from "../constants/actionTypes";
import * as objectTypes from "../constants/objectTypes";

import { Formula } from "../types/formula";

export interface FormulaUpdate {
    type: typeof actionConstants.UPDATE_FORMULA;
    payload: {
        objectType: objectTypes.ObjectType;
        formulaList: Array<Formula>;
        formulaId: number | null;
        baseInProductId: number | null;
    };
}

export function updateFormula(
    objectType: objectTypes.ObjectType,
    formulaList: Array<Formula>,
    formulaId: number | null,
    baseInProductId: number | null
): FormulaUpdate {
    return {
        type: actionConstants.UPDATE_FORMULA,
        payload: {
            objectType,
            formulaList,
            formulaId,
            baseInProductId
        }
    };
}
