import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { BaseInProduct } from "../../../types/baseInProduct";
import { BaseInProductPackage } from "../../../types/baseInProductPackage";
import { Currency } from "../../../types/currency";
import { FileCategory } from "../../../types/fileCategory";
import { MenuItem } from "../../../types/menu";
import { PriceOption } from "../../../types/priceOption";
import { Product } from "../../../types/product";
import { ServerRequest } from "../../../types/serverRequest";
import { SystemProductScene } from "../../../components/scenes/system/SystemProductScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    priceDataPrivileges: MenuItem | null;
    currency: Currency | null;
    fileCategoryList: Array<FileCategory>;
    productTableConstant: string;
    productList: Array<Product>;
    productActiveList: Array<Product>;
    productLoading: boolean;
    productButtonLoading: boolean;
    productAvailableCount: number | null;
    productColumnOrder: Array<propertyConstants.Property>;
    productColumnVisibility: Record<propertyConstants.Property, boolean>;
    productColumnWidth: Record<propertyConstants.Property, number>;
    productOffset: number;
    productPage: number;
    productRowCount: number;
    productRowCountCustom: boolean;
    productSceneTableSize: Record<string, any> | null;
    productSearch: string | null;
    productSearchParams: Record<propertyConstants.Property, string>;
    productShowFilterRow: boolean;
    productShowGlobalSearch: boolean;
    productSortingAsc: boolean;
    productSortingCriterion: propertyConstants.Property | null;
    productTotalCount: number | null;
    baseInProductTableConstant: string;
    baseInProductList: Array<BaseInProduct>;
    baseInProductActiveList: Array<BaseInProduct>;
    baseInProductLoading: boolean;
    baseInProductButtonLoading: boolean;
    baseInProductAvailableCount: number | null;
    baseInProductColumnOrder: Array<propertyConstants.Property>;
    baseInProductColumnVisibility: Record<propertyConstants.Property, boolean>;
    baseInProductColumnWidth: Record<propertyConstants.Property, number>;
    baseInProductOffset: number;
    baseInProductPage: number;
    baseInProductRowCount: number;
    baseInProductRowCountCustom: boolean;
    baseInProductSearch: string | null;
    baseInProductSearchParams: any;
    baseInProductShowFilterRow: boolean;
    baseInProductShowGlobalSearch: boolean;
    baseInProductSortingAsc: boolean;
    baseInProductSortingCriterion: propertyConstants.Property | null;
    baseInProductTotalCount: number | null;
    baseInProductPackageTableConstant: string;
    baseInProductPackageList: Array<BaseInProductPackage>;
    baseInProductPackageActiveList: Array<BaseInProductPackage>;
    baseInProductPackageLoading: boolean;
    baseInProductPackageButtonLoading: boolean;
    baseInProductPackageAvailableCount: number | null;
    baseInProductPackageColumnOrder: Array<propertyConstants.Property>;
    baseInProductPackageColumnVisibility: Record<propertyConstants.Property, boolean>;
    baseInProductPackageColumnWidth: Record<propertyConstants.Property, number>;
    baseInProductPackageOffset: number;
    baseInProductPackagePage: number;
    baseInProductPackageRowCount: number;
    baseInProductPackageRowCountCustom: boolean;
    baseInProductPackageSearch: string | null;
    baseInProductPackageSearchParams: any;
    baseInProductPackageShowFilterRow: boolean;
    baseInProductPackageShowGlobalSearch: boolean;
    baseInProductPackageSortingAsc: boolean;
    baseInProductPackageSortingCriterion: propertyConstants.Property | null;
    baseInProductPackageTotalCount: number | null;
    optionPriceDefinition: PriceOption | null;
    optionPriceEnableCompanyCost: PriceOption | null;
    optionPriceEnablePriceGroups: PriceOption | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_PRODUCTS
    ),
    priceDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_PRICES
    ),
    currency: state.currency.systemCurrency,
    fileCategoryList: state.fileCategory.list,
    productTableConstant: menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS,
    productList: state.product.systemList,
    productActiveList: state.product.systemActiveList,
    productLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCTS ||
            request.method === methods.METHOD_GET_SYSTEM_PRICE_OPTIONS ||
            request.method === methods.METHOD_DELETE_SYSTEM_PRODUCT
    ),
    productButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID ||
            request.method === methods.METHOD_EDIT_SYSTEM_PRODUCT
    ),
    productAvailableCount: state.product.systemCountAvailable,
    productColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productOffset: state.product.systemOffset,
    productPage: state.product.systemPage,
    productRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_SYSTEM_PRODUCTS
    ),
    productSearch: state.product.systemSearch,
    productSearchParams: state.product.systemSearchParams,
    productShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
    ),
    productSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
        )
    ),
    productSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
        )
    ),
    productTotalCount: state.product.systemCountTotal,
    baseInProductTableConstant: menuConstants.TABLE_SYSTEM_PRODUCTS_BASES,
    baseInProductList: state.baseInProduct.systemList,
    baseInProductActiveList: state.baseInProduct.systemActiveList,
    baseInProductLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCTS ||
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT ||
            request.method === methods.METHOD_GET_SYSTEM_PRICE_OPTIONS ||
            request.method === methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT
    ),
    baseInProductButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID ||
            request.method === methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT
    ),
    baseInProductAvailableCount: state.baseInProduct.systemCountAvailable,
    baseInProductColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductOffset: state.baseInProduct.systemOffset,
    baseInProductPage: state.baseInProduct.systemPage,
    baseInProductRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductSearch: state.baseInProduct.systemSearch,
    baseInProductSearchParams: state.baseInProduct.systemSearchParams,
    baseInProductShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
    ),
    baseInProductSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
        )
    ),
    baseInProductSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
        )
    ),
    baseInProductTotalCount: state.baseInProduct.systemCountTotal,
    baseInProductPackageTableConstant: menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES,
    baseInProductPackageList: state.baseInProductPackage.systemList,
    baseInProductPackageActiveList: state.baseInProductPackage.systemActiveList,
    baseInProductPackageLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCTS ||
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT ||
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT ||
            request.method === methods.METHOD_GET_SYSTEM_PRICE_OPTIONS ||
            request.method === methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE
    ),
    baseInProductPackageButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID ||
            request.method === methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE
    ),
    baseInProductPackageAvailableCount: state.baseInProductPackage.systemCountAvailable,
    baseInProductPackageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageOffset: state.baseInProductPackage.systemOffset,
    baseInProductPackagePage: state.baseInProductPackage.systemPage,
    baseInProductPackageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageSearch: state.baseInProductPackage.systemSearch,
    baseInProductPackageSearchParams: state.baseInProductPackage.systemSearchParams,
    baseInProductPackageShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
    ),
    baseInProductPackageSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageTotalCount: state.baseInProductPackage.systemCountTotal,
    optionPriceDefinition: generalHelper.getObjectByKey(
        state.price.systemOptionList,
        priceConstants.PRICE_OPTION_PRICE_DEFINITION
    ),
    optionPriceEnableCompanyCost: generalHelper.getObjectByKey(
        state.price.systemOptionList,
        priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST
    ),
    optionPriceEnablePriceGroups: generalHelper.getObjectByKey(
        state.price.systemOptionList,
        priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS
    ),
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_SYSTEM_PRODUCTS
    )
});

export const SystemProductSceneContainer = connect(mapStateToProps)(SystemProductScene);
