import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Base, mapBase } from "../../types/base";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_BASES:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<Base> = [];
            let base: Base | null;

            for (const item of response.data.data) {
                base = mapBase(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_BASE, baseList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<Base> = [];
            let base: Base | null;

            for (const item of response.data.data) {
                base = mapBase(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_SYSTEM_BASE, baseList, response.data.count)];
        }
        case methods.METHOD_ADD_MASTER_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_BASE),
                universalObjectActions.reloadData(objectTypes.MASTER_SYSTEM_BASE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_BASE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_BASES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_BASE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_EDIT));
            } else {
                const base = mapBase(response.data);

                if (base) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_BASE, [base]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_BASE),
                universalObjectActions.setActiveItems(objectTypes.MASTER_BASE, [])
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<Base> = [];
            let base: Base | null;

            for (const item of response.data.data) {
                base = mapBase(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_BASE,
                    baseList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<Base> = [];
            let base: Base | null;

            for (const item of response.data.data) {
                base = mapBase(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE_BASE, baseList, response.data.count)];
        }
        case methods.METHOD_ADD_SYSTEM_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_ADD)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_BASE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_BASES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_BASE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_BASES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_BASE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_EDIT));
            } else {
                const base = mapBase(response.data);

                if (base) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_BASE, [base]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_BASE, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_BASES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<Base> = [];
            let base: Base | null;

            for (const item of response.data.data) {
                base = mapBase(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_BASE,
                    baseList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_ADD_ZONE_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_ADD)
            ];
        }
        case methods.METHOD_ADD_ZONE_BASE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_BASES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_BASE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_BASES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_BASE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASES_EDIT));
            } else {
                const base = mapBase(response.data);

                if (base) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_BASE, [base]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_BASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE),
                universalObjectActions.setActiveItems(objectTypes.ZONE_BASE, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
