import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export function generateWavelengthData(wavelength: Record<string, any>): Array<any> {
    return [
        {
            [propertyConstants.PROPERTY_BEGIN_VALUE]: wavelength.begin_value,
            [propertyConstants.PROPERTY_END_VALUE]: wavelength.end_value,
            [propertyConstants.PROPERTY_INTERVAL]: wavelength.interval
        }
    ];
}

export function generateWavelengthHeadings(
    objectType: objectTypes.ObjectType,
    intervalList: Array<number>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BEGIN_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BEGIN_VALUE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_BEGIN_VALUE),
            editableList.includes(propertyConstants.PROPERTY_BEGIN_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BEGIN_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BEGIN_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_BEGIN_VALUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_BEGIN_VALUE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_END_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_END_VALUE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_END_VALUE),
            editableList.includes(propertyConstants.PROPERTY_END_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_END_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_END_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_END_VALUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_END_VALUE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_INTERVAL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_INTERVAL),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_INTERVAL),
            editableList.includes(propertyConstants.PROPERTY_INTERVAL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_INTERVAL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_INTERVAL),
            requiredList.includes(propertyConstants.PROPERTY_INTERVAL),
            intervalList,
            widthOption?.[propertyConstants.PROPERTY_INTERVAL] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
