import * as keyCodes from "../constants/keyCodes";
import * as licenseConstants from "../constants/license";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";

import { DispatchType, PropsType } from "../containers/License";
import React, { Component } from "react";
import { createEmptyLicense, generateLicenseWithoutRegistrationData } from "../types/licensing/license";
import {
    generateContactPersonData,
    generateContactPersonHeadings,
    updateContactPerson
} from "../types/licensing/contactPerson";
import { generatePointOfSaleData, generatePointOfSaleHeadings, mapPointOfSale } from "../types/licensing/pointOfSale";

import { Form } from "./modals/general/Form";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../types/tableHeading";
import imgInfo from "../resources/img/icons/error.svg";
import imgLoader from "../resources/img/mini-loader.svg";
import logo from "../resources/img/icons/logo-lab.svg";

import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    objectData: Record<string, any>;
};

export class License extends Component<Props, State> {
    state: State = {
        objectData: createEmptyLicense()
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleModalKeyDown);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        // to map this.props.license into objectData, so that objectData does not have any "subchildren" and all the values are on the same level
        if (
            JSON.stringify(prevProps.license) !== JSON.stringify(this.props.license) &&
            this.props.license &&
            (this.props.license[propertyConstants.PROPERTY_POINT_OF_SALE] ||
                this.props.license[propertyConstants.PROPERTY_CONTACT_PERSON])
        ) {
            this.setState({
                objectData: {
                    ...generateLicenseWithoutRegistrationData(this.props.license),
                    ...generatePointOfSaleData(this.props.license[propertyConstants.PROPERTY_POINT_OF_SALE]),
                    ...generateContactPersonData(this.props.license[propertyConstants.PROPERTY_CONTACT_PERSON])
                }
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    // Checks whether the online license activation is possible
    handleActivateLicenseClick = (): void => {
        this.props.getLicenseOnlineActivationAvailability();
    };

    handleBackButtonClick = (): void => {
        this.props.setProcessStep(this.props.processStep - 1);
    };

    handleConfirmFormClick = (data: Record<string, any>): void => {
        this.props.updateLicense(data);
    };

    handleChange = (property: propertyConstants.Property, value: any): void => {
        this.setState({
            objectData: {
                ...this.state.objectData,
                [property]: value
            }
        });
    };

    handleModalKeyDown = (event: Record<string, any>): void => {
        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.target.type !== "textarea") {
                    switch (this.props.processStep) {
                        case licenseConstants.LICENSE_ZERO_PROCESS_STEP: {
                            this.handleActivateLicenseClick();
                            break;
                        }
                        case licenseConstants.LICENSE_FIRST_PROCESS_STEP:
                        case licenseConstants.LICENSE_SECOND_PROCESS_STEP:
                        case licenseConstants.LICENSE_THIRD_PROCESS_STEP: {
                            const continueButton = document.getElementById("btn-continue") as HTMLButtonElement;

                            if (continueButton && !continueButton.disabled) {
                                this.handleConfirmFormClick(this.getPreparedDataForNextStep());
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    getLicenseStateMessage = (): JSX.Element => {
        const text =
            this.state.objectData[propertyConstants.PROPERTY_STATUS] === licenseConstants.STATUS_VALID_LEASED_LICENSE
                ? translate("license.licenseWillExpire", {
                      dayCount: this.state.objectData[propertyConstants.PROPERTY_REMAINING_DAYS]
                  })
                : translate("license.productNotLicensed");

        return (
            <div className="license-state-container">
                <ReactSVG className="svg-icon" src={imgInfo} />
                <span>{text}</span>
            </div>
        );
    };

    getActivateLicenseButton = (): JSX.Element | null => {
        /* if (this.props.errorCode !== license.DELETED_LICENSE) {*/
        return (
            <button className="btn-info activate-license-btn" onClick={() => this.handleActivateLicenseClick()}>
                {translate("license.activateNewLicense")}
            </button>
        );
        // }
        // return null;
    };

    getBackButton = (): JSX.Element | null => {
        if (this.props.processStep === licenseConstants.LICENSE_FIRST_PROCESS_STEP) {
            if (
                this.props.license &&
                this.props.license[propertyConstants.PROPERTY_STATUS] !== licenseConstants.STATUS_VALID_LEASED_LICENSE
            ) {
                return null;
            }
        }

        return (
            <button className="btn-info" onClick={() => this.handleBackButtonClick()}>
                {translate("general.back")}
            </button>
        );
    };

    getDisabledContinueButton = (content: string): JSX.Element => {
        return (
            <button id="btn-continue" className="btn-info margin-left-auto disabled" disabled>
                {content}
            </button>
        );
    };

    getContinueButton = (data: Record<string, any>, content: string, className?: string): JSX.Element | null => {
        let newClassName = className ? "btn-info " + className : "btn-info";
        if (!this.props.loading) {
            newClassName += " margin-left-auto";
        }

        return (
            <button id="btn-continue" className={newClassName} onClick={() => this.handleConfirmFormClick(data)}>
                {content}
            </button>
        );
    };

    getPreparedDataForNextStep = (): Record<string, any> => {
        const { objectData } = this.state;

        switch (this.props.processStep) {
            case licenseConstants.LICENSE_FIRST_PROCESS_STEP: {
                return {
                    [propertyConstants.PROPERTY_COMPANY_ID]: objectData[propertyConstants.PROPERTY_COMPANY_ID],
                    [propertyConstants.PROPERTY_USER_ID]: objectData[propertyConstants.PROPERTY_USER_ID]
                };
            }
            case licenseConstants.LICENSE_SECOND_PROCESS_STEP: {
                return {
                    [propertyConstants.PROPERTY_PRODUCT_ID]: Number(objectData[propertyConstants.PROPERTY_PRODUCT_ID])
                };
            }
            case licenseConstants.LICENSE_THIRD_PROCESS_STEP: {
                return {
                    [propertyConstants.PROPERTY_POINT_OF_SALE]: mapPointOfSale(objectData),
                    [propertyConstants.PROPERTY_CONTACT_PERSON]: updateContactPerson(objectData)
                };
            }
            default:
                return {};
        }
    };

    getLicenseNextStepButton = (): JSX.Element | null => {
        const { objectData } = this.state;

        switch (this.props.processStep) {
            case licenseConstants.LICENSE_FIRST_PROCESS_STEP: {
                if (
                    objectData[propertyConstants.PROPERTY_COMPANY_ID].length !== 8 ||
                    objectData[propertyConstants.PROPERTY_USER_ID].length !== 8
                ) {
                    return this.getDisabledContinueButton(translate("general.continue"));
                }

                return this.getContinueButton(this.getPreparedDataForNextStep(), translate("general.continue"));
            }
            case licenseConstants.LICENSE_SECOND_PROCESS_STEP: {
                if (objectData[propertyConstants.PROPERTY_PRODUCT_ID] === "") {
                    return this.getDisabledContinueButton(translate("general.continue"));
                }

                return this.getContinueButton(this.getPreparedDataForNextStep(), translate("general.continue"));
            }
            case licenseConstants.LICENSE_THIRD_PROCESS_STEP: {
                if (
                    objectData[propertyConstants.PROPERTY_NAME]?.trim() === "" ||
                    objectData[propertyConstants.PROPERTY_PERSON_NAME]?.trim() === ""
                ) {
                    return this.getDisabledContinueButton(translate("license.activateLicense"));
                }

                return this.getContinueButton(
                    this.getPreparedDataForNextStep(),
                    translate("license.activateLicense"),
                    "activate-button"
                );
            }
            default:
                return null;
        }
    };

    getProcessStepsClass = (id: number): string => {
        let className = "license-steps";

        if (id === this.props.processStep) {
            className += " active";
        }

        if (id < this.props.processStep) {
            className += " done";
        }

        return className;
    };

    getLicenseProcessSteps = (): JSX.Element | null => {
        const steps = licenseConstants.LICENSE_ONLINE_ACTIVATION_STEPS.map((id) => (
            <li key={id} className={this.getProcessStepsClass(id)}></li>
        ));

        if (steps) {
            return (
                <div className="license-wizard">
                    <ul className="row">{steps}</ul>
                </div>
            );
        }

        return null;
    };

    generateRegistrationFormHeadings = (): Array<TableHeading> => {
        const pointOfSaleHeadings = generatePointOfSaleHeadings(
            objectTypes.LICENSE,
            [
                propertyConstants.PROPERTY_POINT_OF_SALE,
                propertyConstants.PROPERTY_NAME,
                propertyConstants.PROPERTY_COUNTRY,
                propertyConstants.PROPERTY_REGION,
                propertyConstants.PROPERTY_CITY,
                propertyConstants.PROPERTY_STREET,
                propertyConstants.PROPERTY_ZIP
            ],
            [
                propertyConstants.PROPERTY_POINT_OF_SALE,
                propertyConstants.PROPERTY_NAME,
                propertyConstants.PROPERTY_COUNTRY,
                propertyConstants.PROPERTY_REGION,
                propertyConstants.PROPERTY_CITY,
                propertyConstants.PROPERTY_STREET,
                propertyConstants.PROPERTY_ZIP
            ],
            [
                propertyConstants.PROPERTY_NAME,
                propertyConstants.PROPERTY_COUNTRY,
                propertyConstants.PROPERTY_REGION,
                propertyConstants.PROPERTY_CITY,
                propertyConstants.PROPERTY_STREET,
                propertyConstants.PROPERTY_ZIP
            ],
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            tableConstants.EMPTY_COLUMN_WIDTH
        );

        const contactPersonHeadings = generateContactPersonHeadings(
            objectTypes.LICENSE,
            [
                propertyConstants.PROPERTY_CONTACT_PERSON,
                propertyConstants.PROPERTY_PERSON_NAME,
                propertyConstants.PROPERTY_PHONE,
                propertyConstants.PROPERTY_EMAIL
            ],
            [
                propertyConstants.PROPERTY_CONTACT_PERSON,
                propertyConstants.PROPERTY_PERSON_NAME,
                propertyConstants.PROPERTY_PHONE,
                propertyConstants.PROPERTY_EMAIL
            ],
            [
                propertyConstants.PROPERTY_PERSON_NAME,
                propertyConstants.PROPERTY_PHONE,
                propertyConstants.PROPERTY_EMAIL
            ],
            [propertyConstants.PROPERTY_PERSON_NAME],
            [propertyConstants.PROPERTY_PERSON_NAME],
            tableConstants.EMPTY_COLUMN_WIDTH
        );

        return pointOfSaleHeadings.concat(contactPersonHeadings);
    };

    generateRegistrationDataForm = (): JSX.Element | null => {
        return (
            <Form
                modalId={0}
                modalType={modalTypes.NO_MODAL}
                sessionUuid={null}
                urlRest={""}
                objectType={objectTypes.LICENSE}
                headings={this.generateRegistrationFormHeadings()}
                objectData={this.state.objectData}
                callbackChange={(property: propertyConstants.Property, value: any) => {
                    this.handleChange(property, value);
                }}
                callbackBlur={(property: propertyConstants.Property, value: any) => {
                    this.handleChange(property, value);
                }}
            />
        );
    };

    getLicenseProductTypes = (): Array<JSX.Element> => {
        const radioButtons: Array<JSX.Element> = [];

        for (const licenseProduct of this.props.productList) {
            if (
                this.props.onlineActivation &&
                !(
                    licenseProduct[propertyConstants.PROPERTY_AVAILABLE] !== undefined &&
                    licenseProduct[propertyConstants.PROPERTY_AVAILABLE] > 0
                )
            ) {
                radioButtons.push(
                    <div
                        className="input-type-radio-button disabled"
                        key={licenseProduct[propertyConstants.PROPERTY_ID]}
                    >
                        <label className="radio-label">
                            <input
                                className="radio"
                                type="radio"
                                name="product"
                                value={licenseProduct[propertyConstants.PROPERTY_ID]}
                                checked={false}
                                disabled
                            />
                            <span className="checkmark" />
                        </label>
                        <span>{licenseProduct[propertyConstants.PROPERTY_NAME]}</span>
                        <span className="no-license">({translate("license.noLicenseAvailable")})</span>
                    </div>
                );
            } else {
                radioButtons.push(
                    <div
                        className="input-type-radio-button"
                        key={licenseProduct[propertyConstants.PROPERTY_ID]}
                        onClick={(event: any) => {
                            event.preventDefault();
                            this.handleChange(
                                propertyConstants.PROPERTY_PRODUCT_ID,
                                licenseProduct[propertyConstants.PROPERTY_ID]
                            );
                        }}
                    >
                        <label className="radio-label">
                            <input
                                type="radio"
                                value={licenseProduct[propertyConstants.PROPERTY_ID]}
                                checked={
                                    this.state.objectData[propertyConstants.PROPERTY_PRODUCT_ID] ===
                                    licenseProduct[propertyConstants.PROPERTY_ID]
                                }
                                onChange={() =>
                                    this.handleChange(
                                        propertyConstants.PROPERTY_PRODUCT_ID,
                                        licenseProduct[propertyConstants.PROPERTY_ID]
                                    )
                                }
                            />
                            <span className="checkmark" />
                        </label>
                        <div>
                            <span className="radio-text">{licenseProduct[propertyConstants.PROPERTY_NAME]}</span>
                        </div>
                    </div>
                );
            }
        }

        return radioButtons;
    };

    getFirstLicenseStepContent = (): JSX.Element | null => {
        const { objectData } = this.state;

        return (
            <div className="form-box-blue row">
                <div className="width-100">
                    <div className="step-title">
                        <span>{translate("license.enterKeys")}</span>
                    </div>
                    <div className="row">
                        <div className="title">
                            <span>{`${translate("license.companyId")}:`}</span>
                        </div>
                        <div className="type-input">
                            <input
                                type="text"
                                value={objectData[propertyConstants.PROPERTY_COMPANY_ID] ?? ""}
                                onChange={(event) =>
                                    this.handleChange(propertyConstants.PROPERTY_COMPANY_ID, event.target.value)
                                }
                                autoFocus={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            <span>{`${translate("license.userId")}:`}</span>
                        </div>
                        <div className="type-input">
                            <input
                                type="text"
                                value={objectData[propertyConstants.PROPERTY_USER_ID] ?? ""}
                                onChange={(event) =>
                                    this.handleChange(propertyConstants.PROPERTY_USER_ID, event.target.value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getLicenseStepsContent = (): JSX.Element | null => {
        switch (this.props.processStep) {
            case licenseConstants.LICENSE_FIRST_PROCESS_STEP: {
                return this.getFirstLicenseStepContent();
            }
            case licenseConstants.LICENSE_SECOND_PROCESS_STEP: {
                return (
                    <div className="form-box-blue row">
                        <div className="width-100">
                            <div className="step-title">
                                <span>{translate("license.selectProduct")}:</span>
                            </div>
                            <div className="license-container">{this.getLicenseProductTypes()}</div>
                        </div>
                    </div>
                );
            }
            case licenseConstants.LICENSE_THIRD_PROCESS_STEP: {
                return (
                    <div className="form-box-blue row registration-container">
                        <div className="height-100 width-100">
                            <div className="step-title">
                                <span>{translate("license.fillData")}</span>
                                <div className="reg-note">{translate("license.asteriskMark")}</div>
                            </div>
                            <div className="registration-form slim-scroll">{this.generateRegistrationDataForm()}</div>
                        </div>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    getActivationLoader = (): JSX.Element | null => {
        if (this.props.loading) {
            return (
                <div className="alert-activating">
                    <ReactSVG className="svg-icon" src={imgLoader} />
                    {translate("license.activatingLicense")}
                </div>
            );
        }
        return null;
    };

    getLicenseContent = (): JSX.Element => {
        const { processStep } = this.props;

        if (processStep > licenseConstants.LICENSE_ZERO_PROCESS_STEP) {
            /* if (this.props.loading && processStep === licenseConstants.LICENSE_THIRD_PROCESS_STEP) {
                return (
                    <div className="license-content">
                        {this.getLicenseStepsContent()}{" "}
                        <div className="license-text activating">
                            <ReactSVG className="svg-icon" src={imgLoader} />
                            {translate("license.activatingLicense")}
                        </div>
                    </div>
                );
            }*/
            return (
                <div className="activating-license-container">
                    {this.getLicenseProcessSteps()}
                    <div className="license-content">
                        {this.getLicenseStepsContent()}
                        <div className="btn-row">
                            {this.getBackButton()}
                            {this.getActivationLoader()}
                            {this.getLicenseNextStepButton()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="license-content">
                <div className="license-text">{this.getLicenseStateMessage()}</div>
                {this.getActivateLicenseButton()}
            </div>
        );
    };

    render(): JSX.Element {
        const { processStep } = this.props;
        let className = "bg-container license-window";

        if (
            processStep === licenseConstants.LICENSE_ZERO_PROCESS_STEP
            // || (processStep === licenseConstants.LICENSE_THIRD_PROCESS_STEP && this.props.loading)
        ) {
            className += " md-window";
        } else {
            className += " lg-window";
        }

        return (
            <div className="bg-screen initial-scenes">
                <div className={className}>
                    <div className="lab-logo">
                        <ReactSVG className="logo-icon" src={logo} />
                    </div>
                    {this.getLicenseContent()}
                </div>
            </div>
        );
    }
}
