import * as translationHelper from "../helpers/translationHelper";

export class ImportStatus {
    id: number;
    key: string;
    name: string;
    result: boolean;
    finished: boolean;

    constructor(id: number, key: string, result: boolean, finished: boolean) {
        this.id = id;
        this.key = key;
        this.name = translationHelper.getImportStatusTranslation(key);
        this.result = result;
        this.finished = finished;
    }
}

export function mapImportStatus(data: Record<string, any>): ImportStatus | null {
    try {
        return new ImportStatus(data.id, data.name, data.result, data.finished);
    } catch (e) {
        return null;
    }
}
