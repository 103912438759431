// generic constants
// TODO: usage of ID_UNKNOWN?
export const ID_UNKNOWN = -1;
export const DEFAULT_ROUNDING = 3;
export const DEFAULT_BACKGROUND_INDEX = 99;
export const MAX_RGB_VALUE = 255;

// custom software
export const COLORIS_SOFTWARE = "KusHci2E";

// export task
export const DEFAULT_EXPORT_SYSTEM_ID = 0;
export const DEFAULT_EXPORT_ZONE_ID = 0;
export const DEFAULT_EXPORT_ZONE_PASSWORD = "12345678";
export const DEFAULT_EXPORT_ZONE_UUID = "";

// flash message constants
export const FLASH_MESSAGES_PANEL_LIMIT = 3;
export const FLASH_MESSAGE_INFO = "FLASH_MESSAGE_INFO";
export const FLASH_MESSAGE_SUCCESS = "FLASH_MESSAGE_SUCCESS";
export const FLASH_MESSAGE_WARNING = "FLASH_MESSAGE_WARNING";
export const FLASH_MESSAGE_ERROR = "FLASH_MESSAGE_ERROR";

// file size
export const SIZE_KB = 1024;
export const SIZE_MB = SIZE_KB * SIZE_KB;
export const SIZE_GB = SIZE_MB * SIZE_KB;
export const SIZE_TB = SIZE_GB * SIZE_KB;

// file size unit
export type SizeUnit =
    | typeof SIZE_UNIT_B
    | typeof SIZE_UNIT_KB
    | typeof SIZE_UNIT_MB
    | typeof SIZE_UNIT_GB
    | typeof SIZE_UNIT_TB;
export const SIZE_UNIT_B = "B";
export const SIZE_UNIT_KB = "kB";
export const SIZE_UNIT_MB = "MB";
export const SIZE_UNIT_GB = "GB";
export const SIZE_UNIT_TB = "TB";

// formula
export const DEFAULT_FORMULA_AMOUNT = 1000;

// licensing
export const REGISTRATION_LICENSE = "http://system.on-redlike.com/license/register/";

// redlike
export const DEFAULT_REDLIKE_USERNAME = "deso";

// ufi codes
export const DEFAULT_UFI_CODE_CHARACTER_SET: any = {
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    A: 10,
    C: 11,
    D: 12,
    E: 13,
    F: 14,
    G: 15,
    H: 16,
    J: 17,
    K: 18,
    M: 19,
    N: 20,
    P: 21,
    Q: 22,
    R: 23,
    S: 24,
    T: 25,
    U: 26,
    V: 27,
    W: 28,
    X: 29,
    Y: 30
};
