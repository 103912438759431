import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { TableHeading } from "../tableHeading";

export class WebZone {
    id: string;
    uuid: string;
    name: string | null;
    version: string | null;
    passwordHash: string | null;
    hasMetaData: boolean;

    constructor(
        id: string,
        uuid: string,
        name: string | null,
        version: string | null,
        passwordHash: string | null,
        hasMetaData: boolean
    ) {
        this.id = id;
        this.uuid = uuid;
        this.name = name;
        this.version = version;
        this.passwordHash = passwordHash;
        this.hasMetaData = hasMetaData;
    }
}

export function mapWebZone(data: Record<string, any>): WebZone | null {
    try {
        return new WebZone(
            data.uuid,
            data.uuid,
            data.name ? data.name : null,
            data.zoneDatabase.length ? data.zoneDatabase[data.zoneDatabase.length - 1] : null,
            data?.metadata?.password ?? null,
            data.metadata ? true : false
        );
    } catch (e) {
        return null;
    }
}

export function generateWebZoneData(webZoneList: Array<WebZone>): Array<any> {
    const data: Array<any> = [];
    let webZone: WebZone;

    for (webZone of webZoneList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: webZone.id,
            [propertyConstants.PROPERTY_UUID]: webZone.uuid,
            [propertyConstants.PROPERTY_NAME]: webZone.name,
            [propertyConstants.PROPERTY_VERSION]: webZone.version,
            [propertyConstants.PROPERTY_PASSWORD_HASH]: webZone.passwordHash,
            [propertyConstants.PROPERTY_HAS_META_DATA]: webZone.hasMetaData
        });
    }

    return data;
}

export function generateWebZoneHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION),
            editableList.includes(propertyConstants.PROPERTY_VERSION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION),
            requiredList.includes(propertyConstants.PROPERTY_VERSION),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
