import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as privilegesHelper from "../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as serverConstants from "../../../constants/serverConstants";

import React, { Component } from "react";

import { TableActionRowContainer as ActionRow } from "../table/TableActionRow";
import { Bookmark } from "../../../types/bookmark";
import { BookmarkTabs } from "../BookmarkTabs";
import { ComplexSelectboxContainer as ComplexSelectbox } from "../inputs/ComplexSelectbox";
import { Fandeck } from "../../../types/fandeck";
import { Formula } from "../../../types/formula";
import { ModalProperties } from "../../../types/modalProperties";
import { Product } from "../../../types/product";
import { ReactSVG } from "react-svg";
import { TableAddButtonContainer } from "../table/buttons/TableAddButton";

import imgLoader from "../../../resources/img/loader.svg";
import { t as translate } from "react-i18nify";

type Props = {
    /**
     * tableConstant: identifier for table, used for server communication
     * objectType: table works with this object, important for all communication and data loading
     * dataPrivileges: decides whether the data can be viewed, updated, deleted
     * bookmarks: list of available bookmarks
     * activeBookmark: selected bookmark in the detail scene
     * activeList: active item in the table
     * fandeckList: list of selected fandeck
     * productList: list of selected product
     * modalProperties: // TODO
     * callback: method that changes the detail scene based on the click in select box
     * setActiveBookmarkCallback: method for changing active bookmark
     */
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    bookmarks: Array<Bookmark>;
    loading?: boolean;
    activeBookmark: string | null;
    activeList: Array<any>;
    fandeckList: Array<Fandeck>;
    productList?: Array<Product>;
    modalProperties: ModalProperties;
    callback?: (list: Array<any>, value: number | null, otherValue: number | null) => any;
    setActiveBookmarkCallback(key: string | null): void;
};

export class SceneDetail extends Component<Props> {
    getContentClass = (): string => {
        let className = "color-container";

        if (
            this.props.objectType === objectTypes.MASTER_FORMULA ||
            this.props.objectType === objectTypes.SYSTEM_FORMULA ||
            this.props.objectType === objectTypes.ZONE_FORMULA
        ) {
            className += " formula-container";
        }

        if (
            !this.props.dataPrivileges.canInsert &&
            !this.props.dataPrivileges.canUpdate &&
            !this.props.dataPrivileges.canInsert
        ) {
            className += " readonly";
        }

        return className;
    };

    getTitle = (): JSX.Element | null => {
        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR_DATA:
            case objectTypes.SYSTEM_COLOR_DATA:
            case objectTypes.ZONE_COLOR_DATA:
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA:
                return (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {this.props.activeList[0][propertyConstants.PROPERTY_COLOR_NAME]}
                    </span>
                );
            default:
                return null;
        }
    };

    getTitleForEmptyPage = (): string => {
        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR_DATA:
            case objectTypes.SYSTEM_COLOR_DATA:
            case objectTypes.ZONE_COLOR_DATA:
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA:
                return translate("color.noColorInFandeck");
            default:
                return "";
        }
    };

    getColorTitlePreview = (): JSX.Element | null => {
        const { activeList } = this.props;

        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR_DATA:
            case objectTypes.SYSTEM_COLOR_DATA:
            case objectTypes.ZONE_COLOR_DATA:
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA:
                return (
                    <div
                        className={
                            activeList[0][propertyConstants.PROPERTY_COLOR_RGB_HEX] !== null
                                ? "color-square"
                                : "color-square no-color"
                        }
                        style={
                            activeList[0][propertyConstants.PROPERTY_COLOR_RGB_HEX]
                                ? {
                                      backgroundColor: activeList[0][propertyConstants.PROPERTY_COLOR_RGB_HEX]
                                  }
                                : {}
                        }
                    ></div>
                );
            default:
                return null;
        }
    };

    getAddButton = (): JSX.Element | null => {
        const { objectType } = this.props;
        const { addModalType, addModalParams } = this.props.modalProperties;

        if (addModalType === undefined || addModalParams === undefined) {
            return null;
        }

        if (objectType === objectTypes.MASTER_FORMULA && !this.props.activeList.length) {
            return null;
        }

        switch (objectType) {
            case objectTypes.MASTER_COLOR_DATA:
                return (
                    <TableAddButtonContainer
                        objectType={objectTypes.MASTER_COLOR}
                        dataPrivileges={this.props.dataPrivileges}
                        modalType={this.props.modalProperties.addModalType}
                        modalParams={this.props.modalProperties.addModalParams}
                    />
                );
            default:
                return (
                    <TableAddButtonContainer
                        objectType={objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        modalType={this.props.modalProperties.addModalType}
                        modalParams={this.props.modalProperties.addModalParams}
                        caption={translate("formula.createNewFormula")}
                    />
                );
        }
    };

    getActionRow = (): JSX.Element | null => {
        if (!privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            const formulaId = this.props.activeList[0]?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null;
            const formula =
                this.props.activeList[0]?.[propertyConstants.PROPERTY_FORMULA_LIST].find(
                    (item: Formula) => item[propertyConstants.PROPERTY_ID] === formulaId
                ) ?? null;
            const activeList =
                this.props.objectType === objectTypes.MASTER_FORMULA ||
                this.props.objectType === objectTypes.SYSTEM_FORMULA ||
                this.props.objectType === objectTypes.ZONE_FORMULA
                    ? formula
                        ? [formula]
                        : []
                    : this.props.activeList;

            return (
                <div className="button-row">
                    <ActionRow
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        headings={[]}
                        data={[]}
                        activeData={activeList}
                        search={null}
                        rowCount={0}
                        rowCountCustom={false}
                        showFilterRow={false}
                        showGlobalSearch={false}
                        modalProperties={this.props.modalProperties}
                    />
                </div>
            );
        }

        return null;
    };

    getColorContent = (): JSX.Element => {
        return (
            <div className={this.getContentClass()}>
                <div className="main-title">
                    {this.getColorTitlePreview()}
                    <span>{this.getTitle()}</span>
                </div>
                {this.getActionRow()}
                <BookmarkTabs
                    bookmarks={this.props.bookmarks}
                    activeBookmark={this.props.activeBookmark}
                    setActiveBookmarkCallback={(key: string | null): void => {
                        this.props.setActiveBookmarkCallback(key);
                    }}
                />
            </div>
        );
    };

    getFormulaContent = (): JSX.Element => {
        const { objectType, activeList, callback } = this.props;

        return (
            <div className={this.getContentClass()}>
                <div className="main-title">
                    {this.getColorTitlePreview()}
                    {this.getTitle()}
                </div>
                <div className="button-row">
                    <ComplexSelectbox
                        dataPrivileges={this.props.dataPrivileges}
                        objectType={objectType}
                        className="scene-select background-fill"
                        editable={true}
                        required={true}
                        value={activeList[0][propertyConstants.PROPERTY_FORMULA_ID] || ""}
                        secondValue={activeList[0][propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] || ""}
                        options={generalHelper.getOptionsForSelectbox(
                            objectType,
                            activeList[0][propertyConstants.PROPERTY_FORMULA_LIST]
                        )}
                        callback={(value: string, otherValue: string): void =>
                            callback
                                ? callback(
                                      activeList[0][propertyConstants.PROPERTY_FORMULA_LIST] || [],
                                      !isNaN(Number(value)) && value !== null && value !== "" ? Number(value) : null,
                                      !isNaN(Number(otherValue)) && otherValue !== null && otherValue !== ""
                                          ? Number(otherValue)
                                          : null
                                  )
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                </div>
                {this.getActionRow()}
                <BookmarkTabs
                    bookmarks={this.props.bookmarks}
                    activeBookmark={this.props.activeBookmark}
                    setActiveBookmarkCallback={(key: string | null): void => {
                        this.props.setActiveBookmarkCallback(key);
                    }}
                />
            </div>
        );
    };

    render(): JSX.Element | null {
        if (this.props.fandeckList.length === 0) {
            return (
                <div className="new-color-container">
                    <span>{translate("fandeck.fandeckNotSelected")}</span>
                </div>
            );
        }

        if (this.props.productList && this.props.productList.length === 0) {
            return (
                <div className="new-color-container">
                    <span>{translate("product.productNotSelected")}</span>
                </div>
            );
        }

        if (!this.props.activeList.length) {
            return (
                <div className="new-color-container">
                    <span>{this.getTitleForEmptyPage()}</span>
                    {this.getAddButton()}
                </div>
            );
        }

        if (this.props.loading) {
            return (
                <div className="new-color-container height-100 loader">
                    {" "}
                    <ReactSVG className="loader-svg" src={imgLoader} />
                </div>
            );
        }

        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR_DATA:
            case objectTypes.SYSTEM_COLOR_DATA:
            case objectTypes.ZONE_COLOR_DATA: {
                return this.getColorContent();
            }
            case objectTypes.MASTER_FORMULA:
            case objectTypes.SYSTEM_FORMULA:
            case objectTypes.ZONE_FORMULA: {
                if (
                    this.props.activeList?.[0]?.[propertyConstants.PROPERTY_FORMULAS_STATUS]?.[
                        propertyConstants.PROPERTY_STATUS
                    ] === serverConstants.FORMULA_FLAG_NO_FORMULA
                ) {
                    return (
                        <div className="new-color-container">
                            <span style={{ whiteSpace: "pre-wrap" }}>
                                {translate("formula.noFormula", {
                                    colorName: this.props.activeList[0][propertyConstants.PROPERTY_COLOR_NAME] || ""
                                })}
                            </span>
                            <div className="action-row">{this.getAddButton()}</div>
                        </div>
                    );
                }

                return this.getFormulaContent();
            }
            default: {
                return <div className="new-color-container"></div>;
            }
        }
    }
}
