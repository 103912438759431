import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Database } from "../../../types/database";
import { ImportScene } from "../../../components/scenes/data/ImportScene";
import { ImportStatus } from "../../../types/importStatus";
import { ImportTask } from "../../../types/importTask";
import { ImportType } from "../../../types/importType";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    privileges: any;
    databaseSystemZoneList: Array<Database>;
    importTypeList: Array<ImportType>;
    importStatusList: Array<ImportStatus>;
    importDataConstant: string;
    importList: Array<ImportTask>;
    importActiveList: Array<ImportTask>;
    importLoading: boolean;
    importColumnOrder: Array<propertyConstants.Property>;
    importColumnVisibility: Record<propertyConstants.Property, boolean>;
    importColumnWidth: Record<propertyConstants.Property, number>;
    importOffset: number;
    importPage: number;
    importRowCount: number;
    importRowCountCustom: boolean;
    importSearch: string | null;
    importSearchParams: Record<propertyConstants.Property, string>;
    importShowDeletedValues: boolean;
    importShowFilterRow: boolean;
    importShowGlobalSearch: boolean;
    importShowHiddenValues: boolean;
    importSortingAsc: boolean;
    importSortingCriterion: propertyConstants.Property | null;
    importTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_IMPORT
    ),
    privileges: state.login.privileges,
    databaseSystemZoneList: state.databaseSystemZone.list,
    importTypeList: state.import.importTypes,
    importStatusList: state.import.importStatuses,
    importDataConstant: menuConstants.TABLE_DATA_IMPORT,
    importList: state.import.list,
    importActiveList: state.import.activeList,
    importLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_IMPORT_TASKS ||
            request.method === methods.METHOD_GET_ALL_IMPORT_TASK_TYPES ||
            request.method === methods.METHOD_GET_ALL_IMPORT_TASK_STATUSES ||
            request.method === methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES ||
            request.method === methods.METHOD_DELETE_IMPORT_TASK
    ),
    importColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importOffset: state.import.offset,
    importPage: state.import.page,
    importRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importSearch: state.import.search,
    importSearchParams: state.import.searchParams,
    importShowDeletedValues: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
        optionsConstants.OPTION_IMPORT_TASK_SHOW_DELETED
    ),
    importShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_DATA_IMPORT
    ),
    importShowHiddenValues: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
        optionsConstants.OPTION_IMPORT_TASK_SHOW_HIDDEN
    ),
    importSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_IMPORT
        )
    ),
    importSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_IMPORT
        )
    ),
    importTotalCount: state.import.count
});

export const ImportSceneContainer = connect(mapStateToProps)(ImportScene);
