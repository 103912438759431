export const SERVER_MONITORING_WARNING_PROGRESS = 70;
export const SERVER_MONITORING_ERROR_PROGRESS = 90;

export const UNIT_INPUT_PADDING = 15;

export const TOOLTIP_DESCRIPTION = 0;
export const TOOLTIP_LABEL = 1;

export const ALERT_VALIDATION = 0;
export const ALERT_LOADING = 1;
