export class LicenseProduct {
    id: number;
    name: string;
    available: number;
    total: number;

    constructor(id: number, name: string, available: number = 0, total: number = 0) {
        this.id = id;
        this.name = name;
        this.available = available;
        this.total = total;
    }
}

export function mapLicenseProduct(id: number, data: Record<string, any>): LicenseProduct | null {
    try {
        return new LicenseProduct(Number(id), data[id]);
    } catch (e) {
        return null;
    }
}

export function updateLicenseProduct(
    oldLicenseProduct: LicenseProduct | null,
    data: Record<string, any>
): LicenseProduct | null {
    try {
        if (oldLicenseProduct) {
            return new LicenseProduct(
                oldLicenseProduct.id,
                oldLicenseProduct.name,
                data.available ? data.available : oldLicenseProduct.available,
                data.total ? data.total : oldLicenseProduct.total
            );
        }

        return null;
    } catch (e) {
        return null;
    }
}
