export class DataSize {
    availableSsd: number;
    ssdUsedPercent: number;
    usedSsd: number;
    availableHdd: number;
    hddUsedPercent: number;
    usedHdd: number;

    constructor(
        availableSsd: number,
        ssdUsedPercent: number,
        usedSsd: number,
        availableHdd: number,
        hddUsedPercent: number,
        usedHdd: number
    ) {
        this.availableSsd = availableSsd;
        this.ssdUsedPercent = ssdUsedPercent;
        this.usedSsd = usedSsd;
        this.availableHdd = availableHdd;
        this.hddUsedPercent = hddUsedPercent;
        this.usedHdd = usedHdd;
    }
}

export function mapDataSize(data: Record<string, any>): DataSize | null {
    try {
        return new DataSize(
            data.availableSsd,
            data.ssdUsedPercent,
            data.usedSsd,
            data.availableHdd,
            data.hddUsedPercent,
            data.usedHdd
        );
    } catch (e) {
        return null;
    }
}
