import * as navigationConstants from "../../../../constants/navigationTypes";
import * as priceActions from "../../../../actions/priceActions";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Currency } from "../../../../types/currency";
import { Dispatch } from "redux";
import { connect } from "react-redux";

type OwnProps = {
    dataPrivileges?: any;
};

type Props = PropsType & DispatchType & OwnProps;

export class SceneCurrencyButtons extends Component<Props> {
    getContainerClassName = (): string => {
        const { activeScene } = this.props;
        let className = "layout-buttons-container currency-buttons";

        if (privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            className += " readonly";
        }

        if (navigationConstants.MASTER_SCENES_LIST.includes(activeScene)) {
            return className + " master";
        }

        if (navigationConstants.SYSTEM_SCENES_LIST.includes(activeScene)) {
            return className + " system";
        }

        if (navigationConstants.ZONE_SCENES_LIST.includes(activeScene)) {
            return className + " zone";
        }

        return className;
    };

    getButtonClassName = (id: number): string => {
        const { activeCurrency } = this.props;
        let className = "btn-info";

        if (activeCurrency && activeCurrency[propertyConstants.PROPERTY_ID] === id) {
            className += " active";
        }

        return className;
    };

    handleButtonClick = (id: number): void => {
        this.props.setCurrency(id);
    };

    getCurrencyButtons = (): JSX.Element | null => {
        const buttons = [];

        for (const currency of this.props.currencyList) {
            buttons.push(
                <button
                    key={currency[propertyConstants.PROPERTY_ID]}
                    className={this.getButtonClassName(currency[propertyConstants.PROPERTY_ID])}
                    onClick={(): void => this.handleButtonClick(currency[propertyConstants.PROPERTY_ID])}
                >
                    {currency[propertyConstants.PROPERTY_SYMBOL]}
                </button>
            );
        }

        return <React.Fragment>{buttons}</React.Fragment>;
    };

    render(): JSX.Element | null {
        return <div className={this.getContainerClassName()}>{this.getCurrencyButtons()}</div>;
    }
}

export type PropsType = Readonly<{
    activeScene: navigationConstants.Scene;
    currencyList: Array<Currency>;
    activeCurrency: Currency | null;
}>;

export type DispatchType = Readonly<{
    setCurrency(currencyId: number): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    currencyList: state.currency.allList,
    activeCurrency: state.currency.systemCurrency
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setCurrency: (currencyId: number): any => dispatch(priceActions.setActiveSystemCurrency(currencyId))
});

export const SceneCurrencyButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(SceneCurrencyButtons);
