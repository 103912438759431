export const de = {
    barcode: {
        addBarcode: "Weiteren Barcode hinzufügen",
        alternativeBarcode: "Alternativer Barcode",
        barcode: "Barcode",
        barcodes: "Barcodes",
        barcodesProductDuplicate:
            "Barcodes (kopierte Barcodes können im Falle von Duplikaten als alternative Barcodes gespeichert werden)",
        noBarcode: "Kein Barcode"
    },
    base: {
        addAnotherBase: "Weitere Base hinzufügen",
        addBase: "Base hinzufügen",
        allBases: "Alle Basen",
        amount: "Basen Menge",
        base: "Base",
        baseVolume: "Basen Volumen",
        bases: "Basen",
        editBase: "Base bearbeiten",
        loadBases: "Bitte warten, Basen werden geladen...",
        noBase: "Kein Base",
        noBaseAvailable: "Es ist keine Base verfügbar",
        noBaseVolume: "Kein Basen Volumen",
        selectBase: "Base auswählen",
        selectBases: "Basen auswählen",
        selectFromBases: "Wählen Sie aus den Basen",
        selectedBases: "Ausgewählte Basen"
    },
    batch: {
        batchNumber: "Batchnummer",
        lastStatusChange: "Letzte Statusänderung",
        productionDate: "Produktionsdatum"
    },
    color: {
        addColor: "Farbe hinzufügen",
        calculateLabFromReflectance: "LAB aus Reflexionsgrad berechnen",
        calculateRgb: "RGB berechnen",
        calculateRgbFromLab: "RGB aus LAB berechnen",
        color: "Farbtöne",
        colorData: "Farbdaten",
        colorInFandeck: "Farbton im Farbtonfächer",
        colors: "Farbton",
        editColor: "Farbton bearbeiten",
        fandeckPage: "Farbtonfächer-Seite",
        fandeckPosition: "Farbtonfächer-Position",
        jumpToColor: "Zum Farbton springen",
        noColorInFandeck: "Es gibt keine Farbton im ausgewählten Farbtonfächer",
        noFandeckPage: "Keine Farbtonfächer-Seite",
        noFandeckPosition: "Kein Farbtonfächer-Position",
        noSpectralData: "Keine Spektraldaten",
        reflectance: "Reflexionsgrad",
        successfullRgbCalculation:
            "Der RGB-Wert wurde erfolgreich berechnet und auf der Registerkarte Allgemein geändert.",
        wavelength: "Wellenlänge"
    },
    colorant: {
        addColorant: "Pigmentkonzentrat hinzufügen",
        addColorantBatch: "Pigmentkonzentrat-Batchnummer hinzufügen",
        addColorantPackage: "Pigmentkonzentrat-Gebinde hinzufügen",
        colorant: "Farbstoff",
        colorantBatches: "Pigmentkonzentrat-Batchnummern",
        colorantPackage: "Pigmentkonzentrat-Gebinde",
        colorantPackages: "Pigmentkonzentrat-Gebinde",
        colorants: "Pigmentkonzentrate",
        editColorant: "Pigmentkonzentrat bearbeiten",
        editColorantBatch: "Pigmentkonzentrat-Batch bearbeiten",
        editColorantPackage: "Pigmentkonzentratgebinde bearbeiten",
        selectColorants: "Pigmentkonzentrate auswählen",
        selectFromColorantPackages: "Aus Pigmentkonzentratgebinden wählen",
        selectFromColorants: "Aus Pigmentkonzentraten wählen",
        selectedColorantPackages: "Ausgewählte Pigmentkonzentratgebinde",
        selectedColorants: "Ausgewählte Pigmentkonzentrate"
    },
    company: {
        addCompany: "Firma hinzufügen",
        allCompanies: "Alle Firmen",
        companies: "Firmen",
        company: "Firma",
        companyName: "Firmenname",
        companySettings: "Einstellungen des Unternehmens",
        editCompany: "Firma bearbeiten"
    },
    confirmationModal: {
        changesText: "Sie haben Änderungen vorgenommen, möchten Sie diese speichern?",
        databaseUpdate: "Datenbank-Update",
        databaseUpdateText:
            "Die Datenbank %{databaseName} muss aktualisiert werden. Möchten Sie jetzt damit fortfahren?",
        deleteContinue: "Ich bin sicher, dass ich die Daten löschen möchte",
        deleteItem: "Löschung bestätigen",
        deleteItemText: "Möchten Sie das ausgewählte Element wirklich löschen?",
        deleteText:
            "Das Löschen dieses Elements kann verknüpfte Formeln und geerbte Elemente auf System- und Zonenebene entfernen.",
        deletionWarning: "Warnung vor dem Löschen",
        saveData: "Speichern bestätigen",
        saveDataText: "Möchten Sie Ihre Änderungen speichern?",
        warningDataSize: "Warnung - der Speicherplatz wird knapp",
        warningDataSizeInfo:
            "Bitte überprüfen Sie Ihre Daten und entfernen Sie unnötige ältere Datenbanken. Wenn Sie mehr Speicherplatz benötigen, wenden Sie sich an die Firma DESO.",
        warningDetail: "Detail der Warnung"
    },
    currency: {
        addCurrency: "Währung hinzufügen",
        currencies: "Währung",
        currency: "Währung",
        editCurrency: "Währung bearbeiten",
        selectCurrencies: "Währungen auswählen"
    },
    database: {
        addDatabase: "Datenbank hinzufügen",
        addEmptyDatabase: "Leere Datenbank hinzufügen",
        backupSelectedDatabase: "Ausgewählte Datenbank sichern",
        createNewDatabase: "Neue Datenbank erstellen",
        database: "Datenbank",
        databases: "Datenbanken",
        defaultDataEmpty: "Leere Datenbank",
        defaultDataSystemZoneCurrency: "System, Zone und Währung hinzufügen Euro",
        duplicateDatabase: "Duplizierte Datenbank %{name}",
        duplicateSelectedDatabase: "Ausgewählte Datenbank duplizieren",
        editDatabase: "Datenbank bearbeiten",
        importDatabase: "Redlike-Datenbank importieren",
        loadDatabase: "Datenbank laden",
        master: "Master",
        masterDatabases: "Master Datenbank",
        masterName: "Master Name",
        masterOnly: "Daten importieren",
        masterOnlyDuplicate: "Daten duplizieren",
        masterOnlyDuplicateFalse: "Duplizieren mit System-/Zonendaten",
        masterOnlyFalse: "Import mit System-/Zonen-Daten",
        masterOnlyTrue: "Vorhandene System-/Zonendaten löschen",
        vacuumDatabasesDescription:
            "Der VACUUM-Befehl baut die Datenbankdatei neu auf und packt sie auf ein Minimum an Speicherplatz zusammen.",
        vacuumMasterDatabases: "Master-Datenbanken verkleinern",
        valueInMaster: "Wert in den Stammdaten"
    },
    duplicate: {
        selectDataToBeCopied: "Zu kopierende Daten auswählen"
    },
    export: {
        abortExport: "Export abbrechen",
        colorsWithoutFormulas: "Farbtöne ohne Rezepturen",
        colorsWithoutFormulasCaption: "Farbtöne ohne Rezepturen in der exportierten Datenbank löschen",
        currentR2WVersion: "Aktuelle %{redlikeWebAbbreviation}-Version",
        dataExport: "Export",
        deleteColorsWithoutFormulas: "Farbtöne ohne Rezepturen löschen",
        deleteExports: "Exporte löschen",
        exportCompleted: "Export beendet",
        exportData: "Daten exportieren",
        exportDate: "Datum exportieren",
        exportError: "Fehler beim Export",
        exportList: "Übersicht der Exporte",
        exportLoading: "Bitte warten, der Export wird vorbereitet...",
        exportTask: "Aufgabe exportieren",
        noR2WVersionAvailable: "Keine %{redlikeWebAbbreviation} Version verfügbar",
        prepareBulkExport: "Export mehrerer Daten vorbereiten",
        r2wVersion: " %{redlikeWebAbbreviation} Version",
        setNextVersion: "Nächste Version erstellen",
        showR2WVersion: "Zeige %{redlikeWebAbbreviation} Version",
        startNewTask: "Starten einer neuen Aufgabe für den Datenexport",
        statuses: {
            aborted: "Abgebrochen",
            aborting: "Abbrechen",
            addingIndexes: "Hinzufügen von Indizes",
            addingMetaData: "Hinzufügen von Metadaten",
            deleted: "Gelöscht",
            enqueued: "Eingereiht unter",
            exportingDatabase: "Datenbank exportieren",
            finalizingDatabase: "Fertigstellung der Datenbank",
            processingData: "Verarbeitung der Daten",
            readyToBeProcessed: "Bereit zur Verarbeitung"
        },
        types: {
            archiveLinux: "Linux-Archiv",
            archiveWindows: "Windows-Archiv",
            desoMasterExcel: "DESO Master Excel",
            desoZoneExcel: "DESO Zone Excel",
            emptyDatabase: "Leere Datenbank",
            largoTint2000: "Largo Tint 2000",
            meffertAscPhb: "Meffert ASC/PHB",
            onlyDatabase: "Nur Datenbank",
            onlyDatabaseToWeb: "Datenbank nach %{redlikeWeb} exportieren",
            tmcProductsExcel: "TMC-Produkte Excel"
        }
    },
    fandeck: {
        addFandeck: "Farbtonfächer hinzufügen",
        editFandeck: "Farbtonfächer bearbeiten",
        fandeck: "Farbtonfächer",
        fandeckNotSelected: "Farbtonfächer ist nicht gewählt",
        fandecks: "Farbtonfächer",
        selectFandecks: "Farbtonfächer auswählen",
        selectFromFandecks: "Aus dem Farbtonächer auswählen",
        selectedFandecks: "Farbtonfächer auswählen"
    },
    file: {
        addFile: "Datei hinzufügen",
        addFileCategory: "Dateikategorie hinzufügen",
        addProductFile: "Produktdatei hinzufügen",
        allProductFilesAdded: "Alle Produktdateien wurden hinzugefügt",
        editFile: "Datei bearbeiten",
        editFileCategory: "Dateikategorie bearbeiten",
        editProductFile: "Produktdatei bearbeiten",
        errorOccured: "Beim Ausführen des SQL-Skripts traten einige Fehler in folgenden Zeilen auf",
        file: "Datei",
        fileCategories: "Datei-Kategorien",
        fileCategory: "Datei-Kategorie",
        fileCount: "Anzahl der Dateien",
        filePreview: "Datei %{name}",
        fileSize: "Dateigröße",
        fileType: "Dateityp",
        files: "Dateien",
        instructions: "Anweisungen",
        noFilePreview: "Keine Datei zur Vorschau",
        runningStaticSql: "Ausführen statischer SQL",
        safetySheet: "Sicherheitsdatenblatt",
        selectDirectory: "Verzeichnis auswählen",
        selectFile: "Datei auswählen",
        selectFiles: "Dateien auswählen",
        selectProductFile: "Produktdatei auswählen",
        staticSqlPreview: "Statische SQL-Vorschau %{name}",
        technicalSheet: "Technisches Datenblatt",
        uploading: "Bitte warten, Ihre Dateien werden hochgeladen...",
        utf8File: "Die Datei muss in der utf-8-Kodierung vorliegen."
    },
    flashMessage: {
        base64Input: "Fehler bei der Dekodierung von Base64-Daten",
        cannotConnectToServer: "Es kann keine Verbindung zum Server hergestellt werden.",
        clientConnectionRejected: "Verbindung wurde abgelehnt.",
        clientError: "Client error",
        clientNotLoggedIn: "Der Kunde ist nicht angemeldet",
        clientSessionNotInitialized: "Client-Sitzung nicht initialisiert",
        dataNotAvailable: "Der Server kann keine Verbindung zur Datenbank herstellen.",
        databaseAutologinFailed: "Datenbank-Autologin fehlgeschlagen.",
        databaseClosed: "Die Datenbank ist geschlossen.",
        databaseError:
            "Datenbankfehler, ausgewählte Datenbank kann nicht verwendet werden, prüfen Sie die Protokolldateien für weitere Informationen.",
        databaseErrorOnOpening: "Fehler beim Öffnen der Datenbank, ungültige Datei oder falsches Passwort.",
        databaseNotCompatible: "Datenbankversion ist nicht kompatibel",
        databaseNotFound: "Datenbankdatei nicht gefunden.",
        databaseUpdated: "Datenbank aktualisiert.",
        defaultMessage: "Während der letzten Serveraktion ist ein unerwarteter Fehler aufgetreten.",
        duplicateBarcode: "Der angegebene Barcode ist bereits in Gebrauch.",
        duplicateEntry: "Ein doppelter Eintrag existiert bereits.",
        failedParameterValidation: "Parameterüberprüfung fehlgeschlagen, ungültiger Wert für Parameter: %{0}.",
        fileUploadError: "Beim Hochladen der Datei ist ein Fehler %{0} aufgetreten.",
        importErrorAborted: "Der Importvorgang wurde abgebrochen",
        importErrorDataMissing: "Daten konnten nicht importiert werden, ungültige %{0}-Daten.",
        importErrorFileMissing: "Die Daten konnten nicht importiert werden, die Datei %{0} %{1} fehlt.",
        importErrorGeneric:
            "Daten konnten nicht importiert werden, prüfen Sie die Protokolldateien für weitere Informationen",
        importErrorHeaderMissing:
            "Daten konnten nicht importiert werden, Fehler in der Liste: %{0}, fehlender Header: %{1}",
        importErrorUnderProgress:
            "Die Daten konnten nicht importiert werden, da ein anderer Import gerade aktiv ist. Warten Sie, bis dieser verarbeitet ist.",
        importTaskMandatoryFile: "Obligatorische Datei %{0} für Importaufgabe fehlt oder ist ungültig",
        importTaskMandatoryOption: "Obligatorische Optionen %{0} für die Importaufgabe fehlen oder sind ungültig",
        invalidDatabaseName: "Der Datenbankname ist ungültig.",
        invalidEmail: "Ihre E-Mail ist ungültig.",
        invalidFileId: "Ungültige Datei-ID: %{0}.",
        invalidFileTypeUploading: "Es werden nur %{validFormats}-Formate hochgeladen.",
        invalidFileUploadUuid: "Ungültige UUID für Dateiupload.",
        invalidImage: "Ungültiges Bild: %{0}.",
        invalidItem: "Ungültige %{0}.",
        invalidJsonInput: "Fehler beim Parsen des JSON-Befehls: %{0}",
        invalidLicenseIdentificationKeys: "Ihre Lizenzidentifikationsschlüssel sind ungültig.",
        invalidLicenseKey: "Ihre Lizenz ist ungültig.",
        invalidMethod: "Methode: %{0} nicht verfügbar.",
        invalidName: "Ungültiger Name",
        invalidServer: "Ungültiger Redlike-Server",
        invalidServerResponse:
            "Bei der Verarbeitung der letzten Serverantwort ist ein unerwarteter Fehler aufgetreten.",
        invalidUserPassword: "Ungültiges Passwort",
        invalidValue: "Ungültiger Wert: %{0} für %{1}.",
        itemAlreadyExists: "Das Element %{0} existiert bereits.",
        itemNotDeletable: "%{0} kann nicht gelöscht werden.",
        itemNotEditable: "Der Wert für %{0} kann nicht aktualisiert werden.",
        itemNotFound: "Das Element %{0} wurde nicht gefunden.",
        licenseActivationIsNotPossible: "Im Moment ist die Aktivierung nicht möglich",
        licenseErrorOnSavingLicense: "Beim Speichern der Lizenz ist ein Problem aufgetreten",
        licenseInvalid: "Die Lizenz ist ungültig",
        licenseNoConnectionToLicenseServer: "Es besteht keine Verbindung zum Lizenzserver",
        licenseUpdated: "Die Lizenz wurde aktualisiert",
        licenseValidNoUpdateNeeded: "Die Lizenz ist gültig, eine Aktualisierung ist nicht erforderlich.",
        licenseVerificationFailed: "Die Lizenzüberprüfung ist fehlgeschlagen",
        licenseWebApiError: "Fehler bei der Web-Api-Lizenzierung",
        loginError: "Beim Einloggen ist ein unerwarteter Fehler aufgetreten.",
        logoutError: "Beim Abmelden ist ein unerwarteter Fehler aufgetreten.",
        malformedFormula: "Die Rezeptur ist fehlerhaft",
        missingCommandType: "Der Befehlstyp fehlt.",
        missingDatabaseConfigFile: "Datenbank-Konfigurationsdatei fehlt",
        noDatabaseSelected: "Keine Datenbank ausgewählt",
        noSystemSelected: "Kein System ausgewählt",
        noZoneSelected: "Keine Zone ausgewählt",
        notFoundBarcode: "Der Barcode wurde nicht gefunden.",
        notFoundBipp: "Base in der Produktverpackung nicht gefunden, kompatible Base: %{0}, verfügbare Gebinde: %{1}.",
        notFoundCustomer: "Kunde nicht gefunden",
        notFoundDirectory: "Verzeichnis: %{0} nicht gefunden.",
        notFoundFile: "Datei: %{0} nicht gefunden.",
        notFoundFormula: "Rezeptur nicht gefunden",
        orderAlreadyAcquired: "Der Auftrag wurde bereits erteilt, er muss erst freigegeben werden.",
        serverAlreadyDefined: "Redlike Server ist bereits definiert",
        serverError: "Server-Fehler",
        shortUserPassword: "Benutzerpasswort ist zu kurz",
        unknownCommand: "Unbekannter Befehlstyp: %{0}",
        unknownOption: "Unbekannte Option",
        unsupportedType: "Nicht unterstützter Typ",
        userAlreadyLoggedIn: "Sie sind bereits eingeloggt.",
        userChangePasswordFailed: "Überprüfung des alten Passworts fehlgeschlagen"
    },
    forcedFormulaBaseAmountType: {
        default: "Default",
        gravimetric: "Gravimetric",
        useAsItIs: "Unverändert verwenden",
        volumetric: "Volumetric"
    },
    formula: {
        addFormula: "Rezeptur hinzufügen",
        allStatuses: "Alle Zustände",
        colorsWithSameFormula: "Farbtöne mit der gleichen Rezeptur",
        colorsWithThisFormula: "Farbtöne mit dieser Rezeptur",
        createNewFormula: "Neue Rezeptur erstellen",
        currentFormula: "Aktuelle Rezeptur",
        editFormula: "Rezeptur bearbeiten %{colorName}",
        formula: "Rezeptur",
        formulaFor: "Rezeptur für",
        formulaForBaseVolume: "Für das Basenvolumen",
        formulaForNominalAmount: "Für den Nennwert",
        formulas: "Rezepturen",
        historicalFormula: "Historische Rezeptur",
        jumpToFormula: "Zur Rezeptur springen",
        noFormula: "Es gibt keine Rezeptur für den Farbton %{colorName}",
        noFormulaFor: "Kein Rezeptur für",
        noOtherFormula: "Es ist kein anderes Rezept verfügbar",
        noValidFormula: "Es gibt kein gültiges Rezept",
        onlyReplaceExistingData:
            "Speichern Sie diese Daten als neue Version des Rezepts und die vorherige als historische Version"
    },
    formulaNote: {
        addCode: "Code hinzufügen",
        addFormulaNote: "Rezepturhinweis hinzufügen",
        editFormulaNote: "Rezepturhinweis bearbeiten",
        formulaNote: "Rezepturhinweis",
        formulaNotes: "Rezepturhinweise",
        selectCode: "Code für Rezepturhinweise auswählen"
    },
    general: {
        accepted: "Akzeptiert",
        action: "Aktion",
        actionMethod: "Aktionsmethode",
        actionType: "Art der Aktion",
        add: "Hinzufügen",
        additionalProperties: "Zusätzliche Eigenschaften",
        all: "Alle",
        allItemsAlreadyAdded: "Alle %{objectType} wurden bereits hinzugefügt",
        amount: "Menge",
        amountCoef: "Koeffizient der Menge",
        back: "Zurück",
        backup: "Backup",
        beginValue: "Anfangswert",
        bfs: "BFS",
        canDelete: "Gebinde löschen",
        canUpdate: "Gebinde update",
        cancel: "Abbrechen",
        canceled: "Abgebrochen",
        category: "Kategorie",
        changePassword: "Passwort ändern",
        changelog: "Changelog",
        checkAll: "Alle überprüfen",
        close: "Close",
        code: "Code",
        coefficient: "Coefficient",
        colorBlue: "B",
        colorGreen: "G",
        colorRed: "R",
        computerId: "Computer ID",
        confirm: "Bestätigen",
        continue: "Continue",
        createNew: "Neu erstellen",
        created: "Erstellt",
        dataMasterSettings: "Master settings",
        date: "Datum",
        dateFilter: "Datum-Filter",
        debugInfo: "Debug-Informationen",
        defaultData: "Standarddaten",
        delete: "Löschen",
        density: "Dichte",
        description: "Beschreibung",
        discard: "Ablegen",
        download: "Download",
        duplicate: "Duplizieren",
        edit: "Bearbeiten",
        editMetadata: "Metadaten bearbeiten",
        endValue: "Endwert",
        errorDetail: "Fehlerdetails",
        export: "Export",
        exportImportData: "Daten exportieren/importieren",
        fillProc: "Füllung in Prozent",
        fillVolume: "Füllmenge",
        filter: "Filter",
        finished: "Beendet",
        fitToHeight: "Anpassung an die Größe",
        general: "Allgemein",
        genericData: "Allgemeine Daten",
        globalSettings: "Globale Einstellungen",
        globalTableSearch: "Globale Tabellensuche...",
        gravimetric: "Gravimetrisch",
        gravimetricFill: "Gravimetrische Füllung",
        groups: "Gruppen",
        height: "Höhe",
        hide: "Ausblenden",
        hideShow: "Ausblenden/Anzeigen",
        historical: "Historisch",
        history: "History",
        id: "ID",
        import: "Import",
        importExport: "Import/export",
        inherited: "Vererbt",
        interval: "Intervall",
        invalid: "Invalid",
        itemsCount: "Anzahl der Artikel",
        itemsOnPage: "Artikel auf der Seite",
        key: "Schlüssel",
        largoName: "Largo name",
        layout: "Layout",
        length: "Länge",
        level: "Level",
        line: "Line",
        lock: "Sperren",
        manager: "Manager",
        metaData: "Metadata",
        minDosage: "Minimale Dosierung",
        missingData: "Einige Daten fehlen",
        moreInfo: "Zusatzinformationen",
        moreInfoInternal: "Interne Zusatzinformationen",
        moreInfoPrint: "Zusatzinformationen drucken",
        name: "Name",
        next: "Nächste",
        no: "Nein",
        noCode: "Kein Code",
        noMoreInfo: "Keine Zusatzinformationen",
        noMoreInfoInternal: "Keine Interne Zusatzinformationen",
        noMoreInfoPrint: "Keine Zusatzinformationen drucken",
        noName: "Kein Name",
        noPreviewAvailable: "Keine Vorschau verfügbar",
        noPriority: "Keine Priorität",
        noResult: "Keine Ergebnisse gefunden",
        noSearchCode: "Kein Code-Suche",
        noSearchName: "Kein Name suchen",
        noStatus: "Kein Status",
        noTmcName: "Keine TMC-Bezeichnung",
        noUuid: "Kein UUID",
        nominalAmount: "Nominaler Wert",
        notAvailable: "Not available",
        note: "Hinweis",
        nothingToAdd: "Keine weiteren Informationen verfügbar",
        ok: "OK",
        operationDuration: "Dieser Vorgang kann mehrere Minuten dauern.",
        option: "Option",
        options: "Optionen",
        parameters: "Parameter",
        password: "Passwort",
        passwordChange: "Passwort ändern",
        passwordNew: "Neues Passwort",
        passwordNewRepeat: "Neues Passwort wiederholen",
        passwordOld: "Altes Passwort",
        path: "Pfad",
        pleaseWait: "Bitte warten...",
        preview: "Vorschau",
        previous: "Vorherige",
        primary: "Ursprünglich",
        printName: "Druckname",
        priority: "Priorität",
        properties: "Eigenschaften",
        range: "Bereich",
        readOnly: "Nur lesen",
        redlikeManagerSettings: "%{thisSoftware} Einstellungen",
        redlikeOptions: "%{software} Optionen",
        redlikePrivileges: "%{software} Einstellungen",
        redlikeUsers: "%{software} Benutzer",
        rejected: "Abgelehnt",
        reloadData: "Daten neu laden",
        restoreAllValues: "Wiederherstellen aller Standardwerte",
        restoreSelectedValue: "Ausgewählten Standardwert wiederherstellen",
        rgb: "RGB",
        rounding: "Rundung",
        run: "Ausführen",
        save: "Speichern",
        saveAll: "Alles speichern",
        saving: "Daten speichern",
        search: "Suche...",
        searchCode: "Suchcode",
        searchName: "Suchname",
        section: "Abschnitt",
        select: "Auswählen",
        selectFile: "Datei von der Festplatte auswählen...",
        serverMonitoring: "Server-Überwachung",
        serverScripts: "Server-Skripte",
        set: "Setzen",
        setAllToDefaultValues: "Alle auf Standardwerte setzen",
        settings: "Einstellungen",
        show: "Anzeigen",
        showAll: "Alle anzeigen",
        showDeletedValues: "Gelöschte Werte anzeigen",
        showHiddenValues: "Ausgeblendete Werte anzeigen",
        showHideColumns: "Spalten ein-/ausblenden",
        showingItemsOfTotal: "Anzeige von %{from} bis %{to} von %{total} Einträgen",
        signOut: "Abmelden",
        size: "Größe",
        sizeByte: "Byte",
        sizeBytes: "Bytes",
        sizeGb: "GB",
        sizeKb: "kB",
        sizeMb: "MB",
        sizeTB: "TB",
        spectralData: "Spektrale Daten",
        staticSql: "Static SQL",
        status: "Status",
        summary: "Zusammenfassung",
        symbol: "Symbol",
        systemZoneSettings: "System- u. Zoneneinstellungen",
        text: "Text",
        textMaster: "Mastertext",
        textSystem: "Systemtext",
        tmcName: "TMC-Bezeichnung",
        type: "Typ",
        typeHereFilter: "Hier eingeben, um danach zu filtern...",
        ufiCode: "UFI code",
        uncheckAll: "Alle abwählen",
        update: "Update",
        updated: "Aktualisiert",
        url: "Url",
        userAccessControl: "Benutzerzugangskontrolle",
        uuid: "UUID",
        valid: "Valid",
        value: "Wert",
        verified: "Geprüft",
        version: "Version",
        versionName: "Versionsname",
        warning: "Warnung",
        width: "Breite",
        yes: "Ja"
    },
    help: {
        createTicket: "Ticket erstellen",
        documentationPage: "Seite der Dokumentation",
        fastAnswer: "Für eine schnellere Antwort, bitte",
        helpNeed: "Benötigen Sie Hilfe?",
        hotline: "Hotline",
        hoursAvailable: "Erreichbar von Montag bis Freitag von 8 Uhr bis 16 Uhr MEZ.",
        orVisit: "Oder besuchen Sie"
    },
    image: {
        addIcon: "Icon hinzufügen",
        addImage: "Bild hinzufügen",
        addImageCategory: "Bildkategorie hinzufügen",
        addImageMore: "Bilder hinzufügen",
        clearImage: "Bild entfernen",
        editImage: "Bild bearbeiten",
        editImageCategory: "Bildkategorie bearbeiten",
        icon: "Icon",
        image: "Bild",
        imageCategories: "Bild-Kategorien",
        imageCategory: "Bild-Kategorie",
        images: "Bilder",
        loadImage: "Neues Image von der Festplatte laden...",
        noImage: "Kein Bild",
        noImageAvailable: "Kein Bild verfügbar",
        noImagePreview: "Kein Bild als Vorschau",
        selectIcon: "Icon/Symbol von der Festplatte auswählen...",
        selectIconFromExisting: "Icon/Symbol auswählen",
        selectImage: "Bild von der Festplatte auswählen...",
        selectImageFromExisting: "Bild auswählen"
    },
    import: {
        abortImport: "Import abbrechen",
        dataImport: "Import",
        deleteImports: "Importe löschen",
        downloadSourceFilesForTask: "Quelldateien für den Task herunterladen",
        formats: {
            akzoMasterZone:
                "Import format that allows to import data from Akzo CSV files. Formulas and system / zone data is processed.",
            barilExcelMaster:
                "Import format that allows to import data from Baril Redlike master Excel format. Only formulas are imported.",
            datacolorBarilCSVMaster:
                "Import format that allows to import data from Datacolor CSV files specially designed for Baril. Only formulas are imported.",
            datacolorCSVMaster:
                "Import format that allows to import data from Datacolor CSV files. Only formulas are imported.",
            datacolorXMLMaster:
                "Import format that allows to import data from Datacolor XML files. Only formulas are imported.",
            desoMaster:
                "Import format that allows to import data from Deso Redlike master Excel format. Only formulas are imported.",
            desoPriceGroupBarcodesZone:
                "Import format that allows to import data from Deso price group barcodes file. Only price group barcodes are imported.",
            desoZone: "Import format that allows to import data from Deso Redlike zone Excel format."
        },
        importData: "Daten importieren",
        importDate: "Datum des Imports",
        importTask: "Task/Auftrag importieren",
        importTaskList: "Liste der Importe",
        keepOldFormulas: {
            keepEverything: "Zusammenführen mit bestehenden Rezepturen",
            removeAll: "Alle Daten löschen (außer Metadaten)",
            removeFormulas:
                "Vorhandene Rezepturen löschen und neue erstellen (Basen, Farbtöne, Produkte und deren Verpackungen bleiben erhalten)"
        },
        modules: {
            importerMasterAlfa: "Importer Master Alfa",
            importerMasterCdColor: "Importer Master CD Color CSV Format",
            importerMasterCdColor2: "Importer Master CD Color2",
            importerMasterColibri: "Importer Master Colibri",
            importerMasterColibriXml: "Importer Master Colibri XML",
            importerMasterColorComposer: "Importer Master Color Composer",
            importerMasterCorobGdata: "Importer Master Corob GData Format",
            importerMasterDatacolor: "Importer Master Datacolor",
            importerMasterDatacolorCsv: "Importer Master Datacolor CSV Format",
            importerMasterDatacolorXml: "Importer Master Datacolor XML Format",
            importerMasterFlugger: "Importer Master Flugger Boomi API Format",
            importerMasterFlugger2: "Importer Master Flugger 2",
            importerMasterInnovatint1: "Importer Master Innovatint 1",
            importerMasterInnovatint3: "Importer Master Innovatint 3 Format",
            importerMasterInnovatintText: "Importer Master Innovatint Text",
            importerMasterLargo2000: "Importer Master LargoTint2000",
            importerMasterLargoT3: "Importer Master LargoT3",
            importerMasterMeffertCsv: "Importer Master Meffert CSV Format",
            importerMasterMeffertSql: "Importer Master Meffert SQL Format",
            importerMasterPpf: "Importer Master PDF",
            importerZoneMeffert: "Importer Zone Meffert"
        },
        optionOldFormulas: "Aktuell gespeicherte Daten",
        optionStrict: "Strikter Modus",
        optionStrictText: "Datenverarbeitung bei Auftreten eines Fehlers anhalten",
        sectionDataFiles: "Dateien",
        sectionOptions: "Eingabeoptionen und Parameter",
        startNewImportTask: "Neuen Datenimport starten",
        statuses: {
            aborted: "Abgebrochen",
            aborting: "Abbrechen",
            analyzingFile: "Datei analysieren",
            connectingToDatabase: "Datenbank verbinden",
            deleted: "Gelöscht",
            enqueued: "Eingereiht unter",
            openingFile: "Datei öffnen",
            preparingForImport: "Für den Import vorbereiten",
            processingBaseInProducts: "Verarbeitung der Base in Produkten",
            processingBasePackages: "Verarbeitung von Basenverpackungen",
            processingBases: "Basen verarbeiten",
            processingColorBarcodes: "Verarbeitung von Farbbarcodes",
            processingColorantPackages: "Verarbeitung von Pigmentkonzentratverpackungen",
            processingColorants: "Verarbeitung von Pigmentkonzentraten",
            processingColors: "Verarbeitung von Farbtönen",
            processingComponents: "Verarbeitung von Komponenten",
            processingFandecks: "Verarbeitung von Farbtonfächern",
            processingFormulas: "Verarbeitung von Rezepturen",
            processingNotes: "Verarbeitung von Hinweisen",
            processingOptions: "Verarbeitungsmöglichkeiten",
            processingPackages: "Verarbeitung von Verpackungen",
            processingPrices: "Verarbeitung von Preisen",
            processingProductBarcodes: "Verarbeitung von Produkt-Barcodes",
            processingProductGroups: "Verarbeitung von Produktgruppen",
            processingProductSources: "Verarbeitung von Produktquellen",
            processingProducts: "Verarbeitung von Produkten",
            processingSettings: "Einstellungen verarbeiten",
            processingSpectro: "Verarbeitung des Spektrometers",
            processingStaticData: "Verarbeitung statischer Daten",
            processingUserGroups: "Verarbeitung von Nutzergruppen",
            processingUsers: "Verarbeitung von Benutzern",
            removingData: "Daten löschen",
            removingFormulas: "Rezepturen löschen",
            renamingBases: "Basen umbenennen",
            renamingColorants: "Pigmentkonzentrat umbenennen",
            renamingColors: "Farbe umbenennen",
            renamingFandecks: "Farbtonfächer umbenennen",
            renamingProductGroups: "Produktgruppen umbenennen",
            renamingProducts: "Produkte umbenennen",
            resultError: "Fehler beim Importieren",
            resultOk: "Import beendet"
        }
    },
    license: {
        activateLicense: "Lizenz aktivieren",
        activateNewLicense: "Neue Lizenz aktivieren",
        activatingLicense: "Bitte warten, Ihre neue Lizenz wird gerade aktiviert...",
        address: "Adresse",
        asteriskMark: "Die mit einem Sternchen gekennzeichneten Felder müssen ausgefüllt werden",
        city: "Stadt",
        company: "Firma",
        companyId: "Firmen ID",
        contactPerson: "Kontaktperson",
        copy: "Kopieren",
        country: "Land",
        email: "E-Mail",
        enterKeys: "Bitte geben Sie Ihre Identifikationsschlüssel ein",
        expiration: "Verfallsdatum",
        fillData: "Bitte füllen Sie die Registrierungsdaten aus",
        insertKey: "Bitte geben Sie den generierten Schlüssel für die Offline-Aktivierung ein",
        license: "Lizenz",
        licenseIdentification: "Lizenz-Identifikation",
        licenseInformation: "Lizenzinformationen",
        licenseStatus: "Lizenzstatus",
        licenseWillExpire: "Ihre Lizenz läuft in %{dayCount} Tagen ab",
        modules: "Module",
        noLicenseAvailable: "Keine Lizenz verfügbar",
        phone: "Telefon",
        productNotLicensed: "Das Produkt ist nicht lizenziert",
        productRedlikeManagerBasic: "Redlike Manager Basic",
        productRedlikeManagerStandard: "Redlike Manager Standard",
        refresh: "Aktualisieren",
        region: "Region",
        registerLicense: "Bitte nehmen Sie eine Kopie der Aktivierungsanfrage und gehen Sie zu",
        removeLicense: "Lizenz löschen",
        reset: "Reset",
        selectProduct: "Produkt auswählen",
        street: "Straße",
        updateLicense: "Lizenz aktualisieren",
        userId: "User ID",
        uuid: "License UUID",
        versionTypes: "Versionstypen",
        zip: "Postleitzahl"
    },
    login: {
        incorrectLogin: "Benutzername oder Passwort ist falsch",
        loading: "Sicheres Anmelden...",
        login: "Anmelden"
    },
    margin: {
        addMargin: "Marge/Rabatt hinzufügen",
        editMargin: "Marge/Rabatt bearbeiten"
    },
    navigation: {
        deleteAll: "Alles löschen",
        noNotifications: "Keine neuen Benachrichtigungen",
        showAllMessages: "Alle Meldungen anzeigen",
        showLess: "Weniger anzeigen"
    },
    oneTimeAccessPassword: {
        copyToClipboard: "Copy to clipboard",
        generateOneTimeAccessPassword: "Generate one time %{software} access password",
        generatePassword: "Generate password",
        hint: "To log in %{software} use standard login dialog. In the username field enter %{username} and in the password field enter the generated password below.",
        oneTimeAccessPassword: "One-time access password",
        validForOneDay: "Valid for one day"
    },
    options: {
        deleteConfirmation: "Bestätigungsdialog vor dem Löschen von Elementen anzeigen",
        description: {
            additionalTabType: "Legt den Typ der neuen Registerkarte fest, wenn Sie auf die Plus-Schaltfläche klicken",
            autoCloseTab: "Legt fest, ob die Registerkarte Formel nach Beendigung der Dosierung geschlossen wird",
            automaticClientStart: "Legt fest, ob die Anwendung sofort startet, nachdem der PC gestartet wurde",
            automaticLogOut: "Legt fest, ob der angemeldete Benutzer aufgrund von Inaktivität abgemeldet werden soll.",
            automaticLogOutTimeOut:
                "Legt fest, wie viele Minuten vergehen müssen, bevor der Benutzer automatisch abgemeldet wird.",
            automaticPrint: "Legt fest, ob der Druck während der Ausgabe automatisch erfolgt",
            barcodesEnabled: "Legt fest, ob Barcode-Validierungen und Barcode-Testumgebungen erlaubt sind",
            checkMinDosableAmount:
                "Legt fest, ob die minimale färbbare Menge geprüft wird. Die Option bezieht sich auf das Element Minimale Dosierung der Pigmentkonzentrate in %{thisSoftware}.",
            checkOverfill: "Legt fest, ob innerhalb einer Formel auf Pigmentkonzentratüberfüllung geprüft wird.",
            checkTotalCntAmounts:
                "Legt fest, ob die minimale und maximale Pigmentkonzentratmenge in einer Formel geprüft wird. Die Option bezieht sich auf das Element Minimale Pigmentkonzentratmenge und Maximale Pigmentkonzentratmenge für Pigmentkonzentrate in %{thisSoftware}.",
            colorPreviewResizable:
                "Legt fest, ob die Farbvorschau aufgeklappt werden kann. Bei Fokus bedeutet, dass die Erweiterung nur verfügbar ist, solange sich der Mauszeiger über ihr befindet.",
            colorTabCompatibleOnly: "Legt fest, ob nicht existierende Rezepturen angezeigt werden",
            colorTabFormulaCode: "Legt fest, ob der Rezepturcode auf jeder Kachel im Farbpanel sichtbar ist",
            colorTabFormulaName: "Legt fest, ob der Rezepturname auf jeder Kachel im Farbpanel sichtbar ist",
            colorTabNoteIcons: "Legt fest, ob auf der Farbpalette Notizensymbole sichtbar sind.",
            colorTabOrderBy: "Legt fest, wie die Kacheln im Farbpanel angeordnet sind",
            colorTabRowCount: "Legt fest, wie viele Kacheln auf dem Farbpanel angezeigt werden",
            colorTabWarningIcon: "Legt fest, ob das Warnsymbol auf Kacheln im Farbpanel sichtbar ist",
            colorantPackageProc: "Legt den Standardwert für die Füllung des Pigmentkonzentratgebindes in Prozent fest.",
            colorantPanelOrderBy:
                "Definiert den Parameter, nach dem die Pigmentkonzentrate beim Öffnen einer Rezeptur angeordnet werden.",
            competitiveColorSearch: "Legt fest, ob die konkurrierende Farbsuche im Bedienfeld Farbe erlaubt ist",
            currencyCode:
                "Legt die Standardwährung für die Preisberechnung fest (zusätzlich zur Einstellung der Währung in den Zonen-Metadaten)",
            customBannerPosition: "Legt fest, wo das Banner angezeigt wird, wenn es verwendet wird",
            customCanOption:
                "Legt fest, welche Einheiten bei der Verwendung benutzerdefinierter Pakete verwendet werden dürfen",
            customCanSize:
                "Legt fest, ob das benutzerdefinierte Gebinde im Rezeptur-Fenster angezeigt wird und ausgewählt werden kann",
            customCanUnits:
                "Legt fest, welche Maßeinheiten für benutzerdefinierte Gebinde zulässig sind, wobei dies nur gilt, wenn customCanOption auf customUnits gesetzt ist.",
            customFormulas:
                "Legt fest, ob Schaltflächen für benutzerdefinierte Rezepturen im Rezepturbereich und in der globalen Suche angezeigt werden",
            customersEnabled: "Legt fest, ob die Kundenauswahl im Rezepturfenster sichtbar ist",
            deleteConfirmation:
                "Legt fest, ob ein Popup-Fenster zur Bestätigung des Löschens von Elementen angezeigt werden soll",
            dispensingEnabled: "Legt fest, ob die Schaltfläche Dosieren sichtbar ist",
            dispensingNotes: "Legt fest, ob der Dosierhinweis im Rezeptur-Fenster angezeigt wird",
            doNotShowAgainCloseRedlike:
                "Legt fest, ob das Pop-up-Fenster mit der Meldung â€žMöchten Sie %{software} wirklich schließen? Alle %{software}-Bedienfelder werden geschlossen, nicht gespeicherte Änderungen gehen verloren.â€ť erscheint oder nicht",
            editColorantAllowance:
                "Legt fest, ob die Schaltfläche Pigmentkonzentrate bearbeiten sichtbar ist, wenn eine Formel ausgewählt ist",
            formulaAutomaticNaming:
                "Legt fest, ob der Name der Rezeptur nach der Änderung von Pigmentkonzentraten automatisch geändert wird",
            formulaCode:
                "Legt fest, ob der Rezeptcode bei der Suche nach einer Rezeptur angezeigt wird. Muss true sein, wenn formulaName false ist",
            formulaCodePrimary:
                "Legt fest, ob der Rezeptcode als erster in der Zeile angezeigt wird, wenn Rezeptname und Code in einem Suchfeld zusammengefasst sind.",
            formulaColorPreview:
                "Legt fest, ob die Farbe der Rezeptur bei der Suche nach einer Rezeptur angezeigt wird",
            formulaName:
                "Legt fest, ob der Rezepturame bei der Suche nach einer Rezeptur angezeigt wird. Muss wahr sein, wenn formulaCode falsch ist.",
            formulaNotes: "Legt fest, ob die Rezeptnotiz im Rezepturbereich angezeigt wird",
            formulaPreview: "Legt fest, was im Detail angezeigt wird, wenn eine Rezeptur ausgewählt wird",
            formulaStatusPanelEnabled:
                "Legt fest, ob auf dem Rezeptur-Fenster das Rezepturfenster-Status-Panel mit dem Rezeptur-Status und dem Erstellungsdatum verfügbar ist.",
            formulaUnits: "Legt die verwendete Maßeinheit fest, standardmäßig ist es",
            fts: "Definiert die Art der Textsuche. Wenn true, ist die Suche schneller, aber nicht im Volltext.",
            generateColorPreview:
                "Legt fest, ob automatisch generierte RGB-Werte aktiviert sind. Die berechneten RGB-Werte sind nur indikativ!",
            help: "Legt fest, welche Werte im Hilfebereich angezeigt werden",
            helpLink: "Definiert einen benutzerdefinierten Hilfe-Link im Hilfe-Bereich",
            infoPanelResizable:
                "Legt fest, ob das Info-Panel ausgeklappt werden kann. Bei Fokus bedeutet, dass die Erweiterung nur so lange verfügbar ist, wie der Cursor darüber schwebt.",
            infoPanelTab:
                "Definiert den Tab-Typ im Info-Panel im Rezepte-Detail, wenn formulaPreview auf image/more info + color gesetzt ist 'Ãndert sich mit der Verwendung von'.",
            infoTypes:
                "Legt fest, welche Werte angezeigt werden und in welcher Reihenfolge sie im Info-Panel im Rezepturfenster erscheinen",
            initTabType: "Legt fest, welcher Tab-Typ beim Starten der Software geöffnet wird",
            keyboard: "Definiert die ausgewählte Tastatur, die für die Anzeige der virtuellen Tastatur verwendet wird",
            keyboardShortcuts: "Legt die Liste der verwendeten Tastaturkürzel fest",
            kiosk: "Legt fest, ob sich die Anwendung im Kioskmodus befindet",
            language: "Legt fest, welche Sprache verwendet wird",
            lightEnabled: "Definiert die Möglichkeit, %{software} in die Light-Version umzuschalten",
            lockPC: "Legt fest, ob der Computer gesperrt werden soll, d. h. Systemoperationen sind nicht verfügbar, erfordert eingeschalteten Kioskmodus und automatische %{software}",
            marginMarkup:
                "Legt fest, ob die Randeinstellungen nur in den Rändern, den Markierungen oder in beiden Werten angezeigt werden",
            maxToleranceForCompetitiveColors:
                "Legt die maximale Toleranz (CIA 2000) für die kompetitive Farbsuche fest",
            navigationItemsOrdered:
                "Legt fest, welche Werte angezeigt werden und in welcher Reihenfolge sie in der Navigationsleiste erscheinen. Die Grenze ist auf 6 gesetzt.",
            numberOfCompetitiveColors:
                "Definiert die Anzahl der konkurrierenden Farbtöne, die nach der Suche im Farbpanel erscheinen",
            numberOfTabs: "Legt die maximale Anzahl von Rezepturfenstern fest, die gleichzeitig geöffnet werden können",
            orderModalEnabled:
                "Legt fest, ob das Popup-Fenster Bestellung angezeigt wird, wenn eine neue Bestellung eingeht",
            orderSearchItems: "Legt fest, welche Werte bei der Auftragssuche in Light verwendet werden",
            orderingEnabled:
                "Legt fest, ob alle Funktionen, die mit der Bestellung verbunden sind, aktiviert sind (Bestellbutton, Meine Bestellungen, Zur Ausgabe, Client/Server-Verbindung)",
            overwriteLastTab:
                "Legt fest, ob nach der Suche mit der globalen Suche das zuletzt geöffnete Rezepturfenster mit der neuen Suche Überschrieben werden soll, wenn das Limit der Rezepturfenster erreicht ist. Wenn nicht, erscheint die Meldung 'Das Limit wurde erreicht, bitte schließen Sie ein Rezeptur-Fenster.'",
            overwriteLogo: "Legt fest, ob sich das Logo bei einem Wechsel der Datenbank ändert",
            priceTypes: "Definiert die Arten der verfügbaren Preise, die im Rezepturfenster sichtbar sein werden",
            priceVisibilityTypes:
                "Legt fest, welche Preise sichtbar sind: mit Mehrwertsteuer, ohne Mehrwertsteuer oder beides",
            pricingEnabled: "Legt fest, ob das gesamte Preissystem erlaubt ist",
            printAfterDispensing:
                "Legt fest, ob nach der Ausgabe automatisch gedruckt werden soll, muss auch Seiten einstellen",
            printAsImage:
                "Legt fest, ob die Vorlage als Bild gedruckt werden soll, hilft bei der Fehlersuche beim Drucken unter Linux",
            printBeforeDispensing:
                "Legt fest, ob nach der Ausgabe automatisch gedruckt werden soll, muss auch Seiten einstellen",
            printEnabled: "Legt fest, ob Druckoptionen erlaubt sind",
            printOnRequest: "Legt fest, ob die Schaltfläche Drucken im Rezepturfenster angezeigt wird.",
            printTasksAfterDispensing: "Definiert die nach der Ausgabe eingestellten Druckaufgaben",
            printTasksBeforeDispensing: "Definiert die vor der Ausgabe eingestellten Druckaufgaben",
            printer:
                "Legt fest, welcher Drucker verwendet wird. Der Drucker muss eingesteckt, installiert und funktionsfähig sein",
            printerTemplate:
                "Legt fest, welche Vorlage für den Druck verwendet wird. Die Vorlage muss im Ordner templates vorhanden sein",
            printerTemplateQuickTint:
                "Legt fest, welche Vorlage für den Druck von Quick Tint verwendet wird. Die Vorlage muss im Vorlagenordner vorhanden sein",
            productCode: "Legt fest, ob der Produktcode bei der Suche nach einer Formel angezeigt wird",
            productImagePreview:
                "Legt fest, ob das Produktbild während der Dosierung in einem Pop-up-Fenster angezeigt wird",
            quickTintCloseAfterDispensing:
                "Legt fest, ob das Quick Tint-Pop-up-Fenster vor oder nach der Dosierung geschlossen wird",
            quickTintSortingType:
                "Legt fest, wie die Pigmentkonzentrate im Fenster Quick Tint sortiert werden (alphabetisch oder nach Kanister-ID)",
            rounding: "Definiert die Anzahl der Dezimalstellen für die Rundung des Pigmentkonzentrats",
            screensaverTimeout: "Legt fest, nach wie vielen Minuten der Bildschirmschoner angezeigt wird",
            searchPanelBase: "Legt fest, ob Base für die Suche verwendet werden kann",
            searchPanelBaseInProductPackage: "Legt fest, ob das Produktgebinde für die Suche verwendet werden kann",
            searchPanelOrder: "Legt die Reihenfolge der Eingaben im Suchfeld fest",
            searchPanelProduct: "Legt fest, ob das Produkt für die Suche verwendet werden kann",
            searchPanelWithoutConfirmation:
                "Legt fest, ob die Rezeptur automatisch geladen werden soll, wenn alle Informationen vorliegen (kein Klicken auf die Schaltfläche 'Laden' erforderlich)",
            settingsActiveFormulaUnit:
                "Definiert die zuletzt verwendete Einheit der Pigmentkonzentrate im Rezepturfenster, sie hat Vorrang vor formulaUnit â€ sie ändert sich mit der Verwendung von %{software} (sie merkt sich die letzte Einstellung)",
            settingsBlendFormulaAndCode:
                "Legt fest, ob Rezzeptname und Rezeptcode in einem Suchfeld zusammengeführt werden, gilt nur, wenn â€śformulaNameâ€ť und â€śformulaCodeâ€ť beide wahr sind",
            settingsCleaningTime: "Definiert die Zeit der Reinigung (Serviceaktion) in Sekunden",
            settingsCustomHomeButton:
                "Definiert die Homeszene in der Light-Version (was nach einem Klick auf die Schaltfläche Home angezeigt wird)",
            settingsInfoItemInRgbPreview:
                "Legt fest, welche Informationen in der Farbvorschau im Rezepturfenster sichtbar sind",
            settingsManualAdditionUnit:
                "Definiert die zuletzt verwendete Einheit im Pop-up-Fenster für die manuelle Hinzufügung â€ sie ändert sich mit der Verwendung von %{software} (sie merkt sich die letzte Einstellung)",
            settingsRecirculationSpeed:
                "Definiert die Standardgeschwindigkeit der Rezirkulation (Serviceaktion) in Prozent",
            settingsRecirculationTime: "Definiert die Standardzeit der Rezirkulation (Serviceaktion) in Sekunden",
            settingsStirringSpeed: "Definiert die Standardgeschwindigkeit der Rezirkulation (Serviceaktion) in Prozent",
            settingsStirringTime: "Definiert die Standardzeit des Rührens (Serviceaktion) in Sekunden",
            shotsCoef: "Definiert den Coeffizienten, der für die Neuberechnung von ml zu Shot verwendet wird",
            shotsName: "Definiert den Namen der Shots, die an mehreren Stellen in der Software sichtbar sind",
            softwareName: "Definiert den Namen der Software",
            softwareNameLight: "Definiert den Namen der Light-Version der Software",
            soundEnabled: "Legt fest, ob der Ton beim Eingang eines neuen Auftrags aktiviert wird",
            spectroEnabled:
                "Legt fest, ob spectro aktiviert ist, beeinflusst den gesamten spectro-Bereich und die Einstellungen",
            style: "Legt die Definition des benutzerdefinierten Style fest",
            tableBase:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Base angezeigt wird",
            tableCustomer:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense, die Spalte Kunde angezeigt wird",
            tableDate:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Datum angezeigt wird",
            tableFandeck:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Farbtonfächer angezeigt wird",
            tableFormulaCode:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense, die Spalte Rezeptur angezeigt wird",
            tableFormulaName:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Rezepturname angezeigt wird",
            tableMoreInfo:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Notiz angezeigt wird",
            tablePackage:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Gebindegröße angezeigt wird",
            tableProduct:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense die Spalte Produkt angezeigt wird",
            tableQuantity:
                "Legt fest, ob in den Tabellen in Dispensing History, My orders und To Dispense, die Spalte Anzahl angezeigt wird",
            tabsEnabled:
                "Legt fest, ob die Arbeit mit Registerkarten erlaubt ist oder ob jeweils nur eine Registerkarte möglich ist",
            ufiCodeBaseValidation: "Legt fest, ob der Basis-UFI-Code bei der Abgabe angefordert wird",
            ufiCodeLogic:
                "Legt fest, ob UFI-Codes aktiviert sind. Und wenn ja, welche UFI-Codes beim Drucken verwendet und in %{software} angezeigt werden.",
            ufiCodeMinAmountGravPercent:
                "Legt den Grenzwert für die Pigmentkonzentratmenge fest. Nach Überschreiten dieses Grenzwertes werden UFI-Codes auf das Etikett gedruckt",
            ufiCodeMinGravPercent:
                "Legt den Grenzwert für die Pigmentkonzentratmenge fest. Nach Überschreiten dieses Grenzwerts werden UFI-Codes einschließlich ihres Prozentsatzes auf das Etikett gedruckt",
            userIdentification:
                "Legt fest, ob der Benutzer vor der Ausgabe autorisiert werden muss (Eingabe des Benutzerpassworts)",
            virtualKeyboardEnabled:
                "Legt fest, ob die virtuelle Tastatur aktiviert ist, die bei der hellen und manuellen Barcodeeingabe verwendet wird.",
            visibleUnits: "Legt fest, welche Einheiten für Pigmentkonzentrate im Rezepturfenster sichtbar sind",
            zoom: "Definiert den Zoom der Software, hilft bei der Anpassung der Software an die Bildschirmauflösung"
        },
        editOption: "Option bearbeiten",
        environmentLanguage: "Umgebungssprache",
        environmentalSettings: "Umgebungseinstellungen",
        globalSettings: "Allgemeine Standardeinstellung",
        language: "Sprache",
        languages: {
            english: "Englisch",
            german: "Deutsch"
        },
        lockCaption: "Freischaltung",
        setAllToDefaultValues: "Alle auf Standardwerte setzen",
        showDefaultValues: "Standardwerte anzeigen",
        showFilterRow: "Filterzeile ein-/ausblenden",
        showGlobalSearch: "Globale Suche ein-/ausblenden",
        translations: {
            fileTypeCsv: "CSV",
            fileTypeCsvNew: "CSV - nur neue",
            manageTranslations: "Übersetzungen verwalten"
        },
        useLargoNames: "Largo-Namen anzeigen",
        useSources: "Quellen verwenden",
        useTmcNames: "TMC-Namen anzeigen",
        userSettings: "Benutzerdefinierte Einstellungen"
    },
    package: {
        addPackage: "Verpackung hinzufügen",
        editPackage: "Verpackung bearbeiten",
        package: "Verpackung",
        packageName: "Name der Verpackung",
        packages: "Verpackungen",
        selectPackages: "Verpackung auswählen"
    },
    prices: {
        changeMainSettings: "Ändern der Haupteinstellungen",
        colorant: "Pigmentkonzentrat",
        defaultPriceColorantCost: "Pigmentkonzentrat - Kosten für das Unternehmen",
        defaultPriceColorantPurchase: "Pigmentkonzentrat - Einkaufspreis",
        defaultPriceColorantSell: "Pigmentkonzentrat - Verkaufspreis",
        defaultPriceColorantVat: "Pigmentkonzentrat MwSt.",
        defaultPricePackageSurcharge: "Verpackungszuschlag für das Produkt",
        defaultPriceProductCost: "Produkt - Kosten des Unternehmens",
        defaultPriceProductPurchase: "Produkt - Einkaufspreis",
        defaultPriceProductSell: "Produkt - Verkaufspreis",
        defaultPriceProductVAT: "Produkt MwSt.",
        defaultPrices: "Standard-Preise",
        discount: "Rabatt",
        margin: "Marge",
        markup: "Handelsspanne",
        maxValue: "Maximaler Wert",
        optionCompanyCost: "Kosten für das Unternehmen",
        optionCompanyCostCaption: "Bearbeitung der Unternehmenskosten aktivieren",
        optionCompanyCostNo: "Kosten für das Unternehmen verstecken",
        optionCompanyCostYes: "Kosten für das Unternehmen anzeigen",
        optionDefinition: "System der Preisbearbeitung",
        optionDefinitionBothPurchase: "Kauf- und Verkaufspreis anzeigen (Ersterwerb)",
        optionDefinitionBothSell: "Kauf- und Verkaufspreis anzeigen (Primärverkauf)",
        optionDefinitionPurchase: "Nur Einkaufspreis anzeigen",
        optionDefinitionSell: "Nur Verkaufspreis anzeigen",
        optionPriceCalculation: "Preiskalkulation",
        optionPriceCalculationDiscount: "Rabatt anzeigen",
        optionPriceCalculationDiscountMarginMarkup: "Marge, Aufschlag und Rabatt anzeigen",
        optionPriceCalculationMargin: "Marge anzeigen",
        optionPriceCalculationMarginDiscount: "Marge und Rabatt anzeigen",
        optionPriceCalculationMarginMarkup: "Marge und Aufschlag anzeigen",
        optionPriceCalculationMarkup: "Aufschlag anzeigen",
        optionPriceCalculationMarkupDiscount: "Aufschlag und Rabatt anzeigen",
        optionPriceCalculationNothing: "Deaktiviert",
        optionPriceGroups: "Preisgruppen",
        optionPriceGroupsCaption: "Preisgruppen verwenden",
        optionPriceGroupsNo: "Preisgruppen sind deaktiviert",
        optionPriceGroupsNumber: "Anzahl der Preisgruppen",
        optionPriceGroupsYes: "Die Verwendung von Preisgruppen ist aktiviert (%{count} groups)",
        packageSurcharge: "Aufpreis für das Gebinde",
        percentValueBase: "Basiswert",
        percentValueColorant: "Wert Pigmentkonzentrat",
        price: "Preis",
        priceGroupNumber: "Preisgruppe %{number}",
        priceGroups: "Preisgruppen",
        priceOption: "Preis-Option",
        priceSettings: "Preiseinstellungen",
        priceSourceCustomValue: "Benutzerdefinierter Artikelwert",
        priceSourceInheritedBase: "Vererbt von base",
        priceSourceInheritedDefault: "Vererbt von Standard",
        priceSourceInheritedProduct: "Vererbt vom Product",
        priceSourceInheritedSystemDefault: "Vererbt vom Systemstandard",
        priceSourceInheritedSystemItem: "Vererbt von System %{item}",
        prices: "Preise",
        product: "Produkt",
        propertyCompanyCostPrice: "Kosten für das Unternehmen",
        propertyPurchasePrice: "Anschaffungskosten",
        propertyPurchasePriceGroup: "Preisgruppen-Preis",
        propertySellPrice: "Verkaufspreis",
        propertySurcharge: "Aufpreis für das Gebinde",
        propertyVatPrice: "MwSt.",
        rangeFrom: "Bereich von",
        rangeTo: "Bereich bis",
        surcharge: "Aufpreis"
    },
    privileges: {
        description: {
            additionalTabType: "",
            autoCloseTab: "",
            automaticClientStart: "",
            automaticLogout: "",
            automaticLogoutTimeout: "",
            automaticPrint: "",
            barcodesEnabled: "",
            checkMinDosableAmount: "",
            checkOverfill: "",
            checkTotalCntAmounts: "",
            colorPreviewAsImage: "",
            colorPreviewResizable: "",
            colorTabCompatibleOnly: "",
            colorTabFormulaCode: "",
            colorTabFormulaName: "",
            colorTabNoteIcons: "",
            colorTabOrderBy: "",
            colorTabRowCount: "",
            colorTabWarningIcon: "",
            colorantPackageProc: "",
            colorantPanelOrderBy: "",
            competitiveColorSearch: "",
            componentActiveServer: "",
            componentAutotasksSettings: "",
            componentBarcodeScanModal: "",
            componentBarcodeTestModal: "",
            componentBugReport: "",
            componentCanisterBarcodes: "",
            componentCanisterBarcodesModal: "",
            componentCanisterFilling: "",
            componentCanistersTab: "",
            componentChangelog: "",
            componentCheaperColorsModal: "",
            componentClientIdentifier: "",
            componentClientServerConnection: "",
            componentColorTab: "",
            componentColorantConsumption: "",
            componentColorantPanel: "",
            componentColorantRefillHistory: "",
            componentCustomFormulaTab: "",
            componentCustomersTab: "",
            componentDatabaseList: "",
            componentDatabaseModal: "",
            componentDebugModal: "",
            componentDeleteImportedData: "",
            componentDispensingLogic: "",
            componentDispensingSettings: "",
            componentDriverSettings: "",
            componentExportSettings: "",
            componentExportStaticSql: "",
            componentFavoritesTab: "",
            componentFormulaMasterProduct: "",
            componentFormulaTab: "",
            componentHideItemsModal: "",
            componentHistoricalFormulas: "",
            componentHistoryOfOrdersTab: "",
            componentImportData: "",
            componentImportSettings: "",
            componentLicenseIdentification: "",
            componentLicenseStatus: "",
            componentLicenseSupplier: "",
            componentMachineDialog: "",
            componentMachineInfo: "",
            componentMachineRegistration: "",
            componentMachineSettings: "",
            componentMyOrdersTab: "",
            componentOrderNotificationModal: "",
            componentPosInfo: "",
            componentPricePanel: "",
            componentQuickTintModal: "",
            componentRccConnection: "",
            componentRefillingLogic: "",
            componentRegistration: "",
            componentRestoreSettings: "",
            componentServerPlugins: "",
            componentSmartPurgeRules: "",
            componentSpectroDialog: "",
            componentSpectroSettings: "",
            componentSpectroTab: "",
            componentStatisticsTab: "",
            componentTabBarPanel: "",
            componentTemplateHint: "",
            componentToDispenseTab: "",
            componentUpdateOptions: "",
            contentAutotasks: "",
            contentBugReport: "",
            contentCanisterSettings: "",
            contentDatabaseConnection: "",
            contentDispensingSettings: "",
            contentDriverSettings: "",
            contentFileTransfer: "",
            contentImportCustomData: "",
            contentItemColorantPricing: "",
            contentItemCurrencySettings: "",
            contentItemCustomData: "",
            contentItemDefaultPriceValues: "",
            contentItemFileTransferExport: "",
            contentItemFileTransferImport: "",
            contentItemLightAtStartup: "",
            contentItemLocalData: "",
            contentItemMachineCanOptions: "",
            contentItemMachineDispensingOptions: "",
            contentItemMachineOptions: "",
            contentItemMachineRefillOptions: "",
            contentItemMarginSettings: "",
            contentItemOneTimeImport: "",
            contentItemPackagePricing: "",
            contentItemPriceExport: "",
            contentItemPriceImport: "",
            contentItemPrintOptions: "",
            contentItemPrinterSettings: "",
            contentItemProductPricing: "",
            contentItemSingleActionsCanisters: "",
            contentItemSingleActionsCap: "",
            contentItemSingleActionsClean: "",
            contentItemSingleActionsComputer: "",
            contentItemSingleActionsGeneral: "",
            contentItemSingleActionsPlugger: "",
            contentItemSingleActionsPuncher: "",
            contentItemSingleActionsPurge: "",
            contentItemSingleActionsRecirculate: "",
            contentItemSingleActionsShelf: "",
            contentItemSingleActionsStir: "",
            contentItemTemplateSettings: "",
            contentItemTmc: "",
            contentItemTranslations: "",
            contentItemUserManagement: "",
            contentLicenseStatus: "",
            contentMachineInfo: "",
            contentMachineManagement: "",
            contentPosInfo: "",
            contentPrintSettings: "",
            contentPurgeStatistics: "",
            contentRccConnection: "",
            contentRefillHistory: "",
            contentRegistration: "",
            contentServerConnection: "",
            contentServerPlugins: "",
            contentSingleActions: "",
            contentSmartPurgeRules: "",
            contentSpectroSettings: "",
            contentUpdateOptions: "",
            currencyCode: "",
            customBannerPosition: "",
            customCanOption: "",
            customCanSettingsUnitGrav: "",
            customCanSettingsUnitVol: "",
            customCanSize: "",
            customCanUnits: "",
            customFormatCurrency: "",
            customFormatDate: "",
            customFormatNumber: "",
            customFormatTime: "",
            customFormulas: "",
            customersEnabled: "",
            deleteConfirmation: "",
            dispensingEnabled: "",
            dispensingNotes: "",
            dispensingWithErrorsEnabled: "",
            doNotShowAgainCloseRedlike: "",
            editColorantAllowance: "",
            enableCompanyCost: "",
            enableLocalPrices: "",
            enablePriceGroups: "",
            fileTransfer: "",
            fileTransferInTasks: "",
            fileTransferOutTasks: "",
            formulaAutomaticNaming: "",
            formulaCode: "",
            formulaCodePrimary: "",
            formulaColorPreview: "",
            formulaName: "",
            formulaNotes: "",
            formulaPreview: "",
            formulaStatusPanelEnabled: "",
            formulaUnits: "",
            fts: "",
            functionAddCanisterLevel: "",
            functionBackupMachineConfiguration: "",
            functionCloseRedlike: "",
            functionCreateTemplate: "",
            functionDeleteOrder: "",
            functionDisconnectServer: "",
            functionDispensing: "",
            functionEditTemplate: "",
            functionExportColorantConsumption: "",
            functionExportHistory: "",
            functionExportPrintLabelToImg: "",
            functionExportPrintLabelToPdf: "",
            functionExportProductFileToPdf: "",
            functionFillCanisterToMax: "",
            functionFindInSettings: "",
            functionLogOut: "",
            functionOrder: "",
            functionPrint: "",
            functionReactivateLicense: "",
            functionRebootPc: "",
            functionRefillByColorantPackage: "",
            functionResetFormulaTab: "",
            functionRestartRedlike: "",
            functionRestartServer: "",
            functionSaveToHistory: "",
            functionSetCanisterBarcode: "",
            functionSetCanisterDispenseLogic: "",
            functionSetCanisterLevel: "",
            functionSetCanisterUfiCode: "",
            functionSetMaxCanisterDispenseAmount: "",
            functionShutdownPc: "",
            functionSwitchToIntergamma: "",
            functionSwitchToLight: "",
            functionSwitchToRedlike: "",
            functionTriggerRefillDialog: "",
            generateColorPreview: "",
            help: "",
            helpLink: "",
            imagesSource: "",
            infoPanelResizable: "",
            infoPanelTab: "",
            infoTypes: "",
            infoTypesRounded: "",
            initTabType: "",
            keyboard: "",
            keyboardShortcuts: "",
            kiosk: "",
            language: "",
            lightEnabled: "",
            locale: "",
            lockPc: "",
            machineActionCapOpen: "",
            machineActionCleanAllChannels: "",
            machineActionCleanChannels: "",
            machineActionDisconnect: "",
            machineActionErrorReset: "",
            machineActionHardReset: "",
            machineActionInitialize: "",
            machineActionPlug: "",
            machineActionPunch: "",
            machineActionPurge: "",
            machineActionPurgeAll: "",
            machineActionRecirculateAllChannels: "",
            machineActionRecirculateChannels: "",
            machineActionReset: "",
            machineActionShelfDown: "",
            machineActionShelfUp: "",
            machineActionShowServiceWindow: "",
            machineActionSmartPurge: "",
            machineActionSmartPurgeAll: "",
            machineActionStirAllCanisters: "",
            machineActionStirCanisters: "",
            machineCapClose: "",
            machineSettingBarcodeRefill: "",
            machineSettingCheckCanBeforeDispensing: "",
            machineSettingDispenseInvalidColorants: "",
            machineSettingMachineUnit: "",
            machineSettingPlaceCanSenzor: "",
            machineSettingPlaceCanSenzorDelay: "",
            machineSettingRemoveCanSenzor: "",
            machineSettingRemoveCanSenzorDelay: "",
            machineSettingsCheckOverfill: "",
            machineSettingsEnableTmc: "",
            machineSettingsManualAddition: "",
            machineSettingsMinimalDispenseAmount: "",
            machineSettingsSkipPlaceCanDialog: "",
            machineSettingsSkipRemoveCanDialog: "",
            machineSettingsSplitFormula: "",
            machineSettingsValidateBarcodeCan: "",
            machineSettingsValidateColorantsDuringInit: "",
            marginMarkup: "",
            masterProductName: "",
            masterProductSiblingsTable: "",
            maxToleranceForCompetitiveColors: "",
            menu: "",
            menuBugReport: "",
            menuDatabase: "",
            menuDatabaseConnection: "",
            menuDevices: "",
            menuFileTransfer: "",
            menuImportCustomData: "",
            menuLicense: "",
            menuLicenseData: "",
            menuMachineAndDevices: "",
            menuMachineManagement: "",
            menuMachineSettings: "",
            menuMaintenance: "",
            menuPrintSettings: "",
            menuRccConnection: "",
            menuServerConnection: "",
            menuServerPlugins: "",
            menuServiceActions: "",
            menuSettings: "",
            menuSimplifiers: "",
            menuSpectroSettings: "",
            menuStatistics: "",
            menuUpdate: "",
            moreInfoInternal: "",
            navigationItemsOrdered: "",
            numPriceGroups: "",
            numberOfCompetitiveColors: "",
            numberOfTabs: "",
            orderModalEnabled: "",
            orderSearchItems: "",
            orderingEnabled: "",
            overwriteLastTab: "",
            overwriteLogo: "",
            priceDefinition: "",
            priceTypes: "",
            priceVisibilityTypes: "",
            pricingEnabled: "",
            printAfterDispensing: "",
            printAsImage: "",
            printBeforeDispensing: "",
            printEnabled: "",
            printOnRequest: "",
            printTasksAfterDispensing: "",
            printTasksBeforeDispensing: "",
            printer: "",
            printerTemplate: "",
            printerTemplateQuicktint: "",
            productCode: "",
            productFilesSource: "",
            productGroups: "",
            productImagePreview: "",
            quickTintCloseAfterDispensing: "",
            quickTintSortingType: "",
            rounding: "",
            screensaverTimeout: "",
            searchCheaperColors: "",
            searchPanelBase: "",
            searchPanelBaseSize: "",
            searchPanelBipp: "",
            searchPanelBippSize: "",
            searchPanelFandeckSize: "",
            searchPanelFormulaSize: "",
            searchPanelOrder: "",
            searchPanelProduct: "",
            searchPanelProductSize: "",
            searchPanelWithoutConfirmation: "",
            settingsActiveFormulaUnit: "",
            settingsBlendFormulaAndCode: "",
            settingsCleaningTime: "",
            settingsCustomHomeButton: "",
            settingsInfoItemInRgbPreview: "",
            settingsManualAdditionUnit: "",
            settingsRecirculationSpeed: "",
            settingsRecirculationTime: "",
            settingsStirringSpeed: "",
            settingsStirringTime: "",
            setupDatabase: "",
            setupEnvironment: "",
            setupEnvironmentFormula: "",
            setupEnvironmentFormulaData: "",
            setupEnvironmentImport: "",
            setupEnvironmentLayout: "",
            setupEnvironmentNavigation: "",
            setupEnvironmentUpdate: "",
            setupLanguage: "",
            setupLicense: "",
            setupLogin: "",
            setupMachine: "",
            setupMachineDriver: "",
            setupMachineSettings: "",
            setupPrinter: "",
            setupPrinterOptions: "",
            setupPrinterSettings: "",
            setupPrinterTemplateSettings: "",
            setupSpectro: "",
            shotsCoef: "",
            shotsName: "",
            softwareName: "",
            softwareNameLight: "",
            soundEnabled: "",
            spectroEnabled: "",
            style: "",
            submenuAutotasks: "",
            submenuCanisterSettings: "",
            submenuDispensingSettings: "",
            submenuDriverSettings: "",
            submenuLicenseStatus: "",
            submenuMachineInfo: "",
            submenuMachineManagement: "",
            submenuPosInfo: "",
            submenuPurgeStatistics: "",
            submenuRefillHistory: "",
            submenuRegistration: "",
            submenuSingleActions: "",
            submenuSmartPurgeRules: "",
            tableBase: "",
            tableBipp: "",
            tableCustomer: "",
            tableDate: "",
            tableFandeck: "",
            tableFormulaCode: "",
            tableFormulaName: "",
            tableMoreInfo: "",
            tableMoreInfoInternal: "",
            tablePackage: "",
            tableProduct: "",
            tableQuantity: "",
            tabsEnabled: "",
            ufiCodeBaseValidation: "",
            ufiCodeLogic: "",
            ufiCodeMinAmontGravPercent: "",
            ufiCodeMinGravPercent: "",
            useDiscount: "",
            useMargin: "",
            useMarkup: "",
            userIdentification: "",
            virtualKeyboardEnabled: "",
            visibleUnits: "",
            zoom: ""
        },
        privileges: "Einstellungen",
        showOnlyNonDefaultValues: "",
        statusAvailable: "",
        statusHidden: "",
        statusNotAvailable: ""
    },
    product: {
        addBaseInProduct: "Base im Produkt hinzufügen",
        addBaseInProductPackage: "Base der Produktverpackung hinzufügen",
        addProduct: "Produkt hinzufügen",
        baseInProduct: "Base im Produkt",
        baseInProductPackage: "Verpackung für Base im Produkt",
        baseInProductPackages: "Verpackungen für Base im Produkt",
        basesInProduct: "Basen im Produkt",
        checkColorantsFilling: "Füllung der Pigmentkonzentrate",
        colorantMaxAmount: "Maximale Pigmentkonzentrate",
        colorantMinAmount: "Mindestmenge an Pigmentkonzentraten",
        customFormula: "Eigene Rezeptur",
        customFormulaCaption: "Verfügbar für eigene Rezepturen",
        duplicateProduct: "Produkt duplizieren",
        editBaseInProduct: "Base des Produkts bearbeiten",
        editBaseInProductPackage: "Base im Produktpaket %{packageName} bearbeiten",
        editProduct: "Produkt bearbeiten",
        forcedFormulaBaseAmountType: "Art des Basisbetrags der erzwungenen Rezeptur",
        inheritProductSystem: "Vererbung der für das Produkt im Master angegebenen Basen",
        inheritProductZone: "Vererbung von Basen und Paketen, die für das Produkt im System angegeben sind",
        noProductAvailable: "Es ist kein Produkt verfügbar",
        packageBaseName: "Name der Basenverpackung",
        product: "Produkt",
        productFiles: "Produktdateien",
        productId: "Produkt-ID",
        productNameInSystem: "Produktname im System",
        productNameInZone: "Produktname in der Zone",
        productNotSelected: "Produkt ist nicht ausgewählt",
        productSources: "Produktquellen",
        products: "Produkte",
        selectFromBaseInProductPackages: "Wählen Sie aus der Base die Produktverpackung",
        selectFromBaseInProducts: "Wählen Sie aus der Base der Produkte",
        selectFromProducts: "Wählen Sie aus den Produkten",
        selectProduct: "Produkt auswählen",
        selectProducts: "Produkte auswählen",
        selectedBaseInProductPackages: "Ausgewählte Base in der Produktverpackung",
        selectedBaseInProducts: "Ausgewählte Base in Produkten",
        selectedProducts: "Ausgewählte Produkte"
    },
    productGroup: {
        addProductGroup: "Produktgruppe hinzufügen",
        editProductGroup: "Produktgruppe bearbeiten",
        productGroup: "Produktgruppe",
        productGroupForProduct: "Produktgruppen für Produkt %{name}",
        productGroups: "Produktgruppen",
        selectFromProductGroups: "Aus Gruppen auswählen",
        selectProductGroups: "Produktgruppen auswählen",
        selectedProductGroups: "Ausgewählte Gruppen"
    },
    propertyHint: {
        currencyCode:
            "Der Währungscode muss in der offiziellen Form mit drei Buchstaben angegeben werden, z. B. EUR. Verwendet in %{software} (prices, currency).",
        currencyName: "Name der Währung, z. B. Euro. Verwendet in %{software} (currency).",
        currencyRounding:
            "Rundung legt den kleinstmöglichen zahlbaren Wert der Währung fest, z. B. hat Euro die Rundung 0,01, weil der kleinstmögliche Wert 1 Cent ist. Verwendet in %{software} (prices, currency).",
        currencySymbol:
            "Offizielles Währungssymbol in %{software}, z. B. € für Euro. Verwendet in %{software} (prices, currency).",
        databaseCompany:
            "Zu welchem Unternehmen die Hauptdatenbank gehört. %{software} kann keine Datenbanken lesen, die für andere Firmen lizenziert wurden. Wird in %{software} nicht angezeigt.",
        databaseDefaultData: "",
        databaseImportData: "",
        databaseMoreInfo:
            "Zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        databaseName:
            "Name der Master-Datenbank, die für die Erstellung verschiedener Systeme (System- und Zoneneinstellungen) verwendet wird. Wird in %{software} nicht angezeigt.",
        databasePassword:
            "Bei der Arbeit mit einer Master-Datenbank muss immer das gleiche Passwort verwendet werden. Das Passwort kann nicht geändert werden. Wird in %{software} nicht angezeigt.",
        databaseUuid:
            "Die Master-UUID wird bei %{thisSoftware} automatisch generiert, um einzelne Master-Datenbanken zu unterscheiden. Wird in %{software} nicht angezeigt.",
        exportAction: "Verfügbare Aktionen auf der Grundlage des Exportergebnisses.",
        exportChangelog:
            "Changelog, das für die angegebene Zonendatenbank (System- und Zoneneinstellungen) geschrieben oder beim Erstellen der Exportaufgabe definiert wurde. Angezeigt in %{software} (databases).",
        exportDatabase:
            "Master, der auf der Grundlage der Systemauswahl für die Exportaufgabe ausgewählt wurde. Wird nicht in %{software} angezeigt.",
        exportDateCreated:
            "Uhrzeit und Datum des Beginns der Erstellung der Zonendatenbank. Angezeigt in %{software} (databases).",
        exportDateFinished:
            "Uhrzeit und Datum, an dem der Export der Zonendatenbank abgeschlossen wurde. Wird nicht in %{software} angezeigt.",
        exportStatus: "Status der Exportverarbeitung für alle vergangenen und laufenden Datenbankexporte.",
        exportSystemName: "System, zu dem die exportierte Zonendatenbank gehört. Wird nicht in %{software} angezeigt.",
        exportSystemUuid:
            "Die korrekte System-UUID wird auf der Grundlage des gewählten Systems generiert. Wird nicht in %{software} angezeigt.",
        exportType:
            "Der Exporttyp richtet sich nach den für die Datenbank erforderlichen weiteren Schritten. Die Archive umfassen main.db sowie local.db und db.ini.",
        exportVersion:
            "Version, die für die angegebene Zonendatenbank definiert ist (System- und Zoneneinstellungen) oder bei der Erstellung der Exportaufgabe definiert wurde. Angezeigt in %{software} (databases).",
        exportVersionName:
            "Die Benutzerkennung der Datenbankversion. Dieser Text wird angezeigt in %{software} (databases).",
        exportZoneName: "Zone, für die die Zonendatenbank erstellt wird. Angezeigt in %{software} (databases).",
        exportZoneUuid:
            "Die richtige Zonen-UUID wird auf der Grundlage der gewählten Zone generiert. Wird nicht in %{software} angezeigt.",
        imageFileType: "Bilder werden insgesamt in %{software} angezeigt. Icons sind nur in %{redlikeHelper} sichtbar.",
        imageHeight: "Höhe des Bildes in Pixeln.",
        imageName: "Name des Bildes, um es bei der Zuordnung zu einem Artikel leicht erkennen zu können.",
        imageSize: "Größe der Bilddatei.",
        imageUrl:
            "Url, unter der das aktuelle Bild online verfügbar ist. Falls zugänglich, wird diese Datei vor den direkt in der endgültigen Datenbank gespeicherten Daten verwendet. Verwendet in %{software} (formula).",
        imageWidth: "Breite des Bildes in Pixeln.",
        importAction: "",
        importDatabaseName: "Wird bei der Erstellung des Imports ausgewählt.",
        importDateCreated:
            "Uhrzeit und Datum des Beginns der Erstellung der Zonendatenbank. Angezeigt in %{software} (databases).",
        importDateFinished:
            "Uhrzeit und Datum, an dem der Export der Zonendatenbank abgeschlossen wurde. Wird nicht in %{software} angezeigt.",
        importStatus: "Status der Importverarbeitung für alle vergangenen und laufenden Datenbankimporte.",
        importSystemName: "Wird bei der Erstellung der Importaufgabe ausgewählt.",
        importSystemUuid: "Ausgewählt anhand der Zone.",
        importType: "",
        importZoneName: "Wird bei der Erstellung der Importaufgabe ausgewählt.",
        importZoneUuid: "Ausgewählt anhand der Zone.",
        masterBaseCode: "Mastercode der Base, kann für Systeme und Zonen geändert werden.",
        masterBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        masterBaseName: "Hauptname der Base, kann für Systeme und Zonen geändert werden.",
        masterBasePrintName:
            "Der Name der Base wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Systeme und Zonen geändert werden.",
        masterBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Basen in %{software} angezeigt werden. Ist sie nicht festgelegt, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Systeme und Zonen geändert werden.",
        masterBaseSearchCode:
            "Der Master-Suchcode der Basis kann für Systeme und Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar.",
        masterBaseSearchName:
            "Der Hauptsuchname der Basis kann für Systeme und Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        masterBaseTmcName: "In TMC angezeigter Name der Hauptbase, kann für Systeme und Zonen geändert werden.",
        masterColorBarcode: "",
        masterColorBlue: "",
        masterColorCode: "Mastercode der Farbe, kann für Systeme und Zonen geändert werden.",
        masterColorFandeckPage: "",
        masterColorFandeckPosition: "",
        masterColorGreen: "",
        masterColorMoreInfo: "",
        masterColorName: "Hauptname der Farbe, kann für Systeme und Zonen geändert werden.",
        masterColorPriority: "",
        masterColorRed: "",
        masterColorReflectanceData: "",
        masterColorRgbHex: "",
        masterColorSearchCode: "",
        masterColorSearchName: "",
        masterColorTmcName:
            "Der Name der Masterfarbe wird in TMC angezeigt und kann für Systeme und Zonen geändert werden.",
        masterColorWavelength: "",
        masterColorWithFormulaInfoCode: "Mastercode der Rezeptur, kann für Systeme und Zonen geändert werden.",
        masterColorWithFormulaInfoName: "Hauptname der Rezeptur, kann für Systeme und Zonen geändert werden.",
        masterColorWithFormulaInfoStatus:
            "Aktueller Status der Rezeptur. Mögliche Status sind akzeptiert, entfernt und historisch.",
        masterColorantBfs: "Deutscher Qualitätswert, kann für Systeme und Zonen geändert werden.",
        masterColorantBlue: "",
        masterColorantCode: "Mastercode des Pigmentkonzentrats, kann für Systeme und Zonen geändert werden.",
        masterColorantDensity:
            "Die Dichte (spezifisches Gewicht) des Pigmentkonzentrats wird zur Berechnung der gravimetrischen Einheiten verwendet. Kann für Systeme und Zonen geändert werden.",
        masterColorantFiles: "",
        masterColorantGreen: "",
        masterColorantMinDosage:
            "Die minimal zulässige Dosiermenge für das Pigmentkonzentrat. Kann für Systeme und Zonen geändert werden.",
        masterColorantMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        masterColorantName: "Hauptname des Pigmentkonzentrats, kann für Systeme und Zonen geändert werden.",
        masterColorantPrintName:
            "Der Name des Pigmentkonzentrats wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Systeme und Zonen geändert werden.",
        masterColorantPriority:
            "Priorität legt die Reihenfolge fest, in der die Pigmentkonzentrate in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Pigmentkonzentrate nach ihrer Position in der Formel geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Systeme und Zonen geändert werden.",
        masterColorantRed: "",
        masterColorantRgb:
            "Langer RGB-Wert, der automatisch aus den angegebenen R-, G- und B-Werten berechnet wird. Kann für Systeme und Zonen geändert werden.",
        masterColorantSearchCode: "",
        masterColorantSearchName: "",
        masterColorantTmcName:
            "Mastername des Pigmentkonzentrats der in TMC angezeigt wird, kann für Systeme und Zonen geändert werden.",
        masterColorantUfiCode: "",
        masterFandeckBarcode:
            "Barcode zum Suchen von Farbfächern in %{software} durch schnelles Barcode-Scannen, kann für Systeme und Zonen geändert werden.",
        masterFandeckCode: "Mastercode des Farbfächers, kann für Systeme und Zonen geändert werden.",
        masterFandeckMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        masterFandeckName: "Hauptname des Farbfächers, kann für Systeme und Zonen geändert werden.",
        masterFandeckPrintName:
            "Dieser Farbfächer-Name wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Systeme und Zonen geändert werden.",
        masterFandeckPriority:
            "Priorität legt die Reihenfolge fest, in der die Farbfächer in %{software} angezeigt werden. Falls nicht festgelegt, werden die Farbfächer alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Systeme und Zonen geändert werden.",
        masterFandeckSearchCode:
            "Der Master-Suchcode des Farbfächers kann für Systeme und Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar.",
        masterFandeckSearchName:
            "Der Hauptsuchname des Farbfächers kann für Systeme und Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        masterFandeckTmcName:
            "In TMC angezeigter Name des Master-Farbfächers, kann für Systeme und Zonen geändert werden.",
        masterFormulaNoteCode:
            "Code zur einfachen Erkennung der Rezepturanmerkung. Eine Rezeptur kann mehrere Anmerkungen enthalten.",
        masterFormulaNoteImage:
            "Bild, das in %{software} als Darstellung einer bestimmten Rezepturanmerkung angezeigt werden soll. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Systeme und Zonen geändert werden.",
        masterFormulaNoteText:
            "Vollständiger Text der Rezepturanmerkung, der bei der Auswahl einer Rezeptur in %{software} angezeigt wird. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseBfs: "Deutscher Qualitätswert. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseCode: "Mastercode der Produktbase, kann für Systeme und Zonen geändert werden.",
        masterProductBaseCoefficient:
            "Wenn nicht eingestellt, automatisch auf 1 gesetzt. Basenkoeffizient für die Rezeptmultiplikation. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseCustomFormula:
            "Definition ob für die Produktbase, benutzerdefinierte Rezepturen in %{software} erstellt werden können. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseDensity:
            "Die Dichte (spezifisches Gewicht) der Base kann für Systeme und Zonen geändert werden.",
        masterProductBaseFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für System und Zonen sowie für bestimmte Verpackungen geändert werden.",
        masterProductBaseFillProc:
            "Prozentualer Wert, der angibt, wie viel Basis von der Nennmenge tatsächlich eingefüllt wird. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseForcedFormulaBaseAmountType: "",
        masterProductBaseImage:
            "Allgemeine Bilder von Basen. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Systeme und Zonen sowie für bestimmte Basenverpackungen geändert werden.",
        masterProductBaseMaxColorantsAmount:
            "Die maximal zulässige Pigmentkonzentratmenge für die Produktbasis. Basierend auf den Einstellungen der %{software}  kann es sein, dass die Abgabe über dieser Menge verboten ist. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseMinColorantsAmount:
            "Die minimal zulässige Pigmentkonzentratmenge für die Produktbasis. Basierend auf den Einstellungen der %{software}  kann es sein, dass die Abgabe unter dieser Menge verboten ist. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        masterProductBaseName:
            "Master-Name der Produktbase. Masterbasen werden im Abschnitt Basen (Master) verwaltet und können für Systeme und Zonen geändert werden.",
        masterProductBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Produktgrundlagen in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Systeme und Zonen geändert werden.",
        masterProductBaseUfiCode: "",
        masterProductCode: "Mastercode des Produkts, kann für Systeme und Zonen geändert werden.",
        masterProductFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für System und Zonen sowie für bestimmte Verpackungen geändert werden.",
        masterProductImage:
            "Allgemeine Bilder von Produkten. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für System und Zonen sowie für bestimmte Verpackungen geändert werden.",
        masterProductMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        masterProductName: "Hauptname des Produkts, kann für Systeme und Zonen geändert werden.",
        masterProductPrintName:
            "Der Produktname wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Systeme und Zonen geändert werden.",
        masterProductPriority:
            "Priorität legt die Reihenfolge fest, in der die Produkte in  %{software} angezeigt werden. Falls nicht festgelegt, werden die Produkte alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Systeme und Zonen geändert werden.",
        masterProductSearchCode:
            "Master-Suchcode des Produkts, kann für Systeme und Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar.",
        masterProductSearchName:
            "Master-Suchname des Produkts, kann für Systeme und Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        masterProductTmcName:
            "Master-Produktname, der in TMC angezeigt wird, kann für Systeme und Zonen geändert werden.",
        packageGravimetric:
            "Angabe, ob die Verpackung gravimetrisch oder volumetrisch ist, basierend auf der verwendeten Einheit (Allgemeine Daten).",
        packageMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator, die nicht in %{software} angezeigt werden.",
        packageName:
            "Name der Verpackung, wie er in %{software} angezeigt wird. Gilt sowohl für Produkt- als auch für Pigmentkonzentratverpackungen. Angezeigt in %{software} (Produktpaket, Nachfüllpackung).",
        packageNominalAmount: "Nennwert der Verpackung unter Berücksichtigung der verwendeten Einheit.",
        packageTmcName: "Name der in TMC angezeigten Verpackung.",
        packageUnit:
            "Definiert die Maßeinheit der Verpackung. Einheiten werden im Abschnitt Einheiten (Allgemeine Daten) erstellt.",
        productFileAction: "Die Möglichkeit, eine hochgeladene Datei herunterzuladen.",
        productFileCategory:
            "Kategorie, zu der die Datei gehört. Wird verwendet, um eine geordnete Struktur zu gewährleisten. Wird in %{software} nicht angezeigt.",
        productFileMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator, die nicht in %{software} angezeigt werden.",
        productFileName:
            "Name der Datei, damit sie bei der Zuweisung zu einem Objekt und bei der Bearbeitung leicht zu erkennen ist. Angezeigt in %{software}",
        productFileSize: "Größe der Datei.",
        productFileType:
            "Dateityp, damit %{software} den Zweck der Datei erkennen kann. Wird in %{software} verwendet.",
        productFileUrl:
            "Url, unter der die aktuelle Produktdatei online verfügbar ist. Falls zugänglich, wird diese Datei vor den direkt in der endgültigen Datenbank gespeicherten Daten verwendet. Wird in Redlike (Rezeptur) verwendet.",
        staticSqlAction: "Die Möglichkeit, die hochgeladene SQL-Datei herunterzuladen.",
        staticSqlName:
            "Name der SQL-Datei, die bei der Zuweisung zu einer Zone leicht zu erkennen ist. Wird in %{software} nicht angezeigt.",
        staticSqlUrl: "Url, unter der die SQL-Datei online verfügbar ist. Wird in %{software} nicht angezeigt.",
        systemBaseCode: "Systemcode der Base, kann für Zonen geändert werden.",
        systemBaseMasterName: "Name der Hauptbase aus den Daten der Base (Master).",
        systemBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemBaseName: "Systemname der Base, kann in Zonen geändert werden.",
        systemBasePrintName:
            "Der Name der Base wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Zonen geändert werden.",
        systemBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Basen in %{software} angezeigt werden. Ist sie nicht festgelegt, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemBaseSearchCode:
            "Der System-Suchcode der Base kann für Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar.",
        systemBaseSearchName:
            "Der Systemsuchname der Base kann für Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        systemBaseTmcName: "Der Name der Systembase wird in TMC angezeigt und kann für Zonen geändert werden.",
        systemColorantBfs: "Deutscher Qualitätswert. Kann für Zonen geändert werden.",
        systemColorantCode: "Systemcode des Pigmentkonzentrats, kann für Zonen geändert werden.",
        systemColorantDensity:
            "Die Dichte (spezifisches Gewicht) des Pigmentkonzentrats wird zur Berechnung der gravimetrischen Einheiten verwendet. Kann für Zonen geändert werden.",
        systemColorantMasterName: "Master-Pigmentkonzentratname aus den Pigmentkonzentratdaten (Master).",
        systemColorantMinDosage:
            "Die minimal zulässige Dosiermenge für das Pigmentkonzentrat. Kann für Zonen geändert werden.",
        systemColorantMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemColorantName: "Systemname des Pigmentkonzentrats, kann für Zonen geändert werden.",
        systemColorantPackageBarcode:
            "Barcode der Pigmentkonzentratpackung zur Validierung des Nachfüllens. Kann für Zonen geändert werden.",
        systemColorantPackageFillProc:
            "Prozentualer Wert, der angibt, wie viel Pigmentkonzentrat von der Nennmenge der Pigmentkonzentratpackung tatsächlich nachgefüllt wird. Kann für Zonen geändert werden.",
        systemColorantPackageFillVolume:
            "Das Volumen wird auf der Grundlage des Füllungsgrads berechnet. Dargestellt in %{software} (refill).",
        systemColorantPackageGravimetric:
            "Angabe, ob die Maßeinheit des Pigmentkonzentratverpackung gravimetrisch oder volumetrisch ist, entnommen aus den definierten Verpackungen (Allgemeine Daten). Dargestellt in %{software} (refill).",
        systemColorantPackageImage:
            "Bild der Pigmentkonzentratverpackung, das in %{software} beim Nachfüllen angezeigt wird. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Zonen geändert werden.",
        systemColorantPackageMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemColorantPackageName:
            "Name des Pigmentkonzentratverpackung aus den definierten Verpackungen (allgemeine Daten). Angezeigt in %{software} (refill).",
        systemColorantPackageNominalAmount:
            "Nominale Menge der Pigmentkonzentratverpackung aus den definierten Verpackungen (Allgemeine Daten). Angezeigt in %{software} (refill).",
        systemColorantPackageUnit:
            "Einheit der Pigmentkonzentratverpackung, das den definierten Verpackungen ausgewählt wurde (Allgemeine Daten). Angezeigt in %{software} (refill).",
        systemColorantPriceCost:
            "Die Herstellungskosten des Pigmentkonzentrats können für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Nicht angezeigt in %{software}.",
        systemColorantPricePurchase:
            "Der POS-Einkaufspreis des Pigmentkonzentrats einschließlich der Herstellermarge kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt in %{software}.",
        systemColorantPricePurchasePriceGroup:
            "Preis des Pigmentkonzentrats, der für die Preisgruppenberechnung verwendet wird, kann für Zonen geändert werden. Der Preisgruppenbereich wird im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemColorantPriceSell:
            "Der POS-Verkaufspreis des Pigmentkonzentrats, einschließlich der POS-Marge, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemColorantPriceVat:
            "Mehrwertsteuer des Pigmentkonzentratpreises, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemColorantPrintName:
            "Der Pigmentkonzentratname wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Zonen geändert werden.",
        systemColorantPriority:
            "Priorität legt die Reihenfolge fest, in der die Pigmentkonzentrate in %{software} angezeigt werden.  Wenn sie nicht festgelegt ist, werden die Pigmentkonzentrate nach ihrer Position in der Rezeptur geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemColorantRgb:
            "Langer RGB-Wert, der automatisch aus den angegebenen R-, G- und B-Werten berechnet wird. Kann für Zonen geändert werden.",
        systemColorantTmcName:
            "Der Name des Systempigmentkonzentrats wird in TMC angezeigt und kann für Zonen geändert werden.",
        systemColorantUfiCode: "",
        systemFandeckBarcode:
            "Barcode zum Suchen von Farbtonfächern in %{software} durch schnelles Barcode-Scannen, kann für Zonen geändert werden.",
        systemFandeckCode: "Systemcode des Farbtonfächers, kann für Zonen geändert werden.",
        systemFandeckMasterName: "Master-Farbtonfächer aus den Farbfächer-Daten (Master).",
        systemFandeckMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemFandeckName: "Systemname des Farbtonfächers, kann für Zonen geändert werden.",
        systemFandeckPrintName:
            "Der Farbtonfächername wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Zonen geändert werden.",
        systemFandeckPriority:
            "Priorität legt die Reihenfolge fest, in der die Farbtonfächer in %{software} angezeigt werden. Falls nicht festgelegt, werden die Farbtonfächer alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemFandeckSearchCode:
            "Der System-Suchcode des Farbtonfächers kann für Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem er gesucht wurde, ansonsten ist nur der Code sichtbar.",
        systemFandeckSearchName:
            "Der System-Suchname des Farbtonfächers kann für Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem er gesucht wurde, ansonsten ist nur der Code sichtbar.",
        systemFandeckTmcName: "Der in TMC angezeigte Farbtonfächer-Name kann für Zonen geändert werden.",
        systemFormulaNoteCode: "Code der Stammrezeptur aus den Daten der Rezepturhinweisen (Master).",
        systemFormulaNoteImage:
            "Bild, das in %{software} für die Darstellung eines bestimmten Rezepturhinweises angezeigt werden soll. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Zonen geändert werden.",
        systemFormulaNoteMasterText: "Mastertext aus den Daten der Rezepturhinweisen (Master).",
        systemFormulaNoteText:
            "Vollständiger Text des Rezepturhinweises, der bei der Auswahl einer Rezeptur in %{software} angezeigt wird. Kann für Zonen geändert werden.",
        systemName:
            "Name des Systems, das verschiedene Zonen enthalten kann (System- und Zoneneinstellungen). Wird nicht in %{software}. angezeigt.",
        systemPackageName:
            "Allgemeine Verpackungen. Diese werden im Abschnitt Verpackungen (Allgemeine Daten) verwaltet und können für die Zone geändert werden.",
        systemPackageSurcharge:
            "Dieser Wert in der gewählten Währung definiert eine zusätzliche Manipulationsgebühr, die für den jeweiligen Verpackungstyp erhoben wird. Der Verpackungszuschlag kann für die Zone geändert werden.",
        systemPriceDefaultName:
            "Allgemeine Preisgestaltung für alle Artikel in allen verfügbaren Preistypen. Preise können gesperrt werden, wodurch die Möglichkeit entfällt, die Preise direkt in %{software} zu bearbeiten.",
        systemPriceDefaultValue:
            "Der Preis wird für 1 L in der gewählten Währung berechnet. Die Mehrwertsteuer wird in % angegeben und der Produktverpackungsaufschlag zählt für die Menge. Die Preise können für einzelne Artikel und für eine Zone geändert werden.",
        systemPriceGroupName:
            "Die Ordnungszahl der Preisgruppe. Je niedriger die Gruppe, desto niedrigere Preise sind in der Regel erforderlich. Preisgruppenpreise können für einzelne Pigmentkonzentrate und für Zonen geändert werden.",
        systemPriceGroupRangeFrom:
            "Der Mindestpreis für Pigmentkonzentrate, der erreicht werden muss, um in diese Preisgruppe eingestuft zu werden. Die Mindestgrenze kann je nach Zone geändert werden.",
        systemPriceGroupRangeTo:
            "Der Maximalpreis für Pigmentkonzentrate, der erreicht werden muss, um in diese Preisgruppe eingestuft zu werden. Die Maximalgrenze kann je nach Zone geändert werden.",
        systemPriceMarginName: "Der Name der Einheit, der für die Zone geändert werden kann.",
        systemPriceMarginPriority:
            "Priorität legt die Reihenfolge fest, in der die Spannen/Aufschläge/Rabatte in %{software} angezeigt werden. Wenn nicht festgelegt, werden sie chronologisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemPriceMarginType:
            "Die Handelsspanne und der Aufschlag erhöhen den Preis, während der Rabatt diesen reduziert.",
        systemPriceMarginValueBase:
            "Wie viel % des Produktgrundpreises soll aufgeschlagen/abgezogen werden. Der Wert kann für die Zone geändert werden.",
        systemPriceMarginValueColorant:
            "Wie viel % des Pigmentkonzentratpreises soll aufgeschlagen/abgezogen werden. Der Wert kann für die Zone geändert werden.",
        systemProductBaseBfs: "Deutscher Qualitätswert. Kann für Zonen geändert werden.",
        systemProductBaseCode: "Systemcode der Produktbase, kann für Zonen geändert werden.",
        systemProductBaseCoefficient:
            "Wenn nicht eingestellt, automatisch auf 1 gesetzt. Basiskoeffizient für die Rezepturmultiplikation. Kann für Zonen geändert werden.",
        systemProductBaseCustomFormula:
            "Definition ob für die Produktbase, benutzerdefinierte Rezepturen in %{software} erstellt werden können. Kann für Zonen geändert werden.",
        systemProductBaseDensity: "Die Dichte (spezifisches Gewicht) der Base kann für Zonen verändert werden.",
        systemProductBaseFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für Zonen und für bestimmte Verpackungen geändert werden.",
        systemProductBaseFillProc:
            "Prozentualer Wert, der angibt, wie viel Base vom Nennwert tatsächlich ausgefüllt wird. Kann für Zonen geändert werden.",
        systemProductBaseFormulaBaseAmountType: "",
        systemProductBaseImage:
            "Allgemeine Bilder von Basen. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Zonen und für bestimmte Basenverpackungen geändert werden.",
        systemProductBaseMaxColorantsAmount:
            "Die maximal zulässige Pigmentkonzentratmenge für die Produktbase. Basierend auf den Einstellungen der %{software} kann es sein, dass die Abgabe über dieser Menge verboten ist. Kann für Zonen geändert werden.",
        systemProductBaseMinColorantsAmount:
            "Die minimal zulässige Pigmentkonzentratmenge für die Produktbase. Basierend auf den Einstellungen der %{software} kann es sein, dass die Abgabe über dieser Menge verboten ist. Kann für Zonen geändert werden.",
        systemProductBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemProductBaseName:
            "Systemname der Produktbase. Systembasen werden im Abschnitt Basen (Master) verwaltet und können für Systeme und Zonen geändert werden.",
        systemProductBasePriceCost:
            "Die Herstellungskosten der Base, können für Zonen und für bestimmte Verpackungen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Nicht angezeigt in %{software}.",
        systemProductBasePricePurchase:
            "Der POS-Einkaufspreis der Base, einschließlich der Herstellermarge, kann für Zonen und für bestimmte Verpackungen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt in %{software}.",
        systemProductBasePriceSell:
            "Der POS-Verkaufspreis der Base, einschließlich der POS-Marge, kann für Zonen und für bestimmte Verpackungen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt in %{software}.",
        systemProductBasePriceVat:
            "Die Basen-Mehrwertsteuer kann für Zonen und für bestimmte Verpackungen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemProductBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Produktgrundlagen in %{software} angezeigt werden. Wenn diese nicht festgelegt ist, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemProductBaseUfiCode: "",
        systemProductCode: "Systemcode des Produkts, kann für Zonen geändert werden.",
        systemProductFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für Zonen sowie für bestimmte Basen und Verpackungen geändert werden.",
        systemProductGroupMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemProductGroupName:
            "Produktgruppen dienen zu organisatorischen Zwecken. Verschiedene Produkte können zu verschiedenen Produktgruppen gehören. Kann für Zonen geändert werden.",
        systemProductGroupPriority:
            "Priorität legt die Reihenfolge fest, in der die Produktgruppen in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Produktgruppen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemProductGroups:
            "Produktgruppen, zu denen das Produkt gehört. Produktgruppen werden im Abschnitt Produktgruppen (System) verwaltet und können für Zonen geändert werden.",
        systemProductImage:
            "Allgemeine Bilder von Produkten. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Zonen und für bestimmte Basenpakete geändert werden.",
        systemProductMasterName: "Master-Produktname aus den Produktdaten (Master).",
        systemProductMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemProductName: "Systemname des Produkts, kann in Zonen geändert werden.",
        systemProductPackageBarcode:
            "Barcode der Produktverpackung für die Basenvalidierung. Kann für Zonen geändert werden.",
        systemProductPackageCode: "Systemcode der Produktverpackung, kann für Zonen geändert werden.",
        systemProductPackageFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für Zonen geändert werden.",
        systemProductPackageFillProc:
            "Prozentualer Wert, der angibt, wie viel Basis aus der Nennmenge der Produktpackung tatsächlich nachgefüllt wird. Kann für Zonen geändert werden.",
        systemProductPackageFillVolume:
            "Das Volumen wird auf der Grundlage des Füllungsgrads berechnet. Dargestellt in %{software} (formula data).",
        systemProductPackageImage:
            "Bild der Produktverpackung, das in %{software} (package preview) angezeigt wird. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für Zonen geändert werden.",
        systemProductPackageMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        systemProductPackageName:
            "Name der Produktverpackung aus den definierten Verpackungen (Allgemeine Daten). Angezeigt in %{software} (packages).",
        systemProductPackageNominalAmount:
            "Nominale Größe der Produktverpackung aus den definierten Verpackungen (Allgemeine Daten). Dargestellt in %{software} (formula data).",
        systemProductPackagePriceCost:
            "Herstellungskosten für die Verpackung, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Nicht angezeigt in %{software}.",
        systemProductPackagePricePurchase:
            "Der POS-Einkaufspreis für die Verpackung, einschließlich der Herstellermarge, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt in %{software}.",
        systemProductPackagePriceSell:
            "Der POS-Verkaufspreis für die Verpackung, einschließlich der POS-Marge, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemProductPackagePriceSurcharge:
            "Dieser Wert in der gewählten Währung definiert eine zusätzliche Manipulationsgebühr, die für die gegebene Base der Verpackung berechnet wird. Der Verpackungszuschlag kann für die Zone geändert werden.",
        systemProductPackagePriceVat:
            "Mehrwertsteuer auf die Verpackung, kann für Zonen geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemProductPackagePrintName:
            "Der Name der Produktverpackung wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Zonen geändert werden.",
        systemProductPackageSearchName:
            "System-Suchname des Produktpakets, kann für Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        systemProductPriceCost:
            "Die Herstellungskosten des Produkts können für Zonen und für bestimmte Basen und Pakete geändert werden. Allgemeine Preise werden im Abschnitt Preise (System) verwaltet. Nicht angezeigt in %{software}.",
        systemProductPricePurchase:
            "Der POS-Einkaufspreis des Produkts, einschließlich der Herstellermarge, kann für Zonen und für bestimmte Basen und Pakete geändert werden. Die allgemeinen Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt in %{software}.",
        systemProductPriceSell:
            "Der POS-Verkaufspreis des Produkts, einschließlich der POS-Marge, kann für Zonen und für bestimmte Basen und Pakete geändert werden. Die allgemeinen Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemProductPriceVat:
            "Die für das Produkt festgelegte Mehrwertsteuer kann für Zonen und für bestimmte Basen und Pakete geändert werden. Die allgemeinen Preise werden im Abschnitt Preise (System) verwaltet. Angezeigt und verwendet in %{software}.",
        systemProductPrintName:
            "Der Produktname wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Kann für Zonen geändert werden.",
        systemProductPriority:
            "Priorität legt die Reihenfolge fest, in der die Produkte in %{software} angezeigt werden. Falls nicht festgelegt, werden die Produkte alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Kann für Zonen geändert werden.",
        systemProductSearchCode:
            "System-Suchcode des Produkts, kann für Zonen geändert werden. Der Suchcode ist nur in %{software} sichtbar, nachdem er gesucht wurde, ansonsten ist nur der Code sichtbar.",
        systemProductSearchName:
            "System-Suchname des Produkts, kann für Zonen geändert werden. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar.",
        systemProductTmcName: "Der in TMC angezeigte Systemproduktname kann für Zonen geändert werden.",
        systemUuid:
            "Die System-UUID wird von %{thisSoftware} automatisch generiert, um einzelne Systeme zu unterscheiden. Wird nicht in %{software} angezeigt.",
        unitAmountCoefficient: "",
        unitGravimetric: "Angabe, ob die Messeinheit gravimetrisch oder volumetrisch ist.",
        unitName: "Abkürzung des Namens der Messeinheit, wie sie in %{software} angezeigt wird.",
        unitPriority:
            "Priorität legt die Reihenfolge fest, in der die Einheiten in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Einheiten auf der Grundlage der %{software}-Standardeinstellungen geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Priorität der Einheiten. Wird in %{software} verwendet.",
        wavelengthBeginValue: "",
        wavelengthEndValue: "",
        wavelengthInterval: "",
        zoneBaseCode: "Zonencode der Base. Dies ist der endgültige Basencode. Wird nicht in %{software} angezeigt.",
        zoneBaseMasterName: "Name der Hauptbase aus den Daten der Base (Master).",
        zoneBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneBasePrintName:
            "Der Basenname wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Dies ist der endgültige Name des Basenndrucks. Verwendet und angezeigt in %{software} (Drucken).",
        zoneBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Basen in %{software} angezeigt werden. Ist sie nicht festgelegt, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Basispriorität. Wird in %{software} verwendet.",
        zoneBaseSearchCode:
            "Zonensuchcode der Base. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar. Dies ist der endgültige Basensuchcode. Verwendet und angezeigt in %{software} (Auswahl der Base).",
        zoneBaseSearchName:
            "Zonensuchname der Base. Der Suchname ist nur in %{software} sichtbar, nachdem er gesucht wurde, ansonsten ist nur der Name sichtbar. Dies ist der endgültige Suchname der Base. Verwendet und angezeigt in %{software} (Auswahl der Base).",
        zoneBaseSystemName: "Der Name der Systembase wird aus den Daten der Base (System) übernommen.",
        zoneBaseTmcName:
            "In TMC angezeigter Name der Zonenbase. Dies ist der endgültige TMC-Name. Wird in TMC angezeigt.",
        zoneChangelog:
            "Liste der Änderungen im Vergleich zur vorherigen Zonendatenbank. Angezeigt in %{software} (Datenbanken).",
        zoneColorantBfs:
            "BFS = Deutscher Qualitätswert. Dies ist der endgültige BFS. Dargestellt in %{software} (Formeldaten).",
        zoneColorantCode:
            "Zonencode des Pigments. Dies ist der endgültige Pigmentcode. Wird in %{software} nicht angezeigt.",
        zoneColorantDensity:
            "Die Dichte (spezifisches Gewicht) des Pigments wird zur Berechnung der gravimetrischen Einheiten verwendet. Dies ist die endgültige Dichte. Wird in %{software} verwendet.",
        zoneColorantMasterName: "Master-Pigmentname aus den Daten der Pigmentkonzentrate (Master).",
        zoneColorantMinDosage:
            "Die minimal zulässige Dosiermenge für das Pigment. Dies ist die endgültige Mindestdosiermenge des Pigments. Verwendet in %{software} (Dosierung).",
        zoneColorantMoreInfo:
            "Alle zusätzlichen Pigment-Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneColorantName:
            "Name des Pigments für die Zone. Dies ist der endgültige Name des Pigments. Dargestellt in %{software}.",
        zoneColorantPackageBarcode:
            "Barcode der Pigmentpackung für die Validierung von Nachfüllungen. Erforderlich in %{software} (Nachfüllpackung).",
        zoneColorantPackageFillProc:
            "Prozentualer Wert, der angibt, wie viel Pigment von der Nennmenge der Farbstoffpackung tatsächlich nachgefüllt wird. Dies ist die endgültige Füllungseinstellung. Verwendet in %{software} (Nachfüllpackung).",
        zoneColorantPackageFillVolume:
            "Auf der Grundlage des Füllungsgrads berechnetes Volumen. Dies ist das endgültige Füllvolumen. Dargestellt in %{software} (Nachfüllpackung).",
        zoneColorantPackageGravimetric:
            "Angabe, ob die Maßeinheit des Pigments gravimetrisch oder volumetrisch ist, entnommen aus den definierten Paketen (Allgemeine Daten). Dargestellt in %{software} (Nachfüllpackung).",
        zoneColorantPackageImage:
            "Bild der Pigmentverpackung. Dies ist das endgültige Bild des Pigmentkonzentratgebindes. Dargestellt in %{software} (Nachfüllpackung).",
        zoneColorantPackageMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneColorantPackageName:
            "Name der Pigmentverpackung aus den definierten Paketen (allgemeine Daten). Dargestellt in %{software} (Nachfüllung).",
        zoneColorantPackageNominalAmount:
            "Nominale Menge des Pigmentpakets aus den definierten Paketen (allgemeine Daten). Dargestellt in %{software} (Nachfüllung).",
        zoneColorantPackageUnit:
            "Einheit des Pigmentpakets, das den definierten Paketen entnommen wurde (allgemeine Daten). Dargestellt in %{software} (Nachfüllung).",
        zoneColorantPriceCost:
            "Herstellungskosten des Pigments. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet. Dies sind die endgültigen Kosten des Pigmentherstellers. Wird nicht in %{software} angezeigt.",
        zoneColorantPricePurchase:
            "POS-Einkaufspreis des Pigments einschließlich der Herstellermarge. Die allgemeinen Preise werden in der Rubrik Preise (Zone) verwaltet. Dies ist der endgültige Einkaufspreis für das Pigment. Angezeigt in %{software}.",
        zoneColorantPricePurchasePriceGroup:
            "Für die Preisgruppenberechnung verwendeter Pigmentpreis. Der Preisgruppenbereich wird im Abschnitt Preise (Zone) verwaltet. Dies ist der endgültige Preis für die Preisgruppe des Pigments. Angezeigt und verwendet in %{software}.",
        zoneColorantPriceSell:
            "POS-Verkaufspreis des Pigments einschließlich der POS-Marge. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet. Dies ist der endgültige Verkaufspreis des Pigments. Angezeigt und verwendet in %{software}.",
        zoneColorantPriceVat:
            "MwSt. des Pigments. Die allgemeinen Preise werden in der Rubrik Preise (Zone) verwaltet. Dies ist die endgültige Mehrwertsteuer des Pigments. Angezeigt und verwendet in %{software}.",
        zoneColorantPrintName:
            "Der Name des Pigments wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Dies ist der endgültige Druckname des Pigments. Verwendet und angezeigt in %{software} (Druck).",
        zoneColorantPriority:
            "Priorität legt die Reihenfolge fest, in der die Pigmente in %{software} angezeigt werden.  Wenn sie nicht festgelegt ist, werden die Pigmente nach ihrer Position in der Formel geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Priorität der Pigmente. Wird in %{software} verwendet.",
        zoneColorantRgb:
            "Langer RGB, das automatisch aus den angegebenen R-, G- und B-Werten berechnet wird. Dies ist das endgültige RGB. Angezeigt in %{software} (Formeldaten).",
        zoneColorantSystemName: "Name des Pigments im System aus den Pigmentkonzentratdaten (System).",
        zoneColorantTmcName:
            "In TMC angezeigter Name des Pigments in der Zone. Dies ist der endgültige TMC-Name der im TMC angezeigt wird.",
        zoneColorantUfiCode: "UfiCode des Zonenpigments",
        zoneCurrency:
            "Primärwährung der Zonendatenbank. Währungen werden im Abschnitt Währungen (Allgemeine Daten) erstellt. Angezeigt in %{software} (Preise, Währung).",
        zoneFandeckBarcode:
            "Barcode für die Suche nach dem Farbtonfächer in %{software} für schnelles Scannen von Barcodes. Dies ist der endgültige Farbtonfächer-Barcode. Verwendet in %{software}.",
        zoneFandeckCode:
            "Zonencode des Farbtonfächers. Dies ist der endgültige Farbtonfächer-Code. Wird in %{software} nicht angezeigt.",
        zoneFandeckMasterName:
            "Der Name des Master-Farbtonfächers wird aus den Farbtonfächer-Daten (Master) übernommen.",
        zoneFandeckMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneFandeckName:
            "Name des Faabtonfächers in der Zone. Dies ist der endgültige Name des Farbtonfächers. Wird in %{software} angezeigt.",
        zoneFandeckPrintName:
            "Der Name des Farbtonfächers wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Dies ist der endgültige Druckname des Farbtonfächers. Verwendet und angezeigt in %{software} (Drucken).",
        zoneFandeckPriority:
            "Priorität legt die Reihenfolge fest, in der die Farbtonfächer in %{software} angezeigt werden. Falls nicht festgelegt, werden die Farbtonfächer alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Farbtonfächer-Priorität. Wird in %{software} verwendet.",
        zoneFandeckSearchCode:
            "Zonensuchcode des Farbtonfächers. Der Suchcode ist nur in %{software} sichtbar, nachdem er gesucht wurde, ansonsten ist nur der Code sichtbar. Dies ist der endgültige Farbtonfächer-Suchcode. Wird in %{software} verwendet und angezeigt.",
        zoneFandeckSearchName:
            "Zonensuchname des Frbtonfächers. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar. Dies ist der endgültige Suchname des Farbtonfächers. Wird in %{software} verwendet und angezeigt.",
        zoneFandeckSystemName: "Der Name des System-Fandecks wird aus den Fandeck-Daten (System) übernommen.",
        zoneFandeckTmcName:
            "In TMC angezeigter Name des Zonenfächers. Dies ist der endgültige TMC-Name. Wird in TMC angezeigt.",
        zoneFormulaNoteCode: "Code der Masterformel aus den Daten der Rezepturnotizen (Master).",
        zoneFormulaNoteImage:
            "Bild, das in %{software} als Darstellung einer bestimmten Rezepturanmerkung angezeigt werden soll. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet. Dies ist das endgültige Bild der Rezepturanmerkung. Angezeigt in %{software}.",
        zoneFormulaNoteMasterText: "Stammtext aus den Daten der Rezepturnotizen (Master).",
        zoneFormulaNoteSystemText: "Systemtext aus den Daten der Rezepturotizen (System).",
        zoneFormulaNoteText:
            "Vollständiger Rezepturmerkungstext, der bei der Auswahl einer Rezeptur in %{software} zu schreiben ist. Dies ist der endgültige Rezepturhinweistext. Angezeigt in %{software}.",
        zoneIcon: "Das Icon wird in %{redlikeHelper} angezeigt.",
        zoneImage: "Bild wird in %{software} angezeigt, wenn die Datenbank gewechselt wird.",
        zoneName: "Name der Zone, zu der die Zonendatenbank gehört. Angezeigt in %{software} (Datenbanken).",
        zonePackageName:
            "Allgemeine Gebinde. Die Gebinde werden im Abschnitt Gebinde (Allgemeine Daten) verwaltet. Dies sind die endgültigen Gebinde, die in der Zone verwendet werden. Wird in %{software} angezeigt und verwendet.",
        zonePackageSurcharge:
            "Dieser Wert in der gewählten Währung definiert die zusätzliche Manipulationsgebühr, die für den jeweiligen Gebindetyp erhoben wird. Dies ist der endgültige Gebindezuschlag. Wird in %{software} angezeigt und verwendet.",
        zonePassword:
            "Passwort für %{software} zum Öffnen der Datenbank. Es muss für alle Datenbanken der gleichen Zone immer gleich und fehlerfrei sein. Wird in %{software} nicht angezeigt.",
        zonePriceDefaultName:
            "Allgemeine Preisgestaltung für alle Artikel in allen verfügbaren Preistypen. Preise können gesperrt werden, wodurch die Möglichkeit entfällt, die Preise direkt in %{software} zu bearbeiten.",
        zonePriceDefaultValue:
            "Der Preiswert wird für 1 L in der gewählten Währung berechnet. Die MwSt. wird in % angegeben und der Produktgebindeaufschlag zählt für die Menge. Die Preise können für einzelne Artikel geändert werden.",
        zonePriceGroupName:
            "Der Preiswert wird für 1 L in der gewählten Währung berechnet. Die MwSt. wird in % angegeben und der Produktgebindeaufschlag zählt für die Menge. Die Preise können für einzelne Artikel geändert werden.",
        zonePriceGroupRangeFrom:
            "Der Mindestpreis der Pigmente, der erreicht werden muss, um in diese Preisgruppe eingestuft zu werden. Dies ist die endgültige Mindestpreisgrenze. Angezeigt und verwendet in %{software}.",
        zonePriceGroupRangeTo:
            "Der maximale Preis der Pigmente, der erreicht werden kann, um in diese Preisgruppe eingestuft zu werden. Alles, was darüber liegt, gehört zur nächsten Preisgruppe. Dies ist die endgültige Höchstpreisgrenze. Angezeigt und verwendet in %{software}.",
        zonePriceMarginName:
            "Der Name des Artikels. Dies ist der endgültige Name. Angezeigt und verwendet in %{software}.",
        zonePriceMarginPriority:
            "Priorität legt die Reihenfolge fest, in der die Spannen/Aufschläge/Rabatte in %{software} angezeigt werden. Falls nicht festgelegt, werden sie chronologisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Priorität für Spannen/Aufschläge/Rabatte. Wird in %{software} verwendet.",
        zonePriceMarginType: "Die Handelsspanne und der Aufschlag erhöhen den Preis, während der Rabatt davon abzieht.",
        zonePriceMarginValueBase:
            "Wie viel % des Produktgrundpreises soll aufgeschlagen/abgezogen werden. Dies ist der endgültige Wert der Basisspanne des Aufschlags/des Rabatts. Wird in %{software} angezeigt und verwendet.",
        zonePriceMarginValueColorant:
            "Wie viel % des Produktgrundpreises soll aufgeschlagen/abgezogen werden. Dies ist der endgültige Wert für die Pigmentkonzentratmarge des Aufschlag/Abschlag. Wird in %{software} angezeigt und verwendet.",
        zoneProductBaseBfs:
            "Deutscher Qualitätswert. Dies ist die endgültige BFS-Kennzeichnung. Dargestellt in %{software} (Rezepturdaten).",
        zoneProductBaseCode: "Zonencode der Produktbase. Angezeigt in %{software} (Auswahl der Basis).",
        zoneProductBaseCoefficient:
            "Wenn nicht eingestellt, automatisch auf 1 gesetzt. Basenkoeffizient für die Formelmultiplikation. Dies ist der endgültige Basenkoeffizient. Wird in %{software} verwendet.",
        zoneProductBaseDensity:
            "Dichte (spezifisches Gewicht) der Base für die Zone. Dies ist die Dichte der Base des Endprodukts. Wird in %{software} verwendet.",
        zoneProductBaseFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können paketspezifisch geändert werden.",
        zoneProductBaseFillProc:
            "Prozentualer Wert, der angibt, wie viel Base von der Nennmenge tatsächlich eingefüllt wird. Dies ist die endgültige Basenfüllung. Wird in %{software} verwendet.",
        zoneProductBaseForcedFormulasBaseAmountType: "",
        zoneProductBaseImage:
            "Allgemeine Bilder von Basen. Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet und können für bestimmte Gebindegrößen geändert werden.",
        zoneProductBaseMaxColorantsAmount:
            "Die maximal zulässige Pigmentmenge für die Produktbase. Basierend auf den Einstellungen der %{software} kann eine Abgabe über dieser Menge verboten sein. Dies ist die Mindestmenge an Pigmenten, die in der %{software} (Dosierung).",
        zoneProductBaseMinColorantsAmount:
            "Die minimal zulässige Pigmentmenge für die Produktbase. Basierend auf den Einstellungen der %{software} kann eine Dosierung unter dieser Menge verboten sein. Dies ist die endgültige Mindestmenge an Pigmenten, die in der %{software} (Dosierung).",
        zoneProductBaseMinCustomFormula:
            "Definition, ob für die Produktbase, benutzerdefinierte Formeln in %{software} erstellt werden können. Dies ist die endgültige Definition der benutzerdefinierten Formel. Verwendet in %{software} (benutzerdefinierte Formel).",
        zoneProductBaseMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneProductBaseName:
            "Zonenname der Produktbase. Zonenbasen werden im Abschnitt Basen (Zone) verwaltet. Dies ist der endgültige Name der Produktbase. Angezeigt in %{software} (Auswahl der Base).",
        zoneProductBasePriceCost:
            "Herstellungskosten der Base. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Pakete geändert werden.",
        zoneProductBasePricePurchase:
            "POS-Einkaufspreis der Base einschließlich der Herstellermarge. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Gebindegrößen geändert werden.",
        zoneProductBasePriceSell:
            "POS-Verkaufspreis der Base einschließlich der POS-Marge. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Gebindegrößen geändert werden.",
        zoneProductBasePriceVat:
            "Die MwSt. wird für die Base festgelegt. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Gebindegrößen geändert werden.",
        zoneProductBasePriority:
            "Priorität legt die Reihenfolge fest, in der die Produktgrundlagen in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Basen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Priorität der Produktbase. Wird in %{software} verwendet.",
        zoneProductBaseUfiCode: "ProductBase-UFICode",
        zoneProductCode:
            "Zonencode des Produkts. Dies ist der endgültige Produktcode. Wird nicht in %{software} angezeigt.",
        zoneProductFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet und können für bestimmte Basen und Pakete geändert werden.",
        zoneProductGroupMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneProductGroupName: "Der Name der Systemproduktgruppe wird aus den Produktgruppendaten (System) übernommen.",
        zoneProductGroupPriority:
            "Priorität legt die Reihenfolge fest, in der die Produktgruppen in %{software} angezeigt werden. Wenn sie nicht festgelegt ist, werden die Produktgruppen alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Produktgruppenpriorität. Wird in %{software} verwendet.",
        zoneProductGroupSystemName:
            "Produktgruppen dienen zu organisatorischen Zwecken. Verschiedene Produkte können zu verschiedenen Produktgruppen gehören. Dies ist der endgültige Produktgruppenname. Angezeigt in %{software}.",
        zoneProductGroups:
            "Produktgruppen, zu denen das Produkt gehört. Die Produktgruppen werden im Abschnitt Produktgruppen (Zone) verwaltet. Dies ist die letzte Produktgruppe. Angezeigt in %{software}.",
        zoneProductImage:
            "Allgemeine Bilder von Produkten. Bilder werden im Abschnitt Bilder (Allgemeine Daten) und für bestimmte Basengebinde verwaltet.",
        zoneProductMasterName: "Master-Produktname aus den Produktdaten (Master).",
        zoneProductMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneProductName:
            "Name des Zonenprodukts. Dies ist der endgültige Produktname. Angezeigt in %{software} (Produktauswahl).",
        zoneProductPackageBarcode:
            "Barcode des Produktgebindes für die Basenvalidierung. Dies ist der endgültige Gebindebarcode. Verwendet in %{software}.",
        zoneProductPackageCode: "Systemcode des Produktpakets, kann für Zonen geändert werden.",
        zoneProductPackageFiles:
            "Allgemeine Produktdateien. Produktdateien werden im Abschnitt Produktdateien (Allgemeine Daten) verwaltet. Dies sind die endgültigen Dateien, die dem Produktpaket zugeordnet sind. Angezeigt in %{software}.",
        zoneProductPackageFillProc:
            "Prozentualer Wert, der angibt, wie viel Base aus der Nennmenge der Produktverpackung tatsächlich nachgefüllt wird. Dies ist die endgültige Füllung der Produktverpackung. Wird in %{software} verwendet.",
        zoneProductPackageFillVolume:
            "Das Volumen wird auf der Grundlage des Füllungsgrads berechnet. Dargestellt in %{software} (Formelangaben).",
        zoneProductPackageImage:
            "Bild des Produktgebindes, das in %{software} (Paketvorschau). Bilder werden im Abschnitt Bilder (Allgemeine Daten) verwaltet. Dies ist das endgültige Produktbild. Wird in %{software} verwendet.",
        zoneProductPackageMoreInfo:
            "Alle zusätzlichen Informationen für den Datenbankadministrator. Wird in %{software} nicht angezeigt.",
        zoneProductPackageName:
            "Name des Produktgebindes aus den definierten Gebinden (allgemeine Daten). Angezeigt in %{software} (Pakete).",
        zoneProductPackageNominalAmount:
            "Nominaler Betrag des Produktgebindes aus den definierten Gebinde (generische Daten). Dargestellt in %{software} (Formeldaten).",
        zoneProductPackagePriceCost:
            "Herstellungskosten für das Gebinde. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet. Dies sind die endgültigen Unternehmenskosten für das Produktbasispaket. Wird nicht in %{software} angezeigt.",
        zoneProductPackagePricePurchase:
            "POS-Einkaufspreis für das Gebinde einschließlich der Herstellermarge. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet. Dies ist der endgültige Einkaufspreis für das Produktbasispaket. Angezeigt und verwendet in %{software}.",
        zoneProductPackagePriceSell:
            "POS-Verkaufspreis des Gebindes einschließlich der POS-Marge. Allgemeine Preise werden im Abschnitt Preise (Zone) verwaltet. Dies ist der endgültige Verkaufspreis für das Produktbasispaket. Angezeigt und verwendet in %{software}.",
        zoneProductPackagePriceSurcharge:
            "Dieser Wert in der gewählten Währung legt eine zusätzliche Manipulationsgebühr fest, die für die gegebene Base im Produktpaket berechnet wird. Dies ist der endgültige Gebindezuschlag. Verwendet und angezeigt in %{software} (Preise).",
        zoneProductPackagePriceVat:
            "MwSt. des Gebinde. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet. Dies ist die endgültige Mehrwertsteuer für das Gebinde der Base des Produkts. Angezeigt und verwendet in %{software}.",
        zoneProductPackagePrintName:
            "Der Name der Base in der Produktverpackung wird auf dem Etikett anstelle des in der Spalte Name der Basenverpackung definierten Namens ausgedruckt. Dies ist der endgültige Base-im-Produkt-Paketname. Verwendet in %{software} (drucken).",
        zoneProductPackageSearchName:
            "Zonensuchname der Base im Produktpaket. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar. Dies ist die endgültige Base im Produktpaket. Verwendet und angezeigt in %{software} (Auswahl der Basis).",
        zoneProductPriceCost:
            "Herstellungskosten des Produkts und können für bestimmte Basen und Verpackungen geändert werden. Allgemeine Preise werden im Abschnitt Preise (Zone) verwaltet.",
        zoneProductPricePurchase:
            "POS-Einkaufspreis des Produkts einschließlich der Herstellermarge und kann für bestimmte Basen und Pakete geändert werden. Allgemeine Preise werden im Abschnitt Preise (Zone) verwaltet.",
        zoneProductPriceSell:
            "POS-Verkaufspreis des Produkts einschließlich der POS-Marge. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Grundlagen und Pakete geändert werden.",
        zoneProductPriceVat:
            "MwSt. des Produkts. Die allgemeinen Preise werden im Abschnitt Preise (Zone) verwaltet und können für bestimmte Grundlagen und Pakete geändert werden.",
        zoneProductPrintName:
            "Der Produktname wird auf dem Etikett anstelle des in der Spalte Name definierten Namens ausgedruckt. Dies ist der endgültige Druckname des Produkts. Verwendet und angezeigt in %{software} (Druck).",
        zoneProductPriority:
            "Priorität legt die Reihenfolge fest, in der die Produkte in %{software} angezeigt werden. Falls nicht festgelegt, werden die Produkte alphabetisch geordnet. Je höher die Zahl, desto höher die Priorität. Dies ist die endgültige Produktpriorität. Wird in %{software} verwendet.",
        zoneProductSearchCode:
            "Zonensuchcode des Produkts. Der Suchcode ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Code sichtbar. Dies ist der endgültige Produkt-Suchcode. Wird in %{software} verwendet und angezeigt.",
        zoneProductSearchName:
            "Zonensuchname des Produkts. Der Suchname ist nur in %{software} sichtbar, nachdem danach gesucht wurde, ansonsten ist nur der Name sichtbar. Dies ist der endgültige Suchname des Produkts. Wird in %{software} verwendet und angezeigt.",
        zoneProductSystemName: "Produktname des Systems aus den Produktdaten (System).",
        zoneProductTmcName:
            "In TMC angezeigter Name des Zonenprodukts. Dies ist der endgültige TMC-Name. Wird in TMC angezeigt.",
        zoneStaticSql:
            "Bietet zusätzliche Einstellungen für die Zonendatenbank und wird im Abschnitt Static SQL (Allgemeine Daten) verwaltet. Verwendet in %{software}.",
        zoneUuid:
            "Zonen-UUID (Identifikator), die auf %{redlikeWeb} generiert wird. Sie muss immer gleich und fehlerfrei sein, damit Online-Aktualisierungen erfolgreich durchgeführt werden können. Vermeiden Sie Leerzeichen. Wird in %{software} nicht angezeigt.",
        zoneVersion:
            "Verwenden Sie vier durch Punkte getrennte Zahlen, z. B. 1.0.0.0, und erhöhen Sie die Versionsnummer schrittweise. Angezeigt in %{software} (Datenbanken).",
        zoneVersionName:
            "Bietet zusätzliche Sortiermöglichkeiten, z. B. für das Datum der Datenbankerstellung, für offizielle Datenbanken/Testdatenbanken usw. Angezeigt in %{software} (Datenbanken).",
        zonemBaseName: "Name der Zonenbase. Dies ist der endgültige Basenname. Angezeigt in %{software} (Basisauswahl)."
    },
    server: {
        connect: "Verbinden",
        noServerConnection: "Keine Serververbindung, bitte versuchen Sie, sich erneut zu verbinden.",
        notAvailable: "Nicht verfügbar",
        reconnect: "Neu verbinden",
        serverVersion: "Server-Version",
        sqlcipherVersion: "SQLCipher-Version",
        sqliteVersion: "SQLite-Version"
    },
    serverScripts: {
        lastResult: "Letztes Ergebnis",
        runScript: "Skript ausführen",
        script: "Script",
        scriptRunning: "Skript wird ausgeführt, bitte warten..."
    },
    spectro: {
        addSpectroType: "Messart hinzufügen",
        editSpectroType: "Messart bearbeiten",
        measurementTypes: "Arten der Messung",
        noGraphPreview: "Keine Grafik in der Vorschau",
        noMeasurementType: "Es gibt keine Arten der Messung",
        spectroType: "Spektro Typ",
        wavelengthSettings: "Einstellungen der Wellenlänge"
    },
    system: {
        createNewSystem: "Neues System erstellen",
        duplicateSystem: "System duplizieren",
        editSystem: "System bearbeiten",
        saveInSystems: "Speichern in Systemen",
        selectSystem: "System auswählen",
        system: "System",
        systemName: "Name des Systems",
        systemOverview: "System-Übersicht",
        systems: "Systeme",
        uuid: "System UUID",
        valueInSystem: "Wert in Systemdaten"
    },
    unit: {
        addUnit: "Einheit hinzufügen",
        editUnit: "Einheit bearbeiten",
        unit: "Einheit",
        units: "Einheiten"
    },
    user: {
        addUser: "Benutzer hinzufügen",
        addUserGroup: "Benutzergruppe hinzufügen",
        autoLogin: "Automatisch anmelden",
        autologinCaption: "Meldet Benutzer nach dem Start von Redlike automatisch an",
        availableUsersAndGroups: "Verfügbare Benutzer und ihre Berechtigungsgruppen",
        duplicateUserGroup: "Benutzergruppe duplizieren",
        editUser: "Benutzer bearbeiten",
        editUserGroup: "Benutzergruppe bearbeiten",
        selectFromUserGroups: "Aus Benutzergruppen auswählen",
        selectedUserGroups: "Ausgewählte Nutzergruppen",
        user: "Benutzer",
        userActionDetail: "Detail der Aktion des Benutzers",
        userActionsHistory: "Historie der Aktionen des Benutzers",
        userGroup: "Benutzergruppe",
        userGroupAdmins: "Admins",
        userGroupGuests: "Guests",
        userGroupManagers: "Managers",
        userGroupPrivileges: {
            dataDatabase: "Master-Einstellungen",
            dataExport: "Export",
            dataImport: "Import",
            dataSystemZone: "System & Zone Einstellungen",
            genericCurrencies: "Generische - Währungen",
            genericImages: "Generische - Bilder",
            genericPackages: "Generische - Gebindegrößen",
            genericProductFiles: "Generische - Produktdateien",
            genericStaticSql: "Generische - Static SQL",
            genericUnits: "Generische - Einheiten",
            headerCompany: "Unternehmensbereich",
            headerMaster: "Master-Daten",
            headerSystem: "System-Daten",
            headerUser: "Benutzerbereich",
            headerZone: "Daten zur Zone",
            taskDelete: "Import / Export - Aufgabe löschen",
            taskHide: "Import/Export - Aufgabe ausblenden",
            uuidMasterSystem: "Master / System UUID hinzufügen / bearbeiten Dialog",
            uuidZone: "Zone UUID"
        },
        userGroupSuperadmins: "Superadministratoren",
        userGroupUsers: "Benutzer der Benutzergruppen",
        userGroups: "Benutzergruppen",
        userSettings: "Benutzereinstellungen",
        username: "Benutzername",
        users: "Benutzer"
    },
    validation: {
        bfsColorant: "BFS des Pigments muss eine Zahl im zwischen 1-3 sein",
        bfsProduct: "BFS des Produkts muss ein Buchstabe in Großbuchstaben sein",
        bookmarksValidation: "Erforderliche Elemente fehlen, bitte überprüfen Sie alle Lesezeichen",
        computerId: "Invalid Computer ID format",
        duplicateNamesProduct: "Jedes Produkt muss einen eindeutigen Namen haben",
        email: "Ungültiges E-Mail-Format",
        invalidInput: "Ungültige Eingabe",
        matchingPassword: "Das eingegebene Passwort stimmt mit dem Passwort auf %{redlikeWebAbbreviation} überein",
        maxFill: "Der Maximalbetrag muss größer sein als der Minimalbetrag",
        noMetaData: "Keine Metadaten zu %{redlikeWebAbbreviation}",
        noPassword: "Kein Passwort für %{redlikeWebAbbreviation}",
        noUuid: "Nicht die gleiche UUID wie auf %{redlikeWebAbbreviation}",
        notMatchingPassword:
            "Das eingegebene Passwort stimmt nicht mit dem Passwort auf %{redlikeWebAbbreviation} überein",
        numberValue: "Der eingefügte Wert muss eine Zahl sein",
        passwordConfirmation: "Die Passwörter müssen identisch sein",
        passwordValue: "Das Passwort muss mindestens 8 Zeichen lang sein",
        percentageValue: "Der eingefügte Wert muss eine Zahl zwischen 0-100 sein.",
        phone: "Ungültiges Telefonformat",
        requiredField: "Dieses Feld muss ausgefüllt werden",
        requiredFieldName: "Das Feld %{fieldName} muss ausgefüllt werden",
        rgbValue: "Der eingefügte Wert muss eine Zahl zwischen 0-255 sein.",
        tableValidation: "%{item} muss für alle ausgewählten Exportaufgaben ausgefüllt werden",
        ufiCode: "Ungültiges Format UFI-Code",
        username: "This username cannot be used",
        uuid: "Ungültiges UUID-Format",
        validateR2W: "Bestätigen Sie %{redlikeWebAbbreviation}",
        versionNameValue: "Der Versionsname muss mindestens 3 Zeichen lang sein.",
        versionValue: "Die Version muss aus bis zu 4 durch Punkt getrennten Zahlen bestehen",
        zoneInvalidUuid:
            "Die angegebene UUID der ausgewählten Zonendatenbank stimmt nicht mit der UUID der Zonendatenbanken im  %{redlikeWeb} überein",
        zoneName:
            "Der Name der Zone darf nur aus Buchstaben, Zahlen oder Unterstrichen bestehen (mindestens 3 Zeichen lang)"
    },
    zone: {
        addZone: "Zone hinzufügen",
        availableZoneDatabase: "Verfügbare Zonendatenbank",
        createNewZone: "Neue Zone erstellen",
        duplicateZone: "Zone duplizieren",
        editChangelog: "Changelog bearbeiten",
        editVersion: "Version bearbeiten",
        editZone: "Zone bearbeiten",
        importDataFromRedlikeWeb: "Daten von %{redlikeWeb} %{redlikeWebWebsite} importieren",
        saveInZones: "In Zonen speichern",
        selectZone: "Zone auswählen",
        selectZones: "Zonen auswählen",
        selectZonesToBeDuplicate: "Zu duplizierende Zonen auswählen",
        selectedZones: "Ausgewählte Zonen",
        showAvailableZones: "Verfügbare Zonen anzeigen",
        uuid: "Zone UUID",
        zone: "Zone",
        zoneName: "Zone-Name",
        zones: "Zonen"
    }
};
