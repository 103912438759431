import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { File, mapFile } from "../../types/file";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";
import { mapStaticSqlResult } from "../../types/staticSqlResult";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_FILES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fileList: Array<File> = [];
            let file: File | null;

            for (const item of response.data.data) {
                file = mapFile(item);

                if (file) {
                    fileList.push(file);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.FILE, fileList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_PRODUCT_SHEETS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fileList: Array<File> = [];
            let file: File | null;

            for (const item of response.data.data) {
                file = mapFile(item);

                if (file) {
                    fileList.push(file);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.PRODUCT_SHEET, fileList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_STATIC_SQL_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fileList: Array<File> = [];
            let file: File | null;

            for (const item of response.data.data) {
                file = mapFile(item);

                if (file) {
                    fileList.push(file);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.STATIC_SQL, fileList)];
        }
        case methods.METHOD_GET_ALL_STATIC_SQL: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fileList: Array<File> = [];
            let file: File | null;

            for (const item of response.data.data) {
                file = mapFile(item);

                if (file) {
                    fileList.push(file);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.STATIC_SQL, fileList, response.data.count)];
        }
        case methods.METHOD_ADD_PRODUCT_SHEET: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.PRODUCT_SHEET),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FILES_ADD)
            ];
        }
        case methods.METHOD_ADD_STATIC_SQL: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.STATIC_SQL),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FILES_ADD)
            ];
        }
        case methods.METHOD_EDIT_PRODUCT_SHEET: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.length > 0) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.PRODUCT_SHEET),
                    universalObjectActions.setActiveItems(objectTypes.PRODUCT_SHEET, []),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_FILES_EDIT)
                );
            } else {
                const productSheet = mapFile(response.data);

                if (productSheet) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.PRODUCT_SHEET, [productSheet]));
                }
            }
            return requests;
        }
        case methods.METHOD_EDIT_STATIC_SQL: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.length > 0) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.STATIC_SQL),
                    universalObjectActions.setActiveItems(objectTypes.STATIC_SQL, []),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_FILES_EDIT)
                );
            } else {
                const staticSql = mapFile(response.data);

                if (staticSql) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.STATIC_SQL, [staticSql]));
                }
            }
            return requests;
        }
        case methods.METHOD_DELETE_PRODUCT_SHEET: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.PRODUCT_SHEET),
                universalObjectActions.setActiveItems(objectTypes.PRODUCT_SHEET, [])
            ];
        }
        case methods.METHOD_DELETE_STATIC_SQL: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.STATIC_SQL),
                universalObjectActions.setActiveItems(objectTypes.STATIC_SQL, [])
            ];
        }
        case methods.METHOD_DELETE_UPLOADED_FILE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_RUN_STATIC_SQL: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const result = mapStaticSqlResult(response.data);

            return [universalObjectActions.saveItems(objectTypes.STATIC_SQL_RESULT, result ? [result] : [])];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
