import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Colorant } from "../../../types/colorant";
import { ColorantPackage } from "../../../types/colorantPackage";
import { Currency } from "../../../types/currency";
import { MenuItem } from "../../../types/menu";
import { PriceOption } from "../../../types/priceOption";
import { ServerRequest } from "../../../types/serverRequest";
import { Unit } from "../../../types/unit";
import { ZoneColorantScene } from "../../../components/scenes/zone/ZoneColorantScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    priceDataPrivileges: MenuItem | null;
    currency: Currency | null;
    unitList: Array<Unit>;
    colorantTableConstant: string;
    colorantList: Array<Colorant>;
    colorantActiveList: Array<Colorant>;
    colorantLoading: boolean;
    colorantButtonLoading: boolean;
    colorantAvailableCount: number | null;
    colorantColumnOrder: Array<propertyConstants.Property>;
    colorantColumnVisibility: Record<propertyConstants.Property, boolean>;
    colorantColumnWidth: Record<propertyConstants.Property, number>;
    colorantSceneTableSize: Record<string, any> | null;
    colorantOffset: number;
    colorantPage: number;
    colorantRowCount: number;
    colorantRowCountCustom: boolean;
    colorantSearch: string | null;
    colorantSearchParams: Record<propertyConstants.Property, string>;
    colorantShowFilterRow: boolean;
    colorantShowGlobalSearch: boolean;
    colorantSortingCriterion: propertyConstants.Property | null;
    colorantSortingAsc: boolean;
    colorantTotalCount: number | null;
    colorantPackageTableConstant: string;
    colorantPackageList: Array<ColorantPackage>;
    colorantPackageActiveList: Array<ColorantPackage>;
    colorantPackageLoading: boolean;
    colorantPackageButtonLoading: boolean;
    colorantPackageAvailableCount: number | null;
    colorantPackageColumnOrder: Array<propertyConstants.Property>;
    colorantPackageColumnVisibility: Record<propertyConstants.Property, boolean>;
    colorantPackageColumnWidth: Record<propertyConstants.Property, number>;
    colorantPackageOffset: number;
    colorantPackagePage: number;
    colorantPackageRowCount: number;
    colorantPackageRowCountCustom: boolean;
    colorantPackageSearch: string | null;
    colorantPackageSearchParams: Record<propertyConstants.Property, string>;
    colorantPackageShowFilterRow: boolean;
    colorantPackageShowGlobalSearch: boolean;
    colorantPackageSortingAsc: boolean;
    colorantPackageSortingCriterion: propertyConstants.Property | null;
    colorantPackageTotalCount: number | null;
    optionPriceDefinition: PriceOption | null;
    optionPriceEnableCompanyCost: PriceOption | null;
    optionPriceEnablePriceGroups: PriceOption | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_COLORANTS
    ),
    priceDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_PRICES
    ),
    currency: state.currency.zoneCurrency,
    unitList: state.unit.allList,
    colorantTableConstant: menuConstants.TABLE_ZONE_COLORANTS,
    colorantList: state.colorant.zoneList,
    colorantActiveList: state.colorant.zoneActiveList,
    colorantLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_COLORANTS ||
            request.method === methods.METHOD_GET_ZONE_PRICE_OPTIONS ||
            request.method === methods.METHOD_DELETE_ZONE_COLORANT
    ),
    colorantButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_COLORANT_BY_ID ||
            request.method === methods.METHOD_EDIT_ZONE_COLORANT
    ),
    colorantAvailableCount: state.colorant.zoneCountAvailable,
    colorantColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantOffset: state.colorant.zoneOffset,
    colorantPage: state.colorant.zonePage,
    colorantRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_ZONE_COLORANTS
    ),
    colorantSearch: state.colorant.zoneSearch,
    colorantSearchParams: state.colorant.zoneSearchParams,
    colorantShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_COLORANTS
    ),
    colorantSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORANTS
        )
    ),
    colorantSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORANTS
        )
    ),
    colorantTotalCount: state.colorant.zoneCountTotal,
    colorantPackageTableConstant: menuConstants.TABLE_ZONE_COLORANT_PACKAGES,
    colorantPackageList: state.colorantPackage.zoneList,
    colorantPackageActiveList: state.colorantPackage.zoneActiveList,
    colorantPackageLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT ||
            request.method === methods.METHOD_DELETE_ZONE_COLORANT_PACKAGE
    ),
    colorantPackageButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID ||
            request.method === methods.METHOD_EDIT_ZONE_COLORANT_PACKAGE
    ),
    colorantPackageAvailableCount: state.colorantPackage.zoneCountAvailable,
    colorantPackageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageOffset: state.colorantPackage.zoneOffset,
    colorantPackagePage: state.colorantPackage.zonePage,
    colorantPackageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageSearch: state.colorantPackage.zoneSearch,
    colorantPackageSearchParams: state.colorantPackage.zoneSearchParams,
    colorantPackageShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
    ),
    colorantPackageSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORANT_PACKAGES
        )
    ),
    colorantPackageSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORANT_PACKAGES
        )
    ),
    colorantPackageTotalCount: state.colorantPackage.zoneCountTotal,
    optionPriceDefinition: generalHelper.getObjectByKey(
        state.price.zoneOptionList,
        priceConstants.PRICE_OPTION_PRICE_DEFINITION
    ),
    optionPriceEnableCompanyCost: generalHelper.getObjectByKey(
        state.price.zoneOptionList,
        priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST
    ),
    optionPriceEnablePriceGroups: generalHelper.getObjectByKey(
        state.price.zoneOptionList,
        priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS
    ),
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_ZONE_COLORANTS
    )
});

export const ZoneColorantSceneContainer = connect(mapStateToProps)(ZoneColorantScene);
