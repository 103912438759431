import * as defaultConstants from "../constants/default";

import { DispatchType, PropsType } from "../containers/NotificationPanel";
import React, { Component } from "react";
import FlashMessage from "./FlashMessage";

import { ReactSVG } from "react-svg";

import imgClose from "../resources/img/icons/close.svg";

import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    showMore: boolean;
};

export class NotificationPanel extends Component<Props, State> {
    state: State = {
        showMore: false
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            JSON.stringify(this.props.flashMessages) !== JSON.stringify(prevProps.flashMessages) &&
            this.props.flashMessages.length === 0
        ) {
            this.setState({ showMore: false });
        }
    }

    handleShowMoreButtonClick = (): void => {
        this.setState({ showMore: !this.state.showMore });
    };

    handleDeleteAllButtonClick = (): void => {
        this.setState({ showMore: false });

        this.props.deleteAllFlashMessages();
    };

    getPanelClassName = (): string => {
        let className = "dropdown-panel notification-panel";

        if (this.props.flashMessages.length === 0) {
            className += " small-notification-panel";
        }

        return className;
    };

    getAllNotifications = (): Array<JSX.Element> => {
        const { flashMessages } = this.props;
        const messages = [];

        for (const message of flashMessages) {
            const key = flashMessages.indexOf(message);

            messages.push(
                <li key={key}>
                    <FlashMessage
                        index={key}
                        type={message.type}
                        text={message.text}
                        deleteFlashMessage={(id: number) => this.props.deleteFlashMessage(id)}
                    />
                </li>
            );
        }

        return messages;
    };

    getNotificationList = (): JSX.Element => {
        if (this.props.flashMessages.length > 0) {
            if (this.state.showMore) {
                return <div className="all-notifications">{this.getAllNotifications()}</div>;
            }

            return (
                <div className="all-notifications">
                    {this.getAllNotifications().slice(0, defaultConstants.FLASH_MESSAGES_PANEL_LIMIT)}
                </div>
            );
        }

        return <div className="no-notification">{translate("navigation.noNotifications")}</div>;
    };

    getPanelFooter = (): JSX.Element | null => {
        if (this.props.flashMessages.length > 1) {
            return (
                <div className="dropdown-panel-footer" onClick={(event: any) => event.stopPropagation()}>
                    {this.getNotificationButton()}
                    {this.getDeleteAllButton()}
                </div>
            );
        }

        return null;
    };

    getNotificationButton = (): JSX.Element | null => {
        const { flashMessages } = this.props;

        if (flashMessages.length > defaultConstants.FLASH_MESSAGES_PANEL_LIMIT) {
            if (this.state.showMore) {
                return (
                    <button className="btn-without-style notification-button" onClick={this.handleShowMoreButtonClick}>
                        {translate("navigation.showLess")}
                    </button>
                );
            }

            return (
                <button className="btn-without-style notification-button" onClick={this.handleShowMoreButtonClick}>
                    <span>
                        {translate("navigation.showAllMessages")} (
                        {flashMessages.length - defaultConstants.FLASH_MESSAGES_PANEL_LIMIT})
                    </span>
                </button>
            );
        }

        return null;
    };

    getDeleteAllButton = (): JSX.Element | null => {
        const { flashMessages } = this.props;

        if (
            flashMessages.length > 1 &&
            (flashMessages.length <= defaultConstants.FLASH_MESSAGES_PANEL_LIMIT ||
                (this.state.showMore && flashMessages.length > defaultConstants.FLASH_MESSAGES_PANEL_LIMIT))
        ) {
            return (
                <button className="btn-without-style delete-all-button" onClick={this.handleDeleteAllButtonClick}>
                    <ReactSVG src={imgClose} className="btn-icon" />
                    {translate("navigation.deleteAll")}
                </button>
            );
        }

        return null;
    };

    render(): JSX.Element | null {
        if (this.props.activeNotificationPanel) {
            return (
                <div className={this.getPanelClassName()}>
                    <div className="dropdown-panel-content messages-panel slim-scroll">
                        {this.getNotificationList()}
                    </div>
                    {this.getPanelFooter()}
                    <i />
                </div>
            );
        }

        return null;
    }
}
