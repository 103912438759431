import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { GenericUnitScene } from "../../../components/scenes/generic/GenericUnitScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { Unit } from "../../../types/unit";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    unitTableConstant: string;
    unitList: Array<Unit>;
    unitActiveList: Array<Unit>;
    unitLoading: boolean;
    unitButtonLoading: boolean;
    unitColumnOrder: Array<propertyConstants.Property>;
    unitColumnVisibility: Record<propertyConstants.Property, boolean>;
    unitColumnWidth: Record<propertyConstants.Property, number>;
    unitOffset: number;
    unitPage: number;
    unitRowCount: number;
    unitRowCountCustom: boolean;
    unitSceneTableSize: Record<string, any>;
    unitSearch: string | null;
    unitSearchParams: Record<propertyConstants.Property, string>;
    unitShowFilterRow: boolean;
    unitShowGlobalSearch: boolean;
    unitSortingAsc: boolean;
    unitSortingCriterion: propertyConstants.Property | null;
    unitTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_UNITS
    ),
    unitTableConstant: menuConstants.TABLE_GENERIC_UNITS,
    unitList: state.unit.list,
    unitActiveList: state.unit.activeList,
    unitLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_UNITS || request.method === methods.METHOD_DELETE_UNIT
    ),
    unitButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_UNIT
    ),
    unitColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitOffset: state.unit.offset,
    unitPage: state.unit.page,
    unitRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_UNITS
    ),
    unitSearch: state.unit.search,
    unitSearchParams: state.unit.searchParams,
    unitShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_UNITS
    ),
    unitSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_UNITS
        )
    ),
    unitSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_UNITS
        )
    ),
    unitTotalCount: state.unit.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_UNITS
    )
});

export const GenericUnitSceneContainer = connect(mapStateToProps)(GenericUnitScene);
