import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Currency, mapCurrency } from "../../types/currency";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_CURRENCIES_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const currencyList: Array<Currency> = [];
            let currency: Currency | null;

            for (const item of response.data.data) {
                currency = mapCurrency(item);

                if (currency) {
                    currencyList.push(currency);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.CURRENCY, currencyList)];
        }
        case methods.METHOD_GET_ALL_CURRENCIES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const currencyList: Array<Currency> = [];
            let currency: Currency | null;

            for (const item of response.data.data) {
                currency = mapCurrency(item);

                if (currency) {
                    currencyList.push(currency);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.CURRENCY, currencyList, response.data.count)];
        }
        case methods.METHOD_ADD_CURRENCY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.CURRENCY),
                navigationActions.navigationCloseModal(modalTypes.MODAL_CURRENCIES_ADD)
            ];
        }
        case methods.METHOD_EDIT_CURRENCY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_CURRENCIES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.CURRENCY));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_CURRENCIES_EDIT));
            } else {
                const currency = mapCurrency(response.data);

                if (currency) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.CURRENCY, [currency]));
                }
            }
            return requests;
        }
        case methods.METHOD_DELETE_CURRENCY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.CURRENCY),
                universalObjectActions.setActiveItems(objectTypes.CURRENCY, [])
            ];
        }
        case methods.METHOD_GET_ACTIVE_SYSTEM_CURRENCY: {
            if (responseValidationHelper.isResponseValid(response)) {
                const currencyList: Array<Currency> = [];

                const currency = mapCurrency(response.data);

                if (currency) {
                    currencyList.push(currency);
                }

                return [
                    universalObjectActions.saveItems(objectTypes.SYSTEM_CURRENCY, currencyList),
                    universalObjectActions.getAllItems(objectTypes.SYSTEM_PRICE_GENERIC),
                    universalObjectActions.getAllItems(objectTypes.SYSTEM_PRICE_GROUP),
                    universalObjectActions.getAllItems(objectTypes.SYSTEM_PRICE_OPTION)
                ];
            }

            return [];
        }
        case methods.METHOD_GET_ACTIVE_ZONE_CURRENCY: {
            if (responseValidationHelper.isResponseValid(response)) {
                const currencyList: Array<Currency> = [];

                const currency = mapCurrency(response.data);

                if (currency) {
                    currencyList.push(currency);
                }

                return [
                    universalObjectActions.saveItems(objectTypes.ZONE_CURRENCY, currencyList),
                    universalObjectActions.getAllItems(objectTypes.ZONE_PRICE_GENERIC),
                    universalObjectActions.getAllItems(objectTypes.ZONE_PRICE_GROUP),
                    universalObjectActions.getAllItems(objectTypes.ZONE_PRICE_OPTION)
                ];
            }

            return [];
        }
        case methods.METHOD_SET_ACTIVE_SYSTEM_CURRENCY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
