import * as bookmarkConstants from "../../constants/bookmarkConstants";
import * as colorHelper from "../../helpers/colorHelper";
import * as designConstants from "../../constants/designConstants";
import * as designHelper from "../../helpers/designHelper";
import * as fileActions from "../../actions/fileActions";
import * as formattingHelper from "../../helpers/formattingHelper";
import * as formulaConstants from "../../constants/entityConstants/formulaConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as languageHelper from "../../helpers/languageHelper";
import * as menuConstants from "../../constants/menuConstants";
import * as methods from "../../constants/serverMethods";
import * as modalHelper from "../../helpers/modalHelper";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as notificationActions from "../../actions/notificationActions";
import * as objectTypeHelper from "../../helpers/objectTypeHelper";
import * as objectTypes from "../../constants/objectTypes";
import * as optionConstants from "../../constants/optionsConstants";
import * as optionHelper from "../../helpers/optionHelper";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as serverConstants from "../../constants/serverConstants";
import * as settingsActions from "../../actions/optionActions";
import * as tableConstants from "../../constants/tableConstants";
import * as translationConstants from "../../constants/translationConstants";
import * as translationHelper from "../../helpers/translationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as userActions from "../../actions/userActions";
import * as validationConstants from "../../constants/validationConstants";
import * as validationHelper from "../../helpers/validationHelper";

import { Bookmark, updateBookmarkContent } from "../../types/bookmark";
import React, { Component } from "react";

import { AppState } from "../../reducers";
import { BookmarkTabs } from "../general/BookmarkTabs";
import { Color } from "../../types/color";
import { Dispatch } from "redux";
import { DuplicateProduct } from "../../types/duplication/duplicateProduct";
import { ExportType } from "../../types/exportType";
import { File } from "../../types/file";
import { FileCategory } from "../../types/fileCategory";
import { FileTable } from "./tables/FileTable";
import { FileType } from "../../types/fileType";
import { FooterAlertProperty } from "./general/FooterAlertProperty";
import { Form } from "./general/Form";
import { FormulaColorantTable } from "../general/table/FormulaColorantTable";
import { GeneralPropertyModal } from "./general/GeneralPropertyModal";
import { ImageCategory } from "../../types/imageCategory";
import { MenuItem } from "../../types/menu";
import { ModalParams } from "../../types/modalParams";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { ModalType } from "../../constants/modalTypes";
import { Price } from "../../types/price";
import { ProductDuplicateTable } from "./tables/ProductDuplicateTable";
import { ReactSVG } from "react-svg";
import { Scene } from "../../constants/navigationTypes";
import { ServerRequest } from "../../types/serverRequest";
import { SpectralSettingsBookmarkContainer } from "../bookmarks/SpectralSettingsBookmark";
import { System } from "../../types/system";
import { TableHeading } from "../../types/tableHeading";
import { Unit } from "../../types/unit";
import { Validation } from "../../types/validation";
import { WebZone } from "../../types/web/webZone";
import { Zone } from "../../types/zone";
import { ZoneDuplicateTable } from "./tables/ZoneDuplicateTable";

import { connect } from "react-redux";
import imgClose from "../../resources/img/icons/close.svg";
import { t as translate } from "react-i18nify";

type OwnProps = PropsType & DispatchType;

type ModalProps = {
    id: number;
    type: ModalType;
    index: number;
    params: ModalParams;
    closeModal(type: ModalType): void;
};

type Props = OwnProps & ModalProps;

type State = {
    objectData: any;
    headingData: Array<TableHeading>;
    isUploading: boolean;
    updatedProperties: Array<propertyConstants.Property>;
    footerValidation: Validation | null;
    generalValidation: Validation | null;
    validationList: Array<Validation>;
};

export class SimpleObjectModal extends Component<Props, State> {
    state: State = {
        objectData: null,
        headingData: [],
        isUploading: false,
        updatedProperties: [],
        footerValidation: null,
        generalValidation: null,
        validationList: []
    };

    // Component life cycle methods

    componentDidMount(): void {
        const { params } = this.props;

        document.addEventListener("keydown", this.handleModalKeyDown, false);

        const headingData = params.headings || [];
        const objectData = generalHelper.cloneObject(params.data);

        // TODO co allProperties delaji?
        let allProperties: Array<any> = [];
        allProperties = allProperties.concat(params.headings || []);
        for (const bookmark of params.bookmarks || []) {
            for (const property of bookmark.properties) {
                allProperties = allProperties.concat(property);
            }
        }
        allProperties = allProperties.filter(
            (property) => property[tableConstants.TABLE_HEADING_TYPE] === tableConstants.TABLE_TYPE_SELECT
        );

        for (const property of allProperties) {
            if (objectData?.[property?.[tableConstants.TABLE_HEADING_NAME]] !== undefined) {
                objectData[property[tableConstants.TABLE_HEADING_NAME]] =
                    objectData[property[tableConstants.TABLE_HEADING_NAME]] ?? "";
            }
        }

        /* if add color modal is opened, then the colorModalActiveList needs to be updated with empty data,
            because in colorActiveList is stored the active color item in the table and is not used in modals*/
        if (this.props.type === modalTypes.MODAL_COLORS_ADD) {
            this.props.editItem(objectTypes.MASTER_COLOR_MODAL, objectData);
        }
        if (this.props.type === modalTypes.MODAL_FORMULAS_ADD) {
            this.props.editItem(objectTypes.MASTER_FORMULA_MODAL, objectData);
        }

        this.setState({ objectData: objectData, headingData: headingData });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        // data in colorData are being save into the store directly, so through this if statement they are updated in the state object
        if (
            this.props.params.objectType === objectTypes.MASTER_COLOR &&
            JSON.stringify(prevProps.colorActiveList) !== JSON.stringify(this.props.colorActiveList)
        ) {
            const updatedObjectData = this.props.colorActiveList.length
                ? generalHelper.cloneObject(this.props.colorActiveList[0])
                : null;

            if (
                JSON.stringify(this.props.colorActiveList?.[0]?.[propertyConstants.PROPERTY_DATA]) ===
                JSON.stringify(prevProps.colorActiveList?.[0]?.[propertyConstants.PROPERTY_DATA])
            ) {
                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [propertyConstants.PROPERTY_DATA]: updatedObjectData?.[propertyConstants.PROPERTY_DATA] || [],
                        [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]:
                            updatedObjectData?.[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] || "",
                        [propertyConstants.PROPERTY_COLOR_RED]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLOR_RED] ?? "",
                        [propertyConstants.PROPERTY_COLOR_GREEN]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? "",
                        [propertyConstants.PROPERTY_COLOR_BLUE]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? "",
                        [propertyConstants.PROPERTY_COLOR_RGB_HEX]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLOR_RGB_HEX] || ""
                    }
                });
            } else {
                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [propertyConstants.PROPERTY_DATA]: updatedObjectData?.[propertyConstants.PROPERTY_DATA] || [],
                        [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]:
                            updatedObjectData?.[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] || ""
                    }
                });
            }
        }

        // formula colorant
        if (
            this.props.params.objectType === objectTypes.MASTER_FORMULA &&
            JSON.stringify(prevProps.colorActiveList) !== JSON.stringify(this.props.colorActiveList)
        ) {
            const newFormula =
                this.props.colorActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_LIST]?.find(
                    (item) =>
                        item[propertyConstants.PROPERTY_ID] ===
                        this.props.colorActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID]
                ) ?? null;
            const oldFormula =
                prevProps.colorActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_LIST]?.find(
                    (item) =>
                        item[propertyConstants.PROPERTY_ID] ===
                        prevProps.colorActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID]
                ) ?? null;

            if (
                newFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] !==
                    oldFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ||
                newFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION] !==
                    oldFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION] ||
                JSON.stringify(newFormula?.[propertyConstants.PROPERTY_COLORANT_LIST]) !==
                    JSON.stringify(oldFormula?.[propertyConstants.PROPERTY_COLORANT_LIST])
            ) {
                const updatedObjectData = newFormula ? generalHelper.cloneObject(newFormula) : null;

                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [propertyConstants.PROPERTY_COLORANT_SORTING_ASC]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ?? true,
                        [propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION] ?? null,
                        [propertyConstants.PROPERTY_COLORANT_LIST]:
                            updatedObjectData?.[propertyConstants.PROPERTY_COLORANT_LIST] ?? []
                    }
                });
            }
        }

        if (
            objectTypeHelper.hasObjectTypeProductFile(this.props.params.objectType) &&
            JSON.stringify(prevProps.productSheetList) !== JSON.stringify(this.props.productSheetList)
        ) {
            this.setState({
                objectData: {
                    ...this.state.objectData,
                    [propertyConstants.PROPERTY_FILES]: [...this.props.productSheetList]
                },
                updatedProperties: !this.state.updatedProperties.includes(propertyConstants.PROPERTY_FILES)
                    ? [...this.state.updatedProperties, propertyConstants.PROPERTY_FILES]
                    : [...this.state.updatedProperties]
            });
        }

        if (
            this.props.params.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD &&
            prevProps.oneTimeAccessRedlikePassword !== this.props.oneTimeAccessRedlikePassword
        ) {
            this.setState({
                objectData: {
                    ...this.state.objectData,
                    [propertyConstants.PROPERTY_PASSWORD]: this.props.oneTimeAccessRedlikePassword
                }
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);

        // Deleting uploaded files when modal closes, so that it does not take space as it is not used anymore
        if (
            this.props.params.objectType === objectTypes.DATABASE &&
            (this.state.objectData?.[propertyConstants.PROPERTY_FILE]?.[propertyConstants.PROPERTY_UUID] || null)
        ) {
            this.props.deleteUploadedFile(
                this.state.objectData[propertyConstants.PROPERTY_FILE][propertyConstants.PROPERTY_UUID]
            );
        }
    }

    // Handle event key down

    handleModalKeyDown = (event: Record<string, any>): void => {
        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.target.type === undefined) {
                    this.handleButtonOkClick();
                } else {
                    event.preventDefault();
                }

                break;
            }
            case keyCodes.ESCAPE: {
                this.props.closeModal(this.props.type);

                break;
            }
            default: {
                break;
            }
        }
    };

    // Handle item row events

    handleItemRowChange = (property: propertyConstants.Property, value: any): any => {
        const { objectType } = this.props.params;
        const { type } = this.props;
        const heading =
            this.state.headingData.find((item) => item[tableConstants.TABLE_HEADING_NAME] === property) ?? null;
        const headingEnum = heading?.[tableConstants.TABLE_HEADING_ENUM] ?? [];

        let isChanged = false;

        // TODO solve boolean + change/blur in selectbox
        switch (property) {
            case propertyConstants.PROPERTY_ACTION_TYPE: {
                if (objectType === objectTypes.TRANSLATION) {
                    isChanged = true;

                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_FILE_TYPE]: ""
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }

                break;
            }
            case propertyConstants.PROPERTY_DATABASE_ID: {
                if (objectType === objectTypes.EXPORT_TASK_DELETE || objectType === objectTypes.IMPORT_TASK_DELETE) {
                    isChanged = true;

                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_SYSTEM_ID]: "",
                            [propertyConstants.PROPERTY_ZONE_ID]: ""
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }

                break;
            }
            case propertyConstants.PROPERTY_DEFAULT_DATA: {
                if (objectType === objectTypes.DATABASE) {
                    isChanged = true;
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_ADD_CURRENCY]: (
                                value === serverConstants.DATABASE_DEFAULT_WITH_SYSTEM_ZONE_CURRENCY
                            ).toString(),
                            [propertyConstants.PROPERTY_MASTER_ONLY]: (
                                value !== serverConstants.DATABASE_DEFAULT_WITH_SYSTEM_ZONE_CURRENCY
                            ).toString()
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }
                break;
            }
            case propertyConstants.PROPERTY_FILE: {
                if (type === modalTypes.MODAL_FILES_ADD) {
                    isChanged = true;
                    const fileName =
                        this.state.objectData[propertyConstants.PROPERTY_NAME] === ""
                            ? value.fileName
                            : this.state.objectData[propertyConstants.PROPERTY_NAME];
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_NAME]: fileName
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }
                break;
            }
            case propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT: {
                isChanged = true;
                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [property]: value === "true"
                    }
                });

                break;
            }
            case propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST:
            case propertyConstants.PROPERTY_PRICE_GROUP_LIST: {
                const list = this.state.objectData[property].map((price: Price) =>
                    price[propertyConstants.PROPERTY_ID] === value[propertyConstants.PROPERTY_ID] ? value : price
                );
                isChanged = true;

                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [property]: list
                    },
                    updatedProperties: !this.state.updatedProperties.includes(property)
                        ? [...this.state.updatedProperties, property]
                        : [...this.state.updatedProperties]
                });
                break;
            }
            case propertyConstants.PROPERTY_SRC: {
                isChanged = true;
                if (type === modalTypes.MODAL_IMAGES_ADD || type === modalTypes.MODAL_ICONS_ADD) {
                    const fileName =
                        this.state.objectData[propertyConstants.PROPERTY_NAME] === ""
                            ? value.name
                            : this.state.objectData[propertyConstants.PROPERTY_NAME];
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value.src,
                            [propertyConstants.PROPERTY_NAME]: fileName
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                } else {
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value.src
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }
                break;
            }
            case propertyConstants.PROPERTY_STATUS: {
                if (objectType === objectTypes.MASTER_FORMULA) {
                    isChanged = true;

                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_ENABLED]:
                                value === formulaConstants.FORMULA_STATUS_ACCEPTED ||
                                value === formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED ||
                                value === formulaConstants.FORMULA_STATUS_NOT_AVAILABLE,
                            [propertyConstants.PROPERTY_REJECTED]:
                                value === formulaConstants.FORMULA_STATUS_CANCELED_REJECTED,
                            [propertyConstants.PROPERTY_VERIFIED]:
                                value === formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED,
                            [propertyConstants.PROPERTY_NOT_AVAILABLE]:
                                value === formulaConstants.FORMULA_STATUS_NOT_AVAILABLE
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }

                break;
            }
            case propertyConstants.PROPERTY_SYSTEM_ID: {
                if (
                    objectType === objectTypes.EXPORT_TASK ||
                    objectType === objectTypes.EXPORT_TASK_DELETE ||
                    objectType === objectTypes.IMPORT_TASK_DELETE
                ) {
                    isChanged = true;

                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_ZONE_ID]: ""
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }

                break;
            }
            case propertyConstants.PROPERTY_UPDATE_ONLY: {
                if (objectType === objectTypes.MASTER_FORMULA) {
                    isChanged = true;
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: !value
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }
                break;
            }
            case propertyConstants.PROPERTY_ZONE_ID: {
                if (objectType === objectTypes.EXPORT_TASK) {
                    const option = generalHelper.getObjectById(headingEnum, value);

                    if (option) {
                        isChanged = true;

                        this.setState({
                            objectData: {
                                ...this.state.objectData,
                                [property]: value,
                                [propertyConstants.PROPERTY_CHANGELOG]: option[propertyConstants.PROPERTY_CHANGELOG],
                                [propertyConstants.PROPERTY_PASSWORD]: option[propertyConstants.PROPERTY_PASSWORD],
                                [propertyConstants.PROPERTY_VERSION]: option[propertyConstants.PROPERTY_VERSION],
                                [propertyConstants.PROPERTY_VERSION_NAME]:
                                    option[propertyConstants.PROPERTY_VERSION_NAME],
                                [propertyConstants.PROPERTY_ZONE_UUID]: option[propertyConstants.PROPERTY_UUID]
                            },
                            updatedProperties: !this.state.updatedProperties.includes(property)
                                ? [...this.state.updatedProperties, property]
                                : [...this.state.updatedProperties]
                        });
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        if (!isChanged) {
            this.setState({
                objectData: { ...this.state.objectData, [property]: value },
                updatedProperties: !this.state.updatedProperties.includes(property)
                    ? [...this.state.updatedProperties, property]
                    : [...this.state.updatedProperties]
            });
        }
    };

    handleItemRowBlur = (property: propertyConstants.Property, value: any): any => {
        const { objectType } = this.props.params;

        switch (property) {
            case propertyConstants.PROPERTY_COLOR_BLUE:
            case propertyConstants.PROPERTY_COLOR_GREEN:
            case propertyConstants.PROPERTY_COLOR_RED: {
                const hexRgb = formattingHelper.convertRgbToHex(
                    property === propertyConstants.PROPERTY_COLOR_RED
                        ? value
                        : this.state.objectData[propertyConstants.PROPERTY_COLOR_RED],
                    property === propertyConstants.PROPERTY_COLOR_GREEN
                        ? value
                        : this.state.objectData[propertyConstants.PROPERTY_COLOR_GREEN],
                    property === propertyConstants.PROPERTY_COLOR_BLUE
                        ? value
                        : this.state.objectData[propertyConstants.PROPERTY_COLOR_BLUE]
                );

                if (validationHelper.isHexRgbValueValid(hexRgb)) {
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value,
                            [propertyConstants.PROPERTY_COLOR_RGB_HEX]: hexRgb?.toUpperCase() || hexRgb
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }

                break;
            }
            case propertyConstants.PROPERTY_COLOR_RGB_HEX: {
                const colors = formattingHelper.convertHexToRgb(value);
                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [property]: colors ? value : null,
                        [propertyConstants.PROPERTY_COLOR_RED]: colors?.red ?? null,
                        [propertyConstants.PROPERTY_COLOR_GREEN]: colors?.green ?? null,
                        [propertyConstants.PROPERTY_COLOR_BLUE]: colors?.blue ?? null
                    },
                    updatedProperties: !this.state.updatedProperties.includes(property)
                        ? [...this.state.updatedProperties, property]
                        : [...this.state.updatedProperties]
                });
                break;
            }
            case propertyConstants.PROPERTY_FILL_PROC: {
                const selectedUnit = this.props.unitList.find(
                    (item: Unit) =>
                        item[propertyConstants.PROPERTY_ID] ===
                        this.state.objectData[propertyConstants.PROPERTY_UNIT_ID]
                );

                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [property]: value,
                        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: generalHelper.calculateFillVolumeMl(
                            this.state.objectData,
                            value,
                            selectedUnit ? selectedUnit : null
                        )
                    },
                    updatedProperties: !this.state.updatedProperties.includes(property)
                        ? [...this.state.updatedProperties, property]
                        : [...this.state.updatedProperties]
                });

                break;
            }
            case propertyConstants.PROPERTY_NAME: {
                if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
                    const systemName =
                        objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE
                            ? this.state.objectData[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
                            : null;
                    const packageName = this.state.objectData[propertyConstants.PROPERTY_PACKAGE_NAME] ?? null;

                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value || systemName || packageName
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                    // updating product name in the table
                } else if (objectTypeHelper.isObjectTypeDuplicateProduct(objectType)) {
                    const activeItem =
                        objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE
                            ? this.props.activeSystem
                            : this.props.activeZone;
                    const data = {
                        data: {
                            ...this.state.objectData,
                            [property]: value
                        },
                        [propertyConstants.PROPERTY_ID]: activeItem ? activeItem[propertyConstants.PROPERTY_ID] : null
                    };

                    this.props.updateItem(objectType, [data]);
                    this.setState({
                        objectData: { ...this.state.objectData, [property]: value },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                } else {
                    this.setState({
                        objectData: {
                            ...this.state.objectData,
                            [property]: value
                        },
                        updatedProperties: !this.state.updatedProperties.includes(property)
                            ? [...this.state.updatedProperties, property]
                            : [...this.state.updatedProperties]
                    });
                }
                break;
            }
            default: {
                this.setState({
                    objectData: {
                        ...this.state.objectData,
                        [property]: value
                    },
                    updatedProperties: !this.state.updatedProperties.includes(property)
                        ? [...this.state.updatedProperties, property]
                        : [...this.state.updatedProperties]
                });

                break;
            }
        }
    };

    // TODO check this method
    handleItemButtonClick = (heading: TableHeading, property: propertyConstants.Property, editable: boolean): any => {
        const { openModal } = this.props;
        const { type, objectType } = this.props.params;
        const { objectData, headingData } = this.state;
        const headingIndex =
            headingData.findIndex(
                (heading: any) =>
                    heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_IMAGE_CATEGORY_ID ||
                    heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_FILE_CATEGORY_ID
            ) || -1;
        const modalType = modalHelper.getModalType(property, editable);

        if (modalType) {
            switch (property) {
                case propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES: {
                    openModal(modalType, {
                        type: modalType,
                        objectType: objectTypes.WEB_ZONE,
                        headings: [],
                        classNameTable: "simple-table",
                        returnAction: (activeWebZone: WebZone | null): void => {
                            if (activeWebZone) {
                                const updatingProperties: Array<propertyConstants.Property> = [
                                    propertyConstants.PROPERTY_UUID,
                                    propertyConstants.PROPERTY_NAME,
                                    propertyConstants.PROPERTY_VERSION
                                ];

                                this.setState({
                                    objectData: {
                                        ...this.state.objectData,
                                        [propertyConstants.PROPERTY_UUID]: activeWebZone[
                                            propertyConstants.PROPERTY_UUID
                                        ]
                                            ? activeWebZone[propertyConstants.PROPERTY_UUID]
                                            : this.state.objectData[propertyConstants.PROPERTY_UUID],
                                        [propertyConstants.PROPERTY_NAME]: activeWebZone[
                                            propertyConstants.PROPERTY_NAME
                                        ]
                                            ? activeWebZone[propertyConstants.PROPERTY_NAME]
                                            : this.state.objectData[propertyConstants.PROPERTY_NAME],
                                        [propertyConstants.PROPERTY_VERSION]: activeWebZone[
                                            propertyConstants.PROPERTY_VERSION
                                        ]
                                            ? activeWebZone[propertyConstants.PROPERTY_VERSION]
                                            : this.state.objectData[propertyConstants.PROPERTY_VERSION]
                                    },
                                    updatedProperties: [
                                        ...this.state.updatedProperties,
                                        ...updatingProperties.filter(
                                            (property: propertyConstants.Property) =>
                                                !this.state.updatedProperties.includes(property)
                                        )
                                    ]
                                });
                            }
                        }
                    });
                    break;
                }
                case propertyConstants.PROPERTY_MORE_INFO:
                case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
                case propertyConstants.PROPERTY_MORE_INFO_PRINT: {
                    const modal = document.getElementById(`modal-${this.props.id || ""}`);
                    const areaEl: any = modal?.querySelector(`#${property}`);
                    const value = this.state.objectData[property] !== null ? this.state.objectData[property] : "";
                    const position = areaEl.selectionStart !== 0 ? areaEl.selectionStart : value.length;

                    openModal(modalType, {
                        returnAction: (code: string): void => {
                            const newValue = [value.slice(0, position), code, value.slice(position)].join(" ");

                            this.setState({
                                objectData: {
                                    ...this.state.objectData,
                                    [property]: newValue
                                },
                                updatedProperties: !this.state.updatedProperties.includes(property)
                                    ? [...this.state.updatedProperties, property]
                                    : [...this.state.updatedProperties]
                            });
                        }
                    });

                    break;
                }
                case propertyConstants.PROPERTY_STATIC_SQL: {
                    const fileId = objectData[propertyConstants.PROPERTY_STATIC_SQL] || null;
                    const file = generalHelper.getObjectById(heading[tableConstants.TABLE_HEADING_ENUM], fileId);

                    if (fileId) {
                        openModal(modalType, {
                            title: translate("file.staticSqlPreview", {
                                name: file?.[propertyConstants.PROPERTY_NAME] || ""
                            }),
                            type: modalTypes.MODAL_FILE_PREVIEW,
                            objectType: objectTypes.FILE,
                            headings: [],
                            fileId: fileId,
                            classNameTable: "simple-table"
                        });
                    }

                    break;
                }
                case propertyConstants.PROPERTY_VERSION: {
                    let webZone = null;

                    if (this.props.webZoneList.length) {
                        webZone = this.props.webZoneList.find(
                            (item) =>
                                item[propertyConstants.PROPERTY_UUID] ===
                                this.state.objectData[propertyConstants.PROPERTY_ZONE_UUID]
                        );
                    }

                    openModal(modalType, {
                        title: translate("export.r2wVersion", {
                            redlikeWebAbbreviation: translationConstants.REDLIKE_WEB_ABBREVIATION
                        }),
                        type: modalType,
                        objectType: objectTypes.EXPORT_TASK,
                        data: {
                            [propertyConstants.PROPERTY_VERSION]: webZone
                                ? webZone[propertyConstants.PROPERTY_VERSION]
                                : null
                        },
                        returnAction: (version: string | null): void => {
                            if (version) {
                                this.setState({
                                    objectData: {
                                        ...this.state.objectData,
                                        [property]: generalHelper.incrementVersionByOne(version)
                                    },
                                    updatedProperties: !this.state.updatedProperties.includes(property)
                                        ? [...this.state.updatedProperties, property]
                                        : [...this.state.updatedProperties]
                                });
                            }
                        }
                    });

                    break;
                }
                default: {
                    openModal(modalType, {
                        returnAction: (
                            categoryId: number | null,
                            categoryList: Array<ImageCategory | FileCategory>
                        ): void => {
                            this.setState({
                                objectData: {
                                    ...this.state.objectData,
                                    [property]: categoryId,
                                    updatedProperties: !this.state.updatedProperties.includes(property)
                                        ? [...this.state.updatedProperties, property]
                                        : [...this.state.updatedProperties]
                                },
                                headingData: this.state.headingData.map((item: any) =>
                                    headingData.indexOf(item) === headingIndex
                                        ? {
                                              ...item,
                                              [tableConstants.TABLE_HEADING_ENUM]: categoryList
                                          }
                                        : item
                                )
                            });
                        }
                    });

                    break;
                }
            }
        } else {
            switch (property) {
                case propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION: {
                    const { validationList } = validationHelper.validateItem(
                        objectType,
                        modalTypes.EDIT_MODAL,
                        this.props.type,
                        [],
                        [],
                        objectData,
                        Object.keys(objectData).map((item) => item),
                        [propertyConstants.PROPERTY_COMPUTER_ID],
                        this.getAdditionalProperties()
                    );

                    if (validationList.length > 0) {
                        this.props.savePassword(null);
                        validationHelper.processValidationList(
                            validationList,
                            this.state.validationList,
                            this.props.id,
                            this.handleValidation
                        );

                        this.setState({
                            validationList: validationList
                        });
                    } else {
                        this.props.generateOneTimeAccessPassword(
                            objectData[propertyConstants.PROPERTY_COMPUTER_ID],
                            formattingHelper.formatDateTimeForServer(
                                new Date(objectData[propertyConstants.PROPERTY_DATE])
                            )
                        );

                        if (this.state.validationList.length > 0) {
                            for (const validation of this.state.validationList) {
                                validationHelper.hideInvalidCaption(validation, this.props.id, this.handleValidation);
                            }
                        }
                        this.setState({
                            validationList: []
                        });
                    }
                    break;
                }
                case propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION: {
                    if (this.props.oneTimeAccessRedlikePassword) {
                        navigator.clipboard.writeText(this.props.oneTimeAccessRedlikePassword);
                    }
                    break;
                }
                case propertyConstants.PROPERTY_PASSWORD: {
                    const { validationList } = validationHelper.validateItem(
                        objectType,
                        type,
                        this.props.type,
                        [],
                        [],
                        objectData,
                        Object.keys(objectData).map((item) => item),
                        [propertyConstants.PROPERTY_PASSWORD_HASH],
                        this.getAdditionalProperties()
                    );

                    if (validationList.length > 0) {
                        const validation = validationList.find(
                            (validation: Validation) =>
                                validation[propertyConstants.PROPERTY_TYPE] === validationConstants.GENERAL_VALIDATION
                        );

                        if (validation) {
                            this.setState({
                                generalValidation: validation,
                                validationList: validationList
                            });
                        }
                    } else {
                        this.setState({
                            generalValidation: null,
                            validationList: []
                        });
                    }
                    break;
                }
                default:
                    break;
            }
        }
    };

    // Handle button click events

    handleButtonCancelClick = (): void => {
        this.props.closeModal(this.props.type);
    };

    handleAdditionalButtonOkClick = (): void => {
        switch (this.props.params.objectType) {
            case objectTypes.EXPORT_TASK_DELETE:
            case objectTypes.IMPORT_TASK_DELETE:
                this.props.deleteItem(this.props.params.objectType, {});
                break;
            default:
                break;
        }
    };

    handleButtonOkClick = (property?: propertyConstants.Property, id?: string | number | null): void => {
        const { bookmarks, headings, type, objectType, returnAction } = this.props.params;
        const { objectData } = this.state;
        let additionalProperties = {};

        switch (objectType) {
            case objectTypes.BARCODE: {
                this.props.closeModal(this.props.type);
                break;
            }
            case objectTypes.IMAGE_DIRECTORY: {
                this.props.addItem(objectType, {});
                break;
            }
            default: {
                let newObjectData = generalHelper.cloneObject(this.state.objectData);
                // Handled image upload
                if (property && id) {
                    newObjectData = { ...newObjectData, [property]: id };
                }

                additionalProperties = this.getAdditionalProperties();

                const { data, validationList } = validationHelper.validateItem(
                    objectType,
                    type,
                    this.props.type,
                    headings ?? [],
                    bookmarks ?? [],
                    newObjectData,
                    this.state.updatedProperties,
                    [],
                    additionalProperties
                );

                if (data !== undefined && data !== null && Object.keys(data).length > 0) {
                    // Data are valid, modal is passing action through epics to the server
                    if (validationList.length === 0 && !this.state.isUploading) {
                        switch (type) {
                            case modalTypes.ADD_MODAL: {
                                if (objectType !== objectTypes.MASTER_PRODUCT) {
                                    this.props.addItem(objectType, data);
                                } else {
                                    this.props.addIndexData(objectType, data);
                                }

                                // in some modals (when two or more of them is opened) there has to be a return action with selected ID
                                const newObjectId = modalHelper.getAddModalProperty(objectType);

                                if (newObjectId && returnAction) {
                                    returnAction(newObjectData ? newObjectData[newObjectId] : null);
                                }

                                break;
                            }
                            case modalTypes.DELETE_MODAL: {
                                this.props.deleteItem(objectType, data);
                                break;
                            }
                            case modalTypes.DUPLICATE_MODAL: {
                                this.props.duplicateItem(objectType, data);

                                break;
                            }
                            case modalTypes.EDIT_MODAL: {
                                this.props.editItem(objectType, data);
                                break;
                            }
                            case modalTypes.TRANSLATION_MODAL: {
                                this.setState({
                                    objectData: {
                                        ...objectData,
                                        [propertyConstants.PROPERTY_VALUE]:
                                            languageHelper.callTranslationFunction(objectData)
                                    }
                                });

                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }

                    if (validationList.length > 0) {
                        validationHelper.processValidationList(
                            validationList,
                            this.state.validationList,
                            this.props.id,
                            this.handleValidation
                        );

                        this.setState({
                            validationList: validationList
                        });
                    } else {
                        if (this.state.validationList.length > 0) {
                            for (const validation of this.state.validationList) {
                                validationHelper.hideInvalidCaption(validation, this.props.id, this.handleValidation);
                            }
                        }

                        this.setState({
                            validationList: [],
                            footerValidation: null,
                            generalValidation: null
                        });
                    }
                } else {
                    this.props.closeModal(this.props.type);
                }
            }
        }
    };

    handleValidation = (validationType: validationConstants.ValidationType, validation: Validation | null): void => {
        if (validationType === validationConstants.CLIENT_ERROR_VALIDATION) {
            this.props.showClientFlashMessage();
        } else {
            this.setState({
                ...this.state,
                [validationType]: validation
            });
        }
    };

    getAdditionalProperties = (): Record<string, any> => {
        const { objectType } = this.props.params;

        switch (objectType) {
            case objectTypes.EXPORT_TASK:
            case objectTypes.SYSTEM_ZONE: {
                return {
                    [propertyConstants.PROPERTY_WEB_ZONE_LIST]: this.props.webZoneList,
                    [propertyConstants.PROPERTY_EXPORT_TYPE_LIST]: this.props.exportTypesList,
                    [propertyConstants.PROPERTY_EXPORT_TASK_TYPE]:
                        this.props.exportTypesList.find(
                            (item: ExportType) =>
                                item[propertyConstants.PROPERTY_ID] ===
                                    this.state.objectData?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID] ?? null
                        ) ?? null
                };
            }
            case objectTypes.SYSTEM_DUPLICATE: {
                return {
                    [propertyConstants.PROPERTY_ZONE_LIST]: this.props.zoneAllList
                };
            }
            case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
            case objectTypes.ZONE_PRODUCT_DUPLICATE: {
                return {
                    [propertyConstants.PROPERTY_PRODUCT_DUPLICATE_LIST]: this.props.duplicateProductAllList
                };
            }
            case objectTypes.ZONE: {
                return {
                    [propertyConstants.PROPERTY_WEB_ZONE_LIST]: this.props.webZoneList
                };
            }
            default:
                return {};
        }
    };

    // Generate classnames

    getModalClassName = (): string => {
        const { type, objectType, bookmarks } = this.props.params;

        let className = "modal simple-modal";

        className += designHelper.getModalClassType(this.props.activeScene);

        if (type === modalTypes.EDIT_MODAL && objectTypes.COMPARE_DATA_OBJECT_TYPES.includes(objectType)) {
            className += " compare-master-data";
        }

        if ((bookmarks || []).length > 1) {
            className += " bookmarks";
        }

        if (objectTypeHelper.isObjectTypeFormulaNote(objectType)) {
            className += " formula-notes-data";
        }

        if (objectTypes.DATE_PICKER_OBJECT_TYPES.includes(objectType)) {
            className += " date-picker-modal";
        }

        if (objectTypeHelper.isObjectTypeRedlikeOption(objectType)) {
            className += " select-box-modal options-modal";
        }

        if (objectType === objectTypes.WAVELENGTH) {
            className += " select-box-modal";
        }

        if (objectType === objectTypes.USER) {
            className += " user-modal";
        }

        if (
            objectType === objectTypes.EXPORT_TASK ||
            objectType === objectTypes.TRANSLATION ||
            objectType === objectTypes.ZONE
        ) {
            className += " modal-bg";
        }

        return className;
    };

    getButtonAdditionalClassName = (): string => {
        let className = "btn-info pull-right";

        if (this.props.loading) {
            className += " disabled";
        }

        return className;
    };

    getButtonCancelClassName = (): string => {
        let className = "btn-info pull-right";

        // TODO cancel by nemel nikdy byt disabled?
        if (this.props.loading) {
            className += " disabled";
        }

        return className;
    };

    getButtonOkClassName = (): string => {
        let className = "btn-info pull-right";

        if (
            this.props.loading ||
            colorHelper.getReflectanceDataValidation(this.props.params.objectType, this.state.objectData)
        ) {
            className += " disabled";
        }

        return className;
    };

    // Generate bookmarks content = main content of modals

    generateForm = (headings: Array<TableHeading>, key?: string): JSX.Element => {
        return (
            <Form
                modalId={this.props.id}
                modalType={this.props.params.type}
                sessionUuid={this.props.sessionUuid}
                urlRest={this.props.urlRest}
                objectType={this.props.params.objectType}
                headings={headings}
                objectData={this.state.objectData}
                bookmarkKey={key}
                currency={this.props.params.currency}
                callbackClick={(heading: TableHeading, property: propertyConstants.Property, editable: boolean) =>
                    this.handleItemButtonClick(heading, property, editable)
                }
                callbackChange={(property: propertyConstants.Property, value: any) =>
                    this.handleItemRowChange(property, value)
                }
                callbackBlur={(property: propertyConstants.Property, value: any) =>
                    this.handleItemRowBlur(property, value)
                }
                confirm={(property: propertyConstants.Property, id: string | number | null) =>
                    this.handleButtonOkClick(property, id)
                }
                setUploadState={(isUploading: boolean) => {
                    this.setState({ isUploading: isUploading });
                }}
            />
        );
    };

    generateFormulaModalColorantTable = (bookmark: any) => {
        if (this.state.objectData && !this.state.objectData[propertyConstants.PROPERTY_NOT_AVAILABLE]) {
            return (
                <div className="width-50">
                    <div className="row">
                        <span>{translate("colorant.colorants")}</span>
                        <div className="unit-select-container">
                            <ModalSelectbox
                                className={"modal-select"}
                                editable={true}
                                required={true}
                                autoselect={false}
                                value={
                                    this.props.activeUnit
                                        ? this.props.activeUnit[propertyConstants.PROPERTY_NAME]
                                        : optionConstants.DEFAULT_FORMULA_COLORANT_UNIT
                                }
                                options={generalHelper.getOptionsForSelectbox(
                                    objectTypes.FORMULA_COLORANT_UNIT,
                                    this.props.unitList
                                )}
                                callback={(event: any, key: string) => {
                                    this.props.setOptions(
                                        [
                                            {
                                                key: optionsConstants.OPTION_FORMULA_COLORANT_UNIT,
                                                value: key,
                                                locked: false
                                            }
                                        ],
                                        true
                                    );
                                }}
                            />
                        </div>
                    </div>
                    {this.getTable(bookmark.key)}
                </div>
            );
        }

        return null;
    };

    generateFormTableBookmark = (bookmark: any): JSX.Element => {
        const { objectType } = this.props.params;
        switch (bookmark[propertyConstants.PROPERTY_KEY]) {
            case bookmarkConstants.BOOKMARK_FORMULA_COLORANT:
                return (
                    <div className="columns-container">
                        <div className="width-50">
                            {this.generateForm(bookmark.properties, bookmark[propertyConstants.PROPERTY_KEY])}
                        </div>
                        {this.generateFormulaModalColorantTable(bookmark)}
                    </div>
                );
            case bookmarkConstants.BOOKMARK_DUPLICATE:
                return (
                    <div className="columns-container">
                        <div className="width-50">
                            <div className="row">
                                <span>{translate("duplicate.selectDataToBeCopied")}</span>
                            </div>
                            {this.generateForm(bookmark.properties)}
                        </div>
                        <div className="width-50">
                            <div className="row">
                                <span>{translationHelper.getBookmarkDuplicationTableHeader(objectType)}</span>
                            </div>
                            {this.getTable(bookmark.key)}
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="columns-container">
                        <div className="width-50">{this.generateForm(bookmark.properties)}</div>
                        <div className="width-50">{this.getTable(bookmark.key)}</div>
                    </div>
                );
        }
    };

    generateFormBookmark = (bookmark: any): JSX.Element => {
        const { objectType } = this.props.params;

        switch (bookmark[propertyConstants.PROPERTY_KEY]) {
            case bookmarkConstants.BOOKMARK_DUPLICATE:
                if (objectType === objectTypes.ZONE_DUPLICATE) {
                    return (
                        <div>
                            <div className="row">
                                <span>{translate("duplicate.selectDataToBeCopied")}</span>
                            </div>
                            {this.generateForm(bookmark.properties, bookmark[propertyConstants.PROPERTY_KEY])}
                        </div>
                    );
                }

                return (
                    <div className="columns-container">
                        <div className="width-50">
                            <div className="row">
                                <span>{translate("duplicate.selectDataToBeCopied")}</span>
                            </div>
                            {this.generateForm(bookmark.properties, bookmark[propertyConstants.PROPERTY_KEY])}
                        </div>
                    </div>
                );
            default:
                return this.generateForm(bookmark.properties, bookmark[propertyConstants.PROPERTY_KEY]);
        }
    };

    // Generate tables

    getTable = (key: string): JSX.Element | null => {
        const { objectType } = this.props.params;

        switch (key) {
            case bookmarkConstants.BOOKMARK_FILES:
                return (
                    <FileTable
                        modalId={this.props.id}
                        tableConstant={menuConstants.TABLE_MODAL_FILES}
                        objectType={objectTypes.PRODUCT_FILE}
                        dataPrivileges={
                            (this.props.fileMenuScene
                                ? this.props.fileMenuScene.items.find(
                                      (item) => item.key === this.props.fileTableConstant
                                  )
                                : null) || null
                        }
                        loading={this.props.fileLoading}
                        categoryList={this.props.categoryList}
                        fileTypeList={this.props.fileTypeList}
                        allList={this.props.productSheetList}
                        activeList={this.props.productSheetActiveList}
                        columnOrder={this.props.productSheetColumnOrder}
                        columnVisibility={this.props.productSheetColumnVisibility}
                        columnWidth={this.props.productSheetColumnWidth}
                        offset={tableConstants.DEFAULT_OFFSET}
                        page={tableConstants.DEFAULT_PAGE}
                        rowCount={tableConstants.DEFAULT_ROW_COUNT}
                        search={tableConstants.DEFAULT_SEARCH}
                        searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                        sortingAsc={false}
                        sortingCriterion={null}
                        totalCount={null}
                        returnAction={(files: Array<File>): void => {
                            this.props.updateFileList(objectTypes.PRODUCT_FILE, files);
                            this.setState({
                                updatedProperties: !this.state.updatedProperties.includes(
                                    propertyConstants.PROPERTY_FILES
                                )
                                    ? [...this.state.updatedProperties, propertyConstants.PROPERTY_FILES]
                                    : [...this.state.updatedProperties]
                            });
                        }}
                    />
                );
            case bookmarkConstants.BOOKMARK_FORMULA_COLORANT:
                return (
                    <FormulaColorantTable
                        tableConstant={this.props.formulaColorantTableConstant}
                        objectType={objectTypes.MASTER_FORMULA_COLORANT}
                        dataPrivileges={
                            (this.props.formulaColorantMenuScene
                                ? this.props.formulaColorantMenuScene.items.find(
                                      (item) => item.key === this.props.formulaColorantTableConstant
                                  )
                                : null) || null
                        }
                        formulaId={this.state.objectData?.[propertyConstants.PROPERTY_ID] ?? undefined}
                        allList={this.state.objectData?.[propertyConstants.PROPERTY_COLORANT_LIST] || []}
                        isEditable={true}
                        columnOrder={this.props.formulaColorantColumnOrder}
                        columnVisibility={this.props.formulaColorantColumnVisibility}
                        columnWidth={this.props.formulaColorantColumnWidth}
                        showFooterRow={true}
                        sortingAsc={this.state.objectData?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ?? true}
                        sortingCriterion={
                            this.state.objectData?.[propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION] ?? null
                        }
                        callbackEdit={(property: propertyConstants.Property, value: any) => {
                            this.handleItemRowBlur(property, value);
                        }}
                    />
                );
            case bookmarkConstants.BOOKMARK_DUPLICATE:
                switch (objectType) {
                    case objectTypes.SYSTEM_DUPLICATE: {
                        return (
                            <ZoneDuplicateTable
                                tableConstant={this.props.zoneTableConstant}
                                objectType={objectTypes.ZONE_DUPLICATE}
                                dataPrivileges={
                                    (this.props.zoneMenuScene
                                        ? this.props.zoneMenuScene.items.find(
                                              (item) => item.key === menuConstants.TABLE_GLOBAL_ZONES
                                          )
                                        : null) || null
                                }
                                allList={this.props.zoneAllList}
                                loading={this.props.zoneLoading}
                                columnOrder={this.props.zoneColumnOrder}
                                columnVisibility={this.props.zoneColumnVisibility}
                                columnWidth={this.props.zoneColumnWidth}
                            />
                        );
                    }
                    case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
                    case objectTypes.ZONE_PRODUCT_DUPLICATE: {
                        let dataPrivileges = null;

                        if (objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE) {
                            dataPrivileges = this.props.duplicateSystemProductMenuScene
                                ? this.props.duplicateSystemProductMenuScene.items.find(
                                      (item) => item.key === menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
                                  )
                                : null;
                        } else {
                            dataPrivileges = this.props.duplicateZoneProductMenuScene
                                ? this.props.duplicateZoneProductMenuScene.items.find(
                                      (item) => item.key === menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS
                                  )
                                : null;
                        }

                        return (
                            <ProductDuplicateTable
                                tableConstant={this.props.duplicateProductTableConstant}
                                objectType={objectType}
                                dataPrivileges={dataPrivileges}
                                allList={this.props.duplicateProductAllList}
                                columnOrder={this.props.duplicateProductColumnOrder}
                                columnVisibility={this.props.duplicateProductColumnVisibility}
                                columnWidth={this.props.duplicateProductColumnWidth}
                            />
                        );
                    }
                    default:
                        return null;
                }
            default:
                return null;
        }
    };

    generateSpectralData = (): JSX.Element => {
        return (
            <SpectralSettingsBookmarkContainer
                modalId={this.props.id}
                objectType={objectTypes.MASTER_COLOR_SPECTRO_TYPE}
                objectData={this.state.objectData}
                callbackChange={(property: propertyConstants.Property, value: any) =>
                    this.handleItemRowChange(property, value)
                }
            />
        );
    };

    generateBookmarks = (): JSX.Element => {
        const bookmarks: Array<Bookmark> = [];

        for (const bookmark of this.props.params.bookmarks || []) {
            let newBookmark = null;

            switch (bookmark.type) {
                case bookmarkConstants.BOOKMARK_TYPE_FORM_TABLE: {
                    newBookmark = updateBookmarkContent(bookmark, this.generateFormTableBookmark(bookmark));
                    break;
                }
                case bookmarkConstants.BOOKMARK_TYPE_TABLE: {
                    newBookmark = updateBookmarkContent(bookmark, this.getTable(bookmark.key));
                    break;
                }
                case bookmarkConstants.BOOKMARK_SPECTRAL_DATA: {
                    newBookmark = updateBookmarkContent(bookmark, this.generateSpectralData());
                    break;
                }
                case bookmarkConstants.BOOKMARK_TYPE_FORM: {
                    if (
                        !objectTypeHelper.isObjectTypeFormula(this.props.params.objectType) ||
                        (bookmark[propertyConstants.PROPERTY_KEY] === bookmarkConstants.BOOKMARK_NOTES &&
                            objectTypeHelper.isObjectTypeFormula(this.props.params.objectType) &&
                            this.state.objectData &&
                            !this.state.objectData[propertyConstants.PROPERTY_NOT_AVAILABLE])
                    ) {
                        newBookmark = updateBookmarkContent(bookmark, this.generateFormBookmark(bookmark));
                    }
                    break;
                }
                default: {
                    newBookmark = updateBookmarkContent(bookmark, this.generateFormBookmark(bookmark));
                    break;
                }
            }

            if (newBookmark) {
                bookmarks.push(newBookmark);
            }
        }

        if (bookmarks.length) {
            return (
                <BookmarkTabs
                    bookmarks={bookmarks}
                    activeBookmark={this.props.activeModalBookmark}
                    modalId={this.props.id}
                    setActiveBookmarkCallback={(key: string | null): void => {
                        this.props.setActiveModalBookmark(key);
                    }}
                />
            );
        }

        return this.generateForm(this.state.headingData || []);
    };

    generateModalAlertProperty = (): JSX.Element | null => {
        const { params } = this.props;
        const { generalValidation, objectData } = this.state;

        if (generalValidation !== null) {
            return (
                <GeneralPropertyModal
                    modalId={this.props.id}
                    objectType={params.objectType}
                    property={generalValidation[propertyConstants.PROPERTY_NAME]}
                    editable={false}
                    required={false}
                    caption={""}
                    value={generalValidation[propertyConstants.PROPERTY_CAPTION]}
                    type={tableConstants.TABLE_TYPE_ALERT}
                />
            );
        }

        for (const heading of this.state.headingData) {
            if (heading[tableConstants.TABLE_HEADING_TYPE] === tableConstants.TABLE_TYPE_ALERT) {
                const property = heading[tableConstants.TABLE_HEADING_NAME];
                let value =
                    objectData && objectData[property] !== null && objectData[property] !== undefined
                        ? objectData[property]
                        : "";

                switch (heading[tableConstants.TABLE_HEADING_NAME]) {
                    case propertyConstants.PROPERTY_UPDATE_ONLY: {
                        value = property === propertyConstants.PROPERTY_UPDATE_ONLY ? !value : value;

                        return (
                            <GeneralPropertyModal
                                modalId={this.props.id}
                                objectType={params.objectType}
                                property={property}
                                editable={heading[tableConstants.TABLE_HEADING_EDITING]}
                                required={heading[tableConstants.TABLE_HEADING_REQUIRED]}
                                caption={heading[tableConstants.TABLE_HEADING_TRANSLATION]}
                                value={value}
                                type={tableConstants.TABLE_TYPE_BOOLEAN}
                                callback={(property: propertyConstants.Property, value: any) =>
                                    this.handleItemRowChange(property, value)
                                }
                            />
                        );
                    }
                    default:
                        return null;
                }
            }
        }
        return null;
    };

    // Generate buttons

    getButtonCancel = (): JSX.Element => {
        return (
            <button
                className={this.getButtonCancelClassName()}
                onClick={(): void => {
                    this.handleButtonCancelClick();
                }}
            >
                {translationHelper.getCancelButtonTranslation(this.props.params.objectType)}
            </button>
        );
    };

    getAdditionalButton = (): JSX.Element | null => {
        if (objectTypes.VISIBLE_ADDITIONAL_BUTTON_OBJECT_TYPES.includes(this.props.params.objectType)) {
            return (
                <button
                    id={`btn-additional-modal-${this.props.id}`}
                    className={this.getButtonAdditionalClassName()}
                    onClick={() => this.handleAdditionalButtonOkClick()}
                >
                    {translationHelper.getAdditionalButtonTranslation(this.props.params.objectType)}
                </button>
            );
        }

        return null;
    };

    getButtonOk = (): JSX.Element | null => {
        if (this.props.params.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
            return null;
        }

        return (
            <button
                id={`btn-confirm-modal-${this.props.id}`}
                className={this.getButtonOkClassName()}
                onClick={() => this.handleButtonOkClick()}
            >
                {translate("general.ok")}
            </button>
        );
    };

    getFooterAlert = (): JSX.Element | null => {
        const { params } = this.props;
        const { footerValidation, validationList } = this.state;

        if (this.props.loading) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_LOADING}
                    caption={translationHelper.getAlertLoaderTranslations(this.props.params.objectType)}
                />
            );
        }

        if (params.bookmarks && params.bookmarks.length && validationList.length) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_VALIDATION}
                    caption={translate("validation.bookmarksValidation")}
                />
            );
        }

        if (footerValidation !== null) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_VALIDATION}
                    caption={footerValidation[propertyConstants.PROPERTY_CAPTION]}
                />
            );
        }

        return null;
    };

    // Render modal

    render(): JSX.Element | null {
        return (
            <div
                className={this.getModalClassName()}
                id={`modal-${this.props.id}`}
                style={{ zIndex: this.props.index }}
            >
                <header className="modal-header">
                    <div className="title">{this.props.params.title}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            this.props.closeModal(this.props.type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">
                    {this.generateBookmarks()}
                    {this.generateModalAlertProperty()}
                </div>
                <footer className="modal-footer">
                    {this.getFooterAlert()}
                    {this.getAdditionalButton()}
                    {this.getButtonOk()}
                    {this.getButtonCancel()}
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    activeModalBookmark: string | null;
    activeScene: Scene;
    loading: boolean;
    sessionUuid: string | null;
    urlRest: string;
    activeSystem: System | null;
    activeZone: Zone | null;
    // colors
    colorActiveList: Array<Color>;
    // export types
    exportTypesList: Array<ExportType>;
    // files
    fileMenuScene: MenuItem | null;
    fileTableConstant: string;
    fileLoading: boolean;
    categoryList: Array<FileCategory>;
    fileTypeList: Array<FileType>;
    productSheetList: Array<File>;
    productSheetActiveList: Array<File>;
    productSheetColumnOrder: Array<propertyConstants.Property>;
    productSheetColumnVisibility: Record<propertyConstants.Property, boolean>;
    productSheetColumnWidth: Record<propertyConstants.Property, number>;
    // formula colorant
    formulaColorantMenuScene: MenuItem | null;
    formulaColorantTableConstant: string;
    formulaColorantColumnOrder: Array<propertyConstants.Property>;
    formulaColorantColumnVisibility: Record<propertyConstants.Property, boolean>;
    formulaColorantColumnWidth: Record<propertyConstants.Property, number>;
    // product
    duplicateSystemProductMenuScene: MenuItem | null;
    duplicateZoneProductMenuScene: MenuItem | null;
    duplicateProductTableConstant: string;
    duplicateProductAllList: Array<DuplicateProduct>;
    duplicateProductColumnOrder: Array<propertyConstants.Property>;
    duplicateProductColumnVisibility: Record<propertyConstants.Property, boolean>;
    duplicateProductColumnWidth: Record<propertyConstants.Property, number>;
    // redlike
    oneTimeAccessRedlikePassword: string | null;
    // unit
    unitList: Array<Unit>;
    activeUnit: Unit | null;
    // web zone
    webZoneList: Array<WebZone>;
    // zone
    zoneMenuScene: MenuItem | null;
    zoneTableConstant: string;
    zoneLoading: boolean;
    zoneAllList: Array<Zone>;
    zoneColumnOrder: Array<propertyConstants.Property>;
    zoneColumnVisibility: Record<propertyConstants.Property, boolean>;
    zoneColumnWidth: Record<propertyConstants.Property, number>;
}>;

export type DispatchType = Readonly<{
    addIndexData(objectType: objectTypes.ObjectType, params: any): void;
    addItem(objectType: objectTypes.ObjectType, params: any): void;
    deleteItem(objectType: objectTypes.ObjectType, params: any): any;
    deleteUploadedFile(fileUuid: string): void;
    generateOneTimeAccessPassword(computerId: string, date: string | null): void;
    updateFileList(objectType: objectTypes.ObjectType, fileList: Array<any>): void;
    duplicateItem(objectType: objectTypes.ObjectType, params: any): void;
    editItem(objectType: objectTypes.ObjectType, params: any): void;
    updateItem(objectType: objectTypes.ObjectType, list: Array<any>): void;
    openModal(type: ModalType, params: any): void;
    savePassword(password: string | null): void;
    setOptions(options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>, save: boolean): any;
    showClientFlashMessage(text?: string): void;
    setActiveModalBookmark(key: string | null): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeModalBookmark: state.navigation.activeModalBookmark,
    activeScene: state.navigation.activeScene,
    loading: state.server.requests.some((request: ServerRequest) => methods.SAVING_MODAL.includes(request.method)),
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    // colors
    colorActiveList: state.color.masterModalActiveList,
    // export types
    exportTypesList: state.export.exportTypes,
    // files
    fileMenuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    fileTableConstant: menuConstants.TABLE_GENERIC_PRODUCT_SHEETS,
    fileLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_FILE_CATEGORIES
    ),
    categoryList: state.fileCategory.allList,
    fileTypeList: state.file.fileFileTypeList,
    productSheetList: state.file.productSheetList,
    productSheetActiveList: state.file.productSheetActiveList,
    productSheetColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_FILES
    ),
    productSheetColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_FILES
    ),
    productSheetColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_FILES
    ),
    // formula colorant
    formulaColorantMenuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_FORMULAS
    ),
    formulaColorantTableConstant: menuConstants.TABLE_MASTER_FORMULA_COLORANTS,
    formulaColorantColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_FORMULA_COLORANTS
    ),
    formulaColorantColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_FORMULA_COLORANTS
    ),
    formulaColorantColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_FORMULA_COLORANTS
    ),
    // product
    duplicateSystemProductMenuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_PRODUCTS
    ),
    duplicateZoneProductMenuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_PRODUCTS
    ),
    duplicateProductTableConstant: menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE,
    duplicateProductAllList: state.product.duplicateAllList,
    duplicateProductColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE
    ),
    duplicateProductColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE
    ),
    duplicateProductColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE
    ),
    // unit
    unitList: state.color.unitAllList,
    activeUnit:
        state.color.unitAllList.find(
            (item: Unit) =>
                optionHelper.getValueFromOptionDictionaryByKey(
                    state.login.options,
                    optionsConstants.OPTION_FORMULA_COLORANT_UNIT
                ) === item[propertyConstants.PROPERTY_NAME]
        ) ?? null,
    // redlike
    oneTimeAccessRedlikePassword: state.user.redlikePassword,
    // web zone
    webZoneList: state.zone.webAllList,
    // zone
    zoneMenuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS
    ),
    zoneTableConstant: menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE,
    zoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_DUPLICATE_ZONES_NO_PARAMS
    ),
    zoneAllList: state.zone.duplicateAllList,
    zoneColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE
    ),
    zoneColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE
    ),
    zoneColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE
    )
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    addIndexData: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.addIndexData(objectType, params)),
    addItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.addItem(objectType, params)),
    deleteItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.deleteItem(objectType, params)),
    deleteUploadedFile: (fileUuid: string): any => dispatch(fileActions.deleteUploadedFile(fileUuid)),
    generateOneTimeAccessPassword: (computerId: string, date: string | null): any =>
        dispatch(userActions.generateOneTimeAccessPassword(computerId, date)),
    updateFileList: (objectType: objectTypes.ObjectType, fileList: Array<any>): any =>
        dispatch(fileActions.updateFileList(objectType, fileList)),
    duplicateItem: (objectType: objectTypes.ObjectType, item: any): any =>
        dispatch(universalObjectActions.duplicateItem(objectType, item)),
    editItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.editItem(objectType, true, params)),
    updateItem: (objectType: objectTypes.ObjectType, list: Array<any>): any =>
        dispatch(universalObjectActions.updateItem(objectType, list)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params)),
    savePassword: (password: string | null): any => dispatch(userActions.savePassword(password)),
    showClientFlashMessage: (text?: string): any => dispatch(notificationActions.saveClientFlashMessage(text)),
    setActiveModalBookmark: (key: string | null): any => dispatch(navigationActions.setActiveModalBookmark(key)),
    setOptions: (options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>, save: boolean): any =>
        dispatch(settingsActions.setDictionaryOptions(null, options, save))
});

export const SimpleObjectModalContainer = connect(mapStateToProps, mapDispatchToProps)(SimpleObjectModal);
