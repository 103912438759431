import * as dataTypeConstants from "../../../constants/dataTypeConstants";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

type Props = {
    className: string;
    editable: boolean;
    required: boolean;
    type: propertyConstants.Property;
    text?: string;
    value: boolean;
    innerRowIndex: number;
    property: propertyConstants.Property;
    allList: Array<any>;
    selectedList?: Array<any>;
    callback: (value: Array<any>) => any;
};

/**
 Used in:
 * Price options modal (price calculation)
 * Import and Export delete modals (status list)
 * Duplication bookmark in Product, System, Zone modals (data type list)
 */
export class ModalCheckboxList extends Component<Props> {
    isEditable = (item: any): boolean => {
        // If ALL checkbox (ID = 0) is visible and selected, other options cannot be changed because they are forced to be selected as well
        const allItemSelected =
            this.props.selectedList?.find(
                (item) => item[propertyConstants.PROPERTY_ID] !== undefined && item[propertyConstants.PROPERTY_ID] === 0
            ) ?? null;
        if (
            allItemSelected &&
            item?.[propertyConstants.PROPERTY_ID] !== undefined &&
            item?.[propertyConstants.PROPERTY_ID] !== 0
        ) {
            return false;
        }

        return this.props.editable;
    };

    getClassName = (editable: boolean, item: Record<string, any>): string => {
        let newClassName = this.props.className;

        if (!editable) {
            newClassName += " disabled";
        }

        // Indent others then ALL checkbox
        const containsAllCheckbox =
            this.props.allList.find((item) => item[propertyConstants.PROPERTY_ID] === 0) ?? null;
        if (containsAllCheckbox && item?.[propertyConstants.PROPERTY_ID] !== 0) {
            newClassName += " indented";
        }

        return newClassName;
    };

    handleDataTypeListCheckChange = (list: Array<any>, item: Record<string, any>): Array<any> => {
        const { allList } = this.props;

        const colorantItem =
            allList.find(
                (listItem) => listItem[propertyConstants.PROPERTY_ID] === dataTypeConstants.DATA_TYPE_COLORANT
            ) ?? null;
        const isColorantChecked =
            list.find(
                (item) => item[propertyConstants.PROPERTY_ID] === colorantItem?.[propertyConstants.PROPERTY_ID]
            ) ?? false;
        const baseItem =
            allList.find((listItem) => listItem[propertyConstants.PROPERTY_ID] === dataTypeConstants.DATA_TYPE_BASE) ??
            null;
        const isBaseChecked =
            list.find((item) => item[propertyConstants.PROPERTY_ID] === baseItem?.[propertyConstants.PROPERTY_ID]) ??
            false;
        const productItem =
            allList.find(
                (listItem) => listItem[propertyConstants.PROPERTY_ID] === dataTypeConstants.DATA_TYPE_PRODUCT
            ) ?? null;
        const isProductChecked =
            list.find((item) => item[propertyConstants.PROPERTY_ID] === productItem?.[propertyConstants.PROPERTY_ID]) ??
            false;
        const baseInProductItem =
            allList.find(
                (listItem) => listItem[propertyConstants.PROPERTY_ID] === dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT
            ) ?? null;
        const isBaseInProductChecked =
            list.find(
                (item) => item[propertyConstants.PROPERTY_ID] === baseInProductItem?.[propertyConstants.PROPERTY_ID]
            ) ?? false;

        switch (item?.[propertyConstants.PROPERTY_ID] ?? null) {
            case dataTypeConstants.DATA_TYPE_COLORANT_PACKAGE: {
                if (!isColorantChecked && colorantItem) {
                    list.push(colorantItem);
                }

                break;
            }
            case dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT: {
                if (!isBaseChecked && baseItem) {
                    list.push(baseItem);
                }

                if (!isProductChecked && productItem) {
                    list.push(productItem);
                }

                break;
            }
            case dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT_PACKAGE: {
                if (!isBaseChecked && baseItem) {
                    list.push(baseItem);
                }

                if (!isProductChecked && productItem) {
                    list.push(productItem);
                }

                if (!isBaseInProductChecked && baseInProductItem) {
                    list.push(baseInProductItem);
                }

                break;
            }
            default: {
                break;
            }
        }

        return list;
    };

    handleDataTypeListUncheckChange = (list: Array<any>, item: Record<string, any>): Array<any> => {
        switch (item?.[propertyConstants.PROPERTY_ID] ?? null) {
            case dataTypeConstants.DATA_TYPE_COLORANT: {
                return list.filter(
                    (listItem) =>
                        listItem[propertyConstants.PROPERTY_ID] !== dataTypeConstants.DATA_TYPE_COLORANT_PACKAGE
                );
            }
            case dataTypeConstants.DATA_TYPE_BASE:
            case dataTypeConstants.DATA_TYPE_PRODUCT: {
                return list.filter(
                    (listItem) =>
                        listItem[propertyConstants.PROPERTY_ID] !== dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT &&
                        listItem[propertyConstants.PROPERTY_ID] !== dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT_PACKAGE
                );
            }
            case dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT: {
                return list.filter(
                    (listItem) =>
                        listItem[propertyConstants.PROPERTY_ID] !== dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT_PACKAGE
                );
            }
            default: {
                return list;
            }
        }
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>, item: Record<string, any>): void => {
        const { allList, type, property, selectedList, value, callback } = this.props;

        // Handling price calculation checkboxes in Price options
        if (type === propertyConstants.PROPERTY_VALUE) {
            item[type] = event.target.checked;
            callback(allList);
            return;
        }

        // Handling status lists in Import and Export delete modals
        // Handling data type lists in Product, System, Zone duplication modals
        // If value = true, it is changing checked to unchecked
        if (type === propertyConstants.PROPERTY_VALUE_ENUM && selectedList) {
            // Handling ALL checkbox (ID = 0 but not undefined), all other options are checked/unchecked as well
            if (item[propertyConstants.PROPERTY_ID] !== undefined && item[propertyConstants.PROPERTY_ID] === 0) {
                callback(value ? [] : allList);
                return;
            }

            // Handle normal values, take selected and either add or remove currently changed item
            let newList = selectedList;
            if (value) {
                newList = newList.filter(
                    (listItem) => listItem[propertyConstants.PROPERTY_ID] !== item[propertyConstants.PROPERTY_ID]
                );
            } else {
                newList.push(item);
            }

            // In duplication modals, more items are changed at once, therefore we need to make some more adjustments
            if (property === propertyConstants.PROPERTY_DATA_TYPE_LIST) {
                if (value) {
                    newList = this.handleDataTypeListUncheckChange(newList, item);
                } else {
                    newList = this.handleDataTypeListCheckChange(newList, item);
                }
            }

            // If all options except ALL are checked, check also ALL
            const uncheckedItems = allList.filter(
                (item) =>
                    !newList.some(
                        (newItem) => item[propertyConstants.PROPERTY_ID] === newItem[propertyConstants.PROPERTY_ID]
                    )
            );
            if (uncheckedItems.length === 1 && uncheckedItems?.[0]?.[propertyConstants.PROPERTY_ID] === 0) {
                newList = allList;
            }

            callback(newList);
        }
    };

    render(): JSX.Element | null {
        const { allList } = this.props;
        const item = allList?.[this.props.innerRowIndex] ?? null;

        if (item) {
            const editable = this.isEditable(item);

            return (
                <div className={this.getClassName(editable, item)}>
                    <label className={"checkbox-label"}>
                        {this.props.text ?? ""}
                        <input
                            type={"checkbox"}
                            name={"show"}
                            className={"checkbox"}
                            checked={this.props.value}
                            readOnly={!editable}
                            disabled={!editable}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(event, item)
                            }
                        />
                        <span className={"checkmark"}></span>
                    </label>
                </div>
            );
        }

        return null;
    }
}
