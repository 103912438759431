import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { ModalSelectbox } from "../inputs/ModalSelectbox";
import { TableFilterInput } from "./components/TableFilterInput";
import { TableHeading } from "../../../types/tableHeading";

import { connect } from "react-redux";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    headings: Array<TableHeading>;
    modalId?: number;
    isVisible: boolean;
    searchParams: Record<propertyConstants.Property, string>;
    setSearch: (objectType: objectTypes.ObjectType, search: string | null, column?: string) => any;
};

type Props = OwnProps & ContainerProps;

export class TableFilterRow extends Component<Props> {
    getTextFilter = (heading: TableHeading): JSX.Element => {
        return (
            <div
                className={`td ${heading[tableConstants.TABLE_HEADING_NAME]}`}
                key={heading[tableConstants.TABLE_HEADING_NAME]}
            >
                <TableFilterInput
                    objectType={this.props.objectType}
                    heading={heading}
                    searchValue={this.props.searchParams?.[heading[tableConstants.TABLE_HEADING_NAME]] ?? ""}
                    setSearch={this.props.setSearch}
                />
            </div>
        );
    };

    getSelectboxFilter = (heading: TableHeading): JSX.Element => {
        // TODO type for selectbox options
        const options: Array<{
            key: string;
            value: string;
        }> = generalHelper.getOptionsForSelectbox(
            this.props.objectType,
            heading[tableConstants.TABLE_HEADING_ENUM],
            heading[tableConstants.TABLE_HEADING_NAME]
        );

        // TODO default value create in ModalSelectbox, for not required properties?
        options.unshift({
            key: "",
            value: translate("general.showAll")
        });
        // TODO callback setSearch should take also number as parameter?
        return (
            <div
                key={heading[tableConstants.TABLE_HEADING_NAME]}
                className={`td ${heading[tableConstants.TABLE_HEADING_NAME]} select-cell`}
            >
                <ModalSelectbox
                    className={"table-select"}
                    modalId={this.props.modalId}
                    editable={true}
                    required={true}
                    value={this.props.searchParams?.[heading[tableConstants.TABLE_HEADING_NAME]] ?? options[0].key}
                    options={options}
                    autoselect={false}
                    callback={(event: any, value: string): void => {
                        this.props.setSearch(
                            this.props.objectType,
                            !isNaN(Number(value)) && value !== null && value !== "" ? value : "",
                            heading[tableConstants.TABLE_HEADING_NAME]
                        );
                    }}
                />
            </div>
        );
    };

    render(): JSX.Element {
        const cells: Array<JSX.Element> = [];
        let filterableColumnCount = 0;

        // Filter row needs to be set visible in Table options
        if (this.props.isVisible) {
            for (const heading of this.props.headings) {
                if (heading[tableConstants.TABLE_HEADING_VISIBILITY]) {
                    if (heading[tableConstants.TABLE_HEADING_FILTERABLE]) {
                        filterableColumnCount++;
                        // filterable column, insert either selectbox or text input
                        if (
                            propertyConstants.TABLE_FILTER_ROW_SELECT_PROPERTIES.includes(
                                heading[tableConstants.TABLE_HEADING_NAME]
                            )
                        ) {
                            cells.push(this.getSelectboxFilter(heading));
                        } else {
                            cells.push(this.getTextFilter(heading));
                        }
                    } else {
                        // column is not filterable, insert empty column
                        cells.push(
                            <div
                                className={`td ${heading[tableConstants.TABLE_HEADING_NAME]}`}
                                key={heading[tableConstants.TABLE_HEADING_NAME]}
                            >
                                {}
                            </div>
                        );
                    }
                }
            }
        }

        return <div className="tr table-filter">{filterableColumnCount > 0 ? cells : []}</div>;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableFilterRowContainer = connect(mapStateToProps, mapDispatchToProps)(TableFilterRow);
