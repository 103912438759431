import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./resources/styles";
import "./resources/modules";

import * as localConfig from "./constants/localConfig";
import * as serviceWorker from "./serviceWorker";

import { ConnectedRouter, connectRouter, routerMiddleware } from "connected-react-router";
import { History, createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";

import { AppContainer } from "./containers/App";
import { ErrorContainer } from "./components/Error";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import ReactGoogleAnalytics from "react-ga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createEpicMiddleware } from "redux-observable";
import epics from "./epics";
import reducers from "./reducers";
import socketMiddleware from "./socketMiddleware";
import thunk from "redux-thunk";

// TODO refactor
const history = createBrowserHistory();
const composeEnhancers = composeWithDevTools({});
const rootReducer = (history: History) => combineReducers({ router: connectRouter(history), ...reducers });
const epicMiddleware = createEpicMiddleware();
const middleware = (history: History) =>
    applyMiddleware(socketMiddleware, routerMiddleware(history), epicMiddleware, thunk);
const enhancer = (history: History) =>
    process.env.NODE_ENV === "production" ? middleware(history) : composeEnhancers(middleware(history));
const store = createStore(rootReducer(history), enhancer(history));

epicMiddleware.run(epics);

if (localConfig.TRACKING_ID) {
    ReactGoogleAnalytics.initialize(localConfig.TRACKING_ID);
    history.listen((location) => {
        ReactGoogleAnalytics.pageview(location.pathname);
    });
}

try {
    window.onload = (): any => {
        ReactDOM.render(
            // TODO <React.StrictMode> a ErrorBoundary
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <AppContainer />
                </ConnectedRouter>
            </Provider>,
            // </React.StrictMode>,
            document.getElementById("root")
        );
    };
} catch (error) {
    window.onload = (): any => {
        ReactDOM.render(
            <Provider store={store}>
                <ErrorContainer />
            </Provider>,
            document.getElementById("root")
        );
    };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
