export class MarginType {
    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

export function mapMarginType(id: number, name: string): MarginType | null {
    try {
        return new MarginType(id, name);
    } catch (e) {
        return null;
    }
}
