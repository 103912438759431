import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as translationHelper from "../helpers/translationHelper";

import React, { Component } from "react";
import { saveItems, setActiveItems, setSearch } from "../actions/universalObjectActions";
import { AppState } from "../reducers";
import { Dispatch } from "redux";
import { ModalType } from "../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { Scene } from "../constants/navigationTypes";
import { TableContainer } from "./Table";

import { connect } from "react-redux";

import imgSearch from "../resources/img/icons/search.svg";

type TableBoxProps = {
    objectType: objectTypes.ObjectType;
    tableConstant: string;
    defaultData: any;
    defaultActiveList: Array<any>;
    defaultHeadings: any;
    defaultSortingAsc: boolean;
    defaultSortingCriterion: propertyConstants.Property | null;
    defaultSearch: string | null;
    defaultSearchParams: Record<propertyConstants.Property, string>;
    updatedActiveList: Array<any>;
    updatedData: any;
    updatedHeadings: any;
    updatedSortingAsc: boolean;
    updatedSortingCriterion: propertyConstants.Property | null;
    dataPrivileges: any;
    classNameTable: any;
    loading: any;
    modalType?: ModalType;
};

type OwnProps = {
    activeScene: Scene;
    setActive(objectType: objectTypes.ObjectType, items: Array<any>): any;
    saveList(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setSearch(objectType: objectTypes.ObjectType, search: string, column?: string): any;
};

type Props = TableBoxProps & OwnProps;

type State = {
    defaultSearch: any;
    updatedSearch: any;
};
// TODO check whole file + dispatchType
class TableBox extends Component<Props, State> {
    state: State = {
        defaultSearch: null,
        updatedSearch: null
    };

    _oppositeObjectType = objectTypeHelper.getOppositeMixedObjectType(this.props.objectType);

    handleRightArrowClick = (): void => {
        const { objectType, setActive, saveList, defaultActiveList, updatedData } = this.props;

        let appendList = [];
        // product data can be inserted multiple times, so we need to create a unique ID for universal Table component
        if (objectType === objectTypes.MASTER_SYSTEM_PRODUCT || objectType === objectTypes.SYSTEM_ZONE_PRODUCT) {
            for (const item of defaultActiveList) {
                appendList.push({
                    ...item,
                    [propertyConstants.PROPERTY_PARENT_ID]: item[propertyConstants.PROPERTY_ID],
                    [propertyConstants.PROPERTY_ID]: `${item[propertyConstants.PROPERTY_ID]}-${new Date().valueOf()}`
                });
            }
        } else {
            appendList = defaultActiveList.filter(
                (item1) => !updatedData.some((item2: { id: number }) => item1.id === item2.id)
            );
        }

        if (this._oppositeObjectType) {
            saveList(this._oppositeObjectType, updatedData.concat(appendList));
            setActive(this._oppositeObjectType, appendList.length ? [appendList[0]] : []);
        } else {
            setActive(objectType, []);
        }
    };

    handleLeftArrowClick = (): void => {
        const { updatedData, defaultData, updatedActiveList, objectType, modalType, setActive, saveList } = this.props;
        let filteredData: Array<any> = [];

        for (const active of updatedActiveList) {
            filteredData = updatedData.filter((item: any) => item.id !== active.id);

            if (this._oppositeObjectType) {
                saveList(this._oppositeObjectType, filteredData);
                setActive(this._oppositeObjectType, []);
                if (
                    modalType &&
                    !defaultData.some((item: any) => item.id === active.id) &&
                    modalTypes.MODALS_INPUTS_WITH_TABLE_BOX.includes(modalType)
                ) {
                    const updatedDefaultData = defaultData.concat(active);
                    saveList(objectType, updatedDefaultData);
                }
            }
        }
    };

    handleSearchDefaultInputChange = (event: any): void => {
        const { setSearch, objectType } = this.props;
        const value = event.target.value !== "" ? event.target.value : null;

        if (objectTypes.CUSTOM_SEARCH_OBJECT_TYPES.includes(objectType)) {
            const value = event.target.value !== "" ? event.target.value : null;
            if (this._oppositeObjectType) {
                this.setState({
                    defaultSearch: value
                });
            }
        } else {
            setSearch(objectType, value);
        }
    };

    handleSearchUpdatedInputChange = (event: any): void => {
        const value = event.target.value !== "" ? event.target.value : null;

        if (this._oppositeObjectType) {
            this.setState({
                updatedSearch: value
            });
        }
    };

    getDefaultDataByObjectType = (defaultData: any): any => {
        const { objectType, updatedData } = this.props;
        const data = [];

        if (objectType === objectTypes.MASTER_SYSTEM_PRODUCT || objectType === objectTypes.SYSTEM_ZONE_PRODUCT) {
            return defaultData;
        }

        for (const defaultItem of defaultData) {
            let item = null;
            for (const updatedItem of updatedData) {
                if (defaultItem[propertyConstants.PROPERTY_ID] === updatedItem[propertyConstants.PROPERTY_ID]) {
                    item = updatedItem;
                }
            }
            if (item === null) {
                data.push(defaultItem);
            }
        }

        return data;
    };

    getTableClassName = (): string => {
        if (navigationTypes.ZONE_SCENES_LIST.includes(this.props.activeScene)) {
            return "height-95 zone";
        }

        return "height-95";
    };

    getDefaultDataTable = (): JSX.Element => {
        return (
            <div className={this.getTableClassName()}>
                <TableContainer
                    className={this.props.classNameTable}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.props.defaultHeadings}
                    multiSelect={true}
                    data={this.getFilteredDefaultData()}
                    updatedData={this.props.updatedData}
                    activeData={this.props.defaultActiveList}
                    disableColumnResizing={true}
                    modalProperties={{}}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.DEFAULT_ROW_COUNT}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={this.props.defaultSearch}
                    searchParams={this.props.defaultSearchParams}
                    sortingAsc={this.props.defaultSortingAsc}
                    sortingCriterion={this.props.defaultSortingCriterion}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                />
            </div>
        );
    };

    getFilteredDefaultData = (): Array<any> => {
        const { defaultData } = this.props;
        const { defaultSearch } = this.state;
        let filteredData: Array<any> = [...defaultData];

        if (defaultSearch) {
            filteredData = defaultData.filter(
                (item: any) =>
                    (item[propertyConstants.PROPERTY_NAME_MASTER] &&
                        item[propertyConstants.PROPERTY_NAME_MASTER]
                            .toLowerCase()
                            .includes(defaultSearch.toLowerCase())) ||
                    (item[propertyConstants.PROPERTY_NAME_SYSTEM] &&
                        item[propertyConstants.PROPERTY_NAME_SYSTEM]
                            .toLowerCase()
                            .includes(defaultSearch.toLowerCase())) ||
                    (item[propertyConstants.PROPERTY_NAME] &&
                        item[propertyConstants.PROPERTY_NAME].toLowerCase().includes(defaultSearch.toLowerCase()))
            );
        }

        return this.getDefaultDataByObjectType(filteredData);
    };

    getFilteredUpdateData = (): Array<any> => {
        const { updatedData } = this.props;
        const { updatedSearch } = this.state;
        let filteredData: Array<any> = [];

        if (updatedSearch) {
            filteredData = updatedData.filter(
                (item: any) =>
                    (item[propertyConstants.PROPERTY_NAME_MASTER] &&
                        item[propertyConstants.PROPERTY_NAME_MASTER]
                            .toLowerCase()
                            .includes(updatedSearch.toLowerCase())) ||
                    (item[propertyConstants.PROPERTY_NAME_SYSTEM] &&
                        item[propertyConstants.PROPERTY_NAME_SYSTEM]
                            .toLowerCase()
                            .includes(updatedSearch.toLowerCase())) ||
                    (item[propertyConstants.PROPERTY_NAME] &&
                        item[propertyConstants.PROPERTY_NAME].toLowerCase().includes(updatedSearch.toLowerCase()))
            );
        }

        return filteredData;
    };

    getUpdatedDataTable = (): JSX.Element | null => {
        if (this._oppositeObjectType) {
            return (
                <div className={this.getTableClassName()}>
                    <TableContainer
                        className={this.props.classNameTable}
                        tableConstant={""}
                        objectType={this._oppositeObjectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={false}
                        headings={this.props.updatedHeadings}
                        data={this.state.updatedSearch ? this.getFilteredUpdateData() : this.props.updatedData}
                        activeData={this.props.updatedActiveList}
                        disableColumnResizing={true}
                        modalProperties={{}}
                        offset={tableConstants.DEFAULT_OFFSET}
                        page={tableConstants.DEFAULT_PAGE}
                        rowCount={tableConstants.DEFAULT_ROW_COUNT}
                        rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                        search={tableConstants.DEFAULT_SEARCH}
                        searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                        showFilterRow={false}
                        showGlobalSearch={false}
                        sortingAsc={this.props.updatedSortingAsc}
                        sortingCriterion={this.props.updatedSortingCriterion}
                        totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    />
                </div>
            );
        }

        return null;
    };

    getDefaultGlobalSearch = (): JSX.Element | null => {
        if (objectTypeHelper.isObjectTypePropagateRedlikeUser(this.props.objectType)) {
            return null;
        }

        return (
            <div className="search-input">
                <input className="pull-right" onChange={(event): void => this.handleSearchDefaultInputChange(event)} />
                <ReactSVG className="search-icon" src={imgSearch} />
            </div>
        );
    };

    getUpdatedGlobalSearch = (): JSX.Element | null => {
        if (objectTypeHelper.isObjectTypePropagateRedlikeUser(this.props.objectType)) {
            return null;
        }

        return (
            <div className="search-input">
                <input className="pull-right" onChange={(event): void => this.handleSearchUpdatedInputChange(event)} />
                <ReactSVG className="search-icon" src={imgSearch} />
            </div>
        );
    };

    render(): JSX.Element {
        const { objectType } = this.props;

        return (
            <div className="modal-content-tables">
                <div className="left-part">
                    <div className="select-search-container">
                        <span className="pull-left ellipsis">
                            {translationHelper.getSelectFromItemTranslation(objectType)}
                        </span>
                        {this.getDefaultGlobalSearch()}
                    </div>
                    {this.getDefaultDataTable()}
                </div>
                <div className="button-container">
                    <button className="btn btn-info icon-button" onClick={() => this.handleRightArrowClick()}>
                        <span className="caret caret-right"></span>
                    </button>
                    <button className="btn btn-info icon-button" onClick={() => this.handleLeftArrowClick()}>
                        <span className="caret caret-left"></span>
                    </button>
                </div>
                <div className="right-part">
                    <div className="select-search-container">
                        <span className="pull-left ellipsis">
                            {translationHelper.getSelectedItemsTranslation(objectType)}
                        </span>
                        {this.getUpdatedGlobalSearch()}
                    </div>
                    {this.getUpdatedDataTable()}
                </div>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    activeScene: Scene;
}>;

export type DispatchType = Readonly<{
    saveList(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setActive(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setSearch(objectType: objectTypes.ObjectType, search: string, column?: string): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    saveList: (objectType: objectTypes.ObjectType, items: Array<any>): any => {
        dispatch(saveItems(objectType, items));
    },
    setActive: (objectType: objectTypes.ObjectType, items: Array<any>): any => {
        dispatch(setActiveItems(objectType, items));
    },
    setSearch: (objectType: objectTypes.ObjectType, search: string, column?: string): any => {
        dispatch(setSearch(objectType, search, column));
    }
});

export const TableBoxContainer = connect(mapStateToProps, mapDispatchToProps)(TableBox);
