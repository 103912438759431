import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as modalTypes from "../../../constants/modalTypes";
import * as navigationActions from "../../../actions/navigationActions";
import * as objectTypes from "../../../constants/objectTypes";
import * as settingsActions from "../../../actions/optionActions";

import { AppState } from "../../../reducers";
import { DataItem } from "../../../types/dataItem";
import { Dispatch } from "redux";
import { Option } from "../../../types/option";
import { OptionsScene } from "../../../components/scenes/header/OptionsScene";
import { Scene } from "../../../constants/navigationTypes";
import { ServerRequest } from "../../../types/serverRequest";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    dataPrivileges: Array<DataItem>;
    globalOptionConstant: string;
    globalOptionList: Array<Option>;
    optionLoading: boolean;
    privileges: any;
    scene: Scene;
    userOptionConstant: string;
    userOptionList: Array<Option>;
}>;

export type DispatchType = Readonly<{
    openModal(modalType: modalTypes.ModalType, params: any): void;
    setActiveScene: (scene: Scene) => void;
    setOptions(
        objectType: objectTypes.ObjectType,
        options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>,
        save: boolean
    ): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    dataPrivileges: state.login.dataPrivileges,
    globalOptionConstant: menuConstants.PAGE_OPTIONS_GLOBAL_SETTINGS,
    globalOptionList: state.options.globalOptionList,
    optionLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_USER_OPTIONS ||
            request.method === methods.METHOD_GET_ALL_GLOBAL_OPTIONS
    ),
    privileges: state.login.privileges,
    scene: state.navigation.activeScene,
    userOptionConstant: menuConstants.PAGE_OPTIONS_USER_SETTINGS,
    userOptionList: state.options.userOptionList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: modalTypes.ModalType, params: any): any =>
        dispatch(navigationActions.navigationOpenModal(type, params)),
    setActiveScene: (scene: Scene): any => dispatch(navigationActions.navigationSetScene(scene)),
    setOptions: (
        objectType: objectTypes.ObjectType,
        options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>,
        save: boolean
    ): any => dispatch(settingsActions.setDictionaryOptions(objectType, options, save))
});

export const OptionsSceneContainer = connect(mapStateToProps, mapDispatchToProps)(OptionsScene);
