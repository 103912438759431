import * as colorActions from "../../actions/colorActions";
import * as colorHelper from "../../helpers/colorHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as inputConstants from "../../constants/inputConstants";
import * as menuConstants from "../../constants/menuConstants";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as validationHelper from "../../helpers/validationHelper";

import { ColorData, createEmptyColorDataFromSpectroType } from "../../types/colorData";
import React, { Component } from "react";
import { ReflectanceData, mapReflectanceData } from "../../types/reflectanceData";
import { generateWavelengthData, generateWavelengthHeadings } from "../../types/wavelength";
import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { LineGraph } from "../general/graph/LineGraph";
import { MenuItem } from "../../types/menu";
import { ModalLabelInputButtonContainer } from "../general/modal/ModalLabelInputButton";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { ModalType } from "../../constants/modalTypes";
import { Option } from "../../types/option";
import { Scene } from "../../constants/navigationTypes";
import { SpectralSettingsTable } from "./tables/SpectralSettingsTable";
import { SpectroType } from "../../types/spectroType";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type OwnProps = StateProps & ContainerProps;

type ContainerProps = {
    modalId: number;
    objectType: objectTypes.ObjectType;
    objectData: any;
    callbackChange: (property: propertyConstants.Property, value: any) => any;
};

type Props = OwnProps & DispatchType;

export class SpectralSettingsBookmark extends Component<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] !==
            prevProps.objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
        ) {
            const modal = document.getElementById("modal-" + this.props.modalId);
            const scrolls = modal?.getElementsByClassName("slim-scroll");
            if (scrolls !== undefined) {
                for (let i = 0; i < scrolls.length; i++) {
                    scrolls[i].scrollTo(0, 0);
                }
            }
        }
    }

    generateEditWavelengthModalParams = (): any => {
        const { optionWavelength } = this.props;

        if (optionWavelength && optionWavelength[propertyConstants.PROPERTY_VALUE]) {
            const activeData = generateWavelengthData(optionWavelength[propertyConstants.PROPERTY_VALUE]);

            if (activeData.length) {
                return {
                    title: translate("spectro.wavelengthSettings"),
                    type: modalTypes.ADD_MODAL,
                    objectType: objectTypes.WAVELENGTH,
                    data: activeData[0],
                    headings: generateWavelengthHeadings(
                        objectTypes.WAVELENGTH,
                        optionsConstants.INTERVAL_LIST,
                        [
                            propertyConstants.PROPERTY_INTERVAL,
                            propertyConstants.PROPERTY_BEGIN_VALUE,
                            propertyConstants.PROPERTY_END_VALUE
                        ],
                        [
                            propertyConstants.PROPERTY_INTERVAL,
                            propertyConstants.PROPERTY_BEGIN_VALUE,
                            propertyConstants.PROPERTY_END_VALUE
                        ],
                        [
                            propertyConstants.PROPERTY_INTERVAL,
                            propertyConstants.PROPERTY_BEGIN_VALUE,
                            propertyConstants.PROPERTY_END_VALUE
                        ],
                        [
                            propertyConstants.PROPERTY_INTERVAL,
                            propertyConstants.PROPERTY_BEGIN_VALUE,
                            propertyConstants.PROPERTY_END_VALUE
                        ],
                        [],
                        tableConstants.EMPTY_COLUMN_WIDTH
                    )
                };
            }
        }

        return null;
    };

    getSelectedColorData = (): ColorData | null => {
        const { objectData } = this.props;

        return (
            objectData?.[propertyConstants.PROPERTY_DATA]?.find(
                (item: any) =>
                    item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                    objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
            ) || null
        );
    };

    getConvertReflToLab = (selectedColorData: ColorData | null): JSX.Element => {
        const { objectData, convertColorReflToLab } = this.props;

        if (objectData && selectedColorData) {
            const reflDataWithValue = selectedColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
                (item: ReflectanceData) => item[propertyConstants.PROPERTY_VALUE] !== null
            );

            if (
                // The length should be at least 2, otherwise server error is fired
                reflDataWithValue.length > 1 &&
                validationHelper.areReflectanceDataValid(selectedColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA])
            ) {
                return (
                    <button
                        className="btn-info btn-md"
                        onClick={(): void =>
                            convertColorReflToLab(
                                generalHelper.getReflDataForServer(
                                    selectedColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA]
                                )
                            )
                        }
                    >
                        {translate("color.calculateLabFromReflectance")}
                    </button>
                );
            }
        }

        return <button className="btn-info btn-md disabled">{translate("color.calculateLabFromReflectance")}</button>;
    };

    handleLabToRgblick = (selectedColorData: ColorData): any => {
        this.props.convertColorLabToRgb(
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_L],
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_A],
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_B]
        );
    };

    getConvertLabToRgb = (selectedColorData: ColorData | null): JSX.Element => {
        const { objectData } = this.props;

        if (
            objectData &&
            selectedColorData &&
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_L] !== null &&
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_A] !== null &&
            selectedColorData[propertyConstants.PROPERTY_SPECTRO_B] !== null
        ) {
            return (
                <button className="btn-info btn-md" onClick={(): void => this.handleLabToRgblick(selectedColorData)}>
                    {translate("color.calculateRgbFromLab")}
                </button>
            );
        }

        return <button className="btn-info btn-md disabled">{translate("color.calculateRgbFromLab")}</button>;
    };

    getUpdatedLchFromLab = (item: ColorData): any => {
        if (
            item[propertyConstants.PROPERTY_SPECTRO_L] !== null &&
            item[propertyConstants.PROPERTY_SPECTRO_A] !== null &&
            item[propertyConstants.PROPERTY_SPECTRO_B] !== null
        ) {
            const lch = colorHelper.calculateLchFromLab(
                item[propertyConstants.PROPERTY_SPECTRO_A],
                item[propertyConstants.PROPERTY_SPECTRO_B]
            );

            if (lch.length === 2) {
                item[propertyConstants.PROPERTY_SPECTRO_C] = lch[0];
                item[propertyConstants.PROPERTY_SPECTRO_H] = lch[1];
            }
        }

        return item;
    };

    getUpdatedColorData = (property: propertyConstants.Property, selectedColorData: ColorData, value: any): any => {
        const { objectData } = this.props;

        // TODO Needs simplification
        return objectData[propertyConstants.PROPERTY_DATA].map((item: any) => {
            if (
                item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
            ) {
                item[property] = value;
            }
            return this.getUpdatedLchFromLab(item);
        });
    };

    getReflectanceData = (): Array<ReflectanceData> => {
        const { optionWavelength } = this.props;
        const reflDataList: Array<ReflectanceData> = [];
        let reflData: ReflectanceData | null;

        if (optionWavelength && optionWavelength[propertyConstants.PROPERTY_VALUE]) {
            const wavelengthList = colorHelper.getWavelengthList(
                optionWavelength[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_INTERVAL],
                optionWavelength[propertyConstants.PROPERTY_VALUE].begin_value,
                optionWavelength[propertyConstants.PROPERTY_VALUE].end_value
            );

            for (const wavelengthItem of wavelengthList) {
                reflData = mapReflectanceData(wavelengthItem, null);

                if (reflData) {
                    reflDataList.push(reflData);
                }
            }
        }

        return reflDataList;
    };

    getWavelengthSettingsModal = (): JSX.Element | null => {
        const { objectData } = this.props;

        if (objectData && this.props.objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE) {
            return (
                <button
                    className="btn-info btn-md"
                    onClick={(): void =>
                        this.props.openModal(
                            modalTypes.MODAL_WAVELENGTH_SETTINGS,
                            this.generateEditWavelengthModalParams()
                        )
                    }
                >
                    {translate("spectro.wavelengthSettings")}
                </button>
            );
        }

        return null;
    };

    getAddSpectroType = (): JSX.Element | null => {
        const { objectType, objectData, optionWavelength } = this.props;

        if (
            objectData &&
            objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE &&
            optionWavelength?.[propertyConstants.PROPERTY_VALUE]
        ) {
            const oldColorDataList = objectData[propertyConstants.PROPERTY_DATA];

            return (
                <button
                    className="btn-info btn-md"
                    onClick={(): void =>
                        this.props.openModal(modalTypes.MODAL_SPECTRO_TYPES_TABLE, {
                            returnAction: (spectroType: SpectroType | null): void => {
                                if (spectroType) {
                                    const newColorData = createEmptyColorDataFromSpectroType(
                                        spectroType,
                                        this.getReflectanceData()
                                    );
                                    const filteredColorDataList =
                                        oldColorDataList?.filter(
                                            (item: ColorData) =>
                                                item[propertyConstants.PROPERTY_ID] !==
                                                spectroType[propertyConstants.PROPERTY_ID]
                                        ) || [];
                                    const updateColorDataList = filteredColorDataList.concat(newColorData);

                                    this.props.updateColorData(
                                        objectTypes.MASTER_COLOR_MODAL,
                                        updateColorDataList,
                                        spectroType[propertyConstants.PROPERTY_ID]
                                    );
                                }
                            }
                        })
                    }
                >
                    {translate("general.add")}
                </button>
            );
        }

        return null;
    };

    getDeleteSpectroType = (selectedColorData: ColorData | null): JSX.Element | null => {
        const { objectData } = this.props;

        if (objectData && this.props.objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE && selectedColorData) {
            return (
                <button
                    className="btn-info btn-md"
                    onClick={(): void => {
                        const updatedColorDataList = objectData[propertyConstants.PROPERTY_DATA].filter(
                            (item: ColorData) =>
                                item[propertyConstants.PROPERTY_ID] !== selectedColorData[propertyConstants.PROPERTY_ID]
                        );

                        this.props.updateColorData(
                            objectTypes.MASTER_COLOR_MODAL,
                            updatedColorDataList,
                            updatedColorDataList.length
                                ? updatedColorDataList[0][propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
                                : null
                        );
                    }}
                >
                    {translate("general.delete")}
                </button>
            );
        }

        return null;
    };

    getSpectroSelectbox = (): JSX.Element | null => {
        const { objectType, objectData } = this.props;

        if (objectData && objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE) {
            const oldColorDataList = objectData[propertyConstants.PROPERTY_DATA];

            return (
                <ModalSelectbox
                    className={"modal-select master"}
                    editable={objectData?.[propertyConstants.PROPERTY_DATA]?.length}
                    required={true}
                    value={objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] || ""}
                    options={generalHelper.getOptionsForSelectbox(
                        objectType,
                        objectData[propertyConstants.PROPERTY_DATA]
                    )}
                    autoselect={false}
                    readonlyCaption={translate("color.noSpectralData")}
                    callback={(event: any, value: string): void => {
                        this.props.updateColorData(
                            objectTypes.MASTER_COLOR_MODAL,
                            oldColorDataList,
                            !isNaN(Number(value)) && value !== null && value !== "" ? Number(value) : null
                        );
                    }}
                />
            );
        }

        return null;
    };

    getLabInput = (
        property: propertyConstants.Property,
        selectedColorData: any, // TODO ColorData | null
        labelCaption: string
    ): JSX.Element | null => {
        const { modalId, objectType, objectData, callbackChange } = this.props;

        if (objectData && objectData[propertyConstants.PROPERTY_DATA] && selectedColorData) {
            return (
                <ModalLabelInputButtonContainer
                    modalId={modalId}
                    type={tableConstants.TABLE_TYPE_REAL_NUMBER}
                    objectType={objectType}
                    editable={true}
                    required={false}
                    labelCaption={labelCaption}
                    inputClassName="type-input"
                    inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                    inputName={property}
                    inputValue={selectedColorData[property]}
                    inputCallbackChange={(value: any): void => {
                        callbackChange(
                            propertyConstants.PROPERTY_DATA,
                            this.getUpdatedColorData(property, selectedColorData, value)
                        );
                    }}
                    inputCallbackBlur={(value: any): void => {
                        this.props.updateColorData(
                            objectTypes.MASTER_COLOR_MODAL,
                            this.getUpdatedColorData(property, selectedColorData, value),
                            objectData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
                        );
                    }}
                />
            );
        }

        return (
            <ModalLabelInputButtonContainer
                modalId={modalId}
                type={tableConstants.TABLE_TYPE_REAL_NUMBER}
                objectType={objectType}
                editable={false}
                required={false}
                labelCaption={labelCaption}
                inputClassName="type-input"
                inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                inputName={property}
            />
        );
    };

    getLabSection = (selectedColorData: ColorData | null): JSX.Element => {
        return (
            <div className="card transparent">
                <div className="row">
                    {this.getLabInput(propertyConstants.PROPERTY_SPECTRO_L, selectedColorData, "L:")}
                </div>
                <div className="row">
                    {this.getLabInput(propertyConstants.PROPERTY_SPECTRO_A, selectedColorData, "A:")}
                </div>
                <div className="row">
                    {this.getLabInput(propertyConstants.PROPERTY_SPECTRO_B, selectedColorData, "B:")}
                </div>
                <div className="btn-column">
                    {this.getConvertReflToLab(selectedColorData)}
                    {this.getConvertLabToRgb(selectedColorData)}
                </div>
            </div>
        );
    };

    getSpectroDetail = (selectedColorData: ColorData | null): JSX.Element | null => {
        const { objectType } = this.props;

        if (objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE) {
            return (
                <div className="charts-container">
                    <div className="row">
                        <SpectralSettingsTable
                            tableConstant={this.props.colorTableConstant}
                            objectType={objectType}
                            dataPrivileges={
                                this.props.menuScene
                                    ? this.props.menuScene.items.find(
                                          (item) => item.key === this.props.colorTableConstant
                                      )
                                    : null || null
                            }
                            allList={selectedColorData?.[propertyConstants.PROPERTY_REFLECTANCE_DATA] || []}
                            columnVisibility={this.props.colorColumnVisibility}
                        />
                        <LineGraph activeColor={this.props.objectData} />
                    </div>
                    <div className="row">{this.getLabSection(selectedColorData)}</div>
                </div>
            );
        }

        // TODO: Co zobrazovat, když neni nic vybrane?
        return null;
    };

    render(): JSX.Element | null {
        const selectedColorData = this.getSelectedColorData();

        return (
            <div className="spectral-content">
                <div className="btn-row">
                    {this.getAddSpectroType()}
                    {this.getDeleteSpectroType(selectedColorData)}
                    {this.getWavelengthSettingsModal()}
                </div>
                <div className="spectral-data-container">
                    {this.getSpectroSelectbox()}
                    {this.getSpectroDetail(selectedColorData)}
                </div>
            </div>
        );
    }
}

type StateProps = {
    activeScene: Scene;
    menuScene: MenuItem | null;
    colorTableConstant: string;
    colorColumnVisibility: any;
    optionWavelength: Option | null;
};

export type DispatchType = Readonly<{
    convertColorLabToRgb(l: number | null, a: number | null, b: number | null): void;
    convertColorReflToLab(reflData: Array<Array<number>>): void;
    deleteItem(objectType: objectTypes.ObjectType, params: any): any;
    openModal(type: ModalType, params: any): void;
    // TODO colorData: ColorData?
    updateColorData(
        objectType: objectTypes.ObjectType,
        colorData: Array<ColorData>,
        spectroTypeId: number | null
    ): void;
}>;

const mapStateToProps = (state: AppState): StateProps => ({
    activeScene: state.navigation.activeScene,
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_COLORS
    ),
    colorTableConstant: menuConstants.TABLE_MASTER_COLORS,
    colorColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_COLOR_DATA
    ),
    optionWavelength: generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_WAVELENGTH)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    convertColorLabToRgb: (l: number | null, a: number | null, b: number | null): any =>
        dispatch(colorActions.convertColorLabToRgb(l, a, b)),
    convertColorReflToLab: (reflData: Array<Array<number>>): any =>
        dispatch(colorActions.convertColorReflToLab(reflData)),
    deleteItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.deleteItem(objectType, params)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params)),
    updateColorData: (
        objectType: objectTypes.ObjectType,
        colorData: Array<ColorData>,
        spectroTypeId: number | null
    ): any => dispatch(colorActions.updateColorData(objectType, colorData, spectroTypeId))
});

export const SpectralSettingsBookmarkContainer = connect(mapStateToProps, mapDispatchToProps)(SpectralSettingsBookmark);
