import * as licenseActions from "../actions/licenseActions";
import * as loginActions from "../actions/loginActions";
import * as navigationActions from "../actions/navigationActions";
import * as notificationActions from "../actions/notificationActions";
import * as optionHelper from "../helpers/optionHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as serverEventsActions from "../actions/serverEventsActions";
import * as websocketActions from "../actions/websocketActions";

import { App } from "../components/App";
import { AppState } from "../reducers";
import { DataVacuuming } from "../types/monitoring/dataVacuuming";
import { Dispatch } from "redux";
import { FlashMessage } from "../types/flashMessage";
import { Language } from "../constants/translationConstants";
import { Modal } from "../types/modal";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    vacuuming: DataVacuuming | null;
    flashMessages: Array<FlashMessage>;
    language: Language;
    licensed: boolean;
    modalList: Array<Modal>;
    serverConnected: boolean;
    urlRest: string;
    urlWebsocket: string;
}>;

export type DispatchType = Readonly<{
    closeAllModals: () => void;
    deleteFlashMessage: (id: number) => void;
    getLicenseStatus: () => void;
    logout: () => void;
    saveSessionUuid: (sessionUuid: string) => void;
    serverConnect: (url: string) => void;
    serverInit: (sessionUuid: string) => void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    vacuuming: state.database.vacuuming,
    flashMessages: state.navigation.flashMessages,
    language: optionHelper.getValueFromOptionDictionaryByKey(state.login.options, optionsConstants.OPTION_LANGUAGE),
    licensed: state.license.licensed,
    modalList: state.navigation.modalList,
    serverConnected: state.server.connected,
    urlRest: state.server.urlRest,
    urlWebsocket: state.server.urlWebsocket
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    closeAllModals: (): any => dispatch(navigationActions.navigationCloseAllModals()),
    deleteFlashMessage: (id: number): any => dispatch(notificationActions.deleteFlashMessage(id)),
    getLicenseStatus: (): any => dispatch(licenseActions.getLicenseStatus()),
    logout: (): any => dispatch(loginActions.logout()),
    saveSessionUuid: (sessionUuid: string): any => dispatch(serverEventsActions.saveSessionInfo(sessionUuid)),
    serverConnect: (url: string): any => dispatch(websocketActions.serverConnect(url)),
    serverInit: (sessionUuid: string): any => dispatch(websocketActions.serverInit(sessionUuid))
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
