import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ColorantBatch, mapColorantBatch } from "../../types/colorantBatch";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantBatchList: Array<ColorantBatch> = [];
            let colorantBatch: ColorantBatch | null;

            for (const item of response.data.data) {
                colorantBatch = mapColorantBatch(item);

                if (colorantBatch) {
                    colorantBatchList.push(colorantBatch);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_COLORANT_BATCH,
                    colorantBatchList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_ADD_MASTER_COLORANT_BATCH: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLORANT_BATCH),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANT_BATCHES_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_COLORANT_BATCH: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_COLORANT_BATCHES_EDIT)
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_COLORANT_BATCH));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANT_BATCHES_EDIT));
            } else {
                const colorantBatch = mapColorantBatch(response.data);

                if (colorantBatch) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.MASTER_COLORANT_BATCH, [colorantBatch])
                    );
                }
            }
            return requests;
        }
        case methods.METHOD_DELETE_MASTER_COLORANT_BATCH: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLORANT_BATCH),
                universalObjectActions.setActiveItems(objectTypes.MASTER_COLORANT_BATCH, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
