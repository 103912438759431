import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import { File, generateFileData, generateFileHeadings } from "../../../types/file";
import React, { Component } from "react";

import { FileCategory } from "../../../types/fileCategory";
import { FileType } from "../../../types/fileType";
import { ModalProperties } from "../../../types/modalProperties";
import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    modalId?: number;
    dataPrivileges: any;
    loading: boolean;
    categoryList: Array<FileCategory>; // TODO unify types FileCategory and ImageCategory?
    fileTypeList: Array<FileType>;
    allList: Array<File>;
    activeList: Array<File>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    returnAction(file: Array<File>): void;
};

export class FileTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_FILE_CATEGORY_ID,
            propertyConstants.PROPERTY_FILE_TYPE_ID,
            propertyConstants.PROPERTY_ACTION
        ];

        return generateFileHeadings(
            this.props.objectType,
            this.props.fileTypeList,
            this.props.categoryList,
            propertyList,
            propertyList,
            [],
            [propertyConstants.PROPERTY_FILE_TYPE_ID, propertyConstants.PROPERTY_NAME],
            [],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    render(): JSX.Element {
        const { allList } = this.props;
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_FILES_TABLE,
            addModalParams: {
                files: allList,
                returnAction: (file: File | null): void => {
                    if (file) {
                        const files = allList.concat(file);
                        this.props.returnAction(files);
                    }
                }
            }
        };

        return (
            <TableContainer
                className={"table-with-filter"}
                tableConstant={this.props.tableConstant}
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                modalId={this.props.modalId}
                loading={this.props.loading}
                headings={this.generateTableHeadings()}
                data={generateFileData(allList)}
                activeData={this.props.activeList}
                disableColumnResizing={true}
                modalProperties={modalProperties}
                offset={this.props.offset}
                page={this.props.page}
                rowCount={this.props.rowCount}
                search={this.props.search}
                searchParams={this.props.searchParams}
                showFilterRow={this.props.objectType !== objectTypes.PRODUCT_FILE}
                showGlobalSearch={false}
                sortingAsc={this.props.sortingAsc}
                sortingCriterion={this.props.sortingCriterion}
                totalCount={this.props.totalCount}
            />
        );
    }
}
