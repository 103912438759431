import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Color, cloneColor, updateFormulaInList, updateFormulaList } from "../types/color";
import { Unit } from "../types/unit";

import { updateFormulaColorantList } from "../types/formula";

export type ColorState = Readonly<{
    masterList: Array<Color>;
    // activeList for Color Scene
    masterActiveList: Array<Color>;
    // activeList for Color Modal (Spectral settings bookmark)
    masterModalActiveList: Array<Color>;
    masterCount: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    systemList: Array<Color>;
    systemActiveList: Array<Color>;
    systemCount: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    zoneList: Array<Color>;
    zoneActiveList: Array<Color>;
    zoneCount: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
    unitAllList: Array<Unit>;
}>;

const initialState: ColorState = {
    masterList: [],
    masterActiveList: [],
    masterModalActiveList: [],
    masterCount: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCount: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCount: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE,
    unitAllList: []
};

export default function colors(state: ColorState = initialState, action: Record<string, any>): ColorState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.FORMULA_COLORANT_UNIT: {
                    return {
                        ...state,
                        unitAllList: action.payload.list
                    };
                }
                default: {
                    return state;
                }
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLOR:
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterModalActiveList: newActiveList,
                        masterCount: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_COLOR:
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCount: action.payload.totalCount
                    };
                }
                case objectTypes.ZONE_COLOR:
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCount: action.payload.totalCount
                    };
                }
                default: {
                    return state;
                }
            }
        }
        // action that updates the parameter data inside Color
        case actionConstants.UPDATE_COLOR_DATA: {
            switch (action.payload.objectType) {
                // updates masterActiveList in the Color Scene
                case objectTypes.MASTER_COLOR_DATA: {
                    if (state.masterActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = cloneColor(
                            state.masterActiveList[0],
                            action.payload.colorData,
                            action.payload.spectroTypeId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            masterActiveList: newActiveList
                        };
                    }

                    return state;
                }
                // updates masterModalActiveList in the Color Modal (specifically Spectral Settings bookmark)
                case objectTypes.MASTER_COLOR_MODAL: {
                    if (state.masterModalActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = cloneColor(
                            state.masterModalActiveList[0],
                            action.payload.colorData,
                            action.payload.spectroTypeId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            masterModalActiveList: newActiveList
                        };
                    }

                    return state;
                }
                case objectTypes.SYSTEM_COLOR_DATA: {
                    if (state.systemActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = cloneColor(
                            state.systemActiveList[0],
                            action.payload.colorData,
                            action.payload.spectroTypeId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            systemActiveList: newActiveList
                        };
                    }

                    return state;
                }
                case objectTypes.ZONE_COLOR_DATA: {
                    if (state.zoneActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = cloneColor(
                            state.zoneActiveList[0],
                            action.payload.colorData,
                            action.payload.spectroTypeId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            zoneActiveList: newActiveList
                        };
                    }

                    return state;
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.UPDATE_FORMULA: {
            switch (action.payload.objectType) {
                // updates masterActiveList in the Formula Scene
                case objectTypes.MASTER_FORMULA: {
                    if (state.masterActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = updateFormulaList(
                            state.masterActiveList[0],
                            action.payload.formulaList,
                            action.payload.formulaId,
                            action.payload.baseInProductId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            masterActiveList: newActiveList
                        };
                    }

                    return state;
                }
                case objectTypes.SYSTEM_FORMULA: {
                    if (state.systemActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = updateFormulaList(
                            state.systemActiveList[0],
                            action.payload.formulaList,
                            action.payload.formulaId,
                            action.payload.baseInProductId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            systemActiveList: newActiveList
                        };
                    }

                    return state;
                }
                case objectTypes.ZONE_FORMULA: {
                    if (state.zoneActiveList.length) {
                        const newActiveList: Array<Color> = [];
                        const newColor = updateFormulaList(
                            state.zoneActiveList[0],
                            action.payload.formulaList,
                            action.payload.formulaId,
                            action.payload.baseInProductId
                        );

                        if (newColor) {
                            newActiveList.push(newColor);
                        }

                        return {
                            ...state,
                            zoneActiveList: newActiveList
                        };
                    }

                    return state;
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLOR:
                case objectTypes.MASTER_COLOR_DATA:
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO:
                case objectTypes.MASTER_FORMULA: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items,
                        masterModalActiveList: action.payload.items
                    };
                }
                case objectTypes.MASTER_COLOR_MODAL: {
                    return {
                        ...state,
                        masterModalActiveList: action.payload.items
                    };
                }
                case objectTypes.MASTER_FORMULA_MODAL: {
                    const updatedColor = updateFormulaInList(
                        state.masterActiveList[0] ?? null,
                        action.payload?.items?.[0] ?? null
                    );
                    return {
                        ...state,
                        masterModalActiveList: updatedColor ? [updatedColor] : []
                    };
                }
                case objectTypes.SYSTEM_COLOR:
                case objectTypes.SYSTEM_COLOR_DATA:
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO:
                case objectTypes.SYSTEM_FORMULA: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_COLOR:
                case objectTypes.ZONE_COLOR_DATA:
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO:
                case objectTypes.ZONE_FORMULA: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLOR:
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_COLOR:
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.ZONE_COLOR:
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLOR:
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_COLOR:
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_COLOR:
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_FORMULA_COLORANT: {
                    if (action.payload.isModal) {
                        const formula =
                            state.masterModalActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_LIST]?.find(
                                (item) => item[propertyConstants.PROPERTY_ID] === action.payload.itemId
                            ) ?? null;
                        const formulaColorantList = generalHelper.sortItemsByProperty(
                            formula?.[propertyConstants.PROPERTY_COLORANT_LIST] ?? [],
                            action.payload.criterion,
                            action.payload.asc
                        );
                        const updatedColor = updateFormulaInList(
                            state.masterModalActiveList[0] ?? null,
                            updateFormulaColorantList(
                                formula,
                                formulaColorantList,
                                action.payload.asc,
                                action.payload.criterion
                            )
                        );

                        return {
                            ...state,
                            masterModalActiveList: updatedColor ? [updatedColor] : state.masterModalActiveList
                        };
                    }

                    const formula =
                        state.masterActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_LIST]?.find(
                            (item) => item[propertyConstants.PROPERTY_ID] === action.payload.itemId
                        ) ?? null;
                    const formulaColorantList = generalHelper.sortItemsByProperty(
                        formula?.[propertyConstants.PROPERTY_COLORANT_LIST] ?? [],
                        action.payload.criterion,
                        action.payload.asc
                    );
                    const updatedColor = updateFormulaInList(
                        state.masterActiveList[0] ?? null,
                        updateFormulaColorantList(
                            formula,
                            formulaColorantList,
                            action.payload.asc,
                            action.payload.criterion
                        )
                    );

                    return {
                        ...state,
                        masterActiveList: updatedColor ? [updatedColor] : state.masterActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        // After closing the Colors modal, the modalActiveList needs to be refreshed with updated data from masterActiveList
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload.type === modalTypes.MODAL_COLORS_ADD ||
                action.payload.type === modalTypes.MODAL_COLORS_EDIT ||
                action.payload.type === modalTypes.MODAL_FORMULAS_ADD ||
                action.payload.type === modalTypes.MODAL_FORMULAS_EDIT
            ) {
                return {
                    ...state,
                    masterModalActiveList: [...state.masterActiveList]
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                masterModalActiveList: [...state.masterActiveList]
            };
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
