import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { Zone, generateZoneData, generateZoneHeadings } from "../../../types/zone";

import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    allList: Array<Zone>;
    loading: boolean;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
};

export class ZoneDuplicateTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_DUPLICATED,
            propertyConstants.PROPERTY_NAME
        ];
        const editableList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_IS_DUPLICATED];
        const orderList = optionHelper.handleTableHeadingOrder(propertyList, this.props.columnOrder);
        const visibleList = optionHelper.handleTableHeadingVisibility(propertyList, this.props.columnVisibility);

        return generateZoneHeadings(
            this.props.objectType,
            [],
            [],
            [],
            orderList,
            visibleList,
            editableList,
            visibleList,
            visibleList,
            this.props.columnWidth
        );
    };

    render(): JSX.Element {
        return (
            <div className="card">
                <TableContainer
                    className={"no-heading-table"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    titleClassName={"no-title"}
                    headings={this.generateTableHeadings()}
                    data={generateZoneData(this.props.allList, this.props.objectType)}
                    activeData={[]}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={this.props.allList.length}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={null}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={true}
                    sortingCriterion={null}
                    totalCount={null}
                    modalProperties={{}}
                />
            </div>
        );
    }
}
