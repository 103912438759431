import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";

import { AppState } from "../../../reducers";
import { MenuItem } from "../../../types/menu";
import { RedlikeOption } from "../../../types/redlikeOption";
import { ServerRequest } from "../../../types/serverRequest";
import { SystemRedlikeOptionScene } from "../../../components/scenes/system/SystemRedlikeOptionScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    custom: string | null;
    optionTableConstant: string;
    optionList: Array<RedlikeOption>;
    optionActiveList: Array<RedlikeOption>;
    optionLoading: boolean;
    optionButtonLoading: boolean;
    optionColumnOrder: Array<propertyConstants.Property>;
    optionColumnVisibility: Record<propertyConstants.Property, boolean>;
    optionColumnWidth: Record<propertyConstants.Property, number>;
    optionSceneTableSize: Record<string, any> | null;
    optionSearch: string | null;
    optionSearchParams: Record<propertyConstants.Property, string>;
    optionShowDefaultValues: boolean;
    optionShowFilterRow: boolean;
    optionShowGlobalSearch: boolean;
    optionSortingAsc: boolean;
    optionSortingCriterion: propertyConstants.Property;
    optionTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS
    ),
    custom: state.software.custom,
    optionTableConstant: menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS,
    optionList: state.options.systemList,
    optionActiveList: state.options.systemActiveList,
    optionLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS
    ),
    optionButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_SET_SYSTEM_REDLIKE_OPTION ||
            request.method === methods.METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE
    ),
    optionColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionSearch: state.options.systemSearch,
    optionSearchParams: state.options.systemSearchParams,
    optionShowDefaultValues: state.options.systemShowDefaultValues,
    optionShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS
    ),
    optionSortingAsc: state.options.systemSortingAsc,
    optionSortingCriterion: state.options.systemSortingCriterion,
    optionTotalCount: state.options.systemCount,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS
    )
});

export const SystemRedlikeOptionSceneContainer = connect(mapStateToProps)(SystemRedlikeOptionScene);
