import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "./optionHelper";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { ExportTask } from "../types/exportTask";
import { ImportTask } from "../types/importTask";

export const isDeletingImportExportTasksEnabled = (
    objectType: objectTypes.ObjectType,
    privileges: any,
    activeList: Array<ExportTask | ImportTask>
): boolean => {
    let isAvailable;

    switch (objectType) {
        case objectTypes.EXPORT_TASK:
            isAvailable = optionHelper.isPrivilegeAvailable(
                privileges?.[privilegeConstants.PRIVILEGE_EXPORT_DELETE_TASK_UPDATE]
            );
            break;
        case objectTypes.IMPORT_TASK:
            isAvailable = optionHelper.isPrivilegeAvailable(
                privileges?.[privilegeConstants.PRIVILEGE_IMPORT_DELETE_TASK_UPDATE]
            );
            break;
        default:
            isAvailable = false;
    }

    // Import and export tasks need to have correct privilege, tasks have to be finished and cannot be deleted - there is a canDelete attribute for each task
    for (const task of activeList) {
        if (!task[propertyConstants.PROPERTY_CAN_DELETE]) {
            isAvailable = isAvailable && false;
        }
    }

    return isAvailable;
};

export const isHidingImportExportTasksEnabled = (
    objectType: objectTypes.ObjectType,
    privileges: any,
    activeList: Array<ExportTask | ImportTask>
): boolean => {
    let isAvailable;

    switch (objectType) {
        case objectTypes.EXPORT_TASK:
            isAvailable = optionHelper.isPrivilegeAvailable(
                privileges?.[privilegeConstants.PRIVILEGE_EXPORT_HIDE_TASK_UPDATE]
            );
            break;
        case objectTypes.IMPORT_TASK:
            isAvailable = optionHelper.isPrivilegeAvailable(
                privileges?.[privilegeConstants.PRIVILEGE_IMPORT_HIDE_TASK_UPDATE]
            );
            break;
        default:
            isAvailable = false;
    }

    // Import and export tasks need to have correct privilege, tasks have to be finished and cannot be deleted - there is a canHide attribute for each task
    for (const task of activeList) {
        if (!task[propertyConstants.PROPERTY_CAN_HIDE]) {
            isAvailable = isAvailable && false;
        }
    }

    return isAvailable;
};
