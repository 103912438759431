import * as browserStorageConstants from "../constants/browserStorageConstants";
import * as defaultConstants from "../constants/default";
import * as formattingHelper from "../helpers/formattingHelper";
import * as generalHelper from "../helpers/generalHelper";
import * as languageHelper from "../helpers/languageHelper";
import * as localConfig from "../constants/localConfig";
import * as requestHelper from "../helpers/requestHelper";
import * as serverConstants from "../constants/serverConstants";
import * as serverMethods from "../constants/serverMethods";
import * as serverRestMethods from "../constants/serverRestMethods";
import * as translationConstants from "../constants/translationConstants";

import { DispatchType, PropsType } from "../containers/App";
import React, { Component } from "react";

import FlashMessage from "../components/FlashMessage";
import { ReactSVG } from "react-svg";
import { RouterContainer } from "../containers/Router";

import imgLoader from "../resources/img/loader.svg";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Helmet = require("react-helmet").default;
type Props = PropsType & DispatchType;

type State = {
    loading: boolean;
    intervalId: NodeJS.Timeout | null;
    modalIndex: number;
};

export class App extends Component<Props, State> {
    state: State = {
        loading: true,
        intervalId: null,
        modalIndex: defaultConstants.DEFAULT_BACKGROUND_INDEX
    };

    componentDidMount(): void {
        this.props.serverConnect(this.props.urlWebsocket);

        // Sets periodical REST ping request to keep the session active
        const pingIntervalMinute = Number(localConfig.SERVER_REST_PING_INTERVAL);
        if (!isNaN(pingIntervalMinute) && pingIntervalMinute > 0) {
            this.serverRestPing();
            const interval = setInterval(
                this.serverRestPing,
                formattingHelper.formatMinuteToMilliseconds(pingIntervalMinute)
            );
            this.setState({ intervalId: interval });
        }

        this.initTranslations();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        // Calling init method to get session UUID to check whether another session already exists
        if (this.props.serverConnected && this.props.serverConnected !== prevProps.serverConnected) {
            this.serverRestInit();
        }

        // Handling modal index (background shadow) when modal is opened or closed
        const modalDiff = this.props.modalList.length - prevProps.modalList.length;

        if ((modalDiff > 0 && prevProps.modalList.length) || (modalDiff < 0 && this.props.modalList.length)) {
            this.setState({ modalIndex: this.state.modalIndex + modalDiff });
        }
    }

    componentWillUnmount(): void {
        const { intervalId } = this.state;

        if (intervalId) {
            clearInterval(intervalId);
        }
    }

    serverRestInit = async (): Promise<any> => {
        try {
            const response = await fetch(
                `${this.props.urlRest}${serverRestMethods.METHOD_INIT}`,
                requestHelper.getRestServerFetchInit({})
            );

            if (response.ok) {
                const json = await response.json();

                if (json && json.data) {
                    this.props.serverInit(json.data);
                    sessionStorage.setItem(browserStorageConstants.SESSION_STORAGE_UUID, json.data);
                } else {
                    this.props.getLicenseStatus();
                }
            } else {
                this.props.getLicenseStatus();
            }
        } catch {
            await this.serverRestInit();
        }
    };

    serverRestPing = async (): Promise<any> => {
        try {
            const response = await fetch(
                `${this.props.urlRest}/api/v1/${serverMethods.METHOD_GET_SESSION_UUID}`,
                requestHelper.getRestServerFetchInit({})
            );

            if (response.ok) {
                const json = await response.json();
                if (json.result !== serverConstants.RESULT_OK) {
                    await this.serverRestInit();
                }
            } else {
                await this.serverRestInit();
            }
        } catch {
            await this.serverRestInit();
        }
    };

    initTranslations = (): void => {
        const language = languageHelper.initializeTranslations(
            localStorage.getItem(browserStorageConstants.LOCAL_STORAGE_LANGUAGE) ??
                this.props.language ??
                translationConstants.ENGLISH
        );

        if (language) {
            this.setState({ loading: false });
        }
    };

    handleBackgroundClick = (): void => {
        this.setState({
            modalIndex: defaultConstants.DEFAULT_BACKGROUND_INDEX
        });

        this.props.closeAllModals();
    };

    getApplicationPublic = (): JSX.Element => {
        switch (this.props.custom) {
            case defaultConstants.COLORIS_SOFTWARE: {
                return (
                    <Helmet>
                        <link rel="icon" href="/favicon-coloris.ico" />
                        <link rel="apple-touch-icon" href="/logo192-coloris.png" />
                        <link rel="manifest" href="/manifest-coloris.json" />
                        <title>CAMAT Manager</title>
                    </Helmet>
                );
            }
            case null:
            default: {
                return (
                    <Helmet>
                        <link rel="icon" href="/favicon.ico" />
                        <link rel="apple-touch-icon" href="/logo192.png" />
                        <link rel="manifest" href="/manifest.json" />
                        <title>Redlike Manager</title>
                    </Helmet>
                );
            }
        }
    };

    getMainClassName = (): string => {
        let className = "main";

        if (this.props.modalList.length) {
            return className + " modal-open";
        }

        if (this.props.custom === defaultConstants.COLORIS_SOFTWARE) {
            className += " coloris-style";
        }

        if (this.state.loading) {
            className += " bg-screen initial-scenes";
        }

        return className;
    };

    getFlashMessages = (): Array<JSX.Element> => {
        const { flashMessages, deleteFlashMessage } = this.props;
        const messages: Array<JSX.Element> = [];

        for (const message of flashMessages) {
            const key = flashMessages.indexOf(message);

            messages.push(
                <div className="flash-message-magic" key={key}>
                    <FlashMessage
                        index={key}
                        type={message.type}
                        text={message.text}
                        deleteFlashMessage={deleteFlashMessage}
                    />
                    <div className="flash-message-space" />
                </div>
            );
        }

        return messages;
    };

    getBackgroundShadow = (): JSX.Element => {
        // TODO
        if (this.props.vacuuming) {
            return <div className="background-shadow" style={{ zIndex: this.state.modalIndex }} />;
        }

        return (
            <div
                className="background-shadow"
                style={{ zIndex: this.state.modalIndex }}
                onClick={() => this.handleBackgroundClick()}
            />
        );
    };

    render(): JSX.Element {
        if (this.state.loading) {
            return (
                <div>
                    {this.getApplicationPublic()}
                    <div className={this.getMainClassName()}>
                        <div className="bg-container md-window">
                            <div className="lab-logo">
                                <ReactSVG
                                    className="logo-icon"
                                    src={generalHelper.getSoftwareLogo(this.props.custom)}
                                />
                            </div>
                            <div className="loader-container">
                                <ReactSVG src={imgLoader} className="loader" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {this.getApplicationPublic()}
                <div className={this.getMainClassName()}>
                    <RouterContainer />
                    <div className="flash-messages-box">{this.getFlashMessages()}</div>
                    {this.getBackgroundShadow()}
                </div>
            </div>
        );
    }
}
