import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Epic, ofType } from "redux-observable";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const resetValue: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.RESET_VALUE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // redlike options
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_MASTER_REDLIKE_OPTION,
                            methods.METHOD_RESET_MASTER_REDLIKE_OPTION,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_SYSTEM_REDLIKE_OPTION,
                            methods.METHOD_RESET_SYSTEM_REDLIKE_OPTION,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_ZONE_REDLIKE_OPTION,
                            methods.METHOD_RESET_ZONE_REDLIKE_OPTION,
                            payload.params
                        )
                    );
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );

export const resetAllValues: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.RESET_ALL_VALUES),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // redlike options
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_ALL_MASTER_REDLIKE_OPTIONS,
                            methods.METHOD_RESET_ALL_MASTER_REDLIKE_OPTIONS
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_ALL_SYSTEM_REDLIKE_OPTIONS,
                            methods.METHOD_RESET_ALL_SYSTEM_REDLIKE_OPTIONS
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_RESET_ALL_ZONE_REDLIKE_OPTIONS,
                            methods.METHOD_RESET_ALL_ZONE_REDLIKE_OPTIONS
                        )
                    );
                    break;
                }
                // redlike privileges
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    const userGroupId = state$.value.userGroup.masterActiveList.length
                        ? state$.value.userGroup.masterActiveList[0][propertyConstants.PROPERTY_ID]
                        : null;

                    if (userGroupId) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_RESET_ALL_REDLIKE_PRIVILEGES,
                                methods.METHOD_RESET_ALL_REDLIKE_PRIVILEGES,
                                {
                                    userGroupId: userGroupId
                                }
                            )
                        );
                    }
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
