import * as propertyConstants from "../../../../constants/propertyConstants";
import * as serverRestMethods from "../../../../constants/serverRestMethods";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";
import imgClose from "../../../../resources/img/icons/close.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    heading: TableHeading;
    item: any;
};

type Props = OwnProps & ContainerProps;

export class TableCellImagePreview extends Component<Props> {
    getClassname = (property: propertyConstants.Property): string => {
        return `td ${property} image-container`;
    };

    getImagePreview = (content: number | null): JSX.Element => {
        const { sessionUuid } = this.props;
        let preview = <img alt={""} key={"file-image"} src={imgClose} />;

        if (content !== null && sessionUuid !== null) {
            // added time constant to avoid cache
            const imageSrc = `${this.props.urlRest}${serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID}?session_uuid=${sessionUuid}&image_id=${content}&height=50&width=50`;

            preview = <img alt={""} key={"file-image"} src={imageSrc} />;
        }

        return preview;
    };

    getCellContent = (value: any): any => {
        return value !== "" && !isNaN(value) ? value : null;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const content = this.getCellContent(value);

        return (
            <div className={this.getClassname(property)} key={property}>
                {this.getImagePreview(content)}
            </div>
        );
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellImagePreviewContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellImagePreview);
