import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { FileCategory } from "../types/fileCategory";

export type FileCategoryState = Readonly<{
    allList: Array<FileCategory>;
    list: Array<FileCategory>;
    activeList: Array<FileCategory>;
    count: number | null;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: FileCategoryState = {
    allList: [],
    list: [],
    activeList: [],
    count: null,
    sortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    sortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function fileCategories(
    state: FileCategoryState = initialState,
    action: Record<string, any>
): FileCategoryState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                return {
                    ...state,
                    allList: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                return {
                    ...state,
                    activeList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.SET_SORTING: {
            if (action.payload.objectType === objectTypes.FILE_CATEGORY) {
                return {
                    ...state,
                    sortingAsc: action.payload.asc,
                    sortingCriterion: action.payload.criterion
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        // TODO chybi close all modals
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (action.payload.type === modalTypes.MODAL_FILE_CATEGORIES_TABLE) {
                return {
                    ...state,
                    activeList: []
                };
            }

            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
