import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { BaseInProductPackage, mapBaseInProductPackage } from "../../types/baseInProductPackage";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<BaseInProductPackage> = [];
            let baseInProductPackage: BaseInProductPackage | null;

            for (const item of response.data.data) {
                baseInProductPackage = mapBaseInProductPackage(
                    item.package,
                    item.baseInProductPackage,
                    state.baseInProduct.systemActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    packageList.push(baseInProductPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                    packageList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<BaseInProductPackage> = [];
            let baseInProductPackage: BaseInProductPackage | null;

            for (const item of response.data.data) {
                baseInProductPackage = mapBaseInProductPackage(
                    item.package,
                    item.baseInProductPackage,
                    state.baseInProduct.systemActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    packageList.push(baseInProductPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                    packageList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<BaseInProductPackage> = [];
            let baseInProductPackage: BaseInProductPackage | null;

            for (const item of response.data.data) {
                baseInProductPackage = mapBaseInProductPackage(
                    item.package,
                    item.baseInProductPackage,
                    state.baseInProduct.zoneActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    packageList.push(baseInProductPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE,
                    packageList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductPackageList: Array<BaseInProductPackage> = [];
            const baseInProductPackage = mapBaseInProductPackage(
                response.data.package,
                response.data.baseInProductPackage,
                state.baseInProduct.systemActiveList?.[0] || null
            );
            const requests: Array<any> = [];

            if (baseInProductPackage) {
                baseInProductPackageList.push(baseInProductPackage);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: baseInProductPackage
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(
                    objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                    baseInProductPackageList
                )
            ];
        }
        case methods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT));
            } else {
                const baseInProductPackage = mapBaseInProductPackage(
                    response.data.package,
                    response.data.baseInProductPackage,
                    state.baseInProduct.systemActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE, [
                            baseInProductPackage
                        ])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<BaseInProductPackage> = [];
            let baseInProductPackage: BaseInProductPackage | null;

            for (const item of response.data.data) {
                baseInProductPackage = mapBaseInProductPackage(
                    item.package,
                    item.baseInProductPackage,
                    state.baseInProduct.zoneActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    packageList.push(baseInProductPackage);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE,
                    packageList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_PACKAGE_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductPackageList: Array<BaseInProductPackage> = [];
            const baseInProductPackage = mapBaseInProductPackage(
                response.data.package,
                response.data.baseInProductPackage,
                state.baseInProduct.zoneActiveList?.[0] || null
            );
            const requests: Array<any> = [];

            if (baseInProductPackage) {
                baseInProductPackageList.push(baseInProductPackage);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: baseInProductPackage
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.setActiveItems(
                    objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE,
                    baseInProductPackageList
                )
            ];
        }
        case methods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT));
            } else {
                const baseInProductPackage = mapBaseInProductPackage(
                    response.data.package,
                    response.data.baseInProductPackage,
                    state.baseInProduct.zoneActiveList?.[0] || null
                );

                if (baseInProductPackage) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE, [
                            baseInProductPackage
                        ])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE),
                universalObjectActions.setActiveItems(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
