export class ImportOption {
    key: string;
    name: string;
    optionType: string;
    value: string | number | boolean | null;
    defaultValue: string | number | null;
    values: Array<any>;
    validation: string | null;
    mandatory: boolean;

    constructor(
        key: string,
        name: string,
        optionType: string,
        value: string | number | boolean | null,
        defaultValue: string | number | null,
        values: Array<any>,
        validation: string | null,
        mandatory: boolean
    ) {
        this.key = key;
        this.name = name;
        this.optionType = optionType;
        this.value = value;
        this.defaultValue = defaultValue;
        this.values = values;
        this.validation = validation;
        this.mandatory = mandatory;
    }
}

export function mapImportOption(data: Record<string, any>): ImportOption | null {
    try {
        const values: Array<any> = [];

        for (const [key, value] of Object.entries(data.values?.[0] ? data.values[0] : {})) {
            values.push({ key: key, value: value });
        }

        return new ImportOption(
            data.key,
            data.name,
            data.optionType,
            data.default,
            data.default,
            values,
            data.validation,
            data.mandatory
        );
    } catch (e) {
        return null;
    }
}

export function mapImportOptions(data: Record<string, any>): Array<ImportOption> {
    const options = [];

    for (const item of Object.values(data)) {
        const option = mapImportOption(item);

        if (option !== null) {
            options.push(option);
        }
    }

    return options;
}
