import * as generalHelper from "../helpers/generalHelper";
import * as menuConstants from "../constants/menuConstants";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "../helpers/optionHelper";
import * as pathConstants from "../constants/paths";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as routingHelper from "../helpers/routingHelper";
import * as translationHelper from "../helpers/translationHelper";

import { DispatchType, PropsType } from "../containers/Header";
import React, { Component } from "react";
import { generateOneTimeAccessPasswordData, generateOneTimeAccessPasswordHeadings } from "../types/redlike/password";

import { DataItem } from "../types/dataItem";
import { Database } from "../types/database";
import { ModalParams } from "../types/modalParams";
import { ReactSVG } from "react-svg";
import { Scene } from "../constants/navigationTypes";
import { System } from "../types/system";
import { Zone } from "../types/zone";

import changelog from "../changelog.json";
import { findDOMNode } from "react-dom";
import { generateChangePasswordModalParams } from "../types/user";
import imgDeso from "../resources/img/deso-logo.png";
import imgHelp from "../resources/img/icons/help.svg";
import imgMessages from "../resources/img/icons/notifications.svg";
import imgSearch from "../resources/img/icons/search.svg";
import imgSettings from "../resources/img/icons/settings.svg";
import imgUser from "../resources/img/icons/profile.svg";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    databaseOn: boolean;
    helpOn: boolean;
    settingsOn: boolean;
    systemOn: boolean;
    userOn: boolean;
    zoneOn: boolean;
    databaseSearch: string | null;
    systemSearch: string | null;
    isOneTimeAccessPasswordEnabled: boolean;
    tableCompanies: DataItem | null;
    tableMonitoring: DataItem | null;
    tableSettings: DataItem | null;
    tableUserActionsHistory: DataItem | null;
    tableUsers: DataItem | null;
    tableUserGroups: DataItem | null;
    zoneSearch: string | null;
    custom: string | null;
};
// TODO dekomponovat
export class Header extends Component<Props, State> {
    state: State = {
        databaseOn: false,
        helpOn: false,
        settingsOn: false,
        systemOn: false,
        userOn: false,
        zoneOn: false,
        databaseSearch: null,
        systemSearch: null,
        isOneTimeAccessPasswordEnabled: optionHelper.isPrivilegeAvailable(
            this.props.privileges?.[privilegeConstants.PRIVILEGE_ONE_TIME_ACCESS_PASSWORD]
        ),
        tableCompanies:
            this.props.dataPrivileges.find((item) => item.key === menuConstants.TABLE_COMPANIES && item.canView) ||
            null,
        tableMonitoring:
            this.props.dataPrivileges.find(
                (item) => item.key === menuConstants.TABLE_MONITORING_DATABASES && item.canView
            ) || null,
        tableSettings:
            this.props.dataPrivileges.find(
                (item) => item.key === menuConstants.PAGE_OPTIONS_USER_SETTINGS && item.canView
            ) || null,
        tableUserActionsHistory:
            this.props.dataPrivileges.find(
                (item) => item.key === menuConstants.TABLE_USER_ACTIONS_HISTORY && item.canView
            ) || null,
        tableUsers:
            this.props.dataPrivileges.find((item) => item.key === menuConstants.TABLE_USERS && item.canView) || null,
        tableUserGroups:
            this.props.dataPrivileges.find((item) => item.key === menuConstants.TABLE_USER_GROUPS && item.canView) ||
            null,
        zoneSearch: null,
        custom: null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.privileges) !== JSON.stringify(prevProps.privileges)) {
            this.setState({
                isOneTimeAccessPasswordEnabled: optionHelper.isPrivilegeAvailable(
                    this.props.privileges?.[privilegeConstants.PRIVILEGE_ONE_TIME_ACCESS_PASSWORD]
                )
            });
        }

        if (JSON.stringify(this.props.dataPrivileges) !== JSON.stringify(prevProps.dataPrivileges)) {
            this.setState({
                tableCompanies:
                    this.props.dataPrivileges.find(
                        (item) => item.key === menuConstants.TABLE_COMPANIES && item.canView
                    ) || null,
                tableMonitoring:
                    this.props.dataPrivileges.find(
                        (item) => item.key === menuConstants.TABLE_MONITORING_DATABASES && item.canView
                    ) || null,
                tableSettings:
                    this.props.dataPrivileges.find(
                        (item) => item.key === menuConstants.PAGE_OPTIONS_USER_SETTINGS && item.canView
                    ) || null,
                tableUserActionsHistory:
                    this.props.dataPrivileges.find(
                        (item) => item.key === menuConstants.TABLE_USER_ACTIONS_HISTORY && item.canView
                    ) || null,
                tableUsers:
                    this.props.dataPrivileges.find((item) => item.key === menuConstants.TABLE_USERS && item.canView) ||
                    null,
                tableUserGroups:
                    this.props.dataPrivileges.find(
                        (item) => item.key === menuConstants.TABLE_USER_GROUPS && item.canView
                    ) || null
            });
        }
    }

    componentDidMount(): void {
        window.addEventListener("click", this.closeDropdownMenu);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeDropdownMenu);
    }

    // TODO
    closeDropdownMenu = (event: Record<string, any>): void => {
        const classNames = ["tab-content", "tab-title", "caret"];

        if ([...event.target.classList].some((item: any) => classNames.indexOf(item) >= 0)) {
            return;
        } else {
            this.setState({
                databaseOn: false,
                systemOn: false,
                zoneOn: false
            });
        }
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                helpOn: false,
                settingsOn: false,
                userOn: false
            });

            if (this.props.activeNotificationPanel) {
                this.props.showNotificationPanel(false);
            }

            this.handleCloseAllDropdowns();
        }
    };

    handleCloseAllDropdowns = (newState: any = {}): any => {
        this.setState({
            databaseOn: false,
            helpOn: false,
            settingsOn: false,
            systemOn: false,
            userOn: false,
            zoneOn: false,
            ...newState
        });

        if (this.props.activeNotificationPanel) {
            this.props.showNotificationPanel(false);
        }
    };

    handleDropdownItemClick = (event: React.MouseEvent, objectType: objectTypes.ObjectType, itemId: number): any => {
        event.stopPropagation();

        const { dataChanged } = this.props;

        switch (objectType) {
            case objectTypes.DATABASE: {
                this.setState({ databaseOn: false });
                this.props.selectDatabase(itemId, !dataChanged);
                break;
            }
            case objectTypes.SYSTEM: {
                this.setState({ systemOn: false });
                this.props.selectSystem(itemId, !dataChanged);
                break;
            }
            case objectTypes.ZONE: {
                this.setState({ zoneOn: false });
                this.props.selectZone(itemId, !dataChanged);
                break;
            }
            default:
                break;
        }
    };

    handleDropdownClick = (event: React.MouseEvent, objectType: objectTypes.ObjectType): any => {
        let newState = {};
        event.stopPropagation();

        switch (objectType) {
            case objectTypes.DATABASE: {
                newState = { ...newState, databaseOn: !this.state.databaseOn };
                break;
            }
            case objectTypes.HEADER_SETTINGS: {
                newState = { ...newState, settingsOn: !this.state.settingsOn };
                break;
            }
            case objectTypes.HELP: {
                newState = { ...newState, helpOn: !this.state.helpOn };
                break;
            }
            case objectTypes.MESSAGE: {
                this.props.showNotificationPanel(!this.props.activeNotificationPanel);
                break;
            }
            case objectTypes.SYSTEM: {
                newState = { ...newState, systemOn: !this.state.systemOn };
                break;
            }
            case objectTypes.ZONE: {
                newState = { ...newState, zoneOn: !this.state.zoneOn };
                break;
            }
            case objectTypes.USER: {
                newState = { ...newState, userOn: !this.state.userOn };
                break;
            }
            default: {
                break;
            }
        }

        this.handleCloseAllDropdowns(newState);
    };

    handleTabClick = (objectType: objectTypes.ObjectType): any => {
        // TODO zlepsit nacitaci newPath, zbytecne se to taha pres scenu?
        let newScene: Scene | null = null;

        switch (objectType) {
            case objectTypes.DATABASE: {
                newScene = this.getMasterScene();
                break;
            }
            case objectTypes.SYSTEM: {
                newScene = this.getSystemScene();
                break;
            }
            case objectTypes.ZONE: {
                newScene = this.getZoneScene();
                break;
            }
            default: {
                break;
            }
        }

        const newPath = routingHelper.getPathByScene(newScene);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleUserPasswordChangeClick = (): any => {
        const changePasswordParams = generateChangePasswordModalParams(this.props.loggedUser);

        this.props.openModal(modalTypes.MODAL_CHANGE_PASSWORD, changePasswordParams);
    };

    handleOpenSettingsSceneClick = (): any => {
        const newPath = routingHelper.getPathByScene(navigationTypes.USER_OPTIONS_SCENE);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleOpenCompanySceneClick = (): any => {
        const newPath = routingHelper.getPathByScene(navigationTypes.COMPANY_SCENE);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleOpenUserSceneClick = (): any => {
        const newPath = routingHelper.getPathByScene(navigationTypes.USERS_SCENE);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleOpenOneTimeAccessPasswordModalClick = (): any => {
        const changePasswordParams = this.generateOneTimeAccessPasswordModalParams();
        this.props.openModal(modalTypes.MODAL_ONE_TIME_ACCESS_PASSWORD_GENERATE, changePasswordParams);
    };

    handleOpenUserActionsHistorySceneClick = (): any => {
        const newPath = routingHelper.getPathByScene(navigationTypes.USER_ACTION_HISTORY_SCENE);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleOpenServerMonitoringSceneClick = (): any => {
        const newPath = routingHelper.getPathByScene(navigationTypes.SERVER_MONITORING_SCENE);

        if (newPath) {
            this.props.routingPush(newPath, !this.props.dataChanged);
        }
    };

    handleChangelogClick = (): any => {
        const changelogParams = {
            title: translate("general.changelog"),
            displayText: this.getChangelog()
        };

        this.props.openModal(modalTypes.MODAL_CHANGELOG, changelogParams);
    };

    handleLicenseInformationClick = (): void => {
        const licenseParams = new ModalParams(
            modalTypes.PREVIEW_MODAL,
            objectTypes.LICENSE,
            translate("license.licenseInformation"),
            {}
        );

        this.props.openModal(modalTypes.MODAL_LICENSE, licenseParams);
    };

    handleSearchInputChange = (objectType: objectTypes.ObjectType, value: string): any => {
        switch (objectType) {
            case objectTypes.DATABASE: {
                this.setState({ databaseSearch: value });
                break;
            }
            case objectTypes.SYSTEM: {
                this.setState({ systemSearch: value });
                break;
            }
            case objectTypes.ZONE: {
                this.setState({ zoneSearch: value });
                break;
            }
            default: {
                break;
            }
        }
    };

    handleOpenDebugClick = (): any => {
        this.props.routingPush(pathConstants.PATH_DEBUG, !this.props.dataChanged);
    };

    generateOneTimeAccessPasswordModalParams(): any {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_DATE,
            propertyConstants.PROPERTY_COMPUTER_ID,
            propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION,
            propertyConstants.PROPERTY_DESCRIPTION,
            propertyConstants.PROPERTY_USERNAME,
            propertyConstants.PROPERTY_PASSWORD,
            propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION
        ];
        const editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_DATE,
            propertyConstants.PROPERTY_COMPUTER_ID,
            propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION,
            propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION
        ];

        return {
            title: translate("oneTimeAccessPassword.oneTimeAccessPassword"),
            type: modalTypes.MODAL_DISPLAY,
            objectType: objectTypes.ONE_TIME_ACCESS_PASSWORD,
            data: generateOneTimeAccessPasswordData(),
            headings: generateOneTimeAccessPasswordHeadings(
                objectTypes.ONE_TIME_ACCESS_PASSWORD,
                propertyList,
                propertyList,
                editableList,
                [],
                [],
                this.props.custom
            )
        };
    }

    getTabClassName = (objectType: objectTypes.ObjectType): string => {
        const { activeScene } = this.props;
        let className = "main-tab";
        let newScene: Scene | null = null;

        switch (objectType) {
            case objectTypes.DATABASE: {
                className += " master";
                // TODO unify master and database naming
                if (this.state.databaseOn) {
                    className += " active";
                }

                if (!navigationTypes.MASTER_SCENES_LIST.includes(activeScene)) {
                    className += " disabled";
                }

                newScene = this.getMasterScene();

                break;
            }
            case objectTypes.SYSTEM: {
                className += " system";

                if (this.state.systemOn) {
                    className += " active";
                }

                if (!navigationTypes.SYSTEM_SCENES_LIST.includes(activeScene)) {
                    className += " disabled";
                }

                newScene = this.getSystemScene();

                break;
            }
            case objectTypes.ZONE: {
                className += " zone";

                if (this.state.zoneOn) {
                    className += " active";
                }
                if (!navigationTypes.ZONE_SCENES_LIST.includes(activeScene)) {
                    className += " disabled";
                }

                newScene = this.getZoneScene();

                break;
            }
            default: {
                break;
            }
        }

        if (newScene) {
            className += " click-able-tab";
        }

        return className;
    };

    getTabDropdownItemClassName = (
        item: Database | System | Zone | null,
        activeItem: Database | System | Zone | null
    ): string => {
        let className = "tab-item";

        if (item?.[propertyConstants.PROPERTY_ID] === activeItem?.[propertyConstants.PROPERTY_ID]) {
            className += " active";
        }

        return className;
    };

    getMessageCount = (): JSX.Element | null => {
        if (this.props.messagesCount !== 0) {
            return <div className="icon-notification">{this.props.messagesCount}</div>;
        }

        return null;
    };

    getMasterScene = (): Scene | null => {
        switch (this.props.activeScene) {
            case navigationTypes.SYSTEM_BASES_SCENE:
            case navigationTypes.ZONE_BASES_SCENE:
                return navigationTypes.MASTER_BASES_SCENE;
            case navigationTypes.SYSTEM_COLORANTS_SCENE:
            case navigationTypes.ZONE_COLORANTS_SCENE:
                return navigationTypes.MASTER_COLORANTS_SCENE;
            case navigationTypes.SYSTEM_COLORS_SCENE:
            case navigationTypes.ZONE_COLORS_SCENE:
                return navigationTypes.MASTER_COLORS_SCENE;
            case navigationTypes.SYSTEM_FANDECKS_SCENE:
            case navigationTypes.ZONE_FANDECKS_SCENE:
                return navigationTypes.MASTER_FANDECKS_SCENE;
            case navigationTypes.SYSTEM_FORMULA_NOTES_SCENE:
            case navigationTypes.ZONE_FORMULA_NOTES_SCENE:
                return navigationTypes.MASTER_FORMULA_NOTES_SCENE;
            case navigationTypes.SYSTEM_FORMULAS_SCENE:
            case navigationTypes.ZONE_FORMULAS_SCENE:
                return navigationTypes.MASTER_FORMULAS_SCENE;
            case navigationTypes.SYSTEM_PRODUCTS_SCENE:
            case navigationTypes.ZONE_PRODUCTS_SCENE:
                return navigationTypes.MASTER_PRODUCTS_SCENE;
            case navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE:
            case navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE:
                return navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE;
            case navigationTypes.SYSTEM_REDLIKE_USERS_SCENE:
            case navigationTypes.ZONE_REDLIKE_USERS_SCENE:
                return navigationTypes.MASTER_REDLIKE_USERS_SCENE;
            default:
                return null;
        }
    };

    getSystemScene = (): Scene | null => {
        switch (this.props.activeScene) {
            case navigationTypes.MASTER_BASES_SCENE:
            case navigationTypes.ZONE_BASES_SCENE:
                return navigationTypes.SYSTEM_BASES_SCENE;
            case navigationTypes.MASTER_COLORANTS_SCENE:
            case navigationTypes.ZONE_COLORANTS_SCENE:
                return navigationTypes.SYSTEM_COLORANTS_SCENE;
            case navigationTypes.MASTER_COLORS_SCENE:
            case navigationTypes.ZONE_COLORS_SCENE:
                return navigationTypes.SYSTEM_COLORS_SCENE;
            case navigationTypes.MASTER_FANDECKS_SCENE:
            case navigationTypes.ZONE_FANDECKS_SCENE:
                return navigationTypes.SYSTEM_FANDECKS_SCENE;
            case navigationTypes.MASTER_FORMULA_NOTES_SCENE:
            case navigationTypes.ZONE_FORMULA_NOTES_SCENE:
                return navigationTypes.SYSTEM_FORMULA_NOTES_SCENE;
            case navigationTypes.MASTER_FORMULAS_SCENE:
            case navigationTypes.ZONE_FORMULAS_SCENE:
                return navigationTypes.SYSTEM_FORMULAS_SCENE;
            case navigationTypes.MASTER_PRODUCTS_SCENE:
            case navigationTypes.ZONE_PRODUCTS_SCENE:
                return navigationTypes.SYSTEM_PRODUCTS_SCENE;
            case navigationTypes.ZONE_PRODUCT_GROUPS_SCENE:
                return navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE;
            case navigationTypes.ZONE_PRICES_SCENE:
                return navigationTypes.SYSTEM_PRICES_SCENE;
            case navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE:
            case navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE:
                return navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE;
            case navigationTypes.MASTER_REDLIKE_USERS_SCENE:
            case navigationTypes.ZONE_REDLIKE_USERS_SCENE:
                return navigationTypes.SYSTEM_REDLIKE_USERS_SCENE;
            default:
                return null;
        }
    };

    getZoneScene = (): Scene | null => {
        switch (this.props.activeScene) {
            case navigationTypes.MASTER_BASES_SCENE:
            case navigationTypes.SYSTEM_BASES_SCENE:
                return navigationTypes.ZONE_BASES_SCENE;
            case navigationTypes.MASTER_COLORANTS_SCENE:
            case navigationTypes.SYSTEM_COLORANTS_SCENE:
                return navigationTypes.ZONE_COLORANTS_SCENE;
            case navigationTypes.MASTER_COLORS_SCENE:
            case navigationTypes.SYSTEM_COLORS_SCENE:
                return navigationTypes.ZONE_COLORS_SCENE;
            case navigationTypes.MASTER_FANDECKS_SCENE:
            case navigationTypes.SYSTEM_FANDECKS_SCENE:
                return navigationTypes.ZONE_FANDECKS_SCENE;
            case navigationTypes.MASTER_FORMULA_NOTES_SCENE:
            case navigationTypes.SYSTEM_FORMULA_NOTES_SCENE:
                return navigationTypes.ZONE_FORMULA_NOTES_SCENE;
            case navigationTypes.MASTER_FORMULAS_SCENE:
            case navigationTypes.SYSTEM_FORMULAS_SCENE:
                return navigationTypes.ZONE_FORMULAS_SCENE;
            case navigationTypes.MASTER_PRODUCTS_SCENE:
            case navigationTypes.SYSTEM_PRODUCTS_SCENE:
                return navigationTypes.ZONE_PRODUCTS_SCENE;
            case navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE:
                return navigationTypes.ZONE_PRODUCT_GROUPS_SCENE;
            case navigationTypes.SYSTEM_PRICES_SCENE:
                return navigationTypes.ZONE_PRICES_SCENE;
            case navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE:
            case navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE:
                return navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE;
            case navigationTypes.MASTER_REDLIKE_USERS_SCENE:
            case navigationTypes.SYSTEM_REDLIKE_USERS_SCENE:
                return navigationTypes.ZONE_REDLIKE_USERS_SCENE;
            default:
                return null;
        }
    };

    getChangelog = (): JSX.Element => {
        const releases = changelog?.releaseList || [];
        const releaseList = [];

        for (const release of releases) {
            releaseList.push(
                <div className="changelog-block" key={release.version}>
                    <dt className="version-title">Version {release.version ? release.version : ""}</dt>
                    <dd className="version-date">{release.date ? release.date : ""}</dd>
                    <dd className="version-content">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: release.message ? release.message : ""
                            }}
                        />
                        {release.link ? (
                            <a href={release.link} target="_blank" rel="noopener noreferrer">
                                More information
                            </a>
                        ) : (
                            <span />
                        )}
                    </dd>
                </div>
            );
        }

        return <div className="changelog-content">{releaseList}</div>;
    };

    getVersion = (): string => {
        const lastRelease = changelog?.releaseList?.[0] || null;

        return lastRelease?.version || "";
    };

    getHelpDropdown = (): JSX.Element => {
        return (
            <div className="dropdown-panel help-panel">
                <div className="dropdown-panel-content">
                    <div className="company-header">
                        <span className="lab-title">{this.props.productTitle}</span>
                        <div className="version-span">
                            <span>{translate("general.version")}:</span>
                            <span onClick={() => this.handleOpenDebugClick()}>{this.getVersion()}</span>
                            <div>
                                <span className="changelog-link" onClick={(): void => this.handleChangelogClick()}>
                                    {translate("general.changelog")}
                                </span>
                            </div>
                            <div>
                                <span>{`${String.fromCharCode(169)}${this.props.year} ${this.props.company}`}</span>
                            </div>
                        </div>
                        <img className="img-deso" src={imgDeso} alt="deso-logo" />
                        <button className="btn-md btn-info" onClick={(): void => this.handleLicenseInformationClick()}>
                            {translate("license.licenseInformation")}
                        </button>
                    </div>
                    <div className="help-title">{translate("help.helpNeed")}</div>
                    <div className="information-container">
                        <div className="information-title">{translate("license.email")}:</div>
                        <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
                    </div>
                    <div className="information-container">
                        <div className="information-title">{translate("help.hotline")}: </div>
                        <span>{this.props.phone}</span>
                    </div>
                    <div className="information-note">({translate("help.hoursAvailable")})</div>
                    {/* <div className="documentation-container">
                            {translate("help.orVisit")}
                            <button className="btn btn-link">{translate("help.documentationPage")}</button>
                        </div>*/}
                </div>
                <div className="dropdown-panel-footer">
                    {/* {translate("help.fastAnswer")}
                        <button className="btn btn-link">{translate("help.createTicket")}</button>*/}
                </div>
                <i />
            </div>
        );
    };

    getUserDropdown = (): JSX.Element => {
        return (
            <div className="dropdown-panel user-info-panel">
                <div className="dropdown-panel-content">
                    <div className="user-container">
                        <span>{this.props.loggedUser?.[propertyConstants.PROPERTY_USERNAME] || ""}</span>
                    </div>
                    <button className="btn btn-link" onClick={() => this.handleUserPasswordChangeClick()}>
                        {translate("general.changePassword")}
                    </button>
                </div>
                <div className="dropdown-panel-footer">
                    <button className="btn btn-info" onClick={() => this.props.logout()}>
                        {translate("general.signOut")}
                    </button>
                </div>
                <i />
            </div>
        );
    };

    getSettingsButton = (): JSX.Element | null => {
        if (this.state.tableSettings !== null) {
            return (
                <button className="btn btn-info" onClick={() => this.handleOpenSettingsSceneClick()}>
                    {translate("general.settings")}
                </button>
            );
        }
        return null;
    };

    getCompanyButton = (): JSX.Element | null => {
        if (this.state.tableCompanies !== null) {
            return (
                <button className="btn btn-info" onClick={() => this.handleOpenCompanySceneClick()}>
                    {translate("company.companySettings")}
                </button>
            );
        }
        return null;
    };

    getUserButton = (): JSX.Element | null => {
        if (this.state.tableUsers !== null || this.state.tableUserGroups !== null) {
            return (
                <button className="btn btn-info" onClick={() => this.handleOpenUserSceneClick()}>
                    {translate("user.userSettings")}
                </button>
            );
        }

        return null;
    };

    getOneTimeAccessPasswordButton = (): JSX.Element | null => {
        const replacements = {
            software: translationHelper.getSoftwareName(this.props.custom)
        };

        if (this.state.isOneTimeAccessPasswordEnabled && this.props.activeLicense) {
            return (
                <button
                    className="btn btn-info caption-long"
                    onClick={() => this.handleOpenOneTimeAccessPasswordModalClick()}
                >
                    {translate("oneTimeAccessPassword.generateOneTimeAccessPassword", replacements)}
                </button>
            );
        }

        return null;
    };

    getUserActionsHistoryButton = (): JSX.Element | null => {
        if (this.state.tableUserActionsHistory !== null) {
            return (
                <button className="btn btn-info" onClick={() => this.handleOpenUserActionsHistorySceneClick()}>
                    {translate("user.userActionsHistory")}
                </button>
            );
        }

        return null;
    };

    getServerMonitoringButton = (): JSX.Element | null => {
        if (this.state.tableMonitoring !== null) {
            return (
                <button className="btn btn-info" onClick={() => this.handleOpenServerMonitoringSceneClick()}>
                    {translate("general.serverMonitoring")}
                </button>
            );
        }

        return null;
    };

    getSettingsDropdown = (): JSX.Element => {
        return (
            <div className="dropdown-panel">
                <div className="dropdown-panel-content">
                    <div className="btn-column">
                        {this.getSettingsButton()}
                        {this.getCompanyButton()}
                        {this.getUserActionsHistoryButton()}
                        {this.getUserButton()}
                        {this.getServerMonitoringButton()}
                        {this.getOneTimeAccessPasswordButton()}
                    </div>
                </div>
                <i />
            </div>
        );
    };

    getDropdownIcon = (objectType: objectTypes.ObjectType): string => {
        switch (objectType) {
            case objectTypes.HEADER_SETTINGS:
                return imgSettings;
            case objectTypes.HELP:
                return imgHelp;
            case objectTypes.MESSAGE:
                return imgMessages;
            case objectTypes.USER:
                return imgUser;
            default:
                return imgHelp;
        }
    };

    getTabDropdownSearch = (objectType: objectTypes.ObjectType, search: string | null): JSX.Element => {
        return (
            <div className="tab-item search-input" onClick={(event: React.MouseEvent) => event.stopPropagation()}>
                <input
                    placeholder={translate("general.search")}
                    value={search || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
                        this.handleSearchInputChange(objectType, event.target.value)
                    }
                />
                <ReactSVG className="search-icon" src={imgSearch} />
            </div>
        );
    };

    getTabDropdownItems = (
        objectType: objectTypes.ObjectType,
        list: Array<Database | System | Zone>,
        activeItem: Database | System | Zone | null
    ): Array<JSX.Element> => {
        const items = [];

        // TODO + import imgDownload from "../resources/img/icons/download.svg";
        /* if (type === objectTypes.ZONE) {
            for (const item of data) {
                options.push(
                    <div key={item.id} className={this.getDropdownOptionClassName(active, item)}>
                        <ReactSVG className="pull-left item-icon" src={imgDownload} />
                        <span onClick={(): void => this.handleDropdownItemClick(type, item.id)}>{item.name}</span>
                    </div>
                );
            }
        } else {*/

        for (const item of list) {
            items.push(
                <div
                    key={item[propertyConstants.PROPERTY_ID]}
                    className={this.getTabDropdownItemClassName(item, activeItem)}
                    onClick={(event: React.MouseEvent): void =>
                        this.handleDropdownItemClick(event, objectType, item[propertyConstants.PROPERTY_ID])
                    }
                >
                    <span>{item?.[propertyConstants.PROPERTY_NAME] || ""}</span>
                </div>
            );
        }

        return items;
    };

    getTabDropdown = (objectType: objectTypes.ObjectType): JSX.Element | null => {
        let activeItem: Database | System | Zone | null = null;
        let allList: Array<Database | System | Zone> = [];
        let dropdownOn = false;
        let search = null;

        switch (objectType) {
            case objectTypes.DATABASE: {
                activeItem = this.props.activeDatabase;
                allList = this.props.databaseList;
                dropdownOn = this.state.databaseOn;
                search = this.state.databaseSearch;
                break;
            }
            case objectTypes.SYSTEM: {
                activeItem = this.props.activeSystem;
                allList = this.props.systemList;
                dropdownOn = this.state.systemOn;
                search = this.state.systemSearch;
                break;
            }
            case objectTypes.ZONE: {
                activeItem = this.props.activeZone;
                allList = this.props.zoneList;
                dropdownOn = this.state.zoneOn;
                search = this.state.zoneSearch;
                break;
            }
            default: {
                break;
            }
        }

        if (dropdownOn) {
            return (
                <div className="tab-dropdown-content">
                    {this.getTabDropdownSearch(objectType, search)}
                    <div className="slim-scroll dropdown-options">
                        {this.getTabDropdownItems(
                            objectType,
                            generalHelper.getFilteredList(allList, search),
                            activeItem
                        )}
                    </div>
                    {/* <button className="btn-link">{translate("general.createNew")}</button>*/}
                </div>
            );
        }

        return null;
    };

    getTabItem = (objectType: objectTypes.ObjectType, activeItem: Database | System | Zone | null): JSX.Element => {
        return (
            <div
                className={this.getTabClassName(objectType)}
                onClick={(): any => {
                    this.handleTabClick(objectType);
                }}
            >
                <div className="tab-content">
                    <div className="tab-info">
                        <div className="tab-type">{translationHelper.getHeaderTranslation(objectType)}</div>
                        <span className="tab-title">{activeItem?.[propertyConstants.PROPERTY_NAME] || ""}</span>
                    </div>
                    <button
                        className="btn-without-style"
                        onClick={(event: React.MouseEvent) => this.handleDropdownClick(event, objectType)}
                    >
                        <span className="caret"></span>
                    </button>
                </div>
                {this.getTabDropdown(objectType)}
            </div>
        );
    };

    getIconDropdown = (objectType: objectTypes.ObjectType): JSX.Element | null => {
        switch (objectType) {
            case objectTypes.HEADER_SETTINGS: {
                if (this.state.settingsOn) {
                    return this.getSettingsDropdown();
                }

                return null;
            }

            case objectTypes.HELP: {
                if (this.state.helpOn) {
                    return this.getHelpDropdown();
                }

                return null;
            }
            case objectTypes.USER: {
                if (this.state.userOn) {
                    return this.getUserDropdown();
                }

                return null;
            }
            default: {
                return null;
            }
        }
    };

    getSettingsItem = (): JSX.Element | null => {
        const { tableCompanies, tableMonitoring, tableSettings, tableUserActionsHistory, tableUsers, tableUserGroups } =
            this.state;

        if (
            tableCompanies !== null ||
            tableUsers !== null ||
            tableMonitoring !== null ||
            tableSettings !== null ||
            tableUserActionsHistory !== null ||
            tableUserGroups !== null
        ) {
            return this.getIconItem(objectTypes.HEADER_SETTINGS);
        }

        return null;
    };

    getIconItem = (objectType: objectTypes.ObjectType): JSX.Element => {
        return (
            <li className="top-menu-item" onClick={(event: any) => this.handleDropdownClick(event, objectType)}>
                <button className="btn-without-style">
                    <ReactSVG className="btn-icon" src={this.getDropdownIcon(objectType)} />
                </button>
                {this.getIconDropdown(objectType)}
                {objectType === objectTypes.MESSAGE ? this.getMessageCount() : null}
            </li>
        );
    };

    render(): JSX.Element {
        return (
            <header className="header" role="banner">
                <div className="main-tabs pull-left">
                    {this.getTabItem(objectTypes.DATABASE, this.props.activeDatabase)}
                    {this.getTabItem(objectTypes.SYSTEM, this.props.activeSystem)}
                    {this.getTabItem(objectTypes.ZONE, this.props.activeZone)}
                </div>
                <div className="pull-right top-menu">
                    <ul className="top-menu-items">
                        {this.getSettingsItem()}
                        {this.getIconItem(objectTypes.HELP)}
                        {this.getIconItem(objectTypes.MESSAGE)}
                        {this.getIconItem(objectTypes.USER)}
                    </ul>
                    <ul className="control-items"></ul>
                </div>
            </header>
        );
    }
}
