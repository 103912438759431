import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";
import { Barcode } from "../../../types/barcode";
import { ReactSVG } from "react-svg";

import imgClose from "../../../resources/img/icons/close.svg";
import imgPrimary from "../../../resources/img/icons/primary-barcode.svg";

type Props = {
    className: string;
    property: propertyConstants.Property;
    editable: boolean;
    required: boolean;
    value: string;
    barcodes: Array<Barcode>;
    innerRowIndex: number;
    callbackChange: (values: Array<Barcode>) => any;
};

export class ModalBarcode extends Component<Props> {
    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        barcodes: Array<Barcode>,
        barcode: Barcode | null
    ): void => {
        if (barcode) {
            barcode[propertyConstants.PROPERTY_BARCODE] = event.target.value;
            this.props.callbackChange(barcodes);
        }
    };

    handleInputResetClick = (barcodes: Array<Barcode>, barcode: Barcode): any => {
        barcodes = barcodes.filter((item: Barcode) => item !== barcode);
        this.props.callbackChange(barcodes);
    };

    handlePrimaryIconClick = (barcodes: Array<Barcode>, barcode: Barcode): any => {
        for (const item of barcodes) {
            if (item === barcode) {
                item[propertyConstants.PROPERTY_IS_PRIMARY] = !item[propertyConstants.PROPERTY_IS_PRIMARY];
            } else {
                item[propertyConstants.PROPERTY_IS_PRIMARY] = false;
            }
        }

        this.props.callbackChange(barcodes);
    };

    isDisabled = (barcode: Barcode): boolean => {
        if (!barcode) {
            return true;
        }

        return (
            !this.props.editable ||
            !barcode[propertyConstants.PROPERTY_CAN_DELETE] ||
            barcode[propertyConstants.PROPERTY_INHERITED]
        );
    };

    getClassName = (disabled: boolean): string => {
        let newClassName = this.props.className;

        if (disabled) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getPrimaryIcon = (
        barcodes: Array<Barcode>,
        barcode: Barcode,
        isPrimaryBarcodeInherited: boolean
    ): JSX.Element | null => {
        if (this.props.property === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES) {
            return null;
        }

        const className = !barcode[propertyConstants.PROPERTY_IS_PRIMARY]
            ? "primary-barcode not-primary"
            : "primary-barcode";

        if (this.props.value !== "" && !isPrimaryBarcodeInherited) {
            return (
                <div className={className} onClick={() => this.handlePrimaryIconClick(barcodes, barcode)}>
                    <ReactSVG className="svg-icon" src={imgPrimary} />
                </div>
            );
        }

        return (
            <div className={barcode[propertyConstants.PROPERTY_IS_PRIMARY] ? className : className + " disabled"}>
                <ReactSVG className="svg-icon" src={imgPrimary} />
            </div>
        );
    };

    getInputResetIcon = (disabled: boolean, barcodes: Array<Barcode>, barcode: Barcode): JSX.Element | null => {
        if (!disabled && (this.props.value !== "" || this.props.innerRowIndex > 0)) {
            return (
                <div className="close-icon" onClick={() => this.handleInputResetClick(barcodes, barcode)}>
                    <ReactSVG className="svg-icon" key={this.props.innerRowIndex} src={imgClose} />
                </div>
            );
        }

        return null;
    };

    render(): JSX.Element | null {
        const { barcodes } = this.props;

        const barcode = barcodes?.[this.props.innerRowIndex] || null;
        const disabled = this.isDisabled(barcode);
        const isPrimaryBarcodeInherited = barcodes.some(
            (item: Barcode) => item[propertyConstants.PROPERTY_INHERITED] && item[propertyConstants.PROPERTY_IS_PRIMARY]
        );

        if (barcode) {
            return (
                <div className={this.getClassName(disabled)}>
                    {this.getPrimaryIcon(barcodes, barcode, isPrimaryBarcodeInherited)}
                    <input
                        value={this.props.value}
                        disabled={disabled}
                        onFocus={(event): void => this.handleFocus(event)}
                        onChange={(event): void => this.handleChange(event, barcodes, barcode)}
                    />
                    {this.getInputResetIcon(disabled, barcodes, barcode)}
                </div>
            );
        }

        return null;
    }
}
