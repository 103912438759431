import * as propertyConstants from "../../constants/propertyConstants";

import React, { Component } from "react";

import { Bookmark } from "../../types/bookmark";
import { ReactSVG } from "react-svg";

import imgLoader from "../../resources/img/loader.svg";

type Props = {
    bookmarks: Array<Bookmark>;
    activeBookmark: string | null;
    modalId?: number;
    setActiveBookmarkCallback: (key: string | null) => void;
};

export class BookmarkTabs extends Component<Props> {
    componentDidMount(): void {
        if (this.props.activeBookmark === null && this.props.bookmarks.length) {
            const firstVisibleBookmark = this.props.bookmarks.find((item) => item.dataPrivileges?.canView) || null;
            this.props.setActiveBookmarkCallback(firstVisibleBookmark?.[propertyConstants.PROPERTY_KEY] || null);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        // If number or type of bookmarks changes (content does not matter), set first bookmark as active
        const prevBookmarks: Array<Bookmark> = [];
        const newBookmarks: Array<Bookmark> = [];
        prevProps.bookmarks.forEach((item) =>
            prevBookmarks.push(
                new Bookmark(item.key, item.type, item.title, item.properties, item.dataPrivileges, item.loading)
            )
        );
        this.props.bookmarks.forEach((item) =>
            newBookmarks.push(
                new Bookmark(item.key, item.type, item.title, item.properties, item.dataPrivileges, item.loading)
            )
        );

        // TODO komentare prosim kdy co ma nastavat, nema byt druhy if v elsu? + TODO pri activeBookmark reduceru jestli by nemelo byt specifikovano/ukladano jinak
        if (this.props.activeBookmark === null && prevProps.activeBookmark !== null) {
            const bookmark =
                this.props.bookmarks.find(
                    (item) => item.key === prevProps.activeBookmark && item.dataPrivileges?.canView
                ) || null;
            this.props.setActiveBookmarkCallback(bookmark?.[propertyConstants.PROPERTY_KEY] || null);
        }

        if (this.props.bookmarks.length && JSON.stringify(prevBookmarks) !== JSON.stringify(newBookmarks)) {
            const firstVisibleBookmark = this.props.bookmarks.find((item) => item.dataPrivileges?.canView) || null;
            this.props.setActiveBookmarkCallback(firstVisibleBookmark?.[propertyConstants.PROPERTY_KEY] || null);
        }
    }

    isVisible = () => {
        if (this.props.bookmarks.find((item) => item.dataPrivileges?.canView)) {
            return true;
        }

        return false;
    };

    getTabClassName = (key: string): string => {
        let className = "tab-links";

        if (key === this.props.activeBookmark) {
            className += " active";
        }

        return className;
    };

    getBookmarkContent = (): JSX.Element | null => {
        const { bookmarks } = this.props;

        for (const bookmark of bookmarks) {
            if (bookmark.key === this.props.activeBookmark && bookmark.dataPrivileges?.canView) {
                if (bookmark.loading) {
                    return (
                        <div className="new-color-container height-100 loader">
                            {" "}
                            <ReactSVG className="loader-svg" src={imgLoader} />
                        </div>
                    );
                }

                return bookmark.content ?? null;
            }
        }

        return null;
    };

    getButtonRow = (): JSX.Element => {
        const { bookmarks } = this.props;
        const buttons: Array<JSX.Element> = [];

        for (const bookmark of bookmarks) {
            if (bookmark.dataPrivileges?.canView) {
                buttons.push(
                    <button
                        className={this.getTabClassName(bookmark.key)}
                        key={bookmark.key}
                        onClick={(): void => this.props.setActiveBookmarkCallback(bookmark.key)}
                    >
                        {bookmark.title}
                    </button>
                );
            }
        }

        return <div className="tab-button-row">{buttons}</div>;
    };

    render(): JSX.Element | null {
        const className = this.props.modalId ? "bookmarks-content slim-scroll" : "bookmarks-content";
        if (!this.isVisible()) {
            return null;
        }

        return (
            <div className="tab-bookmarks">
                {this.getButtonRow()}
                <div className={className}>{this.getBookmarkContent()}</div>
            </div>
        );
    }
}
