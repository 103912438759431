import * as designConstants from "../../constants/designConstants";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { AppState } from "../../reducers";
import { ModalParams } from "../../types/modalParams";
import { ServerResponse } from "../../types/serverResponse";
import { mapDataSize } from "../../types/monitoring/dataSize";
import { translate } from "react-i18nify";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_DATA_SIZE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];
            const dataSize = mapDataSize(response.data);

            if (dataSize) {
                if (state.navigation.activeScene === navigationTypes.SERVER_MONITORING_SCENE) {
                    requests.push(universalObjectActions.saveItemsNoParams(objectTypes.DATA_SIZE, [dataSize]));
                } else {
                    const isModalOpened =
                        state.navigation.modalList.find(
                            (modal) =>
                                modal.type === modalTypes.MODAL_WARNING &&
                                modal?.params?.objectType === objectTypes.DATABASE_MONITORING
                        ) ?? null;
                    const data = [];

                    if (
                        dataSize[propertyConstants.PROPERTY_HDD_USED_PERCENT] * 100 >
                        designConstants.SERVER_MONITORING_ERROR_PROGRESS
                    ) {
                        data.push({
                            title: translate("general.exportImportData"),
                            used: dataSize[propertyConstants.PROPERTY_USED_HDD],
                            available: dataSize[propertyConstants.PROPERTY_AVAILABLE_HDD]
                        });
                    }
                    if (
                        dataSize[propertyConstants.PROPERTY_SSD_USED_PERCENT] * 100 >
                        designConstants.SERVER_MONITORING_ERROR_PROGRESS
                    ) {
                        data.push({
                            title: translate("database.masterDatabases"),
                            used: dataSize[propertyConstants.PROPERTY_USED_SSD],
                            available: dataSize[propertyConstants.PROPERTY_AVAILABLE_SSD]
                        });
                    }

                    if (data.length && !isModalOpened) {
                        const modalParams = new ModalParams(
                            modalTypes.PREVIEW_MODAL,
                            objectTypes.DATABASE_MONITORING,
                            translate("confirmationModal.warningDataSize"),
                            data
                        );
                        modalParams.displayText = translate("confirmationModal.warningDataSizeInfo");

                        requests.push(navigationActions.navigationOpenModal(modalTypes.MODAL_WARNING, modalParams));
                    }
                }
            }

            return requests;
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
