import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as objectTypes from "../constants/objectTypes";

import { ColorData } from "../types/colorData";
import { ServerRequest } from "./websocketActions";

export interface ColorDataUpdate {
    type: typeof actionConstants.UPDATE_COLOR_DATA;
    payload: {
        objectType: objectTypes.ObjectType; // object type is necessary to distinguish update in Edit color modal and in Color detail
        colorData: Array<ColorData>;
        spectroTypeId: number | null;
    };
}

export function updateColorData(
    objectType: objectTypes.ObjectType,
    colorData: Array<ColorData>,
    spectroTypeId: number | null
): ColorDataUpdate {
    return {
        type: actionConstants.UPDATE_COLOR_DATA,
        payload: {
            objectType,
            colorData,
            spectroTypeId
        }
    };
}

export function convertColorReflToLab(reflData: Array<Array<number>>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_COLOR_CONVERT_REFL_TO_LAB,
            params: {
                reflData
            }
        },
        method: methods.METHOD_COLOR_CONVERT_REFL_TO_LAB
    };
}

export function convertColorLabToRgb(l: number | null, a: number | null, b: number | null): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_COLOR_CONVERT_LAB_TO_RGB,
            params: {
                labData: [l, a, b]
            }
        },
        method: methods.METHOD_COLOR_CONVERT_LAB_TO_RGB
    };
}
