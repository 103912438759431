import * as constants from "../../../constants/inputConstants";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as priceHelper from "../../../helpers/priceHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";

import { Currency } from "../../../types/currency";
import { ModalInputButton } from "../modal/ModalInputButton";
import { ModalReadOnlyInput } from "./ModalReadOnlyInput";
import { Price } from "../../../types/price";
import { TableHeading } from "../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    objectType: objectTypes.ObjectType;
    heading: TableHeading;
    objectData: any;
    item: any;
    property: propertyConstants.Property;
    currency?: Currency | null;
    callbackChange: (event: any, value: any) => any;
};

export class ModalPriceGroup extends Component<Props> {
    getPriceGroupInput = (
        item: Price | null,
        property: propertyConstants.Property,
        type: string
    ): JSX.Element | null => {
        const { objectData, objectType } = this.props;

        if (item === null) {
            return null;
        }

        const unit = priceHelper.getPriceUnit(
            item[propertyConstants.PROPERTY_KEY],
            this.props.currency?.[propertyConstants.PROPERTY_SYMBOL] || ""
        );
        const priceIndex = objectData[property].indexOf(item);

        let previousValue = priceIndex >= 0 ? objectData[property][priceIndex - 1]?.value || null : null;

        if (objectType === objectTypes.SYSTEM_PRODUCT || objectType === objectTypes.ZONE_PRODUCT) {
            if (priceIndex === 0) {
                previousValue = 0;
            }
        }

        let value = item?.[propertyConstants.PROPERTY_VALUE]?.toString() || null;

        if (propertyHelper.isPropertyVat(property)) {
            value = formattingHelper.formatPercentWithoutSign(formattingHelper.formatPercent(value));
        }

        if (
            item[propertyConstants.PROPERTY_ID] ===
            `${propertyConstants.PROPERTY_PRICE_PRICE_GROUP}_${objectData[property].length}`
        ) {
            value = `[${translate("prices.maxValue")}]`;
        }

        switch (type) {
            case constants.PARAMETER_TYPE_BUTTON_INPUT: {
                return (
                    <ModalInputButton
                        objectType={objectType}
                        className={"form-input price-input"}
                        type={tableConstants.TABLE_TYPE_PRICE}
                        property={property}
                        editable={item.canUpdate}
                        required={false}
                        value={value ? value.toString() : ""}
                        unit={unit}
                        object={item}
                        callbackInputChange={(value: Price): void => {
                            this.props.callbackChange(property, value);
                        }}
                        callbackInputBlur={(value: Price): void => {
                            this.props.callbackChange(property, value);
                        }}
                        callbackButton={(value: Price): void => {
                            this.props.callbackChange(property, value);
                        }}
                    />
                );
            }
            case constants.PARAMETER_TYPE_TEXT_READONLY: {
                return (
                    <ModalReadOnlyInput
                        className={"form-input"}
                        objectType={objectType}
                        value={previousValue?.toString() || ""}
                        type={tableConstants.TABLE_TYPE_STRING}
                        property={propertyConstants.PROPERTY_RANGE}
                        unit={unit}
                    />
                );
            }
            default:
                return null;
        }
    };

    render(): JSX.Element {
        const { item, property } = this.props;

        return (
            <React.Fragment>
                <div className="form-label">
                    <label>{item.name}</label>
                </div>
                <div className="price-columns">
                    {this.getPriceGroupInput(item, property, constants.PARAMETER_TYPE_TEXT_READONLY)}
                    {this.getPriceGroupInput(item, property, constants.PARAMETER_TYPE_BUTTON_INPUT)}
                </div>
            </React.Fragment>
        );
    }
}
