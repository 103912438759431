import * as defaultConstants from "../constants/default";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Barcode, mapBarcodes } from "./barcode";
import { File, mapFiles } from "./file";
import { Price, mapPrice } from "./price";

import { BaseInProduct } from "./baseInProduct";
import { TableHeading } from "./tableHeading";

export class BaseInProductPackage {
    id: number; // corresponds to package ID
    name: string;
    baseInProductId: number | null;
    baseName: string | null;
    productId: number | null;
    productName: string | null;
    unitId?: number;
    nameSystem?: string;
    nameSource?: number;
    packageName?: string;
    nominalAmount?: number;
    parentId?: number | null;
    baseId?: number | null;
    baseInProductPackageId?: number | null;
    searchName?: string | null;
    searchNameSystem?: string | null;
    searchNameSource?: number;
    printName?: string | null;
    printNameSystem?: string | null;
    printNameSource?: number;
    code?: string;
    codeSystem?: string | null;
    codeSource?: number;
    fillVolume?: number | null;
    fillVolumeMl?: number | null;
    fillProc?: number | null;
    fillProcSystem?: number | null;
    fillProcSource?: number | null;
    moreInfo?: string | null;
    moreInfoSystem?: string | null;
    moreInfoSource?: number | null;
    isGravimetric?: number | null;
    density?: number | null;
    barcodes?: Array<Barcode>;
    alternativeBarcodes?: Array<Barcode>;
    imageId?: number | null;
    imageIdSystem?: number | null;
    imageIdSource?: number;
    uuid?: string | null;
    files?: Array<File>;
    priceGroupBarcodeList?: Array<Price>;
    // eslint-disable-next-line camelcase
    price_cost?: Price | null;
    // eslint-disable-next-line camelcase
    price_cost_per_kilogram?: Price | null;
    // eslint-disable-next-line camelcase
    price_cost_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_cost_per_unit?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_kilogram?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_unit?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_kilogram?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_unit?: Price | null;
    // eslint-disable-next-line camelcase
    price_surcharge?: Price | null;
    // eslint-disable-next-line camelcase
    price_vat?: Price | null;

    constructor(
        id: number,
        name: string,
        baseInProductId: number | null,
        baseName: string | null,
        productId: number | null,
        productName: string | null,
        unitId?: number,
        nameSystem?: string,
        nameSource?: number,
        packageName?: string,
        nominalAmount?: number,
        parentId?: number | null,
        baseId?: number | null,
        baseInProductPackageId?: number | null,
        searchName?: string | null,
        searchNameSystem?: string | null,
        searchNameSource?: number,
        printName?: string | null,
        printNameSystem?: string | null,
        printNameSource?: number,
        code?: string,
        codeSystem?: string | null,
        codeSource?: number,
        fillVolume?: number | null,
        fillVolumeMl?: number | null,
        fillProc?: number | null,
        fillProcSystem?: number | null,
        fillProcSource?: number | null,
        moreInfo?: string | null,
        moreInfoSystem?: string | null,
        moreInfoSource?: number | null,
        isGravimetric?: number | null,
        density?: number | null,
        barcodes?: Array<Barcode>,
        alternativeBarcodes?: Array<Barcode>,
        imageId?: number | null,
        imageIdSystem?: number | null,
        imageIdSource?: number,
        uuid?: string | null,
        files?: Array<File>,
        priceGroupBarcodeList?: Array<Price>,
        // eslint-disable-next-line camelcase
        price_cost?: Price | null,
        // eslint-disable-next-line camelcase
        price_cost_per_kilogram?: Price | null,
        // eslint-disable-next-line camelcase
        price_cost_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_cost_per_unit?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_kilogram?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_unit?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_kilogram?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_unit?: Price | null,
        // eslint-disable-next-line camelcase
        price_surcharge?: Price | null,
        // eslint-disable-next-line camelcase
        price_vat?: Price | null
    ) {
        this.id = id;
        this.name = name;
        this.baseInProductId = baseInProductId;
        this.baseName = baseName;
        this.productId = productId;
        this.productName = productName;
        this.unitId = unitId;
        this.nameSystem = nameSystem;
        this.nameSource = nameSource;
        this.packageName = packageName;
        this.nominalAmount = nominalAmount;
        this.parentId = parentId;
        this.baseId = baseId;
        this.baseInProductPackageId = baseInProductPackageId;
        this.searchName = searchName;
        this.searchNameSystem = searchNameSystem;
        this.searchNameSource = searchNameSource;
        this.printName = printName;
        this.printNameSystem = printNameSystem;
        this.printNameSource = printNameSource;
        this.code = code;
        this.codeSystem = codeSystem;
        this.codeSource = codeSource;
        this.fillVolume = fillVolume;
        this.fillVolumeMl = fillVolumeMl;
        this.fillProc = fillProc;
        this.fillProcSystem = fillProcSystem;
        this.fillProcSource = fillProcSource;
        this.moreInfo = moreInfo;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.isGravimetric = isGravimetric;
        this.density = density;
        this.barcodes = barcodes;
        this.alternativeBarcodes = alternativeBarcodes;
        this.imageId = imageId;
        this.imageIdSystem = imageIdSystem;
        this.imageIdSource = imageIdSource;
        this.uuid = uuid;
        this.files = files;
        this.priceGroupBarcodeList = priceGroupBarcodeList;
        // eslint-disable-next-line camelcase
        this.price_cost = price_cost;
        // eslint-disable-next-line camelcase
        this.price_cost_per_kilogram = price_cost_per_kilogram;
        // eslint-disable-next-line camelcase
        this.price_cost_per_liter = price_cost_per_liter;
        // eslint-disable-next-line camelcase
        this.price_cost_per_unit = price_cost_per_unit;
        // eslint-disable-next-line camelcase
        this.price_purchase = price_purchase;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_kilogram = price_purchase_per_kilogram;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_liter = price_purchase_per_liter;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_unit = price_purchase_per_unit;
        // eslint-disable-next-line camelcase
        this.price_sell = price_sell;
        // eslint-disable-next-line camelcase
        this.price_sell_per_kilogram = price_sell_per_kilogram;
        // eslint-disable-next-line camelcase
        this.price_sell_per_liter = price_sell_per_liter;
        // eslint-disable-next-line camelcase
        this.price_sell_per_unit = price_sell_per_unit;
        // eslint-disable-next-line camelcase
        this.price_surcharge = price_surcharge;
        // eslint-disable-next-line camelcase
        this.price_vat = price_vat;
    }
}

export function mapSimpleBaseInProductPackage(data: Record<string, any>): BaseInProductPackage | null {
    try {
        return new BaseInProductPackage(
            data.packageId,
            data.packageName,
            data.baseInProductId ? data.baseInProductId : null,
            data.baseName ? data.baseName : null,
            data.productId ? data.productId : null,
            data.productName ? data.productName : null
        );
    } catch (e) {
        return null;
    }
}

export function mapBaseInProductPackage(
    packageItem: Record<string, any>,
    baseInProductPackageItem: Record<string, any>,
    baseInProduct: BaseInProduct | null
): BaseInProductPackage | null {
    try {
        let priceGroupBarcodeList: Array<Price> = [];
        let priceGroupBarcode: Price | null;
        let alternativeBarcodeList: Array<Barcode> | undefined = undefined;
        let barcodeList: Array<Barcode> | undefined = undefined;

        if (baseInProductPackageItem && baseInProductPackageItem.prices) {
            for (const key of Object.keys(baseInProductPackageItem.prices)) {
                if (
                    baseInProductPackageItem.prices[key] &&
                    baseInProductPackageItem.prices[key][propertyConstants.PROPERTY_PRICE_TYPE_ID] ===
                        priceConstants.PRICE_TYPE_PRICE_GROUP_BARCODE
                ) {
                    priceGroupBarcode = mapPrice(key, baseInProductPackageItem.prices[key]);

                    if (priceGroupBarcode) {
                        priceGroupBarcodeList.push(priceGroupBarcode);
                    }
                }
            }
            priceGroupBarcodeList = generalHelper.sortItemsByProperty(
                priceGroupBarcodeList,
                propertyConstants.PROPERTY_PRICE_GROUP,
                true
            );
        }

        if (baseInProductPackageItem && baseInProductPackageItem.barcodes) {
            alternativeBarcodeList = baseInProductPackageItem.barcodes.filter(
                (barcode: any) => barcode[propertyConstants.PROPERTY_ADDITIONAL]
            );
            barcodeList = baseInProductPackageItem.barcodes.filter(
                (barcode: any) => !barcode[propertyConstants.PROPERTY_ADDITIONAL]
            );
        }

        return new BaseInProductPackage(
            packageItem ? packageItem.id : null,
            baseInProductPackageItem?.name ?? packageItem?.name ?? null,
            baseInProductPackageItem ? baseInProductPackageItem.baseInProductId : null,
            baseInProductPackageItem ? baseInProductPackageItem.baseName : null,
            baseInProductPackageItem ? baseInProductPackageItem.productId : null,
            baseInProductPackageItem ? baseInProductPackageItem.productName : null,
            packageItem ? packageItem.unitId : null,
            baseInProductPackageItem?.nameSystem ?? null,
            baseInProductPackageItem?.nameSource ?? null,
            packageItem?.name ?? null,
            packageItem ? packageItem.nominalAmount : null,
            baseInProductPackageItem ? baseInProductPackageItem.parentId : null,
            baseInProductPackageItem ? baseInProductPackageItem.baseId : null,
            baseInProductPackageItem ? baseInProductPackageItem.id : null,
            baseInProductPackageItem ? baseInProductPackageItem.searchName : null,
            baseInProductPackageItem ? baseInProductPackageItem.searchNameSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.searchNameSource : null,
            baseInProductPackageItem ? baseInProductPackageItem.printName : null,
            baseInProductPackageItem ? baseInProductPackageItem.printNameSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.printNameSource : null,
            baseInProductPackageItem ? baseInProductPackageItem.code : null,
            baseInProductPackageItem ? baseInProductPackageItem.codeSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.codeSource : null,
            baseInProductPackageItem && baseInProductPackageItem.fillVolume
                ? Number(baseInProductPackageItem.fillVolume.toFixed(defaultConstants.DEFAULT_ROUNDING))
                : null,
            baseInProductPackageItem && baseInProductPackageItem.fillVolumeMl
                ? Number(baseInProductPackageItem.fillVolumeMl.toFixed(defaultConstants.DEFAULT_ROUNDING))
                : null,
            baseInProductPackageItem ? baseInProductPackageItem.fillProc : null,
            baseInProductPackageItem ? baseInProductPackageItem.fillProcSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.fillProcSource : null,
            baseInProductPackageItem ? baseInProductPackageItem.moreInfo : null,
            baseInProductPackageItem ? baseInProductPackageItem.moreInfoSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.moreInfoSource : null,
            packageItem ? packageItem.isGravimetric : null,
            baseInProduct && baseInProduct.density !== undefined ? baseInProduct.density : null,
            barcodeList ? mapBarcodes(barcodeList) : undefined,
            alternativeBarcodeList ? mapBarcodes(alternativeBarcodeList) : undefined,
            baseInProductPackageItem ? baseInProductPackageItem.imageId : null,
            baseInProductPackageItem ? baseInProductPackageItem.imageIdSystem : null,
            baseInProductPackageItem ? baseInProductPackageItem.imageIdSource : null,
            baseInProductPackageItem ? baseInProductPackageItem.uuid : null,
            baseInProductPackageItem && baseInProductPackageItem.files
                ? mapFiles(baseInProductPackageItem.files)
                : undefined,
            priceGroupBarcodeList,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_COST,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                      )
                    : undefined
                : undefined,
            packageItem?.isGravimetric || 0
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM] || null
                  )
                : mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null
                  ),
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_PURCHASE,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                      )
                    : undefined
                : undefined,
            packageItem?.isGravimetric || 0
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM] || null
                  )
                : mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null
                  ),
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_SELL,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                      )
                    : undefined
                : undefined,
            packageItem?.isGravimetric || 0
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM] || null
                  )
                : mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                      baseInProductPackageItem?.prices?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null
                  ),
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SURCHARGE]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_SURCHARGE,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_SURCHARGE]
                      )
                    : undefined
                : undefined,
            baseInProductPackageItem
                ? baseInProductPackageItem.prices &&
                  baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_VAT]
                    ? mapPrice(
                          propertyConstants.PROPERTY_PRICE_VAT,
                          baseInProductPackageItem.prices[propertyConstants.PROPERTY_PRICE_VAT]
                      )
                    : undefined
                : undefined
        );
    } catch (e) {
        return null;
    }
}

export function updateBaseInProductPackage(
    oldBaseInProductPackage: BaseInProductPackage | null,
    updatedData: Record<string, any>
): BaseInProductPackage | null {
    try {
        if (oldBaseInProductPackage === null) {
            return null;
        }

        const inheritedBarcodes =
            oldBaseInProductPackage.barcodes?.filter((barcode) => barcode[propertyConstants.PROPERTY_INHERITED]) || [];
        const updatedBarcodes = inheritedBarcodes.concat(updatedData.barcodes || []);

        return new BaseInProductPackage(
            oldBaseInProductPackage.id,
            updatedData.name ? updatedData.name : oldBaseInProductPackage.name,
            oldBaseInProductPackage.baseInProductId,
            oldBaseInProductPackage.baseName,
            oldBaseInProductPackage.productId,
            oldBaseInProductPackage.productName,
            oldBaseInProductPackage.unitId,
            oldBaseInProductPackage.nameSystem,
            oldBaseInProductPackage.nameSource,
            updatedData.packageName ? updatedData.packageName : oldBaseInProductPackage.packageName,
            updatedData.nominalAmount ? updatedData.nominalAmount : oldBaseInProductPackage.nominalAmount,
            oldBaseInProductPackage.parentId,
            oldBaseInProductPackage.baseId,
            oldBaseInProductPackage.baseInProductPackageId,
            updatedData.searchName ? updatedData.searchName : oldBaseInProductPackage.searchName,
            oldBaseInProductPackage.searchNameSystem,
            oldBaseInProductPackage.searchNameSource,
            updatedData.printName ? updatedData.printName : oldBaseInProductPackage.printName,
            oldBaseInProductPackage.printNameSystem,
            oldBaseInProductPackage.printNameSource,
            updatedData.code ? updatedData.code : oldBaseInProductPackage.code,
            oldBaseInProductPackage.codeSystem,
            oldBaseInProductPackage.codeSource,
            oldBaseInProductPackage.fillVolume,
            oldBaseInProductPackage.fillVolumeMl,
            updatedData.fillProc ? updatedData.fillProc : oldBaseInProductPackage.fillProc,
            oldBaseInProductPackage.fillProcSystem,
            oldBaseInProductPackage.fillProcSource,
            updatedData.moreInfo ? updatedData.moreInfo : oldBaseInProductPackage.moreInfo,
            oldBaseInProductPackage.moreInfoSystem,
            oldBaseInProductPackage.moreInfoSource,
            updatedData.isGravimetric ? updatedData.isGravimetric : oldBaseInProductPackage.isGravimetric,
            updatedData.density ? updatedData.density : oldBaseInProductPackage.density,
            mapBarcodes(updatedBarcodes),
            updatedData.alternativeBarcodes
                ? mapBarcodes(updatedData.alternativeBarcodes)
                : oldBaseInProductPackage.alternativeBarcodes,
            updatedData.imageId ? updatedData.imageId : oldBaseInProductPackage.imageId,
            oldBaseInProductPackage.imageIdSystem,
            oldBaseInProductPackage.imageIdSource,
            updatedData.uuid ? updatedData.uuid : oldBaseInProductPackage.uuid,
            oldBaseInProductPackage.files,
            oldBaseInProductPackage.priceGroupBarcodeList ? oldBaseInProductPackage?.priceGroupBarcodeList : [],
            updatedData.price_cost ? updatedData.price_cost : oldBaseInProductPackage.price_cost,
            updatedData.price_cost_per_kilogram
                ? updatedData.price_cost_per_kilogram
                : oldBaseInProductPackage.price_cost_per_kilogram,
            updatedData.price_cost_per_liter
                ? updatedData.price_cost_per_liter
                : oldBaseInProductPackage.price_cost_per_liter,
            updatedData.price_cost_per_unit
                ? updatedData.price_cost_per_unit
                : oldBaseInProductPackage.price_cost_per_unit,
            updatedData.price_purchase ? updatedData.price_purchase : oldBaseInProductPackage.price_purchase,
            updatedData.price_purchase_per_kilogram
                ? updatedData.price_purchase_per_kilogram
                : oldBaseInProductPackage.price_purchase_per_kilogram,
            updatedData.price_purchase_per_liter
                ? updatedData.price_purchase_per_liter
                : oldBaseInProductPackage.price_purchase_per_liter,
            updatedData.price_purchase_per_unit
                ? updatedData.price_purchase_per_unit
                : oldBaseInProductPackage.price_purchase_per_unit,
            updatedData.price_sell ? updatedData.price_sell : oldBaseInProductPackage.price_sell,
            updatedData.price_sell_per_kilogram
                ? updatedData.price_sell_per_kilogram
                : oldBaseInProductPackage.price_sell_per_kilogram,
            updatedData.price_sell_per_liter
                ? updatedData.price_sell_per_liter
                : oldBaseInProductPackage.price_sell_per_liter,
            updatedData.price_sell_per_unit
                ? updatedData.price_sell_per_unit
                : oldBaseInProductPackage.price_sell_per_unit,
            updatedData.price_surcharge ? updatedData.price_surcharge : oldBaseInProductPackage.price_surcharge,
            updatedData.price_vat ? updatedData.price_vat : oldBaseInProductPackage.price_vat
        );
    } catch (e) {
        return null;
    }
}

/*
export function createEmptyBaseInProductPackage(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_UNIT_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: "",
        [propertyConstants.PROPERTY_PARENT_ID]: "",
        [propertyConstants.PROPERTY_BASE_ID]: "",
        [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: "",
        [propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]: "",
        [propertyConstants.PROPERTY_PRODUCT_ID]: "",
        [propertyConstants.PROPERTY_BASE_NAME]: "",
        [propertyConstants.PROPERTY_PRODUCT_NAME]: "",
        [propertyConstants.PROPERTY_SEARCH_NAME]: "",
        [propertyConstants.PROPERTY_PRINT_NAME]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_FILL_VOLUME]: "",
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: "",
        [propertyConstants.PROPERTY_FILL_PROC]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_BARCODES]: [],
        [propertyConstants.PROPERTY_IMAGE_ID]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_FILES]: [],
        [propertyConstants.PROPERTY_PRICE_COST]: "",
        [propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM]: "",
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_COST_PER_UNIT]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT]: "",
        [propertyConstants.PROPERTY_PRICE_SELL]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT]: "",
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: "",
        [propertyConstants.PROPERTY_PRICE_VAT]: ""
    };
}
*/

export function generateBaseInProductPackageData(list: Array<BaseInProductPackage>): Array<any> {
    const data: Array<any> = [];
    let item: BaseInProductPackage;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_UNIT_ID]: item.unitId,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_NAME_SYSTEM]: item.nameSystem,
            [propertyConstants.PROPERTY_NAME_SOURCE]: item.nameSource,
            [propertyConstants.PROPERTY_PACKAGE_NAME]: item.packageName,
            [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: item.nominalAmount,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_BASE_ID]: item.baseId,
            [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: item.baseInProductId,
            [propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]: item.baseInProductPackageId,
            [propertyConstants.PROPERTY_PRODUCT_ID]: item.productId,
            [propertyConstants.PROPERTY_BASE_NAME]: item.baseName,
            [propertyConstants.PROPERTY_PRODUCT_NAME]: item.productName,
            [propertyConstants.PROPERTY_SEARCH_NAME]: item.searchName,
            [propertyConstants.PROPERTY_SEARCH_NAME_SYSTEM]: item.searchNameSystem,
            [propertyConstants.PROPERTY_SEARCH_NAME_SOURCE]: item.searchNameSource,
            [propertyConstants.PROPERTY_PRINT_NAME]: item.printName,
            [propertyConstants.PROPERTY_PRINT_NAME_SYSTEM]: item.printNameSystem,
            [propertyConstants.PROPERTY_PRINT_NAME_SOURCE]: item.printNameSource,
            [propertyConstants.PROPERTY_CODE]: item.code,
            [propertyConstants.PROPERTY_CODE_SYSTEM]: item.codeSystem,
            [propertyConstants.PROPERTY_CODE_SOURCE]: item.codeSource,
            [propertyConstants.PROPERTY_FILL_VOLUME]: item.fillVolume,
            [propertyConstants.PROPERTY_FILL_VOLUME_ML]: item.fillVolumeMl,
            [propertyConstants.PROPERTY_FILL_PROC]: item.fillProc,
            [propertyConstants.PROPERTY_FILL_PROC_SYSTEM]: item.fillProcSystem,
            [propertyConstants.PROPERTY_FILL_PROC_SOURCE]: item.fillProcSource,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: item.isGravimetric,
            [propertyConstants.PROPERTY_DENSITY]: item.density,
            [propertyConstants.PROPERTY_BARCODES]: item.barcodes,
            [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]: item.alternativeBarcodes,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.imageId,
            [propertyConstants.PROPERTY_IMAGE_ID_SYSTEM]: item.imageIdSystem,
            [propertyConstants.PROPERTY_IMAGE_ID_SOURCE]: item.imageIdSource,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_FILES]: item.files,
            [propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST]: item.priceGroupBarcodeList,
            [propertyConstants.PROPERTY_PRICE_COST]: item.price_cost,
            [propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM]: item.price_cost_per_kilogram,
            [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: item.price_cost_per_liter,
            [propertyConstants.PROPERTY_PRICE_COST_PER_UNIT]: item.price_cost_per_unit,
            [propertyConstants.PROPERTY_PRICE_PURCHASE]: item.price_purchase,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM]: item.price_purchase_per_kilogram,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: item.price_purchase_per_liter,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT]: item.price_purchase_per_unit,
            [propertyConstants.PROPERTY_PRICE_SELL]: item.price_sell,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM]: item.price_sell_per_kilogram,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: item.price_sell_per_liter,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT]: item.price_sell_per_unit,
            [propertyConstants.PROPERTY_PRICE_SURCHARGE]: item.price_surcharge,
            [propertyConstants.PROPERTY_PRICE_VAT]: item.price_vat
        });
    }

    return data;
}

export function generateBaseInProductPackageHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ALTERNATIVE_BARCODES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            editableList.includes(propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            requiredList.includes(propertyConstants.PROPERTY_ALTERNATIVE_BARCODES),
            [],
            widthOption?.[propertyConstants.PROPERTY_ALTERNATIVE_BARCODES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BARCODES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODES),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BARCODES),
            editableList.includes(propertyConstants.PROPERTY_BARCODES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BARCODES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BARCODES),
            requiredList.includes(propertyConstants.PROPERTY_BARCODES),
            [],
            widthOption?.[propertyConstants.PROPERTY_BARCODES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DIVIDING_LINE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.TABLE_TYPE_LINE,
            visibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            editableList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            requiredList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILES),
            tableConstants.TABLE_TYPE_FILE,
            visibleList.includes(propertyConstants.PROPERTY_FILES),
            editableList.includes(propertyConstants.PROPERTY_FILES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILES),
            requiredList.includes(propertyConstants.PROPERTY_FILES),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILL_PROC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            editableList.includes(propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILL_PROC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            requiredList.includes(propertyConstants.PROPERTY_FILL_PROC),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILL_PROC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILL_VOLUME_ML,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILL_VOLUME_ML),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            editableList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            requiredList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILL_VOLUME_ML] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            false,
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NOMINAL_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            [],
            widthOption?.[propertyConstants.PROPERTY_NOMINAL_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PACKAGE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PACKAGE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            editableList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            false,
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PACKAGE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_UNIT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_UNIT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            editableList.includes(propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM
            ),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SURCHARGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SURCHARGE),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SURCHARGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_VAT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_VAT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_VAT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRINT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRINT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRINT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SEARCH_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            editableList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SEARCH_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
