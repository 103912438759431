import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationConstants from "../constants/translationConstants";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

import { getAllLanguages } from "../lang/translations";
import { translate } from "react-i18nify";

export class TranslationManagement {
    actionType: translationConstants.TranslationActionType;
    fileType: translationConstants.TranslationFileType;
    language: translationConstants.Language;
    value: string;

    constructor(
        actionType: translationConstants.TranslationActionType,
        fileType: translationConstants.TranslationFileType,
        language: translationConstants.Language,
        value: string
    ) {
        this.actionType = actionType;
        this.fileType = fileType;
        this.language = language;
        this.value = value;
    }
}

export function createEmptyTranslationManagement(): any {
    return {
        [propertyConstants.PROPERTY_ACTION_TYPE]: "",
        [propertyConstants.PROPERTY_FILE_TYPE]: "",
        [propertyConstants.PROPERTY_LANGUAGE]: "",
        [propertyConstants.PROPERTY_VALUE]: ""
    };
}

export function generateTranslationManagementHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const actionTypeList = [
        { id: translationConstants.TRANSLATION_ACTION_TYPE_IMPORT, name: translate("general.import") },
        { id: translationConstants.TRANSLATION_ACTION_TYPE_EXPORT, name: translate("general.export") }
    ];

    const fileTypeList = [
        { id: translationConstants.TRANSLATION_FILE_TYPE_CSV, name: translate("options.translations.fileTypeCsv") },
        {
            id: translationConstants.TRANSLATION_FILE_TYPE_CSV_NEW,
            name: translate("options.translations.fileTypeCsvNew")
        }
    ];

    const languageList = getAllLanguages().map((item) => {
        return { id: item[propertyConstants.PROPERTY_CODE], name: item[propertyConstants.PROPERTY_NAME] };
    });

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ACTION_TYPE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION_TYPE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_ACTION_TYPE),
            editableList.includes(propertyConstants.PROPERTY_ACTION_TYPE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION_TYPE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION_TYPE),
            requiredList.includes(propertyConstants.PROPERTY_ACTION_TYPE),
            actionTypeList,
            widthOption?.[propertyConstants.PROPERTY_ACTION_TYPE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILE_TYPE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE_TYPE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FILE_TYPE),
            editableList.includes(propertyConstants.PROPERTY_FILE_TYPE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE_TYPE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE_TYPE),
            requiredList.includes(propertyConstants.PROPERTY_FILE_TYPE),
            fileTypeList,
            widthOption?.[propertyConstants.PROPERTY_FILE_TYPE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LANGUAGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LANGUAGE),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_LANGUAGE),
            editableList.includes(propertyConstants.PROPERTY_LANGUAGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LANGUAGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LANGUAGE),
            requiredList.includes(propertyConstants.PROPERTY_LANGUAGE),
            languageList,
            widthOption?.[propertyConstants.PROPERTY_LANGUAGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_VALUE),
            editableList.includes(propertyConstants.PROPERTY_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_VALUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_VALUE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
