import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as modalConstants from "../constants/modalTypes";

import { NavigationModalOpen } from "./navigationActions";
import { ServerRequest } from "./websocketActions";

export function setActiveZone(id: number, confirmed: boolean): ServerRequest | NavigationModalOpen {
    if (!confirmed) {
        return {
            type: actionConstants.NAVIGATION_MODAL_OPEN,
            payload: {
                type: modalConstants.MODAL_SAVE_DATA,
                nextAction: {
                    type: actionConstants.SERVER_REQUEST,
                    payload: {
                        type: methods.METHOD_SET_ACTIVE_ZONE,
                        params: {
                            zoneId: id
                        }
                    },
                    method: methods.METHOD_SET_ACTIVE_ZONE
                },
                params: {}
            }
        };
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_ACTIVE_ZONE,
            params: {
                zoneId: id
            }
        },
        method: methods.METHOD_SET_ACTIVE_ZONE
    };
}
