export class Script {
    id: number;
    name: string;
    description: string;
    lastRun: string | null;
    running: boolean;
    resultCode: number | null;
    output: string | null;
    errorText: string | null;

    constructor(
        id: number,
        name: string,
        description: string,
        lastRun: string | null,
        running: boolean,
        resultCode: number | null,
        output: string | null,
        errorText: string | null
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.lastRun = lastRun;
        this.running = running;
        this.resultCode = resultCode;
        this.output = output;
        this.errorText = errorText;
    }
}

export function mapScript(data: Record<string, any>): Script | null {
    try {
        return new Script(
            data.id,
            data.name,
            data.description,
            data.lastRun,
            data.running,
            data.lastResult ? data.lastResult.returnCode : null,
            data.lastResult ? data.lastResult.output : null,
            data.lastResult ? data.lastResult.error : null
        );
    } catch (e) {
        return null;
    }
}
