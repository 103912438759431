import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterRedlikeOptionTable } from "./tables/MasterRedlikeOptionTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterRedlikeOptionSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

export const MasterRedlikeOptionScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableOptions, setTableOptions] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.optionTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableOptions(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.optionTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.optionTableConstant]);

    const getOptionTable = (): JSX.Element | null => {
        if (tableOptions) {
            return (
                <MasterRedlikeOptionTable
                    tableConstant={props.optionTableConstant}
                    objectType={objectTypes.MASTER_REDLIKE_OPTION}
                    dataPrivileges={tableOptions}
                    loading={props.optionLoading}
                    buttonLoading={props.optionButtonLoading}
                    allList={props.optionList}
                    activeList={props.optionActiveList}
                    columnOrder={props.optionColumnOrder}
                    columnVisibility={props.optionColumnVisibility}
                    columnWidth={props.optionColumnWidth}
                    search={props.optionSearch}
                    searchParams={props.optionSearchParams}
                    showDefaultValues={props.optionShowDefaultValues}
                    showFilterRow={props.optionShowFilterRow}
                    showGlobalSearch={props.optionShowGlobalSearch}
                    sortingAsc={props.optionSortingAsc}
                    sortingCriterion={props.optionSortingCriterion}
                    totalCount={props.optionTotalCount}
                    custom={props.custom}
                />
            );
        }
        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={[getOptionTable()].filter((item) => item !== null) as Array<JSX.Element>}
            layout={props.sceneLayout}
            sceneTableSize={props.optionSceneTableSize}
            dataPrivileges={tableOptions}
            sceneConstant={menuConstants.PAGE_MASTER_REDLIKE_OPTIONS}
        />
    );
};
