import React, { Component } from "react";
import { DataItem } from "../../../../types/dataItem";
import { Scene } from "../../../../constants/navigationTypes";

type Props = {
    scenes: Array<{ scene: Scene; title: string; dataPrivileges: DataItem | null }>;
    activeScene: Scene;
    setActiveSceneCallback: (scene: Scene) => void;
};

export class SceneSwitchButtons extends Component<Props> {
    isVisible = () => {
        if (this.props.scenes.find((item) => !item.dataPrivileges?.canView)) {
            return false;
        }

        return true;
    };

    getTabClassName = (scene: string): string => {
        let className = "btn-md btn-info";

        if (scene === this.props.activeScene) {
            className += " active";
        }

        return className;
    };

    getButtonRow = (): JSX.Element => {
        const { scenes } = this.props;
        const buttons: Array<JSX.Element> = [];

        for (const scene of scenes) {
            if (scene.dataPrivileges?.canView) {
                buttons.push(
                    <button
                        className={this.getTabClassName(scene.scene)}
                        key={scene.scene}
                        onClick={(): void => this.props.setActiveSceneCallback(scene.scene)}
                    >
                        {scene.title}
                    </button>
                );
            }
        }

        return <div className="tab-button-row">{buttons}</div>;
    };

    render(): JSX.Element | null {
        if (!this.isVisible()) {
            return null;
        }

        return <div className="scene-buttons-container">{this.getButtonRow()}</div>;
    }
}
