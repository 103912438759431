import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { HomePage } from "../../components/scenes/trash/HomePage";
import { connect } from "react-redux";

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(HomePage);
