import * as formattingHelper from "../../../helpers/formattingHelper";
import * as modalTypes from "../../../constants/modalTypes";
import * as navigationTypes from "../../../constants/navigationTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as serverConstants from "../../../constants/serverConstants";

import { DispatchType, PropsType } from "../../../containers/scenes/data/ServerScriptsSceneContainer";
import React, { Component } from "react";

import { ReactSVG } from "react-svg";
import { Script } from "../../../types/script";

import imgError from "../../../resources/img/icons/error.svg";
import imgLoader from "../../../resources/img/mini-loader.svg";
import imgSuccess from "../../../resources/img/icons/success.svg";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    intervalId: NodeJS.Timeout | null;
};

export class ServerScriptsScene extends Component<Props, State> {
    state: State = {
        intervalId: null
    };

    componentDidMount() {
        const running = this.props.scriptsList.some((item: Script) => item[propertyConstants.PROPERTY_RUNNING]);

        if (running) {
            this.setReloadScriptsInterval();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const running = this.props.scriptsList.some((item: Script) => item[propertyConstants.PROPERTY_RUNNING]);

        if (running && !this.state.intervalId) {
            this.setReloadScriptsInterval();
        }

        if (!running && this.state.intervalId) {
            clearInterval(this.state.intervalId);
            this.setState({ intervalId: null });
        }
    }

    componentWillUnmount(): void {
        const { intervalId } = this.state;

        if (intervalId) {
            clearInterval(intervalId);
        }
    }

    setReloadScriptsInterval = () => {
        const interval = setInterval(() => {
            if (this.props.activeScene === navigationTypes.DATA_SERVER_SCRIPTS_SCENE) {
                this.props.getAllItems(objectTypes.SERVER_SCRIPTS);
            }
        }, serverConstants.SERVER_SCRIPT_RELOAD_TIMEOUT);

        this.setState({ intervalId: interval });
    };

    handleRunScriptClick = (scriptId: number): void => {
        this.props.runScript(scriptId);
    };

    getErrorButton = (resultCode: number | null, error: string | null): JSX.Element | null => {
        if (resultCode !== serverConstants.RESULT_OK && error) {
            const errorModalParams = {
                title: translate("general.errorDetail"),
                type: modalTypes.MODAL_DISPLAY,
                objectType: objectTypes.DATA_ERROR,
                headings: [],
                displayText: error,
                classNameTable: "simple-table"
            };

            return (
                <button
                    className="btn btn-md btn-link error-link"
                    onClick={() => this.props.openModal(modalTypes.MODAL_DISPLAY, errorModalParams)}
                >
                    {translate("general.errorDetail")}
                </button>
            );
        }

        return null;
    };

    getResultIcon = (resultCode: number | null): JSX.Element => {
        if (resultCode === serverConstants.RESULT_OK) {
            return <ReactSVG src={imgSuccess} className="svg-icon success" />;
        }

        return <ReactSVG src={imgError} className="svg-icon error" />;
    };

    getResultStatus = (resultCode: number | null, error: string | null): JSX.Element | null => {
        if (resultCode !== null) {
            return (
                <span className="result">
                    {translate("general.finished")} -
                    <span>
                        {this.getResultIcon(resultCode)}
                        {this.getErrorButton(resultCode, error)}
                    </span>
                </span>
            );
        }

        return null;
    };

    getLastResult = (script: Script): JSX.Element | null => {
        if (script[propertyConstants.PROPERTY_LAST_RUN]) {
            return (
                <div className="info-message">
                    <span>{translate("serverScripts.lastResult")}: </span>
                    <span>{formattingHelper.formatDateTime(script[propertyConstants.PROPERTY_LAST_RUN])}</span>
                    {this.getResultStatus(
                        script[propertyConstants.PROPERTY_RESULT_CODE],
                        script[propertyConstants.PROPERTY_ERROR_TEXT]
                    )}
                </div>
            );
        }

        return null;
    };

    getScriptButton = (script: Script): JSX.Element => {
        if (script[propertyConstants.PROPERTY_RUNNING]) {
            return (
                <div className="script-loader">
                    <ReactSVG src={imgLoader} className="svg-icon" />
                    <span>{translate("serverScripts.scriptRunning")}</span>
                </div>
            );
        }

        return (
            <div className="scene-btn-row">
                <button
                    className="btn btn-md btn-info"
                    onClick={() => this.handleRunScriptClick(script[propertyConstants.PROPERTY_ID])}
                >
                    {translate("serverScripts.runScript")}
                </button>
                {this.getLastResult(script)}
            </div>
        );
    };

    getScripts = (): Array<JSX.Element> => {
        const scripts = [];

        for (const item of this.props.scriptsList) {
            scripts.push(
                <div className="content-section" key={item[propertyConstants.PROPERTY_ID]}>
                    <div className="sub-title">{item[propertyConstants.PROPERTY_NAME]}</div>
                    <div className="server-text">{item[propertyConstants.PROPERTY_DESCRIPTION]}</div>
                    {this.getScriptButton(item)}
                </div>
            );
        }

        return scripts;
    };

    render(): JSX.Element | null {
        return (
            <div className="content-layout">
                <div className="layout-item">
                    <div className="height-100">
                        <div className="main-title">{translate("general.serverScripts")}</div>
                        <div className="main-content slim-scroll">{this.getScripts()}</div>
                    </div>
                </div>
            </div>
        );
    }
}
