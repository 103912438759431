import React, { Component } from "react";

import { PropsType } from "../containers/Debug";

import { t as translate } from "react-i18nify";

type Props = PropsType;

export class Debug extends Component<Props> {
    render(): JSX.Element {
        return (
            <div className="height-100">
                <div className="content-layout">
                    <header>
                        <div className="row drag-handle-content">
                            <div className="scene-title">{translate("general.debugInfo")}</div>
                            <div>
                                {translate("server.serverVersion")}:{" "}
                                {this.props.serverVersion || translate("server.notAvailable")}
                            </div>
                            <div>
                                {translate("server.sqliteVersion")}:{" "}
                                {this.props.sqliteVersion || translate("server.notAvailable")}
                            </div>
                            <div>
                                {translate("server.sqlcipherVersion")}:{" "}
                                {this.props.sqlcipherVersion || translate("server.notAvailable")}
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        );
    }
}
