import * as databaseActions from "../../actions/databaseActions";
import * as designHelper from "../../helpers/designHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as modalTypes from "../../constants/modalTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as serverActions from "../../actions/serverActions";
import * as universalObjectActions from "../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../reducers";
import { DataVacuuming } from "../../types/monitoring/dataVacuuming";
import { Dispatch } from "redux";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { Scene } from "../../constants/navigationTypes";

import { connect } from "react-redux";

import imgClose from "../../resources/img/icons/close.svg";
import imgWarning from "../../resources/img/icons/warning.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    nextAction: any;
    closeModal(type: ModalType): void;
};

type Props = ContainerProps & OwnProps;

type State = {
    saving: boolean;
};

export class ConfirmationModal extends Component<Props, State> {
    state: State = {
        saving: false
    };

    componentDidMount = (): void => {
        document.addEventListener("keydown", this.handleModalKeyDown);

        this.setState({ saving: this.checkSavingStatus() });
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (
            this.props.type !== prevProps.type ||
            JSON.stringify(this.props.dataVacuuming) !== JSON.stringify(prevProps.dataVacuuming)
        ) {
            this.setState({ saving: this.checkSavingStatus() });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    checkSavingStatus = (): boolean => {
        if (this.props.type === modalTypes.MODAL_DATABASES_VACUUM && this.props.dataVacuuming) {
            return true;
        }

        return false;
    };

    handleModalKeyDown = (event: Record<string, any>): void => {
        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.type !== "textarea") {
                    this.handleConfirmClick();
                }

                break;
            }
            case keyCodes.ESCAPE: {
                this.handleCloseClick();

                break;
            }
            default: {
                break;
            }
        }
    };

    handleConfirmClick = (): void => {
        const { nextAction, passRequest, closeModal, type, commit, updateActiveDatabaseStructure } = this.props;

        switch (type) {
            case modalTypes.MODAL_DATABASES_VACUUM:
                if (nextAction) {
                    passRequest(nextAction);
                }
                break;
            case modalTypes.MODAL_DATABASES_UPDATE:
                updateActiveDatabaseStructure(); // TODO remove to props.nextAction
                closeModal(type);
                break;
            case modalTypes.MODAL_ITEM_DELETE:
            case modalTypes.MODAL_ITEM_DELETE_FINAL:
                closeModal(type); // TODO unify order of closeModal, passRequest actions
                if (nextAction) {
                    passRequest(nextAction);
                }
                break;
            case modalTypes.MODAL_SAVE_DATA:
                commit();
                if (nextAction) {
                    passRequest(nextAction);
                }
                closeModal(type);
                break;

            default:
                break;
        }
    };

    // TODO rename to reject? this is no button? comment
    handleCloseClick = (): void => {
        const { type, nextAction, closeModal } = this.props;

        switch (type) {
            case modalTypes.MODAL_DATABASES_UPDATE: {
                this.props.closeActiveDatabase();
                break;
            }
            case modalTypes.MODAL_SAVE_DATA: {
                if (nextAction) {
                    this.props.passRequest(nextAction);
                }

                this.props.rollback();
                break;
            }
            default: {
                break;
            }
        }

        closeModal(type);
    };

    // TODO rename to close? or keep the same? this is exit cross or background click? comment
    handleExitClick = (): void => {
        const { type } = this.props;

        switch (type) {
            case modalTypes.MODAL_DATABASES_UPDATE: {
                this.props.closeActiveDatabase();
                break;
            }
            default: {
                break;
            }
        }

        this.props.closeModal(type);
    };

    getModalClassName = (): string => {
        let className = "modal modal-xs";
        className += designHelper.getModalClassType(this.props.activeScene);

        if (this.props.type === modalTypes.MODAL_DATABASES_VACUUM) {
            className += " databases-vacuum";
        }

        if (this.props.type === modalTypes.MODAL_ITEM_DELETE_FINAL) {
            return "modal warning-modal-delete";
        }

        return className;
    };

    // TODO rename to reject?
    getCloseButtonText = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_UPDATE:
                return translate("general.no");
            case modalTypes.MODAL_SAVE_DATA:
                return translate("general.discard");
            default:
                return translate("general.cancel");
        }
    };

    getConfirmButtonText = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_UPDATE:
                return translate("general.yes");
            case modalTypes.MODAL_ITEM_DELETE_FINAL:
                return translate("confirmationModal.deleteContinue");
            case modalTypes.MODAL_SAVE_DATA:
                return translate("general.save");
            default:
                return translate("general.confirm");
        }
    };

    getModalTitle = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_UPDATE: {
                return translate("confirmationModal.databaseUpdate");
            }
            case modalTypes.MODAL_ITEM_DELETE: {
                return translate("confirmationModal.deleteItem");
            }
            case modalTypes.MODAL_SAVE_DATA: {
                return translate("confirmationModal.saveData");
            }
            default: {
                return this.props.params.title ?? "";
            }
        }
    };

    getModalHeader = (): JSX.Element => {
        if (this.props.type === modalTypes.MODAL_ITEM_DELETE_FINAL) {
            return (
                <div className="header-with-icons">
                    <ReactSVG className="svg-icon error" src={imgWarning} />
                    <div className="title">{translate("confirmationModal.deletionWarning")}</div>
                    <ReactSVG className="svg-icon error" src={imgWarning} />
                </div>
            );
        }
        return <div className="title">{this.getModalTitle()}</div>;
    };

    getModalDescription = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_UPDATE: {
                return translate("confirmationModal.databaseUpdateText", {
                    databaseName: this.props.params.databaseName || ""
                });
            }
            case modalTypes.MODAL_ITEM_DELETE: {
                return translate("confirmationModal.deleteItemText");
            }
            case modalTypes.MODAL_ITEM_DELETE_FINAL: {
                return `${translate("confirmationModal.deleteText")}\n\n${translate(
                    "confirmationModal.deleteItemText"
                )}`;
            }
            case modalTypes.MODAL_SAVE_DATA: {
                return translate("confirmationModal.saveDataText");
            }
            default: {
                return this.props.params.displayText ?? "";
            }
        }
    };

    getVacuumingProgress = (): JSX.Element | null => {
        const { dataVacuuming } = this.props;

        if (dataVacuuming) {
            return (
                <span>
                    {dataVacuuming[propertyConstants.PROPERTY_DONE] +
                        "/" +
                        dataVacuuming[propertyConstants.PROPERTY_TOTAL]}
                </span>
            );
        }

        return null;
    };

    getOperationProgress = (): JSX.Element | null => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_VACUUM: {
                return this.getVacuumingProgress();
            }
            default: {
                return null;
            }
        }
    };

    getOperationDuration = (): JSX.Element | null => {
        switch (this.props.type) {
            case modalTypes.MODAL_DATABASES_VACUUM: {
                return (
                    <div className="time-warning">
                        {translate("general.operationDuration")}
                        {this.getOperationProgress()}
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    getConfirmationButton = (): JSX.Element => {
        if (this.state.saving) {
            return (
                <button className="btn-info pull-right disabled" disabled>
                    {this.getConfirmButtonText()}
                </button>
            );
        }

        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    this.handleConfirmClick();
                }}
                autoFocus={true}
            >
                {this.getConfirmButtonText()}
            </button>
        );
    };

    getCloseButton = (): JSX.Element => {
        if (this.state.saving) {
            return (
                <button className="btn-info pull-right disabled" disabled>
                    {this.getCloseButtonText()}
                </button>
            );
        }

        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    this.handleCloseClick();
                }}
            >
                {this.getCloseButtonText()}
            </button>
        );
    };

    getExitIcon = (): JSX.Element => {
        if (this.state.saving) {
            return (
                <button className="modal-close btn-without-style disabled" disabled>
                    <ReactSVG src={imgClose} className="close-img" />
                </button>
            );
        }

        return (
            <button
                className="modal-close btn-without-style"
                onClick={(): void => {
                    this.handleExitClick();
                }}
            >
                <ReactSVG src={imgClose} className="close-img" />
            </button>
        );
    };

    render(): JSX.Element {
        // TODO: Loading alert do footera a disablovane tlacitka
        return (
            <div className={this.getModalClassName()} style={{ zIndex: this.props.index }}>
                <header className="modal-header">
                    {this.getModalHeader()}
                    {this.getExitIcon()}
                </header>
                <div className="modal-content">
                    {this.getModalDescription()}
                    {this.getOperationDuration()}
                </div>
                <footer className="modal-footer">
                    {this.getConfirmationButton()}
                    {this.getCloseButton()}
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    activeScene: Scene;
    dataVacuuming: DataVacuuming | null;
}>;

export type DispatchType = Readonly<{
    closeActiveDatabase(): void;
    commit(): void;
    passRequest(nextAction: any): void;
    rollback(): void;
    updateActiveDatabaseStructure(): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    dataVacuuming: state.database.vacuuming
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    closeActiveDatabase: (): any => dispatch(databaseActions.closeActiveDatabase()),
    commit: (): any => dispatch(universalObjectActions.dataSave()),
    passRequest: (nextAction: any): any => dispatch(serverActions.passRequest(nextAction)),
    rollback: (): any => dispatch(universalObjectActions.dataRollback()),
    updateActiveDatabaseStructure: (): any => dispatch(databaseActions.updateActiveDatabaseStructure())
});

export const ConfirmationModalContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
