import * as objectTypes from "../../constants/objectTypes";
import * as translationHelper from "../../helpers/translationHelper";

export class DataType {
    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

export function mapDataType(id: number, objectType: objectTypes.ObjectType): DataType | null {
    try {
        return new DataType(id, translationHelper.getDataTypeTranslation(id, objectType));
    } catch (e) {
        return null;
    }
}
