export class ServerRequest {
    id: number;
    method: string;
    skip: boolean;
    next: any | null;
    time: number;

    constructor(
        id: number,
        method: string,
        skip: boolean = false,
        next: any = null,
        time: number = new Date().getTime()
    ) {
        this.id = id;
        this.method = method;
        this.skip = skip;
        this.next = next;
        this.time = time;
    }
}

export function updateServerRequest(
    oldServerRequest: ServerRequest,
    newServerRequest: Record<string, any>
): ServerRequest | null {
    try {
        return new ServerRequest(
            newServerRequest?.id !== undefined ? newServerRequest?.id : oldServerRequest.id,
            newServerRequest?.method !== undefined ? newServerRequest?.method : oldServerRequest.method,
            newServerRequest?.skip !== undefined ? newServerRequest?.skip : oldServerRequest.skip,
            newServerRequest?.next !== undefined ? newServerRequest?.next : oldServerRequest.next,
            newServerRequest?.time !== undefined ? newServerRequest?.time : oldServerRequest.time
        );
    } catch {
        return null;
    }
}
