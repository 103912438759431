import * as designHelper from "../../../helpers/designHelper";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as privilegesHelper from "../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { ModalProperties } from "../../../types/modalProperties";
import { TableAddButtonContainer } from "./buttons/TableAddButton";
import { TableCheckboxContainer } from "./buttons/TableCheckbox";
import { TableDateFilterContainer } from "./components/TableDateFilter";
import { TableDeleteButtonContainer } from "./buttons/TableDeleteButton";
import { TableDownloadButtonContainer as TableDownloadButton } from "./buttons/TableDownloadButton";
import { TableEditButtonContainer } from "./buttons/TableEditButton";
import { TableGlobalSearchContainer } from "./TableGlobalSearch";
import { TableHeading } from "../../../types/tableHeading";
import { TableHideShowButtonContainer } from "./buttons/TableHideShowButton";
import { TableJumpButtonContainer } from "./buttons/TableJumpButton";
import { TableLoadDataButtonContainer } from "./buttons/TableLoadDataButton";
import { TableMetaDataButtonContainer } from "./buttons/TableMetaDataButton";
import { TableMoreActionsButtonContainer } from "./buttons/TableMoreActionsButton";
import { TableOptionsContainer } from "./TableOptions";
import { TablePreviewButtonContainer } from "./buttons/TablePreviewButton";
import { TableRestoreButtonContainer } from "./buttons/TableRestoreButton";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    title?: string | null;
    titleClassName?: string | null;
    headings: Array<TableHeading>;
    data: Array<any>;
    activeData: Array<any>;
    additionalData?: Record<propertyConstants.Property, any>;
    availableCount?: number | null;
    dateFrom?: Date | null;
    dateTo?: Date | null;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    showAnotherSpecificValues?: boolean;
    showSpecificValues?: boolean;
    modalProperties: ModalProperties;
    loading?: boolean;
    callbackOptionMenuOpened?: (opened: boolean) => any;
    callbackSetActive?: (objectType: objectTypes.ObjectType, items: Array<any>) => any;
};

type Props = OwnProps & ContainerProps;

export class TableActionRow extends Component<Props> {
    getTitle = (): JSX.Element | null => {
        const { title, titleClassName } = this.props;

        if (title && titleClassName) {
            return (
                <div className="title-part ellipsis">
                    <div className={titleClassName}>{title}</div>
                </div>
            );
        }
        if (titleClassName) {
            return (
                <div className="title-part">
                    <div className={titleClassName} />
                </div>
            );
        }

        return null;
    };

    getAddEmptyModalButton = (): JSX.Element | null => {
        const { addEmptyModalType, addEmptyModalParams } = this.props.modalProperties;

        if (addEmptyModalType !== undefined && addEmptyModalParams !== undefined) {
            return (
                <TableAddButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    disabled={this.props.loading}
                    modalType={addEmptyModalType}
                    modalParams={addEmptyModalParams}
                />
            );
        }

        return null;
    };

    getAddModalButton = (): JSX.Element | null => {
        const { addModalType, addModalParams } = this.props.modalProperties;

        if (addModalType !== undefined && addModalParams !== undefined) {
            return (
                <TableAddButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    disabled={this.props.loading}
                    addedItemsList={this.props.data}
                    availableCount={this.props.availableCount}
                    modalType={addModalType}
                    modalParams={addModalParams}
                />
            );
        }

        return null;
    };

    getAddMoreModalButton = (): JSX.Element | null => {
        const { addMoreModalType, addMoreModalParams } = this.props.modalProperties;

        if (addMoreModalType !== undefined && addMoreModalParams !== undefined) {
            return (
                <TableAddButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    disabled={this.props.loading}
                    modalType={addMoreModalType}
                    modalParams={addMoreModalParams}
                />
            );
        }

        return null;
    };

    getEditModalButton = (): JSX.Element | null => {
        const { editModalType, editModalParams } = this.props.modalProperties;

        if (editModalType !== undefined && editModalParams !== undefined) {
            const activeList =
                editModalType === modalTypes.MODAL_CHANGELOG_EDIT && editModalParams
                    ? [editModalParams[propertyConstants.PROPERTY_DATA]]
                    : this.props.activeData;

            return (
                <TableEditButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    activeList={activeList}
                    modalType={editModalType}
                    modalParams={editModalParams}
                    editable={!this.props.loading}
                />
            );
        }

        return null;
    };

    getSecondEditModalButton = (): JSX.Element | null => {
        const { editSecondModalType, editSecondModalParams } = this.props.modalProperties;

        if (editSecondModalType !== undefined && editSecondModalParams !== undefined) {
            const activeList =
                editSecondModalType === modalTypes.MODAL_VERSION_EDIT && editSecondModalParams
                    ? [editSecondModalParams[propertyConstants.PROPERTY_DATA]]
                    : this.props.activeData;

            return (
                <TableEditButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    activeList={activeList}
                    modalType={editSecondModalType}
                    modalParams={editSecondModalParams}
                    editable={!this.props.loading}
                />
            );
        }

        return null;
    };

    getRestoreButton = (): JSX.Element => {
        return (
            <TableRestoreButtonContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                activeList={this.props.activeData}
            />
        );
    };

    getDownloadButton = (): JSX.Element => {
        return <TableDownloadButton objectType={this.props.objectType} activeList={this.props.activeData} />;
    };

    getLoadButton = (): JSX.Element => {
        return (
            <TableLoadDataButtonContainer
                objectType={this.props.objectType}
                activeList={this.props.activeData}
                dataPrivileges={this.props.dataPrivileges}
            />
        );
    };

    getDeleteButton = (): JSX.Element => {
        return (
            <TableDeleteButtonContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                privileges={this.props.privileges}
                activeList={this.props.activeData}
                list={this.props.data}
                editable={!this.props.loading}
            />
        );
    };

    getJumpModalButton = (): JSX.Element | null => {
        const { jumpModalParams } = this.props.modalProperties;

        if (jumpModalParams !== undefined) {
            return <TableJumpButtonContainer objectType={this.props.objectType} modalParams={jumpModalParams} />;
        }

        return null;
    };

    getPreviewButton = (): JSX.Element => {
        const { previewModalParams, previewModalType } = this.props.modalProperties;

        return (
            <TablePreviewButtonContainer
                objectType={this.props.objectType}
                activeList={this.props.activeData}
                modalType={previewModalType}
                modalParams={previewModalParams}
                editable={!this.props.loading}
            />
        );
    };

    getMoreActionsButton = (): JSX.Element => {
        return (
            <TableMoreActionsButtonContainer
                tableConstant={this.props.tableConstant}
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                activeList={this.props.activeData}
                modalProperties={this.props.modalProperties}
                editable={!this.props.loading}
            />
        );
    };

    getMetaDataButton = (): JSX.Element | null => {
        const { metaDataModalType, metaDataModalParams } = this.props.modalProperties;

        if (metaDataModalType !== undefined && metaDataModalParams !== undefined) {
            return (
                <TableMetaDataButtonContainer
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    modalType={metaDataModalType}
                    modalParams={metaDataModalParams}
                />
            );
        }

        return null;
    };

    getGlobalSearchClassName = (): string => {
        if (!this.props.showGlobalSearch) {
            return "";
        }

        let className = "search-input";

        if (
            designHelper.getTableButtonCheckboxVisibility(this.props.objectType, this.props.privileges) ||
            designHelper.getTableDatePickerVisibility(this.props.objectType) ||
            (objectTypes.READONLY_TABLES_WITHOUT_MARGIN_LEFT_SEARCH.includes(this.props.objectType) &&
                privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges))
        ) {
            className += " margin-left-3";
        } else {
            className += " margin-left";
        }

        return className;
    };

    getGlobalSearch = (): JSX.Element => {
        return (
            <TableGlobalSearchContainer
                objectType={this.props.objectType}
                className={this.getGlobalSearchClassName()}
                isVisible={this.props.showGlobalSearch}
                search={this.props.search || null}
            />
        );
    };

    getCheckboxButton = (): JSX.Element => {
        return (
            <TableCheckboxContainer
                objectType={this.props.objectType}
                tableConstant={this.props.tableConstant}
                dataPrivileges={this.props.dataPrivileges}
                privileges={this.props.privileges}
                editable={!this.props.loading}
                value={this.props.showSpecificValues ?? false}
                isSecond={false}
            />
        );
    };

    getSecondCheckboxButton = (): JSX.Element => {
        return (
            <TableCheckboxContainer
                objectType={this.props.objectType}
                tableConstant={this.props.tableConstant}
                dataPrivileges={this.props.dataPrivileges}
                privileges={this.props.privileges}
                editable={!this.props.loading}
                value={this.props.showAnotherSpecificValues ?? false}
                isSecond={true}
            />
        );
    };

    getDateFilter = (): JSX.Element => {
        return (
            <TableDateFilterContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                privileges={this.props.privileges}
                editable={!this.props.loading}
                dateFrom={this.props.dateFrom ? this.props.dateFrom : null}
                dateTo={this.props.dateTo ? this.props.dateTo : null}
            />
        );
    };

    getHideButton = (): JSX.Element => {
        return (
            <TableHideShowButtonContainer
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                privileges={this.props.privileges}
                activeList={this.props.activeData}
                editable={!this.props.loading}
            />
        );
    };

    getOptionsButton = (): JSX.Element => {
        return (
            <TableOptionsContainer
                tableConstant={this.props.tableConstant}
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                headings={this.props.headings}
                additionalData={this.props.additionalData}
                rowCount={this.props.rowCount}
                rowCountCustom={this.props.rowCountCustom}
                showGlobalSearch={this.props.showGlobalSearch}
                showFilterRow={this.props.showFilterRow}
                callbackMenuOpened={(opened: boolean) =>
                    this.props.callbackOptionMenuOpened
                        ? this.props.callbackOptionMenuOpened(opened)
                        : () => {
                              return;
                          }
                }
            />
        );
    };

    getActionRowClassName = (): string => {
        let className = "action-row";

        if (this.props.objectType === objectTypes.DATABASE_MONITORING) {
            return className;
        }

        if (
            !privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges) ||
            this.props.objectType === objectTypes.IMPORT_TASK
        ) {
            className += " wrap-row";
        }

        return className;
    };

    render(): JSX.Element | null {
        if (objectTypes.HIDDEN_ACTION_ROW_OBJECT_TYPES.includes(this.props.objectType)) {
            return null;
        }

        return (
            <div className={this.getActionRowClassName()}>
                {this.getTitle()}
                <div className="action-part">
                    {this.getAddEmptyModalButton()}
                    {this.getAddModalButton()}
                    {this.getEditModalButton()}
                    {this.getSecondEditModalButton()}
                    {this.getRestoreButton()}
                    {this.getDownloadButton()}
                    {this.getLoadButton()}
                    {this.getHideButton()}
                    {this.getDeleteButton()}
                    {this.getAddMoreModalButton()}
                    {this.getPreviewButton()}
                    {this.getJumpModalButton()}
                    {this.getMoreActionsButton()}
                    {this.getMetaDataButton()}
                    {this.getCheckboxButton()}
                    {this.getSecondCheckboxButton()}
                    {this.getDateFilter()}
                    {this.getGlobalSearch()}
                    {this.getOptionsButton()}
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{
    privileges: any;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    privileges: state.login.privileges
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableActionRowContainer = connect(mapStateToProps, mapDispatchToProps)(TableActionRow);
