import * as actionConstants from "../constants/actionTypes";
import * as serverMethods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export function abortExportTask(taskId: number): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ABORT_EXPORT_TASK,
            params: {
                exportTaskId: taskId
            }
        },
        method: serverMethods.METHOD_ABORT_EXPORT_TASK
    };
}

export function abortImportTask(taskId: number): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_ABORT_IMPORT_TASK,
            params: {
                importTaskId: taskId
            }
        },
        method: serverMethods.METHOD_ABORT_IMPORT_TASK
    };
}

export function insertExportTasks(taskTypeId: number, zoneData: Array<Record<string, any>>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_INSERT_EXPORT_TASKS,
            params: {
                exportTaskTypeId: taskTypeId,
                zoneData: zoneData
            }
        },
        method: serverMethods.METHOD_INSERT_EXPORT_TASKS
    };
}
