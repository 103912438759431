import * as objectTypes from "../../../../constants/objectTypes";
import * as serverConstants from "../../../../constants/serverConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";

import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import imgClose from "../../../../resources/img/icons/close.svg";

type OwnProps = {
    objectType: objectTypes.ObjectType;
    heading: TableHeading;
    searchValue: string;
    setSearch: (objectType: objectTypes.ObjectType, search: string | null, column?: string) => any;
};

type Props = OwnProps;

type State = {
    timeoutId: NodeJS.Timeout | null;
    search: string | null;
};

export class TableFilterInput extends Component<Props> {
    state: State = {
        timeoutId: null,
        search: null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.state.search !== prevState.search) {
            this.setTimeout();
        }
    }

    componentWillUnmount(): void {
        const { timeoutId } = this.state;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value !== "" ? event.target.value : null;

        this.setState({
            search: value
        });
    };

    handleResetClick = (): void => {
        this.setState({
            search: null
        });
    };

    setTimeout = () => {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
            this.setState({ timeoutId: null });
        }

        const timeout = setTimeout(
            () =>
                this.props.setSearch(
                    this.props.objectType,
                    this.state.search,
                    this.props.heading[tableConstants.TABLE_HEADING_NAME]
                ),
            serverConstants.SERVER_SEARCH_TIMEOUT
        );

        this.setState({ timeoutId: timeout });
    };

    getResetButton = (): JSX.Element => {
        if (this.props.searchValue) {
            return <ReactSVG className="close-icon" src={imgClose} onClick={(): void => this.handleResetClick()} />;
        }

        return <div />;
    };

    render(): JSX.Element {
        return (
            <div className="search-input">
                <input
                    type="text"
                    className={this.props.searchValue ? "active" : ""}
                    value={this.state.search ?? ""}
                    onChange={(event): void => this.handleSearchInputChange(event)}
                />
                {this.getResetButton()}
            </div>
        );
    }
}
