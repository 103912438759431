import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Colorant } from "./colorant";
import { TableHeading } from "./tableHeading";

export class FormulaColorant {
    id: number;
    colorantName: string;
    rgbHex: string | null;
    amount: number | null;
    density: number | null;

    constructor(
        id: number,
        colorantName: string,
        rgbHex: string | null,
        amount: number | null,
        density: number | null
    ) {
        this.id = id;
        this.colorantName = colorantName;
        this.rgbHex = rgbHex;
        this.amount = amount;
        this.density = density;
    }
}

export function mapFormulaColorant(activeColorant: Colorant, amount: number | null): FormulaColorant | null {
    try {
        return new FormulaColorant(
            activeColorant.id,
            activeColorant.name,
            activeColorant.rgbHex,
            amount,
            activeColorant.density ? activeColorant.density : null
        );
    } catch (e) {
        return null;
    }
}

export function generateFormulaColorantData(formulaColorantList: Array<FormulaColorant>): Array<any> {
    const data: Array<any> = [];

    for (const item of formulaColorantList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_COLORANT_NAME]: item.colorantName,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX]: item.rgbHex,
            [propertyConstants.PROPERTY_AMOUNT]: item.amount,
            [propertyConstants.PROPERTY_DENSITY]: item.density
        });
    }

    return data;
}

export function generateFormulaColorantHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_AMOUNT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_AMOUNT),
            [],
            widthOption?.[propertyConstants.PROPERTY_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
