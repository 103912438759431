import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Base } from "../../../types/base";
import { BaseInProduct } from "../../../types/baseInProduct";
import { FileCategory } from "../../../types/fileCategory";
import { MasterProductScene } from "../../../components/scenes/master/MasterProductScene";
import { MenuItem } from "../../../types/menu";
import { Product } from "../../../types/product";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    fileCategoryList: Array<FileCategory>;
    productTableConstant: string;
    productList: Array<Product>;
    productActiveList: Array<Product>;
    productLoading: boolean;
    productButtonLoading: boolean;
    productColumnOrder: Array<propertyConstants.Property>;
    productColumnVisibility: Record<propertyConstants.Property, boolean>;
    productColumnWidth: Record<propertyConstants.Property, number>;
    productOffset: number;
    productPage: number;
    productRowCount: number;
    productRowCountCustom: boolean;
    productSceneTableSize: Record<string, any> | null;
    productSearch: string | null;
    productSearchParams: Record<propertyConstants.Property, string>;
    productShowFilterRow: boolean;
    productShowGlobalSearch: boolean;
    productSortingAsc: boolean;
    productSortingCriterion: propertyConstants.Property | null;
    productTotalCount: number | null;
    baseList: Array<Base>;
    baseInProductTableConstant: string;
    baseInProductAllList: Array<BaseInProduct>;
    baseInProductList: Array<BaseInProduct>;
    baseInProductActiveList: Array<BaseInProduct>;
    baseInProductLoading: boolean;
    baseInProductButtonLoading: boolean;
    baseInProductColumnOrder: Array<propertyConstants.Property>;
    baseInProductColumnVisibility: Record<propertyConstants.Property, boolean>;
    baseInProductColumnWidth: Record<propertyConstants.Property, number>;
    baseInProductOffset: number;
    baseInProductPage: number;
    baseInProductRowCount: number;
    baseInProductRowCountCustom: boolean;
    baseInProductSearch: string | null;
    baseInProductSearchParams: Record<propertyConstants.Property, string>;
    baseInProductShowFilterRow: boolean;
    baseInProductShowGlobalSearch: boolean;
    baseInProductSortingAsc: boolean;
    baseInProductSortingCriterion: propertyConstants.Property | null;
    baseInProductTotalCount: number | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_PRODUCTS
    ),
    fileCategoryList: state.fileCategory.list,
    productTableConstant: menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS,
    productList: state.product.masterList,
    productActiveList: state.product.masterActiveList,
    productLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_MASTER_PRODUCTS ||
            request.method === methods.METHOD_DELETE_MASTER_PRODUCT
    ),
    productButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_MASTER_PRODUCT_BY_ID ||
            request.method === methods.METHOD_EDIT_MASTER_PRODUCT
    ),
    productColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productOffset: state.product.masterOffset,
    productPage: state.product.masterPage,
    productRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_MASTER_PRODUCTS
    ),
    productSearch: state.product.masterSearch,
    productSearchParams: state.product.masterSearchParams,
    productShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
    ),
    productSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
        )
    ),
    productSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
        )
    ),
    productTotalCount: state.product.masterCountTotal,
    baseList: state.base.masterList,
    baseInProductTableConstant: menuConstants.TABLE_MASTER_PRODUCTS_BASES,
    baseInProductAllList: state.baseInProduct.masterAllList,
    baseInProductList: state.baseInProduct.masterList,
    baseInProductActiveList: state.baseInProduct.masterActiveList,
    baseInProductLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT ||
            request.method === methods.METHOD_DELETE_MASTER_BASE_IN_PRODUCT
    ),
    baseInProductButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID ||
            request.method === methods.METHOD_EDIT_MASTER_BASE_IN_PRODUCT
    ),
    baseInProductColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductOffset: state.baseInProduct.masterOffset,
    baseInProductPage: state.baseInProduct.masterPage,
    baseInProductRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductSearch: state.baseInProduct.masterSearch,
    baseInProductSearchParams: state.baseInProduct.masterSearchParams,
    baseInProductShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_PRODUCTS_BASES
    ),
    baseInProductSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_PRODUCTS_BASES
        )
    ),
    baseInProductSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_PRODUCTS_BASES
        )
    ),
    baseInProductTotalCount: state.baseInProduct.masterCountTotal,
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_MASTER_PRODUCTS
    )
});

export const MasterProductSceneContainer = connect(mapStateToProps)(MasterProductScene);
