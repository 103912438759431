import * as formattingHelper from "../../../helpers/formattingHelper";
import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";
import { Unit } from "../../../types/unit";

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    visible: boolean;
    data: Array<any>;
    activeUnit: Unit | null;
};

type Props = OwnProps;

export class TableFooterRow extends Component<Props> {
    isVisible = (): boolean => {
        if (!this.props.visible) {
            return false;
        }

        if (!this.props.dataPrivileges?.canView) {
            return false;
        }

        if (!this.props.data.length) {
            return false;
        }

        if (
            this.props.objectType === objectTypes.MASTER_FORMULA_COLORANT ||
            this.props.objectType === objectTypes.SYSTEM_FORMULA_COLORANT ||
            this.props.objectType === objectTypes.ZONE_FORMULA_COLORANT
        ) {
            return true;
        }

        return false;
    };

    getContent = (): JSX.Element | null => {
        let totalCount = 0;

        for (const item of this.props.data) {
            if (item[propertyConstants.PROPERTY_AMOUNT]) {
                let updatedValue = item[propertyConstants.PROPERTY_AMOUNT];
                if (objectTypeHelper.isObjectTypeFormulaColorant(this.props.objectType)) {
                    updatedValue = generalHelper.recalculateFormulaColorantFromDefaultValue(
                        this.props.activeUnit,
                        item[propertyConstants.PROPERTY_AMOUNT],
                        item[propertyConstants.PROPERTY_DENSITY]
                    );
                }

                totalCount += formattingHelper.formatRoundedDecimal(updatedValue) ?? 0;
            }
        }

        return (
            <div className="sum-row">
                <span>
                    {formattingHelper.formatDecimal(totalCount)}
                    <span className="cell-unit">
                        {objectTypeHelper.isObjectTypeFormulaColorant(this.props.objectType) && this.props.activeUnit
                            ? this.props.activeUnit[propertyConstants.PROPERTY_UNIT]
                            : optionsConstants.DEFAULT_FORMULA_COLORANT_UNIT}
                    </span>
                </span>
            </div>
        );
    };

    render(): JSX.Element | null {
        if (this.isVisible()) {
            return <div className="footer-row">{this.getContent()}</div>;
        }

        return null;
    }
}
