import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";

import { Image, generateImageData, generateImageHeadings } from "../../../types/image";
import React, { Component } from "react";
import { FileType } from "../../../types/fileType";
import { ImageCategory } from "../../../types/imageCategory";
import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    modalId?: number;
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    categoryList: Array<ImageCategory>;
    fileTypeList: Array<FileType>;
    allList: Array<Image>;
    activeList: Array<Image>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    totalCount: number | null;
};

export class ImageTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const tableVisibleColumns = optionHelper.handleTableHeadingVisibility(
            [
                propertyConstants.PROPERTY_NAME,
                propertyConstants.PROPERTY_IMAGE_CATEGORY_ID,
                propertyConstants.PROPERTY_IMAGE_ID
            ],
            this.props.columnVisibility
        );

        return generateImageHeadings(
            this.props.objectType,
            modalTypes.MODAL_IMAGES_TABLE,
            this.props.categoryList,
            this.props.fileTypeList,
            tableVisibleColumns,
            tableVisibleColumns,
            [],
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            {} as Record<propertyConstants.Property, number>
        );
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    modalId={this.props.modalId}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings()}
                    data={generateImageData(this.props.allList)}
                    activeData={this.props.activeList}
                    disableColumnResizing={true}
                    modalProperties={{}}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={true}
                    showGlobalSearch={false}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
