import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class Currency {
    id: number;
    name: string;
    code: string;
    symbol: string;
    rounding: number;

    constructor(id: number, name: string, code: string, symbol: string, rounding: number) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.symbol = symbol;
        this.rounding = rounding;
    }
}

export function mapCurrency(data: Record<string, any>): Currency | null {
    try {
        return new Currency(data.id, data.name, data.code, data.symbol, data.rounding);
    } catch (e) {
        return null;
    }
}

export function mapCurrencies(data: Record<string, any>): Array<Currency> {
    const currencies = [];

    for (const item of Object.values(data)) {
        const currency = mapCurrency(item);

        if (currency !== null) {
            currencies.push(currency);
        }
    }

    return currencies;
}

export function updateCurrency(oldCurrency: Currency | null, updatedData: Record<string, any>): Currency | null {
    try {
        if (oldCurrency === null) {
            return null;
        }

        return new Currency(
            oldCurrency.id,
            updatedData.name ? updatedData.name : oldCurrency.name,
            updatedData.code ? updatedData.code : oldCurrency.code,
            updatedData.symbol ? updatedData.symbol : oldCurrency.symbol,
            updatedData.rounding ? updatedData.rounding : oldCurrency.rounding
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyCurrency(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_SYMBOL]: "",
        [propertyConstants.PROPERTY_ROUNDING]: ""
    };
}

export function generateCurrencyData(currencyList: Array<Currency>): Array<any> {
    const data: Array<any> = [];
    let currency: Currency;

    for (currency of currencyList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: currency.id,
            [propertyConstants.PROPERTY_NAME]: currency.name,
            [propertyConstants.PROPERTY_CODE]: currency.code,
            [propertyConstants.PROPERTY_SYMBOL]: currency.symbol,
            [propertyConstants.PROPERTY_ROUNDING]: currency.rounding
        });
    }

    return data;
}

export function generateCurrencyHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYMBOL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYMBOL),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYMBOL),
            editableList.includes(propertyConstants.PROPERTY_SYMBOL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYMBOL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYMBOL),
            requiredList.includes(propertyConstants.PROPERTY_SYMBOL),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYMBOL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ROUNDING,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ROUNDING),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_ROUNDING),
            editableList.includes(propertyConstants.PROPERTY_ROUNDING),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ROUNDING),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ROUNDING),
            requiredList.includes(propertyConstants.PROPERTY_ROUNDING),
            [],
            widthOption?.[propertyConstants.PROPERTY_ROUNDING] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
