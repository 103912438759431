import * as generalHelper from "../helpers/generalHelper";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Database } from "./database";
import { ExportStatus } from "./exportStatus";
import { ExportType } from "./exportType";
import { System } from "./system";
import { SystemZone } from "./systemZone";
import { TableHeading } from "./tableHeading";
import { Zone } from "./zone";

export class ExportTask {
    id: number;
    uuid: string;
    databaseId: number;
    databaseName: string;
    systemId: number;
    systemUuid: string;
    systemName: string;
    zoneId: number;
    zoneUuid: string;
    zoneName: string;
    companyKey: string;
    zonePassword: string;
    exportTaskStatusId: number;
    exportTaskTypeId: number;
    progress: number | null;
    version: string;
    versionName: string;
    changelog: string | null;
    dateCreated: string | null;
    dateFinished: string | null;
    errorText: string | null;
    errorCode: string | null;
    mimeType: string | null;
    canDelete: boolean;
    canDownload: boolean;
    canHide: boolean;
    deleted: importExportConstants.TaskDeletedType;
    finished: boolean;
    colorsWithoutFormulas: boolean;
    isChecked: boolean;

    constructor(
        id: number,
        uuid: string,
        databaseId: number,
        databaseName: string,
        systemId: number,
        systemUuid: string,
        systemName: string,
        zoneId: number,
        zoneUuid: string,
        zoneName: string,
        companyKey: string,
        zonePassword: string,
        exportTaskStatusId: number,
        exportTaskTypeId: number,
        progress: number | null,
        version: string,
        versionName: string,
        changelog: string | null,
        dateCreated: string | null,
        dateFinished: string | null,
        errorText: string | null,
        errorCode: string | null,
        mimeType: string | null,
        canDelete: boolean,
        canDownload: boolean,
        canHide: boolean,
        deleted: importExportConstants.TaskDeletedType,
        finished: boolean,
        colorsWithoutFormulas: boolean,
        isChecked: boolean
    ) {
        this.id = id;
        this.uuid = uuid;
        this.databaseId = databaseId;
        this.databaseName = databaseName;
        this.systemId = systemId;
        this.systemUuid = systemUuid;
        this.systemName = systemName;
        this.zoneId = zoneId;
        this.zoneUuid = zoneUuid;
        this.zoneName = zoneName;
        this.companyKey = companyKey;
        this.zonePassword = zonePassword;
        this.exportTaskStatusId = exportTaskStatusId;
        this.exportTaskTypeId = exportTaskTypeId;
        this.progress = progress;
        this.version = version;
        this.versionName = versionName;
        this.changelog = changelog;
        this.dateCreated = dateCreated;
        this.dateFinished = dateFinished;
        this.errorText = errorText;
        this.errorCode = errorCode;
        this.mimeType = mimeType;
        this.canDelete = canDelete;
        this.canDownload = canDownload;
        this.canHide = canHide;
        this.deleted = deleted;
        this.finished = finished;
        this.colorsWithoutFormulas = colorsWithoutFormulas;
        this.isChecked = isChecked;
    }
}

export function mapExportTask(data: Record<string, any>): ExportTask | null {
    try {
        return new ExportTask(
            data.id,
            data.uuid,
            data.databaseId,
            data.databaseName,
            data.systemId,
            data.systemUuid,
            data.systemName,
            data.zoneId,
            data.zoneUuid,
            data.zoneName,
            data.zoneCompanyKey,
            data.zonePassword,
            data.exportTaskStatusId,
            data.exportTaskTypeId,
            data.progress,
            data.version,
            data.versionName,
            data.changelog,
            data.dateCreated,
            data.dateFinished,
            data.errorText,
            data.errorCode,
            data.resultFileMimeType,
            data.canDelete,
            data.canDownload,
            data.canHide,
            data.deleted,
            data.finished,
            data.colorsWithoutFormulas,
            false
        );
    } catch (e) {
        return null;
    }
}

export function updateExportTask(oldTask: ExportTask, newData: Record<string, any>): ExportTask | null {
    try {
        // Update event should be ignored as this happens after hide or delete action, and we do reload ourselves
        if (
            oldTask[propertyConstants.PROPERTY_DELETED] === newData?.[propertyConstants.PROPERTY_DELETED] &&
            oldTask[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID] ===
                newData?.[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID] &&
            oldTask[propertyConstants.PROPERTY_PROGRESS] === newData?.[propertyConstants.PROPERTY_PROGRESS] &&
            oldTask[propertyConstants.PROPERTY_ERROR_CODE] === newData?.[propertyConstants.PROPERTY_ERROR_CODE] &&
            oldTask[propertyConstants.PROPERTY_ERROR_TEXT] === newData?.[propertyConstants.PROPERTY_ERROR_TEXT]
        ) {
            return oldTask;
        }

        return new ExportTask(
            oldTask[propertyConstants.PROPERTY_ID],
            oldTask[propertyConstants.PROPERTY_UUID],
            oldTask[propertyConstants.PROPERTY_DATABASE_ID],
            oldTask[propertyConstants.PROPERTY_DATABASE_NAME],
            oldTask[propertyConstants.PROPERTY_SYSTEM_ID],
            oldTask[propertyConstants.PROPERTY_SYSTEM_UUID],
            oldTask[propertyConstants.PROPERTY_SYSTEM_NAME],
            oldTask[propertyConstants.PROPERTY_ZONE_ID],
            oldTask[propertyConstants.PROPERTY_ZONE_UUID],
            oldTask[propertyConstants.PROPERTY_ZONE_NAME],
            oldTask[propertyConstants.PROPERTY_COMPANY_KEY],
            oldTask[propertyConstants.PROPERTY_ZONE_PASSWORD],
            newData.exportTaskStatusId !== undefined
                ? newData.exportTaskStatusId
                : oldTask[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID],
            oldTask[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID],
            newData.progress !== undefined ? newData.progress : oldTask[propertyConstants.PROPERTY_PROGRESS],
            oldTask[propertyConstants.PROPERTY_VERSION],
            oldTask[propertyConstants.PROPERTY_VERSION_NAME],
            oldTask[propertyConstants.PROPERTY_CHANGELOG],
            oldTask[propertyConstants.PROPERTY_DATE_CREATED],
            newData.exportTaskStatusId === importExportConstants.EXPORT_STATUS_OK ||
            newData.exportTaskStatusId === importExportConstants.EXPORT_STATUS_ERROR ||
            newData.exportTaskStatusId === importExportConstants.EXPORT_STATUS_ABORTED
                ? newData.dateStatus
                : oldTask[propertyConstants.PROPERTY_DATE_FINISHED],
            newData[propertyConstants.PROPERTY_ERROR_TEXT] !== undefined
                ? newData[propertyConstants.PROPERTY_ERROR_TEXT]
                : oldTask[propertyConstants.PROPERTY_ERROR_TEXT],
            newData[propertyConstants.PROPERTY_ERROR_CODE] !== undefined
                ? newData[propertyConstants.PROPERTY_ERROR_CODE]
                : oldTask[propertyConstants.PROPERTY_ERROR_CODE],
            oldTask[propertyConstants.PROPERTY_MIME_TYPE],
            oldTask[propertyConstants.PROPERTY_CAN_DELETE],
            oldTask[propertyConstants.PROPERTY_CAN_DOWNLOAD],
            oldTask[propertyConstants.PROPERTY_CAN_HIDE],
            oldTask[propertyConstants.PROPERTY_DELETED],
            oldTask[propertyConstants.PROPERTY_FINISHED],
            oldTask[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS],
            newData[propertyConstants.PROPERTY_IS_CHECKED] !== undefined
                ? newData[propertyConstants.PROPERTY_IS_CHECKED]
                : oldTask[propertyConstants.PROPERTY_IS_CHECKED]
        );
    } catch (e) {
        return null;
    }
}

export function cloneExportTask(data: Record<string, any>): ExportTask | null {
    try {
        return new ExportTask(
            data.id,
            data.uuid,
            data.databaseId,
            data.databaseName,
            data.systemId,
            data.systemUuid,
            data.systemName,
            data.zoneId,
            data.zoneUuid,
            data.zoneName,
            data.companyKey,
            data.password,
            data.exportTaskStatusId,
            data.exportTaskTypeId,
            data.progress,
            data.version,
            data.versionName,
            data.changelog,
            data.dateCreated,
            data.dateFinished,
            data.errorText,
            data.errorCode,
            data.mimeType,
            data.canDelete,
            data.canDownload,
            data.canHide,
            data.deleted,
            data.finished,
            data.colorsWithoutFormulas,
            data.isChecked
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyExportTask(
    exportTypeList: Array<ExportType>,
    systemZoneList: Array<SystemZone>,
    activeDatabase: Database | null,
    activeSystem: System | null,
    activeZone: Zone | null
): any {
    const activeSystemZone =
        systemZoneList.find(
            (item) =>
                item[propertyConstants.PROPERTY_SYSTEM_ID] === activeSystem?.[propertyConstants.PROPERTY_ID] &&
                item[propertyConstants.PROPERTY_ZONE_ID] === activeZone?.[propertyConstants.PROPERTY_ID]
        ) ?? (systemZoneList.length ? systemZoneList[0] : null);

    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_DATABASE_ID]: activeDatabase?.[propertyConstants.PROPERTY_ID] ?? "",
        [propertyConstants.PROPERTY_DATABASE_NAME]: activeDatabase
            ? activeDatabase[propertyConstants.PROPERTY_NAME]
            : "",
        [propertyConstants.PROPERTY_SYSTEM_ID]: activeSystemZone?.[propertyConstants.PROPERTY_SYSTEM_ID] ?? "",
        [propertyConstants.PROPERTY_SYSTEM_UUID]: "",
        [propertyConstants.PROPERTY_SYSTEM_NAME]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_SYSTEM_NAME]
            : "",
        [propertyConstants.PROPERTY_ZONE_ID]: activeSystemZone?.[propertyConstants.PROPERTY_ZONE_ID] ?? "",
        [propertyConstants.PROPERTY_ZONE_UUID]: activeSystemZone?.[propertyConstants.PROPERTY_UUID] ?? "",
        [propertyConstants.PROPERTY_ZONE_NAME]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_ZONE_NAME]
            : "",
        [propertyConstants.PROPERTY_COMPANY_KEY]: "",
        [propertyConstants.PROPERTY_ZONE_PASSWORD]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_ZONE_PASSWORD]
            : "",
        [propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID]: "",
        [propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID]: exportTypeList?.[0]?.[propertyConstants.PROPERTY_ID] ?? "",
        [propertyConstants.PROPERTY_PROGRESS]: "",
        [propertyConstants.PROPERTY_VERSION]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_VERSION]
            : "",
        [propertyConstants.PROPERTY_VERSION_NAME]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_VERSION_NAME]
            : "",
        [propertyConstants.PROPERTY_CHANGELOG]: activeSystemZone
            ? activeSystemZone[propertyConstants.PROPERTY_CHANGELOG]
            : "",
        [propertyConstants.PROPERTY_DATE_CREATED]: "",
        [propertyConstants.PROPERTY_DATE_FINISHED]: "",
        [propertyConstants.PROPERTY_ERROR_TEXT]: "",
        [propertyConstants.PROPERTY_ERROR_CODE]: "",
        [propertyConstants.PROPERTY_MIME_TYPE]: "",
        [propertyConstants.PROPERTY_CAN_DELETE]: false,
        [propertyConstants.PROPERTY_CAN_DOWNLOAD]: false,
        [propertyConstants.PROPERTY_CAN_HIDE]: false,
        [propertyConstants.PROPERTY_DELETED]: false,
        [propertyConstants.PROPERTY_FINISHED]: false,
        [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]: importExportConstants.DEFAULT_COLORS_WITHOUT_FORMULAS,
        [propertyConstants.PROPERTY_IS_CHECKED]: false
    };
}

export function generateExportTaskData(list: Array<ExportTask>): Array<any> {
    const data: Array<any> = [];
    let item: ExportTask;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_DATABASE_ID]: item.databaseId,
            [propertyConstants.PROPERTY_DATABASE_NAME]: item.databaseName,
            [propertyConstants.PROPERTY_SYSTEM_ID]: item.systemId,
            [propertyConstants.PROPERTY_SYSTEM_UUID]: item.systemUuid,
            [propertyConstants.PROPERTY_SYSTEM_NAME]: item.systemName,
            [propertyConstants.PROPERTY_ZONE_ID]: item.zoneId,
            [propertyConstants.PROPERTY_ZONE_UUID]: item.zoneUuid,
            [propertyConstants.PROPERTY_ZONE_NAME]: item.zoneName,
            [propertyConstants.PROPERTY_COMPANY_KEY]: item.companyKey,
            [propertyConstants.PROPERTY_ZONE_PASSWORD]: item.zonePassword,
            [propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID]: item.exportTaskStatusId,
            [propertyConstants.PROPERTY_ACTION]: item.exportTaskStatusId,
            [propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID]: item.exportTaskTypeId,
            [propertyConstants.PROPERTY_PROGRESS]: item.progress,
            [propertyConstants.PROPERTY_VERSION]: item.version,
            [propertyConstants.PROPERTY_VERSION_NAME]: item.versionName,
            [propertyConstants.PROPERTY_CHANGELOG]: item.changelog,
            [propertyConstants.PROPERTY_DATE_CREATED]: item.dateCreated,
            [propertyConstants.PROPERTY_DATE_FINISHED]: item.dateFinished,
            [propertyConstants.PROPERTY_ERROR_TEXT]: item.errorText,
            [propertyConstants.PROPERTY_ERROR_CODE]: item.errorCode,
            [propertyConstants.PROPERTY_MIME_TYPE]: item.mimeType,
            [propertyConstants.PROPERTY_CAN_DELETE]: item.canDelete,
            [propertyConstants.PROPERTY_CAN_DOWNLOAD]: item.canDownload,
            [propertyConstants.PROPERTY_CAN_HIDE]: item.canHide,
            [propertyConstants.PROPERTY_DELETED]: item.deleted,
            [propertyConstants.PROPERTY_FINISHED]: item.finished,
            [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]: item.colorsWithoutFormulas,
            [propertyConstants.PROPERTY_IS_CHECKED]: item.isChecked
        });
    }

    return data;
}

export function generateExportTaskHeadings(
    objectType: objectTypes.ObjectType,
    statusList: Array<ExportStatus>,
    typeList: Array<ExportType>,
    systemZoneList: Array<SystemZone>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const systemList = generalHelper.isDistinctValueByProperty(
        systemZoneList.map((item) => {
            return {
                [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_SYSTEM_ID],
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_SYSTEM_NAME]
            };
        }),
        propertyConstants.PROPERTY_ID
    );
    const zoneList = generalHelper.isDistinctValueByProperty(
        systemZoneList.map((item) => {
            return {
                [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ZONE_ID],
                [propertyConstants.PROPERTY_NAME]: item[propertyConstants.PROPERTY_ZONE_NAME],
                [propertyConstants.PROPERTY_CHANGELOG]: item[propertyConstants.PROPERTY_CHANGELOG],
                [propertyConstants.PROPERTY_ZONE_PASSWORD]: item[propertyConstants.PROPERTY_ZONE_PASSWORD],
                [propertyConstants.PROPERTY_SYSTEM_ID]: item[propertyConstants.PROPERTY_SYSTEM_ID],
                [propertyConstants.PROPERTY_UUID]: item[propertyConstants.PROPERTY_UUID],
                [propertyConstants.PROPERTY_VERSION]: item[propertyConstants.PROPERTY_VERSION],
                [propertyConstants.PROPERTY_VERSION_NAME]: item[propertyConstants.PROPERTY_VERSION_NAME],
                [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]:
                    item[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]
            };
        }),
        propertyConstants.PROPERTY_ID
    );

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ACTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION),
            tableConstants.TABLE_TYPE_BUTTON,
            visibleList.includes(propertyConstants.PROPERTY_ACTION),
            editableList.includes(propertyConstants.PROPERTY_ACTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION),
            requiredList.includes(propertyConstants.PROPERTY_ACTION),
            statusList,
            widthOption?.[propertyConstants.PROPERTY_ACTION] ?? null,
            "action table-button"
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CHANGELOG,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            editableList.includes(propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CHANGELOG),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            requiredList.includes(propertyConstants.PROPERTY_CHANGELOG),
            [],
            widthOption?.[propertyConstants.PROPERTY_CHANGELOG] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            editableList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            requiredList.includes(propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COMPANY_KEY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPANY_KEY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            editableList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            requiredList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            [],
            widthOption?.[propertyConstants.PROPERTY_COMPANY_KEY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_CREATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            editableList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_CREATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_CREATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_FINISHED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_FINISHED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            editableList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_FINISHED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ERROR_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ERROR_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ERROR_CODE),
            editableList.includes(propertyConstants.PROPERTY_ERROR_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ERROR_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ERROR_CODE),
            requiredList.includes(propertyConstants.PROPERTY_ERROR_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_ERROR_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ERROR_TEXT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ERROR_TEXT),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_ERROR_TEXT),
            editableList.includes(propertyConstants.PROPERTY_ERROR_TEXT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ERROR_TEXT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ERROR_TEXT),
            requiredList.includes(propertyConstants.PROPERTY_ERROR_TEXT),
            [],
            widthOption?.[propertyConstants.PROPERTY_ERROR_TEXT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            tableConstants.TABLE_TYPE_STATUS,
            visibleList.includes(propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            editableList.includes(propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            requiredList.includes(propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID),
            statusList,
            widthOption?.[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID),
            typeList,
            widthOption?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_CHECKED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            editableList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_CHECKED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            requiredList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_CHECKED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MIME_TYPE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MIME_TYPE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_MIME_TYPE),
            editableList.includes(propertyConstants.PROPERTY_MIME_TYPE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MIME_TYPE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MIME_TYPE),
            requiredList.includes(propertyConstants.PROPERTY_MIME_TYPE),
            [],
            widthOption?.[propertyConstants.PROPERTY_MIME_TYPE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_PASSWORD),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_PASSWORD] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PROGRESS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PROGRESS),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PROGRESS),
            editableList.includes(propertyConstants.PROPERTY_PROGRESS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PROGRESS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PROGRESS),
            requiredList.includes(propertyConstants.PROPERTY_PROGRESS),
            [],
            widthOption?.[propertyConstants.PROPERTY_PROGRESS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            systemList,
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION),
            editableList.includes(propertyConstants.PROPERTY_VERSION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION),
            requiredList.includes(propertyConstants.PROPERTY_VERSION),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            editableList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            requiredList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_ID),
            zoneList,
            widthOption?.[propertyConstants.PROPERTY_ZONE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            editableList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
