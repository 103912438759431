import * as objectTypes from "../../../constants/objectTypes";
import * as serverConstants from "../../../constants/serverConstants";
import * as universalObjectActions from "../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";

import { connect } from "react-redux";

import imgClose from "../../../resources/img/icons/close.svg";
import imgSearch from "../../../resources/img/icons/search.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    className: string;
    isVisible: boolean;
    search: string | null;
};

type State = {
    timeoutId: NodeJS.Timeout | null;
    search: string | null;
};

// TODO check all methods onClick, onKeyDOwn, onMouseEnter/Leave, onFocus/Change/Blur - have to be arrow function
type Props = OwnProps & ContainerProps;

export class TableGlobalSearch extends Component<Props> {
    state: State = {
        timeoutId: null,
        search: null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.state.search !== prevState.search) {
            this.setTimeout();
        }
    }

    componentWillUnmount(): void {
        const { timeoutId } = this.state;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value !== "" ? event.target.value : null;

        this.setState({
            search: value
        });
    };

    handleFilterResetClick = (): void => {
        this.setState({
            search: ""
        });
    };

    setTimeout = () => {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
            this.setState({ timeoutId: null });
        }

        const timeout = setTimeout(() => {
            this.props.setSearch(this.props.objectType, this.state.search);
        }, serverConstants.SERVER_SEARCH_TIMEOUT);

        this.setState({ timeoutId: timeout });
    };

    getFilterResetIcon = (): JSX.Element => {
        if (this.props.search?.length) {
            return <ReactSVG className="close-icon" src={imgClose} onClick={() => this.handleFilterResetClick()} />;
        }

        return <div />;
    };

    render(): JSX.Element {
        if (!this.props.isVisible) {
            return <div className={this.props.className}>{}</div>;
        }

        return (
            <div className={this.props.className}>
                <ReactSVG className="search-icon" src={imgSearch} />
                <input
                    value={this.state.search ?? ""}
                    onChange={(event): void => this.handleSearchInputChange(event)}
                />
                {this.getFilterResetIcon()}
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    setSearch(objectType: objectTypes.ObjectType, search: string | null, column?: string): any;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setSearch: (objectType: objectTypes.ObjectType, search: string | null, column?: string): any =>
        dispatch(universalObjectActions.setSearch(objectType, search, column))
});

export const TableGlobalSearchContainer = connect(mapStateToProps, mapDispatchToProps)(TableGlobalSearch);
