import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Script } from "../types/script";

export type ScriptState = Readonly<{
    allList: Array<Script>;
    list: Array<Script>;
    activeList: Array<Script>;
    count: number | null;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: ScriptState = {
    allList: [],
    list: [],
    activeList: [],
    count: tableConstants.DEFAULT_TOTAL_COUNT,
    sortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    sortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    search: tableConstants.DEFAULT_SEARCH,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function scripts(state: ScriptState = initialState, action: Record<string, any>): ScriptState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                return {
                    ...state,
                    allList: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                return {
                    ...state,
                    activeList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.SET_SORTING: {
            if (action.payload.objectType === objectTypes.SERVER_SCRIPTS) {
                return {
                    ...state,
                    sortingAsc: action.payload.asc,
                    sortingCriterion: action.payload.criterion || tableConstants.DEFAULT_SORTING_COLUMN
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE: {
            return {
                ...initialState,
                allList: state.allList
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
