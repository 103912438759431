import * as actionConstants from "../constants/actionTypes";
import * as modalConstants from "../constants/modalTypes";
import * as navigationConstants from "../constants/navigationTypes";
import * as pathConstants from "../constants/paths";

import { Modal } from "../types/modal";
import { ModalType } from "../constants/modalTypes";
import { push } from "connected-react-router";

export interface RoutingPush {
    type: typeof actionConstants.ROUTING_PUSH;
    payload: {
        path: pathConstants.Paths;
        confirmed: boolean;
    };
}

export function routingPush(path: pathConstants.Paths, confirmed: boolean): any {
    if (confirmed) {
        return (dispatch: any): void => {
            dispatch(push(path));
        };
    }

    return {
        type: actionConstants.NAVIGATION_MODAL_OPEN,
        payload: {
            type: modalConstants.MODAL_SAVE_DATA,
            nextAction: routingPush(path, true),
            params: {}
        }
    };
}

export interface NavigationSetJump {
    type: typeof actionConstants.NAVIGATION_SET_JUMP;
    payload: {
        scene: navigationConstants.Scene;
    };
}

export function navigationSetJump(scene: navigationConstants.Scene): NavigationSetJump {
    return {
        type: actionConstants.NAVIGATION_SET_JUMP,
        payload: {
            scene
        }
    };
}

export interface NavigationSetScene {
    type: typeof actionConstants.NAVIGATION_SET_SCENE;
    payload: {
        scene: navigationConstants.Scene;
    };
}

export function navigationSetScene(scene: navigationConstants.Scene): NavigationSetScene {
    return {
        type: actionConstants.NAVIGATION_SET_SCENE,
        payload: {
            scene
        }
    };
}

// TODO wizard actions not in use
export interface NavigationSetWizard {
    type: typeof actionConstants.NAVIGATION_SET_WIZARD;
    payload: {
        wizard: navigationConstants.Wizard | null;
    };
}

export function navigationSetWizard(wizard: navigationConstants.Wizard | null): NavigationSetWizard {
    return {
        type: actionConstants.NAVIGATION_SET_WIZARD,
        payload: {
            wizard
        }
    };
}

export interface NavigationSetWizardStep {
    type: typeof actionConstants.NAVIGATION_SET_WIZARD_STEP;
    payload: {
        step: number;
    };
}

export function navigationSetWizardStep(step: number): NavigationSetWizardStep {
    return {
        type: actionConstants.NAVIGATION_SET_WIZARD_STEP,
        payload: {
            step
        }
    };
}

export interface NavigationModalOpen {
    type: typeof actionConstants.NAVIGATION_MODAL_OPEN;
    payload: {
        type: ModalType;
        params: any;
        nextAction: any;
    };
}

export function navigationOpenModal(type: ModalType, params: any = null, nextAction: any = null): NavigationModalOpen {
    return {
        type: actionConstants.NAVIGATION_MODAL_OPEN,
        payload: {
            type,
            params,
            nextAction
        }
    };
}

export interface NavigationModalClose {
    type: typeof actionConstants.NAVIGATION_MODAL_CLOSE;
    payload: {
        type: ModalType;
    };
}

export function navigationCloseModal(type: ModalType): NavigationModalClose {
    return {
        type: actionConstants.NAVIGATION_MODAL_CLOSE,
        payload: {
            type
        }
    };
}

export interface NavigationCloseAllModals {
    type: typeof actionConstants.NAVIGATION_CLOSE_ALL_MODALS;
}

export function navigationCloseAllModals(): NavigationCloseAllModals {
    return {
        type: actionConstants.NAVIGATION_CLOSE_ALL_MODALS
    };
}

export interface NavigationSetActiveBookmark {
    type: typeof actionConstants.NAVIGATION_SET_ACTIVE_BOOKMARK;
    payload: {
        key: string | null;
    };
}

export function setActiveBookmark(key: string | null): NavigationSetActiveBookmark {
    return {
        type: actionConstants.NAVIGATION_SET_ACTIVE_BOOKMARK,
        payload: {
            key
        }
    };
}

export interface NavigationSetActiveModalBookmark {
    type: typeof actionConstants.NAVIGATION_SET_ACTIVE_MODAL_BOOKMARK;
    payload: {
        key: string | null;
    };
}

export function setActiveModalBookmark(key: string | null): NavigationSetActiveModalBookmark {
    return {
        type: actionConstants.NAVIGATION_SET_ACTIVE_MODAL_BOOKMARK,
        payload: {
            key
        }
    };
}

export interface NavigationSetNextModal {
    type: typeof actionConstants.NAVIGATION_SET_NEXT_MODAL;
    payload: {
        modal: Modal | null;
    };
}

export function setNextModal(modal: Modal | null): NavigationSetNextModal {
    return {
        type: actionConstants.NAVIGATION_SET_NEXT_MODAL,
        payload: {
            modal
        }
    };
}
