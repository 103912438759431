import * as formattingHelper from "../../helpers/formattingHelper";
import * as licenseConstants from "../../constants/license";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { ContactPerson, createEmptyContactPerson } from "./contactPerson";
import { PointOfSale, createEmptyPointOfSale } from "./pointOfSale";
import { TableHeading } from "../tableHeading";

export class License {
    modules: Array<any>;
    productId: number | null;
    status: number;
    expiration: string | null;
    remainingDays: string;
    pointOfSale: PointOfSale;
    contactPerson: ContactPerson;
    companyId: string;
    companyName: string;
    userId: string;
    username: string;
    uuid: string;
    computerId: string;

    constructor(
        modules: Array<any>,
        productId: number | null,
        status: number,
        expiration: string | null,
        remainingDays: string,
        pointOfSale: PointOfSale,
        contactPerson: ContactPerson,
        companyId: string = "",
        companyName: string = "",
        userId: string = "",
        username: string = "",
        uuid: string = "",
        computerId: string = ""
    ) {
        this.modules = modules;
        this.productId = productId;
        this.status = status;
        this.expiration = expiration;
        this.remainingDays = remainingDays;
        this.pointOfSale = pointOfSale;
        this.contactPerson = contactPerson;
        this.companyId = companyId;
        this.companyName = companyName;
        this.userId = userId;
        this.username = username;
        this.uuid = uuid;
        this.computerId = computerId;
    }
}

export function mapLicense(data: Record<string, any>): License | null {
    try {
        if (data.status === licenseConstants.STATUS_ERROR) {
            return createEmptyLicense(data.status);
        }

        return new License(
            data.modules2 ? data.modules2 : [],
            data.product ? data.product : null,
            data.status,
            data.expiration,
            formattingHelper.formatDecimal(formattingHelper.formatSecondsToDays(data.remaining), 0),
            createEmptyPointOfSale(),
            createEmptyContactPerson()
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyLicense(status: number | string = ""): any {
    return {
        [propertyConstants.PROPERTY_MODULES]: [],
        [propertyConstants.PROPERTY_PRODUCT_ID]: "",
        [propertyConstants.PROPERTY_STATUS]: status,
        [propertyConstants.PROPERTY_EXPIRATION]: "",
        [propertyConstants.PROPERTY_REMAINING_DAYS]: "",
        [propertyConstants.PROPERTY_POINT_OF_SALE]: createEmptyPointOfSale(),
        [propertyConstants.PROPERTY_CONTACT_PERSON]: createEmptyContactPerson(),
        [propertyConstants.PROPERTY_COMPANY_ID]: "",
        [propertyConstants.PROPERTY_COMPANY_NAME]: "",
        [propertyConstants.PROPERTY_USER_ID]: "",
        [propertyConstants.PROPERTY_USERNAME]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_COMPUTER_ID]: ""
    };
}

export function updateLicense(oldLicense: License | null, data: Record<string, any>): License | null {
    try {
        if (oldLicense) {
            return new License(
                oldLicense.modules,
                data.productId ? data.productId : oldLicense.productId,
                oldLicense.status,
                oldLicense.expiration,
                oldLicense.remainingDays,
                data.pointOfSale ? data.pointOfSale : oldLicense.pointOfSale,
                data.contactPerson ? data.contactPerson : oldLicense.contactPerson,
                data.companyId ? data.companyId : oldLicense.companyId,
                data.companyName ? data.companyName : oldLicense.companyName,
                data.userId ? data.userId : oldLicense.userId,
                data.userName ? data.userName : oldLicense.username,
                data.uuid ? data.uuid : oldLicense.uuid,
                data.computerId ? data.computerId : oldLicense.computerId
            );
        }

        return null;
    } catch (e) {
        return null;
    }
}

export function generateLicenseWithoutRegistrationData(license: License | null): Record<string, any> | null {
    if (license === null) {
        return null;
    }

    return {
        [propertyConstants.PROPERTY_MODULES]: license.modules,
        [propertyConstants.PROPERTY_PRODUCT_ID]: license.productId,
        [propertyConstants.PROPERTY_STATUS]: license.status,
        [propertyConstants.PROPERTY_EXPIRATION]: license.expiration,
        [propertyConstants.PROPERTY_REMAINING_DAYS]: license.remainingDays,
        [propertyConstants.PROPERTY_COMPANY_ID]: license.companyId,
        [propertyConstants.PROPERTY_COMPANY_NAME]: license.companyName,
        [propertyConstants.PROPERTY_USER_ID]: license.userId,
        [propertyConstants.PROPERTY_USERNAME]: license.username,
        [propertyConstants.PROPERTY_UUID]: license.uuid,
        [propertyConstants.PROPERTY_COMPUTER_ID]: license.uuid
    };
}

export function generateLicensePreviewData(license: License | null): Record<string, any> | null {
    if (license === null) {
        return null;
    }

    return {
        [propertyConstants.PROPERTY_COMPANY_NAME]: license.companyName,
        [propertyConstants.PROPERTY_COMPUTER_ID]: license.computerId,
        [propertyConstants.PROPERTY_EXPIRATION]: license.expiration,
        [propertyConstants.PROPERTY_PRODUCT_ID]: translationHelper.getLicenseVersionTypeTranslation(license.productId),
        [propertyConstants.PROPERTY_USERNAME]: license.username,
        [propertyConstants.PROPERTY_UUID]: license.uuid
    };
}

export function generateLicenseHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_COMPANY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPANY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COMPANY_NAME),
            editableList.includes(propertyConstants.PROPERTY_COMPANY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPANY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPANY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COMPANY_NAME),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COMPUTER_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPUTER_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            editableList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            requiredList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DIVIDING_LINE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.TABLE_TYPE_LINE,
            visibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            editableList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            requiredList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_EXPIRATION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_EXPIRATION),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_EXPIRATION),
            editableList.includes(propertyConstants.PROPERTY_EXPIRATION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_EXPIRATION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_EXPIRATION),
            requiredList.includes(propertyConstants.PROPERTY_EXPIRATION),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LICENSE_IDENTIFICATION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            editableList.includes(propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            requiredList.includes(propertyConstants.PROPERTY_LICENSE_IDENTIFICATION),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LICENSE_STATUS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LICENSE_STATUS),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_LICENSE_STATUS),
            editableList.includes(propertyConstants.PROPERTY_LICENSE_STATUS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LICENSE_STATUS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LICENSE_STATUS),
            requiredList.includes(propertyConstants.PROPERTY_LICENSE_STATUS),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USERNAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USERNAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USERNAME),
            editableList.includes(propertyConstants.PROPERTY_USERNAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USERNAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USERNAME),
            requiredList.includes(propertyConstants.PROPERTY_USERNAME),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
