import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { HistoricalAction, mapHistoricalAction } from "../../types/historicalAction";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_USER_HISTORY_ACTIONS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const historicalActionList: Array<HistoricalAction> = [];
            let historicalAction: HistoricalAction | null;

            for (const item of response.data.data) {
                historicalAction = mapHistoricalAction(item);

                if (historicalAction) {
                    historicalActionList.push(historicalAction);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.USER_ACTION_HISTORY,
                    historicalActionList,
                    response.data.count
                )
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
