import * as objectTypes from "../constants/objectTypes";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";
import * as userGroupPrivilegeConstants from "../constants/userGroupPrivilegeConstants";

import { DataItem } from "./dataItem";
import { TableHeading } from "./tableHeading";
import { User } from "./user";

export class UserGroupPrivilege {
    key: string;
    visible: boolean;
    superadmins: DataItem;
    admins: DataItem;
    managers: DataItem;
    users: DataItem;
    guests: DataItem;

    constructor(
        key: string,
        visible: boolean,
        superadmins: DataItem,
        admins: DataItem,
        managers: DataItem,
        users: DataItem,
        guests: DataItem
    ) {
        this.key = key;
        this.visible = visible;
        this.superadmins = superadmins;
        this.admins = admins;
        this.managers = managers;
        this.users = users;
        this.guests = guests;
    }
}

export function mapAllUserGroupPrivilege(loggedUser: User | null): Array<any> {
    const privileges = [];
    const isSuperadmin = privilegesHelper.isSuperadmin(loggedUser);

    privileges.push(
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_DATABASE,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_SYSTEM_ZONE,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_IMPORT,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_EXPORT,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_MASTER,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_SYSTEM,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_ZONE,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_CURRENCIES,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_STATIC_SQL,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_IMAGES,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_PRODUCT_FILES,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_PACKAGES,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_UNITS,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_UUID_ZONE,
            true,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", true, false, false, false, true, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_UUID_MASTER_SYSTEM,
            isSuperadmin,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_COMPANY,
            isSuperadmin,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_USER,
            isSuperadmin,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_IMPORT_EXPORT_HIDE,
            isSuperadmin,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        ),
        new UserGroupPrivilege(
            userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_IMPORT_EXPORT_DELETE,
            isSuperadmin,
            new DataItem("", "", true, true, true, true, true, true),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false),
            new DataItem("", "", false, false, false, false, false, false)
        )
    );

    return privileges.filter((item) => item !== null && item !== undefined && item.visible);
}

export function generateUserGroupPrivilegeData(loggedUser: User | null): Array<any> {
    const data: Array<any> = [];

    for (const item of mapAllUserGroupPrivilege(loggedUser)) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.key,
            [propertyConstants.PROPERTY_KEY]: item.key,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getUserGroupPrivilegeKeyTranslations(item.key),
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS]:
                translationHelper.getUserGroupPrivilegeValueTranslations(item.superadmins || null),
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS]:
                translationHelper.getUserGroupPrivilegeValueTranslations(item.admins || null),
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS]:
                translationHelper.getUserGroupPrivilegeValueTranslations(item.managers || null),
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS]:
                translationHelper.getUserGroupPrivilegeValueTranslations(item.users || null),
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS]:
                translationHelper.getUserGroupPrivilegeValueTranslations(item.guests || null)
        });
    }

    return data;
}

export function generateUserGroupPrivilegeHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_KEY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_KEY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_KEY),
            editableList.includes(propertyConstants.PROPERTY_KEY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_KEY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_KEY),
            requiredList.includes(propertyConstants.PROPERTY_KEY),
            [],
            widthOption?.[propertyConstants.PROPERTY_KEY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS
            ),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS),
            [],
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS
            ),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS),
            [],
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS
            ),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS),
            [],
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS
            ),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS),
            [],
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS),
            [],
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
