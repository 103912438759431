import * as generalHelper from "../../../../helpers/generalHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ModalSelectbox } from "../../inputs/ModalSelectbox";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    isEditable: boolean;
    isSourceChanged: boolean;
    callbackBlur: (event: any, value: any, rowId?: number | string, headingId?: propertyConstants.Property) => any;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellSelect extends Component<Props> {
    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (property: propertyConstants.Property): string => {
        let className = `td ${property} select-cell`;

        if (this.props.isSourceChanged) {
            className += " dotted";
        }

        return className;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const options: Array<{
            key: string;
            value: string;
        }> = generalHelper.getOptionsForSelectbox(
            this.props.objectType,
            heading[tableConstants.TABLE_HEADING_ENUM],
            heading[tableConstants.TABLE_HEADING_NAME]
        );
        const option =
            value !== null ? generalHelper.getObjectById(heading[tableConstants.TABLE_HEADING_ENUM], value) : null;
        const content = option?.[propertyConstants.PROPERTY_NAME] || "";

        return (
            <div
                className={this.getClassname(property)}
                key={property}
                onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, content)}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
                data-tip
                data-for={"table-cell-tooltip"}
            >
                <ModalSelectbox
                    className={"table-select"}
                    editable={!this.isDisabled()}
                    required={heading[tableConstants.TABLE_HEADING_REQUIRED]}
                    value={value}
                    options={options}
                    autoselect={false}
                    callback={(event: any, value: string): void => {
                        this.props.callbackBlur(
                            event,
                            !isNaN(Number(value)) && value !== null && value !== "" ? Number(value) : value || null,
                            item[propertyConstants.PROPERTY_ID],
                            property
                        );
                    }}
                />
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellSelectContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellSelect);
