import * as actionConstants from "../constants/actionTypes";
import * as fileTypes from "../constants/fileTypes";
import * as formattingHelper from "../helpers/formattingHelper";
import * as generalHelper from "../helpers/generalHelper";
import * as itemsWithParamsActions from "../actions/itemsWithParamsActions";
import * as menuConstants from "../constants/menuConstants";
import * as navigationActions from "../actions/navigationActions";
import * as navigationTypes from "../constants/navigationTypes";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as universalObjectActions from "../actions/universalObjectActions";
import * as websocketActions from "../actions/websocketActions";

import { Epic, ofType } from "redux-observable";

import { Colorant } from "../types/colorant";
import { FileType } from "../types/fileType";
import { Package } from "../types/package";
import { Zone } from "../types/zone";
import { isPrivilegeAvailable } from "../helpers/optionHelper";
import { switchMap } from "rxjs/operators";

export const dataSave: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.DATA_COMMIT),
        switchMap(() => {
            return [websocketActions.serverDataCommit(), universalObjectActions.dataChanged(false)];
        })
    );

export const rollbackData: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.DATA_ROLLBACK),
        switchMap(() => {
            return [
                websocketActions.serverDataRollback(),
                universalObjectActions.dataChanged(false),
                navigationActions.navigationSetScene(state$.value.navigation.activeScene)
            ];
        })
    );

export const saveWizardData: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.WIZARD_SAVE_DATA),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.wizard) {
                case navigationTypes.ADD_SYSTEM_BASE_WIZARD: {
                    const baseData = state$.value.base.masterActiveList;
                    const zoneIds = state$.value.zone.activeList.map((item: Zone) => item.id);

                    requests.push(itemsWithParamsActions.addSystemBaseWithParams(baseData, zoneIds));
                    break;
                }
                case navigationTypes.ADD_SYSTEM_COLORANT_WIZARD: {
                    const colorantData = state$.value.colorant.masterActiveList;
                    const packageIds = state$.value.package.activeList.map((item: Package) => item.id);
                    const zoneIds = state$.value.zone.activeList.map((item: Zone) => item.id);

                    requests.push(
                        itemsWithParamsActions.addSystemColorantWithParams(colorantData, packageIds, zoneIds)
                    );
                    break;
                }
                case navigationTypes.ADD_SYSTEM_COLORANT_PACKAGES_WIZARD: {
                    const packageData = state$.value.package.activeList;
                    const colorantIds = state$.value.colorant.systemActiveList.map((item: Colorant) => item.id);
                    const zoneIds = state$.value.zone.activeList.map((item: Zone) => item.id);

                    requests.push(
                        itemsWithParamsActions.addSystemColorantPackageWithParams(packageData, colorantIds, zoneIds)
                    );
                    break;
                }
                case navigationTypes.ADD_SYSTEM_FANDECK_WIZARD: {
                    const fandeckData = state$.value.fandeck.masterActiveList;
                    const zoneIds = state$.value.zone.activeList.map((item: Zone) => item.id);

                    requests.push(itemsWithParamsActions.addSystemFandeckWithParams(fandeckData, zoneIds));
                    break;
                }
                case navigationTypes.ADD_SYSTEM_PRODUCT_WIZARD: {
                    const productData = state$.value.product.masterActiveList[0];
                    const baseInProductData = state$.value.baseInProduct.masterActiveList;
                    const packageIds = state$.value.package.activeList;
                    const zoneIds = state$.value.zone.activeList.map((item: Zone) => item.id);

                    requests.push(
                        itemsWithParamsActions.addSystemProductWithParams(
                            productData,
                            baseInProductData,
                            packageIds,
                            zoneIds,
                            {}
                        )
                    );
                    break;
                }
                default:
                    break;
            }

            return requests;
        })
    );

export const loadData: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(
            actionConstants.RELOAD_DATA,
            actionConstants.SET_DATE,
            actionConstants.SET_OFFSET,
            actionConstants.SET_SEARCH,
            actionConstants.SET_SORTING
        ),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];
            let actionTypeId = undefined;
            let addBarcodes = undefined;
            let addFiles = undefined;
            let addPrices = undefined;
            let addProductGroups = undefined;
            let columnVisibility: Record<string, any> = {};
            let sorting = null;
            let storeAsc = undefined;
            let storeChangedOnly = undefined;
            let storeDateFrom = undefined;
            let storeDateTo = undefined;
            let storeOffset = undefined;
            let storeOrderBy = undefined;
            let storeSearch = undefined;
            let storeSearchByColumns = undefined;
            let storeShowDeleted = undefined;
            let storeShowHidden = undefined;
            let rowCount = undefined;
            let simpleData = undefined;
            let baseInProductId = undefined;
            let colorInFandeckId = undefined;
            let colorantId = undefined;
            let companyId = undefined;
            let fandeckId = undefined;
            let fileTypeId = undefined;
            let fileTypeIds = undefined;
            let itemTypeId = undefined;
            let productGroupId = undefined;
            let productId = undefined;
            let statusId = undefined;
            let systemId = undefined;
            let userId = undefined;
            let userGroupId = undefined;
            let zoneId = undefined;

            switch (payload.objectType) {
                // barcode
                case objectTypes.BARCODE: {
                    // TODO: unify sorting above the switch, send tableConstant inside payload
                    storeSearchByColumns = tableConstants.DEFAULT_SEARCH_PARAMS;
                    const searchParams = state$.value.barcode.searchParams;

                    // TODO vyhodit do pomocne metody
                    for (const item in searchParams) {
                        if (searchParams[item] !== "") {
                            switch (item) {
                                case propertyConstants.PROPERTY_ITEM_TYPE_ID:
                                    itemTypeId = Number(searchParams[item]);
                                    break;
                                case propertyConstants.PROPERTY_SYSTEM_ID:
                                    systemId = Number(searchParams[item]);
                                    break;
                                case propertyConstants.PROPERTY_ZONE_ID:
                                    zoneId = Number(searchParams[item]);
                                    break;
                                default:
                                    storeSearchByColumns = {
                                        ...storeSearchByColumns,
                                        [item]: searchParams[item]
                                    };
                                    break;
                            }
                        }
                    }

                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_BARCODES
                    );

                    storeOffset = state$.value.barcode.offset;
                    storeSearch = state$.value.barcode.search;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_BARCODES
                    );
                    break;
                }
                // base
                case objectTypes.MASTER_BASE: {
                    // TODO: unify sorting above the switch, send tableConstant inside payload
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_BASES
                    );

                    storeOffset = state$.value.base.masterOffset;
                    storeSearch = state$.value.base.masterSearch;
                    storeSearchByColumns = state$.value.base.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_BASES
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_BASES
                    );

                    storeSearch = state$.value.base.masterSystemSearch;
                    storeSearchByColumns = state$.value.base.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE: {
                    break;
                }
                case objectTypes.SYSTEM_BASE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_BASES
                    );

                    storeOffset = state$.value.base.systemOffset;
                    storeSearch = state$.value.base.systemSearch;
                    storeSearchByColumns = state$.value.base.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_BASES
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_BASES
                    );

                    storeSearch = state$.value.base.systemZoneSearch;
                    storeSearchByColumns = state$.value.base.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.ZONE_BASE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_BASES
                    );

                    storeOffset = state$.value.base.zoneOffset;
                    storeSearch = state$.value.base.zoneSearch;
                    storeSearchByColumns = state$.value.base.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_BASES
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE: {
                    break;
                }
                // color
                case objectTypes.MASTER_COLOR: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_COLORS
                    );

                    storeOffset = state$.value.color.masterOffset;
                    storeSearch = state$.value.color.masterSearch;
                    storeSearchByColumns = state$.value.color.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_COLORS
                    );
                    fandeckId = state$.value.fandeck.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_COLOR: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_COLORS
                    );

                    storeOffset = state$.value.color.systemOffset;
                    storeSearch = state$.value.color.systemSearch;
                    storeSearchByColumns = state$.value.color.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_COLORS
                    );
                    fandeckId = state$.value.fandeck.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_COLOR: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_COLORS
                    );

                    storeOffset = state$.value.color.zoneOffset;
                    storeSearch = state$.value.color.zoneSearch;
                    storeSearchByColumns = state$.value.color.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_COLORS
                    );
                    fandeckId = state$.value.fandeck.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.MASTER_COLOR_DATA: {
                    colorInFandeckId =
                        state$.value.color.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }

                case objectTypes.SYSTEM_COLOR_DATA: {
                    colorInFandeckId =
                        state$.value.color.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_COLOR_DATA: {
                    colorInFandeckId = state$.value.color.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_COLORS
                    );

                    storeOffset = state$.value.color.masterOffset;
                    storeSearch = state$.value.color.masterSearch;
                    storeSearchByColumns = state$.value.color.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_COLORS
                    );
                    fandeckId = state$.value.fandeck.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }

                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_COLORS
                    );

                    storeOffset = state$.value.color.systemOffset;
                    storeSearch = state$.value.color.systemSearch;
                    storeSearchByColumns = state$.value.color.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_COLORS
                    );
                    fandeckId = state$.value.fandeck.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_COLORS
                    );

                    storeOffset = state$.value.color.zoneOffset;
                    storeSearch = state$.value.color.zoneSearch;
                    storeSearchByColumns = state$.value.color.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_COLORS
                    );
                    fandeckId = state$.value.fandeck.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_COLORANTS
                    );

                    storeOffset = state$.value.colorant.masterOffset;
                    storeSearch = state$.value.colorant.masterSearch;
                    storeSearchByColumns = state$.value.colorant.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_COLORANTS
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_COLORANTS
                    );

                    storeSearch = state$.value.colorant.masterSystemSearch;
                    storeSearchByColumns = state$.value.colorant.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    break;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_COLORANTS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_COLORANTS
                    );

                    storeOffset = state$.value.colorant.systemOffset;
                    storeSearch = state$.value.colorant.systemSearch;
                    storeSearchByColumns = state$.value.colorant.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_COLORANTS
                    );
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_COLORANTS
                    );

                    storeSearch = state$.value.colorant.systemZoneSearch;
                    storeSearchByColumns = state$.value.colorant.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_COLORANTS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_COLORANTS
                    );

                    storeOffset = state$.value.colorant.zoneOffset;
                    storeSearch = state$.value.colorant.zoneSearch;
                    storeSearchByColumns = state$.value.colorant.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_COLORANTS
                    );
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                // colorant batch
                case objectTypes.MASTER_COLORANT_BATCH: {
                    storeSearchByColumns = tableConstants.DEFAULT_SEARCH_PARAMS;
                    const searchParams = state$.value.colorantBatch.masterSearchParams;

                    for (const item in searchParams) {
                        if (searchParams[item] !== "") {
                            switch (item) {
                                case propertyConstants.PROPERTY_STATUS_ID:
                                    statusId = Number(searchParams[item]);
                                    break;
                                default:
                                    storeSearchByColumns = {
                                        ...storeSearchByColumns,
                                        [item]: searchParams[item]
                                    };
                                    break;
                            }
                        }
                    }

                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_COLORANT_BATCHES
                    );

                    storeOffset = state$.value.colorantBatch.masterOffset;
                    storeSearch = state$.value.colorantBatch.masterSearch;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_COLORANT_BATCHES
                    );
                    colorantId = state$.value.colorant.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                // colorant package
                case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_COLORANT_PACKAGES
                    );

                    storeSearch = state$.value.colorantPackage.masterSystemSearch;
                    storeSearchByColumns = state$.value.colorantPackage.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    colorantId = state$.value.colorant.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_COLORANT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES
                    );

                    storeOffset = state$.value.colorantPackage.systemOffset;
                    storeSearch = state$.value.colorantPackage.systemSearch;
                    storeSearchByColumns = state$.value.colorantPackage.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES
                    );
                    colorantId = state$.value.colorant.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_COLORANT_PACKAGES
                    );

                    storeSearch = state$.value.colorantPackage.systemZoneSearch;
                    storeSearchByColumns = state$.value.colorantPackage.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    colorantId = state$.value.colorant.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_COLORANT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
                    );

                    storeOffset = state$.value.colorantPackage.zoneOffset;
                    storeSearch = state$.value.colorantPackage.zoneSearch;
                    storeSearchByColumns = state$.value.colorantPackage.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_COLORANT_PACKAGES
                    );
                    colorantId = state$.value.colorant.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE: {
                    break;
                }
                // company
                case objectTypes.COMPANY: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_COMPANIES
                    );

                    storeOffset = state$.value.company.offset;
                    storeSearch = state$.value.company.search;
                    storeSearchByColumns = state$.value.company.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    /* rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_COMPANIES
                    ); */
                    break;
                }
                // currency
                case objectTypes.CURRENCY: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_CURRENCIES
                    );

                    storeOffset = state$.value.currency.offset;
                    storeSearch = state$.value.currency.search;
                    storeSearchByColumns = state$.value.currency.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_CURRENCIES
                    );
                    break;
                }
                // data size
                case objectTypes.DATA_SIZE: {
                    break;
                }
                // database
                case objectTypes.DATABASE_SYSTEM_ZONE: {
                    break;
                }
                case objectTypes.DATABASE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_DATA_DATABASES
                    );

                    storeOffset = state$.value.database.offset;
                    storeSearch = state$.value.database.search;
                    storeSearchByColumns = state$.value.database.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    /* rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_DATA_DATABASES
                    ); */
                    break;
                }
                case objectTypes.DATABASE_MONITORING: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MONITORING_DATABASES
                    );

                    storeOffset = state$.value.database.offset;
                    storeSearch = state$.value.database.search;
                    storeSearchByColumns = state$.value.database.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MONITORING_DATABASES
                    );
                    break;
                }
                // export
                case objectTypes.EXPORT_TASK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_DATA_EXPORT
                    );

                    storeOffset = state$.value.export.offset;
                    storeSearch = state$.value.export.search;
                    storeSearchByColumns = state$.value.export.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_DATA_EXPORT
                    );
                    storeShowDeleted = isPrivilegeAvailable(
                        state$.value.login.privileges?.[privilegeConstants.PRIVILEGE_EXPORT_DELETE_TASK_SHOW]
                    )
                        ? generalHelper.getObjectFromDictionaryByKey(
                              state$.value.login.options,
                              optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
                              optionsConstants.OPTION_EXPORT_TASK_SHOW_DELETED
                          )
                        : false;
                    storeShowHidden = isPrivilegeAvailable(
                        state$.value.login.privileges?.[privilegeConstants.PRIVILEGE_EXPORT_HIDE_TASK_SHOW]
                    )
                        ? generalHelper.getObjectFromDictionaryByKey(
                              state$.value.login.options,
                              optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
                              optionsConstants.OPTION_EXPORT_TASK_SHOW_HIDDEN
                          )
                        : false;
                    const loggedUser = state$.value.login.loggedUser ?? null;
                    companyId = !privilegesHelper.isSuperadmin(loggedUser)
                        ? loggedUser?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null
                        : null;
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_FANDECKS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_MASTER_FANDECKS
                    );

                    storeOffset = state$.value.fandeck.masterOffset;
                    storeSearch = state$.value.fandeck.masterSearch;
                    storeSearchByColumns = state$.value.fandeck.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_FANDECKS
                    );
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.MASTER_FANDECK_IN_COLOR: {
                    storeSearch = state$.value.fandeck.masterSearch;
                    storeSearchByColumns = state$.value.fandeck.masterSearchParams;
                    storeAsc = state$.value.fandeck.masterSortingAsc;
                    storeOrderBy = state$.value.fandeck.masterSortingCriterion;
                    simpleData = true;
                    break;
                }
                case objectTypes.MASTER_SYSTEM_FANDECK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_FANDECKS
                    );

                    storeSearch = state$.value.fandeck.masterSystemSearch;
                    storeSearchByColumns = state$.value.fandeck.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.SYSTEM_MASTER_FANDECK: {
                    break;
                }
                case objectTypes.SYSTEM_FANDECK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_FANDECKS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_FANDECKS
                    );

                    storeOffset = state$.value.fandeck.systemOffset;
                    storeSearch = state$.value.fandeck.systemSearch;
                    storeSearchByColumns = state$.value.fandeck.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_FANDECKS
                    );
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.SYSTEM_FANDECK_IN_COLOR: {
                    storeSearch = state$.value.fandeck.systemSearch;
                    storeSearchByColumns = state$.value.fandeck.systemSearchParams;
                    storeAsc = state$.value.fandeck.systemSortingAsc;
                    storeOrderBy = state$.value.fandeck.systemSortingCriterion;
                    simpleData = true;
                    break;
                }
                case objectTypes.SYSTEM_ZONE_FANDECK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_FANDECKS
                    );

                    storeSearch = state$.value.fandeck.systemZoneSearch;
                    storeSearchByColumns = state$.value.fandeck.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.ZONE_SYSTEM_FANDECK: {
                    break;
                }
                case objectTypes.ZONE_FANDECK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_FANDECKS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_FANDECKS
                    );

                    storeOffset = state$.value.fandeck.zoneOffset;
                    storeSearch = state$.value.fandeck.zoneSearch;
                    storeSearchByColumns = state$.value.fandeck.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_FANDECKS
                    );
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.ZONE_FANDECK_IN_COLOR: {
                    storeSearch = state$.value.fandeck.zoneSearch;
                    storeSearchByColumns = state$.value.fandeck.zoneSearchParams;
                    storeAsc = state$.value.fandeck.zoneSortingAsc;
                    storeOrderBy = state$.value.fandeck.zoneSortingCriterion;
                    simpleData = true;
                    break;
                }
                // file
                case objectTypes.FILE: {
                    storeOffset = state$.value.file.fileOffset;
                    storeSearch = state$.value.file.filetSearch;
                    storeSearchByColumns = state$.value.file.fileSearchParams;
                    storeAsc = state$.value.file.fileSortingAsc;
                    storeOrderBy = state$.value.file.fileSortingCriterion;
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MODAL_FILES
                    );
                    fileTypeIds = state$.value.file.fileFileTypeList.map((item: FileType) => item.id);
                    break;
                }
                // file category
                case objectTypes.FILE_CATEGORY: {
                    storeOffset = state$.value.fileCategory.offset;
                    storeSearch = state$.value.fileCategory.search;
                    storeSearchByColumns = state$.value.fileCategory.searchParams;
                    storeAsc = state$.value.fileCategory.sortingAsc;
                    storeOrderBy = state$.value.fileCategory.sortingCriterion;
                    rowCount = tableConstants.DEFAULT_ROW_COUNT;
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    colorInFandeckId =
                        state$.value.color.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_FORMULA: {
                    colorInFandeckId =
                        state$.value.color.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_FORMULA: {
                    colorInFandeckId = state$.value.color.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                // formula colorant
                case objectTypes.MASTER_FORMULA_COLORANT:
                case objectTypes.SYSTEM_FORMULA_COLORANT:
                case objectTypes.ZONE_FORMULA_COLORANT: {
                    break;
                }
                // formula note
                case objectTypes.MASTER_FORMULA_NOTE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_FORMULA_NOTES
                    );

                    storeOffset = state$.value.formulaNote.masterOffset;
                    storeSearch = state$.value.formulaNote.masterSearch;
                    storeSearchByColumns = state$.value.formulaNote.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_FORMULA_NOTES
                    );
                    break;
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
                    );

                    storeOffset = state$.value.formulaNote.systemOffset;
                    storeSearch = state$.value.formulaNote.systemSearch;
                    storeSearchByColumns = state$.value.formulaNote.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
                    );
                    break;
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_FORMULA_NOTES
                    );

                    storeOffset = state$.value.formulaNote.zoneOffset;
                    storeSearch = state$.value.formulaNote.zoneSearch;
                    storeSearchByColumns = state$.value.formulaNote.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_FORMULA_NOTES
                    );
                    break;
                }
                // icon
                case objectTypes.ICON: {
                    storeOffset = state$.value.image.offset;
                    storeSearch = state$.value.image.search;
                    storeSearchByColumns = state$.value.image.searchParams;
                    storeAsc = state$.value.image.sortingAsc;
                    storeOrderBy = state$.value.image.sortingCriterion;
                    rowCount = tableConstants.DEFAULT_ROW_COUNT;
                    fileTypeId = fileTypes.FILE_TYPE_ICON;
                    break;
                }
                // image
                case objectTypes.GENERIC_IMAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_IMAGES
                    );

                    storeOffset = state$.value.image.offset;
                    storeSearch = state$.value.image.search;
                    storeSearchByColumns = state$.value.image.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_IMAGES
                    );
                    break;
                }
                case objectTypes.IMAGE: {
                    storeOffset = state$.value.image.offset;
                    storeSearch = state$.value.image.search;
                    storeSearchByColumns = state$.value.image.searchParams;
                    storeAsc = state$.value.image.sortingAsc;
                    storeOrderBy = state$.value.image.sortingCriterion;
                    rowCount = tableConstants.DEFAULT_ROW_COUNT;
                    fileTypeId = fileTypes.FILE_TYPE_IMAGE;
                    break;
                }
                // image category
                case objectTypes.IMAGE_CATEGORY: {
                    storeOffset = state$.value.imageCategory.offset;
                    storeSearch = state$.value.imageCategory.search;
                    storeSearchByColumns = state$.value.imageCategory.searchParams;
                    storeAsc = state$.value.imageCategory.sortingAsc;
                    storeOrderBy = state$.value.imageCategory.sortingCriterion;
                    rowCount = tableConstants.DEFAULT_ROW_COUNT;
                    break;
                }
                // import
                case objectTypes.IMPORT_TASK: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_DATA_IMPORT
                    );

                    storeOffset = state$.value.import.offset;
                    storeSearch = state$.value.import.search;
                    storeSearchByColumns = state$.value.import.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_DATA_IMPORT
                    );
                    storeShowDeleted = isPrivilegeAvailable(
                        state$.value.login.privileges?.[privilegeConstants.PRIVILEGE_IMPORT_DELETE_TASK_SHOW]
                    )
                        ? generalHelper.getObjectFromDictionaryByKey(
                              state$.value.login.options,
                              optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
                              optionsConstants.OPTION_IMPORT_TASK_SHOW_DELETED
                          )
                        : false;
                    storeShowHidden = isPrivilegeAvailable(
                        state$.value.login.privileges?.[privilegeConstants.PRIVILEGE_IMPORT_HIDE_TASK_SHOW]
                    )
                        ? generalHelper.getObjectFromDictionaryByKey(
                              state$.value.login.options,
                              optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
                              optionsConstants.OPTION_IMPORT_TASK_SHOW_HIDDEN
                          )
                        : false;
                    const loggedUser = state$.value.login.loggedUser ?? null;
                    companyId = !privilegesHelper.isSuperadmin(loggedUser)
                        ? loggedUser?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null
                        : null;
                    break;
                }
                // options
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    storeSearch = state$.value.options.masterSearch;
                    storeSearchByColumns = state$.value.options.masterSearchParams;
                    storeAsc = state$.value.options.masterSortingAsc;
                    storeOrderBy = state$.value.options.masterSortingCriterion;
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    storeSearch = state$.value.options.systemSearch;
                    storeSearchByColumns = state$.value.options.systemSearchParams;
                    storeAsc = state$.value.options.systemSortingAsc;
                    storeOrderBy = state$.value.options.systemSortingCriterion;
                    break;
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    storeSearch = state$.value.options.zoneSearch;
                    storeSearchByColumns = state$.value.options.zoneSearchParams;
                    storeAsc = state$.value.options.zoneSortingAsc;
                    storeOrderBy = state$.value.options.zoneSortingCriterion;
                    break;
                }
                // package
                case objectTypes.PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_PACKAGES
                    );

                    storeOffset = state$.value.package.offset;
                    storeSearch = state$.value.package.search;
                    storeSearchByColumns = state$.value.package.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_PACKAGES
                    );
                    break;
                }
                case objectTypes.SYSTEM_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE
                    );

                    storeOffset = state$.value.package.systemOffset;
                    storeSearch = state$.value.package.systemSearch;
                    storeSearchByColumns = state$.value.package.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE
                    );
                    break;
                }
                case objectTypes.ZONE_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
                    );

                    storeOffset = state$.value.package.zoneOffset;
                    storeSearch = state$.value.package.zoneSearch;
                    storeSearchByColumns = state$.value.package.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_PRICE_SURCHARGE
                    );
                    break;
                }
                // price generic
                case objectTypes.SYSTEM_PRICE_GENERIC: {
                    break;
                }
                case objectTypes.ZONE_PRICE_GENERIC: {
                    break;
                }
                // price group
                case objectTypes.SYSTEM_PRICE_GROUP: {
                    break;
                }
                case objectTypes.ZONE_PRICE_GROUP: {
                    break;
                }
                // price margin
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRICE_MARGIN
                    );

                    storeSearch = state$.value.price.systemMarginSearch;
                    storeSearchByColumns = state$.value.price.systemMarginSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRICE_MARGIN
                    );

                    storeSearch = state$.value.price.zoneMarginSearch;
                    storeSearchByColumns = state$.value.price.zoneMarginSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                // price option
                case objectTypes.SYSTEM_PRICE_OPTION: {
                    break;
                }
                case objectTypes.ZONE_PRICE_OPTION: {
                    break;
                }
                // privileges
                case objectTypes.MASTER_REDLIKE_PRIVILEGE:
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
                    );

                    userGroupId = state$.value.userGroup.masterActiveList.length
                        ? state$.value.userGroup.masterActiveList[0][propertyConstants.PROPERTY_ID]
                        : null;

                    storeOffset = state$.value.privileges.offset;
                    storeSearch = state$.value.privileges.search;
                    storeSearchByColumns = state$.value.privileges.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    storeChangedOnly = state$.value.privileges.changedOnly;
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
                    );
                    break;
                // product
                case objectTypes.MASTER_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
                    );

                    storeOffset = state$.value.product.masterOffset;
                    storeSearch = state$.value.product.masterSearch;
                    storeSearchByColumns = state$.value.product.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
                    );
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.MASTER_PRODUCT_IN_COLOR: {
                    storeSearch = state$.value.product.masterSearch;
                    storeSearchByColumns = state$.value.product.masterSearchParams;
                    storeAsc = state$.value.product.masterSortingAsc;
                    storeOrderBy = state$.value.product.masterSortingCriterion;
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS
                    );

                    storeSearch = state$.value.product.masterSystemSearch;
                    storeSearchByColumns = state$.value.product.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    simpleData = true;
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT: {
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
                    );

                    storeOffset = state$.value.product.systemOffset;
                    storeSearch = state$.value.product.systemSearch;
                    storeSearchByColumns = state$.value.product.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS
                    );
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    addProductGroups = columnVisibility[propertyConstants.PROPERTY_PRODUCT_GROUP_LIST] ?? false;
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_IN_COLOR: {
                    storeSearch = state$.value.product.systemSearch;
                    storeSearchByColumns = state$.value.product.systemSearchParams;
                    storeAsc = state$.value.product.systemSortingAsc;
                    storeOrderBy = state$.value.product.systemSortingCriterion;
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS
                    );
                    storeSearch = state$.value.product.systemZoneSearch;
                    storeSearchByColumns = state$.value.product.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    simpleData = true;
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT: {
                    break;
                }
                case objectTypes.ZONE_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS
                    );

                    storeOffset = state$.value.product.zoneOffset;
                    storeSearch = state$.value.product.zoneSearch;
                    storeSearchByColumns = state$.value.product.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS
                    );
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    addProductGroups = columnVisibility[propertyConstants.PROPERTY_PRODUCT_GROUP_LIST] ?? false;
                    break;
                }
                case objectTypes.ZONE_PRODUCT_IN_COLOR: {
                    storeSearch = state$.value.product.zoneSearch;
                    storeSearchByColumns = state$.value.product.zoneSearchParams;
                    storeAsc = state$.value.product.zoneSortingAsc;
                    storeOrderBy = state$.value.product.zoneSortingCriterion;
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_PRODUCTS_BASES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_MASTER_PRODUCTS_BASES
                    );

                    storeOffset = state$.value.baseInProduct.masterOffset;
                    storeSearch = state$.value.baseInProduct.masterSarch;
                    storeSearchByColumns = state$.value.baseInProduct.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_PRODUCTS_BASES
                    );
                    productId = state$.value.product.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASES
                    );

                    storeSearch = state$.value.baseInProduct.masterSystemSearch;
                    storeSearchByColumns = state$.value.baseInProduct.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
                    );

                    storeOffset = state$.value.baseInProduct.systemOffset;
                    storeSearch = state$.value.baseInProduct.systemSearch;
                    storeSearchByColumns = state$.value.baseInProduct.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASES
                    );
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASES
                    );

                    storeSearch = state$.value.baseInProduct.systemZoneSearch;
                    storeSearchByColumns = state$.value.baseInProduct.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASES
                    );

                    storeOffset = state$.value.baseInProduct.zoneOffset;
                    storeSearch = state$.value.baseInProduct.zoneSearch;
                    storeSearchByColumns = state$.value.baseInProduct.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASES
                    );
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                // product base package
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES
                    );

                    storeSearch = state$.value.baseInProductPackage.masterSystemSearch;
                    storeSearchByColumns = state$.value.baseInProductPackage.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    baseInProductId =
                        state$.value.baseInProduct.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
                    );

                    storeOffset = state$.value.baseInProductPackage.systemOffset;
                    storeSearch = state$.value.baseInProductPackage.systemSearch;
                    storeSearchByColumns = state$.value.baseInProductPackage.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES
                    );
                    baseInProductId =
                        state$.value.baseInProduct.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES
                    );

                    storeSearch = state$.value.baseInProductPackage.systemZoneSearch;
                    storeSearchByColumns = state$.value.baseInProductPackage.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    baseInProductId =
                        state$.value.baseInProduct.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES
                    );
                    columnVisibility = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES
                    );

                    storeOffset = state$.value.baseInProductPackage.zoneOffset;
                    storeSearch = state$.value.baseInProductPackage.zoneSearch;
                    storeSearchByColumns = state$.value.baseInProductPackage.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES
                    );
                    baseInProductId =
                        state$.value.baseInProduct.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    addBarcodes = tableHelper.isBarcodePropertyVisible(columnVisibility);
                    addFiles = tableHelper.isFilePropertyVisible(columnVisibility);
                    addPrices = tableHelper.isPricePropertyVisible(columnVisibility);
                    break;
                }
                // product groups
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_PRODUCT_GROUPS
                    );

                    storeSearch = state$.value.productGroup.masterSystemSearch;
                    storeSearchByColumns = state$.value.productGroup.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS
                    );

                    storeSearch = state$.value.product.masterSystemSearch;
                    storeSearchByColumns = state$.value.product.masterSystemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productGroupId =
                        state$.value.productGroup.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    storeSearch = state$.value.productGroup.systemMasterSearch;
                    storeSearchByColumns = state$.value.productGroup.systemMasterSearchParams;
                    storeAsc = state$.value.productGroup.systemMasterSortingAsc;
                    storeOrderBy = state$.value.productGroup.systemMasterSortingCriterion;
                    productId = state$.value.product.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP:
                case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS: {
                    storeSearch = state$.value.product.systemMasterSearch;
                    storeSearchByColumns = state$.value.product.systemMasterSearchParams;
                    storeAsc = state$.value.product.systemMasterSortingAsc;
                    storeOrderBy = state$.value.product.systemMasterSortingCriterion;
                    productGroupId =
                        state$.value.productGroup.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS
                    );

                    storeOffset = state$.value.productGroup.systemOffset;
                    storeSearch = state$.value.productGroup.systemSearch;
                    storeSearchByColumns = state$.value.productGroup.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS
                    );

                    storeSearch = state$.value.productGroup.systemZoneSearch;
                    storeSearchByColumns = state$.value.productGroup.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS
                    );

                    storeSearch = state$.value.productGroup.systemZoneSearch;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    storeOrderBy = state$.value.productGroup.systemZoneSortingCriterion;
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS
                    );

                    storeSearch = state$.value.product.systemZoneSearch;
                    storeSearchByColumns = state$.value.product.systemZoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    productGroupId =
                        state$.value.productGroup.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    storeSearch = state$.value.product.zoneSystemSearch;
                    storeSearchByColumns = state$.value.product.zoneSystemSearchParams;
                    storeAsc = state$.value.product.zoneSystemSortingAsc;
                    storeOrderBy = state$.value.product.zoneSystemSortingCriterion;
                    productGroupId =
                        state$.value.productGroup.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    storeSearch = state$.value.productGroup.zoneSystemSearch;
                    storeSearchByColumns = state$.value.productGroup.zoneSystemSearchParams;
                    storeAsc = state$.value.productGroup.zoneSystemSortingAsc;
                    storeOrderBy = state$.value.productGroup.zoneSystemSortingCriterion;
                    productId = state$.value.product.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
                    );

                    storeOffset = state$.value.productGroup.zoneOffset;
                    storeSearch = state$.value.productGroup.zoneSearch;
                    storeSearchByColumns = state$.value.productGroup.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
                    );
                    break;
                }
                // product sheet
                case objectTypes.PRODUCT_SHEET: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
                    );

                    storeOffset = state$.value.file.productSheetOffset;
                    storeSearch = state$.value.file.productSheetSearch;
                    storeSearchByColumns = state$.value.file.productSheetSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
                    );
                    fileTypeIds = state$.value.file.productSheetFileTypeList.map((item: FileType) => item.id);
                    break;
                }
                // scripts
                case objectTypes.SERVER_SCRIPTS: {
                    storeSearch = state$.value.script.search;
                    storeSearchByColumns = state$.value.script.searchParams;
                    storeAsc = state$.value.script.sortingAsc;
                    storeOrderBy = state$.value.script.sortingCriterion;
                    break;
                }
                // spectro
                case objectTypes.SPECTRO_TYPE: {
                    storeSearch = state$.value.spectroType.search;
                    storeSearchByColumns = state$.value.spectroType.searchParams;
                    storeAsc = state$.value.spectroType.sortingAsc;
                    storeOrderBy = state$.value.spectroType.sortingCriterion;
                    break;
                }
                // static sql
                case objectTypes.STATIC_SQL: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_STATIC_SQL
                    );

                    storeOffset = state$.value.file.staticSqlOffset;
                    storeSearch = state$.value.file.staticSqlSearch;
                    storeSearchByColumns = state$.value.file.staticSqlSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_STATIC_SQL
                    );
                    fileTypeIds = state$.value.file.staticSqlFileTypeList.map((item: FileType) => item.id);
                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GLOBAL_SYSTEMS
                    );

                    storeOffset = state$.value.system.offset;
                    storeSearch = state$.value.system.search;
                    storeSearchByColumns = state$.value.system.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GLOBAL_SYSTEMS
                    );
                    break;
                }
                // system zone
                case objectTypes.SYSTEM_ZONE: {
                    storeSearch = state$.value.databaseSystemZone.systemZoneSearch;
                    storeSearchByColumns = state$.value.databaseSystemZone.systemZoneSearchParams;
                    storeAsc = state$.value.databaseSystemZone.systemZoneSortingAsc;
                    storeOrderBy = state$.value.databaseSystemZone.systemZoneSortingCriterion;
                    break;
                }
                // unit
                case objectTypes.UNIT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GENERIC_UNITS
                    );

                    storeOffset = state$.value.unit.offset;
                    storeSearch = state$.value.unit.search;
                    storeSearchByColumns = state$.value.unit.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GENERIC_UNITS
                    );
                    break;
                }
                // user action history
                case objectTypes.USER_ACTION_HISTORY: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_USER_ACTIONS_HISTORY
                    );
                    storeSearchByColumns = tableConstants.DEFAULT_SEARCH_PARAMS;
                    const searchParams = state$.value.actionHistory.searchParams;
                    for (const item in searchParams) {
                        if (searchParams[item] !== "") {
                            switch (item) {
                                case propertyConstants.PROPERTY_ACTION_TYPE_ID:
                                    actionTypeId = Number(searchParams[item]);
                                    break;
                                case propertyConstants.PROPERTY_ITEM_TYPE_ID:
                                    itemTypeId = Number(searchParams[item]);
                                    break;
                                default:
                                    storeSearchByColumns = {
                                        ...storeSearchByColumns,
                                        [item]: searchParams[item]
                                    };
                                    break;
                            }
                        }
                    }

                    storeDateFrom = state$.value.actionHistory.dateFrom
                        ? formattingHelper.formatDateTimeForServer(state$.value.actionHistory.dateFrom)
                        : undefined;
                    storeDateTo = state$.value.actionHistory.dateTo
                        ? formattingHelper.formatDateTimeForServer(state$.value.actionHistory.dateTo)
                        : undefined;
                    storeOffset = state$.value.actionHistory.offset;
                    storeSearch = state$.value.actionHistory.search;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_USER_ACTIONS_HISTORY
                    );
                    break;
                }
                // user
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP: {
                    userId = state$.value.user.masterActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_REDLIKE_USERS
                    );

                    storeOffset = state$.value.user.masterOffset;
                    storeSearch = state$.value.user.masterSearch;
                    storeSearchByColumns = state$.value.user.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_MASTER_REDLIKE_USERS
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP: {
                    userId = state$.value.user.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_SYSTEM_REDLIKE_USERS
                    );

                    storeOffset = state$.value.user.systemOffset;
                    storeSearch = state$.value.user.systemSearch;
                    storeSearchByColumns = state$.value.user.systemSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_SYSTEM_REDLIKE_USERS
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
                case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP: {
                    userId = state$.value.user.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                case objectTypes.USER: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_USERS
                    );

                    storeOffset = state$.value.user.offset;
                    storeSearch = state$.value.user.search;
                    storeSearchByColumns = state$.value.user.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    /* rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_USERS
                    );*/

                    break;
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_ZONE_REDLIKE_USERS
                    );

                    storeOffset = state$.value.user.zoneOffset;
                    storeSearch = state$.value.user.zoneSearch;
                    storeSearchByColumns = state$.value.user.zoneSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_ZONE_REDLIKE_USERS
                    );
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS:
                case objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS:
                case objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS: {
                    break;
                }
                // user group
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
                    );

                    storeSearch = state$.value.userGroup.search;
                    storeSearchByColumns = state$.value.userGroup.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                case objectTypes.USER_GROUP: {
                    storeOffset = state$.value.userGroup.offset;
                    storeSearch = state$.value.userGroup.search;
                    storeSearchByColumns = state$.value.userGroup.searchParams;
                    storeAsc = state$.value.userGroup.sortingAsc;
                    storeOrderBy = state$.value.userGroup.sortingCriterion;
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_USER_GROUPS
                    );
                    break;
                }
                // zone
                case objectTypes.WEB_ZONE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
                    );

                    storeSearch = state$.value.zone.webSearch;
                    storeSearchByColumns = state$.value.zone.webSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    companyId = state$.value.database.active?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null;
                    break;
                }
                case objectTypes.ZONE: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_GLOBAL_ZONES
                    );

                    storeOffset = state$.value.zone.offset;
                    storeSearch = state$.value.zone.search;
                    storeSearchByColumns = state$.value.zone.searchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    rowCount = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_ROW_COUNT,
                        menuConstants.TABLE_GLOBAL_ZONES
                    );
                    systemId = state$.value.system.activeList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null;
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            requests.push(
                universalObjectActions.getAllItems(payload.objectType, {
                    actionTypeId: actionTypeId,
                    addBarcodes: addBarcodes,
                    addFiles: addFiles,
                    addPrices: addPrices,
                    addProductGroups: addProductGroups,
                    asc: storeAsc,
                    changedOnly: storeChangedOnly,
                    dateFrom: storeDateFrom,
                    dateTo: storeDateTo,
                    offset: storeOffset,
                    orderBy: storeOrderBy,
                    rowCount: rowCount,
                    search: storeSearch,
                    searchByColumns: storeSearchByColumns,
                    showDeleted: storeShowDeleted,
                    showHidden: storeShowHidden,
                    simpleData: simpleData,
                    baseInProductId: baseInProductId,
                    colorInFandeckId: colorInFandeckId,
                    colorantId: colorantId,
                    companyId: companyId,
                    fandeckId: fandeckId,
                    fileTypeId: fileTypeId,
                    fileTypeIds: fileTypeIds,
                    itemTypeId: itemTypeId,
                    productGroupId: productGroupId,
                    productId: productId,
                    statusId: statusId,
                    systemId: systemId,
                    userId: userId,
                    userGroupId: userGroupId,
                    zoneId: zoneId
                })
            );

            return requests;
        })
    );

export const insertAdditionalData: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.ADD_INDEX_DATA),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];
            let sorting = undefined;
            let storeAsc = undefined;
            let storeOrderBy = undefined;
            let storeSearch = undefined;
            let storeSearchByColumns = undefined;
            let indexData: Record<string, any> = {};

            switch (payload.objectType) {
                case objectTypes.MASTER_PRODUCT: {
                    sorting = generalHelper.getObjectFromDictionaryByKey(
                        state$.value.login.options,
                        optionsConstants.OPTION_TABLE_SORTING,
                        menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS
                    );

                    storeSearch = state$.value.product.masterSearch;
                    storeSearchByColumns = state$.value.product.masterSearchParams;
                    storeAsc = tableHelper.getSortingObjectValue(sorting);
                    storeOrderBy = tableHelper.getSortingObjectKey(sorting);
                    break;
                }
                default:
                    break;
            }

            indexData = {
                asc: storeAsc,
                orderBy: storeOrderBy,
                search: storeSearch,
                searchByColumns: storeSearchByColumns
            };

            requests.push(
                universalObjectActions.addItem(payload.objectType, {
                    ...payload.params,
                    indexData: indexData
                })
            );

            return requests;
        })
    );
