export class OptionItem {
    key: string;
    type: string;
    privilegeKey: string;
    value: any;
    defaultValue: any;
    visible: boolean;
    editable: boolean;
    modified: boolean;
    locked: boolean;

    constructor(
        key: string,
        type: string,
        privilegeKey: string,
        value: any,
        defaultValue: any,
        visible: boolean,
        editable: boolean,
        modified: boolean,
        locked: boolean
    ) {
        this.key = key;
        this.type = type;
        this.privilegeKey = privilegeKey;
        this.value = value;
        this.defaultValue = defaultValue;
        this.visible = visible;
        this.editable = editable;
        this.modified = modified;
        this.locked = locked;
    }
}

export function mapOptionItem(data: Record<string, any>): OptionItem | null {
    try {
        return new OptionItem(
            data.key,
            data.type,
            data.privilegeKey,
            data.value,
            data.defaultValue,
            data.visible,
            data.editable,
            data.modified,
            data.locked
        );
    } catch (e) {
        return null;
    }
}
