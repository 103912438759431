import * as actionConstants from "../constants/actionTypes";
import * as licenseActions from "../actions/licenseActions";

import { Epic, ofType } from "redux-observable";

import { switchMap } from "rxjs/operators";

export const saveSessionInfo: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.EVENT_SAVE_SESSION_INFO),
        switchMap(() => {
            return [licenseActions.getLicenseStatus()];
        })
    );
