import * as importExportConstants from "../../../../constants/entityConstants/importExportConstants";
import * as importExportTaskHelper from "../../../../helpers/importExportTaskHelper";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as requestHelper from "../../../../helpers/requestHelper";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ExportStatus } from "../../../../types/exportStatus";
import { ImportStatus } from "../../../../types/importStatus";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
    dataPrivileges: any;
    privileges: any;
    editable: boolean;
};

type Props = OwnProps & ContainerProps;

export class TableHideShowButton extends Component<Props> {
    /**
     Button is visible if
     * it is not hidden by constants
     * for Import task and Export task, the privilege is available
     * if logged in user has data privilege to update
     */
    isVisible = (): boolean => {
        const { dataPrivileges, objectType, privileges } = this.props;

        if (!objectTypes.HIDE_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return false;
        }

        if (objectTypeHelper.isObjectTypeExportImport(objectType) && dataPrivileges?.canUpdate) {
            return importExportTaskHelper.isHidingImportExportTasksEnabled(objectType, privileges, []);
        }

        if (dataPrivileges?.canUpdate) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { objectType, dataPrivileges, privileges, activeList, editable } = this.props;

        if (!dataPrivileges?.canUpdate || !editable || !activeList.length) {
            return false;
        }

        if (objectTypeHelper.isObjectTypeExportImport(objectType)) {
            return importExportTaskHelper.isHidingImportExportTasksEnabled(
                objectType,
                privileges,
                this.props.activeList
            );
        }

        return true;
    };

    getButtonClassname = (): string => {
        let className = "btn-md btn-info btn-long";

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    handleHideItemClick = (): void => {
        const { objectType, activeList, hideItem, showItem } = this.props;

        const idProperty = requestHelper.getObjectIdProperty(objectType);

        if (idProperty) {
            for (const item of activeList) {
                if (item[propertyConstants.PROPERTY_DELETED] === importExportConstants.TASK_HIDDEN) {
                    showItem(objectType, {
                        [idProperty]: item[propertyConstants.PROPERTY_ID]
                    });
                } else {
                    hideItem(objectType, {
                        [idProperty]: item[propertyConstants.PROPERTY_ID]
                    });
                }
            }
        }
    };

    getButtonTitle = (): string => {
        if (this.props.activeList[0]?.[propertyConstants.PROPERTY_DELETED] === importExportConstants.TASK_HIDDEN) {
            return translate("general.show");
        }

        return translate("general.hide");
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className={this.getButtonClassname()} onClick={(): void => this.handleHideItemClick()}>
                    {this.getButtonTitle()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getButtonClassname()}>{translate("general.hideShow")}</button>;
        }

        return null;
    }
}

export type PropsType = Readonly<{
    exportStatusList: Array<ExportStatus>;
    importStatusList: Array<ImportStatus>;
}>;

export type DispatchType = Readonly<{
    hideItem(objectType: objectTypes.ObjectType, params: any): void;
    showItem(objectType: objectTypes.ObjectType, params: Record<string, any>): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    exportStatusList: state.export.exportStatuses,
    importStatusList: state.import.importStatuses
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    hideItem: (objectType: objectTypes.ObjectType, params: Record<string, any>): any =>
        dispatch(universalObjectActions.hideItem(objectType, params)),
    showItem: (objectType: objectTypes.ObjectType, params: Record<string, any>): any =>
        dispatch(universalObjectActions.showItem(objectType, params))
});

export const TableHideShowButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableHideShowButton);
