import * as generalHelper from "../../../../helpers/generalHelper";
import * as navigationConstants from "../../../../constants/navigationTypes";
import * as optionActions from "../../../../actions/optionActions";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

import imgLayout2Column from "../../../../resources/img/icons/layout5.svg";
import imgLayoutBigLeft from "../../../../resources/img/icons/layout1.svg";
import imgLayoutBigTop from "../../../../resources/img/icons/layout3.svg";
import imgLayoutColumn from "../../../../resources/img/icons/layout2.svg";
import imgLayoutColumns from "../../../../resources/img/icons/layout4.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    sceneConstant: string;
    dataPrivileges?: any;
    tablesCount: number;
};

type Props = OwnProps & ContainerProps;

type State = {
    activeLayout: string | null;
};

export class SceneLayoutButtons extends Component<Props, State> {
    _dropdownRef = React.createRef<HTMLDivElement>();
    state: State = {
        activeLayout: null
    };

    componentDidMount(): void {
        this.setState({
            activeLayout: generalHelper.getObjectFromDictionaryByKey(
                this.props.options,
                optionsConstants.OPTION_SCENE_LAYOUT,
                this.props.sceneConstant
            )
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({
                activeLayout: generalHelper.getObjectFromDictionaryByKey(
                    this.props.options,
                    optionsConstants.OPTION_SCENE_LAYOUT,
                    this.props.sceneConstant
                )
            });
        }
    }

    getContainerClassName = (): string => {
        const { activeScene } = this.props;
        let className = "layout-buttons-container";

        if (privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            className += " readonly";
        }

        if (navigationConstants.MASTER_SCENES_LIST.includes(activeScene)) {
            return className + " master";
        }

        if (navigationConstants.SYSTEM_SCENES_LIST.includes(activeScene)) {
            return className + " system";
        }

        if (navigationConstants.ZONE_SCENES_LIST.includes(activeScene)) {
            return className + " zone";
        }

        return className;
    };

    getButtonClassName = (key: string): string => {
        let className = "btn-info";

        if (key === this.state.activeLayout) {
            className += " active";
        }

        return className;
    };

    changeLayout = (key: string): void => {
        this.setState({
            activeLayout: key
        });

        this.props.setLayout(this.props.sceneConstant, key);
    };

    getLayoutButtons = (): JSX.Element | null => {
        const { tablesCount } = this.props;

        if (tablesCount === 2) {
            return (
                <React.Fragment>
                    <button
                        className={this.getButtonClassName(optionsConstants.LAYOUT_COLUMN)}
                        onClick={(): void => this.changeLayout(optionsConstants.LAYOUT_COLUMN)}
                    >
                        <ReactSVG src={imgLayout2Column} />
                    </button>
                    <button
                        className={this.getButtonClassName(optionsConstants.LAYOUT_COLUMNS)}
                        onClick={(): void => this.changeLayout(optionsConstants.LAYOUT_COLUMNS)}
                    >
                        <ReactSVG src={imgLayoutColumns} />
                    </button>
                </React.Fragment>
            );
        }

        if (tablesCount === 3) {
            return (
                <React.Fragment>
                    <button
                        className={this.getButtonClassName(optionsConstants.LAYOUT_BIG_LEFT)}
                        onClick={(): void => this.changeLayout(optionsConstants.LAYOUT_BIG_LEFT)}
                    >
                        <ReactSVG src={imgLayoutBigLeft} />
                    </button>
                    <button
                        className={this.getButtonClassName(optionsConstants.LAYOUT_COLUMN)}
                        onClick={(): void => this.changeLayout(optionsConstants.LAYOUT_COLUMN)}
                    >
                        <ReactSVG src={imgLayoutColumn} />
                    </button>
                    <button
                        className={this.getButtonClassName(optionsConstants.LAYOUT_BIG_TOP)}
                        onClick={(): void => this.changeLayout(optionsConstants.LAYOUT_BIG_TOP)}
                    >
                        <ReactSVG src={imgLayoutBigTop} />
                    </button>
                </React.Fragment>
            );
        }

        return null;
    };

    render(): JSX.Element | null {
        if (this.state.activeLayout !== null && this.state.activeLayout !== optionsConstants.LAYOUT_PANEL) {
            return <div className={this.getContainerClassName()}>{this.getLayoutButtons()}</div>;
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeScene: navigationConstants.Scene;
    options: any;
}>;

export type DispatchType = Readonly<{
    setLayout(scene: string, layout: string): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    options: state.login.options
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setLayout: (scene: string, newValue: any): any =>
        dispatch(optionActions.setDictionaryOption(optionsConstants.OPTION_SCENE_LAYOUT, scene, newValue))
});

export const SceneLayoutButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(SceneLayoutButtons);
