export const EVENT_PING = "ping";

export const EVENT_SESSION_INITIALIZED = "sessionInitialized";

export const EVENT_DATABASE_LOCKED = "databaseLocked";
export const EVENT_DATABASE_SELECTED = "databaseSelected";
export const EVENT_DATABASE_VACUUM_CHANGED = "databaseVacuumChanged";
export const EVENT_SYSTEM_CURRENCY_SELECTED = "systemCurrencySelected";
export const EVENT_SYSTEM_SELECTED = "systemSelected";
export const EVENT_USER_SELECTED = "userSelected";
export const EVENT_ZONE_CURRENCY_SELECTED = "zoneCurrencySelected";
export const EVENT_ZONE_SELECTED = "zoneSelected";

export const EVENT_DATA_PRIVILEGES_CHANGED = "dataPrivilegesChanged";
export const EVENT_EXPORT_TASK_CHANGED = "exportTaskStatusChanged";
export const EVENT_IMPORT_TASK_CHANGED = "importTaskStatusChanged";
export const EVENT_MENU_CHANGED = "menuChanged";
export const EVENT_OPTIONS_CHANGED = "optionsChanged";
export const EVENT_PRIVILEGES_CHANGED = "privilegesChanged";
