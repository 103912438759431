import * as databaseActions from "../../../actions/databaseActions";
import * as defaultConstants from "../../../constants/default";
import * as designConstants from "../../../constants/designConstants";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import { DispatchType, PropsType } from "../../../containers/scenes/header/ServerMonitoringSceneContainer";
import React, { FunctionComponent, useEffect, useState } from "react";
import { createEmptyExportImportObject, generateDeleteExportImportHeadings } from "../../../types/exportImportTask";

import { DataItem } from "../../../types/dataItem";
import { ModalParams } from "../../../types/modalParams";
import { MonitoringDatabaseTable } from "./tables/MonitoringDatabaseTable";
import { ReactSVG } from "react-svg";
import { SceneSaveButtonContainer } from "../../general/scene/buttons/SceneSaveButton";

import imgLoader from "../../../resources/img/loader.svg";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

export const ServerMonitoringScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableDatabase, setTableDatabases] = useState<DataItem | null>(
        (props.dataPrivileges
            ? props.dataPrivileges.find((item: DataItem) => item.key === props.databaseTableConstant)
            : null) ?? null
    );
    useEffect(() => {
        setTableDatabases(
            (props.dataPrivileges
                ? props.dataPrivileges.find((item: DataItem) => item.key === props.databaseTableConstant)
                : null) ?? null
        );
    }, [props.dataPrivileges, props.databaseTableConstant]);

    const getPanelClassname = (usedData: number): string => {
        let className = "progress";

        if (usedData > designConstants.SERVER_MONITORING_ERROR_PROGRESS) {
            className += " danger";
        }
        if (
            usedData > designConstants.SERVER_MONITORING_WARNING_PROGRESS &&
            usedData <= designConstants.SERVER_MONITORING_ERROR_PROGRESS
        ) {
            className += " warning";
        }

        return className;
    };

    const getPanels = (): JSX.Element | null => {
        const { dataSize } = props;
        const panels: Array<JSX.Element> = [];

        if (dataSize) {
            panels.push(
                getPanel(
                    translate("database.masterDatabases"),
                    dataSize[propertyConstants.PROPERTY_SSD_USED_PERCENT],
                    dataSize[propertyConstants.PROPERTY_USED_SSD],
                    dataSize[propertyConstants.PROPERTY_AVAILABLE_SSD]
                )
            );
            panels.push(
                getPanel(
                    translate("general.exportImportData"),
                    dataSize[propertyConstants.PROPERTY_HDD_USED_PERCENT],
                    dataSize[propertyConstants.PROPERTY_USED_HDD],
                    dataSize[propertyConstants.PROPERTY_AVAILABLE_HDD]
                )
            );

            return <div className="info-panel">{panels}</div>;
        }

        return (
            <div className="info-panel">
                <ReactSVG src={imgLoader} className="loader" />
            </div>
        );
    };

    const getPanel = (
        caption: string,
        usedDataPercent: number,
        usedData: number,
        availableData: number
    ): JSX.Element => {
        const usedPercentFormatted = usedDataPercent <= 1 ? usedDataPercent * 100 : 100;

        return (
            <div className="panel-row" key={caption}>
                <div className="label">{caption}</div>
                <div className="progress-bar">
                    <div className="progress-caption" style={{ left: usedPercentFormatted - 4 + "%" }}>
                        <span>{formattingHelper.formatFileSize(usedData, defaultConstants.SIZE_UNIT_GB)}</span>
                    </div>
                    <div
                        className={getPanelClassname(usedPercentFormatted)}
                        style={{ width: usedPercentFormatted + "%" }}
                    >
                        <span className="percentage-caption">{formattingHelper.formatPercent(usedDataPercent)}</span>
                    </div>
                </div>
                <div className="limit-label">
                    <span>{formattingHelper.formatFileSize(availableData, defaultConstants.SIZE_UNIT_GB)}</span>
                </div>
            </div>
        );
    };

    const getVacuumButton = (): JSX.Element => {
        const modalParams: ModalParams = {
            type: modalTypes.DELETE_MODAL,
            title: translate("database.vacuumMasterDatabases"),
            objectType: objectTypes.DATABASE_MONITORING,
            data: [],
            displayText: translate("database.vacuumDatabasesDescription")
        };

        return (
            <button
                className="btn-info"
                onClick={(): void =>
                    props.openModal(
                        modalTypes.MODAL_DATABASES_VACUUM,
                        modalParams,
                        databaseActions.vacuumAllDatabases()
                    )
                }
            >
                {translate("database.vacuumMasterDatabases")}
            </button>
        );
    };

    const getModalParams = (
        objectType: objectTypes.ObjectType,
        modalType: modalTypes.ModalType,
        title: string
    ): ModalParams => {
        const list: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_DATE_FROM,
            propertyConstants.PROPERTY_DATE_TO,
            propertyConstants.PROPERTY_DATABASE_ID,
            propertyConstants.PROPERTY_SYSTEM_ID,
            propertyConstants.PROPERTY_ZONE_ID,
            propertyConstants.PROPERTY_STATUS_LIST
        ];

        return {
            type: modalTypes.DELETE_MODAL,
            title: title,
            objectType: objectType,
            headings: generateDeleteExportImportHeadings(
                objectType,
                modalType,
                props.databaseSystemZoneList,
                props.exportStatusList,
                props.importStatusList,
                list,
                list,
                list,
                [propertyConstants.PROPERTY_DATE_FROM, propertyConstants.PROPERTY_DATE_TO],
                list
            ),
            data: createEmptyExportImportObject(objectType, props.exportStatusList, props.importStatusList)
        };
    };

    const getDeleteExportsButton = (): JSX.Element => {
        if (!props.databaseSystemZoneLoading) {
            return (
                <button
                    className="btn-info"
                    onClick={(): void =>
                        props.openModal(
                            modalTypes.MODAL_EXPORT_TASKS_DELETE,
                            getModalParams(
                                objectTypes.EXPORT_TASK_DELETE,
                                modalTypes.MODAL_EXPORT_TASKS_DELETE,
                                translate("export.deleteExports")
                            )
                        )
                    }
                >
                    {translate("export.deleteExports")}
                </button>
            );
        }

        return (
            <button className="btn-info disabled" disabled>
                {translate("export.deleteExports")}
            </button>
        );
    };

    const getDeleteImportsButton = (): JSX.Element => {
        if (!props.databaseSystemZoneLoading) {
            return (
                <button
                    className="btn-info"
                    onClick={(): void =>
                        props.openModal(
                            modalTypes.MODAL_IMPORT_TASKS_DELETE,
                            getModalParams(
                                objectTypes.IMPORT_TASK_DELETE,
                                modalTypes.MODAL_IMPORT_TASKS_DELETE,
                                translate("import.deleteImports")
                            )
                        )
                    }
                >
                    {translate("import.deleteImports")}
                </button>
            );
        }

        return (
            <button className="btn-info disabled" disabled>
                {translate("import.deleteImports")}
            </button>
        );
    };

    const getDatabasesTable = (): JSX.Element | null => {
        if (tableDatabase) {
            return (
                <div className="layout-item scene-item" key={props.databaseTableConstant}>
                    <MonitoringDatabaseTable
                        tableConstant={props.databaseTableConstant}
                        objectType={objectTypes.DATABASE_MONITORING}
                        privileges={props.dataPrivileges}
                        dataPrivileges={tableDatabase}
                        loading={props.databaseLoading}
                        companyList={props.companyList}
                        allList={props.databaseList}
                        activeList={props.databaseActiveList}
                        columnOrder={props.databaseColumnOrder}
                        columnVisibility={props.databaseColumnVisibility}
                        columnWidth={props.databaseColumnWidth}
                        offset={props.databaseOffset}
                        page={props.databasePage}
                        rowCount={props.databaseRowCount}
                        rowCountCustom={props.databaseRowCountCustom}
                        search={props.databaseSearch}
                        searchParams={props.databaseSearchParams}
                        showFilterRow={props.databaseShowFilterRow}
                        showGlobalSearch={props.databaseShowGlobalSearch}
                        sortingAsc={props.databaseSortingAsc}
                        sortingCriterion={props.databaseSortingCriterion}
                        totalCount={props.databaseTotalCount}
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <div className="height-100">
            <SceneSaveButtonContainer />
            <div className="content-layout">
                <div className="layout-item">
                    <div className="height-100">
                        <div className="main-title">{translate("general.serverMonitoring")}</div>
                        {getPanels()}
                        {getDatabasesTable()}
                        <div className="button-column">
                            {getVacuumButton()}
                            {getDeleteExportsButton()}
                            {getDeleteImportsButton()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
