import * as designConstants from "../../constants/designConstants";
import * as designHelper from "../../helpers/designHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as menuConstants from "../../constants/menuConstants";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as objectTypeHelper from "../../helpers/objectTypeHelper";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as validationHelper from "../../helpers/validationHelper";

import { Base, generateBaseData } from "../../types/base";
import { BaseInProduct, generateBaseInProductData } from "../../types/baseInProduct";
import { BaseInProductPackage, generateBaseInProductPackageData } from "../../types/baseInProductPackage";
import { Colorant, generateColorantData } from "../../types/colorant";
import { ColorantPackage, generateColorantPackageData } from "../../types/colorantPackage";
import { Fandeck, generateFandeckData } from "../../types/fandeck";
import { Product, generateProductData } from "../../types/product";
import { ProductGroup, generateProductGroupData } from "../../types/productGroup";
import React, { Component } from "react";
import { UserGroup, generateUserGroupData } from "../../types/userGroup";

import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { FooterAlertProperty } from "./general/FooterAlertProperty";
import { Form } from "./general/Form";
import { GeneralPropertyModal } from "./general/GeneralPropertyModal";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { Scene } from "../../constants/navigationTypes";
import { ServerRequest } from "../../types/serverRequest";
import { TableBoxContainer } from "../TableBox";
import { TableHeading } from "../../types/tableHeading";
import { Validation } from "../../types/validation";
import { connect } from "react-redux";
import imgClose from "../../resources/img/icons/close.svg";
import { t as translate } from "react-i18nify";

type ContainerProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type OwnProps = PropsType & DispatchType;

type Props = ContainerProps & OwnProps;

type State = {
    objectData: Record<string, any> | null;
    isInherited: boolean;
    validationList: Array<Validation>;
};
// TODO check whole file
class PropagateObjectModal extends Component<Props, State> {
    state: State = {
        objectData: null,
        isInherited: false,
        validationList: []
    };

    componentDidMount(): void {
        const { objectType, data } = this.props.params;

        if (
            data &&
            (objectTypeHelper.isObjectTypePropagateProductGroup(objectType) ||
                objectTypeHelper.isObjectTypePropagateRedlikeUser(objectType))
        ) {
            this.setState({
                objectData: data
            });
        }

        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    hasDuplicates = (): boolean => {
        const duplicateProperty = objectTypeHelper.isObjectTypePropagateBaseInProduct(this.props.params.objectType)
            ? propertyConstants.PROPERTY_BASE_NAME
            : propertyConstants.PROPERTY_NAME;
        const updatedData = this.getUpdatedDataByObjectType();
        const uniqueData = generalHelper.isDistinctValueByProperty(updatedData, duplicateProperty);

        return updatedData.length !== uniqueData.length;
    };

    saveObject = (property: propertyConstants.Property, value: any): void => {
        this.setState({
            objectData: { ...this.state.objectData, [property]: value }
        });
    };

    handleModalKeyDown = (event: Record<string, any>): void => {
        switch (event.key) {
            case keyCodes.ENTER: {
                if (this.getUpdatedDataByObjectType().length && event.target.type !== "textarea") {
                    this.handleConfirmClick();
                }

                break;
            }
            case keyCodes.ESCAPE: {
                this.props.closeModal(this.props.type);
                break;
            }
            default:
                break;
        }
    };

    handleIsInheritedChange = (property: propertyConstants.Property, value: any) => {
        if (property === propertyConstants.PROPERTY_INHERITED) {
            this.setState({
                isInherited: value
            });
        }
    };

    handleConfirmClick = (): void => {
        const { inputHeadings, objectType, type } = this.props.params;
        const { addItem, editItem } = this.props;
        const { objectData } = this.state;

        if (!this.hasDuplicates()) {
            // propagate modal with inputSection
            if (objectData) {
                const { data, validationList } = validationHelper.validateItem(
                    objectType,
                    type,
                    this.props.type,
                    inputHeadings ?? [],
                    [],
                    objectData,
                    Object.keys(objectData).map((item) => item)
                );

                if (validationList.length > 0) {
                    validationHelper.processValidationList(
                        validationList,
                        this.state.validationList,
                        this.props.id,
                        () => {
                            return;
                        }
                    );

                    this.setState({
                        validationList: validationList
                    });
                } else {
                    if (type === modalTypes.PROPAGATE_EDIT_MODAL) {
                        editItem(objectType, data);
                    } else {
                        addItem(objectType, data);
                    }

                    if (this.state.validationList.length > 0) {
                        for (const validation of this.state.validationList) {
                            validationHelper.hideInvalidCaption(validation, this.props.id, () => {
                                return;
                            });
                        }
                    }

                    this.setState({
                        validationList: []
                    });
                }
            } else {
                if (
                    objectTypes.PROPAGATE_INHERIT_DATA_OBJECT_TYPES.includes(objectType) &&
                    this.getUpdatedDataByObjectType().length
                ) {
                    addItem(objectType, { inherited: this.state.isInherited });
                } else if (
                    this.getUpdatedDataByObjectType().length ||
                    objectType === objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS ||
                    objectType === objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS
                ) {
                    addItem(objectType);
                }
            }
        }
    };

    getAlert = (): JSX.Element | null => {
        if (this.hasDuplicates()) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_VALIDATION}
                    caption={translate("validation.duplicateNamesProduct")}
                />
            );
        }

        if (this.props.loading) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_LOADING}
                    caption={translate("general.pleaseWait")}
                />
            );
        }

        return null;
    };

    getModalClassName = (): string => {
        const { objectType } = this.props.params;
        let className = "modal modal-lg propagate-modal";

        className += designHelper.getModalClassType(this.props.activeScene);

        if (objectTypeHelper.isObjectTypePropagateProductGroup(objectType)) {
            className += " complex-propagate";
        }

        if (objectTypeHelper.isObjectTypePropagateRedlikeUser(objectType)) {
            className += " complex-propagate propagate-small";
        }

        return className;
    };

    getButtonClassName = (confirm: boolean): string => {
        const { objectType } = this.props.params;

        let className = "btn-info pull-right";

        if (
            (confirm &&
                this.getUpdatedDataByObjectType().length === 0 &&
                objectTypeHelper.isObjectTypePropagateProductGroup(objectType)) ||
            this.props.loading
        ) {
            className += " disabled";
        }

        return className;
    };

    getModalTitle = (): string => {
        const { objectType, type } = this.props.params;
        let value = "";

        if (type === modalTypes.PROPAGATE_EDIT_MODAL) {
            return translationHelper.getEditItemTranslation(objectType);
        }

        if (objectType === objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS) {
            value = this.props.productSystemActiveList?.[0]?.[propertyConstants.PROPERTY_NAME] ?? "";
        }

        if (objectType === objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS) {
            value = this.props.productZoneActiveList?.[0]?.[propertyConstants.PROPERTY_NAME] ?? "";
        }

        return translationHelper.getAddItemTranslation(objectType, value);
    };

    getUpdatedDataByObjectType = (): Array<any> => {
        const { objectType } = this.props.params;

        switch (objectType) {
            case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
            case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
            case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
                return this.props.redlikeUserGroupAvailableList;
            case objectTypes.MASTER_SYSTEM_BASE:
                return this.props.baseSystemMasterList;
            case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
                return this.props.baseInProductSystemMasterList;
            case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
                return this.props.baseInProductPackageSystemMasterList;
            case objectTypes.MASTER_SYSTEM_COLORANT:
                return this.props.colorantSystemMasterList;
            case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
                return this.props.colorantPackageSystemMasterList;
            case objectTypes.MASTER_SYSTEM_FANDECK:
                return this.props.fandeckSystemMasterList;
            case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
            case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
            case objectTypes.MASTER_SYSTEM_PRODUCT:
                return this.props.productSystemMasterList;
            case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
                return this.props.productGroupSystemMasterList;
            case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
            case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
            case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP:
                return this.props.redlikeUserGroupSelectedList;
            case objectTypes.SYSTEM_ZONE_BASE:
                return this.props.baseZoneSystemList;
            case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
                return this.props.baseInProductZoneSystemList;
            case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
                return this.props.baseInProductPackageZoneSystemList;
            case objectTypes.SYSTEM_ZONE_COLORANT:
                return this.props.colorantZoneSystemList;
            case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
                return this.props.colorantPackageZoneSystemList;
            case objectTypes.SYSTEM_ZONE_FANDECK:
                return this.props.fandeckZoneSystemList;
            case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            case objectTypes.SYSTEM_ZONE_PRODUCT:
                return this.props.productZoneSystemList;
            case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                return this.props.productGroupZoneSystemList;
            default:
                return [];
        }
    };

    getTableBox = (): JSX.Element | null => {
        const { dataPrivileges, classNameTable, objectType, headingsDefault, headings } = this.props.params;
        const { type } = this.props;
        let tableConstant = "";
        let updatedData: Array<any> = [];
        let defaultData: Array<any> = [];
        let defaultActiveList: Array<any> = [];
        let updatedActiveList: Array<any> = [];
        let defaultSortingAsc = true;
        let defaultSortingCriterion: propertyConstants.Property | null = null;
        const defaultSearch = tableConstants.DEFAULT_SEARCH;
        const defaultSearchParams = tableConstants.DEFAULT_SEARCH_PARAMS;
        let updatedSortingAsc = true;
        let updatedSortingCriterion: propertyConstants.Property | null = null;
        let loading = false;

        switch (objectType) {
            case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
            case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
            case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                tableConstant = menuConstants.TABLE_AVAILABLE_REDLIKE_USER_GROUPS;
                updatedData = generateUserGroupData(this.props.redlikeUserGroupSelectedList);
                defaultData = generateUserGroupData(this.props.redlikeUserGroupAvailableList);
                defaultActiveList = [...this.props.redlikeUserGroupAvailableActiveList];
                updatedActiveList = [...this.props.redlikeUserGroupSelectedActiveList];
                defaultSortingAsc = tableConstants.DEFAULT_SORTING_ASC;
                defaultSortingCriterion = propertyConstants.PROPERTY_NAME;
                updatedSortingAsc = tableConstants.DEFAULT_SORTING_ASC;
                updatedSortingCriterion = propertyConstants.PROPERTY_NAME;
                loading = this.props.redlikeUserGroupAvailableLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_BASE: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_BASES;
                updatedData = generateBaseData(this.props.baseSystemMasterList);
                defaultData = generateBaseData(this.props.baseMasterSystemList);
                defaultActiveList = [...this.props.baseMasterSystemActiveList];
                updatedActiveList = [...this.props.baseSystemMasterActiveList];
                defaultSortingAsc = this.props.baseMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.baseMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.baseSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.baseSystemMasterSortingCriterion;
                loading = this.props.baseSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASES;
                updatedData = generateBaseInProductData(this.props.baseInProductSystemMasterList);
                defaultData = generateBaseInProductData(this.props.baseInProductMasterSystemList);
                defaultActiveList = [...this.props.baseInProductMasterSystemActiveList];
                updatedActiveList = [...this.props.baseInProductSystemMasterActiveList];
                defaultSortingAsc = this.props.baseInProductMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.baseInProductMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.baseInProductSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.baseInProductSystemMasterSortingCriterion;
                loading = this.props.baseInProductSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES;
                updatedData = generateBaseInProductPackageData(this.props.baseInProductPackageSystemMasterList);
                defaultData = generateBaseInProductPackageData(this.props.baseInProductPackageMasterSystemList);
                defaultActiveList = [...this.props.baseInProductPackageMasterSystemActiveList];
                updatedActiveList = [...this.props.baseInProductPackageSystemMasterActiveList];
                defaultSortingAsc = this.props.baseInProductPackageMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.baseInProductPackageMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.baseInProductPackageSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.baseInProductPackageSystemMasterSortingCriterion;
                loading = this.props.baseInProductPackageSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_COLORANT: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_COLORANTS;
                updatedData = generateColorantData(this.props.colorantSystemMasterList);
                defaultData = generateColorantData(this.props.colorantMasterSystemList);
                defaultActiveList = [...this.props.colorantMasterSystemActiveList];
                updatedActiveList = [...this.props.colorantSystemMasterActiveList];
                defaultSortingAsc = this.props.colorantMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.colorantMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.colorantSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.colorantSystemMasterSortingCriterion;
                loading = this.props.colorantSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_COLORANT_PACKAGES;
                updatedData = generateColorantPackageData(this.props.colorantPackageSystemMasterList);
                defaultData = generateColorantPackageData(this.props.colorantPackageMasterSystemList);
                defaultActiveList = [...this.props.colorantPackageMasterSystemActiveList];
                updatedActiveList = [...this.props.colorantPackageSystemMasterActiveList];
                defaultSortingAsc = this.props.colorantPackageMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.colorantPackageMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.colorantPackageSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.colorantPackageSystemMasterSortingCriterion;
                loading = this.props.colorantPackageSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_FANDECK: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_FANDECKS;
                updatedData = generateFandeckData(this.props.fandeckSystemMasterList);
                defaultData = generateFandeckData(this.props.fandeckMasterSystemList);
                defaultActiveList = [...this.props.fandeckMasterSystemActiveList];
                updatedActiveList = [...this.props.fandeckSystemMasterActiveList];
                defaultSortingAsc = this.props.fandeckMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.fandeckMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.fandeckSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.fandeckSystemMasterSortingCriterion;
                loading = this.props.fandeckSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_PRODUCT_GROUPS;
                updatedData = generateProductGroupData(this.props.productGroupSystemMasterList);
                defaultData = generateProductGroupData(this.props.productGroupMasterSystemList);
                defaultActiveList = [...this.props.productGroupMasterSystemActiveList];
                updatedActiveList = [...this.props.productGroupSystemMasterActiveList];
                defaultSortingAsc = this.props.productGroupMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.productGroupMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.productGroupSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.productGroupSystemMasterSortingCriterion;
                loading = this.props.productGroupSystemLoading;
                break;
            }
            case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
            case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
            case objectTypes.MASTER_SYSTEM_PRODUCT: {
                tableConstant = menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS;
                updatedData = generateProductData(objectType, this.props.productSystemMasterList);
                defaultData = generateProductData(objectType, this.props.productMasterSystemList);
                defaultActiveList = [...this.props.productMasterSystemActiveList];
                updatedActiveList = [...this.props.productSystemMasterActiveList];
                defaultSortingAsc = this.props.productMasterSystemSortingAsc;
                defaultSortingCriterion = this.props.productMasterSystemSortingCriterion;
                updatedSortingAsc = this.props.productSystemMasterSortingAsc;
                updatedSortingCriterion = this.props.productSystemMasterSortingCriterion;
                loading = this.props.productSystemLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_BASE: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_BASES;
                updatedData = generateBaseData(this.props.baseZoneSystemList);
                defaultData = generateBaseData(this.props.baseSystemZoneList);
                defaultActiveList = [...this.props.baseSystemZoneActiveList];
                updatedActiveList = [...this.props.baseZoneSystemActiveList];
                defaultSortingAsc = this.props.baseSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.baseSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.baseZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.baseZoneSystemSortingCriterion;
                loading = this.props.baseZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASES;
                updatedData = generateBaseInProductData(this.props.baseInProductZoneSystemList);
                defaultData = generateBaseInProductData(this.props.baseInProductSystemZoneList);
                defaultActiveList = [...this.props.baseInProductSystemZoneActiveList];
                updatedActiveList = [...this.props.baseInProductZoneSystemActiveList];
                defaultSortingAsc = this.props.baseInProductSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.baseInProductSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.baseInProductZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.baseInProductZoneSystemSortingCriterion;
                loading = this.props.baseInProductZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES;
                updatedData = generateBaseInProductPackageData(this.props.baseInProductPackageZoneSystemList);
                defaultData = generateBaseInProductPackageData(this.props.baseInProductPackageSystemZoneList);
                defaultActiveList = [...this.props.baseInProductPackageSystemZoneActiveList];
                updatedActiveList = [...this.props.baseInProductPackageZoneSystemActiveList];
                defaultSortingAsc = this.props.baseInProductPackageSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.baseInProductPackageSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.baseInProductPackageZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.baseInProductPackageZoneSystemSortingCriterion;
                loading = this.props.baseInProductPackageZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_COLORANT: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_COLORANTS;
                updatedData = generateColorantData(this.props.colorantZoneSystemList);
                defaultData = generateColorantData(this.props.colorantSystemZoneList);
                defaultActiveList = [...this.props.colorantSystemZoneActiveList];
                updatedActiveList = [...this.props.colorantZoneSystemActiveList];
                defaultSortingAsc = this.props.colorantSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.colorantSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.colorantZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.colorantZoneSystemSortingCriterion;
                loading = this.props.colorantZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_COLORANT_PACKAGES;
                updatedData = generateColorantPackageData(this.props.colorantPackageZoneSystemList);
                defaultData = generateColorantPackageData(this.props.colorantPackageSystemZoneList);
                defaultActiveList = [...this.props.colorantPackageSystemZoneActiveList];
                updatedActiveList = [...this.props.colorantPackageZoneSystemActiveList];
                defaultSortingAsc = this.props.colorantPackageSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.colorantPackageSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.colorantPackageZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.colorantPackageZoneSystemSortingCriterion;
                loading = this.props.colorantPackageZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_FANDECK: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_FANDECKS;
                updatedData = generateFandeckData(this.props.fandeckZoneSystemList);
                defaultData = generateFandeckData(this.props.fandeckSystemZoneList);
                defaultActiveList = [...this.props.fandeckSystemZoneActiveList];
                updatedActiveList = [...this.props.fandeckZoneSystemActiveList];
                defaultSortingAsc = this.props.fandeckSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.fandeckSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.fandeckZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.fandeckZoneSystemSortingCriterion;
                loading = this.props.fandeckZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            case objectTypes.SYSTEM_ZONE_PRODUCT: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS;
                updatedData = generateProductData(objectType, this.props.productZoneSystemList);
                defaultData = generateProductData(objectType, this.props.productSystemZoneList);
                defaultActiveList = [...this.props.productSystemZoneActiveList];
                updatedActiveList = [...this.props.productZoneSystemActiveList];
                defaultSortingAsc = this.props.productSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.productSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.productZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.productZoneSystemSortingCriterion;
                loading = this.props.productZoneLoading;
                break;
            }
            case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP: {
                tableConstant = menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS;
                updatedData = generateProductGroupData(this.props.productGroupZoneSystemList);
                defaultData = generateProductGroupData(this.props.productGroupSystemZoneList);
                defaultActiveList = [...this.props.productGroupSystemZoneActiveList];
                updatedActiveList = [...this.props.productGroupZoneSystemActiveList];
                defaultSortingAsc = this.props.productGroupSystemZoneSortingAsc;
                defaultSortingCriterion = this.props.productGroupSystemZoneSortingCriterion;
                updatedSortingAsc = this.props.productGroupZoneSystemSortingAsc;
                updatedSortingCriterion = this.props.productGroupZoneSystemSortingCriterion;
                loading = this.props.productGroupZoneLoading;
                break;
            }
            default: {
                break;
            }
        }

        return (
            <div className="modal-content slim-scroll">
                {this.getForm()}
                <TableBoxContainer
                    tableConstant={tableConstant}
                    updatedData={updatedData}
                    defaultData={defaultData}
                    defaultActiveList={defaultActiveList}
                    updatedActiveList={updatedActiveList}
                    objectType={objectType}
                    dataPrivileges={dataPrivileges}
                    classNameTable={classNameTable}
                    defaultHeadings={headingsDefault}
                    defaultSearch={defaultSearch}
                    defaultSearchParams={defaultSearchParams}
                    updatedHeadings={headings}
                    loading={loading}
                    modalType={type}
                    defaultSortingAsc={defaultSortingAsc}
                    defaultSortingCriterion={defaultSortingCriterion}
                    updatedSortingAsc={updatedSortingAsc}
                    updatedSortingCriterion={updatedSortingCriterion}
                />
                {this.getInheritedCheckbox()}
            </div>
        );
    };

    getForm = (): JSX.Element | null => {
        const { objectType } = this.props.params;

        if (
            objectTypeHelper.isObjectTypePropagateProductGroup(objectType) ||
            objectTypeHelper.isObjectTypePropagateRedlikeUser(objectType)
        ) {
            return (
                <Form
                    modalId={this.props.id}
                    modalType={this.props.params.type}
                    sessionUuid={this.props.sessionUuid}
                    urlRest={this.props.urlRest}
                    objectType={objectType}
                    headings={this.props.params.inputHeadings || []}
                    objectData={this.state.objectData}
                    callbackClick={(heading: TableHeading, property: propertyConstants.Property, value: any) => {
                        return;
                    }}
                    callbackChange={(property: propertyConstants.Property, value: any) => {
                        this.saveObject(property, value);
                    }}
                    callbackBlur={(property: propertyConstants.Property, value: any) => {
                        this.saveObject(property, value);
                    }}
                    confirm={(property: propertyConstants.Property, value: any) => {
                        this.saveObject(property, value);
                    }}
                />
            );
        }

        return null;
    };

    getInheritedCheckbox = (): JSX.Element | null => {
        if (objectTypes.PROPAGATE_INHERIT_DATA_OBJECT_TYPES.includes(this.props.params.objectType)) {
            return (
                <GeneralPropertyModal
                    modalId={this.props.id}
                    objectType={this.props.params.objectType}
                    editable={true}
                    required={false}
                    property={propertyConstants.PROPERTY_INHERITED}
                    caption={translationHelper.getInheritDataPropagateModalTranslations(this.props.params.objectType)}
                    value={this.state.isInherited}
                    type={tableConstants.TABLE_TYPE_BOOLEAN}
                    callback={(property: propertyConstants.Property, value: any) =>
                        this.handleIsInheritedChange(property, value)
                    }
                />
            );
        }
        return null;
    };

    render(): JSX.Element {
        const { type, index, closeModal } = this.props;

        return (
            <div className={this.getModalClassName()} id={`modal-${this.props.id}`} style={{ zIndex: index }}>
                <header className="modal-header">
                    <div className="title">{this.getModalTitle()}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                {this.getTableBox()}
                <footer className="modal-footer">
                    {this.getAlert()}
                    <button
                        className={this.getButtonClassName(true)}
                        onClick={this.handleConfirmClick}
                        autoFocus={true}
                    >
                        {translate("general.ok")}
                    </button>
                    <button
                        className={this.getButtonClassName(false)}
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        {translate("general.cancel")}
                    </button>
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    // base
    baseMasterSystemList: Array<Base>;
    baseMasterSystemActiveList: Array<Base>;
    baseMasterSystemSortingAsc: boolean;
    baseMasterSystemSortingCriterion: propertyConstants.Property | null;
    baseSystemMasterList: Array<Base>;
    baseSystemMasterActiveList: Array<Base>;
    baseSystemMasterSortingAsc: boolean;
    baseSystemMasterSortingCriterion: propertyConstants.Property;
    baseSystemZoneList: Array<Base>;
    baseSystemZoneActiveList: Array<Base>;
    baseSystemZoneSortingAsc: boolean;
    baseSystemZoneSortingCriterion: propertyConstants.Property | null;
    baseSystemLoading: boolean;
    baseZoneSystemList: Array<Base>;
    baseZoneSystemActiveList: Array<Base>;
    baseZoneSystemSortingAsc: boolean;
    baseZoneSystemSortingCriterion: propertyConstants.Property;
    baseZoneLoading: boolean;
    // base in product
    baseInProductMasterSystemList: Array<BaseInProduct>;
    baseInProductMasterSystemActiveList: Array<BaseInProduct>;
    baseInProductMasterSystemSortingAsc: boolean;
    baseInProductMasterSystemSortingCriterion: propertyConstants.Property | null;
    baseInProductSystemMasterList: Array<BaseInProduct>;
    baseInProductSystemMasterActiveList: Array<BaseInProduct>;
    baseInProductSystemMasterSortingAsc: boolean;
    baseInProductSystemMasterSortingCriterion: propertyConstants.Property;
    baseInProductSystemLoading: boolean;
    baseInProductSystemZoneList: Array<BaseInProduct>;
    baseInProductSystemZoneActiveList: Array<BaseInProduct>;
    baseInProductSystemZoneSortingAsc: boolean;
    baseInProductSystemZoneSortingCriterion: propertyConstants.Property | null;
    baseInProductZoneSystemList: Array<BaseInProduct>;
    baseInProductZoneSystemActiveList: Array<BaseInProduct>;
    baseInProductZoneSystemSortingAsc: boolean;
    baseInProductZoneSystemSortingCriterion: propertyConstants.Property;
    baseInProductZoneLoading: boolean;
    // base in product package
    baseInProductPackageMasterSystemList: Array<BaseInProductPackage>;
    baseInProductPackageMasterSystemActiveList: Array<BaseInProductPackage>;
    baseInProductPackageMasterSystemSortingAsc: boolean;
    baseInProductPackageMasterSystemSortingCriterion: propertyConstants.Property | null;
    baseInProductPackageSystemMasterList: Array<BaseInProductPackage>;
    baseInProductPackageSystemMasterActiveList: Array<BaseInProductPackage>;
    baseInProductPackageSystemMasterSortingAsc: boolean;
    baseInProductPackageSystemMasterSortingCriterion: propertyConstants.Property;
    baseInProductPackageSystemZoneList: Array<BaseInProductPackage>;
    baseInProductPackageSystemZoneActiveList: Array<BaseInProductPackage>;
    baseInProductPackageSystemZoneSortingAsc: boolean;
    baseInProductPackageSystemZoneSortingCriterion: propertyConstants.Property | null;
    baseInProductPackageSystemLoading: boolean;
    baseInProductPackageZoneSystemList: Array<BaseInProductPackage>;
    baseInProductPackageZoneSystemActiveList: Array<BaseInProductPackage>;
    baseInProductPackageZoneSystemSortingAsc: boolean;
    baseInProductPackageZoneSystemSortingCriterion: propertyConstants.Property;
    baseInProductPackageZoneLoading: boolean;
    // colorant
    colorantMasterSystemList: Array<Colorant>;
    colorantMasterSystemActiveList: Array<Colorant>;
    colorantMasterSystemSortingAsc: boolean;
    colorantMasterSystemSortingCriterion: propertyConstants.Property | null;
    colorantSystemMasterList: Array<Colorant>;
    colorantSystemMasterActiveList: Array<Colorant>;
    colorantSystemMasterSortingAsc: boolean;
    colorantSystemMasterSortingCriterion: propertyConstants.Property;
    colorantSystemZoneList: Array<Colorant>;
    colorantSystemZoneActiveList: Array<Colorant>;
    colorantSystemZoneSortingAsc: boolean;
    colorantSystemZoneSortingCriterion: propertyConstants.Property | null;
    colorantSystemLoading: boolean;
    colorantZoneSystemList: Array<Colorant>;
    colorantZoneSystemActiveList: Array<Colorant>;
    colorantZoneSystemSortingAsc: boolean;
    colorantZoneSystemSortingCriterion: propertyConstants.Property;
    colorantZoneLoading: boolean;
    // colorant package
    colorantPackageMasterSystemList: Array<ColorantPackage>;
    colorantPackageMasterSystemActiveList: Array<ColorantPackage>;
    colorantPackageMasterSystemSortingAsc: boolean;
    colorantPackageMasterSystemSortingCriterion: propertyConstants.Property | null;
    colorantPackageSystemMasterList: Array<ColorantPackage>;
    colorantPackageSystemMasterActiveList: Array<ColorantPackage>;
    colorantPackageSystemMasterSortingAsc: boolean;
    colorantPackageSystemMasterSortingCriterion: propertyConstants.Property;
    colorantPackageSystemLoading: boolean;
    colorantPackageSystemZoneList: Array<ColorantPackage>;
    colorantPackageSystemZoneActiveList: Array<ColorantPackage>;
    colorantPackageSystemZoneSortingAsc: boolean;
    colorantPackageSystemZoneSortingCriterion: propertyConstants.Property | null;
    colorantPackageZoneLoading: boolean;
    colorantPackageZoneSystemList: Array<ColorantPackage>;
    colorantPackageZoneSystemActiveList: Array<ColorantPackage>;
    colorantPackageZoneSystemSortingAsc: boolean;
    colorantPackageZoneSystemSortingCriterion: propertyConstants.Property;
    // fandeck
    fandeckMasterSystemList: Array<Fandeck>;
    fandeckMasterSystemActiveList: Array<Fandeck>;
    fandeckMasterSystemSortingAsc: boolean;
    fandeckMasterSystemSortingCriterion: propertyConstants.Property | null;
    fandeckSystemMasterList: Array<Fandeck>;
    fandeckSystemMasterActiveList: Array<Fandeck>;
    fandeckSystemMasterSortingAsc: boolean;
    fandeckSystemMasterSortingCriterion: propertyConstants.Property;
    fandeckSystemZoneList: Array<Fandeck>;
    fandeckSystemZoneActiveList: Array<Fandeck>;
    fandeckSystemZoneSortingAsc: boolean;
    fandeckSystemZoneSortingCriterion: propertyConstants.Property | null;
    fandeckSystemLoading: boolean;
    fandeckZoneSystemList: Array<Fandeck>;
    fandeckZoneSystemActiveList: Array<Fandeck>;
    fandeckZoneSystemSortingAsc: boolean;
    fandeckZoneSystemSortingCriterion: propertyConstants.Property;
    fandeckZoneLoading: boolean;
    // product
    productMasterSystemList: Array<Product>;
    productMasterSystemActiveList: Array<Product>;
    productMasterSystemSortingAsc: boolean;
    productMasterSystemSortingCriterion: propertyConstants.Property | null;
    productSystemActiveList: Array<Product>;
    productSystemMasterList: Array<Product>;
    productSystemMasterActiveList: Array<Product>;
    productSystemMasterSortingAsc: boolean;
    productSystemMasterSortingCriterion: propertyConstants.Property;
    productSystemLoading: boolean;
    productSystemZoneList: Array<Product>;
    productSystemZoneActiveList: Array<Product>;
    productSystemZoneSortingAsc: boolean;
    productSystemZoneSortingCriterion: propertyConstants.Property | null;
    productZoneActiveList: Array<Product>;
    productZoneSystemList: Array<Product>;
    productZoneSystemActiveList: Array<Product>;
    productZoneSystemSortingAsc: boolean;
    productZoneSystemSortingCriterion: propertyConstants.Property;
    productZoneLoading: boolean;
    // product group
    productGroupMasterSystemList: Array<ProductGroup>;
    productGroupMasterSystemActiveList: Array<ProductGroup>;
    productGroupMasterSystemSortingAsc: boolean;
    productGroupMasterSystemSortingCriterion: propertyConstants.Property | null;
    productGroupSystemMasterList: Array<ProductGroup>;
    productGroupSystemMasterActiveList: Array<ProductGroup>;
    productGroupSystemMasterSortingAsc: boolean;
    productGroupSystemMasterSortingCriterion: propertyConstants.Property;
    productGroupSystemLoading: boolean;
    productGroupSystemZoneList: Array<ProductGroup>;
    productGroupSystemZoneActiveList: Array<ProductGroup>;
    productGroupSystemZoneSortingAsc: boolean;
    productGroupSystemZoneSortingCriterion: propertyConstants.Property | null;
    productGroupZoneSystemList: Array<ProductGroup>;
    productGroupZoneSystemActiveList: Array<ProductGroup>;
    productGroupZoneSystemSortingAsc: boolean;
    productGroupZoneSystemSortingCriterion: propertyConstants.Property;
    productGroupZoneLoading: boolean;
    // user group
    redlikeUserGroupAvailableList: Array<UserGroup>;
    redlikeUserGroupAvailableActiveList: Array<UserGroup>;
    redlikeUserGroupAvailableLoading: boolean;
    redlikeUserGroupSelectedList: Array<UserGroup>;
    redlikeUserGroupSelectedActiveList: Array<UserGroup>;
    redlikeUserGroupSelectedLoading: boolean;
    activeScene: Scene;
    loading: boolean;
    sessionUuid: string | null;
    urlRest: string;
}>;

export type DispatchType = Readonly<{
    addItem(objectType: objectTypes.ObjectType, params?: Record<string, any>): any;
    editItem(objectType: objectTypes.ObjectType, params: any): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    // base
    baseMasterSystemList: state.base.masterSystemList,
    baseMasterSystemActiveList: state.base.masterSystemActiveList,
    baseMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_BASES
        )
    ),
    baseMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_BASES
        )
    ),
    baseSystemMasterList: state.base.systemMasterList,
    baseSystemMasterActiveList: state.base.systemMasterActiveList,
    baseSystemMasterSortingAsc: state.base.systemMasterSortingAsc,
    baseSystemMasterSortingCriterion: state.base.systemMasterSortingCriterion,
    baseSystemZoneList: state.base.systemZoneList,
    baseSystemZoneActiveList: state.base.systemZoneActiveList,
    baseSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_BASES
        )
    ),
    baseSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_BASES
        )
    ),
    baseSystemLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM
    ),
    baseZoneSystemList: state.base.zoneSystemList,
    baseZoneSystemActiveList: state.base.zoneSystemActiveList,
    baseZoneSystemSortingAsc: state.base.zoneSystemSortingAsc,
    baseZoneSystemSortingCriterion: state.base.zoneSystemSortingCriterion,
    baseZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE
    ),
    // base in product
    baseInProductMasterSystemList: state.baseInProduct.masterSystemList,
    baseInProductMasterSystemActiveList: state.baseInProduct.masterSystemActiveList,
    baseInProductMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASES
        )
    ),
    baseInProductMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASES
        )
    ),
    baseInProductSystemMasterList: state.baseInProduct.systemMasterList,
    baseInProductSystemMasterActiveList: state.baseInProduct.systemMasterActiveList,
    baseInProductSystemMasterSortingAsc: state.baseInProduct.systemMasterSortingAsc,
    baseInProductSystemMasterSortingCriterion: state.baseInProduct.systemMasterSortingCriterion,
    baseInProductSystemLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM
    ),
    baseInProductSystemZoneList: state.baseInProduct.systemZoneList,
    baseInProductSystemZoneActiveList: state.baseInProduct.systemZoneActiveList,
    baseInProductSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASES
        )
    ),
    baseInProductSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASES
        )
    ),
    baseInProductZoneSystemList: state.baseInProduct.zoneSystemList,
    baseInProductZoneSystemActiveList: state.baseInProduct.zoneSystemActiveList,
    baseInProductZoneSystemSortingAsc: state.baseInProduct.zoneSystemSortingAsc,
    baseInProductZoneLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE
    ),
    baseInProductZoneSystemSortingCriterion: state.baseInProduct.zoneSystemSortingCriterion,
    // base in product package
    baseInProductPackageMasterSystemList: state.baseInProductPackage.masterSystemList,
    baseInProductPackageMasterSystemActiveList: state.baseInProductPackage.masterSystemActiveList,
    baseInProductPackageMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageSystemMasterList: state.baseInProductPackage.systemMasterList,
    baseInProductPackageSystemMasterActiveList: state.baseInProductPackage.systemMasterActiveList,
    baseInProductPackageSystemMasterSortingAsc: state.baseInProductPackage.systemMasterSortingAsc,
    baseInProductPackageSystemMasterSortingCriterion: state.baseInProductPackage.systemMasterSortingCriterion,
    baseInProductPackageSystemLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM
    ),
    baseInProductPackageSystemZoneList: state.baseInProductPackage.systemZoneList,
    baseInProductPackageSystemZoneActiveList: state.baseInProductPackage.systemZoneActiveList,
    baseInProductPackageSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES
        )
    ),
    baseInProductPackageZoneSystemList: state.baseInProductPackage.zoneSystemList,
    baseInProductPackageZoneSystemActiveList: state.baseInProductPackage.zoneSystemActiveList,
    baseInProductPackageZoneSystemSortingAsc: state.baseInProductPackage.zoneSystemSortingAsc,
    baseInProductPackageZoneSystemSortingCriterion: state.baseInProductPackage.zoneSystemSortingCriterion,
    baseInProductPackageZoneLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE
    ),
    // colorant
    colorantMasterSystemList: state.colorant.masterSystemList,
    colorantMasterSystemActiveList: state.colorant.masterSystemActiveList,
    colorantMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_COLORANTS
        )
    ),
    colorantMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_COLORANTS
        )
    ),
    colorantSystemMasterList: state.colorant.systemMasterList,
    colorantSystemMasterActiveList: state.colorant.systemMasterActiveList,
    colorantSystemMasterSortingAsc: state.colorant.systemMasterSortingAsc,
    colorantSystemMasterSortingCriterion: state.colorant.systemMasterSortingCriterion,
    colorantSystemZoneList: state.colorant.systemZoneList,
    colorantSystemZoneActiveList: state.colorant.systemZoneActiveList,
    colorantSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_COLORANTS
        )
    ),
    colorantSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_COLORANTS
        )
    ),
    colorantSystemLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM
    ),
    colorantZoneSystemList: state.colorant.zoneSystemList,
    colorantZoneSystemActiveList: state.colorant.zoneSystemActiveList,
    colorantZoneSystemSortingAsc: state.colorant.zoneSystemSortingAsc,
    colorantZoneSystemSortingCriterion: state.colorant.zoneSystemSortingCriterion,
    colorantZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE
    ),
    // colorant package
    colorantPackageMasterSystemList: state.colorantPackage.masterSystemList,
    colorantPackageMasterSystemActiveList: state.colorantPackage.masterSystemActiveList,
    colorantPackageMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_COLORANT_PACKAGES
        )
    ),
    colorantPackageMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_COLORANT_PACKAGES
        )
    ),
    colorantPackageSystemMasterList: state.colorantPackage.systemMasterList,
    colorantPackageSystemMasterActiveList: state.colorantPackage.systemMasterActiveList,
    colorantPackageSystemMasterSortingAsc: state.colorantPackage.systemMasterSortingAsc,
    colorantPackageSystemMasterSortingCriterion: state.colorantPackage.systemMasterSortingCriterion,
    colorantPackageSystemZoneList: state.colorantPackage.systemZoneList,
    colorantPackageSystemZoneActiveList: state.colorantPackage.systemZoneActiveList,
    colorantPackageSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_COLORANT_PACKAGES
        )
    ),
    colorantPackageSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_COLORANT_PACKAGES
        )
    ),
    colorantPackageSystemLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM
    ),
    colorantPackageZoneSystemList: state.colorantPackage.zoneSystemList,
    colorantPackageZoneSystemActiveList: state.colorantPackage.zoneSystemActiveList,
    colorantPackageZoneSystemSortingAsc: state.colorantPackage.zoneSystemSortingAsc,
    colorantPackageZoneSystemSortingCriterion: state.colorantPackage.zoneSystemSortingCriterion,
    colorantPackageZoneLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE
    ),
    // fandeck
    fandeckMasterSystemList: state.fandeck.masterSystemList,
    fandeckMasterSystemActiveList: state.fandeck.masterSystemActiveList,
    fandeckMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_FANDECKS
        )
    ),
    fandeckMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_FANDECKS
        )
    ),
    fandeckSystemMasterList: state.fandeck.systemMasterList,
    fandeckSystemMasterActiveList: state.fandeck.systemMasterActiveList,
    fandeckSystemMasterSortingAsc: state.fandeck.systemMasterSortingAsc,
    fandeckSystemMasterSortingCriterion: state.fandeck.systemMasterSortingCriterion,
    fandeckSystemZoneList: state.fandeck.systemZoneList,
    fandeckSystemZoneActiveList: state.fandeck.systemZoneActiveList,
    fandeckSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_FANDECKS
        )
    ),
    fandeckSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_FANDECKS
        )
    ),
    fandeckSystemLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM
    ),
    fandeckZoneSystemList: state.fandeck.zoneSystemList,
    fandeckZoneSystemActiveList: state.fandeck.zoneSystemActiveList,
    fandeckZoneSystemSortingAsc: state.fandeck.zoneSystemSortingAsc,
    fandeckZoneSystemSortingCriterion: state.fandeck.zoneSystemSortingCriterion,
    fandeckZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE
    ),
    // product
    productMasterSystemList: state.product.masterSystemList,
    productMasterSystemActiveList: state.product.masterSystemActiveList,
    productMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS
        )
    ),
    productMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS
        )
    ),
    productSystemActiveList: state.product.systemActiveList,
    productSystemMasterList: state.product.systemMasterList,
    productSystemMasterActiveList: state.product.systemMasterActiveList,
    productSystemMasterSortingAsc: state.product.systemMasterSortingAsc,
    productSystemMasterSortingCriterion: state.product.systemMasterSortingCriterion,
    productSystemZoneList: state.product.systemZoneList,
    productSystemZoneActiveList: state.product.systemZoneActiveList,
    productSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS
        )
    ),
    productSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS
        )
    ),
    productSystemLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM
    ),
    productZoneActiveList: state.product.zoneActiveList,
    productZoneSystemList: state.product.zoneSystemList,
    productZoneSystemActiveList: state.product.zoneSystemActiveList,
    productZoneSystemSortingAsc: state.product.zoneSystemSortingAsc,
    productZoneSystemSortingCriterion: state.product.zoneSystemSortingCriterion,
    productZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE
    ),
    // product group
    productGroupMasterSystemList: state.productGroup.masterSystemList,
    productGroupMasterSystemActiveList: state.productGroup.masterSystemActiveList,
    productGroupMasterSystemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCT_GROUPS
        )
    ),
    productGroupMasterSystemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_SYSTEM_PRODUCT_GROUPS
        )
    ),
    productGroupSystemMasterList: state.productGroup.systemMasterList,
    productGroupSystemLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT
    ),
    productGroupSystemMasterActiveList: state.productGroup.systemMasterActiveList,
    productGroupSystemMasterSortingAsc: state.productGroup.systemMasterSortingAsc,
    productGroupSystemMasterSortingCriterion: state.productGroup.systemMasterSortingCriterion,
    productGroupSystemZoneList: state.productGroup.systemZoneList,
    productGroupSystemZoneActiveList: state.productGroup.systemZoneActiveList,
    productGroupSystemZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS
        )
    ),
    productGroupSystemZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS
        )
    ),
    productGroupZoneSystemList: state.productGroup.zoneSystemList,
    productGroupZoneSystemActiveList: state.productGroup.zoneSystemActiveList,
    productGroupZoneSystemSortingAsc: state.productGroup.zoneSystemSortingAsc,
    productGroupZoneSystemSortingCriterion: state.productGroup.zoneSystemSortingCriterion,
    productGroupZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE
    ),
    // user group
    redlikeUserGroupAvailableList: state.userGroup.availableList,
    redlikeUserGroupAvailableActiveList: state.userGroup.availableActiveList,
    redlikeUserGroupAvailableLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER
    ),
    redlikeUserGroupSelectedList: state.userGroup.selectedList,
    redlikeUserGroupSelectedActiveList: state.userGroup.selectedActiveList,
    redlikeUserGroupSelectedLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER
    ),
    activeScene: state.navigation.activeScene,
    loading: state.server.requests.some((request: ServerRequest) =>
        methods.LOADING_ADD_EDIT_PROPAGATE_MODAL.includes(request.method)
    ),
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    addItem: (objectType: objectTypes.ObjectType, params?: Record<string, any>): any => {
        dispatch(universalObjectActions.addItem(objectType, params ?? {}));
    },
    editItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.editItem(objectType, true, params))
});

export const PropagateObjectModalContainer = connect(mapStateToProps, mapDispatchToProps)(PropagateObjectModal);
