import * as serverConstants from "../constants/serverConstants";

export class FormulasStatus {
    status: serverConstants.FormulaFlag;
    formulasCount: number;

    constructor(status: serverConstants.FormulaFlag, formulasCount: number) {
        this.status = status;
        this.formulasCount = formulasCount;
    }
}

export function mapFormulaStatus(data: Record<string, any>): FormulasStatus | null {
    try {
        return new FormulasStatus(data.status, data.num);
    } catch (e) {
        return null;
    }
}
