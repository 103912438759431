import { AppState } from "../reducers";
import { Debug } from "../components/Debug";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    serverVersion: string | null;
    sqlcipherVersion: string | null;
    sqliteVersion: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    serverVersion: state.server.serverVersion,
    sqlcipherVersion: state.server.sqlcipherVersion,
    sqliteVersion: state.server.sqliteVersion
});

export const DebugContainer = connect(mapStateToProps)(Debug);
