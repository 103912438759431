// scenes
export const COMPANY_SCENE = "COMPANY_SCENE";
export const DEBUG_SCENE = "DEBUG_SCENE";
export const GLOBAL_OPTIONS_SCENE = "GLOBAL_OPTIONS_SCENE";
export const HOMEPAGE = "HOMEPAGE";
export const USER_ACTION_HISTORY_SCENE = "USER_ACTION_HISTORY_SCENE";
export const USER_OPTIONS_SCENE = "USER_OPTIONS_SCENE";
export const USERS_SCENE = "USERS_SCENE";
export const SERVER_MONITORING_SCENE = "SERVER_MONITORING_SCENE";

// global
export const DATA_EXPORT_SCENE = "DATA_EXPORT_SCENE";
export const DATA_IMPORT_SCENE = "DATA_IMPORT_SCENE";
export const DATA_MASTER_SETTINGS_SCENE = "DATA_MASTER_SETTINGS_SCENE";
export const DATA_SERVER_SCRIPTS_SCENE = "DATA_SERVER_SCRIPTS_SCENE";
export const DATA_SYSTEM_ZONE_SETTINGS_SCENE = "DATA_SYSTEM_ZONE_SETTINGS_SCENE";

export const GLOBAL_SCENES_LIST = [
    DATA_EXPORT_SCENE,
    DATA_IMPORT_SCENE,
    DATA_MASTER_SETTINGS_SCENE,
    DATA_SERVER_SCRIPTS_SCENE,
    DATA_SYSTEM_ZONE_SETTINGS_SCENE
];

// master scenes
export const MASTER_BASES_SCENE = "MASTER_BASES_SCENE";
export const MASTER_COLORANTS_SCENE = "MASTER_COLORANTS_SCENE";
export const MASTER_COLORS_SCENE = "MASTER_COLORS_SCENE";
export const MASTER_FANDECKS_SCENE = "MASTER_FANDECKS_SCENE";
export const MASTER_FORMULAS_SCENE = "MASTER_FORMULAS_SCENE";
export const MASTER_FORMULA_NOTES_SCENE = "MASTER_FORMULA_NOTES_SCENE";
export const MASTER_PRODUCT_GROUPS_SCENE = "MASTER_PRODUCT_GROUPS_SCENE";
export const MASTER_PRODUCTS_SCENE = "MASTER_PRODUCTS_SCENE";
export const MASTER_REDLIKE_OPTIONS_SCENE = "MASTER_REDLIKE_OPTIONS_SCENE";
export const MASTER_REDLIKE_PRIVILEGES_SCENE = "MASTER_REDLIKE_PRIVILEGES_SCENE";
export const MASTER_REDLIKE_USERS_SCENE = "MASTER_REDLIKE_USERS_SCENE";

export const MASTER_SCENES_LIST = [
    MASTER_BASES_SCENE,
    MASTER_COLORANTS_SCENE,
    MASTER_COLORS_SCENE,
    MASTER_FANDECKS_SCENE,
    MASTER_FORMULAS_SCENE,
    MASTER_FORMULA_NOTES_SCENE,
    MASTER_PRODUCT_GROUPS_SCENE,
    MASTER_PRODUCTS_SCENE,
    MASTER_REDLIKE_OPTIONS_SCENE,
    MASTER_REDLIKE_USERS_SCENE,
    MASTER_REDLIKE_PRIVILEGES_SCENE
];

// system scenes
export const SYSTEM_BASES_SCENE = "SYSTEM_BASES_SCENE";
export const SYSTEM_COLORANTS_SCENE = "SYSTEM_COLORANTS_SCENE";
export const SYSTEM_COLORS_SCENE = "SYSTEM_COLORS_SCENE";
export const SYSTEM_FANDECKS_SCENE = "SYSTEM_FANDECKS_SCENE";
export const SYSTEM_FORMULAS_SCENE = "SYSTEM_FORMULAS_SCENE";
export const SYSTEM_FORMULA_NOTES_SCENE = "SYSTEM_FORMULA_NOTES_SCENE";
export const SYSTEM_PRICES_SCENE = "SYSTEM_PRICES_SCENE";
export const SYSTEM_PRODUCT_GROUPS_SCENE = "SYSTEM_PRODUCT_GROUPS_SCENE";
export const SYSTEM_PRODUCTS_SCENE = "SYSTEM_PRODUCTS_SCENE";
export const SYSTEM_REDLIKE_OPTIONS_SCENE = "SYSTEM_REDLIKE_OPTIONS_SCENE";
export const SYSTEM_REDLIKE_USERS_SCENE = "SYSTEM_REDLIKE_USERS_SCENE";

export const SYSTEM_SCENES_LIST = [
    SYSTEM_BASES_SCENE,
    SYSTEM_COLORANTS_SCENE,
    SYSTEM_COLORS_SCENE,
    SYSTEM_FANDECKS_SCENE,
    SYSTEM_FORMULAS_SCENE,
    SYSTEM_FORMULA_NOTES_SCENE,
    SYSTEM_PRICES_SCENE,
    SYSTEM_PRODUCT_GROUPS_SCENE,
    SYSTEM_PRODUCTS_SCENE,
    SYSTEM_REDLIKE_OPTIONS_SCENE,
    SYSTEM_REDLIKE_USERS_SCENE
];

// zone scenes
export const ZONE_BASES_SCENE = "ZONE_BASES_SCENE";
export const ZONE_COLORANTS_SCENE = "ZONE_COLORANTS_SCENE";
export const ZONE_COLORS_SCENE = "ZONE_COLORS_SCENE";
export const ZONE_FANDECKS_SCENE = "ZONE_FANDECKS_SCENE";
export const ZONE_FORMULAS_SCENE = "ZONE_FORMULAS_SCENE";
export const ZONE_FORMULA_NOTES_SCENE = "ZONE_FORMULA_NOTES_SCENE";
export const ZONE_PRICES_SCENE = "ZONE_PRICES_SCENE";
export const ZONE_PRODUCT_GROUPS_SCENE = "ZONE_PRODUCT_GROUPS_SCENE";
export const ZONE_PRODUCTS_SCENE = "ZONE_PRODUCTS_SCENE";
export const ZONE_REDLIKE_OPTIONS_SCENE = "ZONE_REDLIKE_OPTIONS_SCENE";
export const ZONE_REDLIKE_USERS_SCENE = "ZONE_REDLIKE_USERS_SCENE";

export const ZONE_SCENES_LIST = [
    ZONE_BASES_SCENE,
    ZONE_COLORANTS_SCENE,
    ZONE_COLORS_SCENE,
    ZONE_FORMULA_NOTES_SCENE,
    ZONE_FANDECKS_SCENE,
    ZONE_FORMULAS_SCENE,
    ZONE_PRICES_SCENE,
    ZONE_PRODUCT_GROUPS_SCENE,
    ZONE_PRODUCTS_SCENE,
    ZONE_REDLIKE_OPTIONS_SCENE,
    ZONE_REDLIKE_USERS_SCENE
];

// generic scenes
export const GENERIC_BARCODES_SCENE = "GENERIC_BARCODES_SCENE";
export const GENERIC_CURRENCIES_SCENE = "GENERIC_CURRENCIES_SCENE";
export const GENERIC_IMAGES_SCENE = "GENERIC_IMAGES_SCENE";
export const GENERIC_PACKAGES_SCENE = "GENERIC_PACKAGES_SCENE";
export const GENERIC_PRODUCT_SHEETS_SCENE = "GENERIC_PRODUCT_SHEETS_SCENE";
export const GENERIC_STATIC_SQL_SCENE = "GENERIC_STATIC_SQL_SCENE";
export const GENERIC_UNITS_SCENE = "GENERIC_UNITS_SCENE";

export const GENERIC_SCENES_LIST = [
    GENERIC_BARCODES_SCENE,
    GENERIC_CURRENCIES_SCENE,
    GENERIC_IMAGES_SCENE,
    GENERIC_PACKAGES_SCENE,
    GENERIC_PRODUCT_SHEETS_SCENE,
    GENERIC_STATIC_SQL_SCENE,
    GENERIC_UNITS_SCENE
];

export const CURRENCY_BUTTONS_SCENES_LIST = [SYSTEM_COLORANTS_SCENE, SYSTEM_PRICES_SCENE, SYSTEM_PRODUCTS_SCENE];
export const NO_LAYOUT_BUTTONS_SCENE_LIST = [
    DATA_SYSTEM_ZONE_SETTINGS_SCENE,
    MASTER_REDLIKE_PRIVILEGES_SCENE,
    GENERIC_IMAGES_SCENE,
    GENERIC_PRODUCT_SHEETS_SCENE,
    GENERIC_STATIC_SQL_SCENE
];

export type Scene =
    | typeof COMPANY_SCENE
    | typeof DATA_EXPORT_SCENE
    | typeof DATA_IMPORT_SCENE
    | typeof DATA_MASTER_SETTINGS_SCENE
    | typeof DATA_SERVER_SCRIPTS_SCENE
    | typeof DATA_SYSTEM_ZONE_SETTINGS_SCENE
    | typeof DEBUG_SCENE
    | typeof GENERIC_BARCODES_SCENE
    | typeof GENERIC_CURRENCIES_SCENE
    | typeof GENERIC_IMAGES_SCENE
    | typeof GENERIC_PACKAGES_SCENE
    | typeof GENERIC_PRODUCT_SHEETS_SCENE
    | typeof GENERIC_STATIC_SQL_SCENE
    | typeof GENERIC_UNITS_SCENE
    | typeof GLOBAL_OPTIONS_SCENE
    | typeof HOMEPAGE
    | typeof MASTER_BASES_SCENE
    | typeof MASTER_COLORANTS_SCENE
    | typeof MASTER_COLORS_SCENE
    | typeof MASTER_FANDECKS_SCENE
    | typeof MASTER_FORMULAS_SCENE
    | typeof MASTER_FORMULA_NOTES_SCENE
    | typeof MASTER_PRODUCT_GROUPS_SCENE
    | typeof MASTER_PRODUCTS_SCENE
    | typeof MASTER_REDLIKE_OPTIONS_SCENE
    | typeof MASTER_REDLIKE_PRIVILEGES_SCENE
    | typeof MASTER_REDLIKE_USERS_SCENE
    | typeof SERVER_MONITORING_SCENE
    | typeof SYSTEM_BASES_SCENE
    | typeof SYSTEM_COLORANTS_SCENE
    | typeof SYSTEM_COLORS_SCENE
    | typeof SYSTEM_FANDECKS_SCENE
    | typeof SYSTEM_FORMULAS_SCENE
    | typeof SYSTEM_FORMULA_NOTES_SCENE
    | typeof SYSTEM_PRICES_SCENE
    | typeof SYSTEM_PRODUCT_GROUPS_SCENE
    | typeof SYSTEM_PRODUCTS_SCENE
    | typeof SYSTEM_REDLIKE_OPTIONS_SCENE
    | typeof SYSTEM_REDLIKE_USERS_SCENE
    | typeof USER_ACTION_HISTORY_SCENE
    | typeof USER_OPTIONS_SCENE
    | typeof USERS_SCENE
    | typeof ZONE_BASES_SCENE
    | typeof ZONE_COLORANTS_SCENE
    | typeof ZONE_COLORS_SCENE
    | typeof ZONE_FANDECKS_SCENE
    | typeof ZONE_FORMULAS_SCENE
    | typeof ZONE_FORMULA_NOTES_SCENE
    | typeof ZONE_PRICES_SCENE
    | typeof ZONE_PRODUCT_GROUPS_SCENE
    | typeof ZONE_PRODUCTS_SCENE
    | typeof ZONE_REDLIKE_OPTIONS_SCENE
    | typeof ZONE_REDLIKE_USERS_SCENE;

// wizards
export const ADD_SYSTEM_BASE_WIZARD = "ADD_SYSTEM_BASE_WIZARD";
export const ADD_SYSTEM_COLORANT_WIZARD = "ADD_SYSTEM_COLORANT_WIZARD";
export const ADD_SYSTEM_COLORANT_PACKAGES_WIZARD = "ADD_SYSTEM_COLORANT_PACKAGES_WIZARD";
export const ADD_SYSTEM_FANDECK_WIZARD = "ADD_SYSTEM_FANDECK_WIZARD";
export const ADD_SYSTEM_PRODUCT_WIZARD = "ADD_SYSTEM_PRODUCT_WIZARD";
export const ADD_SYSTEM_PRODUCT_BASE_WIZARD = "ADD_SYSTEM_PRODUCT_BASE_WIZARD";
export const ADD_SYSTEM_PRODUCT_BASE_PACKAGE_WIZARD = "ADD_SYSTEM_PRODUCT_BASE_PACKAGE_WIZARD";

export type Wizard =
    | typeof ADD_SYSTEM_BASE_WIZARD
    | typeof ADD_SYSTEM_COLORANT_WIZARD
    | typeof ADD_SYSTEM_COLORANT_PACKAGES_WIZARD
    | typeof ADD_SYSTEM_FANDECK_WIZARD
    | typeof ADD_SYSTEM_PRODUCT_WIZARD
    | typeof ADD_SYSTEM_PRODUCT_BASE_WIZARD
    | typeof ADD_SYSTEM_PRODUCT_BASE_PACKAGE_WIZARD;
