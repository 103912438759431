import * as generalHelper from "../../../helpers/generalHelper";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";
import { PriceOption } from "../../../types/priceOption";
import { ReactSVG } from "react-svg";

import imgCancel from "../../../resources/img/icons/cancel.svg";
import imgSuccess from "../../../resources/img/icons/success.svg";

import { t as translate } from "react-i18nify";

type Props = {
    dataPrivileges: any;
    priceOptionList: Array<PriceOption>;
};

export class PriceInfoPanel extends Component<Props> {
    isVisible = (): boolean => {
        if (this.props.dataPrivileges?.canView) {
            return true;
        }

        return false;
    };

    getPriceDefinition = (): JSX.Element => {
        const optionDefinition = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_PRICE_DEFINITION
        );

        return (
            <div className="row">
                <label className="setting-title">{translate("prices.optionDefinition")}:</label>
                <span>
                    {translationHelper.getPriceOptionTranslation(
                        priceConstants.PRICE_OPTION_PRICE_DEFINITION,
                        optionDefinition?.[propertyConstants.PROPERTY_VALUE] || undefined
                    )}
                </span>
            </div>
        );
    };

    getPriceCalculation = (): JSX.Element => {
        const optionDiscount = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_DISCOUNT
        );
        const optionMargin = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARGIN
        );
        const optionMarkup = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_USE_MARKUP
        );

        return (
            <div className="row">
                <label className="setting-title">{translate("prices.optionPriceCalculation")}:</label>
                <span>
                    {translationHelper.getPriceOptionTranslation(priceConstants.PRICE_OPTION_PRICE_CALCULATION, {
                        [priceConstants.PRICE_OPTION_USE_DISCOUNT]:
                            optionDiscount?.[propertyConstants.PROPERTY_VALUE] || false,
                        [priceConstants.PRICE_OPTION_USE_MARGIN]:
                            optionMargin?.[propertyConstants.PROPERTY_VALUE] || false,
                        [priceConstants.PRICE_OPTION_USE_MARKUP]:
                            optionMarkup?.[propertyConstants.PROPERTY_VALUE] || false
                    })}
                </span>
            </div>
        );
    };

    getPriceCompanyCost = (): JSX.Element => {
        const optionCompanyCostEnable = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST
        );

        return (
            <div className="row">
                <label className="setting-title">{translate("prices.optionCompanyCost")}:</label>
                <span>
                    <ReactSVG
                        src={optionCompanyCostEnable?.[propertyConstants.PROPERTY_VALUE] ? imgSuccess : imgCancel}
                        className={`icon-label ${
                            optionCompanyCostEnable?.[propertyConstants.PROPERTY_VALUE] ? " success" : ""
                        }`}
                    />
                    <span>
                        {translationHelper.getPriceOptionTranslation(
                            priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST,
                            optionCompanyCostEnable?.[propertyConstants.PROPERTY_VALUE] || false
                        )}
                    </span>
                </span>
            </div>
        );
    };

    getPriceGroups = (): JSX.Element => {
        const optionPriceGroupsEnable = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS
        );
        const optionPriceGroupsCount = generalHelper.getObjectByKey(
            this.props.priceOptionList,
            priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS
        );

        return (
            <div className="row">
                <label className="setting-title">{translate("prices.optionPriceGroups")}:</label>
                <span>
                    <ReactSVG
                        src={optionPriceGroupsEnable?.[propertyConstants.PROPERTY_VALUE] ? imgSuccess : imgCancel}
                        className={`icon-label ${
                            optionPriceGroupsEnable?.[propertyConstants.PROPERTY_VALUE] ? " success" : ""
                        }`}
                    />
                    {translationHelper.getPriceOptionTranslation(
                        priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS,
                        optionPriceGroupsEnable?.[propertyConstants.PROPERTY_VALUE] || false,
                        optionPriceGroupsCount?.[propertyConstants.PROPERTY_VALUE] || 0
                    )}
                </span>
            </div>
        );
    };

    render(): JSX.Element | null {
        if (!this.isVisible()) {
            return null;
        }

        return (
            <div className="prices-info-panel">
                <div className="panel-column">
                    {this.getPriceDefinition()}
                    {this.getPriceCalculation()}
                </div>
                <div className="panel-column">
                    {this.getPriceCompanyCost()}
                    {this.getPriceGroups()}
                </div>
            </div>
        );
    }
}
