import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export function getItemByBarcode(id: number): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_ITEM_BY_BARCODE,
            params: {
                barcodeId: id
            }
        },
        method: methods.METHOD_GET_ITEM_BY_BARCODE
    };
}
