// TODO rename file to pathConstants and type to Path
export const PATH_ADD_SYSTEM_BASE_WIZARD = "/add-system-base-wizard";
export const PATH_ADD_SYSTEM_COLORANT_WIZARD = "/add-system-colorant-wizard";
export const PATH_ADD_SYSTEM_COLORANT_PACKAGES_WIZARD = "/add-system-colorant-package-wizard";
export const PATH_ADD_SYSTEM_FANDECK_WIZARD = "/add-system-fandeck-wizard";
export const PATH_ADD_SYSTEM_PRODUCT_WIZARD = "/add-system-product-wizard";

export const PATH_COLORS = "/colors";
export const PATH_COMPANIES = "/companies";
export const PATH_DATA_EXPORT = "/data-export";
export const PATH_DATA_IMPORT = "/data-import";
export const PATH_DATA_MASTER_SETTINGS = "/data-master-settings";
export const PATH_DATA_SERVER_SCRIPTS = "/data-server-scripts";
export const PATH_DATA_SYSTEM_ZONE_SETTINGS = "/data-system-zone-settings";
export const PATH_DEBUG = "/debug";
export const PATH_FORMULAS = "/formulas";
export const PATH_GENERIC_BARCODES = "/barcodes";
export const PATH_GENERIC_CURRENCIES = "/currencies";
export const PATH_GENERIC_IMAGES = "/images";
export const PATH_GENERIC_PACKAGES = "/packages";
export const PATH_GENERIC_PRODUCT_SHEETS = "/product-sheets";
export const PATH_GENERIC_STATIC_SQL = "/static-sql";
export const PATH_GENERIC_UNITS = "/units";
export const PATH_GLOBAL_OPTIONS = "/global-options";
export const PATH_HOME_PAGE = "/";
export const PATH_LICENSE = "/license";
export const PATH_LOGIN = "/login";
export const PATH_MASTER_BASES = "/master-bases";
export const PATH_MASTER_COLORANTS = "/master-colorants";
export const PATH_MASTER_COLORS = "/master-colors";
export const PATH_MASTER_FANDECKS = "/master-fandecks";
export const PATH_MASTER_FORMULAS = "/master-formulas";
export const PATH_MASTER_FORMULA_NOTES = "/master-formula-notes";
export const PATH_MASTER_PRODUCTS = "/master-products";
export const PATH_MASTER_REDLIKE_OPTIONS = "/master-software-options";
export const PATH_MASTER_REDLIKE_PRIVILEGES = "/master-software-privileges";
export const PATH_MASTER_REDLIKE_USERS = "/master-software-users";
export const PATH_SERVER_MONITORING = "/server-monitoring";
export const PATH_SYSTEM_BASES = "/system-bases";
export const PATH_SYSTEM_COLORANTS = "/system-colorants";
export const PATH_SYSTEM_COLORS = "/system-colors";
export const PATH_SYSTEM_FANDECKS = "/system-fandecks";
export const PATH_SYSTEM_FORMULAS = "/system-formulas";
export const PATH_SYSTEM_FORMULA_NOTES = "/system-formula-notes";
export const PATH_SYSTEM_PRICES = "/system-prices";
export const PATH_SYSTEM_PRODUCT_GROUPS = "/system-product-groups";
export const PATH_SYSTEM_PRODUCTS = "/system-products";
export const PATH_SYSTEM_REDLIKE_OPTIONS = "/system-software-options";
export const PATH_SYSTEM_REDLIKE_USERS = "/system-software-users";
export const PATH_USER_ACTION_HISTORY = "/user-action-history";
export const PATH_USER_OPTIONS = "/user-options";
export const PATH_USERS = "/users";
export const PATH_WELCOME = "/welcome";
export const PATH_ZONE_BASES = "/zone-bases";
export const PATH_ZONE_COLORANTS = "/zone-colorants";
export const PATH_ZONE_COLORS = "/zone-colors";
export const PATH_ZONE_FANDECKS = "/zone-fandecks";
export const PATH_ZONE_FORMULAS = "/zone-formulas";
export const PATH_ZONE_FORMULA_NOTES = "/zone-formula-notes";
export const PATH_ZONE_PRICES = "/zone-prices";
export const PATH_ZONE_PRODUCT_GROUPS = "/zone-product-groups";
export const PATH_ZONE_PRODUCTS = "/zone-products";
export const PATH_ZONE_REDLIKE_OPTIONS = "/zone-software-options";
export const PATH_ZONE_REDLIKE_USERS = "/zone-software-users";

export type Paths =
    | typeof PATH_ADD_SYSTEM_BASE_WIZARD
    | typeof PATH_ADD_SYSTEM_COLORANT_WIZARD
    | typeof PATH_ADD_SYSTEM_COLORANT_PACKAGES_WIZARD
    | typeof PATH_ADD_SYSTEM_FANDECK_WIZARD
    | typeof PATH_ADD_SYSTEM_PRODUCT_WIZARD
    | typeof PATH_COLORS
    | typeof PATH_COMPANIES
    | typeof PATH_DATA_EXPORT
    | typeof PATH_DATA_IMPORT
    | typeof PATH_DATA_MASTER_SETTINGS
    | typeof PATH_DATA_SERVER_SCRIPTS
    | typeof PATH_DATA_SYSTEM_ZONE_SETTINGS
    | typeof PATH_DEBUG
    | typeof PATH_FORMULAS
    | typeof PATH_GENERIC_BARCODES
    | typeof PATH_GENERIC_CURRENCIES
    | typeof PATH_GENERIC_IMAGES
    | typeof PATH_GENERIC_PACKAGES
    | typeof PATH_GENERIC_PRODUCT_SHEETS
    | typeof PATH_GENERIC_STATIC_SQL
    | typeof PATH_GENERIC_UNITS
    | typeof PATH_GLOBAL_OPTIONS
    | typeof PATH_HOME_PAGE
    | typeof PATH_LICENSE
    | typeof PATH_LOGIN
    | typeof PATH_MASTER_BASES
    | typeof PATH_MASTER_COLORANTS
    | typeof PATH_MASTER_COLORS
    | typeof PATH_MASTER_FANDECKS
    | typeof PATH_MASTER_FORMULAS
    | typeof PATH_MASTER_FORMULA_NOTES
    | typeof PATH_MASTER_PRODUCTS
    | typeof PATH_MASTER_REDLIKE_OPTIONS
    | typeof PATH_MASTER_REDLIKE_PRIVILEGES
    | typeof PATH_MASTER_REDLIKE_USERS
    | typeof PATH_SERVER_MONITORING
    | typeof PATH_SYSTEM_BASES
    | typeof PATH_SYSTEM_COLORANTS
    | typeof PATH_SYSTEM_COLORS
    | typeof PATH_SYSTEM_FANDECKS
    | typeof PATH_SYSTEM_FORMULAS
    | typeof PATH_SYSTEM_FORMULA_NOTES
    | typeof PATH_SYSTEM_PRICES
    | typeof PATH_SYSTEM_PRODUCT_GROUPS
    | typeof PATH_SYSTEM_PRODUCTS
    | typeof PATH_SYSTEM_REDLIKE_OPTIONS
    | typeof PATH_SYSTEM_REDLIKE_USERS
    | typeof PATH_USER_ACTION_HISTORY
    | typeof PATH_USER_OPTIONS
    | typeof PATH_USERS
    | typeof PATH_WELCOME
    | typeof PATH_ZONE_BASES
    | typeof PATH_ZONE_COLORANTS
    | typeof PATH_ZONE_COLORS
    | typeof PATH_ZONE_FANDECKS
    | typeof PATH_ZONE_FORMULAS
    | typeof PATH_ZONE_FORMULA_NOTES
    | typeof PATH_ZONE_PRICES
    | typeof PATH_ZONE_PRODUCT_GROUPS
    | typeof PATH_ZONE_PRODUCTS
    | typeof PATH_ZONE_REDLIKE_OPTIONS
    | typeof PATH_ZONE_REDLIKE_USERS;
