import * as modalTypes from "../../../../constants/modalTypes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";

import { AppState } from "../../../../reducers";
import { BaseInProduct } from "../../../../types/baseInProduct";
import { Colorant } from "../../../../types/colorant";
import { Database } from "../../../../types/database";
import { Dispatch } from "redux";
import { Fandeck } from "../../../../types/fandeck";
import { File } from "../../../../types/file";
import { FileType } from "../../../../types/fileType";
import { ModalType } from "../../../../constants/modalTypes";
import { Product } from "../../../../types/product";
import ReactTooltip from "react-tooltip";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    modalType?: ModalType;
    modalParams: any;
    caption?: string;
    disabled?: boolean;
    availableCount?: number | null;
    addedItemsList?: Array<any>;
};

type Props = OwnProps & ContainerProps;

type State = {
    tooltipOn: boolean;
    tooltipText: string;
};

export class TableAddButton extends Component<Props, State> {
    state: State = {
        tooltipOn: false,
        tooltipText: ""
    };

    isVisible = (): boolean => {
        const { objectType, dataPrivileges } = this.props;

        if (objectTypes.HIDDEN_ADD_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return false;
        }

        if (dataPrivileges?.canInsert) {
            return true;
        }

        return false;
    };

    /**
    Button is enabled if
    * appropriate database/system/zone is active
    * it has correct dataPrivileges (canInsert: true)
    * we know what type of modal should be opened and we have its parameters
    * button is not temporarily disabled mainly because of loading
    * for PropagationModal there have to be some items that can be added, otherwise availableCount is null
    * when there are more tables in one scene, there has to be an active item in the previous table
    * for files in Products there has to be a file type which is not assigned to the given product
    * for colors and formulas there have to be selected fandeck, and also product for formula
     */
    isEnabled = (): boolean => {
        const { dataPrivileges, modalType, modalParams, availableCount } = this.props;

        if (
            objectTypeHelper.isObjectTypeEnabledByHeader(
                this.props.objectType,
                this.props.activeDatabase ?? null,
                this.props.activeSystem ?? null,
                this.props.activeZone ?? null
            ) &&
            dataPrivileges?.canInsert &&
            modalType &&
            modalParams &&
            !this.props.disabled &&
            availableCount !== 0 &&
            !this.isNothingToAdd() &&
            !this.areAllFileTypesSelected() &&
            this.isFandeckSelected() &&
            this.isProductSelected()
        ) {
            return true;
        }

        return false;
    };

    isNothingToAdd = (): boolean => {
        switch (this.props.objectType) {
            case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
                return !this.props.baseInProductSystemList;
            case objectTypes.SYSTEM_BASE_IN_PRODUCT:
                return !this.props.productSystemList;
            case objectTypes.SYSTEM_COLORANT_PACKAGE:
                return !this.props.colorantSystemList;
            case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE:
                return !this.props.baseInProductZoneList;
            case objectTypes.ZONE_BASE_IN_PRODUCT:
                return !this.props.productZoneList;
            case objectTypes.ZONE_COLORANT_PACKAGE:
                return !this.props.colorantZoneList;
            default:
                return false;
        }
    };

    isFandeckSelected = (): boolean => {
        switch (this.props.objectType) {
            case objectTypes.MASTER_COLOR:
            case objectTypes.MASTER_FORMULA: {
                if (this.props.fandeckActiveList.length > 0) {
                    return true;
                }

                return false;
            }
            default: {
                return true;
            }
        }
    };

    isProductSelected = (): boolean => {
        switch (this.props.objectType) {
            case objectTypes.MASTER_FORMULA: {
                if (this.props.productActiveList.length > 0) {
                    return true;
                }

                return false;
            }
            default: {
                return true;
            }
        }
    };

    areAllFileTypesSelected = (): boolean => {
        const { modalType, objectType, addedItemsList } = this.props;

        if (modalType === modalTypes.MODAL_FILES_TABLE && objectType === objectTypes.PRODUCT_FILE) {
            for (const fileType of this.props.allFileTypeList) {
                const file =
                    (addedItemsList || []).find(
                        (file: File) =>
                            file[propertyConstants.PROPERTY_FILE_TYPE_ID] === fileType[propertyConstants.PROPERTY_ID]
                    ) || null;
                if (!file) {
                    return false;
                }
            }

            return true;
        }

        return false;
    };

    handleMouseOver = (): void => {
        this.setState({
            tooltipOn: true,
            tooltipText: this.getTooltipContent()
        });
    };

    handleMouseLeave = (): void => {
        this.setState({
            tooltipOn: false,
            tooltipText: ""
        });
    };

    handleOpenAddModal = (): void => {
        const { modalType, modalParams, openModal } = this.props;

        // TODO remove modalType as it cannot be undefined
        if (this.isEnabled() && modalType) {
            openModal(modalType, modalParams);
        }
    };

    getAddButtonCaption = (): string => {
        const { modalType, caption } = this.props;

        if (caption) {
            return caption;
        }

        switch (modalType) {
            case modalTypes.MODAL_DATA_EXPORT:
                return translate("export.startNewTask");
            case modalTypes.MODAL_DATA_EXPORT_ALL:
                return translate("export.prepareBulkExport");
            case modalTypes.MODAL_IMAGES_ADD_MORE:
                return translate("image.addImageMore");
            case modalTypes.MODAL_IMPORT_TASK_ADD:
                return translate("import.startNewImportTask");
            case modalTypes.MODAL_DATABASES_EMPTY_ADD:
                return translate("database.createNewDatabase");
            case modalTypes.MODAL_DATABASES_ADD:
                return translate("database.importDatabase");
            case modalTypes.MODAL_FORMULAS_ADD:
                return translate("base.addAnotherBase");
            default:
                return translate("general.add");
        }
    };

    getTooltipContent = (): string => {
        const { objectType, availableCount, addedItemsList } = this.props;

        if ((availableCount === 0 && addedItemsList !== undefined && !addedItemsList.length) || this.isNothingToAdd()) {
            return translate("general.nothingToAdd");
        }

        if (availableCount === 0) {
            return translate("general.allItemsAlreadyAdded", {
                objectType: translationHelper.getObjectTypeTranslation(objectType, true).toLowerCase()
            });
        }

        if (!this.isFandeckSelected()) {
            return translate("fandeck.fandeckNotSelected");
        }

        if (!this.isProductSelected()) {
            return translate("product.productNotSelected");
        }

        if (this.areAllFileTypesSelected()) {
            return translate("file.allProductFilesAdded");
        }

        return "";
    };

    getTooltip = (): JSX.Element | null => {
        if (this.state.tooltipOn && this.state.tooltipText) {
            return (
                <ReactTooltip id="button-tooltip" className="tooltip" place="top" effect="solid">
                    <span>{this.state.tooltipText}</span>{" "}
                </ReactTooltip>
            );
        }

        return null;
    };

    getClassName = (): string => {
        let className = "table-tooltip-container";

        if (this.props.modalType === modalTypes.MODAL_IMAGES_ADD_MORE) {
            className += " margin-left-3";
        }

        return className;
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <div className={this.getClassName()}>
                    <button
                        className="btn-md btn-info"
                        onClick={(): void => this.handleOpenAddModal()}
                        onMouseDown={(event) => event.preventDefault()}
                        data-tip
                        data-for="button-tooltip"
                    >
                        {this.getAddButtonCaption()}
                    </button>
                    {this.getTooltip()}
                </div>
            );
        }

        if (this.isVisible()) {
            return (
                <div
                    className="table-tooltip-container"
                    onMouseOver={(): void => this.handleMouseOver()}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <button className="btn-md btn-info disabled" data-tip data-for="button-tooltip">
                        {this.getAddButtonCaption()}
                    </button>
                    {this.getTooltip()}
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
    allFileTypeList: Array<FileType>;
    baseInProductSystemList: Array<BaseInProduct>;
    baseInProductZoneList: Array<BaseInProduct>;
    colorantSystemList: Array<Colorant>;
    colorantZoneList: Array<Colorant>;
    fandeckActiveList: Array<Fandeck>;
    productActiveList: Array<Product>;
    productSystemList: Array<Product>;
    productZoneList: Array<Product>;
}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState): PropsType => {
    return {
        activeDatabase: state.database.active,
        activeSystem: state.system.active,
        activeZone: state.zone.active,
        allFileTypeList: state.file.productSheetFileTypeList,
        baseInProductSystemList: state.baseInProduct.systemList,
        baseInProductZoneList: state.baseInProduct.zoneList,
        colorantSystemList: state.colorant.systemList,
        colorantZoneList: state.colorant.zoneList,
        fandeckActiveList: state.fandeck.masterActiveList,
        productActiveList: state.product.masterActiveList,
        productSystemList: state.product.systemList,
        productZoneList: state.product.zoneList
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TableAddButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableAddButton);
