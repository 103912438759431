import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionActions from "../../../actions/optionActions";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as universalObjectActions from "../../../actions/universalObjectActions";

import React, { Component, RefObject } from "react";

import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { Modal } from "../../../types/modal";
import { Option } from "../../../types/option";
import { TableHeading } from "../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    privileges: Record<string, number>;
    loading: boolean;
    headings: Array<TableHeading>;
    data: Array<any>;
    itemId?: number;
    showFilterRow: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    isOptionMenuOpened: boolean;
    isResizable: boolean;
    tableRef: RefObject<HTMLDivElement>;
    callbackMouseEnter: (property: propertyConstants.Property) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

type State = {
    isResizing: boolean;
    resizeProperty: propertyConstants.Property | null;
    resizeStartPosition: number | null;
};

export class TableHeadingRow extends Component<Props, State> {
    state: State = {
        isResizing: false,
        resizeProperty: null,
        resizeStartPosition: null
    };

    componentDidMount() {
        if (this.props.isResizable) {
            this.setTableWidth();
        }

        document.addEventListener("mousemove", this.handleColumnResizingMouseMove);
        document.addEventListener("mouseup", this.handleColumnResizingMouseUp);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            this.props.isResizable &&
            ((prevProps.loading !== this.props.loading && !this.props.loading && this.props.data.length) ||
                (this.props.showFilterRow !== prevProps.showFilterRow && this.props.showFilterRow) ||
                (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && this.props.data.length) ||
                JSON.stringify(prevProps.tableColumnVisibility) !== JSON.stringify(this.props.tableColumnVisibility))
        ) {
            this.setTableWidth();
        }

        if (
            this.props.sortingCriterion &&
            (prevProps.sortingCriterion !== this.props.sortingCriterion ||
                prevProps.sortingAsc !== this.props.sortingAsc)
        ) {
            this.props.reloadData(this.props.objectType);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleColumnResizingMouseMove);
        document.removeEventListener("mouseup", this.handleColumnResizingMouseUp);
    }

    /**
     * Recalculates column width while resizing on mouse move, does not send data to server
     * resizeStartPosition: starting x-axis position of resizing
     * resizeProperty: width of only this column is changing, others keep the same width
     */
    recalculateColumnsWidth = (event: any): Record<propertyConstants.Property, number> => {
        const { resizeStartPosition, resizeProperty } = this.state;
        const { tableColumnWidth, tableRef } = this.props;
        let columns = tableConstants.EMPTY_COLUMN_WIDTH;

        if (this.state.isResizing && resizeStartPosition !== null && tableRef?.current && resizeProperty) {
            const endPosition = event.pageX;
            const positionDiff = resizeStartPosition - endPosition;
            const newColumnWidth =
                (tableColumnWidth?.[resizeProperty] ?? tableConstants.DEFAULT_COLUMN_WIDTH) - positionDiff;

            if (newColumnWidth >= 0) {
                if (tableRef.current.scrollWidth - tableRef.current.scrollLeft === tableRef.current.clientWidth) {
                    tableRef.current.scrollTo(tableRef.current.scrollWidth - positionDiff, 0);
                }
                const tableElement = document.getElementById(this.props.objectType);

                for (const heading of this.props.headings) {
                    const property = heading[tableConstants.TABLE_HEADING_NAME];
                    const columnWidthOption =
                        tableColumnWidth?.[property] ??
                        (heading[tableConstants.TABLE_HEADING_VISIBILITY] ? tableConstants.DEFAULT_COLUMN_WIDTH : null);

                    if (property === resizeProperty) {
                        columns = { ...columns, [property]: newColumnWidth };
                    } else {
                        columns = { ...columns, [property]: columnWidthOption };
                    }
                }

                columns = generalHelper.sortObjectKeys(columns);
                this.setColumnWidth(tableElement, resizeProperty, newColumnWidth);
            }
        }

        return columns;
    };

    /**
     * On double click the columns should resize equally to the whole width of table (no horizontal scroll)
     */
    calculateFullSizeColumnsWidth = (): Record<propertyConstants.Property, number> => {
        let columns = tableConstants.EMPTY_COLUMN_WIDTH;

        const tableWidth = this.props.tableRef?.current?.clientWidth ?? null;

        if (!this.state.isResizing && tableWidth) {
            const columnCount = this.props.headings.filter(
                (item) => item[tableConstants.TABLE_HEADING_VISIBILITY]
            ).length;
            const newColumnWidth = tableWidth / columnCount;

            for (const heading of this.props.headings) {
                const property = heading[tableConstants.TABLE_HEADING_NAME];

                columns = { ...columns, [property]: newColumnWidth };
            }
        }

        return columns;
    };

    /**
     * Sets column width after CDM or CDU
     */
    setTableWidth = () => {
        const { tableColumnWidth } = this.props;
        let columns = {};

        for (const heading of this.props.headings) {
            const property = heading[tableConstants.TABLE_HEADING_NAME];
            const isValid =
                propertyHelper.isPropertyValidForObjectType(
                    property,
                    this.props.objectType,
                    {},
                    priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY,
                    true,
                    true,
                    priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY,
                    true,
                    true
                ) || propertyConstants.FIXED_COLUMN_PROPERTIES.includes(property);

            if (isValid) {
                columns = { ...columns, [property]: tableColumnWidth[property] || null };
            }
        }

        this.setColumnsWidth(columns);

        if (
            !this.props.isOptionMenuOpened &&
            Object.keys(columns).length &&
            Object.keys(tableColumnWidth).length &&
            JSON.stringify(generalHelper.sortObjectKeys(tableColumnWidth)) !==
                JSON.stringify(generalHelper.sortObjectKeys(columns))
        ) {
            this.props.setColumnWidthOption(this.props.tableConstant, columns);
        }
    };

    setColumnsWidth = (columns: Record<any, number | null>) => {
        const tableElement = document.getElementById(this.props.objectType);

        for (const [columnKey, columnWidth] of Object.entries(columns)) {
            this.setColumnWidth(tableElement, columnKey, columnWidth);
        }
    };

    /**
     * Takes each column key (property) and its new width, and sets width to all HTML elements according to its property classname
     */
    setColumnWidth = (tableElement: HTMLElement | null, columnKey: any, columnWidth: number | null) => {
        if (tableElement) {
            const columnElements = tableElement.getElementsByClassName(columnKey);
            const width =
                columnWidth !== null
                    ? columnWidth >= tableConstants.MINIMAL_COLUMN_WIDTH
                        ? columnWidth
                        : tableConstants.MINIMAL_COLUMN_WIDTH
                    : tableConstants.DEFAULT_COLUMN_WIDTH;

            for (const element of columnElements) {
                if (element instanceof HTMLElement) {
                    element.style.width = `${width}px`;
                    element.style.minWidth = `${width}px`;
                }
            }
        }
    };

    handleColumnResizingClick = (event: any) => {
        event.stopPropagation();
    };

    handleColumnResizingDoubleClick = (event: any) => {
        let columns = this.calculateFullSizeColumnsWidth();
        columns = generalHelper.sortObjectKeys(columns);

        this.setColumnsWidth(columns);
        this.props.setColumnWidthOption(this.props.tableConstant, columns);
    };

    handleColumnResizingMouseOverOut = (property: propertyConstants.Property, on: boolean) => {
        const tableElement = document.getElementById(this.props.objectType);

        if (tableElement) {
            const columnElements = tableElement.getElementsByClassName(property);

            for (const element of columnElements) {
                if (on) {
                    element.classList.add("resizable-column");
                } else {
                    element.classList.remove("resizable-column");
                }
            }
        }
    };

    /**
     * Starts the resizing, saves starting values
     */
    handleColumnResizingMouseDown = (event: any, property: propertyConstants.Property) => {
        this.setState({
            isResizing: true,
            resizeProperty: property,
            resizeStartPosition: event.pageX
        });
    };

    /**
     * Handles setting correct HTML elements width while resizing
     */
    handleColumnResizingMouseMove = (event: any) => {
        if (this.state.isResizing) {
            this.recalculateColumnsWidth(event);
        }
    };

    /**
     * Ends the resizing, sets correct HTML elements width and sends new width to server
     */
    handleColumnResizingMouseUp = (event: any) => {
        if (this.state.isResizing && this.state.resizeProperty && this.state.resizeStartPosition !== null) {
            const columns = this.recalculateColumnsWidth(event);

            if (
                Object.keys(columns).length &&
                JSON.stringify(generalHelper.sortObjectKeys(this.props.tableColumnWidth)) !==
                    JSON.stringify(generalHelper.sortObjectKeys(columns))
            ) {
                this.props.setColumnWidthOption(this.props.tableConstant, columns);
            }

            this.setState({
                isResizing: false,
                resizeStartPosition: null,
                resizeProperty: null
            });
        }
    };

    handleSortingChange = (property: propertyConstants.Property): void => {
        const { objectType } = this.props;

        if (
            tableConstants.SORTABLE_COLUMNS.includes(property) &&
            !objectTypes.DISABLED_SORTING_OBJECT_TYPES.includes(objectType) &&
            !this.state.isResizing
        ) {
            if (objectTypes.SORTING_OBJECT_TYPES_ON_CLIENT_SIDE.includes(this.props.objectType)) {
                this.props.setSorting(
                    this.props.objectType,
                    !this.props.sortingAsc,
                    property,
                    this.props.modalList.length > 0 ? true : false,
                    this.props.itemId
                );
            } else {
                if (property === this.props.sortingCriterion) {
                    this.props.setSortingDictionaryOption(this.props.tableConstant, {
                        [property]: !this.props.sortingAsc
                    });
                } else {
                    this.props.setSortingDictionaryOption(this.props.tableConstant, {
                        [property]: tableConstants.DEFAULT_SORTING_ASC
                    });
                }
            }
        }
    };

    getHeadingClassName = (heading: TableHeading): string => {
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        let className = `heading td ${property}`;

        if (heading[tableConstants.TABLE_HEADING_CLASS]) {
            className += ` ${heading[tableConstants.TABLE_HEADING_CLASS]}`;
        }

        if (
            tableConstants.SORTABLE_COLUMNS.includes(property) &&
            !objectTypes.DISABLED_SORTING_OBJECT_TYPES.includes(this.props.objectType)
        ) {
            if (this.props.sortingCriterion !== property) {
                className += " sorting";
            }

            if (this.props.sortingCriterion === property) {
                if (this.props.sortingAsc) {
                    className += " sorting-asc";
                } else {
                    className += " sorting-desc";
                }
            }
        }

        return className;
    };

    getTextClassName = (heading: TableHeading): string => {
        let className = "ellipsis";

        if (
            heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_GENERATED &&
            objectTypeHelper.isObjectTypeRedlikeUserWithUserGroups(this.props.objectType)
        ) {
            className += " th-center";
        }

        return className;
    };

    getColumnResizing = (heading: TableHeading): JSX.Element | null => {
        if (this.props.isResizable && !this.props.loading) {
            const property = heading[tableConstants.TABLE_HEADING_NAME];

            return (
                <div
                    className={"column-resizing"}
                    onClick={(event) => this.handleColumnResizingClick(event)}
                    onDoubleClick={(event) => this.handleColumnResizingDoubleClick(event)}
                    onMouseDown={(event) => this.handleColumnResizingMouseDown(event, property)}
                    onMouseOver={() => this.handleColumnResizingMouseOverOut(property, true)}
                    onMouseOut={() => this.handleColumnResizingMouseOverOut(property, false)}
                />
            );
        }

        return null;
    };

    getHeadingKey = (heading: TableHeading): string => {
        if (heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_GENERATED) {
            return (
                heading[tableConstants.TABLE_HEADING_NAME] + "_" + heading[tableConstants.TABLE_HEADING_GENERATED_KEY]
            );
        }

        return heading[tableConstants.TABLE_HEADING_NAME];
    };

    render(): JSX.Element {
        const columns: Array<JSX.Element> = [];
        const visibleHeadings = this.props.headings.filter((item) => item[tableConstants.TABLE_HEADING_VISIBILITY]);

        for (const heading of visibleHeadings) {
            columns.push(
                <div
                    className={this.getHeadingClassName(heading)}
                    key={this.getHeadingKey(heading)}
                    onClick={(): void => this.handleSortingChange(heading[tableConstants.TABLE_HEADING_NAME])}
                    onMouseEnter={(): void => this.props.callbackMouseEnter(heading[tableConstants.TABLE_HEADING_NAME])}
                    onMouseLeave={(): void => this.props.callbackMouseLeave()}
                    data-tip
                    data-for={"header-tooltip"}
                >
                    <span className={this.getTextClassName(heading)}>
                        {heading[tableConstants.TABLE_HEADING_TRANSLATION]}
                    </span>
                    {this.getColumnResizing(heading)}
                </div>
            );
        }

        return (
            <div className="tr" key="heading">
                {columns}
            </div>
        );
    }
}

export type PropsType = Readonly<{
    modalList: Array<Modal>;
    tableColumnVisibility: Option | null;
    tableColumnWidth: Record<string, number | null>;
    systemOptionPriceDefinition: string | null;
    systemOptionPriceEnableCompanyCost: boolean;
    systemOptionPriceEnablePriceGroups: boolean;
    zoneOptionPriceDefinition: string | null;
    zoneOptionPriceEnableCompanyCost: boolean;
    zoneOptionPriceEnablePriceGroups: boolean;
}>;

export type DispatchType = Readonly<{
    reloadData(objectType: objectTypes.ObjectType): any;
    setColumnWidthOption(tableConstant: string, columns: Record<string, number | null>): any;
    setSorting(
        objectType: objectTypes.ObjectType,
        asc: boolean,
        criterion: string,
        isModal: boolean,
        itemId?: number
    ): any;
    setSortingDictionaryOption(tableConstant: string, tableSorting: Record<string, boolean>): any;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    modalList: state.navigation.modalList,
    tableColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        ownProps.tableConstant
    ),
    tableColumnWidth:
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
            ownProps.tableConstant
        ) ?? {},
    systemOptionPriceDefinition:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_PRICE_DEFINITION)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? null,
    systemOptionPriceEnableCompanyCost:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    systemOptionPriceEnablePriceGroups:
        generalHelper.getObjectByKey(state.price.systemOptionList, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    zoneOptionPriceDefinition:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_PRICE_DEFINITION)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? null,
    zoneOptionPriceEnableCompanyCost:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false,
    zoneOptionPriceEnablePriceGroups:
        generalHelper.getObjectByKey(state.price.zoneOptionList, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS)?.[
            propertyConstants.PROPERTY_VALUE
        ] ?? false
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    reloadData: (objectType: objectTypes.ObjectType): any => dispatch(universalObjectActions.reloadData(objectType)),
    setColumnWidthOption: (tableConstant: string, columns: Record<string, number | null>): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_TABLE_COLUMNS_WIDTH, tableConstant, columns)
        ),
    setSorting: (
        objectType: objectTypes.ObjectType,
        asc: boolean,
        criterion: string,
        isModal: boolean,
        itemId?: number
    ): any => dispatch(universalObjectActions.setSorting(objectType, asc, criterion, isModal, itemId)),
    setSortingDictionaryOption: (tableConstant: string, tableSorting: Record<string, boolean>): any =>
        dispatch(optionActions.setDictionaryOption(optionsConstants.OPTION_TABLE_SORTING, tableConstant, tableSorting))
});

export const TableHeadingRowContainer = connect(mapStateToProps, mapDispatchToProps)(TableHeadingRow);
