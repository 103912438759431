import * as generalHelper from "../helpers/generalHelper";
import * as keyCodes from "../constants/keyCodes";

import { DispatchType, PropsType } from "../containers/Login";
import React, { Component } from "react";
import { PasswordInput } from "./general/inputs/PasswordInput";
import { ReactSVG } from "react-svg";

import imgLoader from "../resources/img/mini-loader.svg";
import sha from "sha256";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    username: string;
    password: string;
};

export class Login extends Component<Props, State> {
    state: State = {
        username: "",
        password: ""
    };

    handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ username: event.target.value });
    };

    handlePasswordChange = (value: string): void => {
        this.setState({ password: value });
    };

    handleLogInKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === keyCodes.ENTER) {
            this.props.login(this.state.username, sha(this.state.password).toUpperCase());
        }
    };

    handleLoginButtonClick = (): void => {
        this.props.login(this.state.username, sha(this.state.password).toUpperCase());
    };

    showWrongLoginDialog = (): JSX.Element | null => {
        if (this.props.wrongLogin) {
            return (
                <div className="wrong-login">
                    <span>{translate("login.incorrectLogin")}</span>
                </div>
            );
        }

        return null;
    };

    getLoader = (): JSX.Element | null => {
        if (this.props.loading) {
            return (
                <div className="wrong-login loader">
                    <ReactSVG className="loader-svg" src={imgLoader} />
                    <span>{translate("login.loading")}</span>
                </div>
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <div className="bg-screen initial-scenes login">
                <div className="bg-container md-window login-window">
                    <div className="lab-logo">
                        <ReactSVG className="logo-icon" src={generalHelper.getSoftwareLogo(this.props.custom)} />
                    </div>
                    <div className="width-100 login-form">
                        <div className="row">
                            <div className="login-title">
                                <span>{`${translate("user.username")}:`}</span>
                            </div>
                            <div>
                                <input
                                    autoFocus={true}
                                    type="text"
                                    value={this.state.username}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleUsernameChange(event);
                                    }}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                        this.handleLogInKeyDown(event);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="login-title">
                                <span>{`${translate("general.password")}:`}</span>
                            </div>
                            <div>
                                <PasswordInput
                                    autoComplete="on"
                                    className={"password-input"}
                                    editable={true}
                                    value={this.state.password}
                                    callbackBlur={() => {
                                        return;
                                    }}
                                    callbackChange={(value: string) => {
                                        this.handlePasswordChange(value);
                                    }}
                                    callbackKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                        this.handleLogInKeyDown(event);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <button
                                className="btn-info ellipsis"
                                onClick={() => {
                                    this.handleLoginButtonClick();
                                }}
                            >
                                {`${translate("login.login")}`}
                            </button>
                        </div>
                    </div>
                </div>
                {this.showWrongLoginDialog()}
                {this.getLoader()}
            </div>
        );
    }
}
