import * as actionConstants from "../constants/actionTypes";
import * as defaultConstants from "../constants/default";

import { FlashMessage } from "../types/flashMessage";

import { t as translate } from "react-i18nify";

export interface NavigationSetNotificationPanel {
    type: typeof actionConstants.NAVIGATION_SET_NOTIFICATION_PANEL;
    payload: {
        activeNotificationPanel: boolean;
    };
}

export function setNotificationPanel(activeNotificationPanel: boolean): NavigationSetNotificationPanel {
    return {
        type: actionConstants.NAVIGATION_SET_NOTIFICATION_PANEL,
        payload: {
            activeNotificationPanel
        }
    };
}

export interface NavigationSaveFlashMessage {
    type: typeof actionConstants.NAVIGATION_FLASH_MESSAGE_SAVE;
    payload: {
        flashMessage: FlashMessage;
    };
}

export function saveFlashMessage(flashMessage: FlashMessage): NavigationSaveFlashMessage {
    return {
        type: actionConstants.NAVIGATION_FLASH_MESSAGE_SAVE,
        payload: {
            flashMessage
        }
    };
}

export function saveClientFlashMessage(text?: string): NavigationSaveFlashMessage {
    const flashMessage = new FlashMessage(
        defaultConstants.FLASH_MESSAGE_WARNING,
        text ? `${translate("flashMessage.clientError")}: ${text}` : translate("flashMessage.clientError")
    );

    return {
        type: actionConstants.NAVIGATION_FLASH_MESSAGE_SAVE,
        payload: {
            flashMessage
        }
    };
}

export interface NavigationDeleteFlashMessage {
    type: typeof actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE;
    payload: {
        id: number;
    };
}

export function deleteFlashMessage(id: number): NavigationDeleteFlashMessage {
    return {
        type: actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE,
        payload: {
            id
        }
    };
}

export interface NavigationDeleteAllFlashMessages {
    type: typeof actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE_ALL;
}

export function deleteAllFlashMessages(): NavigationDeleteAllFlashMessages {
    return {
        type: actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE_ALL
    };
}
