import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/zone/ZoneProductSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { ZoneProductBasePackageTable } from "./tables/ZoneProductBasePackageTable";
import { ZoneProductBaseTable } from "./tables/ZoneProductBaseTable";
import { ZoneProductTable } from "./tables/ZoneProductTable";

type Props = PropsType;

export const ZoneProductScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableProducts, setTableProducts] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.productTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableProducts(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.productTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.productTableConstant]);

    const [tableBases, setTableBases] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.baseInProductTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableBases(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.baseInProductTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.baseInProductTableConstant]);

    const [tablePackages, setTablePackages] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.baseInProductPackageTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePackages(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.baseInProductPackageTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.baseInProductPackageTableConstant]);

    const getProductsTable = (): JSX.Element | null => {
        if (tableProducts) {
            return (
                <ZoneProductTable
                    tableConstant={props.productTableConstant}
                    objectType={objectTypes.ZONE_PRODUCT}
                    dataPrivileges={tableProducts}
                    priceDataPrivileges={props.priceDataPrivileges}
                    loading={props.productLoading}
                    buttonLoading={props.productButtonLoading}
                    allList={props.productList}
                    activeList={props.productActiveList}
                    availableCount={props.productAvailableCount}
                    columnOrder={props.productColumnOrder}
                    columnVisibility={props.productColumnVisibility}
                    columnWidth={props.productColumnWidth}
                    offset={props.productOffset}
                    page={props.productPage}
                    rowCount={props.productRowCount}
                    rowCountCustom={props.productRowCountCustom}
                    search={props.productSearch}
                    searchParams={props.productSearchParams}
                    showFilterRow={props.productShowFilterRow}
                    showGlobalSearch={props.productShowGlobalSearch}
                    sortingAsc={props.productSortingAsc}
                    sortingCriterion={props.productSortingCriterion}
                    totalCount={props.productTotalCount}
                    useLargoNames={props.optionUseLargoNames || false}
                    useTmcNames={props.optionUseTmcNames || false}
                    currency={props.currency}
                    optionPriceDefinition={props.optionPriceDefinition?.[propertyConstants.PROPERTY_VALUE] || null}
                    optionPriceEnableCompanyCost={
                        props.optionPriceEnableCompanyCost?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                    optionPriceEnablePriceGroups={
                        props.optionPriceEnablePriceGroups?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                />
            );
        }
        return null;
    };

    const getBasesInProductsTable = (): JSX.Element | null => {
        if (tableBases) {
            return (
                <ZoneProductBaseTable
                    tableConstant={props.baseInProductTableConstant}
                    objectType={objectTypes.ZONE_BASE_IN_PRODUCT}
                    dataPrivileges={tableBases}
                    priceDataPrivileges={props.priceDataPrivileges}
                    loading={props.baseInProductLoading}
                    buttonLoading={props.baseInProductButtonLoading}
                    allList={props.baseInProductList}
                    activeList={props.baseInProductActiveList}
                    availableCount={props.baseInProductAvailableCount}
                    columnOrder={props.baseInProductColumnOrder}
                    columnVisibility={props.baseInProductColumnVisibility}
                    columnWidth={props.baseInProductColumnWidth}
                    offset={props.baseInProductOffset}
                    page={props.baseInProductPage}
                    rowCount={props.baseInProductRowCount}
                    rowCountCustom={props.baseInProductRowCountCustom}
                    search={props.baseInProductSearch}
                    searchParams={props.baseInProductSearchParams}
                    showFilterRow={props.baseInProductShowFilterRow}
                    showGlobalSearch={props.baseInProductShowGlobalSearch}
                    sortingAsc={props.baseInProductSortingAsc}
                    sortingCriterion={props.baseInProductSortingCriterion}
                    totalCount={props.baseInProductTotalCount}
                    currency={props.currency}
                    optionPriceDefinition={props.optionPriceDefinition?.[propertyConstants.PROPERTY_VALUE] || null}
                    optionPriceEnableCompanyCost={
                        props.optionPriceEnableCompanyCost?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                    optionPriceEnablePriceGroups={
                        props.optionPriceEnablePriceGroups?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                />
            );
        }
        return null;
    };

    const getBasesInProductPackagesTable = (): JSX.Element | null => {
        if (tablePackages) {
            return (
                <ZoneProductBasePackageTable
                    tableConstant={props.baseInProductPackageTableConstant}
                    objectType={objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE}
                    dataPrivileges={tablePackages}
                    priceDataPrivileges={props.priceDataPrivileges}
                    loading={props.baseInProductPackageLoading}
                    buttonLoading={props.baseInProductPackageButtonLoading}
                    allList={props.baseInProductPackageList}
                    activeList={props.baseInProductPackageActiveList}
                    availableCount={props.baseInProductPackageAvailableCount}
                    columnOrder={props.baseInProductPackageColumnOrder}
                    columnVisibility={props.baseInProductPackageColumnVisibility}
                    columnWidth={props.baseInProductPackageColumnWidth}
                    offset={props.baseInProductPackageOffset}
                    page={props.baseInProductPackagePage}
                    rowCount={props.baseInProductPackageRowCount}
                    rowCountCustom={props.baseInProductPackageRowCountCustom}
                    search={props.baseInProductPackageSearch}
                    searchParams={props.baseInProductPackageSearchParams}
                    showFilterRow={props.baseInProductPackageShowFilterRow}
                    showGlobalSearch={props.baseInProductPackageShowGlobalSearch}
                    sortingAsc={props.baseInProductPackageSortingAsc}
                    sortingCriterion={props.baseInProductPackageSortingCriterion}
                    totalCount={props.baseInProductPackageTotalCount}
                    currency={props.currency}
                    optionPriceDefinition={props.optionPriceDefinition?.[propertyConstants.PROPERTY_VALUE] || null}
                    optionPriceEnableCompanyCost={
                        props.optionPriceEnableCompanyCost?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                    optionPriceEnablePriceGroups={
                        props.optionPriceEnablePriceGroups?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                />
            );
        }
        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={
                [getProductsTable(), getBasesInProductsTable(), getBasesInProductPackagesTable()].filter(
                    (item) => item !== null
                ) as Array<JSX.Element>
            }
            layout={props.sceneLayout}
            sceneTableSize={props.productSceneTableSize}
            dataPrivileges={tableProducts}
            sceneConstant={menuConstants.PAGE_ZONE_PRODUCTS}
        />
    );
};
