import * as databaseActions from "../actions/databaseActions";
import * as loginActions from "../actions/loginActions";
import * as modalTypes from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as navigationTypes from "../constants/navigationTypes";
import * as notificationActions from "../actions/notificationActions";
import * as systemActions from "../actions/systemActions";
import * as zoneActions from "../actions/zoneActions";

import { AppState } from "../reducers";
import { DataItem } from "../types/dataItem";
import { Database } from "../types/database";
import { Dispatch } from "redux";
import { Header } from "../components/Header";
import { License } from "../types/licensing/license";
import { Paths } from "../constants/paths";
import { System } from "../types/system";
import { User } from "../types/user";
import { Zone } from "../types/zone";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    activeDatabase: Database | null;
    activeLicense: License | null;
    activeNotificationPanel: boolean;
    activeScene: navigationTypes.Scene;
    activeSystem: System | null;
    activeZone: Zone | null;
    company: string;
    databaseList: Array<Database>;
    dataChanged: boolean;
    dataPrivileges: Array<DataItem>;
    email: string;
    loggedUser: User | null;
    messagesCount: number;
    phone: string;
    privileges: any;
    productTitle: string;
    systemList: Array<System>;
    year: string;
    zoneList: Array<Zone>;
}>;

export type DispatchType = Readonly<{
    logout(): void;
    openModal(modalType: modalTypes.ModalType, params: any): void;
    routingPush(path: Paths, confirmed: boolean): void;
    showNotificationPanel(activeNotificationPanel: boolean): void;
    selectDatabase(id: number, confirmed: boolean): void;
    selectSystem(id: number, confirmed: boolean): void;
    selectZone(id: number, confirmed: boolean): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    activeDatabase: state.database.active,
    activeNotificationPanel: state.navigation.activeNotificationPanel,
    activeLicense: state.license.activeLicense,
    activeScene: state.navigation.activeScene,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    company: state.software.company,
    dataChanged: state.navigation.dataChanged,
    databaseList: state.database.allList,
    dataPrivileges: state.login.dataPrivileges,
    email: state.software.email,
    loggedUser: state.login.loggedUser,
    messagesCount: state.navigation.flashMessages.length,
    phone: state.software.phone,
    privileges: state.login.privileges,
    productTitle: state.software.productTitle,
    systemList: state.system.allList,
    year: state.software.year,
    zoneList: state.zone.allList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    logout: (): any => dispatch(loginActions.logout()),
    openModal: (type: modalTypes.ModalType, params: any): any =>
        dispatch(navigationActions.navigationOpenModal(type, params)),
    routingPush: (path: Paths, confirmed: boolean): any => dispatch(navigationActions.routingPush(path, confirmed)),
    showNotificationPanel: (activeNotificationPanel: boolean): any =>
        dispatch(notificationActions.setNotificationPanel(activeNotificationPanel)),
    selectDatabase: (id: number, confirmed: boolean): any => dispatch(databaseActions.setActiveDatabase(id, confirmed)),
    selectSystem: (id: number, confirmed: boolean): any => dispatch(systemActions.setActiveSystem(id, confirmed)),
    selectZone: (id: number, confirmed: boolean): any => dispatch(zoneActions.setActiveZone(id, confirmed))
});

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
