import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as redlikeOptionConstants from "../constants/entityConstants/redlikeOptionConstants";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class RedlikeOption {
    id: string;
    key: string;
    dataType: serverConstants.DataType;
    universalValue: any;
    valueSource: number;
    overwrite: boolean;
    settings: Array<any> | null;
    description: string | null;

    constructor(
        id: string,
        key: string,
        dataType: serverConstants.DataType,
        universalValue: any,
        valueSource: number,
        overwrite: boolean,
        settings: Array<any> | null,
        description: string | null
    ) {
        this.id = id;
        this.key = key;
        this.dataType = dataType;
        this.universalValue = universalValue;
        this.valueSource = valueSource;
        this.overwrite = overwrite;
        this.settings = settings;
        this.description = description;
    }
}

export function mapRedlikeOption(data: Record<string, any>, custom: string | null = null): RedlikeOption | null {
    try {
        if (redlikeOptionConstants.HIDDEN_REDLIKE_OPTINS.includes(data.key)) {
            return null;
        }

        return new RedlikeOption(
            data.key,
            data.key,
            data.dataType,
            parseRedlikeOption(data),
            data.valueSource,
            !data.overwrite,
            data.settings,
            translationHelper.getRedlikeOptionDescriptionTranslation(data.key, custom)
        );
    } catch (e) {
        return null;
    }
}

export function updateRedlikeOption(
    oldRedlikeOption: RedlikeOption | null,
    updatedData: Record<string, any>
): RedlikeOption | null {
    if (oldRedlikeOption === null) {
        return null;
    }

    try {
        return new RedlikeOption(
            oldRedlikeOption.id,
            oldRedlikeOption.key,
            oldRedlikeOption.dataType,
            updatedData.universalValue ? updatedData.universalValue : oldRedlikeOption.universalValue,
            oldRedlikeOption.valueSource,
            updatedData.overwrite ? updatedData.overwrite : oldRedlikeOption.overwrite,
            oldRedlikeOption.settings,
            oldRedlikeOption.description
        );
    } catch (e) {
        return null;
    }
}

export function parseRedlikeOption(data: Record<string, any>): any {
    try {
        if (serverConstants.DATA_TYPES_LIST.includes(data.dataType) && Array.isArray(data.value)) {
            if (data.value !== null) {
                // creating new JSON object, which includes the list of items from server
                const jsonData = { [propertyConstants.PROPERTY_DATA]: data.value };
                return JSON.stringify(jsonData);
            }

            return "";
        }

        if (typeof data.value === "object") {
            if (data.value !== null) {
                return JSON.stringify(data.value);
            }

            return "";
        }
        return data.value;
    } catch (e) {
        return "";
    }
}

export function generateRedlikeOptionData(redlikeOptionList: Array<RedlikeOption>): Array<any> {
    const data: Array<any> = [];

    for (const item of redlikeOptionList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.key,
            [propertyConstants.PROPERTY_KEY]: item.key,
            [propertyConstants.PROPERTY_DATA_TYPE]: item.dataType,
            [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: item.universalValue,
            [propertyConstants.PROPERTY_VALUE_SOURCE]: item.valueSource,
            [propertyConstants.PROPERTY_OVERWRITE]: item.overwrite,
            [propertyConstants.PROPERTY_SETTINGS]: item.settings,
            [propertyConstants.PROPERTY_DESCRIPTION]: item.description
        });
    }

    return data;
}

export function generateRedlikeOptionHeadings(
    objectType: objectTypes.ObjectType,
    activeItem: RedlikeOption | null,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    let updatedOptionType = null;
    let updatedOptionEnum = [];

    if (activeItem) {
        updatedOptionType = generalHelper.getDataType(activeItem, true);
        updatedOptionEnum =
            updatedOptionType === tableConstants.TABLE_TYPE_SELECT && activeItem[propertyConstants.PROPERTY_SETTINGS]
                ? activeItem[propertyConstants.PROPERTY_SETTINGS] || []
                : [];
    }

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_DESCRIPTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DESCRIPTION),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            editableList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DESCRIPTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            requiredList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            [],
            widthOption?.[propertyConstants.PROPERTY_DESCRIPTION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_KEY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_KEY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_KEY),
            editableList.includes(propertyConstants.PROPERTY_KEY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_KEY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_KEY),
            requiredList.includes(propertyConstants.PROPERTY_KEY),
            [],
            widthOption?.[propertyConstants.PROPERTY_KEY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_OVERWRITE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_OVERWRITE),
            activeItem !== null ? tableConstants.TABLE_TYPE_BOOLEAN : tableConstants.TABLE_TYPE_ICON,
            visibleList.includes(propertyConstants.PROPERTY_OVERWRITE),
            editableList.includes(propertyConstants.PROPERTY_OVERWRITE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_OVERWRITE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_OVERWRITE),
            requiredList.includes(propertyConstants.PROPERTY_OVERWRITE),
            [],
            widthOption?.[propertyConstants.PROPERTY_OVERWRITE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UNIVERSAL_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            updatedOptionType ? updatedOptionType : tableConstants.TABLE_TYPE_UNIVERSAL,
            visibleList.includes(propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            editableList.includes(propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_UNIVERSAL_VALUE),
            updatedOptionEnum,
            widthOption?.[propertyConstants.PROPERTY_UNIVERSAL_VALUE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
