import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { BarcodeTable } from "./tables/BarcodeTable";
import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericBarcodeSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableBarcodes: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericBarcodeScene extends Component<Props, State> {
    state: State = {
        tableBarcodes:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.barcodeTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableBarcodes:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.barcodeTableConstant)
                        : null) || null
            });
        }
    }

    getBarcodesTable(): JSX.Element | null {
        const { barcodeTableConstant } = this.props;
        const { tableBarcodes } = this.state;

        if (tableBarcodes) {
            return (
                <BarcodeTable
                    tableConstant={barcodeTableConstant}
                    objectType={objectTypes.BARCODE}
                    dataPrivileges={tableBarcodes}
                    loading={this.props.barcodeLoading}
                    allList={this.props.barcodeList}
                    activeList={this.props.barcodeActiveList}
                    systemList={this.props.systemList}
                    zoneList={this.props.zoneList}
                    columnOrder={this.props.barcodeColumnOrder}
                    columnVisibility={this.props.barcodeColumnVisibility}
                    columnWidth={this.props.barcodeColumnWidth}
                    offset={this.props.barcodeOffset}
                    page={this.props.barcodePage}
                    rowCount={this.props.barcodeRowCount}
                    rowCountCustom={this.props.barcodeRowCountCustom}
                    search={this.props.barcodeSearch}
                    searchParams={this.props.barcodeSearchParams}
                    showFilterRow={this.props.barcodeShowFilterRow}
                    showGlobalSearch={this.props.barcodeShowGlobalSearch}
                    sortingAsc={this.props.barcodeSortingAsc}
                    sortingCriterion={this.props.barcodeSortingCriterion}
                    totalCount={this.props.barcodeTotalCount}
                />
            );
        }

        return null;
    }
    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getBarcodesTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.barcodeSceneTableSize}
                dataPrivileges={this.state.tableBarcodes}
                sceneConstant={menuConstants.PAGE_GENERIC_BARCODES}
            />
        );
    }
}
