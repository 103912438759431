import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";

import { Epic, ofType } from "redux-observable";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const duplicateItem: Epic<any, any> = (action$) =>
    action$.pipe(
        ofType(actionConstants.DUPLICATE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // database
                case objectTypes.DATABASE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_DUPLICATE_DATABASE,
                            methods.METHOD_DUPLICATE_DATABASE,
                            payload.params
                        )
                    );
                    break;
                }
                // redlike user group
                case objectTypes.MASTER_REDLIKE_USER_GROUP_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_DUPLICATE_REDLIKE_USER_GROUP,
                                methods.METHOD_DUPLICATE_REDLIKE_USER_GROUP,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_DUPLICATE_MASTER_PRODUCT,
                                methods.METHOD_DUPLICATE_MASTER_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_DUPLICATE_SYSTEM_PRODUCT,
                                methods.METHOD_DUPLICATE_SYSTEM_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_PRODUCT_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_DUPLICATE_ZONE_PRODUCT,
                                methods.METHOD_DUPLICATE_ZONE_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // system
                case objectTypes.SYSTEM_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_DUPLICATE_SYSTEM,
                                methods.METHOD_DUPLICATE_SYSTEM,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // zone
                case objectTypes.ZONE_DUPLICATE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_DUPLICATE_ZONE, methods.METHOD_DUPLICATE_ZONE, payload.params)
                        );
                    }
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
