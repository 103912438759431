import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as modalTypes from "../../../constants/modalTypes";
import * as navigationActions from "../../../actions/navigationActions";
import * as objectTypes from "../../../constants/objectTypes";
import * as scriptActions from "../../../actions/scriptActions";
import * as universalObjectActions from "../../../actions/universalObjectActions";

import { AppState } from "../../../reducers";
import { Dispatch } from "redux";
import { MenuItem } from "../../../types/menu";
import { Scene } from "../../../constants/navigationTypes";
import { Script } from "../../../types/script";
import { ServerRequest } from "../../../types/serverRequest";
import { ServerScriptsScene } from "../../../components/scenes/data/ServerScriptsScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    activeScene: Scene;
    menuScene: MenuItem | null;
    scriptLoading: boolean;
    scriptsList: Array<Script>;
}>;

export type DispatchType = Readonly<{
    getAllItems(objectType: objectTypes.ObjectType): void;
    openModal(modalType: modalTypes.ModalType, params: any): void;
    runScript(scriptId: number): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_SERVER_SCRIPTS
    ),
    scriptLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SCRIPTS_NO_PARAMS
    ),
    scriptsList: state.script.allList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    getAllItems: (objectType: objectTypes.ObjectType): any => dispatch(universalObjectActions.getAllItems(objectType)),
    openModal: (type: modalTypes.ModalType, params: any): any =>
        dispatch(navigationActions.navigationOpenModal(type, params)),
    runScript: (scriptId: number): any => dispatch(scriptActions.runScript(scriptId))
});

export const ServerScriptsSceneContainer = connect(mapStateToProps, mapDispatchToProps)(ServerScriptsScene);
