export const PARAMETER_TYPE_BARCODE = "barcode";
export const PARAMETER_TYPE_BUTTON_INPUT = "buttoninput";
export const PARAMETER_TYPE_BUTTON_SECTION = "buttonsection";
export const PARAMETER_TYPE_CHECK_BOX = "checkbox";
export const PARAMETER_TYPE_CONVERSION_INPUTS = "conversion";
export const PARAMETER_TYPE_DATE_PICKER = "datePicker";
export const PARAMETER_TYPE_FILTER_SELECT_BOX = "filterdropdown";
export const PARAMETER_TYPE_ICON = "icon";
export const PARAMETER_TYPE_LIST = "list";
export const PARAMETER_TYPE_LOCK = "lock";
export const PARAMETER_TYPE_PASSWORD = "password";
export const PARAMETER_TYPE_RADIO_BUTTON = "radiobutton";
export const PARAMETER_TYPE_SELECT_BOX = "dropdown";
export const PARAMETER_TYPE_TEXT_AREA = "textarea";
export const PARAMETER_TYPE_TEXT_INPUT = "textinput";
export const PARAMETER_TYPE_TEXT_READONLY = "readonlyinput";

export type InputType =
    | typeof PARAMETER_TYPE_BARCODE
    | typeof PARAMETER_TYPE_BUTTON_INPUT
    | typeof PARAMETER_TYPE_BUTTON_SECTION
    | typeof PARAMETER_TYPE_CHECK_BOX
    | typeof PARAMETER_TYPE_CONVERSION_INPUTS
    | typeof PARAMETER_TYPE_DATE_PICKER
    | typeof PARAMETER_TYPE_FILTER_SELECT_BOX
    | typeof PARAMETER_TYPE_ICON
    | typeof PARAMETER_TYPE_LIST
    | typeof PARAMETER_TYPE_LOCK
    | typeof PARAMETER_TYPE_PASSWORD
    | typeof PARAMETER_TYPE_RADIO_BUTTON
    | typeof PARAMETER_TYPE_SELECT_BOX
    | typeof PARAMETER_TYPE_TEXT_AREA
    | typeof PARAMETER_TYPE_TEXT_INPUT
    | typeof PARAMETER_TYPE_TEXT_READONLY;

export const UPDATE_TYPE_ICON = "updateIcon";
export const UPDATE_TYPE_IMAGE = "updateImage";
export const UPLOAD_TYPE_DIRECTORY = "directory";
export const UPLOAD_TYPE_FILE = "file";
export const UPLOAD_TYPE_FILES = "files";
export const UPLOAD_TYPE_ICON = "uploadIcon";
export const UPLOAD_TYPE_IMAGE = "uploadImage";
export const UPLOAD_TYPE_IMAGES = "uploadImages";

export type UploadType =
    | typeof UPDATE_TYPE_ICON
    | typeof UPDATE_TYPE_IMAGE
    | typeof UPLOAD_TYPE_DIRECTORY
    | typeof UPLOAD_TYPE_FILE
    | typeof UPLOAD_TYPE_FILES
    | typeof UPLOAD_TYPE_ICON
    | typeof UPLOAD_TYPE_IMAGE
    | typeof UPLOAD_TYPE_IMAGES;
