export const ADD_MODAL = "add";
export const DELETE_MODAL = "delete";
export const DUPLICATE_MODAL = "duplicate";
export const EDIT_MODAL = "edit";
export const EXPORT_MODAL = "export";
export const NO_MODAL = "no";
export const PREVIEW_MODAL = "preview";
export const PRICE_MODAL = "price";
export const PROPAGATE_MODAL = "propagate";
export const PROPAGATE_EDIT_MODAL = "propagate_edit";
export const TABLE_MODAL = "table";
export const TRANSLATION_MODAL = "translation";

export type ModalFunctionType =
    | typeof ADD_MODAL
    | typeof DELETE_MODAL
    | typeof DUPLICATE_MODAL
    | typeof EDIT_MODAL
    | typeof EXPORT_MODAL
    | typeof NO_MODAL
    | typeof PREVIEW_MODAL
    | typeof PRICE_MODAL
    | typeof PROPAGATE_MODAL
    | typeof PROPAGATE_EDIT_MODAL
    | typeof TABLE_MODAL
    | typeof TRANSLATION_MODAL;

export const MODAL_AVAILABLE_WEB_ZONES_TABLE = "MODAL_AVAILABLE_WEB_ZONES_TABLE";
export const MODAL_BARCODE_EDIT = "MODAL_BARCODE_EDIT";
export const MODAL_BASE_IN_PRODUCT_ADD = "MODAL_BASE_IN_PRODUCT_ADD";
export const MODAL_BASE_IN_PRODUCT_EDIT = "MODAL_BASE_IN_PRODUCT_EDIT";
export const MODAL_BASE_IN_PRODUCT_PACKAGE_ADD = "MODAL_BASE_IN_PRODUCT_PACKAGE_ADD";
export const MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT = "MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT";
export const MODAL_BASES_ADD = "MODAL_BASES_ADD";
export const MODAL_BASES_EDIT = "MODAL_BASES_EDIT";
export const MODAL_CHANGE_PASSWORD = "MODAL_CHANGE_PASSWORD";
export const MODAL_CHANGELOG = "MODAL_CHANGELOG";
export const MODAL_COLORANT_BATCHES_ADD = "MODAL_COLORANT_BATCHES_ADD";
export const MODAL_COLORANT_BATCHES_EDIT = "MODAL_COLORANT_BATCHES_EDIT";
export const MODAL_COLORANT_PACKAGES_ADD = "MODAL_COLORANT_PACKAGES_ADD";
export const MODAL_COLORANT_PACKAGES_EDIT = "MODAL_COLORANT_PACKAGES_EDIT";
export const MODAL_COLORANTS_ADD = "MODAL_COLORANTS_ADD";
export const MODAL_COLORANTS_EDIT = "MODAL_COLORANTS_EDIT";
export const MODAL_COLORS_ADD = "MODAL_COLORS_ADD";
export const MODAL_COLORS_EDIT = "MODAL_COLORS_EDIT";
export const MODAL_COMPANIES_ADD = "MODAL_COMPANIES_ADD";
export const MODAL_COMPANIES_EDIT = "MODAL_COMPANIES_EDIT";
export const MODAL_CURRENCIES_ADD = "MODAL_CURRENCIES_ADD";
export const MODAL_CURRENCIES_EDIT = "MODAL_CURRENCIES_EDIT";
export const MODAL_DATA_EXPORT = "MODAL_DATA_EXPORT";
export const MODAL_DATA_EXPORT_ALL = "MODAL_DATA_EXPORT_ALL";
export const MODAL_DATABASES_ADD = "MODAL_DATABASES_ADD";
export const MODAL_DATABASES_EMPTY_ADD = "MODAL_DATABASES_EMPTY_ADD"; // TODO MODAL_DATABASES_ADD_EMPTY
export const MODAL_DATABASES_DUPLICATE = "MODAL_DATABASES_DUPLICATE";
export const MODAL_DATABASES_EDIT = "MODAL_DATABASES_EDIT";
export const MODAL_DATABASES_UPDATE = "MODAL_DATABASES_UPDATE";
export const MODAL_DATABASES_VACUUM = "MODAL_DATABASES_VACUUM";
export const MODAL_DISPLAY = "MODAL_DISPLAY";
export const MODAL_EMPTY = "MODAL_EMPTY";
export const MODAL_EXPORT_TASKS_DELETE = "MODAL_EXPORT_TASKS_DELETE";
export const MODAL_FANDECKS_ADD = "MODAL_FANDECKS_ADD";
export const MODAL_FANDECKS_EDIT = "MODAL_FANDECKS_EDIT";
export const MODAL_FILE_CATEGORIES_ADD = "MODAL_FILE_CATEGORIES_ADD";
export const MODAL_FILE_CATEGORIES_EDIT = "MODAL_FILE_CATEGORIES_EDIT";
export const MODAL_FILE_CATEGORIES_TABLE = "MODAL_FILE_CATEGORIES_TABLE";
export const MODAL_FILE_PREVIEW = "MODAL_FILE_PREVIEW";
export const MODAL_FILE_RUN = "MODAL_FILE_RUN";
export const MODAL_FILES_ADD = "MODAL_FILES_ADD";
export const MODAL_FILES_EDIT = "MODAL_FILES_EDIT";
export const MODAL_FILES_TABLE = "MODAL_FILES_TABLE";
export const MODAL_FORMULA_NOTES_ADD = "MODAL_FORMULA_NOTES_ADD";
export const MODAL_FORMULA_NOTES_EDIT = "MODAL_FORMULA_NOTES_EDIT";
export const MODAL_FORMULA_NOTES_TABLE = "MODAL_FORMULA_NOTES_TABLE";
export const MODAL_FORMULAS_ADD = "MODAL_FORMULAS_ADD";
export const MODAL_FORMULAS_EDIT = "MODAL_FORMULAS_EDIT";
export const MODAL_CHANGELOG_EDIT = "MODAL_CHANGELOG_EDIT";
export const MODAL_ICONS_ADD = "MODAL_ICONS_ADD";
export const MODAL_ICONS_TABLE = "MODAL_ICON_TABLE";
export const MODAL_IMAGES_ADD = "MODAL_IMAGES_ADD";
export const MODAL_IMAGES_ADD_MORE = "MODAL_IMAGES_ADD_MORE";
export const MODAL_IMAGES_EDIT = "MODAL_IMAGES_EDIT";
export const MODAL_IMAGES_TABLE = "MODAL_IMAGES_TABLE";
export const MODAL_IMAGE_CATEGORIES_ADD = "MODAL_IMAGE_CATEGORIES_ADD";
export const MODAL_IMAGE_CATEGORIES_EDIT = "MODAL_IMAGE_CATEGORIES_EDIT";
export const MODAL_IMAGE_CATEGORIES_TABLE = "MODAL_IMAGE_CATEGORIES_TABLE";
export const MODAL_IMPORT_TASK_ADD = "MODAL_IMPORT_TASK_ADD";
export const MODAL_IMPORT_TASKS_DELETE = "MODAL_IMPORT_TASKS_DELETE";
export const MODAL_ITEM_DELETE = "MODAL_ITEM_DELETE";
export const MODAL_ITEM_DELETE_FINAL = "MODAL_ITEM_DELETE_FINAL";
export const MODAL_LICENSE = "MODAL_LICENSE";
export const MODAL_ONE_TIME_ACCESS_PASSWORD_GENERATE = "MODAL_ONE_TIME_ACCESS_PASSWORD_GENERATE";
export const MODAL_PACKAGES_ADD = "MODAL_PACKAGES_ADD";
export const MODAL_PACKAGES_EDIT = "MODAL_PACKAGES_EDIT";
export const MODAL_PRICE_GENERIC_EDIT = "MODAL_PRICE_GENERIC_EDIT";
export const MODAL_PRICE_GROUP_EDIT = "MODAL_PRICE_GROUP_EDIT";
export const MODAL_PRICE_MARGIN_ADD = "MODAL_PRICE_MARGIN_ADD";
export const MODAL_PRICE_MARGIN_EDIT = "MODAL_PRICE_MARGIN_EDIT";
export const MODAL_PRICE_OPTIONS = "MODAL_PRICE_OPTIONS";
export const MODAL_PRODUCT_GROUPS_ADD = "MODAL_PRODUCT_GROUPS_ADD";
export const MODAL_PRODUCTS_ADD = "MODAL_PRODUCTS_ADD";
export const MODAL_PRODUCTS_DUPLICATE = "MODAL_PRODUCTS_DUPLICATE";
export const MODAL_PRODUCTS_EDIT = "MODAL_PRODUCTS_EDIT";
export const MODAL_R2W_VERSION_PREVIEW = "MODAL_R2W_VERSION_PREVIEW";
export const MODAL_REDLIKE_OPTIONS_EDIT = "MODAL_REDLIKE_OPTIONS_EDIT";
export const MODAL_REDLIKE_USER_GROUPS_ADD = "MODAL_REDLIKE_USER_GROUPS_ADD";
export const MODAL_REDLIKE_USER_GROUPS_DUPLICATE = "MODAL_REDLIKE_USER_GROUPS_DUPLICATE";
export const MODAL_REDLIKE_USER_GROUPS_EDIT = "MODAL_REDLIKE_USER_GROUPS_EDIT";
export const MODAL_RGB_CALCULATE = "MODAL_RGB_CALCULATE";
export const MODAL_SAVE_DATA = "MODAL_SAVE_DATA";
export const MODAL_SELECT_SYSTEM = "MODAL_SELECT_SYSTEM";
export const MODAL_SELECT_ZONE = "MODAL_SELECT_ZONE";
export const MODAL_SPECTRO_TYPE_ADD = "MODAL_SPECTRO_TYPE_ADD";
export const MODAL_SPECTRO_TYPE_EDIT = "MODAL_SPECTRO_TYPE_EDIT";
export const MODAL_SPECTRO_TYPES_TABLE = "MODAL_SPECTRO_TYPES_TABLE";
export const MODAL_SYSTEMS_ADD = "MODAL_SYSTEMS_ADD";
export const MODAL_SYSTEMS_DUPLICATE = "MODAL_SYSTEMS_DUPLICATE";
export const MODAL_SYSTEMS_EDIT = "MODAL_SYSTEMS_EDIT";
export const MODAL_TRANSLATION_MANAGEMENT = "MODAL_TRANSLATION_MANAGEMENT";
export const MODAL_UNITS_ADD = "MODAL_UNITS_ADD";
export const MODAL_UNITS_EDIT = "MODAL_UNITS_EDIT";
export const MODAL_USER_ACTION_HISTORY = "MODAL_USER_ACTION_HISTORY";
export const MODAL_USER_GROUPS_ADD = "MODAL_USER_GROUPS_ADD";
export const MODAL_USER_GROUPS_EDIT = "MODAL_USER_GROUPS_EDIT";
export const MODAL_USERS_ADD = "MODAL_USERS_ADD";
export const MODAL_USERS_EDIT = "MODAL_USERS_EDIT";
export const MODAL_VERSION_EDIT = "MODAL_VERSION_EDIT";
export const MODAL_WARNING = "MODAL_WARNING";
export const MODAL_WAVELENGTH_SETTINGS = "MODAL_WAVELENGTH_SETTINGS";
export const MODAL_ZONES_ADD = "MODAL_ZONES_ADD";
export const MODAL_ZONES_DUPLICATE = "MODAL_ZONES_DUPLICATE";
export const MODAL_ZONES_EDIT = "MODAL_ZONES_EDIT";
export const MODAL_ZONES_METADATA = "MODAL_ZONES_METADATA";

export const MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE = "MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE";
export const MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE = "MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE";
export const MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE = "MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE";
export const MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE = "MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE";
export const MODAL_SYSTEM_BASES_PROPAGATE = "MODAL_SYSTEM_BASES_PROPAGATE";
export const MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE = "MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE";
export const MODAL_SYSTEM_COLORANTS_PROPAGATE = "MODAL_SYSTEM_COLORANTS_PROPAGATE";
export const MODAL_SYSTEM_FANDECKS_PROPAGATE = "MODAL_SYSTEM_FANDECKS_PROPAGATE";
export const MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE = "MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE";
export const MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE = "MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE";
export const MODAL_SYSTEM_PRODUCTS_PROPAGATE = "MODAL_SYSTEM_PRODUCTS_PROPAGATE";
export const MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE =
    "MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE";
export const MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE = "MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE";
export const MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE = "MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE";
export const MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE = "MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE";
export const MODAL_ZONE_BASES_PROPAGATE = "MODAL_ZONE_BASES_PROPAGATE";
export const MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE = "MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE";
export const MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE = "MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE";
export const MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE = "MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE";
export const MODAL_ZONE_COLORANTS_PROPAGATE = "MODAL_ZONE_COLORANTS_PROPAGATE";
export const MODAL_ZONE_FANDECKS_PROPAGATE = "MODAL_ZONE_FANDECKS_PROPAGATE";
export const MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE = "MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE";
export const MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE = "MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE";
export const MODAL_ZONE_PRODUCTS_PROPAGATE = "MODAL_ZONE_PRODUCTS_PROPAGATE";
export const MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE = "MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE";
export const MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE = "MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE";

export type ModalType =
    | typeof MODAL_AVAILABLE_WEB_ZONES_TABLE
    | typeof MODAL_BARCODE_EDIT
    | typeof MODAL_BASE_IN_PRODUCT_ADD
    | typeof MODAL_BASE_IN_PRODUCT_EDIT
    | typeof MODAL_BASE_IN_PRODUCT_PACKAGE_ADD
    | typeof MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT
    | typeof MODAL_BASES_ADD
    | typeof MODAL_BASES_EDIT
    | typeof MODAL_CHANGE_PASSWORD
    | typeof MODAL_CHANGELOG
    | typeof MODAL_COLORANT_BATCHES_ADD
    | typeof MODAL_COLORANT_BATCHES_EDIT
    | typeof MODAL_COLORANT_PACKAGES_ADD
    | typeof MODAL_COLORANT_PACKAGES_EDIT
    | typeof MODAL_COLORANTS_ADD
    | typeof MODAL_COLORANTS_EDIT
    | typeof MODAL_COLORS_ADD
    | typeof MODAL_COLORS_EDIT
    | typeof MODAL_COMPANIES_ADD
    | typeof MODAL_COMPANIES_EDIT
    | typeof MODAL_CURRENCIES_ADD
    | typeof MODAL_CURRENCIES_EDIT
    | typeof MODAL_DATA_EXPORT
    | typeof MODAL_DATA_EXPORT_ALL
    | typeof MODAL_DATABASES_ADD
    | typeof MODAL_DATABASES_EMPTY_ADD
    | typeof MODAL_DATABASES_DUPLICATE
    | typeof MODAL_DATABASES_EDIT
    | typeof MODAL_DATABASES_UPDATE
    | typeof MODAL_DATABASES_VACUUM
    | typeof MODAL_DISPLAY
    | typeof MODAL_EMPTY
    | typeof MODAL_EXPORT_TASKS_DELETE
    | typeof MODAL_FANDECKS_ADD
    | typeof MODAL_FANDECKS_EDIT
    | typeof MODAL_FILE_CATEGORIES_ADD
    | typeof MODAL_FILE_CATEGORIES_EDIT
    | typeof MODAL_FILE_CATEGORIES_TABLE
    | typeof MODAL_FILE_PREVIEW
    | typeof MODAL_FILE_RUN
    | typeof MODAL_FILES_ADD
    | typeof MODAL_FILES_EDIT
    | typeof MODAL_FILES_TABLE
    | typeof MODAL_FORMULA_NOTES_ADD
    | typeof MODAL_FORMULA_NOTES_EDIT
    | typeof MODAL_FORMULA_NOTES_TABLE
    | typeof MODAL_FORMULAS_ADD
    | typeof MODAL_FORMULAS_EDIT
    | typeof MODAL_CHANGELOG_EDIT
    | typeof MODAL_ICONS_ADD
    | typeof MODAL_ICONS_TABLE
    | typeof MODAL_IMAGE_CATEGORIES_ADD
    | typeof MODAL_IMAGE_CATEGORIES_EDIT
    | typeof MODAL_IMAGE_CATEGORIES_TABLE
    | typeof MODAL_IMAGES_ADD
    | typeof MODAL_IMAGES_ADD_MORE
    | typeof MODAL_IMAGES_EDIT
    | typeof MODAL_IMAGES_TABLE
    | typeof MODAL_IMPORT_TASK_ADD
    | typeof MODAL_IMPORT_TASKS_DELETE
    | typeof MODAL_ITEM_DELETE
    | typeof MODAL_ITEM_DELETE_FINAL
    | typeof MODAL_LICENSE
    | typeof MODAL_ONE_TIME_ACCESS_PASSWORD_GENERATE
    | typeof MODAL_PACKAGES_ADD
    | typeof MODAL_PACKAGES_EDIT
    | typeof MODAL_PRICE_GENERIC_EDIT
    | typeof MODAL_PRICE_GROUP_EDIT
    | typeof MODAL_PRICE_MARGIN_ADD
    | typeof MODAL_PRICE_MARGIN_EDIT
    | typeof MODAL_PRICE_OPTIONS
    | typeof MODAL_PRODUCT_GROUPS_ADD
    | typeof MODAL_PRODUCTS_ADD
    | typeof MODAL_PRODUCTS_DUPLICATE
    | typeof MODAL_PRODUCTS_EDIT
    | typeof MODAL_R2W_VERSION_PREVIEW
    | typeof MODAL_REDLIKE_OPTIONS_EDIT
    | typeof MODAL_REDLIKE_USER_GROUPS_ADD
    | typeof MODAL_REDLIKE_USER_GROUPS_DUPLICATE
    | typeof MODAL_REDLIKE_USER_GROUPS_EDIT
    | typeof MODAL_RGB_CALCULATE
    | typeof MODAL_SAVE_DATA
    | typeof MODAL_SELECT_SYSTEM
    | typeof MODAL_SELECT_ZONE
    | typeof MODAL_SPECTRO_TYPE_ADD
    | typeof MODAL_SPECTRO_TYPE_EDIT
    | typeof MODAL_SPECTRO_TYPES_TABLE
    | typeof MODAL_SYSTEMS_ADD
    | typeof MODAL_SYSTEMS_DUPLICATE
    | typeof MODAL_SYSTEMS_EDIT
    | typeof MODAL_TRANSLATION_MANAGEMENT
    | typeof MODAL_UNITS_ADD
    | typeof MODAL_UNITS_EDIT
    | typeof MODAL_USER_ACTION_HISTORY
    | typeof MODAL_USER_GROUPS_ADD
    | typeof MODAL_USER_GROUPS_EDIT
    | typeof MODAL_USERS_ADD
    | typeof MODAL_USERS_EDIT
    | typeof MODAL_VERSION_EDIT
    | typeof MODAL_WARNING
    | typeof MODAL_WAVELENGTH_SETTINGS
    | typeof MODAL_ZONES_ADD
    | typeof MODAL_ZONES_DUPLICATE
    | typeof MODAL_ZONES_EDIT
    | typeof MODAL_ZONES_METADATA
    | typeof MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE
    | typeof MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE
    | typeof MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE
    | typeof MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE
    | typeof MODAL_SYSTEM_BASES_PROPAGATE
    | typeof MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE
    | typeof MODAL_SYSTEM_COLORANTS_PROPAGATE
    | typeof MODAL_SYSTEM_FANDECKS_PROPAGATE
    | typeof MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE
    | typeof MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE
    | typeof MODAL_SYSTEM_PRODUCTS_PROPAGATE
    | typeof MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE
    | typeof MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE
    | typeof MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE
    | typeof MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE
    | typeof MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE
    | typeof MODAL_ZONE_BASES_PROPAGATE
    | typeof MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE
    | typeof MODAL_ZONE_COLORANTS_PROPAGATE
    | typeof MODAL_ZONE_FANDECKS_PROPAGATE
    | typeof MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE
    | typeof MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE
    | typeof MODAL_ZONE_PRODUCTS_PROPAGATE
    | typeof MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE
    | typeof MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE
    | typeof MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE;

export const MODALS_INPUTS_WITH_TABLE_BOX = [
    MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE,
    MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE,
    MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE,
    MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE,
    MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE,
    MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE,
    MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE,
    MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE,
    MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE,
    MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE
];
