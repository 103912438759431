import * as actionConstants from "../constants/actionTypes";

export interface ServerSaveRequest {
    type: typeof actionConstants.SERVER_SAVE_REQUEST;
    payload: {
        sequence: number;
        method: string;
    };
}

export function serverSaveRequest(sequence: number, method: string): ServerSaveRequest {
    return {
        type: actionConstants.SERVER_SAVE_REQUEST,
        payload: {
            sequence,
            method
        }
    };
}

export interface ServerRemoveRequest {
    type: typeof actionConstants.SERVER_REMOVE_REQUEST;
    payload: {
        sequence: number;
    };
}

export function serverRemoveRequest(sequence: number): ServerRemoveRequest {
    return {
        type: actionConstants.SERVER_REMOVE_REQUEST,
        payload: {
            sequence
        }
    };
}

export interface VersionSave {
    type: typeof actionConstants.SAVE_VERSION;
    payload: {
        serverVersion: string | null;
    };
}

export function saveVersion(serverVersion: string | null): VersionSave {
    return {
        type: actionConstants.SAVE_VERSION,
        payload: {
            serverVersion
        }
    };
}

export interface SqliteInfoSave {
    type: typeof actionConstants.SAVE_SQLITE_INFO;
    payload: {
        sqliteVersion: string | null;
        sqlcipherVersion: string | null;
    };
}

export function saveSqliteInfo(sqliteVersion: string | null, sqlcipherVersion: string | null): SqliteInfoSave {
    return {
        type: actionConstants.SAVE_SQLITE_INFO,
        payload: {
            sqliteVersion,
            sqlcipherVersion
        }
    };
}

export function passRequest(request: any): any {
    if (request === null || request === undefined) {
        return null;
    }

    return request;
}
