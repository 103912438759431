import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Barcode, mapBarcodes } from "./barcode";

import { TableHeading } from "./tableHeading";

export class Fandeck {
    id: number;
    name: string;
    nameMaster?: string | null;
    nameSystem?: string | null;
    parentId?: number | null;
    nameSource?: number;
    searchName?: string | null;
    searchNameMaster?: string | null;
    searchNameSystem?: string | null;
    searchNameSource?: number;
    printName?: string | null;
    printNameMaster?: string | null;
    printNameSystem?: string | null;
    printNameSource?: number;
    tmcName?: string;
    tmcNameMaster?: string | null;
    tmcNameSystem?: string | null;
    tmcNameSource?: number;
    largoName?: string | null;
    code?: string;
    codeMaster?: string | null;
    codeSystem?: string | null;
    codeSource?: number;
    searchCode?: string | null;
    searchCodeMaster?: string | null;
    searchCodeSystem?: string | null;
    searchCodeSource?: number;
    priority?: number | null;
    priorityMaster?: number | null;
    prioritySystem?: number | null;
    prioritySource?: number;
    moreInfo?: string | null;
    moreInfoMaster?: string | null;
    moreInfoSystem?: string | null;
    moreInfoSource?: number;
    uuid?: string | null;
    barcodes?: Array<Barcode>;

    constructor(
        id: number,
        name: string,
        nameMaster?: string | null,
        nameSystem?: string | null,
        parentId?: number | null,
        nameSource?: number,
        searchName?: string | null,
        searchNameMaster?: string | null,
        searchNameSystem?: string | null,
        searchNameSource?: number,
        printName?: string | null,
        printNameMaster?: string | null,
        printNameSystem?: string | null,
        printNameSource?: number,
        tmcName?: string,
        tmcNameMaster?: string | null,
        tmcNameSystem?: string | null,
        tmcNameSource?: number,
        largoName?: string | null,
        code?: string,
        codeMaster?: string | null,
        codeSystem?: string | null,
        codeSource?: number,
        searchCode?: string | null,
        searchCodeMaster?: string | null,
        searchCodeSystem?: string | null,
        searchCodeSource?: number,
        priority?: number,
        priorityMaster?: number | null,
        prioritySystem?: number | null,
        prioritySource?: number,
        moreInfo?: string | null,
        moreInfoMaster?: string | null,
        moreInfoSystem?: string | null,
        moreInfoSource?: number,
        uuid?: string | null,
        barcodes?: Array<Barcode>
    ) {
        this.id = id;
        this.name = name;
        this.nameMaster = nameMaster;
        this.nameSystem = nameSystem;
        this.parentId = parentId;
        this.nameSource = nameSource;
        this.searchName = searchName;
        this.searchNameMaster = searchNameMaster;
        this.searchNameSystem = searchNameSystem;
        this.searchNameSource = searchNameSource;
        this.printName = printName;
        this.printNameMaster = printNameMaster;
        this.printNameSystem = printNameSystem;
        this.printNameSource = printNameSource;
        this.tmcName = tmcName;
        this.tmcNameMaster = tmcNameMaster;
        this.tmcNameSystem = tmcNameSystem;
        this.tmcNameSource = tmcNameSource;
        this.largoName = largoName;
        this.code = code;
        this.codeMaster = codeMaster;
        this.codeSystem = codeSystem;
        this.codeSource = codeSource;
        this.searchCode = searchCode;
        this.searchCodeMaster = searchCodeMaster;
        this.searchCodeSystem = searchCodeSystem;
        this.searchCodeSource = searchCodeSource;
        this.priority = priority;
        this.priorityMaster = priorityMaster;
        this.prioritySystem = prioritySystem;
        this.prioritySource = prioritySource;
        this.moreInfo = moreInfo;
        this.moreInfoMaster = moreInfoMaster;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.uuid = uuid;
        this.barcodes = barcodes;
    }
}

export function mapSimpleFandeck(data: Record<string, any>): Fandeck | null {
    try {
        return new Fandeck(data.id, data.name);
    } catch (e) {
        return null;
    }
}

export function mapFandeck(data: Record<string, any>): Fandeck | null {
    try {
        return new Fandeck(
            data.id,
            data.name,
            data.nameMaster,
            data.nameSystem,
            data.parentId,
            data.nameSource,
            data.searchName,
            data.searchNameMaster,
            data.searchNameSystem,
            data.searchNameSource,
            data.printName,
            data.printNameMaster,
            data.printNameSystem,
            data.printNameSource,
            data.tmcName,
            data.tmcNameMaster,
            data.tmcNameSystem,
            data.tmcNameSource,
            data.jsonAttributes && data.jsonAttributes.largoName ? data.jsonAttributes.largoName : null,
            data.code,
            data.codeMaster,
            data.codeSystem,
            data.codeSource,
            data.searchCode,
            data.searchCodeMaster,
            data.searchCodeSystem,
            data.searchCodeSource,
            data.priority,
            data.priorityMaster,
            data.prioritySystem,
            data.prioritySource,
            data.moreInfo,
            data.moreInfoMaster,
            data.moreInfoSystem,
            data.moreInfoSource,
            data.uuid,
            data.barcodes ? mapBarcodes(data.barcodes) : undefined
        );
    } catch (e) {
        return null;
    }
}

export function updateFandeck(oldFandeck: Fandeck | null, updatedData: Record<string, any>): Fandeck | null {
    try {
        if (oldFandeck === null) {
            return null;
        }

        const inheritedBarcodes =
            oldFandeck.barcodes?.filter((barcode) => barcode[propertyConstants.PROPERTY_INHERITED]) || [];
        const updatedBarcodes = inheritedBarcodes.concat(updatedData.barcodes || []);

        return new Fandeck(
            oldFandeck.id,
            updatedData.name ? updatedData.name : oldFandeck.name,
            oldFandeck.nameMaster,
            oldFandeck.nameSystem,
            oldFandeck.parentId,
            oldFandeck.nameSource,
            updatedData.searchName ? updatedData.searchName : oldFandeck.searchName,
            oldFandeck.searchNameMaster,
            oldFandeck.searchNameSystem,
            oldFandeck.searchNameSource,
            updatedData.printName ? updatedData.printName : oldFandeck.printName,
            oldFandeck.printNameMaster,
            oldFandeck.printNameSystem,
            oldFandeck.printNameSource,
            updatedData.tmcName ? updatedData.tmcName : oldFandeck.tmcName,
            oldFandeck.tmcNameMaster,
            oldFandeck.tmcNameSystem,
            oldFandeck.tmcNameSource,
            oldFandeck.largoName,
            updatedData.code ? updatedData.code : oldFandeck.code,
            oldFandeck.codeMaster,
            oldFandeck.codeSystem,
            oldFandeck.codeSource,
            updatedData.searchCode ? updatedData.searchCode : oldFandeck.searchCode,
            oldFandeck.searchCodeMaster,
            oldFandeck.searchCodeSystem,
            oldFandeck.searchCodeSource,
            updatedData.priority ? updatedData.priority : oldFandeck.priority,
            oldFandeck.priorityMaster,
            oldFandeck.prioritySystem,
            oldFandeck.prioritySource,
            updatedData.moreInfo ? updatedData.moreInfo : oldFandeck.moreInfo,
            oldFandeck.moreInfoMaster,
            oldFandeck.moreInfoSystem,
            oldFandeck.moreInfoSource,
            updatedData.uuid ? updatedData.uuid : oldFandeck.uuid,
            mapBarcodes(updatedBarcodes)
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyFandeck(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_NAME_MASTER]: "",
        [propertyConstants.PROPERTY_NAME_SYSTEM]: "",
        [propertyConstants.PROPERTY_PARENT_ID]: "",
        [propertyConstants.PROPERTY_SEARCH_NAME]: "",
        [propertyConstants.PROPERTY_PRINT_NAME]: "",
        [propertyConstants.PROPERTY_LARGO_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_SEARCH_CODE]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_BARCODES]: []
    };
}

export function generateFandeckData(list: Array<Fandeck>): Array<any> {
    const data: Array<any> = [];
    let item: Fandeck;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_NAME_MASTER]: item.nameMaster,
            [propertyConstants.PROPERTY_NAME_SYSTEM]: item.nameSystem,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_NAME_SOURCE]: item.nameSource,
            [propertyConstants.PROPERTY_SEARCH_NAME]: item.searchName,
            [propertyConstants.PROPERTY_SEARCH_NAME_MASTER]: item.searchNameMaster,
            [propertyConstants.PROPERTY_SEARCH_NAME_SYSTEM]: item.searchNameSystem,
            [propertyConstants.PROPERTY_SEARCH_NAME_SOURCE]: item.searchNameSource,
            [propertyConstants.PROPERTY_PRINT_NAME]: item.printName,
            [propertyConstants.PROPERTY_PRINT_NAME_MASTER]: item.printNameMaster,
            [propertyConstants.PROPERTY_PRINT_NAME_SYSTEM]: item.printNameSystem,
            [propertyConstants.PROPERTY_PRINT_NAME_SOURCE]: item.printNameSource,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_TMC_NAME_MASTER]: item.tmcNameMaster,
            [propertyConstants.PROPERTY_TMC_NAME_SYSTEM]: item.tmcNameSystem,
            [propertyConstants.PROPERTY_TMC_NAME_SOURCE]: item.tmcNameSource,
            [propertyConstants.PROPERTY_LARGO_NAME]: item.largoName,
            [propertyConstants.PROPERTY_CODE]: item.code,
            [propertyConstants.PROPERTY_CODE_MASTER]: item.codeMaster,
            [propertyConstants.PROPERTY_CODE_SYSTEM]: item.codeSystem,
            [propertyConstants.PROPERTY_CODE_SOURCE]: item.codeSource,
            [propertyConstants.PROPERTY_SEARCH_CODE]: item.searchCode,
            [propertyConstants.PROPERTY_SEARCH_CODE_MASTER]: item.searchCodeMaster,
            [propertyConstants.PROPERTY_SEARCH_CODE_SYSTEM]: item.searchCodeSystem,
            [propertyConstants.PROPERTY_SEARCH_CODE_SOURCE]: item.searchCodeSource,
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_PRIORITY_MASTER]: item.priorityMaster,
            [propertyConstants.PROPERTY_PRIORITY_SYSTEM]: item.prioritySystem,
            [propertyConstants.PROPERTY_PRIORITY_SOURCE]: item.prioritySource,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_MASTER]: item.moreInfoMaster,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_BARCODES]: item.barcodes
        });
    }

    return data;
}

export function generateFandeckHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BARCODES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODES),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BARCODES),
            editableList.includes(propertyConstants.PROPERTY_BARCODES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BARCODES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BARCODES),
            requiredList.includes(propertyConstants.PROPERTY_BARCODES),
            [],
            widthOption?.[propertyConstants.PROPERTY_BARCODES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LARGO_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            editableList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LARGO_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            requiredList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_LARGO_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_MASTER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            editableList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_MASTER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            requiredList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_MASTER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRINT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRINT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRINT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SEARCH_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SEARCH_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            editableList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            requiredList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_SEARCH_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SEARCH_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            editableList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SEARCH_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_TMC_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            editableList.includes(propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_TMC_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            requiredList.includes(propertyConstants.PROPERTY_TMC_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_TMC_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
