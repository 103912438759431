import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Unit, updateUnit } from "../types/unit";

export type UnitState = Readonly<{
    allList: Array<Unit>;
    list: Array<Unit>;
    activeList: Array<Unit>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: UnitState = {
    allList: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function units(state: UnitState = initialState, action: Record<string, any>): UnitState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.UNIT: {
                    return {
                        ...state,
                        allList: action.payload.list
                    };
                }
                default: {
                    return state;
                }
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.UNIT) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.UNIT: {
                    return {
                        ...state,
                        activeList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.UNIT) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.UNIT) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.UNIT: {
                        const updatedUnitList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_UNIT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateUnit
                        );

                        if (updatedUnitList.length) {
                            return {
                                ...state,
                                list: updatedUnitList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.UNIT: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
