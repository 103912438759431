import * as colorHelper from "../../../helpers/colorHelper";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as generalHelper from "../../../helpers/generalHelper";
import * as inputConstants from "../../../constants/inputConstants";
import * as modalHelper from "../../../helpers/modalHelper";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as priceHelper from "../../../helpers/priceHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationConstants from "../../../constants/translationConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";
import { Currency } from "../../../types/currency";
import { ModalButton } from "../inputs/ModalButton";
import { ModalLabelInputButtonContainer } from "./ModalLabelInputButton";
import { ModalSubHeading } from "../labels/ModalSubHeading";
import { Price } from "../../../types/price";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../types/tableHeading";

import imgLock from "../../../resources/img/icons/lock.svg";
import imgUnlock from "../../../resources/img/icons/lock-unlock.svg";

type Props = {
    modalId: number;
    objectType: objectTypes.ObjectType;
    heading: TableHeading;
    object: any;
    currency: Currency | null;
    callbackClick: (heading: TableHeading, property: propertyConstants.Property, editable: boolean) => any;
    callbackChange: (property: propertyConstants.Property, value: any) => any;
    callbackBlur: (property: propertyConstants.Property, value: any) => any;
    confirm: (property: propertyConstants.Property, id: string | number | null) => any;
    setUploadState?: (isUploading: boolean) => void;
};

export class ModalItemRow extends Component<Props> {
    getValue = (type: tableConstants.TableType, property: propertyConstants.Property): string => {
        // TODO navratova hodnota string? asi nemusi byt vzdycky? popr v returnu pridat object[property].toString() ale co boolean, pole?
        const { object } = this.props;

        if (type === tableConstants.TABLE_TYPE_PERCENT) {
            return formattingHelper.formatPercentWithoutSign(formattingHelper.formatPercent(object?.[property]));
        }

        if (type === tableConstants.TABLE_TYPE_PRICE) {
            const price = object[property];
            let value = price?.[propertyConstants.PROPERTY_VALUE] || null;

            if (propertyHelper.isPropertyVat(property)) {
                value = formattingHelper.formatPercentWithoutSign(formattingHelper.formatPercent(value));
            }

            return value ? value.toString() : "";
        }

        if (property === propertyConstants.PROPERTY_USER_GROUP_LIST) {
            const item =
                generalHelper.getItemFromListByHighestPropertyValue(
                    object?.[property] || [],
                    propertyConstants.PROPERTY_PRIORITY
                ) || null;
            return item?.[propertyConstants.PROPERTY_ID] ?? "";
        }

        return object && object[property] !== null && object[property] !== undefined ? object[property] : "";
    };

    getHeadingEnum = (): Array<any> => {
        const { heading, object, objectType } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const propertyEnum = heading[tableConstants.TABLE_HEADING_ENUM];

        if (property === propertyConstants.PROPERTY_BEGIN_VALUE) {
            return colorHelper.getWavelengthList(
                object[propertyConstants.PROPERTY_INTERVAL],
                optionsConstants.DEFAULT_WAVELENGTH_BEGIN_VALUE,
                optionsConstants.DEFAULT_MAXIMUM_WAVELENGTH_BEGIN_VALUE
            );
        }

        if (property === propertyConstants.PROPERTY_END_VALUE) {
            return colorHelper.getWavelengthList(
                object[propertyConstants.PROPERTY_INTERVAL],
                optionsConstants.DEFAULT_MINIMUM_WAVELENGTH_END_VALUE,
                optionsConstants.DEFAULT_WAVELENGTH_END_VALUE
            );
        }

        if (objectType === objectTypes.EXPORT_TASK && property === propertyConstants.PROPERTY_ZONE_ID) {
            return propertyEnum.filter(
                (item) => item[propertyConstants.PROPERTY_SYSTEM_ID] === object[propertyConstants.PROPERTY_SYSTEM_ID]
            );
        }

        if (
            (objectType === objectTypes.EXPORT_TASK_DELETE || objectType === objectTypes.IMPORT_TASK_DELETE) &&
            property === propertyConstants.PROPERTY_SYSTEM_ID
        ) {
            return propertyEnum.filter(
                (item) =>
                    item[propertyConstants.PROPERTY_DATABASE_ID] === object[propertyConstants.PROPERTY_DATABASE_ID]
            );
        }

        if (
            (objectType === objectTypes.EXPORT_TASK_DELETE || objectType === objectTypes.IMPORT_TASK_DELETE) &&
            property === propertyConstants.PROPERTY_ZONE_ID
        ) {
            return propertyEnum.filter(
                (item) =>
                    item[propertyConstants.PROPERTY_DATABASE_ID] === object[propertyConstants.PROPERTY_DATABASE_ID] &&
                    item[propertyConstants.PROPERTY_SYSTEM_ID] === object[propertyConstants.PROPERTY_SYSTEM_ID]
            );
        }

        if (objectType === objectTypes.TRANSLATION && property === propertyConstants.PROPERTY_FILE_TYPE) {
            if (
                object?.[propertyConstants.PROPERTY_ACTION_TYPE] === translationConstants.TRANSLATION_ACTION_TYPE_IMPORT
            ) {
                return propertyEnum.filter(
                    (item) => item[propertyConstants.PROPERTY_ID] === translationConstants.TRANSLATION_FILE_TYPE_CSV
                );
            }
        }

        return propertyEnum;
    };

    getStringInputType = (property: propertyConstants.Property): inputConstants.InputType => {
        if (propertyHelper.isPropertyPassword(property)) {
            return inputConstants.PARAMETER_TYPE_PASSWORD;
        }

        return inputConstants.PARAMETER_TYPE_TEXT_INPUT;
    };

    getStringClassName = (objectType: objectTypes.ObjectType, property: propertyConstants.Property): string => {
        return (propertyHelper.isPropertyPassword(property) &&
            objectTypes.VALIDATE_PASSWORD_TYPES.includes(objectType)) ||
            (property === propertyConstants.PROPERTY_VERSION && objectType === objectTypes.EXPORT_TASK)
            ? "input-button-container"
            : "";
    };

    getStringInputClassName = (property: propertyConstants.Property): string => {
        let className = "type-input";

        if (propertyHelper.isPropertyPassword(property)) {
            className += " password-input";
        }

        return className;
    };

    isEditable = (
        isEditable: boolean,
        type: tableConstants.TableType,
        property: propertyConstants.Property,
        object: any
    ) => {
        switch (type) {
            case tableConstants.TABLE_TYPE_BUTTON: {
                if (property === propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION) {
                    return (
                        isEditable &&
                        object &&
                        object[propertyConstants.PROPERTY_DATE] &&
                        object[propertyConstants.PROPERTY_COMPUTER_ID]
                    );
                }
                if (property === propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION) {
                    return isEditable && object && object[propertyConstants.PROPERTY_PASSWORD];
                }

                return isEditable;
            }
            default:
                return isEditable;
        }
    };

    getItemRow = (): JSX.Element | null => {
        const {
            modalId,
            objectType,
            object,
            heading,
            currency,
            callbackClick,
            callbackChange,
            callbackBlur,
            confirm,
            setUploadState
        } = this.props;
        const type = heading[tableConstants.TABLE_HEADING_TYPE];
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const editable = this.isEditable(heading[tableConstants.TABLE_HEADING_EDITING], type, property, object);
        const required = heading[tableConstants.TABLE_HEADING_REQUIRED];
        const labelCaption = heading[tableConstants.TABLE_HEADING_TRANSLATION];
        const value = this.getValue(type, property);
        const inputCaption = translationHelper.getInputCaptionTranslation(type, property);

        switch (type) {
            case tableConstants.TABLE_TYPE_BOOLEAN: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={inputCaption ? "checkbox-with-title" : "input-type-bool"}
                        inputType={inputConstants.PARAMETER_TYPE_CHECK_BOX}
                        inputName={property}
                        inputValue={value}
                        inputCaption={inputCaption}
                        inputCallbackChange={(value: boolean): void => {
                            callbackChange(property, value);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_BUTTON: {
                return (
                    <ModalButton
                        className={"input-button-container"}
                        editable={editable}
                        buttonTitle={inputCaption}
                        buttonClassName={"btn-info button-input-section"}
                        callbackButton={(): void => callbackClick(heading, property, editable)}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_BUTTON_SECTION: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={"width-auto"}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={"input-button-container"}
                        inputType={inputConstants.PARAMETER_TYPE_BUTTON_SECTION}
                        inputName={property}
                        inputCaption={inputCaption}
                        buttonClassName=" btn-info button-input-section"
                        buttonCallback={(): void => {
                            callbackClick(heading, property, editable);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_DATE:
                return (
                    <ModalLabelInputButtonContainer
                        modalId={this.props.modalId}
                        type={type}
                        objectType={this.props.objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={"type-input"}
                        inputType={inputConstants.PARAMETER_TYPE_DATE_PICKER}
                        inputName={property}
                        inputValue={value}
                        inputCallbackChange={(value: string | null) => callbackChange(property, value)}
                    />
                );
            case tableConstants.TABLE_TYPE_ADDITIONAL_LABEL:
            case tableConstants.TABLE_TYPE_LABEL: {
                return (
                    <ModalSubHeading
                        className={
                            tableConstants.TABLE_TYPE_ADDITIONAL_LABEL === type ? "info-description" : "form-subheading"
                        }
                        objectType={objectType}
                        labelCaption={labelCaption}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_INTEGER:
            case tableConstants.TABLE_TYPE_NUMBER: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName="type-input"
                        inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                        inputName={property}
                        inputValue={value}
                        inputUnit={
                            property === propertyConstants.PROPERTY_FILL_VOLUME_ML
                                ? optionsConstants.DEFAULT_FORMULA_COLORANT_UNIT
                                : ""
                        }
                        inputCallbackChange={(value: string): void => {
                            callbackChange(property, value);
                        }}
                        inputCallbackBlur={(value: string): void => {
                            callbackBlur(property, value);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_STRING: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={this.getStringClassName(objectType, property)}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={this.getStringInputClassName(property)}
                        inputType={this.getStringInputType(property)}
                        inputName={property}
                        inputValue={value}
                        inputCallbackChange={(value: string): void => {
                            callbackChange(property, value);
                        }}
                        inputCallbackBlur={(value: any): void => {
                            callbackBlur(property, value);
                        }}
                        inputCallbackKeyDown={(): void => {
                            return;
                        }}
                        buttonClassName={modalHelper.getStringButtonClassName(objectType, property)}
                        buttonVisible={modalHelper.getStringButtonVisibility(objectType, property)}
                        buttonEditable={modalHelper.getButtonEditing(property, object)}
                        buttonCaption={modalHelper.getButtonCaption(objectType, property)}
                        buttonCallback={(): void => {
                            callbackClick(heading, property, editable);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_FILE: {
                const fileNote = modalHelper.getFileNote(objectType);

                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={fileNote ? "input-note" : ""}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName="files-input"
                        inputType={inputConstants.UPLOAD_TYPE_FILE}
                        inputName={property}
                        inputNote={fileNote}
                        fileValidation={modalHelper.getFileValidation(objectType)}
                        fileCallback={(property: propertyConstants.Property, files: any): void => {
                            callbackChange(property, files);
                        }}
                        object={{ ...object }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_ICON: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={"input-type-icon"}
                        inputType={inputConstants.PARAMETER_TYPE_ICON}
                        inputName={property}
                        inputValue={value}
                        inputCaption={inputCaption}
                        inputCallbackChange={(value: boolean): void => {
                            callbackChange(property, value);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_IMAGE:
            case tableConstants.TABLE_TYPE_IMAGE_SECTION: {
                if (
                    objectTypeHelper.isObjectTypeImage(objectType) &&
                    object?.[propertyConstants.PROPERTY_IMAGE_ID] === null
                ) {
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={modalId}
                            type={type}
                            objectType={objectType}
                            editable={editable}
                            required={required}
                            labelCaption={labelCaption}
                            inputClassName="files-input"
                            inputType={modalHelper.getUploadImageInputType(objectType)}
                            inputName={property}
                            fileValidation={modalHelper.getFileValidation(objectType)}
                            fileValidTypes={modalHelper.getFileValidTypes(objectType)}
                            fileCallback={(
                                property: propertyConstants.Property,
                                id: string | number | null,
                                src?: string,
                                name?: string
                            ): void => {
                                callbackChange(property, id);
                                if (src && name) {
                                    callbackChange(propertyConstants.PROPERTY_SRC, { src, name });
                                }
                            }}
                            object={{ ...object }}
                            confirm={(id: string | number | null) => confirm(property, id)}
                            setUploadState={(isUploading: boolean) =>
                                setUploadState
                                    ? setUploadState(isUploading)
                                    : () => {
                                          return;
                                      }
                            }
                        />
                    );
                } else {
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={modalId}
                            type={type}
                            objectType={objectType}
                            editable={editable}
                            required={required}
                            labelCaption={labelCaption}
                            inputClassName="files-input"
                            inputType={inputConstants.UPDATE_TYPE_IMAGE}
                            inputName={property}
                            fileId={object?.[property] || null}
                            fileValidation={modalHelper.getFileValidation(objectType)}
                            fileValidTypes={modalHelper.getFileValidTypes(objectType)}
                            fileCallback={(
                                property: propertyConstants.Property,
                                id: string | number | null,
                                src?: string,
                                name?: string
                            ): void => {
                                callbackChange(property, id);
                                if (src && name) {
                                    callbackChange(propertyConstants.PROPERTY_SRC, { src, name });
                                }
                            }}
                            object={{ ...object }}
                            confirm={(id: string | number | null) => confirm(property, id)}
                            setUploadState={(isUploading: boolean) =>
                                setUploadState
                                    ? setUploadState(isUploading)
                                    : () => {
                                          return;
                                      }
                            }
                        />
                    );
                }
            }
            case tableConstants.TABLE_TYPE_PERCENT: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName="type-input"
                        inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                        inputName={property}
                        inputValue={value}
                        inputUnit={"%"}
                        inputCallbackChange={(value: string): void => {
                            callbackChange(property, value);
                        }}
                        inputCallbackBlur={(value: string): void => {
                            callbackBlur(property, value);
                        }}
                    />
                );
            }
            // TODO check PRICE
            case tableConstants.TABLE_TYPE_PRICE: {
                const inputList = [];
                const buttonIcon = object?.[property]?.[propertyConstants.PROPERTY_OVERWRITE] ? imgUnlock : imgLock;
                const secondProperty = propertyHelper.getPriceUnitProperty(property);
                const priceClassName = object?.[property]?.[propertyConstants.PROPERTY_OVERWRITE]
                    ? "price-input"
                    : "price-input lock";
                let unitInput = null;

                const totalInput = {
                    inputClassName: "type-input",
                    inputType: inputConstants.PARAMETER_TYPE_CONVERSION_INPUTS,
                    inputName: property,
                    inputValue: value,
                    inputUnit: priceHelper.getPriceUnit(
                        object?.[property]?.[propertyConstants.PROPERTY_KEY] || "",
                        currency?.[propertyConstants.PROPERTY_SYMBOL] || ""
                    ),
                    inputCallbackChange: (value: Price): void => {
                        callbackChange(property, value);
                    },
                    inputCallbackBlur: (value: Price): void => {
                        callbackBlur(property, value);
                    }
                };

                if (secondProperty) {
                    const secondValue = this.getValue(type, secondProperty);

                    unitInput = {
                        inputClassName: "type-input",
                        inputType: inputConstants.PARAMETER_TYPE_CONVERSION_INPUTS,
                        inputName: secondProperty,
                        inputValue: secondValue,
                        inputUnit: priceHelper.getPriceUnit(
                            object?.[secondProperty]?.[propertyConstants.PROPERTY_KEY] || "",
                            currency?.[propertyConstants.PROPERTY_SYMBOL] || ""
                        ),
                        inputCallbackChange: (value: Price): void => {
                            callbackChange(secondProperty, value);
                        },
                        inputCallbackBlur: (value: Price): void => {
                            callbackBlur(secondProperty, value);
                        }
                    };

                    inputList.push(unitInput);
                }

                inputList.push(totalInput);

                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={priceClassName}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName={"type-input units-input"}
                        inputType={inputConstants.PARAMETER_TYPE_CONVERSION_INPUTS}
                        inputName={property}
                        inputs={inputList}
                        buttonClassName={`btn btn-info input-btn${value === "" ? " disabled" : ""}`}
                        buttonEditable={value !== ""}
                        buttonVisible={true}
                        buttonCaption={<ReactSVG src={buttonIcon} className="svg-icon" />}
                        buttonCallback={(): void => {
                            const priceObject = object?.[property] || null;

                            if (priceObject) {
                                const newValue = !object?.[property]?.[propertyConstants.PROPERTY_OVERWRITE] || false;

                                callbackBlur(property, {
                                    ...priceObject,
                                    [propertyConstants.PROPERTY_OVERWRITE]: newValue
                                });
                            }
                        }}
                        currency={this.props.currency}
                        object={{ ...object }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_SELECT: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={
                            propertyConstants.SELECT_INPUT_BUTTON_PROPERTIES.includes(property)
                                ? "input-button-container"
                                : ""
                        }
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputAutoselect={
                            (objectType === objectTypes.EXPORT_TASK &&
                                (property === propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID ||
                                    property === propertyConstants.PROPERTY_SYSTEM_ID ||
                                    property === propertyConstants.PROPERTY_ZONE_ID)) ||
                            objectType === objectTypes.MASTER_COLORANT_BATCH
                        }
                        inputClassName={
                            propertyConstants.SELECT_INPUT_BUTTON_PROPERTIES.includes(property)
                                ? "button-select button-small"
                                : "modal-select"
                        }
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputName={property}
                        inputValue={value}
                        inputCaption={inputCaption}
                        inputOptions={generalHelper.getOptionsForSelectbox(objectType, this.getHeadingEnum(), property)}
                        inputCallbackChange={(value: string): void => {
                            const id = !isNaN(Number(value)) && value !== "" ? Number(value) : value;

                            if (property === propertyConstants.PROPERTY_USER_GROUP_LIST) {
                                const item =
                                    this.getHeadingEnum().find(
                                        (element) => element[propertyConstants.PROPERTY_ID] === id
                                    ) || null;
                                const items =
                                    this.getHeadingEnum().filter(
                                        (element) =>
                                            element[propertyConstants.PROPERTY_PRIORITY] <=
                                            item?.[propertyConstants.PROPERTY_PRIORITY]
                                    ) || [];
                                callbackChange(property, items);
                            } else {
                                callbackChange(property, id);
                            }
                        }}
                        buttonClassName="button-input"
                        buttonEditable={modalHelper.getButtonEditing(property, object)}
                        buttonVisible={modalHelper.getButtonVisibility(property)}
                        buttonCaption={modalHelper.getButtonCaption(objectType, property)}
                        buttonCallback={(): void => {
                            callbackClick(heading, property, editable);
                        }}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_TEXT: {
                return (
                    <ModalLabelInputButtonContainer
                        modalId={modalId}
                        className={property === propertyConstants.PROPERTY_UNIVERSAL_VALUE ? "resizing-input" : ""}
                        type={type}
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        labelCaption={labelCaption}
                        inputClassName="type-input"
                        inputType={inputConstants.PARAMETER_TYPE_TEXT_AREA}
                        inputName={property}
                        inputValue={value}
                        inputCallbackChange={(value: string): void => {
                            callbackChange(property, value);
                        }}
                        inputCallbackBlur={(value: string): void => {
                            callbackBlur(property, value);
                        }}
                    />
                );
            }
            default:
                return null;
        }
    };

    render(): JSX.Element | null {
        // TODO this should be already in SOM?
        if (this.props.object === null) {
            return null;
        }

        return this.getItemRow();
    }
}
