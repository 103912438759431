// data scenes
export const USER_GROUP_PRIVILEGE_DATA_DATABASE = "dataDatabases";
export const USER_GROUP_PRIVILEGE_DATA_SYSTEM_ZONE = "dataSystemZone";
export const USER_GROUP_PRIVILEGE_DATA_IMPORT = "dataImport";
export const USER_GROUP_PRIVILEGE_DATA_EXPORT = "dataExport";
// headers + header scenes
export const USER_GROUP_PRIVILEGE_HEADER_COMPANY = "headerCompany";
export const USER_GROUP_PRIVILEGE_HEADER_MASTER = "headerMaster";
export const USER_GROUP_PRIVILEGE_HEADER_SYSTEM = "headerSystem";
export const USER_GROUP_PRIVILEGE_HEADER_USER = "headerUser";
export const USER_GROUP_PRIVILEGE_HEADER_ZONE = "headerZone";
// generic scenes
export const USER_GROUP_PRIVILEGE_GENERIC_CURRENCIES = "genericCurrencies";
export const USER_GROUP_PRIVILEGE_GENERIC_IMAGES = "genericImages";
export const USER_GROUP_PRIVILEGE_GENERIC_PACKAGES = "genericPackages";
export const USER_GROUP_PRIVILEGE_GENERIC_PRODUCT_FILES = "genericProductFiles";
export const USER_GROUP_PRIVILEGE_GENERIC_STATIC_SQL = "genericStaticSql";
export const USER_GROUP_PRIVILEGE_GENERIC_UNITS = "genericUnits";
// others
export const USER_GROUP_PRIVILEGE_IMPORT_EXPORT_DELETE = "taskDelete";
export const USER_GROUP_PRIVILEGE_IMPORT_EXPORT_HIDE = "taskHide";
export const USER_GROUP_PRIVILEGE_UUID_ZONE = "uuidZone";
export const USER_GROUP_PRIVILEGE_UUID_MASTER_SYSTEM = "uuidMasterSystem";
