import * as defaultConstants from "../../constants/default";
import * as generalHelper from "../../helpers/generalHelper";
import * as licenseActions from "../../actions/licenseActions";
import * as licenseConstants from "../../constants/license";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as notificationActions from "../../actions/notificationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { License, mapLicense, updateLicense } from "../../types/licensing/license";
import { LicenseProduct, mapLicenseProduct, updateLicenseProduct } from "../../types/licensing/licenseProduct";

import { AppState } from "../../reducers";
import { FlashMessage } from "../../types/flashMessage";
import { ServerResponse } from "../../types/serverResponse";
import { t as translate } from "react-i18nify";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_LICENSE_STATUS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const licenseList: Array<License> = [];
            const license = mapLicense(response.data);
            const requests: Array<any> = [];

            if (license) {
                licenseList.push(license);
            }

            if (licenseList[0][propertyConstants.PROPERTY_STATUS] !== licenseConstants.STATUS_ERROR) {
                requests.push(licenseActions.licenseSuccess());
                requests.push(licenseActions.getLicenseUuid());
                requests.push(licenseActions.getLicenseInfo());
            }

            requests.push(universalObjectActions.saveItems(objectTypes.LICENSE, licenseList));

            return requests;
        }
        case methods.METHOD_ACTIVATE_ONLINE_LICENSE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [licenseActions.getLicenseStatus()];
        }
        case methods.METHOD_GET_LICENSE_ONLINE_ACTIVATION_AVAILABILITY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            if (!response.data) {
                return [
                    notificationActions.saveFlashMessage(
                        new FlashMessage(
                            defaultConstants.FLASH_MESSAGE_ERROR,
                            translate("flashMessage.licenseActivationIsNotPossible")
                        )
                    ),
                    licenseActions.setLicenseOnlineActivation(response.data)
                ];
            }

            return [
                licenseActions.setLicenseOnlineActivation(response.data),
                licenseActions.setStep(licenseConstants.LICENSE_FIRST_PROCESS_STEP)
            ];
        }
        case methods.METHOD_GET_LICENSE_AVAILABLE_PRODUCTS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let licenseProduct: LicenseProduct | null = null;
            let licenseKey = "";
            const licenseProductList: Array<LicenseProduct> = [];

            for (const id in response.data) {
                licenseProduct = mapLicenseProduct(Number(id), response.data);

                if (licenseProduct) {
                    licenseProductList.push(licenseProduct);
                }
            }

            if (state.license.activeLicense) {
                licenseKey =
                    state.license.activeLicense[propertyConstants.PROPERTY_COMPANY_ID] +
                    "-" +
                    state.license.activeLicense[propertyConstants.PROPERTY_USER_ID];
            }

            return [
                universalObjectActions.saveItems(objectTypes.LICENSE_PRODUCT, licenseProductList),
                licenseActions.getLicenseOnlineCounts(licenseKey)
            ];
        }
        case methods.METHOD_GET_LICENSE_ONLINE_COUNTS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let licenseProduct: LicenseProduct | null = null;
            const licenseProductList: Array<LicenseProduct> = [];

            for (const id in response.data) {
                const oldLicenseProduct = generalHelper.getObjectById(state.license.productList, Number(id));

                if (oldLicenseProduct) {
                    licenseProduct = updateLicenseProduct(oldLicenseProduct, response.data[id]);
                }

                if (licenseProduct) {
                    licenseProductList.push(licenseProduct);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.LICENSE_PRODUCT, licenseProductList),
                licenseActions.setStep(licenseConstants.LICENSE_SECOND_PROCESS_STEP)
            ];
        }
        case methods.METHOD_GET_LICENSE_UUID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let license: License | null = null;
            const licenseList: Array<License> = [];

            if (state.license.activeLicense) {
                license = updateLicense(state.license.activeLicense, {
                    [propertyConstants.PROPERTY_UUID]: response.data
                });
            }

            if (license) {
                licenseList.push(license);
            }

            return [universalObjectActions.saveItems(objectTypes.LICENSE, licenseList)];
        }
        case methods.METHOD_GET_LICENSE_INFO: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let license: License | null = null;
            const licenseList: Array<License> = [];

            if (state.license.activeLicense) {
                license = updateLicense(state.license.activeLicense, response.data);
            }

            if (license) {
                licenseList.push(license);
            }

            return [universalObjectActions.saveItems(objectTypes.LICENSE, licenseList)];
        }
        case methods.METHOD_UPDATE_ONLINE_LICENSE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const flashMessage = messageHelper.getUpdateLicenseMessage(response);
            if (flashMessage) {
                requests.push(flashMessage);

                if (response.data === licenseConstants.LICENSE_MESSAGE_UPDATED) {
                    requests.push(licenseActions.getLicenseStatus());
                    requests.push(universalObjectActions.getAllItems(objectTypes.EXPORT_TYPE));
                    requests.push(universalObjectActions.getAllItems(objectTypes.IMPORT_TYPE));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_LICENSE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                licenseActions.getLicenseStatus(),
                navigationActions.navigationCloseModal(modalTypes.MODAL_LICENSE)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
