import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { MasterRedlikePrivilegeScene } from "../../../components/scenes/master/MasterRedlikePrivilegeScene";
import { MenuItem } from "../../../types/menu";
import { Privilege } from "../../../types/privilege";
import { ServerRequest } from "../../../types/serverRequest";
import { UserGroup } from "../../../types/userGroup";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    custom: string | null;
    userGroupTableConstant: string;
    userGroupList: Array<UserGroup>;
    userGroupActiveList: Array<UserGroup>;
    userGroupLoading: boolean;
    userGroupButtonLoading: boolean;
    userGroupColumnOrder: Array<propertyConstants.Property>;
    userGroupColumnVisibility: Record<propertyConstants.Property, boolean>;
    userGroupColumnWidth: Record<propertyConstants.Property, number>;
    userGroupSearch: string | null;
    userGroupSearchParams: Record<propertyConstants.Property, string>;
    userGroupSortingAsc: boolean;
    userGroupSortingCriterion: propertyConstants.Property | null;
    userGroupTotalCount: number | null;
    privilegeTableConstant: string;
    privilegeList: Array<Privilege>;
    privilegeActiveList: Array<Privilege>;
    privilegeLoading: boolean;
    privilegeButtonLoading: boolean;
    privilegeColumnOrder: Array<propertyConstants.Property>;
    privilegeColumnVisibility: Record<propertyConstants.Property, boolean>;
    privilegeColumnWidth: Record<propertyConstants.Property, number>;
    privilegeOffset: number;
    privilegePage: number;
    privilegeRowCount: number;
    privilegeSceneTableSize: Record<string, any>;
    privilegeRowCountCustom: boolean;
    privilegeSearch: string | null;
    privilegeSearchParams: Record<propertyConstants.Property, string>;
    privilegeShowOnlyNonDefaultValues: boolean;
    privilegeShowGlobalSearch: boolean;
    privilegeSortingAsc: boolean;
    privilegeSortingCriterion: propertyConstants.Property | null;
    privilegeTotalCount: number | null;
    sceneLayout: string;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES
    ),
    custom: state.software.custom,
    userGroupTableConstant: menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS,
    userGroupList: state.userGroup.masterList,
    userGroupActiveList: state.userGroup.masterActiveList,
    userGroupLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS
    ),
    userGroupButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_REDLIKE_USER_GROUP
    ),
    userGroupColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
    ),
    userGroupColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
    ),
    userGroupColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
    ),
    userGroupSearch: state.userGroup.masterSearch,
    userGroupSearchParams: state.userGroup.masterSearchParams,
    userGroupSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
        )
    ),
    userGroupSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS
        )
    ),
    userGroupTotalCount: state.userGroup.masterCount,
    privilegeTableConstant: menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES,
    privilegeList: state.privileges.list,
    privilegeActiveList: state.privileges.activeList,
    privilegeLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP
    ),
    privilegeButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_SET_REDLIKE_PRIVILEGE
    ),
    privilegeColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeOffset: state.privileges.offset,
    privilegePage: state.privileges.page,
    privilegeRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeSearch: state.privileges.search,
    privilegeSearchParams: state.privileges.searchParams,
    privilegeShowOnlyNonDefaultValues: state.privileges.changedOnly,
    privilegeShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
    ),
    privilegeSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
        )
    ),
    privilegeSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES
        )
    ),
    privilegeTotalCount: state.privileges.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES
    )
});

export const MasterRedlikePrivilegeSceneContainer = connect(mapStateToProps)(MasterRedlikePrivilegeScene);
