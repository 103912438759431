import * as objectTypeHelper from "./objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "./optionHelper";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as priceHelper from "../helpers/priceHelper";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { ExportTypeOptions } from "../types/export/exportTypeOptions";

/**
 * if we call this from TableHeadingRow privileges are {} and for price options there are default values, because we do not want these settings to change column width
 * otherwise this is called from TableOptions and privileges and price options are send correctly
 */
export const isPropertyValidForObjectType = (
    property: propertyConstants.Property,
    objectType: objectTypes.ObjectType,
    privileges: Record<string, number>,
    systemOptionPriceDefinition: string | null,
    systemOptionPriceEnableCompanyCost: boolean,
    systemOptionPriceEnablePriceGroups: boolean,
    zoneOptionPriceDefinition: string | null,
    zoneOptionPriceEnableCompanyCost: boolean,
    zoneOptionPriceEnablePriceGroups: boolean,
    exportTaskTypeOptions: ExportTypeOptions | null = null
): boolean => {
    const isMasterObjectType = objectTypes.MASTER_OBJECT_TYPES.includes(objectType);
    const isSystemObjectType = objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType);

    if (isMasterObjectType && isSpecificMasterProperty(property)) {
        return false;
    }

    if ((isMasterObjectType || isSystemObjectType) && isSpecificSystemProperty(property)) {
        return false;
    }

    if (
        propertyConstants.PRICE_PROPERTIES.includes(property) &&
        !priceHelper.isPricePropertyValidForObjectType(
            property,
            objectType,
            systemOptionPriceDefinition,
            systemOptionPriceEnableCompanyCost,
            systemOptionPriceEnablePriceGroups,
            zoneOptionPriceDefinition,
            zoneOptionPriceEnableCompanyCost,
            zoneOptionPriceEnablePriceGroups
        )
    ) {
        return false;
    }

    const priceProperties = [
        propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM,
        propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
        propertyConstants.PROPERTY_PRICE_COST_PER_UNIT,
        propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
        propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
        propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT,
        propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM,
        propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
        propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT
    ];
    if (
        (objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
            objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE) &&
        priceProperties.includes(property)
    ) {
        return false;
    }

    const packageProperties = [
        propertyConstants.PROPERTY_IS_GRAVIMETRIC,
        propertyConstants.PROPERTY_MORE_INFO,
        propertyConstants.PROPERTY_NOMINAL_AMOUNT,
        propertyConstants.PROPERTY_TMC_NAME,
        propertyConstants.PROPERTY_UNIT_ID
    ];
    if (
        (objectType === objectTypes.SYSTEM_PACKAGE || objectType === objectTypes.ZONE_PACKAGE) &&
        packageProperties.includes(property)
    ) {
        return false;
    }

    // Export task type options in Bulk modal
    for (const [optionKey, optionValue] of Object.entries(exportTaskTypeOptions ?? {})) {
        if (optionKey === property) {
            return optionValue;
        }
    }

    if (objectType === objectTypes.PACKAGE && property === propertyConstants.PROPERTY_PRICE_SURCHARGE) {
        return false;
    }

    if (objectType === objectTypes.EXPORT_TASK && property === propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS) {
        return false;
    }
    if (
        objectType === objectTypes.ZONE &&
        (property === propertyConstants.PROPERTY_PASSWORD_HASH || property === propertyConstants.PROPERTY_IS_DUPLICATED)
    ) {
        return false;
    }

    if (
        objectType !== objectTypes.BARCODE &&
        (property === propertyConstants.PROPERTY_SYSTEM_ID || property === propertyConstants.PROPERTY_ZONE_ID)
    ) {
        return false;
    }

    if (
        objectType === objectTypes.USER_ACTION_HISTORY &&
        (property === propertyConstants.PROPERTY_DATABASE_NAME ||
            property === propertyConstants.PROPERTY_SYSTEM_NAME ||
            property === propertyConstants.PROPERTY_ZONE_NAME)
    ) {
        return false;
    }

    if (
        objectTypeHelper.isObjectTypeRedlikeUser(objectType) &&
        (property === propertyConstants.PROPERTY_PASSWORD || property === propertyConstants.PROPERTY_COMPANY_ID)
    ) {
        return false;
    }

    const fileProperties = [
        propertyConstants.PROPERTY_ACTION,
        propertyConstants.PROPERTY_DATE_UPLOADED,
        propertyConstants.PROPERTY_MORE_INFO
    ];
    if (objectType === objectTypes.PRODUCT_SHEET && fileProperties.includes(property)) {
        return false;
    }

    const staticSqlProperties = [
        propertyConstants.PROPERTY_ACTION,
        propertyConstants.PROPERTY_DATE_UPLOADED,
        propertyConstants.PROPERTY_FILE_CATEGORY_ID,
        propertyConstants.PROPERTY_FILE_TYPE_ID,
        propertyConstants.PROPERTY_MORE_INFO
    ];
    if (objectType === objectTypes.STATIC_SQL && staticSqlProperties.includes(property)) {
        return false;
    }

    const colorProperties = [
        propertyConstants.PROPERTY_BARCODES,
        propertyConstants.PROPERTY_COLOR_BLUE,
        propertyConstants.PROPERTY_COLOR_GREEN,
        propertyConstants.PROPERTY_COLOR_MORE_INFO,
        propertyConstants.PROPERTY_COLOR_PRIORITY,
        propertyConstants.PROPERTY_COLOR_RED,
        propertyConstants.PROPERTY_COLOR_RGB_HEX,
        propertyConstants.PROPERTY_COLOR_SEARCH_CODE,
        propertyConstants.PROPERTY_COLOR_SEARCH_NAME,
        propertyConstants.PROPERTY_IMAGE_ID,
        propertyConstants.PROPERTY_PRIORITY,
        propertyConstants.PROPERTY_TMC_NAME
    ];
    if (objectTypes.COLOR_TYPES_FOR_PROPERTY.includes(objectType) && colorProperties.includes(property)) {
        return false;
    }

    switch (property) {
        case propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES:
        case propertyConstants.PROPERTY_BASE_ID:
        case propertyConstants.PROPERTY_COLOR_DATA:
        case propertyConstants.PROPERTY_COLORANT_ID:
        case propertyConstants.PROPERTY_COLORANT_NAME:
        case propertyConstants.PROPERTY_COLORANT_PACKAGE_ID:
        case propertyConstants.PROPERTY_DATA:
        case propertyConstants.PROPERTY_DATA_TYPE_LIST:
        case propertyConstants.PROPERTY_DATABASE_FILE_PATH:
        case propertyConstants.PROPERTY_DATABASE_ID:
        case propertyConstants.PROPERTY_DEFAULT_DATA:
        case propertyConstants.PROPERTY_DIVIDING_LINE:
        case propertyConstants.PROPERTY_ERROR_CODE:
        case propertyConstants.PROPERTY_ERROR_TEXT:
        case propertyConstants.PROPERTY_FANDECK_NAME:
        case propertyConstants.PROPERTY_FANDECK_PAGE:
        case propertyConstants.PROPERTY_FANDECK_POSITION:
        case propertyConstants.PROPERTY_FILE:
        case propertyConstants.PROPERTY_ID:
        case propertyConstants.PROPERTY_IS_CHECKED:
        case propertyConstants.PROPERTY_LARGO_NAME:
        case propertyConstants.PROPERTY_MASTER_ONLY:
        case propertyConstants.PROPERTY_MIME_TYPE:
        case propertyConstants.PROPERTY_PACKAGE_NAME:
        case propertyConstants.PROPERTY_PATH:
        case propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST:
        case propertyConstants.PROPERTY_PRICE_GROUP_LIST:
        case propertyConstants.PROPERTY_PRODUCT_ID:
        case propertyConstants.PROPERTY_PRODUCT_NAME:
        case propertyConstants.PROPERTY_PROGRESS:
        case propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES:
        case propertyConstants.PROPERTY_USER_GROUP_LIST:
            return false;
        case propertyConstants.PROPERTY_AUTOLOGIN: {
            if (objectType === objectTypes.USER) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_BASE_NAME: {
            if (objectType === objectTypes.BARCODE) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_COLOR_NAME: {
            if (objectType === objectTypes.BARCODE) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_COMPANY_KEY: {
            if (objectType === objectTypes.EXPORT_TASK) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_FORMULAS_STATUS: {
            if (Object.keys(privileges).length && objectTypes.COLOR_TYPES.includes(objectType)) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_NAME_SYSTEM: {
            if (objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_PASSWORD: {
            if (
                objectType === objectTypes.EXPORT_TASK ||
                objectType === objectTypes.USER ||
                objectType === objectTypes.DATABASE_MONITORING
            ) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_RANGE: {
            if (objectType === objectTypes.SYSTEM_PRICE_GENERIC || objectType === objectTypes.ZONE_PRICE_GENERIC) {
                return false;
            }
            break;
        }
        case propertyConstants.PROPERTY_UUID: {
            if (
                objectTypes.COLOR_TYPES_FOR_PROPERTY.includes(objectType) ||
                objectType === objectTypes.DATABASE_MONITORING
            ) {
                return false;
            }
            if (
                objectType === objectTypes.DATABASE &&
                Object.keys(privileges).length &&
                !optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_VIEW])
            ) {
                return false;
            }
            if (objectType === objectTypes.EXPORT_TASK) {
                return false;
            }
            if (
                objectType === objectTypes.SYSTEM &&
                Object.keys(privileges).length &&
                !optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_VIEW])
            ) {
                return false;
            }
            break;
        }
    }

    return true;
};

export const getPropertySource = (property: propertyConstants.Property): propertyConstants.Property | null => {
    switch (property) {
        case propertyConstants.PROPERTY_BFS:
            return propertyConstants.PROPERTY_BFS_SOURCE;
        case propertyConstants.PROPERTY_CODE:
            return propertyConstants.PROPERTY_CODE_SOURCE;
        case propertyConstants.PROPERTY_COEFFICIENT:
            return propertyConstants.PROPERTY_COEFFICIENT_SOURCE;
        case propertyConstants.PROPERTY_COLOR_BLUE:
            return propertyConstants.PROPERTY_COLOR_BLUE_SOURCE;
        case propertyConstants.PROPERTY_COLOR_GREEN:
            return propertyConstants.PROPERTY_COLOR_GREEN_SOURCE;
        case propertyConstants.PROPERTY_COLOR_RED:
            return propertyConstants.PROPERTY_COLOR_RED_SOURCE;
        case propertyConstants.PROPERTY_COLOR_RGB_HEX:
            return propertyConstants.PROPERTY_COLOR_RGB_HEX_SOURCE;
        case propertyConstants.PROPERTY_COLOR_RGB_INT:
            return propertyConstants.PROPERTY_COLOR_RGB_INT_SOURCE;
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
            return propertyConstants.PROPERTY_COLORANT_MAX_FILL_SOURCE;
        case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
            return propertyConstants.PROPERTY_COLORANT_MIN_FILL_SOURCE;
        case propertyConstants.PROPERTY_CUSTOM_FORMULA:
            return propertyConstants.PROPERTY_CUSTOM_FORMULA_SOURCE;
        case propertyConstants.PROPERTY_DENSITY:
            return propertyConstants.PROPERTY_DENSITY_SOURCE;
        case propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL:
            return propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_SOURCE;
        case propertyConstants.PROPERTY_FILL_PROC:
            return propertyConstants.PROPERTY_FILL_PROC_SOURCE;
        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
            return propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SOURCE;
        case propertyConstants.PROPERTY_MORE_INFO:
            return propertyConstants.PROPERTY_MORE_INFO_SOURCE;
        case propertyConstants.PROPERTY_NAME:
            return propertyConstants.PROPERTY_NAME_SOURCE;
        case propertyConstants.PROPERTY_PRINT_NAME:
            return propertyConstants.PROPERTY_PRINT_NAME_SOURCE;
        case propertyConstants.PROPERTY_SEARCH_CODE:
            return propertyConstants.PROPERTY_SEARCH_CODE_SOURCE;
        case propertyConstants.PROPERTY_SEARCH_NAME:
            return propertyConstants.PROPERTY_SEARCH_NAME_SOURCE;
        case propertyConstants.PROPERTY_TMC_NAME:
            return propertyConstants.PROPERTY_TMC_NAME_SOURCE;
        case propertyConstants.PROPERTY_UFI_CODE:
            return propertyConstants.PROPERTY_UFI_CODE_SOURCE;
        case propertyConstants.PROPERTY_UNIVERSAL_VALUE:
        case propertyConstants.PROPERTY_VALUE:
            return propertyConstants.PROPERTY_VALUE_SOURCE;
        default:
            return null;
    }
};

export const getPropertyMaster = (property: propertyConstants.Property): propertyConstants.Property | null => {
    switch (property) {
        case propertyConstants.PROPERTY_BASE_NAME:
            return propertyConstants.PROPERTY_BASE_NAME_MASTER;
        case propertyConstants.PROPERTY_BFS:
            return propertyConstants.PROPERTY_BFS_MASTER;
        case propertyConstants.PROPERTY_CODE:
            return propertyConstants.PROPERTY_CODE_MASTER;
        case propertyConstants.PROPERTY_COEFFICIENT:
            return propertyConstants.PROPERTY_COEFFICIENT_MASTER;
        case propertyConstants.PROPERTY_COLOR_BLUE:
            return propertyConstants.PROPERTY_COLOR_BLUE_MASTER;
        case propertyConstants.PROPERTY_COLOR_GREEN:
            return propertyConstants.PROPERTY_COLOR_GREEN_MASTER;
        case propertyConstants.PROPERTY_COLOR_RED:
            return propertyConstants.PROPERTY_COLOR_RED_MASTER;
        case propertyConstants.PROPERTY_COLOR_RGB_HEX:
            return propertyConstants.PROPERTY_COLOR_RGB_HEX_MASTER;
        case propertyConstants.PROPERTY_COLOR_RGB_INT:
            return propertyConstants.PROPERTY_COLOR_RGB_INT_MASTER;
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
            return propertyConstants.PROPERTY_COLORANT_MAX_FILL_MASTER;
        case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
            return propertyConstants.PROPERTY_COLORANT_MIN_FILL_MASTER;
        case propertyConstants.PROPERTY_CUSTOM_FORMULA:
            return propertyConstants.PROPERTY_CUSTOM_FORMULA_MASTER;
        case propertyConstants.PROPERTY_DENSITY:
            return propertyConstants.PROPERTY_DENSITY_MASTER;
        case propertyConstants.PROPERTY_FILL_PROC:
            return propertyConstants.PROPERTY_FILL_PROC_MASTER;
        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
            return propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_MASTER;
        case propertyConstants.PROPERTY_IMAGE_ID:
            return propertyConstants.PROPERTY_IMAGE_ID_MASTER;
        case propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL:
            return propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_MASTER;
        case propertyConstants.PROPERTY_MORE_INFO:
            return propertyConstants.PROPERTY_MORE_INFO_MASTER;
        case propertyConstants.PROPERTY_NAME:
            return propertyConstants.PROPERTY_NAME_MASTER;
        case propertyConstants.PROPERTY_PRINT_NAME:
            return propertyConstants.PROPERTY_PRINT_NAME_MASTER;
        case propertyConstants.PROPERTY_PRIORITY:
            return propertyConstants.PROPERTY_PRIORITY_MASTER;
        case propertyConstants.PROPERTY_SEARCH_CODE:
            return propertyConstants.PROPERTY_SEARCH_CODE_MASTER;
        case propertyConstants.PROPERTY_SEARCH_NAME:
            return propertyConstants.PROPERTY_SEARCH_NAME_MASTER;
        case propertyConstants.PROPERTY_TMC_NAME:
            return propertyConstants.PROPERTY_TMC_NAME_MASTER;
        case propertyConstants.PROPERTY_UFI_CODE:
            return propertyConstants.PROPERTY_UFI_CODE_MASTER;
        case propertyConstants.PROPERTY_VALUE:
            return propertyConstants.PROPERTY_VALUE_MASTER;
        default:
            return null;
    }
};

export const getPropertySystem = (property: propertyConstants.Property): propertyConstants.Property | null => {
    switch (property) {
        case propertyConstants.PROPERTY_BASE_NAME:
            return propertyConstants.PROPERTY_BASE_NAME_SYSTEM;
        case propertyConstants.PROPERTY_BFS:
            return propertyConstants.PROPERTY_BFS_SYSTEM;
        case propertyConstants.PROPERTY_CODE:
            return propertyConstants.PROPERTY_CODE_SYSTEM;
        case propertyConstants.PROPERTY_COEFFICIENT:
            return propertyConstants.PROPERTY_COEFFICIENT_SYSTEM;
        case propertyConstants.PROPERTY_COLOR_BLUE:
            return propertyConstants.PROPERTY_COLOR_BLUE_SYSTEM;
        case propertyConstants.PROPERTY_COLOR_GREEN:
            return propertyConstants.PROPERTY_COLOR_GREEN_SYSTEM;
        case propertyConstants.PROPERTY_COLOR_RED:
            return propertyConstants.PROPERTY_COLOR_RED_SYSTEM;
        case propertyConstants.PROPERTY_COLOR_RGB_HEX:
            return propertyConstants.PROPERTY_COLOR_RGB_HEX_SYSTEM;
        case propertyConstants.PROPERTY_COLOR_RGB_INT:
            return propertyConstants.PROPERTY_COLOR_RGB_INT_SYSTEM;
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
            return propertyConstants.PROPERTY_COLORANT_MAX_FILL_SYSTEM;
        case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
            return propertyConstants.PROPERTY_COLORANT_MIN_FILL_SYSTEM;
        case propertyConstants.PROPERTY_CUSTOM_FORMULA:
            return propertyConstants.PROPERTY_CUSTOM_FORMULA_SYSTEM;
        case propertyConstants.PROPERTY_DENSITY:
            return propertyConstants.PROPERTY_DENSITY_SYSTEM;
        case propertyConstants.PROPERTY_FILL_PROC:
            return propertyConstants.PROPERTY_FILL_PROC_SYSTEM;
        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
            return propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_MASTER;
        case propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL:
            return propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL_SYSTEM;
        case propertyConstants.PROPERTY_IMAGE_ID:
            return propertyConstants.PROPERTY_IMAGE_ID_SYSTEM;
        case propertyConstants.PROPERTY_MORE_INFO:
            return propertyConstants.PROPERTY_MORE_INFO_SYSTEM;
        case propertyConstants.PROPERTY_NAME:
            return propertyConstants.PROPERTY_NAME_SYSTEM;
        case propertyConstants.PROPERTY_PRINT_NAME:
            return propertyConstants.PROPERTY_PRINT_NAME_SYSTEM;
        case propertyConstants.PROPERTY_PRIORITY:
            return propertyConstants.PROPERTY_PRIORITY_SYSTEM;
        case propertyConstants.PROPERTY_SEARCH_CODE:
            return propertyConstants.PROPERTY_SEARCH_CODE_SYSTEM;
        case propertyConstants.PROPERTY_SEARCH_NAME:
            return propertyConstants.PROPERTY_SEARCH_NAME_SYSTEM;
        case propertyConstants.PROPERTY_TMC_NAME:
            return propertyConstants.PROPERTY_TMC_NAME_SYSTEM;
        case propertyConstants.PROPERTY_UFI_CODE:
            return propertyConstants.PROPERTY_UFI_CODE_SYSTEM;
        case propertyConstants.PROPERTY_VALUE:
            return propertyConstants.PROPERTY_VALUE_SYSTEM;
        default:
            return null;
    }
};

export const getPriceUnitProperty = (property: propertyConstants.Property): propertyConstants.Property | null => {
    switch (property) {
        case propertyConstants.PROPERTY_PRICE_COST:
            return propertyConstants.PROPERTY_PRICE_COST_PER_UNIT;
        case propertyConstants.PROPERTY_PRICE_PURCHASE:
            return propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT;
        case propertyConstants.PROPERTY_PRICE_SELL:
            return propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT;
        default:
            return null;
    }
};

export const getFormattedMoreInfoProperty = (
    property: propertyConstants.Property
): propertyConstants.Property | null => {
    switch (property) {
        case propertyConstants.PROPERTY_MORE_INFO:
            return propertyConstants.PROPERTY_MORE_INFO_FORMATTED;
        case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
            return propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED;
        case propertyConstants.PROPERTY_MORE_INFO_PRINT:
            return propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED;
        default:
            return null;
    }
};

export const isSpecificMasterProperty = (property: propertyConstants.Property): boolean => {
    switch (property) {
        case propertyConstants.PROPERTY_BASE_NAME_MASTER:
        case propertyConstants.PROPERTY_NAME_MASTER:
        case propertyConstants.PROPERTY_PACKAGE_NAME_MASTER:
        case propertyConstants.PROPERTY_PRODUCT_GROUP_LIST:
        case propertyConstants.PROPERTY_SEARCH_NAME_MASTER:
        case propertyConstants.PROPERTY_TMC_NAME_MASTER:
        case propertyConstants.PROPERTY_VALUE_MASTER:
            return true;
        default:
            return false;
    }
};

export const isSpecificSystemProperty = (property: propertyConstants.Property): boolean => {
    switch (property) {
        case propertyConstants.PROPERTY_BASE_NAME_SYSTEM:
        case propertyConstants.PROPERTY_NAME_SYSTEM:
        case propertyConstants.PROPERTY_PACKAGE_NAME_SYSTEM:
        case propertyConstants.PROPERTY_SEARCH_NAME_SYSTEM:
        case propertyConstants.PROPERTY_TMC_NAME_SYSTEM:
        case propertyConstants.PROPERTY_VALUE_SYSTEM:
            return true;
        default:
            return false;
    }
};

export const isPropertyBarcodes = (property: propertyConstants.Property | null): boolean => {
    if (
        property &&
        (property === propertyConstants.PROPERTY_BARCODES ||
            property === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES)
    ) {
        return true;
    }

    return false;
};

export const isPropertyColorRedGreenBlue = (property: propertyConstants.Property | null): boolean => {
    if (
        property &&
        (property === propertyConstants.PROPERTY_COLOR_RED ||
            property === propertyConstants.PROPERTY_COLOR_BLUE ||
            property === propertyConstants.PROPERTY_COLOR_GREEN)
    ) {
        return true;
    }

    return false;
};

export const isPropertyPassword = (property: propertyConstants.Property | null): boolean => {
    if (
        property &&
        (property === propertyConstants.PROPERTY_PASSWORD ||
            property === propertyConstants.PROPERTY_PASSWORD_OLD ||
            property === propertyConstants.PROPERTY_PASSWORD_NEW ||
            property === propertyConstants.PROPERTY_ZONE_PASSWORD)
    ) {
        return true;
    }

    return false;
};

export const isPropertyUfiCode = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_UFI_CODE) {
        return true;
    }

    return false;
};

export const isPropertyUuid = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_UUID) {
        return true;
    }

    return false;
};

export const isPropertyVersion = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_VERSION) {
        return true;
    }

    return false;
};

export const isPropertyVersionName = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_VERSION_NAME) {
        return true;
    }

    return false;
};

export const isPropertyColorantMaxFill = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_COLORANT_MAX_FILL) {
        return true;
    }

    return false;
};

export const isPropertyColorantMinFill = (property: propertyConstants.Property | null): boolean => {
    if (property && property === propertyConstants.PROPERTY_COLORANT_MIN_FILL) {
        return true;
    }

    return false;
};

// TODO do we need to keep string?
export const isPropertyVat = (property: propertyConstants.Property | string | null): boolean => {
    if (
        property &&
        (property === propertyConstants.PROPERTY_PRICE_VAT ||
            property === priceConstants.PRICE_GENERIC_COLORANT_VAT ||
            property === priceConstants.PRICE_GENERIC_PRODUCT_VAT)
    ) {
        return true;
    }

    return false;
};

export const isParentPropertyBfs = (property: propertyConstants.Property): boolean => {
    if (
        property === propertyConstants.PROPERTY_BFS ||
        property === propertyConstants.PROPERTY_BFS_SYSTEM ||
        property === propertyConstants.PROPERTY_BFS_MASTER
    ) {
        return true;
    }

    return false;
};

export const isParentPropertyColorHex = (property: propertyConstants.Property): boolean => {
    if (
        property === propertyConstants.PROPERTY_COLOR_RGB_HEX ||
        property === propertyConstants.PROPERTY_COLOR_RGB_HEX_SYSTEM ||
        property === propertyConstants.PROPERTY_COLOR_RGB_HEX_MASTER
    ) {
        return true;
    }

    return false;
};

export const isParentPropertyName = (property: propertyConstants.Property | null): boolean => {
    if (
        property &&
        (property === propertyConstants.PROPERTY_NAME ||
            property === propertyConstants.PROPERTY_NAME_SYSTEM ||
            property === propertyConstants.PROPERTY_NAME_MASTER)
    ) {
        return true;
    }

    return false;
};

export const isPropertyDisabledForEmptyDatabase = (property: propertyConstants.Property): boolean => {
    if (
        property === propertyConstants.PROPERTY_CHANGELOG ||
        property === propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS ||
        property === propertyConstants.PROPERTY_VERSION ||
        property === propertyConstants.PROPERTY_VERSION_NAME
    ) {
        return true;
    }

    return false;
};

export const isPropertyDisabledForFormulaNotAvailableModal = (property: propertyConstants.Property): boolean => {
    return (
        property === propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT ||
        property === propertyConstants.PROPERTY_BASE_AMOUNT ||
        property === propertyConstants.PROPERTY_UUID
    );
};
