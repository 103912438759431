import * as formattingHelper from "../helpers/formattingHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Currency, mapCurrencies } from "./currency";
import { System, mapSystems } from "./system";

import { Company } from "./company";
import { Option } from "./option";
import { TableHeading } from "./tableHeading";
import { User } from "./user";

import { t as translate } from "react-i18nify";

export class Database {
    id: number;
    companyId: number;
    uuid: string;
    name: string;
    password: string;
    path: string;
    file: File | null;
    moreInfo: string | null;
    dataLen: string | null;
    addCurrency: boolean;
    masterOnly: boolean;
    defaultData: serverConstants.DatabaseDefaultData;
    updateNeeded: boolean | null;
    currencyList: Array<Currency>;
    systemList: Array<System>;
    dateCreated: Date | null;
    dateUpdated: Date | null;

    constructor(
        id: number,
        companyId: number,
        uuid: string,
        name: string,
        password: string,
        path: string,
        file: File | null,
        moreInfo: string | null,
        dataLen: string | null,
        addCurrency: boolean,
        masterOnly: boolean,
        defaultData: serverConstants.DatabaseDefaultData,
        updateNeeded: boolean | null,
        currencyList: Array<Currency>,
        systemList: Array<System>,
        dateCreated: Date | null,
        dateUpdated: Date | null
    ) {
        this.id = id;
        this.companyId = companyId;
        this.uuid = uuid;
        this.name = name;
        this.password = password;
        this.path = path;
        this.file = file;
        this.moreInfo = moreInfo;
        this.dataLen = dataLen;
        this.addCurrency = addCurrency;
        this.masterOnly = masterOnly;
        this.defaultData = defaultData;
        this.updateNeeded = updateNeeded;
        this.currencyList = currencyList;
        this.systemList = systemList;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }
}

export function mapDatabase(
    data: Record<string, any>,
    loggedUser: User | null = null,
    optionCompaniesForUsers: Option | null = null
): Database | null {
    try {
        /**
         * User can be assigned to a company. Then user can view only databases that are also assigned to this company.
         * Or logged in user is in superadmin user group.
         */
        const userId = loggedUser?.[propertyConstants.PROPERTY_ID] || null;
        const companyId = userId
            ? optionCompaniesForUsers?.[propertyConstants.PROPERTY_VALUE]?.[userId.toString()] || null
            : null;

        if (companyId !== null && companyId !== data.companyId && !privilegesHelper.isSuperadmin(loggedUser)) {
            return null;
        }

        return new Database(
            data.id,
            data.companyId,
            data.uuid,
            data.name.toString(),
            data.password,
            data.path,
            null,
            data.moreInfo,
            formattingHelper.formatFileSize(data.dataLen),
            false,
            true,
            serverConstants.DATABASE_DEFAULT_EMPTY,
            data.updateNeeded,
            data.currencies ? mapCurrencies(data.currencies) : [],
            data.systems ? mapSystems(data.systems) : [],
            data.dateCreated,
            data.dateUpdated
        );
    } catch (e) {
        return null;
    }
}

export function updateDatabase(oldDatabase: Database | null, updatedData: Record<string, any>): Database | null {
    try {
        if (oldDatabase === null) {
            return null;
        }

        return new Database(
            oldDatabase.id,
            updatedData.companyId ? updatedData.companyId : oldDatabase.companyId,
            updatedData.uuid ? updatedData.uuid : oldDatabase.uuid,
            updatedData.name ? updatedData.name : oldDatabase.name,
            updatedData.password ? updatedData.password : oldDatabase.password,
            updatedData.path ? updatedData.path : oldDatabase.path,
            oldDatabase.file,
            updatedData.moreInfo ? updatedData.moreInfo : oldDatabase.moreInfo,
            oldDatabase.dataLen,
            updatedData.addCurrency ? updatedData.addCurrency : oldDatabase.addCurrency,
            updatedData.masterOnly ? updatedData.masterOnly : oldDatabase.masterOnly,
            serverConstants.DATABASE_DEFAULT_EMPTY,
            oldDatabase.updateNeeded,
            oldDatabase.currencyList,
            oldDatabase.systemList,
            oldDatabase.dateCreated,
            oldDatabase.dateUpdated
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyDatabase(companyList: Array<Company>): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_COMPANY_ID]:
            companyList.length === 1 ? companyList[0][propertyConstants.PROPERTY_ID] : "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PASSWORD]: "",
        [propertyConstants.PROPERTY_PATH]: "",
        [propertyConstants.PROPERTY_FILE]: null,
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_LENGTH]: "",
        [propertyConstants.PROPERTY_ADD_CURRENCY]: "false",
        [propertyConstants.PROPERTY_MASTER_ONLY]: "true",
        [propertyConstants.PROPERTY_DEFAULT_DATA]: serverConstants.DATABASE_DEFAULT_EMPTY,
        [propertyConstants.PROPERTY_DATE_CREATED]: "",
        [propertyConstants.PROPERTY_DATE_UPDATED]: ""
    };
}

export function generateDatabaseData(databaseList: Array<Database>): Array<any> {
    const data: Array<any> = [];
    let database: Database;

    for (database of databaseList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: database.id,
            [propertyConstants.PROPERTY_COMPANY_ID]: database.companyId,
            [propertyConstants.PROPERTY_UUID]: database.uuid,
            [propertyConstants.PROPERTY_NAME]: database.name,
            [propertyConstants.PROPERTY_PASSWORD]: database.password,
            [propertyConstants.PROPERTY_PATH]: database.path,
            [propertyConstants.PROPERTY_FILE]: database.file,
            [propertyConstants.PROPERTY_MORE_INFO]: database.moreInfo,
            [propertyConstants.PROPERTY_LENGTH]: database.dataLen,
            [propertyConstants.PROPERTY_ADD_CURRENCY]: database.addCurrency,
            [propertyConstants.PROPERTY_MASTER_ONLY]: database.masterOnly,
            [propertyConstants.PROPERTY_DATE_CREATED]: database.dateCreated,
            [propertyConstants.PROPERTY_DATE_UPDATED]: database.dateUpdated
        });
    }

    return data;
}

export function generateDuplicatedDatabaseData(database: Database): any {
    try {
        return {
            [propertyConstants.PROPERTY_ID]: database.id,
            [propertyConstants.PROPERTY_COMPANY_ID]: database.companyId,
            [propertyConstants.PROPERTY_UUID]: "",
            [propertyConstants.PROPERTY_NAME]: "",
            [propertyConstants.PROPERTY_PASSWORD]: database.password,
            [propertyConstants.PROPERTY_MORE_INFO]: "",
            [propertyConstants.PROPERTY_LENGTH]: database.dataLen,
            [propertyConstants.PROPERTY_MASTER_ONLY]: "false"
        };
    } catch (e) {
        return null;
    }
}

export function generateDatabaseHeadings(
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalType | null,
    companyList: Array<Company>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const defaultDataEnum = [
        {
            id: serverConstants.DATABASE_DEFAULT_EMPTY,
            name: translate("database.defaultDataEmpty")
        },
        {
            id: serverConstants.DATABASE_DEFAULT_WITH_SYSTEM_ZONE_CURRENCY,
            name: translate("database.defaultDataSystemZoneCurrency")
        }
    ];
    let masterOnlyEnum = [
        { id: "true", name: translate("database.masterOnlyTrue") },
        { id: "false", name: translate("database.masterOnlyFalse") }
    ];

    if (modalType === modalTypes.MODAL_DATABASES_DUPLICATE) {
        masterOnlyEnum = [
            { id: "true", name: translate("database.masterOnlyTrue") },
            { id: "false", name: translate("database.masterOnlyDuplicateFalse") }
        ];
    }

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_COMPANY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPANY_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            editableList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPANY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            requiredList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            companyList,
            widthOption?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_CREATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            editableList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_CREATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_CREATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_UPDATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_UPDATED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_UPDATED),
            editableList.includes(propertyConstants.PROPERTY_DATE_UPDATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_UPDATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_UPDATED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_UPDATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_UPDATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DEFAULT_DATA,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DEFAULT_DATA),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_DEFAULT_DATA),
            editableList.includes(propertyConstants.PROPERTY_DEFAULT_DATA),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DEFAULT_DATA),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DEFAULT_DATA),
            requiredList.includes(propertyConstants.PROPERTY_DEFAULT_DATA),
            defaultDataEnum,
            widthOption?.[propertyConstants.PROPERTY_DEFAULT_DATA] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE),
            tableConstants.TABLE_TYPE_FILE,
            visibleList.includes(propertyConstants.PROPERTY_FILE),
            editableList.includes(propertyConstants.PROPERTY_FILE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE),
            requiredList.includes(propertyConstants.PROPERTY_FILE),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LENGTH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LENGTH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LENGTH),
            editableList.includes(propertyConstants.PROPERTY_LENGTH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LENGTH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LENGTH),
            requiredList.includes(propertyConstants.PROPERTY_LENGTH),
            [],
            widthOption?.[propertyConstants.PROPERTY_LENGTH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MASTER_ONLY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MASTER_ONLY, modalType),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_MASTER_ONLY),
            editableList.includes(propertyConstants.PROPERTY_MASTER_ONLY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MASTER_ONLY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MASTER_ONLY),
            requiredList.includes(propertyConstants.PROPERTY_MASTER_ONLY),
            masterOnlyEnum,
            widthOption?.[propertyConstants.PROPERTY_MASTER_ONLY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_PASSWORD),
            [],
            widthOption?.[propertyConstants.PROPERTY_PASSWORD] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PATH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PATH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PATH),
            editableList.includes(propertyConstants.PROPERTY_PATH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PATH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PATH),
            requiredList.includes(propertyConstants.PROPERTY_PATH),
            [],
            widthOption?.[propertyConstants.PROPERTY_PATH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
