import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { System, mapSystem } from "../../types/system";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SYSTEMS_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const systemList: Array<System> = [];
            let system: System | null;

            for (const item of response.data.data) {
                system = mapSystem(item);

                if (system) {
                    systemList.push(system);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.SYSTEM, systemList)];
        }
        case methods.METHOD_GET_ALL_SYSTEMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const systemList: Array<System> = [];
            let system: System | null;

            for (const item of response.data.data) {
                system = mapSystem(item);

                if (system) {
                    systemList.push(system);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM, systemList, response.data.count)];
        }
        case methods.METHOD_ADD_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.reloadData(objectTypes.SYSTEM),
                universalObjectActions.getAllItems(objectTypes.SYSTEM),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEMS_ADD)
            ];
        }
        case methods.METHOD_DUPLICATE_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.reloadData(objectTypes.SYSTEM),
                universalObjectActions.getAllItems(objectTypes.SYSTEM),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEMS_DUPLICATE)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.getAllItems(objectTypes.SYSTEM)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_SYSTEMS_EDIT)) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                    universalObjectActions.reloadData(objectTypes.SYSTEM),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEMS_EDIT)
                );
            } else {
                const system = mapSystem(response.data);

                if (system) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM, [system]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.reloadData(objectTypes.SYSTEM),
                universalObjectActions.getAllItems(objectTypes.SYSTEM),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM, [])
            ];
        }
        case methods.METHOD_SET_ACTIVE_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
