import * as formattingHelper from "../helpers/formattingHelper";
import * as generalHelper from "../helpers/generalHelper";
import * as importExportActions from "../actions/importExportActions";
import * as keyCodes from "../constants/keyCodes";
import * as navigationActions from "../actions/navigationActions";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "../helpers/optionHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as propertyHelper from "../helpers/propertyHelper";
import * as requestHelper from "../helpers/requestHelper";
import * as tableConstants from "../constants/tableConstants";
import * as translationHelper from "../helpers/translationHelper";
import * as universalObjectActions from "../actions/universalObjectActions";
import * as validationHelper from "../helpers/validationHelper";

import React, { Component } from "react";

import { AppState } from "../reducers";
import { Currency } from "../types/currency";
import { Dispatch } from "redux";
import { FormulaColorant } from "../types/formulaColorant";
import { Modal } from "../types/modal";
import { ModalProperties } from "../types/modalProperties";
import { ModalType } from "../constants/modalTypes";
import { Option } from "../types/option";
import { Price } from "../types/price";
import { ReactSVG } from "react-svg";
import ReactTooltip from "react-tooltip";

import { TableActionFooterRow } from "./general/table/TableActionFooterRow";
import { TableActionRowContainer as TableActionRow } from "./general/table/TableActionRow";
import { TableFilterRowContainer as TableFilterRow } from "./general/table/TableFilterRow";
import { TableFooterRow } from "./general/table/TableFooterRow";
import { TableHeading } from "../types/tableHeading";
import { TableHeadingRowContainer as TableHeadingRow } from "./general/table/TableHeadingRow";
import { TableItemRowContainer as TableItemRow } from "./general/table/TableItemRow";
import { TablePaginationContainer as TablePagination } from "./general/table/TablePagination";
import { Unit } from "../types/unit";

import { connect } from "react-redux";
import imgLoader from "../resources/img/loader.svg";
import { t as translate } from "react-i18nify";

type TableProps = {
    // TODO create type for tableConstant, dataPrivileges
    // TODO description for updatedData
    /**
     * className: classname for the whole table
     * tableConstant: identifier for table, used for server communication
     * objectType: table works with this object, important for all communication and data loading
     * dataPrivileges: decides whether the data can be viewed, updated, deleted
     * modalId: says whether Table is in modal, used for counting elements height
     * itemId
     * loading: some of the server requests are pending
     * buttonLoading
     * multiSelect: tells whether multiple items can be selected in the table, or just a single one - as default
     * disableColumnResizing: if true, table column resizing is disabled and default column width is set
     * title: title of the table to actionRow for scenes tables
     * titleClassName: className of title for scene tables
     * headings: specifies the columns of the table
     * data: table rows are filled with these data
     * updatedData:
     * activeData: items that are currently selected in the table, buttons then provide actions for these items
     * availableCount: number of items available to add from parent layer
     * modalProperties: tells which modals (types and params) can be used in this table
     * offset: number of items to skip, used for paging
     * page: current page
     * rowCount: number of items on page, specified in options
     * rowCountCustom: boolean if it is fit to height
     * search: text in the global search
     * searchParams: contains search text for each column separately
     * showFilterRow: specifies whether this table should display row with column filters
     * showFooterRow: specifies whether there is a row under table with buttons
     * showGlobalSearch: specifies whether this table should display global search input
     * showSpecificValues: specifies whether displayed data should be filtered locally (user for Redlike options)
     * sortingAsc: specifies whether sorting should be ascending or descending
     * sortingCriterion: specifies the column according to which the sorting is done
     * totalCount: total number of items in the table, without paging
     * currency: active currency, the symbol might be displayed in the table
     * callbackEdit: used for local values editing in tables (used for Formula colorant amount)
     */
    className: string;
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    modalId?: number;
    itemId?: number;
    loading: boolean;
    buttonLoading?: boolean;
    multiSelect?: boolean;
    disableColumnResizing?: boolean;
    title?: string;
    titleClassName?: string;
    headings: Array<TableHeading>;
    data: Array<any>;
    updatedData?: Array<any>;
    activeData: Array<any>;
    additionalData?: Record<propertyConstants.Property, any>;
    availableCount?: number | null;
    modalProperties: ModalProperties;
    dateFrom?: Date | null;
    dateTo?: Date | null;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom?: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showAnotherSpecificValues?: boolean;
    showFilterRow: boolean;
    showFooterRow?: boolean;
    showGlobalSearch: boolean;
    showSpecificValues?: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    currency?: Currency | null;
    callbackEdit?: (property: propertyConstants.Property, value: any) => any;
};

type OwnProps = PropsType & DispatchType;

type Props = OwnProps & TableProps;

type State = {
    /**
     * activeHeadingId: active column property, used for inline editing
     * activeRowId: active row ID, string is for prices, otherwise item ID is used
     * cellContent: content of active cell, updated on change events
     * originalCellContent: original content of active cell, does not change
     * tooltipHintOn: says whether tooltip with hint is displayed
     * tooltipOn: says whether tooltip with uncut value is displayed
     * tooltipParentText: parent value, displayed if it differs from current value
     * tooltipText: content of tooltip
     */
    // TODO
    //  neslo by sjednotit tooltipHintOn a tooltipOn, stejne tak tooltipParentText a tooltipText,
    //  popr sjednotit default hodnotu pro tooltipText na null
    //  kdyz ne nevadi, jen hledam optimalizace
    activeHeadingId: propertyConstants.Property | null;
    activeRowId: number | string | null;
    cellContent: string;
    isOptionMenuOpened: boolean;
    originalCellContent: string;
    tooltipHintOn: boolean;
    tooltipOn: boolean;
    tooltipParentText: string | null;
    tooltipText: string | JSX.Element;
};

export class Table extends Component<Props, State> {
    state: State = {
        activeHeadingId: null,
        activeRowId: null,
        cellContent: "",
        isOptionMenuOpened: false,
        originalCellContent: "",
        tooltipHintOn: false,
        tooltipOn: false,
        tooltipParentText: null,
        tooltipText: ""
    };

    _tableRef = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        if (!this.props.multiSelect && this.props.modalList.length === 0) {
            const item = this.props.data[0] || null;
            if (item) {
                this.handleItemRowClick(null, item);
            }
        }

        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        // setting active first item automatically except for Propagation modal tables
        // disables loading wrong values when editing first item in the table

        if (
            !this.props.multiSelect &&
            this.props.modalList.length === 0 &&
            JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
            (JSON.stringify(prevProps.activeData) === JSON.stringify(this.props.activeData) ||
                this.props.activeData.length === 0)
        ) {
            const item = this.props.data[0] || null;
            // Artificial data are for cases when we do not want to autoselect first item in the table if data has changed
            let artificialData = [];

            switch (this.props.objectType) {
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO:
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO:
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    // Selected formulaId in Master formulas scene detail
                    artificialData = [
                        ...this.props.data.map((item: any) => {
                            return { ...item, [propertyConstants.PROPERTY_FORMULA_ID]: null };
                        })
                    ];
                    break;
                }
                case objectTypes.MASTER_COLOR:
                case objectTypes.SYSTEM_COLOR:
                case objectTypes.ZONE_COLOR: {
                    // Jump from formulas to colors
                    artificialData = [
                        ...this.props.data.map((item: any) => {
                            const prevDataItem = prevProps.data.find((prevItem) => prevItem.id === item.id) || null;
                            return {
                                ...item,
                                [propertyConstants.PROPERTY_FORMULAS_STATUS]:
                                    prevDataItem?.[propertyConstants.PROPERTY_FORMULAS_STATUS] ?? null,
                                [propertyConstants.PROPERTY_FORMULA_ID]:
                                    prevDataItem?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null,
                                [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]:
                                    prevDataItem?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null,
                                [propertyConstants.PROPERTY_IMAGE_ID]:
                                    prevDataItem?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null,
                                [propertyConstants.PROPERTY_BARCODES]:
                                    prevDataItem?.[propertyConstants.PROPERTY_BARCODES] ?? []
                            };
                        })
                    ];
                    break;
                }
                default: {
                    artificialData = [...this.props.data];
                    break;
                }
            }

            if (
                item &&
                (this.props.totalCount !== prevProps.totalCount || this.props.activeData.length === 0) &&
                JSON.stringify(prevProps.data) !== JSON.stringify(artificialData)
            ) {
                this.setState({
                    activeHeadingId: null,
                    cellContent: "",
                    originalCellContent: ""
                });
                this.handleItemRowClick(null, item);
            }
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    // TODO unify handleKeyDown and handleItemRowClick
    handleKeyDown = (event: Record<string, any>): any => {
        const { data, activeData, multiSelect } = this.props;

        switch (event.key) {
            case keyCodes.KEY_UP:
            case keyCodes.KEY_DOWN: {
                // Handling shift (+ ctrl) + key down/up
                if (event.shiftKey && multiSelect) {
                    let newActiveData = [];

                    const firstActiveItemIndex = data.findIndex(
                        (item: any) =>
                            item[propertyConstants.PROPERTY_ID] === activeData[0]?.[propertyConstants.PROPERTY_ID]
                    );
                    const lastActiveItemIndex = data.findIndex(
                        (item: any) =>
                            item[propertyConstants.PROPERTY_ID] ===
                            activeData[activeData.length - 1]?.[propertyConstants.PROPERTY_ID]
                    );

                    const startItemIndex = keyCodes.KEY_UP ? firstActiveItemIndex : lastActiveItemIndex;
                    const endItemIndex =
                        event.key === keyCodes.KEY_UP ? lastActiveItemIndex - 1 : lastActiveItemIndex + 1;

                    if (
                        firstActiveItemIndex >= 0 &&
                        lastActiveItemIndex >= 0 &&
                        endItemIndex >= 0 &&
                        endItemIndex < data.length
                    ) {
                        if (startItemIndex < endItemIndex) {
                            newActiveData = data.slice(startItemIndex, endItemIndex + 1);
                        } else {
                            newActiveData = data.slice(endItemIndex, startItemIndex + 1).reverse();
                        }

                        this.setState({
                            activeRowId: data[endItemIndex]?.[propertyConstants.PROPERTY_ID] || null
                        });

                        if (JSON.stringify(activeData) !== JSON.stringify(newActiveData)) {
                            this.props.setActive(this.props.objectType, newActiveData);
                        }
                    }

                    break;
                }

                // Handling ctrl + key down/up
                if (event.ctrlKey) {
                    break;
                }

                // Handling only key down/up

                break;
            }
            default: {
                break;
            }
        }
    };

    handleHeadingMouseEnter = (property: propertyConstants.Property): any => {
        this.setState({
            tooltipHintOn: true,
            tooltipText: translationHelper.getHeadingTooltipTranslation(
                this.props.objectType,
                property,
                this.props.custom
            )
        });
    };

    handleHeadingMouseLeave = (): any => {
        this.setState({
            tooltipHintOn: false,
            tooltipText: ""
        });
    };

    handleItemRowMouseEnter = (
        event: React.MouseEvent<any>,
        heading: TableHeading,
        item: Record<string, any> | null,
        value: string | JSX.Element,
        isSourceChanged: boolean
    ): any => {
        const { objectType } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const propertyType = heading[tableConstants.TABLE_HEADING_TYPE];
        const element = event.currentTarget;
        let parentProperty: propertyConstants.Property | null = null;

        // Handling parent value
        if (isSourceChanged) {
            if (objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType)) {
                parentProperty = propertyHelper.getPropertyMaster(property);
            }

            if (objectTypes.ZONE_OBJECT_TYPES.includes(objectType)) {
                parentProperty = propertyHelper.getPropertySystem(property);
            }
        }

        let parentValue = item && parentProperty && item[parentProperty] !== undefined ? item[parentProperty] : null;

        if (propertyType === tableConstants.TABLE_TYPE_SELECT) {
            const parentOption =
                heading[tableConstants.TABLE_HEADING_ENUM].find(
                    (item: any) => item[propertyConstants.PROPERTY_ID] === parentValue
                ) || null;

            parentValue = parentOption?.[propertyConstants.PROPERTY_NAME] || null;
        }

        if (parentValue !== null) {
            this.setState({
                tooltipParentText: parentValue.toString(),
                tooltipText: value
            });
        }

        // Handling selectbox values
        if (propertyType === tableConstants.TABLE_TYPE_SELECT) {
            const child: any = element.querySelector(".selected-value");
            if (child && child.offsetWidth < child.scrollWidth) {
                this.setState({
                    tooltipOn: true,
                    tooltipText: value
                });
            }
        }

        if (propertyType === tableConstants.TABLE_TYPE_RADIO_ICON) {
            const tooltip = translationHelper.getCellTooltipTranslation(objectType, property, value);

            if (tooltip) {
                this.setState({
                    tooltipOn: true,
                    tooltipText: tooltip
                });
            }
        }

        // Handling long values, barcodes, files and images
        if (
            (element.offsetWidth < element.scrollWidth ||
                property === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES ||
                property === propertyConstants.PROPERTY_BARCODES ||
                property === propertyConstants.PROPERTY_FILES ||
                property === propertyConstants.PROPERTY_IMAGE_ID ||
                property === propertyConstants.PROPERTY_ICON_ID) &&
            propertyType !== tableConstants.TABLE_TYPE_SELECT
        ) {
            this.setState({
                tooltipOn: true,
                tooltipText: value
            });
        }
    };

    handleItemRowMouseLeave = (): any => {
        this.setState({
            tooltipOn: false,
            tooltipParentText: null,
            tooltipText: ""
        });
    };

    handleItemRowFocus = (property: propertyConstants.Property, itemId: number | string, value: string): any => {
        this.setState({
            activeHeadingId: property,
            activeRowId: itemId,
            cellContent: value,
            originalCellContent: value
        });
    };

    handleItemRowClick = (event: React.MouseEvent | null, item: Record<string, any>): any => {
        const { data, activeData, multiSelect } = this.props;
        let newActiveData = [];

        // Handle click with SHIFT as multiselect across many rows
        // For now used only in Propagation modal
        if (event?.shiftKey && multiSelect) {
            // Active data contains those items which are already selected
            const firstActiveItemIndex = data.findIndex(
                (element: any) =>
                    element[propertyConstants.PROPERTY_ID] === activeData[0]?.[propertyConstants.PROPERTY_ID]
            );
            const lastActiveItemIndex = data.findIndex(
                (element: any) =>
                    element[propertyConstants.PROPERTY_ID] ===
                    activeData?.[activeData.length - 1]?.[propertyConstants.PROPERTY_ID]
            );
            // Item is the currently clicked row
            const newItemIndex = data.findIndex(
                (element: any) => element[propertyConstants.PROPERTY_ID] === item[propertyConstants.PROPERTY_ID]
            );
            // TODO explanation for this magic
            // TODO does not work correctly, oznacim 1. polozku, se shiftem 3., oznaci se mi vsechny 3, se shiftem oznacim 4. a zustanou mi oznaceny jen 3. a 4.
            const oldItemIndex =
                firstActiveItemIndex > activeData.length - 1 ? firstActiveItemIndex : lastActiveItemIndex;

            if (oldItemIndex >= 0 && newItemIndex >= 0) {
                newActiveData =
                    newItemIndex < oldItemIndex
                        ? data.slice(newItemIndex, oldItemIndex + 1)
                        : data.slice(oldItemIndex, newItemIndex + 1);
            }
        }

        // Handle click with CTRL without SHIFT as multiselecting all of the clicked rows
        // For now used only in Propagation modal
        if (event?.ctrlKey && !event?.shiftKey && multiSelect) {
            newActiveData = generalHelper.handleActiveItems(item, activeData, true);
        }

        // Handle only one row click or row autoselect on lifecycle methods
        if ((!event?.ctrlKey && !event?.shiftKey) || !multiSelect) {
            newActiveData = generalHelper.handleActiveItems(item, activeData, false);
        }

        this.setState({
            activeRowId: item[propertyConstants.PROPERTY_ID]
        });

        if (JSON.stringify(activeData) !== JSON.stringify(newActiveData)) {
            this.props.setActive(this.props.objectType, newActiveData);
        }
    };

    handleItemRowDoubleClick = (event: React.MouseEvent, item: Record<string, any>): any => {
        const { objectType, updatedData, multiSelect } = this.props;
        const inversedObjectType = objectTypeHelper.getOppositeMixedObjectType(objectType);
        let newActiveData = [];
        let activeItem = null;

        // Handle transfer from available items table (on the left side) to the table of items which should be added (on the right side)
        // For now used only in Propagation modal
        if (multiSelect && inversedObjectType && updatedData) {
            switch (objectType) {
                // Product data can be inserted multiple times, so we need to create a unique ID
                case objectTypes.MASTER_SYSTEM_PRODUCT:
                case objectTypes.SYSTEM_ZONE_PRODUCT: {
                    activeItem = {
                        ...item,
                        [propertyConstants.PROPERTY_PARENT_ID]: item[propertyConstants.PROPERTY_ID],
                        [propertyConstants.PROPERTY_ID]: `${
                            item[propertyConstants.PROPERTY_ID]
                        }-${new Date().valueOf()}`
                    };
                    break;
                }
                default: {
                    activeItem = { ...item };
                    break;
                }
            }

            newActiveData = generalHelper.handleActiveItems(activeItem, this.props.activeData, multiSelect);

            if (newActiveData.length === 1) {
                this.props.saveList(inversedObjectType, updatedData.concat(newActiveData));
                this.props.setActive(inversedObjectType, newActiveData);
            }
        }
    };

    handleItemRowChange = (isValid: boolean, value: string): any => {
        this.setState({
            cellContent: isValid ? value : this.state.cellContent
        });
    };

    handleItemRowBlur = (
        event: React.FocusEvent<any>,
        value: boolean | number | string | null,
        rowId?: number | string,
        headingId?: propertyConstants.Property,
        price?: Record<string, any>
    ): any => {
        const { objectType } = this.props;
        const { activeHeadingId, activeRowId } = this.state;
        const heading =
            this.props.headings.find(
                (heading: TableHeading) => heading[tableConstants.TABLE_HEADING_NAME] === activeHeadingId
            ) || null;
        const priceProperty =
            typeof value === "boolean" ? propertyConstants.PROPERTY_OVERWRITE : propertyConstants.PROPERTY_VALUE;

        if (event?.currentTarget) {
            event.currentTarget.scrollLeft = 0;
        }

        // Validate item
        switch (objectType) {
            case objectTypes.SYSTEM_ZONE: {
                if (
                    (propertyHelper.isPropertyPassword(activeHeadingId) &&
                        !validationHelper.isPasswordValid(value?.toString() || null)) ||
                    (propertyHelper.isPropertyVersion(activeHeadingId) &&
                        !validationHelper.isVersionValid(value?.toString() || null)) ||
                    (propertyHelper.isPropertyVersionName(activeHeadingId) &&
                        !validationHelper.isVersionNameValid(value?.toString() || null))
                ) {
                    value = this.state.originalCellContent;
                }

                break;
            }
            case objectTypes.ZONE: {
                if (
                    (propertyHelper.isParentPropertyName(activeHeadingId) &&
                        !validationHelper.isZoneNameValid(value?.toString() || null)) ||
                    (propertyHelper.isPropertyPassword(activeHeadingId) &&
                        !validationHelper.isPasswordValid(value?.toString() || null)) ||
                    (propertyHelper.isPropertyUuid(activeHeadingId) &&
                        !validationHelper.isUuidValid(value?.toString() || null))
                ) {
                    value = this.state.originalCellContent;
                }

                break;
            }
            case objectTypes.MASTER_BASE_IN_PRODUCT:
            case objectTypes.SYSTEM_BASE_IN_PRODUCT:
            case objectTypes.ZONE_BASE_IN_PRODUCT: {
                if (
                    (propertyHelper.isPropertyColorantMaxFill(activeHeadingId) &&
                        !validationHelper.isRangeValid(
                            this.props.activeData[0][propertyConstants.PROPERTY_COLORANT_MIN_FILL],
                            Number(value)
                        )) ||
                    (propertyHelper.isPropertyColorantMinFill(activeHeadingId) &&
                        !validationHelper.isRangeValid(
                            Number(value),
                            this.props.activeData[0][propertyConstants.PROPERTY_COLORANT_MAX_FILL]
                        ))
                ) {
                    value = this.state.originalCellContent;
                }

                break;
            }
            default: {
                break;
            }
        }

        // Check required properties
        if (heading?.[tableConstants.TABLE_HEADING_REQUIRED] && value === null) {
            value = this.state.originalCellContent;
        }

        // Edit item only if the value differs from the original one
        if (
            (value?.toString() ?? "") !== this.state.originalCellContent ||
            priceProperty === propertyConstants.PROPERTY_OVERWRITE
        ) {
            // Handle prices
            if (price && headingId !== undefined && rowId !== undefined) {
                if (
                    objectType === objectTypes.SYSTEM_PRICE_GENERIC ||
                    objectType === objectTypes.SYSTEM_PRICE_GROUP ||
                    objectType === objectTypes.ZONE_PRICE_GENERIC ||
                    objectType === objectTypes.ZONE_PRICE_GROUP
                ) {
                    price[headingId] = value;
                    this.handleItemEdit(price[propertyConstants.PROPERTY_ID], { [rowId]: price });
                } else {
                    price[priceProperty] = value;
                    this.handleItemEdit(rowId, { prices: [price] });
                }
            }

            // Handle color RGB
            const isPropertyColor = propertyHelper.isPropertyColorRedGreenBlue(activeHeadingId);
            if (isPropertyColor && activeRowId !== null && activeHeadingId !== null) {
                const rgbValue = formattingHelper.formatRgbHex(
                    activeHeadingId,
                    value,
                    this.props.activeData?.[0] || null
                );
                this.handleItemEdit(activeRowId, {
                    [propertyConstants.PROPERTY_COLOR_RGB]: rgbValue
                });
            }

            // Handle ufi code
            const isPropertyUfiCode = propertyHelper.isPropertyUfiCode(activeHeadingId);
            if (
                isPropertyUfiCode &&
                activeRowId !== null &&
                activeHeadingId !== null &&
                validationHelper.isUfiCodeValid(value !== null ? value.toString() : null)
            ) {
                this.handleItemEdit(activeRowId, {
                    [activeHeadingId]: value
                });
            }

            // Handle uuid
            const isPropertyUuid = propertyHelper.isPropertyUuid(activeHeadingId);
            if (
                isPropertyUuid &&
                activeRowId !== null &&
                activeHeadingId !== null &&
                validationHelper.isUuidValid(value !== null ? value.toString() : null)
            ) {
                this.handleItemEdit(activeRowId, {
                    [activeHeadingId]: value
                });
            }

            // Handle barcodes
            // Sending only custom barcodes to server, not the inherited ones
            const isPropertyBarcode = propertyHelper.isPropertyBarcodes(activeHeadingId);
            if (
                isPropertyBarcode &&
                activeRowId !== null &&
                activeHeadingId !== null &&
                this.props.activeData &&
                this.props.activeData.length
            ) {
                let allBarcodes = requestHelper.getAllBarcodesForServer(
                    this.props.activeData[0][activeHeadingId],
                    activeHeadingId,
                    value
                );

                if (
                    activeHeadingId === propertyConstants.PROPERTY_BARCODE &&
                    this.props.activeData?.[0]?.[propertyConstants.PROPERTY_ALTERNATIVE_BARCODES].length
                ) {
                    allBarcodes = allBarcodes.concat(
                        this.props.activeData?.[0]?.[propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]
                    );
                }

                if (
                    activeHeadingId === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES &&
                    this.props.activeData?.[0]?.[propertyConstants.PROPERTY_BARCODES].length
                ) {
                    allBarcodes = allBarcodes.concat(this.props.activeData?.[0]?.[propertyConstants.PROPERTY_BARCODES]);
                }

                this.handleItemEdit(activeRowId, { [propertyConstants.PROPERTY_BARCODES]: allBarcodes });
            }

            // Handle formula colorant amount
            const isFormulaColorant =
                objectType === objectTypes.MASTER_FORMULA_COLORANT &&
                activeHeadingId === propertyConstants.PROPERTY_AMOUNT;
            if (isFormulaColorant && activeRowId) {
                const colorantList = this.props.data || [];
                const colorant =
                    colorantList.find((item: FormulaColorant) => item[propertyConstants.PROPERTY_ID] === activeRowId) ||
                    null;

                if (colorant) {
                    colorant[propertyConstants.PROPERTY_AMOUNT] = value;
                }

                this.handleItemEdit(activeRowId, { [propertyConstants.PROPERTY_COLORANT_LIST]: colorantList });
            }

            if (
                price === undefined &&
                !isFormulaColorant &&
                !isPropertyBarcode &&
                !isPropertyColor &&
                !isPropertyUfiCode &&
                !isPropertyUuid &&
                activeHeadingId !== null &&
                activeRowId !== null
            ) {
                this.handleItemEdit(activeRowId, { [activeHeadingId]: value });
            } else if (price === undefined && !isPropertyColor && rowId !== undefined && headingId !== undefined) {
                // Handles checkbox and selectbox, as there is no focus event
                this.handleItemEdit(rowId, { [headingId]: value });
            }
        }

        this.setState({
            activeHeadingId: null,
            activeRowId: null,
            cellContent: "",
            originalCellContent: ""
        });
    };

    handleItemEdit = (itemId: number | string, params: Record<string, any>): any => {
        const { objectType, callbackEdit } = this.props;

        // Handling formula colorants as there should be no server request
        if (objectType === objectTypes.MASTER_FORMULA_COLORANT && callbackEdit) {
            callbackEdit(
                propertyConstants.PROPERTY_COLORANT_LIST,
                params?.[propertyConstants.PROPERTY_COLORANT_LIST] || []
            );
        }

        // General edit
        if (objectType !== objectTypes.MASTER_FORMULA_COLORANT) {
            this.props.editItem(objectType, {
                [requestHelper.getObjectIdProperty(objectType)]: itemId,
                data: generalHelper.updateObjectData(params)
            });
        }
    };

    getTableLayoutClassName = (): string => {
        let tableClass = "table-tooltip-container";

        if (privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            tableClass += " readonly-table";
        }

        if (this.props.disableColumnResizing) {
            tableClass += " no-resize";
        }

        return tableClass;
    };

    getTableHead = (): JSX.Element | null => {
        if (!objectTypes.HIDDEN_TABLE_HEADER_OBJECT_TYPES.includes(this.props.objectType)) {
            const className = this.props.showFilterRow ? "thead" : "thead filter-hidden";

            return (
                <div className={className}>
                    <TableHeadingRow
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        privileges={this.props.privileges}
                        loading={this.props.loading}
                        headings={this.props.headings}
                        data={this.props.data}
                        itemId={this.props.itemId}
                        showFilterRow={this.props.showFilterRow}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        isOptionMenuOpened={this.state.isOptionMenuOpened}
                        isResizable={!this.props.disableColumnResizing ?? true}
                        tableRef={this._tableRef ?? null}
                        callbackMouseEnter={(property: propertyConstants.Property): void =>
                            this.handleHeadingMouseEnter(property)
                        }
                        callbackMouseLeave={(): void => this.handleHeadingMouseLeave()}
                    />
                    <TableFilterRow
                        objectType={this.props.objectType}
                        modalId={this.props.modalId}
                        headings={this.props.headings}
                        isVisible={this.props.showFilterRow}
                        searchParams={this.props.searchParams}
                        setSearch={this.props.setSearch}
                    />
                </div>
            );
        }

        return null;
    };

    getTableBody = (): Array<JSX.Element> => {
        const { dataPrivileges, data } = this.props;
        const rows: Array<JSX.Element> = [];
        let rowIndex = 0;

        if (!dataPrivileges?.canView) {
            return rows;
        }

        if (this.props.loading) {
            return [
                <div className="loading" key="loading">
                    <div className="loader">
                        <ReactSVG className="loader-svg" src={imgLoader} />
                    </div>
                </div>
            ];
        }

        if (data.length === 0) {
            return [
                <div className="table-nothing" key="nothing">
                    <div>{translate("general.noResult")}</div>
                </div>
            ];
        }

        for (const item of data) {
            const row = (
                <TableItemRow
                    key={item[propertyConstants.PROPERTY_ID]}
                    objectType={this.props.objectType}
                    dataPrivileges={dataPrivileges}
                    headings={this.props.headings}
                    item={item}
                    activeContent={this.state.cellContent}
                    activeProperty={this.state.activeHeadingId}
                    currency={this.props.currency}
                    modalProperties={this.props.modalProperties}
                    isRowActive={this.props.activeData.find(
                        (activeItem: any) =>
                            activeItem[propertyConstants.PROPERTY_ID] === item[propertyConstants.PROPERTY_ID]
                    )}
                    isRowFirst={rowIndex === 0}
                    isRowFocused={this.state.activeRowId === item[propertyConstants.PROPERTY_ID]}
                    isRowLast={rowIndex === data.length - 1}
                    callbackBlur={(
                        event: any,
                        value: any,
                        rowId?: number | string,
                        headingId?: propertyConstants.Property,
                        price?: Price
                    ): void => this.handleItemRowBlur(event, value, rowId, headingId, price)}
                    callbackChange={(isValid: boolean, value: string): void => this.handleItemRowChange(isValid, value)}
                    callbackClick={(event: any): void => this.handleItemRowClick(event, item)}
                    callbackDoubleClick={(event: any): void => this.handleItemRowDoubleClick(event, item)}
                    callbackFocus={(
                        property: propertyConstants.Property,
                        itemId: number | string,
                        value: string
                    ): void => this.handleItemRowFocus(property, itemId, value)}
                    callbackMouseEnter={(
                        event: any,
                        heading: TableHeading,
                        value: any,
                        isSourceChanged: boolean
                    ): void => this.handleItemRowMouseEnter(event, heading, item, value, isSourceChanged)}
                    callbackMouseLeave={(): void => this.handleItemRowMouseLeave()}
                />
            );

            rowIndex++;

            if (row) {
                rows.push(row);
            }
        }

        return rows;
    };

    // TODO mozne zjednoduseni uz ve statu? nebo tady, s popiskama kdy se co deje
    getTooltipContent = (): JSX.Element | string => {
        const { tooltipText, tooltipParentText } = this.state;
        const { objectType } = this.props;

        if (tooltipParentText === null) {
            return tooltipText;
        }

        let className = "";
        let caption = "";

        if (objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType)) {
            className = "master-value-container";
            caption = `${translate("database.master")}:`;
        }

        if (objectTypes.ZONE_OBJECT_TYPES.includes(objectType)) {
            className = "system-value-container";
            caption = `${translate("system.system")}:`;
        }

        if (this.state.tooltipOn) {
            return (
                <div>
                    <div className={className}>
                        <span>{caption}</span>
                        {tooltipParentText}
                    </div>
                    <div className="overflowed-text">{tooltipText}</div>
                </div>
            );
        }

        return (
            <div>
                <div className={className}>
                    <span>{caption}</span>
                    {tooltipParentText}
                </div>
            </div>
        );
    };

    getTooltip = (): JSX.Element | null => {
        const { tooltipText, tooltipParentText } = this.state;

        if (this.state.tooltipOn || tooltipParentText !== null) {
            if (!(typeof tooltipText === "string")) {
                return (
                    <ReactTooltip
                        id="image-tooltip"
                        className="tooltip tooltip-image"
                        place="top"
                        getContent={(): JSX.Element => tooltipText}
                    />
                );
            }

            return (
                <ReactTooltip
                    id="table-cell-tooltip"
                    className="tooltip"
                    place="top"
                    effect="solid"
                    multiline={true}
                    html={tooltipParentText === null}
                    getContent={(): JSX.Element | string => this.getTooltipContent()}
                />
            );
        }

        if (this.state.tooltipHintOn) {
            return (
                <ReactTooltip
                    id="header-tooltip"
                    className="tooltip"
                    place="top"
                    delayShow={1000}
                    getContent={(): JSX.Element | string => tooltipText}
                />
            );
        }

        return null;
    };

    getActionRow = (): JSX.Element | null => {
        return (
            <TableActionRow
                tableConstant={this.props.tableConstant}
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                title={this.props.title || null}
                titleClassName={this.props.titleClassName || null}
                headings={this.props.headings}
                data={this.props.data}
                activeData={this.props.activeData}
                additionalData={this.props.additionalData}
                loading={this.props.loading || this.props.buttonLoading}
                dateFrom={this.props.dateFrom}
                dateTo={this.props.dateTo}
                rowCount={this.props.rowCount}
                rowCountCustom={this.props.rowCountCustom ?? tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                search={this.props.search}
                showFilterRow={this.props.showFilterRow}
                showGlobalSearch={this.props.showGlobalSearch}
                showAnotherSpecificValues={this.props.showAnotherSpecificValues}
                showSpecificValues={this.props.showSpecificValues}
                modalProperties={this.props.modalProperties}
                callbackOptionMenuOpened={(opened: boolean) => this.setState({ isOptionMenuOpened: opened })}
            />
        );
    };

    getActionFooterRow = (): JSX.Element | null => {
        return (
            <TableActionFooterRow
                objectType={this.props.objectType}
                dataPrivileges={this.props.dataPrivileges}
                data={this.props.data}
                loading={this.props.loading}
            />
        );
    };

    getTableFooter = (): JSX.Element | null => {
        return (
            <TableFooterRow
                dataPrivileges={this.props.dataPrivileges}
                objectType={this.props.objectType}
                visible={this.props.showFooterRow ?? false}
                data={this.props.data}
                activeUnit={this.props.activeUnit}
            />
        );
    };

    getTablePagination = (): JSX.Element => {
        return (
            <TablePagination
                objectType={this.props.objectType}
                totalCount={this.props.totalCount}
                offset={this.props.offset}
                page={this.props.page}
                rowCount={this.props.rowCount}
            />
        );
    };

    render(): JSX.Element {
        const className =
            this._tableRef?.current !== null &&
            this._tableRef.current.clientHeight < this._tableRef.current.scrollHeight
                ? "table slim-scroll scrolling"
                : "table slim-scroll";
        return (
            <div className={this.props.className}>
                {this.getActionRow()}
                <div className={this.getTableLayoutClassName()}>
                    <div className={className} id={this.props.objectType} ref={this._tableRef}>
                        {this.getTableHead()}
                        <div className="tbody">{this.getTableBody()}</div>
                    </div>
                    {this.getTableFooter()}
                    {this.getTooltip()}
                </div>
                {this.getTablePagination()}
                {this.getActionFooterRow()}
            </div>
        );
    }
}

export type PropsType = Readonly<{
    custom: string | null;
    deleteConfirmationOption: Option | null;
    modalList: Array<Modal>;
    privileges: Record<string, number>;
    sessionUuid: string | null;
    urlRest: string;
    activeUnit: Unit | null;
}>;

export type DispatchType = Readonly<{
    abortExportTask(taskId: number): any;
    abortImportTask(taskId: number): any;
    deleteItem(objectType: objectTypes.ObjectType, params: any): any;
    editItem(objectType: objectTypes.ObjectType, params: any): any;
    openModal(type: ModalType, params: any): any;
    saveList(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setActive(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setSearch(objectType: objectTypes.ObjectType, search: string | null, column?: string): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    deleteConfirmationOption: generalHelper.getObjectByKey(
        state.login.options,
        optionsConstants.OPTION_DELETE_CONFIRMATION
    ),
    modalList: state.navigation.modalList,
    privileges: state.login.privileges,
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest,
    activeUnit:
        state.color.unitAllList.find(
            (item: Unit) =>
                optionHelper.getValueFromOptionDictionaryByKey(
                    state.login.options,
                    optionsConstants.OPTION_FORMULA_COLORANT_UNIT
                ) === item[propertyConstants.PROPERTY_NAME]
        ) ?? null
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    abortExportTask: (taskId: number): any => dispatch(importExportActions.abortExportTask(taskId)),
    abortImportTask: (taskId: number): any => dispatch(importExportActions.abortImportTask(taskId)),
    deleteItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.deleteItem(objectType, params)),
    editItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.editItem(objectType, false, params)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params)),
    saveList: (objectType: objectTypes.ObjectType, items: Array<any>): any => {
        dispatch(universalObjectActions.saveItems(objectType, items));
    },
    setActive: (objectType: objectTypes.ObjectType, items: Array<any>): any =>
        dispatch(universalObjectActions.setActiveItems(objectType, items)),
    setSearch: (objectType: objectTypes.ObjectType, search: string | null, column?: string): any =>
        dispatch(universalObjectActions.setSearch(objectType, search, column))
});

export const TableContainer = connect(mapStateToProps, mapDispatchToProps)(Table);
