import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionHelper from "../../helpers/optionHelper";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Option, mapOption } from "../../types/option";
import { RedlikeOption, mapRedlikeOption } from "../../types/redlikeOption";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_OPTIONS: {
            const optionlist: Array<RedlikeOption> = [];
            let option: RedlikeOption | null;

            for (const item in response.data) {
                option = mapRedlikeOption(response.data[item], state.software.custom);

                if (option) {
                    optionlist.push(option);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.MASTER_REDLIKE_OPTION, optionlist)];
        }
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS: {
            const optionlist: Array<RedlikeOption> = [];
            let option: RedlikeOption | null;

            for (const item in response.data) {
                option = mapRedlikeOption(response.data[item], state.software.custom);

                if (option) {
                    optionlist.push(option);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.SYSTEM_REDLIKE_OPTION, optionlist)];
        }
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_OPTIONS: {
            const optionlist: Array<RedlikeOption> = [];
            let option: RedlikeOption | null;

            for (const item in response.data) {
                option = mapRedlikeOption(response.data[item], state.software.custom);

                if (option) {
                    optionlist.push(option);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.ZONE_REDLIKE_OPTION, optionlist)];
        }
        case methods.METHOD_SET_MASTER_REDLIKE_OPTION:
        case methods.METHOD_SET_MASTER_REDLIKE_OPTION_OVERWRITE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_REDLIKE_OPTIONS_EDIT)
            ) {
                requests.push(universalObjectActions.getAllItems(objectTypes.MASTER_REDLIKE_OPTION));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_OPTIONS_EDIT));
            } else {
                const option = mapRedlikeOption(response.data);

                if (option) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_REDLIKE_OPTION, [option]));
                }
            }

            return requests;
        }
        case methods.METHOD_SET_SYSTEM_REDLIKE_OPTION:
        case methods.METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_REDLIKE_OPTIONS_EDIT)
            ) {
                requests.push(universalObjectActions.getAllItems(objectTypes.SYSTEM_REDLIKE_OPTION));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_OPTIONS_EDIT));
            } else {
                const option = mapRedlikeOption(response.data);

                if (option) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_REDLIKE_OPTION, [option]));
                }
            }

            return requests;
        }
        case methods.METHOD_SET_ZONE_REDLIKE_OPTION:
        case methods.METHOD_SET_ZONE_REDLIKE_OPTION_OVERWRITE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_REDLIKE_OPTIONS_EDIT)
            ) {
                requests.push(universalObjectActions.getAllItems(objectTypes.ZONE_REDLIKE_OPTION));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_OPTIONS_EDIT));
            } else {
                const option = mapRedlikeOption(response.data);

                if (option) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_REDLIKE_OPTION, [option]));
                }
            }

            return requests;
        }
        case methods.METHOD_RESET_ALL_MASTER_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_MASTER_REDLIKE_OPTION: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.getAllItems(objectTypes.MASTER_REDLIKE_OPTION)
            ];
        }
        case methods.METHOD_RESET_ALL_SYSTEM_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_SYSTEM_REDLIKE_OPTION: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.getAllItems(objectTypes.SYSTEM_REDLIKE_OPTION)
            ];
        }
        case methods.METHOD_RESET_ALL_ZONE_REDLIKE_OPTIONS:
        case methods.METHOD_RESET_ZONE_REDLIKE_OPTION: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.getAllItems(objectTypes.ZONE_REDLIKE_OPTION)
            ];
        }
        case methods.METHOD_GET_ALL_GLOBAL_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let item: any;
            const optionList: Array<Option> = [];
            let newOption: Option | null;

            for (item of Object.values(response.data)) {
                newOption = mapOption({
                    ...item,
                    value: optionHelper.getUpdatedOptions(item.key, item.value)
                });

                if (newOption) {
                    optionList.push(newOption);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.GLOBAL_OPTION, optionList)];
        }
        case methods.METHOD_GET_ALL_USER_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let item: any;
            const optionList: Array<Option> = [];
            let newOption: Option | null;

            for (item of Object.values(response.data)) {
                newOption = mapOption({
                    ...item,
                    value: optionHelper.getUpdatedOptions(item.key, item.value)
                });

                if (newOption) {
                    optionList.push(newOption);
                }
            }
            return [universalObjectActions.saveItems(objectTypes.USER_OPTION, optionList)];
        }
        case methods.METHOD_SET_GLOBAL_OPTION:
        case methods.METHOD_SET_GLOBAL_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            return [universalObjectActions.getAllItems(objectTypes.GLOBAL_OPTION)];
        }

        case methods.METHOD_SET_USER_OPTION:
        case methods.METHOD_SET_USER_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            return [universalObjectActions.getAllItems(objectTypes.USER_OPTION)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
