import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Fandeck } from "../../../types/fandeck";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { ZoneFandeckScene } from "../../../components/scenes/zone/ZoneFandeckScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    fandeckTableConstant: string;
    fandeckList: Array<Fandeck>;
    fandeckActiveList: Array<Fandeck>;
    fandeckLoading: boolean;
    fandeckButtonLoading: boolean;
    fandeckAvailableCount: number | null;
    fandeckColumnOrder: Array<propertyConstants.Property>;
    fandeckColumnVisibility: Record<propertyConstants.Property, boolean>;
    fandeckColumnWidth: Record<propertyConstants.Property, number>;
    fandeckOffset: number;
    fandeckPage: number;
    fandeckRowCount: number;
    fandeckRowCountCustom: boolean;
    fandeckSceneTableSize: Record<string, any> | null;
    fandeckSearch: string | null;
    fandeckSearchParams: Record<propertyConstants.Property, string>;
    fandeckShowFilterRow: boolean;
    fandeckShowGlobalSearch: boolean;
    fandeckSortingAsc: boolean;
    fandeckSortingCriterion: propertyConstants.Property | null;
    fandeckTotalCount: number | null;
    optionUseLargoNames: boolean;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_FANDECKS
    ),
    fandeckTableConstant: menuConstants.TABLE_ZONE_FANDECKS,
    fandeckList: state.fandeck.zoneList,
    fandeckActiveList: state.fandeck.zoneActiveList,
    fandeckLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_FANDECKS ||
            request.method === methods.METHOD_DELETE_ZONE_FANDECK
    ),
    fandeckButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ZONE_FANDECK_BY_ID ||
            request.method === methods.METHOD_EDIT_ZONE_FANDECK
    ),
    fandeckAvailableCount: state.fandeck.zoneCountAvailable,
    fandeckColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckOffset: state.fandeck.zoneOffset,
    fandeckPage: state.fandeck.zonePage,
    fandeckRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_ZONE_FANDECKS
    ),
    fandeckSearch: state.fandeck.zoneSearch,
    fandeckSearchParams: state.fandeck.zoneSearchParams,
    fandeckShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_FANDECKS
    ),
    fandeckSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_FANDECKS
        )
    ),
    fandeckSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_FANDECKS
        )
    ),
    fandeckTotalCount: state.fandeck.zoneCountTotal,
    optionUseLargoNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_LARGO_NAMES
    ),
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_ZONE_FANDECKS
    )
});

export const ZoneFandeckSceneContainer = connect(mapStateToProps)(ZoneFandeckScene);
