import * as importExportTaskHelper from "../../../../helpers/importExportTaskHelper";
import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import { ImportTask, generateImportTaskData, generateImportTaskHeadings } from "../../../../types/importTask";
import React, { Component } from "react";
import { createEmptyExportImportObject, generateDeleteExportImportHeadings } from "../../../../types/exportImportTask";

import { Database } from "../../../../types/database";
import { ImportStatus } from "../../../../types/importStatus";
import { ImportType } from "../../../../types/importType";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    privileges: any;
    loading: boolean;
    databaseSystemZoneList: Array<Database>;
    importStatusList: Array<ImportStatus>;
    importTypeList: Array<ImportType>;
    allList: Array<ImportTask>;
    activeList: Array<ImportTask>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showDeletedValues: boolean;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    showHiddenValues: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class ImportTaskTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const requiredList: Array<propertyConstants.Property> = [];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID];
        const editableList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_IS_CHECKED];
        let visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID,
            propertyConstants.PROPERTY_ACTION,
            propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID,
            propertyConstants.PROPERTY_DATABASE_NAME,
            propertyConstants.PROPERTY_SYSTEM_NAME,
            propertyConstants.PROPERTY_SYSTEM_UUID,
            propertyConstants.PROPERTY_ZONE_NAME,
            propertyConstants.PROPERTY_ZONE_UUID,
            propertyConstants.PROPERTY_DATE_CREATED,
            propertyConstants.PROPERTY_DATE_FINISHED,
            propertyConstants.PROPERTY_UUID
        ];

        if (
            objectTypeHelper.isObjectTypeExportImport(this.props.objectType) &&
            privilegesHelper.isDataPrivilegesCanDelete(this.props.dataPrivileges) &&
            importExportTaskHelper.isDeletingImportExportTasksEnabled(this.props.objectType, this.props.privileges, [])
        ) {
            visibleList.push(propertyConstants.PROPERTY_IS_CHECKED);
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateImportTaskHeadings(
            this.props.objectType,
            this.props.importStatusList,
            this.props.importTypeList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateDeleteImportTasksModalParams = (): ModalParams => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_DATE_FROM,
            propertyConstants.PROPERTY_DATE_TO,
            propertyConstants.PROPERTY_DATABASE_ID,
            propertyConstants.PROPERTY_SYSTEM_ID,
            propertyConstants.PROPERTY_ZONE_ID,
            propertyConstants.PROPERTY_STATUS_LIST
        ];

        return {
            type: modalTypes.DELETE_MODAL,
            title: translate("import.deleteImports"),
            objectType: objectTypes.IMPORT_TASK_DELETE,
            headings: generateDeleteExportImportHeadings(
                objectTypes.IMPORT_TASK_DELETE,
                modalTypes.MODAL_IMPORT_TASKS_DELETE,
                this.props.databaseSystemZoneList,
                [],
                this.props.importStatusList,
                propertyList,
                propertyList,
                propertyList,
                [propertyConstants.PROPERTY_DATE_FROM, propertyConstants.PROPERTY_DATE_TO],
                propertyList
            ),
            data: createEmptyExportImportObject(objectTypes.IMPORT_TASK_DELETE, [], this.props.importStatusList)
        };
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            deleteModalType: modalTypes.MODAL_IMPORT_TASKS_DELETE,
            deleteModalParams: this.generateDeleteImportTasksModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter-and-footer-buttons"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generateImportTaskData(this.props.objectType, this.props.allList)}
                    activeData={this.props.activeList}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showAnotherSpecificValues={this.props.showDeletedValues}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    showSpecificValues={this.props.showHiddenValues}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
