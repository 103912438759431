import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as keyCodes from "../../../../constants/keyCodes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as priceHelper from "../../../../helpers/priceHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as propertyHelper from "../../../../helpers/propertyHelper";
import * as tableConstants from "../../../../constants/tableConstants";
import * as validationHelper from "../../../../helpers/validationHelper";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import ContentEditable from "react-contenteditable";
import { Currency } from "../../../../types/currency";
import { Dispatch } from "redux";
import { Price } from "../../../../types/price";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

import imgLoader from "../../../../resources/img/mini-loader.svg";
import imgLock from "../../../../resources/img/icons/table-lock.svg";
import imgUnlock from "../../../../resources/img/icons/table-unlock.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    content: string;
    currency?: Currency | null;
    isActive: boolean;
    isEditable: boolean;
    isRowFirst: boolean;
    isRowLast: boolean;
    isSourceChanged: boolean;
    callbackBlur: (
        event: any,
        value: any,
        rowId?: number | string,
        headingId?: propertyConstants.Property,
        price?: Price
    ) => any;
    callbackChange: (isValid: boolean, value: any) => any;
    callbackFocus: (value: any) => any;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellPrice extends Component<Props> {
    handleCellFocus = (value: number | null, price: Price | null): any => {
        const priceType = price?.[propertyConstants.PROPERTY_KEY] || null;
        let content = value?.toString() ?? "";

        if (propertyHelper.isPropertyVat(priceType || "")) {
            content = formattingHelper.formatServerPercentValue(value)?.toString() ?? "";
        }

        this.props.callbackFocus(content);
    };

    handleCellChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
        const content = validationHelper.formatStringFromHtml(event.target.value);
        const isValid = validationHelper.isDecimalValueValid(content);

        this.props.callbackChange(isValid, content);
    };

    handleCellKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        property: propertyConstants.Property,
        price: Price | null
    ): any => {
        switch (event.key) {
            case keyCodes.ENTER: {
                event.currentTarget.blur();
                break;
            }
            case keyCodes.ESCAPE: {
                if (price) {
                    this.props.callbackChange(true, this.getCellValue(property, price) ?? "");
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    handleCellBlur = (
        event: React.FocusEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        price: Price | null
    ): any => {
        const property = this.props.heading[tableConstants.TABLE_HEADING_NAME];
        const priceType = price?.[propertyConstants.PROPERTY_KEY] || null;

        let value: any = validationHelper.formatNumber(this.props.objectType, property, this.props.content);

        // Handle VAT items with % sign, others according to its unit
        if (propertyHelper.isPropertyVat(property || "")) {
            value = formattingHelper.formatFromPercent(value);
        } else {
            value = formattingHelper.formatPriceFromUnit(priceType || "", value);
        }
        // TODO Check increasing values in price groups
        value = value.trim() !== "" ? value : null;
        value = !isNaN(value) && value !== null ? Number(value) : null;

        if (price) {
            this.props.callbackBlur(
                event,
                value,
                this.getRowId(),
                this.getHeadingId(propertyConstants.PROPERTY_VALUE),
                price
            );
        }
    };

    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getRowId = (): number | string => {
        const { item, objectType } = this.props;

        if (
            objectType === objectTypes.SYSTEM_PRICE_GENERIC ||
            objectType === objectTypes.SYSTEM_PRICE_GROUP ||
            objectType === objectTypes.ZONE_PRICE_GENERIC ||
            objectType === objectTypes.ZONE_PRICE_GROUP
        ) {
            return item?.[propertyConstants.PROPERTY_KEY] ?? null;
        }

        return item?.[propertyConstants.PROPERTY_ID] ?? null;
    };

    getHeadingId = (property: propertyConstants.Property): propertyConstants.Property => {
        const { objectType } = this.props;

        if (
            objectType === objectTypes.SYSTEM_PRICE_GENERIC ||
            objectType === objectTypes.SYSTEM_PRICE_GROUP ||
            objectType === objectTypes.ZONE_PRICE_GENERIC ||
            objectType === objectTypes.ZONE_PRICE_GROUP
        ) {
            return property;
        }

        return this.props.heading[tableConstants.TABLE_HEADING_NAME];
    };

    getCellClassname = (property: propertyConstants.Property): string => {
        return `td td-edit ${property} price-cell`;
    };

    getContentClassname = (): string => {
        let className = "td-edit";

        if (this.isDisabled()) {
            className += " edit-disabled";
        }

        return className;
    };

    getEditableContentClassName = (price: Price | null): string => {
        const { objectType } = this.props;
        let className = "";

        if (objectType === objectTypes.SYSTEM_PRICE_GROUP || objectType === objectTypes.ZONE_PRICE_GROUP) {
            if (!price?.[propertyConstants.PROPERTY_CAN_UPDATE]) {
                className += " max-price-value";
            }
        }

        return className;
    };

    getLockClassname = (price: Price, editable: boolean): string => {
        let className = "table-icon";

        if (!editable) {
            className += " disabled";
        }

        if (price[propertyConstants.PROPERTY_OVERWRITE]) {
            className += " unlock";
        }

        return className;
    };

    getLockSource = (price: Price): string => {
        if (price[propertyConstants.PROPERTY_OVERWRITE]) {
            return imgUnlock;
        }

        return imgLock;
    };

    getLockIcon = (property: propertyConstants.Property, price: Price | null): JSX.Element | null => {
        const editable =
            this.props.heading[tableConstants.TABLE_HEADING_EDITING] && this.props.dataPrivileges?.canUpdate;

        if (
            property !== propertyConstants.PROPERTY_RANGE &&
            price?.[propertyConstants.PROPERTY_VALUE] !== null &&
            price?.[propertyConstants.PROPERTY_VALUE] !== undefined
        ) {
            if (editable) {
                return (
                    <div
                        onClick={(event: any): void =>
                            this.props.callbackBlur(
                                event,
                                !price[propertyConstants.PROPERTY_OVERWRITE],
                                this.getRowId(),
                                this.getHeadingId(propertyConstants.PROPERTY_OVERWRITE),
                                price
                            )
                        }
                    >
                        <ReactSVG className={this.getLockClassname(price, editable)} src={this.getLockSource(price)} />
                    </div>
                );
            } else {
                return (
                    <div>
                        <ReactSVG className={this.getLockClassname(price, editable)} src={this.getLockSource(price)} />
                    </div>
                );
            }
        }

        return null;
    };

    getCellContent = (property: propertyConstants.Property, price: Price | null, value: number | null): string => {
        const { objectType, currency } = this.props;

        if (price === null) {
            return "";
        }

        if (objectType === objectTypes.SYSTEM_PRICE_GROUP || objectType === objectTypes.ZONE_PRICE_GROUP) {
            if (property === propertyConstants.PROPERTY_VALUE && this.props.isRowLast) {
                return `[${translate("prices.maxValue")}]`;
            }
        }

        if (propertyHelper.isPropertyVat(price[propertyConstants.PROPERTY_KEY] || "")) {
            return value !== null ? formattingHelper.formatPercent(value) : `<span class="cell-unit"> %</span>`;
        }

        return `${
            value !== null ? formattingHelper.formatCurrencyDecimal(value, currency) : ""
        }<span class="cell-unit">${priceHelper.getPriceUnit(
            price[propertyConstants.PROPERTY_KEY] || "",
            currency?.[propertyConstants.PROPERTY_SYMBOL] || ""
        )}</span>`;
    };

    getCellValue = (property: propertyConstants.Property, price: any | null): number | null => {
        const { objectType } = this.props;
        let value = price?.[propertyConstants.PROPERTY_VALUE] || null;

        if (objectType === objectTypes.SYSTEM_PRICE_GROUP || objectType === objectTypes.ZONE_PRICE_GROUP) {
            if (property === propertyConstants.PROPERTY_RANGE) {
                value = price?.[propertyConstants.PROPERTY_RANGE] || null;
                value = Array.isArray(value) && value.length ? value[0] : null;

                if (this.props.isRowFirst) {
                    value = 0;
                }
            }
        }
        return value;
    };

    getCellInput = (property: propertyConstants.Property, price: any): JSX.Element => {
        const { item } = this.props;
        const value = this.getCellValue(property, price) ?? null;
        const content = this.props.isActive ? this.props.content : this.getCellContent(property, price, value ?? null);
        const editableHtml = `<span class="${this.getEditableContentClassName(price)}">${content}</span>`;

        if (item[property] === undefined) {
            return <ReactSVG src={imgLoader} className="svg-icon" />;
        }

        return (
            <ContentEditable
                className={this.getContentClassname()}
                disabled={this.isDisabled()}
                html={editableHtml}
                key={property}
                tagName={"div"}
                onFocus={(): void => this.handleCellFocus(value, price)}
                onChange={(event: any): void => this.handleCellChange(event)}
                onBlur={(event): void => this.handleCellBlur(event, price)}
                onKeyDown={(event): void => this.handleCellKeyDown(event, property, price)}
                onMouseEnter={(event: any): void => {
                    this.props.callbackMouseEnter(event, content);
                }}
                data-tip
                data-for={"table-cell-tooltip"}
            />
        );
    };

    // TODO neslo by sjednotit objectTypes.PRICES_OBJECT_TYPES a propertyConstants.PRICE_PROPERTIES
    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const price = objectTypes.PRICES_OBJECT_TYPES.includes(this.props.objectType)
            ? item && item[property] !== undefined
                ? item[property]
                : null
            : item;

        return (
            <div
                className={this.getCellClassname(property)}
                key={property}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
            >
                {this.getLockIcon(property, price)}
                {this.getCellInput(property, price)}
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellPriceContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellPrice);
