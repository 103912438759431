import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as serverMethods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export interface LicenseSuccess {
    type: typeof actionConstants.LICENSE_SUCCESS;
}

export function licenseSuccess(): LicenseSuccess {
    return {
        type: actionConstants.LICENSE_SUCCESS
    };
}

export interface SetStep {
    type: typeof actionConstants.LICENSE_SET_PROCESS_STEP;
    payload: {
        step: number;
    };
}

export function setStep(step: number): SetStep {
    return {
        type: actionConstants.LICENSE_SET_PROCESS_STEP,
        payload: {
            step
        }
    };
}

export interface SetLicenseOnlineActivation {
    type: typeof actionConstants.LICENSE_SET_ONLINE_ACTIVATION;
    payload: {
        onlineActivation: boolean;
    };
}

export function setLicenseOnlineActivation(onlineActivation: boolean): SetLicenseOnlineActivation {
    return {
        type: actionConstants.LICENSE_SET_ONLINE_ACTIVATION,
        payload: {
            onlineActivation
        }
    };
}

export function getLicenseStatus(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_GET_LICENSE_STATUS
        },
        method: serverMethods.METHOD_GET_LICENSE_STATUS
    };
}

export interface UpdateLicense {
    type: typeof actionConstants.LICENSE_UPDATE;
    payload: {
        params: Record<string, any>;
    };
}

export function updateLicense(params: Record<string, any>): UpdateLicense {
    return {
        type: actionConstants.LICENSE_UPDATE,
        payload: {
            params
        }
    };
}

export function updateOnlineLicense(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_UPDATE_ONLINE_LICENSE
        },
        method: serverMethods.METHOD_UPDATE_ONLINE_LICENSE
    };
}

export function getLicenseOnlineActivationAvailability(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_LICENSE_ONLINE_ACTIVATION_AVAILABILITY
        },
        method: methods.METHOD_GET_LICENSE_ONLINE_ACTIVATION_AVAILABILITY
    };
}

export function getLicenseOnlineCounts(key: string): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_LICENSE_ONLINE_COUNTS,
            params: {
                key: key
            }
        },
        method: methods.METHOD_GET_LICENSE_ONLINE_COUNTS
    };
}

export function getLicenseAvailableProducts(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_LICENSE_AVAILABLE_PRODUCTS
        },
        method: methods.METHOD_GET_LICENSE_AVAILABLE_PRODUCTS
    };
}

export function activateOnlineLicense(params: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_ACTIVATE_ONLINE_LICENSE,
            params: {
                ...params
            }
        },
        method: methods.METHOD_ACTIVATE_ONLINE_LICENSE
    };
}

export function getLicenseUuid(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_GET_LICENSE_UUID
        },
        method: serverMethods.METHOD_GET_LICENSE_UUID
    };
}

export function getLicenseInfo(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_GET_LICENSE_INFO
        },
        method: serverMethods.METHOD_GET_LICENSE_INFO
    };
}
