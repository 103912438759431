import * as propertyConstants from "../../../constants/propertyConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";

type Props = {
    className: string;
    editable: boolean;
    required: boolean;
    property: propertyConstants.Property;
    value: string | number;
    callbackChange: (value: string) => any;
    callbackBlur: (value: string) => any;
};

export class ModalTextArea extends Component<Props> {
    handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
        event.target.select();
    };

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const { callbackChange } = this.props;

        const content = validationHelper.formatStringFromHtml(event.target.value);

        callbackChange(content);
    };

    getClassName = (): string => {
        let newClassName = this.props.className;

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    render(): JSX.Element {
        const { editable, property, callbackBlur } = this.props;

        return (
            <div className={this.getClassName()}>
                <textarea
                    className="slim-scroll"
                    id={property}
                    value={this.props.value}
                    readOnly={!editable}
                    disabled={!editable}
                    onFocus={(event): void => this.handleFocus(event)}
                    onChange={(event): void => this.handleChange(event)}
                    onBlur={(event: any): void => callbackBlur(event.target.value)}
                />
            </div>
        );
    }
}
