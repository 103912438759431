import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { PropsType } from "../../../containers/scenes/header/UserActionsHistorySceneContainer";
import { SceneSaveButtonContainer } from "../../general/scene/buttons/SceneSaveButton";
import { UserActionsHistoryTable } from "./tables/UserActionsHistoryTable";

type Props = PropsType;

export const UserActionsHistoryScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableActionHistory, setTableActionHistory] = useState<DataItem | null>(
        (props.dataPrivileges
            ? props.dataPrivileges.find((item: DataItem) => item.key === props.actionHistoryTableConstant)
            : null) ?? null
    );
    useEffect(() => {
        setTableActionHistory(
            (props.dataPrivileges
                ? props.dataPrivileges.find((item: DataItem) => item.key === props.actionHistoryTableConstant)
                : null) ?? null
        );
    }, [props.dataPrivileges, props.actionHistoryTableConstant]);

    const getUserActionHistoryTable = (): JSX.Element | null => {
        return (
            <div className="layout-item" key={props.actionHistoryTableConstant}>
                <UserActionsHistoryTable
                    tableConstant={props.actionHistoryTableConstant}
                    objectType={objectTypes.USER_ACTION_HISTORY}
                    dataPrivileges={tableActionHistory}
                    loading={props.actionHistoryLoading}
                    allList={props.actionHistoryList}
                    activeList={props.actionHistoryActiveList}
                    columnOrder={props.actionHistoryColumnOrder}
                    columnVisibility={props.actionHistoryColumnVisibility}
                    columnWidth={props.actionHistoryColumnWidth}
                    dateFrom={props.actionHistoryDateFrom}
                    dateTo={props.actionHistoryDateTo}
                    offset={props.actionHistoryOffset}
                    page={props.actionHistoryPage}
                    rowCount={props.actionHistoryRowCount}
                    rowCountCustom={props.actionHistoryRowCountCustom}
                    search={props.actionHistorySearch}
                    searchParams={props.actionHistorySearchParams}
                    showFilterRow={props.actionHistoryShowFilterRow}
                    showGlobalSearch={props.actionHistoryShowGlobalSearch}
                    sortingAsc={props.actionHistorySortingAsc}
                    sortingCriterion={props.actionHistorySortingCriterion}
                    totalCount={props.actionHistoryTotalCount}
                    custom={props.custom}
                />
            </div>
        );
    };

    return (
        <div className="height-100">
            <SceneSaveButtonContainer />
            <div className="content-layout">{getUserActionHistoryTable()}</div>
        </div>
    );
};
