import * as priceConstants from "./entityConstants/priceConstants";

export type Property =
    | typeof PROPERTY_ACTION
    | typeof PROPERTY_ACTION_METHOD
    | typeof PROPERTY_ACTION_TYPE
    | typeof PROPERTY_ACTION_TYPE_ID
    | typeof PROPERTY_ADD_CURRENCY
    | typeof PROPERTY_ADDITIONAL
    | typeof PROPERTY_ADDITIONAL_PROPERTIES
    | typeof PROPERTY_ALTERNATIVE_BARCODES
    | typeof PROPERTY_AMOUNT
    | typeof PROPERTY_AMOUNT_COEFFICIENT
    | typeof PROPERTY_AUTOLOGIN
    | typeof PROPERTY_AVAILABLE
    | typeof PROPERTY_AVAILABLE_HDD
    | typeof PROPERTY_AVAILABLE_SSD
    | typeof PROPERTY_BARCODE
    | typeof PROPERTY_BARCODES
    | typeof PROPERTY_BASE_AMOUNT
    | typeof PROPERTY_BASE_ID
    | typeof PROPERTY_BASE_IN_PRODUCT_ID
    | typeof PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID
    | typeof PROPERTY_BASE_NAME
    | typeof PROPERTY_BASE_NAME_MASTER
    | typeof PROPERTY_BASE_NAME_SYSTEM
    | typeof PROPERTY_BATCH_ID
    | typeof PROPERTY_BATCH_NUMBER
    | typeof PROPERTY_BEGIN_VALUE
    | typeof PROPERTY_BFS
    | typeof PROPERTY_BFS_MASTER
    | typeof PROPERTY_BFS_SOURCE
    | typeof PROPERTY_BFS_SYSTEM
    | typeof PROPERTY_CAN_DELETE
    | typeof PROPERTY_CAN_DOWNLOAD
    | typeof PROPERTY_CAN_HIDE
    | typeof PROPERTY_CAN_UPDATE
    | typeof PROPERTY_CAN_VIEW
    | typeof PROPERTY_CAPTION
    | typeof PROPERTY_CATEGORY
    | typeof PROPERTY_CHANGELOG
    | typeof PROPERTY_CITY
    | typeof PROPERTY_COLORANT_MAX_FILL
    | typeof PROPERTY_COLORANT_MAX_FILL_MASTER
    | typeof PROPERTY_COLORANT_MAX_FILL_SOURCE
    | typeof PROPERTY_COLORANT_MAX_FILL_SYSTEM
    | typeof PROPERTY_COLORANT_MIN_FILL
    | typeof PROPERTY_COLORANT_MIN_FILL_MASTER
    | typeof PROPERTY_COLORANT_MIN_FILL_SOURCE
    | typeof PROPERTY_COLORANT_MIN_FILL_SYSTEM
    | typeof PROPERTY_CODE
    | typeof PROPERTY_CODE_MASTER
    | typeof PROPERTY_CODE_SOURCE
    | typeof PROPERTY_CODE_SYSTEM
    | typeof PROPERTY_COEFFICIENT
    | typeof PROPERTY_COEFFICIENT_MASTER
    | typeof PROPERTY_COEFFICIENT_SOURCE
    | typeof PROPERTY_COEFFICIENT_SYSTEM
    | typeof PROPERTY_COLOR_BLUE
    | typeof PROPERTY_COLOR_BLUE_MASTER
    | typeof PROPERTY_COLOR_BLUE_SOURCE
    | typeof PROPERTY_COLOR_BLUE_SYSTEM
    | typeof PROPERTY_COLOR_CODE
    | typeof PROPERTY_COLOR_DATA
    | typeof PROPERTY_COLOR_GREEN
    | typeof PROPERTY_COLOR_GREEN_MASTER
    | typeof PROPERTY_COLOR_GREEN_SOURCE
    | typeof PROPERTY_COLOR_GREEN_SYSTEM
    | typeof PROPERTY_COLOR_IN_FANDECK_ID
    | typeof PROPERTY_COLOR_NAME
    | typeof PROPERTY_COLOR_RED
    | typeof PROPERTY_COLOR_RED_MASTER
    | typeof PROPERTY_COLOR_RED_SOURCE
    | typeof PROPERTY_COLOR_RED_SYSTEM
    | typeof PROPERTY_COLOR_RGB_HEX
    | typeof PROPERTY_COLOR_RGB_HEX_MASTER
    | typeof PROPERTY_COLOR_RGB_HEX_SOURCE
    | typeof PROPERTY_COLOR_RGB_HEX_SYSTEM
    | typeof PROPERTY_COLOR_RGB_INT
    | typeof PROPERTY_COLOR_RGB_INT_MASTER
    | typeof PROPERTY_COLOR_RGB_INT_SOURCE
    | typeof PROPERTY_COLOR_RGB_INT_SYSTEM
    | typeof PROPERTY_COLOR_MORE_INFO
    | typeof PROPERTY_COLOR_PRIORITY
    | typeof PROPERTY_COLOR_SEARCH_CODE
    | typeof PROPERTY_COLOR_SEARCH_NAME
    | typeof PROPERTY_COLORS_WITHOUT_FORMULAS
    | typeof PROPERTY_COLORANT_ID
    | typeof PROPERTY_COLORANT_NAME
    | typeof PROPERTY_COLORANT_LIST
    | typeof PROPERTY_COLORANT_PACKAGE_ID
    | typeof PROPERTY_COLORANT_SORTING_ASC
    | typeof PROPERTY_COLORANT_SORTING_CRITERION
    | typeof PROPERTY_COMPANY_ID
    | typeof PROPERTY_COMPANY_KEY
    | typeof PROPERTY_COMPANY_NAME
    | typeof PROPERTY_COMPUTER_ID
    | typeof PROPERTY_CONTACT_PERSON
    | typeof PROPERTY_COPY_TO_CLIPBOARD_ACTION
    | typeof PROPERTY_COUNTRY
    | typeof PROPERTY_CURRENCY_ID
    | typeof PROPERTY_CUSTOM_FORMULA
    | typeof PROPERTY_CUSTOM_FORMULA_MASTER
    | typeof PROPERTY_CUSTOM_FORMULA_SOURCE
    | typeof PROPERTY_CUSTOM_FORMULA_SYSTEM
    | typeof PROPERTY_DATA
    | typeof PROPERTY_DATA_TYPE
    | typeof PROPERTY_DATA_TYPE_LIST
    | typeof PROPERTY_DATABASE_FILE_PATH
    | typeof PROPERTY_DATABASE_ID
    | typeof PROPERTY_DATABASE_NAME
    | typeof PROPERTY_DATABASE_UUID
    | typeof PROPERTY_DATE
    | typeof PROPERTY_DATE_CREATED
    | typeof PROPERTY_DATE_FINISHED
    | typeof PROPERTY_DATE_FROM
    | typeof PROPERTY_DATE_TO
    | typeof PROPERTY_DATE_UPDATED
    | typeof PROPERTY_DATE_UPLOADED
    | typeof PROPERTY_DEFAULT_DATA
    | typeof PROPERTY_DELETED
    | typeof PROPERTY_DENSITY
    | typeof PROPERTY_DENSITY_MASTER
    | typeof PROPERTY_DENSITY_SOURCE
    | typeof PROPERTY_DENSITY_SYSTEM
    | typeof PROPERTY_DESCRIPTION
    | typeof PROPERTY_DISABLED
    | typeof PROPERTY_DIVIDING_LINE
    | typeof PROPERTY_DONE
    | typeof PROPERTY_EMAIL
    | typeof PROPERTY_ENABLED
    | typeof PROPERTY_END_VALUE
    | typeof PROPERTY_ERROR_CODE
    | typeof PROPERTY_ERROR_LIST
    | typeof PROPERTY_ERROR_TEXT
    | typeof PROPERTY_EXPIRATION
    | typeof PROPERTY_EXPORT_TASK_ID
    | typeof PROPERTY_EXPORT_TASK_STATUS_ID
    | typeof PROPERTY_EXPORT_TASK_TYPE
    | typeof PROPERTY_EXPORT_TASK_TYPE_ID
    | typeof PROPERTY_EXPORT_TYPE_LIST
    | typeof PROPERTY_EXPORT_TYPE_OPTIONS
    | typeof PROPERTY_FANDECK_ID
    | typeof PROPERTY_FANDECK_NAME
    | typeof PROPERTY_FANDECK_PAGE
    | typeof PROPERTY_FANDECK_POSITION
    | typeof PROPERTY_FILE
    | typeof PROPERTY_FILE_CATEGORY
    | typeof PROPERTY_FILE_CATEGORY_ID
    | typeof PROPERTY_FILE_EXTENSIONS
    | typeof PROPERTY_FILE_ID
    | typeof PROPERTY_FILE_TYPE
    | typeof PROPERTY_FILE_TYPE_ID
    | typeof PROPERTY_FILES
    | typeof PROPERTY_FILES_ABS
    | typeof PROPERTY_FILL_PROC
    | typeof PROPERTY_FILL_PROC_MASTER
    | typeof PROPERTY_FILL_PROC_SOURCE
    | typeof PROPERTY_FILL_PROC_SYSTEM
    | typeof PROPERTY_FILL_VOLUME
    | typeof PROPERTY_FILL_VOLUME_ML
    | typeof PROPERTY_FINISHED
    | typeof PROPERTY_FOR_NOMINAL_AMOUNT
    | typeof PROPERTY_FORMULAS_COUNT
    | typeof PROPERTY_FORMULA_ID
    | typeof PROPERTY_FORMULA_LIST
    | typeof PROPERTY_FORMULA_NOTE_ID
    | typeof PROPERTY_FORMULAS_STATUS
    | typeof PROPERTY_FORMULA_TYPE_ID
    | typeof PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID
    | typeof PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_MASTER
    | typeof PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SYSTEM
    | typeof PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SOURCE
    | typeof PROPERTY_GENERATED
    | typeof PROPERTY_GENERATE_PASSWORD_ACTION
    | typeof PROPERTY_HAS_IMAGE
    | typeof PROPERTY_HAS_META_DATA
    | typeof PROPERTY_HDD_USED_PERCENT
    | typeof PROPERTY_HEIGHT
    | typeof PROPERTY_HISTORICAL
    | typeof PROPERTY_ICON_ID
    | typeof PROPERTY_ID
    | typeof PROPERTY_IMAGE_CATEGORY_ID
    | typeof PROPERTY_IMAGE_CATEGORY_NAME
    | typeof PROPERTY_IMAGE_ID
    | typeof PROPERTY_IMAGE_ID_MASTER
    | typeof PROPERTY_IMAGE_ID_SOURCE
    | typeof PROPERTY_IMAGE_ID_SYSTEM
    | typeof PROPERTY_IMPORT_TASK_ID
    | typeof PROPERTY_IMPORT_TASK_STATUS_ID
    | typeof PROPERTY_IMPORT_TASK_TYPE_ID
    | typeof PROPERTY_INFO_DATA
    | typeof PROPERTY_INHERITED
    | typeof PROPERTY_INTERVAL
    | typeof PROPERTY_IP_ADDRESS
    | typeof PROPERTY_IS_CHECKED
    | typeof PROPERTY_IS_DUPLICATED
    | typeof PROPERTY_IS_GRAVIMETRIC
    | typeof PROPERTY_IS_GRAVIMETRIC_FILL
    | typeof PROPERTY_IS_GRAVIMETRIC_FILL_MASTER
    | typeof PROPERTY_IS_GRAVIMETRIC_FILL_SOURCE
    | typeof PROPERTY_IS_GRAVIMETRIC_FILL_SYSTEM
    | typeof PROPERTY_IS_PRIMARY
    | typeof PROPERTY_ITEM_ID
    | typeof PROPERTY_ITEM_TYPE
    | typeof PROPERTY_ITEM_TYPE_ID
    | typeof PROPERTY_KEEP_OLD_FORMULAS
    | typeof PROPERTY_KEY
    | typeof PROPERTY_LANGUAGE
    | typeof PROPERTY_LARGO_NAME
    | typeof PROPERTY_LAST_RESULT
    | typeof PROPERTY_LAST_RUN
    | typeof PROPERTY_LAST_STATUS_CHANGE
    | typeof PROPERTY_LENGTH
    | typeof PROPERTY_LEVEL
    | typeof PROPERTY_LICENSE_IDENTIFICATION
    | typeof PROPERTY_LICENSE_STATUS
    | typeof PROPERTY_MANDATORY
    | typeof PROPERTY_MARGIN_ID
    | typeof PROPERTY_MARGIN_TYPE
    | typeof PROPERTY_MASTER_ONLY
    | typeof PROPERTY_MIME_TYPE
    | typeof PROPERTY_MIN_DOSAGE
    | typeof PROPERTY_MIN_DOSAGE_MASTER
    | typeof PROPERTY_MIN_DOSAGE_SOURCE
    | typeof PROPERTY_MIN_DOSAGE_SYSTEM
    | typeof PROPERTY_MODULES
    | typeof PROPERTY_MORE_INFO
    | typeof PROPERTY_MORE_INFO_FORMATTED
    | typeof PROPERTY_MORE_INFO_INTERNAL
    | typeof PROPERTY_MORE_INFO_INTERNAL_FORMATTED
    | typeof PROPERTY_MORE_INFO_MASTER
    | typeof PROPERTY_MORE_INFO_PRINT
    | typeof PROPERTY_MORE_INFO_PRINT_FORMATTED
    | typeof PROPERTY_MORE_INFO_SOURCE
    | typeof PROPERTY_MORE_INFO_SYSTEM
    | typeof PROPERTY_NAME
    | typeof PROPERTY_NAME_MASTER
    | typeof PROPERTY_NAME_SOURCE
    | typeof PROPERTY_NAME_SYSTEM
    | typeof PROPERTY_NAME_ZONE
    | typeof PROPERTY_NOMINAL_AMOUNT
    | typeof PROPERTY_OK_LIST
    | typeof PROPERTY_OPTION_TYPE
    | typeof PROPERTY_OPTIONS
    | typeof PROPERTY_OVERWRITE
    | typeof PROPERTY_PACKAGE_ID
    | typeof PROPERTY_PACKAGE_NAME
    | typeof PROPERTY_PACKAGE_NAME_MASTER
    | typeof PROPERTY_PACKAGE_NAME_SYSTEM
    | typeof PROPERTY_PACKAGE_NOMINAL_AMOUNT
    | typeof PROPERTY_PARENT_ID
    | typeof PROPERTY_PASSWORD
    | typeof PROPERTY_PASSWORD_HASH
    | typeof PROPERTY_PASSWORD_NEW
    | typeof PROPERTY_PASSWORD_OLD
    | typeof PROPERTY_PATH
    | typeof PROPERTY_PERSON_NAME
    | typeof PROPERTY_PHONE
    | typeof PROPERTY_POINT_OF_SALE
    | typeof PROPERTY_PRICE_COST
    | typeof PROPERTY_PRICE_COST_PER_KILOGRAM
    | typeof PROPERTY_PRICE_COST_PER_LITER
    | typeof PROPERTY_PRICE_COST_PER_UNIT
    | typeof PROPERTY_PRICE_GROUP
    | typeof PROPERTY_PRICE_GROUP_BARCODE_LIST
    | typeof PROPERTY_PRICE_GROUP_ID
    | typeof PROPERTY_PRICE_GROUP_LIST
    | typeof PROPERTY_PRICE_PRICE_GROUP
    | typeof PROPERTY_PRICE_PURCHASE
    | typeof PROPERTY_PRICE_PURCHASE_PER_KILOGRAM
    | typeof PROPERTY_PRICE_PURCHASE_PER_LITER
    | typeof PROPERTY_PRICE_PURCHASE_PER_UNIT
    | typeof PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER
    | typeof PROPERTY_PRICE_SELL
    | typeof PROPERTY_PRICE_SELL_PER_KILOGRAM
    | typeof PROPERTY_PRICE_SELL_PER_LITER
    | typeof PROPERTY_PRICE_SELL_PER_UNIT
    | typeof PROPERTY_PRICE_SURCHARGE
    | typeof PROPERTY_PRICE_TYPE_ID
    | typeof PROPERTY_PRICE_VAT
    | typeof PROPERTY_PRICES
    | typeof PROPERTY_PRINT_NAME
    | typeof PROPERTY_PRINT_NAME_MASTER
    | typeof PROPERTY_PRINT_NAME_SOURCE
    | typeof PROPERTY_PRINT_NAME_SYSTEM
    | typeof PROPERTY_PRIORITY
    | typeof PROPERTY_PRIORITY_MASTER
    | typeof PROPERTY_PRIORITY_SOURCE
    | typeof PROPERTY_PRIORITY_SYSTEM
    | typeof PROPERTY_PROC_COLORANT
    | typeof PROPERTY_PROC_PRODUCT
    | typeof PROPERTY_PRODUCT_DUPLICATE_LIST
    | typeof PROPERTY_PRODUCT_ID
    | typeof PROPERTY_PRODUCT_GROUP_ID
    | typeof PROPERTY_PRODUCT_GROUP_LIST
    | typeof PROPERTY_PRODUCT_NAME
    | typeof PROPERTY_PRODUCTION_DATE
    | typeof PROPERTY_PROGRESS
    | typeof PROPERTY_RANGE
    | typeof PROPERTY_REFLECTANCE_DATA
    | typeof PROPERTY_REGION
    | typeof PROPERTY_REJECTED
    | typeof PROPERTY_REMAINING_DAYS
    | typeof PROPERTY_REQUEST_TYPE
    | typeof PROPERTY_RESULT
    | typeof PROPERTY_RESULT_CODE
    | typeof PROPERTY_ROUNDING
    | typeof PROPERTY_RUNNING
    | typeof PROPERTY_SCRIPT_ID
    | typeof PROPERTY_SEARCH_CODE
    | typeof PROPERTY_SEARCH_CODE_MASTER
    | typeof PROPERTY_SEARCH_CODE_SOURCE
    | typeof PROPERTY_SEARCH_CODE_SYSTEM
    | typeof PROPERTY_SEARCH_NAME
    | typeof PROPERTY_SEARCH_NAME_MASTER
    | typeof PROPERTY_SEARCH_NAME_SOURCE
    | typeof PROPERTY_SEARCH_NAME_SYSTEM
    | typeof PROPERTY_SETTINGS
    | typeof PROPERTY_SHOW_AVAILABLE_ZONES
    | typeof PROPERTY_SPACE_ONE
    | typeof PROPERTY_SPACE_TWO
    | typeof PROPERTY_SPECTRO_A
    | typeof PROPERTY_SPECTRO_B
    | typeof PROPERTY_SPECTRO_L
    | typeof PROPERTY_SRC
    | typeof PROPERTY_SSD_USED_PERCENT
    | typeof PROPERTY_STATIC_SQL
    | typeof PROPERTY_STATUS
    | typeof PROPERTY_STATUS_ID
    | typeof PROPERTY_STATUS_LIST
    | typeof PROPERTY_STREET
    | typeof PROPERTY_STRICT
    | typeof PROPERTY_SYMBOL
    | typeof PROPERTY_SYSTEM_ID
    | typeof PROPERTY_SYSTEM_NAME
    | typeof PROPERTY_SYSTEM_UUID
    | typeof PROPERTY_TMC_NAME
    | typeof PROPERTY_TMC_NAME_MASTER
    | typeof PROPERTY_TMC_NAME_SOURCE
    | typeof PROPERTY_TMC_NAME_SYSTEM
    | typeof PROPERTY_TOTAL
    | typeof PROPERTY_TYPE
    | typeof PROPERTY_TYPE_ID
    | typeof PROPERTY_UFI_CODE
    | typeof PROPERTY_UFI_CODE_MASTER
    | typeof PROPERTY_UFI_CODE_SOURCE
    | typeof PROPERTY_UFI_CODE_SYSTEM
    | typeof PROPERTY_UNIT
    | typeof PROPERTY_UNIT_ID
    | typeof PROPERTY_UNIT_NAME
    | typeof PROPERTY_UNIVERSAL_VALUE
    | typeof PROPERTY_UPDATE_NEEDED
    | typeof PROPERTY_UPDATE_ONLY
    | typeof PROPERTY_URL
    | typeof PROPERTY_USED_HDD
    | typeof PROPERTY_USED_SSD
    | typeof PROPERTY_USER_ID
    | typeof PROPERTY_USER_GROUP_ID
    | typeof PROPERTY_USER_GROUP_LIST
    | typeof PROPERTY_USER_GROUP_PRIVILEGE_ADMINS
    | typeof PROPERTY_USER_GROUP_PRIVILEGE_GUESTS
    | typeof PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS
    | typeof PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS
    | typeof PROPERTY_USER_GROUP_PRIVILEGE_USERS
    | typeof PROPERTY_USERNAME
    | typeof PROPERTY_UUID
    | typeof PROPERTY_VALIDATION
    | typeof PROPERTY_VALIDATION_LIST
    | typeof PROPERTY_VALUE
    | typeof PROPERTY_VALUE_ENUM
    | typeof PROPERTY_VALUE_MASTER
    | typeof PROPERTY_VALUE_SOURCE
    | typeof PROPERTY_VALUE_SYSTEM
    | typeof PROPERTY_VALUES
    | typeof PROPERTY_VERIFIED
    | typeof PROPERTY_VERSION
    | typeof PROPERTY_VERSION_NAME
    | typeof PROPERTY_WARNING_TEXT
    | typeof PROPERTY_WAVELENGTH
    | typeof PROPERTY_WEB_ZONE_LIST
    | typeof PROPERTY_WIDTH
    | typeof PROPERTY_ZIP
    | typeof PROPERTY_ZONE_ID
    | typeof PROPERTY_ZONE_LIST
    | typeof PROPERTY_ZONE_NAME
    | typeof PROPERTY_ZONE_PASSWORD
    | typeof PROPERTY_ZONE_UUID
    | typeof priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST
    | typeof priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS
    | typeof priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS
    | typeof priceConstants.PRICE_OPTION_PRICE_DEFINITION
    | typeof priceConstants.PRICE_OPTION_PRICE_CALCULATION
    | typeof priceConstants.PRICE_OPTION_USE_DISCOUNT
    | typeof priceConstants.PRICE_OPTION_USE_MARGIN
    | typeof priceConstants.PRICE_OPTION_USE_MARKUP;

export const PROPERTY_ACTION = "action";
export const PROPERTY_ACTION_METHOD = "actionMethod";
export const PROPERTY_ACTION_TYPE = "actionType";
export const PROPERTY_ACTION_TYPE_ID = "actionTypeId";
export const PROPERTY_ADD_CURRENCY = "addCurrency";
export const PROPERTY_ADDITIONAL = "additional";
export const PROPERTY_ADDITIONAL_PROPERTIES = "additionalProperties";
export const PROPERTY_ALTERNATIVE_BARCODES = "alternativeBarcodes";
export const PROPERTY_AMOUNT = "amount";
export const PROPERTY_AMOUNT_COEFFICIENT = "amountCoef";
export const PROPERTY_AUTOLOGIN = "autoLogin";
export const PROPERTY_AVAILABLE = "available";
export const PROPERTY_AVAILABLE_HDD = "availableHdd";
export const PROPERTY_AVAILABLE_SSD = "availableSsd";
export const PROPERTY_BARCODE = "barcode";
export const PROPERTY_BARCODES = "barcodes";
export const PROPERTY_BASE_AMOUNT = "baseAmount";
export const PROPERTY_BASE_ID = "baseId";
export const PROPERTY_BASE_IN_PRODUCT_ID = "baseInProductId";
export const PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID = "baseInProductPackageId";
export const PROPERTY_BASE_NAME = "baseName";
export const PROPERTY_BASE_NAME_MASTER = "baseNameMaster";
export const PROPERTY_BASE_NAME_SYSTEM = "baseNameSystem";
export const PROPERTY_BATCH_ID = "batchId";
export const PROPERTY_BATCH_NUMBER = "batchNumber";
export const PROPERTY_BEGIN_VALUE = "beginValue";
export const PROPERTY_BFS = "bfs";
export const PROPERTY_BFS_MASTER = "bfsMaster";
export const PROPERTY_BFS_SOURCE = "bfsSource";
export const PROPERTY_BFS_SYSTEM = "bfsSystem";
export const PROPERTY_CAN_DELETE = "canDelete";
export const PROPERTY_CAN_DOWNLOAD = "canDownload";
export const PROPERTY_CAN_HIDE = "canHide";
export const PROPERTY_CAN_UPDATE = "canUpdate";
export const PROPERTY_CAN_VIEW = "canView";
export const PROPERTY_CAPTION = "caption";
export const PROPERTY_CATEGORY = "category";
export const PROPERTY_CHANGELOG = "changelog";
export const PROPERTY_CITY = "city";
export const PROPERTY_COLORANT_MAX_FILL = "cntMaxFill";
export const PROPERTY_COLORANT_MAX_FILL_MASTER = "cntMaxFillMaster";
export const PROPERTY_COLORANT_MAX_FILL_SOURCE = "cntMaxFillSource";
export const PROPERTY_COLORANT_MAX_FILL_SYSTEM = "cntMaxFillSystem";
export const PROPERTY_COLORANT_MIN_FILL = "cntMinFill";
export const PROPERTY_COLORANT_MIN_FILL_MASTER = "cntMinFillMaster";
export const PROPERTY_COLORANT_MIN_FILL_SOURCE = "cntMinFillSource";
export const PROPERTY_COLORANT_MIN_FILL_SYSTEM = "cntMinFillSystem";
export const PROPERTY_CODE = "code";
export const PROPERTY_CODE_MASTER = "codeMaster";
export const PROPERTY_CODE_SOURCE = "codeSource";
export const PROPERTY_CODE_SYSTEM = "codeSystem";
export const PROPERTY_COEFFICIENT = "coef";
export const PROPERTY_COEFFICIENT_MASTER = "coefMaster";
export const PROPERTY_COEFFICIENT_SOURCE = "coefSource";
export const PROPERTY_COEFFICIENT_SYSTEM = "coefSystem";
export const PROPERTY_COLOR_BLUE = "b";
export const PROPERTY_COLOR_BLUE_MASTER = "bMaster";
export const PROPERTY_COLOR_BLUE_SOURCE = "bSource";
export const PROPERTY_COLOR_BLUE_SYSTEM = "bSystem";
export const PROPERTY_COLOR_CODE = "colorCode";
export const PROPERTY_COLOR_DATA = "colorData";
export const PROPERTY_COLOR_GREEN = "g";
export const PROPERTY_COLOR_GREEN_MASTER = "gMaster";
export const PROPERTY_COLOR_GREEN_SOURCE = "gSource";
export const PROPERTY_COLOR_GREEN_SYSTEM = "gSystem";
export const PROPERTY_COLOR_IN_FANDECK_ID = "colorInFandeckId";
export const PROPERTY_COLOR_NAME = "colorName";
export const PROPERTY_COLOR_RED = "r";
export const PROPERTY_COLOR_RED_MASTER = "rMaster";
export const PROPERTY_COLOR_RED_SOURCE = "rSource";
export const PROPERTY_COLOR_RED_SYSTEM = "rSystem";
export const PROPERTY_COLOR_RGB = "rgb";
export const PROPERTY_COLOR_RGB_HEX = "rgbHex";
export const PROPERTY_COLOR_RGB_HEX_MASTER = "rgbHexMaster";
export const PROPERTY_COLOR_RGB_HEX_SOURCE = "rgbHexSource";
export const PROPERTY_COLOR_RGB_HEX_SYSTEM = "rgbHexSystem";
export const PROPERTY_COLOR_RGB_INT = "rgbInt";
export const PROPERTY_COLOR_RGB_INT_MASTER = "rgbIntMaster";
export const PROPERTY_COLOR_RGB_INT_SOURCE = "rgbIntSource";
export const PROPERTY_COLOR_RGB_INT_SYSTEM = "rgbIntSystem";
export const PROPERTY_COLOR_MORE_INFO = "colorMoreInfo";
export const PROPERTY_COLOR_PRIORITY = "colorPriority";
export const PROPERTY_COLOR_SEARCH_CODE = "colorSearchCode";
export const PROPERTY_COLOR_SEARCH_NAME = "colorSearchName";
export const PROPERTY_COLORS_WITHOUT_FORMULAS = "colorsWithoutFormulas";
export const PROPERTY_COLORANT_ID = "colorantId";
export const PROPERTY_COLORANT_LIST = "colorantList";
export const PROPERTY_COLORANT_NAME = "colorantName";
export const PROPERTY_COLORANT_PACKAGE_ID = "colorantPackageId";
export const PROPERTY_COLORANT_SORTING_ASC = "colorantSortingAsc";
export const PROPERTY_COLORANT_SORTING_CRITERION = "colorantSortingCriterion";
export const PROPERTY_COMPANY_ID = "companyId";
export const PROPERTY_COMPANY_KEY = "companyKey";
export const PROPERTY_COMPANY_NAME = "companyName";
export const PROPERTY_COMPUTER_ID = "computerId";
export const PROPERTY_CONTACT_PERSON = "contactPerson";
export const PROPERTY_COPY_TO_CLIPBOARD_ACTION = "copyToClipboardAction";
export const PROPERTY_COUNTRY = "country";
export const PROPERTY_CURRENCY_ID = "currencyId";
export const PROPERTY_CUSTOM_FORMULA = "customFormula";
export const PROPERTY_CUSTOM_FORMULA_MASTER = "customFormulaMaster";
export const PROPERTY_CUSTOM_FORMULA_SYSTEM = "customFormulaSystem";
export const PROPERTY_CUSTOM_FORMULA_SOURCE = "customFormulaSource";
export const PROPERTY_DATA = "data";
export const PROPERTY_DATABASE_FILE_PATH = "databaseFilePath";
export const PROPERTY_DATABASE_ID = "databaseId";
export const PROPERTY_DATABASE_NAME = "databaseName";
export const PROPERTY_DATABASE_UUID = "databaseUuid";
export const PROPERTY_DATE = "date";
export const PROPERTY_DATE_CREATED = "dateCreated";
export const PROPERTY_DATE_FINISHED = "dateFinished";
export const PROPERTY_DATE_FROM = "dateFrom";
export const PROPERTY_DATE_TO = "dateTo";
export const PROPERTY_DATA_TYPE = "dataType";
export const PROPERTY_DATA_TYPE_LIST = "dataTypeList";
export const PROPERTY_DATE_UPDATED = "updatedDate";
export const PROPERTY_DATE_UPLOADED = "uploadedDate";
export const PROPERTY_DEFAULT_DATA = "defaultData";
export const PROPERTY_DELETED = "deleted";
export const PROPERTY_DENSITY = "density";
export const PROPERTY_DENSITY_MASTER = "densityMaster";
export const PROPERTY_DENSITY_SOURCE = "densitySource";
export const PROPERTY_DENSITY_SYSTEM = "densitySystem";
export const PROPERTY_DESCRIPTION = "description";
export const PROPERTY_DISABLED = "disabled";
export const PROPERTY_DIVIDING_LINE = "dividingLine";
export const PROPERTY_DONE = "done";
export const PROPERTY_EMAIL = "email";
export const PROPERTY_ENABLED = "enabled";
export const PROPERTY_END_VALUE = "endValue";
export const PROPERTY_ERROR_CODE = "errorCode";
export const PROPERTY_ERROR_LIST = "errorList";
export const PROPERTY_ERROR_TEXT = "errorText";
export const PROPERTY_EXPIRATION = "expiration";
export const PROPERTY_EXPORT_TASK_ID = "exportTaskId";
export const PROPERTY_EXPORT_TASK_STATUS_ID = "exportTaskStatusId";
export const PROPERTY_EXPORT_TASK_TYPE = "exportTaskType";
export const PROPERTY_EXPORT_TASK_TYPE_ID = "exportTaskTypeId";
export const PROPERTY_EXPORT_TYPE_LIST = "exportTypeList";
export const PROPERTY_EXPORT_TYPE_OPTIONS = "exportTypeOptions";
export const PROPERTY_FANDECK_ID = "fandeckId";
export const PROPERTY_FANDECK_NAME = "fandeckName";
export const PROPERTY_FANDECK_PAGE = "fandeckPage";
export const PROPERTY_FANDECK_POSITION = "fandeckPosition";
export const PROPERTY_FILE = "file";
export const PROPERTY_FILE_CATEGORY = "fileCategory";
export const PROPERTY_FILE_CATEGORY_ID = "fileCategoryId";
export const PROPERTY_FILE_EXTENSIONS = "fileExtensions";
export const PROPERTY_FILE_ID = "fileId";
export const PROPERTY_FILE_TYPE = "fileType";
export const PROPERTY_FILE_TYPE_ID = "fileTypeId";
export const PROPERTY_FILES = "files";
export const PROPERTY_FILES_ABS = "filesAbs";
export const PROPERTY_FILL_PROC = "fillProc";
export const PROPERTY_FILL_PROC_MASTER = "fillProcMaster";
export const PROPERTY_FILL_PROC_SOURCE = "fillProcSource";
export const PROPERTY_FILL_PROC_SYSTEM = "fillProcSystem";
export const PROPERTY_FILL_VOLUME = "fillVolume";
export const PROPERTY_FILL_VOLUME_ML = "fillVolumeMl";
export const PROPERTY_FINISHED = "finished";
export const PROPERTY_FOR_NOMINAL_AMOUNT = "forNominalAmount";
export const PROPERTY_FORMULAS_COUNT = "formulasCount";
export const PROPERTY_FORMULA_ID = "formulaId";
export const PROPERTY_FORMULA_LIST = "formulaList";
export const PROPERTY_FORMULA_NOTE_ID = "formulaNoteId";
export const PROPERTY_FORMULAS_STATUS = "formulasStatus";
export const PROPERTY_FORMULA_TYPE_ID = "formulaTypeId";
export const PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID = "forcedFormulaBaseAmountTypeId";
export const PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_MASTER = "forcedFormulaBaseAmountTypeIdMaster";
export const PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SYSTEM = "forcedFormulaBaseAmountTypeIdSystem";
export const PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID_SOURCE = "forcedFormulaBaseAmountTypeIdSource";
export const PROPERTY_GENERATED = "generated";
export const PROPERTY_GENERATE_PASSWORD_ACTION = "generatePasswordAction";
export const PROPERTY_HAS_IMAGE = "hasImage";
export const PROPERTY_HAS_META_DATA = "hasMetaData";
export const PROPERTY_HDD_USED_PERCENT = "hddUsedPercent";
export const PROPERTY_HEIGHT = "height";
export const PROPERTY_HISTORICAL = "historical";
export const PROPERTY_ICON_ID = "iconId";
export const PROPERTY_ID = "id";
export const PROPERTY_IMAGE_CATEGORY_ID = "imageCategoryId";
export const PROPERTY_IMAGE_CATEGORY_NAME = "imageCategoryName";
export const PROPERTY_IMAGE_ID = "imageId";
export const PROPERTY_IMAGE_ID_MASTER = "imageIdMaster";
export const PROPERTY_IMAGE_ID_SOURCE = "imageIdSource";
export const PROPERTY_IMAGE_ID_SYSTEM = "imageIdSystem";
export const PROPERTY_IMPORT_TASK_ID = "importTaskId";
export const PROPERTY_IMPORT_TASK_STATUS_ID = "importTaskStatusId";
export const PROPERTY_IMPORT_TASK_TYPE_ID = "importTaskTypeId";
export const PROPERTY_INFO_DATA = "infoData";
export const PROPERTY_INHERITED = "inherited";
export const PROPERTY_INTERVAL = "interval";
export const PROPERTY_IP_ADDRESS = "ipAddress";
export const PROPERTY_ITEM_TYPE = "itemType";
export const PROPERTY_ITEM_TYPE_ID = "itemTypeId";
export const PROPERTY_IS_CHECKED = "isChecked";
export const PROPERTY_IS_DUPLICATED = "isDuplicated";
export const PROPERTY_IS_GRAVIMETRIC = "isGravimetric";
export const PROPERTY_IS_GRAVIMETRIC_FILL = "isGravimetricFill";
export const PROPERTY_IS_GRAVIMETRIC_FILL_MASTER = "isGravimetricFillMaster";
export const PROPERTY_IS_GRAVIMETRIC_FILL_SOURCE = "isGravimetricFillSource";
export const PROPERTY_IS_GRAVIMETRIC_FILL_SYSTEM = "isGravimetricFillSystem";
export const PROPERTY_IS_PRIMARY = "isPrimary";
export const PROPERTY_ITEM_ID = "itemId";
export const PROPERTY_KEEP_OLD_FORMULAS = "keepOldFormulas";
export const PROPERTY_KEY = "key";
export const PROPERTY_LAB = "lab";
export const PROPERTY_LANGUAGE = "language";
export const PROPERTY_LARGO_NAME = "largoName";
export const PROPERTY_LAST_RESULT = "lastResult";
export const PROPERTY_LAST_RUN = "lastRun";
export const PROPERTY_LAST_STATUS_CHANGE = "lastStatusChange";
export const PROPERTY_LENGTH = "dataLen";
export const PROPERTY_LEVEL = "level";
export const PROPERTY_LICENSE_IDENTIFICATION = "licenseIdentification";
export const PROPERTY_LICENSE_STATUS = "licenseStatus";
export const PROPERTY_MANDATORY = "mandatory";
export const PROPERTY_MARGIN_ID = "marginId";
export const PROPERTY_MARGIN_TYPE = "marginTypeId";
export const PROPERTY_MASTER_ONLY = "masterOnly";
export const PROPERTY_MIME_TYPE = "mimeType";
export const PROPERTY_MIN_DOSAGE = "minDosage";
export const PROPERTY_MIN_DOSAGE_MASTER = "minDosageMaster";
export const PROPERTY_MIN_DOSAGE_SOURCE = "minDosageSource";
export const PROPERTY_MIN_DOSAGE_SYSTEM = "minDosageSystem";
export const PROPERTY_MODULES = "modules";
export const PROPERTY_MORE_INFO = "moreInfo";
export const PROPERTY_MORE_INFO_FORMATTED = "moreInfoFormatted";
export const PROPERTY_MORE_INFO_INTERNAL = "moreInfoInternal";
export const PROPERTY_MORE_INFO_INTERNAL_FORMATTED = "moreInfoInternalFormatted";
export const PROPERTY_MORE_INFO_MASTER = "moreInfoMaster";
export const PROPERTY_MORE_INFO_PRINT = "moreInfoPrint";
export const PROPERTY_MORE_INFO_PRINT_FORMATTED = "moreInfoPrintFormatted";
export const PROPERTY_MORE_INFO_SOURCE = "moreInfoSource";
export const PROPERTY_MORE_INFO_SYSTEM = "moreInfoSystem";
export const PROPERTY_NAME = "name";
export const PROPERTY_NAME_MASTER = "nameMaster";
export const PROPERTY_NAME_SOURCE = "nameSource";
export const PROPERTY_NAME_SYSTEM = "nameSystem";
export const PROPERTY_NAME_ZONE = "nameZone";
export const PROPERTY_NOMINAL_AMOUNT = "nominalAmount";
export const PROPERTY_NOT_AVAILABLE = "notAvailable";
export const PROPERTY_OK_LIST = "okList";
export const PROPERTY_OPTION_TYPE = "optionType";
export const PROPERTY_OPTIONS = "options";
export const PROPERTY_OVERWRITE = "overwrite";
export const PROPERTY_PACKAGE_ID = "packageId";
export const PROPERTY_PACKAGE_NAME = "packageName";
export const PROPERTY_PACKAGE_NAME_MASTER = "packageNameMaster";
export const PROPERTY_PACKAGE_NAME_SYSTEM = "packageNameSystem";
export const PROPERTY_PACKAGE_NOMINAL_AMOUNT = "packageNominalAmount";
export const PROPERTY_PARENT_ID = "parentId";
export const PROPERTY_PASSWORD = "password";
export const PROPERTY_PASSWORD_HASH = "passwordHash";
export const PROPERTY_PASSWORD_NEW = "newPassword";
export const PROPERTY_PASSWORD_OLD = "oldPassword";
export const PROPERTY_PATH = "path";
export const PROPERTY_PERSON_NAME = "personName";
export const PROPERTY_PHONE = "phone";
export const PROPERTY_POINT_OF_SALE = "pointOfSale";
export const PROPERTY_PRICE_COST = "price_cost";
export const PROPERTY_PRICE_COST_PER_KILOGRAM = "price_cost_per_kilogram";
export const PROPERTY_PRICE_COST_PER_LITER = "price_cost_per_liter";
export const PROPERTY_PRICE_COST_PER_UNIT = "price_cost_per_unit";
export const PROPERTY_PRICE_GROUP = "priceGroup";
export const PROPERTY_PRICE_GROUP_BARCODE_LIST = "priceGroupBarcodeList";
export const PROPERTY_PRICE_GROUP_ID = "priceGroupId";
export const PROPERTY_PRICE_GROUP_LIST = "priceGroupList";
export const PROPERTY_PRICE_PRICE_GROUP = "price_price_group";
export const PROPERTY_PRICE_PURCHASE = "price_purchase";
export const PROPERTY_PRICE_PURCHASE_PER_KILOGRAM = "price_purchase_per_kilogram";
export const PROPERTY_PRICE_PURCHASE_PER_LITER = "price_purchase_per_liter";
export const PROPERTY_PRICE_PURCHASE_PER_UNIT = "price_purchase_per_unit";
export const PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER = "price_purchase_pg_per_liter";
export const PROPERTY_PRICE_SELL = "price_sell";
export const PROPERTY_PRICE_SELL_PER_KILOGRAM = "price_sell_per_kilogram";
export const PROPERTY_PRICE_SELL_PER_LITER = "price_sell_per_liter";
export const PROPERTY_PRICE_SELL_PER_UNIT = "price_sell_per_unit";
export const PROPERTY_PRICE_SURCHARGE = "price_surcharge";
export const PROPERTY_PRICE_TYPE_ID = "priceTypeId";
export const PROPERTY_PRICE_VAT = "price_vat";
export const PROPERTY_PRICES = "prices";
export const PROPERTY_PRINT_NAME = "printName";
export const PROPERTY_PRINT_NAME_MASTER = "printNameMaster";
export const PROPERTY_PRINT_NAME_SOURCE = "printNameSource";
export const PROPERTY_PRINT_NAME_SYSTEM = "printNameSystem";
export const PROPERTY_PRIORITY = "priority";
export const PROPERTY_PRIORITY_MASTER = "priorityMaster";
export const PROPERTY_PRIORITY_SOURCE = "prioritySource";
export const PROPERTY_PRIORITY_SYSTEM = "prioritySystem";
export const PROPERTY_PROC_COLORANT = "procColorants";
export const PROPERTY_PROC_PRODUCT = "procProducts";
export const PROPERTY_PRODUCT_DUPLICATE_LIST = "productDuplicateList";
export const PROPERTY_PRODUCT_GROUP_ID = "productGroupId";
export const PROPERTY_PRODUCT_GROUP_LIST = "productGroupList";
export const PROPERTY_PRODUCT_ID = "productId";
export const PROPERTY_PRODUCT_NAME = "productName";
export const PROPERTY_PRODUCTION_DATE = "productionDate";
export const PROPERTY_PROGRESS = "progress";
export const PROPERTY_RANGE = "range";
export const PROPERTY_REFLECTANCE_DATA = "reflData";
export const PROPERTY_REGION = "region";
export const PROPERTY_REJECTED = "rejected";
export const PROPERTY_REMAINING_DAYS = "remainingDays";
export const PROPERTY_REQUEST_TYPE = "requestType";
export const PROPERTY_RESULT = "result";
export const PROPERTY_RESULT_CODE = "resultCode";
export const PROPERTY_ROUNDING = "rounding";
export const PROPERTY_RUNNING = "running";
export const PROPERTY_SCRIPT_ID = "scriptId";
export const PROPERTY_SEARCH_CODE = "searchCode";
export const PROPERTY_SEARCH_CODE_MASTER = "searchCodeMaster";
export const PROPERTY_SEARCH_CODE_SOURCE = "searchCodeSource";
export const PROPERTY_SEARCH_CODE_SYSTEM = "searchCodeSystem";
export const PROPERTY_SEARCH_NAME = "searchName";
export const PROPERTY_SEARCH_NAME_MASTER = "searchNameMaster";
export const PROPERTY_SEARCH_NAME_SOURCE = "searchNameSource";
export const PROPERTY_SEARCH_NAME_SYSTEM = "searchNameSystem";
export const PROPERTY_SETTINGS = "settings";
export const PROPERTY_SHOW_AVAILABLE_ZONES = "showAvailableZones";
export const PROPERTY_SPACE_ONE = "spaceOne";
export const PROPERTY_SPACE_TWO = "spaceTwo";
export const PROPERTY_SPECTRO_A = "a";
export const PROPERTY_SPECTRO_B = "b";
export const PROPERTY_SPECTRO_C = "c";
export const PROPERTY_SPECTRO_H = "h";
export const PROPERTY_SPECTRO_L = "l";
export const PROPERTY_SPECTRO_TYPE_ID = "spectroTypeId";
export const PROPERTY_SPECTRO_TYPE_NAME = "spectroTypeName";
export const PROPERTY_SRC = "src";
export const PROPERTY_SSD_USED_PERCENT = "ssdUsedPercent";
export const PROPERTY_STATIC_SQL = "staticSql";
export const PROPERTY_STATUS = "status";
export const PROPERTY_STATUS_ID = "statusId";
export const PROPERTY_STATUS_LIST = "statusList";
export const PROPERTY_STREET = "street";
export const PROPERTY_STRICT = "strict";
export const PROPERTY_SYMBOL = "symbol";
export const PROPERTY_SYSTEM_ID = "systemId";
export const PROPERTY_SYSTEM_NAME = "systemName";
export const PROPERTY_SYSTEM_UUID = "systemUuid";
export const PROPERTY_TMC_NAME = "tmcName";
export const PROPERTY_TMC_NAME_MASTER = "tmcNameMaster";
export const PROPERTY_TMC_NAME_SOURCE = "tmcNameSource";
export const PROPERTY_TMC_NAME_SYSTEM = "tmcNameSystem";
export const PROPERTY_TOTAL = "total";
export const PROPERTY_TYPE = "type";
export const PROPERTY_TYPE_ID = "typeId";
export const PROPERTY_UFI_CODE = "ufiCode";
export const PROPERTY_UFI_CODE_MASTER = "ufiCodeMaster";
export const PROPERTY_UFI_CODE_SOURCE = "ufiCodeSource";
export const PROPERTY_UFI_CODE_SYSTEM = "ufiCodeSystem";
export const PROPERTY_UNIT = "unit";
export const PROPERTY_UNIT_ID = "unitId";
export const PROPERTY_UNIT_NAME = "unitName";
export const PROPERTY_UNIVERSAL_VALUE = "universalValue";
export const PROPERTY_UPDATE_NEEDED = "updateNeeded";
export const PROPERTY_UPDATE_ONLY = "updateOnly";
export const PROPERTY_URL = "url";
export const PROPERTY_USED_HDD = "usedHdd";
export const PROPERTY_USED_SSD = "usedSsd";
export const PROPERTY_USER_ID = "userId";
export const PROPERTY_USER_GROUP_ID = "userGroupId";
export const PROPERTY_USER_GROUP_LIST = "userGroupList";
export const PROPERTY_USER_GROUP_PRIVILEGE_ADMINS = "admins";
export const PROPERTY_USER_GROUP_PRIVILEGE_GUESTS = "guests";
export const PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS = "managers";
export const PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS = "superadmins";
export const PROPERTY_USER_GROUP_PRIVILEGE_USERS = "users";
export const PROPERTY_USERNAME = "username";
export const PROPERTY_UUID = "uuid";
export const PROPERTY_VALIDATION = "validation";
export const PROPERTY_VALIDATION_LIST = "validationList";
export const PROPERTY_VALUE = "value";
export const PROPERTY_VALUE_ENUM = "valueEnum";
export const PROPERTY_VALUE_MASTER = "valueMaster";
export const PROPERTY_VALUE_SOURCE = "valueSource";
export const PROPERTY_VALUE_SYSTEM = "valueSystem";
export const PROPERTY_VALUES = "values";
export const PROPERTY_VERIFIED = "verified";
export const PROPERTY_VERSION = "version";
export const PROPERTY_VERSION_NAME = "versionName";
export const PROPERTY_WARNING_TEXT = "warningText";
export const PROPERTY_WAVELENGTH = "wavelength";
export const PROPERTY_WEB_ZONE_LIST = "webZoneList";
export const PROPERTY_WIDTH = "width";
export const PROPERTY_XYZ = "xyz";
export const PROPERTY_ZIP = "zip";
export const PROPERTY_ZONE_ID = "zoneId";
export const PROPERTY_ZONE_LIST = "zoneList";
export const PROPERTY_ZONE_NAME = "zoneName";
export const PROPERTY_ZONE_PASSWORD = "zonePassword";
export const PROPERTY_ZONE_UUID = "zoneUuid";

export const BARCODE_PROPERTIES: Array<Property> = [PROPERTY_ALTERNATIVE_BARCODES, PROPERTY_BARCODES];

export const FILE_PROPERTIES: Array<Property> = [PROPERTY_FILES];

export const PRICE_BASE_IN_PRODUCT_PROPERTIES: Array<Property> = [
    PROPERTY_PRICE_COST_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PER_LITER,
    PROPERTY_PRICE_SELL_PER_LITER,
    PROPERTY_PRICE_VAT
];

export const PRICE_BASE_IN_PRODUCT_PACKAGE_PROPERTIES: Array<Property> = [
    PROPERTY_PRICE_COST,
    PROPERTY_PRICE_COST_PER_KILOGRAM,
    PROPERTY_PRICE_COST_PER_LITER,
    PROPERTY_PRICE_COST_PER_UNIT,
    PROPERTY_PRICE_PURCHASE,
    PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
    PROPERTY_PRICE_PURCHASE_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PER_UNIT,
    PROPERTY_PRICE_SELL,
    PROPERTY_PRICE_SELL_PER_KILOGRAM,
    PROPERTY_PRICE_SELL_PER_LITER,
    PROPERTY_PRICE_SELL_PER_UNIT,
    PROPERTY_PRICE_SURCHARGE,
    PROPERTY_PRICE_VAT
];

export const PRICE_COLORANT_PROPERTIES: Array<Property> = [
    PROPERTY_PRICE_COST_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER,
    PROPERTY_PRICE_SELL_PER_LITER,
    PROPERTY_PRICE_VAT
];

export const PRICE_PRODUCT_PROPERTIES: Array<Property> = [
    PROPERTY_PRICE_GROUP_LIST,
    PROPERTY_PRICE_COST_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PER_LITER,
    PROPERTY_PRICE_SELL_PER_LITER,
    PROPERTY_PRICE_VAT
];

export const PRICE_PROPERTIES: Array<Property> = [
    PROPERTY_PRICE_COST,
    PROPERTY_PRICE_COST_PER_KILOGRAM,
    PROPERTY_PRICE_COST_PER_LITER,
    PROPERTY_PRICE_COST_PER_UNIT,
    PROPERTY_PRICE_GROUP_BARCODE_LIST,
    PROPERTY_PRICE_GROUP_LIST,
    PROPERTY_PRICE_PURCHASE,
    PROPERTY_PRICE_PURCHASE_PER_KILOGRAM,
    PROPERTY_PRICE_PURCHASE_PER_LITER,
    PROPERTY_PRICE_PURCHASE_PER_UNIT,
    PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER,
    PROPERTY_PRICE_SELL,
    PROPERTY_PRICE_SELL_PER_KILOGRAM,
    PROPERTY_PRICE_SELL_PER_LITER,
    PROPERTY_PRICE_SELL_PER_UNIT,
    PROPERTY_PRICE_SURCHARGE,
    PROPERTY_PRICE_VAT
];

export const PRODUCT_GROUP_PROPERTIES: Array<Property> = [PROPERTY_PRODUCT_GROUP_LIST];

export const SELECT_INPUT_BUTTON_PROPERTIES = [
    PROPERTY_STATIC_SQL,
    PROPERTY_IMAGE_CATEGORY_ID,
    PROPERTY_FILE_CATEGORY_ID
];

export const SKIP_MODAL_PROPERTIES = [
    PROPERTY_PRICE_COST_PER_UNIT,
    PROPERTY_PRICE_PURCHASE_PER_UNIT,
    PROPERTY_PRICE_SELL_PER_UNIT,
    PROPERTY_MORE_INFO_FORMATTED,
    PROPERTY_MORE_INFO_PRINT_FORMATTED,
    PROPERTY_MORE_INFO_INTERNAL_FORMATTED,
    PROPERTY_COLOR_RED,
    PROPERTY_COLOR_BLUE,
    PROPERTY_COLOR_GREEN,
    PROPERTY_DATE_TO
];

export const COLOR_COMPONENTS = [PROPERTY_COLOR_RED, PROPERTY_COLOR_GREEN, PROPERTY_COLOR_BLUE];

export const FORMATTED_NOTES = [
    PROPERTY_MORE_INFO_FORMATTED,
    PROPERTY_MORE_INFO_PRINT_FORMATTED,
    PROPERTY_MORE_INFO_INTERNAL_FORMATTED
];

export const TABLE_FILTER_ROW_SELECT_PROPERTIES = [
    PROPERTY_ACTION_TYPE_ID,
    PROPERTY_EXPORT_TASK_STATUS_ID,
    PROPERTY_EXPORT_TASK_TYPE_ID,
    PROPERTY_FILE_CATEGORY_ID,
    PROPERTY_FILE_TYPE_ID,
    PROPERTY_IMAGE_CATEGORY_ID,
    PROPERTY_IMPORT_TASK_STATUS_ID,
    PROPERTY_IMPORT_TASK_TYPE_ID,
    PROPERTY_ITEM_TYPE_ID,
    PROPERTY_STATUS_ID,
    PROPERTY_SYSTEM_ID,
    PROPERTY_ZONE_ID
];

export const FIXED_COLUMN_PROPERTIES = [PROPERTY_IS_CHECKED];
