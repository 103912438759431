import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { DataItem } from "../../../types/dataItem";
import { HistoricalAction } from "../../../types/historicalAction";
import { ServerRequest } from "../../../types/serverRequest";
import { UserActionsHistoryScene } from "../../../components/scenes/header/UserActionsHistoryScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    dataPrivileges: Array<DataItem>;
    actionHistoryTableConstant: string;
    actionHistoryList: Array<HistoricalAction>;
    actionHistoryActiveList: Array<HistoricalAction>;
    actionHistoryLoading: boolean;
    actionHistoryColumnOrder: Array<propertyConstants.Property>;
    actionHistoryColumnVisibility: Record<propertyConstants.Property, boolean>;
    actionHistoryColumnWidth: Record<propertyConstants.Property, number>;
    actionHistoryDateFrom: Date | null;
    actionHistoryDateTo: Date | null;
    actionHistoryOffset: number;
    actionHistoryPage: number;
    actionHistoryRowCount: number;
    actionHistoryRowCountCustom: boolean;
    actionHistorySearch: string | null;
    actionHistorySearchParams: Record<propertyConstants.Property, string>;
    actionHistoryShowFilterRow: boolean;
    actionHistoryShowGlobalSearch: boolean;
    actionHistorySortingAsc: boolean;
    actionHistorySortingCriterion: propertyConstants.Property | null;
    actionHistoryTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    dataPrivileges: state.login.dataPrivileges,
    actionHistoryTableConstant: menuConstants.TABLE_USER_ACTIONS_HISTORY,
    actionHistoryList: state.actionHistory.list,
    actionHistoryActiveList: state.actionHistory.activeList,
    actionHistoryLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_USER_HISTORY_ACTIONS
    ),
    actionHistoryColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistoryColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistoryColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistoryDateFrom: state.actionHistory.dateFrom,
    actionHistoryDateTo: state.actionHistory.dateTo,
    actionHistoryOffset: state.actionHistory.offset,
    actionHistoryPage: state.actionHistory.page,
    actionHistoryRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistoryRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistorySearch: state.actionHistory.search,
    actionHistorySearchParams: state.actionHistory.searchParams,
    actionHistoryShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistoryShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_USER_ACTIONS_HISTORY
    ),
    actionHistorySortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_USER_ACTIONS_HISTORY
        )
    ),
    actionHistorySortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_USER_ACTIONS_HISTORY
        )
    ),
    actionHistoryTotalCount: state.actionHistory.count
});

export const UserActionsHistorySceneContainer = connect(mapStateToProps)(UserActionsHistoryScene);
