import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { generateReflectanceWavelengthData, generateReflectanceWavelengthDataHeadings } from "../../../types/colorData";
import { ModalProperties } from "../../../types/modalProperties";
import { ReflectanceData } from "../../../types/reflectanceData";
import { TableContainer } from "../../Table";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    allList: Array<ReflectanceData>;
    columnVisibility: any;
};

export class SpectralSettingsTable extends Component<Props> {
    generateTableHeadings = (): Array<any> => {
        const tableVisibleColumns = optionHelper.handleTableHeadingVisibility(
            [propertyConstants.PROPERTY_WAVELENGTH, propertyConstants.PROPERTY_REFLECTANCE_DATA],
            this.props.columnVisibility
        );

        return generateReflectanceWavelengthDataHeadings(
            this.props.objectType,
            tableVisibleColumns,
            tableVisibleColumns,
            [propertyConstants.PROPERTY_REFLECTANCE_DATA],
            [propertyConstants.PROPERTY_WAVELENGTH],
            [],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {};

        return (
            <div className="card">
                <div className="card-content">
                    <TableContainer
                        className={"table-with-filter"}
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={false}
                        headings={this.generateTableHeadings()}
                        data={generateReflectanceWavelengthData(this.props.allList)}
                        activeData={[]}
                        disableColumnResizing={true}
                        modalProperties={modalProperties}
                        offset={tableConstants.DEFAULT_OFFSET}
                        page={tableConstants.DEFAULT_PAGE}
                        rowCount={this.props.allList.length}
                        rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                        search={null}
                        searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                        showFilterRow={false}
                        showGlobalSearch={false}
                        sortingAsc={true}
                        sortingCriterion={null}
                        totalCount={null}
                    />
                </div>
            </div>
        );
    }
}
