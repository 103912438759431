import * as actionConstants from "../constants/actionTypes";
import * as barcodeActions from "../actions/barcodeActions";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Epic, ofType } from "redux-observable";
import { getAllItems, reloadData } from "../actions/universalObjectActions";

import { switchMap } from "rxjs/operators";

export const setActive: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.SET_ACTIVE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // color
                case objectTypes.BARCODE: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(
                            barcodeActions.getItemByBarcode(payload.items?.[0]?.[propertyConstants.PROPERTY_ID])
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_COLOR: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.MASTER_COLOR_DATA));
                    }
                    break;
                }
                case objectTypes.SYSTEM_COLOR: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.SYSTEM_COLOR_DATA));
                    }
                    break;
                }
                case objectTypes.ZONE_COLOR: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.ZONE_COLOR_DATA));
                    }
                    break;
                }
                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.MASTER_FORMULA));
                    }
                    break;
                }
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.SYSTEM_FORMULA));
                    }
                    break;
                }
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.ZONE_FORMULA));
                    }
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.MASTER_COLORANT_BATCH));
                    }
                    break;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.SYSTEM_COLORANT_PACKAGE));
                    }
                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.ZONE_COLORANT_PACKAGE));
                    }
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.MASTER_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.MASTER_COLOR));
                        }
                    }
                    if (state$.value.navigation.activeScene === navigationTypes.MASTER_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.product.masterActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO));
                        }
                    }
                    break;
                }
                case objectTypes.SYSTEM_FANDECK_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.SYSTEM_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.SYSTEM_COLOR));
                        }
                    }
                    if (state$.value.navigation.activeScene === navigationTypes.SYSTEM_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.product.systemActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO));
                        }
                    }
                    break;
                }
                case objectTypes.ZONE_FANDECK_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.ZONE_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.ZONE_COLOR));
                        }
                    }
                    if (state$.value.navigation.activeScene === navigationTypes.ZONE_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.product.zoneActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.ZONE_COLOR_WITH_FORMULA_INFO));
                        }
                    }
                    break;
                }
                // privilege
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID] !== null) {
                        requests.push(reloadData(objectTypes.MASTER_REDLIKE_PRIVILEGE));
                    }
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.MASTER_BASE_IN_PRODUCT));
                        requests.push(
                            getAllItems(objectTypes.MASTER_BASE_IN_PRODUCT, {
                                productId: payload.items?.[0]?.[propertyConstants.PROPERTY_ID],
                                simpleData: true
                            })
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_PRODUCT_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.MASTER_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.MASTER_FORMULA));
                        }
                    }

                    if (state$.value.navigation.activeScene === navigationTypes.MASTER_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.fandeck.masterActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO));
                        }
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(
                                getAllItems(objectTypes.MASTER_BASE_IN_PRODUCT, {
                                    productId: payload.items[0][propertyConstants.PROPERTY_ID],
                                    simpleData: true
                                })
                            );
                        }
                    }

                    break;
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT));
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.SYSTEM_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.SYSTEM_FORMULA));
                        }
                    }

                    if (state$.value.navigation.activeScene === navigationTypes.SYSTEM_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.fandeck.systemActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO));
                        }
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(
                                getAllItems(objectTypes.SYSTEM_BASE_IN_PRODUCT, {
                                    productId: payload.items[0][propertyConstants.PROPERTY_ID],
                                    simpleData: true
                                })
                            );
                        }
                    }

                    break;
                }
                case objectTypes.ZONE_PRODUCT: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.ZONE_BASE_IN_PRODUCT));
                    }
                    break;
                }
                case objectTypes.ZONE_PRODUCT_IN_COLOR: {
                    if (state$.value.navigation.activeScene === navigationTypes.ZONE_COLORS_SCENE) {
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(reloadData(objectTypes.ZONE_FORMULA));
                        }
                    }

                    if (state$.value.navigation.activeScene === navigationTypes.ZONE_FORMULAS_SCENE) {
                        if (
                            payload.items?.[0]?.[propertyConstants.PROPERTY_ID] &&
                            state$.value.fandeck.zoneActiveList.length > 0
                        ) {
                            requests.push(reloadData(objectTypes.ZONE_COLOR_WITH_FORMULA_INFO));
                        }
                        if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                            requests.push(
                                getAllItems(objectTypes.ZONE_BASE_IN_PRODUCT, {
                                    productId: payload.items[0][propertyConstants.PROPERTY_ID],
                                    simpleData: true
                                })
                            );
                        }
                    }

                    break;
                }
                // product base
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    if (
                        state$.value.product.systemActiveList.length &&
                        payload.items?.[0]?.[propertyConstants.PROPERTY_ID]
                    ) {
                        requests.push(reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE));
                    }
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    if (
                        state$.value.product.zoneActiveList.length &&
                        payload.items?.[0]?.[propertyConstants.PROPERTY_ID]
                    ) {
                        requests.push(reloadData(objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE));
                    }
                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    if (payload.items?.[0]?.[propertyConstants.PROPERTY_ID]) {
                        requests.push(reloadData(objectTypes.ZONE));
                        requests.push(
                            getAllItems(objectTypes.ZONE_DUPLICATE, {
                                systemId: payload.items?.[0]?.[propertyConstants.PROPERTY_ID]
                            })
                        );
                    }
                    break;
                }
                default: {
                    break;
                }
            }
            return requests;
        })
    );
