import * as generalHelper from "../../../../helpers/generalHelper";
import * as importExportTaskHelper from "../../../../helpers/importExportTaskHelper";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as requestHelper from "../../../../helpers/requestHelper";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Database } from "../../../../types/database";
import { Dispatch } from "redux";
import { ExportStatus } from "../../../../types/exportStatus";
import { ImportStatus } from "../../../../types/importStatus";
import { Option } from "../../../../types/option";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    privileges: any;
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
    list: Array<any>;
    editable: boolean;
};

type Props = OwnProps & ContainerProps;

class TableDeleteButton extends Component<Props> {
    /**
     Button is visible if
     * it is not hidden by constants
     * for License, Import task and Export task, the privilege is available
     * if logged in user has data privilege to delete
     */
    isVisible = (): boolean => {
        const { dataPrivileges, objectType, privileges } = this.props;

        if (objectTypes.HIDDEN_DELETE_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return false;
        }

        if (
            objectType === objectTypes.LICENSE &&
            optionHelper.isPrivilegeAvailable(this.props.privileges?.[privilegeConstants.PRIVILEGE_LICENSE_DELETE])
        ) {
            return true;
        }

        if (objectTypeHelper.isObjectTypeExportImport(objectType) && dataPrivileges?.canDelete) {
            return importExportTaskHelper.isDeletingImportExportTasksEnabled(objectType, privileges, []);
        }

        if (dataPrivileges?.canDelete) {
            return true;
        }

        return false;
    };

    canDeleteAllItems = (items: Array<any>): boolean => {
        for (const item of items) {
            if (item[propertyConstants.PROPERTY_CAN_DELETE] === false) {
                return false;
            }
        }

        return true;
    };

    /**
     Button is enabled if
     * appropriate database/system/zone is active
     * for Units, Redlike users and User groups, each item needs to have permission (canDelete attribute) in type
     * for import and export types, either some items are checked or active row will be deleted, privilege needs to be allowed
     * for License according to the privilege defined on server side
     * it has correct dataPrivileges (canDelete: true)
     */
    isEnabled = (): boolean => {
        const { dataPrivileges, privileges, editable, activeList, objectType } = this.props;
        const checkedItems = this.props.list.filter((item) => item?.[propertyConstants.PROPERTY_IS_CHECKED] ?? false);

        if (
            !objectTypeHelper.isObjectTypeEnabledByHeader(
                this.props.objectType,
                this.props.activeDatabase ?? null,
                this.props.activeSystem ?? null,
                this.props.activeZone ?? null
            )
        ) {
            return false;
        }

        if (
            (objectType === objectTypes.MASTER_REDLIKE_USER_GROUP ||
                objectType === objectTypes.UNIT ||
                objectType === objectTypes.USER_GROUP ||
                objectTypeHelper.isObjectTypeRedlikeUser(objectType)) &&
            activeList.length &&
            !this.canDeleteAllItems(activeList)
        ) {
            return false;
        }

        if (objectTypeHelper.isObjectTypeExportImport(objectType) && dataPrivileges?.canDelete && editable) {
            if (activeList.length || checkedItems.length) {
                return importExportTaskHelper.isDeletingImportExportTasksEnabled(
                    objectType,
                    privileges,
                    checkedItems.length ? checkedItems : activeList
                );
            }
        }

        if (
            objectType === objectTypes.LICENSE &&
            optionHelper.isPrivilegeAvailable(this.props.privileges?.[privilegeConstants.PRIVILEGE_LICENSE_DELETE])
        ) {
            return true;
        }

        if (dataPrivileges?.canDelete && activeList.length && editable) {
            return true;
        }

        return false;
    };

    getDeleteConfirmation = (): boolean => {
        const { objectType, deleteConfirmationOption } = this.props;

        if (
            objectType === objectTypes.DATABASE ||
            objectType === objectTypes.LICENSE ||
            objectType === objectTypes.SYSTEM ||
            objectType === objectTypes.ZONE
        ) {
            return true;
        }

        if (deleteConfirmationOption && deleteConfirmationOption.value !== undefined) {
            return deleteConfirmationOption.value;
        }

        return true;
    };

    handleDeleteItem = (): void => {
        const { list, objectType, activeList, deleteItem } = this.props;
        const checkedItems = list.filter((item) => item?.[propertyConstants.PROPERTY_IS_CHECKED] ?? false);
        const idProperty = requestHelper.getObjectIdProperty(objectType);
        const visible = this.isVisible();

        // Handling license
        if (objectType === objectTypes.LICENSE) {
            deleteItem(objectType, {
                deleteConfirmation: this.getDeleteConfirmation()
            });
        }

        // Handling import and export tasks bulk delete
        if (objectTypeHelper.isObjectTypeExportImport(objectType) && checkedItems.length && visible && idProperty) {
            const updatedObjectType =
                objectType === objectTypes.EXPORT_TASK
                    ? objectTypes.EXPORT_TASK_DELETE
                    : objectTypes.IMPORT_TASK_DELETE;
            const updatedIdProperty = requestHelper.getObjectIdProperty(updatedObjectType);
            const mappedIds = checkedItems.map((item) => item[propertyConstants.PROPERTY_ID]);

            deleteItem(updatedObjectType, {
                [updatedIdProperty]: mappedIds,
                deleteConfirmation: this.getDeleteConfirmation()
            });
        }

        // Handling all others then license; import and export tasks may be delete one by one too
        if (objectType !== objectTypes.LICENSE && !checkedItems.length && visible && idProperty) {
            for (const item of activeList) {
                deleteItem(objectType, {
                    [idProperty]: item[propertyConstants.PROPERTY_ID],
                    deleteConfirmation: this.getDeleteConfirmation()
                });
            }
        }
    };

    getButtonClassname = (): string => {
        let className = "btn-md btn-info";

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    getButtonTitle = (): string => {
        switch (this.props.objectType) {
            case objectTypes.LICENSE:
                return translate("license.removeLicense");
            default:
                return translate("general.delete") + this.getNumberOfItemsTitle();
        }
    };

    getNumberOfItemsTitle = (): string => {
        const checkedItems = this.props.list.filter((item) => item?.[propertyConstants.PROPERTY_IS_CHECKED] ?? false);

        if (objectTypeHelper.isObjectTypeExportImport(this.props.objectType) && checkedItems.length) {
            return ` (${checkedItems.length})`;
        }

        return "";
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className={this.getButtonClassname()} onClick={(): void => this.handleDeleteItem()}>
                    {this.getButtonTitle()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getButtonClassname()}>{this.getButtonTitle()}</button>;
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
    deleteConfirmationOption: Option | null;
    exportStatusList: Array<ExportStatus>;
    importStatusList: Array<ImportStatus>;
}>;

export type DispatchType = Readonly<{
    deleteItem(objectType: objectTypes.ObjectType, params: any): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    deleteConfirmationOption: generalHelper.getObjectByKey(
        state.login.options,
        optionsConstants.OPTION_DELETE_CONFIRMATION
    ),
    exportStatusList: state.export.exportStatuses,
    importStatusList: state.import.importStatuses
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    deleteItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.deleteItem(objectType, params))
});

export const TableDeleteButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableDeleteButton);
