import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as priceConstants from "../../../../constants/entityConstants/priceConstants";
import * as priceHelper from "../../../../helpers/priceHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import { Price, generatePriceData, generatePriceHeadings } from "../../../../types/price";
import React, { Component } from "react";

import { Currency } from "../../../../types/currency";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<Price>;
    activeList: Array<Price>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    currency: Currency | null;
    optionPriceDefinition: string | null;
    optionPriceEnableCompanyCost: boolean;
};

export class ZonePriceDefaultTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_OVERWRITE,
            propertyConstants.PROPERTY_VALUE
        ];
        const requiredList: Array<propertyConstants.Property> = [];
        const alwaysVisibleList: Array<propertyConstants.Property> = [];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        visibleList.splice(0, 0, propertyConstants.PROPERTY_NAME);

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generatePriceHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateFilteredPriceData = (): Array<Price> => {
        const filteredOptions = priceHelper.getPriceSettingsKeys(
            this.props.dataPrivileges,
            this.props.optionPriceEnableCompanyCost,
            this.props.optionPriceDefinition
        );
        const filteredData = this.props.allList.filter((item) => filteredOptions.includes(item.id));

        return filteredData;
    };

    generateEditModalParams = (): ModalParams => {
        const { optionPriceEnableCompanyCost, optionPriceDefinition } = this.props;

        return new ModalParams(
            modalTypes.PRICE_MODAL,
            this.props.objectType,
            translate("prices.defaultPrices"),
            this.generateFilteredPriceData(),
            undefined,
            undefined,
            undefined,
            undefined,
            {
                colorant: priceHelper.getPriceSettingsKeys(
                    null,
                    optionPriceEnableCompanyCost,
                    optionPriceDefinition,
                    priceConstants.COLORANT
                ),
                product: priceHelper.getPriceSettingsKeys(
                    null,
                    optionPriceEnableCompanyCost,
                    optionPriceDefinition,
                    priceConstants.PRODUCT
                ),
                defaultPriceColorantVat: [priceConstants.PRICE_GENERIC_COLORANT_VAT],
                defaultPriceProductVAT: [priceConstants.PRICE_GENERIC_PRODUCT_VAT],
                packageSurcharge: [priceConstants.PRICE_GENERIC_PACKAGE_SURCHARGE]
            },
            undefined,
            undefined,
            undefined,
            this.props.currency,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            optionPriceEnableCompanyCost,
            optionPriceDefinition
        );
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            editModalType: modalTypes.MODAL_PRICE_GENERIC_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generatePriceData(this.generateFilteredPriceData())}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    currency={this.props.currency}
                />
            </div>
        );
    }
}
