import * as actionConstants from "../constants/actionTypes";
import * as navigationActions from "../actions/navigationActions";
import * as objectTypes from "../constants/objectTypes";
import * as pathConstants from "../constants/paths";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

export const jumpToItem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.JUMP),
        switchMap(({ payload }) => {
            switch (payload.objectType) {
                // color -> formula
                case objectTypes.MASTER_FORMULA: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_MASTER_FORMULAS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_PRODUCT_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_FORMULA, payload.params)
                    ];
                }
                case objectTypes.SYSTEM_FORMULA: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_SYSTEM_FORMULAS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_PRODUCT_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_FORMULA, payload.params)
                    ];
                }
                case objectTypes.ZONE_FORMULA: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_ZONE_FORMULAS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_PRODUCT_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_FORMULA, payload.params)
                    ];
                }
                // formula -> color
                case objectTypes.MASTER_COLOR: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_MASTER_COLORS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.MASTER_COLOR, payload.params)
                    ];
                }
                case objectTypes.SYSTEM_COLOR: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_SYSTEM_COLORS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.SYSTEM_COLOR, payload.params)
                    ];
                }
                case objectTypes.ZONE_COLOR: {
                    return [
                        navigationActions.routingPush(
                            pathConstants.PATH_ZONE_COLORS,
                            !state$.value.navigation.dataChanged
                        ),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_FANDECK_IN_COLOR, payload.params),
                        universalObjectActions.getItemDetail(objectTypes.ZONE_COLOR, payload.params)
                    ];
                }
                default:
                    return [];
            }
        })
    );
