import * as modalTypes from "../../../../constants/modalTypes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ModalType } from "../../../../constants/modalTypes";
import ReactTooltip from "react-tooltip";
import { Scene } from "../../../../constants/navigationTypes";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    modalType?: ModalType;
    modalParams?: any;
    activeList: Array<any>;
    editable?: boolean;
};

type Props = OwnProps & ContainerProps;

type State = {
    tooltipOn: boolean;
    tooltipText: string;
};

export class TablePreviewButton extends Component<Props, State> {
    state: State = {
        tooltipOn: false,
        tooltipText: ""
    };

    isVisible = (): boolean => {
        const { objectType } = this.props;

        if (objectTypes.PREVIEW_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        if (this.props.activeList.length) {
            return true;
        }

        if (this.props.editable === false) {
            return false;
        }

        return false;
    };

    getTooltipContent = (): string => {
        const { activeList } = this.props;

        if (activeList.length === 0) {
            return translate("general.noPreviewAvailable");
        }

        return "";
    };

    handleMouseOver = (): void => {
        this.setState({
            tooltipOn: true,
            tooltipText: this.getTooltipContent()
        });
    };

    handleMouseLeave = (): void => {
        this.setState({
            tooltipOn: false,
            tooltipText: ""
        });
    };

    handleOpenModal = (): void => {
        const { activeList, activeScene, modalType, modalParams, objectType, openModal } = this.props;

        if (this.isEnabled() && activeList.length) {
            switch (objectType) {
                case objectTypes.FILE: {
                    const modalParams = {
                        type: modalTypes.MODAL_FILE_PREVIEW,
                        objectType: objectTypes.FILE,
                        activeScene: activeScene,
                        headings: [],
                        title: translate("general.preview"),
                        fileId: activeList[0][propertyConstants.PROPERTY_ID],
                        fileMimeType: activeList[0][propertyConstants.PROPERTY_MIME_TYPE],
                        classNameTable: "simple-table"
                    };

                    openModal(modalTypes.MODAL_FILE_PREVIEW, modalParams);
                    break;
                }
                default: {
                    if (modalType && modalParams) {
                        openModal(modalType, modalParams);
                    }
                    break;
                }
            }
        }
    };

    getButtonCaption = (): string => {
        switch (this.props.objectType) {
            case objectTypes.USER_ACTION_HISTORY:
                return translate("user.userActionDetail");
            default:
                return translate("general.preview");
        }
    };

    getButtonClassName = (): string => {
        let className = "bnt-md btn-info";

        if (this.props.objectType === objectTypes.USER_ACTION_HISTORY) {
            className += " preview-button";
        }

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    getTooltip = (): JSX.Element | null => {
        if (this.state.tooltipOn) {
            return (
                <ReactTooltip id="button-tooltip" className="tooltip" place="top" effect="solid">
                    <span>{this.state.tooltipText}</span>{" "}
                </ReactTooltip>
            );
        }
        return null;
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <div className="table-tooltip-container">
                    <button
                        className={this.getButtonClassName()}
                        onClick={(): void => this.handleOpenModal()}
                        data-tip
                        data-for="button-tooltip"
                    >
                        {this.getButtonCaption()}
                    </button>
                    {this.getTooltip()}
                </div>
            );
        }

        if (this.isVisible()) {
            return (
                <div
                    className="table-tooltip-container"
                    onMouseOver={(): void => this.handleMouseOver()}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <button className={this.getButtonClassName()} data-tip data-for="button-tooltip">
                        {this.getButtonCaption()}
                    </button>
                    {this.getTooltip()}
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeScene: Scene;
}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TablePreviewButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TablePreviewButton);
