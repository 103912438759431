// TODO sjednotit

export const BOOKMARK_TYPE_FORM = "BOOKMARK_TYPE_FORM";
export const BOOKMARK_TYPE_FORM_TABLE = "BOOKMARK_TYPE_FORM_TABLE";
export const BOOKMARK_TYPE_TABLE = "BOOKMARK_TYPE_TABLE";

export const BOOKMARK_COLORANTS_AMOUNT = "bookmark_colorants_amount";
export const BOOKMARK_COLORS = "bookmark_colors";
export const BOOKMARK_DUPLICATE = "bookmark_duplicate";
export const BOOKMARK_FILES = "bookmark_files";
export const BOOKMARK_FORMULA = "bookmark_formula";
export const BOOKMARK_FORMULA_COLORANT = "bookmark_formula_colorant";
export const BOOKMARK_FORMULA_HISTORY = "bookmark_formula_history";
export const BOOKMARK_GENERAL = "bookmark_general";
export const BOOKMARK_NOTES = "bookmark_notes";
export const BOOKMARK_PRICES = "bookmark_prices";
export const BOOKMARK_PROPERTIES = "bookmark_properties";
export const BOOKMARK_SPECTRAL_DATA = "bookmark_spectral_data";
