import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PackageTable } from "./tables/PackageTable";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericPackageSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tablePackages: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericPackageScene extends Component<Props, State> {
    state: State = {
        tablePackages:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.packageTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tablePackages:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.packageTableConstant)
                        : null) || null
            });
        }
    }

    getPackagesTable = (): JSX.Element | null => {
        const { packageTableConstant } = this.props;
        const { tablePackages } = this.state;

        if (tablePackages) {
            return (
                <PackageTable
                    tableConstant={packageTableConstant}
                    objectType={objectTypes.PACKAGE}
                    dataPrivileges={tablePackages}
                    loading={this.props.packageLoading}
                    buttonLoading={this.props.packageButtonLoading}
                    unitList={this.props.unitList}
                    allList={this.props.packageList}
                    activeList={this.props.packageActiveList}
                    columnOrder={this.props.packageColumnOrder}
                    columnVisibility={this.props.packageColumnVisibility}
                    columnWidth={this.props.packageColumnWidth}
                    offset={this.props.packageOffset}
                    page={this.props.packagePage}
                    rowCount={this.props.packageRowCount}
                    rowCountCustom={this.props.packageRowCountCustom}
                    search={this.props.packageSearch}
                    searchParams={this.props.packageSearchParams}
                    showFilterRow={this.props.packageShowFilterRow}
                    showGlobalSearch={this.props.packageShowGlobalSearch}
                    sortingAsc={this.props.packageSortingAsc}
                    sortingCriterion={this.props.packageSortingCriterion}
                    totalCount={this.props.packageTotalCount}
                    useTmcNames={this.props.optionUseTmcNames || false}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getPackagesTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.packageSceneTableSize}
                dataPrivileges={this.state.tablePackages}
                sceneConstant={menuConstants.PAGE_GENERIC_PACKAGES}
            />
        );
    }
}
