import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";

import { RedlikeOption, updateRedlikeOption } from "../types/redlikeOption";

import { Option } from "../types/option";

export type OptionsState = Readonly<{
    masterAllList: Array<RedlikeOption>;
    masterList: Array<RedlikeOption>;
    masterActiveList: Array<RedlikeOption>;
    masterCount: number | null;
    masterShowDefaultValues: boolean;
    masterSortingAsc: boolean;
    masterSortingCriterion: propertyConstants.Property;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    systemAllList: Array<RedlikeOption>;
    systemList: Array<RedlikeOption>;
    systemActiveList: Array<RedlikeOption>;
    systemCount: number | null;
    systemShowDefaultValues: boolean;
    systemSortingAsc: boolean;
    systemSortingCriterion: propertyConstants.Property;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    zoneAllList: Array<RedlikeOption>;
    zoneList: Array<RedlikeOption>;
    zoneActiveList: Array<RedlikeOption>;
    zoneCount: number | null;
    zoneShowDefaultValues: boolean;
    zoneSortingAsc: boolean;
    zoneSortingCriterion: propertyConstants.Property;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    globalOptionList: Array<Option>;
    userOptionList: Array<Option>;
}>;

const initialState: OptionsState = {
    masterAllList: [],
    masterList: [],
    masterActiveList: [],
    masterCount: null,
    masterShowDefaultValues: tableConstants.DEFAULT_SHOW_DEFAULT_VALUES,
    masterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    masterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemAllList: [],
    systemList: [],
    systemActiveList: [],
    systemCount: null,
    systemShowDefaultValues: tableConstants.DEFAULT_SHOW_DEFAULT_VALUES,
    systemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneAllList: [],
    zoneList: [],
    zoneActiveList: [],
    zoneCount: null,
    zoneShowDefaultValues: tableConstants.DEFAULT_SHOW_DEFAULT_VALUES,
    zoneSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    globalOptionList: [],
    userOptionList: []
};

// DEPRECATED globalOptionList + userOptionList
// use login.options for current options for logged in user
// to use again, uncomment serverEventResponseService EVENT_OPTIONS_CHANGED, but be careful about real-time options reloading

export default function options(state: OptionsState = initialState, action: Record<string, any>): OptionsState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    const sortedAllList = generalHelper.sortItemsByProperty(
                        action.payload.list,
                        propertyConstants.PROPERTY_KEY,
                        state.masterSortingAsc
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        sortedAllList,
                        state.masterSearch
                    );
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        filteredSearchList,
                        state.masterShowDefaultValues
                    );
                    const newActiveList = generalHelper.updateActiveLists(
                        state.masterActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        masterAllList: sortedAllList,
                        masterList: defaultValuesFilteredList,
                        masterActiveList: newActiveList,
                        masterCount: defaultValuesFilteredList.length
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    const sortedAllList = generalHelper.sortItemsByProperty(
                        action.payload.list,
                        propertyConstants.PROPERTY_KEY,
                        state.systemSortingAsc
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        sortedAllList,
                        state.systemSearch
                    );
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        filteredSearchList,
                        state.systemShowDefaultValues
                    );
                    const newActiveList = generalHelper.updateActiveLists(
                        state.systemActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        systemAllList: sortedAllList,
                        systemList: defaultValuesFilteredList,
                        systemActiveList: newActiveList,
                        systemCount: defaultValuesFilteredList.length
                    };
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    const sortedAllList = generalHelper.sortItemsByProperty(
                        action.payload.list,
                        propertyConstants.PROPERTY_KEY,
                        state.zoneSortingAsc
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        sortedAllList,
                        state.zoneSearch
                    );
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        filteredSearchList,
                        state.zoneShowDefaultValues
                    );
                    const newActiveList = generalHelper.updateActiveLists(
                        state.zoneActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        zoneAllList: sortedAllList,
                        zoneList: defaultValuesFilteredList,
                        zoneActiveList: newActiveList,
                        zoneCount: defaultValuesFilteredList.length
                    };
                }
                case objectTypes.GLOBAL_OPTION: {
                    const newActiveList = generalHelper.updateActiveLists(state.globalOptionList, action.payload.list);
                    return {
                        ...state,
                        globalOptionList: newActiveList
                    };
                }
                case objectTypes.USER_OPTION: {
                    const newActiveList = generalHelper.updateActiveLists(state.userOptionList, action.payload.list);
                    return {
                        ...state,
                        userOptionList: newActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.GLOBAL_OPTION: {
                    return {
                        ...state,
                        globalOptionList: action.payload.list
                    };
                }
                case objectTypes.USER_OPTION: {
                    return {
                        ...state,
                        userOptionList: action.payload.list
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.masterAllList,
                        state.masterShowDefaultValues
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        defaultValuesFilteredList,
                        action.payload.search
                    );
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, filteredSearchList);

                    return {
                        ...state,
                        masterList: filteredSearchList,
                        masterActiveList: newActiveList,
                        masterCount: filteredSearchList.length,
                        masterSearch: action.payload.search
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.systemAllList,
                        state.systemShowDefaultValues
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        defaultValuesFilteredList,
                        action.payload.search
                    );

                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, filteredSearchList);

                    return {
                        ...state,
                        systemList: filteredSearchList,
                        systemActiveList: newActiveList,
                        systemCount: filteredSearchList.length,
                        systemSearch: action.payload.search
                    };
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    const defaultValuesFilteredList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.zoneAllList,
                        state.zoneShowDefaultValues
                    );
                    const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                        defaultValuesFilteredList,
                        action.payload.search
                    );
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, filteredSearchList);

                    return {
                        ...state,
                        zoneList: filteredSearchList,
                        zoneActiveList: newActiveList,
                        zoneCount: filteredSearchList.length,
                        zoneSearch: action.payload.search
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    return {
                        ...state,
                        masterList: generalHelper.sortItemsByProperty(
                            state.masterList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        masterSortingAsc: action.payload.asc,
                        masterSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    return {
                        ...state,
                        systemList: generalHelper.sortItemsByProperty(
                            state.systemList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        systemSortingAsc: action.payload.asc,
                        systemSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    return {
                        ...state,
                        zoneList: generalHelper.sortItemsByProperty(
                            state.zoneList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        zoneSortingAsc: action.payload.asc,
                        zoneSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SHOW_SPECIFIC_VALUES: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    const filteredSearchList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.masterAllList,
                        action.payload.showSpecificValues
                    );
                    const defaultValuesFilteredList = generalHelper.getGlobalSearchFilteredList(
                        filteredSearchList,
                        state.masterSearch
                    );

                    const newActiveList = generalHelper.updateActiveLists(
                        state.masterActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        masterList: defaultValuesFilteredList,
                        masterActiveList: newActiveList,
                        masterCount: defaultValuesFilteredList.length,
                        masterShowDefaultValues: action.payload.showSpecificValues
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    const filteredSearchList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.systemAllList,
                        action.payload.showSpecificValues
                    );
                    const defaultValuesFilteredList = generalHelper.getGlobalSearchFilteredList(
                        filteredSearchList,
                        state.systemSearch
                    );
                    const newActiveList = generalHelper.updateActiveLists(
                        state.systemActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        systemList: defaultValuesFilteredList,
                        systemActiveList: newActiveList,
                        systemCount: defaultValuesFilteredList.length,
                        systemShowDefaultValues: action.payload.showSpecificValues
                    };
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    const filteredSearchList = generalHelper.getFilteredListBasedOnSource(
                        action.payload.objectType,
                        state.zoneAllList,
                        action.payload.showSpecificValues
                    );
                    const defaultValuesFilteredList = generalHelper.getGlobalSearchFilteredList(
                        filteredSearchList,
                        state.zoneSearch
                    );
                    const newActiveList = generalHelper.updateActiveLists(
                        state.zoneActiveList,
                        defaultValuesFilteredList
                    );

                    return {
                        ...state,
                        zoneList: defaultValuesFilteredList,
                        zoneActiveList: newActiveList,
                        zoneCount: defaultValuesFilteredList.length,
                        zoneShowDefaultValues: action.payload.showSpecificValues
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_OPTION: {
                        const updatedOptionList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_KEY],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateRedlikeOption
                        );

                        if (updatedOptionList.length) {
                            return {
                                ...state,
                                masterList: updatedOptionList
                            };
                        }

                        return state;
                    }
                    case objectTypes.SYSTEM_REDLIKE_OPTION: {
                        const updatedOptionList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_KEY],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateRedlikeOption
                        );

                        if (updatedOptionList.length) {
                            return {
                                ...state,
                                systemList: updatedOptionList
                            };
                        }

                        return state;
                    }
                    case objectTypes.ZONE_REDLIKE_OPTION: {
                        const updatedOptionList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_KEY],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateRedlikeOption
                        );

                        if (updatedOptionList.length) {
                            return {
                                ...state,
                                zoneList: updatedOptionList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_OPTION: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_REDLIKE_OPTION: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_REDLIKE_OPTION: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return {
                ...initialState,
                masterAllList: state.masterAllList,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterShowDefaultValues: state.masterShowDefaultValues,
                masterCount: state.masterCount,
                masterSortingAsc: state.masterSortingAsc,
                masterSortingCriterion: state.masterSortingCriterion,
                masterSearch: state.masterSearch
            };
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            return {
                ...initialState,
                masterAllList: state.masterAllList,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterShowDefaultValues: state.masterShowDefaultValues,
                masterCount: state.masterCount,
                masterSortingAsc: state.masterSortingAsc,
                masterSortingCriterion: state.masterSortingCriterion,
                masterSearch: state.masterSearch,
                systemAllList: state.systemAllList,
                systemList: state.systemList,
                systemActiveList: state.systemActiveList,
                systemShowDefaultValues: state.systemShowDefaultValues,
                systemCount: state.systemCount,
                systemSortingAsc: state.systemSortingAsc,
                systemSortingCriterion: state.systemSortingCriterion,
                systemSearch: state.systemSearch
            };
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
