export const PRODUCT_REDMAN = 120;
export const PRODUCT_REDMAN_BASIC = 121;

export const STATUS_ERROR = -1;
export const STATUS_UNKNOWN = 0;
export const STATUS_VALID_UNLIMITED_LICENSE = 1;
export const STATUS_VALID_LEASED_LICENSE = 2;

// Steps used to get through license dialogs
export const LICENSE_ZERO_PROCESS_STEP = 0;
export const LICENSE_FIRST_PROCESS_STEP = 1;
export const LICENSE_SECOND_PROCESS_STEP = 2;
export const LICENSE_THIRD_PROCESS_STEP = 3;

export const LICENSE_ONLINE_ACTIVATION_STEPS = [
    LICENSE_FIRST_PROCESS_STEP,
    LICENSE_SECOND_PROCESS_STEP,
    LICENSE_THIRD_PROCESS_STEP
];

// update license messages
export const LICENSE_MESSAGE_ERROR_ON_SAVING_LICENSE = "error_on_saving_license";
export const LICENSE_MESSAGE_INVALID = "invalid_license";
export const LICENSE_MESSAGE_NO_CONNECTION_TO_LICENSE_SERVER = "no_connection_to_license_server";
export const LICENSE_MESSAGE_UPDATED = "license_updated";
export const LICENSE_MESSAGE_VALID_NO_UPDATE_NEEDED = "license_valid_no_update_needed";
export const LICENSE_MESSAGE_VERIFICATION_FAILED = "license_verification_failed";
