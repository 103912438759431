import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { Fandeck } from "../../../types/fandeck";
import { MasterColorTable } from "../../scenes/master/tables/MasterColorTable";
import { MasterColorWithFormulaInfoTable } from "../../scenes/master/tables/MasterColorWithFormulaInfoTable";
import { ModalLabelInputButtonContainer } from "../../general/modal/ModalLabelInputButton";
import { SystemColorTable } from "../../scenes/system/tables/SystemColorTable";
import { SystemColorWithFormulaInfoTable } from "../../scenes/system/tables/SystemColorWithFormulaInfoTable";
import { ZoneColorTable } from "../../scenes/zone/tables/ZoneColorTable";
import { ZoneColorWithFormulaInfoTable } from "../../scenes/zone/tables/ZoneColorWithFormulaInfoTable";

import { translate } from "react-i18nify";

type Props = {
    /**
     * title: title for the whole section which si displayed above the components
     * tableConstant: identifier for table, used for server communication
     * objectType: table works with this object, important for all communication and data loading
     * dataPrivileges: decides whether the data can be viewed, updated, deleted
     * loading: some of the server requests are pending
     * allList: list of items into table
     * activeList: active item in table
     * totalCount: total number of items in the table, without paging
     * offset: number of items to skip, used for paging
     * page: current page
     * search: text in the global search
     * searchParams: contains search text for each column separately
     * sortingAsc: specifies whether sorting should be ascending or descending
     * sortingCriterion: specifies the column according to which the sorting is done
     * rowCount: number of items on page, specified in options
     * showFilterRow: specifies whether this table should display row with column filters
     * showGlobalSearch: specifies whether this table should display global search input
     * columnVisibility: store columns which are visible and which are not
     * useTmcNames: boolean value if the TMC name is visible according to option
     * fandeckActiveList: stores selected fandeck
     * inputs: //TODO
     */
    title: string;
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<any>;
    activeList: Array<any>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    useLargoNames: boolean;
    useTmcNames: boolean;
    fandeckActiveList: Array<Fandeck>;
    inputs: Array<any>;
};

export class SceneFilterTable extends Component<Props> {
    getFilteredSelectbox = (): JSX.Element => {
        const { inputs } = this.props;
        const items = [];

        for (const item of inputs) {
            items.push(
                <div className="row" key={item.labelCaption}>
                    <ModalLabelInputButtonContainer
                        className={item.filterClassName}
                        editable={true}
                        required={true}
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={this.props.objectType}
                        inputLoading={item.filterLoading}
                        labelCaption={item.labelCaption}
                        inputClassName={"button-select"}
                        inputType={inputConstants.PARAMETER_TYPE_FILTER_SELECT_BOX}
                        inputName={""}
                        inputValue={item.filterValue}
                        inputOptions={item.filterOptions}
                        inputAutoselect={true}
                        inputCallbackClick={(value: any): void =>
                            item.filterValueCallbackClick
                                ? item.filterValueCallbackClick(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        inputCallbackChange={(value: any): void =>
                            item.filterValueCallbackChange
                                ? item.filterValueCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                </div>
            );
        }

        return (
            <div className="panel-section">
                {items}
                {this.getTableTitle()}
            </div>
        );
    };

    getTableTitle = (): JSX.Element | null => {
        if (this.props.objectType === objectTypes.MASTER_COLOR_WITH_FORMULA_INFO) {
            return <div className="table-title">{translate("color.color")}</div>;
        }

        return null;
    };

    getTable = (): JSX.Element | null => {
        const { objectType } = this.props;

        switch (objectType) {
            case objectTypes.MASTER_COLOR: {
                return (
                    <MasterColorTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        fandeckActiveList={this.props.fandeckActiveList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useLargoNames={this.props.useLargoNames}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            case objectTypes.SYSTEM_COLOR: {
                return (
                    <SystemColorTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        fandeckActiveList={this.props.fandeckActiveList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            case objectTypes.ZONE_COLOR: {
                return (
                    <ZoneColorTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        fandeckActiveList={this.props.fandeckActiveList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                return (
                    <MasterColorWithFormulaInfoTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                return (
                    <SystemColorWithFormulaInfoTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                return (
                    <ZoneColorWithFormulaInfoTable
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={this.props.loading}
                        allList={this.props.allList}
                        activeList={this.props.activeList}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        offset={this.props.offset}
                        page={this.props.page}
                        rowCount={this.props.rowCount}
                        rowCountCustom={this.props.rowCountCustom}
                        search={this.props.search}
                        searchParams={this.props.searchParams}
                        showFilterRow={this.props.showFilterRow}
                        showGlobalSearch={this.props.showGlobalSearch}
                        sortingAsc={this.props.sortingAsc}
                        sortingCriterion={this.props.sortingCriterion}
                        totalCount={this.props.totalCount}
                        useTmcNames={this.props.useTmcNames}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    render(): JSX.Element {
        return (
            <div className="layout-item panel-item" key={this.props.tableConstant}>
                <div className="height-100">
                    <header>
                        <div className="row drag-handle-content">
                            <div className="main-title">{this.props.title}</div>
                        </div>
                    </header>
                    {this.getFilteredSelectbox()}
                    {this.getTable()}
                </div>
            </div>
        );
    }
}
