import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import ContentEditable from "react-contenteditable";
import { Dispatch } from "redux";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    content: string;
    isActive: boolean;
    isEditable: boolean;
    isSourceChanged: boolean;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellDate extends Component<Props> {
    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (): string => {
        let className = "td-edit";

        if (this.isDisabled()) {
            className += " edit-disabled";
        }

        return className;
    };

    getCellContent = (property: propertyConstants.Property, value: string | null): string => {
        return property === propertyConstants.PROPERTY_PRODUCTION_DATE
            ? formattingHelper.formatDate(value)
            : formattingHelper.formatDateTime(value);
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        let value = item && item[property] !== undefined ? item[property] : null;

        if (this.props.isActive) {
            value = this.props.content;
        }

        const content = this.getCellContent(property, value);
        const editableHtml = `<span class="${this.props.isSourceChanged ? "dotted" : ""}">${content}</span>`;

        return (
            <div
                className={`td td-edit ${property}`}
                key={property}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
            >
                <ContentEditable
                    className={this.getClassname()}
                    disabled={this.isDisabled()}
                    html={editableHtml}
                    key={property}
                    tagName={"div"}
                    onChange={(): void => {
                        return;
                    }}
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, content)}
                    data-tip
                    data-for={"table-cell-tooltip"}
                />
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellDateContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellDate);
