import * as designConstants from "../../../constants/designConstants";
import * as generalHelper from "../../../helpers/generalHelper";
import * as inputConstants from "../../../constants/inputConstants";
import * as modalHelper from "../../../helpers/modalHelper";
import * as modalTypes from "../../../constants/modalTypes";
import * as navigationActions from "../../../actions/navigationActions";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as serverRestMethods from "../../../constants/serverRestMethods";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";
import { createEmptyImage, generateImageHeadings } from "../../../types/image";

import { AppState } from "../../../reducers";
import { Barcode } from "../../../types/barcode";
import { Currency } from "../../../types/currency";
import { Dispatch } from "redux";
import { ModalBarcode } from "../inputs/ModalBarcode";
import { ModalButton } from "../inputs/ModalButton";
import { ModalCheckbox } from "../inputs/ModalCheckbox";
import { ModalCheckboxList } from "../inputs/ModalCheckboxList";
import { ModalConversionInputs } from "../inputs/ModalConversionInputs";
import { ModalDatePicker } from "../inputs/ModalDatePicker";
import { ModalFileInputContainer as ModalFileInput } from "../inputs/ModalFileInput";
import { ModalFilterSelectbox } from "../inputs/ModalFilterSelectbox";
import { ModalIcon } from "../inputs/ModalIcon";
import { ModalInputButton } from "./ModalInputButton";
import { ModalParams } from "../../../types/modalParams";
import { ModalRadiobutton } from "../inputs/ModalRadiobutton";
import { ModalReadOnlyInput } from "../inputs/ModalReadOnlyInput";
import { ModalSelectbox } from "../inputs/ModalSelectbox";
import { ModalTextArea } from "../inputs/ModalTextArea";
import { ModalTextInput } from "../inputs/ModalTextInput";
import { ModalType } from "../../../constants/modalTypes";
import { PasswordInput } from "../inputs/PasswordInput";
import { ReactSVG } from "react-svg";
import ReactTooltip from "react-tooltip";
import { TableHeading } from "../../../types/tableHeading";

import { connect } from "react-redux";

import imgAddImg from "../../../resources/img/icons/add-image.svg";
import imgClose from "../../../resources/img/icons/close.svg";
import imgImg from "../../../resources/img/icons/image.svg";
import imgInfo from "../../../resources/img/icons/info.svg";
import noImage from "../../../resources/img/icons/no-image.svg";

import { t as translate } from "react-i18nify";

type OwnProps = PropsType & DispatchType;

type ContainerProps = {
    /**
     * modalId: ID of the modal containing this component, for correct file upload and validation
     * className: additional classname for the whole section of input
     * type: heading type of the item and so far is needed in the ModalInputButton
     * objectType: //TODO
     * editable: parameter which decides whether the item is editable
     * required: decides whether the item is required in the modal or not
     * labelClassName: additional className for the label for design changes
     * labelCaption: displayed text in the label section
     * inputs: //TODO
     * inputClassName: //TODO
     * inputType: type of the input, these types are defined in inputConstants
     * inputName: input heading name = property, for instance necessary for FileSection and ModalInputButton
     * inputCaption: additional displayed text showed within the input part (for instance for checkbox)
     * inputValue: value in the input which is being updated
     * inputUnit: unit which is displayed inside the input (it can be for instance % in VAT)
     * inputOptions: array of options displayed in radio buttons or select box
     * inputAutoselect: decides whether first value from inputOptions should be automatically selected
     * inputNote: additional information displayed under question mark
     * inputCallbackChange: method containing the onChange event handler for input
     * inputCallbackBlur: method containing the onBlur event handler for input
     * inputCallbackKeyDown: //TODO
     * fileId : contains fileId and is used to update specific file in the modal
     * fileValidation: decides what kind of files are allowed
     * fileCallback: method containing callback for updating imageId/file
     * buttonClassName: classname for the button
     * buttonEditable: parameter which decides whether the button is editable
     * buttonVisible: parameter which decides whether the button is visible
     * buttonCaption: text displayed on the button
     * buttonCallback: method containing callback when button is clicked
     * currency: active currency for rounding
     * innerRowIndex: index of the row in more rows component
     * object: stores object/part of the object which is stored in the state in the parent Modal
     */
    modalId?: number;
    className?: string;
    type: tableConstants.TableType;
    objectType: objectTypes.ObjectType;
    editable: boolean;
    required: boolean;
    inputLoading?: boolean;
    labelClassName?: string;
    labelCaption: string;
    inputs?: Array<any>;
    inputClassName: string;
    inputType: inputConstants.InputType | inputConstants.UploadType | any;
    inputName: propertyConstants.Property | any;
    inputInnerName?: propertyConstants.Property | any;
    inputCaption?: string;
    inputValue?: any;
    inputUnit?: string;
    inputOptions?: Array<{ key: string; value: string }>;
    inputAllList?: Array<any>;
    inputList?: Array<any>;
    inputAutoselect?: boolean;
    inputNote?: string | null;
    inputCallbackClick?: (value: any) => any;
    inputCallbackChange?: (value: any) => any;
    inputCallbackBlur?: (value: any) => any;
    inputCallbackKeyDown?: () => any;
    fileId?: number | null;
    fileValidation?: string;
    fileValidTypes?: Array<string>;
    fileCallback?: (property: propertyConstants.Property, value: any, otherValue?: any, nameValue?: any) => any;
    buttonClassName?: string;
    buttonEditable?: boolean;
    buttonVisible?: boolean;
    buttonCaption?: string | JSX.Element;
    buttonCallback?: (value?: any) => any; // TODO value undef?
    currency?: Currency | null;
    innerRowIndex?: number;
    object?: any;
    confirm?: (id: string | number | null) => any;
    setUploadState?: (isUploading: boolean) => void;
};

type Props = ContainerProps & OwnProps;

type State = {
    tooltipOn: number | null;
    tooltipText: string;
};

export class ModalLabelInputButton extends Component<Props> {
    state: State = {
        tooltipOn: null,
        tooltipText: ""
    };

    handleMouseEnter = (property: propertyConstants.Property): void => {
        this.setState({
            tooltipOn: designConstants.TOOLTIP_DESCRIPTION,
            tooltipText: translationHelper.getHeadingTooltipTranslation(
                this.props.objectType,
                property,
                this.props.custom
            )
        });
    };

    handleMouseEnterEllipsis = (event: React.MouseEvent<HTMLDivElement>, labelCaption: string): void => {
        const element = event.currentTarget;

        if (element.offsetWidth < element.scrollWidth) {
            this.setState({
                tooltipOn: designConstants.TOOLTIP_LABEL,
                tooltipText: labelCaption
            });
        }
    };

    handleMouseLeave = (): void => {
        this.setState({
            tooltipOn: false,
            tooltipText: ""
        });
    };

    getButtonClassName = (): string => {
        let newClassName = this.props.buttonClassName || "";

        if (!this.props.editable || this.props.buttonEditable === false) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getLabel = (): JSX.Element | null => {
        const { labelCaption } = this.props;

        if (!labelCaption) {
            return null;
        }

        return (
            <div
                className="form-label"
                onMouseEnter={(event): void => this.handleMouseEnterEllipsis(event, labelCaption)}
                onMouseLeave={(): void => this.handleMouseLeave()}
                data-for="modal-tooltip-label"
                data-tip
            >
                <label className={this.props.labelClassName}>
                    {labelCaption}
                    {this.props.required ? "*" : ""}
                </label>
            </div>
        );
    };

    getRgbPreview = (): string | null | undefined => {
        if (propertyHelper.isParentPropertyColorHex(this.props.inputName)) {
            return this.props.inputValue?.toString() || null;
        }

        return undefined;
    };

    getButton = (): JSX.Element | null => {
        const { buttonCaption, buttonCallback, buttonVisible } = this.props;

        if (!buttonCaption || !buttonCallback || !buttonVisible) {
            return null;
        }

        if (!this.props.editable || this.props.buttonEditable === false) {
            return (
                <button key="button" className={this.getButtonClassName()} disabled>
                    {buttonCaption}
                </button>
            );
        }

        return (
            <button
                key="button"
                className={this.getButtonClassName()}
                onClick={(event) => {
                    event.preventDefault();
                    buttonCallback();
                }}
            >
                {buttonCaption}
            </button>
        );
    };

    getInputNote = (): JSX.Element | null => {
        const { inputNote } = this.props;

        if (inputNote) {
            return (
                <div className="info-input-note" key={inputNote}>
                    <ReactSVG className="svg-icon" src={imgInfo} />
                    <span>{inputNote}</span>
                </div>
            );
        }

        return null;
    };

    generateImageTableHeadings = (objectType: objectTypes.ObjectType): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_IMAGE_ID
        ];

        return generateImageHeadings(
            objectType,
            null,
            [],
            [],
            [...propertyList],
            [...propertyList],
            [...propertyList],
            [propertyConstants.PROPERTY_NAME],
            [...propertyList],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    getAddImageButton = (): JSX.Element => {
        const { inputName, fileCallback } = this.props;

        const title =
            inputName === propertyConstants.PROPERTY_ICON_ID ? translate("image.addIcon") : translate("image.addImage");
        const objectType = inputName === propertyConstants.PROPERTY_ICON_ID ? objectTypes.ICON : objectTypes.IMAGE;

        const modalType =
            inputName === propertyConstants.PROPERTY_ICON_ID ? modalTypes.MODAL_ICONS_ADD : modalTypes.MODAL_IMAGES_ADD;
        const modalParams = new ModalParams(
            modalTypes.ADD_MODAL,
            objectType,
            title,
            createEmptyImage(inputName === propertyConstants.PROPERTY_ICON_ID),
            this.generateImageTableHeadings(objectType)
        );
        modalParams.returnAction = (imageId: number | null): void =>
            fileCallback
                ? fileCallback(inputName, imageId)
                : (): void => {
                      return;
                  };

        return (
            <label className="image-input" onClick={(): void => this.props.openModal(modalType, modalParams)}>
                <ReactSVG src={imgAddImg} />
            </label>
        );
    };

    getSelectImageButton = (): JSX.Element => {
        const { inputName, fileCallback } = this.props;
        const modalType =
            inputName === propertyConstants.PROPERTY_ICON_ID
                ? modalTypes.MODAL_ICONS_TABLE
                : modalTypes.MODAL_IMAGES_TABLE;
        const modalParams = {
            objectType: this.props.objectType,
            returnAction: (image: number | null): void =>
                fileCallback
                    ? fileCallback(inputName, image)
                    : (): void => {
                          return;
                      }
        };

        return (
            <label className="image-input" onClick={(): void => this.props.openModal(modalType, modalParams)}>
                <ReactSVG src={imgImg} />
            </label>
        );
    };

    getCancelImageButton = (): JSX.Element | null => {
        const { object, inputName, fileCallback } = this.props;

        if (
            !object ||
            object[inputName] === null ||
            object[inputName] === "" ||
            generalHelper.isImageInherited(this.props.objectType, object)
        ) {
            return null;
        }

        return (
            <label
                className="image-input"
                onClick={(): void =>
                    fileCallback
                        ? fileCallback(inputName, null)
                        : (): void => {
                              return;
                          }
                }
            >
                <ReactSVG src={imgClose} />
            </label>
        );
    };

    getImageButtons = (): JSX.Element | null => {
        const { objectType, type } = this.props;

        // Buttons are not visible in Generic image modals
        if (type !== tableConstants.TABLE_TYPE_IMAGE_SECTION) {
            return null;
        }

        // Buttons are not visible for system and zone formula notes, as these images cannot be edited
        if (objectType === objectTypes.SYSTEM_FORMULA_NOTE || objectType === objectTypes.ZONE_FORMULA_NOTE) {
            return null;
        }

        if (!this.props.editable) {
            return (
                <div className="image-input-container" key="image-input">
                    <label className="image-input disabled">
                        <ReactSVG src={imgAddImg} />
                    </label>
                    <label className="image-input disabled">
                        <ReactSVG src={imgImg} />
                    </label>
                </div>
            );
        }

        return (
            <div className="image-input-container" key="image-input">
                {this.getAddImageButton()}
                {this.getSelectImageButton()}
                {this.getCancelImageButton()}
            </div>
        );
    };

    getImagePreview = (): JSX.Element | null => {
        const { sessionUuid, fileId, object } = this.props;
        // Image was uploaded right now, it is not send to server yet
        if (object?.[propertyConstants.PROPERTY_SRC]) {
            return (
                <div className="image-container">
                    <img key="file-image" alt="loaded file" src={object[propertyConstants.PROPERTY_SRC]} />
                </div>
            );
        }

        // Image stored in database, display its thumbnail by fileId
        if (fileId && sessionUuid) {
            return (
                <div className="image-container">
                    <img
                        key="file-image"
                        alt="loaded file"
                        src={`${this.props.urlRest}${serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID}?session_uuid=${sessionUuid}&image_id=${fileId}&height=500&width=500`}
                    />
                </div>
            );
        }

        // There is no image to display, moreover it is not possible to insert an image
        if (!this.props.editable) {
            return (
                <div className="no-image-info-container type-input">
                    <ReactSVG src={noImage} className="svg-icon" />
                    <span>{translate("image.noImageAvailable")}</span>
                </div>
            );
        }

        // There is no image to display, but it may be inserted
        return null;
    };

    getImage = (): JSX.Element | null => {
        if (modalHelper.isImagePreviewUploadType(this.props.inputType)) {
            return (
                <div key="image-preview" id="images">
                    {this.getImagePreview()}
                    {this.getImageButtons()}
                </div>
            );
        }

        return null;
    };

    // Rendering main subcomponents

    getInputByType = (): JSX.Element | null => {
        const {
            modalId,
            inputType,
            editable,
            type,
            objectType,
            required,
            inputLoading,
            inputClassName,
            inputName,
            inputCaption,
            inputValue,
            fileValidation,
            fileValidTypes,
            fileId,
            fileCallback,
            inputOptions,
            inputAutoselect,
            inputCallbackClick,
            inputCallbackChange,
            inputCallbackBlur,
            inputCallbackKeyDown,
            inputUnit,
            buttonCallback,
            buttonClassName,
            innerRowIndex,
            object,
            confirm,
            setUploadState
        } = this.props;

        switch (inputType) {
            case inputConstants.PARAMETER_TYPE_BARCODE:
                return (
                    <ModalBarcode
                        className={inputClassName}
                        property={inputName}
                        editable={editable}
                        required={required}
                        value={inputValue ? inputValue : ""} // TODO only string can pass
                        innerRowIndex={innerRowIndex || 0}
                        barcodes={object?.[inputName] || []}
                        callbackChange={(values: Array<Barcode>): void =>
                            inputCallbackChange
                                ? inputCallbackChange(values)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_BUTTON_INPUT:
                return (
                    <ModalInputButton
                        objectType={objectType}
                        className={inputClassName}
                        type={type}
                        property={inputName}
                        editable={editable}
                        required={required}
                        value={inputValue}
                        unit={inputUnit}
                        object={{ ...object }}
                        callbackInputChange={(value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackInputBlur={(value: string): void =>
                            inputCallbackBlur
                                ? inputCallbackBlur(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackButton={(value: string): void =>
                            buttonCallback
                                ? buttonCallback(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_BUTTON_SECTION:
                return (
                    <ModalButton
                        className={inputClassName}
                        editable={editable}
                        buttonTitle={inputCaption}
                        buttonClassName={buttonClassName}
                        callbackButton={(): void =>
                            buttonCallback
                                ? buttonCallback()
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_CHECK_BOX:
                return (
                    <ModalCheckbox
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        text={inputCaption}
                        value={inputValue ?? false}
                        callback={(value: boolean): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_CONVERSION_INPUTS:
                return (
                    <ModalConversionInputs
                        objectType={objectType}
                        className={inputClassName}
                        type={type}
                        property={inputName}
                        editable={editable}
                        required={required}
                        inputs={this.props.inputs}
                        currency={this.props.currency}
                        object={{ ...object }}
                        priceObject={{ ...(object?.[inputName] || {}) }}
                    />
                );
            case inputConstants.PARAMETER_TYPE_DATE_PICKER:
                return (
                    <ModalDatePicker
                        objectType={objectType}
                        editable={editable}
                        required={required}
                        value={inputValue}
                        property={inputName}
                        callbackChange={(value: string | null): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_ICON:
                return (
                    <ModalIcon
                        objectType={objectType}
                        editable={editable}
                        property={inputName}
                        value={inputValue ? inputValue : false}
                        callback={(event: any, value: boolean): void =>
                            inputCallbackBlur
                                ? inputCallbackBlur(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_FILTER_SELECT_BOX:
                return (
                    <ModalFilterSelectbox
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        value={inputValue ? inputValue : ""}
                        loading={inputLoading}
                        options={inputOptions ? inputOptions : []}
                        autoselect={inputAutoselect || false}
                        callback={(value: string): void =>
                            inputCallbackClick
                                ? inputCallbackClick(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackFilter={(value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_LIST: {
                return (
                    <ModalCheckboxList
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        type={this.props.inputInnerName}
                        text={inputCaption}
                        value={inputValue ?? false}
                        property={inputName}
                        allList={this.props.inputAllList ?? []}
                        selectedList={this.props.inputList}
                        innerRowIndex={innerRowIndex ?? 0}
                        callback={(values: Array<any>): void =>
                            inputCallbackChange
                                ? inputCallbackChange(values)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            }
            case inputConstants.PARAMETER_TYPE_PASSWORD:
                return (
                    <PasswordInput
                        modalId={modalId}
                        className={inputClassName}
                        editable={editable}
                        type={type}
                        objectType={objectType}
                        params={inputName}
                        value={inputValue ? inputValue : ""}
                        autoComplete="new-password"
                        callbackChange={(value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackBlur={(value: string): void =>
                            inputCallbackBlur
                                ? inputCallbackBlur(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackKeyDown={(): void =>
                            inputCallbackKeyDown
                                ? inputCallbackKeyDown()
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_RADIO_BUTTON:
                return (
                    <ModalRadiobutton
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        value={inputValue ? inputValue : false}
                        options={inputOptions ? inputOptions : []}
                        callback={(value: boolean): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_SELECT_BOX:
                return (
                    <ModalSelectbox
                        modalId={modalId}
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        value={inputValue ?? ""}
                        defaultCaption={inputCaption ?? null}
                        options={inputOptions ? inputOptions : []}
                        autoselect={inputAutoselect || false}
                        callback={(event: any, value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_TEXT_AREA:
                return (
                    <ModalTextArea
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        property={inputName}
                        value={inputValue ? inputValue : ""}
                        callbackChange={(value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackBlur={(value: string): void =>
                            inputCallbackBlur
                                ? inputCallbackBlur(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_TEXT_INPUT:
                return (
                    <ModalTextInput
                        modalId={modalId}
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        type={type}
                        objectType={objectType}
                        params={inputName}
                        value={inputValue ?? ""}
                        unit={inputUnit}
                        rgbPreview={this.getRgbPreview()}
                        callbackChange={(value: string): void =>
                            inputCallbackChange
                                ? inputCallbackChange(value)
                                : (): void => {
                                      return;
                                  }
                        }
                        callbackBlur={(value: string | number | null): void =>
                            inputCallbackBlur
                                ? inputCallbackBlur(value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                );
            case inputConstants.PARAMETER_TYPE_TEXT_READONLY:
                return (
                    <ModalReadOnlyInput
                        className={inputClassName}
                        objectType={objectType}
                        type={type}
                        value={inputValue}
                        property={inputName}
                    />
                );
            case inputConstants.UPDATE_TYPE_ICON:
            case inputConstants.UPDATE_TYPE_IMAGE:
            case inputConstants.UPLOAD_TYPE_DIRECTORY:
            case inputConstants.UPLOAD_TYPE_FILE:
            case inputConstants.UPLOAD_TYPE_FILES:
            case inputConstants.UPLOAD_TYPE_ICON:
            case inputConstants.UPLOAD_TYPE_IMAGE:
            case inputConstants.UPLOAD_TYPE_IMAGES:
                if (type === tableConstants.TABLE_TYPE_IMAGE_SECTION) {
                    return null;
                }

                return (
                    <ModalFileInput
                        modalId={modalId}
                        className={inputClassName}
                        editable={editable}
                        required={required}
                        type={inputType}
                        param={inputName}
                        fileId={fileId || null}
                        validation={fileValidation || ""}
                        validTypes={fileValidTypes}
                        object={object}
                        callback={(id: string | number | null, src?: string, name?: string): void =>
                            fileCallback
                                ? fileCallback(inputName, id, src, name)
                                : (): void => {
                                      return;
                                  }
                        }
                        confirm={(id: string | number | null): void =>
                            confirm
                                ? confirm(id)
                                : (): void => {
                                      return;
                                  }
                        }
                        setUploadState={(isUploading: boolean) =>
                            setUploadState
                                ? setUploadState(isUploading)
                                : () => {
                                      return;
                                  }
                        }
                    />
                );
            default:
                return null;
        }
    };

    getOtherElement = (): Array<JSX.Element> => {
        // Displaying button next to the input (import task - create new, formatted more info)
        const button = this.getButton();

        // Displaying note (static SQL - file format)
        const inputNote = this.getInputNote();

        // Displaying image preview
        const image = this.getImage();

        // Final list
        const elementList = [button, inputNote, image].filter((item) => item !== null) as Array<JSX.Element>;

        return elementList;
    };

    getTooltip = (): JSX.Element | null => {
        if (this.state.tooltipOn !== null && this.state.tooltipText !== "") {
            if (this.state.tooltipOn === designConstants.TOOLTIP_DESCRIPTION) {
                return (
                    <ReactTooltip id="modal-tooltip" className="tooltip modal-tooltip" delayShow={1000} place="bottom">
                        <span>{this.state.tooltipText}</span>
                    </ReactTooltip>
                );
            }
            if (this.state.tooltipOn === designConstants.TOOLTIP_LABEL) {
                return (
                    <ReactTooltip id="modal-tooltip-label" className="tooltip modal-tooltip" place="top">
                        <span>{this.state.tooltipText}</span>
                    </ReactTooltip>
                );
            }
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <React.Fragment>
                {this.getLabel()}
                <div
                    className={`form-input ${this.props.className || ""}`}
                    onMouseEnter={(): void => this.handleMouseEnter(this.props.inputName)}
                    onMouseLeave={(): void => this.handleMouseLeave()}
                    data-for="modal-tooltip"
                    data-tip
                >
                    {this.getInputByType()}
                    {this.getOtherElement()}
                    {this.getTooltip()}
                    <div className="notification-error-icon">!</div>
                </div>
            </React.Fragment>
        );
    }
}

export type PropsType = Readonly<{
    custom: string | null;
    sessionUuid: string | null;
    urlRest: string;
}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const ModalLabelInputButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ModalLabelInputButton);
