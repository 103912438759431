import * as menuConstants from "./menuConstants";
import * as propertyConstants from "./propertyConstants";
import * as tableConstants from "./tableConstants";

// option keys
export const OPTION_COMPANIES_FOR_USERS = "companies_for_users";
export const OPTION_DATABASE_AUTOSAVE = "database_autosave";
export const OPTION_DELETE_CONFIRMATION = "delete_confirmation";
export const OPTION_FORMULA_COLORANT_UNIT = "formula_colorant_unit";
export const OPTION_LANGUAGE = "language";
export const OPTION_USE_LARGO_NAMES = "use_largo_names";
export const OPTION_USE_SOURCES = "use_sources";
export const OPTION_USE_TMC_NAMES = "use_tmc_names";

// background option keys
export const OPTION_MENU_SECTION_VISIBILITY = "menu_section_visibility";
export const OPTION_TABLE_COLUMNS_ORDER = "table_columns_order";
export const OPTION_TABLE_COLUMNS_VISIBILITY = "table_columns_visibility";
export const OPTION_TABLE_COLUMNS_WIDTH = "table_columns_width";
export const OPTION_TABLE_ITEMS_VISIBILITY = "table_items_visibility";
export const OPTION_TABLE_ROW_COUNT = "row_count";
export const OPTION_TABLE_ROW_COUNT_CUSTOM = "row_count_custom";
export const OPTION_TABLE_SORTING = "table_sorting";
export const OPTION_SCENE_LAYOUT = "scene_layout";
export const OPTION_SCENE_TABLE_SIZE = "scene_table_size";
export const OPTION_SHOW_FILTER_ROW = "show_filter_row";
export const OPTION_SHOW_GLOBAL_SEARCH = "show_global_search";
export const OPTION_WAVELENGTH = "wavelength_options";

// layout types
export const LAYOUT_BIG_LEFT = "LAYOUT_BIG_LEFT";
export const LAYOUT_BIG_TOP = "LAYOUT_BIG_TOP";
export const LAYOUT_COLUMN = "LAYOUT_COLUMN";
export const LAYOUT_COLUMNS = "LAYOUT_COLUMNS";
export const LAYOUT_PANEL = "LAYOUT_PANEL";

// wavelength
export const INTERVAL_LIST = [10, 20];
export const DEFAULT_MAXIMUM_WAVELENGTH_BEGIN_VALUE = 400;
export const DEFAULT_MINIMUM_WAVELENGTH_END_VALUE = 700;
export const DEFAULT_WAVELENGTH_BEGIN_VALUE = 360;
export const DEFAULT_WAVELENGTH_END_VALUE = 780;
export const DEFAULT_COLUMN_WIDTH_1 = 50;
export const DEFAULT_COLUMN_WIDTH_2 = 100;
export const DEFAULT_COLUMN_WIDTH_3 = 150;
export const DEFAULT_COLUMN_WIDTH_4 = 200;

// table items
export const OPTION_EXPORT_TASK_SHOW_DELETED = "export_task_show_deleted";
export const OPTION_EXPORT_TASK_SHOW_HIDDEN = "export_task_show_hidden";
export const OPTION_IMPORT_TASK_SHOW_DELETED = "import_task_show_deleted";
export const OPTION_IMPORT_TASK_SHOW_HIDDEN = "import_task_show_hidden";

export const VISIBLE_USER_OPTIONS = [OPTION_DELETE_CONFIRMATION, OPTION_LANGUAGE];

export const VISIBLE_GLOBAL_OPTIONS = [
    OPTION_DELETE_CONFIRMATION,
    OPTION_USE_TMC_NAMES,
    OPTION_USE_LARGO_NAMES,
    OPTION_LANGUAGE
];

export const DEFAULT_FORMULA_COLORANT_UNIT = "ml";

export const DEFAULT_TABLE_ROW_COUNT = {
    [menuConstants.TABLE_COMPANIES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_DATA_DATABASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_DATA_EXPORT]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_DATA_IMPORT]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_BARCODES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_CURRENCIES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_IMAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GENERIC_UNITS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_GLOBAL_ZONES]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MASTER_COLOR_DATA]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_COLORS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_FILES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_ICONS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MODAL_IMAGES]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_MONITORING_DATABASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_COLORS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_USERS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_COLORS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_ZONE_PRICE_GENERIC]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_ZONE_PRICE_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_ZONE_PRICE_MARGIN]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT,
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT,
    // [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT
    // [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT,
};

export const DEFAULT_TABLE_ROW_COUNT_CUSTOM = {
    [menuConstants.TABLE_COMPANIES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_DATA_DATABASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_DATA_EXPORT]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_DATA_IMPORT]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_BARCODES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_CURRENCIES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_IMAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GENERIC_UNITS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_GLOBAL_ZONES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_COLOR_DATA]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_COLORS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_FILES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_ICONS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MODAL_IMAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_MONITORING_DATABASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_COLORS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_USERS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_COLORANTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_COLORS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_FANDECKS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_ZONE_PRICE_GENERIC]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_ZONE_PRICE_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_ZONE_PRICE_MARGIN]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
    // [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM
    // [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_ROW_COUNT_CUSTOM,
};

export const DEFAULT_SCENE_LAYOUT = {
    [menuConstants.PAGE_GENERIC_IMAGES]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_GENERIC_PRODUCT_SHEETS]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_GENERIC_STATIC_SQL]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_MASTER_COLORANTS]: LAYOUT_COLUMN,
    [menuConstants.PAGE_MASTER_COLORS]: LAYOUT_PANEL,
    [menuConstants.PAGE_MASTER_FORMULAS]: LAYOUT_PANEL,
    [menuConstants.PAGE_MASTER_PRODUCTS]: LAYOUT_COLUMN,
    [menuConstants.PAGE_MASTER_REDLIKE_USERS]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_SYSTEM_COLORANTS]: LAYOUT_COLUMN,
    [menuConstants.PAGE_SYSTEM_COLORS]: LAYOUT_PANEL,
    [menuConstants.PAGE_SYSTEM_FORMULAS]: LAYOUT_PANEL,
    [menuConstants.PAGE_SYSTEM_PRODUCTS]: LAYOUT_BIG_LEFT,
    [menuConstants.PAGE_SYSTEM_REDLIKE_USERS]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_USERS]: LAYOUT_COLUMNS,
    [menuConstants.PAGE_ZONE_COLORANTS]: LAYOUT_COLUMN,
    [menuConstants.PAGE_ZONE_COLORS]: LAYOUT_PANEL,
    [menuConstants.PAGE_ZONE_FORMULAS]: LAYOUT_PANEL,
    [menuConstants.PAGE_ZONE_PRODUCTS]: LAYOUT_BIG_LEFT,
    [menuConstants.PAGE_ZONE_REDLIKE_USERS]: LAYOUT_COLUMNS
};

export const DEFAULT_SHOW_FILTER_ROW = {
    [menuConstants.TABLE_COMPANIES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_DATA_DATABASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_DATA_EXPORT]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_DATA_IMPORT]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_BARCODES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_CURRENCIES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_IMAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_PACKAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GENERIC_UNITS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_GLOBAL_ZONES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MASTER_COLOR_DATA]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_COLORANTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_COLORS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_FANDECKS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_FILES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_ICONS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_IMAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MONITORING_DATABASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_COLORANTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_COLORS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_FANDECKS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_USER_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_USERS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_COLORANTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_COLORS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_FANDECKS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_ZONE_PRICE_GENERIC]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    // [menuConstants.TABLE_ZONE_PRICE_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_FILTER_ROW
    // [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
};

export const DEFAULT_SHOW_GLOBAL_SEARCH = {
    [menuConstants.TABLE_COMPANIES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_DATA_DATABASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_DATA_EXPORT]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_DATA_IMPORT]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_BARCODES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_CURRENCIES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_IMAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_PACKAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GENERIC_UNITS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_GLOBAL_ZONES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MASTER_COLOR_DATA]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_COLORANTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_COLORS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_FILTER_ROW,
    [menuConstants.TABLE_MASTER_FANDECKS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_FILES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_ICONS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_IMAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_MONITORING_DATABASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_COLORANTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_COLORS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_FANDECKS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_USER_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_USERS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_COLORANTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_COLORS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_FANDECKS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_ZONE_PRICE_GENERIC]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    // [menuConstants.TABLE_ZONE_PRICE_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH
    // [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: tableConstants.DEFAULT_SHOW_GLOBAL_SEARCH,
};

export const DEFAULT_TABLE_COLUMNS_ORDER = {
    [menuConstants.TABLE_COMPANIES]: [[propertyConstants.PROPERTY_NAME], [propertyConstants.PROPERTY_COMPANY_KEY]],
    [menuConstants.TABLE_DATA_DATABASES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PASSWORD],
        [propertyConstants.PROPERTY_COMPANY_ID],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_LENGTH],
        [propertyConstants.PROPERTY_DATE_CREATED],
        [propertyConstants.PROPERTY_DATE_UPDATED]
    ],
    [menuConstants.TABLE_DATA_EXPORT]: [
        [propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID],
        [propertyConstants.PROPERTY_ACTION],
        [propertyConstants.PROPERTY_DATABASE_NAME],
        [propertyConstants.PROPERTY_SYSTEM_UUID],
        [propertyConstants.PROPERTY_SYSTEM_NAME],
        [propertyConstants.PROPERTY_ZONE_UUID],
        [propertyConstants.PROPERTY_ZONE_NAME],
        [propertyConstants.PROPERTY_ZONE_PASSWORD,],
        [propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID],
        [propertyConstants.PROPERTY_VERSION],
        [propertyConstants.PROPERTY_VERSION_NAME],
        [propertyConstants.PROPERTY_CHANGELOG],
        [propertyConstants.PROPERTY_DATE_CREATED],
        [propertyConstants.PROPERTY_DATE_FINISHED]
    ],
    [menuConstants.TABLE_DATA_IMPORT]: [
        [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID],
        [propertyConstants.PROPERTY_ACTION],
        [propertyConstants.PROPERTY_DATABASE_NAME],
        [propertyConstants.PROPERTY_SYSTEM_UUID],
        [propertyConstants.PROPERTY_SYSTEM_NAME],
        [propertyConstants.PROPERTY_ZONE_UUID],
        [propertyConstants.PROPERTY_ZONE_NAME],
        [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID],
        [propertyConstants.PROPERTY_DATE_CREATED],
        [propertyConstants.PROPERTY_DATE_FINISHED],
        [propertyConstants.PROPERTY_UUID]
    ],
    [menuConstants.TABLE_GENERIC_BARCODES]: [
        [propertyConstants.PROPERTY_BARCODE],
        [propertyConstants.PROPERTY_ITEM_TYPE_ID],
        [propertyConstants.PROPERTY_SYSTEM_ID],
        [propertyConstants.PROPERTY_ZONE_ID]
    ],
    [menuConstants.TABLE_GENERIC_CURRENCIES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SYMBOL],
        [propertyConstants.PROPERTY_ROUNDING]
    ],
    [menuConstants.TABLE_GENERIC_IMAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_FILE_TYPE_ID],
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_URL],
        [propertyConstants.PROPERTY_WIDTH],
        [propertyConstants.PROPERTY_HEIGHT],
        [propertyConstants.PROPERTY_LENGTH]
    ],
    [menuConstants.TABLE_GENERIC_PACKAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT],
        [propertyConstants.PROPERTY_UNIT_ID],
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC],
        [propertyConstants.PROPERTY_MORE_INFO]
    ],
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_FILE_TYPE_ID],
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID],
        [propertyConstants.PROPERTY_URL],
        [propertyConstants.PROPERTY_LENGTH]
    ],
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_URL],
        [propertyConstants.PROPERTY_LENGTH]
    ],
    [menuConstants.TABLE_GENERIC_UNITS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC],
        [propertyConstants.PROPERTY_AMOUNT_COEFFICIENT],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: [[propertyConstants.PROPERTY_NAME], [propertyConstants.PROPERTY_UUID]],
    [menuConstants.TABLE_GLOBAL_ZONES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PASSWORD],
        [propertyConstants.PROPERTY_CURRENCY_ID],
        [propertyConstants.PROPERTY_VERSION],
        [propertyConstants.PROPERTY_VERSION_NAME],
        [propertyConstants.PROPERTY_CHANGELOG],
        [propertyConstants.PROPERTY_STATIC_SQL],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_ICON_ID],
        [propertyConstants.PROPERTY_MORE_INFO]
    ],
    [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: [
        [propertyConstants.PROPERTY_IS_DUPLICATED],
        [propertyConstants.PROPERTY_NAME]
    ],
    [menuConstants.TABLE_MASTER_BASES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    /* [menuConstants.TABLE_MASTER_COLOR_DATA]: [
        [propertyConstants.PROPERTY_WAVELENGTH],
        [propertyConstants.PROPERTY_REFLECTANCE_DATA]
    ],*/
    [menuConstants.TABLE_MASTER_COLORANTS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COLOR_RED],
        [propertyConstants.PROPERTY_COLOR_GREEN],
        [propertyConstants.PROPERTY_COLOR_BLUE],
        [propertyConstants.PROPERTY_COLOR_RGB_HEX],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_MIN_DOSAGE],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: [
        [propertyConstants.PROPERTY_STATUS_ID],
        [propertyConstants.PROPERTY_BATCH_NUMBER],
        [propertyConstants.PROPERTY_PRODUCTION_DATE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_LAST_STATUS_CHANGE]
    ],
    [menuConstants.TABLE_MASTER_COLORS]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_MASTER_FANDECKS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    /* [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: [
        [propertyConstants.PROPERTY_COLORANT_NAME],
        [propertyConstants.PROPERTY_AMOUNT]
    ],*/
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: [
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_VALUE],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: [
        [propertyConstants.PROPERTY_BASE_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COEFFICIENT],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL],
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL],
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID],
        [propertyConstants.PROPERTY_CUSTOM_FORMULA],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES]
    ],
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES]
    ],
    [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: [
        [propertyConstants.PROPERTY_KEY],
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE],
        [propertyConstants.PROPERTY_OVERWRITE],
        [propertyConstants.PROPERTY_DESCRIPTION]
    ],
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: [
        [propertyConstants.PROPERTY_KEY],
        [propertyConstants.PROPERTY_VALUE],
        [propertyConstants.PROPERTY_DESCRIPTION]
    ],
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: [
        [propertyConstants.PROPERTY_AUTOLOGIN],
        [propertyConstants.PROPERTY_USERNAME]
    ],
    [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: [[propertyConstants.PROPERTY_USERNAME]],
    // modal tables
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_VERSION]
    ],
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: [
        [propertyConstants.PROPERTY_SYSTEM_NAME],
        [propertyConstants.PROPERTY_ZONE_NAME],
        [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS],
        [propertyConstants.PROPERTY_VERSION],
        [propertyConstants.PROPERTY_VERSION_NAME],
        [propertyConstants.PROPERTY_ZONE_PASSWORD],
        [propertyConstants.PROPERTY_CHANGELOG],
        [propertyConstants.PROPERTY_UUID]
    ],
    /* [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: [
        [propertyConstants.PROPERTY_NAME]
    ], 
    [menuConstants.TABLE_MODAL_FILES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_FILE_TYPE_ID],
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]
    ],
    [menuConstants.TABLE_MODAL_FORMULA_NOTES]: [
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_VALUE]
    ],
    [menuConstants.TABLE_MODAL_ICONS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: [
        [propertyConstants.PROPERTY_NAME]
    ],
    [menuConstants.TABLE_MODAL_IMAGES]: [
        [propertyConstants.PROPERTY_NAME]
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: [
        [propertyConstants.PROPERTY_NAME]
    ],
    */
    [menuConstants.TABLE_MONITORING_DATABASES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_COMPANY_ID],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_LENGTH]
    ],
    [menuConstants.TABLE_SYSTEM_BASES]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_UNIT_ID],
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_FILL_VOLUME_ML],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_SYSTEM_COLORANTS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COLOR_RED],
        [propertyConstants.PROPERTY_COLOR_GREEN],
        [propertyConstants.PROPERTY_COLOR_BLUE],
        [propertyConstants.PROPERTY_COLOR_RGB_HEX],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_MIN_DOSAGE],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_VAT],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]
    ],
    [menuConstants.TABLE_SYSTEM_COLORS]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_SYSTEM_FANDECKS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: [
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_VALUE_MASTER],
        [propertyConstants.PROPERTY_VALUE],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: [[propertyConstants.PROPERTY_NAME], [propertyConstants.PROPERTY_VALUE]],
    [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_RANGE],
        [propertyConstants.PROPERTY_VALUE]
    ],
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_MARGIN_TYPE],
        [propertyConstants.PROPERTY_PROC_PRODUCT],
        [propertyConstants.PROPERTY_PROC_COLORANT],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]
    ],
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT],
        [propertyConstants.PROPERTY_FILL_VOLUME_ML],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST],
        [propertyConstants.PROPERTY_PRICE_PURCHASE],
        [propertyConstants.PROPERTY_PRICE_SELL],
        [propertyConstants.PROPERTY_PRICE_SURCHARGE],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: [
        [propertyConstants.PROPERTY_BASE_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COEFFICIENT],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL],
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL],
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID],
        [propertyConstants.PROPERTY_CUSTOM_FORMULA],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SURCHARGE],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: [
        [propertyConstants.PROPERTY_KEY],
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE],
        [propertyConstants.PROPERTY_OVERWRITE],
        [propertyConstants.PROPERTY_DESCRIPTION]
    ],
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: [
        [propertyConstants.PROPERTY_AUTOLOGIN],
        [propertyConstants.PROPERTY_USERNAME]
    ],
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: [[propertyConstants.PROPERTY_USERNAME]],
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: [
        [propertyConstants.PROPERTY_IS_DUPLICATED],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_PRODUCT_NAME]
    ],
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: [
        [propertyConstants.PROPERTY_ACTION_TYPE_ID],
        [propertyConstants.PROPERTY_ITEM_TYPE_ID],
        [propertyConstants.PROPERTY_ACTION_METHOD],
        [propertyConstants.PROPERTY_LEVEL],
        [propertyConstants.PROPERTY_USERNAME],
        [propertyConstants.PROPERTY_DATE]
    ],
    [menuConstants.TABLE_USER_GROUPS]: [[propertyConstants.PROPERTY_NAME], [propertyConstants.PROPERTY_PRIORITY]],
    [menuConstants.TABLE_USERS]: [[propertyConstants.PROPERTY_USERNAME], [propertyConstants.PROPERTY_COMPANY_ID]],
    [menuConstants.TABLE_ZONE_BASES]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME_SYSTEM],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_UNIT_ID],
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_FILL_VOLUME_ML],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_ZONE_COLORANTS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME_SYSTEM],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COLOR_RED],
        [propertyConstants.PROPERTY_COLOR_GREEN],
        [propertyConstants.PROPERTY_COLOR_BLUE],
        [propertyConstants.PROPERTY_COLOR_RGB_HEX],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_MIN_DOSAGE],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_ZONE_COLORS]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: [
        [propertyConstants.PROPERTY_FORMULAS_STATUS],
        [propertyConstants.PROPERTY_COLOR_NAME],
        [propertyConstants.PROPERTY_COLOR_CODE]
    ],
    [menuConstants.TABLE_ZONE_FANDECKS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME_SYSTEM],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: [
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_VALUE_MASTER],
        [propertyConstants.PROPERTY_VALUE_SYSTEM],
        [propertyConstants.PROPERTY_VALUE],
        [propertyConstants.PROPERTY_IMAGE_ID]
    ],
    [menuConstants.TABLE_ZONE_PRICE_GENERIC]: [[propertyConstants.PROPERTY_NAME], [propertyConstants.PROPERTY_VALUE]],
    [menuConstants.TABLE_ZONE_PRICE_GROUPS]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_RANGE],
        [propertyConstants.PROPERTY_VALUE]
    ],
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_MARGIN_TYPE],
        [propertyConstants.PROPERTY_PROC_PRODUCT],
        [propertyConstants.PROPERTY_PROC_COLORANT],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]
    ],
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: [
        [propertyConstants.PROPERTY_NAME_SYSTEM],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_PRIORITY]
    ],
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: [
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT],
        [propertyConstants.PROPERTY_FILL_VOLUME_ML],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_BARCODES],
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST],
        [propertyConstants.PROPERTY_PRICE_PURCHASE],
        [propertyConstants.PROPERTY_PRICE_SELL],
        [propertyConstants.PROPERTY_PRICE_SURCHARGE],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: [
        [propertyConstants.PROPERTY_BASE_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_COEFFICIENT],
        [propertyConstants.PROPERTY_DENSITY],
        [propertyConstants.PROPERTY_FILL_PROC],
        [propertyConstants.PROPERTY_BFS],
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL],
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL],
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL],
        [propertyConstants.PROPERTY_UFI_CODE],
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID],
        [propertyConstants.PROPERTY_CUSTOM_FORMULA],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: [
        [propertyConstants.PROPERTY_NAME_MASTER],
        [propertyConstants.PROPERTY_NAME_SYSTEM],
        [propertyConstants.PROPERTY_NAME],
        [propertyConstants.PROPERTY_TMC_NAME],
        [propertyConstants.PROPERTY_SEARCH_NAME],
        [propertyConstants.PROPERTY_PRINT_NAME],
        [propertyConstants.PROPERTY_CODE],
        [propertyConstants.PROPERTY_SEARCH_CODE],
        [propertyConstants.PROPERTY_MORE_INFO],
        [propertyConstants.PROPERTY_UUID],
        [propertyConstants.PROPERTY_PRIORITY],
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST],
        [propertyConstants.PROPERTY_IMAGE_ID],
        [propertyConstants.PROPERTY_FILES],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER],
        [propertyConstants.PROPERTY_PRICE_VAT]
    ],
    [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: [
        [propertyConstants.PROPERTY_KEY],
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE],
        [propertyConstants.PROPERTY_OVERWRITE],
        [propertyConstants.PROPERTY_DESCRIPTION]
    ],
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: [
        [propertyConstants.PROPERTY_AUTOLOGIN],
        [propertyConstants.PROPERTY_USERNAME]
    ],
    [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: [[propertyConstants.PROPERTY_USERNAME]]
};

export const DEFAULT_TABLE_COLUMNS_VISIBILITY = {
    [menuConstants.TABLE_COMPANIES]: {
        [propertyConstants.PROPERTY_COMPANY_KEY]: true,
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_DATA_DATABASES]: {
        [propertyConstants.PROPERTY_COMPANY_ID]: true,
        [propertyConstants.PROPERTY_DATE_CREATED]: false,
        [propertyConstants.PROPERTY_DATE_UPDATED]: false,
        [propertyConstants.PROPERTY_LENGTH]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PASSWORD]: false,
        [propertyConstants.PROPERTY_UUID]: true
    },
    [menuConstants.TABLE_DATA_EXPORT]: {
        [propertyConstants.PROPERTY_ACTION]: true,
        [propertyConstants.PROPERTY_CHANGELOG]: false,
        [propertyConstants.PROPERTY_DATABASE_NAME]: true,
        [propertyConstants.PROPERTY_DATE_CREATED]: false,
        [propertyConstants.PROPERTY_DATE_FINISHED]: true,
        [propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID]: true,
        [propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID]: false,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: true,
        [propertyConstants.PROPERTY_SYSTEM_UUID]: false,
        [propertyConstants.PROPERTY_VERSION]: true,
        [propertyConstants.PROPERTY_VERSION_NAME]: false,
        [propertyConstants.PROPERTY_ZONE_NAME]: true,
        [propertyConstants.PROPERTY_ZONE_UUID]: false,
        [propertyConstants.PROPERTY_ZONE_PASSWORD]: false
    },
    [menuConstants.TABLE_DATA_IMPORT]: {
        [propertyConstants.PROPERTY_ACTION]: true,
        [propertyConstants.PROPERTY_DATABASE_NAME]: true,
        [propertyConstants.PROPERTY_DATE_CREATED]: false,
        [propertyConstants.PROPERTY_DATE_FINISHED]: true,
        [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]: true,
        [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]: true,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: true,
        [propertyConstants.PROPERTY_SYSTEM_UUID]: false,
        [propertyConstants.PROPERTY_UUID]: false,
        [propertyConstants.PROPERTY_ZONE_NAME]: true,
        [propertyConstants.PROPERTY_ZONE_UUID]: false
    },
    [menuConstants.TABLE_GENERIC_BARCODES]: {
        [propertyConstants.PROPERTY_BARCODE]: true,
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: true,
        [propertyConstants.PROPERTY_SYSTEM_ID]: true,
        [propertyConstants.PROPERTY_ZONE_ID]: true
    },
    [menuConstants.TABLE_GENERIC_CURRENCIES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_ROUNDING]: true,
        [propertyConstants.PROPERTY_SYMBOL]: true
    },
    [menuConstants.TABLE_GENERIC_IMAGES]: {
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: true,
        [propertyConstants.PROPERTY_HEIGHT]: false,
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_LENGTH]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_URL]: false,
        [propertyConstants.PROPERTY_WIDTH]: false
    },
    [menuConstants.TABLE_GENERIC_PACKAGES]: {
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: true,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UNIT_ID]: true
    },
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: {
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: true,
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: true,
        [propertyConstants.PROPERTY_LENGTH]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_URL]: false
    },
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: {
        [propertyConstants.PROPERTY_LENGTH]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_URL]: false
    },
    [menuConstants.TABLE_GENERIC_UNITS]: {
        [propertyConstants.PROPERTY_AMOUNT_COEFFICIENT]: true,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: true,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: false
    },
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: true
    },
    [menuConstants.TABLE_GLOBAL_ZONES]: {
        [propertyConstants.PROPERTY_CHANGELOG]: false,
        [propertyConstants.PROPERTY_CURRENCY_ID]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_PASSWORD]: false,
        [propertyConstants.PROPERTY_STATIC_SQL]: false,
        [propertyConstants.PROPERTY_UUID]: true,
        [propertyConstants.PROPERTY_VERSION]: false,
        [propertyConstants.PROPERTY_VERSION_NAME]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_ICON_ID]: false
    },
    [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: {
        [propertyConstants.PROPERTY_IS_DUPLICATED]: true,
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_BASES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_MASTER_COLOR_DATA]: {
        [propertyConstants.PROPERTY_WAVELENGTH]: true,
        [propertyConstants.PROPERTY_REFLECTANCE_DATA]: true
    },
    [menuConstants.TABLE_MASTER_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_BLUE]: true,
        [propertyConstants.PROPERTY_COLOR_GREEN]: true,
        [propertyConstants.PROPERTY_COLOR_RED]: true,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: true,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: {
        [propertyConstants.PROPERTY_BATCH_NUMBER]: true,
        [propertyConstants.PROPERTY_LAST_STATUS_CHANGE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: true,
        [propertyConstants.PROPERTY_PRODUCTION_DATE]: true,
        [propertyConstants.PROPERTY_STATUS_ID]: true
    },
    [menuConstants.TABLE_MASTER_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true
    },
    /* [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },*/
    [menuConstants.TABLE_MASTER_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: true,
        [propertyConstants.PROPERTY_AMOUNT]: true
    },
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true,
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COEFFICIENT]: true,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: false,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: false,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: false,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: true,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: true,
        [propertyConstants.PROPERTY_OVERWRITE]: true,
        [propertyConstants.PROPERTY_DESCRIPTION]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: {
        [propertyConstants.PROPERTY_DESCRIPTION]: true,
        [propertyConstants.PROPERTY_KEY]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: true,
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: true,
        [propertyConstants.PROPERTY_VERSION]: false
    },
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: {
        [propertyConstants.PROPERTY_CHANGELOG]: false,
        [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]: true,
        [propertyConstants.PROPERTY_IS_CHECKED]: true,
        [propertyConstants.PROPERTY_PASSWORD]: false,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: true,
        [propertyConstants.PROPERTY_VERSION]: true,
        [propertyConstants.PROPERTY_VERSION_NAME]: false,
        [propertyConstants.PROPERTY_ZONE_NAME]: true,
        [propertyConstants.PROPERTY_ZONE_PASSWORD]: false
    },
    [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MODAL_FILES]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: true,
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: true
    },
    [menuConstants.TABLE_MODAL_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_MODAL_ICONS]: {
        [propertyConstants.PROPERTY_IMAGE_ID]: true,
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MODAL_IMAGES]: {
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: true,
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MONITORING_DATABASES]: {
        [propertyConstants.PROPERTY_COMPANY_ID]: true,
        [propertyConstants.PROPERTY_LENGTH]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_BASES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: true,
        [propertyConstants.PROPERTY_UNIT_ID]: true
    },
    [menuConstants.TABLE_SYSTEM_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_BLUE]: true,
        [propertyConstants.PROPERTY_COLOR_GREEN]: true,
        [propertyConstants.PROPERTY_COLOR_RED]: true,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: true,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true
    },
    /* [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },*/
    [menuConstants.TABLE_SYSTEM_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_VALUE]: true,
        [propertyConstants.PROPERTY_VALUE_MASTER]: true
    },
    [menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: true,
        [propertyConstants.PROPERTY_AMOUNT]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_RANGE]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_MARGIN_TYPE]: true,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_PROC_PRODUCT]: true,
        [propertyConstants.PROPERTY_PROC_COLORANT]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: true
    },
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: false
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]: false,
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_CODE]: false,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: true,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: true,
        [propertyConstants.PROPERTY_PRICE_COST]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE]: false,
        [propertyConstants.PROPERTY_PRICE_SELL]: false,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true,
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COEFFICIENT]: true,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: false,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: false,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: false,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: true,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: true,
        [propertyConstants.PROPERTY_OVERWRITE]: true,
        [propertyConstants.PROPERTY_DESCRIPTION]: true
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: true,
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: {
        [propertyConstants.PROPERTY_IS_DUPLICATED]: true,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRODUCT_NAME]: true
    },
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: {
        [propertyConstants.PROPERTY_ACTION_METHOD]: false,
        [propertyConstants.PROPERTY_ACTION_TYPE_ID]: true,
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: true,
        [propertyConstants.PROPERTY_LEVEL]: true,
        [propertyConstants.PROPERTY_USERNAME]: true,
        [propertyConstants.PROPERTY_DATE]: true
    },
    [menuConstants.TABLE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: true
    },
    [menuConstants.TABLE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: true,
        [propertyConstants.PROPERTY_COMPANY_ID]: false
    },
    [menuConstants.TABLE_ZONE_BASES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: false,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: true,
        [propertyConstants.PROPERTY_UNIT_ID]: true
    },
    [menuConstants.TABLE_ZONE_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_BLUE]: true,
        [propertyConstants.PROPERTY_COLOR_GREEN]: true,
        [propertyConstants.PROPERTY_COLOR_RED]: true,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: true,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: true,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true
    },
    /* [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: true,
        [propertyConstants.PROPERTY_COLOR_CODE]: true,
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },*/
    [menuConstants.TABLE_ZONE_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: true,
        [propertyConstants.PROPERTY_AMOUNT]: true
    },
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_VALUE]: true,
        [propertyConstants.PROPERTY_VALUE_MASTER]: true,
        [propertyConstants.PROPERTY_VALUE_SYSTEM]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_GENERIC]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_RANGE]: true,
        [propertyConstants.PROPERTY_VALUE]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_MARGIN_TYPE]: true,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_PROC_COLORANT]: true,
        [propertyConstants.PROPERTY_PROC_PRODUCT]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: true
    },
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true,
        [propertyConstants.PROPERTY_PRIORITY]: false
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]: false,
        [propertyConstants.PROPERTY_BARCODES]: false,
        [propertyConstants.PROPERTY_CODE]: false,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: true,
        [propertyConstants.PROPERTY_PRICE_COST]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE]: false,
        [propertyConstants.PROPERTY_PRICE_SELL]: false,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true,
        [propertyConstants.PROPERTY_BFS]: false,
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_COEFFICIENT]: true,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: false,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: false,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: false,
        [propertyConstants.PROPERTY_DENSITY]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_FILL_PROC]: true,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_UFI_CODE]: false,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: true,
        [propertyConstants.PROPERTY_FILES]: false,
        [propertyConstants.PROPERTY_IMAGE_ID]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: false,
        [propertyConstants.PROPERTY_NAME]: true,
        [propertyConstants.PROPERTY_NAME_MASTER]: true,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: false,
        [propertyConstants.PROPERTY_PRICE_VAT]: false,
        [propertyConstants.PROPERTY_PRINT_NAME]: false,
        [propertyConstants.PROPERTY_PRIORITY]: false,
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: false,
        [propertyConstants.PROPERTY_SEARCH_CODE]: false,
        [propertyConstants.PROPERTY_SEARCH_NAME]: false,
        [propertyConstants.PROPERTY_TMC_NAME]: true,
        [propertyConstants.PROPERTY_UUID]: false
    },
    [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: true,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: true,
        [propertyConstants.PROPERTY_OVERWRITE]: true,
        [propertyConstants.PROPERTY_DESCRIPTION]: true
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: true,
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    }
};

export const DEFAULT_SCENE_TABLE_SIZE: Record<string, any> = {
    [menuConstants.PAGE_GENERIC_IMAGES]: {
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_GENERIC_IMAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_GENERIC_PREVIEW]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_GENERIC_PRODUCT_SHEETS]: {
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_GENERIC_PREVIEW]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_GENERIC_STATIC_SQL]: {
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_GENERIC_STATIC_SQL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_GENERIC_PREVIEW]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS]: {
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_GLOBAL_SYSTEMS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_GLOBAL_ZONES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_COLORANTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_MASTER_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_MASTER_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_COLORS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_MASTER_COLORS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_COLORS_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_FORMULAS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_PRODUCTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.75
            },
            [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.25
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_REDLIKE_USERS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_MASTER_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_MASTER_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES]: {
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_SYSTEM_COLORANTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_SYSTEM_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_SYSTEM_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_SYSTEM_COLORS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_SYSTEM_COLORS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_SYSTEM_COLORS_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_SYSTEM_FORMULAS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_SYSTEM_PRODUCTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            }
        },
        [LAYOUT_BIG_LEFT]: {
            [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_BIG_TOP]: {
            [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        }
    },
    [menuConstants.PAGE_SYSTEM_REDLIKE_USERS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_ZONE_COLORANTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_ZONE_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_ZONE_COLORANTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_ZONE_COLORS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_ZONE_COLORS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_ZONE_COLORS_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_ZONE_FORMULAS]: {
        [LAYOUT_PANEL]: {
            [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.3,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO_DETAIL]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.7,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    },
    [menuConstants.PAGE_ZONE_PRODUCTS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 1 / 3
            }
        },
        [LAYOUT_BIG_LEFT]: {
            [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_BIG_TOP]: {
            [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        }
    },
    [menuConstants.PAGE_ZONE_REDLIKE_USERS]: {
        [LAYOUT_COLUMN]: {
            [menuConstants.TABLE_ZONE_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            },
            [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 1,
                [propertyConstants.PROPERTY_HEIGHT]: 0.5
            }
        },
        [LAYOUT_COLUMNS]: {
            [menuConstants.TABLE_ZONE_REDLIKE_USERS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            },
            [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: {
                [propertyConstants.PROPERTY_WIDTH]: 0.5,
                [propertyConstants.PROPERTY_HEIGHT]: 1
            }
        }
    }
};

export const DEFAULT_TABLE_COLUMNS_WIDTH = {
    [menuConstants.TABLE_COMPANIES]: {
        [propertyConstants.PROPERTY_COMPANY_KEY]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_DATA_DATABASES]: {
        [propertyConstants.PROPERTY_COMPANY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_DATE_CREATED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_DATE_UPDATED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_LENGTH]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PASSWORD]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_DATA_EXPORT]: {
        [propertyConstants.PROPERTY_ACTION]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_CHANGELOG]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_DATABASE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_DATE_CREATED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_DATE_FINISHED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IS_CHECKED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SYSTEM_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VERSION]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_VERSION_NAME]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_ZONE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ZONE_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_ZONE_PASSWORD]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_DATA_IMPORT]: {
        [propertyConstants.PROPERTY_ACTION]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_DATABASE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_DATE_CREATED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_DATE_FINISHED]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IS_CHECKED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SYSTEM_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_ZONE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ZONE_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_GENERIC_BARCODES]: {
        [propertyConstants.PROPERTY_BARCODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SYSTEM_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ZONE_ID]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_GENERIC_CURRENCIES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ROUNDING]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SYMBOL]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_GENERIC_IMAGES]: {
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_HEIGHT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_LENGTH]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_URL]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_WIDTH]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_GENERIC_PACKAGES]: {
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UNIT_ID]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: {
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_LENGTH]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_URL]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: {
        [propertyConstants.PROPERTY_LENGTH]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_URL]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_GENERIC_UNITS]: {
        [propertyConstants.PROPERTY_AMOUNT_COEFFICIENT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: {
        [propertyConstants.PROPERTY_IS_DUPLICATED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_GLOBAL_ZONES]: {
        [propertyConstants.PROPERTY_CHANGELOG]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CURRENCY_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_ICON_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_PASSWORD]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_STATIC_SQL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VERSION]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_VERSION_NAME]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_MASTER_BASES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_COLOR_DATA]: {
        [propertyConstants.PROPERTY_WAVELENGTH]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_REFLECTANCE_DATA]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_MASTER_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_BLUE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_GREEN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: {
        [propertyConstants.PROPERTY_BATCH_NUMBER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_LAST_STATUS_CHANGE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_PRODUCTION_DATE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_STATUS_ID]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MASTER_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3
    },
    /* [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
    },*/
    [menuConstants.TABLE_MASTER_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_AMOUNT]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COEFFICIENT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_OVERWRITE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DESCRIPTION]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: {
        [propertyConstants.PROPERTY_DESCRIPTION]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_KEY]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VERSION]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_MODAL_EXPORT_TASKS]: {
        [propertyConstants.PROPERTY_CHANGELOG]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IS_CHECKED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SYSTEM_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VERSION]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_VERSION_NAME]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_ZONE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ZONE_PASSWORD]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_MODAL_FILE_CATEGORIES]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_FILES]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_MODAL_ICONS]: {
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_IMAGE_CATEGORIES]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_IMAGES]: {
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MODAL_SPECTRO_TYPES]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_MONITORING_DATABASES]: {
        [propertyConstants.PROPERTY_COMPANY_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_LENGTH]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_SYSTEM_BASES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UNIT_ID]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_SYSTEM_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_BLUE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_GREEN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3
    },
    /* [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
    },*/
    [menuConstants.TABLE_SYSTEM_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_AMOUNT]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VALUE_MASTER]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_PRICE_GENERIC]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_SYSTEM_PRICE_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_RANGE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_MARGIN_TYPE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PROC_PRODUCT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PROC_COLORANT]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PRICE_COST]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COEFFICIENT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_OVERWRITE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DESCRIPTION]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: {
        [propertyConstants.PROPERTY_IS_DUPLICATED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRODUCT_NAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: {
        [propertyConstants.PROPERTY_ACTION_METHOD]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_ACTION_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_LEVEL]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_DATE]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COMPANY_ID]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_ZONE_BASES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UNIT_ID]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_ZONE_COLORANTS]: {
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_BLUE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_GREEN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RED]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MIN_DOSAGE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_COLORS]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3
    },
    /* [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLOR_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COLOR_NAME]: DEFAULT_COLUMN_WIDTH_3,
    },*/
    [menuConstants.TABLE_ZONE_FANDECKS]: {
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_FORMULA_COLORANTS]: {
        [propertyConstants.PROPERTY_COLORANT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_AMOUNT]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VALUE_MASTER]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_VALUE_SYSTEM]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_PRICE_GENERIC]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_ZONE_PRICE_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_RANGE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_VALUE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_MARGIN_TYPE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PROC_COLORANT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PROC_PRODUCT]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: DEFAULT_COLUMN_WIDTH_2
    },
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_ALTERNATIVE_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_BARCODES]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PRICE_COST]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_BFS]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_COEFFICIENT]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MAX_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_COLORANT_MIN_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_CUSTOM_FORMULA]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DENSITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FILL_PROC]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_UFI_CODE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_FILES]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_IMAGE_ID]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_MORE_INFO]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_MASTER]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_NAME_SYSTEM]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRINT_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRICE_VAT]: DEFAULT_COLUMN_WIDTH_2,
        [propertyConstants.PROPERTY_PRIORITY]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_SEARCH_CODE]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_SEARCH_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_TMC_NAME]: DEFAULT_COLUMN_WIDTH_3,
        [propertyConstants.PROPERTY_UUID]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_REDLIKE_OPTIONS]: {
        [propertyConstants.PROPERTY_KEY]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_UNIVERSAL_VALUE]: DEFAULT_COLUMN_WIDTH_4,
        [propertyConstants.PROPERTY_OVERWRITE]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_DESCRIPTION]: DEFAULT_COLUMN_WIDTH_4
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_AUTOLOGIN]: DEFAULT_COLUMN_WIDTH_1,
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: DEFAULT_COLUMN_WIDTH_3
    }
};

export const DEFAULT_TABLE_SORTING = {
    [menuConstants.TABLE_AVAILABLE_REDLIKE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_COMPANIES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_DATA_DATABASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_DATA_EXPORT]: {
        [propertyConstants.PROPERTY_DATE_CREATED]: true
    },
    [menuConstants.TABLE_DATA_IMPORT]: {
        [propertyConstants.PROPERTY_DATE_CREATED]: true
    },
    [menuConstants.TABLE_GENERIC_BARCODES]: {
        [propertyConstants.PROPERTY_BARCODE]: true
    },
    [menuConstants.TABLE_GENERIC_CURRENCIES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GENERIC_IMAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GENERIC_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GENERIC_PRODUCT_SHEETS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GENERIC_STATIC_SQL]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GENERIC_UNITS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GLOBAL_SYSTEMS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GLOBAL_ZONES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_GLOBAL_ZONES_DUPLICATE]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_BASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_COLORANTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_COLORANT_BATCHES]: {
        [propertyConstants.PROPERTY_BATCH_NUMBER]: true
    },
    [menuConstants.TABLE_MASTER_COLORS]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_MASTER_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_MASTER_FANDECKS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_PRIVILEGES]: {
        [propertyConstants.PROPERTY_KEY]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USER_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_BASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_COLORANTS]: {
        [propertyConstants.PROPERTY_NAME_MASTER]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_FANDECKS]: {
        [propertyConstants.PROPERTY_NAME_MASTER]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME_MASTER]: true
    },
    [menuConstants.TABLE_MONITORING_DATABASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_BASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_COLORANTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_COLORS]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_FANDECKS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_BASES]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_COLORANTS]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_FANDECKS]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME_SYSTEM]: true
    },
    [menuConstants.TABLE_USER_ACTIONS_HISTORY]: {
        [propertyConstants.PROPERTY_DATE]: false
    },
    [menuConstants.TABLE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_ZONE_BASES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_COLORANT_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_COLORANTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_COLORS]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO]: {
        [propertyConstants.PROPERTY_COLOR_NAME]: true
    },
    [menuConstants.TABLE_ZONE_FANDECKS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_FORMULA_NOTES]: {
        [propertyConstants.PROPERTY_CODE]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_MARGIN]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_PRICE_SURCHARGE]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_PRODUCT_GROUPS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASES]: {
        [propertyConstants.PROPERTY_BASE_NAME]: true
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS]: {
        [propertyConstants.PROPERTY_NAME]: true
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    },
    [menuConstants.TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS]: {
        [propertyConstants.PROPERTY_USERNAME]: true
    }
};

export const DEFAULT_TABLE_ITEMS_VISIBILITY = {
    [OPTION_EXPORT_TASK_SHOW_DELETED]: true,
    [OPTION_EXPORT_TASK_SHOW_HIDDEN]: true,
    [OPTION_IMPORT_TASK_SHOW_DELETED]: true,
    [OPTION_IMPORT_TASK_SHOW_HIDDEN]: true
};

export const DEFAULT_MENU_SECTION_VISIBILITY = {
    [menuConstants.HEADER_MASTER]: true,
    [menuConstants.HEADER_SYSTEM]: true,
    [menuConstants.HEADER_ZONE]: true,
    [menuConstants.HEADER_GENERIC]: true
};
