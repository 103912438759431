import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Currency } from "../../../types/currency";
import { GenericCurrencyScene } from "../../../components/scenes/generic/GenericCurrencyScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    currencyTableConstant: string;
    currencyList: Array<Currency>;
    currencyActiveList: Array<Currency>;
    currencyLoading: boolean;
    currencyButtonLoading: boolean;
    currencyColumnOrder: Array<propertyConstants.Property>;
    currencyColumnVisibility: Record<propertyConstants.Property, boolean>;
    currencyColumnWidth: Record<propertyConstants.Property, number>;
    currencyOffset: number;
    currencyPage: number;
    currencyRowCount: number;
    currencyRowCountCustom: boolean;
    currencySceneTableSize: Record<string, any> | null;
    currencySearch: string | null;
    currencySearchParams: Record<propertyConstants.Property, string>;
    currencyShowFilterRow: boolean;
    currencyShowGlobalSearch: boolean;
    currencySortingAsc: boolean;
    currencySortingCriterion: propertyConstants.Property | null;
    currencyTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_CURRENCIES
    ),
    currencyTableConstant: menuConstants.TABLE_GENERIC_CURRENCIES,
    currencyList: state.currency.list,
    currencyActiveList: state.currency.activeList,
    currencyLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_CURRENCIES || request.method === methods.METHOD_DELETE_CURRENCY
    ),
    currencyButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_CURRENCY
    ),
    currencyColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencyColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencyColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencyOffset: state.currency.offset,
    currencyPage: state.currency.page,
    currencyRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencyRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencySceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_CURRENCIES
    ),
    currencySearch: state.currency.search,
    currencySearchParams: state.currency.searchParams,
    currencyShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencyShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_CURRENCIES
    ),
    currencySortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_CURRENCIES
        )
    ),
    currencySortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_CURRENCIES
        )
    ),
    currencyTotalCount: state.currency.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_CURRENCIES
    )
});

export const GenericCurrencySceneContainer = connect(mapStateToProps)(GenericCurrencyScene);
