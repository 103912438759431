import * as generalHelper from "./generalHelper";
import * as inputConstants from "../constants/inputConstants";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as privilegeHelper from "./privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";

import { Language } from "../types/language";

import { getAllLanguages } from "../lang/translations";

// TODO check while file

/**
 * Gets value from option dictionary by key
 * @param list
 * @param key
 */
export const getValueFromOptionDictionaryByKey = (list: Array<any>, key: string): any => {
    let item;

    for (item of list) {
        if (item[propertyConstants.PROPERTY_KEY] === key && item[propertyConstants.PROPERTY_VALUE] !== null) {
            return item[propertyConstants.PROPERTY_VALUE];
        }
    }

    return null;
};

export const getOptionType = (key: string): string => {
    switch (key) {
        case optionsConstants.OPTION_DATABASE_AUTOSAVE:
        case optionsConstants.OPTION_DELETE_CONFIRMATION:
        case optionsConstants.OPTION_USE_LARGO_NAMES:
        case optionsConstants.OPTION_USE_SOURCES:
        case optionsConstants.OPTION_USE_TMC_NAMES: {
            return inputConstants.PARAMETER_TYPE_CHECK_BOX;
        }
        case optionsConstants.OPTION_LANGUAGE: {
            return inputConstants.PARAMETER_TYPE_SELECT_BOX;
        }
        default: {
            return inputConstants.PARAMETER_TYPE_TEXT_INPUT;
        }
    }
};

export const getOptionListForOptions = (key: string): Array<{ key: string; value: any }> => {
    switch (key) {
        case optionsConstants.OPTION_LANGUAGE:
            return getAllLanguages().map((item: Language) => {
                return { key: item.code, value: item.nativeName };
            });
        default:
            return [];
    }
};

/**
 * Sorts visible columns in table according to OPTION_TABLE_COLUMNS_ORDER
 * @param columns: all visible properties
 * @param optionOrder: order of properties saved in options
 */
export const handleTableHeadingOrder = (
    columns: Array<propertyConstants.Property>,
    optionOrder: Array<propertyConstants.Property>
): Array<propertyConstants.Property> => {
    return columns.sort((a, b) => {
        // Hardcoded property to be always as the first column, order cannot be changed
        if (
            propertyConstants.FIXED_COLUMN_PROPERTIES.includes(a) &&
            propertyConstants.FIXED_COLUMN_PROPERTIES.includes(b)
        ) {
            return 0;
        }
        if (propertyConstants.FIXED_COLUMN_PROPERTIES.includes(a)) {
            return -1;
        }
        if (propertyConstants.FIXED_COLUMN_PROPERTIES.includes(b)) {
            return 1;
        }

        if (optionOrder) {
            const aIndex = optionOrder.indexOf(a);
            const bIndex = optionOrder.indexOf(b);

            if (aIndex >= 0 && bIndex >= 0) {
                return aIndex - bIndex;
            }

            if (aIndex >= 0) {
                return -1;
            }

            if (bIndex >= 0) {
                return 1;
            }
        }

        return 0;
    });
};

/**
 * Filters only visible columns in table - need to be visible in XXXTable component and set to true in OPTION_TABLE_COLUMNS_VISIBILITY
 * @param visible: all possibly visible columns in table
 * @param optionVisibility: object with boolean values for each column saved in options
 */
export const handleTableHeadingVisibility = (
    visible: Array<propertyConstants.Property>,
    optionVisibility: Record<propertyConstants.Property, boolean>
): Array<propertyConstants.Property> => {
    const columns: Array<propertyConstants.Property> = [];

    for (const property of visible) {
        let optionValue = optionVisibility?.[property] ?? false;

        // Hardcoded property, always visible as the first column in the table
        if (property === propertyConstants.PROPERTY_IS_CHECKED) {
            optionValue = true;
        }

        if (optionValue) {
            columns.push(property);
        }
    }

    return columns;
};

export const getDefaultOptions = (optionKey: string): any => {
    switch (optionKey) {
        case optionsConstants.OPTION_DATABASE_AUTOSAVE: {
            return true;
        }
        case optionsConstants.OPTION_DELETE_CONFIRMATION: {
            return false;
        }
        case optionsConstants.OPTION_FORMULA_COLORANT_UNIT: {
            return optionsConstants.DEFAULT_FORMULA_COLORANT_UNIT;
        }
        case optionsConstants.OPTION_MENU_SECTION_VISIBILITY: {
            return optionsConstants.DEFAULT_MENU_SECTION_VISIBILITY;
        }
        case optionsConstants.OPTION_SCENE_LAYOUT: {
            return optionsConstants.DEFAULT_SCENE_LAYOUT;
        }
        case optionsConstants.OPTION_SCENE_TABLE_SIZE: {
            return optionsConstants.DEFAULT_SCENE_TABLE_SIZE;
        }
        case optionsConstants.OPTION_SHOW_FILTER_ROW: {
            return optionsConstants.DEFAULT_SHOW_FILTER_ROW;
        }
        case optionsConstants.OPTION_SHOW_GLOBAL_SEARCH: {
            return optionsConstants.DEFAULT_SHOW_GLOBAL_SEARCH;
        }
        case optionsConstants.OPTION_TABLE_COLUMNS_ORDER: {
            return optionsConstants.DEFAULT_TABLE_COLUMNS_ORDER;
        }
        case optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY: {
            return optionsConstants.DEFAULT_TABLE_COLUMNS_VISIBILITY;
        }
        case optionsConstants.OPTION_TABLE_COLUMNS_WIDTH: {
            return optionsConstants.DEFAULT_TABLE_COLUMNS_WIDTH;
        }
        case optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY: {
            return optionsConstants.DEFAULT_TABLE_ITEMS_VISIBILITY;
        }
        case optionsConstants.OPTION_TABLE_ROW_COUNT: {
            return optionsConstants.DEFAULT_TABLE_ROW_COUNT;
        }
        case optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM:
            return optionsConstants.DEFAULT_TABLE_ROW_COUNT_CUSTOM;
        case optionsConstants.OPTION_TABLE_SORTING: {
            return optionsConstants.DEFAULT_TABLE_SORTING;
        }
        case optionsConstants.OPTION_USE_LARGO_NAMES: {
            return false;
        }
        case optionsConstants.OPTION_USE_SOURCES: {
            return true;
        }
        case optionsConstants.OPTION_USE_TMC_NAMES: {
            return true;
        }
        default: {
            return null;
        }
    }
};

export const getUpdatedOptions = (optionKey: string, optionValue: any): any => {
    const defaultOption = getDefaultOptions(optionKey);

    switch (optionKey) {
        // boolean values
        case optionsConstants.OPTION_DATABASE_AUTOSAVE:
        case optionsConstants.OPTION_DELETE_CONFIRMATION:
        case optionsConstants.OPTION_USE_LARGO_NAMES:
        case optionsConstants.OPTION_USE_SOURCES:
        case optionsConstants.OPTION_USE_TMC_NAMES: {
            if (optionValue === true || optionValue === false) {
                return optionValue;
            }

            return defaultOption;
        }
        // simple object values
        case optionsConstants.OPTION_MENU_SECTION_VISIBILITY:
        case optionsConstants.OPTION_SCENE_LAYOUT:
        case optionsConstants.OPTION_SHOW_FILTER_ROW:
        case optionsConstants.OPTION_SHOW_GLOBAL_SEARCH:
        case optionsConstants.OPTION_TABLE_COLUMNS_ORDER:
        case optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY:
        case optionsConstants.OPTION_TABLE_ROW_COUNT:
        case optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM:
        case optionsConstants.OPTION_TABLE_SORTING: {
            let newOptions = {};
            let key: any;

            if (!defaultOption) {
                return optionValue;
            }

            for (key of Object.keys(defaultOption)) {
                newOptions = {
                    ...newOptions,
                    [key]: optionValue && optionValue[key] !== undefined ? optionValue[key] : defaultOption[key]
                };
            }

            return newOptions;
        }
        // object of object values
        case optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY:
        case optionsConstants.OPTION_TABLE_COLUMNS_WIDTH: {
            let newOptions = {};
            let newValues = {};
            let key: any;

            if (!defaultOption) {
                return optionValue;
            }

            for (key of Object.keys(defaultOption)) {
                newValues = {};
                for (const keyValue of Object.keys(defaultOption[key])) {
                    newValues = {
                        ...newValues,
                        [keyValue]:
                            optionValue && optionValue[key] && optionValue[key][keyValue] !== undefined
                                ? optionValue[key][keyValue]
                                : defaultOption[key][keyValue]
                    };
                }
                newOptions = {
                    ...newOptions,
                    [key]: newValues
                };
            }

            return generalHelper.sortObjectKeys(newOptions);
        }
        case optionsConstants.OPTION_SCENE_TABLE_SIZE: {
            let newSceneOption = {};
            let newLayoutOption = {};
            let newTableOption = {};
            let newTableValues = {};

            if (!defaultOption) {
                return optionValue;
            }

            for (const scene of Object.keys(defaultOption)) {
                newLayoutOption = {};
                for (const layout of Object.keys(defaultOption[scene])) {
                    newTableOption = {};
                    for (const table of Object.keys(defaultOption[scene][layout])) {
                        newTableValues = {};
                        for (const key of Object.keys(defaultOption[scene][layout][table])) {
                            newTableValues = {
                                ...newTableValues,
                                [key]:
                                    optionValue?.[scene]?.[layout]?.[table]?.[key] !== undefined
                                        ? optionValue[scene][layout][table][key]
                                        : defaultOption[scene][layout][table][key]
                            };
                        }
                        newTableOption = {
                            ...newTableOption,
                            [table]: newTableValues
                        };
                    }
                    newLayoutOption = {
                        ...newLayoutOption,
                        [layout]: newTableOption
                    };
                }
                newSceneOption = {
                    ...newSceneOption,
                    [scene]: newLayoutOption
                };
            }

            return newSceneOption;
        }
        default: {
            return optionValue;
        }
    }
};

export const getLayoutClassName = (layout: string | null, privileges: any): string => {
    let className = "content-layout";

    switch (layout) {
        case optionsConstants.LAYOUT_BIG_LEFT: {
            className += " layout-3-tables-big-left";
            break;
        }
        case optionsConstants.LAYOUT_COLUMN: {
            break;
        }
        case optionsConstants.LAYOUT_COLUMNS: {
            className += " layout-columns";
            break;
        }
        case optionsConstants.LAYOUT_BIG_TOP: {
            className += " layout-3-tables-big-top";
            break;
        }
        case optionsConstants.LAYOUT_PANEL: {
            className += " layout-panel";
            break;
        }
        default:
            break;
    }

    if (privilegeHelper.isDataPrivilegesReadOnly(privileges)) {
        className += " readonly";
    }

    return className;
};

export const isPrivilegeAvailable = (privilege: number | undefined): boolean => {
    return privilege === privilegeConstants.PRIVILEGE_STATUS_AVAILABLE;
};

export const addVisibleColumn = (
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalFunctionType,
    columns: Array<propertyConstants.Property>,
    privileges: any
): Array<propertyConstants.Property> => {
    const updatedColumns = [...columns];

    switch (objectType) {
        case objectTypes.DATABASE: {
            if (isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_VIEW])) {
                updatedColumns.push(propertyConstants.PROPERTY_UUID);
            }
            break;
        }
        case objectTypes.SYSTEM: {
            if (isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_VIEW])) {
                updatedColumns.push(propertyConstants.PROPERTY_UUID);
            }
            break;
        }
    }
    return updatedColumns;
};

export const addEditableColumn = (
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalFunctionType,
    columns: Array<propertyConstants.Property>,
    privileges: any
): Array<propertyConstants.Property> => {
    const updatedColumns = [...columns];

    switch (objectType) {
        case objectTypes.DATABASE: {
            if (
                (modalType === modalTypes.ADD_MODAL &&
                    isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_INSERT])) ||
                (modalType === modalTypes.DUPLICATE_MODAL &&
                    isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_UPDATE])) ||
                (modalType === modalTypes.EDIT_MODAL &&
                    isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_UPDATE]))
            ) {
                updatedColumns.push(propertyConstants.PROPERTY_UUID);
            }
            break;
        }
        case objectTypes.SYSTEM: {
            if (
                (modalType === modalTypes.ADD_MODAL &&
                    isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_INSERT])) ||
                (modalType === modalTypes.EDIT_MODAL &&
                    isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_UPDATE]))
            ) {
                updatedColumns.push(propertyConstants.PROPERTY_UUID);
            }
            break;
        }
        case objectTypes.ZONE: {
            if (
                modalType === modalTypes.EDIT_MODAL &&
                isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_ZONE_UUID_UPDATE])
            ) {
                updatedColumns.push(propertyConstants.PROPERTY_UUID);
            }
            break;
        }
        default:
            break;
    }
    return updatedColumns;
};

export const getTableItemsVisibilityOption = (objectType: objectTypes.ObjectType, isSecond: boolean): string | null => {
    switch (objectType) {
        case objectTypes.EXPORT_TASK: {
            if (isSecond) {
                return optionsConstants.OPTION_EXPORT_TASK_SHOW_DELETED;
            }
            return optionsConstants.OPTION_EXPORT_TASK_SHOW_HIDDEN;
        }
        case objectTypes.IMPORT_TASK: {
            if (isSecond) {
                return optionsConstants.OPTION_IMPORT_TASK_SHOW_DELETED;
            }
            return optionsConstants.OPTION_IMPORT_TASK_SHOW_HIDDEN;
        }
        default:
            return null;
    }
};
