import { DispatchType, PropsType } from "../../../containers/scenes/HomePageContainer";

import { Component } from "react";

type Props = PropsType & DispatchType;
// TODO not in use, need to be removed or deleted
export class HomePage extends Component<Props> {
    render(): JSX.Element | null {
        return null;
    }
}
