import * as designHelper from "../../helpers/designHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as licenseActions from "../../actions/licenseActions";
import * as modalTypes from "../../constants/modalTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as translationConstants from "../../constants/translationConstants";
import * as translationHelper from "../../helpers/translationHelper";

import { License, generateLicenseHeadings, generateLicensePreviewData } from "../../types/licensing/license";
import React, { Component } from "react";

import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { FilePreviewContainer } from "../general/scene/previews/FilePreview";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { TableDeleteButtonContainer } from "../general/table/buttons/TableDeleteButton";
import { TableHeading } from "../../types/tableHeading";
import { TextPreview } from "../general/scene/TextPreview";

import { connect } from "react-redux";
import { generateHistoricalActionHeadings } from "../../types/historicalAction";

import imgClose from "../../resources/img/icons/close.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type Props = OwnProps & ContainerProps;

// TODO unify handleModalKeyDown and params.title, params.displayText/description with e.g.ConfirmationModal: generate title in modal components
export class PreviewModal extends Component<Props> {
    componentDidMount(): void {
        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    handleModalKeyDown = (event: Record<string, any>): void => {
        const { closeModal, type } = this.props;

        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.target.type !== "textarea") {
                    closeModal(type);
                }

                break;
            }
            case keyCodes.ESCAPE: {
                closeModal(type);
                break;
            }
            default: {
                break;
            }
        }
    };

    handleUpdateClick = (): void => {
        this.props.updateOnlineLicense();
    };

    getModalClassName = (): string => {
        const { type } = this.props;
        const { activeScene } = this.props.params;
        let className = "modal simple-modal";

        if (activeScene) {
            className += designHelper.getModalClassType(activeScene);
        }

        if (type === modalTypes.MODAL_FILE_PREVIEW) {
            className += " sql-preview-modal";
        }

        if (type === modalTypes.MODAL_USER_ACTION_HISTORY) {
            className += " modal-bg";
        }

        return className;
    };

    getLicenseModules = (): JSX.Element => {
        const list: Array<JSX.Element> = [];

        if (this.props.activeLicense) {
            for (const item of this.props.activeLicense[propertyConstants.PROPERTY_MODULES]) {
                list.push(
                    <div key={item[propertyConstants.PROPERTY_CODE]}>
                        {translationHelper.getModulesTranslation(item[propertyConstants.PROPERTY_CODE])}
                    </div>
                );
            }
        }

        return <span className="property-text">{list}</span>;
    };

    getHeadings = (): Array<TableHeading> => {
        const { params } = this.props;
        let propertyList: Array<propertyConstants.Property> = [];

        switch (params.objectType) {
            case objectTypes.LICENSE: {
                propertyList = [
                    propertyConstants.PROPERTY_LICENSE_STATUS,
                    propertyConstants.PROPERTY_EXPIRATION,
                    propertyConstants.PROPERTY_DIVIDING_LINE,
                    propertyConstants.PROPERTY_LICENSE_IDENTIFICATION,
                    propertyConstants.PROPERTY_UUID,
                    propertyConstants.PROPERTY_COMPUTER_ID,
                    propertyConstants.PROPERTY_COMPANY_NAME,
                    propertyConstants.PROPERTY_USERNAME,
                    propertyConstants.PROPERTY_PRODUCT_ID
                ];

                return generateLicenseHeadings(params.objectType, propertyList, propertyList, [], [], propertyList);
            }
            case objectTypes.USER_ACTION_HISTORY: {
                propertyList = [
                    propertyConstants.PROPERTY_DATE,
                    propertyConstants.PROPERTY_USERNAME,
                    propertyConstants.PROPERTY_DATABASE_NAME,
                    propertyConstants.PROPERTY_SYSTEM_NAME,
                    propertyConstants.PROPERTY_ZONE_NAME,
                    propertyConstants.PROPERTY_ITEM_TYPE_ID,
                    propertyConstants.PROPERTY_DATA
                ];

                return generateHistoricalActionHeadings(
                    params.objectType,
                    [],
                    [],
                    propertyList,
                    propertyList,
                    [],
                    [],
                    propertyList
                );
            }
            default:
                return [];
        }
    };

    getContent = (): JSX.Element | null => {
        const { activeLicense, params } = this.props;
        const replacements = {
            redlikeWebAbbreviation: translationConstants.REDLIKE_WEB_ABBREVIATION
        };

        switch (params.objectType) {
            case objectTypes.EXPORT_TASK: {
                return (
                    <div>
                        <div className="height-100">
                            {translate("export.currentR2WVersion", replacements)}:{" "}
                            {params.data[propertyConstants.PROPERTY_VERSION] ??
                                translate("export.noR2WVersionAvailable", replacements)}
                        </div>
                    </div>
                );
            }
            case objectTypes.FILE:
            case objectTypes.PRODUCT_FILE: {
                return (
                    <div className="file-preview-modal">
                        <div className="height-100">
                            <FilePreviewContainer fileId={params.fileId || null} />
                        </div>
                    </div>
                );
            }

            case objectTypes.MASTER_COLOR_SPECTRO_TYPE: {
                return (
                    <div>
                        <div className="row">{translate("color.successfullRgbCalculation")}</div>
                        <div className="row">
                            <span>{translate("general.rgb")}:</span>
                            <span className="rgb-text">
                                <div className="color-square red" />
                                {params?.data?.[propertyConstants.PROPERTY_COLOR_RED] ?? ""}
                                <div className="color-square green" />{" "}
                                {params?.data?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? ""}
                                <div className="color-square blue" />{" "}
                                {params?.data?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? ""}
                            </span>
                        </div>
                        <div
                            className={
                                params?.data?.[propertyConstants.PROPERTY_COLOR_RGB_HEX]
                                    ? "color-preview"
                                    : "color-preview no-color"
                            }
                            style={{
                                backgroundColor: params?.data?.[propertyConstants.PROPERTY_COLOR_RGB_HEX] || ""
                            }}
                        ></div>
                    </div>
                );
            }
            case objectTypes.LICENSE: {
                if (activeLicense) {
                    return (
                        <div className="preview-content">
                            <button className="btn-md btn-info" onClick={(): void => this.handleUpdateClick()}>
                                {translate("license.updateLicense")}
                            </button>
                            <TableDeleteButtonContainer
                                dataPrivileges={[]}
                                privileges={this.props.privileges}
                                objectType={this.props.params.objectType}
                                activeList={[]}
                                list={[]}
                                editable={true}
                            />
                            <TextPreview
                                className=""
                                objectType={objectTypes.LICENSE}
                                headings={this.getHeadings()}
                                data={generateLicensePreviewData(activeLicense)}
                            />
                            <div className="row padding-top-10">
                                <span className="property-title">{translate("license.modules")}:</span>
                                {this.getLicenseModules()}
                            </div>
                        </div>
                    );
                }

                return null;
            }
            case objectTypes.USER_ACTION_HISTORY: {
                return (
                    <div className="preview-content">
                        <TextPreview
                            className=""
                            objectType={objectTypes.USER_ACTION_HISTORY}
                            headings={this.getHeadings()}
                            data={params.data}
                        />
                    </div>
                );
            }
            default:
                return null;
        }

        return null;
    };

    getActionButton = (): JSX.Element | null => {
        const { params } = this.props;

        if (params.objectType === objectTypes.EXPORT_TASK) {
            if (params.data && params.data[propertyConstants.PROPERTY_VERSION] && params.returnAction) {
                return (
                    <button
                        className="btn-info pull-right"
                        onClick={(): void => {
                            params.returnAction(params.data[propertyConstants.PROPERTY_VERSION]);
                            this.props.closeModal(this.props.type);
                        }}
                        autoFocus={true}
                    >
                        {translate("export.setNextVersion")}
                    </button>
                );
            } else {
                return (
                    <button className="btn-info pull-right disabled" disabled>
                        {translate("export.setNextVersion")}
                    </button>
                );
            }
        }
        return null;
    };

    render(): JSX.Element | null {
        const { type, closeModal, index } = this.props;

        return (
            <div className={this.getModalClassName()} style={{ zIndex: index }}>
                <header className="modal-header">
                    <div className="title">{this.props.params.title}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">{this.getContent()}</div>
                <footer className="modal-footer">
                    <button
                        className="btn-info pull-right"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                        autoFocus={true}
                    >
                        {translate("general.ok")}
                    </button>
                    {this.getActionButton()}
                </footer>
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{
    activeLicense: License | null;
    privileges: any;
}>;

export type DispatchType = Readonly<{
    updateOnlineLicense(): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeLicense: state.license.activeLicense,
    privileges: state.login.privileges
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    updateOnlineLicense: (): any => dispatch(licenseActions.updateOnlineLicense())
});

export const PreviewModalContainer = connect(mapStateToProps, mapDispatchToProps)(PreviewModal);
