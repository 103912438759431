import * as browserStorageConstants from "../constants/browserStorageConstants";
import * as databaseActions from "../actions/databaseActions";
import * as generalHelper from "../helpers/generalHelper";
import * as messageHelper from "../helpers/messageHelper";
import * as modalTypes from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "../helpers/optionHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as responseValidationHelper from "../helpers/responseValidationHelper";
import * as serverEvents from "../constants/serverEvents";
import * as serverEventsActions from "../actions/serverEventsActions";
import * as universalObjectActions from "../actions/universalObjectActions";
import * as websocketActions from "../actions/websocketActions";

import { Database, mapDatabase } from "../types/database";
import { Option, mapOption } from "../types/option";
import { mapAllDataPrivilege, mapAllPrivilege } from "../types/dataItem";

import { AppState } from "../reducers";
import { getAllItems } from "../actions/universalObjectActions";
import { mapCurrency } from "../types/currency";
import { mapDataVacuuming } from "../types/monitoring/dataVacuuming";
import { mapMenu } from "../types/menu";
import { mapSystem } from "../types/system";
import { mapZone } from "../types/zone";
import { setRedlikeTranslations } from "../helpers/languageHelper";
import { updateExportTask } from "../types/exportTask";
import { updateImportTask } from "../types/importTask";

export const processServerResponse = (response: any, state: AppState): Array<any> => {
    switch (response.event) {
        case serverEvents.EVENT_PING: {
            return [];
        }
        case serverEvents.EVENT_SESSION_INITIALIZED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverEventsActions.saveSessionInfo(response.data.uuid)];
        }
        case serverEvents.EVENT_DATABASE_SELECTED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];
            const active = mapDatabase(
                response.data.active,
                state.login.loggedUser,
                generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
            );
            const databaseList = [];

            for (const item of response.data.all) {
                const database = mapDatabase(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (database !== null) {
                    databaseList.push(database);
                }
            }

            requests.push(serverEventsActions.saveDatabases(active, databaseList));
            requests.push(universalObjectActions.getAllItems(objectTypes.DATABASE_SYSTEM_ZONE));

            // TODO kontrola vsude aby se loadData volalo jen pokud je zvolena nejaka DB, a neni null
            if (active) {
                requests.push(databaseActions.loadData());
            }

            if (active && active[propertyConstants.PROPERTY_UPDATE_NEEDED]) {
                requests.push(
                    navigationActions.navigationOpenModal(modalTypes.MODAL_DATABASES_UPDATE, {
                        databaseName: active.name
                    })
                );
            }

            return requests;
        }
        case serverEvents.EVENT_SYSTEM_SELECTED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const active = mapSystem(response.data.active);
            const systemList = [];

            for (const item of response.data.all) {
                const system = mapSystem(item);

                if (system !== null) {
                    systemList.push(system);
                }
            }

            return [
                serverEventsActions.saveSystems(active, systemList),
                universalObjectActions.reloadData(objectTypes.ZONE)
            ];
        }
        case serverEvents.EVENT_SYSTEM_CURRENCY_SELECTED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [];
            }

            const requests = [];
            const active = mapCurrency(response.data.active);
            const currencyList = [];

            for (const item of response.data.all) {
                const currency = mapCurrency(item);

                if (currency !== null) {
                    currencyList.push(currency);
                }
            }

            requests.push(serverEventsActions.saveCurrencies(currencyList, active, undefined));

            switch (state.navigation.activeScene) {
                case navigationTypes.SYSTEM_PRICES_SCENE: {
                    requests.push(
                        universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_GENERIC),
                        universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_GROUP),
                        getAllItems(objectTypes.SYSTEM_PACKAGE)
                    );
                    break;
                }
                case navigationTypes.SYSTEM_COLORANTS_SCENE: {
                    requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT));
                    break;
                }
                case navigationTypes.SYSTEM_PRODUCTS_SCENE: {
                    requests.push(
                        universalObjectActions.reloadData(objectTypes.SYSTEM_PRODUCT),
                        universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT),
                        universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE)
                    );
                    break;
                }
                default:
                    break;
            }

            return requests;
        }
        case serverEvents.EVENT_USER_SELECTED: {
            return [];
        }
        case serverEvents.EVENT_ZONE_SELECTED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const active = mapZone(response.data.active);
            const zoneList = [];

            for (const item of response.data.all) {
                const zone = mapZone(item);

                if (zone !== null) {
                    zoneList.push(zone);
                }
            }

            return [serverEventsActions.saveZones(active, zoneList)];
        }
        case serverEvents.EVENT_ZONE_CURRENCY_SELECTED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [];
            }

            const activeCurrency = mapCurrency(response.data);
            const requests: Array<any> = [serverEventsActions.saveCurrencies(undefined, undefined, activeCurrency)];

            if (activeCurrency) {
                switch (state.navigation.activeScene) {
                    case navigationTypes.ZONE_COLORANTS_SCENE:
                        requests.push(getAllItems(objectTypes.ZONE_PRICE_OPTION));
                        break;
                    case navigationTypes.ZONE_PRICES_SCENE:
                        requests.push(
                            getAllItems(objectTypes.ZONE_PRICE_GENERIC),
                            getAllItems(objectTypes.ZONE_PRICE_GROUP),
                            getAllItems(objectTypes.ZONE_PRICE_MARGIN),
                            getAllItems(objectTypes.ZONE_PRICE_OPTION),
                            getAllItems(objectTypes.ZONE_PACKAGE)
                        );
                        break;
                    case navigationTypes.ZONE_PRODUCTS_SCENE:
                        requests.push(getAllItems(objectTypes.ZONE_PRICE_OPTION));
                        break;
                    default:
                        break;
                }
            }

            return requests;
        }
        case serverEvents.EVENT_DATA_PRIVILEGES_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverEventsActions.saveDataPrivileges(mapAllDataPrivilege(response.data))];
        }
        case serverEvents.EVENT_EXPORT_TASK_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            const requests = [];
            const newTasks = [];
            for (const task of state.export.list) {
                let updateIndex = -1;

                for (const index in response.data) {
                    if (response.data[index]?.[propertyConstants.PROPERTY_ID] === task[propertyConstants.PROPERTY_ID]) {
                        updateIndex = Number(index);
                    }
                }

                if (updateIndex === -1) {
                    newTasks.push(task);
                } else {
                    newTasks.push(updateExportTask(task, response.data[updateIndex]));
                }
            }

            requests.push(universalObjectActions.saveItems(objectTypes.EXPORT_TASK, newTasks, state.export.count));

            for (const item in response.data) {
                const currentStatus =
                    state.export.exportStatuses.find(
                        (status) =>
                            status[propertyConstants.PROPERTY_ID] ===
                            response.data[item]?.[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID]
                    ) ?? null;

                if (currentStatus?.[propertyConstants.PROPERTY_FINISHED]) {
                    requests.push(universalObjectActions.getAllItems(objectTypes.DATA_SIZE));
                }
            }

            return requests;
        }
        case serverEvents.EVENT_IMPORT_TASK_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];
            const newTasks = [];

            for (const task of state.import.list) {
                let updateIndex = -1;

                for (const index in response.data) {
                    if (response.data[index]?.[propertyConstants.PROPERTY_ID] === task[propertyConstants.PROPERTY_ID]) {
                        updateIndex = Number(index);
                    }
                }

                if (updateIndex === -1) {
                    newTasks.push(task);
                } else {
                    newTasks.push(
                        updateImportTask(
                            state.import.importTypes,
                            state.databaseSystemZone.list,
                            task,
                            response.data[updateIndex],
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            state.import.importStatuses
                        )
                    );
                }
            }

            requests.push(universalObjectActions.saveItems(objectTypes.IMPORT_TASK, newTasks, state.import.count));

            for (const item in response.data) {
                const currentStatus =
                    state.import.importStatuses.find(
                        (status) =>
                            status[propertyConstants.PROPERTY_ID] ===
                            response.data[item]?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]
                    ) ?? null;

                if (currentStatus?.[propertyConstants.PROPERTY_FINISHED]) {
                    requests.push(universalObjectActions.getAllItems(objectTypes.DATA_SIZE));
                }
            }

            return requests;
        }
        case serverEvents.EVENT_MENU_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverEventsActions.saveMenu(mapMenu(response.data, state.software.custom))];
        }
        case serverEvents.EVENT_OPTIONS_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            let item: any;
            let newOption: Option | null;
            const optionList = [];

            for (item of Object.values(response.data)) {
                newOption = mapOption({
                    ...item,
                    value: optionHelper.getUpdatedOptions(item.key, item.value)
                });

                if (newOption) {
                    optionList.push(newOption);

                    if (newOption[propertyConstants.PROPERTY_KEY] === optionsConstants.OPTION_LANGUAGE) {
                        const language = setRedlikeTranslations(newOption[propertyConstants.PROPERTY_VALUE]);
                        localStorage.setItem(browserStorageConstants.LOCAL_STORAGE_LANGUAGE, language);
                    }
                }
            }
            return [
                serverEventsActions.saveOptions(optionList)
                // universalObjectActions.getAllItems(objectTypes.GLOBAL_OPTION),
                // universalObjectActions.getAllItems(objectTypes.USER_OPTION)
            ];
        }
        case serverEvents.EVENT_PRIVILEGES_CHANGED: {
            if (!responseValidationHelper.isEventValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverEventsActions.savePrivileges(mapAllPrivilege(response.data))];
        }
        case serverEvents.EVENT_DATABASE_VACUUM_CHANGED: {
            const requests = [];

            const dataVacuuming = mapDataVacuuming(response.data);

            if (dataVacuuming) {
                if (dataVacuuming[propertyConstants.PROPERTY_DONE] < dataVacuuming[propertyConstants.PROPERTY_TOTAL]) {
                    requests.push(
                        universalObjectActions.saveItemsNoParams(objectTypes.DATA_VACUUMING, [dataVacuuming])
                    );
                } else {
                    requests.push(
                        universalObjectActions.saveItemsNoParams(objectTypes.DATA_VACUUMING, []),
                        universalObjectActions.getAllItems(objectTypes.DATA_SIZE),
                        navigationActions.navigationCloseModal(modalTypes.MODAL_DATABASES_VACUUM)
                    );
                }
            }

            return requests;
        }
        case serverEvents.EVENT_DATABASE_LOCKED: {
            const databaseList: Array<Database> = [];
            let database: Database | null;

            for (const item of response.data) {
                database = mapDatabase(item);

                if (database) {
                    databaseList.push(database);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.LOCKED_DATABASE, databaseList)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
