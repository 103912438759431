import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterFandeckTable } from "./tables/MasterFandeckTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterFandeckSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableFandecks: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class MasterFandeckScene extends Component<Props, State> {
    state: State = {
        tableFandecks:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.fandeckTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableFandecks:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.fandeckTableConstant)
                        : null) || null
            });
        }
    }

    getFandecksTable = (): JSX.Element | null => {
        const { fandeckTableConstant } = this.props;
        const { tableFandecks } = this.state;

        if (tableFandecks) {
            return (
                <MasterFandeckTable
                    tableConstant={fandeckTableConstant}
                    objectType={objectTypes.MASTER_FANDECK}
                    dataPrivileges={tableFandecks}
                    loading={this.props.fandeckLoading}
                    buttonLoading={this.props.fandeckButtonLoading}
                    allList={this.props.fandeckList}
                    activeList={this.props.fandeckActiveList}
                    columnOrder={this.props.fandeckColumnOrder}
                    columnVisibility={this.props.fandeckColumnVisibility}
                    columnWidth={this.props.fandeckColumnWidth}
                    offset={this.props.fandeckOffset}
                    page={this.props.fandeckPage}
                    rowCount={this.props.fandeckRowCount}
                    rowCountCustom={this.props.fandeckRowCountCustom}
                    search={this.props.fandeckSearch}
                    searchParams={this.props.fandeckSearchParams}
                    showFilterRow={this.props.fandeckShowFilterRow}
                    showGlobalSearch={this.props.fandeckShowGlobalSearch}
                    sortingAsc={this.props.fandeckSortingAsc}
                    sortingCriterion={this.props.fandeckSortingCriterion}
                    totalCount={this.props.fandeckTotalCount}
                    useTmcNames={this.props.optionUseTmcNames || false}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getFandecksTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.fandeckSceneTableSize}
                dataPrivileges={this.state.tableFandecks}
                sceneConstant={menuConstants.PAGE_MASTER_FANDECKS}
            />
        );
    }
}
