import * as dataTypeConstants from "../../../constants/dataTypeConstants";
import * as generalHelper from "../../../helpers/generalHelper";
import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";
import { ModalLabelInputButtonContainer } from "./ModalLabelInputButton";
import { TableHeading } from "../../../types/tableHeading";
import { createBarcode } from "../../../types/barcode";
import { t as translate } from "react-i18nify";

type Props = {
    modalId: number;
    className: string;
    parameterType: inputConstants.InputType;
    objectType: objectTypes.ObjectType;
    heading: TableHeading;
    property: propertyConstants.Property;
    innerProperty: propertyConstants.Property;
    object: any;
    callbackChange: (value: any) => any;
};

export class ModalMoreRows extends Component<Props> {
    componentDidMount(): void {
        const list = this.props.object?.[this.props.property] || [];

        if (list.length === 0) {
            this.handleAddNewItem();
            this.props.callbackChange(list);
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>): void {
        if (
            JSON.stringify(this.props.object?.[this.props.property]) !==
            JSON.stringify(prevProps.object?.[prevProps.property])
        ) {
            const list = this.props.object?.[this.props.property] || [];

            if (list.length === 0) {
                this.handleAddNewItem();
                this.props.callbackChange(list);
            }
        }
    }

    handleAddNewItem = (): any => {
        const { property } = this.props;

        const list = this.props.object?.[property] || [];

        if (propertyHelper.isPropertyBarcodes(property)) {
            list.push(createBarcode("", property === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES));
        }

        this.props.callbackChange(list);
    };

    getRowClassName = (editable: boolean): any => {
        let className = "";

        switch (this.props.parameterType) {
            case inputConstants.PARAMETER_TYPE_BARCODE: {
                className = "type-input units-input";
                break;
            }
            case inputConstants.PARAMETER_TYPE_LIST: {
                className = "checkbox-with-title";
                break;
            }
            default: {
                break;
            }
        }

        if (!editable) {
            className += " disabled";
        }

        return className;
    };

    getLabel = (): string => {
        switch (this.props.innerProperty) {
            case propertyConstants.PROPERTY_BARCODE:
                return translate("barcode.addBarcode");
            default:
                return "";
        }
    };

    getAddNewRow = (): JSX.Element | null => {
        if (
            this.props.heading[tableConstants.TABLE_HEADING_EDITING] &&
            this.props.parameterType === inputConstants.PARAMETER_TYPE_BARCODE
        ) {
            return (
                <div className={this.props.className}>
                    <label className="form-label link" onClick={(): any => this.handleAddNewItem()}>
                        {this.getLabel()}
                    </label>
                </div>
            );
        }

        return null;
    };

    getValue = (item: any, property: propertyConstants.Property): any => {
        if (this.props.object) {
            switch (this.props.innerProperty) {
                case propertyConstants.PROPERTY_VALUE_ENUM:
                    return generalHelper.isObjectInListById(item, this.props.object[property]);
                default:
                    return item[this.props.innerProperty];
            }
        }
    };

    getListTranslations = (item: any, property: propertyConstants.Property): string => {
        switch (property) {
            case priceConstants.PRICE_OPTION_PRICE_CALCULATION:
                return translationHelper.getPropertyTranslation(
                    this.props.objectType,
                    item[propertyConstants.PROPERTY_KEY]
                );
            default:
                return item[propertyConstants.PROPERTY_NAME];
        }
    };

    getRow = (item: Record<string, any>, index: number): JSX.Element | null => {
        const { modalId, heading, objectType, parameterType, innerProperty } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        let editable: boolean = heading[tableConstants.TABLE_HEADING_EDITING];
        let labelCaption = index === 0 ? heading[tableConstants.TABLE_HEADING_TRANSLATION] : "";

        if (property === propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST) {
            labelCaption =
                item[propertyConstants.PROPERTY_NAME] +
                " " +
                translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODE);
        }

        if (
            objectTypeHelper.isObjectTypeDuplicateProduct(objectType) &&
            item[propertyConstants.PROPERTY_ID] === dataTypeConstants.DATA_TYPE_PRODUCT
        ) {
            editable = false;
        }

        const value = this.getValue(item, property);

        switch (parameterType) {
            case inputConstants.PARAMETER_TYPE_BARCODE:
                return (
                    <div className={this.props.className} key={property + index}>
                        <ModalLabelInputButtonContainer
                            modalId={modalId}
                            className={index > 0 ? "margin-input" : ""}
                            type={heading[tableConstants.TABLE_HEADING_TYPE]}
                            objectType={objectType}
                            editable={editable}
                            required={heading[tableConstants.TABLE_HEADING_REQUIRED]}
                            labelCaption={labelCaption}
                            inputClassName={this.getRowClassName(editable)}
                            inputType={this.props.parameterType}
                            inputName={property}
                            inputValue={value || ""}
                            inputCallbackChange={(values: Array<any>): void => {
                                this.props.callbackChange(values);
                            }}
                            innerRowIndex={index}
                            object={this.props.object}
                        />
                    </div>
                );
            case inputConstants.PARAMETER_TYPE_LIST:
                return (
                    <div className={this.props.className} key={property + index}>
                        <ModalLabelInputButtonContainer
                            modalId={modalId}
                            className={
                                index > 0 && property !== propertyConstants.PROPERTY_DATA_TYPE_LIST
                                    ? "margin-input"
                                    : ""
                            }
                            type={heading[tableConstants.TABLE_HEADING_TYPE]}
                            objectType={objectType}
                            editable={editable}
                            required={heading[tableConstants.TABLE_HEADING_REQUIRED]}
                            labelCaption={labelCaption}
                            inputClassName={this.getRowClassName(editable)}
                            inputType={this.props.parameterType}
                            inputName={property}
                            inputInnerName={innerProperty}
                            inputCaption={this.getListTranslations(item, property)}
                            inputAllList={this.getList()}
                            inputList={
                                innerProperty === propertyConstants.PROPERTY_VALUE_ENUM
                                    ? this.props.object?.[this.props.property]
                                    : []
                            }
                            inputValue={value || ""}
                            inputCallbackChange={(values: Array<any>): void => {
                                this.props.callbackChange(values);
                            }}
                            innerRowIndex={index}
                            object={this.props.object}
                        />
                    </div>
                );
            case inputConstants.PARAMETER_TYPE_TEXT_INPUT:
                return (
                    <div className={this.props.className} key={property + index}>
                        <ModalLabelInputButtonContainer
                            modalId={modalId}
                            type={heading[tableConstants.TABLE_HEADING_TYPE]}
                            objectType={objectType}
                            editable={editable}
                            required={heading[tableConstants.TABLE_HEADING_REQUIRED]}
                            labelCaption={labelCaption}
                            inputClassName="type-input"
                            inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                            inputName={property}
                            inputValue={item?.[propertyConstants.PROPERTY_VALUE] ?? ""}
                            inputCallbackChange={(value: string): void => {
                                if (property === propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST) {
                                    const updatedItem = {
                                        ...item,
                                        [propertyConstants.PROPERTY_VALUE]: value
                                    };

                                    this.props.callbackChange(updatedItem);
                                } else {
                                    this.props.callbackChange(value);
                                }
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    getList = (): Array<any> => {
        switch (this.props.parameterType) {
            case inputConstants.PARAMETER_TYPE_BARCODE:
                return this.props.object?.[this.props.property] || [];
            case inputConstants.PARAMETER_TYPE_TEXT_INPUT:
                if (this.props.property === propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST) {
                    return this.props.object?.[this.props.property] || [];
                }
                return [];
            case inputConstants.PARAMETER_TYPE_LIST:
                if (this.props.property === priceConstants.PRICE_OPTION_PRICE_CALCULATION) {
                    return this.props.object?.[this.props.property] || [];
                }
                return this.props.heading[tableConstants.TABLE_HEADING_ENUM];
            default:
                return [];
        }
    };

    generateRows = (): Array<JSX.Element | null> => {
        const list = this.getList();
        const inputList: Array<JSX.Element | null> = [];

        list.forEach((item: any, index: number) => inputList.push(this.getRow(item, index)));

        return inputList;
    };

    getClassName = (): string => {
        return this.props.property === propertyConstants.PROPERTY_DATA_TYPE_LIST
            ? "width-100 data-container slim-scroll"
            : "width-100";
    };

    render(): JSX.Element {
        return (
            <div key={this.props.property} className={this.getClassName()}>
                {this.generateRows()}
                {this.getAddNewRow()}
            </div>
        );
    }
}
