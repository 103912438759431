import * as bookmarkConstants from "../../../constants/bookmarkConstants";
import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as serverRestMethods from "../../../constants/serverRestMethods";
import * as tableConstants from "../../../constants/tableConstants";

import { Color, generateColorHeadings } from "../../../types/color";
import { DispatchType, PropsType } from "../../../containers/scenes/zone/ZoneColorSceneContainer";
import React, { Component } from "react";

import { Bookmark } from "../../../types/bookmark";
import { ColorFormulaBookmark } from "../../bookmarks/ColorFormulaBookmark";
import { DataItem } from "../../../types/dataItem";
import { Fandeck } from "../../../types/fandeck";
import { LineGraph } from "../../general/graph/LineGraph";
import { MenuItem } from "../../../types/menu";
import { ModalProperties } from "../../../types/modalProperties";
import { ModalSelectbox } from "../../general/inputs/ModalSelectbox";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { SceneDetail } from "../../general/scene/SceneDetail";
import { SceneFilterTable } from "../../general/scene/SceneFilterTable";
import { TableHeading } from "../../../types/tableHeading";
import { TextPreview } from "../../general/scene/TextPreview";

import { generateFormulaHeadings } from "../../../types/formula";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    tableColors: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class ZoneColorScene extends Component<Props, State> {
    state: State = {
        tableColors:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.colorTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableColors:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.colorTableConstant)
                        : null) || null
            });
        }
    }

    getHeadings = (
        modalType: modalTypes.ModalType | null = null,
        bookmark: string | null = null
    ): Array<TableHeading> => {
        const genericPropertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLOR_NAME,
            propertyConstants.PROPERTY_COLOR_SEARCH_NAME,
            propertyConstants.PROPERTY_COLOR_CODE,
            propertyConstants.PROPERTY_COLOR_SEARCH_CODE,
            propertyConstants.PROPERTY_UUID,
            propertyConstants.PROPERTY_IMAGE_ID
        ];
        const additionalPropertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_FANDECK_PAGE,
            propertyConstants.PROPERTY_FANDECK_POSITION,
            propertyConstants.PROPERTY_COLOR_MORE_INFO,
            propertyConstants.PROPERTY_COLOR_PRIORITY,
            propertyConstants.PROPERTY_BARCODES
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLOR_CODE,
            propertyConstants.PROPERTY_COLOR_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_COLOR_NAME];
        let editableList: Array<propertyConstants.Property> = [];
        let visibleList: Array<propertyConstants.Property> = [];

        if (this.props.optionUseTmcNames) {
            genericPropertyList.splice(1, 0, propertyConstants.PROPERTY_TMC_NAME);
        }

        if (modalType === null) {
            visibleList = [...genericPropertyList].concat([...additionalPropertyList]);
            editableList = [...genericPropertyList].concat([...additionalPropertyList]);
        }

        if (modalType !== null) {
            switch (bookmark) {
                case bookmarkConstants.BOOKMARK_GENERAL: {
                    visibleList = [...genericPropertyList];
                    editableList = [...genericPropertyList];

                    visibleList.splice(5, 0, propertyConstants.PROPERTY_COLOR_RGB_HEX);
                    visibleList.splice(5, 0, propertyConstants.PROPERTY_COLOR_BLUE);
                    visibleList.splice(5, 0, propertyConstants.PROPERTY_COLOR_GREEN);
                    visibleList.splice(5, 0, propertyConstants.PROPERTY_COLOR_RED);

                    editableList = editableList.concat(
                        propertyConstants.PROPERTY_COLOR_BLUE,
                        propertyConstants.PROPERTY_COLOR_GREEN,
                        propertyConstants.PROPERTY_COLOR_RED
                    );

                    break;
                }
                case bookmarkConstants.BOOKMARK_PROPERTIES: {
                    visibleList = [...additionalPropertyList];
                    editableList = [...additionalPropertyList];
                    break;
                }
                default:
                    break;
            }
        }

        const orderList = [...visibleList];

        return generateColorHeadings(
            objectTypes.ZONE_COLOR,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.colorColumnWidth
        );
    };

    getFormulaHeadings = (): Array<TableHeading> => {
        const visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_STATUS,
            propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_BASE_AMOUNT,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_MORE_INFO_INTERNAL,
            propertyConstants.PROPERTY_MORE_INFO_PRINT,
            propertyConstants.PROPERTY_DATE_CREATED
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_BASE_AMOUNT,
            propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID,
            propertyConstants.PROPERTY_COLORANT_LIST,
            propertyConstants.PROPERTY_STATUS,
            propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_IS_GRAVIMETRIC,
            propertyConstants.PROPERTY_PRODUCT_ID
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [];
        const editableList: Array<propertyConstants.Property> = [];
        const orderList = [...visibleList];

        return generateFormulaHeadings(
            objectTypes.ZONE_FORMULA,
            this.props.productList,
            [],
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            {} as Record<propertyConstants.Property, number>
        );
    };

    getImagePreview = (imageId: number | null, isVisible: boolean): JSX.Element | null => {
        if (imageId && isVisible) {
            return (
                <div className="card image">
                    <img
                        key="file-image"
                        alt=""
                        src={`${this.props.urlRest}${serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID}?session_uuid=${this.props.sessionUuid}&image_id=${imageId}&height=500&width=500`}
                    />
                </div>
            );
        }

        return null;
    };

    // TODO: New component?
    getImageColorPreview = (activeColor: Color, isImageVisible: boolean): JSX.Element => {
        let classNameColorPreview = "card color";

        if (activeColor[propertyConstants.PROPERTY_IMAGE_ID] && isImageVisible) {
            classNameColorPreview += " small-color";
        }
        return (
            <div className={classNameColorPreview}>
                <div className="card-content">
                    <div
                        className={
                            activeColor[propertyConstants.PROPERTY_COLOR_RGB_HEX]
                                ? "color-square"
                                : "color-square no-color"
                        }
                        style={{
                            backgroundColor: activeColor[propertyConstants.PROPERTY_COLOR_RGB_HEX] || ""
                        }}
                    ></div>
                </div>
                <div className="card-title">
                    <span>{translate("general.rgb")}:</span>
                    <span className="rgb-text">
                        <div className="color-square red" />
                        {activeColor[propertyConstants.PROPERTY_COLOR_RED] !== null
                            ? activeColor[propertyConstants.PROPERTY_COLOR_RED]
                            : ""}
                        <div className="color-square green" />{" "}
                        {activeColor[propertyConstants.PROPERTY_COLOR_GREEN] !== null
                            ? activeColor[propertyConstants.PROPERTY_COLOR_GREEN]
                            : ""}
                        <div className="color-square blue" />{" "}
                        {activeColor[propertyConstants.PROPERTY_COLOR_BLUE] !== null
                            ? activeColor[propertyConstants.PROPERTY_COLOR_BLUE]
                            : ""}
                    </span>
                </div>
            </div>
        );
    };

    getPropertiesBookmark = (activeColor: Color): JSX.Element => {
        const headings = this.getHeadings();
        const imageHeading =
            headings.find(
                (heading: TableHeading) =>
                    heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_IMAGE_ID
            ) || null;
        const isImageVisible = imageHeading?.[tableConstants.TABLE_HEADING_VISIBILITY] ?? false;

        return (
            <div className="color-properties" key="color-properties">
                <div className="data-color-container">
                    <TextPreview
                        className="color-content"
                        objectType={objectTypes.ZONE_COLOR_DATA}
                        headings={headings}
                        data={activeColor}
                    />
                </div>

                <div className="data-color-container">
                    <ModalSelectbox
                        className={"scene-select zone"}
                        editable={activeColor[propertyConstants.PROPERTY_DATA].length ? true : false}
                        required={true}
                        value={activeColor[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] || ""}
                        options={generalHelper.getOptionsForSelectbox(
                            objectTypes.ZONE_COLOR_DATA,
                            activeColor[propertyConstants.PROPERTY_DATA]
                        )}
                        autoselect={false}
                        readonlyCaption={translate("color.noSpectralData")}
                        callback={(event: any, value: string): void => {
                            this.props.updateColorData(
                                objectTypes.ZONE_COLOR_DATA,
                                activeColor[propertyConstants.PROPERTY_DATA],
                                !isNaN(Number(value)) && value !== null && value !== "" ? Number(value) : null
                            );
                        }}
                    />
                    <div className="charts-container">
                        <LineGraph activeColor={activeColor} />
                        {this.getImageColorPreview(activeColor, isImageVisible)}
                        {this.getImagePreview(activeColor[propertyConstants.PROPERTY_IMAGE_ID], isImageVisible)}
                    </div>
                </div>
            </div>
        );
    };

    getFormulaBookmark = (): JSX.Element | null => {
        if (this.props.colorActiveList.length) {
            return (
                <ColorFormulaBookmark
                    tableConstant={this.props.formulaColorantTableConstant}
                    dataPrivileges={this.state.tableColors} // TODO own privileges
                    headings={this.getFormulaHeadings()}
                    colorActiveList={this.props.colorActiveList}
                    productList={this.props.productList}
                    productActiveList={this.props.productActiveList}
                    formulaList={this.props.colorActiveList[0][propertyConstants.PROPERTY_FORMULA_LIST]}
                    formulasLoading={this.props.formulasLoading}
                    unitList={this.props.unitList}
                    activeUnit={this.props.activeUnit}
                    columnOrder={this.props.formulaColorantColumnOrder}
                    columnVisibility={this.props.formulaColorantColumnVisibility}
                    columnWidth={this.props.formulaColorantColumnWidth}
                    count={this.props.colorTotalCount}
                    offset={this.props.colorOffset}
                    page={this.props.colorPage}
                    rowCount={this.props.colorRowCount}
                    search={this.props.colorSearch}
                    searchParams={this.props.colorSearchParams}
                    sortingAsc={this.props.colorSortingAsc}
                    sortingCriterion={this.props.colorSortingCriterion}
                    setActive={this.props.setActive}
                    setOptions={this.props.setOptions}
                    setSearch={this.props.setSearch}
                />
            );
        }

        return null;
    };

    // TODO: generate input in different method
    getSceneFilterTable = (): JSX.Element | null => {
        const { tableColors } = this.state;
        const inputs = [];

        const fandeckInput = {
            labelCaption: translate("fandeck.fandeck"),
            filterClassName: "scene-select zone scene-title-select",
            filterValue: this.props.fandeckActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || "",
            filterLoading: this.props.fandeckLoading,
            filterOptions: generalHelper.getOptionsForSelectbox(objectTypes.ZONE_COLOR, this.props.fandeckList),
            filterValueCallbackClick: (id: number): void => {
                this.props.setActive(
                    objectTypes.ZONE_FANDECK_IN_COLOR,
                    this.props.fandeckList.filter((item: Fandeck) => item[propertyConstants.PROPERTY_ID] === Number(id))
                );
            },
            filterValueCallbackChange: (value: string): void => {
                this.props.setSearch(objectTypes.ZONE_FANDECK_IN_COLOR, value, propertyConstants.PROPERTY_NAME);
            }
        };

        inputs.push(fandeckInput);

        if (tableColors !== null) {
            return (
                <SceneFilterTable
                    title={translate("color.colors")}
                    tableConstant={this.props.colorTableConstant}
                    objectType={objectTypes.ZONE_COLOR}
                    dataPrivileges={tableColors}
                    loading={this.props.colorLoading}
                    allList={this.props.colorList}
                    activeList={this.props.colorActiveList}
                    columnOrder={this.props.colorColumnOrder}
                    columnVisibility={this.props.colorColumnVisibility}
                    columnWidth={this.props.colorColumnWidth}
                    offset={this.props.colorOffset}
                    page={this.props.colorPage}
                    rowCount={this.props.colorRowCount}
                    rowCountCustom={this.props.colorRowCountCustom}
                    search={this.props.colorSearch}
                    searchParams={this.props.colorSearchParams}
                    showFilterRow={this.props.colorShowFilterRow}
                    showGlobalSearch={this.props.colorShowGlobalSearch}
                    sortingAsc={this.props.colorSortingAsc}
                    sortingCriterion={this.props.colorSortingCriterion}
                    totalCount={this.props.colorTotalCount}
                    useLargoNames={false}
                    useTmcNames={this.props.optionUseTmcNames || false}
                    fandeckActiveList={this.props.fandeckActiveList}
                    inputs={inputs}
                />
            );
        }

        return null;
    };

    getSceneDetailClassName = (): string => {
        let className = "right-panel";

        if (
            this.props.fandeckActiveList.length === 0 ||
            this.props.colorActiveList.length === 0 ||
            this.props.colorLoading
        ) {
            className += " center";
        }

        return className;
    };

    getSceneDetail = (): JSX.Element => {
        let bookmarks: Array<Bookmark> = [];

        if (this.props.colorActiveList.length > 0) {
            const activeColor = this.props.colorActiveList[0];

            if (activeColor) {
                bookmarks = [
                    new Bookmark(
                        bookmarkConstants.BOOKMARK_PROPERTIES,
                        "",
                        translate("general.properties"),
                        [],
                        this.state.tableColors, // TODO own privilege
                        false,
                        this.getPropertiesBookmark(activeColor)
                    ),
                    new Bookmark(
                        bookmarkConstants.BOOKMARK_FORMULA,
                        "",
                        translate("formula.formula"),
                        [],
                        this.state.tableColors, // TODO own privilege
                        false,
                        this.getFormulaBookmark()
                    )
                ];
            }
        }

        const modalProperties: ModalProperties = {};

        return (
            <SceneDetail
                tableConstant={this.props.colorDetailTableConstant}
                objectType={objectTypes.ZONE_COLOR_DATA}
                dataPrivileges={this.state.tableColors}
                activeBookmark={this.props.activeBookmark}
                bookmarks={bookmarks}
                loading={this.props.colorLoading}
                activeList={this.props.colorActiveList}
                fandeckList={this.props.fandeckActiveList}
                modalProperties={modalProperties}
                setActiveBookmarkCallback={(key: string): void => {
                    this.props.setActiveBookmark(key);
                }}
            />
        );
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getSceneFilterTable(), this.getSceneDetail()].filter(
                        (item) => item !== null
                    ) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.colorSceneTableSize}
                dataPrivileges={this.state.tableColors}
                sceneConstant={menuConstants.PAGE_ZONE_COLORS}
            />
        );
    }
}
