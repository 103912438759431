export class StaticSqlResult {
    result: boolean;
    okList: Record<string, string>;
    errorList: Record<string, string>;

    constructor(result: boolean, okList: Record<string, string>, errorList: Record<string, string>) {
        this.result = result;
        this.okList = okList;
        this.errorList = errorList;
    }
}

export function mapStaticSqlResult(data: Record<string, any>): StaticSqlResult | null {
    try {
        return new StaticSqlResult(data.result, data.okQueries, data.errorQueries);
    } catch (e) {
        return null;
    }
}
