// price option keys
export const PRICE_OPTION_ENABLE_COMPANY_COST = "enable_company_cost";
export const PRICE_OPTION_ENABLE_PRICE_GROUPS = "enable_price_groups";
export const PRICE_OPTION_NUMBER_OF_PRICE_GROUPS = "num_price_groups";
export const PRICE_OPTION_PRICE_DEFINITION = "price_definition";
export const PRICE_OPTION_PRICE_CALCULATION = "price_calculation"; // not a real type, just to match discount, margin and markup
export const PRICE_OPTION_USE_DISCOUNT = "use_discount";
export const PRICE_OPTION_USE_MARGIN = "use_margin";
export const PRICE_OPTION_USE_MARKUP = "use_markup";

// price definition type
export const PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY = "both_purchase_primary";
export const PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY = "both_sell_primary";
export const PRICE_DEFINITION_TYPE_PURCHASE_ONLY = "purchase_only";
export const PRICE_DEFINITION_TYPE_SELL_ONLY = "sell_only";

// price generic types
export const PRICE_GENERIC_COLORANT_COST_PER_LITER = "colorantPriceCostPerLiter";
export const PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER = "colorantPricePurchasePerLiter";
export const PRICE_GENERIC_COLORANT_SELL_PER_LITER = "colorantPriceSellPerLiter";
export const PRICE_GENERIC_COLORANT_VAT = "colorantPriceVat";
export const PRICE_GENERIC_PACKAGE_SURCHARGE = "packagePriceSurcharge";
export const PRICE_GENERIC_PRODUCT_COST_PER_LITER = "productPriceCostPerLiter";
export const PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER = "productPricePurchasePerLiter";
export const PRICE_GENERIC_PRODUCT_SELL_PER_LITER = "productPriceSellPerLiter";
export const PRICE_GENERIC_PRODUCT_VAT = "productPriceVat";

export const PRICE_GENERIC_TYPES = [
    PRICE_GENERIC_COLORANT_COST_PER_LITER,
    PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER,
    PRICE_GENERIC_COLORANT_SELL_PER_LITER,
    PRICE_GENERIC_COLORANT_VAT,
    PRICE_GENERIC_PACKAGE_SURCHARGE,
    PRICE_GENERIC_PRODUCT_COST_PER_LITER,
    PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER,
    PRICE_GENERIC_PRODUCT_SELL_PER_LITER,
    PRICE_GENERIC_PRODUCT_VAT
];

// price sources
export const PRICE_SOURCE_SYSTEM_GENERIC = 1;
export const PRICE_SOURCE_SYSTEM_GENERIC_COLORANT = 2;
export const PRICE_SOURCE_SYSTEM_GENERIC_PACKAGE = 3;
export const PRICE_SOURCE_SYSTEM_GENERIC_PRODUCT = 4;
export const PRICE_SOURCE_SYSTEM_COLORANT = 5;
export const PRICE_SOURCE_SYSTEM_PACKAGE = 6;
export const PRICE_SOURCE_SYSTEM_PRODUCT = 7;
export const PRICE_SOURCE_SYSTEM_PRODUCT_BASE = 8;
export const PRICE_SOURCE_SYSTEM_PRODUCT_PACKAGE = 9;
export const PRICE_SOURCE_ZONE_GENERIC = 10;
export const PRICE_SOURCE_ZONE_GENERIC_COLORANT = 11;
export const PRICE_SOURCE_ZONE_GENERIC_PACKAGE = 12;
export const PRICE_SOURCE_ZONE_GENERIC_PRODUCT = 13;
export const PRICE_SOURCE_ZONE_COLORANT = 14;
export const PRICE_SOURCE_ZONE_PACKAGE = 15;
export const PRICE_SOURCE_ZONE_PRODUCT = 16;
export const PRICE_SOURCE_ZONE_PRODUCT_BASE = 17;
export const PRICE_SOURCE_ZONE_PRODUCT_PACKAGE = 18;

// price units
export const CURRENCY = "currency";
export const CURRENCY_PER_LITER = "currency_per_liter";
export const CURRENCY_PER_KILOGRAM = "currency_per_kilogram";

// default price object types
export const COLORANT = "colorant";
export const PRODUCT = "product";

// margin price types
export const PRICE_TYPE_MARGIN = 1;
export const PRICE_TYPE_DISCOUNT = 2;
export const PRICE_TYPE_MARKUP = 3;
export const PRICE_TYPE_PRICE_GROUP = 8;
export const PRICE_TYPE_PRICE_GROUP_BARCODE = 17;
