import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import {
    Database,
    createEmptyDatabase,
    generateDatabaseData,
    generateDatabaseHeadings,
    generateDuplicatedDatabaseData
} from "../../../../types/database";
import React, { Component } from "react";

import { Company } from "../../../../types/company";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    privileges: any;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    companyList: Array<Company>;
    allList: Array<Database>;
    activeList: Array<Database>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class DatabaseTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const { privileges } = this.props;

        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COMPANY_ID,
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_DEFAULT_DATA,
            propertyConstants.PROPERTY_MASTER_ONLY,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_NAME
        ];
        let visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD,
            propertyConstants.PROPERTY_COMPANY_ID,
            propertyConstants.PROPERTY_MORE_INFO
        ];

        if (modalType === null || modalType === modalTypes.MODAL_DATABASES_EDIT) {
            requiredList.push(propertyConstants.PROPERTY_UUID);
        }

        if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_VIEW])) {
            visibleList.splice(2, 0, propertyConstants.PROPERTY_UUID);
        }

        if (modalType === modalTypes.MODAL_DATABASES_ADD) {
            visibleList.splice(3, 0, propertyConstants.PROPERTY_FILE);
            visibleList.splice(3, 0, propertyConstants.PROPERTY_MASTER_ONLY);

            editableList.push(propertyConstants.PROPERTY_COMPANY_ID);
            editableList.push(propertyConstants.PROPERTY_FILE);
            editableList.push(propertyConstants.PROPERTY_PASSWORD);

            requiredList.push(propertyConstants.PROPERTY_FILE);
            requiredList.push(propertyConstants.PROPERTY_MASTER_ONLY);

            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_INSERT])) {
                editableList.push(propertyConstants.PROPERTY_UUID);
            }
        }

        if (modalType === modalTypes.MODAL_DATABASES_EMPTY_ADD) {
            visibleList.splice(3, 0, propertyConstants.PROPERTY_DEFAULT_DATA);

            editableList.push(propertyConstants.PROPERTY_COMPANY_ID);
            editableList.push(propertyConstants.PROPERTY_PASSWORD);

            requiredList.push(propertyConstants.PROPERTY_DEFAULT_DATA);

            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_INSERT])) {
                editableList.push(propertyConstants.PROPERTY_UUID);
            }
        }

        if (modalType === modalTypes.MODAL_DATABASES_DUPLICATE) {
            visibleList.splice(3, 0, propertyConstants.PROPERTY_MASTER_ONLY);

            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_UPDATE])) {
                editableList.push(propertyConstants.PROPERTY_UUID);
            }
        }

        if (modalType === modalTypes.MODAL_DATABASES_EDIT) {
            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_UPDATE])) {
                editableList.push(propertyConstants.PROPERTY_UUID);
            }
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            visibleList.splice(3, 0, propertyConstants.PROPERTY_LENGTH);
            visibleList.splice(3, 0, propertyConstants.PROPERTY_DATE_CREATED);
            visibleList.splice(3, 0, propertyConstants.PROPERTY_DATE_UPDATED);

            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateDatabaseHeadings(
            this.props.objectType,
            modalType,
            this.props.companyList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            this.props.objectType,
            translate("database.importDatabase"),
            createEmptyDatabase(this.props.companyList),
            this.generateTableHeadings(modalTypes.MODAL_DATABASES_ADD)
        );
    };

    generateAddEmptyDatabaseModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            this.props.objectType,
            translate("database.createNewDatabase"),
            createEmptyDatabase(this.props.companyList),
            this.generateTableHeadings(modalTypes.MODAL_DATABASES_EMPTY_ADD)
        );
    };

    generateDuplicateModalParams = (): ModalParams | null => {
        const { activeList } = this.props;

        if (activeList.length) {
            return new ModalParams(
                modalTypes.DUPLICATE_MODAL,
                this.props.objectType,
                translate("database.duplicateDatabase", {
                    name: activeList[0][propertyConstants.PROPERTY_NAME]
                }),
                generateDuplicatedDatabaseData(activeList[0]),
                this.generateTableHeadings(modalTypes.MODAL_DATABASES_DUPLICATE)
            );
        }

        return null;
    };

    generateEditModalParams = (): any => {
        const { activeList } = this.props;

        if (activeList.length) {
            return {
                title: translate("database.editDatabase"),
                type: modalTypes.EDIT_MODAL,
                objectType: this.props.objectType,
                data: activeList[0],
                headings: this.generateTableHeadings(modalTypes.MODAL_DATABASES_EDIT)
            };
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_DATABASES_ADD,
            addModalParams: this.generateAddModalParams(),
            addEmptyModalType: modalTypes.MODAL_DATABASES_EMPTY_ADD,
            addEmptyModalParams: this.generateAddEmptyDatabaseModalParams(),
            duplicateModalType: modalTypes.MODAL_DATABASES_DUPLICATE,
            duplicateModalParams: this.generateDuplicateModalParams(),
            editModalType: modalTypes.MODAL_DATABASES_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    headings={this.generateTableHeadings()}
                    data={generateDatabaseData(this.props.allList)}
                    activeData={this.props.activeList}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
