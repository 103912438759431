import * as actionConstants from "../constants/actionTypes";
import * as objectTypes from "../constants/objectTypes";

import { DataSize } from "../types/monitoring/dataSize";

export type ServerMonitoringState = Readonly<{
    dataSize: DataSize | null;
}>;

const initialState: ServerMonitoringState = {
    dataSize: null
};

export default function serverMonitoring(
    state: ServerMonitoringState = initialState,
    action: Record<string, any>
): ServerMonitoringState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.DATA_SIZE) {
                return {
                    ...state,
                    dataSize: action.payload.list.length ? action.payload.list[0] : null
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
