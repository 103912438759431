import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterFormulaNoteTable } from "./tables/MasterFormulaNoteTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterFormulaNoteSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableFormulaNotes: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class MasterFormulaNoteScene extends Component<Props, State> {
    state: State = {
        tableFormulaNotes:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.formulaNoteTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableFormulaNotes:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.formulaNoteTableConstant)
                        : null) || null
            });
        }
    }

    getFormulaNotesTable = (): JSX.Element | null => {
        const { formulaNoteTableConstant } = this.props;
        const { tableFormulaNotes } = this.state;

        if (tableFormulaNotes !== null) {
            return (
                <MasterFormulaNoteTable
                    tableConstant={formulaNoteTableConstant}
                    objectType={objectTypes.MASTER_FORMULA_NOTE}
                    dataPrivileges={tableFormulaNotes}
                    loading={this.props.formulaNoteLoading}
                    buttonLoading={this.props.formulaNoteButtonLoading}
                    allList={this.props.formulaNoteList}
                    activeList={this.props.formulaNoteActiveList}
                    columnOrder={this.props.formulaNoteColumnOrder}
                    columnVisibility={this.props.formulaNoteColumnVisibility}
                    columnWidth={this.props.formulaNoteColumnWidth}
                    offset={this.props.formulaNoteOffset}
                    page={this.props.formulaNotePage}
                    rowCount={this.props.formulaNoteRowCount}
                    rowCountCustom={this.props.formulaNoteRowCountCustom}
                    search={this.props.formulaNoteSearch}
                    searchParams={this.props.formulaNoteSearchParams}
                    showFilterRow={this.props.formulaNoteShowFilterRow}
                    showGlobalSearch={this.props.formulaNoteShowGlobalSearch}
                    sortingAsc={this.props.formulaNoteSortingAsc}
                    sortingCriterion={this.props.formulaNoteSortingCriterion}
                    totalCount={this.props.formulaNoteTotalCount}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getFormulaNotesTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.formulaNoteSceneTableSize}
                dataPrivileges={this.state.tableFormulaNotes}
                sceneConstant={menuConstants.PAGE_MASTER_FORMULA_NOTES}
            />
        );
    }
}
