import React, { Component } from "react";

type Props = {
    className: string;
    editable: boolean;
    required: boolean;
    text?: string;
    value: boolean;
    callback?: (value: boolean) => any;
};

export class ModalCheckbox extends Component<Props> {
    getClassName = (): string => {
        let newClassName = this.props.className;

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    render(): JSX.Element {
        const { editable, value, callback } = this.props;

        return (
            <div className={this.getClassName()}>
                <label className={"checkbox-label"}>
                    {this.props.text ?? ""}
                    <input
                        type={"checkbox"}
                        name={"show"}
                        className={"checkbox"}
                        checked={value}
                        readOnly={!editable}
                        disabled={!editable}
                        onChange={
                            callback
                                ? (): void => callback(!value)
                                : (): void => {
                                      return;
                                  }
                        }
                    />
                    <span className={"checkmark"}></span>
                </label>
            </div>
        );
    }
}
