import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

import { ModalIcon } from "./ModalIcon";

type Props = {
    className: string;
    objectType: objectTypes.ObjectType;
    type: string;
    value: any;
    text?: string;
};

export class SettingsReadOnlyInput extends Component<Props> {
    getReadOnlyContent = (): JSX.Element | null => {
        const { objectType, value, type, text } = this.props;

        switch (type) {
            case inputConstants.PARAMETER_TYPE_CHECK_BOX: {
                return (
                    <React.Fragment>
                        <ModalIcon
                            editable={false}
                            objectType={objectType}
                            property={propertyConstants.PROPERTY_ENABLED}
                            value={this.props.value}
                            callback={(): void => {
                                return;
                            }}
                        />
                        {text}
                    </React.Fragment>
                );
            }
            case inputConstants.PARAMETER_TYPE_SELECT_BOX:
            default:
                return <span>{value}</span>;
        }
    };

    render(): JSX.Element {
        const { className } = this.props;

        return <div className={className}>{this.getReadOnlyContent()}</div>;
    }
}
