import * as dataTypeConstants from "../constants/dataTypeConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { DataType, mapDataType } from "./duplication/dataType";

import { Currency } from "./currency";
import { TableHeading } from "./tableHeading";

export class Zone {
    id: number;
    databaseId: number | null;
    systemId: number | null;
    currencyId: number | null;
    uuid: string;
    name: string;
    password: string | null;
    version: string | null;
    versionName: string | null;
    changelog: string | null;
    moreInfo: string | null;
    staticSql: number | null;
    imageId: number | null;
    iconId: number | null;
    isDuplicated: boolean;

    constructor(
        id: number,
        databaseId: number | null,
        systemId: number | null,
        currencyId: number | null,
        uuid: string,
        name: string,
        password: string | null,
        version: string | null,
        versionName: string | null,
        changelog: string | null,
        moreInfo: string | null,
        staticSql: number | null,
        imageId: number | null,
        iconId: number | null,
        isDuplicated: boolean
    ) {
        this.id = id;
        this.databaseId = databaseId;
        this.systemId = systemId;
        this.currencyId = currencyId;
        this.uuid = uuid;
        this.name = name;
        this.password = password;
        this.version = version;
        this.versionName = versionName;
        this.changelog = changelog;
        this.moreInfo = moreInfo;
        this.staticSql = staticSql;
        this.imageId = imageId;
        this.iconId = iconId;
        this.isDuplicated = isDuplicated;
    }
}
// TODO ne natvrdo cteni - konstanta misto stringu - data.files?.["static_sql"]?.fileId
export function mapZone(data: Record<string, any>): Zone | null {
    try {
        return new Zone(
            data.id,
            data.databaseId ?? null, // TODO set active database id as default ?
            data.systemId,
            data.currencyId,
            data.uuid,
            data.name,
            data.password,
            data.version ? data.version.version : null,
            data.version ? data.version.versionName : null,
            data.version ? data.version.changelog : null,
            data.moreInfo,
            data.files?.static_sql?.fileId || null,
            data.imageImageId,
            data.iconImageId,
            true
        );
    } catch (e) {
        return null;
    }
}

export function mapZones(data: Record<string, any>): Array<Zone> {
    const zones = [];

    for (const item of Object.values(data)) {
        const zone = mapZone(item);

        if (zone !== null) {
            zones.push(zone);
        }
    }

    return zones;
}

export function updateZone(oldZone: Zone | null, updatedData: Record<string, any>): Zone | null {
    try {
        if (oldZone === null) {
            return null;
        }

        return new Zone(
            oldZone.id,
            oldZone.databaseId,
            oldZone.systemId,
            oldZone.currencyId,
            updatedData.uuid ? updatedData.uuid : oldZone.uuid,
            updatedData.name ? updatedData.name : oldZone.name,
            updatedData.password ? updatedData.password : oldZone.password,
            updatedData.version ? updatedData.version : oldZone.version,
            updatedData.versionName ? updatedData.versionName : oldZone.versionName,
            updatedData.changelog ? updatedData.changelog : oldZone.changelog,
            updatedData.moreInfo ? updatedData.moreInfo : oldZone.moreInfo,
            oldZone.staticSql,
            oldZone.imageId,
            oldZone.iconId,
            updatedData.isDuplicated ? updatedData.isDuplicated : oldZone.isDuplicated
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyZone(systemId: number | null, databaseId?: number | null): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_DATABASE_ID]: databaseId ?? null,
        [propertyConstants.PROPERTY_SYSTEM_ID]: systemId,
        [propertyConstants.PROPERTY_CURRENCY_ID]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PASSWORD]: "",
        [propertyConstants.PROPERTY_VERSION]: "",
        [propertyConstants.PROPERTY_VERSION_NAME]: "",
        [propertyConstants.PROPERTY_CHANGELOG]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_STATIC_SQL]: null,
        [propertyConstants.PROPERTY_IMAGE_ID]: null,
        [propertyConstants.PROPERTY_ICON_ID]: null
    };
}

export function generateZoneData(
    zoneList: Array<Zone>,
    objectType: objectTypes.ObjectType,
    isUuidEmpty: boolean = false
): Array<any> {
    const data: Array<any> = [];
    const dataTypesList = [];
    let dataType: DataType | null = null;
    let zone: Zone;

    for (const dataTypeId of dataTypeConstants.DATA_TYPE_LIST) {
        dataType = mapDataType(dataTypeId, objectType);

        if (dataType) {
            dataTypesList.push(dataType);
        }
    }

    for (zone of zoneList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: zone.id,
            [propertyConstants.PROPERTY_DATABASE_ID]: zone.databaseId,
            [propertyConstants.PROPERTY_SYSTEM_ID]: zone.systemId,
            [propertyConstants.PROPERTY_CURRENCY_ID]: zone.currencyId,
            [propertyConstants.PROPERTY_UUID]: !isUuidEmpty ? zone.uuid : "",
            [propertyConstants.PROPERTY_NAME]: zone.name,
            [propertyConstants.PROPERTY_PASSWORD]: zone.password,
            [propertyConstants.PROPERTY_PASSWORD_HASH]: null,
            [propertyConstants.PROPERTY_VERSION]: zone.version,
            [propertyConstants.PROPERTY_VERSION_NAME]: zone.versionName,
            [propertyConstants.PROPERTY_CHANGELOG]: zone.changelog,
            [propertyConstants.PROPERTY_MORE_INFO]: zone.moreInfo,
            [propertyConstants.PROPERTY_STATIC_SQL]: zone.staticSql,
            [propertyConstants.PROPERTY_IMAGE_ID]: zone.imageId,
            [propertyConstants.PROPERTY_ICON_ID]: zone.iconId,
            [propertyConstants.PROPERTY_IS_DUPLICATED]: zone.isDuplicated,
            [propertyConstants.PROPERTY_DATA_TYPE_LIST]: dataTypesList
        });
    }

    return data;
}

export function generateZoneHeadings(
    objectType: objectTypes.ObjectType,
    currencyList: Array<Currency>,
    staticSqlList: Array<any>,
    dataTypeList: Array<DataType>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number> | null = null
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CHANGELOG,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            editableList.includes(propertyConstants.PROPERTY_CHANGELOG),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CHANGELOG),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CHANGELOG),
            requiredList.includes(propertyConstants.PROPERTY_CHANGELOG),
            [],
            widthOption?.[propertyConstants.PROPERTY_CHANGELOG] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CURRENCY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CURRENCY_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_CURRENCY_ID),
            editableList.includes(propertyConstants.PROPERTY_CURRENCY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CURRENCY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CURRENCY_ID),
            requiredList.includes(propertyConstants.PROPERTY_CURRENCY_ID),
            currencyList,
            widthOption?.[propertyConstants.PROPERTY_CURRENCY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATA_TYPE_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.TABLE_TYPE_LIST,
            visibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            editableList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            requiredList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            dataTypeList,
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ICON_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ICON_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_ICON_ID),
            editableList.includes(propertyConstants.PROPERTY_ICON_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ICON_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ICON_ID),
            requiredList.includes(propertyConstants.PROPERTY_ICON_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ICON_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_DUPLICATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_DUPLICATED),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            editableList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            requiredList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_DUPLICATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_PASSWORD),
            [],
            widthOption?.[propertyConstants.PROPERTY_PASSWORD] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PASSWORD_HASH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PASSWORD_HASH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PASSWORD_HASH),
            editableList.includes(propertyConstants.PROPERTY_PASSWORD_HASH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PASSWORD_HASH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PASSWORD_HASH),
            requiredList.includes(propertyConstants.PROPERTY_PASSWORD_HASH),
            [],
            widthOption?.[propertyConstants.PROPERTY_PASSWORD_HASH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            tableConstants.TABLE_TYPE_BUTTON_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            editableList.includes(propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            requiredList.includes(propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES),
            [],
            widthOption?.[propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_STATIC_SQL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_STATIC_SQL),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_STATIC_SQL),
            editableList.includes(propertyConstants.PROPERTY_STATIC_SQL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_STATIC_SQL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_STATIC_SQL),
            requiredList.includes(propertyConstants.PROPERTY_STATIC_SQL),
            staticSqlList,
            widthOption?.[propertyConstants.PROPERTY_STATIC_SQL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION),
            editableList.includes(propertyConstants.PROPERTY_VERSION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION),
            requiredList.includes(propertyConstants.PROPERTY_VERSION),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VERSION_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            editableList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VERSION_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            requiredList.includes(propertyConstants.PROPERTY_VERSION_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_VERSION_NAME] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
