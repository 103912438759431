import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { createEmptyExportTask, generateExportTaskHeadings } from "../../../types/exportTask";

import { DataItem } from "../../../types/dataItem";
import { ExportTaskTable } from "./tables/ExportTaskTable";
import { MenuItem } from "../../../types/menu";
import { ModalParams } from "../../../types/modalParams";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/data/ExportSceneContainer";
import { TableAddButtonContainer } from "../../general/table/buttons/TableAddButton";
import { TableHeading } from "../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = PropsType;

type State = {
    tableExportData: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class ExportScene extends Component<Props, State> {
    state: State = {
        tableExportData:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.exportDataConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableExportData:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.exportDataConstant)
                        : null) || null
            });
        }
    }

    generateHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID,
            propertyConstants.PROPERTY_SYSTEM_ID,
            propertyConstants.PROPERTY_ZONE_ID,
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME,
            propertyConstants.PROPERTY_CHANGELOG
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID,
            propertyConstants.PROPERTY_SYSTEM_ID,
            propertyConstants.PROPERTY_ZONE_ID,
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [];

        return generateExportTaskHeadings(
            objectTypes.EXPORT_TASK,
            this.props.exportStatusList,
            this.props.exportTypeList,
            this.props.systemZoneAllList,
            propertyList,
            propertyList,
            propertyList,
            requiredList,
            alwaysVisibleList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    getNewExportButton = (): JSX.Element => {
        const modalParams = new ModalParams(
            modalTypes.ADD_MODAL,
            objectTypes.EXPORT_TASK,
            translate("export.exportData"),
            createEmptyExportTask(
                this.props.exportTypeList,
                this.props.systemZoneAllList,
                this.props.activeDatabase,
                this.props.activeSystem,
                this.props.activeZone
            ),
            this.generateHeadings()
        );

        return (
            <TableAddButtonContainer
                dataPrivileges={this.state.tableExportData}
                objectType={objectTypes.EXPORT_TASK}
                modalType={modalTypes.MODAL_DATA_EXPORT}
                modalParams={modalParams}
                disabled={this.props.exportLoading}
            />
        );
    };

    getNewExportAllButton = (): JSX.Element => {
        const modalParams = new ModalParams(modalTypes.ADD_MODAL, objectTypes.SYSTEM_ZONE, "", [], []);

        return (
            <TableAddButtonContainer
                dataPrivileges={this.state.tableExportData}
                objectType={objectTypes.SYSTEM_ZONE}
                modalType={modalTypes.MODAL_DATA_EXPORT_ALL}
                modalParams={modalParams}
                disabled={this.props.exportLoading}
            />
        );
    };

    getExportDataTable = (): JSX.Element | null => {
        const { exportDataConstant } = this.props;
        const { tableExportData } = this.state;

        if (tableExportData) {
            return (
                <div className="layout-item item-scene-row export-table check-table" key={exportDataConstant}>
                    <ExportTaskTable
                        tableConstant={exportDataConstant}
                        objectType={objectTypes.EXPORT_TASK}
                        dataPrivileges={tableExportData}
                        privileges={this.props.privileges}
                        loading={this.props.exportLoading}
                        databaseSystemZoneList={this.props.databaseSystemZoneList}
                        exportStatusList={this.props.exportStatusList}
                        exportTypeList={this.props.exportTypeList}
                        allList={this.props.exportList}
                        activeList={this.props.exportActiveList}
                        columnOrder={this.props.exportColumnOrder}
                        columnVisibility={this.props.exportColumnVisibility}
                        columnWidth={this.props.exportColumnWidth}
                        offset={this.props.exportOffset}
                        page={this.props.exportPage}
                        rowCount={this.props.exportRowCount}
                        rowCountCustom={this.props.exportRowCountCustom}
                        search={this.props.exportSearch}
                        searchParams={this.props.exportSearchParams}
                        showDeletedValues={this.props.exportShowDeletedValues}
                        showFilterRow={this.props.exportShowFilterRow}
                        showGlobalSearch={this.props.exportShowGlobalSearch}
                        showHiddenValues={this.props.exportShowHiddenValues}
                        sortingAsc={this.props.exportSortingAsc}
                        sortingCriterion={this.props.exportSortingCriterion}
                        totalCount={this.props.exportTotalCount}
                    />
                </div>
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <div className="content-layout">
                <div className="layout-item">
                    <div className="height-100">
                        <div className="main-title">{translate("export.exportData")}</div>
                        <div className="scene-btn-row">
                            {this.getNewExportButton()}
                            {this.getNewExportAllButton()}
                        </div>
                        {this.getExportDataTable()}
                    </div>
                </div>
            </div>
        );
    }
}
