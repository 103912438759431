import * as actionConstants from "../constants/actionTypes";
import * as licenseConstants from "../constants/license";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { License, updateLicense } from "../types/licensing/license";
import { LicenseProduct } from "../types/licensing/licenseProduct";

/* const initialState = {
    errorCode: null,
};*/

export type LicenseState = Readonly<{
    licensed: boolean;
    activeLicense: License | null;
    productList: Array<LicenseProduct>;
    processStep: number;
    onlineActivation: boolean;
}>;

const initialState: LicenseState = {
    licensed: false,
    activeLicense: null,
    productList: [],
    processStep: 0,
    onlineActivation: false
};

export default function license(state: LicenseState = initialState, action: Record<string, any>): LicenseState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.LICENSE:
                    if (action.payload.list.length) {
                        return {
                            ...state,
                            licensed:
                                action.payload.list[0][propertyConstants.PROPERTY_STATUS] ===
                                licenseConstants.STATUS_ERROR
                                    ? false
                                    : true,
                            processStep: 0,
                            activeLicense: action.payload.list[0]
                        };
                    }
                    return state;
                case objectTypes.LICENSE_PRODUCT:
                    return {
                        ...state,
                        productList: action.payload.list
                    };

                default:
                    return state;
            }
        }
        case actionConstants.LICENSE_UPDATE: {
            return {
                ...state,
                activeLicense: updateLicense(state.activeLicense, action.payload.params)
            };
        }
        case actionConstants.LICENSE_SET_ONLINE_ACTIVATION: {
            return {
                ...state,
                onlineActivation: action.payload.onlineActivation
            };
        }
        case actionConstants.LICENSE_SET_PROCESS_STEP: {
            return {
                ...state,
                processStep: action.payload.step
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
