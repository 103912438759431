import * as formattingHelper from "../helpers/formattingHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { FileCategory } from "./fileCategory";
import { FileType } from "./fileType";
import { TableHeading } from "./tableHeading";

export class File {
    id: number;
    fileTypeId: number;
    fileCategoryId: number | null;
    name: string;
    path: string;
    mimeType: string;
    uploadedDate: Date | null;
    dataLen: string | null;
    moreInfo: string | null;
    file: any | null;
    url: string | null;

    constructor(
        id: number,
        fileTypeId: number,
        fileCategoryId: number | null,
        name: string,
        path: string,
        mimeType: string,
        uploadedDate: Date | null,
        dataLen: string | null,
        moreInfo: string | null,
        file: any | null,
        url: string | null
    ) {
        this.id = id;
        this.fileTypeId = fileTypeId;
        this.fileCategoryId = fileCategoryId;
        this.name = name;
        this.path = path;
        this.mimeType = mimeType;
        this.uploadedDate = uploadedDate;
        this.dataLen = dataLen;
        this.moreInfo = moreInfo;
        this.file = file;
        this.url = url;
    }
}

export function mapFile(data: Record<string, any>): File | null {
    // TODO format size az pri zobrazovani a ne dopredu?
    try {
        return new File(
            data.id,
            data.fileTypeId,
            data.fileCategoryId,
            data.name,
            data.path,
            data.mimeType,
            data.uploadedDate,
            formattingHelper.formatFileSize(data.dataLen),
            data.moreInfo,
            null,
            data.url
        );
    } catch (e) {
        return null;
    }
}

export function mapFiles(data: Array<any>): Array<File> {
    const files = [];

    for (const item in data) {
        let file = null;

        if (data[item].file) {
            file = mapFile(data[item].file);
        }

        if (file) {
            files.push(file);
        }
    }

    return files;
}

export function updateFile(oldFile: File | null, updatedData: Record<string, any>): File | null {
    try {
        if (oldFile === null) {
            return null;
        }

        return new File(
            oldFile.id,
            updatedData.fileTypeId ? updatedData.fileTypeId : oldFile.fileTypeId,
            updatedData.fileCategoryId ? updatedData.fileCategoryId : oldFile.fileCategoryId,
            updatedData.name ? updatedData.name : oldFile.name,
            oldFile.path,
            oldFile.mimeType,
            oldFile.uploadedDate,
            formattingHelper.formatFileSize(Number(oldFile.dataLen)),
            updatedData.moreInfo ? updatedData.moreInfo : oldFile.moreInfo,
            null,
            oldFile.url
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyFile(fileTypeList: Array<FileType>): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: fileTypeList.length
            ? fileTypeList[0][propertyConstants.PROPERTY_ID]
            : "",
        [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PATH]: "",
        [propertyConstants.PROPERTY_MIME_TYPE]: "",
        [propertyConstants.PROPERTY_DATE_UPLOADED]: "",
        [propertyConstants.PROPERTY_LENGTH]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_FILE]: null,
        [propertyConstants.PROPERTY_URL]: ""
    };
}

export function generateFileData(fileList: Array<File>): Array<any> {
    const data: Array<any> = [];

    for (const item of fileList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_ACTION]: item.id,
            [propertyConstants.PROPERTY_FILE_TYPE_ID]: item.fileTypeId,
            [propertyConstants.PROPERTY_FILE_CATEGORY_ID]: item.fileCategoryId,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_PATH]: item.path,
            [propertyConstants.PROPERTY_MIME_TYPE]: item.mimeType,
            [propertyConstants.PROPERTY_DATE_UPLOADED]: item.uploadedDate,
            [propertyConstants.PROPERTY_LENGTH]: item.dataLen,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_FILE]: item.file,
            [propertyConstants.PROPERTY_URL]: item.url
        });
    }

    return data;
}

export function generateFileHeadings(
    objectType: objectTypes.ObjectType,
    fileTypeList: Array<FileType>,
    categoryList: Array<FileCategory>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ACTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION),
            tableConstants.TABLE_TYPE_BUTTON,
            visibleList.includes(propertyConstants.PROPERTY_ACTION),
            editableList.includes(propertyConstants.PROPERTY_ACTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION),
            requiredList.includes(propertyConstants.PROPERTY_ACTION),
            [],
            widthOption?.[propertyConstants.PROPERTY_ACTION] ?? null,
            "action table-button"
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_UPLOADED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_UPLOADED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_UPLOADED),
            editableList.includes(propertyConstants.PROPERTY_DATE_UPLOADED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_UPLOADED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_UPLOADED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_UPLOADED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_UPLOADED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE),
            tableConstants.TABLE_TYPE_FILE,
            visibleList.includes(propertyConstants.PROPERTY_FILE),
            editableList.includes(propertyConstants.PROPERTY_FILE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE),
            requiredList.includes(propertyConstants.PROPERTY_FILE),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILE_CATEGORY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            editableList.includes(propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            requiredList.includes(propertyConstants.PROPERTY_FILE_CATEGORY_ID),
            categoryList,
            widthOption?.[propertyConstants.PROPERTY_FILE_CATEGORY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILE_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE_TYPE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            fileTypeList,
            widthOption?.[propertyConstants.PROPERTY_FILE_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LENGTH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LENGTH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LENGTH),
            editableList.includes(propertyConstants.PROPERTY_LENGTH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LENGTH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LENGTH),
            requiredList.includes(propertyConstants.PROPERTY_LENGTH),
            [],
            widthOption?.[propertyConstants.PROPERTY_LENGTH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PATH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PATH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PATH),
            editableList.includes(propertyConstants.PROPERTY_PATH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PATH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PATH),
            requiredList.includes(propertyConstants.PROPERTY_PATH),
            [],
            widthOption?.[propertyConstants.PROPERTY_PATH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_URL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_URL),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_URL),
            editableList.includes(propertyConstants.PROPERTY_URL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_URL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_URL),
            requiredList.includes(propertyConstants.PROPERTY_URL),
            [],
            widthOption?.[propertyConstants.PROPERTY_URL] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
