import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Price, mapPrice } from "./price";
import { TableHeading } from "./tableHeading";
import { Unit } from "./unit";

export class Package {
    id: number;
    unitId: number;
    name: string;
    tmcName: string;
    nominalAmount: number;
    isGravimetric: boolean;
    moreInfo: string;
    // eslint-disable-next-line camelcase
    price_surcharge: Price | null;

    constructor(
        id: number,
        unitId: number,
        name: string,
        tmcName: string,
        nominalAmount: number,
        isGravimetric: false,
        moreInfo: string,
        // eslint-disable-next-line camelcase
        price_surcharge: Price | null
    ) {
        this.id = id;
        this.unitId = unitId;
        this.name = name;
        this.tmcName = tmcName;
        this.nominalAmount = nominalAmount;
        this.isGravimetric = isGravimetric;
        this.moreInfo = moreInfo;
        // eslint-disable-next-line camelcase
        this.price_surcharge = price_surcharge;
    }
}

export function mapPackage(data: Record<string, any>): Package | null {
    try {
        return new Package(
            data.id,
            data.unitId,
            data.name,
            data.tmcName,
            data.nominalAmount,
            data.isGravimetric,
            data.moreInfo,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_SURCHARGE]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SURCHARGE,
                      data.prices[propertyConstants.PROPERTY_PRICE_SURCHARGE]
                  )
                : null
        );
    } catch (e) {
        return null;
    }
}

export function updatePackage(oldPackage: Package | null, updatedData: Record<string, any>): Package | null {
    try {
        if (oldPackage === null) {
            return null;
        }

        return new Package(
            oldPackage.id,
            updatedData.unitId ? updatedData.unitId : oldPackage.unitId,
            updatedData.name ? updatedData.name : oldPackage.name,
            updatedData.tmcName ? updatedData.tmcName : oldPackage.tmcName,
            updatedData.nominalAmount ? updatedData.nominalAmount : oldPackage.nominalAmount,
            updatedData.isGravimetric ? updatedData.isGravimetric : oldPackage.isGravimetric,
            updatedData.moreInfo ? updatedData.moreInfo : oldPackage.moreInfo,
            updatedData.price_surcharge ? updatedData.price_surcharge : oldPackage.price_surcharge
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyPackage(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_UNIT_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: "",
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_PRICE_SURCHARGE]: ""
    };
}

export function generatePackageData(packageList: Array<Package>): Array<any> {
    const data: Array<any> = [];
    let item: Package;

    for (item of packageList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_UNIT_ID]: item.unitId,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: item.nominalAmount,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: item.isGravimetric,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_PRICE_SURCHARGE]: item.price_surcharge
        });
    }
    return data;
}

export function generatePackageHeadings(
    objectType: objectTypes.ObjectType,
    unitList: Array<Unit>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_GRAVIMETRIC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            editableList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            requiredList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_GRAVIMETRIC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NOMINAL_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            [],
            widthOption?.[propertyConstants.PROPERTY_NOMINAL_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SURCHARGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SURCHARGE),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SURCHARGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SURCHARGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_TMC_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            editableList.includes(propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_TMC_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            requiredList.includes(propertyConstants.PROPERTY_TMC_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_TMC_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UNIT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UNIT_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_UNIT_ID),
            editableList.includes(propertyConstants.PROPERTY_UNIT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UNIT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UNIT_ID),
            requiredList.includes(propertyConstants.PROPERTY_UNIT_ID),
            unitList,
            widthOption?.[propertyConstants.PROPERTY_UNIT_ID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
