// connection type
export type ConnectionType = typeof CONNECTION_REST | typeof CONNECTION_WEBSOCKET;
export const CONNECTION_REST = "rest";
export const CONNECTION_WEBSOCKET = "websocket";

// data source
export const DEFAULT_DATA_SOURCE = 0;
export const MASTER_DATA_SOURCE = 1;
export const SYSTEM_DATA_SOURCE = 2;
export const ZONE_DATA_SOURCE = 3;

// data types for Redlike options
export type DataType =
    | typeof DATA_TYPE_BOOLEAN
    | typeof DATA_TYPE_DICTIONARY
    | typeof DATA_TYPE_INT
    | typeof DATA_TYPE_LIST
    | typeof DATA_TYPE_STRING
    | typeof DATA_TYPE_UNKNOWN;

export const DATA_TYPE_BOOLEAN = "bool";
export const DATA_TYPE_DICTIONARY = "dict";
export const DATA_TYPE_FLOAT = "float";
export const DATA_TYPE_INFO_TYPES_LIST = "info_types_list";
export const DATA_TYPE_INT = "int";
export const DATA_TYPE_LIST = "list";
export const DATA_TYPE_PRICE_TYPES_LIST = "price_types_list";
export const DATA_TYPE_PRINT_TASKS_LIST = "print_tasks_list";
export const DATA_TYPE_STRING = "str";
export const DATA_TYPE_UNKNOWN = "uknown";

export const DATA_TYPES_LIST = [
    DATA_TYPE_INFO_TYPES_LIST,
    DATA_TYPE_LIST,
    DATA_TYPE_PRICE_TYPES_LIST,
    DATA_TYPE_PRINT_TASKS_LIST
];

// database default data
export type DatabaseDefaultData = typeof DATABASE_DEFAULT_EMPTY | typeof DATABASE_DEFAULT_WITH_SYSTEM_ZONE_CURRENCY;

export const DATABASE_DEFAULT_EMPTY = "empty";
export const DATABASE_DEFAULT_WITH_SYSTEM_ZONE_CURRENCY = "system_zone_currency";

// default
export const SERVER_SEARCH_TIMEOUT = 500;
export const SERVER_SCRIPT_RELOAD_TIMEOUT = 10000;
export const SERVER_WEBSOCKET_TIMEOUT = 2000;
export const DEFAULT_FORMULA_BASE_AMOUNT = 1000;

// formula flags
export type FormulaFlag =
    | typeof FORMULA_FLAG_NO_FORMULA
    | typeof FORMULA_FLAG_DISABLED
    | typeof FORMULA_FLAG_ENABLED
    | typeof FORMULA_FLAG_VERIFIED
    | typeof FORMULA_FLAG_REJECTED;

export const FORMULA_FLAG_NO_FORMULA = "no_formula";
export const FORMULA_FLAG_DISABLED = "disabled";
export const FORMULA_FLAG_ENABLED = "enabled";
export const FORMULA_FLAG_VERIFIED = "verified";
export const FORMULA_FLAG_REJECTED = "rejected";

// formula type
export type FormulaTypeId =
    | typeof FORMULA_TYPE_ID_DISABLED
    | typeof FORMULA_TYPE_ID_ENABLED
    | typeof FORMULA_TYPE_ID_VERIFIED
    | typeof FORMULA_TYPE_ID_REJECTED
    | typeof FORMULA_TYPE_ID_FOR_NOMINAL_AMOUNT
    | typeof FORMULA_TYPE_ID_NOT_AVAILABLE;

export const FORMULA_TYPE_ID_DISABLED = 0;
export const FORMULA_TYPE_ID_ENABLED = 1;
export const FORMULA_TYPE_ID_VERIFIED = 2;
export const FORMULA_TYPE_ID_REJECTED = 4;
export const FORMULA_TYPE_ID_FOR_NOMINAL_AMOUNT = 8;
export const FORMULA_TYPE_ID_NOT_AVAILABLE = 16;

// forced formula base amount type
export const FORCED_FORMULA_BASE_AMOUNT_TYPE_DEFAULT = 0;
export const FORCED_FORMULA_BASE_AMOUNT_TYPE_VOLUMETRIC = 1;
export const FORCED_FORMULA_BASE_AMOUNT_TYPE_GRAVIMETRIC = 2;
export const FORCED_FORMULA_BASE_AMOUNT_TYPE_USE_AS_IT_IS = 3;

// results
export const RESULT_OK = 0;
export const RESULT_INVALID_JSON_INPUT = 1;
export const RESULT_COMMAND_TYPE_MISSING = 2;
export const RESULT_PARAMETER_VALIDATION_FAILED = 3;
export const RESULT_UNKNOWN_COMMAND = 4;
export const RESULT_BASE64_INPUT = 5;
export const RESULT_CLIENT_CONNECTION_REJECTED = 10;
export const RESULT_CLIENT_NOT_LOGGED_IN = 11;
export const RESULT_CLIENT_SESSION_NOT_INITIALIZED = 12;
export const RESULT_INVALID_LICENSE = 20;
export const RESULT_INVALID_LICENSE_IDENTIFICATION_KEYS = 21;
export const RESULT_INVALID_LICENSE_WEB_API_ERROR = 22;
export const RESULT_DATABASE_CONFIG_FILE_MISSING = 30;
export const RESULT_LOGIN_FAILED = 100;
export const RESULT_LOGOUT_FAILED = 101;
export const RESULT_USER_ALREADY_LOGGED_IN = 102;
export const RESULT_DUPLICATE_ENTRY = 103;
export const RESULT_INVALID_EMAIL = 104;
export const RESULT_ITEM_NOT_FOUND = 105;
export const RESULT_UNSUPPORTED_TYPE = 106;
export const RESULT_USER_CHANGE_PASSWORD_FAILED = 107;
export const RESULT_DATABASE_AUTOLOGIN_FAILED = 108;
export const RESULT_BARCODE_NOT_FOUND = 109;
export const RESULT_INVALID_DATABASE_NAME = 110;
export const RESULT_REDLIKE_DATA_NOT_AVAILABLE = 111;
export const RESULT_DATABASE_NOT_COMPATIBLE = 112;
export const RESULT_DATABASE_ERROR = 113;
export const RESULT_SERVER_ALREADY_DEFINED = 120;
export const RESULT_CANNOT_CONNECT_TO_SERVER = 121;
export const RESULT_INVALID_SERVER = 122;
export const RESULT_SHORT_USER_PASSWORD = 125;
export const RESULT_INVALID_USER_PASSWORD = 126;
export const RESULT_DUPLICATE_BARCODE = 127;
export const RESULT_ITEM_NOT_EDITABLE = 130;
export const RESULT_INVALID_ITEM = 131;
export const RESULT_INVALID_VALUE = 132;
export const RESULT_FORMULA_NOT_FOUND = 133;
export const RESULT_MALFORMED_FORMULA = 134;
export const RESULT_BIPP_NOT_FOUND = 135;
export const RESULT_CUSTOMER_NOT_FOUND = 136;
export const RESULT_INVALID_NAME = 137;
export const RESULT_ITEM_NOT_DELETABLE = 138;
export const RESULT_ORDER_HAS_ALREADY_BEEN_ACQUIRED = 140;
export const RESULT_ITEM_ALREADY_EXISTS = 141;
export const RESULT_FILE_NOT_FOUND = 150;
export const RESULT_DIRECTORY_NOT_FOUND = 151;
export const RESULT_INVALID_METHOD = 152;
export const RESULT_INVALID_FILE_ID = 153;
export const RESULT_INVALID_IMAGE = 154;
export const RESULT_IMPORT_GENERIC = 200;
export const RESULT_IMPORT_FILE_MISSING = 201;
export const RESULT_IMPORT_DATA_MISSING = 202;
export const RESULT_IMPORT_UNKNOWN_FORMAT = 203;
export const RESULT_IMPORT_ABORTED = 204;
export const RESULT_IMPORT_UNDER_PROGRESS = 205;
export const RESULT_IMPORT_HEADER_MISSING = 206;
export const RESULT_DATABASE_NOT_FOUND = 500;
export const RESULT_ERROR_ON_OPENING_DATABASE = 501;
export const RESULT_UNKNOWN_OPTION = 502;
export const RESULT_NO_DATABASE_SELECTED = 503;
export const RESULT_NO_SYSTEM_SELECTED = 504;
export const RESULT_NO_ZONE_SELECTED = 505;
export const RESULT_INVALID_FILE_UPLOAD_UUID = 510;
export const RESULT_IMPORT_TASK_MANDATORY_FILE = 520;
export const RESULT_IMPORT_TASK_MANDATORY_OPTION = 521;
export const RESULT_UNKNOWN_ERROR = 999;
