import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { GenericPackageScene } from "../../../components/scenes/generic/GenericPackageScene";
import { MenuItem } from "../../../types/menu";
import { Package } from "../../../types/package";
import { ServerRequest } from "../../../types/serverRequest";
import { Unit } from "../../../types/unit";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    unitList: Array<Unit>;
    packageTableConstant: string;
    packageList: Array<Package>;
    packageActiveList: Array<Package>;
    packageLoading: boolean;
    packageButtonLoading: boolean;
    packageColumnOrder: Array<propertyConstants.Property>;
    packageColumnVisibility: Record<propertyConstants.Property, boolean>;
    packageColumnWidth: Record<propertyConstants.Property, number>;
    packageOffset: number;
    packagePage: number;
    packageRowCount: number;
    packageRowCountCustom: boolean;
    packageSceneTableSize: Record<string, any> | null;
    packageSearch: string | null;
    packageSearchParams: Record<propertyConstants.Property, string>;
    packageShowFilterRow: boolean;
    packageShowGlobalSearch: boolean;
    packageSortingAsc: boolean;
    packageSortingCriterion: propertyConstants.Property | null;
    packageTotalCount: number | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_PACKAGES
    ),
    unitList: state.unit.allList,
    packageTableConstant: menuConstants.TABLE_GENERIC_PACKAGES,
    packageList: state.package.list,
    packageActiveList: state.package.activeList,
    packageLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_PACKAGES || request.method === methods.METHOD_DELETE_PACKAGE
    ),
    packageButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_PACKAGE
    ),
    packageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageOffset: state.package.offset,
    packagePage: state.package.page,
    packageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_PACKAGES
    ),
    packageSearch: state.package.search,
    packageSearchParams: state.package.searchParams,
    packageShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_PACKAGES
    ),
    packageSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_PACKAGES
        )
    ),
    packageSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_PACKAGES
        )
    ),
    packageTotalCount: state.package.count,
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_PACKAGES
    )
});

export const GenericPackageSceneContainer = connect(mapStateToProps)(GenericPackageScene);
