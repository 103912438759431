export class ExportTypeOptions {
    changelog: boolean;
    colorsWithoutFormulas: boolean;
    zonePassword: boolean;
    systemId: boolean;
    version: boolean;
    versionName: boolean;
    zoneId: boolean;

    constructor(
        changelog: boolean,
        colorsWithoutFormulas: boolean,
        zonePassword: boolean,
        systemId: boolean,
        version: boolean,
        versionName: boolean,
        zoneId: boolean
    ) {
        this.changelog = changelog;
        this.colorsWithoutFormulas = colorsWithoutFormulas;
        this.zonePassword = zonePassword;
        this.systemId = systemId;
        this.version = version;
        this.versionName = versionName;
        this.zoneId = zoneId;
    }
}

export function mapExportTypeOptions(data: Record<string, boolean>): ExportTypeOptions {
    return new ExportTypeOptions(
        data.changelog ?? false,
        data.colors_without_formulas ?? false,
        data.password ?? false,
        data.system ?? false,
        data.version ?? false,
        data.version_name ?? false,
        data.zone ?? false
    );
}
