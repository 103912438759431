import * as formulaConstants from "../constants/entityConstants/formulaConstants";
import * as menuConstants from "../constants/menuConstants";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverConstants from "../constants/serverConstants";
import * as validationHelper from "./validationHelper";

import React from "react";
import { ReactSVG } from "react-svg";

import imgAccepted from "../resources/img/icons/formulaAccepted.svg";
import imgCancel from "../resources/img/icons/formulaRejected.svg";
import imgError from "../resources/img/icons/error-circle.svg";
import imgHistorical from "../resources/img/icons/history.svg";
import imgNotAvailable from "../resources/img/icons/not-available.svg";
import imgSuccess from "../resources/img/icons/success.svg";

import { t as translate } from "react-i18nify";

export const getWavelengthList = (
    interval: number,
    beginValue: number = optionsConstants.DEFAULT_WAVELENGTH_BEGIN_VALUE,
    endValue: number = optionsConstants.DEFAULT_WAVELENGTH_END_VALUE
): Array<any> => {
    const defaultValueList = [];

    for (let item = beginValue; item <= endValue; item = interval + item) {
        defaultValueList.push(item);
    }

    return defaultValueList;
};

export const getReflectanceDataValidation = (
    objectType: objectTypes.ObjectType,
    object: Record<string, any>
): boolean => {
    if (objectType === objectTypes.MASTER_COLOR) {
        for (const item of object?.[propertyConstants.PROPERTY_DATA] || []) {
            if (!validationHelper.areReflectanceDataValid(item?.[propertyConstants.PROPERTY_REFLECTANCE_DATA] || [])) {
                return true;
            }
        }
    }

    return false;
};

export const calculateLchFromLab = (a: number | null, b: number | null): Array<number> => {
    if (a !== null && b !== null) {
        const c = Math.sqrt(a * a + b * b);
        const radians = Math.atan2(b, a);
        let h = radians * (180 / Math.PI);

        if (h < 0) {
            h += 360;
        }

        return [c, h];
    }

    return [];
};

export const getFormulaStatusName = (status: formulaConstants.FormulaStatus, name: boolean): string | null => {
    if (name) {
        switch (status) {
            case formulaConstants.FORMULA_STATUS_CANCELED:
                return translate("general.canceled");
            case formulaConstants.FORMULA_STATUS_CANCELED_REJECTED:
                return translate("general.rejected");
            case formulaConstants.FORMULA_STATUS_ACCEPTED:
                return translate("general.accepted");
            case formulaConstants.FORMULA_STATUS_HISTORICAL:
                return translate("general.historical");
            case formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED:
            case formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED:
                return translate("general.verified");
            case formulaConstants.FORMULA_STATUS_NOT_AVAILABLE:
                return translate("general.notAvailable");
            default:
                break;
        }
    }

    return null;
};

export const getFormulaStatus = (
    status: formulaConstants.FormulaStatus,
    name: boolean,
    multiState: boolean
): JSX.Element => {
    if (status === formulaConstants.FORMULA_STATUS_CANCELED) {
        return (
            <React.Fragment>
                <ReactSVG src={imgCancel} className="svg-icon" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_CANCELED, name)}{" "}
            </React.Fragment>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_CANCELED_REJECTED) {
        if (multiState) {
            return (
                <div className="multiple-icons">
                    <ReactSVG src={imgCancel} className="svg-icon" />{" "}
                    {getFormulaStatusName(formulaConstants.FORMULA_STATUS_CANCELED, name)}{" "}
                    <ReactSVG src={imgError} className="svg-icon error" />{" "}
                    {getFormulaStatusName(formulaConstants.FORMULA_STATUS_CANCELED_REJECTED, name)}{" "}
                </div>
            );
        }
        return (
            <React.Fragment>
                <ReactSVG src={imgError} className="svg-icon error" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_CANCELED_REJECTED, name)}{" "}
            </React.Fragment>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_ACCEPTED) {
        // ma v sebe farby ikony
        return (
            <React.Fragment>
                <ReactSVG src={imgAccepted} className="svg-icon" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_ACCEPTED, name)}{" "}
            </React.Fragment>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED) {
        return (
            <div className="multiple-icons">
                <ReactSVG src={imgHistorical} className="svg-icon historical" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_HISTORICAL, name)}{" "}
                <ReactSVG src={imgSuccess} className="svg-icon success" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED, name)}{" "}
            </div>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_HISTORICAL) {
        return (
            <React.Fragment>
                <ReactSVG src={imgHistorical} className="svg-icon historical" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_HISTORICAL, name)}{" "}
            </React.Fragment>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED) {
        if (multiState) {
            return (
                <div className="multiple-icons">
                    <ReactSVG src={imgAccepted} className="svg-icon" />{" "}
                    {getFormulaStatusName(formulaConstants.FORMULA_STATUS_ACCEPTED, name)}{" "}
                    <ReactSVG src={imgSuccess} className="svg-icon success" />{" "}
                    {getFormulaStatusName(formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED, name)}{" "}
                </div>
            );
        }
        return (
            <React.Fragment>
                <ReactSVG src={imgSuccess} className="svg-icon success" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED, name)}{" "}
            </React.Fragment>
        );
    }

    if (status === formulaConstants.FORMULA_STATUS_NOT_AVAILABLE) {
        return (
            <React.Fragment>
                <ReactSVG src={imgNotAvailable} className="svg-icon not-available" />{" "}
                {getFormulaStatusName(formulaConstants.FORMULA_STATUS_NOT_AVAILABLE, name)}{" "}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <ReactSVG src={imgError} className="svg-icon without-status" />{" "}
        </React.Fragment>
    );
};

/**
 * Counts formula type ID with bitwise operators
 * @param verified
 * @param enabled
 * @param rejected
 * @param forNominalAmount
 * @param notAvailable
 */
export const getFormulaTypeId = (
    verified: boolean,
    enabled: boolean,
    rejected: boolean,
    forNominalAmount: boolean,
    notAvailable: boolean
): number => {
    return (
        0 |
        (verified ? serverConstants.FORMULA_TYPE_ID_VERIFIED : 0) |
        (enabled ? serverConstants.FORMULA_TYPE_ID_ENABLED : 0) |
        (rejected ? serverConstants.FORMULA_TYPE_ID_REJECTED : 0) |
        (forNominalAmount ? serverConstants.FORMULA_TYPE_ID_FOR_NOMINAL_AMOUNT : 0) |
        (notAvailable ? serverConstants.FORMULA_TYPE_ID_NOT_AVAILABLE : 0)
    );
};

export const getColorObjectType = (type: string, tableConstant: string): objectTypes.ObjectType | null => {
    switch (type) {
        case formulaConstants.COLOR: {
            switch (tableConstant) {
                case menuConstants.TABLE_MASTER_FORMULA_COLORANTS: {
                    return objectTypes.MASTER_COLOR;
                }
                case menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS: {
                    return objectTypes.SYSTEM_COLOR;
                }
                case menuConstants.TABLE_ZONE_FORMULA_COLORANTS: {
                    return objectTypes.ZONE_COLOR;
                }
                default:
                    break;
            }
            break;
        }
        case formulaConstants.FORMULA: {
            switch (tableConstant) {
                case menuConstants.TABLE_MASTER_FORMULA_COLORANTS: {
                    return objectTypes.MASTER_FORMULA;
                }
                case menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS: {
                    return objectTypes.SYSTEM_FORMULA;
                }
                case menuConstants.TABLE_ZONE_FORMULA_COLORANTS: {
                    return objectTypes.ZONE_FORMULA;
                }
                default:
                    break;
            }
            break;
        }
        case formulaConstants.FORMULA_COLORANT: {
            switch (tableConstant) {
                case menuConstants.TABLE_MASTER_FORMULA_COLORANTS: {
                    return objectTypes.MASTER_FORMULA_COLORANT;
                }
                case menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS: {
                    return objectTypes.SYSTEM_FORMULA_COLORANT;
                }
                case menuConstants.TABLE_ZONE_FORMULA_COLORANTS: {
                    return objectTypes.ZONE_FORMULA_COLORANT;
                }
                default:
                    break;
            }
            break;
        }
        case formulaConstants.PRODUCT_IN_COLOR: {
            switch (tableConstant) {
                case menuConstants.TABLE_MASTER_FORMULA_COLORANTS: {
                    return objectTypes.MASTER_PRODUCT_IN_COLOR;
                }
                case menuConstants.TABLE_SYSTEM_FORMULA_COLORANTS: {
                    return objectTypes.SYSTEM_PRODUCT_IN_COLOR;
                }
                case menuConstants.TABLE_ZONE_FORMULA_COLORANTS: {
                    return objectTypes.ZONE_PRODUCT_IN_COLOR;
                }
                default:
                    break;
            }
            break;
        }
        default:
            break;
    }

    return null;
};
