import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";

import React, { Component } from "react";

import { Formula } from "../../types/formula";
import { MasterColorForFormulaTable } from "../scenes/master/tables/MasterColorForFormulaTable";
import { MenuItem } from "../../types/menu";
import { t as translate } from "react-i18nify";

type Props = {
    menuScene: MenuItem | null;
    tableConstant: string;
    list: Array<Formula>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
};

export class FormulaColorsBookmark extends Component<Props> {
    render(): JSX.Element {
        return (
            <div>
                <div>
                    <span>{translate("formula.colorsWithThisFormula")}</span>
                </div>
                <MasterColorForFormulaTable
                    tableConstant={this.props.tableConstant}
                    objectType={objectTypes.MASTER_FORMULA}
                    dataPrivileges={this.props.menuScene}
                    loading={false}
                    allList={[]}
                    activeList={[]}
                    columnOrder={this.props.columnOrder}
                    columnVisibility={this.props.columnVisibility}
                    columnWidth={this.props.columnWidth}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.DEFAULT_ROW_COUNT}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={tableConstants.DEFAULT_SEARCH}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={true}
                    sortingCriterion={tableConstants.DEFAULT_SORTING_COLUMN}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    useTmcNames={false}
                />
            </div>
        );
    }
}
