import React, { Component } from "react";

type Props = {
    className: string;
    editable: boolean;
    buttonTitle?: string;
    buttonClassName?: string;
    callbackButton: () => any;
};

export class ModalButton extends Component<Props> {
    getClassName = (): string => {
        let newClassName = this.props.buttonClassName ? this.props.buttonClassName : "";

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    render(): JSX.Element {
        const { buttonTitle } = this.props;

        if (this.props.editable) {
            return (
                <div className={this.props.className}>
                    <button className={this.getClassName()} onClick={(): void => this.props.callbackButton()}>
                        {buttonTitle ? buttonTitle : ""}
                    </button>
                </div>
            );
        } else {
            return (
                <div className={this.props.className}>
                    <button className={this.getClassName()} disabled>
                        {buttonTitle ? buttonTitle : ""}
                    </button>
                </div>
            );
        }
    }
}
