import * as generalHelper from "../../helpers/generalHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as modalTypes from "../../constants/modalTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as universalObjectActions from "../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { System } from "../../types/system";
import { Zone } from "../../types/zone";

import { connect } from "react-redux";

import imgClose from "../../resources/img/icons/close.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    closeModal(type: ModalType): void;
};

type OwnProps = PropsType & DispatchType;

type Props = ContainerProps & OwnProps;

type State = {
    activeId: number | null;
};

class SelectObjectModal extends Component<Props, State> {
    state: State = {
        activeId: null
    };

    componentDidMount(): void {
        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    handleModalKeyDown = (event: Record<string, any>): void => {
        const { closeModal, type } = this.props;

        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.target.type !== "textarea") {
                    this.handleConfirmClick();
                }

                break;
            }
            case keyCodes.ESCAPE: {
                closeModal(type);

                break;
            }
            default: {
                break;
            }
        }
    };

    handleConfirmClick = (): void => {
        const { activeId } = this.state;

        if (activeId !== null) {
            const data = this.generateData();
            const item = generalHelper.getObjectById(data, activeId);

            switch (this.props.type) {
                case modalTypes.MODAL_SELECT_SYSTEM: {
                    if (item !== null) {
                        this.props.selectSystem(item);
                    }
                    break;
                }
                case modalTypes.MODAL_SELECT_ZONE: {
                    if (item !== null) {
                        this.props.selectZone(item);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    handleItemClick = (id: number): void => {
        this.setState({ activeId: id });
    };

    generateData = (): Array<System | Zone> => {
        switch (this.props.type) {
            case modalTypes.MODAL_SELECT_SYSTEM: {
                return this.props.systemList;
            }
            case modalTypes.MODAL_SELECT_ZONE: {
                return this.props.zoneList;
            }
            default: {
                return [];
            }
        }
    };

    getModalClassName = (): string => {
        let className = "modal modal-md select-object-modal master";

        if (this.props.type === modalTypes.MODAL_SELECT_ZONE) {
            className += " zone";
        }

        return className;
    };

    getRowClassName = (id: number): string => {
        let className = "row";

        if (id === this.state.activeId) {
            className += " active";
        }

        return className;
    };

    getModalTitle = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_SELECT_SYSTEM: {
                return translate("system.selectSystem");
            }
            case modalTypes.MODAL_SELECT_ZONE: {
                return translate("zone.selectZone");
            }
            default: {
                return "";
            }
        }
    };

    getCreateNewButton = (): JSX.Element | null => {
        switch (this.props.type) {
            case modalTypes.MODAL_SELECT_SYSTEM: {
                return <button className="btn-info pull-left">{translate("system.createNewSystem")}</button>;
            }
            case modalTypes.MODAL_SELECT_ZONE: {
                return <button className="btn-info pull-left">{translate("zone.createNewZone")}</button>;
            }
            default: {
                return null;
            }
        }
    };

    getContent = (): JSX.Element => {
        const content: Array<JSX.Element> = [];
        const data = this.generateData();

        for (const item of data) {
            content.push(
                <div
                    key={item[propertyConstants.PROPERTY_ID]}
                    className={this.getRowClassName(item[propertyConstants.PROPERTY_ID])}
                    onClick={(): void => this.handleItemClick(item[propertyConstants.PROPERTY_ID])}
                >
                    {item[propertyConstants.PROPERTY_NAME]}
                </div>
            );
        }
        return (
            <div className="content-window slim-scroll">
                <div className="system-rows">{content}</div>
            </div>
        );
    };
    // TODO separate or unify with other modal: cross for closing, cancel button, ok button
    render(): JSX.Element {
        const { type, closeModal } = this.props;

        return (
            <div className={this.getModalClassName()}>
                <header className="modal-header">
                    <div className="title">{this.getModalTitle()}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content">{this.getContent()}</div>
                <footer className="modal-footer">
                    {this.getCreateNewButton()}
                    <button className="btn-info pull-right" onClick={this.handleConfirmClick} autoFocus={true}>
                        {translate("general.ok")}
                    </button>
                    <button
                        className="btn-info pull-right"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        {translate("general.cancel")}
                    </button>
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    systemList: Array<System>;
    zoneList: Array<Zone>;
}>;

export type DispatchType = Readonly<{
    selectSystem(system: System): void;
    selectZone(zone: Zone): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    systemList: state.system.allList,
    zoneList: state.zone.allList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    selectSystem: (system: System): any =>
        dispatch(universalObjectActions.setActiveItems(objectTypes.SYSTEM, [system])),
    selectZone: (zone: Zone): any => dispatch(universalObjectActions.setActiveItems(objectTypes.ZONE, [zone]))
});

export const SelectObjectModalContainer = connect(mapStateToProps, mapDispatchToProps)(SelectObjectModal);
