import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as propertyHelper from "./propertyHelper";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";

import { TableHeading } from "../types/tableHeading";

export const sortTableHeadings = (headings: Array<TableHeading>, visibleList: Array<propertyConstants.Property>) => {
    return headings.sort((aHeading, bHeading) => {
        const aIndex = visibleList.indexOf(aHeading[tableConstants.TABLE_HEADING_NAME]);
        const bIndex = visibleList.indexOf(bHeading[tableConstants.TABLE_HEADING_NAME]);

        if (aIndex >= 0 && bIndex >= 0) {
            return aIndex - bIndex;
        }

        if (aIndex >= 0) {
            return -1;
        }

        if (bIndex >= 0) {
            return 1;
        }

        return 0;
    });
};

export const isPropertySourceChanged = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property,
    data: any
): boolean => {
    const propertySource = propertyHelper.getPropertySource(property);

    if (propertySource && data?.[propertySource]) {
        if (
            objectTypes.MASTER_OBJECT_TYPES.includes(objectType) &&
            data[propertySource] === serverConstants.MASTER_DATA_SOURCE
        ) {
            if (
                objectType === objectTypes.MASTER_REDLIKE_OPTION ||
                objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE
            ) {
                return true;
            }
        }

        if (
            objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType) &&
            data[propertySource] === serverConstants.SYSTEM_DATA_SOURCE
        ) {
            if (
                objectType !== objectTypes.SYSTEM_COLORANT_PACKAGE &&
                objectType !== objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE &&
                objectType !== objectTypes.SYSTEM_PRODUCT_GROUP
            ) {
                return true;
            }
        }

        if (
            objectTypes.ZONE_OBJECT_TYPES.includes(objectType) &&
            data[propertySource] === serverConstants.ZONE_DATA_SOURCE
        ) {
            return true;
        }
    }

    return false;
};

export const getSortingObjectKey = (
    sortingOption: Record<string, boolean> | null
): propertyConstants.Property | null => {
    return sortingOption ? (Object.keys(sortingOption)[0] as propertyConstants.Property) : null;
};

export const getSortingObjectValue = (sortingOption: Record<string, boolean> | null): boolean => {
    return sortingOption ? Object.values(sortingOption)[0] : tableConstants.DEFAULT_SORTING_ASC;
};

export const isFilePropertyVisible = (columnVisibility: Record<string, any>): boolean => {
    return propertyConstants.FILE_PROPERTIES.some((property) => columnVisibility[property]);
};

export const isPricePropertyVisible = (columnVisibility: Record<string, any>): boolean => {
    return propertyConstants.PRICE_PROPERTIES.some((property) => columnVisibility[property]);
};

export const isBarcodePropertyVisible = (columnVisibility: Record<string, any>): boolean => {
    return propertyConstants.BARCODE_PROPERTIES.some((property) => columnVisibility[property]);
};

export const getNewTablePage = (count: number, rowCount: number): number => {
    return Math.ceil(count / rowCount);
};

export const getNewTableOffset = (page: number, rowCount: number): number => {
    return (page - 1) * rowCount;
};
