import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { UserGroup, updateRedlikeUserGroup } from "../types/userGroup";

export type UserGroupState = Readonly<{
    masterAllList: Array<UserGroup>;
    masterList: Array<UserGroup>;
    masterActiveList: Array<UserGroup>;
    masterCount: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    allList: Array<UserGroup>;
    list: Array<UserGroup>;
    activeList: Array<UserGroup>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    offset: number;
    page: number;
    availableList: Array<UserGroup>;
    availableActiveList: Array<UserGroup>;
    selectedList: Array<UserGroup>;
    selectedActiveList: Array<UserGroup>;
}>;

const initialState: UserGroupState = {
    masterAllList: [],
    masterList: [],
    masterActiveList: [],
    masterCount: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE,
    allList: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    sortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    sortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE,
    availableList: [],
    availableActiveList: [],
    selectedList: [],
    selectedActiveList: []
};

export default function userGroups(state: UserGroupState = initialState, action: Record<string, any>): UserGroupState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.USER_GROUP: {
                    return {
                        ...state,
                        allList: action.payload.list
                    };
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP:
                case objectTypes.REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        masterAllList: action.payload.list
                    };
                }
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.availableActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        availableList: action.payload.list,
                        availableActiveList: newActiveList
                    };
                }
                case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        selectedList: action.payload.list
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.USER_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        list: action.payload.list,
                        activeList: newActiveList,
                        count: action.payload.totalCount
                    };
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterCount: action.payload.totalCount
                    };
                }
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        availableList: action.payload.list
                    };
                }
                case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        selectedList: action.payload.list
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.USER_GROUP: {
                    return {
                        ...state,
                        activeList: action.payload.items
                    };
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        availableActiveList: action.payload.items
                    };
                }
                case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        selectedActiveList: action.payload.items
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.USER_GROUP: {
                    return {
                        ...state,
                        offset: action.payload.offset,
                        page: action.payload.page
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.USER_GROUP: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SEARCH,
                        tableConstants.SEARCH_PARAMS
                    );
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.USER_GROUP: {
                    return {
                        ...state,
                        sortingAsc: action.payload.asc,
                        sortingCriterion: action.payload.criterion || tableConstants.DEFAULT_SORTING_COLUMN
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                        const updatedUserGroupList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_USER_GROUP_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateRedlikeUserGroup
                        );

                        if (updatedUserGroupList.length) {
                            return {
                                ...state,
                                masterList: updatedUserGroupList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload &&
                (action.payload.type === modalTypes.MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE ||
                    action.payload.type === modalTypes.MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE ||
                    action.payload.type === modalTypes.MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE ||
                    action.payload.type === modalTypes.MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE ||
                    action.payload.type === modalTypes.MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE ||
                    action.payload.type === modalTypes.MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE)
            ) {
                return {
                    ...state,
                    availableList: [],
                    availableActiveList: [],
                    selectedList: [],
                    selectedActiveList: []
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                availableList: [],
                availableActiveList: [],
                selectedList: [],
                selectedActiveList: []
            };
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
