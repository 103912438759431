import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Colorant, mapColorant } from "../../types/colorant";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_COLORANTS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_COLORANTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            let colorant: Colorant | null;

            for (const item of response.data.data) {
                colorant = mapColorant(item);

                if (colorant) {
                    colorantList.push(colorant);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_COLORANT, colorantList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            let colorant: Colorant | null;

            for (const item of response.data.data) {
                colorant = mapColorant(item);

                if (colorant) {
                    colorantList.push(colorant);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_SYSTEM_COLORANT, colorantList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_MASTER_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLORANT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_COLORANT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_COLORANTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_COLORANT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_EDIT));
            } else {
                const colorant = mapColorant(response.data);

                if (colorant) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_COLORANT, [colorant]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLORANT),
                universalObjectActions.setActiveItems(objectTypes.MASTER_COLORANT, [])
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_COLORANTS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_COLORANTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            let colorant: Colorant | null;

            for (const item of response.data.data) {
                colorant = mapColorant(item);

                if (colorant) {
                    colorantList.push(colorant);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_COLORANT,
                    colorantList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            let colorant: Colorant | null;

            for (const item of response.data.data) {
                colorant = mapColorant(item);

                if (colorant) {
                    colorantList.push(colorant);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE_COLORANT, colorantList, response.data.count)
            ];
        }
        case methods.METHOD_GET_SYSTEM_COLORANT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            const colorant = mapColorant(response.data);
            const requests: Array<any> = [];

            if (colorant) {
                colorantList.push(colorant);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: colorant
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [...requests, universalObjectActions.updateActiveItems(objectTypes.SYSTEM_COLORANT, colorantList)];
        }
        case methods.METHOD_ADD_SYSTEM_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT)
                ];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_ADD)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_COLORANTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_COLORANTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_EDIT));
            } else {
                const colorant = mapColorant(response.data);

                if (colorant) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_COLORANT, [colorant]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_COLORANT),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_COLORANT, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_COLORANTS:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_COLORANTS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            let colorant: Colorant | null;

            for (const item of response.data.data) {
                colorant = mapColorant(item);

                if (colorant) {
                    colorantList.push(colorant);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_COLORANT,
                    colorantList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ZONE_COLORANT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const colorantList: Array<Colorant> = [];
            const colorant = mapColorant(response.data);
            const requests: Array<any> = [];

            if (colorant) {
                colorantList.push(colorant);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: colorant
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [universalObjectActions.updateActiveItems(objectTypes.ZONE_COLORANT, colorantList)];
        }
        case methods.METHOD_ADD_ZONE_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_ADD)
            ];
        }
        case methods.METHOD_ADD_ZONE_COLORANT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_COLORANTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_COLORANT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_COLORANTS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_COLORANT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_COLORANTS_EDIT));
            } else {
                const colorant = mapColorant(response.data);

                if (colorant) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_COLORANT, [colorant]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_COLORANT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_COLORANT),
                universalObjectActions.setActiveItems(objectTypes.ZONE_COLORANT, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
