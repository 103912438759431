export class DataVacuuming {
    done: number;
    total: number;

    constructor(done: number, total: number) {
        this.done = done;
        this.total = total;
    }
}

export function mapDataVacuuming(data: Record<string, any>): DataVacuuming | null {
    try {
        return new DataVacuuming(data.done, data.total);
    } catch (e) {
        return null;
    }
}
