import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";
import imgFile from "../../../../resources/img/icons/product-file.svg";
import imgLoader from "../../../../resources/img/mini-loader.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    heading: TableHeading;
    item: any;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellFile extends Component<Props> {
    getCellClassname = (property: propertyConstants.Property, content: string): string => {
        return `td ${property} ${content ? "icon" : ""}`;
    };

    getTooltipText = (files: Array<any>): string => {
        let tooltip = "";

        for (const file of files) {
            tooltip += `${file[propertyConstants.PROPERTY_NAME]} (${translationHelper.getFileTypeTranslation(
                file[propertyConstants.PROPERTY_FILE_TYPE_ID],
                ""
            )})<br/>`;
        }

        return tooltip;
    };

    getCellInput = (): JSX.Element => {
        const { item, heading } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;

        if (item[property] === undefined) {
            return (
                <div className={this.getCellClassname(property, value)} key={property}>
                    <ReactSVG src={imgLoader} className="svg-icon" />
                </div>
            );
        }

        if (value?.length) {
            const tooltipText = this.getTooltipText(value);

            return (
                <div
                    className={this.getCellClassname(property, value)}
                    key={property}
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, tooltipText)}
                    onMouseLeave={(): void => this.props.callbackMouseLeave()}
                    data-tip
                    data-for={"table-cell-tooltip"}
                >
                    <ReactSVG className={"svg-icon"} src={imgFile} />
                    <span>{`(${value.length})`}</span>
                </div>
            );
        }

        return <div className={this.getCellClassname(property, value)} key={property}></div>;
    };

    render(): JSX.Element {
        return this.getCellInput();
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellFileContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellFile);
