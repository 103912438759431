import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class PriceOption {
    key: string;
    value: any;
    valueSource: number;
    systemId: number | null;
    zoneId: number | null;

    constructor(key: string, value: any, valueSource: number, systemId: number | null, zoneId: number | null) {
        this.key = key;
        this.value = value;
        this.valueSource = valueSource;
        this.systemId = systemId;
        this.zoneId = zoneId;
    }
}

export function mapPriceOption(data: any): PriceOption | null {
    try {
        return new PriceOption(data.key, data.value, data.valueSource, data.systemId, data.zoneId);
    } catch (e) {
        return null;
    }
}

export function mapPriceOptions(data: Record<string, any>): Array<PriceOption> {
    const options = [];

    for (const item of Object.values(data)) {
        const option = mapPriceOption(item);

        if (option !== null) {
            options.push(option);
        }
    }

    return options;
}

export function generatePriceOptionData(objectType: objectTypes.ObjectType, list: Array<PriceOption>): any {
    const optionDefinition = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_PRICE_DEFINITION);
    const optionDiscount = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_USE_DISCOUNT);
    const optionMargin = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_USE_MARGIN);
    const optionMarkup = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_USE_MARKUP);
    const optionCompanyCostEnable = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST);
    const optionPriceGroupsEnable = generalHelper.getObjectByKey(list, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS);
    const optionPriceGroupsCount = generalHelper.getObjectByKey(
        list,
        priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS
    );

    const margin = mapPriceOption(optionMargin);
    const markup = mapPriceOption(optionMarkup);
    const discount = mapPriceOption(optionDiscount);
    const optionCalculation = [];

    if (margin) {
        optionCalculation.push(margin);
    }
    if (markup) {
        optionCalculation.push(markup);
    }
    if (discount) {
        optionCalculation.push(discount);
    }

    return {
        [priceConstants.PRICE_OPTION_PRICE_DEFINITION]: optionDefinition?.[propertyConstants.PROPERTY_VALUE] || null,
        [priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST]:
            optionCompanyCostEnable?.[propertyConstants.PROPERTY_VALUE] || false,
        [priceConstants.PRICE_OPTION_PRICE_CALCULATION]: optionCalculation,
        [priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS]:
            optionPriceGroupsEnable?.[propertyConstants.PROPERTY_VALUE] || false,
        [priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS]:
            optionPriceGroupsCount?.[propertyConstants.PROPERTY_VALUE] || ""
    };
}

export function generatePriceOptionHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const priceCalculationList = [
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_OPTION_USE_MARGIN,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_OPTION_USE_MARGIN,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPropertyTranslation(
                objectType,
                priceConstants.PRICE_OPTION_USE_MARGIN
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_OPTION_USE_MARKUP,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_OPTION_USE_MARKUP,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPropertyTranslation(
                objectType,
                priceConstants.PRICE_OPTION_USE_MARKUP
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_OPTION_USE_DISCOUNT,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_OPTION_USE_DISCOUNT,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPropertyTranslation(
                objectType,
                priceConstants.PRICE_OPTION_USE_DISCOUNT
            )
        }
    ];
    const priceDefinitionList = [
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPriceOptionTranslation(
                priceConstants.PRICE_OPTION_PRICE_DEFINITION,
                priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPriceOptionTranslation(
                priceConstants.PRICE_OPTION_PRICE_DEFINITION,
                priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPriceOptionTranslation(
                priceConstants.PRICE_OPTION_PRICE_DEFINITION,
                priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY
            )
        },
        {
            [propertyConstants.PROPERTY_ID]: priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY,
            [propertyConstants.PROPERTY_KEY]: priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getPriceOptionTranslation(
                priceConstants.PRICE_OPTION_PRICE_DEFINITION,
                priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY
            )
        }
    ];

    const list = [
        new TableHeading(
            priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST,
            translationHelper.getPropertyTranslation(objectType, priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            editableList.includes(priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            tableConstants.FILTERABLE_COLUMNS.includes(priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            alwaysVisibleList.includes(priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            requiredList.includes(priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST),
            [],
            widthOption?.[priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST] ?? null
        ),
        new TableHeading(
            priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS,
            translationHelper.getPropertyTranslation(objectType, priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            editableList.includes(priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            tableConstants.FILTERABLE_COLUMNS.includes(priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            alwaysVisibleList.includes(priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            requiredList.includes(priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS),
            [],
            widthOption?.[priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS] ?? null
        ),
        new TableHeading(
            priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS,
            translationHelper.getPropertyTranslation(objectType, priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            editableList.includes(priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            tableConstants.FILTERABLE_COLUMNS.includes(priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            alwaysVisibleList.includes(priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            requiredList.includes(priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS),
            [],
            widthOption?.[priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS] ?? null
        ),
        new TableHeading(
            priceConstants.PRICE_OPTION_PRICE_CALCULATION,
            translationHelper.getPropertyTranslation(objectType, priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            tableConstants.TABLE_TYPE_LIST,
            visibleList.includes(priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            editableList.includes(priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            tableConstants.FILTERABLE_COLUMNS.includes(priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            alwaysVisibleList.includes(priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            requiredList.includes(priceConstants.PRICE_OPTION_PRICE_CALCULATION),
            priceCalculationList,
            widthOption?.[priceConstants.PRICE_OPTION_PRICE_CALCULATION] ?? null
        ),
        new TableHeading(
            priceConstants.PRICE_OPTION_PRICE_DEFINITION,
            translationHelper.getPropertyTranslation(objectType, priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            editableList.includes(priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            tableConstants.FILTERABLE_COLUMNS.includes(priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            alwaysVisibleList.includes(priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            requiredList.includes(priceConstants.PRICE_OPTION_PRICE_DEFINITION),
            priceDefinitionList,
            widthOption?.[priceConstants.PRICE_OPTION_PRICE_DEFINITION] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
