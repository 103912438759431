import * as actionConstants from "../constants/actionTypes";
import * as localConfig from "../constants/localConfig";

export type SoftwareState = Readonly<{
    sessionUuid: string | null;
    company: string;
    custom: null | string;
    email: string;
    phone: string;
    productTitle: string;
    year: string;
}>;

const initialState: SoftwareState = {
    sessionUuid: null,
    company: "DESO development",
    custom: localConfig.CUSTOM,
    email: "support@deso.cz",
    phone: "+420 608 540 590",
    productTitle: "Redlike Manager",
    year: "2022"
};

export default function software(state: SoftwareState = initialState, action: Record<string, any>): SoftwareState {
    switch (action.type) {
        case actionConstants.EVENT_SAVE_SESSION_INFO: {
            return {
                ...state,
                sessionUuid: action.payload.uuid
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
