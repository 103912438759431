import * as menuConstants from "../constants/menuConstants";
import * as navigationConstants from "../constants/navigationTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as pathConstants from "../constants/paths";

import { Paths } from "../constants/paths";
import { Scene } from "../constants/navigationTypes";

// TODO check jestli nejde zmenšit množství metod

export const getHomePagePath = (userName: string): pathConstants.Paths => {
    return userName === "user" ? pathConstants.PATH_MASTER_COLORANTS : pathConstants.PATH_DATA_MASTER_SETTINGS;
};

export const isValidPath = (sessionPath: string): boolean => {
    switch (sessionPath) {
        case pathConstants.PATH_COMPANIES:
        case pathConstants.PATH_DATA_EXPORT:
        case pathConstants.PATH_DATA_IMPORT:
        case pathConstants.PATH_DATA_MASTER_SETTINGS:
        case pathConstants.PATH_DATA_SERVER_SCRIPTS:
        case pathConstants.PATH_DATA_SYSTEM_ZONE_SETTINGS:
        case pathConstants.PATH_DEBUG:
        case pathConstants.PATH_GENERIC_BARCODES:
        case pathConstants.PATH_GENERIC_CURRENCIES:
        case pathConstants.PATH_GENERIC_IMAGES:
        case pathConstants.PATH_GENERIC_PACKAGES:
        case pathConstants.PATH_GENERIC_PRODUCT_SHEETS:
        case pathConstants.PATH_GENERIC_STATIC_SQL:
        case pathConstants.PATH_GENERIC_UNITS:
        case pathConstants.PATH_GLOBAL_OPTIONS:
        case pathConstants.PATH_USER_OPTIONS:
        case pathConstants.PATH_MASTER_BASES:
        case pathConstants.PATH_MASTER_COLORANTS:
        case pathConstants.PATH_MASTER_COLORS:
        case pathConstants.PATH_MASTER_FANDECKS:
        case pathConstants.PATH_MASTER_FORMULA_NOTES:
        case pathConstants.PATH_MASTER_FORMULAS:
        case pathConstants.PATH_MASTER_PRODUCTS:
        case pathConstants.PATH_MASTER_REDLIKE_OPTIONS:
        case pathConstants.PATH_MASTER_REDLIKE_PRIVILEGES:
        case pathConstants.PATH_MASTER_REDLIKE_USERS:
        case pathConstants.PATH_SERVER_MONITORING:
        case pathConstants.PATH_SYSTEM_BASES:
        case pathConstants.PATH_SYSTEM_COLORANTS:
        case pathConstants.PATH_SYSTEM_COLORS:
        case pathConstants.PATH_SYSTEM_FANDECKS:
        case pathConstants.PATH_SYSTEM_FORMULAS:
        case pathConstants.PATH_SYSTEM_FORMULA_NOTES:
        case pathConstants.PATH_SYSTEM_PRICES:
        case pathConstants.PATH_SYSTEM_PRODUCT_GROUPS:
        case pathConstants.PATH_SYSTEM_PRODUCTS:
        case pathConstants.PATH_SYSTEM_REDLIKE_OPTIONS:
        case pathConstants.PATH_SYSTEM_REDLIKE_USERS:
        case pathConstants.PATH_USERS:
        case pathConstants.PATH_USER_ACTION_HISTORY:
        case pathConstants.PATH_ZONE_BASES:
        case pathConstants.PATH_ZONE_COLORANTS:
        case pathConstants.PATH_ZONE_COLORS:
        case pathConstants.PATH_ZONE_FANDECKS:
        case pathConstants.PATH_ZONE_FORMULAS:
        case pathConstants.PATH_ZONE_FORMULA_NOTES:
        case pathConstants.PATH_ZONE_PRICES:
        case pathConstants.PATH_ZONE_PRODUCT_GROUPS:
        case pathConstants.PATH_ZONE_PRODUCTS:
        case pathConstants.PATH_ZONE_REDLIKE_OPTIONS:
        case pathConstants.PATH_ZONE_REDLIKE_USERS:
            return true;
        default:
            return false;
    }
};

export const getPathByMenuItem = (type: string): Paths | null => {
    switch (type) {
        case menuConstants.HEADER_MASTER:
            return pathConstants.PATH_DATA_MASTER_SETTINGS;
        case menuConstants.HEADER_SYSTEM:
        case menuConstants.HEADER_ZONE:
            return pathConstants.PATH_DATA_SYSTEM_ZONE_SETTINGS;
        case menuConstants.PAGE_DATA_EXPORT:
            return pathConstants.PATH_DATA_EXPORT;
        case menuConstants.PAGE_DATA_IMPORT:
            return pathConstants.PATH_DATA_IMPORT;
        case menuConstants.PAGE_DATA_MASTER_SETTINGS:
            return pathConstants.PATH_DATA_MASTER_SETTINGS;
        case menuConstants.PAGE_DATA_SERVER_SCRIPTS:
            return pathConstants.PATH_DATA_SERVER_SCRIPTS;
        case menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS:
            return pathConstants.PATH_DATA_SYSTEM_ZONE_SETTINGS;
        case menuConstants.PAGE_MASTER_BASES:
            return pathConstants.PATH_MASTER_BASES;
        case menuConstants.PAGE_MASTER_COLORANTS:
            return pathConstants.PATH_MASTER_COLORANTS;
        case menuConstants.PAGE_MASTER_COLORS:
            return pathConstants.PATH_MASTER_COLORS;
        case menuConstants.PAGE_MASTER_FANDECKS:
            return pathConstants.PATH_MASTER_FANDECKS;
        case menuConstants.PAGE_MASTER_FORMULAS:
            return pathConstants.PATH_MASTER_FORMULAS;
        case menuConstants.PAGE_MASTER_FORMULA_NOTES:
            return pathConstants.PATH_MASTER_FORMULA_NOTES;
        case menuConstants.PAGE_MASTER_PRODUCTS:
            return pathConstants.PATH_MASTER_PRODUCTS;
        case menuConstants.PAGE_MASTER_REDLIKE_OPTIONS:
            return pathConstants.PATH_MASTER_REDLIKE_OPTIONS;
        case menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES:
            return pathConstants.PATH_MASTER_REDLIKE_PRIVILEGES;
        case menuConstants.PAGE_MASTER_REDLIKE_USERS:
            return pathConstants.PATH_MASTER_REDLIKE_USERS;
        case menuConstants.PAGE_GENERIC_BARCODES:
            return pathConstants.PATH_GENERIC_BARCODES;
        case menuConstants.PAGE_GENERIC_CURRENCIES:
            return pathConstants.PATH_GENERIC_CURRENCIES;
        case menuConstants.PAGE_GENERIC_IMAGES:
            return pathConstants.PATH_GENERIC_IMAGES;
        case menuConstants.PAGE_GENERIC_PACKAGES:
            return pathConstants.PATH_GENERIC_PACKAGES;
        case menuConstants.PAGE_GENERIC_PRODUCT_SHEETS:
            return pathConstants.PATH_GENERIC_PRODUCT_SHEETS;
        case menuConstants.PAGE_GENERIC_STATIC_SQL:
            return pathConstants.PATH_GENERIC_STATIC_SQL;
        case menuConstants.PAGE_GENERIC_UNITS:
            return pathConstants.PATH_GENERIC_UNITS;
        case menuConstants.PAGE_OPTIONS_GLOBAL_SETTINGS:
            return pathConstants.PATH_GLOBAL_OPTIONS;
        case menuConstants.PAGE_OPTIONS_USER_SETTINGS:
            return pathConstants.PATH_USER_OPTIONS;
        case menuConstants.PAGE_SYSTEM_BASES:
            return pathConstants.PATH_SYSTEM_BASES;
        case menuConstants.PAGE_SYSTEM_COLORANTS:
            return pathConstants.PATH_SYSTEM_COLORANTS;
        case menuConstants.PAGE_SYSTEM_COLORS:
            return pathConstants.PATH_SYSTEM_COLORS;
        case menuConstants.PAGE_SYSTEM_FANDECKS:
            return pathConstants.PATH_SYSTEM_FANDECKS;
        case menuConstants.PAGE_SYSTEM_FORMULAS:
            return pathConstants.PATH_SYSTEM_FORMULAS;
        case menuConstants.PAGE_SYSTEM_FORMULA_NOTES:
            return pathConstants.PATH_SYSTEM_FORMULA_NOTES;
        case menuConstants.PAGE_SYSTEM_PRICES:
            return pathConstants.PATH_SYSTEM_PRICES;
        case menuConstants.PAGE_SYSTEM_PRODUCT_GROUPS:
            return pathConstants.PATH_SYSTEM_PRODUCT_GROUPS;
        case menuConstants.PAGE_SYSTEM_PRODUCTS:
            return pathConstants.PATH_SYSTEM_PRODUCTS;
        case menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS:
            return pathConstants.PATH_SYSTEM_REDLIKE_OPTIONS;
        case menuConstants.PAGE_SYSTEM_REDLIKE_USERS:
            return pathConstants.PATH_SYSTEM_REDLIKE_USERS;
        case menuConstants.PAGE_ZONE_BASES:
            return pathConstants.PATH_ZONE_BASES;
        case menuConstants.PAGE_ZONE_COLORANTS:
            return pathConstants.PATH_ZONE_COLORANTS;
        case menuConstants.PAGE_ZONE_COLORS:
            return pathConstants.PATH_ZONE_COLORS;
        case menuConstants.PAGE_ZONE_FANDECKS:
            return pathConstants.PATH_ZONE_FANDECKS;
        case menuConstants.PAGE_ZONE_FORMULAS:
            return pathConstants.PATH_ZONE_FORMULAS;
        case menuConstants.PAGE_ZONE_FORMULA_NOTES:
            return pathConstants.PATH_ZONE_FORMULA_NOTES;
        case menuConstants.PAGE_ZONE_PRICES:
            return pathConstants.PATH_ZONE_PRICES;
        case menuConstants.PAGE_ZONE_PRODUCT_GROUPS:
            return pathConstants.PATH_ZONE_PRODUCT_GROUPS;
        case menuConstants.PAGE_ZONE_PRODUCTS:
            return pathConstants.PATH_ZONE_PRODUCTS;
        case menuConstants.PAGE_ZONE_REDLIKE_OPTIONS:
            return pathConstants.PATH_ZONE_REDLIKE_OPTIONS;
        case menuConstants.PAGE_ZONE_REDLIKE_USERS:
            return pathConstants.PATH_ZONE_REDLIKE_USERS;
        default:
            return null;
    }
};

export const getPathByScene = (scene: Scene | null): Paths => {
    switch (scene) {
        case navigationTypes.COMPANY_SCENE:
            return pathConstants.PATH_COMPANIES;
        case navigationTypes.DEBUG_SCENE:
            return pathConstants.PATH_DEBUG;
        case navigationTypes.DATA_EXPORT_SCENE:
            return pathConstants.PATH_DATA_EXPORT;
        case navigationTypes.DATA_IMPORT_SCENE:
            return pathConstants.PATH_DATA_IMPORT;
        case navigationTypes.DATA_MASTER_SETTINGS_SCENE:
            return pathConstants.PATH_DATA_MASTER_SETTINGS;
        case navigationTypes.DATA_SERVER_SCRIPTS_SCENE:
            return pathConstants.PATH_DATA_SERVER_SCRIPTS;
        case navigationTypes.DATA_SYSTEM_ZONE_SETTINGS_SCENE:
            return pathConstants.PATH_DATA_SYSTEM_ZONE_SETTINGS;
        case navigationTypes.GENERIC_BARCODES_SCENE:
            return pathConstants.PATH_GENERIC_BARCODES;
        case navigationTypes.GENERIC_CURRENCIES_SCENE:
            return pathConstants.PATH_GENERIC_CURRENCIES;
        case navigationTypes.GENERIC_IMAGES_SCENE:
            return pathConstants.PATH_GENERIC_IMAGES;
        case navigationTypes.GENERIC_PACKAGES_SCENE:
            return pathConstants.PATH_GENERIC_PACKAGES;
        case navigationTypes.GENERIC_PRODUCT_SHEETS_SCENE:
            return pathConstants.PATH_GENERIC_PRODUCT_SHEETS;
        case navigationTypes.GENERIC_STATIC_SQL_SCENE:
            return pathConstants.PATH_GENERIC_STATIC_SQL;
        case navigationTypes.GENERIC_UNITS_SCENE:
            return pathConstants.PATH_GENERIC_UNITS;
        case navigationTypes.GLOBAL_OPTIONS_SCENE:
            return pathConstants.PATH_GLOBAL_OPTIONS;
        case navigationTypes.USER_OPTIONS_SCENE:
            return pathConstants.PATH_USER_OPTIONS;
        case navigationTypes.MASTER_BASES_SCENE:
            return pathConstants.PATH_MASTER_BASES;
        case navigationTypes.MASTER_COLORANTS_SCENE:
            return pathConstants.PATH_MASTER_COLORANTS;
        case navigationTypes.MASTER_COLORS_SCENE:
            return pathConstants.PATH_MASTER_COLORS;
        case navigationTypes.MASTER_FANDECKS_SCENE:
            return pathConstants.PATH_MASTER_FANDECKS;
        case navigationTypes.MASTER_FORMULAS_SCENE:
            return pathConstants.PATH_MASTER_FORMULAS;
        case navigationTypes.MASTER_FORMULA_NOTES_SCENE:
            return pathConstants.PATH_MASTER_FORMULA_NOTES;
        case navigationTypes.MASTER_PRODUCTS_SCENE:
            return pathConstants.PATH_MASTER_PRODUCTS;
        case navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE:
            return pathConstants.PATH_MASTER_REDLIKE_OPTIONS;
        case navigationTypes.MASTER_REDLIKE_PRIVILEGES_SCENE:
            return pathConstants.PATH_MASTER_REDLIKE_PRIVILEGES;
        case navigationTypes.MASTER_REDLIKE_USERS_SCENE:
            return pathConstants.PATH_MASTER_REDLIKE_USERS;
        case navigationTypes.SERVER_MONITORING_SCENE:
            return pathConstants.PATH_SERVER_MONITORING;
        case navigationTypes.SYSTEM_BASES_SCENE:
            return pathConstants.PATH_SYSTEM_BASES;
        case navigationTypes.SYSTEM_COLORANTS_SCENE:
            return pathConstants.PATH_SYSTEM_COLORANTS;
        case navigationTypes.SYSTEM_COLORS_SCENE:
            return pathConstants.PATH_SYSTEM_COLORS;
        case navigationTypes.SYSTEM_FANDECKS_SCENE:
            return pathConstants.PATH_SYSTEM_FANDECKS;
        case navigationTypes.SYSTEM_FORMULAS_SCENE:
            return pathConstants.PATH_SYSTEM_FORMULAS;
        case navigationTypes.SYSTEM_FORMULA_NOTES_SCENE:
            return pathConstants.PATH_SYSTEM_FORMULA_NOTES;
        case navigationTypes.SYSTEM_PRICES_SCENE:
            return pathConstants.PATH_SYSTEM_PRICES;
        case navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE:
            return pathConstants.PATH_SYSTEM_PRODUCT_GROUPS;
        case navigationTypes.SYSTEM_PRODUCTS_SCENE:
            return pathConstants.PATH_SYSTEM_PRODUCTS;
        case navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE:
            return pathConstants.PATH_SYSTEM_REDLIKE_OPTIONS;
        case navigationTypes.SYSTEM_REDLIKE_USERS_SCENE:
            return pathConstants.PATH_SYSTEM_REDLIKE_USERS;
        case navigationTypes.USER_ACTION_HISTORY_SCENE:
            return pathConstants.PATH_USER_ACTION_HISTORY;
        case navigationTypes.USERS_SCENE:
            return pathConstants.PATH_USERS;
        case navigationTypes.ZONE_BASES_SCENE:
            return pathConstants.PATH_ZONE_BASES;
        case navigationTypes.ZONE_COLORANTS_SCENE:
            return pathConstants.PATH_ZONE_COLORANTS;
        case navigationTypes.ZONE_COLORS_SCENE:
            return pathConstants.PATH_ZONE_COLORS;
        case navigationTypes.ZONE_FANDECKS_SCENE:
            return pathConstants.PATH_ZONE_FANDECKS;
        case navigationTypes.ZONE_FORMULAS_SCENE:
            return pathConstants.PATH_ZONE_FORMULAS;
        case navigationTypes.ZONE_FORMULA_NOTES_SCENE:
            return pathConstants.PATH_ZONE_FORMULA_NOTES;
        case navigationTypes.ZONE_PRICES_SCENE:
            return pathConstants.PATH_ZONE_PRICES;
        case navigationTypes.ZONE_PRODUCT_GROUPS_SCENE:
            return pathConstants.PATH_ZONE_PRODUCT_GROUPS;
        case navigationTypes.ZONE_PRODUCTS_SCENE:
            return pathConstants.PATH_ZONE_PRODUCTS;
        case navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE:
            return pathConstants.PATH_ZONE_REDLIKE_OPTIONS;
        case navigationTypes.ZONE_REDLIKE_USERS_SCENE:
            return pathConstants.PATH_ZONE_REDLIKE_USERS;
        default:
            return pathConstants.PATH_DATA_MASTER_SETTINGS;
    }
};

export const getSceneByMenuItem = (type: string): Scene | null => {
    switch (type) {
        case menuConstants.HEADER_MASTER:
            return navigationConstants.DATA_MASTER_SETTINGS_SCENE;
        case menuConstants.HEADER_SYSTEM:
        case menuConstants.HEADER_ZONE:
            return navigationConstants.DATA_SYSTEM_ZONE_SETTINGS_SCENE;
        case menuConstants.PAGE_DATA_EXPORT:
            return navigationConstants.DATA_EXPORT_SCENE;
        case menuConstants.PAGE_DATA_IMPORT:
            return navigationConstants.DATA_IMPORT_SCENE;
        case menuConstants.PAGE_DATA_MASTER_SETTINGS:
            return navigationConstants.DATA_MASTER_SETTINGS_SCENE;
        case menuConstants.PAGE_DATA_SERVER_SCRIPTS:
            return navigationConstants.DATA_SERVER_SCRIPTS_SCENE;
        case menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS:
            return navigationConstants.DATA_SYSTEM_ZONE_SETTINGS_SCENE;
        case menuConstants.PAGE_GENERIC_BARCODES:
            return navigationConstants.GENERIC_BARCODES_SCENE;
        case menuConstants.PAGE_GENERIC_CURRENCIES:
            return navigationConstants.GENERIC_CURRENCIES_SCENE;
        case menuConstants.PAGE_GENERIC_IMAGES:
            return navigationConstants.GENERIC_IMAGES_SCENE;
        case menuConstants.PAGE_GENERIC_PACKAGES:
            return navigationConstants.GENERIC_PACKAGES_SCENE;
        case menuConstants.PAGE_GENERIC_PRODUCT_SHEETS:
            return navigationConstants.GENERIC_PRODUCT_SHEETS_SCENE;
        case menuConstants.PAGE_GENERIC_STATIC_SQL:
            return navigationConstants.GENERIC_STATIC_SQL_SCENE;
        case menuConstants.PAGE_GENERIC_UNITS:
            return navigationConstants.GENERIC_UNITS_SCENE;
        case menuConstants.PAGE_MASTER_BASES:
            return navigationConstants.MASTER_BASES_SCENE;
        case menuConstants.PAGE_MASTER_COLORANTS:
            return navigationConstants.MASTER_COLORANTS_SCENE;
        case menuConstants.PAGE_MASTER_COLORS:
            return navigationConstants.MASTER_COLORS_SCENE;
        case menuConstants.PAGE_MASTER_FANDECKS:
            return navigationConstants.MASTER_FANDECKS_SCENE;
        case menuConstants.PAGE_MASTER_FORMULAS:
            return navigationConstants.MASTER_FORMULAS_SCENE;
        case menuConstants.PAGE_MASTER_FORMULA_NOTES:
            return navigationConstants.MASTER_FORMULA_NOTES_SCENE;
        case menuConstants.PAGE_MASTER_PRODUCTS:
            return navigationConstants.MASTER_PRODUCTS_SCENE;
        case menuConstants.PAGE_MASTER_REDLIKE_OPTIONS:
            return navigationConstants.MASTER_REDLIKE_OPTIONS_SCENE;
        case menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES:
            return navigationConstants.MASTER_REDLIKE_PRIVILEGES_SCENE;
        case menuConstants.PAGE_MASTER_REDLIKE_USERS:
            return navigationConstants.MASTER_REDLIKE_USERS_SCENE;
        case menuConstants.PAGE_OPTIONS_GLOBAL_SETTINGS:
            return navigationConstants.GLOBAL_OPTIONS_SCENE;
        case menuConstants.PAGE_OPTIONS_USER_SETTINGS:
            return navigationConstants.USER_OPTIONS_SCENE;
        case menuConstants.PAGE_SYSTEM_BASES:
            return navigationConstants.SYSTEM_BASES_SCENE;
        case menuConstants.PAGE_SYSTEM_COLORANTS:
            return navigationConstants.SYSTEM_COLORANTS_SCENE;
        case menuConstants.PAGE_SYSTEM_COLORS:
            return navigationConstants.SYSTEM_COLORS_SCENE;
        case menuConstants.PAGE_SYSTEM_FANDECKS:
            return navigationConstants.SYSTEM_FANDECKS_SCENE;
        case menuConstants.PAGE_SYSTEM_FORMULAS:
            return navigationConstants.SYSTEM_FORMULAS_SCENE;
        case menuConstants.PAGE_SYSTEM_FORMULA_NOTES:
            return navigationConstants.SYSTEM_FORMULA_NOTES_SCENE;
        case menuConstants.PAGE_SYSTEM_PRICES:
            return navigationConstants.SYSTEM_PRICES_SCENE;
        case menuConstants.PAGE_SYSTEM_PRODUCT_GROUPS:
            return navigationConstants.SYSTEM_PRODUCT_GROUPS_SCENE;
        case menuConstants.PAGE_SYSTEM_PRODUCTS:
            return navigationConstants.SYSTEM_PRODUCTS_SCENE;
        case menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS:
            return navigationConstants.SYSTEM_REDLIKE_OPTIONS_SCENE;
        case menuConstants.PAGE_SYSTEM_REDLIKE_USERS:
            return navigationConstants.SYSTEM_REDLIKE_USERS_SCENE;
        case menuConstants.PAGE_ZONE_BASES:
            return navigationConstants.ZONE_BASES_SCENE;
        case menuConstants.PAGE_ZONE_COLORANTS:
            return navigationConstants.ZONE_COLORANTS_SCENE;
        case menuConstants.PAGE_ZONE_COLORS:
            return navigationConstants.ZONE_COLORS_SCENE;
        case menuConstants.PAGE_ZONE_FANDECKS:
            return navigationConstants.ZONE_FANDECKS_SCENE;
        case menuConstants.PAGE_ZONE_FORMULAS:
            return navigationConstants.ZONE_FORMULAS_SCENE;
        case menuConstants.PAGE_ZONE_FORMULA_NOTES:
            return navigationConstants.ZONE_FORMULA_NOTES_SCENE;
        case menuConstants.PAGE_ZONE_PRICES:
            return navigationConstants.ZONE_PRICES_SCENE;
        case menuConstants.PAGE_ZONE_PRODUCT_GROUPS:
            return navigationConstants.ZONE_PRODUCT_GROUPS_SCENE;
        case menuConstants.PAGE_ZONE_PRODUCTS:
            return navigationConstants.ZONE_PRODUCTS_SCENE;
        case menuConstants.PAGE_ZONE_REDLIKE_OPTIONS:
            return navigationConstants.ZONE_REDLIKE_OPTIONS_SCENE;
        case menuConstants.PAGE_ZONE_REDLIKE_USERS:
            return navigationConstants.ZONE_REDLIKE_USERS_SCENE;
        default:
            return null;
    }
};

export const getSceneByPath = (path: string): Scene => {
    switch (path) {
        case pathConstants.PATH_COMPANIES:
            return navigationTypes.COMPANY_SCENE;
        case pathConstants.PATH_DEBUG:
            return navigationTypes.DEBUG_SCENE;
        case pathConstants.PATH_DATA_EXPORT:
            return navigationTypes.DATA_EXPORT_SCENE;
        case pathConstants.PATH_DATA_IMPORT:
            return navigationTypes.DATA_IMPORT_SCENE;
        case pathConstants.PATH_DATA_MASTER_SETTINGS:
            return navigationTypes.DATA_MASTER_SETTINGS_SCENE;
        case pathConstants.PATH_DATA_SERVER_SCRIPTS:
            return navigationTypes.DATA_SERVER_SCRIPTS_SCENE;
        case pathConstants.PATH_DATA_SYSTEM_ZONE_SETTINGS:
            return navigationTypes.DATA_SYSTEM_ZONE_SETTINGS_SCENE;
        case pathConstants.PATH_GENERIC_BARCODES:
            return navigationTypes.GENERIC_BARCODES_SCENE;
        case pathConstants.PATH_GENERIC_CURRENCIES:
            return navigationTypes.GENERIC_CURRENCIES_SCENE;
        case pathConstants.PATH_GENERIC_IMAGES:
            return navigationTypes.GENERIC_IMAGES_SCENE;
        case pathConstants.PATH_GENERIC_PACKAGES:
            return navigationTypes.GENERIC_PACKAGES_SCENE;
        case pathConstants.PATH_GENERIC_PRODUCT_SHEETS:
            return navigationTypes.GENERIC_PRODUCT_SHEETS_SCENE;
        case pathConstants.PATH_GENERIC_STATIC_SQL:
            return navigationTypes.GENERIC_STATIC_SQL_SCENE;
        case pathConstants.PATH_GENERIC_UNITS:
            return navigationTypes.GENERIC_UNITS_SCENE;
        case pathConstants.PATH_GLOBAL_OPTIONS:
            return navigationTypes.GLOBAL_OPTIONS_SCENE;
        case pathConstants.PATH_USER_OPTIONS:
            return navigationTypes.USER_OPTIONS_SCENE;
        case pathConstants.PATH_MASTER_BASES:
            return navigationTypes.MASTER_BASES_SCENE;
        case pathConstants.PATH_MASTER_COLORANTS:
            return navigationTypes.MASTER_COLORANTS_SCENE;
        case pathConstants.PATH_MASTER_COLORS:
            return navigationTypes.MASTER_COLORS_SCENE;
        case pathConstants.PATH_MASTER_FANDECKS:
            return navigationTypes.MASTER_FANDECKS_SCENE;
        case pathConstants.PATH_MASTER_FORMULAS:
            return navigationTypes.MASTER_FORMULAS_SCENE;
        case pathConstants.PATH_MASTER_FORMULA_NOTES:
            return navigationTypes.MASTER_FORMULA_NOTES_SCENE;
        case pathConstants.PATH_MASTER_PRODUCTS:
            return navigationTypes.MASTER_PRODUCTS_SCENE;
        case pathConstants.PATH_MASTER_REDLIKE_OPTIONS:
            return navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE;
        case pathConstants.PATH_MASTER_REDLIKE_PRIVILEGES:
            return navigationTypes.MASTER_REDLIKE_PRIVILEGES_SCENE;
        case pathConstants.PATH_MASTER_REDLIKE_USERS:
            return navigationTypes.MASTER_REDLIKE_USERS_SCENE;
        case pathConstants.PATH_SERVER_MONITORING:
            return navigationTypes.SERVER_MONITORING_SCENE;
        case pathConstants.PATH_SYSTEM_BASES:
            return navigationTypes.SYSTEM_BASES_SCENE;
        case pathConstants.PATH_SYSTEM_COLORANTS:
            return navigationTypes.SYSTEM_COLORANTS_SCENE;
        case pathConstants.PATH_SYSTEM_COLORS:
            return navigationTypes.SYSTEM_COLORS_SCENE;
        case pathConstants.PATH_SYSTEM_FANDECKS:
            return navigationTypes.SYSTEM_FANDECKS_SCENE;
        case pathConstants.PATH_SYSTEM_FORMULAS:
            return navigationTypes.SYSTEM_FORMULAS_SCENE;
        case pathConstants.PATH_SYSTEM_FORMULA_NOTES:
            return navigationTypes.SYSTEM_FORMULA_NOTES_SCENE;
        case pathConstants.PATH_SYSTEM_PRICES:
            return navigationTypes.SYSTEM_PRICES_SCENE;
        case pathConstants.PATH_SYSTEM_PRODUCT_GROUPS:
            return navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE;
        case pathConstants.PATH_SYSTEM_PRODUCTS:
            return navigationTypes.SYSTEM_PRODUCTS_SCENE;
        case pathConstants.PATH_SYSTEM_REDLIKE_OPTIONS:
            return navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE;
        case pathConstants.PATH_SYSTEM_REDLIKE_USERS:
            return navigationTypes.SYSTEM_REDLIKE_USERS_SCENE;
        case pathConstants.PATH_USER_ACTION_HISTORY:
            return navigationTypes.USER_ACTION_HISTORY_SCENE;
        case pathConstants.PATH_USERS:
            return navigationTypes.USERS_SCENE;
        case pathConstants.PATH_ZONE_BASES:
            return navigationTypes.ZONE_BASES_SCENE;
        case pathConstants.PATH_ZONE_COLORANTS:
            return navigationTypes.ZONE_COLORANTS_SCENE;
        case pathConstants.PATH_ZONE_COLORS:
            return navigationTypes.ZONE_COLORS_SCENE;
        case pathConstants.PATH_ZONE_FANDECKS:
            return navigationTypes.ZONE_FANDECKS_SCENE;
        case pathConstants.PATH_ZONE_FORMULAS:
            return navigationTypes.ZONE_FORMULAS_SCENE;
        case pathConstants.PATH_ZONE_FORMULA_NOTES:
            return navigationTypes.ZONE_FORMULA_NOTES_SCENE;
        case pathConstants.PATH_ZONE_PRICES:
            return navigationTypes.ZONE_PRICES_SCENE;
        case pathConstants.PATH_ZONE_PRODUCT_GROUPS:
            return navigationTypes.ZONE_PRODUCT_GROUPS_SCENE;
        case pathConstants.PATH_ZONE_PRODUCTS:
            return navigationTypes.ZONE_PRODUCTS_SCENE;
        case pathConstants.PATH_ZONE_REDLIKE_OPTIONS:
            return navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE;
        case pathConstants.PATH_ZONE_REDLIKE_USERS:
            return navigationTypes.ZONE_REDLIKE_USERS_SCENE;
        default:
            return navigationTypes.HOMEPAGE;
    }
};

export const getPageByScene = (scene: Scene): string => {
    switch (scene) {
        case navigationConstants.DATA_EXPORT_SCENE:
            return menuConstants.PAGE_DATA_EXPORT;
        case navigationConstants.DATA_IMPORT_SCENE:
            return menuConstants.PAGE_DATA_IMPORT;
        case navigationConstants.DATA_MASTER_SETTINGS_SCENE:
            return menuConstants.PAGE_DATA_MASTER_SETTINGS;
        case navigationConstants.DATA_SERVER_SCRIPTS_SCENE:
            return menuConstants.PAGE_DATA_SERVER_SCRIPTS;

        case navigationConstants.DATA_SYSTEM_ZONE_SETTINGS_SCENE:
            return menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS;

        case navigationConstants.GENERIC_CURRENCIES_SCENE:
            return menuConstants.PAGE_GENERIC_CURRENCIES;

        case navigationConstants.GENERIC_IMAGES_SCENE:
            return menuConstants.PAGE_GENERIC_IMAGES;

        case navigationConstants.GENERIC_PACKAGES_SCENE:
            return menuConstants.PAGE_GENERIC_PACKAGES;

        case navigationConstants.GENERIC_PRODUCT_SHEETS_SCENE:
            return menuConstants.PAGE_GENERIC_PRODUCT_SHEETS;

        case navigationConstants.GENERIC_STATIC_SQL_SCENE:
            return menuConstants.PAGE_GENERIC_STATIC_SQL;

        case navigationConstants.GENERIC_UNITS_SCENE:
            return menuConstants.PAGE_GENERIC_UNITS;

        case navigationConstants.MASTER_BASES_SCENE:
            return menuConstants.PAGE_MASTER_BASES;

        case navigationConstants.MASTER_COLORANTS_SCENE:
            return menuConstants.PAGE_MASTER_COLORANTS;

        case navigationConstants.MASTER_COLORS_SCENE:
            return menuConstants.PAGE_MASTER_COLORS;

        case navigationConstants.MASTER_FANDECKS_SCENE:
            return menuConstants.PAGE_MASTER_FANDECKS;

        case navigationConstants.MASTER_FORMULAS_SCENE:
            return menuConstants.PAGE_MASTER_FORMULAS;

        case navigationConstants.MASTER_FORMULA_NOTES_SCENE:
            return menuConstants.PAGE_MASTER_FORMULA_NOTES;

        case navigationConstants.MASTER_PRODUCTS_SCENE:
            return menuConstants.PAGE_MASTER_PRODUCTS;

        case navigationConstants.MASTER_REDLIKE_OPTIONS_SCENE:
            return menuConstants.PAGE_MASTER_REDLIKE_OPTIONS;

        case navigationConstants.MASTER_REDLIKE_PRIVILEGES_SCENE:
            return menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES;

        case navigationConstants.MASTER_REDLIKE_USERS_SCENE:
            return menuConstants.PAGE_MASTER_REDLIKE_USERS;

        case navigationConstants.GLOBAL_OPTIONS_SCENE:
            return menuConstants.PAGE_OPTIONS_GLOBAL_SETTINGS;

        case navigationConstants.USER_OPTIONS_SCENE:
            return menuConstants.PAGE_OPTIONS_USER_SETTINGS;

        case navigationConstants.SERVER_MONITORING_SCENE:
            return menuConstants.PAGE_MONITORING;
        case navigationConstants.COMPANY_SCENE:
            return menuConstants.PAGE_COMPANIES;
        case navigationConstants.USERS_SCENE:
            return menuConstants.PAGE_USERS;

        case navigationConstants.SYSTEM_BASES_SCENE:
            return menuConstants.PAGE_SYSTEM_BASES;

        case navigationConstants.SYSTEM_COLORANTS_SCENE:
            return menuConstants.PAGE_SYSTEM_COLORANTS;

        case navigationConstants.SYSTEM_COLORS_SCENE:
            return menuConstants.PAGE_SYSTEM_COLORS;

        case navigationConstants.SYSTEM_FANDECKS_SCENE:
            return menuConstants.PAGE_SYSTEM_FANDECKS;

        case navigationConstants.SYSTEM_FORMULAS_SCENE:
            return menuConstants.PAGE_SYSTEM_FORMULAS;

        case navigationConstants.SYSTEM_FORMULA_NOTES_SCENE:
            return menuConstants.PAGE_SYSTEM_FORMULA_NOTES;

        case navigationConstants.SYSTEM_PRICES_SCENE:
            return menuConstants.PAGE_SYSTEM_PRICES;

        case navigationConstants.SYSTEM_PRODUCT_GROUPS_SCENE:
            return menuConstants.PAGE_SYSTEM_PRODUCT_GROUPS;

        case navigationConstants.SYSTEM_PRODUCTS_SCENE:
            return menuConstants.PAGE_SYSTEM_PRODUCTS;

        case navigationConstants.SYSTEM_REDLIKE_OPTIONS_SCENE:
            return menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS;

        case navigationConstants.SYSTEM_REDLIKE_USERS_SCENE:
            return menuConstants.PAGE_SYSTEM_REDLIKE_USERS;

        case navigationConstants.ZONE_BASES_SCENE:
            return menuConstants.PAGE_ZONE_BASES;

        case navigationConstants.ZONE_COLORANTS_SCENE:
            return menuConstants.PAGE_ZONE_COLORANTS;

        case navigationConstants.ZONE_COLORS_SCENE:
            return menuConstants.PAGE_ZONE_COLORS;

        case navigationConstants.ZONE_FANDECKS_SCENE:
            return menuConstants.PAGE_ZONE_FANDECKS;

        case navigationConstants.ZONE_FORMULAS_SCENE:
            return menuConstants.PAGE_ZONE_FORMULAS;

        case navigationConstants.ZONE_FORMULA_NOTES_SCENE:
            return menuConstants.PAGE_ZONE_FORMULA_NOTES;

        case navigationConstants.ZONE_PRICES_SCENE:
            return menuConstants.PAGE_ZONE_PRICES;

        case navigationConstants.ZONE_PRODUCT_GROUPS_SCENE:
            return menuConstants.PAGE_ZONE_PRODUCT_GROUPS;

        case navigationConstants.ZONE_PRODUCTS_SCENE:
            return menuConstants.PAGE_ZONE_PRODUCTS;

        case navigationConstants.ZONE_REDLIKE_OPTIONS_SCENE:
            return menuConstants.PAGE_ZONE_REDLIKE_OPTIONS;

        case navigationConstants.ZONE_REDLIKE_USERS_SCENE:
            return menuConstants.PAGE_ZONE_REDLIKE_USERS;
        default:
            return "";
    }
};
