export const HISTORICAL_ACTION_TYPE_DELETE = 0;
export const HISTORICAL_ACTION_TYPE_DUPLICATE = 1;
export const HISTORICAL_ACTION_TYPE_INSERT = 2;
export const HISTORICAL_ACTION_TYPE_SET = 3;
export const HISTORICAL_ACTION_TYPE_UPDATE = 4;

export type HistoricalActionType =
    | typeof HISTORICAL_ACTION_TYPE_DELETE
    | typeof HISTORICAL_ACTION_TYPE_DUPLICATE
    | typeof HISTORICAL_ACTION_TYPE_INSERT
    | typeof HISTORICAL_ACTION_TYPE_SET
    | typeof HISTORICAL_ACTION_TYPE_UPDATE;

export const HISTORICAL_ACTION_TYPES_LIST: Array<HistoricalActionType> = [
    HISTORICAL_ACTION_TYPE_DELETE,
    HISTORICAL_ACTION_TYPE_DUPLICATE,
    HISTORICAL_ACTION_TYPE_INSERT,
    HISTORICAL_ACTION_TYPE_SET,
    HISTORICAL_ACTION_TYPE_UPDATE
];

export const HISTORICAL_ITEM_TYPE_BARCODE = 50;
export const HISTORICAL_ITEM_TYPE_BASE = 51;
export const HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT = 52;
export const HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE = 53;
export const HISTORICAL_ITEM_TYPE_COLOR = 54;
export const HISTORICAL_ITEM_TYPE_COLOR_IN_FANDECK = 55;
export const HISTORICAL_ITEM_TYPE_COLORANT = 56;
export const HISTORICAL_ITEM_TYPE_COLORANT_PACKAGE = 57;
export const HISTORICAL_ITEM_TYPE_CURRENCY = 58;
export const HISTORICAL_ITEM_TYPE_FANDECK = 59;
export const HISTORICAL_ITEM_TYPE_FILE = 60;
export const HISTORICAL_ITEM_TYPE_FILE_CATEGORY = 61;
export const HISTORICAL_ITEM_TYPE_FORMULA = 62;
export const HISTORICAL_ITEM_TYPE_FORMULA_NOTE = 63;
export const HISTORICAL_ITEM_TYPE_IMAGE = 64;
export const HISTORICAL_ITEM_TYPE_IMAGE_CATEGORY = 65;
export const HISTORICAL_ITEM_TYPE_LAB_COMPANY = 0;
export const HISTORICAL_ITEM_TYPE_LAB_DATABASE = 1;
export const HISTORICAL_ITEM_TYPE_LAB_EXPORT_TASK = 2;
export const HISTORICAL_ITEM_TYPE_LAB_IMPORT_TASK = 3;
export const HISTORICAL_ITEM_TYPE_LAB_OPTION = 4;
export const HISTORICAL_ITEM_TYPE_LAB_SCRIPT = 30;
export const HISTORICAL_ITEM_TYPE_LAB_USER = 5;
export const HISTORICAL_ITEM_TYPE_LAB_USER_GROUP = 6;
export const HISTORICAL_ITEM_TYPE_LAB_USER_GROUP_PRIVILEGE = 7;
export const HISTORICAL_ITEM_TYPE_MARGIN = 66;
export const HISTORICAL_ITEM_TYPE_OPTION = 67;
export const HISTORICAL_ITEM_TYPE_PACKAGE = 68;
export const HISTORICAL_ITEM_TYPE_PRICE = 69;
export const HISTORICAL_ITEM_TYPE_PRICE_OPTIONS = 70;
export const HISTORICAL_ITEM_TYPE_PRODUCT = 71;
export const HISTORICAL_ITEM_TYPE_PRODUCT_GROUP = 72;
export const HISTORICAL_ITEM_TYPE_SPECTRO_TYPE = 763;
export const HISTORICAL_ITEM_TYPE_SYSTEM = 74;
export const HISTORICAL_ITEM_TYPE_UNIT = 75;
export const HISTORICAL_ITEM_TYPE_USER = 76;
export const HISTORICAL_ITEM_TYPE_USER_GROUP = 77;
export const HISTORICAL_ITEM_TYPE_USER_GROUP_PRIVILEGE = 78;
export const HISTORICAL_ITEM_TYPE_ZONE = 79;

export type HistoricalItemType =
    | typeof HISTORICAL_ITEM_TYPE_BARCODE
    | typeof HISTORICAL_ITEM_TYPE_BASE
    | typeof HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT
    | typeof HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE
    | typeof HISTORICAL_ITEM_TYPE_COLOR
    | typeof HISTORICAL_ITEM_TYPE_COLOR_IN_FANDECK
    | typeof HISTORICAL_ITEM_TYPE_COLORANT
    | typeof HISTORICAL_ITEM_TYPE_COLORANT_PACKAGE
    | typeof HISTORICAL_ITEM_TYPE_CURRENCY
    | typeof HISTORICAL_ITEM_TYPE_FANDECK
    | typeof HISTORICAL_ITEM_TYPE_FILE
    | typeof HISTORICAL_ITEM_TYPE_FILE_CATEGORY
    | typeof HISTORICAL_ITEM_TYPE_FORMULA
    | typeof HISTORICAL_ITEM_TYPE_FORMULA_NOTE
    | typeof HISTORICAL_ITEM_TYPE_IMAGE
    | typeof HISTORICAL_ITEM_TYPE_IMAGE_CATEGORY
    | typeof HISTORICAL_ITEM_TYPE_LAB_COMPANY
    | typeof HISTORICAL_ITEM_TYPE_LAB_DATABASE
    | typeof HISTORICAL_ITEM_TYPE_LAB_EXPORT_TASK
    | typeof HISTORICAL_ITEM_TYPE_LAB_IMPORT_TASK
    | typeof HISTORICAL_ITEM_TYPE_LAB_OPTION
    | typeof HISTORICAL_ITEM_TYPE_LAB_SCRIPT
    | typeof HISTORICAL_ITEM_TYPE_LAB_USER
    | typeof HISTORICAL_ITEM_TYPE_LAB_USER_GROUP
    | typeof HISTORICAL_ITEM_TYPE_LAB_USER_GROUP_PRIVILEGE
    | typeof HISTORICAL_ITEM_TYPE_MARGIN
    | typeof HISTORICAL_ITEM_TYPE_OPTION
    | typeof HISTORICAL_ITEM_TYPE_PACKAGE
    | typeof HISTORICAL_ITEM_TYPE_PRICE
    | typeof HISTORICAL_ITEM_TYPE_PRICE_OPTIONS
    | typeof HISTORICAL_ITEM_TYPE_PRODUCT
    | typeof HISTORICAL_ITEM_TYPE_PRODUCT_GROUP
    | typeof HISTORICAL_ITEM_TYPE_SPECTRO_TYPE
    | typeof HISTORICAL_ITEM_TYPE_SYSTEM
    | typeof HISTORICAL_ITEM_TYPE_UNIT
    | typeof HISTORICAL_ITEM_TYPE_USER
    | typeof HISTORICAL_ITEM_TYPE_USER_GROUP
    | typeof HISTORICAL_ITEM_TYPE_USER_GROUP_PRIVILEGE
    | typeof HISTORICAL_ITEM_TYPE_ZONE;

export const HISTORICAL_ITEM_TYPES_LIST: Array<HistoricalItemType> = [
    HISTORICAL_ITEM_TYPE_BARCODE,
    HISTORICAL_ITEM_TYPE_BASE,
    HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT,
    HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE,
    HISTORICAL_ITEM_TYPE_COLOR,
    HISTORICAL_ITEM_TYPE_COLOR_IN_FANDECK,
    HISTORICAL_ITEM_TYPE_COLORANT,
    HISTORICAL_ITEM_TYPE_COLORANT_PACKAGE,
    HISTORICAL_ITEM_TYPE_CURRENCY,
    HISTORICAL_ITEM_TYPE_FANDECK,
    HISTORICAL_ITEM_TYPE_FILE,
    HISTORICAL_ITEM_TYPE_FILE_CATEGORY,
    HISTORICAL_ITEM_TYPE_FORMULA,
    HISTORICAL_ITEM_TYPE_FORMULA_NOTE,
    HISTORICAL_ITEM_TYPE_IMAGE,
    HISTORICAL_ITEM_TYPE_IMAGE_CATEGORY,
    HISTORICAL_ITEM_TYPE_LAB_COMPANY,
    HISTORICAL_ITEM_TYPE_LAB_DATABASE,
    HISTORICAL_ITEM_TYPE_LAB_EXPORT_TASK,
    HISTORICAL_ITEM_TYPE_LAB_IMPORT_TASK,
    HISTORICAL_ITEM_TYPE_LAB_OPTION,
    HISTORICAL_ITEM_TYPE_LAB_SCRIPT,
    HISTORICAL_ITEM_TYPE_LAB_USER,
    HISTORICAL_ITEM_TYPE_LAB_USER_GROUP,
    HISTORICAL_ITEM_TYPE_LAB_USER_GROUP_PRIVILEGE,
    HISTORICAL_ITEM_TYPE_MARGIN,
    HISTORICAL_ITEM_TYPE_OPTION,
    HISTORICAL_ITEM_TYPE_PACKAGE,
    HISTORICAL_ITEM_TYPE_PRICE,
    HISTORICAL_ITEM_TYPE_PRICE_OPTIONS,
    HISTORICAL_ITEM_TYPE_PRODUCT,
    HISTORICAL_ITEM_TYPE_PRODUCT_GROUP,
    HISTORICAL_ITEM_TYPE_SPECTRO_TYPE,
    HISTORICAL_ITEM_TYPE_SYSTEM,
    HISTORICAL_ITEM_TYPE_UNIT,
    HISTORICAL_ITEM_TYPE_USER,
    HISTORICAL_ITEM_TYPE_USER_GROUP,
    HISTORICAL_ITEM_TYPE_USER_GROUP_PRIVILEGE,
    HISTORICAL_ITEM_TYPE_ZONE
];
