export type MenuSection =
    | typeof HEADER_MASTER
    | typeof HEADER_SYSTEM
    | typeof HEADER_ZONE
    | typeof HEADER_OPTIONS
    | typeof HEADER_GENERIC;

// menu item definitions
export const MENU_HEADER_ITEM = "menu_header_item";
export const MENU_MAIN_PAGE = "main_page";
export const MENU_SUBMENU_ITEM = "sub_menu_item";
export const MENU_DATA_ITEM = "data_item";
export const MENU_OPTION_ITEM = "option_item";
export const MENU_USER_OPTION_ITEM = "user_option_item";
export const MENU_PRIVILEGE_ITEM = "privilege_item";

// header
export const PAGE_COMPANIES = "global_companies";
export const TABLE_COMPANIES = "global_companies";
export const PAGE_MONITORING = "global_monitoring_databases";
export const TABLE_MONITORING_DATABASES = "global_monitoring_databases";
export const PAGE_USER_ACTIONS_HISTORY = "global_user_actions_history";
export const TABLE_USER_ACTIONS_HISTORY = "global_user_actions_history";
export const PAGE_USERS = "global_users";
export const TABLE_USER_GROUPS = "global_user_groups";
export const TABLE_USERS = "global_users";
export const TABLE_USER_GROUP_PRIVILEGES = "global_user_group_privileges";

// general
export const TABLE_DATA_DATABASES = "global_databases";
export const PAGE_DATA_EXPORT = "global_data_export";
export const TABLE_DATA_EXPORT = "global_data_export";
export const PAGE_DATA_IMPORT = "global_data_import";
export const TABLE_DATA_IMPORT = "global_data_import";
export const PAGE_DATA_MASTER_SETTINGS = "global_data_master";
export const PAGE_DATA_SYSTEM_ZONE_SETTINGS = "global_data_system_zone";
export const PAGE_DATA_SERVER_SCRIPTS = "global_data_server_scripts";
export const TABLE_GLOBAL_SYSTEMS = "global_systems";
export const TABLE_GLOBAL_ZONES = "global_zones";
export const TABLE_AVAILABLE_REDLIKE_USER_GROUPS = "global_available_redlike_user_groups";
export const TABLE_SELECTED_REDLIKE_USER_GROUPS = "global_selected_redlike_user_groups";
export const TABLE_GLOBAL_ZONES_DUPLICATE = "global_zones_duplicate";

// master
export const HEADER_MASTER = "master";
export const PAGE_MASTER_BASES = "master_bases";
export const TABLE_MASTER_BASES = "master_bases";
export const TABLE_MASTER_COLORANT_BATCHES = "master_colorant_batches";
export const PAGE_MASTER_COLORANTS = "master_colorants";
export const TABLE_MASTER_COLORANTS = "master_colorants";
export const PAGE_MASTER_COLORS = "master_colors";
export const TABLE_MASTER_COLORS = "master_colors";
export const TABLE_MASTER_COLORS_DETAIL = "master_colors_detail";
export const TABLE_MASTER_COLOR_DATA = "master_color_data";
export const TABLE_MASTER_COLORS_WITH_FORMULA_INFO = "master_colors_with_formula_info";
export const TABLE_MASTER_COLORS_WITH_FORMULA_INFO_DETAIL = "master_colors_with_formula_info_detail";
export const PAGE_MASTER_FANDECKS = "master_fandecks";
export const TABLE_MASTER_FANDECKS = "master_fandecks";
export const PAGE_MASTER_FORMULAS = "master_formulas";
export const TABLE_MASTER_FORMULA_COLORANTS = "master_formula_colorants";
export const PAGE_MASTER_FORMULA_NOTES = "master_formula_notes";
export const TABLE_MASTER_FORMULA_NOTES = "master_formula_notes";
export const PAGE_MASTER_PRODUCTS = "master_products";
export const TABLE_MASTER_PRODUCTS_BASES = "master_bases_in_product";
export const TABLE_MASTER_PRODUCTS_PRODUCTS = "master_products";
export const PAGE_MASTER_REDLIKE_OPTIONS = "master_redlike_options";
export const TABLE_MASTER_REDLIKE_OPTIONS = "master_redlike_options";
export const PAGE_MASTER_REDLIKE_USERS = "master_redlike_users";
export const TABLE_MASTER_REDLIKE_USERS = "master_redlike_users";
export const TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS = "master_redlike_users_with_user_groups";
export const PAGE_MASTER_REDLIKE_PRIVILEGES = "master_redlike_privileges";
export const TABLE_MASTER_REDLIKE_USER_GROUPS = "master_redlike_user_groups";
export const TABLE_MASTER_REDLIKE_PRIVILEGES = "master_redlike_privileges";

// master system
export const TABLE_MASTER_SYSTEM_BASES = "master_system_bases";
export const TABLE_MASTER_SYSTEM_COLORANTS = "master_system_colorants";
export const TABLE_MASTER_SYSTEM_COLORANT_PACKAGES = "master_system_colorant_packages";
export const TABLE_MASTER_SYSTEM_FANDECKS = "master_system_fandecks";
export const TABLE_MASTER_SYSTEM_PRODUCT_GROUPS = "master_system_product_groups";
export const TABLE_MASTER_SYSTEM_PRODUCTS_BASES = "master_system_bases_in_product";
export const TABLE_MASTER_SYSTEM_PRODUCTS_BASE_PACKAGES = "master_system_base_in_product_packages";
export const TABLE_MASTER_SYSTEM_PRODUCTS_PRODUCTS = "master_system_products";

// system
export const HEADER_SYSTEM = "system";
export const PAGE_SYSTEM_BASES = "system_bases";
export const TABLE_SYSTEM_BASES = "system_bases";
export const PAGE_SYSTEM_COLORANTS = "system_colorants";
export const TABLE_SYSTEM_COLORANTS = "system_colorants";
export const TABLE_SYSTEM_COLORANT_PACKAGES = "system_colorant_packages";
export const PAGE_SYSTEM_COLORS = "system_colors";
export const TABLE_SYSTEM_COLORS = "system_colors";
export const TABLE_SYSTEM_COLORS_DETAIL = "system_colors_detail";
export const TABLE_SYSTEM_COLOR_DATA = "system_color_data";
export const TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO = "system_colors_with_formula_info";
export const TABLE_SYSTEM_COLORS_WITH_FORMULA_INFO_DETAIL = "system_colors_with_formula_info_detail";
export const PAGE_SYSTEM_FANDECKS = "system_fandecks";
export const TABLE_SYSTEM_FANDECKS = "system_fandecks";
export const PAGE_SYSTEM_FORMULAS = "system_formulas";
export const TABLE_SYSTEM_FORMULA_COLORANTS = "system_formula_colorants";
export const PAGE_SYSTEM_FORMULA_NOTES = "system_formula_notes";
export const TABLE_SYSTEM_FORMULA_NOTES = "system_formula_notes";
export const PAGE_SYSTEM_PRICES = "system_prices";
export const TABLE_SYSTEM_PRICE_GENERIC = "system_price_generic";
export const TABLE_SYSTEM_PRICE_GROUPS = "system_price_groups";
export const TABLE_SYSTEM_PRICE_MARGIN = "system_price_margin";
export const TABLE_SYSTEM_PRICE_OPTIONS = "system_price_options";
export const TABLE_SYSTEM_PRICE_SURCHARGE = "system_price_surcharge";
export const PAGE_SYSTEM_PRODUCT_GROUPS = "system_product_groups";
export const TABLE_SYSTEM_PRODUCT_GROUPS = "system_product_groups";
export const PAGE_SYSTEM_PRODUCTS = "system_products";
export const TABLE_SYSTEM_PRODUCTS_BASES = "system_bases_in_product";
export const TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES = "system_base_in_product_packages";
export const TABLE_SYSTEM_PRODUCTS_PRODUCTS = "system_products";
export const PAGE_SYSTEM_REDLIKE_OPTIONS = "system_redlike_options";
export const TABLE_SYSTEM_REDLIKE_OPTIONS = "system_redlike_options";
export const PAGE_SYSTEM_REDLIKE_USERS = "system_redlike_users";
export const TABLE_SYSTEM_REDLIKE_USERS = "system_redlike_users";
export const TABLE_SYSTEM_REDLIKE_USERS_WITH_USER_GROUPS = "system_redlike_users_with_user_groups";

// system zone
export const TABLE_SYSTEM_ZONE_BASES = "system_zone_bases";
export const TABLE_SYSTEM_ZONE_COLORANTS = "system_zone_colorants";
export const TABLE_SYSTEM_ZONE_COLORANT_PACKAGES = "system_zone_colorant_packages";
export const TABLE_SYSTEM_ZONE_FANDECKS = "system_zone_fandecks";
export const TABLE_SYSTEM_ZONE_PRODUCT_GROUPS = "system_zone_product_groups";
export const TABLE_SYSTEM_ZONE_PRODUCTS_BASES = "system_zone_bases_in_product";
export const TABLE_SYSTEM_ZONE_PRODUCTS_BASE_PACKAGES = "system_zone_base_in_product_packages";
export const TABLE_SYSTEM_ZONE_PRODUCTS_PRODUCTS = "system_zone_products";
export const TABLE_SYSTEM_ZONE_PRODUCTS_DUPLICATE = "system_zone_products_duplicate";

// zone
export const HEADER_ZONE = "zone";
export const PAGE_ZONE_BASES = "zone_bases";
export const TABLE_ZONE_BASES = "zone_bases";
export const PAGE_ZONE_COLORANTS = "zone_colorants";
export const TABLE_ZONE_COLORANTS = "zone_colorants";
export const TABLE_ZONE_COLORANT_PACKAGES = "zone_colorant_packages";
export const PAGE_ZONE_COLORS = "zone_colors";
export const TABLE_ZONE_COLORS = "zone_colors";
export const TABLE_ZONE_COLORS_DETAIL = "zone_colors_detail";
export const TABLE_ZONE_COLOR_DATA = "zone_color_data";
export const TABLE_ZONE_COLORS_WITH_FORMULA_INFO = "zone_colors_with_formula_info";
export const TABLE_ZONE_COLORS_WITH_FORMULA_INFO_DETAIL = "zone_colors_with_formula_info_detail";
export const PAGE_ZONE_FANDECKS = "zone_fandecks";
export const TABLE_ZONE_FANDECKS = "zone_fandecks";
export const PAGE_ZONE_FORMULAS = "zone_formulas";
export const TABLE_ZONE_FORMULA_COLORANTS = "zone_formula_colorants";
export const PAGE_ZONE_FORMULA_NOTES = "zone_formula_notes";
export const TABLE_ZONE_FORMULA_NOTES = "zone_formula_notes";
export const PAGE_ZONE_PRICES = "zone_prices";
export const TABLE_ZONE_PRICE_GENERIC = "zone_price_generic";
export const TABLE_ZONE_PRICE_GROUPS = "zone_price_groups";
export const TABLE_ZONE_PRICE_MARGIN = "zone_price_margin";
export const TABLE_ZONE_PRICE_OPTIONS = "zone_price_options";
export const TABLE_ZONE_PRICE_SURCHARGE = "zone_price_surcharge";
export const PAGE_ZONE_PRODUCT_GROUPS = "zone_product_groups";
export const TABLE_ZONE_PRODUCT_GROUPS = "zone_product_groups";
export const PAGE_ZONE_PRODUCTS = "zone_products";
export const TABLE_ZONE_PRODUCTS_BASES = "zone_bases_in_product";
export const TABLE_ZONE_PRODUCTS_BASE_PACKAGES = "zone_base_in_product_packages";
export const TABLE_ZONE_PRODUCTS_PRODUCTS = "zone_products";
export const PAGE_ZONE_REDLIKE_OPTIONS = "zone_redlike_options";
export const TABLE_ZONE_REDLIKE_OPTIONS = "zone_redlike_options";
export const PAGE_ZONE_REDLIKE_USERS = "zone_redlike_users";
export const TABLE_ZONE_REDLIKE_USERS = "zone_redlike_users";
export const TABLE_ZONE_REDLIKE_USERS_WITH_USER_GROUPS = "zone_redlike_users_with_user_groups";

// generic
export const HEADER_GENERIC = "generic";
export const PAGE_GENERIC_BARCODES = "generic_barcodes";
export const TABLE_GENERIC_BARCODES = "generic_barcodes";
export const PAGE_GENERIC_CURRENCIES = "generic_currencies";
export const TABLE_GENERIC_CURRENCIES = "generic_currencies";
export const PAGE_GENERIC_IMAGES = "generic_images";
export const TABLE_GENERIC_IMAGES = "generic_images";
export const PAGE_GENERIC_PACKAGES = "generic_packages";
export const TABLE_GENERIC_PACKAGES = "generic_packages";
export const TABLE_GENERIC_PREVIEW = "generic_preview";
export const PAGE_GENERIC_PRODUCT_SHEETS = "generic_files";
export const TABLE_GENERIC_PRODUCT_SHEETS = "generic_files";
export const PAGE_GENERIC_STATIC_SQL = "generic_static_sql";
export const TABLE_GENERIC_STATIC_SQL = "generic_static_sql";
export const PAGE_GENERIC_UNITS = "generic_units";
export const TABLE_GENERIC_UNITS = "generic_units";

// options
export const HEADER_OPTIONS = "options";
export const PAGE_OPTIONS_GLOBAL_SETTINGS = "option_global_settings";
export const TABLE_OPTIONS_GLOBAL_SETTINGS_ENVIRONMENTAL = "option_global_settings_environmental";
export const TABLE_OPTIONS_GLOBAL_SETTINGS_DATA = "option_global_settings_data";
export const PAGE_OPTIONS_LICENSE = "option_license";
export const PAGE_OPTIONS_USER_SETTINGS = "option_user_settings";
export const TABLE_OPTIONS_USER_SETTINGS_ENVIRONMENTAL = "option_user_settings_environmental";

// modal
export const MODAL_ONE_TIME_ACCESS_PASSWORD = "modal_one_time_access_password";

export const TABLE_MODAL_AVAILABLE_WEB_ZONES = "modal_available_web_zones";
export const TABLE_MODAL_EXPORT_TASKS = "modal_export_tasks";
export const TABLE_MODAL_FILE_CATEGORIES = "modal_file_categories";
export const TABLE_MODAL_FILES = "modal_files";
export const TABLE_MODAL_FORMULA_NOTES = "modal_formula_notes";
export const TABLE_MODAL_ICONS = "modal_icons";
export const TABLE_MODAL_IMAGE_CATEGORIES = "modal_image_categories";
export const TABLE_MODAL_IMAGES = "modal_images";
export const TABLE_MODAL_SPECTRO_TYPES = "modal_spectro_types";
