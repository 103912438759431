import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as serverActions from "../actions/serverActions";

export interface Unknown {
    type: typeof actionConstants.UNKNOWN_ACTION;
}

export function serverUnknown(): Unknown {
    return {
        type: actionConstants.UNKNOWN_ACTION
    };
}

export interface ServerWebsocketConnecting {
    type: typeof actionConstants.SERVER_WEBSOCKET_CONNECTING;
}

export function serverWebsocketConnecting(): ServerWebsocketConnecting {
    return {
        type: actionConstants.SERVER_WEBSOCKET_CONNECTING
    };
}

export interface ServerWebsocketOpened {
    type: typeof actionConstants.SERVER_WEBSOCKET_OPENED;
}

export function serverWebsocketOpened(): ServerWebsocketOpened {
    return {
        type: actionConstants.SERVER_WEBSOCKET_OPENED
    };
}

export interface ServerWebsocketClosed {
    type: typeof actionConstants.SERVER_WEBSOCKET_CLOSED;
}

export function serverWebsocketClosed(): ServerWebsocketClosed {
    return {
        type: actionConstants.SERVER_WEBSOCKET_CLOSED
    };
}

export interface ServerWebsocketRequest {
    type: typeof actionConstants.SERVER_WEBSOCKET_REQUEST;
    payload: any;
}

export function serverWebsocketRequest(payload: Record<string, any>): ServerWebsocketRequest {
    return {
        type: actionConstants.SERVER_WEBSOCKET_REQUEST,
        payload: payload
    };
}

export interface ServerWebsocketResponse {
    type: typeof actionConstants.SERVER_WEBSOCKET_RESPONSE;
    payload: {
        data: any;
    };
}

export function serverWebsocketResponse(event: Record<string, any>): ServerWebsocketResponse {
    return {
        type: actionConstants.SERVER_WEBSOCKET_RESPONSE,
        payload: {
            data: event.data
        }
    };
}

export function serverProcessWebsocketRequest(payload: Record<string, any>, method: string): any {
    return (dispatch: any, getState: any): void => {
        const sequence = getState().server.sequence + 1;
        const sessionUuid = getState().software.sessionUuid;

        dispatch(serverActions.serverSaveRequest(sequence, method));

        payload.sequence = sequence;
        payload.sessionUuid = sessionUuid;

        dispatch(serverWebsocketRequest(payload));
    };
}

export interface ServerConnect {
    type: typeof actionConstants.SERVER_CONNECT;
    payload: {
        url: string;
    };
}

export function serverConnect(url: string): ServerConnect {
    return {
        type: actionConstants.SERVER_CONNECT,
        payload: {
            url
        }
    };
}

export interface ServerDisconnect {
    type: typeof actionConstants.SERVER_DISCONNECT;
}

export function serverDisconnect(): ServerDisconnect {
    return {
        type: actionConstants.SERVER_DISCONNECT
    };
}

export interface ServerRequest {
    type: typeof actionConstants.SERVER_REQUEST;
    payload: {
        type: string;
        params?: any;
    };
    method: string;
}

export function serverRequest(type: string, method: string, params?: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type,
            params
        },
        method: method ? method : type
    };
}

export function serverDataCommit(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_DATA_COMMIT
        },
        method: methods.METHOD_DATA_COMMIT
    };
}

export function serverDataRollback(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_DATA_ROLLBACK
        },
        method: methods.METHOD_DATA_ROLLBACK
    };
}

export function serverInit(sessionUuid: string): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SERVER_INIT,
            params: {
                sessionUuid: sessionUuid
            }
        },
        method: methods.METHOD_SERVER_INIT
    };
}
