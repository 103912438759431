import * as actionConstants from "../constants/actionTypes";
import * as databaseActions from "../actions/databaseActions";
import * as localConfig from "../constants/localConfig";
import * as modalTypes from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as objectTypes from "../constants/objectTypes";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";

import { switchMap } from "rxjs/operators";
import { t as translate } from "react-i18nify";

export const getSelectSystem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.LOGIN_SUCCESS),
        switchMap(() => {
            const requests: Array<any> = [
                navigationActions.navigationSetScene(state$.value.navigation.activeScene),
                universalObjectActions.getAllItems(objectTypes.DATA_SIZE),
                universalObjectActions.getAllItems(objectTypes.DATABASE),
                universalObjectActions.getAllItems(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.getAllItems(objectTypes.EXPORT_STATUS),
                universalObjectActions.getAllItems(objectTypes.EXPORT_TYPE),
                universalObjectActions.getAllItems(objectTypes.IMPORT_STATUS),
                universalObjectActions.getAllItems(objectTypes.IMPORT_TYPE),
                universalObjectActions.getAllItems(objectTypes.SERVER_SCRIPTS),
                universalObjectActions.getAllItems(objectTypes.SYSTEM_PRICE_OPTION),
                universalObjectActions.getAllItems(objectTypes.ZONE_PRICE_OPTION),
                universalObjectActions.getAllItems(objectTypes.SYSTEM),
                universalObjectActions.getAllItems(objectTypes.ZONE),
                databaseActions.loadData()
            ];

            if (!localConfig.QUICK_INIT) {
                requests.push(
                    navigationActions.navigationOpenModal(modalTypes.MODAL_SELECT_SYSTEM, {
                        title: translate("system.selectSystem")
                    })
                );
            }

            return requests;
        })
    );
