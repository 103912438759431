import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/zone/ZoneProductGroupSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { ZoneProductGroupTable } from "./tables/ZoneProductGroupTable";

type Props = PropsType;

type State = {
    tableProductGroups: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class ZoneProductGroupScene extends Component<Props, State> {
    state: State = {
        tableProductGroups:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.productGroupTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableProductGroups:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.productGroupTableConstant)
                        : null) || null
            });
        }
    }

    getProductGroupsTable = (): JSX.Element | null => {
        const { productGroupTableConstant } = this.props;
        const { tableProductGroups } = this.state;

        if (tableProductGroups) {
            return (
                <ZoneProductGroupTable
                    tableConstant={productGroupTableConstant}
                    objectType={objectTypes.ZONE_PRODUCT_GROUP}
                    dataPrivileges={tableProductGroups}
                    loading={this.props.productGroupLoading}
                    buttonLoading={this.props.productGroupButtonLoading}
                    allList={this.props.productGroupList}
                    activeList={this.props.productGroupActiveList}
                    availableCount={this.props.productGroupAvailableCount}
                    columnOrder={this.props.productGroupColumnOrder}
                    columnVisibility={this.props.productGroupColumnVisibility}
                    columnWidth={this.props.productGroupColumnWidth}
                    offset={this.props.productGroupOffset}
                    page={this.props.productGroupPage}
                    rowCount={this.props.productGroupRowCount}
                    rowCountCustom={this.props.productGroupRowCountCustom}
                    search={this.props.productGroupSearch}
                    searchParams={this.props.productGroupSearchParams}
                    showFilterRow={this.props.productGroupShowFilterRow}
                    showGlobalSearch={this.props.productGroupShowGlobalSearch}
                    sortingAsc={this.props.productGroupSortingAsc}
                    sortingCriterion={this.props.productGroupSortingCriterion}
                    totalCount={this.props.productGroupTotalCount}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getProductGroupsTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                dataPrivileges={this.state.tableProductGroups}
                sceneTableSize={this.props.productGroupSceneTableSize}
                sceneConstant={menuConstants.PAGE_ZONE_PRODUCT_GROUPS}
            />
        );
    }
}
