import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Barcode, mapBarcodes } from "./barcode";

import { TableHeading } from "./tableHeading";
import { Unit } from "./unit";

export class ColorantPackage {
    id: number; // corresponds to package ID
    name: string;
    colorantId: number | null;
    colorantName: string | null;
    unitId?: number;
    tmcName?: string;
    nominalAmount?: number;
    isGravimetric?: boolean;
    moreInfo?: string | null;
    moreInfoSystem?: string | null;
    moreInfoSource?: number;
    parentId?: number | null;
    colorantPackageId?: number | null;
    fillProc?: number | null;
    fillProcSystem?: number | null;
    fillProcSource?: number;
    fillVolume?: number | null;
    fillVolumeMl?: number | null;
    barcodes?: Array<Barcode>;
    imageId?: number | null;
    imageIdSystem?: number | null;
    imageIdSource?: number;

    constructor(
        id: number,
        name: string,
        colorantId: number | null,
        colorantName: string | null,
        unitId?: number,
        tmcName?: string,
        nominalAmount?: number,
        isGravimetric?: boolean,
        moreInfo?: string | null,
        moreInfoSystem?: string | null,
        moreInfoSource?: number,
        parentId?: number | null,
        colorantPackageId?: number | null,
        fillProc?: number | null,
        fillProcSystem?: number | null,
        fillProcSource?: number,
        fillVolume?: number | null,
        fillVolumeMl?: number | null,
        barcodes?: Array<Barcode>,
        imageId?: number | null,
        imageIdSystem?: number | null,
        imageIdSource?: number
    ) {
        this.id = id;
        this.name = name;
        this.colorantId = colorantId;
        this.colorantName = colorantName;
        this.unitId = unitId;
        this.tmcName = tmcName;
        this.nominalAmount = nominalAmount;
        this.isGravimetric = isGravimetric;
        this.moreInfo = moreInfo;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.parentId = parentId;
        this.colorantPackageId = colorantPackageId;
        this.fillProc = fillProc;
        this.fillProcSystem = fillProcSystem;
        this.fillProcSource = fillProcSource;
        this.fillVolume = fillVolume;
        this.fillVolumeMl = fillVolumeMl;
        this.barcodes = barcodes;
        this.imageId = imageId;
        this.imageIdSystem = imageIdSystem;
        this.imageIdSource = imageIdSource;
    }
}

export function mapSimpleColorantPackage(data: Record<string, any>): ColorantPackage | null {
    try {
        return new ColorantPackage(
            data.packageId,
            data.packageName,
            data.colorantId ? data.colorantId : null,
            data.colorantName ? data.colorantName : null
        );
    } catch (e) {
        return null;
    }
}

export function mapColorantPackage(
    packageItem: Record<string, any>,
    colorantPackageItem: Record<string, any>
): ColorantPackage | null {
    try {
        return new ColorantPackage(
            packageItem ? packageItem.id : colorantPackageItem ? colorantPackageItem.packageId : null,
            packageItem ? packageItem.name : colorantPackageItem ? colorantPackageItem.packageName : null,
            colorantPackageItem ? colorantPackageItem.colorantId : null,
            colorantPackageItem ? colorantPackageItem.colorantName : null,
            packageItem ? packageItem.unitId : colorantPackageItem ? colorantPackageItem.unitId : null,
            packageItem ? packageItem.tmcName : null,
            packageItem
                ? packageItem.nominalAmount
                : colorantPackageItem
                ? colorantPackageItem.packageNominalAmount
                : null,
            packageItem ? packageItem.isGravimetric : false,
            packageItem ? packageItem.moreInfo : null,
            packageItem ? packageItem.moreInfoSystem : null,
            packageItem ? packageItem.moreInfoSource : null,
            colorantPackageItem ? colorantPackageItem.parentId : null,
            colorantPackageItem ? colorantPackageItem.id : null,
            colorantPackageItem ? colorantPackageItem.fillProc : null,
            colorantPackageItem ? colorantPackageItem.fillProcSystem : null,
            colorantPackageItem ? colorantPackageItem.fillProcSource : null,
            colorantPackageItem ? colorantPackageItem.fillVolume : null,
            colorantPackageItem ? colorantPackageItem.fillVolumeMl : null,
            colorantPackageItem && colorantPackageItem.barcodes ? mapBarcodes(colorantPackageItem.barcodes) : undefined,
            colorantPackageItem ? colorantPackageItem.imageId : null,
            colorantPackageItem ? colorantPackageItem.imageIdSystem : null,
            colorantPackageItem ? colorantPackageItem.imageIdSource : null
        );
    } catch (e) {
        return null;
    }
}

export function updateColorantPackage(
    oldColorantPackage: ColorantPackage,
    updatedData: Record<string, any>
): ColorantPackage | null {
    try {
        if (oldColorantPackage === null) {
            return null;
        }

        const inheritedBarcodes =
            oldColorantPackage.barcodes?.filter((barcode) => barcode[propertyConstants.PROPERTY_INHERITED]) || [];
        const updatedBarcodes = inheritedBarcodes.concat(updatedData.barcodes || []);

        return new ColorantPackage(
            oldColorantPackage.id,
            updatedData.name ? updatedData.name : oldColorantPackage.name,
            oldColorantPackage.colorantId,
            oldColorantPackage.colorantName,
            oldColorantPackage.unitId,
            updatedData.tmcName ? updatedData.tmcName : oldColorantPackage.tmcName,
            updatedData.nominalAmount ? updatedData.nominalAmount : oldColorantPackage.nominalAmount,
            updatedData.isGravimetric ? updatedData.isGravimetric : oldColorantPackage.isGravimetric,
            updatedData.moreInfo ? updatedData.moreInfo : oldColorantPackage.moreInfo,
            oldColorantPackage.moreInfoSystem,
            oldColorantPackage.moreInfoSource,
            oldColorantPackage.parentId,
            oldColorantPackage.colorantPackageId,
            updatedData.fillProc ? updatedData.fillProc : oldColorantPackage.fillProc,
            oldColorantPackage.fillProcSystem,
            oldColorantPackage.fillProcSource,
            oldColorantPackage.fillVolume,
            oldColorantPackage.fillVolumeMl,
            mapBarcodes(updatedBarcodes),
            oldColorantPackage.imageId,
            oldColorantPackage.imageIdSystem,
            oldColorantPackage.imageIdSource
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyColorantPackage(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_UNIT_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: "",
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]: "",
        [propertyConstants.PROPERTY_COLORANT_ID]: "",
        [propertyConstants.PROPERTY_COLORANT_NAME]: "",
        [propertyConstants.PROPERTY_FILL_PROC]: "",
        [propertyConstants.PROPERTY_FILL_VOLUME]: "",
        [propertyConstants.PROPERTY_FILL_VOLUME_ML]: "",
        [propertyConstants.PROPERTY_BARCODES]: [],
        [propertyConstants.PROPERTY_IMAGE_ID]: ""
    };
}

export function generateColorantPackageData(list: Array<ColorantPackage>): Array<any> {
    const data: Array<any> = [];

    for (const item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_UNIT_ID]: item.unitId,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_NOMINAL_AMOUNT]: item.nominalAmount,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: item.isGravimetric,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]: item.colorantPackageId,
            [propertyConstants.PROPERTY_COLORANT_ID]: item.colorantId,
            [propertyConstants.PROPERTY_COLORANT_NAME]: item.colorantName,
            [propertyConstants.PROPERTY_FILL_PROC]: item.fillProc,
            [propertyConstants.PROPERTY_FILL_PROC_SYSTEM]: item.fillProcSystem,
            [propertyConstants.PROPERTY_FILL_PROC_SOURCE]: item.fillProcSource,
            [propertyConstants.PROPERTY_FILL_VOLUME]: item.fillVolume,
            [propertyConstants.PROPERTY_FILL_VOLUME_ML]: item.fillVolumeMl,
            [propertyConstants.PROPERTY_BARCODES]: item.barcodes,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.imageId,
            [propertyConstants.PROPERTY_IMAGE_ID_SYSTEM]: item.imageIdSystem,
            [propertyConstants.PROPERTY_IMAGE_ID_SOURCE]: item.imageIdSource
        });
    }

    return data;
}

export function generateColorantPackageHeadings(
    objectType: objectTypes.ObjectType,
    unitList: Array<Unit>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BARCODES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODES),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BARCODES),
            editableList.includes(propertyConstants.PROPERTY_BARCODES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BARCODES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BARCODES),
            requiredList.includes(propertyConstants.PROPERTY_BARCODES),
            [],
            widthOption?.[propertyConstants.PROPERTY_BARCODES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_ID),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_ID),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILL_PROC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.TABLE_TYPE_PERCENT,
            visibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            editableList.includes(propertyConstants.PROPERTY_FILL_PROC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILL_PROC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILL_PROC),
            requiredList.includes(propertyConstants.PROPERTY_FILL_PROC),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILL_PROC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILL_VOLUME_ML,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILL_VOLUME_ML),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            editableList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            requiredList.includes(propertyConstants.PROPERTY_FILL_VOLUME_ML),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILL_VOLUME_ML] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NOMINAL_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_NOMINAL_AMOUNT),
            [],
            widthOption?.[propertyConstants.PROPERTY_NOMINAL_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UNIT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UNIT_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_UNIT_ID),
            editableList.includes(propertyConstants.PROPERTY_UNIT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UNIT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UNIT_ID),
            requiredList.includes(propertyConstants.PROPERTY_UNIT_ID),
            unitList,
            widthOption?.[propertyConstants.PROPERTY_UNIT_ID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
