import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import { FormulaColorant, generateFormulaColorantHeadings } from "../../../types/formulaColorant";
import React, { Component } from "react";

import { TableContainer } from "../../Table";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    formulaId?: number;
    allList: Array<FormulaColorant>;
    isEditable: boolean;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    showFooterRow: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    callbackEdit?: (property: propertyConstants.Property, value: any) => any;
};

export class FormulaColorantTable extends Component<Props> {
    generateTableHeadings = (): Array<any> => {
        const visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLORANT_NAME,
            propertyConstants.PROPERTY_AMOUNT
        ];

        const editableList: Array<propertyConstants.Property> = [];
        if (this.props.isEditable && this.props.callbackEdit) {
            editableList.push(propertyConstants.PROPERTY_AMOUNT);
        }

        const tableVisibleColumns = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);

        return generateFormulaColorantHeadings(
            this.props.objectType,
            tableVisibleColumns,
            tableVisibleColumns,
            editableList,
            [propertyConstants.PROPERTY_COLORANT_NAME],
            [],
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    handleEdit = (property: propertyConstants.Property, value: any): any => {
        const { callbackEdit } = this.props;

        if (callbackEdit) {
            callbackEdit(property, value);
        }
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter sum-table"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    itemId={this.props.formulaId ?? undefined}
                    loading={false}
                    headings={this.generateTableHeadings()}
                    data={this.props.allList}
                    activeData={[]}
                    disableColumnResizing={true}
                    modalProperties={{}}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.ROWS_IN_TABLE[tableConstants.ROWS_IN_TABLE.length - 1]}
                    search={null}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showFooterRow={this.props.showFooterRow}
                    showGlobalSearch={false}
                    sortingAsc={this.props.sortingAsc ?? true}
                    sortingCriterion={this.props.sortingCriterion ?? null}
                    totalCount={null}
                    callbackEdit={(property: propertyConstants.Property, value: any) =>
                        this.handleEdit(property, value)
                    }
                />
            </div>
        );
    }
}
