import * as dataTypeConstants from "../constants/dataTypeConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { DataType, mapDataType } from "./duplication/dataType";
import { Zone, mapZones } from "./zone";

import { TableHeading } from "./tableHeading";

export class System {
    id: number;
    databaseId: number | null;
    uuid: string;
    name: string;
    zoneList: Array<Zone>;

    constructor(id: number, databaseId: number | null, uuid: string, name: string, zoneList: Array<Zone>) {
        this.id = id;
        this.databaseId = databaseId;
        this.uuid = uuid;
        this.name = name;
        this.zoneList = zoneList;
    }
}

export function mapSystem(data: Record<string, any>): System | null {
    try {
        return new System(
            data.id,
            data.databaseId ?? null,
            data.uuid,
            data.name,
            data.zones ? mapZones(data.zones) : []
        );
    } catch (e) {
        return null;
    }
}

export function mapSystems(data: Record<string, any>): Array<System> {
    const systems = [];

    for (const item of Object.values(data)) {
        const system = mapSystem(item);

        if (system !== null) {
            systems.push(system);
        }
    }

    return systems;
}

export function updateSystem(oldSystem: System | null, updatedData: Record<string, any>): System | null {
    try {
        if (oldSystem === null) {
            return null;
        }

        return new System(
            oldSystem.id,
            oldSystem.databaseId,
            updatedData.uuid ? updatedData.uuid : oldSystem.uuid,
            updatedData.name ? updatedData.name : oldSystem.name,
            oldSystem.zoneList
        );
    } catch (e) {
        return null;
    }
}

// TODO check all createEmpty
export function createEmptySystem(databaseId: number | null): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_DATABASE_ID]: databaseId,
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_NAME]: ""
    };
}

export function generateSystemData(objectType: objectTypes.ObjectType, systemList: Array<System>): Array<any> {
    const data: Array<any> = [];
    const dataTypesList = [];
    let dataType: DataType | null = null;
    let system: System;

    for (const dataTypeId of dataTypeConstants.DATA_TYPE_LIST) {
        dataType = mapDataType(dataTypeId, objectType);

        if (dataType) {
            dataTypesList.push(dataType);
        }
    }

    for (system of systemList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: system.id,
            [propertyConstants.PROPERTY_DATABASE_ID]: system.databaseId,
            [propertyConstants.PROPERTY_NAME]: system.name,
            [propertyConstants.PROPERTY_UUID]: system.uuid,
            [propertyConstants.PROPERTY_DATA_TYPE_LIST]: dataTypesList
        });
    }

    return data;
}

export function generateSystemHeadings(
    objectType: objectTypes.ObjectType,
    dataTypeList: Array<DataType>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_DATA_TYPE_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.TABLE_TYPE_LIST,
            visibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            editableList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            requiredList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            dataTypeList,
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
