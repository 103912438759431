import * as navigationConstants from "../constants/navigationTypes";
import * as objectTypeHelper from "./objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as optionHelper from "./optionHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { Scene } from "../constants/navigationTypes";

export const getModalClassType = (scene: Scene): string => {
    if (navigationConstants.MASTER_SCENES_LIST.includes(scene)) {
        return " modal-master";
    }

    if (navigationConstants.SYSTEM_SCENES_LIST.includes(scene)) {
        return " modal-system";
    }

    if (navigationConstants.ZONE_SCENES_LIST.includes(scene)) {
        return " modal-zone";
    }

    return "";
};

export const getSceneClassType = (scene: Scene): string => {
    if (navigationConstants.MASTER_SCENES_LIST.includes(scene)) {
        return " master";
    }

    if (navigationConstants.SYSTEM_SCENES_LIST.includes(scene)) {
        return " system";
    }

    if (navigationConstants.ZONE_SCENES_LIST.includes(scene)) {
        return " zone";
    }

    return "";
};

export const getTextWidth = (text: string): number => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context !== null) {
        context.font = getComputedStyle(document.body).font;
    }

    return context?.measureText(text).width || 0;
};

/**
 * Decides what kind of margin should be applied on the checkbox
 * @param objectType
 * @param privileges
 */
export const getTableButtonCheckboxVisibility = (objectType: objectTypes.ObjectType, privileges: any): boolean => {
    if (objectTypeHelper.isObjectTypeRedlikeOption(objectType) || objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) {
        return true;
    }

    if (objectTypeHelper.isObjectTypeExportImport(objectType)) {
        if (
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_EXPORT_DELETE_TASK_SHOW]) ||
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_IMPORT_DELETE_TASK_SHOW]) ||
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_EXPORT_HIDE_TASK_SHOW]) ||
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_IMPORT_HIDE_TASK_SHOW])
        ) {
            return true;
        }
    }
    return false;
};

export const getTableDatePickerVisibility = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.USER_ACTION_HISTORY) {
        return true;
    }

    return false;
};

export const getMousePositionSceneResizing = (
    event: any,
    layout: string | null,
    index: number | null
): number | null => {
    switch (layout) {
        case optionsConstants.LAYOUT_COLUMN: {
            return event.pageY;
        }
        case optionsConstants.LAYOUT_COLUMNS:
        case optionsConstants.LAYOUT_PANEL: {
            return event.pageX;
        }
        case optionsConstants.LAYOUT_BIG_LEFT: {
            if (index === 0) {
                return event.pageX;
            }
            return event.pageY;
        }
        case optionsConstants.LAYOUT_BIG_TOP: {
            if (index === 0) {
                return event.pageY;
            }
            return event.pageX;
        }
    }
    return null;
};

export const getSizePropertySceneResizing = (
    layout: string,
    index: number | null
): propertyConstants.Property | null => {
    switch (layout) {
        case optionsConstants.LAYOUT_COLUMN: {
            return propertyConstants.PROPERTY_HEIGHT;
        }
        case optionsConstants.LAYOUT_COLUMNS:
        case optionsConstants.LAYOUT_PANEL: {
            return propertyConstants.PROPERTY_WIDTH;
        }
        case optionsConstants.LAYOUT_BIG_LEFT: {
            if (index === 0) {
                return propertyConstants.PROPERTY_WIDTH;
            }
            return propertyConstants.PROPERTY_HEIGHT;
        }
        case optionsConstants.LAYOUT_BIG_TOP: {
            if (index === 0) {
                return propertyConstants.PROPERTY_HEIGHT;
            }
            return propertyConstants.PROPERTY_WIDTH;
        }
    }
    return null;
};

export const getElementSizeForLayout = (el: Element | null, layout: string, index: number | null): number | null => {
    if (el) {
        switch (layout) {
            case optionsConstants.LAYOUT_COLUMN: {
                return el.clientHeight;
            }
            case optionsConstants.LAYOUT_COLUMNS:
            case optionsConstants.LAYOUT_PANEL: {
                return el.clientWidth;
            }
            case optionsConstants.LAYOUT_BIG_LEFT: {
                if (index === 0) {
                    return el.clientWidth;
                }
                return el.clientHeight;
            }
            case optionsConstants.LAYOUT_BIG_TOP: {
                if (index === 0) {
                    return el.clientHeight;
                }
                return el.clientWidth;
            }
        }
    }
    return null;
};
