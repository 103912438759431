import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { FileType, getDefaultImageFileTypes } from "../types/fileType";
import { Image, updateImage } from "../types/image";

export type ImageState = Readonly<{
    imageFileTypeList: Array<FileType>;
    list: Array<Image>;
    activeList: Array<Image>;
    count: number | null;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: ImageState = {
    imageFileTypeList: [],
    list: [],
    activeList: [],
    count: null,
    sortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    sortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function images(state: ImageState = initialState, action: Record<string, any>): ImageState {
    switch (action.type) {
        case actionConstants.DATA_LOAD: {
            return {
                ...state,
                imageFileTypeList: getDefaultImageFileTypes()
            };
        }
        case actionConstants.LIST_SAVE: {
            if (
                action.payload.objectType === objectTypes.ICON ||
                action.payload.objectType === objectTypes.IMAGE ||
                action.payload.objectType === objectTypes.GENERIC_IMAGE
            ) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (
                action.payload.objectType === objectTypes.ICON ||
                action.payload.objectType === objectTypes.IMAGE ||
                action.payload.objectType === objectTypes.GENERIC_IMAGE
            ) {
                return {
                    ...state,
                    activeList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (
                action.payload.objectType === objectTypes.ICON ||
                action.payload.objectType === objectTypes.IMAGE ||
                action.payload.objectType === objectTypes.GENERIC_IMAGE
            ) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (
                action.payload.objectType === objectTypes.ICON ||
                action.payload.objectType === objectTypes.IMAGE ||
                action.payload.objectType === objectTypes.GENERIC_IMAGE
            ) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.SET_SORTING: {
            if (
                action.payload.objectType === objectTypes.ICON ||
                action.payload.objectType === objectTypes.IMAGE ||
                action.payload.objectType === objectTypes.GENERIC_IMAGE
            ) {
                return {
                    ...state,
                    sortingAsc: action.payload.asc,
                    sortingCriterion: action.payload.criterion
                };
            }

            return state;
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.GENERIC_IMAGE:
                    case objectTypes.IMAGE: {
                        const updatedImageList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_IMAGE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateImage
                        );

                        if (updatedImageList.length) {
                            return {
                                ...state,
                                list: updatedImageList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.GENERIC_IMAGE:
                    case objectTypes.IMAGE: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                imageFileTypeList: state.imageFileTypeList
            };
        }
        // TODO handlovat na modal close? + chybi modal close all?
        case actionConstants.NAVIGATION_MODAL_OPEN: {
            if (
                action.payload.type === modalTypes.MODAL_IMAGES_TABLE ||
                action.payload.type === modalTypes.MODAL_ICONS_TABLE
            ) {
                return {
                    ...initialState,
                    imageFileTypeList: state.imageFileTypeList
                };
            }
            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return {
                ...initialState,
                imageFileTypeList: state.imageFileTypeList
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
