import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { ModalIcon } from "../../inputs/ModalIcon";
import { TableHeading } from "../../../../types/tableHeading";
import { UserGroup } from "../../../../types/userGroup";

type Props = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    isEditable: boolean;
    isSourceChanged: boolean;
    className: string;
    callbackBlur: (event: any, value: boolean, rowId?: number | string, headingId?: propertyConstants.Property) => any;
};

export class TableCellIcon extends Component<Props> {
    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (property: propertyConstants.Property): string => {
        let className = `td ${property} ${this.props.className}`;

        if (this.props.isSourceChanged) {
            className += " dotted";
        }

        return className;
    };

    getValue = (property: propertyConstants.Property): any => {
        const { heading, item } = this.props;

        if (objectTypeHelper.isObjectTypeRedlikeUserWithUserGroups(this.props.objectType)) {
            return !!item[propertyConstants.PROPERTY_USER_GROUP_LIST].some(
                (userGroup: UserGroup) =>
                    userGroup[propertyConstants.PROPERTY_ID] === heading[tableConstants.TABLE_HEADING_GENERATED_KEY]
            );
        }

        return item && item[property] !== undefined ? item[property] : null;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];

        return (
            <div className={this.getClassname(property)} key={property}>
                <ModalIcon
                    objectType={this.props.objectType}
                    editable={!this.isDisabled()}
                    property={property}
                    value={this.getValue(property)}
                    callback={(event: any, value: boolean): void => {
                        this.props.callbackBlur(event, value, item[propertyConstants.PROPERTY_ID], property);
                    }}
                />
            </div>
        );
    }
}
