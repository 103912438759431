import * as colorHelper from "../../helpers/colorHelper";
import * as formattingHelper from "../../helpers/formattingHelper";
import * as formulaConstants from "../../constants/entityConstants/formulaConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as objectTypes from "../../constants/objectTypes";
import * as optionConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as translationHelper from "../../helpers/translationHelper";

import React, { Component } from "react";

import { Formula } from "../../types/formula";
import { FormulaColorantTable } from "../general/table/FormulaColorantTable";
import { MenuItem } from "../../types/menu";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { Unit } from "../../types/unit";

import { t as translate } from "react-i18nify";

type Props = {
    menuScene: MenuItem | null;
    tableConstant: string;
    list: Array<Formula>;
    activeUnit: Unit | null;
    unitList: Array<Unit>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    setOptions(options: Array<any>, locked: boolean): any;
};

type State = {
    baseFilter: number | null;
    statusFilter: string | null;
};

export class FormulaHistoryBookmark extends Component<Props, State> {
    state: State = {
        baseFilter: null,
        statusFilter: null
    };

    isFiltered = (formula: Formula): boolean => {
        const { baseFilter, statusFilter } = this.state;

        if (baseFilter && formula[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] !== baseFilter) {
            return false;
        }

        if (statusFilter && formula[propertyConstants.PROPERTY_STATUS] !== statusFilter) {
            return false;
        }

        return true;
    };

    getBaseSelectOptions = (): Array<{ key: string; value: string }> => {
        const options: Array<any> = [];

        for (const item of this.props.list) {
            if (options.every((option: any) => option.value !== item.baseName)) {
                options.push({ key: item.baseInProductId?.toString() || "", value: item.baseName });
            }
        }

        options.unshift({
            key: "",
            value: translate("base.allBases")
        });

        return options;
    };

    getStatusSelectOptions = (): Array<{ key: string; value: string }> => {
        return [
            {
                key: "",
                value: translate("formula.allStatuses")
            },
            {
                key: formulaConstants.FORMULA_STATUS_ACCEPTED,
                value: translate("general.accepted")
            },
            {
                key: formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED,
                value: translate("general.accepted") + " / " + translate("general.verified")
            },
            {
                key: formulaConstants.FORMULA_STATUS_HISTORICAL,
                value: translate("general.historical")
            },
            {
                key: formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED,
                value: translate("general.historical") + " / " + translate("general.verified")
            },
            {
                key: formulaConstants.FORMULA_STATUS_CANCELED,
                value: translate("general.canceled")
            },
            {
                key: formulaConstants.FORMULA_STATUS_CANCELED_REJECTED,
                value: translate("general.canceled") + " / " + translate("general.rejected")
            }
        ];
    };

    getFilters = () => {
        return (
            <div className="action-row">
                {/* <ModalTextInput
                    className={"bookmark-input"}
                    editable={true}
                    required={true}
                    type={tableConstants.TABLE_TYPE_DECIMAL}
                    objectType={objectTypes.MASTER_FORMULA}
                    params={propertyConstants.PROPERTY_BASE_AMOUNT}
                    value={1000}
                    unit={"ml"}
                    callbackChange={() => {
                        return;
                    }}
                    callbackBlur={() => {
                        return;
                    }}
                />*/}
                <ModalSelectbox
                    className={"scene-select"}
                    editable={true}
                    required={true}
                    value={this.state.baseFilter || ""}
                    options={this.getBaseSelectOptions()}
                    autoselect={false}
                    callback={(event: any, value: string) => {
                        const baseId = Number(value) ? Number(value) : null;
                        this.setState({ baseFilter: baseId });
                    }}
                />
                <ModalSelectbox
                    className={"scene-select"}
                    editable={true}
                    required={true}
                    value={this.state.statusFilter || ""}
                    options={this.getStatusSelectOptions()}
                    autoselect={false}
                    callback={(event: any, value: string) => {
                        const formulaStatus = value || null;
                        this.setState({ statusFilter: formulaStatus });
                    }}
                />
                <ModalSelectbox
                    className={"scene-select"}
                    editable={true}
                    required={true}
                    value={
                        this.props.activeUnit
                            ? this.props.activeUnit[propertyConstants.PROPERTY_NAME]
                            : optionConstants.DEFAULT_FORMULA_COLORANT_UNIT
                    }
                    options={generalHelper.getOptionsForSelectbox(
                        objectTypes.FORMULA_COLORANT_UNIT,
                        this.props.unitList
                    )}
                    autoselect={false}
                    callback={(event: any, key: string) => {
                        this.props.setOptions(
                            [
                                {
                                    key: optionConstants.OPTION_FORMULA_COLORANT_UNIT,
                                    value: key,
                                    locked: false
                                }
                            ],
                            true
                        );
                    }}
                />
            </div>
        );
    };

    getFormulaInfo = (formula: Formula) => {
        // TODO: tooltips
        return (
            <div className="formula-detail">
                <div className="row">
                    <span className="text-title">{translate("general.status")}:</span>
                    <span className="formula-status">
                        {colorHelper.getFormulaStatus(formula[propertyConstants.PROPERTY_STATUS], true, true)}
                    </span>
                </div>
                <div className="row">
                    <span className="text-title">{translate("general.created")}:</span>
                    <span className="date-note">
                        {formattingHelper.formatDate(formula[propertyConstants.PROPERTY_DATE_CREATED] || null)}
                    </span>
                </div>
                <div className="row">
                    <span className="text-title">{translate("base.base")}:</span>{" "}
                    <span>{formula[propertyConstants.PROPERTY_BASE_NAME]}</span>
                </div>
                <div className="row">
                    <span className="text-title">
                        {translationHelper.getFormulaAmountTranslation(
                            formula[propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT]
                        )}
                        :
                    </span>{" "}
                    <span>{`${formula[propertyConstants.PROPERTY_BASE_AMOUNT]} ${
                        formula[propertyConstants.PROPERTY_IS_GRAVIMETRIC] ? "g" : "ml"
                    }`}</span>
                </div>
                <div className="row">
                    <span className="text-title">{translate("general.note")}:</span>{" "}
                    <span>{formula[propertyConstants.PROPERTY_MORE_INFO_FORMATTED] || ""}</span>
                </div>
            </div>
        );
    };

    getFormulaCards = (): JSX.Element => {
        const formulas = [];
        const tableObjectType = colorHelper.getColorObjectType(
            formulaConstants.FORMULA_COLORANT,
            this.props.tableConstant
        );

        if (tableObjectType !== null) {
            for (const formula of this.props.list) {
                if (this.isFiltered(formula) && !formula[propertyConstants.PROPERTY_NOT_AVAILABLE]) {
                    formulas.push(
                        <div
                            className="table-container"
                            key={`${formula[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]}-${
                                formula[propertyConstants.PROPERTY_ID]
                            }`}
                        >
                            {this.getFormulaInfo(formula)}
                            <FormulaColorantTable
                                tableConstant={this.props.tableConstant}
                                objectType={tableObjectType}
                                dataPrivileges={
                                    (this.props.menuScene
                                        ? this.props.menuScene.items.find(
                                              (item) => item.key === this.props.tableConstant
                                          )
                                        : null) || null
                                }
                                formulaId={formula?.[propertyConstants.PROPERTY_ID] ?? undefined}
                                allList={formula[propertyConstants.PROPERTY_COLORANT_LIST]}
                                isEditable={false}
                                columnOrder={this.props.columnOrder}
                                columnVisibility={this.props.columnVisibility}
                                columnWidth={this.props.columnWidth}
                                showFooterRow={true}
                                sortingAsc={formula?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ?? true}
                                sortingCriterion={
                                    (formula?.[
                                        propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION
                                    ] as propertyConstants.Property) ?? null
                                }
                            />
                        </div>
                    );
                }
            }
        }

        if (formulas.length) {
            return <div className="history-tables-container slim-scroll">{formulas}</div>;
        }

        return <div className="history-tables-container">{translate("formula.noOtherFormula")}</div>;
    };

    render(): JSX.Element {
        return (
            <div className="history-bookmark">
                {this.getFilters()}
                {this.getFormulaCards()}
            </div>
        );
    }
}
