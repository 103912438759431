import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";
import { ReactSVG } from "react-svg";

import imgCancel from "../../../resources/img/icons/cancel.svg";
import imgLock from "../../../resources/img/icons/table-lock.svg";
import imgSuccess from "../../../resources/img/icons/success-transparent.svg";
import imgUnlock from "../../../resources/img/icons/table-unlock.svg";

type Props = {
    objectType: objectTypes.ObjectType;
    editable: boolean;
    value: boolean;
    property: propertyConstants.Property;
    callback: (event: any, value: any) => any;
};

export class ModalIcon extends Component<Props> {
    getClassname = (): string => {
        // tato trieda je aj v tabulke aj v , zatial nedefinovane vlastnosti v modali - nevyuziva sa
        let className = "property-icon";

        if (!this.props.editable) {
            className += " disabled";
        }

        return className;
    };

    getIconClassname = (): string => {
        let className = "svg-icon";

        if (
            objectTypeHelper.isObjectTypeRedlikeOption(this.props.objectType) ||
            objectTypeHelper.isObjectTypeRedlikeUserWithUserGroups(this.props.objectType) ||
            objectTypeHelper.isObjectTypeOption(this.props.objectType)
        ) {
            switch (this.props.property) {
                case propertyConstants.PROPERTY_OVERWRITE: {
                    if (this.props.value) {
                        className += " unlock";
                    } else {
                        className += " lock";
                    }
                    break;
                }
                case propertyConstants.PROPERTY_ENABLED:
                case propertyConstants.PROPERTY_GENERATED:
                case propertyConstants.PROPERTY_UNIVERSAL_VALUE: {
                    if (this.props.value) {
                        className += " success";
                    } else {
                        className += " error";
                    }
                    break;
                }
                default:
                    break;
            }
        }

        return className;
    };

    getIcon = (): string => {
        if (
            objectTypeHelper.isObjectTypeRedlikeOption(this.props.objectType) ||
            objectTypeHelper.isObjectTypeRedlikeUserWithUserGroups(this.props.objectType) ||
            objectTypeHelper.isObjectTypeOption(this.props.objectType)
        ) {
            switch (this.props.property) {
                case propertyConstants.PROPERTY_OVERWRITE: {
                    return this.props.value ? imgUnlock : imgLock;
                }
                case propertyConstants.PROPERTY_ENABLED:
                case propertyConstants.PROPERTY_GENERATED:
                case propertyConstants.PROPERTY_UNIVERSAL_VALUE: {
                    return this.props.value ? imgSuccess : imgCancel;
                }
                default:
                    break;
            }
        }

        return "";
    };

    render(): JSX.Element {
        const { editable } = this.props;

        return (
            <div
                className={this.getClassname()}
                onClick={(event): void => this.props.callback(event, !this.props.value)}
            >
                <ReactSVG disabled={!editable} className={this.getIconClassname()} src={this.getIcon()} />
            </div>
        );
    }
}
