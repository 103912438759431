import * as modalTypes from "../constants/modalTypes";

import React, { Component } from "react";

import { ConfirmationModalContainer } from "./modals/ConfirmationModal";
import { DisplayModalContainer } from "./modals/DisplayModal";
import { ExportAllModalContainer } from "./modals/ExportAllModal";
import { ImportModalContainer } from "./modals/ImportModal";
import { ModalType } from "../constants/modalTypes";
import { PreviewModalContainer } from "./modals/PreviewModal";
import { PriceModalContainer } from "./modals/PriceModal";
import { PropagateObjectModalContainer } from "./modals/PropagateObjectModal";
import { SelectObjectModalContainer } from "./modals/SelectObjectModal";
import { SimpleObjectModalContainer } from "./modals/SimpleObjectModal";
import { TableModalContainer } from "./modals/TableModal";

type Props = {
    id: number;
    type: ModalType;
    params: any;
    index: number;
    nextAction: any;
    closeModal: (type: ModalType) => void;
};

export class Modal extends Component<Props> {
    render(): JSX.Element | null {
        const { id, type, params, nextAction, index, closeModal } = this.props;

        switch (type) {
            case modalTypes.MODAL_DATABASES_UPDATE:
            case modalTypes.MODAL_DATABASES_VACUUM:
            case modalTypes.MODAL_ITEM_DELETE:
            case modalTypes.MODAL_ITEM_DELETE_FINAL:
            case modalTypes.MODAL_SAVE_DATA:
                return (
                    <ConfirmationModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        nextAction={nextAction}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_CHANGELOG:
            case modalTypes.MODAL_DISPLAY:
            case modalTypes.MODAL_FILE_RUN:
            case modalTypes.MODAL_WARNING:
                return (
                    <DisplayModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_FILE_PREVIEW:
            case modalTypes.MODAL_R2W_VERSION_PREVIEW:
            case modalTypes.MODAL_RGB_CALCULATE:
            case modalTypes.MODAL_LICENSE:
            case modalTypes.MODAL_USER_ACTION_HISTORY:
                return (
                    <PreviewModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_DATA_EXPORT_ALL:
                return (
                    <ExportAllModalContainer
                        id={id}
                        type={type}
                        index={index}
                        params={params}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_IMPORT_TASK_ADD:
                return (
                    <ImportModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_PRICE_GENERIC_EDIT:
            case modalTypes.MODAL_PRICE_GROUP_EDIT:
                return (
                    <PriceModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE:
            case modalTypes.MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_BASES_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_COLORANTS_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_FANDECKS_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE:
            case modalTypes.MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE:
            case modalTypes.MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE:
            case modalTypes.MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE:
            case modalTypes.MODAL_ZONE_BASES_PROPAGATE:
            case modalTypes.MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE:
            case modalTypes.MODAL_ZONE_COLORANTS_PROPAGATE:
            case modalTypes.MODAL_ZONE_FANDECKS_PROPAGATE:
            case modalTypes.MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE:
            case modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE:
            case modalTypes.MODAL_ZONE_PRODUCTS_PROPAGATE:
            case modalTypes.MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE:
            case modalTypes.MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE:
            case modalTypes.MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE:
                return (
                    <PropagateObjectModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_SELECT_SYSTEM:
            case modalTypes.MODAL_SELECT_ZONE:
                return (
                    <SelectObjectModalContainer
                        id={id}
                        type={type}
                        params={params}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_BARCODE_EDIT:
            case modalTypes.MODAL_BASE_IN_PRODUCT_ADD:
            case modalTypes.MODAL_BASE_IN_PRODUCT_EDIT:
            case modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_ADD:
            case modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT:
            case modalTypes.MODAL_BASES_ADD:
            case modalTypes.MODAL_BASES_EDIT:
            case modalTypes.MODAL_COLORANT_BATCHES_ADD:
            case modalTypes.MODAL_COLORANT_BATCHES_EDIT:
            case modalTypes.MODAL_COLORANT_PACKAGES_ADD:
            case modalTypes.MODAL_COLORANT_PACKAGES_EDIT:
            case modalTypes.MODAL_COLORANTS_ADD:
            case modalTypes.MODAL_COLORANTS_EDIT:
            case modalTypes.MODAL_COLORS_ADD:
            case modalTypes.MODAL_COLORS_EDIT:
            case modalTypes.MODAL_COMPANIES_ADD:
            case modalTypes.MODAL_COMPANIES_EDIT:
            case modalTypes.MODAL_CURRENCIES_ADD:
            case modalTypes.MODAL_CURRENCIES_EDIT:
            case modalTypes.MODAL_DATABASES_ADD:
            case modalTypes.MODAL_DATABASES_DUPLICATE:
            case modalTypes.MODAL_DATABASES_EDIT:
            case modalTypes.MODAL_DATABASES_EMPTY_ADD: // TODO rename databases_add_empty
            case modalTypes.MODAL_DATA_EXPORT:
            case modalTypes.MODAL_EXPORT_TASKS_DELETE:
            case modalTypes.MODAL_FANDECKS_ADD:
            case modalTypes.MODAL_FANDECKS_EDIT:
            case modalTypes.MODAL_FILE_CATEGORIES_ADD:
            case modalTypes.MODAL_FILE_CATEGORIES_EDIT:
            case modalTypes.MODAL_FILES_ADD:
            case modalTypes.MODAL_FILES_EDIT:
            case modalTypes.MODAL_FORMULA_NOTES_ADD:
            case modalTypes.MODAL_FORMULA_NOTES_EDIT:
            case modalTypes.MODAL_FORMULAS_ADD:
            case modalTypes.MODAL_FORMULAS_EDIT:
            case modalTypes.MODAL_CHANGELOG_EDIT:
            case modalTypes.MODAL_CHANGE_PASSWORD:
            case modalTypes.MODAL_ICONS_ADD:
            case modalTypes.MODAL_IMAGE_CATEGORIES_ADD:
            case modalTypes.MODAL_IMAGE_CATEGORIES_EDIT:
            case modalTypes.MODAL_IMAGES_ADD:
            case modalTypes.MODAL_IMAGES_ADD_MORE:
            case modalTypes.MODAL_IMAGES_EDIT:
            case modalTypes.MODAL_IMPORT_TASKS_DELETE:
            case modalTypes.MODAL_ONE_TIME_ACCESS_PASSWORD_GENERATE:
            case modalTypes.MODAL_PACKAGES_ADD:
            case modalTypes.MODAL_PACKAGES_EDIT:
            case modalTypes.MODAL_PRICE_MARGIN_ADD:
            case modalTypes.MODAL_PRICE_MARGIN_EDIT:
            case modalTypes.MODAL_PRICE_OPTIONS:
            case modalTypes.MODAL_PRODUCT_GROUPS_ADD:
            case modalTypes.MODAL_PRODUCTS_ADD:
            case modalTypes.MODAL_PRODUCTS_DUPLICATE:
            case modalTypes.MODAL_PRODUCTS_EDIT:
            case modalTypes.MODAL_REDLIKE_OPTIONS_EDIT:
            case modalTypes.MODAL_REDLIKE_USER_GROUPS_ADD:
            case modalTypes.MODAL_REDLIKE_USER_GROUPS_DUPLICATE:
            case modalTypes.MODAL_REDLIKE_USER_GROUPS_EDIT:
            case modalTypes.MODAL_SPECTRO_TYPE_ADD:
            case modalTypes.MODAL_SPECTRO_TYPE_EDIT:
            case modalTypes.MODAL_SYSTEMS_ADD:
            case modalTypes.MODAL_SYSTEMS_DUPLICATE:
            case modalTypes.MODAL_SYSTEMS_EDIT:
            case modalTypes.MODAL_TRANSLATION_MANAGEMENT:
            case modalTypes.MODAL_UNITS_ADD:
            case modalTypes.MODAL_UNITS_EDIT:
            case modalTypes.MODAL_USER_GROUPS_ADD:
            case modalTypes.MODAL_USER_GROUPS_EDIT:
            case modalTypes.MODAL_USERS_ADD:
            case modalTypes.MODAL_USERS_EDIT:
            case modalTypes.MODAL_VERSION_EDIT:
            case modalTypes.MODAL_WAVELENGTH_SETTINGS:
            case modalTypes.MODAL_ZONES_ADD:
            case modalTypes.MODAL_ZONES_DUPLICATE:
            case modalTypes.MODAL_ZONES_EDIT:
                return (
                    <SimpleObjectModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            case modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE:
            case modalTypes.MODAL_FILE_CATEGORIES_TABLE:
            case modalTypes.MODAL_FILES_TABLE:
            case modalTypes.MODAL_FORMULA_NOTES_TABLE:
            case modalTypes.MODAL_ICONS_TABLE:
            case modalTypes.MODAL_IMAGE_CATEGORIES_TABLE:
            case modalTypes.MODAL_IMAGES_TABLE:
            case modalTypes.MODAL_SPECTRO_TYPES_TABLE:
                return (
                    <TableModalContainer
                        id={id}
                        type={type}
                        params={params}
                        index={index}
                        closeModal={(type: ModalType): void => closeModal(type)}
                    />
                );
            default:
                return null;
        }
    }
}
