import * as generalHelper from "../../../../helpers/generalHelper";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { Option } from "../../../../types/option";
import { ReactSVG } from "react-svg";

import { connect } from "react-redux";
import imgClose from "../../../../resources/img/icons/close.svg";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type Props = ContainerProps;

export class SceneSaveButton extends Component<Props> {
    render(): JSX.Element | null {
        const { isDataChanged, optionDatabaseAutoSave, commit, rollback } = this.props;

        if (isDataChanged && optionDatabaseAutoSave?.[propertyConstants.PROPERTY_VALUE]) {
            return (
                <div className="fixed-btn-row">
                    <span>{translate("confirmationModal.changesText")}</span>
                    <div className="btns-row">
                        <button className="btn-info" onClick={(): any => commit()}>
                            {translate("general.saveAll")}
                        </button>
                        <button className="btn-info btn-link" onClick={(): any => rollback()}>
                            <ReactSVG src={imgClose} className="btn-icon" />
                            {translate("general.cancel")}
                        </button>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    isDataChanged: boolean;
    optionDatabaseAutoSave: Option | null;
}>;

export type DispatchType = Readonly<{
    commit(): any;
    rollback(): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    isDataChanged: state.navigation.dataChanged,
    optionDatabaseAutoSave: generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_DATABASE_AUTOSAVE)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    commit: (): any => dispatch(universalObjectActions.dataSave()),
    rollback: (): any => dispatch(universalObjectActions.dataRollback())
});

export const SceneSaveButtonContainer = connect(mapStateToProps, mapDispatchToProps)(SceneSaveButton);
