import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";
import { TableDownloadButtonContainer } from "./buttons/TableDownloadButton";
import { TableLoadDataButtonContainer } from "./buttons/TableLoadDataButton";
import { t as translate } from "react-i18nify";

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
};

type Props = OwnProps;

export class TablePreviewRow extends Component<Props> {
    getButtons = (): JSX.Element | null => {
        switch (this.props.objectType) {
            case objectTypes.PRODUCT_SHEET_PREVIEW: {
                return (
                    <TableDownloadButtonContainer
                        objectType={this.props.objectType}
                        activeList={this.props.activeList}
                    />
                );
            }
            case objectTypes.STATIC_SQL_PREVIEW: {
                return (
                    <React.Fragment>
                        <TableLoadDataButtonContainer
                            objectType={this.props.objectType}
                            activeList={this.props.activeList}
                            dataPrivileges={this.props.dataPrivileges}
                        />
                        <TableDownloadButtonContainer
                            objectType={this.props.objectType}
                            activeList={this.props.activeList}
                        />
                    </React.Fragment>
                );
            }
            default:
                return null;
        }
    };

    render(): JSX.Element {
        return (
            <div className="action-row">
                <div className="title">{translate("general.preview")}</div>
                <div className="btn-row-container">{this.getButtons()}</div>
            </div>
        );
    }
}
