import * as loginActions from "../actions/loginActions";
import * as methods from "../constants/serverMethods";

import { AppState } from "../reducers";
import { Dispatch } from "redux";
import { Login } from "../components/Login";
import { ServerRequest } from "../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    loading: boolean;
    wrongLogin: boolean;
}>;

export type DispatchType = Readonly<{
    login(username: string, password: string): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    loading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_SERVER_INIT ||
            request.method === methods.METHOD_IS_USER_LOGGED_IN ||
            request.method === methods.METHOD_LOGIN
    ),
    wrongLogin: state.login.wrongLogin
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    login: (username: string, password: string): any => dispatch(loginActions.login(username, password))
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
