import * as notificationActions from "../actions/notificationActions";

import { AppState } from "../reducers";
import { Dispatch } from "redux";
import { FlashMessage } from "../types/flashMessage";
import { NotificationPanel } from "../components/NotificationPanel";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    activeNotificationPanel: boolean;
    flashMessages: Array<FlashMessage>;
}>;

export type DispatchType = Readonly<{
    deleteAllFlashMessages(): void;
    deleteFlashMessage(id: number): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeNotificationPanel: state.navigation.activeNotificationPanel,
    flashMessages: state.navigation.flashMessages
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    deleteAllFlashMessages: (): any => dispatch(notificationActions.deleteAllFlashMessages()),
    deleteFlashMessage: (id: number): any => dispatch(notificationActions.deleteFlashMessage(id))
});

export const NotificationPanelContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationPanel);
