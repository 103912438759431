import * as defaultConstants from "../../../constants/default";
import * as designConstants from "../../../constants/designConstants";
import * as designHelper from "../../../helpers/designHelper";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";

type Props = {
    modalId?: number;
    className: string;
    editable: boolean;
    required: boolean;
    type: tableConstants.TableType;
    objectType: objectTypes.ObjectType;
    params: propertyConstants.Property;
    value: string | number;
    unit?: string;
    rgbPreview?: string | null | undefined;
    callbackChange: (value: string) => any;
    callbackBlur: (value: string | number | null) => any;
};

export class ModalTextInput extends Component<Props> {
    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { type, callbackChange } = this.props;
        const content = validationHelper.formatStringFromHtml(event.target.value);
        let validation = false;

        switch (type) {
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_PERCENT: {
                validation = validationHelper.isDecimalValueValid(content);
                break;
            }
            case tableConstants.TABLE_TYPE_INTEGER: {
                validation = validationHelper.isIntegerValueValid(content);
                break;
            }
            case tableConstants.TABLE_TYPE_NUMBER: {
                validation = validationHelper.isNumberValueValid(content);
                break;
            }
            case tableConstants.TABLE_TYPE_REAL_NUMBER: {
                validation = validationHelper.isRealValueValid(content);
                break;
            }
            case tableConstants.TABLE_TYPE_STRING:
            case tableConstants.TABLE_TYPE_TEXT: {
                validation = true;
                break;
            }
            default:
                break;
        }

        if (validation) {
            callbackChange(content);
        }
    };

    handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { type, params, objectType, callbackBlur } = this.props;
        let value: any = event.target.value;

        switch (type) {
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_INTEGER:
            case tableConstants.TABLE_TYPE_NUMBER:
            case tableConstants.TABLE_TYPE_REAL_NUMBER: {
                value =
                    this.props.params === propertyConstants.PROPERTY_BASE_AMOUNT && value === ""
                        ? defaultConstants.DEFAULT_FORMULA_AMOUNT
                        : value;
                value = validationHelper.formatNumber(objectType, params, value);
                value = value.trim() !== "" ? value : null;
                value = !isNaN(value) && value !== null ? Number(value) : null;
                break;
            }
            case tableConstants.TABLE_TYPE_PERCENT: {
                value = validationHelper.formatNumber(objectType, params, value);
                value = formattingHelper.formatFromPercent(value);
                value = value.trim() !== "" ? value : null;
                value = !isNaN(value) && value !== null ? Number(value) : null;
                break;
            }
            case tableConstants.TABLE_TYPE_STRING:
            case tableConstants.TABLE_TYPE_TEXT: {
                value = validationHelper.formatStringFromHtml(value);
                value = value.trim() !== "" ? value : null;
                if (propertyHelper.isPropertyVersion(params)) {
                    value = formattingHelper.formatVersion(value);
                }
                break;
            }
            default:
                break;
        }

        callbackBlur(value);
    };

    getClassName = (): string => {
        const { rgbPreview, params, objectType } = this.props;
        let newClassName = this.props.className;

        if (!this.props.editable) {
            newClassName += " disabled";
        }

        if (this.props.unit) {
            newClassName += " units-input";
        }

        if (rgbPreview !== undefined) {
            newClassName += " preview-input";
        }

        if (
            propertyConstants.COLOR_COMPONENTS.includes(params) &&
            !objectTypes.RGB_MINI_PREVIEW_HIDDEN.includes(objectType)
        ) {
            newClassName += " mini-preview-input";
        }

        return newClassName;
    };

    getRgbMiniPreviewClassName = (): string => {
        const { params } = this.props;
        let className = "color-square mini";

        if (params === propertyConstants.PROPERTY_COLOR_RED) {
            className += " red";
        }

        if (params === propertyConstants.PROPERTY_COLOR_GREEN) {
            className += " green";
        }

        if (params === propertyConstants.PROPERTY_COLOR_BLUE) {
            className += " blue";
        }

        return className;
    };

    getRgbPreview = (): JSX.Element | null => {
        const { rgbPreview, params, objectType } = this.props;

        if (rgbPreview) {
            return (
                <div
                    className="color-square"
                    style={{
                        backgroundColor: rgbPreview
                    }}
                ></div>
            );
        }

        if (rgbPreview === null) {
            return <div className="color-square no-color"></div>;
        }

        if (
            propertyConstants.COLOR_COMPONENTS.includes(params) &&
            !objectTypes.RGB_MINI_PREVIEW_HIDDEN.includes(objectType)
        ) {
            return <div className={this.getRgbMiniPreviewClassName()} />;
        }

        return null;
    };

    getUnit = (): JSX.Element | null => {
        const { unit } = this.props;

        if (unit) {
            return <span className="unit-input">{unit}</span>;
        }

        return null;
    };

    render(): JSX.Element {
        const { editable, unit } = this.props;
        const textWidth = unit ? designHelper.getTextWidth(unit) : 0;
        const unitPadding = unit && textWidth !== 0 ? textWidth : designConstants.UNIT_INPUT_PADDING;

        return (
            <div className={this.getClassName()}>
                {this.getRgbPreview()}
                <input
                    style={{ paddingRight: unitPadding }}
                    type={"text"}
                    value={this.props.value}
                    readOnly={!editable}
                    disabled={!editable}
                    onFocus={(event): void => this.handleFocus(event)}
                    onChange={(event): void => this.handleChange(event)}
                    onBlur={(event): void => this.handleBlur(event)}
                />
                {this.getUnit()}
            </div>
        );
    }
}
