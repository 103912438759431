import * as addEpics from "../epics/addEpic";
import * as dataEpics from "../epics/dataEpics";
import * as databaseEpic from "../epics/databaseEpic";
import * as deleteEpics from "./deleteEpic";
import * as duplicateEpic from "./duplicateEpic";
import * as editEpics from "../epics/editEpic";
import * as getAllEpics from "./getAllEpic";
import * as getDetailEpic from "./getDetailEpic";
import * as hideEpic from "./hideEpic";
import * as jumpEpic from "./jumpEpic";
import * as licenseEpic from "../epics/licenseEpic";
import * as loginEpics from "../epics/loginEpics";
import * as navigationEpics from "./navigationEpics";
import * as optionEpics from "./optionEpics";
import * as resetEpic from "./resetEpic";
import * as restEpic from "./restEpic";
import * as saveListEpic from "../epics/saveListEpic";
import * as serverEpics from "../epics/serverEpics";
import * as setActiveEpics from "../epics/setActiveEpic";
import * as setPositionEpic from "../epics/setPositionEpic";
import * as showEpic from "../epics/showEpic";
import * as websocketEpic from "./websocketEpic";

import { combineEpics } from "redux-observable";

const epics = combineEpics(
    ...Object.values(addEpics),
    ...Object.values(dataEpics),
    ...Object.values(databaseEpic),
    ...Object.values(deleteEpics),
    ...Object.values(duplicateEpic),
    ...Object.values(editEpics),
    ...Object.values(getAllEpics),
    ...Object.values(getDetailEpic),
    ...Object.values(hideEpic),
    ...Object.values(jumpEpic),
    ...Object.values(licenseEpic),
    ...Object.values(loginEpics),
    ...Object.values(navigationEpics),
    ...Object.values(optionEpics),
    ...Object.values(resetEpic),
    ...Object.values(restEpic),
    ...Object.values(saveListEpic),
    ...Object.values(setActiveEpics),
    ...Object.values(setPositionEpic),
    ...Object.values(serverEpics),
    ...Object.values(showEpic),
    ...Object.values(websocketEpic)
);

export default epics;
