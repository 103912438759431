import * as propertyConstants from "../../../../constants/propertyConstants";
import * as serverRestMethods from "../../../../constants/serverRestMethods";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";
import imgImg from "../../../../resources/img/icons/image.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    heading: TableHeading;
    item: any;
    callbackMouseEnter: (event: any, value: JSX.Element | null) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellImageSection extends Component<Props> {
    getClassname = (property: propertyConstants.Property, content: any): string => {
        return `td ${property} ${content ? "icon" : ""}`;
    };

    getImagePreview = (content: number | null): JSX.Element | null => {
        const { sessionUuid } = this.props;

        if (content && sessionUuid) {
            // added time constant to avoid cache
            const imageSrc = `${this.props.urlRest}${serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID}?session_uuid=${sessionUuid}&image_id=${content}&height=250&width=250`;

            return <img alt={""} id={"tooltip-image"} key={"file-image"} src={imageSrc} />;
        }

        return null;
    };

    getCellContent = (value: any): any => {
        return value !== "" && !isNaN(value) ? value : null;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const content = this.getCellContent(value);
        const preview = this.getImagePreview(content);

        if (content && preview) {
            return (
                <div
                    className={this.getClassname(property, content)}
                    key={property}
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, preview)}
                    onMouseLeave={(): void => this.props.callbackMouseLeave()}
                >
                    <ReactSVG className={"svg-icon"} src={imgImg} data-tip data-for={"image-tooltip"} />
                </div>
            );
        }

        return <div className={this.getClassname(property, content)} key={property}></div>;
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellImageSectionContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellImageSection);
