import * as defaultConstants from "../constants/default";
import * as fileTypes from "../constants/fileTypes";
import * as formattingHelper from "./formattingHelper";
import * as formulaConstants from "../constants/entityConstants/formulaConstants";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as redlikeOptionsConstants from "../constants/entityConstants/redlikeOptionConstants";
import * as requestHelper from "../helpers/requestHelper";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";

import { DataType, mapDataType } from "../types/duplication/dataType";

import { Barcode } from "../types/barcode";
import { BaseInProductPackage } from "../types/baseInProductPackage";
import { ColorData } from "../types/colorData";
import { DataItem } from "../types/dataItem";
import { FileType } from "../types/fileType";
import { MenuItem } from "../types/menu";
import { OptionItem } from "../types/optionItem";
import { PrivilegeItem } from "../types/privilegeItem";
import { ReflectanceData } from "../types/reflectanceData";
import { Unit } from "../types/unit";

import logoColoris from "../resources/img/icons/coloris-logo.svg";
import logoDefault from "../resources/img/icons/logo-lab.svg";

/**
 * Clones source object deeply.
 * @param source
 */
export const cloneObject = (source: Record<string, any>): any => {
    const target: any = {};

    for (const prop in source) {
        if (Array.isArray(source[prop])) {
            target[prop] = [
                ...source[prop].map((item: any) =>
                    typeof item !== "string" && typeof item !== "number" ? cloneObject(item) : item
                )
            ];
        } else if (typeof source[prop] === "object" && source[prop] !== null) {
            target[prop] = cloneObject(source[prop]);
        } else {
            target[prop] = source[prop];
        }
    }

    return target;
};

/**
 * Clones source array deeply.
 * @param source
 */
export const cloneArray = (source: Array<any>): Array<any> => {
    const target: any = [];

    for (const prop in source) {
        if (Array.isArray(source[prop])) {
            target[prop] = [...source[prop].map((item: any) => (typeof item !== "string" ? cloneObject(item) : item))];
        } else if (typeof source[prop] === "object" && source[prop] !== null) {
            target[prop] = cloneObject(source[prop]);
        } else {
            target[prop] = source[prop];
        }
    }

    return target;
};

/**
 * Moves element in array from position `fromIndex` to position `toIndex`. Keeps array length.
 * @param list
 * @param fromIndex
 * @param toIndex
 */
export const moveItemInArray = (list: Array<any>, fromIndex: number, toIndex: number): Array<any> => {
    const newList = [...list];

    newList.splice(toIndex, 0, newList.splice(fromIndex, 1)[0]);

    return newList;
};

/**
 * Changes property attribute so that server can accept it.
 * @param key
 */
export const getUpdatedKey = (key: string): string => {
    if (key === propertyConstants.PROPERTY_UNIVERSAL_VALUE) {
        return propertyConstants.PROPERTY_VALUE;
    }

    return key;
};

/**
 * Updates data before edit server request.
 * @param key
 * @param values
 */
export const getUpdatedObjectData = (key: string, values: any): any => {
    if (key === propertyConstants.PROPERTY_PRICES) {
        return requestHelper.getPricesListForServer(values);
    }

    if (priceConstants.PRICE_GENERIC_TYPES.includes(key) || key.startsWith("priceGroup")) {
        return {
            ...values,
            [propertyConstants.PROPERTY_OVERWRITE]: !values[propertyConstants.PROPERTY_OVERWRITE]
        };
    }

    return values;
};

/**
 * Generally updates keys and data before edit server request.
 * @param source
 */
export const updateObjectData = (source: Record<string, any>): any => {
    let target: any = {};

    for (const key of Object.keys(source)) {
        const updatedKey = getUpdatedKey(key);
        target = {
            ...target,
            [updatedKey]: getUpdatedObjectData(key, source[key])
        };
    }

    return target;
};

/**
 * Gets item from list by ID.
 * @param list
 * @param id
 */
export const getObjectById = (list: Array<any>, id: number | string): any => {
    let item;

    for (item of list) {
        if (item[propertyConstants.PROPERTY_ID] === id) {
            return item;
        }
    }

    return null;
};

/**
 * Gets element from list by key.
 * @param list
 * @param key
 */
export const getObjectByKey = (list: Array<any>, key: string): any => {
    let item;

    for (item of list) {
        if (item[propertyConstants.PROPERTY_KEY] === key) {
            return item;
        }
    }

    return null;
};

/**
 * Gets item from dictionary by keys.
 * @param list
 * @param key
 * @param objectConstant
 */
export const getObjectFromDictionaryByKey = (list: Array<any>, key: string, objectConstant: string): any => {
    let item;

    for (item of list) {
        if (item[propertyConstants.PROPERTY_KEY] === key) {
            if (
                item[propertyConstants.PROPERTY_VALUE] &&
                item[propertyConstants.PROPERTY_VALUE][objectConstant] !== undefined
            ) {
                return item[propertyConstants.PROPERTY_VALUE][objectConstant];
            }
        }
    }

    return null;
};

/**
 * Returns only distinct items of given array by given property.
 * @param list
 * @param property
 */
export const isDistinctValueByProperty = (list: Array<any>, property: propertyConstants.Property): Array<any> => {
    return list.filter((item, index, self) => self.map((value) => value[property]).indexOf(item[property]) === index);
};

// TODO can be part of getUpdatedObjectData?
export const getFilesObject = (files: Array<any>): any => {
    let filesObject = {
        // eslint-disable-next-line camelcase
        instructions: { file_id: null },
        // eslint-disable-next-line camelcase
        safetySheet: { file_id: null },
        // eslint-disable-next-line camelcase
        technicalSheet: { file_id: null }
    };

    for (const file of files) {
        if (file.fileTypeId === fileTypes.FILE_TYPE_INSTRUCTIONS) {
            filesObject = {
                ...filesObject,
                instructions: {
                    // eslint-disable-next-line camelcase
                    file_id: file[propertyConstants.PROPERTY_ID]
                }
            };
        }
        if (file.fileTypeId === fileTypes.FILE_TYPE_SAFETY_SHEET) {
            filesObject = {
                ...filesObject,
                safetySheet: {
                    // eslint-disable-next-line camelcase
                    file_id: file[propertyConstants.PROPERTY_ID]
                }
            };
        }
        if (file.fileTypeId === fileTypes.FILE_TYPE_TECHNICAL_SHEET) {
            filesObject = {
                ...filesObject,
                technicalSheet: {
                    // eslint-disable-next-line camelcase
                    file_id: file[propertyConstants.PROPERTY_ID]
                }
            };
        }
    }

    return filesObject;
};

// TODO check
export const getFilteredFileTypes = (allFileTypes: Array<FileType>, category: string): Array<FileType> => {
    const newFileTypes = [];

    for (const item of allFileTypes) {
        if (item.active && (!category || item[propertyConstants.PROPERTY_CATEGORY] === category)) {
            newFileTypes.push(item);
        }
    }

    return newFileTypes;
};

/**
 * Finds item in menu tree by key and type.
 * @param tree
 * @param type
 * @param key
 */
export const getMenuItemByKey = (
    tree: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>,
    type: string,
    key: string
): any => {
    let item;

    for (item of tree) {
        if (item.type === type && item[propertyConstants.PROPERTY_KEY] === key) {
            return item;
        }

        if (item instanceof MenuItem && item.items !== undefined && item.items.length > 0) {
            const subItem = getMenuItemByKey(item.items, type, key);

            if (subItem) {
                return subItem;
            }
        }
    }

    return null;
};

// TODO check next 3 methods
/**
 * Updates active items.
 * @param item
 * @param activeList
 * @param multiSelect
 */
export const handleActiveItems = (
    item: Record<string, any>,
    activeList: Array<any>,
    multiSelect = false
): Array<any> => {
    if (!multiSelect) {
        return [item];
    }

    // deactivating selected items in TableBox
    let newArray = [];
    const newItem =
        activeList.find((active) => active[propertyConstants.PROPERTY_ID] === item[propertyConstants.PROPERTY_ID]) ||
        null;

    if (newItem) {
        newArray = activeList.filter(
            (active) => active[propertyConstants.PROPERTY_ID] !== item[propertyConstants.PROPERTY_ID]
        );
    } else {
        newArray = activeList.map((active) => active);
        newArray.push(item);
    }

    return newArray;
};

/**
 * Updates list
 * @param oldList
 * @param newList
 */
export const updateLists = (oldList: Array<any>, newList: Array<any>): Array<any> => {
    return oldList.map((item: any) => {
        const replaceItem =
            newList.find(
                (newItem: any) => item[propertyConstants.PROPERTY_ID] === newItem[propertyConstants.PROPERTY_ID]
            ) || null;

        return replaceItem ? replaceItem : item;
    });
};

/**
 * Updates list
 * @param oldList
 * @param newList
 */
export const updateActiveLists = (oldList: Array<any>, newList: Array<any>): Array<any> => {
    const returnList: Array<any> = [];

    for (const oldItem of oldList) {
        const newItem =
            newList.find(
                (item: any) => item[propertyConstants.PROPERTY_ID] === oldItem[propertyConstants.PROPERTY_ID]
            ) || null;

        if (newItem) {
            returnList.push(newItem);
        }
    }

    return returnList;
};

/**
 * Returns active item
 * @param list
 * @param activeItemId
 */
export const getActiveItem = (list: Array<any>, activeItemId: number): any => {
    return list.find((item: any) => item[propertyConstants.PROPERTY_ID] === activeItemId) || null;
};

/**
 * Sorts object by its keys
 * @param object
 */
export const sortObjectKeys = (object: Record<string, any>): any => {
    const keys = Object.keys(object);
    const result: any = {};

    keys.sort();

    for (let i = 0; i < keys.length; i++) {
        result[keys[i]] = object[keys[i]];
    }

    return result;
};

/**
 * Sorts items locally in list according to the given property and asc
 * ONLY FOR STRING VALUES
 * @param list
 * @param property
 * @param asc
 */
export const sortItemsByProperty = (
    list: Array<any>,
    property: propertyConstants.Property | string,
    asc: boolean
): Array<any> => {
    const listCopy = cloneArray(list);

    if (asc) {
        return listCopy.sort((a: any, b: any) => {
            if (a[property] && b[property]) {
                if (typeof a[property] === "string" && typeof b[property] === "string") {
                    return a[property].localeCompare(b[property]);
                }
                if (typeof a[property] === "number" && typeof b[property] === "number") {
                    return a[property] - b[property];
                }
            }

            if (a[property]) {
                return 1;
            }

            if (b[property]) {
                return -1;
            }

            return 0;
        });
    }

    return listCopy.sort((a: any, b: any) => {
        if (a[property] && b[property]) {
            if (typeof a[property] === "string" && typeof b[property] === "string") {
                return b[property].localeCompare(a[property]);
            }
            if (typeof a[property] === "number" && typeof b[property] === "number") {
                return b[property] - a[property];
            }
        }

        if (a[property]) {
            return -1;
        }

        if (b[property]) {
            return 1;
        }

        return 0;
    });
};

// TODO send parameter as an array and loop with for cycle
/**
 * Returns number of visible tables according to privileges.
 * @param item1
 * @param item2
 * @param item3
 */
export const getTablesCount = (
    item1: MenuItem | DataItem | OptionItem | PrivilegeItem | null = null,
    item2: MenuItem | DataItem | OptionItem | PrivilegeItem | null = null,
    item3: MenuItem | DataItem | OptionItem | PrivilegeItem | null = null
): number => {
    let count = 0;

    if (item1) {
        count += 1;
    }

    if (item2) {
        count += 1;
    }

    if (item3) {
        count += 1;
    }

    return count;
};

/**
 * Gets software logo.
 * @param key
 */
export const getSoftwareLogo = (key: string | null): string => {
    switch (key) {
        case defaultConstants.COLORIS_SOFTWARE: {
            return logoColoris;
        }
        case null:
        default: {
            return logoDefault;
        }
    }
};

export const isObjectInListById = (value: Record<string, any> | null, list: Array<any>): boolean => {
    if (!value) {
        return false;
    }

    // User groups Guests should always be checked, every user is in guests group
    if (value?.[propertyConstants.PROPERTY_KEY] === privilegeConstants.USER_GROUP_GUESTS) {
        return true;
    }

    if (list.some((item: any) => item[propertyConstants.PROPERTY_ID] === value[propertyConstants.PROPERTY_ID])) {
        return true;
    }

    return false;
};

export const isIdInList = (value: number | null, list: Array<any>): boolean => {
    if (!value) {
        return false;
    }
    if (list.some((item: any) => item === value)) {
        return true;
    }
    return false;
};

// TODO better solution with property for MASTER_FORMULA
// TODO check
export const getOptionsForSelectbox = (
    objectType: objectTypes.ObjectType,
    values: Array<any>,
    property?: propertyConstants.Property
): Array<{ key: string; value: string }> => {
    const optionList = [];

    switch (objectType) {
        case objectTypes.BARCODE: {
            if (property === propertyConstants.PROPERTY_ZONE_ID) {
                for (const value of values) {
                    optionList.push({
                        key: value[propertyConstants.PROPERTY_ID]?.toString() || "",
                        value: value[propertyConstants.PROPERTY_ZONE_NAME] || ""
                    });
                }

                break;
            } else {
                for (const value of values) {
                    optionList.push({
                        key: value[propertyConstants.PROPERTY_ID]?.toString() || "",
                        value: value[propertyConstants.PROPERTY_NAME] || ""
                    });
                }
            }
            break;
        }
        case objectTypes.MASTER_FORMULA:
        case objectTypes.SYSTEM_FORMULA:
        case objectTypes.ZONE_FORMULA: {
            if (property === propertyConstants.PROPERTY_PRODUCT_ID) {
                for (const value of values) {
                    optionList.push({
                        key: value[propertyConstants.PROPERTY_ID]?.toString() || "",
                        value: value[propertyConstants.PROPERTY_NAME] || ""
                    });
                }

                break;
            }

            if (property === propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID) {
                for (const value of values) {
                    optionList.push({
                        key: value[propertyConstants.PROPERTY_ID]?.toString() || "",
                        value: value[propertyConstants.PROPERTY_BASE_NAME] || ""
                    });
                }

                break;
            }

            if (
                property === propertyConstants.PROPERTY_IS_GRAVIMETRIC ||
                property === propertyConstants.PROPERTY_STATUS ||
                property === propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT
            ) {
                for (const value of values) {
                    optionList.push({
                        key: value?.key?.toString() || "",
                        value: value?.value || ""
                    });
                }
                break;
            }

            for (const item of values) {
                optionList.push({
                    [propertyConstants.PROPERTY_KEY]: `${item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]}-${
                        item[propertyConstants.PROPERTY_ID]
                    }`,
                    [propertyConstants.PROPERTY_VALUE]: {
                        [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID] || null,
                        [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]:
                            item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] || null,
                        [propertyConstants.PROPERTY_BASE_NAME]: item[propertyConstants.PROPERTY_BASE_NAME] || "",
                        [propertyConstants.PROPERTY_STATUS]:
                            item[propertyConstants.PROPERTY_STATUS] || formulaConstants.FORMULA_STATUS_DISABLED,
                        [propertyConstants.PROPERTY_DATE_CREATED]: formattingHelper.formatDateTime(
                            item[propertyConstants.PROPERTY_DATE_CREATED] || null
                        ),
                        [propertyConstants.PROPERTY_PRIORITY]: item[propertyConstants.PROPERTY_PRIORITY] || null,
                        [propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]:
                            item[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] ?? null,
                        [propertyConstants.PROPERTY_FORMULA_ID]: item[propertyConstants.PROPERTY_ID] ?? null,
                        [propertyConstants.PROPERTY_PRODUCT_ID]: item[propertyConstants.PROPERTY_PRODUCT_ID] ?? null
                    }
                });
            }

            break;
        }
        case objectTypes.MASTER_COLOR_DATA:
        case objectTypes.SYSTEM_COLOR_DATA:
        case objectTypes.ZONE_COLOR_DATA:
        case objectTypes.MASTER_COLOR_SPECTRO_TYPE: {
            for (const value of values) {
                if (
                    value[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] &&
                    value[propertyConstants.PROPERTY_SPECTRO_TYPE_NAME]
                ) {
                    optionList.push({
                        key: value[propertyConstants.PROPERTY_SPECTRO_TYPE_ID].toString(),
                        value: value[propertyConstants.PROPERTY_SPECTRO_TYPE_NAME]
                    });
                }
            }
            break;
        }
        case objectTypes.MASTER_REDLIKE_OPTION:
        case objectTypes.SYSTEM_REDLIKE_OPTION:
        case objectTypes.WAVELENGTH:
        case objectTypes.ZONE_REDLIKE_OPTION: {
            for (const value of values) {
                optionList.push({
                    key: value.toString(),
                    value: value
                });
            }
            break;
        }
        case objectTypes.FORMULA_COLORANT_UNIT: {
            for (const value of values) {
                optionList.push({
                    key: value[propertyConstants.PROPERTY_NAME],
                    value: value[propertyConstants.PROPERTY_NAME]
                });
            }
            break;
        }
        default:
            for (const value of values) {
                optionList.push({
                    key: value[propertyConstants.PROPERTY_ID]?.toString() ?? "",
                    value: value[propertyConstants.PROPERTY_NAME] || ""
                });
            }
            break;
    }

    return optionList;
};

// TODO can be inside getUpdatedObjectData?
export const getBarcodesForServer = (
    barcodes: Array<Barcode>,
    alternativeBarcodes: Array<Barcode> = []
): Array<Barcode> => {
    // TODO newly added barcode is not of type Barcode

    if (Array.isArray(barcodes)) {
        const updatedBarcodes =
            Array.isArray(alternativeBarcodes) && alternativeBarcodes.length
                ? barcodes.concat(alternativeBarcodes)
                : barcodes;

        return updatedBarcodes.filter(
            (item: any) =>
                item[propertyConstants.PROPERTY_BARCODE] &&
                item[propertyConstants.PROPERTY_BARCODE].length &&
                !item[propertyConstants.PROPERTY_INHERITED]
        );
    }

    return [];
};

// TODO specify return value?
// TODO can be inside getUpdatedObjectData?
export const getReflDataForServer = (reflData: Array<ReflectanceData>): Array<any> => {
    const reflDataList: Array<any> = [];

    for (const item of reflData) {
        if (item[propertyConstants.PROPERTY_VALUE] !== null) {
            reflDataList.push([item[propertyConstants.PROPERTY_WAVELENGTH], item[propertyConstants.PROPERTY_VALUE]]);
        }
    }

    return reflDataList;
};

// TODO can be inside getUpdatedObjectData? or specific serverRequestHelper
export const getColorDataForServer = (data: Array<ColorData>): Array<any> => {
    const colorDataList: Array<any> = [];

    for (const item of data) {
        let dataItem = {};
        const reflDataForServer = getReflDataForServer(item[propertyConstants.PROPERTY_REFLECTANCE_DATA]);

        if (
            item[propertyConstants.PROPERTY_SPECTRO_L] &&
            item[propertyConstants.PROPERTY_SPECTRO_A] &&
            item[propertyConstants.PROPERTY_SPECTRO_B]
        ) {
            dataItem = {
                ...dataItem,
                l: item[propertyConstants.PROPERTY_SPECTRO_L],
                a: item[propertyConstants.PROPERTY_SPECTRO_A],
                b: item[propertyConstants.PROPERTY_SPECTRO_B]
            };
        }

        if (reflDataForServer.length > 0) {
            dataItem = {
                ...dataItem,
                // eslint-disable-next-line camelcase
                refl_data: reflDataForServer
            };
        }

        if (Object.keys(dataItem).length > 0 && item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]) {
            dataItem = {
                ...dataItem,
                // eslint-disable-next-line camelcase
                spectro_type_id: item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
            };
        }

        if (Object.keys(dataItem).length > 0) {
            colorDataList.push(dataItem);
        }
    }

    return colorDataList;
};

/**
 * Filters list accoring to name search.
 * @param list
 * @param search
 */
export const getFilteredList = (list: Array<any>, search: string | null): Array<any> => {
    if (search) {
        return list.filter((item) =>
            item[propertyConstants.PROPERTY_NAME]
                ? item[propertyConstants.PROPERTY_NAME].toLowerCase().includes(search.toLowerCase())
                : false
        );
    }

    return list;
};

/**
 * Filters list as a global search (in every value)
 * @param list
 * @param search
 */
export const getGlobalSearchFilteredList = (list: Array<any>, search: string | null): Array<any> => {
    const filteredList: Array<any> = [];

    if (search) {
        for (const item of list) {
            for (const value of Object.values(item)) {
                if (
                    filteredList.indexOf(item) === -1 &&
                    typeof value === "string" &&
                    value.toLowerCase().includes(search.toLowerCase())
                ) {
                    filteredList.push(item);
                }
            }
        }

        return filteredList;
    }

    return list;
};

export const isImageInherited = (objectType: objectTypes.ObjectType, object: Record<string, any>): boolean => {
    // TODO unify a bit with TableItemRow/isSourceChanged
    if (
        objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
        objectType === objectTypes.SYSTEM_COLORANT_PACKAGE
    ) {
        return false;
    }

    if (
        objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType) &&
        object[propertyConstants.PROPERTY_IMAGE_ID_MASTER] !== null
    ) {
        return true;
    }

    if (
        objectTypes.ZONE_OBJECT_TYPES.includes(objectType) &&
        object[propertyConstants.PROPERTY_IMAGE_ID_SYSTEM] !== null
    ) {
        return true;
    }

    return false;
};

/**
 * Pairs data type from server with table constant
 * Used for Redlike options
 * @param item
 * @param source
 */
export const getDataType = (item: Record<string, any>, isModal: boolean): tableConstants.TableType | null => {
    if (item === null || !item[propertyConstants.PROPERTY_DATA_TYPE]) {
        return null;
    }

    switch (item[propertyConstants.PROPERTY_DATA_TYPE]) {
        case serverConstants.DATA_TYPE_DICTIONARY:
        case serverConstants.DATA_TYPE_INFO_TYPES_LIST:
        case serverConstants.DATA_TYPE_LIST:
        case serverConstants.DATA_TYPE_PRICE_TYPES_LIST:
        case serverConstants.DATA_TYPE_PRINT_TASKS_LIST:
            return tableConstants.TABLE_TYPE_TEXT;
        case serverConstants.DATA_TYPE_BOOLEAN:
            if (!isModal) {
                return tableConstants.TABLE_TYPE_ICON;
            }
            return tableConstants.TABLE_TYPE_BOOLEAN;
        case serverConstants.DATA_TYPE_FLOAT:
            if (item[propertyConstants.PROPERTY_SETTINGS]) {
                return tableConstants.TABLE_TYPE_SELECT;
            }
            return tableConstants.TABLE_TYPE_DECIMAL;
        case serverConstants.DATA_TYPE_INT:
            if (item[propertyConstants.PROPERTY_SETTINGS]) {
                return tableConstants.TABLE_TYPE_SELECT;
            }
            return tableConstants.TABLE_TYPE_NUMBER;
        case serverConstants.DATA_TYPE_STRING:
            if (item[propertyConstants.PROPERTY_SETTINGS]) {
                return tableConstants.TABLE_TYPE_SELECT;
            }
            return tableConstants.TABLE_TYPE_STRING;
        case serverConstants.DATA_TYPE_UNKNOWN:
            switch (item[propertyConstants.PROPERTY_KEY]) {
                case redlikeOptionsConstants.LOCALE: {
                    return tableConstants.TABLE_TYPE_SELECT;
                }
                case redlikeOptionsConstants.CURRENCY_CODE:
                case redlikeOptionsConstants.CUSTOM_FORMAT_DATE:
                case redlikeOptionsConstants.CUSTOM_FORMAT_NUMBER:
                case redlikeOptionsConstants.CUSTOM_FORMAT_TIME:
                case redlikeOptionsConstants.PRINTER:
                case redlikeOptionsConstants.PRINTER_TEMPLATE:
                case redlikeOptionsConstants.PRINTER_TEMPLATE_QUICK_TINT:
                case redlikeOptionsConstants.SETTINGS_CUSTOM_HOME_BUTTON:
                case redlikeOptionsConstants.SETTINGS_INFO_ITEM_IN_RGB_PREVIEW: {
                    return tableConstants.TABLE_TYPE_STRING;
                }
                default:
                    return null;
            }
        default:
            return null;
    }
};

/**
 * Filters non default values base on Master/system/zone scene
 * @param objectType
 * @param list
 * @param showDefaultValues
 */
export const getFilteredListBasedOnSource = (
    objectType: objectTypes.ObjectType,
    list: Array<any>,
    showDefaultValues: boolean
): Array<any> => {
    if (!showDefaultValues) {
        if (objectType === objectTypes.MASTER_REDLIKE_OPTION) {
            return list.filter(
                (item) => item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.MASTER_DATA_SOURCE
            );
        }
        if (objectType === objectTypes.SYSTEM_REDLIKE_OPTION) {
            return list.filter(
                (item) => item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.SYSTEM_DATA_SOURCE
            );
        }
        if (objectType === objectTypes.ZONE_REDLIKE_OPTION) {
            return list.filter(
                (item) => item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.ZONE_DATA_SOURCE
            );
        }
    }

    return list;
};

/**
 * Decides based on valueSource whether the value was inherited or not
 * @param objectType
 * @param item
 */
export const isValueChangedBasedOnSource = (objectType: objectTypes.ObjectType, item: any): boolean => {
    switch (objectType) {
        case objectTypes.SYSTEM_FORMULA_NOTE:
            return item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.SYSTEM_DATA_SOURCE;
        case objectTypes.ZONE_FORMULA_NOTE:
            return item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.ZONE_DATA_SOURCE;
        case objectTypes.MASTER_REDLIKE_OPTION:
            return item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.MASTER_DATA_SOURCE;
        case objectTypes.SYSTEM_REDLIKE_OPTION:
            return item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.SYSTEM_DATA_SOURCE;
        case objectTypes.ZONE_REDLIKE_OPTION:
            return item[propertyConstants.PROPERTY_VALUE_SOURCE] === serverConstants.ZONE_DATA_SOURCE;
        default:
            return false;
    }
};

export const calculateFillVolumeMl = (
    baseInProductPackage: BaseInProductPackage | null,
    fillProc: number,
    unit: Unit | null
): string | null => {
    if (baseInProductPackage && unit && fillProc !== null) {
        let result: number;
        const nominalAmount = baseInProductPackage[propertyConstants.PROPERTY_NOMINAL_AMOUNT] || null;

        if (nominalAmount) {
            if (unit[propertyConstants.PROPERTY_IS_GRAVIMETRIC]) {
                result =
                    (fillProc * nominalAmount * unit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT] * 1000) /
                    (baseInProductPackage[propertyConstants.PROPERTY_DENSITY] || 1.0);
            } else {
                result = fillProc * nominalAmount * unit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT] * 1000;
            }
            return formattingHelper.formatDecimal(Number(result));
        }
    }

    return null;
};

export const getItemFromListByHighestPropertyValue = (list: Array<any>, property: propertyConstants.Property): any => {
    let highestItem = null;

    for (const item of list) {
        if (!highestItem || item?.[property] > highestItem?.[property]) {
            highestItem = item;
        }
    }

    return highestItem;
};

export const updateItem = (
    list: Array<any>,
    itemId: number,
    updatedData: Record<string, any>,
    updateItem: (oldItem: any, updatedData: Record<string, any>) => any
): Array<any> => {
    const updatedItem = updateItem(getActiveItem(list, itemId), updatedData);

    if (updatedItem) {
        return updateLists(list, [updatedItem]);
    }

    return [];
};

export const isSelectedPropertyUndefined = (
    propertyList: Array<propertyConstants.Property>,
    item: Record<string, any>
): boolean => {
    return propertyList.some((property) => item[property] === undefined);
};

export const isSelectedPropertyUndefinedInList = (
    propertyList: Array<propertyConstants.Property>,
    itemList: Array<Record<string, any>>
): boolean => {
    let isUndefined = false;

    for (const item of itemList) {
        isUndefined = propertyList.some((property) => item[property] === undefined);

        if (isUndefined) {
            return true;
        }
    }
    return false;
};

export const updateDataBasedOnColumnVisibility = (
    objectType: objectTypes.ObjectType | null,
    state: Record<string, any>,
    columnVisibility?: Record<string, any>
): boolean => {
    if (objectType === null) {
        return false;
    }

    switch (objectType) {
        case objectTypes.MASTER_BASE_IN_PRODUCT: {
            if (
                state.baseInProduct.masterList.length &&
                isSelectedPropertyUndefinedInList(propertyConstants.FILE_PROPERTIES, state.baseInProduct.masterList) &&
                (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
            if (
                state.baseInProduct.systemActiveList.length &&
                ((isSelectedPropertyUndefinedInList(
                    propertyConstants.FILE_PROPERTIES,
                    state.baseInProduct.systemList
                ) &&
                    (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRICE_BASE_IN_PRODUCT_PROPERTIES,
                        state.baseInProduct.systemList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            if (
                state.baseInProduct.zoneList.length &&
                ((isSelectedPropertyUndefinedInList(propertyConstants.FILE_PROPERTIES, state.baseInProduct.zoneList) &&
                    (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRICE_BASE_IN_PRODUCT_PROPERTIES,
                        state.baseInProduct.zoneList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
            if (
                state.baseInProductPackage.systemList.length &&
                ((isSelectedPropertyUndefinedInList(
                    propertyConstants.BARCODE_PROPERTIES,
                    state.baseInProductPackage.systemList
                ) &&
                    (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.FILE_PROPERTIES,
                        state.baseInProductPackage.systemList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRICE_BASE_IN_PRODUCT_PACKAGE_PROPERTIES,
                        state.baseInProductPackage.systemList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            if (
                state.baseInProductPackage.zoneList.length &&
                ((isSelectedPropertyUndefinedInList(
                    propertyConstants.BARCODE_PROPERTIES,
                    state.baseInProductPackage.zoneList
                ) &&
                    (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.FILE_PROPERTIES,
                        state.baseInProductPackage.zoneList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRICE_BASE_IN_PRODUCT_PACKAGE_PROPERTIES,
                        state.baseInProductPackage.zoneList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_COLORANT: {
            if (
                state.colorant.systemList.length &&
                isSelectedPropertyUndefinedInList(
                    propertyConstants.PRICE_COLORANT_PROPERTIES,
                    state.colorant.systemList
                ) &&
                (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_COLORANT: {
            if (
                state.colorant.zoneList.length &&
                isSelectedPropertyUndefinedInList(
                    propertyConstants.PRICE_COLORANT_PROPERTIES,
                    state.colorant.zoneList
                ) &&
                (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE: {
            if (
                state.colorantPackage.systemList.length &&
                isSelectedPropertyUndefinedInList(
                    propertyConstants.BARCODE_PROPERTIES,
                    state.colorantPackage.systemList
                ) &&
                (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            if (
                state.colorantPackage.zoneList.length &&
                isSelectedPropertyUndefinedInList(
                    propertyConstants.BARCODE_PROPERTIES,
                    state.colorantPackage.zoneList
                ) &&
                (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.MASTER_FANDECK: {
            if (
                (state.fandeck.masterList.length &&
                    isSelectedPropertyUndefinedInList(
                        propertyConstants.BARCODE_PROPERTIES,
                        state.fandeck.masterList
                    )) ||
                columnVisibility === undefined ||
                tableHelper.isBarcodePropertyVisible(columnVisibility)
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_FANDECK: {
            if (
                state.fandeck.systemList.length &&
                isSelectedPropertyUndefinedInList(propertyConstants.BARCODE_PROPERTIES, state.fandeck.systemList) &&
                (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_FANDECK: {
            if (
                state.fandeck.zoneList.length &&
                isSelectedPropertyUndefinedInList(propertyConstants.BARCODE_PROPERTIES, state.fandeck.zoneList) &&
                (columnVisibility === undefined || tableHelper.isBarcodePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.MASTER_PRODUCT: {
            if (
                state.product.masterList.length &&
                isSelectedPropertyUndefinedInList(propertyConstants.FILE_PROPERTIES, state.product.masterList) &&
                (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.SYSTEM_PRODUCT: {
            if (
                (state.product.systemList.length &&
                    isSelectedPropertyUndefinedInList(propertyConstants.FILE_PROPERTIES, state.product.systemList) &&
                    (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                (isSelectedPropertyUndefinedInList(
                    propertyConstants.PRICE_PRODUCT_PROPERTIES,
                    state.product.systemList
                ) &&
                    (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))) ||
                (isSelectedPropertyUndefinedInList(
                    propertyConstants.PRODUCT_GROUP_PROPERTIES,
                    state.product.systemList
                ) &&
                    (columnVisibility === undefined || columnVisibility[propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]))
            ) {
                return true;
            }

            return false;
        }
        case objectTypes.ZONE_PRODUCT: {
            if (
                state.product.zoneList.length &&
                ((isSelectedPropertyUndefinedInList(propertyConstants.FILE_PROPERTIES, state.product.zoneList) &&
                    (columnVisibility === undefined || tableHelper.isFilePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRICE_PRODUCT_PROPERTIES,
                        state.product.zoneList
                    ) &&
                        (columnVisibility === undefined || tableHelper.isPricePropertyVisible(columnVisibility))) ||
                    (isSelectedPropertyUndefinedInList(
                        propertyConstants.PRODUCT_GROUP_PROPERTIES,
                        state.product.zoneList
                    ) &&
                        (columnVisibility === undefined ||
                            columnVisibility[propertyConstants.PROPERTY_PRODUCT_GROUP_LIST])))
            ) {
                return true;
            }

            return false;
        }
        default:
            return false;
    }
};

export const updateActiveDataInTable = (
    objectType: objectTypes.ObjectType | null,
    activeItem: Record<string, any>
): boolean => {
    if (objectType === null || activeItem === null) {
        return false;
    }

    switch (objectType) {
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.MASTER_PRODUCT_DUPLICATE: {
            return isSelectedPropertyUndefined(propertyConstants.FILE_PROPERTIES, activeItem);
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            return (
                isSelectedPropertyUndefined(propertyConstants.FILE_PROPERTIES, activeItem) ||
                isSelectedPropertyUndefined(propertyConstants.PRICE_BASE_IN_PRODUCT_PROPERTIES, activeItem)
            );
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            return (
                isSelectedPropertyUndefined(propertyConstants.BARCODE_PROPERTIES, activeItem) ||
                isSelectedPropertyUndefined(propertyConstants.FILE_PROPERTIES, activeItem) ||
                isSelectedPropertyUndefined(propertyConstants.PRICE_BASE_IN_PRODUCT_PACKAGE_PROPERTIES, activeItem)
            );
        }
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            return isSelectedPropertyUndefined(propertyConstants.PRICE_COLORANT_PROPERTIES, activeItem);
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE:
        case objectTypes.MASTER_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            return isSelectedPropertyUndefined([propertyConstants.PROPERTY_BARCODES], activeItem);
        }
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
        case objectTypes.ZONE_PRODUCT:
        case objectTypes.ZONE_PRODUCT_DUPLICATE: {
            return (
                isSelectedPropertyUndefined(propertyConstants.FILE_PROPERTIES, activeItem) ||
                isSelectedPropertyUndefined(propertyConstants.PRICE_PRODUCT_PROPERTIES, activeItem) ||
                isSelectedPropertyUndefined(propertyConstants.PRODUCT_GROUP_PROPERTIES, activeItem)
            );
        }
        default:
            return false;
    }
};

export const generateDataTypes = (objectType: objectTypes.ObjectType, dataTypeList: Array<number>): Array<any> => {
    const dataTypesList = [];
    let dataType: DataType | null = null;

    for (const dataTypeId of dataTypeList) {
        dataType = mapDataType(dataTypeId, objectType);

        if (dataType) {
            dataTypesList.push(dataType);
        }
    }

    return dataTypesList;
};

export const incrementVersionByOne = (version: string): string => {
    const versionArray = version.split(".").map(Number);
    const updatedVersionArray = versionArray.map((number, index) => {
        if (index === versionArray.length - 1) {
            return ++number;
        }

        return number;
    });

    return updatedVersionArray.join(".");
};

export const recalculateFormulaColorantFromDefaultValue = (
    activeUnit: Unit | null,
    value: number | null,
    density: number | null
): number | null => {
    if (activeUnit && value) {
        // The value is in default unit, therefore it does not have to be recalculated when it is equal to itself
        if (activeUnit[propertyConstants.PROPERTY_NAME] === optionsConstants.DEFAULT_FORMULA_COLORANT_UNIT) {
            return value / activeUnit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT];
        }

        return activeUnit[propertyConstants.PROPERTY_IS_GRAVIMETRIC] && density
            ? value * activeUnit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT] * density
            : value * activeUnit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT];
    }

    return value;
};

export const recalculateFormulaColorantToDefaultValue = (
    activeUnit: Unit | null,
    value: number | null,
    density: number | null
): number | null => {
    if (activeUnit && value) {
        return activeUnit[propertyConstants.PROPERTY_IS_GRAVIMETRIC] && density
            ? value / activeUnit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT] / density
            : value / activeUnit[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT];
    }

    return value;
};
