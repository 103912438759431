import * as defaultConstants from "../constants/default";
import * as fileTypeConstants from "../constants/fileTypes";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as requestHelper from "./requestHelper";
import * as tableConstants from "../constants/tableConstants";
import * as translationHelper from "./translationHelper";
import * as validationConstants from "../constants/validationConstants";

import { Bookmark } from "../types/bookmark";
import { ExportType } from "../types/exportType";
import { ReflectanceData } from "../types/reflectanceData";
import { TableHeading } from "../types/tableHeading";
import { User } from "../types/user";
import { Validation } from "../types/validation";
import { WebZone } from "../types/web/webZone";
import { t as translate } from "react-i18nify";

// TODO check while file

export const isNumberValueValid = (value: string): boolean => {
    if (value === "") {
        return true;
    }

    return value.match(/^\d*$/) !== null;
};

export const isIntegerValueValid = (value: string): boolean => {
    if (value === "") {
        return true;
    }

    return value.match(/^-?\d*$/) !== null;
};

export const isHexRgbValueValid = (value: string | null): boolean => {
    if (value === "" || value === null) {
        return true;
    }

    return value.match(/#[0-9a-fA-F]{6}/) !== null;
};

export const isDecimalValueValid = (val: string): boolean => {
    const value = val.replace(",", ".");

    if (value === "") {
        return true;
    }

    if (value === ".") {
        return false;
    }

    if (value.startsWith("-")) {
        return false;
    }

    if (value.endsWith(".")) {
        const substring = value.substr(0, value.length - 1);

        if (substring.includes(".")) {
            return false;
        }

        return substring.match(/^-?\d*(\.\d+)?$/) !== null;
    }

    return value.match(/^-?\d*(\.\d+)?$/) !== null;
};

export const isRealValueValid = (val: string): boolean => {
    const value = val.replace(",", ".");

    if (value === "") {
        return true;
    }

    if (value === ".") {
        return false;
    }

    if (value.endsWith(".")) {
        const substring = value.substr(0, value.length - 1);

        if (substring.includes(".")) {
            return false;
        }

        return substring.match(/^-?\d*(\.\d+)?$/) !== null;
    }

    return value.match(/^-?\d*(\.\d+)?$/) !== null;
};

export const checkMinimalAndMaximalValues = (boundaries: Array<number | null>, value: number | null): number | null => {
    if (value !== null && boundaries[0] !== null && value < boundaries[0]) {
        return boundaries[0];
    }

    if (value !== null && boundaries[1] !== null && value > boundaries[1]) {
        return boundaries[1];
    }

    return value;
};

export const formatNumber = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property,
    val: string
): string => {
    const boundaries = getMinimalAndMaximalValues(objectType, property);

    val = (val?.toString() ?? "").replace(",", ".");
    let value = !isNaN(Number(val)) && val !== "" && val !== null ? Number(val) : null;
    value = checkMinimalAndMaximalValues(boundaries, value);

    return value !== null ? value.toString() : "";
};

export const formatStringFromHtml = (text: string | null): string => {
    if (text !== null) {
        const element = document.createElement("span");
        element.innerHTML = text;

        return element.innerText;
    }

    return "";
};

export const getMinimalAndMaximalValues = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property
): Array<number | null> => {
    switch (property) {
        case propertyConstants.PROPERTY_COLOR_RED:
        case propertyConstants.PROPERTY_COLOR_GREEN: {
            return [0, defaultConstants.MAX_RGB_VALUE];
        }
        // ATTENTION equal property constant "b"
        case propertyConstants.PROPERTY_COLOR_BLUE:
        case propertyConstants.PROPERTY_SPECTRO_B: {
            if (objectType === objectTypes.MASTER_COLOR_SPECTRO_TYPE) {
                return [-128, 127];
            }

            return [0, defaultConstants.MAX_RGB_VALUE];
        }
        case propertyConstants.PROPERTY_SPECTRO_A: {
            return [-128, 127];
        }
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
        case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
        case propertyConstants.PROPERTY_FILL_PROC:
        case propertyConstants.PROPERTY_PRICE_VAT:
        case propertyConstants.PROPERTY_REFLECTANCE_DATA:
        case propertyConstants.PROPERTY_SPECTRO_L: {
            return [0, 100];
        }
        default: {
            return [null, null];
        }
    }
};

export const isDuplicateName = (data: Array<any>, value: string, id: number): boolean => {
    for (const item of data) {
        if (item[propertyConstants.PROPERTY_NAME] === value && item[propertyConstants.PROPERTY_ID] !== id) {
            return true;
        }
    }

    return false;
};

export const isZoneNameValid = (name: string | null): boolean => {
    return name !== null && name.match(/^[\w ]{3,}$/) !== null;
};

export const isUuidValid = (uuid: string | null): boolean => {
    return (
        uuid === null ||
        (uuid !== null &&
            uuid.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/) !== null)
    );
};

export const isPasswordValid = (password: string | null): boolean => {
    return (
        password === null ||
        (password !== null && password.match(/^[a-zA-Z0-9%s!"#$%&\\'()*+,\-./:;<=>?@\]^_`{|}~]{8,}$/) !== null)
    );
};

export const isNewPasswordValid = (
    loggedUser: User,
    oldPassword: string | null,
    newPassword: string | null,
    newPasswordRepeat: string | null
): boolean => {
    // TODO && loggedUser.password === sha(oldPassword))
    if (newPassword && oldPassword && newPassword === newPasswordRepeat) {
        return isPasswordValid(newPassword);
    }

    return false;
};

export const isVersionValid = (version: string | null): boolean => {
    return (
        version === null ||
        (version !== null && version.match(/^(?:(\d{1,3})\.)?(?:(\d{1,3})\.)?(?:(\d{1,3})\.)?(\d{1,3})$/) !== null)
    );
};

export const isVersionNameValid = (versionName: string | null): boolean => {
    return versionName === null || (versionName !== null && versionName.match(/[\S ]{3,}$/) !== null);
};

export const isBaseInProductBfsValid = (bfs: string | null): boolean => {
    return bfs === null || bfs.toUpperCase() === "A" || bfs.toUpperCase() === "B" || bfs.toUpperCase() === "C";
};

export const isColorantBfsValid = (bfs: number | string | null): boolean => {
    return bfs === null || bfs === 1 || bfs === "1" || bfs === 2 || bfs === "2" || bfs === 3 || bfs === "3";
};

export const isRangeValid = (minValue: number | null, maxValue: number | null): boolean => {
    if (minValue === null || maxValue === null) {
        return true;
    }
    return minValue < maxValue;
};

export const isComputerIdValid = (computerId: string | null): boolean => {
    if (computerId === null) {
        return false;
    }

    return computerId.match(/^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/) !== null;
};

export const isBfsValid = (objectType: objectTypes.ObjectType, bfs: string | number | null): boolean => {
    if (bfs === "" || bfs === null) {
        return true;
    }

    switch (objectType) {
        case objectTypes.ZONE_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.MASTER_COLORANT: {
            return !isNaN(Number(bfs)) && isColorantBfsValid(bfs);
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.MASTER_BASE_IN_PRODUCT: {
            return typeof bfs === "string" && isBaseInProductBfsValid(bfs);
        }
        default: {
            return false;
        }
    }
};

export const isObjectForServerValid = (
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalType | null,
    bookmarkKey: string | null,
    requiredHeadings: Array<TableHeading>,
    originalParams: Record<string, any>,
    params: Record<string, any>,
    additionalParams: Record<string, any> = {}
): Array<Validation> => {
    const validationList: Array<Validation> = [];

    // Check all required properties
    for (const heading of requiredHeadings) {
        const property = heading[tableConstants.TABLE_HEADING_NAME];

        if (
            originalParams?.[property] === "" ||
            originalParams?.[property] === undefined ||
            originalParams?.[property] === null
        ) {
            validationList.push(
                new Validation(
                    property,
                    bookmarkKey,
                    validationConstants.REQUIRED_VALIDATION,
                    translate("validation.requiredFieldName", {
                        fieldName: heading[tableConstants.TABLE_HEADING_TRANSLATION]
                    })
                )
            );
        }
    }

    switch (objectType) {
        case objectTypes.MASTER_BASE:
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
        case objectTypes.ZONE_BASE: {
            const { uuid } = params.data;

            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            const { bfs, cntMaxFill, cntMinFill, files, prices, ufiCode, uuid } = params.data;

            if (bfs !== undefined && bfs !== null && !isBaseInProductBfsValid(bfs)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BFS,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BFS)
                    )
                );
            }
            if (
                cntMaxFill !== undefined &&
                cntMaxFill !== null &&
                cntMinFill !== undefined &&
                cntMinFill !== null &&
                !isRangeValid(Number(cntMinFill), Number(cntMaxFill))
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_COLORANT_MAX_FILL,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_COLORANT_MAX_FILL
                        )
                    )
                );
            }
            // TODO fix files with constants, also in the creation
            if (
                files !== undefined &&
                (!files ||
                    !files.instructions ||
                    files.instructions.file_id === undefined ||
                    !files.safetySheet ||
                    files.safetySheet.file_id === undefined ||
                    !files.technicalSheet ||
                    files.technicalSheet.file_id === undefined)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_FILES)
                    )
                );
            }
            // TODO improve prices
            if (
                prices !== undefined &&
                (!prices ||
                    (objectType !== objectTypes.MASTER_BASE_IN_PRODUCT &&
                        (!prices.price_cost_per_liter ||
                            !prices.price_purchase_per_liter ||
                            !prices.price_sell_per_liter ||
                            !prices.price_vat)))
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PRICES)
                    )
                );
            }
            if (ufiCode !== undefined && ufiCode !== null && !isUfiCodeValid(ufiCode)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UFI_CODE,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UFI_CODE)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            const { barcodes, files, prices, uuid } = params.data;

            if (barcodes !== undefined && !Array.isArray(barcodes)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BARCODES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BARCODES)
                    )
                );
            }
            // TODO fix files with constants, also in the creation
            if (
                files !== undefined &&
                (!files.instructions ||
                    files.instructions.file_id === undefined ||
                    !files.safetySheet ||
                    files.safetySheet.file_id === undefined ||
                    !files.technicalSheet ||
                    files.technicalSheet.file_id === undefined)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_FILES)
                    )
                );
            }
            // TODO improve prices
            if (
                prices !== undefined &&
                (!prices.price_cost ||
                    !prices.price_purchase ||
                    !prices.price_sell ||
                    !prices.price_surcharge ||
                    !prices.price_vat)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PRICES)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_COLOR: {
            const { barcodes, uuid } = params.data;

            if (barcodes !== undefined && !Array.isArray(barcodes)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BARCODES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BARCODES)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            const { bfs, prices, ufiCode, uuid } = params.data;

            if (bfs !== undefined && bfs !== null && !isColorantBfsValid(bfs)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BFS,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BFS)
                    )
                );
            }
            // TODO improve prices
            if (
                prices !== undefined &&
                objectType !== objectTypes.MASTER_COLORANT &&
                (!prices.price_cost_per_liter ||
                    !prices.price_purchase_per_liter ||
                    !prices.price_purchase_pg_per_liter ||
                    !prices.price_sell_per_liter ||
                    !prices.price_vat)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PRICES)
                    )
                );
            }
            if (ufiCode !== undefined && ufiCode !== null && !isUfiCodeValid(ufiCode)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UFI_CODE,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UFI_CODE)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            const { barcodes } = params.data;

            if (barcodes !== undefined && !Array.isArray(barcodes)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BARCODES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BARCODES)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.DATABASE: {
            const { uuid } = params.data;

            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.EXPORT_TASK: {
            const { exportTaskTypeId, version, versionName, zonePassword, zoneUuid } = params.data;

            if (version !== undefined && version !== null && !isVersionValid(version)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_VERSION)
                    )
                );
            }
            if (versionName !== undefined && versionName !== null && !isVersionNameValid(versionName)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION_NAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_VERSION_NAME
                        )
                    )
                );
            }
            if (zonePassword !== undefined && zonePassword !== null && !isPasswordValid(zonePassword)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_ZONE_PASSWORD,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PASSWORD)
                    )
                );
            }

            if (
                zoneUuid !== undefined &&
                zoneUuid !== null &&
                !isUuidOnWeb(
                    zoneUuid,
                    additionalParams.webZoneList ?? [],
                    additionalParams.exportTypeList ?? [],
                    additionalParams.exportTaskType
                        ? additionalParams.exportTaskType[propertyConstants.PROPERTY_ID]
                        : exportTaskTypeId
                )
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_ZONE_UUID,
                        bookmarkKey,
                        validationConstants.FOOTER_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_ZONE_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.EXPORT_TASK_DELETE: {
            const { exportTaskStatusIds } = params;

            if (
                exportTaskStatusIds !== undefined &&
                exportTaskStatusIds !== null &&
                Array.isArray(exportTaskStatusIds) &&
                exportTaskStatusIds.find((id) => typeof id !== "number")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_FANDECK:
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            const { barcodes, uuid } = params.data;

            if (!Array.isArray(barcodes)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_BARCODES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_BARCODES)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.FILE:
        case objectTypes.PRODUCT_SHEET:
        case objectTypes.STATIC_SQL: {
            const { url } = params.data;

            if (url !== undefined && url !== null && !isUrlValid(url)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_URL,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_URL)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_FORMULA: {
            const { data } = params;

            if (data && typeof data.colorants !== "object") {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_COLORANT_LIST,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_COLORANT_LIST
                        )
                    )
                );
            }
            if (data && data.uuid !== undefined && data.uuid !== null && !isUuidValid(data.uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.ICON: {
            const { fileTypeId } = params.data;

            if (fileTypeId !== undefined && fileTypeId !== null && fileTypeId !== fileTypeConstants.FILE_TYPE_ICON) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILE_TYPE_ID,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_FILE_TYPE_ID
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.GENERIC_IMAGE:
        case objectTypes.IMAGE: {
            const { imageId } = params;
            const { fileTypeId, url } = params.data;

            if (
                objectType === objectTypes.IMAGE &&
                fileTypeId !== undefined &&
                fileTypeId !== null &&
                fileTypeId !== fileTypeConstants.FILE_TYPE_IMAGE
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILE_TYPE_ID,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_FILE_TYPE_ID
                        )
                    )
                );
            }
            if (url !== undefined && url !== null && !isUrlValid(url)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_URL,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_URL)
                    )
                );
            }
            if (imageId === null && url === null) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_IMAGE_ID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_IMAGE_ID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.IMPORT_TASK: {
            const { files, options } = params.data;

            if (files !== undefined && files !== null && typeof files !== "object") {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_FILES)
                    )
                );
            }
            if (options !== undefined && options !== null && typeof options !== "object") {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_OPTIONS,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_OPTIONS)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.IMPORT_TASK_DELETE: {
            const { importTaskStatusIds } = params;

            if (
                importTaskStatusIds !== undefined &&
                importTaskStatusIds !== null &&
                Array.isArray(importTaskStatusIds) &&
                importTaskStatusIds.find((id) => typeof id !== "number")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.LICENSE: {
            const { modules } = params;
            const { contactPerson, pointOfSale } = params.regData;

            if (
                contactPerson[propertyConstants.PROPERTY_NAME] === undefined ||
                typeof contactPerson[propertyConstants.PROPERTY_NAME] !== "string"
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_CONTACT_PERSON,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_CONTACT_PERSON
                        )
                    )
                );
            }

            if (
                contactPerson[propertyConstants.PROPERTY_EMAIL] === undefined ||
                (contactPerson[propertyConstants.PROPERTY_EMAIL] !== null &&
                    typeof contactPerson[propertyConstants.PROPERTY_EMAIL] !== "string") ||
                !isEmailValid(contactPerson[propertyConstants.PROPERTY_EMAIL])
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_EMAIL,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_EMAIL)
                    )
                );
            }

            if (
                contactPerson[propertyConstants.PROPERTY_PHONE] === undefined ||
                (contactPerson[propertyConstants.PROPERTY_PHONE] !== null &&
                    typeof contactPerson[propertyConstants.PROPERTY_PHONE] !== "string") ||
                !isPhoneValid(contactPerson[propertyConstants.PROPERTY_PHONE])
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PHONE,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PHONE)
                    )
                );
            }
            if (modules !== undefined && modules !== null && !Array.isArray(modules)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_MODULES,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_MODULES)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_CITY] === undefined ||
                (pointOfSale[propertyConstants.PROPERTY_CITY] !== null &&
                    typeof pointOfSale[propertyConstants.PROPERTY_CITY] !== "string")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_CITY,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_CITY)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_COUNTRY] === undefined ||
                (pointOfSale[propertyConstants.PROPERTY_COUNTRY] !== null &&
                    typeof pointOfSale[propertyConstants.PROPERTY_COUNTRY] !== "string")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_COUNTRY,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_COUNTRY)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_NAME] === undefined ||
                typeof pointOfSale[propertyConstants.PROPERTY_NAME] !== "string"
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_NAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_NAME)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_REGION] === undefined ||
                (pointOfSale[propertyConstants.PROPERTY_REGION] !== null &&
                    typeof pointOfSale[propertyConstants.PROPERTY_REGION] !== "string")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_REGION,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_REGION)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_STREET] === undefined ||
                (pointOfSale[propertyConstants.PROPERTY_STREET] !== null &&
                    typeof pointOfSale[propertyConstants.PROPERTY_STREET] !== "string")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_STREET,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_STREET)
                    )
                );
            }
            if (
                pointOfSale[propertyConstants.PROPERTY_ZIP] === undefined ||
                (pointOfSale[propertyConstants.PROPERTY_ZIP] !== null &&
                    typeof pointOfSale[propertyConstants.PROPERTY_ZIP] !== "string")
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_ZIP,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_ZIP)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.ONE_TIME_ACCESS_PASSWORD: {
            const { computerId } = params.data;

            if (computerId !== undefined && computerId !== null && !isComputerIdValid(computerId)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_COMPUTER_ID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_COMPUTER_ID
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM_PACKAGE:
        case objectTypes.ZONE_PACKAGE: {
            const { prices } = params.data;

            // TODO improve prices
            if (prices !== undefined && !prices.price_surcharge) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICE_SURCHARGE,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_PRICE_SURCHARGE
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.ZONE_PRODUCT: {
            const { files, prices, uuid } = params.data;

            // TODO fix files with constants, also in the creation
            if (
                files !== undefined &&
                (!files.instructions ||
                    files.instructions.file_id === undefined ||
                    !files.safetySheet ||
                    files.safetySheet.file_id === undefined ||
                    !files.technicalSheet ||
                    files.technicalSheet.file_id === undefined)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_FILES)
                    )
                );
            }

            // TODO improve prices
            if (
                prices !== undefined &&
                objectType !== objectTypes.MASTER_PRODUCT &&
                (!prices.price_cost_per_liter ||
                    !prices.price_purchase_per_liter ||
                    !prices.price_sell_per_liter ||
                    !prices.price_vat)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PRICES)
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
        case objectTypes.ZONE_PRODUCT_DUPLICATE: {
            const { files, prices } = params.data;

            // TODO fix files with constants, also in the creation
            if (
                files !== undefined &&
                (!files.instructions ||
                    files.instructions.file_id === undefined ||
                    !files.safetySheet ||
                    files.safetySheet.file_id === undefined ||
                    !files.technicalSheet ||
                    files.technicalSheet.file_id === undefined)
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_FILES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_FILES)
                    )
                );
            }

            // TODO improve prices
            if (
                !prices ||
                !prices.price_cost_per_liter ||
                !prices.price_purchase_per_liter ||
                !prices.price_sell_per_liter ||
                !prices.price_vat
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PRICES,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PRICES)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM: {
            const { uuid } = params.data;

            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.SYSTEM_ZONE: {
            const { exportTaskTypeId, version, versionName, uuid, zonePassword } = params.data;

            if (modalType && modalType === modalTypes.MODAL_DATA_EXPORT_ALL) {
                if (version === undefined || version === null || !isVersionValid(version)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_VERSION,
                            bookmarkKey,
                            validationConstants.GENERAL_VALIDATION,
                            translationHelper.getInvalidCaptionForModalTable(propertyConstants.PROPERTY_VERSION)
                        )
                    );
                }
                if (versionName === undefined || versionName === null || !isVersionNameValid(versionName)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_VERSION_NAME,
                            bookmarkKey,
                            validationConstants.GENERAL_VALIDATION,
                            translationHelper.getInvalidCaptionForModalTable(propertyConstants.PROPERTY_VERSION_NAME)
                        )
                    );
                }
                if (
                    additionalParams.webZoneList !== undefined &&
                    additionalParams.exportTypeList !== undefined &&
                    (uuid === undefined ||
                        uuid === null ||
                        !isUuidOnWeb(
                            uuid,
                            additionalParams.webZoneList,
                            additionalParams.exportTypeList,
                            exportTaskTypeId
                        ))
                ) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_UUID,
                            bookmarkKey,
                            validationConstants.GENERAL_VALIDATION,
                            translationHelper.getInvalidCaptionForModalTable(propertyConstants.PROPERTY_UUID)
                        )
                    );
                }
                if (zonePassword === undefined || zonePassword === null || !isPasswordValid(zonePassword)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_ZONE_PASSWORD,
                            bookmarkKey,
                            validationConstants.GENERAL_VALIDATION,
                            translationHelper.getInvalidCaptionForModalTable(propertyConstants.PROPERTY_PASSWORD)
                        )
                    );
                }
            } else {
                if (version !== undefined && version !== null && !isVersionValid(version)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_VERSION,
                            bookmarkKey,
                            validationConstants.INPUT_VALIDATION,
                            translationHelper.getInvalidCaptionByProperty(
                                objectType,
                                propertyConstants.PROPERTY_VERSION
                            )
                        )
                    );
                }
                if (versionName !== undefined && versionName !== null && !isVersionNameValid(versionName)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_VERSION_NAME,
                            bookmarkKey,
                            validationConstants.INPUT_VALIDATION,
                            translationHelper.getInvalidCaptionByProperty(
                                objectType,
                                propertyConstants.PROPERTY_VERSION_NAME
                            )
                        )
                    );
                }
                if (
                    uuid !== undefined &&
                    uuid !== null &&
                    additionalParams.webZoneList !== undefined &&
                    additionalParams.exportTypeList !== undefined &&
                    !isUuidOnWeb(uuid, additionalParams.webZoneList, additionalParams.exportTypeList, exportTaskTypeId)
                ) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_UUID,
                            bookmarkKey,
                            validationConstants.INPUT_VALIDATION,
                            translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                        )
                    );
                }
                if (zonePassword === undefined || typeof zonePassword !== "string" || !isPasswordValid(zonePassword)) {
                    validationList.push(
                        new Validation(
                            propertyConstants.PROPERTY_ZONE_PASSWORD,
                            bookmarkKey,
                            validationConstants.INPUT_VALIDATION,
                            translationHelper.getInvalidCaptionByProperty(
                                objectType,
                                propertyConstants.PROPERTY_ZONE_PASSWORD
                            )
                        )
                    );
                }
            }
            return validationList;
        }
        case objectTypes.USER: {
            const { userGroupIds } = params.data;

            if (userGroupIds !== undefined && userGroupIds !== null && !Array.isArray(userGroupIds)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_USER_GROUP_ID,
                        bookmarkKey,
                        validationConstants.CLIENT_ERROR_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_USER_GROUP_ID
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
            const { username } = params;

            if (username !== undefined && username !== null && username === defaultConstants.DEFAULT_REDLIKE_USERNAME) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_USERNAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_USERNAME)
                    )
                );
            }

            return validationList;
        }
        case objectTypes.USER_PASSWORD: {
            const { newPassword, confirmationPassword } = params.data;

            if (newPassword !== confirmationPassword) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PASSWORD_NEW,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_PASSWORD_NEW
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.VERSION: {
            const { version, versionName } = params.data;

            if (version !== undefined && version !== null && !isVersionValid(version)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_VERSION)
                    )
                );
            }
            if (versionName !== undefined && versionName !== null && !isVersionNameValid(versionName)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION_NAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_VERSION_NAME
                        )
                    )
                );
            }

            return validationList;
        }
        case objectTypes.ZONE: {
            const { name, password, version, versionName, uuid } = params.data;

            if (name !== undefined && name !== null && !isZoneNameValid(name)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_NAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_NAME)
                    )
                );
            }
            if (password !== undefined && password !== null && !isPasswordValid(password)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PASSWORD,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_PASSWORD)
                    )
                );
            }
            if (
                password !== undefined &&
                password !== null &&
                uuid !== undefined &&
                uuid !== null &&
                additionalParams[propertyConstants.PROPERTY_WEB_ZONE_LIST] &&
                additionalParams[propertyConstants.PROPERTY_WEB_ZONE_LIST].length
            ) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_PASSWORD_HASH,
                        bookmarkKey,
                        validationConstants.GENERAL_VALIDATION,
                        translationHelper.getPasswordHashComparisonTranslation(
                            additionalParams[propertyConstants.PROPERTY_WEB_ZONE_LIST],
                            password,
                            uuid
                        )
                    )
                );
            }
            if (uuid !== undefined && uuid !== null && !isUuidValid(uuid)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_UUID,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_UUID)
                    )
                );
            }
            if (version !== undefined && version !== null && !isVersionValid(version)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(objectType, propertyConstants.PROPERTY_VERSION)
                    )
                );
            }
            if (versionName !== undefined && versionName !== null && !isVersionNameValid(versionName)) {
                validationList.push(
                    new Validation(
                        propertyConstants.PROPERTY_VERSION_NAME,
                        bookmarkKey,
                        validationConstants.INPUT_VALIDATION,
                        translationHelper.getInvalidCaptionByProperty(
                            objectType,
                            propertyConstants.PROPERTY_VERSION_NAME
                        )
                    )
                );
            }
            return validationList;
        }
        default: {
            return validationList;
        }
    }
};

export const setInvalidInput = (modalId: number, property: propertyConstants.Property, caption: string): any => {
    const modal = modalId !== 0 ? document.getElementById(`modal-${modalId}`) : document;

    if (modal !== null) {
        // filtering table cell validation in modals
        const items = Array.from(modal.getElementsByClassName(property)).filter(
            (item) => !item.classList.contains("td")
        );

        for (let i = 0; i < items.length; i++) {
            items[i].classList.add("visible");
            items[i].parentElement?.classList.add("visible-message", "wrap-row");
            items[i].innerHTML = caption;
        }
    }
};

export const hideInvalidInput = (modalId: number, property: propertyConstants.Property): any => {
    const modal = modalId !== 0 ? document.getElementById(`modal-${modalId}`) : document;

    if (modal !== null) {
        const items = modal.getElementsByClassName(property);

        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove("visible");
            items[i].parentElement?.classList.remove("visible-message", "wrap-row");
            items[i].innerHTML = "";
        }
    }
};

export const areReflectanceDataValid = (reflData: Array<ReflectanceData>): boolean => {
    const reflectanceList: Array<number | null> = [];

    for (const item of reflData) {
        if (item[propertyConstants.PROPERTY_VALUE] !== null || reflectanceList[reflectanceList.length - 1] !== null) {
            reflectanceList.push(item[propertyConstants.PROPERTY_VALUE]);
        }
    }

    for (let id = 1; id < reflectanceList.length - 1; id++) {
        if (reflectanceList[id] === null && reflectanceList[id - 1] !== null && reflectanceList[id + 1] !== null) {
            return false;
        }
    }

    return true;
};

/**
 *
 * @param ufiCode: string | null
 * Examples: "V300-P0FF-500G-G8MU", "N1QV-R02N-J00M-WQD5"
 * https://ufi.echa.europa.eu/#/validate
 */
export const isUfiCodeValid = (ufiCode: string | null): boolean => {
    if (ufiCode === null) {
        return true;
    }

    const trimmedUfiCode = ufiCode.trim();

    if (trimmedUfiCode.length !== 19) {
        return false;
    }

    if (trimmedUfiCode.match(/^(([A-Y0-9]{4})-([A-Y0-9]{4})-([A-Y0-9]{4})-([A-Y0-9]{4}))$/) === null) {
        return false;
    }

    const splitted: any = trimmedUfiCode.replace(/-/g, "").split("");

    if (splitted.some((ufiChar: any) => defaultConstants.DEFAULT_UFI_CODE_CHARACTER_SET[ufiChar] === undefined)) {
        return false;
    }

    let sum = 0;

    for (const [i, ch] of splitted.entries()) {
        if (i > 0) {
            sum += (i + 1) * defaultConstants.DEFAULT_UFI_CODE_CHARACTER_SET[ch];
        }
    }

    const checkSum = (31 - (sum % 31)) % 31;

    for (const [i, j] of Object.entries(defaultConstants.DEFAULT_UFI_CODE_CHARACTER_SET)) {
        if (checkSum === j) {
            return i === splitted[0];
        }
    }

    return false;
};

export const isEmailValid = (email: string | null) => {
    if (email === null || email === "") {
        return true;
    }

    if (email.match(/\S+@\S+\.\S+/)) {
        return true;
    }

    return false;
};

export const isPhoneValid = (phoneNumber: string | null) => {
    if (phoneNumber === null || phoneNumber === "") {
        return true;
    }

    if (phoneNumber.match(/^([0,1,2,3,4,5,6,7,8,9,(,),+]+)$/)) {
        return true;
    }

    return false;
};

export const isUrlValid = (url: string) => {
    if (url === null) {
        return true;
    }

    if (
        typeof url === "string" &&
        url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
    ) {
        return true;
    }

    return false;
};

export const isUuidOnWeb = (
    uuid: string,
    webZoneList: Array<WebZone>,
    exportTypeList: Array<ExportType> = [],
    exportTypeId: number | null = null
) => {
    if (uuid === null || webZoneList.length === 0) {
        return false;
    }

    if (
        exportTypeId === null ||
        exportTypeList.some(
            (exportType: ExportType) =>
                exportType[propertyConstants.PROPERTY_ID] === exportTypeId &&
                exportType[propertyConstants.PROPERTY_KEY] !==
                    importExportConstants.EXPORT_TYPE_ONLY_DATABASE_TO_WEB_KEY
        )
    ) {
        return true;
    }

    if (webZoneList.some((webZone: WebZone) => webZone[propertyConstants.PROPERTY_UUID] === uuid)) {
        return true;
    }

    return false;
};

// TODO: Return properties is going to be used to restrict properties in the item that are going to be send to server
export const validateItem = (
    objectType: objectTypes.ObjectType,
    modalFunctionType: modalTypes.ModalFunctionType,
    modalType: modalTypes.ModalType | null,
    headings: Array<TableHeading>,
    bookmarks: Array<Bookmark>,
    item: Record<string, any>,
    returnProperties: Array<string> = [],
    checkProperties: Array<propertyConstants.Property> = [],
    additionalParams: Record<string, any> = {}
): Record<string, any> => {
    const data = requestHelper.getObjectForServer(
        objectType,
        modalFunctionType,
        item,
        additionalParams,
        returnProperties
    );

    let validationList: Array<Validation> = [];

    // Validate properties in no bookmark
    let requiredHeadings = headings.filter((heading: TableHeading) => heading[tableConstants.TABLE_HEADING_REQUIRED]);

    if (data !== undefined && data !== null && Object.keys(data).length > 0) {
        validationList = validationList.concat(
            isObjectForServerValid(objectType, modalType, null, requiredHeadings, item, data, additionalParams)
        );

        // Validate properties in bookmarks
        for (const bookmark of bookmarks) {
            requiredHeadings = (bookmark?.properties || []).filter(
                (heading: TableHeading) => heading[tableConstants.TABLE_HEADING_REQUIRED]
            );
            validationList = validationList.concat(
                isObjectForServerValid(
                    objectType,
                    modalType,
                    bookmark[propertyConstants.PROPERTY_KEY],
                    requiredHeadings,
                    item,
                    data,
                    additionalParams
                )
            );
        }

        // If checkProperties are defined, return only those validations which contain properties in checkProperties, otherwise return all validations
        validationList = checkPropertiesInValidation(objectType, validationList, checkProperties);

        // For export tasks single+bulk, return only those validations which are visible, according to export task type options
        // TODO move probably to checkProperties
        if (objectType === objectTypes.EXPORT_TASK || objectType === objectTypes.SYSTEM_ZONE) {
            for (const [property, visibility] of Object.entries(
                additionalParams?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE]?.[
                    propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS
                ] ?? {}
            )) {
                if (!visibility) {
                    validationList = validationList.filter((validation) => validation.name !== property);
                }
            }
        }
    }

    // Return data ready for server and list of validations
    return {
        [propertyConstants.PROPERTY_DATA]: data,
        [propertyConstants.PROPERTY_VALIDATION_LIST]: validationList
    };
};

export const checkPropertiesInValidation = (
    objectType: objectTypes.ObjectType,
    validationList: Array<Validation>,
    checkProperties: Array<any>
): Array<Validation> => {
    let updatedValidationList: Array<Validation> = [...validationList];

    if (checkProperties.length && validationList.length) {
        updatedValidationList = validationList.filter((item: Validation) =>
            checkProperties.includes(item[propertyConstants.PROPERTY_NAME])
        );
    }

    if (checkProperties.length === 0 && objectType === objectTypes.ZONE) {
        updatedValidationList = validationList.filter(
            (item: Validation) => item[propertyConstants.PROPERTY_NAME] !== propertyConstants.PROPERTY_PASSWORD_HASH
        );
    }

    return updatedValidationList;
};

export const showInvalidCaption = (
    validation: Validation,
    modalId: number,
    callBackValidation: (validationType: validationConstants.ValidationType, validation: Validation | null) => any
): void => {
    switch (validation[propertyConstants.PROPERTY_TYPE]) {
        case validationConstants.CLIENT_ERROR_VALIDATION:
            callBackValidation(validationConstants.CLIENT_ERROR_VALIDATION, validation);
            break;
        case validationConstants.FOOTER_VALIDATION:
            callBackValidation(validationConstants.FOOTER_VALIDATION, validation);
            break;
        case validationConstants.GENERAL_VALIDATION:
            callBackValidation(validationConstants.GENERAL_VALIDATION, validation);
            break;
        case validationConstants.INPUT_VALIDATION:
        case validationConstants.REQUIRED_VALIDATION:
            setInvalidInput(
                modalId,
                validation[propertyConstants.PROPERTY_NAME],
                validation[propertyConstants.PROPERTY_CAPTION]
            );
            break;
        default:
            break;
    }
};

export const hideInvalidCaption = (
    validation: Validation,
    modalId: number,
    callBackValidation: (validationType: validationConstants.ValidationType, validation: Validation | null) => any
): void => {
    switch (validation[propertyConstants.PROPERTY_TYPE]) {
        case validationConstants.FOOTER_VALIDATION:
            callBackValidation(validationConstants.FOOTER_VALIDATION, null);
            break;
        case validationConstants.GENERAL_VALIDATION:
            callBackValidation(validationConstants.GENERAL_VALIDATION, null);
            break;
        case validationConstants.INPUT_VALIDATION:
        case validationConstants.REQUIRED_VALIDATION:
            hideInvalidInput(modalId, validation[propertyConstants.PROPERTY_NAME]);
            break;
        default:
            break;
    }
};

export const processValidationList = (
    validationList: Array<Validation>,
    oldValidationList: Array<Validation>,
    modalId: number,
    callBackValidation: (validationType: validationConstants.ValidationType, validation: Validation | null) => any
): void => {
    let showValidationList = [...validationList];

    const solvedValidationList = oldValidationList.filter(
        (validation: Validation) =>
            !validationList.some(
                (newValidation: Validation) =>
                    validation[propertyConstants.PROPERTY_NAME] === newValidation[propertyConstants.PROPERTY_NAME]
            ) && validation[propertyConstants.PROPERTY_NAME] !== propertyConstants.PROPERTY_PASSWORD_HASH
    );

    if (solvedValidationList.length > 0) {
        showValidationList = validationList.filter((validation: Validation) =>
            solvedValidationList.some(
                (solvedValidation: Validation) =>
                    validation[propertyConstants.PROPERTY_NAME] !== solvedValidation[propertyConstants.PROPERTY_NAME]
            )
        );
    }

    for (const validation of showValidationList) {
        showInvalidCaption(validation, modalId, callBackValidation);
    }

    for (const validation of solvedValidationList) {
        hideInvalidCaption(validation, modalId, callBackValidation);
    }
};

export const formatTranslationKey = (key: string): string => {
    return key.replace(/_([a-zA-Z])/g, function (_value1, value) {
        return value.toUpperCase();
    });
};
