import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";

import { SystemZone, updateSystemZoneIsChecked } from "../types/systemZone";

import { Database } from "../types/database";

export type DatabaseSystemZoneState = Readonly<{
    list: Array<Database>;
    systemZoneAllList: Array<SystemZone>;
    systemZoneList: Array<SystemZone>;
    systemZoneActiveList: Array<SystemZone>;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneSortingAsc: boolean;
    systemZoneSortingCriterion: propertyConstants.Property;
}>;

const initialState: DatabaseSystemZoneState = {
    list: [],
    systemZoneAllList: [],
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSearch: tableConstants.DEFAULT_SEARCH,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemZoneSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN
};

export default function databaseSystemZones(
    state: DatabaseSystemZoneState = initialState,
    action: Record<string, any>
): DatabaseSystemZoneState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.DATABASE_SYSTEM_ZONE) {
                return {
                    ...state,
                    list: action.payload.list
                };
            }
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                const newActiveList = generalHelper.updateActiveLists(state.systemZoneActiveList, action.payload.list);

                return {
                    ...state,
                    systemZoneAllList: action.payload.list,
                    systemZoneList: action.payload.list,
                    systemZoneActiveList: newActiveList
                };
            }
            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                const sortedAllList = generalHelper.sortItemsByProperty(
                    action.payload.list,
                    propertyConstants.PROPERTY_KEY,
                    state.systemZoneSortingAsc
                );
                const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                    sortedAllList,
                    state.systemZoneSearch
                );

                const updateSystemZoneAllList = state.systemZoneAllList.map((allItem: SystemZone) => {
                    return (
                        filteredSearchList.find(
                            (item: SystemZone) =>
                                item[propertyConstants.PROPERTY_ID] === allItem[propertyConstants.PROPERTY_ID]
                        ) || allItem
                    );
                });

                const newActiveList = generalHelper.updateActiveLists(state.systemZoneActiveList, filteredSearchList);

                return {
                    ...state,
                    systemZoneAllList: updateSystemZoneAllList,
                    systemZoneList: filteredSearchList,
                    systemZoneActiveList: newActiveList
                };
            }
            return state;
        }

        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                return {
                    ...state,
                    systemZoneActiveList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.CHECK_ALL: {
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                const updatedSystemZoneList: Array<SystemZone> = [];
                const sortedAllList = generalHelper.sortItemsByProperty(
                    state.systemZoneAllList,
                    propertyConstants.PROPERTY_KEY,
                    state.systemZoneSortingAsc
                );
                const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                    sortedAllList,
                    state.systemZoneSearch
                );

                for (const item of filteredSearchList) {
                    const updatedSystemZone = updateSystemZoneIsChecked(item, action.payload.checkAll);

                    if (updatedSystemZone) {
                        updatedSystemZoneList.push(updatedSystemZone);
                    }
                }

                const updateSystemZoneAllList = state.systemZoneAllList.map((allItem: SystemZone) => {
                    return (
                        updatedSystemZoneList.find(
                            (item: SystemZone) =>
                                item[propertyConstants.PROPERTY_ID] === allItem[propertyConstants.PROPERTY_ID]
                        ) || allItem
                    );
                });

                const newActiveList = generalHelper.updateActiveLists(
                    state.systemZoneActiveList,
                    updatedSystemZoneList
                );
                return {
                    ...state,
                    systemZoneAllList: updateSystemZoneAllList,
                    systemZoneList: updatedSystemZoneList,
                    systemZoneActiveList: newActiveList
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                const sortedAllList = generalHelper.sortItemsByProperty(
                    state.systemZoneAllList,
                    propertyConstants.PROPERTY_KEY,
                    state.systemZoneSortingAsc
                );
                const filteredSearchList = generalHelper.getGlobalSearchFilteredList(
                    sortedAllList,
                    action.payload.search
                );
                const newActiveList = generalHelper.updateActiveLists(state.systemZoneActiveList, filteredSearchList);

                return {
                    ...state,
                    systemZoneList: filteredSearchList,
                    systemZoneActiveList: newActiveList,
                    systemZoneSearch: action.payload.search
                };
            }

            return state;
        }
        case actionConstants.SET_SORTING: {
            if (action.payload.objectType === objectTypes.SYSTEM_ZONE) {
                return {
                    ...state,
                    systemZoneList: generalHelper.sortItemsByProperty(
                        state.systemZoneList,
                        action.payload.criterion,
                        action.payload.asc
                    ),
                    systemZoneSortingAsc: action.payload.asc,
                    systemZoneSortingCriterion: action.payload.criterion
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                list: state.list,
                systemZoneAllList: state.systemZoneAllList,
                systemZoneList: state.systemZoneList
            };
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE:
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            if ((action.payload && action.payload.type === modalTypes.MODAL_DATA_EXPORT_ALL) || !action.payload) {
                const updatedSystemZoneList: Array<SystemZone> = [];

                for (const item of state.systemZoneAllList) {
                    const updatedSystemZone = updateSystemZoneIsChecked(item, false);

                    if (updatedSystemZone) {
                        updatedSystemZoneList.push(updatedSystemZone);
                    }
                }

                return {
                    ...initialState,
                    list: state.list,
                    systemZoneAllList: updatedSystemZoneList,
                    systemZoneList: updatedSystemZoneList
                };
            }

            return state;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
