import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Unit, mapUnit } from "../../types/unit";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_UNITS_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const unitList: Array<Unit> = [];
            let unit: Unit | null;

            for (const item of response.data.data) {
                unit = mapUnit(item);

                if (unit) {
                    unitList.push(unit);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.UNIT, unitList)];
        }
        case methods.METHOD_GET_ALL_UNITS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const unitList: Array<Unit> = [];
            let unit: Unit | null;

            for (const item of response.data.data) {
                unit = mapUnit(item);

                if (unit) {
                    unitList.push(unit);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.UNIT, unitList, response.data.count)];
        }
        case methods.METHOD_ADD_UNIT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.UNIT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_UNITS_ADD)
            ];
        }
        case methods.METHOD_EDIT_UNIT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_UNITS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.UNIT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_UNITS_EDIT));
            } else {
                const unit = mapUnit(response.data);

                if (unit) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.UNIT, [unit]));
                }
            }
            return requests;
        }
        case methods.METHOD_DELETE_UNIT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.UNIT),
                universalObjectActions.setActiveItems(objectTypes.UNIT, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
