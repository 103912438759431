import * as designConstants from "../../../constants/designConstants";
import * as designHelper from "../../../helpers/designHelper";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";
import { Price } from "../../../types/price";
import { ReactSVG } from "react-svg";

import imgLock from "../../../resources/img/icons/lock.svg";
import imgUnlock from "../../../resources/img/icons/lock-unlock.svg";

type Props = {
    objectType: objectTypes.ObjectType;
    className: string;
    type: tableConstants.TableType;
    property: propertyConstants.Property;
    editable: boolean;
    required: boolean;
    value: string;
    unit?: string;
    object?: any;
    priceObject?: any;
    callbackInputChange: (value: any) => any;
    callbackInputBlur: (value: any) => any;
    callbackButton?: (value?: any) => any;
};
// TODO check whole file https://gitlab.deso.cz/Zita/redlike-lab-client/-/merge_requests/440
// TODO remove to general/inputs?
export class ModalInputButton extends Component<Props> {
    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { type, object } = this.props;
        let validation = false;
        let newValue = validationHelper.formatStringFromHtml(event.target.value);

        switch (type) {
            case tableConstants.TABLE_TYPE_PRICE: {
                validation = object !== undefined && validationHelper.isDecimalValueValid(newValue);
                break;
            }
            default: {
                break;
            }
        }

        if (validation) {
            switch (type) {
                case tableConstants.TABLE_TYPE_PRICE: {
                    newValue = {
                        ...object,
                        [propertyConstants.PROPERTY_VALUE]: newValue
                    };
                    break;
                }
                default: {
                    break;
                }
            }

            this.props.callbackInputChange(newValue);
        }
    };

    handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { type, property, object, priceObject } = this.props;
        let newValue: any = event.target.value;
        const price =
            priceObject?.find(
                (obj: Price) => obj[propertyConstants.PROPERTY_ID] === object[propertyConstants.PROPERTY_ID]
            ) || null;
        const priceIndex = price && priceObject ? priceObject.indexOf(price) : null;

        newValue = newValue.trim();

        switch (type) {
            case tableConstants.TABLE_TYPE_PRICE: {
                newValue = validationHelper.formatNumber(this.props.objectType, property, newValue);

                if (propertyHelper.isPropertyVat(property)) {
                    newValue = formattingHelper.formatFromPercent(newValue);
                }
                newValue = newValue.trim() !== "" ? newValue : null;
                newValue = !isNaN(newValue) && newValue !== null ? Number(newValue) : null;

                if (priceObject) {
                    if (object) {
                        if (
                            priceIndex === 0 ||
                            (priceObject[priceIndex - 1] &&
                                (priceObject[priceIndex - 1].value === null ||
                                    priceObject[priceIndex - 1].value < newValue))
                        ) {
                            newValue = {
                                ...object,
                                [propertyConstants.PROPERTY_VALUE]: newValue
                            };
                        } else {
                            newValue = {
                                ...object,
                                [propertyConstants.PROPERTY_VALUE]: null
                            };
                        }
                    }
                } else {
                    if (object) {
                        newValue = {
                            ...object,
                            [propertyConstants.PROPERTY_VALUE]: newValue
                        };
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        this.props.callbackInputBlur(newValue);
    };

    handleButtonClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        const { object, callbackButton } = this.props;
        let newValue = null;

        if (object && object[propertyConstants.PROPERTY_OVERWRITE] !== undefined && callbackButton !== undefined) {
            switch (this.props.type) {
                case tableConstants.TABLE_TYPE_PRICE: {
                    newValue = {
                        ...object,
                        [propertyConstants.PROPERTY_OVERWRITE]: !object[propertyConstants.PROPERTY_OVERWRITE]
                    };
                    break;
                }
                default: {
                    newValue = !object[propertyConstants.PROPERTY_OVERWRITE];
                    break;
                }
            }

            callbackButton(newValue);
        }
    };

    getClassName = (): string => {
        let newClassName = "type-input";

        if (!this.props.editable) {
            newClassName += " disabled";
        }
        if (this.props.unit) {
            newClassName += " units-input";
        }

        return newClassName;
    };

    getButtonClassName = (): string => {
        let newClassName = "btn btn-info input-btn";

        if (
            !this.props.editable ||
            this.props.callbackButton === undefined ||
            this.props.object[propertyConstants.PROPERTY_OVERWRITE] === undefined ||
            this.props.value === ""
        ) {
            newClassName += " disabled";
        }

        return newClassName;
    };

    getUnit = (): JSX.Element | null => {
        const { unit } = this.props;

        if (unit) {
            return <span className="unit-input">{unit}</span>;
        }
        return null;
    };

    getButton = (): JSX.Element => {
        if (
            this.props.object &&
            this.props.object[propertyConstants.PROPERTY_OVERWRITE] !== undefined &&
            this.props.callbackButton !== undefined &&
            this.props.value !== ""
        ) {
            return (
                <button
                    className={this.getButtonClassName()}
                    onClick={(event: any): void => this.handleButtonClick(event)}
                >
                    <ReactSVG
                        src={this.props.object[propertyConstants.PROPERTY_OVERWRITE] ? imgUnlock : imgLock}
                        className="svg-icon"
                    />
                </button>
            );
        }

        return (
            <button className={this.getButtonClassName()}>
                <ReactSVG src={imgUnlock} className="svg-icon" />
            </button>
        );
    };

    getInput = (): JSX.Element => {
        const { editable, unit } = this.props;
        const textWidth = unit ? designHelper.getTextWidth(unit) : 0;
        const unitPadding = unit && textWidth !== 0 ? textWidth : designConstants.UNIT_INPUT_PADDING;

        return (
            <div className={this.getClassName()} key="input-item">
                <input
                    style={{ paddingRight: unitPadding }}
                    type={"text"}
                    value={this.props.value}
                    readOnly={!editable}
                    disabled={!editable}
                    onFocus={(event): void => this.handleFocus(event)}
                    onChange={(event): void => this.handleInputChange(event)}
                    onBlur={(event): void => this.handleInputBlur(event)}
                />
                {this.getUnit()}
            </div>
        );
    };

    render(): JSX.Element {
        const className =
            this.props.object && this.props.object[propertyConstants.PROPERTY_OVERWRITE] === false
                ? this.props.className + " lock"
                : this.props.className;

        return (
            <div className={className}>
                {this.getInput()}
                {this.getButton()}
            </div>
        );
    }
}
