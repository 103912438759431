import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";
import { ReactSVG } from "react-svg";

import imgEye from "../../../resources/img/icons/eye.svg";
import imgEyeNo from "../../../resources/img/icons/eye-no.svg";

type Props = {
    modalId?: number;
    className?: string;
    editable: boolean;
    type?: tableConstants.TableType;
    objectType?: objectTypes.ObjectType;
    params?: propertyConstants.Property;
    value: any;
    autoComplete: string;
    callbackChange: (value: string) => any;
    callbackBlur: (event: any) => any;
    callbackKeyDown: (event: any) => any;
};

type State = {
    showPassword: boolean;
};

export class PasswordInput extends Component<Props, State> {
    state: State = {
        showPassword: false
    };

    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { callbackChange } = this.props;
        const content = validationHelper.formatStringFromHtml(event.target.value);

        callbackChange(content);
    };

    handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { callbackBlur } = this.props;

        let value: any = event.target.value;
        value = validationHelper.formatStringFromHtml(value);
        if (value.trim() === "") {
            value = null;
        }
        callbackBlur(value);
    };

    handleShowPasswordClick = (): void => {
        const { showPassword } = this.state;

        this.setState({
            showPassword: !showPassword
        });
    };

    getClassName = (): string => {
        let updatedClassname = this.props.className ?? "";

        if (this.props.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
            updatedClassname += " big-input";
        }

        return updatedClassname;
    };

    getInputType = (): string => {
        const { showPassword } = this.state;

        if (this.props.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
            return "text";
        }

        if (showPassword) {
            return "text";
        }

        return "password";
    };

    getShowPasswordButton = (): JSX.Element | null => {
        const { showPassword } = this.state;

        if (this.props.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
            return null;
        }

        if (showPassword) {
            return (
                <button
                    className="btn-without-style password-button"
                    type="button"
                    onClick={this.handleShowPasswordClick}
                >
                    <ReactSVG className="btn-icon" src={imgEyeNo} />
                </button>
            );
        }

        return (
            <button className="btn-without-style password-button" type="button" onClick={this.handleShowPasswordClick}>
                <ReactSVG className="btn-icon" src={imgEye} />
            </button>
        );
    };

    render(): JSX.Element | null {
        const { autoComplete, value, editable, callbackKeyDown } = this.props;
        return (
            <div className={this.getClassName()}>
                <input
                    autoComplete={autoComplete}
                    type={this.getInputType()}
                    value={value}
                    readOnly={!editable}
                    onFocus={(event): void => this.handleFocus(event)}
                    onChange={(event): void => this.handleChange(event)}
                    onBlur={(event): void => this.handleBlur(event)}
                    onKeyDown={(event): void => callbackKeyDown(event)}
                />
                {this.getShowPasswordButton()}
            </div>
        );
    }
}
