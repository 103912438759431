import * as formulaConstants from "../constants/entityConstants/formulaConstants";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { FormulaColorant, mapFormulaColorant } from "./formulaColorant";

import { BaseInProduct } from "./baseInProduct";
import { Color } from "./color";
import { Colorant } from "./colorant";
import { Product } from "./product";
import { TableHeading } from "./tableHeading";

import { t as translate } from "react-i18nify";

export class Formula {
    id: number;
    colorInFandeckId: number;
    name: string | null;
    verified: boolean;
    enabled: boolean;
    rejected: boolean;
    historical: boolean;
    colorantList: Array<FormulaColorant>;
    colorantSortingAsc: boolean;
    colorantSortingCriterion: string;
    productId: number;
    productName: string;
    baseInProductId: number | null;
    forNominalAmount: boolean;
    baseAmount: number | null;
    baseName: string | null;
    isGravimetric: boolean;
    moreInfo: string | null;
    moreInfoFormatted: string | null;
    moreInfoInternal: string | null;
    moreInfoInternalFormatted: string | null;
    moreInfoPrint: string | null;
    moreInfoPrintFormatted: string | null;
    uuid: string | null;
    dateCreated: string | null;
    updateOnly: boolean;
    priority: number | null;
    status: formulaConstants.FormulaStatus;
    notAvailable: boolean;

    constructor(
        id: number,
        colorInFandeckId: number,
        name: string | null,
        verified: boolean,
        enabled: boolean,
        rejected: boolean,
        historical: boolean,
        colorantList: Array<FormulaColorant>,
        colorantSortingAsc: boolean,
        colorantSortingCriterion: string,
        productId: number,
        productName: string,
        baseInProductId: number | null,
        forNominalAmount: boolean,
        baseAmount: number | null,
        baseName: string | null,
        isGravimetric: boolean,
        moreInfo: string | null,
        moreInfoFormatted: string | null,
        moreInfoInternal: string | null,
        moreInfoInternalFormatted: string | null,
        moreInfoPrint: string | null,
        moreInfoPrintFormatted: string | null,
        uuid: string | null,
        dateCreated: string | null,
        updateOnly: boolean,
        priority: number | null,
        notAvailable: boolean
    ) {
        this.id = id;
        this.colorInFandeckId = colorInFandeckId;
        this.name = name;
        this.verified = verified;
        this.enabled = enabled;
        this.rejected = rejected;
        this.historical = historical;
        this.colorantList = colorantList;
        this.colorantSortingAsc = colorantSortingAsc;
        this.colorantSortingCriterion = colorantSortingCriterion;
        this.productId = productId;
        this.productName = productName;
        this.baseInProductId = baseInProductId;
        this.forNominalAmount = forNominalAmount;
        this.baseAmount = baseAmount;
        this.baseName = baseName;
        this.isGravimetric = isGravimetric;
        this.moreInfo = moreInfo;
        this.moreInfoFormatted = moreInfoFormatted;
        this.moreInfoInternal = moreInfoInternal;
        this.moreInfoInternalFormatted = moreInfoInternalFormatted;
        this.moreInfoPrint = moreInfoPrint;
        this.moreInfoPrintFormatted = moreInfoPrintFormatted;
        this.uuid = uuid;
        this.dateCreated = dateCreated;
        this.updateOnly = updateOnly;
        this.priority = priority;
        this.status = updateFormulaStatus(verified, enabled, rejected, historical, notAvailable);
        this.notAvailable = notAvailable;
    }
}

export function mapFormula(data: Record<string, any>, colorantList: Array<FormulaColorant>): Formula | null {
    try {
        return new Formula(
            data.id,
            data.colorInFandeckId,
            data.name,
            data.verified,
            data.enabled,
            data.rejected,
            data.historical,
            colorantList,
            tableConstants.DEFAULT_SORTING_ASC,
            propertyConstants.PROPERTY_COLORANT_NAME,
            data.product ? data.product.id : 0,
            data.product ? data.product.name : "",
            data.baseInProductId,
            data.forNominalAmount,
            data.baseAmount ?? 1000,
            data.base ? data.base.name : null,
            data.isGravimetric,
            data.moreInfoParsed ? data.moreInfoParsed.textOrig : data.moreInfo,
            data.moreInfoParsed ? data.moreInfoParsed.textFormatted : "",
            data.moreInfoInternalParsed ? data.moreInfoInternalParsed.textOrig : data.moreInfoInternal,
            data.moreInfoInternalParsed ? data.moreInfoInternalParsed.textFormatted : "",
            data.moreInfoPrintParsed ? data.moreInfoPrintParsed.textOrig : data.moreInfoPrint,
            data.moreInfoPrintParsed ? data.moreInfoPrintParsed.textFormatted : "",
            data.uuid,
            data.created,
            formulaConstants.DEFAULT_FORMULA_UPDATE_ONLY,
            data.priority,
            data.notAvailable
        );
    } catch (e) {
        return null;
    }
}

export function mapFormulas(data: Array<any>): Array<Formula> {
    const formulas = [];
    let formula;

    for (const item of data) {
        formula = mapFormula(item, item[propertyConstants.PROPERTY_COLORANT_LIST]);

        if (formula !== null) {
            formulas.push(formula);
        }
    }

    return formulas;
}

export function createEmptyFormula(
    color: Color | null = null,
    product: Product | null = null,
    colorantList: Array<Colorant>
): any {
    // TODO extract to single method
    /** Mapping formula colorants */
    const formulaColorantList = [];

    for (const colorant of colorantList) {
        const formulaColorant = mapFormulaColorant(colorant, null);

        if (formulaColorant) {
            formulaColorantList.push(formulaColorant);
        }
    }

    /** Getting automatic priority for new formula, it needs to have the highest priority to display as the first one */
    const formulaForPriority =
        generalHelper.getItemFromListByHighestPropertyValue(
            color?.[propertyConstants.PROPERTY_FORMULA_LIST] || [],
            propertyConstants.PROPERTY_PRIORITY
        ) ?? null;
    const priority =
        formulaForPriority && formulaForPriority[propertyConstants.PROPERTY_PRIORITY] !== null
            ? formulaForPriority[propertyConstants.PROPERTY_PRIORITY] + 1
            : null;

    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]: color ? color[propertyConstants.PROPERTY_ID] : null,
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_VERIFIED]: false,
        [propertyConstants.PROPERTY_ENABLED]: true,
        [propertyConstants.PROPERTY_REJECTED]: false,
        [propertyConstants.PROPERTY_HISTORICAL]: false,
        [propertyConstants.PROPERTY_COLORANT_LIST]: formulaColorantList,
        [propertyConstants.PROPERTY_COLORANT_SORTING_ASC]: tableConstants.DEFAULT_SORTING_ASC,
        [propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION]: propertyConstants.PROPERTY_COLORANT_NAME,
        [propertyConstants.PROPERTY_PRODUCT_ID]: product ? product[propertyConstants.PROPERTY_ID] : null,
        [propertyConstants.PROPERTY_PRODUCT_NAME]: product ? product[propertyConstants.PROPERTY_NAME] : "",
        [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: "",
        [propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT]: false,
        [propertyConstants.PROPERTY_BASE_AMOUNT]: serverConstants.DEFAULT_FORMULA_BASE_AMOUNT,
        [propertyConstants.PROPERTY_BASE_NAME]: "",
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: false,
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_MORE_INFO_FORMATTED]: "",
        [propertyConstants.PROPERTY_MORE_INFO_INTERNAL]: "",
        [propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED]: "",
        [propertyConstants.PROPERTY_MORE_INFO_PRINT]: "",
        [propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_DATE_CREATED]: "",
        [propertyConstants.PROPERTY_UPDATE_ONLY]: formulaConstants.DEFAULT_FORMULA_UPDATE_ONLY,
        [propertyConstants.PROPERTY_PRIORITY]: priority,
        [propertyConstants.PROPERTY_STATUS]: formulaConstants.FORMULA_STATUS_ACCEPTED,
        [propertyConstants.PROPERTY_NOT_AVAILABLE]: false
    };
}

export function cloneFormula(formula: Formula | null): Formula | null {
    if (!formula) {
        return null;
    }

    try {
        return new Formula(
            formula.id,
            formula.colorInFandeckId,
            formula.name,
            formula.verified,
            formula.enabled,
            formula.rejected,
            formula.historical,
            formula.colorantList,
            formula.colorantSortingAsc,
            formula.colorantSortingCriterion,
            formula.productId,
            formula.productName,
            formula.baseInProductId,
            formula.forNominalAmount,
            formula.baseAmount,
            formula.baseName,
            formula.isGravimetric,
            formula.moreInfo,
            formula.moreInfoFormatted,
            formula.moreInfoInternal,
            formula.moreInfoInternalFormatted,
            formula.moreInfoPrint,
            formula.moreInfoPrintFormatted,
            formula.uuid,
            formula.dateCreated,
            formula.updateOnly,
            formula.priority,
            formula.notAvailable
        );
    } catch (e) {
        return null;
    }
}

/**
 * Updates server formula type for displaying formula status in client.
 * DO NOT CHANGE THE ORDER
 */
export function updateFormulaStatus(
    verified: boolean,
    enabled: boolean,
    rejected: boolean,
    historical: boolean,
    notAvailable: boolean
): formulaConstants.FormulaStatus {
    if (rejected) {
        return formulaConstants.FORMULA_STATUS_CANCELED_REJECTED;
    }

    if (notAvailable) {
        return formulaConstants.FORMULA_STATUS_NOT_AVAILABLE;
    }

    if (!enabled) {
        return formulaConstants.FORMULA_STATUS_CANCELED;
    }

    if (historical) {
        if (enabled && verified) {
            return formulaConstants.FORMULA_STATUS_HISTORICAL_VERIFIED;
        }
        if (enabled) {
            return formulaConstants.FORMULA_STATUS_HISTORICAL;
        }
    }

    if (enabled && verified) {
        return formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED;
    }

    if (enabled) {
        return formulaConstants.FORMULA_STATUS_ACCEPTED;
    }

    return formulaConstants.FORMULA_STATUS_DISABLED;
}

export function updateFormulaColorantList(
    formula: Formula | null,
    colorantList: Array<FormulaColorant>,
    sortingAsc: boolean,
    sortingCriterion: string
): Formula | null {
    if (!formula) {
        return null;
    }

    try {
        return new Formula(
            formula.id,
            formula.colorInFandeckId,
            formula.name,
            formula.verified,
            formula.enabled,
            formula.rejected,
            formula.historical,
            colorantList,
            sortingAsc,
            sortingCriterion,
            formula.productId,
            formula.productName,
            formula.baseInProductId,
            formula.forNominalAmount,
            formula.baseAmount,
            formula.baseName,
            formula.isGravimetric,
            formula.moreInfo,
            formula.moreInfoFormatted,
            formula.moreInfoInternal,
            formula.moreInfoInternalFormatted,
            formula.moreInfoPrint,
            formula.moreInfoPrintFormatted,
            formula.uuid,
            formula.dateCreated,
            formula.updateOnly,
            formula.priority,
            formula.notAvailable
        );
    } catch (e) {
        return null;
    }
}

export function generateFormulaData(list: Array<Color>, enableHistorical: boolean = true): Array<any> {
    const data: Array<any> = [];
    let color: Color;
    let item: Formula;

    for (color of list) {
        for (item of color[propertyConstants.PROPERTY_FORMULA_LIST]) {
            if (item[propertyConstants.PROPERTY_ID] === color[propertyConstants.PROPERTY_FORMULA_ID]) {
                const status = updateFormulaStatus(
                    item.verified,
                    item.enabled,
                    item.rejected,
                    enableHistorical ? item.historical : false,
                    item.notAvailable
                );

                data.push({
                    [propertyConstants.PROPERTY_ID]: item.id,
                    [propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]: item.colorInFandeckId,
                    [propertyConstants.PROPERTY_NAME]: item.name,
                    [propertyConstants.PROPERTY_COLOR_NAME]: color.colorName,
                    [propertyConstants.PROPERTY_VERIFIED]: item.verified,
                    [propertyConstants.PROPERTY_ENABLED]: item.enabled,
                    [propertyConstants.PROPERTY_REJECTED]: item.rejected,
                    [propertyConstants.PROPERTY_HISTORICAL]: item.historical,
                    [propertyConstants.PROPERTY_COLORANT_LIST]: item.colorantList,
                    [propertyConstants.PROPERTY_COLORANT_SORTING_ASC]: item.colorantSortingAsc,
                    [propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION]: item.colorantSortingCriterion,
                    [propertyConstants.PROPERTY_PRODUCT_ID]: item.productId,
                    [propertyConstants.PROPERTY_PRODUCT_NAME]: item.productName,
                    [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: item.baseInProductId,
                    [propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT]: item.forNominalAmount,
                    [propertyConstants.PROPERTY_BASE_AMOUNT]: item.baseAmount,
                    [propertyConstants.PROPERTY_BASE_NAME]: item.baseName,
                    [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: item.isGravimetric,
                    [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
                    [propertyConstants.PROPERTY_MORE_INFO_FORMATTED]: item.moreInfoFormatted,
                    [propertyConstants.PROPERTY_MORE_INFO_INTERNAL]: item.moreInfoInternal,
                    [propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED]: item.moreInfoInternalFormatted,
                    [propertyConstants.PROPERTY_MORE_INFO_PRINT]: item.moreInfoPrint,
                    [propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED]: item.moreInfoPrintFormatted,
                    [propertyConstants.PROPERTY_UUID]: item.uuid,
                    [propertyConstants.PROPERTY_DATE_CREATED]: item.dateCreated,
                    [propertyConstants.PROPERTY_UPDATE_ONLY]: item.updateOnly,
                    [propertyConstants.PROPERTY_PRIORITY]: item.priority,
                    [propertyConstants.PROPERTY_STATUS]: status,
                    [propertyConstants.PROPERTY_NOT_AVAILABLE]: item.notAvailable
                });
            }
        }
    }

    return data;
}

export function generateFormulaHeadings(
    objectType: objectTypes.ObjectType,
    productList: Array<Product>,
    baseInProductList: Array<BaseInProduct>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const amountTypeList = [
        { key: "false", value: translate("formula.formulaForBaseVolume") },
        { key: "true", value: translate("formula.formulaForNominalAmount") }
    ];
    const formulaStatusList = [
        {
            key: formulaConstants.FORMULA_STATUS_ACCEPTED,
            value: translate("general.accepted")
        },
        {
            key: formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED,
            value: translate("general.accepted") + " / " + translate("general.verified")
        },
        {
            key: formulaConstants.FORMULA_STATUS_CANCELED,
            value: translate("general.canceled")
        },
        {
            key: formulaConstants.FORMULA_STATUS_CANCELED_REJECTED,
            value: translate("general.canceled") + " / " + translate("general.rejected")
        },
        {
            key: formulaConstants.FORMULA_STATUS_NOT_AVAILABLE,
            value: translate("general.notAvailable")
        }
    ];
    const gravimetricList = [
        { key: "false", value: "ml" },
        { key: "true", value: "g" }
    ];

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BASE_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_AMOUNT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_BASE_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_BASE_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_BASE_AMOUNT),
            [],
            widthOption?.[propertyConstants.PROPERTY_BASE_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            editableList.includes(propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            requiredList.includes(propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID),
            baseInProductList,
            widthOption?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_BASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_BASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_CREATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            editableList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_CREATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_CREATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            editableList.includes(propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            requiredList.includes(propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT),
            amountTypeList,
            widthOption?.[propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_GRAVIMETRIC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            editableList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            requiredList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            gravimetricList,
            widthOption?.[propertyConstants.PROPERTY_IS_GRAVIMETRIC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO_FORMATTED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO_FORMATTED),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO_FORMATTED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO_INTERNAL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO_INTERNAL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED
            ),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO_INTERNAL_FORMATTED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO_PRINT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO_PRINT),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO_PRINT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO_PRINT_FORMATTED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_ID),
            productList,
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_STATUS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_STATUS),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_STATUS),
            editableList.includes(propertyConstants.PROPERTY_STATUS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_STATUS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_STATUS),
            requiredList.includes(propertyConstants.PROPERTY_STATUS),
            formulaStatusList,
            widthOption?.[propertyConstants.PROPERTY_STATUS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UPDATE_ONLY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UPDATE_ONLY),
            tableConstants.TABLE_TYPE_ALERT,
            visibleList.includes(propertyConstants.PROPERTY_UPDATE_ONLY),
            editableList.includes(propertyConstants.PROPERTY_UPDATE_ONLY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UPDATE_ONLY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UPDATE_ONLY),
            requiredList.includes(propertyConstants.PROPERTY_UPDATE_ONLY),
            [],
            widthOption?.[propertyConstants.PROPERTY_UPDATE_ONLY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
