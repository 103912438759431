import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Database } from "../../../types/database";
import { ExportScene } from "../../../components/scenes/data/ExportScene";
import { ExportStatus } from "../../../types/exportStatus";
import { ExportTask } from "../../../types/exportTask";
import { ExportType } from "../../../types/exportType";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { System } from "../../../types/system";
import { SystemZone } from "../../../types/systemZone";
import { Zone } from "../../../types/zone";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    privileges: any;
    databaseSystemZoneList: Array<Database>;
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
    exportTypeList: Array<ExportType>;
    exportStatusList: Array<ExportStatus>;
    systemZoneAllList: Array<SystemZone>;
    exportDataConstant: string;
    exportList: Array<ExportTask>;
    exportActiveList: Array<ExportTask>;
    exportLoading: boolean;
    exportColumnOrder: Array<propertyConstants.Property>;
    exportColumnVisibility: Record<propertyConstants.Property, boolean>;
    exportColumnWidth: Record<propertyConstants.Property, number>;
    exportOffset: number;
    exportPage: number;
    exportRowCount: number;
    exportRowCountCustom: boolean;
    exportSearch: string | null;
    exportSearchParams: Record<propertyConstants.Property, string>;
    exportShowDeletedValues: boolean;
    exportShowFilterRow: boolean;
    exportShowGlobalSearch: boolean;
    exportShowHiddenValues: boolean;
    exportSortingAsc: boolean;
    exportSortingCriterion: propertyConstants.Property | null;
    exportTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_EXPORT
    ),
    privileges: state.login.privileges,
    databaseSystemZoneList: state.databaseSystemZone.list,
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    exportTypeList: state.export.exportTypes,
    exportStatusList: state.export.exportStatuses,
    systemZoneAllList: state.databaseSystemZone.systemZoneAllList,
    exportDataConstant: menuConstants.TABLE_DATA_EXPORT,
    exportList: state.export.list,
    exportActiveList: state.export.activeList,
    exportLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_EXPORT_TASKS ||
            request.method === methods.METHOD_GET_ALL_EXPORT_TASK_TYPES ||
            request.method === methods.METHOD_GET_ALL_EXPORT_TASK_STATUSES ||
            request.method === methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES ||
            request.method === methods.METHOD_DELETE_EXPORT_TASK
    ),
    exportColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportOffset: state.export.offset,
    exportPage: state.export.page,
    exportRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportSearch: state.export.search,
    exportSearchParams: state.export.searchParams,
    exportShowDeletedValues: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
        optionsConstants.OPTION_EXPORT_TASK_SHOW_DELETED
    ),
    exportShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_DATA_EXPORT
    ),
    exportShowHiddenValues: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ITEMS_VISIBILITY,
        optionsConstants.OPTION_EXPORT_TASK_SHOW_HIDDEN
    ),
    exportSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_EXPORT
        )
    ),
    exportSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_EXPORT
        )
    ),
    exportTotalCount: state.export.count
});

export const ExportSceneContainer = connect(mapStateToProps)(ExportScene);
