import * as formulaActions from "../../../actions/formulaActions";
import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as navigationActions from "../../../actions/navigationActions";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as settingsActions from "../../../actions/optionActions";
import * as tableHelper from "../../../helpers/tableHelper";
import * as universalObjectActions from "../../../actions/universalObjectActions";

import { AppState } from "../../../reducers";
import { BaseInProduct } from "../../../types/baseInProduct";
import { Color } from "../../../types/color";
import { Colorant } from "../../../types/colorant";
import { Dispatch } from "redux";
import { Fandeck } from "../../../types/fandeck";
import { Formula } from "../../../types/formula";
import { MenuItem } from "../../../types/menu";
import { Product } from "../../../types/product";
import { ServerRequest } from "../../../types/serverRequest";
import { Unit } from "../../../types/unit";
import { ZoneFormulaScene } from "../../../components/scenes/zone/ZoneFormulaScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    activeBookmark: string | null;
    baseInProductList: Array<BaseInProduct>;
    colorantList: Array<Colorant>;
    fandeckList: Array<Fandeck>;
    fandeckActiveList: Array<Fandeck>;
    fandeckLoading: boolean;
    productList: Array<Product>;
    productActiveList: Array<Product>;
    productLoading: boolean;
    colorWithFormulaInfoTableConstant: string;
    colorWithFormulaInfoTableDetailConstant: string;
    colorList: Array<Color>;
    colorActiveList: Array<Color>;
    colorLoading: boolean;
    colorColumnOrder: Array<propertyConstants.Property>;
    colorColumnVisibility: Record<propertyConstants.Property, boolean>;
    colorColumnWidth: Record<propertyConstants.Property, number>;
    colorOffset: number;
    colorPage: number;
    colorRowCount: number;
    colorRowCountCustom: boolean;
    colorSearch: string | null;
    colorSearchParams: Record<propertyConstants.Property, string>;
    colorShowFilterRow: boolean;
    colorShowGlobalSearch: boolean;
    colorSortingAsc: boolean;
    colorSortingCriterion: propertyConstants.Property | null;
    colorTotalCount: number | null;
    formulasLoading: boolean;
    formulaColorantTableConstant: string;
    formulaColorantColumnOrder: Array<propertyConstants.Property>;
    formulaColorantColumnVisibility: Record<propertyConstants.Property, boolean>;
    formulaColorantColumnWidth: Record<propertyConstants.Property, number>;
    formulaSceneTableSize: Record<string, any> | null;
    unitList: Array<Unit>;
    activeUnit: Unit | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

export type DispatchType = Readonly<{
    setActive(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setActiveBookmark(key: string): void;
    setOptions(options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>, save: boolean): any;
    setSearch(objectType: objectTypes.ObjectType, search: string, column?: string): any;
    updateFormula(
        objectType: objectTypes.ObjectType,
        formulaList: Array<Formula>,
        formulaId: number | null,
        baseInProductId: number | null
    ): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_FORMULAS
    ),
    activeBookmark: state.navigation.activeBookmark,
    baseInProductList: state.baseInProduct.zoneList,
    colorantList: state.colorant.zoneList,
    fandeckList: state.fandeck.zoneList,
    fandeckActiveList: state.fandeck.zoneActiveList,
    fandeckLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_ZONE_FANDECKS
    ),
    productList: state.product.zoneList,
    productActiveList: state.product.zoneActiveList,
    productLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS
    ),
    colorWithFormulaInfoTableConstant: menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO,
    colorWithFormulaInfoTableDetailConstant: menuConstants.TABLE_ZONE_COLORS_WITH_FORMULA_INFO_DETAIL,
    colorList: state.color.zoneList,
    colorActiveList: state.color.zoneActiveList,
    colorLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO
    ),
    colorColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorOffset: state.color.zoneOffset,
    colorPage: state.color.zonePage,
    colorRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorSearch: state.color.zoneSearch,
    colorSearchParams: state.color.zoneSearchParams,
    colorShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_ZONE_COLORS
    ),
    colorSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORS
        )
    ),
    colorSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_COLORS
        )
    ),
    colorTotalCount: state.color.zoneCount,
    formulasLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO ||
            request.method === methods.METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION
    ),
    formulaColorantTableConstant: menuConstants.TABLE_ZONE_FORMULA_COLORANTS,
    formulaColorantColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_FORMULA_COLORANTS
    ),
    formulaColorantColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_FORMULA_COLORANTS
    ),
    formulaColorantColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_FORMULA_COLORANTS
    ),
    formulaSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_ZONE_FORMULAS
    ),
    unitList: state.color.unitAllList,
    activeUnit:
        state.color.unitAllList.find(
            (item: Unit) =>
                optionHelper.getValueFromOptionDictionaryByKey(
                    state.login.options,
                    optionsConstants.OPTION_FORMULA_COLORANT_UNIT
                ) === item[propertyConstants.PROPERTY_NAME]
        ) ?? null,
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_ZONE_FORMULAS
    )
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setActive: (objectType: objectTypes.ObjectType, items: Array<any>): any =>
        dispatch(universalObjectActions.setActiveItems(objectType, items)),
    setActiveBookmark: (key: string): any => dispatch(navigationActions.setActiveBookmark(key)),
    setOptions: (options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>, save: boolean): any =>
        dispatch(settingsActions.setDictionaryOptions(null, options, save)),
    setSearch: (objectType: objectTypes.ObjectType, search: string | null, column?: string): any =>
        dispatch(universalObjectActions.setSearch(objectType, search, column)),
    updateFormula: (
        objectType: objectTypes.ObjectType,
        formulaList: Array<Formula>,
        formulaId: number | null,
        baseInProductId: number | null
    ): any => dispatch(formulaActions.updateFormula(objectType, formulaList, formulaId, baseInProductId))
});

export const ZoneFormulaSceneContainer = connect(mapStateToProps, mapDispatchToProps)(ZoneFormulaScene);
