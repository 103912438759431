import * as actionConstants from "./constants/actionTypes";
import * as localConfig from "./constants/localConfig";
import * as restActions from "./actions/restActions";
import * as serverConstants from "./constants/serverConstants";
import * as serverMethods from "./constants/serverMethods";
import * as websocketActions from "./actions/websocketActions";

import { Dispatch, MiddlewareAPI, compose } from "redux";

const socketMiddleware = (): any => {
    /**
     * Websocket for communication with Redlike server.
     */
    let serverSocket: any = null;

    const initServerSocket = ({ dispatch }: MiddlewareAPI, url: string): void => {
        serverSocket = new WebSocket(url);

        serverSocket.onopen = compose(dispatch, websocketActions.serverWebsocketOpened);
        serverSocket.onclose = compose(dispatch, websocketActions.serverWebsocketClosed);
        serverSocket.onmessage = compose(dispatch, websocketActions.serverWebsocketResponse);
        serverSocket.onconnecting = dispatch(websocketActions.serverWebsocketConnecting());
    };

    const closeServerSocket = (): void => {
        if (serverSocket !== null) {
            serverSocket.close();
            serverSocket = null;
        }
    };

    return (store: MiddlewareAPI) =>
        (dispatch: Dispatch) =>
        (action: any): void => {
            switch (action.type) {
                case actionConstants.SERVER_WEBSOCKET_REQUEST: {
                    if (serverSocket && serverSocket.readyState === 1) {
                        const request = JSON.stringify(action.payload);
                        serverSocket.send(request);
                    }

                    dispatch(action);
                    break;
                }
                case actionConstants.SERVER_WEBSOCKET_CLOSED: {
                    closeServerSocket();

                    setTimeout(() => {
                        if (!store.getState().server.connected) {
                            initServerSocket(store, store.getState().server.urlWebsocket);
                        }
                    }, serverConstants.SERVER_WEBSOCKET_TIMEOUT);

                    dispatch(action);
                    break;
                }
                case actionConstants.SERVER_CONNECT: {
                    closeServerSocket();
                    initServerSocket(store, action.payload.url);
                    dispatch(action);
                    break;
                }
                case actionConstants.SERVER_DISCONNECT: {
                    closeServerSocket();
                    break;
                }
                case actionConstants.SERVER_REQUEST: {
                    if (
                        localConfig.SERVER_CONNECTION_TYPE === serverConstants.CONNECTION_REST &&
                        action.method !== serverMethods.METHOD_SERVER_INIT
                    ) {
                        dispatch(restActions.serverProcessRestRequest(action.payload, action.method));
                    } else {
                        dispatch(websocketActions.serverProcessWebsocketRequest(action.payload, action.method));
                    }
                    break;
                }
                default: {
                    dispatch(action);
                }
            }
        };
};

export default socketMiddleware();
