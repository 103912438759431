import * as propertyConstants from "../../../../constants/propertyConstants";
import * as serverRestMethods from "../../../../constants/serverRestMethods";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { Image } from "../../../../types/image";
import { ReactSVG } from "react-svg";

import { connect } from "react-redux";

import noImage from "../../../../resources/img/icons/no-image.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    activeImageList: Array<Image>;
};

type Props = OwnProps & ContainerProps;

export class ImagePreview extends Component<Props> {
    getImagePreview = (): JSX.Element => {
        const { urlRest, sessionUuid, activeImageList } = this.props;

        if (activeImageList.length && activeImageList[0][propertyConstants.PROPERTY_ID]) {
            return (
                <img
                    key="file-image"
                    alt=""
                    src={`${urlRest}${
                        serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID
                    }?session_uuid=${sessionUuid}&image_id=${
                        activeImageList[0][propertyConstants.PROPERTY_ID]
                    }&height=500&width=500`}
                />
            );
        }
        return (
            <div className="svg-container">
                <ReactSVG className="no-image-svg svg-icon" src={noImage} />
                <span className="no-image-text">{translate("image.noImagePreview")}</span>
            </div>
        );
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <div className="action-row">
                    <div className="title">{translate("general.preview")}</div>
                    <div className="btn-row-container"></div>
                </div>
                <div className="image-container">
                    <div className="loaded-images">{this.getImagePreview()} </div>
                </div>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const ImagePreviewContainer = connect(mapStateToProps, mapDispatchToProps)(ImagePreview);
