import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class Unit {
    id: number;
    name: string;
    isGravimetric: boolean;
    amountCoef: number;
    priority: number;
    canUpdate: boolean;
    canDelete: boolean;
    unit: string | null;

    constructor(
        id: number,
        name: string,
        isGravimetric: boolean,
        amountCoef: number,
        priority: number,
        canUpdate: boolean,
        canDelete: boolean,
        unit: string | null
    ) {
        this.id = id;
        this.name = name;
        this.isGravimetric = isGravimetric;
        this.amountCoef = amountCoef;
        this.priority = priority;
        this.canUpdate = canUpdate;
        this.canDelete = canDelete;
        this.unit = unit;
    }
}

export function mapUnit(data: Record<string, any>): Unit | null {
    try {
        return new Unit(
            data.id,
            data.name,
            data.isGravimetric,
            data.amountCoef,
            data.priority,
            data.canUpdate,
            data.canDelete,
            data.unit ?? null
        );
    } catch (e) {
        return null;
    }
}

export function updateUnit(oldUnit: Unit | null, updatedData: Record<string, any>): Unit | null {
    try {
        if (oldUnit === null) {
            return null;
        }

        return new Unit(
            oldUnit.id,
            updatedData.name ? updatedData.name : oldUnit.name,
            updatedData.isGravimetric ? updatedData.isGravimetric : oldUnit.isGravimetric,
            updatedData.amountCoef ? updatedData.amountCoef : oldUnit.amountCoef,
            updatedData.priority ? updatedData.priority : oldUnit.priority,
            oldUnit.canUpdate,
            oldUnit.canDelete,
            oldUnit.unit
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyUnit(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_AMOUNT_COEFFICIENT]: "",
        [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: false,
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_UNIT]: ""
    };
}

export function generateUnitData(unitList: Array<Unit>): Array<any> {
    const data: Array<any> = [];
    let unit: Unit;

    for (unit of unitList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: unit.id,
            [propertyConstants.PROPERTY_NAME]: unit.name,
            [propertyConstants.PROPERTY_IS_GRAVIMETRIC]: unit.isGravimetric,
            [propertyConstants.PROPERTY_AMOUNT_COEFFICIENT]: unit.amountCoef,
            [propertyConstants.PROPERTY_PRIORITY]: unit.priority,
            [propertyConstants.PROPERTY_CAN_UPDATE]: unit.canUpdate,
            [propertyConstants.PROPERTY_CAN_DELETE]: unit.canDelete,
            [propertyConstants.PROPERTY_UNIT]: unit.unit
        });
    }

    return data;
}

export function generateUnitHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_AMOUNT_COEFFICIENT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            editableList.includes(propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            requiredList.includes(propertyConstants.PROPERTY_AMOUNT_COEFFICIENT),
            [],
            widthOption?.[propertyConstants.PROPERTY_AMOUNT_COEFFICIENT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_GRAVIMETRIC,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            editableList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            requiredList.includes(propertyConstants.PROPERTY_IS_GRAVIMETRIC),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_GRAVIMETRIC] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
