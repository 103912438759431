import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";
import { FilePreviewContainer } from "./previews/FilePreview";
import { ImagePreviewContainer } from "./previews/ImagePreview";
import { TablePreviewRow } from "../table/TablePreviewRow";

type OwnProps = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    fileId: number | null;
    activeList: Array<any>;
    dataPrivileges: any;
};

type Props = OwnProps;

export class Preview extends Component<Props> {
    render(): JSX.Element {
        const { objectType, fileId, activeList, dataPrivileges } = this.props;

        if (objectType === objectTypes.GENERIC_IMAGE) {
            return <ImagePreviewContainer activeImageList={activeList} />;
        }

        return (
            <div className="height-100">
                <TablePreviewRow objectType={objectType} activeList={activeList} dataPrivileges={dataPrivileges} />
                <FilePreviewContainer fileId={fileId} />
            </div>
        );
    }
}
