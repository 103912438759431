import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { ProductGroup, updateProductGroup } from "../types/productGroup";

// TODO chybí systemCountAvailable , je to schválně nebo je potřeba doplnit
export type ProductGroupState = Readonly<{
    masterSystemList: Array<ProductGroup>;
    masterSystemActiveList: Array<ProductGroup>;
    masterSystemSortingAsc: boolean;
    masterSystemSortingCriterion: propertyConstants.Property;
    masterSystemSearch: string | null;
    masterSystemSearchParams: Record<propertyConstants.Property, string>;
    masterSystemOffset: number;
    masterSystemPage: number;
    systemMasterList: Array<ProductGroup>;
    systemMasterActiveList: Array<ProductGroup>;
    systemMasterSortingAsc: boolean;
    systemMasterSortingCriterion: propertyConstants.Property;
    systemMasterSearch: string | null;
    systemMasterSearchParams: Record<propertyConstants.Property, string>;
    systemMasterOffset: number;
    systemMasterPage: number;
    systemList: Array<ProductGroup>;
    systemActiveList: Array<ProductGroup>;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    systemZoneList: Array<ProductGroup>;
    systemZoneActiveList: Array<ProductGroup>;
    systemZoneSortingAsc: boolean;
    systemZoneSortingCriterion: propertyConstants.Property;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneOffset: number;
    systemZonePage: number;
    zoneSystemList: Array<ProductGroup>;
    zoneSystemActiveList: Array<ProductGroup>;
    zoneSystemSortingAsc: boolean;
    zoneSystemSortingCriterion: propertyConstants.Property;
    zoneSystemSearch: string | null;
    zoneSystemSearchParams: Record<propertyConstants.Property, string>;
    zoneSystemOffset: number;
    zoneSystemPage: number;
    zoneList: Array<ProductGroup>;
    zoneActiveList: Array<ProductGroup>;
    zoneCountAvailable: number | null;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
}>;

const initialState: ProductGroupState = {
    masterSystemList: [],
    masterSystemActiveList: [],
    masterSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    masterSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    masterSystemSearch: null,
    masterSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterSystemOffset: tableConstants.DEFAULT_OFFSET,
    masterSystemPage: tableConstants.DEFAULT_PAGE,
    systemMasterList: [],
    systemMasterActiveList: [],
    systemMasterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemMasterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemMasterSearch: null,
    systemMasterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemMasterOffset: tableConstants.DEFAULT_OFFSET,
    systemMasterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemZoneSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemZoneSearch: null,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneOffset: tableConstants.DEFAULT_OFFSET,
    systemZonePage: tableConstants.DEFAULT_PAGE,
    zoneSystemList: [],
    zoneSystemActiveList: [],
    zoneSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSystemSearch: null,
    zoneSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneSystemOffset: tableConstants.DEFAULT_OFFSET,
    zoneSystemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCountAvailable: null,
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE
};

export default function productGroups(
    state: ProductGroupState = initialState,
    action: Record<string, any>
): ProductGroupState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        masterSystemList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemMasterList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemZoneList: action.payload.list
                    };
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        zoneSystemList: action.payload.list
                    };
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountAvailable: action.payload.availableCount,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        masterSystemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    const list = generalHelper.updateLists(state.systemMasterList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        systemMasterList: list,
                        systemMasterActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemZoneActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    const list = generalHelper.updateLists(state.zoneSystemList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        zoneSystemList: list,
                        zoneSystemActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        masterSystemOffset: action.payload.offset,
                        masterSystemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemZoneOffset: action.payload.offset,
                        systemZonePage: action.payload.page
                    };
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }

        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SYSTEM_SEARCH,
                        tableConstants.MASTER_SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_MASTER_SEARCH,
                        tableConstants.SYSTEM_MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_ZONE_SEARCH,
                        tableConstants.SYSTEM_ZONE_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SYSTEM_SEARCH,
                        tableConstants.ZONE_SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        masterSystemSortingAsc: action.payload.asc,
                        masterSystemSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemMasterList: generalHelper.sortItemsByProperty(
                            state.systemMasterList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        systemMasterSortingAsc: action.payload.asc,
                        systemMasterSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        systemZoneSortingAsc: action.payload.asc,
                        systemZoneSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    return {
                        ...state,
                        zoneSystemList: generalHelper.sortItemsByProperty(
                            state.zoneSystemList,
                            action.payload.criterion,
                            action.payload.asc
                        ),
                        zoneSystemSortingAsc: action.payload.asc,
                        zoneSystemSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_PRODUCT_GROUP: {
                        const updatedProductGroupList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateProductGroup
                        );

                        if (updatedProductGroupList.length) {
                            return {
                                ...state,
                                systemList: updatedProductGroupList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_PRODUCT_GROUP: {
                        const updatedProductGroupList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateProductGroup
                        );

                        if (updatedProductGroupList.length) {
                            return {
                                ...state,
                                zoneList: updatedProductGroupList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_PRODUCT_GROUP: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_PRODUCT_GROUP: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            if (
                action.payload.wizard === null ||
                action.payload.scene === navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE
            ) {
                return {
                    ...initialState,
                    systemActiveList: state.systemActiveList
                };
            }

            return initialState;
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                masterSystemList: initialState.masterSystemList,
                masterSystemActiveList: initialState.masterSystemActiveList,
                masterSystemSearch: initialState.masterSystemSearch,
                masterSystemSearchParams: initialState.masterSystemSearchParams,
                systemMasterList: initialState.systemMasterList,
                systemMasterActiveList: initialState.systemMasterActiveList,
                systemZoneList: initialState.systemZoneList,
                systemZoneActiveList: initialState.systemZoneActiveList,
                systemZoneSearch: initialState.systemZoneSearch,
                systemZoneSearchParams: initialState.systemZoneSearchParams,
                zoneSystemList: initialState.zoneSystemList,
                zoneSystemActiveList: initialState.zoneSystemActiveList
            };
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload.type === modalTypes.MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE ||
                action.payload.type === modalTypes.MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE
            ) {
                return {
                    ...state,
                    masterSystemList: initialState.masterSystemList,
                    masterSystemActiveList: initialState.masterSystemActiveList,
                    masterSystemSearch: initialState.masterSystemSearch,
                    masterSystemSearchParams: initialState.masterSystemSearchParams,
                    systemMasterList: initialState.systemMasterList,
                    systemMasterActiveList: initialState.systemMasterActiveList,
                    systemZoneList: initialState.systemZoneList,
                    systemZoneActiveList: initialState.systemZoneActiveList,
                    systemZoneSearch: initialState.systemZoneSearch,
                    systemZoneSearchParams: initialState.systemZoneSearchParams,
                    zoneSystemList: initialState.zoneSystemList,
                    zoneSystemActiveList: initialState.zoneSystemActiveList
                };
            }
            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            // TODO keep master system and system master?
            return {
                ...initialState,
                systemList: state.systemList,
                systemActiveList: state.systemActiveList,
                systemCountTotal: state.systemCountTotal,
                systemSearch: state.systemSearch,
                systemSearchParams: state.systemSearchParams,
                systemOffset: state.systemOffset,
                systemPage: state.systemPage,
                systemZoneList: state.systemZoneList
            };
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
