import * as browserStorageConstants from "../constants/browserStorageConstants";
import * as navigationTypes from "../constants/navigationTypes";
import * as paths from "../constants/paths";
import * as routingHelper from "../helpers/routingHelper";

import { DispatchType, PropsType } from "../containers/Router";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Scene, Wizard } from "../constants/navigationTypes";

import { CompanySceneContainer } from "../containers/scenes/header/CompanySceneContainer";
import { DatabaseSceneContainer } from "../containers/scenes/data/DatabaseSceneContainer";
import { DebugContainer } from "../containers/Debug";
import { ExportSceneContainer } from "../containers/scenes/data/ExportSceneContainer";
import { GenericBarcodeSceneContainer } from "../containers/scenes/generic/GenericBarcodeSceneContainer";
import { GenericCurrencySceneContainer } from "../containers/scenes/generic/GenericCurrencySceneContainer";
import { GenericImageSceneContainer } from "../containers/scenes/generic/GenericImageSceneContainer";
import { GenericPackageSceneContainer } from "../containers/scenes/generic/GenericPackageSceneContainer";
import { GenericProductSheetSceneContainer } from "../containers/scenes/generic/GenericProductSheetSceneContainer";
import { GenericStaticSqlSceneContainer } from "../containers/scenes/generic/GenericStaticSqlSceneContainer";
import { GenericUnitSceneContainer } from "../containers/scenes/generic/GenericUnitSceneContainer";
import { HeaderContainer } from "../containers/Header";
import { HomePageContainer } from "../containers/scenes/HomePageContainer";
import { ImportSceneContainer } from "../containers/scenes/data/ImportSceneContainer";
import { LicenseContainer } from "../containers/License";
import { LoginContainer } from "../containers/Login";
import { MasterBaseSceneContainer } from "../containers/scenes/master/MasterBaseSceneContainer";
import { MasterColorSceneContainer } from "../containers/scenes/master/MasterColorSceneContainer";
import { MasterColorantSceneContainer } from "../containers/scenes/master/MasterColorantSceneContainer";
import { MasterFandeckSceneContainer } from "../containers/scenes/master/MasterFandeckSceneContainer";
import { MasterFormulaNoteSceneContainer } from "../containers/scenes/master/MasterFormulaNoteSceneContainer";
import { MasterFormulaSceneContainer } from "../containers/scenes/master/MasterFormulaSceneContainer";
import { MasterProductSceneContainer } from "../containers/scenes/master/MasterProductSceneContainer";
import { MasterRedlikeOptionSceneContainer } from "../containers/scenes/master/MasterRedlikeOptionSceneContainer";
import { MasterRedlikePrivilegeSceneContainer } from "../containers/scenes/master/MasterRedlikePrivilegeSceneContainer";
import { MasterRedlikeUserSceneContainer } from "../containers/scenes/master/MasterRedlikeUserSceneContainer";
import { Modal } from "./Modal";
import { ModalType } from "../constants/modalTypes";
import { NavigationContainer } from "../containers/Navigation";
import { NotificationPanelContainer } from "../containers/NotificationPanel";
import { OptionsSceneContainer } from "../containers/scenes/header/OptionsSceneContainer";
import { ServerMonitoringSceneContainer } from "../containers/scenes/header/ServerMonitoringSceneContainer";
import { ServerScriptsSceneContainer } from "../containers/scenes/data/ServerScriptsSceneContainer";
import { SystemBaseSceneContainer } from "../containers/scenes/system/SystemBaseSceneContainer";
import { SystemColorSceneContainer } from "../containers/scenes/system/SystemColorSceneContainer";
import { SystemColorantSceneContainer } from "../containers/scenes/system/SystemColorantSceneContainer";
import { SystemFandeckSceneContainer } from "../containers/scenes/system/SystemFandeckSceneContainer";
import { SystemFormulaNoteSceneContainer } from "../containers/scenes/system/SystemFormulaNoteSceneContainer";
import { SystemFormulaSceneContainer } from "../containers/scenes/system/SystemFormulaSceneContainer";
import { SystemPriceSceneContainer } from "../containers/scenes/system/SystemPriceSceneContainer";
import { SystemProductGroupSceneContainer } from "../containers/scenes/system/SystemProductGroupSceneContainer";
import { SystemProductSceneContainer } from "../containers/scenes/system/SystemProductSceneContainer";
import { SystemRedlikeOptionSceneContainer } from "../containers/scenes/system/SystemRedlikeOptionSceneContainer";
import { SystemRedlikeUserSceneContainer } from "../containers/scenes/system/SystemRedlikeUserSceneContainer";
import { SystemZoneSceneContainer } from "../containers/scenes/data/SystemZoneSceneContainer";
import { UserActionsHistorySceneContainer } from "../containers/scenes/header/UserActionsHistorySceneContainer";
import { UserSceneContainer } from "../containers/scenes/header/UserSceneContainer";
import { WelcomeContainer } from "../containers/Welcome";
import { ZoneBaseSceneContainer } from "../containers/scenes/zone/ZoneBaseSceneContainer";
import { ZoneColorSceneContainer } from "../containers/scenes/zone/ZoneColorSceneContainer";
import { ZoneColorantSceneContainer } from "../containers/scenes/zone/ZoneColorantSceneContainer";
import { ZoneFandeckSceneContainer } from "../containers/scenes/zone/ZoneFandeckSceneContainer";
import { ZoneFormulaNoteSceneContainer } from "../containers/scenes/zone/ZoneFormulaNoteSceneContainer";
import { ZoneFormulaSceneContainer } from "../containers/scenes/zone/ZoneFormulaSceneContainer";
import { ZonePriceSceneContainer } from "../containers/scenes/zone/ZonePriceSceneContainer";
import { ZoneProductGroupSceneContainer } from "../containers/scenes/zone/ZoneProductGroupSceneContainer";
import { ZoneProductSceneContainer } from "../containers/scenes/zone/ZoneProductSceneContainer";
import { ZoneRedlikeOptionSceneContainer } from "../containers/scenes/zone/ZoneRedlikeOptionSceneContainer";
import { ZoneRedlikeUserSceneContainer } from "../containers/scenes/zone/ZoneRedlikeUserSceneContainer";
import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

/**
 * Uses path property in sessionStorage, which is removed when logging in.
 * Listener beforeunload is used when page is refreshing (F5).
 * Listener popstate is used when forward and backward buttons are pressed.
 */
export class Router extends Component<Props> {
    componentDidMount(): void {
        sessionStorage.setItem(browserStorageConstants.SESSION_STORAGE_PATH, this.props.routingPath);

        window.addEventListener("beforeunload", this.handleBeforeUnload);
        window.addEventListener("popstate", this.handlePopState);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>): void {
        if (this.props.routingPath !== prevProps.routingPath) {
            const scene = routingHelper.getSceneByPath(this.props.routingPath);

            if (this.props.activeScene !== scene) {
                sessionStorage.setItem(browserStorageConstants.SESSION_STORAGE_PATH, this.props.routingPath);

                if (this.props.isJumping) {
                    this.props.setJumpScene(scene);
                } else {
                    this.props.setActiveScene(scene);
                }
            }
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        window.removeEventListener("popstate", this.handlePopState);
    }

    handleBeforeUnload = (): any => {
        // TODO this cases reload in static sql scene after file download, to be resolved when rollback is in use again
        // this.props.rollback();
    };

    handlePopState = (): any => {
        if (this.props.dataChanged) {
            if (window.confirm(translate("confirmationModal.saveDataText"))) {
                this.props.commit();
            } else {
                this.props.rollback();
            }
        }
    };

    handleRouting = (): any => {
        if (!this.props.serverConnected) {
            return <Redirect to={paths.PATH_WELCOME} />;
        }

        if (!this.props.licensed) {
            return <Redirect to={paths.PATH_LICENSE} />;
        }

        if (!this.props.loggedIn) {
            return <Redirect to={paths.PATH_LOGIN} />;
        }

        const sessionPath = sessionStorage.getItem(browserStorageConstants.SESSION_STORAGE_PATH);
        if (sessionPath && routingHelper.isValidPath(sessionPath)) {
            return <Redirect push to={sessionPath} />;
        }

        // homepage depends on dataPrivileges (if scene is visible), for F5 it has to be solved directly by user name
        // there should be a new home page for all users in the future
        const homePage = routingHelper.getHomePagePath(this.props.activeUser?.username || "");
        sessionStorage.setItem(browserStorageConstants.SESSION_STORAGE_PATH, homePage);
        return <Redirect push to={homePage} />;
    };

    returnMainContent = (content: Scene | Wizard): JSX.Element => {
        let scene;

        switch (content) {
            case navigationTypes.COMPANY_SCENE:
                scene = <CompanySceneContainer />;
                break;
            case navigationTypes.DATA_EXPORT_SCENE:
                scene = <ExportSceneContainer />;
                break;
            case navigationTypes.DATA_IMPORT_SCENE:
                scene = <ImportSceneContainer />;
                break;
            case navigationTypes.DATA_MASTER_SETTINGS_SCENE:
                scene = <DatabaseSceneContainer />;
                break;
            case navigationTypes.DATA_SERVER_SCRIPTS_SCENE:
                scene = <ServerScriptsSceneContainer />;
                break;
            case navigationTypes.DATA_SYSTEM_ZONE_SETTINGS_SCENE:
                scene = <SystemZoneSceneContainer />;
                break;
            case navigationTypes.DEBUG_SCENE:
                scene = <DebugContainer />;
                break;
            case navigationTypes.GENERIC_BARCODES_SCENE:
                scene = <GenericBarcodeSceneContainer />;
                break;
            case navigationTypes.GENERIC_CURRENCIES_SCENE:
                scene = <GenericCurrencySceneContainer />;
                break;
            case navigationTypes.GENERIC_IMAGES_SCENE:
                scene = <GenericImageSceneContainer />;
                break;
            case navigationTypes.GENERIC_PACKAGES_SCENE:
                scene = <GenericPackageSceneContainer />;
                break;
            case navigationTypes.GENERIC_PRODUCT_SHEETS_SCENE:
                scene = <GenericProductSheetSceneContainer />;
                break;
            case navigationTypes.GENERIC_STATIC_SQL_SCENE:
                scene = <GenericStaticSqlSceneContainer />;
                break;
            case navigationTypes.GENERIC_UNITS_SCENE:
                scene = <GenericUnitSceneContainer />;
                break;
            case navigationTypes.GLOBAL_OPTIONS_SCENE:
            case navigationTypes.USER_OPTIONS_SCENE:
                scene = <OptionsSceneContainer />;
                break;
            case navigationTypes.MASTER_BASES_SCENE:
                scene = <MasterBaseSceneContainer />;
                break;
            case navigationTypes.MASTER_COLORANTS_SCENE:
                scene = <MasterColorantSceneContainer />;
                break;
            case navigationTypes.MASTER_COLORS_SCENE:
                scene = <MasterColorSceneContainer />;
                break;
            case navigationTypes.MASTER_FANDECKS_SCENE:
                scene = <MasterFandeckSceneContainer />;
                break;
            case navigationTypes.MASTER_FORMULAS_SCENE:
                scene = <MasterFormulaSceneContainer />;
                break;
            case navigationTypes.MASTER_FORMULA_NOTES_SCENE:
                scene = <MasterFormulaNoteSceneContainer />;
                break;
            case navigationTypes.MASTER_PRODUCTS_SCENE:
                scene = <MasterProductSceneContainer />;
                break;
            case navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE:
                scene = <MasterRedlikeOptionSceneContainer />;
                break;
            case navigationTypes.MASTER_REDLIKE_PRIVILEGES_SCENE:
                scene = <MasterRedlikePrivilegeSceneContainer />;
                break;
            case navigationTypes.MASTER_REDLIKE_USERS_SCENE:
                scene = <MasterRedlikeUserSceneContainer />;
                break;
            case navigationTypes.SERVER_MONITORING_SCENE:
                scene = <ServerMonitoringSceneContainer />;
                break;
            case navigationTypes.SYSTEM_BASES_SCENE:
                scene = <SystemBaseSceneContainer />;
                break;
            case navigationTypes.SYSTEM_COLORANTS_SCENE:
                scene = <SystemColorantSceneContainer />;
                break;
            case navigationTypes.SYSTEM_COLORS_SCENE:
                scene = <SystemColorSceneContainer />;
                break;
            case navigationTypes.SYSTEM_FANDECKS_SCENE:
                scene = <SystemFandeckSceneContainer />;
                break;
            case navigationTypes.SYSTEM_FORMULAS_SCENE:
                scene = <SystemFormulaSceneContainer />;
                break;
            case navigationTypes.SYSTEM_FORMULA_NOTES_SCENE:
                scene = <SystemFormulaNoteSceneContainer />;
                break;
            case navigationTypes.SYSTEM_PRICES_SCENE:
                scene = <SystemPriceSceneContainer />;
                break;
            case navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE:
                scene = <SystemProductGroupSceneContainer />;
                break;
            case navigationTypes.SYSTEM_PRODUCTS_SCENE:
                scene = <SystemProductSceneContainer />;
                break;
            case navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE:
                scene = <SystemRedlikeOptionSceneContainer />;
                break;
            case navigationTypes.SYSTEM_REDLIKE_USERS_SCENE:
                scene = <SystemRedlikeUserSceneContainer />;
                break;
            case navigationTypes.USER_ACTION_HISTORY_SCENE:
                scene = <UserActionsHistorySceneContainer />;
                break;
            case navigationTypes.USERS_SCENE:
                scene = <UserSceneContainer />;
                break;
            case navigationTypes.ZONE_BASES_SCENE:
                scene = <ZoneBaseSceneContainer />;
                break;
            case navigationTypes.ZONE_COLORANTS_SCENE:
                scene = <ZoneColorantSceneContainer />;
                break;
            case navigationTypes.ZONE_COLORS_SCENE:
                scene = <ZoneColorSceneContainer />;
                break;
            case navigationTypes.ZONE_FANDECKS_SCENE:
                scene = <ZoneFandeckSceneContainer />;
                break;
            case navigationTypes.ZONE_FORMULAS_SCENE:
                scene = <ZoneFormulaSceneContainer />;
                break;
            case navigationTypes.ZONE_FORMULA_NOTES_SCENE:
                scene = <ZoneFormulaNoteSceneContainer />;
                break;
            case navigationTypes.ZONE_PRICES_SCENE:
                scene = <ZonePriceSceneContainer />;
                break;
            case navigationTypes.ZONE_PRODUCT_GROUPS_SCENE:
                scene = <ZoneProductGroupSceneContainer />;
                break;
            case navigationTypes.ZONE_PRODUCTS_SCENE:
                scene = <ZoneProductSceneContainer />;
                break;
            case navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE:
                scene = <ZoneRedlikeOptionSceneContainer />;
                break;
            case navigationTypes.ZONE_REDLIKE_USERS_SCENE:
                scene = <ZoneRedlikeUserSceneContainer />;
                break;
            case navigationTypes.HOMEPAGE:
            default:
                scene = <HomePageContainer />;
                break;
        }

        return (
            <div className="App">
                <HeaderContainer />
                <NavigationContainer />
                {scene}
                {this.renderModals()}
                <NotificationPanelContainer />
            </div>
        );
    };

    renderModals = (): Array<JSX.Element> => {
        const modals: Array<JSX.Element> = [];
        const index = 99;

        this.props.modalList.forEach((modal) => {
            modals.push(
                <Modal
                    key={modal.id}
                    id={modal.id}
                    type={modal.type}
                    index={index + modal.id}
                    params={modal.params}
                    nextAction={modal.nextAction}
                    closeModal={(type: ModalType): void => this.props.closeModal(type)}
                />
            );
        });

        return modals;
    };

    render(): JSX.Element {
        const { loggedIn, licensed, serverConnected } = this.props;

        return (
            <Switch>
                <Route
                    exact
                    path={paths.PATH_WELCOME}
                    render={(): any => (!serverConnected ? <WelcomeContainer /> : this.handleRouting())}
                />
                <Route
                    exact
                    path={paths.PATH_LICENSE}
                    render={(): any => (serverConnected && !licensed ? <LicenseContainer /> : this.handleRouting())}
                />
                <Route
                    exact
                    path={paths.PATH_LOGIN}
                    render={(): any =>
                        serverConnected && licensed && !loggedIn ? <LoginContainer /> : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GLOBAL_OPTIONS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GLOBAL_OPTIONS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_USER_ACTION_HISTORY}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.USER_ACTION_HISTORY_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_USER_OPTIONS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.USER_OPTIONS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_COMPANIES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.COMPANY_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_DEBUG}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DEBUG_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_DATA_EXPORT}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DATA_EXPORT_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_DATA_IMPORT}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DATA_IMPORT_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    path={paths.PATH_DATA_MASTER_SETTINGS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DATA_MASTER_SETTINGS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    path={paths.PATH_DATA_SERVER_SCRIPTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DATA_SERVER_SCRIPTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    path={paths.PATH_DATA_SYSTEM_ZONE_SETTINGS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.DATA_SYSTEM_ZONE_SETTINGS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_BARCODES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_BARCODES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_CURRENCIES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_CURRENCIES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_IMAGES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_IMAGES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_PACKAGES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_PACKAGES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_PRODUCT_SHEETS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_PRODUCT_SHEETS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_STATIC_SQL}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_STATIC_SQL_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_GENERIC_UNITS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.GENERIC_UNITS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_BASES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_BASES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_COLORANTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_COLORANTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_COLORS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_COLORS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_FANDECKS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_FANDECKS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_FORMULAS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_FORMULAS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_FORMULA_NOTES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_FORMULA_NOTES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_PRODUCTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_PRODUCTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_REDLIKE_OPTIONS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_REDLIKE_PRIVILEGES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_REDLIKE_PRIVILEGES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_MASTER_REDLIKE_USERS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.MASTER_REDLIKE_USERS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SERVER_MONITORING}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SERVER_MONITORING_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_BASES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_BASES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_COLORANTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_COLORANTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_COLORS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_COLORS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_FANDECKS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_FANDECKS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_FORMULAS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_FORMULAS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_FORMULA_NOTES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_FORMULA_NOTES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_PRICES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_PRICES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_PRODUCT_GROUPS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_PRODUCTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_PRODUCTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_REDLIKE_OPTIONS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_SYSTEM_REDLIKE_USERS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.SYSTEM_REDLIKE_USERS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_USERS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.USERS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_BASES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_BASES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_COLORANTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_COLORANTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_COLORS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_COLORS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_FANDECKS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_FANDECKS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_FORMULAS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_FORMULAS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_FORMULA_NOTES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_FORMULA_NOTES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_PRICES}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_PRICES_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_PRODUCT_GROUPS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_PRODUCT_GROUPS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_PRODUCTS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_PRODUCTS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_REDLIKE_OPTIONS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    exact
                    path={paths.PATH_ZONE_REDLIKE_USERS}
                    render={(): any =>
                        serverConnected && licensed && loggedIn
                            ? this.returnMainContent(navigationTypes.ZONE_REDLIKE_USERS_SCENE)
                            : this.handleRouting()
                    }
                />
                <Route
                    // homepage is set directly by user name, should be handled differently when new homepage appears
                    path={paths.PATH_HOME_PAGE}
                    render={(): any => {
                        return this.handleRouting();
                    }}
                />
            </Switch>
        );
    }
}
