import { ImportDataType } from "../constants/entityConstants/importExportConstants";
import { mapImportFiles } from "./importFile";
import { mapImportOptions } from "./importOption";

export class ImportType {
    id: number;
    key: string;
    name: string;
    dataType: ImportDataType;
    module: string;
    description: string;
    files: Array<any>;
    options: Array<any>;

    constructor(
        id: number,
        key: string,
        name: string,
        dataType: ImportDataType,
        module: string,
        description: string,
        files: Array<any>,
        options: Array<any>
    ) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.dataType = dataType;
        this.module = module;
        this.description = description;
        this.files = files;
        this.options = options;
    }
}

export function mapImportType(data: Record<string, any>): ImportType | null {
    try {
        return new ImportType(
            data.data?.taskType,
            data.name,
            data.data?.name,
            data.data?.dataType,
            data.data?.module,
            data.data?.description,
            mapImportFiles(data.data?.files),
            mapImportOptions(data.data?.options)
        );
    } catch (e) {
        return null;
    }
}
