import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as methods from "../constants/serverMethods";
import * as modalConstants from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Epic, ofType } from "redux-observable";

import { Formula } from "../types/formula";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const deleteItem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.DELETE),
        switchMap(({ payload }) => {
            let deleteRequest = null;

            switch (payload.objectType) {
                // base
                case objectTypes.MASTER_BASE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_BASE,
                        methods.METHOD_DELETE_MASTER_BASE,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_BASE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_BASE,
                        methods.METHOD_DELETE_SYSTEM_BASE,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_BASE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_BASE,
                        methods.METHOD_DELETE_ZONE_BASE,
                        payload.params
                    );
                    break;
                }
                // color
                case objectTypes.MASTER_COLOR_DATA: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_COLOR_IN_FANDECK,
                        methods.METHOD_DELETE_MASTER_COLOR_IN_FANDECK,
                        payload.params
                    );
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_COLORANT,
                        methods.METHOD_DELETE_MASTER_COLORANT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_COLORANT,
                        methods.METHOD_DELETE_SYSTEM_COLORANT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_COLORANT,
                        methods.METHOD_DELETE_ZONE_COLORANT,
                        payload.params
                    );
                    break;
                }
                // colorant batch
                case objectTypes.MASTER_COLORANT_BATCH: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_COLORANT_BATCH,
                        methods.METHOD_DELETE_MASTER_COLORANT_BATCH,
                        payload.params
                    );
                    break;
                }
                // colorant package
                case objectTypes.SYSTEM_COLORANT_PACKAGE: {
                    const item = generalHelper.getObjectById(
                        state$.value.colorantPackage.systemList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                    );

                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_COLORANT_PACKAGE,
                        methods.METHOD_DELETE_SYSTEM_COLORANT_PACKAGE,
                        {
                            ...payload.params,
                            colorantPackageId: item[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                        }
                    );
                    break;
                }
                case objectTypes.ZONE_COLORANT_PACKAGE: {
                    const item = generalHelper.getObjectById(
                        state$.value.colorantPackage.zoneList,
                        payload.params[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                    );

                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_COLORANT_PACKAGE,
                        methods.METHOD_DELETE_ZONE_COLORANT_PACKAGE,
                        {
                            ...payload.params,
                            colorantPackageId: item[propertyConstants.PROPERTY_COLORANT_PACKAGE_ID]
                        }
                    );
                    break;
                }
                // company
                case objectTypes.COMPANY: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_COMPANY,
                        methods.METHOD_DELETE_COMPANY,
                        payload.params
                    );
                    break;
                }
                // currency
                case objectTypes.CURRENCY: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_CURRENCY,
                        methods.METHOD_DELETE_CURRENCY,
                        payload.params
                    );
                    break;
                }
                // database
                case objectTypes.DATABASE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_DATABASE,
                        methods.METHOD_DELETE_DATABASE,
                        payload.params
                    );
                    break;
                }
                // export task
                case objectTypes.EXPORT_TASK: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_EXPORT_TASK,
                        methods.METHOD_DELETE_EXPORT_TASK,
                        payload.params
                    );
                    break;
                }
                case objectTypes.EXPORT_TASK_DELETE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_EXPORT_TASKS,
                        methods.METHOD_DELETE_EXPORT_TASKS,
                        payload.params
                    );
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_FANDECK,
                        methods.METHOD_DELETE_MASTER_FANDECK,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_FANDECK: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_FANDECK,
                        methods.METHOD_DELETE_SYSTEM_FANDECK,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_FANDECK: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_FANDECK,
                        methods.METHOD_DELETE_ZONE_FANDECK,
                        payload.params
                    );
                    break;
                }
                // file category
                case objectTypes.FILE_CATEGORY: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_FILE_CATEGORY,
                        methods.METHOD_DELETE_FILE_CATEGORY,
                        payload.params
                    );
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    const formula =
                        state$.value.color.masterActiveList[0].formulaList.find(
                            (item: Formula) => item[propertyConstants.PROPERTY_ID] === payload.params.formulaId
                        ) || null;

                    if (formula) {
                        deleteRequest = serverRequest(
                            methods.METHOD_DELETE_MASTER_FORMULA,
                            methods.METHOD_DELETE_MASTER_FORMULA,
                            {
                                ...payload.params,
                                baseInProductId: formula[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID],
                                colorInFandeckId: formula[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]
                            }
                        );
                    }

                    break;
                }
                // formula note
                case objectTypes.MASTER_FORMULA_NOTE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_FORMULA_NOTE,
                        methods.METHOD_DELETE_MASTER_FORMULA_NOTE,
                        payload.params
                    );
                    break;
                }
                // image
                case objectTypes.GENERIC_IMAGE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_IMAGE,
                        methods.METHOD_DELETE_IMAGE,
                        payload.params
                    );
                    break;
                }
                // image category
                case objectTypes.IMAGE_CATEGORY:
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_IMAGE_CATEGORY,
                        methods.METHOD_DELETE_IMAGE_CATEGORY,
                        payload.params
                    );
                    break;
                // import task
                case objectTypes.IMPORT_TASK: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_IMPORT_TASK,
                        methods.METHOD_DELETE_IMPORT_TASK,
                        payload.params
                    );
                    break;
                }
                case objectTypes.IMPORT_TASK_DELETE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_IMPORT_TASKS,
                        methods.METHOD_DELETE_IMPORT_TASKS,
                        payload.params
                    );
                    break;
                }
                // license
                case objectTypes.LICENSE: {
                    deleteRequest = serverRequest(methods.METHOD_DELETE_LICENSE, methods.METHOD_DELETE_LICENSE);
                    break;
                }
                // package
                case objectTypes.PACKAGE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_PACKAGE,
                        methods.METHOD_DELETE_PACKAGE,
                        payload.params
                    );
                    break;
                }
                // price
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_PRICE_MARGIN,
                        methods.METHOD_DELETE_SYSTEM_PRICE_MARGIN,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_PRICE_MARGIN,
                        methods.METHOD_DELETE_ZONE_PRICE_MARGIN,
                        payload.params
                    );
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_PRODUCT,
                        methods.METHOD_DELETE_MASTER_PRODUCT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_PRODUCT,
                        methods.METHOD_DELETE_SYSTEM_PRODUCT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_PRODUCT,
                        methods.METHOD_DELETE_ZONE_PRODUCT,
                        payload.params
                    );
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_BASE_IN_PRODUCT,
                        methods.METHOD_DELETE_MASTER_BASE_IN_PRODUCT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT,
                        methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT,
                        methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT,
                        payload.params
                    );
                    break;
                }
                // product base package
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    // kontrola jeste item !== null
                    const item = generalHelper.getObjectById(
                        state$.value.baseInProductPackage.systemList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                    );

                    if (item) {
                        deleteRequest = serverRequest(
                            methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                            methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
                            {
                                ...payload.params,
                                baseInProductPackageId: item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                            }
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    const item = generalHelper.getObjectById(
                        state$.value.baseInProductPackage.zoneList,
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                    );

                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT_PACKAGE,
                        methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT_PACKAGE,
                        {
                            ...payload.params,
                            baseInProductPackageId: item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID]
                        }
                    );
                    break;
                }
                // product group
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_PRODUCT_GROUP,
                        methods.METHOD_DELETE_SYSTEM_PRODUCT_GROUP,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_PRODUCT_GROUP,
                        methods.METHOD_DELETE_ZONE_PRODUCT_GROUP,
                        payload.params
                    );
                    break;
                }
                // product sheet
                case objectTypes.PRODUCT_FILE: {
                    break;
                }
                case objectTypes.PRODUCT_SHEET: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_FILE,
                        methods.METHOD_DELETE_PRODUCT_SHEET,
                        payload.params
                    );
                    break;
                }
                // spectro
                case objectTypes.SPECTRO_TYPE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SPECTRO_TYPE,
                        methods.METHOD_DELETE_SPECTRO_TYPE,
                        payload.params
                    );
                    break;
                }
                // static sql
                case objectTypes.STATIC_SQL: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_FILE,
                        methods.METHOD_DELETE_STATIC_SQL,
                        payload.params
                    );
                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM,
                        methods.METHOD_DELETE_SYSTEM,
                        payload.params
                    );
                    break;
                }
                // unit
                case objectTypes.UNIT: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_UNIT,
                        methods.METHOD_DELETE_UNIT,
                        payload.params
                    );
                    break;
                }
                // user
                case objectTypes.MASTER_REDLIKE_USER: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_MASTER_REDLIKE_USER,
                        methods.METHOD_DELETE_MASTER_REDLIKE_USER,
                        payload.params
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_SYSTEM_REDLIKE_USER,
                        methods.METHOD_DELETE_SYSTEM_REDLIKE_USER,
                        payload.params
                    );
                    break;
                }
                case objectTypes.USER: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_USER,
                        methods.METHOD_DELETE_USER,
                        payload.params
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE_REDLIKE_USER,
                        methods.METHOD_DELETE_ZONE_REDLIKE_USER,
                        payload.params
                    );
                    break;
                }
                case objectTypes.USER_GROUP: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_USER_GROUP,
                        methods.METHOD_DELETE_USER_GROUP,
                        payload.params
                    );
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_REDLIKE_USER_GROUP,
                        methods.METHOD_DELETE_REDLIKE_USER_GROUP,
                        payload.params
                    );
                    break;
                }
                // zone
                case objectTypes.ZONE: {
                    deleteRequest = serverRequest(
                        methods.METHOD_DELETE_ZONE,
                        methods.METHOD_DELETE_ZONE,
                        payload.params
                    );
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            if (payload.params.deleteConfirmation) {
                if (objectTypes.DELETE_WARNING_OBJECT_TYPES.includes(payload.objectType)) {
                    return [
                        navigationActions.navigationOpenModal(
                            modalConstants.MODAL_ITEM_DELETE,
                            payload.params,
                            navigationActions.navigationOpenModal(
                                modalConstants.MODAL_ITEM_DELETE_FINAL,
                                payload.params,
                                deleteRequest
                            )
                        )
                    ];
                }

                return [
                    navigationActions.navigationOpenModal(
                        modalConstants.MODAL_ITEM_DELETE,
                        payload.params,
                        deleteRequest
                    )
                ];
            }

            if (objectTypes.DELETE_WARNING_OBJECT_TYPES.includes(payload.objectType)) {
                return [
                    navigationActions.navigationOpenModal(
                        modalConstants.MODAL_ITEM_DELETE_FINAL,
                        payload.params,
                        deleteRequest
                    )
                ];
            }

            if (deleteRequest) {
                return [deleteRequest];
            }

            return [];
        })
    );
