import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Company } from "../../../types/company";
import { DataItem } from "../../../types/dataItem";
import { ServerRequest } from "../../../types/serverRequest";
import { User } from "../../../types/user";
import { UserGroup } from "../../../types/userGroup";
import { UserScene } from "../../../components/scenes/header/UserScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    dataPrivileges: Array<DataItem>;
    loggedUser: User | null;
    companyList: Array<Company>;
    userGroupPrivilegeTableConstant: string;
    userTableConstant: string;
    userList: Array<User>;
    userActiveList: Array<User>;
    userDetailLoading: boolean;
    userLoading: boolean;
    userColumnOrder: Array<propertyConstants.Property>;
    userColumnVisibility: Record<propertyConstants.Property, boolean>;
    userColumnWidth: Record<propertyConstants.Property, number>;
    userOffset: number;
    userPage: number;
    userRowCount: number;
    userRowCountCustom: boolean;
    userSearch: string | null;
    userSearchParams: Record<propertyConstants.Property, string>;
    userShowFilterRow: boolean;
    userShowGlobalSearch: boolean;
    userSortingAsc: boolean;
    userSortingCriterion: propertyConstants.Property | null;
    userTotalCount: number | null;
    userGroupTableConstant: string;
    userGroupList: Array<UserGroup>;
    userGroupAllList: Array<UserGroup>;
    userGroupActiveList: Array<UserGroup>;
    userGroupLoading: boolean;
    userGroupColumnOrder: Array<propertyConstants.Property>;
    userGroupColumnVisibility: Record<propertyConstants.Property, boolean>;
    userGroupColumnWidth: Record<propertyConstants.Property, number>;
    userGroupOffset: number;
    userGroupPage: number;
    userGroupRowCount: number;
    userGroupRowCountCustom: boolean;
    userGroupSearch: string | null;
    userGroupSearchParams: Record<propertyConstants.Property, string>;
    userGroupShowFilterRow: boolean;
    userGroupShowGlobalSearch: boolean;
    userGroupSortingAsc: boolean;
    userGroupSortingCriterion: propertyConstants.Property;
    userGroupTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    dataPrivileges: state.login.dataPrivileges,
    loggedUser: state.login.loggedUser,
    companyList: state.company.allList,
    userGroupPrivilegeTableConstant: menuConstants.TABLE_USER_GROUP_PRIVILEGES,
    userTableConstant: menuConstants.TABLE_USERS,
    userList: state.user.list,
    userActiveList: state.user.activeList,
    userDetailLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_EDIT_USER || request.method === methods.METHOD_EDIT_USER_WITH_USER_GROUPS
    ),
    userLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_USERS_WITH_GROUPS || request.method === methods.METHOD_DELETE_USER
    ),

    userColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_USERS
    ),
    userColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_USERS
    ),
    userColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_USERS
    ),
    userOffset: state.user.offset,
    userPage: state.user.page,
    userRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_USERS
    ),
    userRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_USERS
    ),
    userSearch: state.user.search,
    userSearchParams: state.user.searchParams,
    userShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_USERS
    ),
    userShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_USERS
    ),
    userSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_USERS
        )
    ),
    userSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_USERS
        )
    ),
    userTotalCount: state.user.count,
    userGroupTableConstant: menuConstants.TABLE_USER_GROUPS,
    userGroupList: state.userGroup.list,
    userGroupAllList: state.userGroup.allList,
    userGroupActiveList: state.userGroup.activeList,
    userGroupLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_USER_GROUPS
    ),
    userGroupColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupOffset: state.userGroup.offset,
    userGroupPage: state.userGroup.page,
    userGroupRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupSearch: state.userGroup.search,
    userGroupSearchParams: state.userGroup.searchParams,
    userGroupShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_USER_GROUPS
    ),
    userGroupSortingAsc: state.userGroup.sortingAsc,
    userGroupSortingCriterion: state.userGroup.sortingCriterion,
    userGroupTotalCount: state.userGroup.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_USERS
    )
});

export const UserSceneContainer = connect(mapStateToProps)(UserScene);
