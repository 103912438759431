import * as modalTypes from "../../../../constants/modalTypes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypes from "../../../../constants/objectTypes";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ModalType } from "../../../../constants/modalTypes";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    modalType?: ModalType;
    modalParams: any;
};

type Props = OwnProps & ContainerProps;

export class TableMetaDataButton extends Component<Props> {
    handleOpenAddModal = (): void => {
        const { modalType, modalParams, openModal } = this.props;

        // TODO remove modalType as it cannot be undefined
        if (modalType) {
            openModal(modalType, modalParams);
        }
    };

    render(): JSX.Element | null {
        const { modalType } = this.props;
        if (modalType === modalTypes.MODAL_ZONES_METADATA) {
            return (
                <button className="btn-md btn-info" onClick={(): void => this.handleOpenAddModal()}>
                    {translate("general.metaData")}
                </button>
            );
        }
        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TableMetaDataButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableMetaDataButton);
