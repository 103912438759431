import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as navigationActions from "../actions/navigationActions";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Epic, ofType } from "redux-observable";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const getItemDetail: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.GET_DETAIL),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // color
                case objectTypes.MASTER_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_COLOR_IN_FANDECK,
                                methods.METHOD_GET_MASTER_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                // colorant
                case objectTypes.SYSTEM_COLORANT: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_COLORANT_BY_ID,
                            methods.METHOD_GET_SYSTEM_COLORANT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_COLORANT_BY_ID,
                            methods.METHOD_GET_ZONE_COLORANT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                // colorant package
                case objectTypes.SYSTEM_COLORANT_PACKAGE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_COLORANT_PACKAGE_BY_ID,
                            methods.METHOD_GET_SYSTEM_COLORANT_PACKAGE_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_COLORANT_PACKAGE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID,
                            methods.METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_MASTER_FANDECK_BY_ID,
                                methods.METHOD_GET_MASTER_FANDECK_BY_ID,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.MASTER_FANDECK: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_MASTER_FANDECK_BY_ID,
                            methods.METHOD_GET_MASTER_FANDECK_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_FANDECK: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_FANDECK_BY_ID,
                            methods.METHOD_GET_SYSTEM_FANDECK_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_FANDECK: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_FANDECK_BY_ID,
                            methods.METHOD_GET_ZONE_FANDECK_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] &&
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FORMULA_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(methods.METHOD_GET_FORMULA, methods.METHOD_GET_MASTER_FORMULA, payload.params)
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_COLOR_IN_FANDECK,
                                methods.METHOD_GET_SYSTEM_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_FANDECK_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_SYSTEM_FANDECK,
                                methods.METHOD_GET_SYSTEM_FANDECK,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] &&
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FORMULA_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(methods.METHOD_GET_FORMULA, methods.METHOD_GET_SYSTEM_FORMULA, payload.params)
                        );
                    }

                    break;
                }
                case objectTypes.ZONE_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_COLOR_IN_FANDECK,
                                methods.METHOD_GET_ZONE_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.ZONE_FANDECK_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ZONE_FANDECK,
                                methods.METHOD_GET_ZONE_FANDECK,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.ZONE_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] &&
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_FORMULA_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(methods.METHOD_GET_FORMULA, methods.METHOD_GET_ZONE_FORMULA, payload.params)
                        );
                    }

                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT:
                case objectTypes.MASTER_PRODUCT_DUPLICATE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_MASTER_PRODUCT_BY_ID,
                            methods.METHOD_GET_MASTER_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_PRODUCT_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_MASTER_PRODUCT_BY_ID,
                                methods.METHOD_GET_MASTER_PRODUCT_BY_ID,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_PRODUCT:
                case objectTypes.SYSTEM_PRODUCT_DUPLICATE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID,
                            methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_SYSTEM_PRODUCT_BY_ID,
                                methods.METHOD_GET_SYSTEM_PRODUCT,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.ZONE_PRODUCT:
                case objectTypes.ZONE_PRODUCT_DUPLICATE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_PRODUCT_BY_ID,
                            methods.METHOD_GET_ZONE_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PRODUCT_IN_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ZONE_PRODUCT_BY_ID,
                                methods.METHOD_GET_ZONE_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID,
                            methods.METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID,
                            methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_BY_ID,
                            methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                // product base package
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID,
                            methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.modal) {
                        requests.push(navigationActions.setNextModal(payload.modal));
                    }

                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_PACKAGE_BY_ID,
                            methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_PACKAGE_BY_ID,
                            payload.params
                        )
                    );
                    break;
                }
                default: {
                    requests.push(notificationActions.saveClientFlashMessage());

                    break;
                }
            }

            return requests;
        })
    );
