import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as methods from "../constants/serverMethods";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { Modal } from "../types/modal";
import { ServerRequest } from "./websocketActions";

export interface GetAll {
    type: typeof actionConstants.GET_ALL;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

// TODO refactor params
export function getAllItems(
    objectType: objectTypes.ObjectType,
    data: {
        actionTypeId?: number;
        addBarcodes?: boolean;
        addFiles?: boolean;
        addPrices?: boolean;
        addProductGroups?: boolean;
        asc?: boolean;
        changedOnly?: boolean;
        dateFrom?: string | null;
        dateTo?: string | null;
        offset?: number;
        orderBy?: string;
        rowCount?: number;
        search?: string;
        searchByColumns?: any;
        showDeleted?: boolean;
        showHidden?: boolean;
        simpleData?: boolean;
        baseId?: number;
        baseInProductId?: number;
        colorInFandeckId?: number;
        colorantId?: number;
        companyId?: number;
        fandeckId?: number;
        fileTypeId?: number;
        fileTypeIds?: Array<number>;
        itemTypeId?: number;
        productGroupId?: number;
        productId?: number;
        statusId?: number;
        systemId?: number;
        userId?: number;
        userGroupId?: number;
        zoneId?: number;
    } = {}
): any {
    return (dispatch: any, getState: any): GetAll | void => {
        if (
            getState().login.loggedIn &&
            !getState().server.requests.some((request: ServerRequest) => request.method === methods.METHOD_LOGOUT)
        ) {
            dispatch({
                type: actionConstants.GET_ALL,
                payload: {
                    objectType,
                    params: data
                }
            });
        }
    };
}

export interface ListSave {
    type: typeof actionConstants.LIST_SAVE;
    payload: {
        objectType: objectTypes.ObjectType;
        list: Array<any>;
        totalCount: number | null;
        availableCount: number | null;
    };
}

export function saveItems(
    objectType: objectTypes.ObjectType,
    list: Array<any>,
    totalCount: number | null = null,
    availableCount: number | null = null
): ListSave {
    return {
        type: actionConstants.LIST_SAVE,
        payload: {
            objectType,
            list,
            totalCount,
            availableCount
        }
    };
}

export interface ListSaveNoParams {
    type: typeof actionConstants.LIST_SAVE_NO_PARAMS;
    payload: {
        objectType: objectTypes.ObjectType;
        list: Array<any>;
    };
}

export function saveItemsNoParams(objectType: objectTypes.ObjectType, list: Array<any>): ListSaveNoParams {
    return {
        type: actionConstants.LIST_SAVE_NO_PARAMS,
        payload: {
            objectType,
            list
        }
    };
}

export interface GetDetail {
    type: typeof actionConstants.GET_DETAIL;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
        modal?: Modal | null;
    };
}

export function getItemDetail(
    objectType: objectTypes.ObjectType,
    params: Record<string, any>,
    modal?: Modal | null
): GetDetail {
    return {
        type: actionConstants.GET_DETAIL,
        payload: {
            objectType,
            params,
            modal
        }
    };
}

export interface Add {
    type: typeof actionConstants.ADD;
    payload: {
        objectType: objectTypes.ObjectType;
        params: any;
    };
}

export function addItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Add {
    return {
        type: actionConstants.ADD,
        payload: {
            objectType,
            params
        }
    };
}

export interface Edit {
    type: typeof actionConstants.EDIT;
    payload: {
        objectType: objectTypes.ObjectType;
        isModal: boolean;
        params: Record<string, any>;
    };
}

export function editItem(objectType: objectTypes.ObjectType, isModal: boolean, params: Record<string, any>): Edit {
    return {
        type: actionConstants.EDIT,
        payload: {
            objectType,
            isModal,
            params
        }
    };
}

export interface Delete {
    type: typeof actionConstants.DELETE;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function deleteItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Delete {
    return {
        type: actionConstants.DELETE,
        payload: {
            objectType,
            params
        }
    };
}

export interface SetActive {
    type: typeof actionConstants.SET_ACTIVE;
    payload: {
        objectType: objectTypes.ObjectType;
        items: Array<any>;
    };
}

export function setActiveItems(objectType: objectTypes.ObjectType, items: Array<any>): SetActive {
    return {
        type: actionConstants.SET_ACTIVE,
        payload: {
            objectType,
            items
        }
    };
}

export interface UpdateActive {
    type: typeof actionConstants.UPDATE_ACTIVE;
    payload: {
        objectType: objectTypes.ObjectType;
        items: Array<any>;
    };
}

export function updateActiveItems(objectType: objectTypes.ObjectType, items: Array<any>): UpdateActive {
    return {
        type: actionConstants.UPDATE_ACTIVE,
        payload: {
            objectType,
            items
        }
    };
}

export interface ReloadData {
    type: typeof actionConstants.RELOAD_DATA;
    payload: {
        objectType: objectTypes.ObjectType;
    };
}

export function reloadData(objectType: objectTypes.ObjectType): ReloadData {
    return {
        type: actionConstants.RELOAD_DATA,
        payload: {
            objectType
        }
    };
}

export interface SetSorting {
    type: typeof actionConstants.SET_SORTING;
    payload: {
        objectType: objectTypes.ObjectType;
        asc: boolean;
        criterion: string;
        isModal: boolean;
        itemId?: number;
    };
}

export function setSorting(
    objectType: objectTypes.ObjectType,
    asc: boolean,
    criterion: string,
    isModal: boolean,
    itemId?: number
): SetSorting {
    return {
        type: actionConstants.SET_SORTING,
        payload: {
            objectType,
            asc,
            criterion,
            isModal,
            itemId
        }
    };
}

export interface SetSearch {
    type: typeof actionConstants.SET_SEARCH;
    payload: {
        objectType: objectTypes.ObjectType;
        search: string | null;
        searchColumn?: string;
    };
}

export function setSearch(objectType: objectTypes.ObjectType, search: string | null, column?: string): SetSearch {
    return {
        type: actionConstants.SET_SEARCH,
        payload: {
            objectType,
            search,
            searchColumn: column
        }
    };
}

export interface SetOffset {
    type: typeof actionConstants.SET_OFFSET;
    payload: {
        objectType: objectTypes.ObjectType;
        offset: number;
        page: number;
    };
}

export function setOffset(objectType: objectTypes.ObjectType, offset: number, page: number): SetOffset {
    return {
        type: actionConstants.SET_OFFSET,
        payload: {
            objectType,
            offset,
            page
        }
    };
}

export interface SetShowSpecificValues {
    type: typeof actionConstants.SET_SHOW_SPECIFIC_VALUES;
    payload: {
        objectType: objectTypes.ObjectType;
        showSpecificValues: boolean;
    };
}

export function setShowSpecificValues(
    objectType: objectTypes.ObjectType,
    showSpecificValues: boolean
): SetShowSpecificValues {
    return {
        type: actionConstants.SET_SHOW_SPECIFIC_VALUES,
        payload: {
            objectType,
            showSpecificValues
        }
    };
}

export interface Duplicate {
    type: typeof actionConstants.DUPLICATE;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function duplicateItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Duplicate {
    return {
        type: actionConstants.DUPLICATE,
        payload: {
            objectType,
            params
        }
    };
}

export interface DataSave {
    type: typeof actionConstants.DATA_COMMIT;
}

export function dataSave(): DataSave {
    return {
        type: actionConstants.DATA_COMMIT
    };
}

export interface DataRollback {
    type: typeof actionConstants.DATA_ROLLBACK;
}

export function dataRollback(): DataRollback {
    return {
        type: actionConstants.DATA_ROLLBACK
    };
}

export interface DataChanged {
    type: typeof actionConstants.DATA_CHANGED;
    payload: {
        dataChanged: boolean;
    };
}

export function dataChanged(dataChanged: boolean): any {
    return (dispatch: any, getState: any): DataChanged => {
        const optionDatabaseAutoSave = generalHelper.getObjectByKey(
            getState().login.options,
            optionsConstants.OPTION_DATABASE_AUTOSAVE
        );

        return {
            type: actionConstants.DATA_CHANGED,
            payload: {
                dataChanged: dataChanged && optionDatabaseAutoSave?.[propertyConstants.PROPERTY_VALUE]
            }
        };
    };
}

export interface ResetValue {
    type: typeof actionConstants.RESET_VALUE;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function resetValue(objectType: objectTypes.ObjectType, params: Record<string, any>): ResetValue {
    return {
        type: actionConstants.RESET_VALUE,
        payload: {
            objectType,
            params
        }
    };
}

export interface ResetAllValues {
    type: typeof actionConstants.RESET_ALL_VALUES;
    payload: {
        objectType: objectTypes.ObjectType;
    };
}

export function resetAllValues(objectType: objectTypes.ObjectType): ResetAllValues {
    return {
        type: actionConstants.RESET_ALL_VALUES,
        payload: {
            objectType
        }
    };
}

export interface Jump {
    type: typeof actionConstants.JUMP;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function jumpToItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Jump {
    return {
        type: actionConstants.JUMP,
        payload: {
            objectType,
            params
        }
    };
}

export interface Hide {
    type: typeof actionConstants.HIDE;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function hideItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Hide {
    return {
        type: actionConstants.HIDE,
        payload: {
            objectType,
            params
        }
    };
}

export interface Show {
    type: typeof actionConstants.SHOW;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function showItem(objectType: objectTypes.ObjectType, params: Record<string, any>): Show {
    return {
        type: actionConstants.SHOW,
        payload: {
            objectType,
            params
        }
    };
}

export interface CheckAllItems {
    type: typeof actionConstants.CHECK_ALL;
    payload: {
        objectType: objectTypes.ObjectType;
        checkAll: boolean;
    };
}

export function checkAllItems(objectType: objectTypes.ObjectType, checkAll: boolean): CheckAllItems {
    return {
        type: actionConstants.CHECK_ALL,
        payload: {
            objectType,
            checkAll
        }
    };
}

export interface SetItemPosition {
    type: typeof actionConstants.SET_POSITION;
    payload: {
        objectType: objectTypes.ObjectType;
        params: Record<string, any>;
    };
}

export function setItemPosition(objectType: objectTypes.ObjectType, params: Record<string, any>): SetItemPosition {
    return {
        type: actionConstants.SET_POSITION,
        payload: {
            objectType,
            params
        }
    };
}

export interface EditItemSuccess {
    type: typeof actionConstants.EDIT_SUCCESS;
    payload: {
        objectType: objectTypes.ObjectType;
        items: Array<any>;
    };
}

export function editItemSuccess(objectType: objectTypes.ObjectType, items: Array<any>): EditItemSuccess {
    return {
        type: actionConstants.EDIT_SUCCESS,
        payload: {
            objectType,
            items
        }
    };
}

export interface UpdateItem {
    type: typeof actionConstants.UPDATE_ITEM;
    payload: {
        objectType: objectTypes.ObjectType;
        list: Array<any>;
    };
}

export function updateItem(objectType: objectTypes.ObjectType, list: Array<any>): UpdateItem {
    return {
        type: actionConstants.UPDATE_ITEM,
        payload: {
            objectType,
            list
        }
    };
}

export interface AddIndexData {
    type: typeof actionConstants.ADD_INDEX_DATA;
    payload: {
        objectType: objectTypes.ObjectType;
        params: any;
    };
}

export function addIndexData(objectType: objectTypes.ObjectType, params: Record<string, any>): AddIndexData {
    return {
        type: actionConstants.ADD_INDEX_DATA,
        payload: {
            objectType,
            params
        }
    };
}

export interface SetDate {
    type: typeof actionConstants.SET_DATE;
    payload: {
        objectType: objectTypes.ObjectType;
        property: propertyConstants.Property;
        date: Date | null;
    };
}

export function setDate(
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property,
    date: Date | null
): SetDate {
    return {
        type: actionConstants.SET_DATE,
        payload: {
            objectType,
            property,
            date
        }
    };
}
