import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";

import { AppState } from "../../../../reducers";
import DatePicker from "react-date-picker";
import { Dispatch } from "redux";
import { PropsType } from "../buttons/TableJumpButton";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    privileges: any;
    editable: boolean;
    dateFrom: Date | null;
    dateTo: Date | null;
};

type Props = OwnProps & DispatchType;

export class TableDateFilter extends Component<Props> {
    isVisible = (): boolean => {
        const { dataPrivileges, objectType } = this.props;

        if (objectTypes.SHOW_DATE_PICKER_OBJECT_TYPES.includes(objectType) && dataPrivileges?.canUpdate) {
            return true;
        }

        return false;
    };

    getClassname = (): string => {
        let className = "date-input margin-left";

        if (!this.props.editable) {
            className += " disabled";
        }

        return className;
    };

    handleDateChange = (value: Date | Array<Date> | null, property: propertyConstants.Property): void => {
        if (!Array.isArray(value)) {
            this.props.setDateFilter(this.props.objectType, property, value);
        }
    };

    render(): JSX.Element | null {
        const { dateFrom, dateTo } = this.props;

        if (this.isVisible()) {
            return (
                <div className={this.getClassname()}>
                    <span>{translate("general.dateFilter")}:</span>
                    <DatePicker
                        disabled={!this.props.editable}
                        format={"y/MM/dd"}
                        maxDate={new Date()}
                        value={dateFrom ? new Date(dateFrom) : null}
                        onChange={(value: Date | Array<Date> | null) =>
                            this.handleDateChange(value, propertyConstants.PROPERTY_DATE_FROM)
                        }
                    />
                    -
                    <DatePicker
                        disabled={!this.props.editable}
                        format={"y/MM/dd"}
                        maxDate={new Date()}
                        value={dateTo ? new Date(dateTo) : null}
                        onChange={(value: Date | Array<Date> | null) =>
                            this.handleDateChange(value, propertyConstants.PROPERTY_DATE_TO)
                        }
                    />
                </div>
            );
        }

        return null;
    }
}

export type DispatchType = Readonly<{
    setDateFilter(objectType: objectTypes.ObjectType, property: propertyConstants.Property, date: Date | null): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setDateFilter: (objectType: objectTypes.ObjectType, property: propertyConstants.Property, date: Date | null): any =>
        dispatch(universalObjectActions.setDate(objectType, property, date))
});

export const TableDateFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TableDateFilter);
