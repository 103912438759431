import * as modalTypes from "../constants/modalTypes";

export class Modal {
    id: number;
    type: modalTypes.ModalType;
    params: any;
    nextAction: any;

    constructor(id: number, type: modalTypes.ModalType, params: any, nextAction: any) {
        this.id = id;
        this.type = type;
        this.params = params;
        this.nextAction = nextAction;
    }
}
