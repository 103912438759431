import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Epic, ofType } from "redux-observable";

import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const getAllItems: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.GET_ALL),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            if (
                !objectTypeHelper.isObjectTypeEnabledByHeader(
                    payload.objectType,
                    state$.value.database.active ?? null,
                    state$.value.system.active ?? null,
                    state$.value.zone.active ?? null
                )
            ) {
                return [];
            }

            switch (payload.objectType) {
                // barcode
                case objectTypes.BARCODE: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_ALL_BARCODES, methods.METHOD_GET_ALL_BARCODES, payload.params)
                    );
                    break;
                }
                // base
                case objectTypes.MASTER_BASE: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES,
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_BASES,
                                methods.METHOD_GET_ALL_MASTER_BASES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM,
                            methods.METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE: {
                    break;
                }
                case objectTypes.SYSTEM_BASE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_BASES,
                            methods.METHOD_GET_ALL_SYSTEM_BASES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE,
                            methods.METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE: {
                    break;
                }
                case objectTypes.ZONE_BASE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_BASES,
                            methods.METHOD_GET_ALL_ZONE_BASES,
                            payload.params
                        )
                    );
                    break;
                }
                // color
                case objectTypes.MASTER_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_BY_FANDECK,
                                methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_BY_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }

                case objectTypes.SYSTEM_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_BY_FANDECK,
                                methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_BY_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }

                case objectTypes.ZONE_COLOR: {
                    if (payload.params[propertyConstants.PROPERTY_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_BY_FANDECK,
                                methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_BY_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }

                case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
                    if (
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO: {
                    if (
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_COLOR_WITH_FORMULA_INFO: {
                    if (
                        payload.params[propertyConstants.PROPERTY_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO,
                                payload.params
                            )
                        );
                    }
                    break;
                }

                case objectTypes.MASTER_COLOR_DATA: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK,
                                methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_COLOR_DATA: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK,
                                methods.METHOD_GET_DATA_FOR_SYSTEM_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_COLOR_DATA: {
                    if (payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK,
                                methods.METHOD_GET_DATA_FOR_ZONE_COLOR_IN_FANDECK,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_COLORANTS,
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_COLORANTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_COLORANTS,
                                methods.METHOD_GET_ALL_MASTER_COLORANTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM,
                            methods.METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    break;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_COLORANTS,
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_COLORANTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_COLORANTS,
                                methods.METHOD_GET_ALL_SYSTEM_COLORANTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE,
                            methods.METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    break;
                }
                case objectTypes.ZONE_COLORANT: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_COLORANTS,
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_COLORANTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_COLORANTS,
                                methods.METHOD_GET_ALL_ZONE_COLORANTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // colorant batch
                case objectTypes.MASTER_COLORANT_BATCH: {
                    if (payload.params[propertyConstants.PROPERTY_COLORANT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT,
                                methods.METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // colorant package
                case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_COLORANT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM,
                                methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE: {
                    break;
                }
                case objectTypes.SYSTEM_COLORANT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_COLORANT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT,
                                methods.METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_COLORANT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE,
                                methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE: {
                    break;
                }
                case objectTypes.ZONE_COLORANT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_COLORANT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT,
                                methods.METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // company
                case objectTypes.COMPANY: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_COMPANIES,
                                methods.METHOD_GET_ALL_COMPANIES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_COMPANIES,
                                methods.METHOD_GET_ALL_COMPANIES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // currency
                case objectTypes.CURRENCY: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_CURRENCIES,
                                methods.METHOD_GET_ALL_CURRENCIES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_CURRENCIES,
                                methods.METHOD_GET_ALL_CURRENCIES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_CURRENCY: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ACTIVE_SYSTEM_CURRENCY,
                            methods.METHOD_GET_ACTIVE_SYSTEM_CURRENCY,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_CURRENCY: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ACTIVE_ZONE_CURRENCY,
                            methods.METHOD_GET_ACTIVE_ZONE_CURRENCY,
                            payload.params
                        )
                    );
                    break;
                }
                // data size
                case objectTypes.DATA_SIZE: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_DATA_SIZE, methods.METHOD_GET_DATA_SIZE, payload.params)
                    );
                    break;
                }
                // database
                case objectTypes.DATABASE: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_DATABASES,
                                methods.METHOD_GET_ALL_DATABASES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_DATABASES,
                                methods.METHOD_GET_ALL_DATABASES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.DATABASE_MONITORING: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_DATABASES,
                            methods.METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.DATABASE_SYSTEM_ZONE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES,
                            methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES,
                            payload.params
                        )
                    );
                    break;
                }
                // export
                case objectTypes.EXPORT_STATUS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_EXPORT_TASK_STATUSES,
                            methods.METHOD_GET_ALL_EXPORT_TASK_STATUSES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.EXPORT_TASK: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_EXPORT_TASKS,
                            methods.METHOD_GET_ALL_EXPORT_TASKS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.EXPORT_TYPE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_EXPORT_TASK_TYPES,
                            methods.METHOD_GET_ALL_EXPORT_TASK_TYPES,
                            payload.params
                        )
                    );
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK:
                case objectTypes.MASTER_FANDECK_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_FANDECKS,
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_FANDECKS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_FANDECKS,
                                methods.METHOD_GET_ALL_MASTER_FANDECKS,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.MASTER_SYSTEM_FANDECK: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM,
                            methods.METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_MASTER_FANDECK: {
                    break;
                }
                case objectTypes.SYSTEM_FANDECK:
                case objectTypes.SYSTEM_FANDECK_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_FANDECKS,
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_FANDECKS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_FANDECKS,
                                methods.METHOD_GET_ALL_SYSTEM_FANDECKS,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                case objectTypes.SYSTEM_ZONE_FANDECK: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE,
                            methods.METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_FANDECK: {
                    break;
                }
                case objectTypes.ZONE_FANDECK:
                case objectTypes.ZONE_FANDECK_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_FANDECKS,
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_FANDECKS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_FANDECKS,
                                methods.METHOD_GET_ALL_ZONE_FANDECKS,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                // file
                case objectTypes.FILE: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_ALL_FILES, methods.METHOD_GET_ALL_FILES, payload.params)
                    );
                    break;
                }
                // file category
                case objectTypes.FILE_CATEGORY: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_FILE_CATEGORIES,
                                methods.METHOD_GET_ALL_FILE_CATEGORIES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_FILE_CATEGORIES,
                                methods.METHOD_GET_ALL_FILE_CATEGORIES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_MAIN_FORMULAS_FOR_COMBINATION,
                                methods.METHOD_GET_MAIN_FORMULAS_FOR_COMBINATION,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_SYSTEM_MAIN_FORMULAS_FOR_COMBINATION,
                                methods.METHOD_GET_SYSTEM_MAIN_FORMULAS_FOR_COMBINATION,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_FORMULA: {
                    if (
                        payload.params[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] &&
                        payload.params[propertyConstants.PROPERTY_PRODUCT_ID]
                    ) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION,
                                methods.METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // formula colorant
                case objectTypes.MASTER_FORMULA_COLORANT:
                case objectTypes.SYSTEM_FORMULA_COLORANT:
                case objectTypes.ZONE_FORMULA_COLORANT: {
                    break;
                }
                // formula unit
                case objectTypes.FORMULA_COLORANT_UNIT: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_FORMULA_UNITS,
                            methods.METHOD_GET_ALL_FORMULA_UNITS,
                            payload.params
                        )
                    );
                    break;
                }
                // formula note
                case objectTypes.MASTER_FORMULA_NOTE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_FORMULA_NOTES,
                            methods.METHOD_GET_ALL_MASTER_FORMULA_NOTES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_FORMULA_NOTE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_FORMULA_NOTES,
                            methods.METHOD_GET_ALL_SYSTEM_FORMULA_NOTES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_FORMULA_NOTE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_FORMULA_NOTES,
                            methods.METHOD_GET_ALL_ZONE_FORMULA_NOTES,
                            payload.params
                        )
                    );
                    break;
                }
                // image
                case objectTypes.ICON:
                case objectTypes.GENERIC_IMAGE:
                case objectTypes.IMAGE: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_ALL_IMAGES, methods.METHOD_GET_ALL_IMAGES, payload.params)
                    );
                    break;
                }
                // image category
                case objectTypes.IMAGE_CATEGORY: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_IMAGE_CATEGORIES,
                                methods.METHOD_GET_ALL_IMAGE_CATEGORIES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_IMAGE_CATEGORIES,
                                methods.METHOD_GET_ALL_IMAGE_CATEGORIES,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // import
                case objectTypes.IMPORT_STATUS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_IMPORT_TASK_STATUSES,
                            methods.METHOD_GET_ALL_IMPORT_TASK_STATUSES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.IMPORT_TASK: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_IMPORT_TASKS,
                            methods.METHOD_GET_ALL_IMPORT_TASKS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.IMPORT_TYPE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_IMPORT_TASK_TYPES,
                            methods.METHOD_GET_ALL_IMPORT_TASK_TYPES,
                            payload.params
                        )
                    );
                    break;
                }
                // option
                case objectTypes.GLOBAL_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_GLOBAL_OPTIONS,
                            methods.METHOD_GET_ALL_GLOBAL_OPTIONS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_REDLIKE_OPTION: {
                    if (Object.keys(payload.params).length === 0) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_REDLIKE_OPTIONS,
                                methods.METHOD_GET_ALL_MASTER_REDLIKE_OPTIONS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_OPTION: {
                    if (Object.keys(payload.params).length === 0) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS,
                                methods.METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.USER_OPTION: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_USER_OPTIONS,
                            methods.METHOD_GET_ALL_USER_OPTIONS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_OPTION: {
                    if (Object.keys(payload.params).length === 0) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_REDLIKE_OPTIONS,
                                methods.METHOD_GET_ALL_ZONE_REDLIKE_OPTIONS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // package
                case objectTypes.PACKAGE: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_ALL_PACKAGES, methods.METHOD_GET_ALL_PACKAGES, payload.params)
                    );
                    break;
                }
                case objectTypes.SYSTEM_PACKAGE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PACKAGES,
                            methods.METHOD_GET_ALL_SYSTEM_PACKAGES,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PACKAGE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_PACKAGES,
                            methods.METHOD_GET_ALL_ZONE_PACKAGES,
                            payload.params
                        )
                    );
                    break;
                }
                // price
                case objectTypes.SYSTEM_PRICE_GENERIC: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_GENERIC_PRICE_DATA,
                            methods.METHOD_GET_SYSTEM_GENERIC_PRICE_DATA
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRICE_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_SYSTEM_PRICE_GROUP_DATA,
                            methods.METHOD_GET_SYSTEM_PRICE_GROUP_DATA
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PRICE_MARGINS,
                            methods.METHOD_GET_ALL_SYSTEM_PRICE_MARGINS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_PRICE_OPTION: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_SYSTEM_PRICE_OPTIONS, methods.METHOD_GET_SYSTEM_PRICE_OPTIONS)
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_GENERIC: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA,
                            methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ZONE_PRICE_GROUP_DATA,
                            methods.METHOD_GET_ZONE_PRICE_GROUP_DATA
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS,
                            methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_OPTION: {
                    requests.push(
                        serverRequest(methods.METHOD_GET_ZONE_PRICE_OPTIONS, methods.METHOD_GET_ZONE_PRICE_OPTIONS)
                    );
                    break;
                }
                // privileges
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    if (payload.params[propertyConstants.PROPERTY_USER_GROUP_ID] !== null) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP,
                                methods.METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT:
                case objectTypes.MASTER_PRODUCT_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS,
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_PRODUCTS,
                                methods.METHOD_GET_ALL_MASTER_PRODUCTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS,
                                methods.METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_PRODUCTS,
                                methods.METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT: {
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT:
                case objectTypes.SYSTEM_PRODUCT_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS,
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_FOR_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCTS,
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT: {
                    break;
                }
                case objectTypes.ZONE_PRODUCT:
                case objectTypes.ZONE_PRODUCT_IN_COLOR: {
                    if (payload.params.simpleData) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS,
                                methods.METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS,
                                { ...payload.params, simpleData: undefined }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_PRODUCTS,
                                methods.METHOD_GET_ALL_ZONE_PRODUCTS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                            if (payload.params.simpleData) {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        { ...payload.params, simpleData: undefined }
                                    )
                                );
                            } else {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        payload.params
                                    )
                                );
                            }
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    payload.params
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM,
                                methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT: {
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                            if (payload.params.simpleData) {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_SYSTEM_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        { ...payload.params, simpleData: undefined }
                                    )
                                );
                            } else {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        payload.params
                                    )
                                );
                            }
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    payload.params
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE,
                                methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT: {
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                            if (payload.params.simpleData) {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_ZONE_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        { ...payload.params, simpleData: undefined }
                                    )
                                );
                            } else {
                                requests.push(
                                    serverRequest(
                                        methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                        methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS,
                                        payload.params
                                    )
                                );
                            }
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT,
                                    payload.params
                                )
                            );
                        }
                    }
                    break;
                }
                // product base package
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM,
                                methods.METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE: {
                    break;
                }
                case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT,
                                methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE,
                                methods.METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    break;
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    if (payload.params[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT,
                                methods.METHOD_GET_ALL_ZONE_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // product groups
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP,
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP: {
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_PRODUCT,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUP,
                                methods.METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUP,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_PRODUCT_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS,
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE,
                            methods.METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT,
                                methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP,
                            methods.METHOD_GET_ALL_ZONE_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP: {
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_FOR_PRODUCT,
                                methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_FOR_PRODUCT,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    if (payload.params[propertyConstants.PROPERTY_PRODUCT_GROUP_ID]) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUP,
                                methods.METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUP,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.ZONE_PRODUCT_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS,
                            methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                // product sheet
                case objectTypes.PRODUCT_SHEET: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_FILES,
                            methods.METHOD_GET_ALL_PRODUCT_SHEETS,
                            payload.params
                        )
                    );
                    break;
                }
                // scripts
                case objectTypes.SERVER_SCRIPTS: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SCRIPTS,
                                methods.METHOD_GET_ALL_SCRIPTS_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SCRIPTS,
                                methods.METHOD_GET_ALL_SCRIPTS,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                // spectro types
                case objectTypes.SPECTRO_TYPE: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SPECTRO_TYPES,
                                methods.METHOD_GET_ALL_SPECTRO_TYPES_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SPECTRO_TYPES,
                                methods.METHOD_GET_ALL_SPECTRO_TYPES,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                // static sql
                case objectTypes.STATIC_SQL: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_FILES,
                                methods.METHOD_GET_ALL_STATIC_SQL_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_FILES,
                                methods.METHOD_GET_ALL_STATIC_SQL,
                                payload.params
                            )
                        );
                    }

                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEMS,
                                methods.METHOD_GET_ALL_SYSTEMS_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_SYSTEMS,
                                methods.METHOD_GET_ALL_SYSTEMS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // system zone
                case objectTypes.SYSTEM_ZONE: {
                    break;
                }
                // unit
                case objectTypes.UNIT: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_UNITS,
                                methods.METHOD_GET_ALL_UNITS_NO_PARAMS,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(methods.METHOD_GET_ALL_UNITS, methods.METHOD_GET_ALL_UNITS, payload.params)
                        );
                    }
                    break;
                }
                // user
                case objectTypes.MASTER_REDLIKE_USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS,
                            methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS,
                            methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS,
                            methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS_WITH_GROUPS,
                            methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS_WITH_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.USER_ACTION_HISTORY: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_USER_HISTORY_ACTIONS,
                            methods.METHOD_GET_ALL_USER_HISTORY_ACTIONS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_USERS_WITH_GROUPS,
                            methods.METHOD_GET_ALL_USERS_WITH_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS,
                            methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS_WITH_GROUPS,
                            methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS_WITH_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                // user group
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER,
                            methods.METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS,
                            methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.REDLIKE_USER_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS,
                            methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS_NO_PARAMS,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER,
                            methods.METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.USER_GROUP: {
                    if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_USER_GROUPS,
                                methods.METHOD_GET_ALL_USER_GROUPS_NO_PARAMS,
                                { ...payload.params, orderBy: propertyConstants.PROPERTY_PRIORITY }
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_GET_ALL_USER_GROUPS,
                                methods.METHOD_GET_ALL_USER_GROUPS,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // zone
                case objectTypes.WEB_ZONE: {
                    if (payload.params[propertyConstants.PROPERTY_COMPANY_ID]) {
                        if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY,
                                    methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY_NO_PARAMS,
                                    payload.params
                                )
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY,
                                    methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY,
                                    payload.params
                                )
                            );
                        }
                    }
                    break;
                }
                case objectTypes.ZONE:
                case objectTypes.ZONE_DUPLICATE: {
                    if (payload.params[propertyConstants.PROPERTY_SYSTEM_ID]) {
                        if (payload.params.rowCount === undefined && payload.params.offset === undefined) {
                            const method =
                                payload.objectType === objectTypes.ZONE_DUPLICATE
                                    ? methods.METHOD_GET_ALL_DUPLICATE_ZONES_NO_PARAMS
                                    : methods.METHOD_GET_ALL_ZONES_NO_PARAMS;

                            requests.push(
                                serverRequest(methods.METHOD_GET_ALL_ZONES_BY_SYSTEM, method, payload.params)
                            );
                        } else {
                            requests.push(
                                serverRequest(
                                    methods.METHOD_GET_ALL_ZONES_BY_SYSTEM,
                                    methods.METHOD_GET_ALL_ZONES_BY_SYSTEM,
                                    payload.params
                                )
                            );
                        }
                    }
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
