import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { Product } from "../product";
import { TableHeading } from "../tableHeading";

export class DuplicateProduct {
    id: number;
    name: string;
    productName: string;
    isDuplicated: boolean;

    constructor(id: number, name: string, productName: string) {
        this.id = id;
        this.name = name;
        this.productName = productName;
        this.isDuplicated = true;
    }
}

export function mapDuplicateProduct(data: Record<string, any>, product: Product): DuplicateProduct | null {
    try {
        return new DuplicateProduct(data.id, data.name, product[propertyConstants.PROPERTY_NAME]);
    } catch (e) {
        return null;
    }
}

export function updateDuplicateProduct(
    oldDuplicateProduct: DuplicateProduct | null,
    updatedData: Record<string, any>
): DuplicateProduct | null {
    if (oldDuplicateProduct === null) {
        return null;
    }

    try {
        return new DuplicateProduct(
            oldDuplicateProduct.id,
            oldDuplicateProduct.name,
            updatedData.name ? updatedData.name : oldDuplicateProduct.productName
        );
    } catch (e) {
        return null;
    }
}

export function generateDuplicateProductData(duplicateProductList: Array<DuplicateProduct>): Array<any> {
    const data: Array<any> = [];
    let duplicateProduct: DuplicateProduct;

    for (duplicateProduct of duplicateProductList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: duplicateProduct.id,
            [propertyConstants.PROPERTY_NAME]: duplicateProduct.name,
            [propertyConstants.PROPERTY_PRODUCT_NAME]: duplicateProduct.productName,
            [propertyConstants.PROPERTY_IS_DUPLICATED]: duplicateProduct.isDuplicated
        });
    }

    return data;
}

export function generateDuplicateProductHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_DUPLICATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_DUPLICATED),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            editableList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            requiredList.includes(propertyConstants.PROPERTY_IS_DUPLICATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_DUPLICATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_NAME] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
