import * as databaseActions from "../../actions/databaseActions";
import * as defaultConstants from "../../constants/default";
import * as generalHelper from "../../helpers/generalHelper";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as notificationActions from "../../actions/notificationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Database, mapDatabase } from "../../types/database";
import { SystemZone, mapSystemZones } from "../../types/systemZone";

import { AppState } from "../../reducers";
import { FlashMessage } from "../../types/flashMessage";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";
import { t as translate } from "react-i18nify";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const databaseList: Array<Database> = [];
            let systemZoneList: Array<SystemZone> = [];
            let database: Database | null;

            for (const item of response.data) {
                database = mapDatabase(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (database) {
                    databaseList.push(database);
                }

                if (item[propertyConstants.PROPERTY_ID] === state.database.active?.[propertyConstants.PROPERTY_ID]) {
                    for (const system of item.systems) {
                        const list = mapSystemZones(system.zones);

                        if (list.length > 0) {
                            systemZoneList = systemZoneList.concat(list);
                        }
                    }
                }
            }

            return [
                universalObjectActions.saveItemsNoParams(objectTypes.DATABASE_SYSTEM_ZONE, databaseList),
                universalObjectActions.saveItemsNoParams(objectTypes.SYSTEM_ZONE, systemZoneList)
            ];
        }
        case methods.METHOD_GET_ALL_DATABASES_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const databaseList: Array<Database> = [];
            let database: Database | null;

            for (const item of response.data.data) {
                database = mapDatabase(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (database) {
                    databaseList.push(database);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.DATABASE, databaseList)];
        }
        case methods.METHOD_GET_ALL_DATABASES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const databaseList: Array<Database> = [];
            let database: Database | null;

            for (const item of response.data.data) {
                database = mapDatabase(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (database) {
                    databaseList.push(database);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.DATABASE, databaseList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const databaseList: Array<Database> = [];
            let database: Database | null;

            for (const item of response.data.data) {
                database = mapDatabase(item);

                if (database) {
                    databaseList.push(database);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.DATABASE_MONITORING, databaseList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_DATABASE:
        case methods.METHOD_ADD_EMPTY_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE),
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.getAllItems(objectTypes.DATABASE),
                universalObjectActions.getAllItems(objectTypes.DATA_SIZE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_DATABASES_ADD),
                navigationActions.navigationCloseModal(modalTypes.MODAL_DATABASES_EMPTY_ADD)
            ];
        }
        case methods.METHOD_DUPLICATE_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE),
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.getAllItems(objectTypes.DATABASE),
                universalObjectActions.getAllItems(objectTypes.DATA_SIZE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_DATABASES_DUPLICATE)
            ];
        }
        case methods.METHOD_EDIT_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.getAllItems(objectTypes.DATABASE)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_DATABASES_EDIT)) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                    universalObjectActions.reloadData(objectTypes.DATABASE),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_DATABASES_EDIT)
                );
            } else {
                const activeDatabase = mapDatabase(
                    response.data,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (activeDatabase) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.DATABASE, [activeDatabase]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.DATABASE),
                universalObjectActions.reloadData(objectTypes.DATABASE_SYSTEM_ZONE),
                universalObjectActions.getAllItems(objectTypes.DATABASE),
                universalObjectActions.setActiveItems(objectTypes.DATABASE, [])
            ];
        }
        case methods.METHOD_GET_ACTIVE_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_SET_ACTIVE_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [databaseActions.loadData()];
        }
        case methods.METHOD_UPDATE_ACTIVE_DATABASE_STRUCTURE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response), databaseActions.closeActiveDatabase()];
            }

            return [
                notificationActions.saveFlashMessage(
                    new FlashMessage(defaultConstants.FLASH_MESSAGE_SUCCESS, translate("flashMessage.databaseUpdated"))
                ),
                databaseActions.getActiveDatabase()
            ];
        }
        case methods.METHOD_CLOSE_ACTIVE_DATABASE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                notificationActions.saveFlashMessage(
                    new FlashMessage(defaultConstants.FLASH_MESSAGE_WARNING, translate("flashMessage.databaseClosed"))
                )
            ];
        }
        case methods.METHOD_VACUUM_ALL_DATABASES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
