import * as generalHelper from "../../helpers/generalHelper";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as priceHelper from "../../helpers/priceHelper";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Margin, mapMargin } from "../../types/margin";
import { Price, generateLastPriceGroup, mapPrice } from "../../types/price";
import { PriceOption, mapPriceOption } from "../../types/priceOption";

import { AppState } from "../../reducers";
import { MarginType } from "../../types/marginType";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SYSTEM_PRICE_MARGINS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const marginList: Array<Margin> = [];
            let margin: Margin | null;

            for (const item of response.data.data) {
                margin = mapMargin(item);

                if (margin) {
                    marginList.push(margin);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_PRICE_MARGIN, marginList)];
        }
        case methods.METHOD_ADD_SYSTEM_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_MARGIN),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_MARGIN_ADD)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PRICE_MARGIN_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_MARGIN));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_MARGIN_EDIT));
            } else {
                const priceMargin = mapMargin(response.data);

                if (priceMargin) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PRICE_MARGIN, [priceMargin])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_MARGIN),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_PRICE_MARGIN, [])
            ];
        }
        case methods.METHOD_GET_SYSTEM_PRICE_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const optionList: Array<PriceOption> = [];
            let option: PriceOption | null;

            const marginTypeList: Array<MarginType> = [];
            let marginType: MarginType | null;

            for (const item of Object.values(response.data)) {
                option = mapPriceOption(item);

                if (option) {
                    optionList.push(option);
                    marginType = priceHelper.getMarginTypes(option);

                    if (marginType) {
                        marginTypeList.push(marginType);
                    }
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.SYSTEM_MARGIN_TYPE, marginTypeList),
                universalObjectActions.saveItems(objectTypes.SYSTEM_PRICE_OPTION, optionList)
            ];
        }
        case methods.METHOD_SET_SYSTEM_PRICE_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_GROUP),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_OPTION),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_OPTIONS)
            ];
        }
        case methods.METHOD_GET_SYSTEM_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceList: Array<Price> = [];
            let price: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                price = mapPrice(key, value);

                if (price) {
                    priceList.push(price);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_PRICE_GENERIC, priceList)];
        }
        case methods.METHOD_SET_SYSTEM_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PACKAGE),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_GENERIC),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_GENERIC_EDIT)
            ];
        }
        case methods.METHOD_SET_SINGLE_SYSTEM_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceList: Array<Price> = [];
            let price: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                price = mapPrice(key, value);

                if (price) {
                    priceList.push(price);
                }
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PACKAGE),
                universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PRICE_GENERIC, priceList)
            ];
        }
        case methods.METHOD_GET_SYSTEM_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceGroupList: Array<Price> = [];
            let priceGroup: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                priceGroup = mapPrice(key, value);

                if (priceGroup) {
                    priceGroupList.push(priceGroup);
                }
            }

            const sortedList = generalHelper.sortItemsByProperty(
                priceGroupList,
                propertyConstants.PROPERTY_PRICE_GROUP,
                true
            );

            if (sortedList.length) {
                const lastPriceGroup = generateLastPriceGroup(
                    sortedList[sortedList.length - 1],
                    propertyConstants.PROPERTY_PRICE_GROUP
                );

                if (lastPriceGroup) {
                    sortedList.push(lastPriceGroup);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_PRICE_GROUP, sortedList)];
        }
        case methods.METHOD_SET_SYSTEM_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_PRICE_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_GROUP_EDIT)
            ];
        }
        case methods.METHOD_SET_SINGLE_SYSTEM_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceGroupList: Array<Price> = [];
            let priceGroup: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                priceGroup = mapPrice(key, value);

                if (priceGroup) {
                    priceGroupList.push(priceGroup);
                }
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PRICE_GROUP, priceGroupList)
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_PRICE_MARGINS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const marginList: Array<Margin> = [];
            let margin: Margin | null;

            for (const item of response.data.data) {
                margin = mapMargin(item);

                if (margin) {
                    marginList.push(margin);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.ZONE_PRICE_MARGIN, marginList)];
        }
        case methods.METHOD_ADD_ZONE_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_MARGIN),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_MARGIN_ADD)
            ];
        }
        case methods.METHOD_EDIT_ZONE_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PRICE_MARGIN_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_PRICE_MARGIN));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_MARGIN_EDIT));
            } else {
                const priceMargin = mapMargin(response.data);

                if (priceMargin) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_PRICE_MARGIN, [priceMargin]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_PRICE_MARGIN: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_MARGIN),
                universalObjectActions.setActiveItems(objectTypes.ZONE_PRICE_MARGIN, [])
            ];
        }
        case methods.METHOD_GET_ZONE_PRICE_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const optionList: Array<PriceOption> = [];
            let option: PriceOption | null;

            const marginTypeList: Array<MarginType> = [];
            let marginType: MarginType | null;

            for (const item of Object.values(response.data)) {
                option = mapPriceOption(item);

                if (option) {
                    optionList.push(option);
                    marginType = priceHelper.getMarginTypes(option);

                    if (marginType) {
                        marginTypeList.push(marginType);
                    }
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.ZONE_MARGIN_TYPE, marginTypeList),
                universalObjectActions.saveItems(objectTypes.ZONE_PRICE_OPTION, optionList)
            ];
        }
        case methods.METHOD_SET_ZONE_PRICE_OPTIONS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_GROUP),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_OPTION),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_OPTIONS)
            ];
        }
        case methods.METHOD_GET_ZONE_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceList: Array<Price> = [];
            let price: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                price = mapPrice(key, value);

                if (price) {
                    priceList.push(price);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.ZONE_PRICE_GENERIC, priceList)];
        }
        case methods.METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceList: Array<Price> = [];
            let price: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                price = mapPrice(key, value);

                if (price) {
                    priceList.push(price);
                }
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PACKAGE),
                universalObjectActions.editItemSuccess(objectTypes.ZONE_PRICE_GENERIC, priceList)
            ];
        }
        case methods.METHOD_SET_ZONE_GENERIC_PRICE_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PACKAGE),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_GENERIC),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_GENERIC_EDIT)
            ];
        }
        case methods.METHOD_GET_ZONE_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceGroupList: Array<Price> = [];
            let priceGroup: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                priceGroup = mapPrice(key, value);

                if (priceGroup) {
                    priceGroupList.push(priceGroup);
                }
            }

            const sortedList = priceGroupList.sort((groupA: Price, groupB: Price) =>
                groupA[propertyConstants.PROPERTY_KEY].localeCompare(groupB[propertyConstants.PROPERTY_KEY])
            );

            if (sortedList.length) {
                const lastPriceGroup = generateLastPriceGroup(
                    sortedList[sortedList.length - 1],
                    propertyConstants.PROPERTY_PRICE_GROUP
                );

                if (lastPriceGroup) {
                    sortedList.push(lastPriceGroup);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.ZONE_PRICE_GROUP, sortedList)];
        }
        case methods.METHOD_SET_ZONE_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_PRICE_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PRICE_GROUP_EDIT)
            ];
        }
        case methods.METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const priceGroupList: Array<Price> = [];
            let priceGroup: Price | null;

            for (const [key, value] of Object.entries(response.data)) {
                priceGroup = mapPrice(key, value);

                if (priceGroup) {
                    priceGroupList.push(priceGroup);
                }
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.editItemSuccess(objectTypes.ZONE_PRICE_GROUP, priceGroupList)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
