import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

import DatePicker from "react-date-picker";

type Props = {
    objectType: objectTypes.ObjectType;
    editable: boolean;
    required: boolean;
    value: Date;
    property: propertyConstants.Property;
    callbackChange: (value: string | null) => any;
};

export class ModalDatePicker extends Component<Props> {
    getClassname = (): string => {
        let className = "date-input";

        if (!this.props.editable) {
            className += " disabled";
        }

        return className;
    };

    handleDateChange = (value: Date | Array<Date> | null): void => {
        if (this.props.required && value === null) {
            value = new Date();
        }

        if (!Array.isArray(value)) {
            this.props.callbackChange(value ? value.toDateString() : null);
        }
    };

    getMaximumDate = (): Date | undefined => {
        return this.props.property !== propertyConstants.PROPERTY_PRODUCTION_DATE &&
            this.props.objectType !== objectTypes.ONE_TIME_ACCESS_PASSWORD
            ? new Date()
            : undefined;
    };

    getMinimumDate = (): Date | undefined => {
        return this.props.objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD ? new Date() : undefined;
    };

    render(): JSX.Element {
        const { value } = this.props;

        return (
            <div className={this.getClassname()}>
                <DatePicker
                    disabled={!this.props.editable}
                    required={this.props.required}
                    format={"y/MM/dd"}
                    maxDate={this.getMaximumDate()}
                    minDate={this.getMinimumDate()}
                    value={value ? new Date(value) : null}
                    onChange={(value: Date | Array<Date> | null) => this.handleDateChange(value)}
                />
            </div>
        );
    }
}
