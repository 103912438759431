import * as generalHelper from "../helpers/generalHelper";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Database } from "./database";
import { ImportFile } from "./importFile";
import { ImportOption } from "./importOption";
import { ImportStatus } from "./importStatus";
import { ImportType } from "./importType";
import { System } from "./system";
import { TableHeading } from "./tableHeading";
import { Zone } from "./zone";

export class ImportTask {
    id: number;
    uuid: string;
    databaseId: number | null;
    databaseName: string;
    databaseFilePath: string;
    systemId: number | null;
    systemUuid: string | null;
    systemName: string | null;
    zoneId: number | null;
    zoneUuid: string | null;
    zoneName: string | null;
    importTaskTypeId: number;
    importTaskStatusId: number;
    strict: boolean;
    keepOldFormulas: importExportConstants.KeepOldFormulas;
    files: Array<ImportFile>;
    filesAbs: Array<any>;
    options: Array<ImportOption>;
    infoData: string | null;
    warningText: string | null;
    errorText: string | null;
    errorCode: string | null;
    progress: number | null;
    dateCreated: string | null;
    dateFinished: string | null;
    priority: number | null;
    canDelete: boolean;
    canDownload: boolean;
    canHide: boolean;
    deleted: importExportConstants.TaskDeletedType;
    finished: boolean;
    isChecked: boolean;

    constructor(
        id: number,
        uuid: string,
        databaseId: number | null,
        databaseName: string,
        databaseFilePath: string,
        systemId: number | null,
        systemUuid: string | null,
        systemName: string | null,
        zoneId: number | null,
        zoneUuid: string | null,
        zoneName: string | null,
        importTaskTypeId: number,
        importTaskStatusId: number,
        strict: boolean,
        keepOldFormulas: importExportConstants.KeepOldFormulas,
        files: Array<ImportFile>,
        filesAbs: Array<any>,
        options: Array<ImportOption>,
        infoData: string | null,
        warningText: string | null,
        errorText: string | null,
        errorCode: string | null,
        progress: number | null,
        dateCreated: string | null,
        dateFinished: string | null,
        priority: number | null,
        canDelete: boolean,
        canDownload: boolean,
        canHide: boolean,
        deleted: importExportConstants.TaskDeletedType,
        finished: boolean,
        isChecked: boolean
    ) {
        this.id = id;
        this.uuid = uuid;
        this.databaseId = databaseId;
        this.databaseName = databaseName;
        this.databaseFilePath = databaseFilePath;
        this.systemId = systemId;
        this.systemUuid = systemUuid;
        this.systemName = systemName;
        this.zoneId = zoneId;
        this.zoneUuid = zoneUuid;
        this.zoneName = zoneName;
        this.importTaskTypeId = importTaskTypeId;
        this.importTaskStatusId = importTaskStatusId;
        this.strict = strict;
        this.keepOldFormulas = keepOldFormulas;
        this.files = files;
        this.filesAbs = filesAbs;
        this.options = options;
        this.infoData = infoData;
        this.warningText = warningText;
        this.errorText = errorText;
        this.errorCode = errorCode;
        this.progress = progress;
        this.dateCreated = dateCreated;
        this.dateFinished = dateFinished;
        this.priority = priority;
        this.canDelete = canDelete;
        this.canDownload = canDownload;
        this.canHide = canHide;
        this.deleted = deleted;
        this.finished = finished;
        this.isChecked = isChecked;
    }
}

export function mapImportTask(data: Record<string, any>): ImportTask | null {
    try {
        return new ImportTask(
            data.id,
            data.uuid,
            data.databaseId,
            data.databaseName,
            data.databaseFilePath,
            data.systemId,
            data.systemUuid,
            data.systemName,
            data.zoneId,
            data.zoneUuid,
            data.zoneName,
            data.importTaskTypeId,
            data.importTaskStatusId,
            data.strict,
            data.keepOldFormulas,
            data.files,
            data.filesAbs,
            data.options,
            data.infoData,
            data.warningText,
            data.errorText,
            data.errorCode,
            data.progress,
            data.dateCreated,
            data.dateFinished,
            data.priority,
            data.canDelete,
            data.canDownload,
            data.canHide,
            data.deleted,
            data.finished,
            false
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyImportTask(
    importTypeList: Array<ImportType>,
    databaseSystemZoneList: Array<Database>,
    activeDatabase: Database | null,
    activeSystem: System | null,
    activeZone: Zone | null
): any {
    const selectedDatabase = databaseSystemZoneList.length
        ? activeDatabase
            ? databaseSystemZoneList.find(
                  (item: Database) =>
                      item[propertyConstants.PROPERTY_ID] === activeDatabase?.[propertyConstants.PROPERTY_ID]
              ) || null
            : databaseSystemZoneList[0]
        : null;
    const selectedSystem = selectedDatabase?.systemList?.length
        ? activeSystem
            ? selectedDatabase.systemList.find(
                  (item: System) =>
                      item[propertyConstants.PROPERTY_ID] === activeSystem?.[propertyConstants.PROPERTY_ID]
              ) || null
            : selectedDatabase.systemList[0]
        : null;
    const selectedZone = selectedSystem?.zoneList?.length
        ? activeZone
            ? selectedSystem.zoneList.find(
                  (item: Zone) => item[propertyConstants.PROPERTY_ID] === activeZone?.[propertyConstants.PROPERTY_ID]
              ) || null
            : selectedSystem.zoneList[0]
        : null;

    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_DATABASE_ID]: selectedDatabase
            ? selectedDatabase[propertyConstants.PROPERTY_ID]
            : "",
        [propertyConstants.PROPERTY_DATABASE_NAME]: selectedDatabase
            ? selectedDatabase[propertyConstants.PROPERTY_NAME]
            : "",
        [propertyConstants.PROPERTY_DATABASE_FILE_PATH]: "",
        [propertyConstants.PROPERTY_SYSTEM_ID]: selectedSystem ? selectedSystem[propertyConstants.PROPERTY_ID] : "",
        [propertyConstants.PROPERTY_SYSTEM_UUID]: selectedSystem ? selectedSystem[propertyConstants.PROPERTY_UUID] : "",
        [propertyConstants.PROPERTY_SYSTEM_NAME]: selectedSystem ? selectedSystem[propertyConstants.PROPERTY_NAME] : "",
        [propertyConstants.PROPERTY_ZONE_ID]: selectedZone ? selectedZone[propertyConstants.PROPERTY_ID] : "",
        [propertyConstants.PROPERTY_ZONE_UUID]: selectedZone ? selectedZone[propertyConstants.PROPERTY_UUID] : "",
        [propertyConstants.PROPERTY_ZONE_NAME]: selectedZone ? selectedZone[propertyConstants.PROPERTY_NAME] : "",
        [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]: importTypeList.length
            ? importTypeList[0][propertyConstants.PROPERTY_ID]
            : "",
        [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]: "",
        [propertyConstants.PROPERTY_STRICT]: true,
        [propertyConstants.PROPERTY_KEEP_OLD_FORMULAS]: importExportConstants.DEFAULT_KEEP_OLD_FORMULAS,
        [propertyConstants.PROPERTY_FILES]: importTypeList.length
            ? importTypeList[0][propertyConstants.PROPERTY_FILES]
            : [],
        [propertyConstants.PROPERTY_FILES_ABS]: [],
        [propertyConstants.PROPERTY_OPTIONS]: importTypeList.length
            ? importTypeList[0][propertyConstants.PROPERTY_OPTIONS]
            : [],
        [propertyConstants.PROPERTY_INFO_DATA]: "",
        [propertyConstants.PROPERTY_WARNING_TEXT]: "",
        [propertyConstants.PROPERTY_ERROR_TEXT]: "",
        [propertyConstants.PROPERTY_ERROR_CODE]: "",
        [propertyConstants.PROPERTY_PROGRESS]: "",
        [propertyConstants.PROPERTY_DATE_CREATED]: "",
        [propertyConstants.PROPERTY_DATE_FINISHED]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_CAN_DELETE]: false,
        [propertyConstants.PROPERTY_CAN_DOWNLOAD]: false,
        [propertyConstants.PROPERTY_CAN_HIDE]: false,
        [propertyConstants.PROPERTY_DELETED]: false,
        [propertyConstants.PROPERTY_FINISHED]: false,
        [propertyConstants.PROPERTY_IS_CHECKED]: false
    };
}

// TODO refactor to single update?
export function updateImportTaskIsChecked(data: Record<string, any>, isChecked: boolean): ImportTask | null {
    try {
        return new ImportTask(
            data.id,
            data.uuid,
            data.databaseId,
            data.databaseName,
            data.databaseFilePath,
            data.systemId,
            data.systemUuid,
            data.systemName,
            data.zoneId,
            data.zoneUuid,
            data.zoneName,
            data.importTaskTypeId,
            data.importTaskStatusId,
            data.strict,
            data.keepOldFormulas,
            data.files,
            data.filesAbs,
            data.options,
            data.infoData,
            data.warningText,
            data.errorText,
            data.errorCode,
            data.progress,
            data.dateCreated,
            data.dateFinished,
            data.priority,
            data.canDelete,
            data.canDownload,
            data.canHide,
            data.deleted,
            data.finished,
            isChecked
        );
    } catch (e) {
        return null;
    }
}

// TODO refactor
export function updateImportTask(
    importTypeList: Array<ImportType>,
    databaseSystemZoneList: Array<Database>,
    importTask: Record<string, any>,
    newData?: Record<string, any>,
    selectedDatabaseId?: number,
    selectedSystemId?: number,
    selectedZoneId?: number,
    fileList?: Array<ImportFile>,
    optionList?: Array<ImportOption>,
    importStatusList?: Array<ImportStatus>
): any {
    // Update event should be ignored as this happens after hide or delete action, and we do reload ourselves
    if (
        importTask[propertyConstants.PROPERTY_DELETED] === newData?.[propertyConstants.PROPERTY_DELETED] &&
        importTask[propertyConstants.PROPERTY_ERROR_CODE] === newData?.[propertyConstants.PROPERTY_ERROR_CODE] &&
        importTask[propertyConstants.PROPERTY_ERROR_TEXT] === newData?.[propertyConstants.PROPERTY_ERROR_TEXT] &&
        importTask[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID] ===
            newData?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID] &&
        importTask[propertyConstants.PROPERTY_PROGRESS] === newData?.[propertyConstants.PROPERTY_PROGRESS] &&
        importTask[propertyConstants.PROPERTY_WARNING_TEXT] === newData?.[propertyConstants.PROPERTY_WARNING_TEXT]
    ) {
        return importTask;
    }

    const importType =
        importTypeList.find(
            (type: ImportType) =>
                type[propertyConstants.PROPERTY_ID] === importTask?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
        ) || null;
    const importDataType = importType?.[propertyConstants.PROPERTY_DATA_TYPE] ?? null;

    const databaseId = selectedDatabaseId
        ? selectedDatabaseId
        : importTask?.[propertyConstants.PROPERTY_DATABASE_ID]
        ? importTask[propertyConstants.PROPERTY_DATABASE_ID]
        : databaseSystemZoneList.length
        ? databaseSystemZoneList[0][propertyConstants.PROPERTY_ID]
        : null;
    const selectedDatabase = databaseId ? generalHelper.getObjectById(databaseSystemZoneList, databaseId) : null;

    const systemId =
        selectedDatabase && (selectedSystemId !== 0 || importDataType !== importExportConstants.IMPORT_MASTER_AND_ZONE)
            ? selectedSystemId
                ? selectedSystemId
                : databaseId === importTask?.[propertyConstants.PROPERTY_DATABASE_ID] &&
                  (importTask?.[propertyConstants.PROPERTY_SYSTEM_ID] ||
                      importDataType === importExportConstants.IMPORT_MASTER_AND_ZONE)
                ? importTask?.[propertyConstants.PROPERTY_SYSTEM_ID]
                : selectedDatabase?.systemList?.length
                ? selectedDatabase.systemList[0][propertyConstants.PROPERTY_ID]
                : null
            : null;
    const selectedSystem = systemId ? generalHelper.getObjectById(selectedDatabase?.systemList || [], systemId) : null;

    const zoneId =
        selectedDatabase && selectedSystem
            ? selectedZoneId
                ? selectedZoneId
                : databaseId === importTask?.[propertyConstants.PROPERTY_DATABASE_ID] &&
                  systemId === importTask?.[propertyConstants.PROPERTY_SYSTEM_ID] &&
                  (importTask?.[propertyConstants.PROPERTY_ZONE_ID] ||
                      importDataType === importExportConstants.IMPORT_MASTER_AND_ZONE)
                ? importTask?.[propertyConstants.PROPERTY_ZONE_ID]
                : selectedSystem?.zoneList?.length
                ? selectedSystem.zoneList[0][propertyConstants.PROPERTY_ID]
                : null
            : null;
    const selectedZone = zoneId ? generalHelper.getObjectById(selectedSystem?.zoneList || [], zoneId) : null;

    const keepOldFormulas =
        importType?.[propertyConstants.PROPERTY_DATA_TYPE] === importExportConstants.IMPORT_ZONE_ONLY
            ? importExportConstants.DEFAULT_KEEP_OLD_FORMULAS
            : importTask?.[propertyConstants.PROPERTY_KEEP_OLD_FORMULAS];

    const importStatus =
        (importStatusList || []).find(
            (status: ImportStatus) =>
                status[propertyConstants.PROPERTY_ID] === newData?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]
        ) || null;

    return {
        ...importTask,
        [propertyConstants.PROPERTY_DATABASE_ID]: selectedDatabase?.[propertyConstants.PROPERTY_ID] || "",
        [propertyConstants.PROPERTY_DATABASE_NAME]: selectedDatabase?.[propertyConstants.PROPERTY_NAME] || "",
        [propertyConstants.PROPERTY_SYSTEM_ID]: selectedSystem?.[propertyConstants.PROPERTY_ID] || "",
        [propertyConstants.PROPERTY_SYSTEM_NAME]: selectedSystem?.[propertyConstants.PROPERTY_NAME] || "",
        [propertyConstants.PROPERTY_ZONE_ID]: selectedZone?.[propertyConstants.PROPERTY_ID] || "",
        [propertyConstants.PROPERTY_ZONE_NAME]: selectedZone?.[propertyConstants.PROPERTY_NAME] || "",
        [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]:
            importTask?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID] || "",
        [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]:
            newData?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID] ||
            importTask?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID],
        [propertyConstants.PROPERTY_KEEP_OLD_FORMULAS]: keepOldFormulas,
        [propertyConstants.PROPERTY_FILES]: fileList || importTask?.[propertyConstants.PROPERTY_FILES] || [],
        [propertyConstants.PROPERTY_OPTIONS]: optionList || importTask?.[propertyConstants.PROPERTY_OPTIONS] || [],
        [propertyConstants.PROPERTY_WARNING_TEXT]:
            newData?.[propertyConstants.PROPERTY_WARNING_TEXT] ||
            importTask?.[propertyConstants.PROPERTY_WARNING_TEXT] ||
            null,
        [propertyConstants.PROPERTY_ERROR_TEXT]:
            newData?.[propertyConstants.PROPERTY_ERROR_TEXT] ||
            importTask?.[propertyConstants.PROPERTY_ERROR_TEXT] ||
            null,
        [propertyConstants.PROPERTY_ERROR_CODE]:
            newData?.[propertyConstants.PROPERTY_ERROR_CODE] ||
            importTask?.[propertyConstants.PROPERTY_ERROR_CODE] ||
            null,
        [propertyConstants.PROPERTY_PROGRESS]:
            newData?.[propertyConstants.PROPERTY_PROGRESS] || importTask?.[propertyConstants.PROPERTY_PROGRESS],
        [propertyConstants.PROPERTY_DATE_FINISHED]: importStatus?.[propertyConstants.PROPERTY_FINISHED]
            ? newData?.dateStatus || importTask?.[propertyConstants.PROPERTY_DATE_FINISHED] || null
            : importTask?.[propertyConstants.PROPERTY_DATE_FINISHED] || null,
        [propertyConstants.PROPERTY_IS_CHECKED]: importTask?.[propertyConstants.PROPERTY_IS_CHECKED] ?? false
    };
}

export function generateImportTaskData(objectType: objectTypes.ObjectType, list: Array<ImportTask>): Array<any> {
    const data: Array<any> = [];
    let item: ImportTask;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_DATABASE_ID]: item.databaseId,
            [propertyConstants.PROPERTY_DATABASE_NAME]: item.databaseName,
            [propertyConstants.PROPERTY_DATABASE_FILE_PATH]: item.databaseFilePath,
            [propertyConstants.PROPERTY_SYSTEM_ID]: item.systemId,
            [propertyConstants.PROPERTY_SYSTEM_UUID]: item.systemUuid,
            [propertyConstants.PROPERTY_SYSTEM_NAME]: item.systemName,
            [propertyConstants.PROPERTY_ZONE_ID]: item.zoneId,
            [propertyConstants.PROPERTY_ZONE_UUID]: item.zoneUuid,
            [propertyConstants.PROPERTY_ZONE_NAME]: item.zoneName,
            [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]: item.importTaskTypeId,
            [propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID]: item.importTaskStatusId,
            [propertyConstants.PROPERTY_ACTION]: item.importTaskStatusId,
            [propertyConstants.PROPERTY_STRICT]: item.strict,
            [propertyConstants.PROPERTY_KEEP_OLD_FORMULAS]: item.keepOldFormulas,
            [propertyConstants.PROPERTY_FILES]: item.files,
            [propertyConstants.PROPERTY_FILES_ABS]: item.filesAbs,
            [propertyConstants.PROPERTY_OPTIONS]: item.options,
            [propertyConstants.PROPERTY_INFO_DATA]: item.infoData,
            [propertyConstants.PROPERTY_WARNING_TEXT]: item.warningText,
            [propertyConstants.PROPERTY_ERROR_TEXT]: item.errorText,
            [propertyConstants.PROPERTY_ERROR_CODE]: item.errorCode,
            [propertyConstants.PROPERTY_PROGRESS]: item.progress,
            [propertyConstants.PROPERTY_DATE_CREATED]: item.dateCreated,
            [propertyConstants.PROPERTY_DATE_FINISHED]: item.dateFinished,
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_CAN_DELETE]: item.canDelete,
            [propertyConstants.PROPERTY_CAN_DOWNLOAD]: item.canDownload,
            [propertyConstants.PROPERTY_CAN_HIDE]: item.canHide,
            [propertyConstants.PROPERTY_DELETED]: item.deleted,
            [propertyConstants.PROPERTY_FINISHED]: item.finished,
            [propertyConstants.PROPERTY_IS_CHECKED]: item.isChecked
        });
    }

    return data;
}

export function generateImportTaskHeadings(
    objectType: objectTypes.ObjectType,
    statusList: Array<ImportStatus>,
    typeList: Array<ImportType>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ACTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ACTION),
            tableConstants.TABLE_TYPE_BUTTON,
            visibleList.includes(propertyConstants.PROPERTY_ACTION),
            editableList.includes(propertyConstants.PROPERTY_ACTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ACTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ACTION),
            requiredList.includes(propertyConstants.PROPERTY_ACTION),
            statusList,
            widthOption?.[propertyConstants.PROPERTY_ACTION] ?? null,
            "action table-button"
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATABASE_FILE_PATH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            editableList.includes(propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            requiredList.includes(propertyConstants.PROPERTY_DATABASE_FILE_PATH),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATABASE_FILE_PATH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_CREATED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            editableList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_CREATED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_CREATED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_CREATED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE_FINISHED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE_FINISHED),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            editableList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            requiredList.includes(propertyConstants.PROPERTY_DATE_FINISHED),
            [],
            widthOption?.[propertyConstants.PROPERTY_DATE_FINISHED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID),
            typeList,
            widthOption?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            tableConstants.TABLE_TYPE_STATUS,
            visibleList.includes(propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            editableList.includes(propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID),
            statusList,
            widthOption?.[propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IS_CHECKED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            editableList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IS_CHECKED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            requiredList.includes(propertyConstants.PROPERTY_IS_CHECKED),
            [],
            widthOption?.[propertyConstants.PROPERTY_IS_CHECKED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PROGRESS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PROGRESS),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PROGRESS),
            editableList.includes(propertyConstants.PROPERTY_PROGRESS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PROGRESS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PROGRESS),
            requiredList.includes(propertyConstants.PROPERTY_PROGRESS),
            [],
            widthOption?.[propertyConstants.PROPERTY_PROGRESS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            editableList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZONE_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
