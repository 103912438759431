import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Base } from "../../../types/base";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { SystemBaseScene } from "../../../components/scenes/system/SystemBaseScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    baseTableConstant: string;
    baseList: Array<Base>;
    baseActiveList: Array<Base>;
    baseLoading: boolean;
    baseButtonLoading: boolean;
    baseAvailableCount: number | null;
    baseColumnOrder: Array<propertyConstants.Property>;
    baseColumnVisibility: Record<propertyConstants.Property, boolean>;
    baseColumnWidth: Record<propertyConstants.Property, number>;
    baseOffset: number;
    basePage: number;
    baseRowCount: number;
    baseRowCountCustom: boolean;
    baseSceneTableSize: Record<string, any> | null;
    baseSearch: string | null;
    baseSearchParams: Record<propertyConstants.Property, string>;
    baseShowFilterRow: boolean;
    baseShowGlobalSearch: boolean;
    baseSortingAsc: boolean;
    baseSortingCriterion: propertyConstants.Property | null;
    baseTotalCount: number | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_BASES
    ),
    baseTableConstant: menuConstants.TABLE_SYSTEM_BASES,
    baseList: state.base.systemList,
    baseActiveList: state.base.systemActiveList,
    baseLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_BASES ||
            request.method === methods.METHOD_DELETE_SYSTEM_BASE
    ),
    baseButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_SYSTEM_BASE
    ),
    baseAvailableCount: state.base.systemCountAvailable,
    baseColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseOffset: state.base.systemOffset,
    basePage: state.base.systemPage,
    baseRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_SYSTEM_BASES
    ),
    baseSearch: state.base.systemSearch,
    baseSearchParams: state.base.systemSearchParams,
    baseShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_BASES
    ),
    baseSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_BASES
        )
    ),
    baseSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_BASES
        )
    ),
    baseTotalCount: state.base.systemCountTotal,
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_SYSTEM_BASES
    )
});

export const SystemBaseSceneContainer = connect(mapStateToProps)(SystemBaseScene);
