import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import {
    DuplicateProduct,
    generateDuplicateProductData,
    generateDuplicateProductHeadings
} from "../../../types/duplication/duplicateProduct";
import React, { Component } from "react";

import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    allList: Array<DuplicateProduct>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
};

export class ProductDuplicateTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_DUPLICATED,
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PRODUCT_NAME
        ];
        const editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_DUPLICATED,
            propertyConstants.PROPERTY_PRODUCT_NAME
        ];
        let visibleList = [...propertyList];

        const orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
        visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);

        return generateDuplicateProductHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            visibleList,
            visibleList,
            this.props.columnWidth
        );
    };

    render(): JSX.Element {
        return (
            <div className="card">
                <div className="card-content">
                    <TableContainer
                        className={"no-heading-table"}
                        tableConstant={this.props.tableConstant}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        loading={false}
                        titleClassName={"no-title"}
                        headings={this.generateTableHeadings()}
                        data={generateDuplicateProductData(this.props.allList)}
                        activeData={[]}
                        offset={tableConstants.DEFAULT_OFFSET}
                        page={tableConstants.DEFAULT_PAGE}
                        rowCount={this.props.allList.length}
                        rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                        search={null}
                        searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                        showFilterRow={false}
                        showGlobalSearch={false}
                        sortingAsc={true}
                        sortingCriterion={null}
                        totalCount={null}
                        modalProperties={{}}
                    />
                </div>
            </div>
        );
    }
}
