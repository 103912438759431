import * as bookmarkConstants from "../../../../constants/bookmarkConstants";
import * as dataTypeConstants from "../../../../constants/dataTypeConstants";
import * as generalHelper from "../../../../helpers/generalHelper";
import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import { Zone, createEmptyZone, generateZoneData, generateZoneHeadings } from "../../../../types/zone";

import { Bookmark } from "../../../../types/bookmark";
import { Currency } from "../../../../types/currency";
import { File } from "../../../../types/file";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { System } from "../../../../types/system";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    privileges: any;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    currencyList: Array<Currency>;
    staticSqlList: Array<File>;
    systemActiveList: Array<System>;
    allList: Array<Zone>;
    activeList: Array<Zone>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class ZoneTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        isDuplicatedBookmark: boolean = false
    ): Array<TableHeading> => {
        const { privileges } = this.props;

        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD,
            propertyConstants.PROPERTY_CURRENCY_ID,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME,
            propertyConstants.PROPERTY_CHANGELOG,
            propertyConstants.PROPERTY_STATIC_SQL,
            propertyConstants.PROPERTY_IMAGE_ID,
            propertyConstants.PROPERTY_ICON_ID,
            propertyConstants.PROPERTY_MORE_INFO
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_CURRENCY_ID,
            propertyConstants.PROPERTY_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        let editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (modalType === null) {
            editableList = [propertyConstants.PROPERTY_CURRENCY_ID, propertyConstants.PROPERTY_NAME];
        }

        visibleList.splice(1, 0, propertyConstants.PROPERTY_UUID);

        if (
            modalType === modalTypes.MODAL_ZONES_ADD ||
            modalType === modalTypes.MODAL_ZONES_DUPLICATE ||
            modalType === modalTypes.MODAL_ZONES_EDIT
        ) {
            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_ZONE_UUID_UPDATE])) {
                editableList.push(propertyConstants.PROPERTY_UUID);
            }
        }

        if (modalType === modalTypes.MODAL_ZONES_ADD || modalType === modalTypes.MODAL_ZONES_EDIT) {
            if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_ZONE_REDLIKE_WEB_SHOW])) {
                visibleList.splice(0, 0, propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES);
                editableList.splice(0, 0, propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES);
            }
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        if (isDuplicatedBookmark) {
            visibleList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
            editableList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
        }

        return generateZoneHeadings(
            this.props.objectType,
            this.props.currencyList,
            this.props.staticSqlList,
            generalHelper.generateDataTypes(this.props.objectType, dataTypeConstants.DATA_TYPE_LIST),
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateBookmarks = (modalType: modalTypes.ModalType): Array<Bookmark> => {
        return [
            new Bookmark(
                bookmarkConstants.BOOKMARK_GENERAL,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.general"),
                this.generateTableHeadings(modalType),
                this.props.dataPrivileges,
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_DUPLICATE,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.duplicate"),
                this.generateTableHeadings(modalType, true),
                this.props.dataPrivileges,
                false
            )
        ];
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            this.props.objectType,
            translate("zone.addZone"),
            createEmptyZone(this.props.systemActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null),
            this.generateTableHeadings(modalTypes.MODAL_ZONES_ADD)
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateZoneData(this.props.activeList, this.props.objectType);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("zone.editZone"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_ZONES_EDIT)
            );
        }

        return null;
    };

    generateDuplicateModalParams = (): ModalParams | null => {
        const activeData = generateZoneData(this.props.activeList, this.props.objectType, true);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.DUPLICATE_MODAL,
                objectTypes.ZONE_DUPLICATE,
                translate("zone.duplicateZone"),
                activeData[0],
                undefined,
                this.generateBookmarks(modalTypes.MODAL_ZONES_DUPLICATE),
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges,
                undefined,
                undefined
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_ZONES_ADD,
            addModalParams: this.generateAddModalParams(),
            duplicateModalType: modalTypes.MODAL_ZONES_DUPLICATE,
            duplicateModalParams: this.generateDuplicateModalParams(),
            editModalType: modalTypes.MODAL_ZONES_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100 zone">
                <TableContainer
                    className={"table-with-filter zones-settings-table"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generateZoneData(this.props.allList, this.props.objectType)}
                    activeData={this.props.activeList}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
