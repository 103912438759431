import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { MasterRedlikeUserScene } from "../../../components/scenes/master/MasterRedlikeUserScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { User } from "../../../types/user";
import { UserGroup } from "../../../types/userGroup";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    custom: string | null;
    userTableConstant: string;
    userList: Array<User>;
    userActiveList: Array<User>;
    userLoading: boolean;
    userButtonLoading: boolean;
    userColumnOrder: Array<propertyConstants.Property>;
    userColumnVisibility: Record<propertyConstants.Property, boolean>;
    userColumnWidth: Record<propertyConstants.Property, number>;
    userOffset: number;
    userPage: number;
    userRowCount: number;
    userRowCountCustom: boolean;
    userSceneTableSize: Record<string, any> | null;
    userSearch: string | null;
    userSearchParams: Record<propertyConstants.Property, string>;
    userShowFilterRow: boolean;
    userShowGlobalSearch: boolean;
    userSortingAsc: boolean;
    userSortingCriterion: propertyConstants.Property | null;
    userTotalCount: number | null;
    userGroupList: Array<UserGroup>;
    usersWithUserGroupsTableConstant: string;
    usersWithUserGroupsAllList: Array<User>;
    usersWithUserGroupsLoading: boolean;
    usersWithUserGroupsColumnOrder: Array<propertyConstants.Property>;
    usersWithUserGroupsColumnVisibility: Record<propertyConstants.Property, boolean>;
    usersWithUserGroupsColumnWidth: Record<propertyConstants.Property, number>;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_REDLIKE_USERS
    ),
    custom: state.software.custom,
    userTableConstant: menuConstants.TABLE_MASTER_REDLIKE_USERS,
    userList: state.user.masterList,
    userActiveList: state.user.masterActiveList,
    userLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS
    ),
    userButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_MASTER_REDLIKE_USER
    ),
    userColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userOffset: state.user.masterOffset,
    userPage: state.user.masterPage,
    userRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userSearch: state.user.masterSearch,
    userSearchParams: state.user.masterSearchParams,
    userShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_REDLIKE_USERS
    ),
    userSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_USERS
        )
    ),
    userSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_REDLIKE_USERS
        )
    ),
    userTotalCount: state.user.masterCountTotal,
    userGroupList: state.userGroup.masterAllList,
    usersWithUserGroupsTableConstant: menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS,
    usersWithUserGroupsAllList: state.user.allList,
    usersWithUserGroupsLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS
    ),
    usersWithUserGroupsColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS
    ),
    usersWithUserGroupsColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS
    ),
    usersWithUserGroupsColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_REDLIKE_USERS_WITH_USER_GROUPS
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_MASTER_REDLIKE_USERS
    )
});

export const MasterRedlikeUserSceneContainer = connect(mapStateToProps)(MasterRedlikeUserScene);
