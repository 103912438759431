import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Price, mapPrice } from "./price";
import { TableHeading } from "./tableHeading";

export class Colorant {
    id: number;
    name: string;
    nameMaster: string | null;
    nameSystem: string | null;
    r: number | null;
    g: number | null;
    b: number | null;
    rgbHex: string;
    rgbInt: number;
    parentId?: number | null;
    systemId?: number | null;
    zoneId?: number | null;
    nameSource?: number;
    tmcName?: string;
    tmcNameMaster?: string | null;
    tmcNameSystem?: string | null;
    tmcNameSource?: number;
    printName?: string | null;
    printNameMaster?: string | null;
    printNameSystem?: string | null;
    printNameSource?: number;
    code?: string;
    codeMaster?: string | null;
    codeSystem?: string | null;
    codeSource?: number;
    rMaster?: number | null;
    rSystem?: number | null;
    rSource?: number;
    gMaster?: number | null;
    gSystem?: number | null;
    gSource?: number;
    bMaster?: number | null;
    bSystem?: number | null;
    bSource?: number;
    rgbHexMaster?: string | null;
    rgbHexSystem?: string | null;
    rgbHexSource?: number;
    rgbIntMaster?: number | null;
    rgbIntSystem?: number | null;
    rgbIntSource?: number;
    density?: number;
    densityMaster?: number | null;
    densitySystem?: number | null;
    densitySource?: number;
    bfs?: string | null;
    bfsMaster?: string | null;
    bfsSystem?: string | null;
    bfsSource?: number;
    minDosage?: number | null;
    minDosageMaster?: number | null;
    minDosageSystem?: number | null;
    minDosageSource?: number;
    priority?: number | null;
    priorityMaster?: number | null;
    prioritySystem?: number | null;
    prioritySource?: number;
    ufiCode?: string | null;
    ufiCodeMaster?: string | null;
    ufiCodeSystem?: string | null;
    ufiCodeSource?: string;
    moreInfo?: string | null;
    moreInfoMaster?: string | null;
    moreInfoSystem?: string | null;
    moreInfoSource?: number;
    uuid?: string | null;
    // eslint-disable-next-line camelcase
    price_cost_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_pg_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_vat?: Price | null;

    constructor(
        id: number,
        name: string,
        nameMaster: string | null,
        nameSystem: string | null,
        r: number | null,
        g: number | null,
        b: number | null,
        rgbHex: string,
        rgbInt: number,
        parentId?: number | null,
        systemId?: number | null,
        zoneId?: number | null,
        nameSource?: number,
        tmcName?: string,
        tmcNameMaster?: string | null,
        tmcNameSystem?: string | null,
        tmcNameSource?: number,
        printName?: string | null,
        printNameMaster?: string | null,
        printNameSystem?: string | null,
        printNameSource?: number,
        code?: string,
        codeMaster?: string | null,
        codeSystem?: string | null,
        codeSource?: number,
        rMaster?: number | null,
        rSystem?: number | null,
        rSource?: number,
        gMaster?: number | null,
        gSystem?: number | null,
        gSource?: number,
        bMaster?: number | null,
        bSystem?: number | null,
        bSource?: number,
        rgbHexMaster?: string | null,
        rgbHexSystem?: string | null,
        rgbHexSource?: number,
        rgbIntMaster?: number | null,
        rgbIntSystem?: number | null,
        rgbIntSource?: number,
        density?: number,
        densityMaster?: number | null,
        densitySystem?: number | null,
        densitySource?: number,
        bfs?: string | null,
        bfsMaster?: string | null,
        bfsSystem?: string | null,
        bfsSource?: number,
        minDosage?: number | null,
        minDosageMaster?: number | null,
        minDosageSystem?: number | null,
        minDosageSource?: number,
        priority?: number,
        priorityMaster?: number | null,
        prioritySystem?: number | null,
        prioritySource?: number,
        ufiCode?: string | null,
        ufiCodeMaster?: string | null,
        ufiCodeSystem?: string | null,
        ufiCodeSource?: string,
        moreInfo?: string | null,
        moreInfoMaster?: string | null,
        moreInfoSystem?: string | null,
        moreInfoSource?: number,
        uuid?: string | null,
        // eslint-disable-next-line camelcase
        price_cost_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_pg_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_vat?: Price | null
    ) {
        this.id = id;
        this.name = name;
        this.nameMaster = nameMaster;
        this.nameSystem = nameSystem;
        this.r = r;
        this.g = g;
        this.b = b;
        this.rgbHex = rgbHex;
        this.rgbInt = rgbInt;
        this.parentId = parentId;
        this.systemId = systemId;
        this.zoneId = zoneId;
        this.nameSource = nameSource;
        this.tmcName = tmcName;
        this.tmcNameMaster = tmcNameMaster;
        this.tmcNameSystem = tmcNameSystem;
        this.tmcNameSource = tmcNameSource;
        this.printName = printName;
        this.printNameMaster = printNameMaster;
        this.printNameSystem = printNameSystem;
        this.printNameSource = printNameSource;
        this.code = code;
        this.codeMaster = codeMaster;
        this.codeSystem = codeSystem;
        this.codeSource = codeSource;
        this.rMaster = rMaster;
        this.rSystem = rSystem;
        this.rSource = rSource;
        this.gMaster = gMaster;
        this.gSystem = gSystem;
        this.gSource = gSource;
        this.bMaster = bMaster;
        this.bSystem = bSystem;
        this.bSource = bSource;
        this.rgbHexMaster = rgbHexMaster;
        this.rgbHexSystem = rgbHexSystem;
        this.rgbHexSource = rgbHexSource;
        this.rgbIntMaster = rgbIntMaster;
        this.rgbIntSystem = rgbIntSystem;
        this.rgbIntSource = rgbIntSource;
        this.density = density;
        this.densityMaster = densityMaster;
        this.densitySystem = densitySystem;
        this.densitySource = densitySource;
        this.bfs = bfs;
        this.bfsMaster = bfsMaster;
        this.bfsSystem = bfsSystem;
        this.bfsSource = bfsSource;
        this.minDosage = minDosage;
        this.minDosageMaster = minDosageMaster;
        this.minDosageSystem = minDosageSystem;
        this.minDosageSource = minDosageSource;
        this.priority = priority;
        this.priorityMaster = priorityMaster;
        this.prioritySystem = prioritySystem;
        this.prioritySource = prioritySource;
        this.ufiCode = ufiCode;
        this.ufiCodeMaster = ufiCodeMaster;
        this.ufiCodeSystem = ufiCodeSystem;
        this.ufiCodeSource = ufiCodeSource;
        this.moreInfo = moreInfo;
        this.moreInfoMaster = moreInfoMaster;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.uuid = uuid;
        // eslint-disable-next-line camelcase
        this.price_cost_per_liter = price_cost_per_liter;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_liter = price_purchase_per_liter;
        // eslint-disable-next-line camelcase
        this.price_purchase_pg_per_liter = price_purchase_pg_per_liter;
        // eslint-disable-next-line camelcase
        this.price_sell_per_liter = price_sell_per_liter;
        // eslint-disable-next-line camelcase
        this.price_vat = price_vat;
    }
}

export function mapColorant(data: Record<string, any>): Colorant | null {
    try {
        return new Colorant(
            data.id,
            data.name,
            data.nameMaster,
            data.nameSystem,
            data.r,
            data.g,
            data.b,
            data.rgb,
            data.rgbInt,
            data.parentId,
            data.systemId,
            data.zoneId,
            data.nameSource,
            data.tmcName,
            data.tmcNameMaster,
            data.tmcNameSystem,
            data.tmcNameSource,
            data.printName,
            data.printNameMaster,
            data.printNameSystem,
            data.printNameSource,
            data.code,
            data.codeMaster,
            data.codeSystem,
            data.codeSource,
            data.rMaster,
            data.rSystem,
            data.rSource,
            data.gMaster,
            data.gSystem,
            data.gSource,
            data.bMaster,
            data.bSystem,
            data.bSource,
            data.rgbHexMaster,
            data.rgbHexSystem,
            data.rgbHexSource,
            data.rgbIntMaster,
            data.rgbIntSystem,
            data.rgbIntSource,
            data.density,
            data.densityMaster,
            data.densitySystem,
            data.densitySource,
            data.bfs !== undefined && !isNaN(data.bfs) ? data.bfs : undefined,
            data.bfs !== undefined && !isNaN(data.bfsMaster) ? data.bfsMaster : undefined,
            data.bfs !== undefined && !isNaN(data.bfsSystem) ? data.bfsSystem : undefined,
            data.bfsSource,
            data.minDosage,
            data.minDosageMaster,
            data.minDosageSystem,
            data.minDosageSource,
            data.priority,
            data.priorityMaster,
            data.prioritySystem,
            data.prioritySource,
            data.ufiCode,
            data.ufiCodeMaster,
            data.ufiCodeSystem,
            data.ufiCodeSource,
            data.moreInfo,
            data.moreInfoMaster,
            data.moreInfoSystem,
            data.moreInfoSource,
            data.uuid,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_VAT]
                ? mapPrice(propertyConstants.PROPERTY_PRICE_VAT, data.prices[propertyConstants.PROPERTY_PRICE_VAT])
                : undefined
        );
    } catch (e) {
        return null;
    }
}

export function updateColorant(oldColorant: Colorant | null, updatedData: Record<string, any>): Colorant | null {
    try {
        if (oldColorant === null) {
            return null;
        }

        return new Colorant(
            oldColorant.id,
            updatedData.name ? updatedData.name : oldColorant.name,
            oldColorant.nameMaster,
            oldColorant.nameSystem,
            updatedData.r ? updatedData.r : oldColorant.r,
            updatedData.g ? updatedData.g : oldColorant.g,
            updatedData.b ? updatedData.b : oldColorant.b,
            updatedData.rgbHex ? updatedData.rgbHex : oldColorant.rgbHex,
            updatedData.rgbInt ? updatedData.rgbInt : oldColorant.rgbInt,
            oldColorant.parentId,
            oldColorant.systemId,
            oldColorant.zoneId,
            oldColorant.nameSource,
            updatedData.tmcName ? updatedData.tmcName : oldColorant.tmcName,
            oldColorant.tmcNameMaster,
            oldColorant.tmcNameSystem,
            oldColorant.tmcNameSource,
            updatedData.printName ? updatedData.printName : oldColorant.printName,
            oldColorant.printNameMaster,
            oldColorant.printNameSystem,
            oldColorant.printNameSource,
            updatedData.code ? updatedData.code : oldColorant.code,
            oldColorant.codeMaster,
            oldColorant.codeSystem,
            oldColorant.codeSource,
            oldColorant.rMaster,
            oldColorant.rSystem,
            oldColorant.rSource,
            oldColorant.gMaster,
            oldColorant.gSystem,
            oldColorant.gSource,
            oldColorant.bMaster,
            oldColorant.bSystem,
            oldColorant.bSource,
            oldColorant.rgbHexMaster,
            oldColorant.rgbHexSystem,
            oldColorant.rgbHexSource,
            oldColorant.rgbIntMaster,
            oldColorant.rgbIntSystem,
            oldColorant.rgbIntSource,
            updatedData.density ? updatedData.density : oldColorant.density,
            oldColorant.densityMaster,
            oldColorant.densitySystem,
            oldColorant.densitySource,
            updatedData.bfs ? updatedData.bfs : oldColorant.bfs,
            oldColorant.bfsMaster,
            oldColorant.bfsSystem,
            oldColorant.bfsSource,
            updatedData.minDosage ? updatedData.minDosage : oldColorant.minDosage,
            oldColorant.minDosageMaster,
            oldColorant.minDosageSystem,
            oldColorant.minDosageSource,
            updatedData.priority ? updatedData.priority : oldColorant.priority,
            oldColorant.priorityMaster,
            oldColorant.prioritySystem,
            oldColorant.prioritySource,
            updatedData.ufiCode ? updatedData.ufiCode : oldColorant.ufiCode,
            oldColorant.ufiCodeMaster,
            oldColorant.ufiCodeSystem,
            oldColorant.ufiCodeSource,
            updatedData.moreInfo ? updatedData.moreInfo : oldColorant.moreInfo,
            oldColorant.moreInfoMaster,
            oldColorant.moreInfoSystem,
            oldColorant.moreInfoSource,
            updatedData.uuid ? updatedData.uuid : oldColorant.uuid,
            updatedData.price_cost_per_liter ? updatedData.price_cost_per_liter : oldColorant.price_cost_per_liter,
            updatedData.price_purchase_per_liter
                ? updatedData.price_purchase_per_liter
                : oldColorant.price_purchase_per_liter,
            updatedData.price_purchase_pg_per_liter
                ? updatedData.price_purchase_pg_per_liter
                : oldColorant.price_purchase_pg_per_liter,
            updatedData.price_sell_per_liter ? updatedData.price_sell_per_liter : oldColorant.price_sell_per_liter,
            updatedData.price_vat ? updatedData.price_vat : oldColorant.price_vat
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyColorant(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_COLOR_RED]: "",
        [propertyConstants.PROPERTY_COLOR_GREEN]: "",
        [propertyConstants.PROPERTY_COLOR_BLUE]: "",
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: "",
        [propertyConstants.PROPERTY_COLOR_RGB_INT]: "",
        [propertyConstants.PROPERTY_PRINT_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_DENSITY]: "",
        [propertyConstants.PROPERTY_BFS]: "",
        [propertyConstants.PROPERTY_MIN_DOSAGE]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_UFI_CODE]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_VAT]: ""
    };
}

export function generateColorantData(list: Array<Colorant>): Array<any> {
    const data: Array<any> = [];
    let item: Colorant;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_NAME_MASTER]: item.nameMaster,
            [propertyConstants.PROPERTY_NAME_SYSTEM]: item.nameSystem,
            [propertyConstants.PROPERTY_COLOR_RED]: item.r,
            [propertyConstants.PROPERTY_COLOR_GREEN]: item.g,
            [propertyConstants.PROPERTY_COLOR_BLUE]: item.b,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX]: item.rgbHex,
            [propertyConstants.PROPERTY_COLOR_RGB_INT]: item.rgbInt,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_SYSTEM_ID]: item.systemId,
            [propertyConstants.PROPERTY_ZONE_ID]: item.zoneId,
            [propertyConstants.PROPERTY_NAME_SOURCE]: item.nameSource,
            [propertyConstants.PROPERTY_PRINT_NAME]: item.printName,
            [propertyConstants.PROPERTY_PRINT_NAME_MASTER]: item.printNameMaster,
            [propertyConstants.PROPERTY_PRINT_NAME_SYSTEM]: item.printNameSystem,
            [propertyConstants.PROPERTY_PRINT_NAME_SOURCE]: item.printNameSource,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_TMC_NAME_MASTER]: item.tmcNameMaster,
            [propertyConstants.PROPERTY_TMC_NAME_SYSTEM]: item.tmcNameSystem,
            [propertyConstants.PROPERTY_TMC_NAME_SOURCE]: item.tmcNameSource,
            [propertyConstants.PROPERTY_CODE]: item.code,
            [propertyConstants.PROPERTY_CODE_MASTER]: item.codeMaster,
            [propertyConstants.PROPERTY_CODE_SYSTEM]: item.codeSystem,
            [propertyConstants.PROPERTY_CODE_SOURCE]: item.codeSource,
            [propertyConstants.PROPERTY_COLOR_RED_MASTER]: item.rMaster,
            [propertyConstants.PROPERTY_COLOR_RED_SYSTEM]: item.rSystem,
            [propertyConstants.PROPERTY_COLOR_RED_SOURCE]: item.rSource,
            [propertyConstants.PROPERTY_COLOR_GREEN_MASTER]: item.gMaster,
            [propertyConstants.PROPERTY_COLOR_GREEN_SYSTEM]: item.gSystem,
            [propertyConstants.PROPERTY_COLOR_GREEN_SOURCE]: item.gSource,
            [propertyConstants.PROPERTY_COLOR_BLUE_MASTER]: item.bMaster,
            [propertyConstants.PROPERTY_COLOR_BLUE_SYSTEM]: item.bSystem,
            [propertyConstants.PROPERTY_COLOR_BLUE_SOURCE]: item.bSource,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX_MASTER]: item.rgbHexMaster,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX_SYSTEM]: item.rgbHexSystem,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX_SOURCE]: item.rgbHexSource,
            [propertyConstants.PROPERTY_COLOR_RGB_INT_MASTER]: item.rgbIntMaster,
            [propertyConstants.PROPERTY_COLOR_RGB_INT_SYSTEM]: item.rgbIntSystem,
            [propertyConstants.PROPERTY_COLOR_RGB_INT_SOURCE]: item.rgbIntSource,
            [propertyConstants.PROPERTY_DENSITY]: item.density,
            [propertyConstants.PROPERTY_DENSITY_MASTER]: item.densityMaster,
            [propertyConstants.PROPERTY_DENSITY_SYSTEM]: item.densitySystem,
            [propertyConstants.PROPERTY_DENSITY_SOURCE]: item.densitySource,
            [propertyConstants.PROPERTY_BFS]: item.bfs,
            [propertyConstants.PROPERTY_BFS_MASTER]: item.bfsMaster,
            [propertyConstants.PROPERTY_BFS_SYSTEM]: item.bfsSystem,
            [propertyConstants.PROPERTY_BFS_SOURCE]: item.bfsSource,
            [propertyConstants.PROPERTY_MIN_DOSAGE]: item.minDosage,
            [propertyConstants.PROPERTY_MIN_DOSAGE_MASTER]: item.minDosageMaster,
            [propertyConstants.PROPERTY_MIN_DOSAGE_SYSTEM]: item.minDosageSystem,
            [propertyConstants.PROPERTY_MIN_DOSAGE_SOURCE]: item.minDosageSource,
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_PRIORITY_MASTER]: item.priorityMaster,
            [propertyConstants.PROPERTY_PRIORITY_SYSTEM]: item.prioritySystem,
            [propertyConstants.PROPERTY_PRIORITY_SOURCE]: item.prioritySource,
            [propertyConstants.PROPERTY_UFI_CODE]: item.ufiCode,
            [propertyConstants.PROPERTY_UFI_CODE_MASTER]: item.ufiCodeMaster,
            [propertyConstants.PROPERTY_UFI_CODE_SYSTEM]: item.ufiCodeSystem,
            [propertyConstants.PROPERTY_UFI_CODE_SOURCE]: item.ufiCodeSource,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_MASTER]: item.moreInfoMaster,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: item.price_cost_per_liter,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: item.price_purchase_per_liter,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER]: item.price_purchase_pg_per_liter,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: item.price_sell_per_liter,
            [propertyConstants.PROPERTY_PRICE_VAT]: item.price_vat
        });
    }

    return data;
}

// TODO required and alwaysVisible as mandatory
export function generateColorantHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME],
    alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME],
    widthOption: Record<string, number> = {}
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BFS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BFS),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BFS),
            editableList.includes(propertyConstants.PROPERTY_BFS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BFS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BFS),
            requiredList.includes(propertyConstants.PROPERTY_BFS),
            [],
            widthOption?.[propertyConstants.PROPERTY_BFS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_BLUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_BLUE),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            editableList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_GREEN,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_GREEN),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            editableList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_RED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_RED),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_RED),
            editableList.includes(propertyConstants.PROPERTY_COLOR_RED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_RED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_RED),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_RED),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_RED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_RGB_HEX,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_RGB_HEX),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            editableList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_RGB_HEX] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DENSITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DENSITY),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_DENSITY),
            editableList.includes(propertyConstants.PROPERTY_DENSITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DENSITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DENSITY),
            requiredList.includes(propertyConstants.PROPERTY_DENSITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_DENSITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MIN_DOSAGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MIN_DOSAGE),
            tableConstants.TABLE_TYPE_DECIMAL,
            visibleList.includes(propertyConstants.PROPERTY_MIN_DOSAGE),
            editableList.includes(propertyConstants.PROPERTY_MIN_DOSAGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MIN_DOSAGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MIN_DOSAGE),
            requiredList.includes(propertyConstants.PROPERTY_MIN_DOSAGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_MIN_DOSAGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_MASTER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            editableList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_MASTER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            requiredList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_MASTER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER,
            translationHelper.getPropertyTranslation(
                objectType,
                propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER
            ),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_VAT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_VAT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_VAT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRINT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRINT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRINT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_TMC_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            editableList.includes(propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_TMC_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            requiredList.includes(propertyConstants.PROPERTY_TMC_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_TMC_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UFI_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UFI_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UFI_CODE),
            editableList.includes(propertyConstants.PROPERTY_UFI_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UFI_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UFI_CODE),
            requiredList.includes(propertyConstants.PROPERTY_UFI_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_UFI_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
