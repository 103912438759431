import * as generalHelper from "../../../helpers/generalHelper";
import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { ModalLabelInputButtonContainer } from "./ModalLabelInputButton";
import { TableHeading } from "../../../types/tableHeading";
import { t as translate } from "react-i18nify";

type Props = {
    modalId: number;
    objectType: objectTypes.ObjectType;
    properties: Array<propertyConstants.Property>;
    labelProperty: propertyConstants.Property;
    headings: Array<TableHeading>;
    object: any;
    callbackChange: (property: propertyConstants.Property, value: any) => any;
    callbackBlur: (property: propertyConstants.Property, value: any) => any;
    callbackClick: (heading: TableHeading, property: propertyConstants.Property, editable: boolean) => any;
};

export class ModalMultipleInputs extends Component<Props> {
    getLabel = (): JSX.Element => {
        const labelHeading =
            this.props.headings?.find((item) => item[tableConstants.TABLE_HEADING_NAME] === this.props.labelProperty) ||
            null;

        return (
            <div className="form-label">
                <label>
                    {labelHeading?.[tableConstants.TABLE_HEADING_TRANSLATION] || ""}
                    {labelHeading?.[tableConstants.TABLE_HEADING_REQUIRED] ? "*" : ""}
                </label>
            </div>
        );
    };

    getInput = (property: propertyConstants.Property, index: number): JSX.Element | null => {
        const { object } = this.props;
        const heading =
            this.props.headings?.find((item) => item[tableConstants.TABLE_HEADING_NAME] === property) || null;

        // TODO inputClassName, inputType (use ModalItemRow switch values)
        if (heading) {
            const type = heading[tableConstants.TABLE_HEADING_TYPE];
            const editable = heading[tableConstants.TABLE_HEADING_EDITING];
            const required = heading[tableConstants.TABLE_HEADING_REQUIRED];
            const value = object && object[property] !== null && object[property] !== undefined ? object[property] : "";

            switch (type) {
                case tableConstants.TABLE_TYPE_SELECT: {
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={this.props.modalId}
                            className={index > 0 ? "margin-input" : ""}
                            type={type}
                            objectType={this.props.objectType}
                            editable={editable}
                            required={required}
                            labelCaption={""}
                            inputClassName={""}
                            inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                            inputName={property}
                            inputValue={value}
                            inputOptions={generalHelper.getOptionsForSelectbox(
                                this.props.objectType,
                                heading[tableConstants.TABLE_HEADING_ENUM],
                                property
                            )}
                            inputCallbackChange={(value: any) => this.props.callbackChange(property, value)}
                        />
                    );
                }
                case tableConstants.TABLE_TYPE_TEXT: {
                    // docasne
                    if (propertyConstants.FORMATTED_NOTES.includes(property)) {
                        return null;
                    }
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={this.props.modalId}
                            className={index > 0 ? "margin-input input-button-container" : "input-button-container"}
                            type={type}
                            objectType={this.props.objectType}
                            editable={editable}
                            required={required}
                            labelCaption={""}
                            inputClassName={
                                propertyConstants.FORMATTED_NOTES.includes(property)
                                    ? "type-input text-preview"
                                    : "type-input"
                            }
                            inputType={
                                propertyConstants.FORMATTED_NOTES.includes(property)
                                    ? inputConstants.PARAMETER_TYPE_TEXT_INPUT
                                    : inputConstants.PARAMETER_TYPE_TEXT_AREA
                            }
                            inputName={property}
                            inputValue={value}
                            inputCallbackChange={(value: any) => this.props.callbackChange(property, value)}
                            inputCallbackBlur={(value: any) => this.props.callbackBlur(property, value)}
                            buttonCaption={translate("formulaNote.addCode")}
                            buttonEditable={true}
                            buttonClassName={
                                propertyConstants.FORMATTED_NOTES.includes(property) ? "btn-info hidden" : "btn-info"
                            }
                            buttonCallback={(): void => {
                                this.props.callbackClick(heading, property, editable);
                            }}
                        />
                    );
                }
                default:
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={this.props.modalId}
                            className={index > 0 ? "margin-input" : ""}
                            type={type}
                            objectType={this.props.objectType}
                            editable={editable}
                            required={required}
                            labelCaption={""}
                            inputClassName={"type-input"}
                            inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                            inputName={property}
                            inputValue={value}
                            inputCallbackChange={(value: any) => this.props.callbackChange(property, value)}
                            inputCallbackBlur={(value: any) => this.props.callbackBlur(property, value)}
                        />
                    );
            }
        }

        return null;
    };

    generateInputs = (): Array<JSX.Element> => {
        const inputList: Array<JSX.Element> = [];

        this.props.properties.forEach((item: any, index: number) => {
            const input = this.getInput(item, index);

            const label = index === 0 ? this.getLabel() : null;

            if (input) {
                inputList.push(
                    <div key={item} className="row">
                        {label}
                        {input}
                    </div>
                );
            }
        });

        return inputList;
    };

    render(): JSX.Element {
        return <div className="width-100">{this.generateInputs()}</div>;
    }
}
