import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { CompanyTable } from "./tables/CompanyTable";
import { DataItem } from "../../../types/dataItem";
import { PropsType } from "../../../containers/scenes/header/CompanySceneContainer";
import { SceneSaveButtonContainer } from "../../general/scene/buttons/SceneSaveButton";

type Props = PropsType;

export const CompanyScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableCompany, setTableCompanies] = useState<DataItem | null>(
        (props.dataPrivileges
            ? props.dataPrivileges.find((item: DataItem) => item.key === props.companyTableConstant)
            : null) ?? null
    );
    useEffect(() => {
        setTableCompanies(
            (props.dataPrivileges
                ? props.dataPrivileges.find((item: DataItem) => item.key === props.companyTableConstant)
                : null) ?? null
        );
    }, [props.dataPrivileges, props.companyTableConstant]);

    const getCompanyTable = (): JSX.Element | null => {
        return (
            <div className="layout-item" key={props.companyTableConstant}>
                <CompanyTable
                    tableConstant={props.companyTableConstant}
                    objectType={objectTypes.COMPANY}
                    dataPrivileges={tableCompany}
                    loading={props.companyLoading}
                    buttonLoading={props.companyButtonLoading}
                    allList={props.companyList}
                    activeList={props.companyActiveList}
                    columnOrder={props.companyColumnOrder}
                    columnVisibility={props.companyColumnVisibility}
                    columnWidth={props.companyColumnWidth}
                    offset={props.companyOffset}
                    page={props.companyPage}
                    rowCount={props.companyRowCount}
                    rowCountCustom={props.companyRowCountCustom}
                    search={props.companySearch}
                    searchParams={props.companySearchParams}
                    showFilterRow={props.companyShowFilterRow}
                    showGlobalSearch={props.companyShowGlobalSearch}
                    sortingAsc={props.companySortingAsc}
                    sortingCriterion={props.companySortingCriterion}
                    totalCount={props.companyTotalCount}
                />
            </div>
        );
    };

    return (
        <div className="height-100">
            <SceneSaveButtonContainer />
            <div className="content-layout">{getCompanyTable()}</div>
        </div>
    );
};
