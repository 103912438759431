import * as translationHelper from "../helpers/translationHelper";

export class ExportStatus {
    id: number;
    key: string;
    name: string;
    result: boolean;
    finished: boolean;

    constructor(id: number, key: string, result: boolean, finished: boolean) {
        this.id = id;
        this.key = key;
        this.name = translationHelper.getExportStatusTranslation(id);
        this.result = result;
        this.finished = finished;
    }
}

export function mapExportStatus(data: Record<string, any>): ExportStatus | null {
    try {
        return new ExportStatus(data.id, data.name, data.result, data.finished);
    } catch (e) {
        return null;
    }
}
