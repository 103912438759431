import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

import { t as translate } from "react-i18nify";

export class Price {
    id: string;
    key: string;
    name: string;
    currencyId: number | null;
    itemId: number | null;
    itemTypeId: number | null;
    priceTypeId: number | null;
    systemId: number | null;
    zoneId: number | null;
    value: number | null;
    valueSource: number | null;
    inherited: boolean;
    overwrite: boolean;
    priceGroup: number | null;
    validFrom: string | null;
    validTo: string | null;
    canUpdate: boolean;

    constructor(
        id: string,
        key: string,
        name: string,
        currencyId: number | null,
        itemId: number | null,
        itemTypeId: number | null,
        priceTypeId: number | null,
        systemId: number | null,
        zoneId: number | null,
        value: number | null,
        valueSource: number | null,
        inherited: boolean,
        overwrite: boolean,
        priceGroup: number | null,
        validFrom: string | null,
        validTo: string | null,
        canUpdate: boolean
    ) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.currencyId = currencyId;
        this.itemId = itemId;
        this.itemTypeId = itemTypeId;
        this.priceTypeId = priceTypeId;
        this.systemId = systemId;
        this.zoneId = zoneId;
        this.value = value;
        this.valueSource = valueSource;
        this.inherited = inherited;
        this.overwrite = overwrite;
        this.priceGroup = priceGroup;
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.canUpdate = canUpdate;
    }
}

export function mapPrice(key: string, data: any): Price | null {
    try {
        return new Price(
            key,
            key,
            data.priceGroup
                ? translate("prices.priceGroupNumber", { number: data.priceGroup })
                : translationHelper.getPriceGenericTranslation(key),
            data.currencyId,
            data.itemId,
            data.itemTypeId,
            data.priceTypeId,
            data.systemId,
            data.zoneId,
            data.value,
            data.valueSource,
            data.inherited,
            !data.overwrite,
            data.priceGroup,
            data.validFrom,
            data.validTo,
            true
        );
    } catch (e) {
        return null;
    }
}

export function updatePrice(oldPrice: Price | null, updatedData: Record<string, any>): Price | null {
    if (oldPrice === null) {
        return null;
    }

    try {
        return new Price(
            oldPrice.id,
            oldPrice.key,
            oldPrice.name,
            oldPrice.currencyId,
            oldPrice.itemId,
            oldPrice.itemTypeId,
            oldPrice.priceTypeId,
            oldPrice.systemId,
            oldPrice.zoneId,
            updatedData.value ? updatedData.value : oldPrice.value,
            updatedData.valueSource ? updatedData.valueSource : oldPrice.valueSource,
            updatedData.inherited ? updatedData.inherited : oldPrice.inherited,
            updatedData.overwrite ? updatedData.overwrite : oldPrice.overwrite,
            oldPrice.priceGroup,
            oldPrice.validFrom,
            oldPrice.validTo,
            oldPrice.canUpdate
        );
    } catch (e) {
        return null;
    }
}

export function generatePriceData(priceList: Array<Price>): Array<any> {
    const data: Array<any> = [];
    let price: Price;
    let previousPrice: Price | null = null;

    for (price of priceList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: price.id,
            [propertyConstants.PROPERTY_KEY]: price.key,
            [propertyConstants.PROPERTY_NAME]: price.name,
            [propertyConstants.PROPERTY_CURRENCY_ID]: "",
            [propertyConstants.PROPERTY_ITEM_ID]: "",
            [propertyConstants.PROPERTY_VALUE]: price.value,
            [propertyConstants.PROPERTY_OVERWRITE]: price.overwrite,
            [propertyConstants.PROPERTY_RANGE]: [
                previousPrice ? previousPrice[propertyConstants.PROPERTY_VALUE] : null,
                price.value
            ],
            [propertyConstants.PROPERTY_CAN_UPDATE]: price.canUpdate
        });
        previousPrice = price;
    }

    return data;
}

export const generateLastPriceGroup = (lastPriceGroup: Price, priceConstant: string): Price | null => {
    const lastPriceGroupId = (lastPriceGroup.priceGroup || 0) + 1;

    try {
        return new Price(
            `${priceConstant}_${lastPriceGroupId}`,
            `${priceConstant}_${lastPriceGroupId}`,
            translate("prices.priceGroupNumber", { number: lastPriceGroupId }),
            lastPriceGroup.currencyId ?? null,
            lastPriceGroup.itemId ?? null,
            lastPriceGroup.itemTypeId ?? null,
            lastPriceGroup.priceTypeId ?? null,
            lastPriceGroup.systemId ?? null,
            lastPriceGroup.zoneId ?? null,
            null,
            null,
            true,
            false,
            lastPriceGroupId,
            null,
            null,
            false
        );
    } catch (e) {
        return null;
    }
};

export function generatePriceHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_RANGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_RANGE),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_RANGE),
            editableList.includes(propertyConstants.PROPERTY_RANGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_RANGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_RANGE),
            requiredList.includes(propertyConstants.PROPERTY_RANGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_RANGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_VALUE),
            editableList.includes(propertyConstants.PROPERTY_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_VALUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_VALUE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
