import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { Preview } from "../../general/scene/Preview";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericStaticSqlSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { StaticSqlTable } from "./tables/StaticSqlTable";

type Props = PropsType;

type State = {
    tableStaticSql: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericStaticSqlScene extends Component<Props, State> {
    state: State = {
        tableStaticSql:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.staticSqlTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableStaticSql:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.staticSqlTableConstant)
                        : null) || null
            });
        }
    }

    getFilesTable = (): JSX.Element | null => {
        const { tableStaticSql } = this.state;
        const { staticSqlTableConstant } = this.props;

        if (tableStaticSql) {
            return (
                <StaticSqlTable
                    tableConstant={staticSqlTableConstant}
                    objectType={objectTypes.STATIC_SQL}
                    dataPrivileges={tableStaticSql}
                    loading={this.props.staticSqlLoading}
                    buttonLoading={this.props.staticSqlButtonLoading}
                    categoryList={this.props.categoryList}
                    fileTypeList={this.props.fileTypeList}
                    allList={this.props.staticSqlList}
                    activeList={this.props.staticSqlActiveList}
                    columnOrder={this.props.staticSqlColumnOrder}
                    columnVisibility={this.props.staticSqlColumnVisibility}
                    columnWidth={this.props.staticSqlColumnWidth}
                    offset={this.props.staticSqlOffset}
                    page={this.props.staticSqlPage}
                    rowCount={this.props.staticSqlRowCount}
                    rowCountCustom={this.props.staticSqlRowCountCustom}
                    search={this.props.staticSqlSearch}
                    searchParams={this.props.staticSqlSearchParams}
                    showFilterRow={this.props.staticSqlShowFilterRow}
                    showGlobalSearch={this.props.staticSqlShowGlobalSearch}
                    sortingAsc={this.props.staticSqlSortingAsc}
                    sortingCriterion={this.props.staticSqlSortingCriterion}
                    totalCount={this.props.staticSqlTotalCount}
                />
            );
        }

        return null;
    };

    getFilePreview = (): JSX.Element | null => {
        const { staticSqlActiveList } = this.props;
        const { tableStaticSql } = this.state;

        return (
            <Preview
                tableConstant={menuConstants.TABLE_GENERIC_PREVIEW}
                objectType={objectTypes.STATIC_SQL_PREVIEW}
                activeList={staticSqlActiveList}
                fileId={staticSqlActiveList.length ? staticSqlActiveList[0][propertyConstants.PROPERTY_ID] : null}
                dataPrivileges={tableStaticSql}
            />
        );
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getFilesTable(), this.getFilePreview()].filter((item) => item !== null) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.staticSqlSceneTableSize}
                dataPrivileges={this.state.tableStaticSql}
                sceneConstant={menuConstants.PAGE_GENERIC_STATIC_SQL}
            />
        );
    }
}
