import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ExportStatus, mapExportStatus } from "../../types/exportStatus";
import { ExportTask, mapExportTask } from "../../types/exportTask";
import { ExportType, mapExportType } from "../../types/exportType";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_EXPORT_TASK_STATUSES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const exportStatuses: Array<ExportStatus> = [];
            let exportStatus: ExportStatus | null;

            for (const item of response.data.data) {
                exportStatus = mapExportStatus(item);

                if (exportStatus) {
                    exportStatuses.push(exportStatus);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.EXPORT_STATUS, exportStatuses)];
        }
        case methods.METHOD_GET_ALL_EXPORT_TASK_TYPES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const exportTypes: Array<ExportType> = [];
            let exportType: ExportType | null;

            for (const item of response.data.data) {
                exportType = mapExportType(item);

                if (exportType) {
                    exportTypes.push(exportType);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.EXPORT_TYPE, exportTypes)];
        }
        case methods.METHOD_GET_ALL_EXPORT_TASKS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const exportTasks: Array<ExportTask> = [];
            let exportTask: ExportTask | null;

            for (const item of response.data.data) {
                exportTask = mapExportTask(item);

                if (exportTask) {
                    exportTasks.push(exportTask);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.EXPORT_TASK, exportTasks, response.data.count)];
        }
        case methods.METHOD_INSERT_EXPORT_TASK:
        case methods.METHOD_INSERT_EXPORT_TASKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.EXPORT_TASK),
                universalObjectActions.getAllItems(objectTypes.DATABASE_SYSTEM_ZONE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_DATA_EXPORT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_DATA_EXPORT_ALL)
            ];
        }
        case methods.METHOD_DELETE_EXPORT_TASK:
        case methods.METHOD_HIDE_EXPORT_TASK:
        case methods.METHOD_SHOW_EXPORT_TASK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [universalObjectActions.reloadData(objectTypes.EXPORT_TASK)];
        }
        case methods.METHOD_DELETE_EXPORT_TASKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];

            if (state.navigation.activeScene === navigationTypes.SERVER_MONITORING_SCENE) {
                requests.push(universalObjectActions.getAllItems(objectTypes.DATA_SIZE));
            }

            return [
                ...requests,
                universalObjectActions.reloadData(objectTypes.EXPORT_TASK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_EXPORT_TASKS_DELETE)
            ];
        }
        case methods.METHOD_ABORT_EXPORT_TASK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
