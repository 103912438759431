import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import React, { Component } from "react";
import { ModalCheckbox } from "./ModalCheckbox";
import { ReactSVG } from "react-svg";

import imgInfo from "../../../resources/img/icons/info.svg";
import noImage from "../../../resources/img/icons/no-image.svg";

import { t as translate } from "react-i18nify";

type Props = {
    className: string;
    objectType: objectTypes.ObjectType;
    type: tableConstants.TableType;
    value: any;
    property: propertyConstants.Property;
    valueSource?: number | null;
    unit?: string;
    src?: string | null;
};

export class ModalReadOnlyInput extends Component<Props> {
    getReadOnlyContent = (): JSX.Element | null => {
        const { objectType, value, type, valueSource } = this.props;

        switch (type) {
            case tableConstants.TABLE_TYPE_IMAGE_SECTION: {
                if (this.props.value && this.props.src) {
                    return (
                        <div id="images">
                            <div className="image-container">
                                <img key="file-image" alt="loaded file" src={this.props.src} />
                            </div>
                        </div>
                    );
                }
                return (
                    <div id="images">
                        <div className="no-image-info-container type-input">
                            <ReactSVG src={noImage} className="svg-icon" />
                            <span>{translate("image.noImageAvailable")}</span>
                        </div>
                    </div>
                );
            }
            case tableConstants.TABLE_TYPE_PRICE: {
                if (valueSource) {
                    if (value) {
                        return (
                            <div className="readonly-message">
                                <ReactSVG src={imgInfo} className="svg-icon" />
                                <span>
                                    {objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType)
                                        ? translationHelper.getSystemPriceInheritanceTranslation(valueSource)
                                        : translationHelper.getZonePriceInheritanceTranslation(valueSource)}
                                </span>
                            </div>
                        );
                    }
                    return (
                        <div className="readonly-message">
                            <ReactSVG src={imgInfo} className="svg-icon" />
                            <span>{translate("prices.priceSourceCustomValue")}</span>
                        </div>
                    );
                }

                return null;
            }
            case tableConstants.TABLE_TYPE_TEXT: {
                return (
                    <div className="type-input">
                        <textarea className="slim-scroll" key={type} value={value || ""} readOnly={true} />
                    </div>
                );
            }
            case tableConstants.TABLE_TYPE_ALERT: {
                return (
                    <React.Fragment>
                        <ReactSVG src={imgInfo} className="svg-icon" />
                        <span>{this.props.value}</span>
                    </React.Fragment>
                );
            }
            case tableConstants.TABLE_TYPE_BOOLEAN: {
                return (
                    <ModalCheckbox
                        className={this.getClassName()}
                        editable={false}
                        required={false}
                        value={this.props.value ?? false}
                        text={
                            translationHelper.getInputCaptionTranslation(this.props.type, this.props.property) ||
                            translationHelper.getPropertyTranslation(this.props.objectType, this.props.property)
                        }
                    />
                );
            }
            case tableConstants.TABLE_TYPE_PERCENT:
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_NUMBER:
            case tableConstants.TABLE_TYPE_STRING:
            default:
                return (
                    <div className={this.getClassName()} key={type}>
                        {this.getRgbPreview()}
                        <input value={value || ""} readOnly={true} />
                        {this.getUnit()}
                    </div>
                );
        }
    };

    getClassName = (): string => {
        let className = "type-input";

        if (this.props.unit) {
            className += " units-input";
        }

        if (this.props.property && propertyHelper.isParentPropertyColorHex(this.props.property)) {
            className += " preview-input";
        }

        return className;
    };

    getUnit = (): JSX.Element | null => {
        const { unit } = this.props;

        if (unit) {
            return <span className="unit-input">{unit}</span>;
        }

        return null;
    };

    getRgbPreview = (): JSX.Element | null => {
        if (this.props.property && propertyHelper.isParentPropertyColorHex(this.props.property)) {
            const color = this.props.value ? this.props.value.toString() : null;
            if (color) {
                return (
                    <div
                        className="color-square"
                        style={{
                            backgroundColor: color
                        }}
                    ></div>
                );
            }

            return <div className="color-square no-color"></div>;
        }

        return null;
    };

    render(): JSX.Element {
        const { className } = this.props;

        return <div className={className}>{this.getReadOnlyContent()}</div>;
    }
}
