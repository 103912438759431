import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import {
    generateUserGroupPrivilegeData,
    generateUserGroupPrivilegeHeadings
} from "../../../../types/userGroupPrivilege";

import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";
import { User } from "../../../../types/user";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loggedUser: User | null;
};

export class UserGroupPrivilegeTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const columnOrder: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS
        ];
        const columnVisibility: Record<propertyConstants.Property, boolean> = {
            [propertyConstants.PROPERTY_NAME]: true,
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS]: true,
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS]: true,
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS]: true,
            [propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS]: true
        } as Record<propertyConstants.Property, boolean>;

        const requiredList: Array<propertyConstants.Property> = [];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_KEY];
        const editableList: Array<propertyConstants.Property> = [];
        let visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS,
            propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS
        ];

        if (privilegesHelper.isSuperadmin(this.props.loggedUser)) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS);
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, columnVisibility);
        }

        return generateUserGroupPrivilegeHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={false}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generateUserGroupPrivilegeData(this.props.loggedUser)}
                    activeData={[]}
                    disableColumnResizing={true}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={tableConstants.DEFAULT_ROW_COUNT}
                    search={tableConstants.DEFAULT_SEARCH}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={tableConstants.DEFAULT_SORTING_ASC}
                    sortingCriterion={null}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    modalProperties={{}}
                />
            </div>
        );
    }
}
