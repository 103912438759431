import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Package, mapPackage } from "../../types/package";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_PACKAGES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<Package> = [];
            let newPackage: Package | null;

            for (const item of response.data.data) {
                newPackage = mapPackage(item);

                if (newPackage) {
                    packageList.push(newPackage);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.PACKAGE, packageList, response.data.count)];
        }
        case methods.METHOD_ADD_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.PACKAGE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PACKAGES_ADD)
            ];
        }
        case methods.METHOD_EDIT_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.PACKAGE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PACKAGES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PACKAGES_EDIT));
            } else {
                const activePackage = mapPackage(response.data);

                if (activePackage) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.PACKAGE, [activePackage]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.PACKAGE),
                universalObjectActions.setActiveItems(objectTypes.PACKAGE, []),
                navigationActions.navigationCloseModal(modalTypes.MODAL_PACKAGES_EDIT)
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_PACKAGES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<Package> = [];
            let newPackage: Package | null;

            for (const item of response.data.data) {
                newPackage = mapPackage(item);

                if (newPackage) {
                    packageList.push(newPackage);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_PACKAGE, packageList, response.data.count)];
        }
        case methods.METHOD_EDIT_SYSTEM_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PACKAGES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PACKAGES_EDIT));
            } else {
                const systemPackage = mapPackage(response.data);

                if (systemPackage) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_PACKAGE, [systemPackage]));
                }
            }

            return requests;
        }
        case methods.METHOD_GET_ALL_ZONE_PACKAGES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const packageList: Array<Package> = [];
            let newPackage: Package | null;

            for (const item of response.data.data) {
                newPackage = mapPackage(item);

                if (newPackage) {
                    packageList.push(newPackage);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.ZONE_PACKAGE, packageList, response.data.count)];
        }
        case methods.METHOD_EDIT_ZONE_PACKAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_PACKAGES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_PACKAGE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_PACKAGES_EDIT));
            } else {
                const zonePackage = mapPackage(response.data);

                if (zonePackage) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_PACKAGE, [zonePackage]));
                }
            }

            return requests;
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
