import * as generalHelper from "../helpers/generalHelper";

import { DispatchType, PropsType } from "../containers/Welcome";
import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { translate } from "react-i18nify";

type Props = PropsType & DispatchType;

export class Welcome extends Component<Props> {
    render(): JSX.Element {
        return (
            <div className="bg-screen initial-scenes">
                <div className="bg-container md-window server-window">
                    <div className="lab-logo">
                        <ReactSVG className="logo-icon" src={generalHelper.getSoftwareLogo(this.props.custom)} />
                    </div>
                    <div className="select-server-text">
                        <span>{translate("server.noServerConnection")}</span>
                    </div>
                    <div className="btn-row">
                        <button
                            className="btn btn-info btn-md"
                            onClick={() => {
                                this.props.reconnect(this.props.url);
                            }}
                        >
                            <span>{translate("server.reconnect")}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
