import { TableHeading } from "../types/tableHeading";

export class Bookmark {
    key: string;
    type: string;
    title: string;
    properties: Array<TableHeading>;
    dataPrivileges: any;
    loading: boolean;
    content?: JSX.Element | null;

    constructor(
        key: string,
        type: string,
        title: string,
        properties: Array<TableHeading>,
        dataPrivileges: any,
        loading: boolean,
        content: JSX.Element | null = null
    ) {
        this.key = key;
        this.type = type;
        this.title = title;
        this.properties = properties;
        this.dataPrivileges = dataPrivileges;
        this.loading = loading;
        this.content = content;
    }
}

export function updateBookmarkContent(bookmark: Bookmark, content: JSX.Element | null): Bookmark | null {
    try {
        return new Bookmark(
            bookmark.key,
            bookmark.type,
            bookmark.title,
            bookmark.properties,
            bookmark.dataPrivileges,
            bookmark.loading,
            content
        );
    } catch (e) {
        return null;
    }
}
