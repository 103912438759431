import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import {
    UserGroup,
    createEmptyUserGroup,
    generateUserGroupData,
    generateUserGroupHeadings
} from "../../../../types/userGroup";

import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<UserGroup>;
    activeList: Array<UserGroup>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    custom: string | null;
};

export class MasterRedlikeUserGroupTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PRIORITY
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PRIORITY
        ];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];
        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateUserGroupHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            this.props.objectType,
            translate("user.addUserGroup"),
            createEmptyUserGroup(),
            this.generateTableHeadings(modalTypes.MODAL_REDLIKE_USER_GROUPS_ADD)
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateUserGroupData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("user.editUserGroup"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_REDLIKE_USER_GROUPS_EDIT)
            );
        }

        return null;
    };

    generateDuplicateModalParams = (): ModalParams | null => {
        const activeData = generateUserGroupData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.DUPLICATE_MODAL,
                objectTypes.MASTER_REDLIKE_USER_GROUP_DUPLICATE,
                translate("user.duplicateUserGroup", {
                    name: activeData[0][propertyConstants.PROPERTY_NAME]
                }),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_REDLIKE_USER_GROUPS_DUPLICATE)
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_REDLIKE_USER_GROUPS_ADD,
            addModalParams: this.generateAddModalParams(),
            duplicateModalType: modalTypes.MODAL_REDLIKE_USER_GROUPS_DUPLICATE,
            duplicateModalParams: this.generateDuplicateModalParams(),
            editModalType: modalTypes.MODAL_REDLIKE_USER_GROUPS_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100 panel-table">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(
                        objectTypes.MASTER_REDLIKE_USER_PRIVILEGES,
                        this.props.custom
                    )}
                    titleClassName={"main-title"}
                    headings={this.generateTableHeadings()}
                    data={generateUserGroupData(this.props.allList)}
                    activeData={this.props.activeList}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={this.props.allList.length}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={true}
                    showGlobalSearch={false}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
