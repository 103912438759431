import * as colorHelper from "../helpers/colorHelper";
import * as dataTypeConstants from "../constants/dataTypeConstants";
import * as defaultConstants from "../constants/default";
import * as formattingHelper from "../helpers/formattingHelper";
import * as generalHelper from "../helpers/generalHelper";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as localConfig from "../constants/localConfig";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as serverConstants from "../constants/serverConstants";

import { Barcode, createBarcode } from "../types/barcode";

import { DataType } from "../types/duplication/dataType";
import { ExportStatus } from "../types/exportStatus";
import { ImportStatus } from "../types/importStatus";
import { Price } from "../types/price";
import { UserGroup } from "../types/userGroup";

import sha from "sha256";

// TODO check while file

export function getRestServerFetchInit(params: Record<string, any>): any {
    return {
        body: JSON.stringify(params),
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": `${localConfig.CLIENT_PROTOCOL}://${localConfig.CLIENT_HOST}`,
            "Content-Type": "application/json"
        },
        method: "POST"
    };
}

/*
export function getRestServerFetchQuery(params: any): string {
    return Object.keys(params)
        .map((key) => {
            let queryParamValue = params[key];

            if (Array.isArray(params[key])) {
                queryParamValue = JSON.stringify(params[key]);
            } else if (typeof params[key] === "object" && params[key] !== null) {
                queryParamValue = JSON.stringify(params[key]);
            }

            return `${key}=${queryParamValue}`;
        })
        .join("&");
}
*/

// TODO instead of string is can be propertyConstants.Property with new definitions
// TODO specify return type
export function getObjectIdProperty(objectType: objectTypes.ObjectType): any {
    switch (objectType) {
        case objectTypes.MASTER_BASE:
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_MASTER_BASE:
        case objectTypes.SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
        case objectTypes.ZONE_SYSTEM_BASE:
        case objectTypes.ZONE_BASE: {
            return "baseId";
        }
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            return "baseInProductId";
        }
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            return "baseInProductPackageId";
        }
        case objectTypes.MASTER_COLORANT_BATCH: {
            return "batchId";
        }
        case objectTypes.MASTER_COLOR:
        case objectTypes.MASTER_COLOR_DATA: {
            return "colorInFandeckId";
        }
        case objectTypes.MASTER_COLORANT:
        case objectTypes.MASTER_SYSTEM_COLORANT:
        case objectTypes.SYSTEM_MASTER_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
        case objectTypes.ZONE_SYSTEM_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            return "colorantId";
        }
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
        case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            return "colorantPackageId";
        }
        case objectTypes.COMPANY: {
            return "companyId";
        }
        case objectTypes.CURRENCY: {
            return "currencyId";
        }
        case objectTypes.DATABASE: {
            return "databaseId";
        }
        case objectTypes.EXPORT_TASK: {
            return "exportTaskId";
        }
        case objectTypes.EXPORT_TASK_DELETE: {
            return "exportTaskIds";
        }
        case objectTypes.MASTER_FANDECK:
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_MASTER_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
        case objectTypes.ZONE_SYSTEM_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            return "fandeckId";
        }
        case objectTypes.FILE:
        case objectTypes.PRODUCT_FILE:
        case objectTypes.PRODUCT_SHEET:
        case objectTypes.STATIC_SQL: {
            return "fileId";
        }
        case objectTypes.FILE_CATEGORY: {
            return "fileCategoryId";
        }
        case objectTypes.MASTER_FORMULA:
        case objectTypes.SYSTEM_FORMULA:
        case objectTypes.ZONE_FORMULA: {
            return "formulaId";
        }
        case objectTypes.MASTER_FORMULA_NOTE:
        case objectTypes.SYSTEM_FORMULA_NOTE:
        case objectTypes.ZONE_FORMULA_NOTE: {
            return "formulaNoteId";
        }
        case objectTypes.GENERIC_IMAGE:
        case objectTypes.IMAGE: {
            return "imageId";
        }
        case objectTypes.IMAGE_CATEGORY: {
            return "imageCategoryId";
        }
        case objectTypes.IMPORT_TASK: {
            return "importTaskId";
        }
        case objectTypes.IMPORT_TASK_DELETE: {
            return "importTaskIds";
        }
        case objectTypes.MASTER_REDLIKE_OPTION:
        case objectTypes.MASTER_REDLIKE_PRIVILEGE:
        case objectTypes.SYSTEM_PRICE_GENERIC:
        case objectTypes.SYSTEM_REDLIKE_OPTION:
        case objectTypes.ZONE_PRICE_GENERIC:
        case objectTypes.ZONE_REDLIKE_OPTION: {
            return "key";
        }
        case objectTypes.SYSTEM_PRICE_MARGIN:
        case objectTypes.ZONE_PRICE_MARGIN: {
            return "marginId";
        }
        case objectTypes.PACKAGE:
        case objectTypes.SYSTEM_PACKAGE:
        case objectTypes.ZONE_PACKAGE: {
            return "packageId";
        }
        case objectTypes.SYSTEM_PRICE_GROUP:
        case objectTypes.ZONE_PRICE_GROUP: {
            return "priceGroupId";
        }
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.MASTER_PRODUCT_DUPLICATE:
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.SYSTEM_MASTER_PRODUCT:
        case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT_FOR_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.ZONE_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
        case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.ZONE_PRODUCT:
        case objectTypes.ZONE_PRODUCT_DUPLICATE: {
            return "productId";
        }
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP:
        case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.SYSTEM_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
        case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.ZONE_PRODUCT_GROUP: {
            return "productGroupId";
        }
        case objectTypes.SPECTRO_TYPE: {
            return "spectroTypeId";
        }
        case objectTypes.SYSTEM: {
            return "systemId";
        }
        case objectTypes.CHANGELOG:
        case objectTypes.SYSTEM_ZONE:
        case objectTypes.VERSION: {
            return "id";
        }
        case objectTypes.UNIT: {
            return "unitId";
        }
        case objectTypes.USER_GROUP:
        case objectTypes.MASTER_REDLIKE_USER_GROUP: {
            return "userGroupId";
        }
        case objectTypes.MASTER_REDLIKE_USER:
        case objectTypes.SYSTEM_REDLIKE_USER:
        case objectTypes.USER:
        case objectTypes.ZONE_REDLIKE_USER: {
            return "userId";
        }
        case objectTypes.MASTER_COLOR_SPECTRO_TYPE: {
            return "wavelengthId";
        }
        case objectTypes.ZONE:
        case objectTypes.ZONE_DUPLICATE: {
            return "zoneId";
        }
        default:
            return null;
    }
}

export function getObjectForServer(
    objectType: objectTypes.ObjectType,
    actionType: modalTypes.ModalFunctionType,
    item: any,
    additionalParams: Record<string, any>,
    returnProperties: Array<string> = []
): any | null {
    let data: any;
    let updatedData: Record<string, any> = {};

    if (item === null) {
        return null;
    }

    switch (objectType) {
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_FILES: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] ? generalHelper.getFilesObject(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" && item[property] !== -1 ? item[property] : null
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_VAT: {
                            if (updatedData[propertyConstants.PROPERTY_PRICES] === undefined) {
                                updatedData = {
                                    ...updatedData,
                                    [propertyConstants.PROPERTY_PRICES]: getPricesListForServer([
                                        item?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                                    ])
                                };
                            }
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: {
                        ...updatedData,
                        [propertyConstants.PROPERTY_PRODUCT_ID]: item[propertyConstants.PROPERTY_PRODUCT_ID]
                    }
                };
            }
            break;
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_BARCODES:
                        case propertyConstants.PROPERTY_ALTERNATIVE_BARCODES: {
                            updatedData = {
                                ...updatedData,
                                [propertyConstants.PROPERTY_BARCODES]: item[property]
                                    ? generalHelper.getBarcodesForServer(item.barcodes, item.alternativeBarcodes)
                                    : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_BASE_IN_PRODUCT_PACKAGE_ID: {
                            updatedData = {
                                ...updatedData,
                                [propertyConstants.PROPERTY_PACKAGE_ID]: item[property] !== "" ? item[property] : null
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_FILES: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] ? generalHelper.getFilesObject(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" && item[property] !== -1 ? item[property] : null
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_PRICE_COST:
                        case propertyConstants.PROPERTY_PRICE_PURCHASE:
                        case propertyConstants.PROPERTY_PRICE_SELL:
                        case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                        case propertyConstants.PROPERTY_PRICE_VAT:
                        case propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST: {
                            if (updatedData[propertyConstants.PROPERTY_PRICES] === undefined) {
                                updatedData = {
                                    ...updatedData,
                                    [propertyConstants.PROPERTY_PRICES]: getPricesListForServer(
                                        [
                                            item?.[propertyConstants.PROPERTY_PRICE_COST] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_PURCHASE] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_SELL] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_SURCHARGE] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                                        ].concat(item?.[propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST] ?? [])
                                    )
                                };
                            }
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.MASTER_COLOR: {
            const colorBlue = item.b !== "" ? item.b : null;
            const colorGreen = item.g !== "" ? item.g : null;
            const colorRed = item.r !== "" ? item.r : null;
            let jsonAttributes = {};

            if (item.largoName !== "") {
                jsonAttributes = {
                    largoName: item.largoName
                };
            }

            data = {
                data: {
                    barcodes: item.barcodes ? generalHelper.getBarcodesForServer(item.barcodes) : undefined,
                    colorCode: item.colorCode !== "" ? item.colorCode : null,
                    colorJsonAttributes: jsonAttributes,
                    colorMoreInfo: item.colorMoreInfo !== "" ? item.colorMoreInfo : null,
                    colorName: item.colorName !== "" ? item.colorName : null,
                    colorPriority: item.colorPriority !== "" ? item.colorPriority : null,
                    colorSearchCode: item.colorSearchCode !== "" ? item.colorSearchCode : null,
                    colorSearchName: item.colorSearchName !== "" ? item.colorSearchName : null,
                    colorTmcName: item.tmcName !== "" ? item.tmcName : null,
                    fandeckId: item.fandeckId !== "" ? item.fandeckId : null,
                    imageId: item.imageId !== "" ? item.imageId : null,
                    page: item.fandeckPage !== "" ? item.fandeckPage : null,
                    pagePosition: item.fandeckPosition !== "" ? item.fandeckPosition : null,
                    rgb: formattingHelper.convertRgbToHex(colorRed, colorGreen, colorBlue),
                    spectralData: generalHelper.getColorDataForServer(item.data),
                    uuid: item.uuid !== "" ? item.uuid : null
                }
            };
            break;
        }
        case objectTypes.MASTER_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_COLOR_BLUE:
                        case propertyConstants.PROPERTY_COLOR_GREEN:
                        case propertyConstants.PROPERTY_COLOR_RED: {
                            if (updatedData[propertyConstants.PROPERTY_COLOR_RGB] === undefined) {
                                const colorBlue = item.b !== "" ? item.b : null;
                                const colorGreen = item.g !== "" ? item.g : null;
                                const colorRed = item.r !== "" ? item.r : null;

                                updatedData = {
                                    ...updatedData,
                                    [propertyConstants.PROPERTY_COLOR_RGB]: formattingHelper.convertRgbToHex(
                                        colorRed,
                                        colorGreen,
                                        colorBlue
                                    )
                                };
                            }
                            break;
                        }
                        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_VAT: {
                            if (updatedData[propertyConstants.PROPERTY_PRICES] === undefined) {
                                updatedData = {
                                    ...updatedData,
                                    [propertyConstants.PROPERTY_PRICES]: getPricesListForServer([
                                        item?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_SURCHARGE] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null,
                                        item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                                    ])
                                };
                            }
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.MASTER_COLORANT_BATCH: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_PRODUCTION_DATE: {
                            updatedData = {
                                ...updatedData,
                                [property]:
                                    item[property] !== ""
                                        ? formattingHelper.formatDateTimeForServer(new Date(item[property]))
                                        : null
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: {
                        ...updatedData,
                        [propertyConstants.PROPERTY_COLORANT_ID]:
                            item[propertyConstants.PROPERTY_ITEM_ID] !== ""
                                ? item[propertyConstants.PROPERTY_ITEM_ID]
                                : null
                    }
                };
            }
            break;
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_BARCODES: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property]
                                    ? generalHelper.getBarcodesForServer(item[property])
                                    : undefined
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.CHANGELOG: {
            data = {
                data: {
                    changelog: item.changelog !== "" ? item.changelog : null
                }
            };
            break;
        }
        case objectTypes.DATABASE: {
            data = {
                data: {
                    addCurrency: item.addCurrency === "true",
                    companyId: item.companyId !== "" ? item.companyId : null,
                    fileUuid: item.file ? item.file.uuid : null,
                    masterOnly: item.masterOnly === "true",
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    password: item.password !== "" ? item.password : null,
                    uuid: item.uuid !== "" ? item.uuid : null
                }
            };
            break;
        }
        case objectTypes.EXPORT_TASK: {
            const exportTypeOptions =
                additionalParams?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE]?.[
                    propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS
                ] ?? null;

            data = {
                data: {
                    changelog:
                        item.changelog !== "" && exportTypeOptions?.[propertyConstants.PROPERTY_CHANGELOG]
                            ? item.changelog
                            : null,
                    colorsWithoutFormulas:
                        item.colorsWithoutFormulas !== "" &&
                        exportTypeOptions?.[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]
                            ? !item.colorsWithoutFormulas
                            : null,
                    exportTaskTypeId: item.exportTaskTypeId !== "" ? item.exportTaskTypeId : null,
                    systemId: exportTypeOptions?.[propertyConstants.PROPERTY_SYSTEM_ID]
                        ? item.systemId !== ""
                            ? item.systemId
                            : null
                        : defaultConstants.DEFAULT_EXPORT_SYSTEM_ID,
                    version: exportTypeOptions?.[propertyConstants.PROPERTY_VERSION]
                        ? item.version !== ""
                            ? item.version
                            : null
                        : importExportConstants.EXPORT_DEFAULT_VERSION,
                    versionName: exportTypeOptions?.[propertyConstants.PROPERTY_VERSION_NAME]
                        ? item.versionName !== ""
                            ? item.versionName
                            : null
                        : importExportConstants.EXPORT_DEFAULT_VERSION_NAME,
                    zoneId: exportTypeOptions?.[propertyConstants.PROPERTY_ZONE_ID]
                        ? item.zoneId !== ""
                            ? item.zoneId
                            : null
                        : defaultConstants.DEFAULT_EXPORT_ZONE_ID,
                    zonePassword: exportTypeOptions?.[propertyConstants.PROPERTY_ZONE_PASSWORD]
                        ? item.zonePassword !== ""
                            ? item.zonePassword
                            : null
                        : defaultConstants.DEFAULT_EXPORT_ZONE_PASSWORD,
                    zoneUuid: exportTypeOptions?.[propertyConstants.PROPERTY_ZONE_ID]
                        ? item.zoneUuid !== ""
                            ? item.zoneUuid
                            : null
                        : defaultConstants.DEFAULT_EXPORT_ZONE_UUID
                }
            };
            break;
        }
        case objectTypes.EXPORT_TASK_DELETE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_STATUS_LIST: {
                            updatedData = {
                                ...updatedData,
                                exportTaskStatusIds: item.statusList
                                    .filter(
                                        (status: ExportStatus) =>
                                            status[propertyConstants.PROPERTY_ID] !==
                                            importExportConstants.EXPORT_STATUS_ALL
                                    )
                                    .map((status: ExportStatus) => status[propertyConstants.PROPERTY_ID])
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_DATE_FROM: {
                            const date = new Date(item[property]).setHours(0, 0, 0);

                            updatedData = {
                                ...updatedData,
                                [property]:
                                    item[property] !== ""
                                        ? formattingHelper.formatDateTimeForServer(new Date(date))
                                        : null
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_DATE_TO: {
                            const date = new Date(item[property]).setHours(23, 59, 59);

                            updatedData = {
                                ...updatedData,
                                [property]:
                                    item[property] !== ""
                                        ? formattingHelper.formatDateTimeForServer(new Date(date))
                                        : null
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }
            }

            data = updatedData;
            break;
        }
        case objectTypes.MASTER_FANDECK:
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_BARCODES: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property]
                                    ? generalHelper.getBarcodesForServer(item[property])
                                    : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_LARGO_NAME: {
                            const jsonAttributes = {
                                largoName: item.largoName
                            };

                            updatedData = {
                                ...updatedData,
                                jsonAttributes: jsonAttributes
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.FILE:
        case objectTypes.PRODUCT_SHEET:
        case objectTypes.STATIC_SQL: {
            data = {
                data: {
                    fileCategoryId: item.fileCategoryId !== "" ? item.fileCategoryId : null,
                    fileTypeId: item.fileTypeId !== "" ? item.fileTypeId : null,
                    fileUuid: item.file !== null ? item.file.uuid : null,
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    url: item.url !== "" ? item.url : null
                }
            };
            break;
        }
        case objectTypes.MASTER_FORMULA:
        case objectTypes.SYSTEM_FORMULA:
        case objectTypes.ZONE_FORMULA: {
            let colorants = {};
            for (const colorant of item.colorantList || []) {
                if (colorant[propertyConstants.PROPERTY_AMOUNT]) {
                    colorants = {
                        ...colorants,
                        [colorant[propertyConstants.PROPERTY_ID]]: colorant[propertyConstants.PROPERTY_AMOUNT]
                    };
                }
            }

            const verified = item.verified !== "" ? item.verified : false;
            const enabled = item.enabled !== "" ? item.enabled : false;
            const rejected = item.rejected !== "" ? item.rejected : false;
            const forNominalAmount = item.forNominalAmount !== "" ? item.forNominalAmount : false;
            const notAvailable = item.notAvailable !== "" ? item.notAvailable : false;

            data = {
                priority: item.priority !== "" ? item.priority : null,
                updateOnly: item.updateOnly !== "" ? item.updateOnly : null,
                data: {
                    baseAmount: item.baseAmount !== "" ? item.baseAmount : null,
                    baseInProductId: item.baseInProductId !== "" ? item.baseInProductId : null,
                    colorInFandeckId: item.colorInFandeckId !== "" ? item.colorInFandeckId : null,
                    colorants: colorants,
                    formulaTypeId: colorHelper.getFormulaTypeId(
                        verified,
                        enabled,
                        rejected,
                        forNominalAmount,
                        notAvailable
                    ),
                    isGravimetric: item.isGravimetric === "true",
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    moreInfoInternal: item.moreInfoInternal !== "" ? item.moreInfoInternal : null,
                    moreInfoPrint: item.moreInfoPrint !== "" ? item.moreInfoPrint : null,
                    name: item.name !== "" ? item.name : null,
                    uuid: item.uuid !== "" ? item.uuid : null
                }
            };

            break;
        }
        case objectTypes.ICON:
        case objectTypes.GENERIC_IMAGE:
        case objectTypes.IMAGE: {
            data = {
                imageId:
                    item[propertyConstants.PROPERTY_IMAGE_ID] !== "" ? item[propertyConstants.PROPERTY_IMAGE_ID] : null,
                data: {
                    fileTypeId: item.fileTypeId !== "" ? item.fileTypeId : null,
                    imageCategoryId: item.imageCategoryId !== "" ? item.imageCategoryId : null,
                    name: item.name !== "" ? item.name : null,
                    src: item.src !== "" ? item.src : null,
                    url: item.url !== "" ? item.url : null
                }
            };
            break;
        }
        case objectTypes.IMPORT_TASK: {
            const optionList: any = {};
            const fileList: any = {};

            for (const option of item.options ?? []) {
                if (option[propertyConstants.PROPERTY_VALUE] !== null) {
                    optionList[option[propertyConstants.PROPERTY_KEY]] = option[propertyConstants.PROPERTY_VALUE];
                }
            }
            for (const file of item.files ?? []) {
                if (file.files) {
                    fileList[file[propertyConstants.PROPERTY_KEY]] = file[propertyConstants.PROPERTY_FILES];
                }
            }

            data = {
                data: {
                    databaseId: item.databaseId !== "" ? item.databaseId : null,
                    files: fileList,
                    importTaskTypeId: item.importTaskTypeId !== "" ? item.importTaskTypeId : null,
                    keepOldFormulas:
                        item.keepOldFormulas !== ""
                            ? item.keepOldFormulas
                            : importExportConstants.DEFAULT_KEEP_OLD_FORMULAS,
                    options: optionList,
                    strict: item.strict !== false,
                    systemId: item.systemId !== "" ? item.systemId : null,
                    zoneId: item.zoneId !== "" ? item.zoneId : null
                }
            };
            break;
        }
        case objectTypes.IMPORT_TASK_DELETE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_STATUS_LIST: {
                            updatedData = {
                                ...updatedData,
                                importTaskStatusIds: item.statusList
                                    .filter(
                                        (status: ImportStatus) =>
                                            status[propertyConstants.PROPERTY_KEY] !==
                                            importExportConstants.IMPORT_STATUS_ALL
                                    )
                                    .map((status: ImportStatus) => status[propertyConstants.PROPERTY_ID])
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_DATE_FROM: {
                            const date = new Date(item[property]).setHours(0, 0, 0);

                            updatedData = {
                                ...updatedData,
                                [property]:
                                    item[property] !== ""
                                        ? formattingHelper.formatDateTimeForServer(new Date(date))
                                        : null
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_DATE_TO: {
                            const date = new Date(item[property]).setHours(23, 59, 59);

                            updatedData = {
                                ...updatedData,
                                [property]:
                                    item[property] !== ""
                                        ? formattingHelper.formatDateTimeForServer(new Date(date))
                                        : null
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = updatedData;
            }
            break;
        }
        case objectTypes.LICENSE: {
            data = {
                [propertyConstants.PROPERTY_KEY]:
                    item[propertyConstants.PROPERTY_COMPANY_ID] !== "" &&
                    item[propertyConstants.PROPERTY_USER_ID] !== ""
                        ? `${item[propertyConstants.PROPERTY_COMPANY_ID]}-${item[propertyConstants.PROPERTY_USER_ID]}`
                        : null,
                [propertyConstants.PROPERTY_MODULES]: item[propertyConstants.PROPERTY_MODULES],
                product: item[propertyConstants.PROPERTY_PRODUCT_ID],
                regData: {
                    [propertyConstants.PROPERTY_POINT_OF_SALE]: item.pointOfSale,
                    [propertyConstants.PROPERTY_CONTACT_PERSON]: {
                        ...item.contactPerson,
                        [propertyConstants.PROPERTY_NAME]:
                            item.contactPerson[propertyConstants.PROPERTY_PERSON_NAME] || ""
                    }
                }
            };
            break;
        }
        case objectTypes.MASTER_REDLIKE_OPTION:
        case objectTypes.SYSTEM_REDLIKE_OPTION:
        case objectTypes.ZONE_REDLIKE_OPTION: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_OVERWRITE: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? !item[property] : false
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_UNIVERSAL_VALUE: {
                            updatedData = {
                                ...updatedData,
                                [propertyConstants.PROPERTY_VALUE]:
                                    item[propertyConstants.PROPERTY_UNIVERSAL_VALUE] !== ""
                                        ? getRedlikeOptionValueForServer(item)
                                        : null
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.SYSTEM_PACKAGE:
        case objectTypes.ZONE_PACKAGE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_PRICE_SURCHARGE: {
                            updatedData = {
                                ...updatedData,
                                [propertyConstants.PROPERTY_PRICES]: getPricesListForServer([item?.[property] || null])
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.SYSTEM_PRICE_OPTION:
        case objectTypes.ZONE_PRICE_OPTION: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST:
                        case priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] || false
                            };
                            break;
                        }
                        case priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS:
                        case priceConstants.PRICE_OPTION_PRICE_DEFINITION: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] || null
                            };
                            break;
                        }
                        case priceConstants.PRICE_OPTION_PRICE_CALCULATION: {
                            const discount = generalHelper.getObjectByKey(
                                item?.[priceConstants.PRICE_OPTION_PRICE_CALCULATION] || [],
                                priceConstants.PRICE_OPTION_USE_DISCOUNT
                            );
                            const margin = generalHelper.getObjectByKey(
                                item?.[priceConstants.PRICE_OPTION_PRICE_CALCULATION] || [],
                                priceConstants.PRICE_OPTION_USE_MARGIN
                            );
                            const markup = generalHelper.getObjectByKey(
                                item?.[priceConstants.PRICE_OPTION_PRICE_CALCULATION] || [],
                                priceConstants.PRICE_OPTION_USE_MARKUP
                            );

                            updatedData = {
                                ...updatedData,
                                [priceConstants.PRICE_OPTION_USE_DISCOUNT]:
                                    discount?.[propertyConstants.PROPERTY_VALUE] ?? false,
                                [priceConstants.PRICE_OPTION_USE_MARGIN]:
                                    margin?.[propertyConstants.PROPERTY_VALUE] ?? false,
                                [priceConstants.PRICE_OPTION_USE_MARKUP]:
                                    markup?.[propertyConstants.PROPERTY_VALUE] ?? false
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
        case objectTypes.ZONE_PRODUCT: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_FILES: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] ? generalHelper.getFilesObject(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_LARGO_NAME: {
                            const jsonAttributes = {
                                largoName: item.largoName
                            };

                            updatedData = {
                                ...updatedData,
                                jsonAttributes: jsonAttributes
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                        case propertyConstants.PROPERTY_PRICE_VAT:
                        case propertyConstants.PROPERTY_PRICE_GROUP_LIST: {
                            if (updatedData[propertyConstants.PROPERTY_PRICES] === undefined) {
                                updatedData = {
                                    ...updatedData,
                                    [propertyConstants.PROPERTY_PRICES]: getPricesListForServer(
                                        [
                                            item?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null,
                                            item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                                        ].concat(item?.[propertyConstants.PROPERTY_PRICE_GROUP_LIST] ?? [])
                                    )
                                };
                            }
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.MASTER_PRODUCT_DUPLICATE: {
            data = {
                data: {
                    code: item.code !== "" ? item.code : null,
                    files: item.files ? generalHelper.getFilesObject(item.files) : undefined,
                    imageId: item.imageId !== "" ? item.imageId : null,
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    searchCode: item.searchCode !== "" ? item.searchCode : null,
                    searchName: item.searchName !== "" ? item.searchName : null,
                    printName: item.printName !== "" ? item.printName : null,
                    priority: item.priority !== "" ? item.priority : null,
                    tmcName: item.tmcName !== "" ? item.tmcName : null,
                    uuid: item.uuid !== "" ? item.uuid : null
                },
                dataTypeIds: item.dataTypeList.length
                    ? item.dataTypeList.map((dataType: DataType) => dataType[propertyConstants.PROPERTY_ID])
                    : []
            };
            break;
        }
        case objectTypes.SYSTEM_PRODUCT_DUPLICATE: {
            data = {
                data: {
                    code: item.code !== "" ? item.code : null,
                    files: item.files ? generalHelper.getFilesObject(item.files) : undefined,
                    imageId: item.imageId !== "" ? item.imageId : null,
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    searchCode: item.searchCode !== "" ? item.searchCode : null,
                    searchName: item.searchName !== "" ? item.searchName : null,
                    prices: getPricesListForServer(
                        [
                            item?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                        ].concat(item?.[propertyConstants.PROPERTY_PRICE_GROUP_LIST] ?? [])
                    ),
                    printName: item.printName !== "" ? item.printName : null,
                    priority: item.priority !== "" ? item.priority : null,
                    tmcName: item.tmcName !== "" ? item.tmcName : null
                },
                dataTypeIds: item.dataTypeList.length
                    ? item.dataTypeList.map((dataType: DataType) => dataType[propertyConstants.PROPERTY_ID])
                    : [],
                systemData: getDuplicateDataForServer(
                    additionalParams[propertyConstants.PROPERTY_PRODUCT_DUPLICATE_LIST] ?? [],
                    propertyConstants.PROPERTY_PRODUCT_NAME
                )
            };
            break;
        }
        case objectTypes.ZONE_PRODUCT_DUPLICATE: {
            data = {
                data: {
                    code: item.code !== "" ? item.code : null,
                    files: item.files ? generalHelper.getFilesObject(item.files) : undefined,
                    imageId: item.imageId !== "" ? item.imageId : null,
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    searchCode: item.searchCode !== "" ? item.searchCode : null,
                    searchName: item.searchName !== "" ? item.searchName : null,
                    prices: getPricesListForServer(
                        [
                            item?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] || null,
                            item?.[propertyConstants.PROPERTY_PRICE_VAT] || null
                        ].concat(item?.[propertyConstants.PROPERTY_PRICE_GROUP_LIST] ?? [])
                    ),
                    printName: item.printName !== "" ? item.printName : null,
                    priority: item.priority !== "" ? item.priority : null,
                    tmcName: item.tmcName !== "" ? item.tmcName : null
                },
                dataTypeIds: item.dataTypeList.length
                    ? item.dataTypeList.map((dataType: DataType) => dataType[propertyConstants.PROPERTY_ID])
                    : [],
                zoneData: getDuplicateDataForServer(
                    additionalParams[propertyConstants.PROPERTY_PRODUCT_DUPLICATE_LIST] ?? [],
                    propertyConstants.PROPERTY_PRODUCT_NAME
                )
            };
            break;
        }
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.SYSTEM_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS: {
            data = {
                moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                name: item.name !== "" ? item.name : null,
                priority: item.priority !== "" ? item.priority : null
            };
            break;
        }
        case objectTypes.SYSTEM: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    updatedData = {
                        ...updatedData,
                        [property]: item[property] !== "" ? item[property] : null
                    };
                }

                data = {
                    [propertyConstants.PROPERTY_DATABASE_ID]:
                        item[propertyConstants.PROPERTY_DATABASE_ID] !== ""
                            ? item[propertyConstants.PROPERTY_DATABASE_ID]
                            : null,
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.SYSTEM_DUPLICATE: {
            data = {
                systemId: item.id !== "" ? item.id : null,
                data: {
                    name: item.name !== "" ? item.name : null
                },
                dataTypeIds: item.dataTypeList.length
                    ? item.dataTypeList.map((dataType: DataType) => dataType[propertyConstants.PROPERTY_ID])
                    : [],
                zoneData: getDuplicateDataForServer(
                    additionalParams[propertyConstants.PROPERTY_ZONE_LIST] ?? [],
                    propertyConstants.PROPERTY_NAME
                )
            };
            break;
        }
        case objectTypes.SYSTEM_ZONE: {
            const exportTypeOptions =
                additionalParams?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE]?.[
                    propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS
                ] ?? null;

            data = {
                data: {
                    changelog:
                        item.changelog !== "" && exportTypeOptions?.[propertyConstants.PROPERTY_CHANGELOG]
                            ? item.changelog
                            : null,
                    colorsWithoutFormulas:
                        item.colorsWithoutFormulas !== "" &&
                        exportTypeOptions?.[propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS]
                            ? !item.colorsWithoutFormulas
                            : null,
                    systemId: exportTypeOptions?.[propertyConstants.PROPERTY_SYSTEM_ID]
                        ? item.systemId !== ""
                            ? item.systemId
                            : null
                        : defaultConstants.DEFAULT_EXPORT_SYSTEM_ID,
                    version: exportTypeOptions?.[propertyConstants.PROPERTY_VERSION]
                        ? item.version !== ""
                            ? item.version
                            : null
                        : importExportConstants.EXPORT_DEFAULT_VERSION,
                    versionName: exportTypeOptions?.[propertyConstants.PROPERTY_VERSION_NAME]
                        ? item.versionName !== ""
                            ? item.versionName
                            : null
                        : importExportConstants.EXPORT_DEFAULT_VERSION_NAME,
                    zoneId: exportTypeOptions?.[propertyConstants.PROPERTY_ZONE_ID]
                        ? item.zoneId !== ""
                            ? item.zoneId
                            : null
                        : defaultConstants.DEFAULT_EXPORT_ZONE_ID,
                    zonePassword: exportTypeOptions?.[propertyConstants.PROPERTY_ZONE_PASSWORD]
                        ? item.zonePassword !== ""
                            ? item.zonePassword
                            : null
                        : defaultConstants.DEFAULT_EXPORT_ZONE_PASSWORD,
                    uuid: item.uuid !== "" ? item.uuid : null
                }
            };
            break;
        }
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
            data = {
                autoLogin: item.autoLogin !== "" ? item.autoLogin : null,
                password: item.password ? sha(item.password) : undefined,
                username: item.username !== "" ? item.username : null
            };
            break;
        }
        case objectTypes.USER: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_PASSWORD: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] ? sha(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_USER_GROUP_LIST: {
                            updatedData = {
                                ...updatedData,
                                userGroupIds: item.userGroupList.map((group: UserGroup) => group.id)
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    userId: item[propertyConstants.PROPERTY_ID] !== "" ? item[propertyConstants.PROPERTY_ID] : null,
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.MASTER_REDLIKE_USER_GROUP_DUPLICATE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    updatedData = {
                        ...updatedData,
                        [property]: item[property] !== "" ? item[property] : null
                    };
                }

                data = {
                    userGroupId:
                        item[propertyConstants.PROPERTY_ID] !== "" ? item[propertyConstants.PROPERTY_ID] : null,
                    data: updatedData,
                    dataTypeIds: [dataTypeConstants.DATA_TYPE_PRIVILEGE]
                };
            }
            break;
        }
        case objectTypes.USER_PASSWORD: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    switch (property) {
                        case propertyConstants.PROPERTY_PASSWORD: {
                            updatedData = {
                                ...updatedData,
                                newPassword: item[property] ? sha(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_PASSWORD_NEW: {
                            updatedData = {
                                ...updatedData,
                                confirmationPassword: item[property] ? sha(item[property]) : undefined
                            };
                            break;
                        }
                        case propertyConstants.PROPERTY_PASSWORD_OLD: {
                            updatedData = {
                                ...updatedData,
                                currentPassword: item[property] ? sha(item[property]) : undefined
                            };
                            break;
                        }
                        default: {
                            updatedData = {
                                ...updatedData,
                                [property]: item[property] !== "" ? item[property] : null
                            };
                        }
                    }
                }

                data = {
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.VERSION: {
            data = {
                data: {
                    version: item.version !== "" ? item.version : null,
                    versionName: item.versionName !== "" ? item.versionName : null
                }
            };
            break;
        }
        case objectTypes.WAVELENGTH: {
            data = {
                // eslint-disable-next-line camelcase
                begin_value: item.beginValue !== "" ? item.beginValue : null,
                // eslint-disable-next-line camelcase
                end_value: item.endValue !== "" ? item.endValue : null,
                interval: item.interval !== "" ? item.interval : null
            };
            break;
        }
        case objectTypes.ZONE: {
            if (returnProperties.length) {
                for (const property of returnProperties) {
                    updatedData = {
                        ...updatedData,
                        [propertyConstants.PROPERTY_FILE_ID]:
                            item[propertyConstants.PROPERTY_STATIC_SQL] !== ""
                                ? item[propertyConstants.PROPERTY_STATIC_SQL]
                                : null,
                        [property]: item[property] !== "" ? item[property] : null
                    };
                }

                data = {
                    [propertyConstants.PROPERTY_DATABASE_ID]:
                        item[propertyConstants.PROPERTY_DATABASE_ID] !== ""
                            ? item[propertyConstants.PROPERTY_DATABASE_ID]
                            : null,
                    [propertyConstants.PROPERTY_SYSTEM_ID]:
                        item[propertyConstants.PROPERTY_SYSTEM_ID] !== ""
                            ? item[propertyConstants.PROPERTY_SYSTEM_ID]
                            : null,
                    data: updatedData
                };
            }
            break;
        }
        case objectTypes.ZONE_DUPLICATE: {
            data = {
                systemId: item.systemId !== "" ? item.systemId : null,
                zoneId: item.id !== "" ? item.id : null,
                data: {
                    changelog: item.changelog !== "" ? item.changelog : null,
                    currencyId: item.currencyId !== "" ? item.currencyId : null,
                    fileId: item.staticSql !== "" ? item.staticSql : null,
                    iconId: item.iconId !== "" ? item.iconId : null,
                    imageId: item.imageId !== "" ? item.imageId : null,
                    moreInfo: item.moreInfo !== "" ? item.moreInfo : null,
                    name: item.name !== "" ? item.name : null,
                    password: item.password !== "" ? item.password : null,
                    uuid: item.uuid !== "" ? item.uuid : null,
                    version: item.version !== "" ? item.version : null,
                    versionName: item.versionName !== "" ? item.versionName : null
                },
                dataTypeIds: item.dataTypeList.length
                    ? item.dataTypeList.map((dataType: DataType) => dataType[propertyConstants.PROPERTY_ID])
                    : []
            };
            break;
        }
        default:
            for (const property of returnProperties) {
                updatedData = {
                    ...updatedData,
                    [property]: item[property] !== "" ? item[property] : null
                };
            }
            data = {
                data: updatedData
            };
            break;
    }

    switch (actionType) {
        case modalTypes.ADD_MODAL:
        case modalTypes.DELETE_MODAL:
        case modalTypes.NO_MODAL:
        case modalTypes.PROPAGATE_MODAL:
        case modalTypes.TRANSLATION_MODAL: {
            return data;
        }
        case modalTypes.DUPLICATE_MODAL:
        case modalTypes.EDIT_MODAL:
        case modalTypes.PROPAGATE_EDIT_MODAL: {
            const idProperty = getObjectIdProperty(objectType);

            if (idProperty && data) {
                return {
                    [idProperty]: item.id,
                    ...data
                };
            }

            return {
                ...data
            };
        }
        default:
            return null;
    }
}

// There is a wrong value OVERWRITE stored in the database, client is working with the correct value, which needs to be changed for server.
// TODO value should not be changing to Number, it is problem in ModalConversionInputs, probably because 2 blur callbacks are called at the same time
export function getPricesListForServer(list: Array<Price>): any {
    let prices = {};

    for (const item of list) {
        if (item && item[propertyConstants.PROPERTY_KEY]) {
            const value = item[propertyConstants.PROPERTY_VALUE];

            prices = {
                ...prices,
                [item[propertyConstants.PROPERTY_KEY]]: {
                    ...item,
                    [propertyConstants.PROPERTY_VALUE]:
                        value !== null
                            ? item[propertyConstants.PROPERTY_PRICE_TYPE_ID] ===
                              priceConstants.PRICE_TYPE_PRICE_GROUP_BARCODE
                                ? value
                                : !isNaN(value)
                                ? Number(value)
                                : null
                            : null,
                    // Server requires opposite value
                    [propertyConstants.PROPERTY_OVERWRITE]: !item[propertyConstants.PROPERTY_OVERWRITE]
                }
            };
        }
    }

    return prices;
}

export function getRedlikeOptionValueForServer(item: any): any {
    if (item[propertyConstants.PROPERTY_DATA_TYPE] === serverConstants.DATA_TYPE_DICTIONARY) {
        try {
            return JSON.parse(item[propertyConstants.PROPERTY_UNIVERSAL_VALUE]);
        } catch (e) {
            return undefined;
        }
    }

    if (
        item[propertyConstants.PROPERTY_UNIVERSAL_VALUE] &&
        serverConstants.DATA_TYPES_LIST.includes(item[propertyConstants.PROPERTY_DATA_TYPE])
    ) {
        try {
            const parsedData = JSON.parse(item[propertyConstants.PROPERTY_UNIVERSAL_VALUE]);
            if (
                propertyConstants.PROPERTY_DATA in parsedData &&
                Array.isArray(parsedData[propertyConstants.PROPERTY_DATA])
            ) {
                return parsedData[propertyConstants.PROPERTY_DATA];
            }
        } catch (e) {
            return undefined;
        }
    }

    return item[propertyConstants.PROPERTY_UNIVERSAL_VALUE];
}

export const getAllBarcodesForServer = (
    previousBarcodes: Array<Barcode>,
    activeHeadingId: propertyConstants.Property,
    value: any
): Array<Barcode> => {
    const allBarcodes = [];

    for (let item of value?.toString()?.split(",") || []) {
        item = item.trim();

        if (item) {
            const oldBarcode =
                previousBarcodes.find((previous: Barcode) => previous[propertyConstants.PROPERTY_BARCODE] === item) ??
                null;

            if (oldBarcode) {
                allBarcodes.push(oldBarcode);
            } else {
                allBarcodes.push(
                    createBarcode(item, activeHeadingId === propertyConstants.PROPERTY_ALTERNATIVE_BARCODES)
                );
            }
        }
    }

    return allBarcodes;
};

export const getDuplicateDataForServer = (list: Array<any>, propertyName: propertyConstants.Property): Array<any> => {
    const updatedList = [];

    for (const item of list) {
        if (item[propertyConstants.PROPERTY_IS_DUPLICATED]) {
            updatedList.push({
                [propertyConstants.PROPERTY_ID]: item[propertyConstants.PROPERTY_ID],
                data: {
                    [propertyConstants.PROPERTY_NAME]: item[propertyName]
                }
            });
        }
    }

    return updatedList;
};
