import * as websocketActions from "../actions/websocketActions";

import { AppState } from "../reducers";
import { Dispatch } from "redux";
import { Welcome } from "../components/Welcome";
import { connect } from "react-redux";

export type PropsType = Readonly<{
    custom: string | null;
    url: string;
}>;

export type DispatchType = Readonly<{
    reconnect(url: string): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    custom: state.software.custom,
    url: state.server.urlWebsocket
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    reconnect: (url: string): any => dispatch(websocketActions.serverConnect(url))
});

export const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(Welcome);
