import * as actionConstants from "../constants/actionTypes";
import * as tableConstants from "../constants/tableConstants";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

export const saveList: Epic<any, any> = (action$) =>
    action$.pipe(
        ofType(actionConstants.LIST_SAVE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            if (payload.totalCount > 0 && payload.list.length === 0) {
                requests.push(
                    universalObjectActions.setOffset(
                        payload.objectType,
                        tableConstants.DEFAULT_OFFSET,
                        tableConstants.DEFAULT_PAGE
                    )
                );
            }

            return requests;
        })
    );
