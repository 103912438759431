import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { TableHeading } from "../tableHeading";

export class PointOfSale {
    name: string;
    country: string;
    region: string;
    city: string;
    street: string;
    zip: string;

    constructor(name: string, country: string, region: string, city: string, street: string, zip: string) {
        this.name = name;
        this.country = country;
        this.region = region;
        this.city = city;
        this.street = street;
        this.zip = zip;
    }
}

export function mapPointOfSale(data: Record<string, any>): PointOfSale | null {
    try {
        return new PointOfSale(data.name, data.country, data.region, data.city, data.street, data.zip);
    } catch (e) {
        return null;
    }
}

export function createEmptyPointOfSale(): any {
    return {
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_COUNTRY]: "",
        [propertyConstants.PROPERTY_REGION]: "",
        [propertyConstants.PROPERTY_CITY]: "",
        [propertyConstants.PROPERTY_STREET]: "",
        [propertyConstants.PROPERTY_ZIP]: ""
    };
}

export function generatePointOfSaleData(pointOfSale: PointOfSale | null): Record<string, any> | null {
    if (pointOfSale === null) {
        return createEmptyPointOfSale();
    }

    return {
        [propertyConstants.PROPERTY_NAME]: pointOfSale.name,
        [propertyConstants.PROPERTY_COUNTRY]: pointOfSale.country,
        [propertyConstants.PROPERTY_REGION]: pointOfSale.region,
        [propertyConstants.PROPERTY_CITY]: pointOfSale.city,
        [propertyConstants.PROPERTY_STREET]: pointOfSale.street,
        [propertyConstants.PROPERTY_ZIP]: pointOfSale.zip
    };
}

export function generatePointOfSaleHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CITY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CITY),
            editableList.includes(propertyConstants.PROPERTY_CITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CITY),
            requiredList.includes(propertyConstants.PROPERTY_CITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_CITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COUNTRY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COUNTRY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COUNTRY),
            editableList.includes(propertyConstants.PROPERTY_COUNTRY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COUNTRY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COUNTRY),
            requiredList.includes(propertyConstants.PROPERTY_COUNTRY),
            [],
            widthOption?.[propertyConstants.PROPERTY_COUNTRY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_POINT_OF_SALE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_POINT_OF_SALE),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_POINT_OF_SALE),
            editableList.includes(propertyConstants.PROPERTY_POINT_OF_SALE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_POINT_OF_SALE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_POINT_OF_SALE),
            requiredList.includes(propertyConstants.PROPERTY_POINT_OF_SALE),
            [],
            widthOption?.[propertyConstants.PROPERTY_POINT_OF_SALE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_REGION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_REGION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_REGION),
            editableList.includes(propertyConstants.PROPERTY_REGION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_REGION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_REGION),
            requiredList.includes(propertyConstants.PROPERTY_REGION),
            [],
            widthOption?.[propertyConstants.PROPERTY_REGION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_STREET,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_STREET),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_STREET),
            editableList.includes(propertyConstants.PROPERTY_STREET),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_STREET),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_STREET),
            requiredList.includes(propertyConstants.PROPERTY_STREET),
            [],
            widthOption?.[propertyConstants.PROPERTY_STREET] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZIP,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZIP),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_ZIP),
            editableList.includes(propertyConstants.PROPERTY_ZIP),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZIP),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZIP),
            requiredList.includes(propertyConstants.PROPERTY_ZIP),
            [],
            widthOption?.[propertyConstants.PROPERTY_ZIP] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
