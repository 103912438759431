import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { BaseInProduct, mapBaseInProduct } from "../../types/baseInProduct";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.MASTER_BASE_IN_PRODUCT, baseList)];
        }
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_BASE_IN_PRODUCT, baseList, response.data.count)
            ];
        }
        case methods.METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT,
                    baseList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductList: Array<BaseInProduct> = [];
            const baseInProduct = mapBaseInProduct(response.data);
            const requests: Array<any> = [];

            if (baseInProduct) {
                baseInProductList.push(baseInProduct);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: baseInProduct
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.MASTER_BASE_IN_PRODUCT, baseInProductList)
            ];
        }
        case methods.METHOD_ADD_MASTER_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_BASE_IN_PRODUCT),
                universalObjectActions.getAllItems(objectTypes.MASTER_BASE_IN_PRODUCT, {
                    productId: state.product.masterActiveList[0][propertyConstants.PROPERTY_ID],
                    simpleData: true
                }),
                navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_BASE_IN_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_BASE_IN_PRODUCT_EDIT)
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_BASE_IN_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_EDIT));
            } else {
                const baseInProduct = mapBaseInProduct(response.data);

                if (baseInProduct) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.MASTER_BASE_IN_PRODUCT, [baseInProduct])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_BASE_IN_PRODUCT),
                universalObjectActions.getAllItems(objectTypes.MASTER_BASE_IN_PRODUCT, {
                    productId: state.product.masterActiveList[0][propertyConstants.PROPERTY_ID],
                    simpleData: true
                }),
                universalObjectActions.setActiveItems(objectTypes.MASTER_BASE_IN_PRODUCT, [])
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_BASE_IN_PRODUCT,
                    baseList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }

        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.SYSTEM_BASE_IN_PRODUCT, baseList)];
        }
        case methods.METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductList: Array<BaseInProduct> = [];
            let baseInProduct: BaseInProduct | null;

            for (const item of response.data.data) {
                baseInProduct = mapBaseInProduct(item);

                if (baseInProduct) {
                    baseInProductList.push(baseInProduct);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT,
                    baseInProductList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductList: Array<BaseInProduct> = [];
            const baseInProduct = mapBaseInProduct(response.data);
            const requests: Array<any> = [];

            if (baseInProduct) {
                baseInProductList.push(baseInProduct);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: baseInProduct
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.SYSTEM_BASE_IN_PRODUCT, baseInProductList)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_BASE_IN_PRODUCT_EDIT)
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_EDIT));
            } else {
                const baseInProduct = mapBaseInProduct(response.data);

                if (baseInProduct) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_BASE_IN_PRODUCT, [baseInProduct])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_BASE_IN_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_BASE_IN_PRODUCT, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.ZONE_BASE_IN_PRODUCT, baseList)];
        }
        case methods.METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseList: Array<BaseInProduct> = [];
            let base: BaseInProduct | null;

            for (const item of response.data.data) {
                base = mapBaseInProduct(item);

                if (base) {
                    baseList.push(base);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_BASE_IN_PRODUCT,
                    baseList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ZONE_BASE_IN_PRODUCT_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const baseInProductList: Array<BaseInProduct> = [];
            const baseInProduct = mapBaseInProduct(response.data);
            const requests: Array<any> = [];

            if (baseInProduct) {
                baseInProductList.push(baseInProduct);

                if (state.navigation.nextModal) {
                    requests.push(
                        navigationActions.navigationOpenModal(
                            state.navigation.nextModal[propertyConstants.PROPERTY_TYPE],
                            {
                                ...state.navigation.nextModal.params,
                                data: baseInProduct
                            }
                        ),
                        navigationActions.setNextModal(null)
                    );
                }
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.ZONE_BASE_IN_PRODUCT, baseInProductList)
            ];
        }
        case methods.METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS: {
            const requests = [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE)
            ];

            if (!responseValidationHelper.isResponseValid(response)) {
                requests.splice(0, 0, messageHelper.getInvalidFormatMessage(response));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.length > 0) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_BASE_IN_PRODUCT_EDIT));
            } else {
                const baseInProduct = mapBaseInProduct(response.data);

                if (baseInProduct) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.ZONE_BASE_IN_PRODUCT, [baseInProduct])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_BASE_IN_PRODUCT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_BASE_IN_PRODUCT),
                universalObjectActions.setActiveItems(objectTypes.ZONE_BASE_IN_PRODUCT, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
