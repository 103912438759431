import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Currency, updateCurrency } from "../types/currency";

export type CurrencyState = Readonly<{
    systemCurrency: Currency | null;
    zoneCurrency: Currency | null;
    allList: Array<Currency>;
    list: Array<Currency>;
    activeList: Array<Currency>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: CurrencyState = {
    systemCurrency: null,
    zoneCurrency: null,
    allList: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function currencies(state: CurrencyState = initialState, action: Record<string, any>): CurrencyState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.CURRENCY) {
                return {
                    ...state,
                    allList: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.CURRENCY) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            if (action.payload.objectType === objectTypes.SYSTEM_CURRENCY) {
                return {
                    ...state,
                    systemCurrency: action.payload.list.length ? action.payload.list[0] : null
                };
            }

            if (action.payload.objectType === objectTypes.ZONE_CURRENCY) {
                return {
                    ...state,
                    zoneCurrency: action.payload.list.length ? action.payload.list[0] : null
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.CURRENCY) {
                return {
                    ...state,
                    activeList: action.payload.items
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.CURRENCY) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.CURRENCY) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.CURRENCY: {
                        const updatedCurrencyList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_CURRENCY_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateCurrency
                        );

                        if (updatedCurrencyList.length) {
                            return {
                                ...state,
                                list: updatedCurrencyList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.CURRENCY: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                systemCurrency: state.systemCurrency,
                zoneCurrency: state.zoneCurrency,
                allList: state.allList
            };
        }
        case actionConstants.EVENT_SAVE_CURRENCIES: {
            return {
                ...state,
                allList: action.payload.currencyList !== undefined ? action.payload.currencyList : state.allList,
                systemCurrency:
                    action.payload.systemCurrency !== undefined ? action.payload.systemCurrency : state.systemCurrency,
                zoneCurrency:
                    action.payload.zoneCurrency !== undefined ? action.payload.zoneCurrency : state.zoneCurrency
            };
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
