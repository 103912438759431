import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as modalConstants from "../constants/modalTypes";

import { NavigationModalOpen } from "./navigationActions";
import { ServerRequest } from "./websocketActions";

export function setActiveDatabase(id: number, confirmed: boolean): ServerRequest | NavigationModalOpen {
    if (!confirmed) {
        return {
            type: actionConstants.NAVIGATION_MODAL_OPEN,
            payload: {
                type: modalConstants.MODAL_SAVE_DATA,
                nextAction: {
                    type: actionConstants.SERVER_REQUEST,
                    payload: {
                        type: methods.METHOD_SET_ACTIVE_DATABASE,
                        params: {
                            databaseId: id
                        }
                    },
                    method: methods.METHOD_SET_ACTIVE_DATABASE
                },
                params: {}
            }
        };
    }

    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_ACTIVE_DATABASE,
            params: {
                databaseId: id
            }
        },
        method: methods.METHOD_SET_ACTIVE_DATABASE
    };
}

export function getActiveDatabase(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_ACTIVE_DATABASE
        },
        method: methods.METHOD_GET_ACTIVE_DATABASE
    };
}

export function updateActiveDatabaseStructure(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_UPDATE_ACTIVE_DATABASE_STRUCTURE
        },
        method: methods.METHOD_UPDATE_ACTIVE_DATABASE_STRUCTURE
    };
}

export function closeActiveDatabase(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_CLOSE_ACTIVE_DATABASE
        },
        method: methods.METHOD_CLOSE_ACTIVE_DATABASE
    };
}

export interface DataLoad {
    type: typeof actionConstants.DATA_LOAD;
}

export function loadData(): DataLoad {
    return {
        type: actionConstants.DATA_LOAD
    };
}

export function vacuumAllDatabases(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_VACUUM_ALL_DATABASES
        },
        method: methods.METHOD_VACUUM_ALL_DATABASES
    };
}

export interface RunStaticSqlFile {
    type: typeof actionConstants.RUN_STATIC_SQL;
    payload: {
        params: {
            fileId: number;
        };
    };
}

export function runStaticSqlFile(fileId: number): RunStaticSqlFile {
    return {
        type: actionConstants.RUN_STATIC_SQL,
        payload: {
            params: {
                fileId
            }
        }
    };
}
