import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Colorant, updateColorant } from "../types/colorant";

export type ColorantState = Readonly<{
    masterList: Array<Colorant>;
    masterActiveList: Array<Colorant>;
    masterCount: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    masterSystemList: Array<Colorant>;
    masterSystemActiveList: Array<Colorant>;
    masterSystemSearch: string | null;
    masterSystemSearchParams: Record<propertyConstants.Property, string>;
    masterSystemOffset: number;
    masterSystemPage: number;
    systemMasterList: Array<Colorant>;
    systemMasterActiveList: Array<Colorant>;
    systemMasterSortingAsc: boolean;
    systemMasterSortingCriterion: propertyConstants.Property;
    systemMasterSearch: string | null;
    systemMasterSearchParams: Record<propertyConstants.Property, string>;
    systemMasterOffset: number;
    systemMasterPage: number;
    systemList: Array<Colorant>;
    systemActiveList: Array<Colorant>;
    systemCountAvailable: number | null;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    systemZoneList: Array<Colorant>;
    systemZoneActiveList: Array<Colorant>;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneOffset: number;
    systemZonePage: number;
    zoneSystemList: Array<Colorant>;
    zoneSystemActiveList: Array<Colorant>;
    zoneSystemSortingAsc: boolean;
    zoneSystemSortingCriterion: propertyConstants.Property;
    zoneSystemSearch: string | null;
    zoneSystemSearchParams: Record<propertyConstants.Property, string>;
    zoneSystemOffset: number;
    zoneSystemPage: number;
    zoneList: Array<Colorant>;
    zoneActiveList: Array<Colorant>;
    zoneCountAvailable: number | null;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
}>;

const initialState: ColorantState = {
    masterList: [],
    masterActiveList: [],
    masterCount: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: 0,
    masterPage: 1,
    masterSystemList: [],
    masterSystemActiveList: [],
    masterSystemSearch: null,
    masterSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterSystemOffset: 0,
    masterSystemPage: 1,
    systemMasterList: [],
    systemMasterActiveList: [],
    systemMasterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemMasterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemMasterSearch: null,
    systemMasterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemMasterOffset: 0,
    systemMasterPage: 1,
    systemList: [],
    systemActiveList: [],
    systemCountAvailable: null,
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: 0,
    systemPage: 1,
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSearch: null,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneOffset: 0,
    systemZonePage: 1,
    zoneSystemList: [],
    zoneSystemActiveList: [],
    zoneSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSystemSearch: null,
    zoneSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneSystemOffset: 0,
    zoneSystemPage: 1,
    zoneList: [],
    zoneActiveList: [],
    zoneCountAvailable: null,
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: 0,
    zonePage: 1
};

export default function colorants(state: ColorantState = initialState, action: Record<string, any>): ColorantState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLORANT: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterCount: action.payload.totalCount
                    };
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    return {
                        ...state,
                        masterSystemList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    if (action.payload.objectType === objectTypes.SYSTEM_MASTER_COLORANT) {
                        return {
                            ...state,
                            systemMasterList: action.payload.list
                        };
                    }
                    return state;
                }
                case objectTypes.SYSTEM_COLORANT: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountAvailable: action.payload.availableCount,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    return {
                        ...state,
                        systemZoneList: action.payload.list
                    };
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    return {
                        ...state,
                        zoneSystemList: action.payload.list
                    };
                }
                case objectTypes.ZONE_COLORANT: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountAvailable: action.payload.availableCount,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLORANT: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    return {
                        ...state,
                        masterSystemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    const list = generalHelper.updateLists(state.systemMasterList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        systemMasterList: list,
                        systemMasterActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_COLORANT: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    return {
                        ...state,
                        systemZoneActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    const list = generalHelper.updateLists(state.zoneSystemList, action.payload.items);
                    const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                    return {
                        ...state,
                        zoneSystemList: list,
                        zoneSystemActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_COLORANT: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.UPDATE_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_COLORANT: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_COLORANT: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLORANT: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    return {
                        ...state,
                        masterSystemOffset: action.payload.offset,
                        masterSystemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_COLORANT: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    return {
                        ...state,
                        systemZoneOffset: action.payload.offset,
                        systemZonePage: action.payload.page
                    };
                }
                case objectTypes.ZONE_COLORANT: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SYSTEM_SEARCH,
                        tableConstants.MASTER_SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_MASTER_SEARCH,
                        tableConstants.SYSTEM_MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_ZONE_SEARCH,
                        tableConstants.SYSTEM_ZONE_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SYSTEM_SEARCH,
                        tableConstants.ZONE_SYSTEM_SEARCH
                    );
                }
                case objectTypes.ZONE_COLORANT: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }

                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_MASTER_COLORANT: {
                    // TODO
                    let sortedList: Array<Colorant> = [];

                    if (action.payload.asc) {
                        switch (action.payload.criterion) {
                            case propertyConstants.PROPERTY_NAME_MASTER:
                                sortedList = state.systemMasterList.sort((a: any, b: any) =>
                                    a[propertyConstants.PROPERTY_NAME_MASTER].localeCompare(
                                        b[propertyConstants.PROPERTY_NAME_MASTER]
                                    )
                                );
                                break;
                            case propertyConstants.PROPERTY_NAME:
                                sortedList = state.systemMasterList.sort((a: any, b: any) =>
                                    a[propertyConstants.PROPERTY_NAME].localeCompare(b[propertyConstants.PROPERTY_NAME])
                                );
                                break;
                            default:
                                break;
                        }
                    } else {
                        switch (action.payload.criterion) {
                            case propertyConstants.PROPERTY_NAME_MASTER:
                                sortedList = state.systemMasterList.sort((a: any, b: any) =>
                                    b[propertyConstants.PROPERTY_NAME_MASTER].localeCompare(
                                        a[propertyConstants.PROPERTY_NAME_MASTER]
                                    )
                                );
                                break;
                            case propertyConstants.PROPERTY_NAME:
                                sortedList = state.systemMasterList.sort((a: any, b: any) =>
                                    b[propertyConstants.PROPERTY_NAME].localeCompare(a[propertyConstants.PROPERTY_NAME])
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    return {
                        ...state,
                        systemMasterList: sortedList,
                        systemMasterSortingAsc: action.payload.asc,
                        systemMasterSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.ZONE_SYSTEM_COLORANT: {
                    let sortedList: Array<Colorant> = [];

                    if (action.payload.asc) {
                        switch (action.payload.criterion) {
                            case propertyConstants.PROPERTY_NAME_SYSTEM:
                                sortedList = state.zoneSystemList.sort((a: any, b: any) =>
                                    a[propertyConstants.PROPERTY_NAME_SYSTEM].localeCompare(
                                        b[propertyConstants.PROPERTY_NAME_SYSTEM]
                                    )
                                );
                                break;
                            case propertyConstants.PROPERTY_NAME:
                                sortedList = state.zoneSystemList.sort((a: any, b: any) =>
                                    a[propertyConstants.PROPERTY_NAME].localeCompare(b[propertyConstants.PROPERTY_NAME])
                                );
                                break;
                            default:
                                break;
                        }
                    } else {
                        switch (action.payload.criterion) {
                            case propertyConstants.PROPERTY_NAME_SYSTEM:
                                sortedList = state.zoneSystemList.sort((a: any, b: any) =>
                                    b[propertyConstants.PROPERTY_NAME_SYSTEM].localeCompare(
                                        a[propertyConstants.PROPERTY_NAME_SYSTEM]
                                    )
                                );
                                break;
                            case propertyConstants.PROPERTY_NAME:
                                sortedList = state.zoneSystemList.sort((a: any, b: any) =>
                                    b[propertyConstants.PROPERTY_NAME].localeCompare(a[propertyConstants.PROPERTY_NAME])
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    return {
                        ...state,
                        zoneSystemList: sortedList,
                        zoneSystemSortingAsc: action.payload.asc,
                        zoneSystemSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_COLORANT: {
                        const updatedColorantList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_COLORANT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateColorant
                        );

                        if (updatedColorantList.length) {
                            return {
                                ...state,
                                masterList: updatedColorantList
                            };
                        }

                        return state;
                    }
                    case objectTypes.SYSTEM_COLORANT: {
                        const updatedColorantList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_COLORANT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateColorant
                        );

                        if (updatedColorantList.length) {
                            return {
                                ...state,
                                systemList: updatedColorantList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_COLORANT: {
                        const updatedColorantList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_COLORANT_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateColorant
                        );

                        if (updatedColorantList.length) {
                            return {
                                ...state,
                                zoneList: updatedColorantList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_COLORANT: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_COLORANT: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_COLORANT: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            // keep active system colorant so that we can load system colorant packages in the wizard
            if (action.payload.wizard === null || action.payload.scene === navigationTypes.SYSTEM_COLORANTS_SCENE) {
                return {
                    ...initialState,
                    systemActiveList: state.systemActiveList
                };
            }
            if (action.payload.wizard === null || action.payload.scene === navigationTypes.ZONE_COLORANTS_SCENE) {
                return {
                    ...initialState,
                    zoneActiveList: state.zoneActiveList
                };
            }
            return initialState;
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            if (
                action.payload.type === modalTypes.MODAL_SYSTEM_COLORANTS_PROPAGATE ||
                action.payload.type === modalTypes.MODAL_ZONE_COLORANTS_PROPAGATE
            ) {
                return {
                    ...state,
                    masterSystemList: initialState.masterSystemList,
                    masterSystemActiveList: initialState.masterSystemActiveList,
                    masterSystemSearch: initialState.masterSystemSearch,
                    masterSystemSearchParams: initialState.masterSystemSearchParams,
                    systemMasterList: initialState.systemMasterList,
                    systemMasterActiveList: initialState.systemMasterActiveList,
                    systemZoneList: initialState.systemZoneList,
                    systemZoneActiveList: initialState.systemZoneActiveList,
                    systemZoneSearch: initialState.systemZoneSearch,
                    systemZoneSearchParams: initialState.systemZoneSearchParams,
                    zoneSystemList: initialState.zoneSystemList,
                    zoneSystemActiveList: initialState.zoneSystemActiveList
                };
            }
            return state;
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                masterSystemList: initialState.masterSystemList,
                masterSystemActiveList: initialState.masterSystemActiveList,
                masterSystemSearch: initialState.masterSystemSearch,
                masterSystemSearchParams: initialState.masterSystemSearchParams,
                systemMasterList: initialState.systemMasterList,
                systemMasterActiveList: initialState.systemMasterActiveList,
                systemZoneList: initialState.systemZoneList,
                systemZoneActiveList: initialState.systemZoneActiveList,
                systemZoneSearch: initialState.systemZoneSearch,
                systemZoneSearchParams: initialState.systemZoneSearchParams,
                zoneSystemList: initialState.zoneSystemList,
                zoneSystemActiveList: initialState.zoneSystemActiveList
            };
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return {
                ...initialState,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterCount: state.masterCount,
                masterSearch: state.masterSearch,
                masterSearchParams: state.masterSearchParams,
                masterOffset: state.masterOffset,
                masterPage: state.masterPage
            };
        }
        case actionConstants.EVENT_SAVE_ZONES: {
            return {
                ...initialState,
                masterList: state.masterList,
                masterActiveList: state.masterActiveList,
                masterCount: state.masterCount,
                masterSearch: state.masterSearch,
                masterSearchParams: state.masterSearchParams,
                masterOffset: state.masterOffset,
                masterPage: state.masterPage,
                masterSystemList: state.masterSystemList,
                systemList: state.systemList,
                systemActiveList: state.systemActiveList,
                systemCountTotal: state.systemCountTotal,
                systemSearch: state.systemSearch,
                systemSearchParams: state.systemSearchParams,
                systemOffset: state.systemOffset,
                systemPage: state.systemPage,
                systemZoneList: state.systemZoneList
            };
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
