import * as navigationActions from "../actions/navigationActions";
import * as navigationConstants from "../constants/navigationTypes";
import * as optionActions from "../actions/optionActions";
import * as optionHelper from "../helpers/optionHelper";
import * as optionsConstants from "../constants/optionsConstants";

import { AppState } from "../reducers";
import { DataItem } from "../types/dataItem";
import { Dispatch } from "redux";
import { MenuItem } from "../types/menu";
import Navigation from "../components/Navigation";
import { OptionItem } from "../types/optionItem";
import { Paths } from "../constants/paths";
import { PrivilegeItem } from "../types/privilegeItem";
import { Script } from "../types/script";
import { User } from "../types/user";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    activeScene: navigationConstants.Scene;
    activeUser: User | null;
    custom: string | null;
    dataChanged: boolean;
    menuList: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>;
    menuVisibility: Record<string, any> | null;
    scriptList: Array<Script>;
}>;

export type DispatchType = Readonly<{
    routingPush(path: Paths, confirmed: boolean): void;
    setMenuVisibility(tableConstant: string, value: boolean): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    activeUser: state.login.loggedUser,
    custom: state.software.custom,
    dataChanged: state.navigation.dataChanged,
    menuList: state.navigation.menuList,
    menuVisibility: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_MENU_SECTION_VISIBILITY
    ),
    scriptList: state.script.allList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    routingPush: (path: Paths, confirmed: boolean): any => dispatch(navigationActions.routingPush(path, confirmed)),
    setMenuVisibility: (tableConstant: string, value: boolean): any =>
        dispatch(
            optionActions.setDictionaryOption(optionsConstants.OPTION_MENU_SECTION_VISIBILITY, tableConstant, value)
        )
});

export const NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(Navigation);
