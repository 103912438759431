import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import {
    ColorantPackage,
    generateColorantPackageData,
    generateColorantPackageHeadings
} from "../../../../types/colorantPackage";
import React, { Component } from "react";

import { Colorant } from "../../../../types/colorant";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";
import { Unit } from "../../../../types/unit";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    colorantActiveList: Array<Colorant>;
    unitList: Array<Unit>;
    allList: Array<ColorantPackage>;
    activeList: Array<ColorantPackage>;
    availableCount: number | null;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    useTmcNames: boolean;
};

export class SystemColorantPackageTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        objectType: objectTypes.ObjectType = this.props.objectType
    ): Array<TableHeading> => {
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLORANT_ID,
            propertyConstants.PROPERTY_FILL_PROC,
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_UNIT_ID
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        let editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_BARCODES,
            propertyConstants.PROPERTY_FILL_PROC
        ];
        let visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_UNIT_ID,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_FILL_PROC,
            propertyConstants.PROPERTY_FILL_VOLUME_ML,
            propertyConstants.PROPERTY_IMAGE_ID,
            propertyConstants.PROPERTY_BARCODES
        ];

        if (modalType === modalTypes.MODAL_COLORANT_PACKAGES_EDIT) {
            editableList.push(propertyConstants.PROPERTY_IMAGE_ID);
        }

        if (modalType === modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE) {
            visibleList = [propertyConstants.PROPERTY_NAME];
            editableList = [];
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateColorantPackageHeadings(
            objectType,
            this.props.unitList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.PROPAGATE_MODAL,
            objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE,
            "",
            [],
            this.generateTableHeadings(
                modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE,
                objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE
            ),
            undefined,
            this.generateTableHeadings(
                modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE,
                objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE
            ),
            undefined,
            undefined,
            this.props.dataPrivileges,
            undefined,
            "simple-table"
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateColorantPackageData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("colorant.editColorantPackage"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_COLORANT_PACKAGES_EDIT)
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE,
            addModalParams: this.generateAddModalParams(),
            editModalType: modalTypes.MODAL_COLORANT_PACKAGES_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generateColorantPackageData(this.props.allList)}
                    activeData={this.props.activeList}
                    availableCount={this.props.availableCount}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
