import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";

import { Bookmark } from "./bookmark";
import { Currency } from "./currency";
import { Scene } from "../constants/navigationTypes";
import { TableHeading } from "../types/tableHeading";

export class ModalParams {
    type: modalTypes.ModalFunctionType;
    objectType: objectTypes.ObjectType;
    title: string;
    data: any;
    headings?: Array<TableHeading>;
    bookmarks?: Array<Bookmark>;
    headingsDefault?: Array<TableHeading>;
    inputHeadings?: Array<TableHeading>;
    matrix?: any;
    dataPrivileges?: any;
    activeScene?: Scene | null;
    classNameTable?: string;
    currency?: Currency | null;
    exportTaskTypeId?: number | null;
    databaseId?: number | null;
    databaseName?: string;
    displayText?: string;
    optionPriceCompanyCost?: any;
    optionPriceDefinition?: any;
    fileId?: number | null;
    systemId?: number | null;
    nextAction?: any;
    returnAction?: any;

    constructor(
        type: modalTypes.ModalFunctionType,
        objectType: objectTypes.ObjectType,
        title: string,
        data: any,
        headings?: Array<TableHeading>,
        bookmarks?: Array<Bookmark>,
        headingsDefault?: Array<TableHeading>,
        inputHeadings?: Array<TableHeading>,
        matrix?: any,
        dataPrivileges?: any,
        activeScene?: Scene | null,
        classNameTable?: string,
        currency?: Currency | null,
        exportTaskTypeId?: number | null,
        databaseId?: number | null,
        databaseName?: string,
        displayText?: string,
        fileId?: number | null,
        optionPriceCompanyCost?: any,
        optionPriceDefinition?: any,
        systemId?: number | null,
        nextAction?: any,
        returnAction?: any
    ) {
        this.type = type;
        this.objectType = objectType;
        this.title = title;
        this.data = data;
        this.headings = headings;
        this.bookmarks = bookmarks;
        this.headingsDefault = headingsDefault;
        this.inputHeadings = inputHeadings;
        this.matrix = matrix;
        this.dataPrivileges = dataPrivileges;
        this.activeScene = activeScene;
        this.classNameTable = classNameTable;
        this.currency = currency;
        this.exportTaskTypeId = exportTaskTypeId;
        this.databaseId = databaseId;
        this.databaseName = databaseName;
        this.displayText = displayText;
        this.fileId = fileId;
        this.optionPriceCompanyCost = optionPriceCompanyCost;
        this.optionPriceDefinition = optionPriceDefinition;
        this.systemId = systemId;
        this.nextAction = nextAction;
        this.returnAction = returnAction;
    }
}
