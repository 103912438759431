import * as menuConstants from "../constants/menuConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";

import { Database } from "../types/database";
import { System } from "../types/system";
import { Zone } from "../types/zone";

/**
 * Checks whether appropriate database, system and zone are selected, and server requests should pass
 * @param objectType
 * @param activeDatabase: selected database in the header
 * @param activeSystem: selected system in the header
 * @param activeZone: selected zone in the header
 */
export const isObjectTypeEnabledByHeader = (
    objectType: objectTypes.ObjectType,
    activeDatabase: Database | null,
    activeSystem: System | null,
    activeZone: Zone | null
): boolean => {
    if (
        (objectTypes.GENERIC_OBJECT_TYPES.includes(objectType) ||
            objectTypes.MASTER_OBJECT_TYPES.includes(objectType) ||
            objectTypes.MASTER_SYSTEM_OBJECT_TYPES.includes(objectType) ||
            objectTypes.SYSTEM_MASTER_OBJECT_TYPES.includes(objectType) ||
            objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType) ||
            objectTypes.ZONE_OBJECT_TYPES.includes(objectType)) &&
        (activeDatabase === null || activeDatabase?.[propertyConstants.PROPERTY_UPDATE_NEEDED] === true)
    ) {
        return false;
    }

    if (
        (objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType) || objectTypes.ZONE_OBJECT_TYPES.includes(objectType)) &&
        activeSystem === null
    ) {
        return false;
    }

    if (objectTypes.ZONE_OBJECT_TYPES.includes(objectType) && activeZone === null) {
        return false;
    }

    return true;
};

/**
 * Finds opposite object type for Propagation modal.
 * @param oldObject
 */
export const getOppositeMixedObjectType = (oldObject: objectTypes.ObjectType): objectTypes.ObjectType | null => {
    switch (oldObject) {
        case objectTypes.MASTER_SYSTEM_BASE:
            return objectTypes.SYSTEM_MASTER_BASE;
        case objectTypes.SYSTEM_MASTER_BASE:
            return objectTypes.MASTER_SYSTEM_BASE;
        case objectTypes.SYSTEM_ZONE_BASE:
            return objectTypes.ZONE_SYSTEM_BASE;
        case objectTypes.ZONE_SYSTEM_BASE:
            return objectTypes.SYSTEM_ZONE_BASE;
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
            return objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT;
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT:
            return objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT;
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
            return objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT;
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT:
            return objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT;
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
            return objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE;
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE:
            return objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE;
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
            return objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE;
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
            return objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE;
        case objectTypes.MASTER_SYSTEM_COLORANT:
            return objectTypes.SYSTEM_MASTER_COLORANT;
        case objectTypes.SYSTEM_MASTER_COLORANT:
            return objectTypes.MASTER_SYSTEM_COLORANT;
        case objectTypes.SYSTEM_ZONE_COLORANT:
            return objectTypes.ZONE_SYSTEM_COLORANT;
        case objectTypes.ZONE_SYSTEM_COLORANT:
            return objectTypes.SYSTEM_ZONE_COLORANT;
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
            return objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE;
        case objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE:
            return objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE;
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
            return objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE;
        case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE:
            return objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE;
        case objectTypes.MASTER_SYSTEM_FANDECK:
            return objectTypes.SYSTEM_MASTER_FANDECK;
        case objectTypes.SYSTEM_MASTER_FANDECK:
            return objectTypes.MASTER_SYSTEM_FANDECK;
        case objectTypes.SYSTEM_ZONE_FANDECK:
            return objectTypes.ZONE_SYSTEM_FANDECK;
        case objectTypes.ZONE_SYSTEM_FANDECK:
            return objectTypes.SYSTEM_ZONE_FANDECK;
        case objectTypes.MASTER_SYSTEM_PRODUCT:
            return objectTypes.SYSTEM_MASTER_PRODUCT;
        case objectTypes.SYSTEM_MASTER_PRODUCT:
            return objectTypes.MASTER_SYSTEM_PRODUCT;
        case objectTypes.SYSTEM_ZONE_PRODUCT:
            return objectTypes.ZONE_SYSTEM_PRODUCT;
        case objectTypes.ZONE_SYSTEM_PRODUCT:
            return objectTypes.SYSTEM_ZONE_PRODUCT;
        case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
            return objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS;
        case objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS:
            return objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS;
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
            return objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS;
        case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS:
            return objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS;
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
            return objectTypes.SYSTEM_MASTER_PRODUCT_GROUP;
        case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP:
            return objectTypes.MASTER_SYSTEM_PRODUCT_GROUP;
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
            return objectTypes.ZONE_SYSTEM_PRODUCT_GROUP;
        case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
            return objectTypes.SYSTEM_ZONE_PRODUCT_GROUP;
        case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            return objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS;
        case objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
            return objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS;
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            return objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS;
        case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
            return objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS;
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
            return objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP;
        case objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP:
            return objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP;
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
            return objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP;
        case objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP:
            return objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP;
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
            return objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP;
        case objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP:
            return objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP;
        default:
            return null;
    }
};

/**
 * Used in method getOptions in TableOptions component
 * Defined object types which do not have any table options (as row count, column visibility, etc.) so there is no icon displayed
 */
export const isObjectTypeWithoutTableOptions = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.FILE ||
        objectType === objectTypes.SYSTEM_PRICE_GENERIC ||
        objectType === objectTypes.SYSTEM_PRICE_GROUP ||
        objectType === objectTypes.USER_GROUP_PRIVILEGE ||
        objectType === objectTypes.ZONE_PRICE_GENERIC ||
        objectType === objectTypes.ZONE_PRICE_GROUP
    ) {
        return true;
    }

    return false;
};

// Propagation object types

export const isObjectTypePropagateBaseInProduct = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT ||
        objectType === objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT ||
        objectType === objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT ||
        objectType === objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT
    ) {
        return true;
    }

    return false;
};

export const isObjectTypePropagateProductGroup = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_SYSTEM_PRODUCT_GROUP ||
        objectType === objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS ||
        objectType === objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeRedlikeUser = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_REDLIKE_USER ||
        objectType === objectTypes.SYSTEM_REDLIKE_USER ||
        objectType === objectTypes.ZONE_REDLIKE_USER
    ) {
        return true;
    }

    return false;
};

export const isObjectTypePropagateRedlikeUser = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP ||
        objectType === objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP ||
        objectType === objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP
    ) {
        return true;
    }

    return false;
};

// Classic object types check

export const isObjectTypeBaseInProductPackage = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
        objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeColorant = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_COLORANT ||
        objectType === objectTypes.SYSTEM_COLORANT ||
        objectType === objectTypes.ZONE_COLORANT
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeFormulaNote = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_FORMULA_NOTE ||
        objectType === objectTypes.SYSTEM_FORMULA_NOTE ||
        objectType === objectTypes.ZONE_FORMULA_NOTE
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeImage = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.GENERIC_IMAGE ||
        objectType === objectTypes.ICON ||
        objectType === objectTypes.IMAGE ||
        objectType === objectTypes.IMAGE_DIRECTORY
    ) {
        return true;
    }

    return false;
};

export const isObjectTypePriceGroup = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.SYSTEM_PRICE_GROUP || objectType === objectTypes.ZONE_PRICE_GROUP) {
        return true;
    }

    return false;
};

export const isObjectTypePriceMargin = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.SYSTEM_PRICE_MARGIN || objectType === objectTypes.ZONE_PRICE_MARGIN) {
        return true;
    }

    return false;
};

export const isObjectTypeRedlikeOption = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_REDLIKE_OPTION ||
        objectType === objectTypes.SYSTEM_REDLIKE_OPTION ||
        objectType === objectTypes.ZONE_REDLIKE_OPTION
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeOption = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.GLOBAL_OPTION || objectType === objectTypes.USER_OPTION) {
        return true;
    }

    return false;
};

export const isObjectTypeExportImport = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.EXPORT_TASK || objectType === objectTypes.IMPORT_TASK) {
        return true;
    }

    return false;
};

export const hasObjectTypeProductFile = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_BASE_IN_PRODUCT ||
        objectType === objectTypes.MASTER_PRODUCT ||
        objectType === objectTypes.MASTER_PRODUCT_DUPLICATE ||
        objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT ||
        objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
        objectType === objectTypes.SYSTEM_PRODUCT ||
        objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE ||
        objectType === objectTypes.ZONE_BASE_IN_PRODUCT ||
        objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE ||
        objectType === objectTypes.ZONE_PRODUCT ||
        objectType === objectTypes.ZONE_PRODUCT_DUPLICATE
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeSystemZone = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.SYSTEM_ZONE) {
        return true;
    }

    return false;
};

export const isObjectTypeUser = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.USER) {
        return true;
    }

    return false;
};

export const isObjectTypeDatabase = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.DATABASE) {
        return true;
    }

    return false;
};

export const isObjectTypeWebZone = (objectType: objectTypes.ObjectType): boolean => {
    if (objectType === objectTypes.WEB_ZONE) {
        return true;
    }

    return false;
};

export const isObjectTypeRedlikeUserWithUserGroups = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS ||
        objectType === objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS ||
        objectType === objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS
    ) {
        return true;
    }

    return false;
};

export const getTableConstantFromObjectType = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.MASTER_BASE_IN_PRODUCT:
            return menuConstants.TABLE_MASTER_PRODUCTS_BASES;
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
            return menuConstants.TABLE_SYSTEM_PRODUCTS_BASES;
        case objectTypes.ZONE_BASE_IN_PRODUCT:
            return menuConstants.TABLE_ZONE_PRODUCTS_BASES;
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
            return menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES;
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE:
            return menuConstants.TABLE_ZONE_PRODUCTS_BASE_PACKAGES;
        case objectTypes.SYSTEM_COLORANT:
            return menuConstants.TABLE_SYSTEM_COLORANTS;
        case objectTypes.ZONE_COLORANT:
            return menuConstants.TABLE_ZONE_COLORANTS;
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
            return menuConstants.TABLE_SYSTEM_COLORANT_PACKAGES;
        case objectTypes.ZONE_COLORANT_PACKAGE:
            return menuConstants.TABLE_ZONE_COLORANT_PACKAGES;
        case objectTypes.MASTER_FANDECK:
            return menuConstants.TABLE_MASTER_FANDECKS;
        case objectTypes.SYSTEM_FANDECK:
            return menuConstants.TABLE_SYSTEM_FANDECKS;
        case objectTypes.ZONE_FANDECK:
            return menuConstants.TABLE_ZONE_FANDECKS;
        case objectTypes.MASTER_PRODUCT:
            return menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS;
        case objectTypes.SYSTEM_PRODUCT:
            return menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS;
        case objectTypes.ZONE_PRODUCT:
            return menuConstants.TABLE_ZONE_PRODUCTS_PRODUCTS;
        default:
            return "";
    }
};

export const isObjectTypeDuplicateProduct = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_PRODUCT_DUPLICATE ||
        objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE ||
        objectType === objectTypes.ZONE_PRODUCT_DUPLICATE
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeFormulaColorant = (objectType: objectTypes.ObjectType): boolean => {
    if (
        objectType === objectTypes.MASTER_FORMULA_COLORANT ||
        objectType === objectTypes.SYSTEM_FORMULA_COLORANT ||
        objectType === objectTypes.ZONE_FORMULA_COLORANT
    ) {
        return true;
    }

    return false;
};

export const isObjectTypeFormula = (objectType: objectTypes.ObjectType): boolean => {
    return (
        objectType === objectTypes.MASTER_FORMULA ||
        objectType === objectTypes.SYSTEM_FORMULA ||
        objectType === objectTypes.ZONE_FORMULA
    );
};
