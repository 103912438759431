import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Fandeck } from "../../../types/fandeck";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { SystemFandeckScene } from "../../../components/scenes/system/SystemFandeckScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    fandeckTableConstant: string;
    fandeckList: Array<Fandeck>;
    fandeckActiveList: Array<Fandeck>;
    fandeckLoading: boolean;
    fandeckButtonLoading: boolean;
    fandeckAvailableCount: number | null;
    fandeckColumnOrder: Array<propertyConstants.Property>;
    fandeckColumnVisibility: Record<propertyConstants.Property, boolean>;
    fandeckColumnWidth: Record<propertyConstants.Property, number>;
    fandeckOffset: number;
    fandeckPage: number;
    fandeckRowCount: number;
    fandeckRowCountCustom: boolean;
    fandeckSceneTableSize: Record<string, any> | null;
    fandeckSearch: string | null;
    fandeckSearchParams: Record<propertyConstants.Property, string>;
    fandeckShowFilterRow: boolean;
    fandeckShowGlobalSearch: boolean;
    fandeckSortingAsc: boolean;
    fandeckSortingCriterion: propertyConstants.Property | null;
    fandeckTotalCount: number | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_FANDECKS
    ),
    fandeckList: state.fandeck.systemList,
    fandeckActiveList: state.fandeck.systemActiveList,
    fandeckTableConstant: menuConstants.TABLE_SYSTEM_FANDECKS,
    fandeckLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEM_FANDECKS ||
            request.method === methods.METHOD_DELETE_SYSTEM_FANDECK
    ),
    fandeckButtonLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_SYSTEM_FANDECK_BY_ID ||
            request.method === methods.METHOD_EDIT_SYSTEM_FANDECK
    ),
    fandeckAvailableCount: state.fandeck.systemCountAvailable,
    fandeckColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckOffset: state.fandeck.systemOffset,
    fandeckPage: state.fandeck.systemPage,
    fandeckRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_SYSTEM_FANDECKS
    ),
    fandeckSearch: state.fandeck.systemSearch,
    fandeckSearchParams: state.fandeck.systemSearchParams,
    fandeckShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_FANDECKS
    ),
    fandeckSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_FANDECKS
        )
    ),
    fandeckSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_FANDECKS
        )
    ),
    fandeckTotalCount: state.fandeck.systemCountTotal,
    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_SYSTEM_FANDECKS
    )
});

export const SystemFandeckSceneContainer = connect(mapStateToProps)(SystemFandeckScene);
