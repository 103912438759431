import * as objectTypes from "../constants/objectTypes";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";
import { User } from "./user";

export class UserGroup {
    id: number;
    name: string;
    key: string;
    priority: number;
    canUpdate: boolean;
    canDelete: boolean;

    constructor(id: number, name: string, key: string, priority: number, canUpdate: boolean, canDelete: boolean) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.priority = priority;
        this.canUpdate = canUpdate;
        this.canDelete = canDelete;
    }
}

export function mapUserGroup(data: Record<string, any>, loggedUser: User | null): UserGroup | null {
    try {
        const canView =
            !loggedUser ||
            privilegesHelper.isSuperadmin(loggedUser) ||
            data?.translation !== privilegeConstants.USER_GROUP_SUPERADMINS;

        if (canView) {
            return new UserGroup(data.id, data.name, data.translation, data.priority, data.canEdit, data.canDelete);
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export function mapRedlikeUserGroup(data: Record<string, any>): UserGroup | null {
    try {
        return new UserGroup(data.id, data.name, data.translation, data.priority, !data.default, !data.default);
    } catch (e) {
        return null;
    }
}

export function mapRedlikeUserGroups(data: Array<any>): Array<UserGroup> {
    const userGroups = [];
    let userGroup;

    for (const item of data) {
        userGroup = mapRedlikeUserGroup(item);

        if (userGroup !== null) {
            userGroups.push(userGroup);
        }
    }

    return userGroups;
}

export function updateRedlikeUserGroup(
    oldUserGroup: UserGroup | null,
    updatedData: Record<string, any>
): UserGroup | null {
    if (oldUserGroup === null) {
        return null;
    }

    try {
        return new UserGroup(
            oldUserGroup.id,
            updatedData.name ? updatedData.name : oldUserGroup.name,
            oldUserGroup.key,
            updatedData.priority ? updatedData.priority : oldUserGroup.priority,
            oldUserGroup.canUpdate,
            oldUserGroup.canDelete
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyUserGroup(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_KEY]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_CAN_UPDATE]: "",
        [propertyConstants.PROPERTY_CAN_DELETE]: ""
    };
}

export function generateUserGroupData(userGroupList: Array<UserGroup>): Array<any> {
    const data: Array<any> = [];

    let userGroup: UserGroup;

    for (userGroup of userGroupList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: userGroup.id,
            [propertyConstants.PROPERTY_NAME]: userGroup.name,
            [propertyConstants.PROPERTY_KEY]: userGroup.key,
            [propertyConstants.PROPERTY_PRIORITY]: userGroup.priority,
            [propertyConstants.PROPERTY_CAN_UPDATE]: userGroup.canUpdate,
            [propertyConstants.PROPERTY_CAN_DELETE]: userGroup.canDelete
        });
    }

    return data;
}

export function generateUserGroupHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_INTEGER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
