import * as generalHelper from "../../helpers/generalHelper";
import * as importExportConstants from "../../constants/entityConstants/importExportConstants";
import * as inputConstants from "../../constants/inputConstants";
import * as keyCodes from "../../constants/keyCodes";
import * as methods from "../../constants/serverMethods";
import * as modalHelper from "../../helpers/modalHelper";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionHelper from "../../helpers/optionHelper";
import * as privilegeConstants from "../../constants/privilegeConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as translationHelper from "../../helpers/translationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as validationHelper from "../../helpers/validationHelper";

import { Database, createEmptyDatabase, generateDatabaseHeadings } from "../../types/database";
import React, { Component } from "react";
import { System, createEmptySystem, generateSystemHeadings } from "../../types/system";
import { Zone, createEmptyZone, generateZoneHeadings } from "../../types/zone";
import { createEmptyImportTask, updateImportTask } from "../../types/importTask";
import { AppState } from "../../reducers";
import { Company } from "../../types/company";
import { Currency } from "../../types/currency";
import { Dispatch } from "redux";
import { ImportFile } from "../../types/importFile";
import { ImportOption } from "../../types/importOption";
import { ImportType } from "../../types/importType";
import { ModalLabelInputButtonContainer } from "../general/modal/ModalLabelInputButton";
import { ModalParams } from "../../types/modalParams";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { ModalSubHeading } from "../general/labels/ModalSubHeading";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { ServerRequest } from "../../types/serverRequest";
import { TableHeading } from "../../types/tableHeading";

import { connect } from "react-redux";
import imgClose from "../../resources/img/icons/close.svg";
import { t as translate } from "react-i18nify";

type ContainerProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type PropsType = {
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
    companyList: Array<Company>;
    databaseSystemZoneList: Array<Database>;
    importTypeList: Array<ImportType>;
    loading: boolean;
    privileges: Record<string, number>;
};

type DispatchType = {
    addItem(objectType: objectTypes.ObjectType, params: any): any;
    openModal(type: ModalType, params: any): void;
};

type OwnProps = PropsType & DispatchType;

type Props = ContainerProps & OwnProps;

type State = {
    object: any;
};
// TODO check whole file
class ImportModal extends Component<Props, State> {
    state: State = {
        object: createEmptyImportTask(
            this.props.importTypeList,
            this.props.databaseSystemZoneList,
            this.props.activeDatabase,
            this.props.activeSystem,
            this.props.activeZone
        )
    };

    componentDidMount(): void {
        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // Prepare system and zone IDs
        const newSystemId =
            this.state.object?.[propertyConstants.PROPERTY_SYSTEM_ID] !== ""
                ? this.state.object?.[propertyConstants.PROPERTY_SYSTEM_ID]
                : undefined;
        const newZoneId =
            this.state.object?.[propertyConstants.PROPERTY_ZONE_ID] !== ""
                ? this.state.object?.[propertyConstants.PROPERTY_ZONE_ID]
                : undefined;

        // Handling change of format = import task type ID, recalculating description, files and options
        if (
            this.state.object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID] !==
            prevState.object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
        ) {
            const importType = this.state.object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
                ? generalHelper.getObjectById(
                      this.props.importTypeList,
                      this.state.object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
                  )
                : null;

            const updatedImportTask = updateImportTask(
                this.props.importTypeList,
                this.props.databaseSystemZoneList,
                this.state.object,
                undefined,
                undefined,
                undefined,
                undefined,
                importType?.[propertyConstants.PROPERTY_FILES],
                importType?.[propertyConstants.PROPERTY_OPTIONS]
            );

            this.setState({ object: { ...this.state.object, ...updatedImportTask } });
        }

        // Handling change of database, recalculating systems and zones
        if (
            this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] !==
            prevState.object?.[propertyConstants.PROPERTY_DATABASE_ID]
        ) {
            const updatedImportTask = updateImportTask(
                this.props.importTypeList,
                this.props.databaseSystemZoneList,
                prevState.object,
                undefined,
                this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] || undefined
            );

            this.setState({
                object: {
                    ...this.state.object,
                    ...updatedImportTask
                }
            });
        }

        // Handling change of system, recalculating zones
        if (
            this.state.object?.[propertyConstants.PROPERTY_SYSTEM_ID] !==
            prevState.object?.[propertyConstants.PROPERTY_SYSTEM_ID]
        ) {
            const updatedImportTask = updateImportTask(
                this.props.importTypeList,
                this.props.databaseSystemZoneList,
                prevState.object,
                undefined,
                this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] || undefined,
                newSystemId
            );

            this.setState({
                object: {
                    ...this.state.object,
                    ...updatedImportTask
                }
            });
        }

        // Handling change of zone
        if (
            this.state.object?.[propertyConstants.PROPERTY_ZONE_ID] !==
            prevState.object?.[propertyConstants.PROPERTY_ZONE_ID]
        ) {
            const updatedImportTask = updateImportTask(
                this.props.importTypeList,
                this.props.databaseSystemZoneList,
                prevState.object,
                undefined,
                this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] || undefined,
                newSystemId,
                newZoneId
            );

            this.setState({
                object: {
                    ...this.state.object,
                    ...updatedImportTask
                }
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    handleModalKeyDown = (event: Record<string, any>): void => {
        const { closeModal, type } = this.props;

        if (event.key === keyCodes.ENTER && event.target.type !== "textarea") {
            closeModal(type);
        }
        if (event.key === keyCodes.ESCAPE) {
            closeModal(type);
        }
    };

    handleConfirmationButtonClick = (): void => {
        const { data, validationList } = validationHelper.validateItem(
            this.props.params.objectType,
            modalTypes.ADD_MODAL,
            this.props.type,
            [],
            [],
            this.state.object,
            Object.keys(this.state.object).map((item) => item)
        );

        if (validationList.length === 0) {
            this.props.addItem(this.props.params.objectType, data);
        }
    };

    getModalClassName = (): string => {
        return "modal simple-modal";
    };

    getFileExtensions = (extensions: Array<string> = []): string => {
        const validation = extensions.map((item) => item.replace(/\*/g, "")).join(", ");
        return validation;
    };

    getFileInput = (fileParams: ImportFile): JSX.Element => {
        return (
            <div key={fileParams[propertyConstants.PROPERTY_KEY]} className="row">
                <ModalLabelInputButtonContainer
                    modalId={this.props.id}
                    editable={true}
                    required={fileParams[propertyConstants.PROPERTY_MANDATORY] || false}
                    objectType={this.props.params.objectType}
                    type={tableConstants.TABLE_TYPE_FILE}
                    labelCaption={fileParams[propertyConstants.PROPERTY_NAME] || ""}
                    inputClassName={"files-input"}
                    inputType={fileParams[propertyConstants.PROPERTY_FILE_TYPE]}
                    inputName={fileParams[propertyConstants.PROPERTY_KEY]}
                    fileValidation={this.getFileExtensions(fileParams[propertyConstants.PROPERTY_FILE_EXTENSIONS])}
                    fileCallback={(key: string, files: any): void => {
                        const oldFileList = this.state.object?.[propertyConstants.PROPERTY_FILES] || [];
                        const file = generalHelper.getObjectByKey(
                            oldFileList,
                            fileParams[propertyConstants.PROPERTY_KEY]
                        );
                        const fileIndex = oldFileList.indexOf(file);
                        const newFileList = [...oldFileList];

                        if (file && fileIndex !== -1) {
                            file[propertyConstants.PROPERTY_FILES] = files;
                            newFileList[fileIndex] = file;
                        }

                        this.setState({
                            object: {
                                ...this.state.object,
                                [propertyConstants.PROPERTY_FILES]: newFileList
                            }
                        });
                    }}
                />
            </div>
        );
    };

    getOptionInputClassName = (inputType: string): string => {
        switch (inputType) {
            case inputConstants.PARAMETER_TYPE_CHECK_BOX:
                return "input-type-bool";
            case inputConstants.UPLOAD_TYPE_FILE:
            case inputConstants.UPLOAD_TYPE_FILES:
            case inputConstants.UPLOAD_TYPE_DIRECTORY:
                return "";
            case inputConstants.PARAMETER_TYPE_RADIO_BUTTON:
                return "input-type-radio-button";
            case inputConstants.PARAMETER_TYPE_SELECT_BOX:
                return "modal-select";
            default:
                return "type-input";
        }
    };

    getOptionInput = (optionParams: ImportOption): JSX.Element | null => {
        const tableType = modalHelper.getTableType(optionParams[propertyConstants.PROPERTY_OPTION_TYPE]);

        if (!tableType) {
            return null;
        }

        return (
            <div key={optionParams[propertyConstants.PROPERTY_KEY]} className="row">
                <ModalLabelInputButtonContainer
                    modalId={this.props.id}
                    editable={true}
                    required={optionParams[propertyConstants.PROPERTY_MANDATORY] || false}
                    type={tableType}
                    objectType={objectTypes.IMPORT_TASK}
                    labelClassName={"form-label"}
                    labelCaption={optionParams[propertyConstants.PROPERTY_NAME] || ""}
                    inputType={optionParams[propertyConstants.PROPERTY_OPTION_TYPE]}
                    inputName={optionParams[propertyConstants.PROPERTY_KEY]}
                    inputClassName={this.getOptionInputClassName(optionParams[propertyConstants.PROPERTY_OPTION_TYPE])}
                    inputValue={optionParams[propertyConstants.PROPERTY_VALUE]}
                    inputOptions={optionParams[propertyConstants.PROPERTY_VALUES]}
                    // inputValidation={optionParams[propertyConstants.PROPERTY_VALIDATION] || ""}
                    inputCallbackChange={(value: any): void => {
                        const oldOptionList = this.state.object?.[propertyConstants.PROPERTY_OPTIONS] || [];
                        const option = generalHelper.getObjectByKey(
                            oldOptionList,
                            optionParams[propertyConstants.PROPERTY_KEY]
                        );
                        const optionIndex = oldOptionList.indexOf(option);
                        const newOptionList = [...oldOptionList];

                        if (option && optionIndex !== -1) {
                            option[propertyConstants.PROPERTY_VALUE] = value;
                            newOptionList[optionIndex] = option;
                        }

                        this.setState({
                            object: {
                                ...this.state.object,
                                [propertyConstants.PROPERTY_OPTIONS]: newOptionList
                            }
                        });
                    }}
                />
            </div>
        );
    };

    getConfirmationButton = (): JSX.Element => {
        if (this.props.loading) {
            return (
                <button className="btn-info pull-right disabled" autoFocus={true} disabled>
                    {translate("general.ok")}
                </button>
            );
        }

        return (
            <button
                className="btn-info pull-right"
                autoFocus={true}
                onClick={(): void => this.handleConfirmationButtonClick()}
            >
                {translate("general.ok")}
            </button>
        );
    };

    getCancelButton = (): JSX.Element => {
        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    this.props.closeModal(this.props.type);
                }}
            >
                {translate("general.cancel")}
            </button>
        );
    };

    getFormat = (): JSX.Element => {
        const { object } = this.state;

        const value =
            object && object[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
                ? object[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID].toString()
                : "";
        const optionList = generalHelper.getOptionsForSelectbox(objectTypes.IMPORT_TYPE, this.props.importTypeList);

        return (
            <ModalSelectbox
                modalId={this.props.id}
                className={""}
                editable={true}
                required={true}
                value={value}
                options={optionList}
                autoselect={false}
                callback={(event: any, value: string): void => {
                    const valueNum = Number(value);
                    this.setState({
                        object: {
                            ...object,
                            [propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]:
                                !isNaN(valueNum) && value !== null && value !== "" ? valueNum : ""
                        }
                    });
                }}
            />
        );
    };

    getDescription = (): JSX.Element | null => {
        const { object } = this.state;
        const importType = object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
            ? generalHelper.getObjectById(
                  this.props.importTypeList,
                  object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
              )
            : null;

        if (importType?.[propertyConstants.PROPERTY_DESCRIPTION]) {
            return (
                <ModalSubHeading
                    className="info-description"
                    objectType={this.props.params.objectType}
                    labelCaption={translationHelper.getImportFormatTranslation(
                        importType[propertyConstants.PROPERTY_KEY],
                        importType[propertyConstants.PROPERTY_DESCRIPTION]
                    )}
                />
            );
        }

        return null;
    };

    generateDatabaseTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const { privileges } = this.props;

        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD,
            propertyConstants.PROPERTY_COMPANY_ID,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_DEFAULT_DATA
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COMPANY_ID,
            propertyConstants.PROPERTY_DEFAULT_DATA,
            propertyConstants.PROPERTY_MASTER_ONLY,
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        const visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_DATABASE_UUID_INSERT])) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_UUID);
            editableList.push(propertyConstants.PROPERTY_UUID);
        }

        const orderList: Array<propertyConstants.Property> = [...visibleList];

        return generateDatabaseHeadings(
            objectTypes.DATABASE,
            modalType,
            this.props.companyList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateDatabaseModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            objectTypes.DATABASE,
            translate("database.createNewDatabase"),
            createEmptyDatabase(this.props.companyList),
            this.generateDatabaseTableHeadings(modalTypes.MODAL_DATABASES_EMPTY_ADD)
        );
    };

    generateSystemTableHeadings = (): Array<TableHeading> => {
        const { privileges } = this.props;

        const propertyList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        const visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_UPDATE])) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_UUID);
            editableList.push(propertyConstants.PROPERTY_UUID);
        }

        const orderList = [...visibleList];

        return generateSystemHeadings(
            objectTypes.SYSTEM,
            [],
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateSystemModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            objectTypes.SYSTEM,
            translate("system.createNewSystem"),
            createEmptySystem(this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] ?? null),
            this.generateSystemTableHeadings()
        );
    };

    generateZoneTableHeadings = (currencyList: Array<Currency>): Array<TableHeading> => {
        const { privileges } = this.props;

        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_PASSWORD,
            propertyConstants.PROPERTY_CURRENCY_ID,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME,
            propertyConstants.PROPERTY_CHANGELOG
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_CURRENCY_ID,
            propertyConstants.PROPERTY_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        const visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_ZONE_UUID_UPDATE])) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_UUID);
            editableList.push(propertyConstants.PROPERTY_UUID);
        }

        const orderList = [...visibleList];

        return generateZoneHeadings(
            objectTypes.ZONE,
            currencyList,
            [],
            [],
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateZoneModalParams = (currencyList: Array<Currency>): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            objectTypes.ZONE,
            translate("zone.addZone"),
            createEmptyZone(
                this.state.object?.[propertyConstants.PROPERTY_SYSTEM_ID] ?? null,
                this.state.object?.[propertyConstants.PROPERTY_DATABASE_ID] ?? null
            ),
            this.generateZoneTableHeadings(currencyList)
        );
    };

    getMasterSystemZone = (): JSX.Element | null => {
        const { openModal } = this.props;
        const { object } = this.state;

        const importType = object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
            ? generalHelper.getObjectById(
                  this.props.importTypeList,
                  object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
              )
            : null;

        const activeDatabase = object?.[propertyConstants.PROPERTY_DATABASE_ID]
            ? generalHelper.getObjectById(
                  this.props.databaseSystemZoneList,
                  object[propertyConstants.PROPERTY_DATABASE_ID]
              )
            : null;
        const systemList = activeDatabase ? activeDatabase.systemList : [];
        const currencyList = activeDatabase ? activeDatabase.currencyList : [];
        const activeSystem = object?.[propertyConstants.PROPERTY_SYSTEM_ID]
            ? generalHelper.getObjectById(systemList, object[propertyConstants.PROPERTY_SYSTEM_ID])
            : null;
        const zoneList = activeSystem ? activeSystem.zoneList : [];
        const tableType = modalHelper.getTableType(inputConstants.PARAMETER_TYPE_SELECT_BOX);

        if (!tableType) {
            return null;
        }

        return (
            <div className="input-rows">
                <div className="row">
                    <ModalLabelInputButtonContainer
                        modalId={this.props.id}
                        className={"input-button-container"}
                        editable={true}
                        required={true}
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={objectTypes.IMPORT_TASK}
                        labelClassName={"form-label"}
                        labelCaption={translate("database.master")}
                        inputClassName={"button-select"}
                        inputName={""}
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputValue={object?.[propertyConstants.PROPERTY_DATABASE_ID]}
                        inputOptions={generalHelper.getOptionsForSelectbox(
                            objectTypes.DATABASE,
                            this.props.databaseSystemZoneList
                        )}
                        inputCallbackChange={(value: string): void => {
                            const valueNum = Number(value);
                            this.setState({
                                object: {
                                    ...object,
                                    [propertyConstants.PROPERTY_DATABASE_ID]: !isNaN(valueNum) ? valueNum : ""
                                }
                            });
                        }}
                        buttonVisible={modalHelper.getButtonVisibility(propertyConstants.PROPERTY_DATABASE_ID)}
                        buttonClassName={"btn-info form-label"}
                        buttonEditable={modalHelper.getButtonEditing(propertyConstants.PROPERTY_DATABASE_ID, object)}
                        buttonCaption={translate("general.createNew")}
                        buttonCallback={(): void => {
                            openModal(modalTypes.MODAL_DATABASES_EMPTY_ADD, this.generateDatabaseModalParams());
                        }}
                    />
                </div>
                <div className="row">
                    <ModalLabelInputButtonContainer
                        modalId={this.props.id}
                        className={"input-button-container"}
                        editable={
                            importType?.[propertyConstants.PROPERTY_DATA_TYPE] !==
                            importExportConstants.IMPORT_MASTER_ONLY
                        }
                        required={
                            importType?.[propertyConstants.PROPERTY_DATA_TYPE] ===
                            importExportConstants.IMPORT_ZONE_ONLY
                        }
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={objectTypes.IMPORT_TASK}
                        labelClassName={"form-label"}
                        labelCaption={translate("system.system")}
                        inputName={""}
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputClassName={"button-select"}
                        inputValue={object?.[propertyConstants.PROPERTY_SYSTEM_ID]}
                        inputOptions={generalHelper.getOptionsForSelectbox(objectTypes.SYSTEM, systemList)}
                        inputCallbackChange={(value: string): void => {
                            const valueNum =
                                value !== null && value !== "" && !isNaN(Number(value)) ? Number(value) : 0;
                            this.setState({
                                object: {
                                    ...object,
                                    [propertyConstants.PROPERTY_SYSTEM_ID]: valueNum
                                }
                            });
                        }}
                        buttonVisible={modalHelper.getButtonVisibility(propertyConstants.PROPERTY_SYSTEM_ID)}
                        buttonClassName={"btn-info form-label"}
                        buttonEditable={modalHelper.getButtonEditing(propertyConstants.PROPERTY_SYSTEM_ID, object)}
                        buttonCaption={translate("general.createNew")}
                        buttonCallback={(): void => {
                            openModal(modalTypes.MODAL_SYSTEMS_ADD, this.generateSystemModalParams());
                        }}
                    />
                </div>
                <div className="row">
                    <ModalLabelInputButtonContainer
                        modalId={this.props.id}
                        className={"input-button-container"}
                        editable={
                            importType?.[propertyConstants.PROPERTY_DATA_TYPE] !==
                            importExportConstants.IMPORT_MASTER_ONLY
                        }
                        required={
                            importType?.[propertyConstants.PROPERTY_DATA_TYPE] ===
                            importExportConstants.IMPORT_ZONE_ONLY
                        }
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={objectTypes.IMPORT_TASK}
                        labelClassName={"form-label"}
                        labelCaption={translate("zone.zone")}
                        inputName={""}
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputClassName={"button-select"}
                        inputValue={object?.[propertyConstants.PROPERTY_ZONE_ID]}
                        inputOptions={generalHelper.getOptionsForSelectbox(objectTypes.ZONE, zoneList)}
                        inputCallbackChange={(value: string): void => {
                            const valueNum =
                                value !== null && value !== "" && !isNaN(Number(value)) ? Number(value) : 0;
                            this.setState({
                                object: {
                                    ...object,
                                    [propertyConstants.PROPERTY_ZONE_ID]: valueNum
                                }
                            });
                        }}
                        buttonClassName={"btn-info form-label"}
                        buttonVisible={modalHelper.getButtonVisibility(propertyConstants.PROPERTY_ZONE_ID)}
                        buttonEditable={modalHelper.getButtonEditing(propertyConstants.PROPERTY_ZONE_ID, object)}
                        buttonCaption={translate("general.createNew")}
                        buttonCallback={(): void => {
                            openModal(modalTypes.MODAL_ZONES_ADD, this.generateZoneModalParams(currencyList));
                        }}
                    />
                </div>
            </div>
        );
    };

    getFixedParameters = (): JSX.Element | null => {
        const { object } = this.state;
        const importType = object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
            ? generalHelper.getObjectById(
                  this.props.importTypeList,
                  object?.[propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID]
              )
            : null;

        const tableType = modalHelper.getTableType(inputConstants.PARAMETER_TYPE_SELECT_BOX);

        if (!tableType) {
            return null;
        }

        return (
            <div className="input-rows">
                <div className="row">
                    <ModalLabelInputButtonContainer
                        modalId={this.props.id}
                        editable={
                            importType?.[propertyConstants.PROPERTY_DATA_TYPE] !==
                            importExportConstants.IMPORT_ZONE_ONLY
                        }
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={objectTypes.IMPORT_TASK}
                        required={true}
                        labelClassName={"form-label"}
                        labelCaption={translate("import.optionOldFormulas")}
                        inputName={""}
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputClassName={"modal-select"}
                        inputValue={
                            object?.[propertyConstants.PROPERTY_KEEP_OLD_FORMULAS]?.toString() ||
                            importExportConstants.DEFAULT_KEEP_OLD_FORMULAS.toString()
                        }
                        inputOptions={[
                            {
                                key: importExportConstants.KEEP_OLD_FORMULAS_KEEP_EVERYTHING.toString(),
                                value: translate("import.keepOldFormulas.keepEverything")
                            },
                            {
                                key: importExportConstants.KEEP_OLD_FORMULAS_REMOVE_FORMULAS.toString(),
                                value: translate("import.keepOldFormulas.removeFormulas")
                            },
                            {
                                key: importExportConstants.KEEP_OLD_FORMULAS_REMOVE_ALL.toString(),
                                value: translate("import.keepOldFormulas.removeAll")
                            }
                        ]}
                        inputCallbackChange={(value: string): void => {
                            this.setState({
                                object: {
                                    ...object,
                                    [propertyConstants.PROPERTY_KEEP_OLD_FORMULAS]: !isNaN(Number(value))
                                        ? Number(value)
                                        : importExportConstants.DEFAULT_KEEP_OLD_FORMULAS
                                }
                            });
                        }}
                    />
                </div>
                <div className="row">
                    <ModalLabelInputButtonContainer
                        modalId={this.props.id}
                        editable={true}
                        required={true}
                        type={tableConstants.TABLE_TYPE_BOOLEAN}
                        objectType={objectTypes.IMPORT_TASK}
                        labelClassName={"form-label"}
                        labelCaption={translate("import.optionStrict")}
                        inputName={""}
                        inputType={inputConstants.PARAMETER_TYPE_CHECK_BOX}
                        inputClassName={"checkbox-with-title"}
                        inputCaption={translate("import.optionStrictText")}
                        inputValue={object?.[propertyConstants.PROPERTY_STRICT]}
                        inputCallbackChange={(value: boolean): void => {
                            this.setState({
                                object: {
                                    ...object,
                                    [propertyConstants.PROPERTY_STRICT]: value
                                }
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    getFileSection = (): JSX.Element | null => {
        const fileParams: Array<JSX.Element> = [];
        const fileSource = this.state.object?.[propertyConstants.PROPERTY_FILES] || [];

        for (const params of fileSource) {
            fileParams.push(this.getFileInput(params));
        }

        if (fileParams.length) {
            return (
                <div className="modal-section">
                    <h3>{translate("import.sectionDataFiles")}</h3>
                    {fileParams}
                </div>
            );
        }

        return null;
    };

    getOptionSection = (): JSX.Element | null => {
        const optionParams: Array<JSX.Element | null> = [];
        const optionSource = this.state.object?.[propertyConstants.PROPERTY_OPTIONS] || [];

        for (const params of optionSource) {
            optionParams.push(this.getOptionInput(params));
        }

        if (optionParams.length) {
            return (
                <div className="modal-section">
                    <h3>{translate("import.sectionOptions")}</h3>
                    {optionParams}
                </div>
            );
        }

        return null;
    };

    getContent = (): JSX.Element => {
        return (
            <form
                onSubmit={(event): void => {
                    event.preventDefault();
                }}
            >
                <div className="modal-section">
                    {this.getFormat()}
                    {this.getDescription()}
                    {this.getMasterSystemZone()}
                    {this.getFixedParameters()}
                </div>
                {this.getFileSection()}
                {this.getOptionSection()}
            </form>
        );
    };

    render(): JSX.Element | null {
        return (
            <div
                className={this.getModalClassName()}
                style={{ zIndex: this.props.index }}
                id={`modal-${this.props.id}`}
            >
                <header className="modal-header">
                    <div className="title">{this.props.params.title}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            this.props.closeModal(this.props.type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">{this.getContent()}</div>
                <footer className="modal-footer">
                    {this.getConfirmationButton()}
                    {this.getCancelButton()}
                </footer>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): PropsType => ({
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    companyList: state.company.allList,
    databaseSystemZoneList: state.databaseSystemZone.list,
    importTypeList: state.import.importTypes,
    loading: state.server.requests.some((request: ServerRequest) => request.method === methods.METHOD_ADD_IMPORT_TASK),
    privileges: state.login.privileges
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    addItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.addItem(objectType, params)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const ImportModalContainer = connect(mapStateToProps, mapDispatchToProps)(ImportModal);
