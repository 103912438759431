import * as actionConstants from "../constants/actionTypes";
import * as fileActions from "../actions/fileActions";
import * as menuConstants from "../constants/menuConstants";
import * as methods from "../constants/serverMethods";
import * as modalTypes from "../constants/modalTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as objectTypes from "../constants/objectTypes";
import * as optionActions from "../actions/optionActions";
import * as optionHelper from "../helpers/optionHelper";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { DuplicateProduct, mapDuplicateProduct } from "../types/duplication/duplicateProduct";
import { Epic, ofType } from "redux-observable";
import { getAllItems, reloadData, saveItemsNoParams } from "../actions/universalObjectActions";

import { FileType } from "../types/fileType";
import { ServerRequest } from "../types/serverRequest";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

// TODO proc tu je i akce actionConstants.EVENT_SAVE_ZONES?
export const loadDataForScenes: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.NAVIGATION_SET_SCENE, actionConstants.EVENT_SAVE_ZONES),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            // TODO loading menu section in helper?
            let menuSection: menuConstants.MenuSection | null = null;

            if (navigationTypes.MASTER_SCENES_LIST.includes(state$.value.navigation.activeScene)) {
                menuSection = menuConstants.HEADER_MASTER;
            }
            if (navigationTypes.SYSTEM_SCENES_LIST.includes(state$.value.navigation.activeScene)) {
                menuSection = menuConstants.HEADER_SYSTEM;
            }
            if (navigationTypes.ZONE_SCENES_LIST.includes(state$.value.navigation.activeScene)) {
                menuSection = menuConstants.HEADER_ZONE;
            }

            if (menuSection) {
                const menuVisibility = optionHelper.getValueFromOptionDictionaryByKey(
                    state$.value.login.options,
                    optionsConstants.OPTION_MENU_SECTION_VISIBILITY
                );

                if (menuVisibility && menuVisibility[menuSection] !== null && !menuVisibility[menuSection]) {
                    requests.push(
                        optionActions.setDictionaryOption(
                            optionsConstants.OPTION_MENU_SECTION_VISIBILITY,
                            menuSection,
                            true
                        )
                    );
                }
            }

            switch (state$.value.navigation.activeScene) {
                case navigationTypes.COMPANY_SCENE:
                    requests.push(reloadData(objectTypes.COMPANY));
                    break;
                case navigationTypes.DATA_EXPORT_SCENE:
                    requests.push(reloadData(objectTypes.EXPORT_TASK));
                    requests.push(getAllItems(objectTypes.SYSTEM));
                    break;
                case navigationTypes.DATA_IMPORT_SCENE:
                    requests.push(reloadData(objectTypes.IMPORT_TASK));
                    break;
                case navigationTypes.DATA_MASTER_SETTINGS_SCENE:
                    requests.push(reloadData(objectTypes.DATABASE));
                    requests.push(getAllItems(objectTypes.COMPANY));
                    break;
                case navigationTypes.SERVER_MONITORING_SCENE:
                    requests.push(reloadData(objectTypes.DATABASE_MONITORING));
                    requests.push(getAllItems(objectTypes.COMPANY));
                    requests.push(getAllItems(objectTypes.DATA_SIZE));
                    break;
                case navigationTypes.DATA_SERVER_SCRIPTS_SCENE:
                    requests.push(getAllItems(objectTypes.SERVER_SCRIPTS));
                    break;
                case navigationTypes.DATA_SYSTEM_ZONE_SETTINGS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM));
                    requests.push(getAllItems(objectTypes.CURRENCY));
                    requests.push(
                        getAllItems(objectTypes.STATIC_SQL, {
                            fileTypeIds: state$.value.file.staticSqlFileTypeList.map((item: FileType) => item.id)
                        })
                    );
                    break;
                case navigationTypes.DEBUG_SCENE:
                    requests.push(serverRequest(methods.METHOD_GET_VERSION, methods.METHOD_GET_VERSION));
                    requests.push(serverRequest(methods.METHOD_GET_SQLITE_INFO, methods.METHOD_GET_SQLITE_INFO));
                    break;
                case navigationTypes.GENERIC_BARCODES_SCENE:
                    requests.push(reloadData(objectTypes.BARCODE));
                    requests.push(getAllItems(objectTypes.SYSTEM));
                    break;
                case navigationTypes.GENERIC_CURRENCIES_SCENE:
                    requests.push(reloadData(objectTypes.CURRENCY));
                    break;
                case navigationTypes.GENERIC_IMAGES_SCENE:
                    requests.push(reloadData(objectTypes.GENERIC_IMAGE));
                    requests.push(getAllItems(objectTypes.IMAGE_CATEGORY));
                    break;
                case navigationTypes.GENERIC_PACKAGES_SCENE:
                    requests.push(reloadData(objectTypes.PACKAGE));
                    requests.push(getAllItems(objectTypes.UNIT));
                    break;
                case navigationTypes.GENERIC_PRODUCT_SHEETS_SCENE:
                    requests.push(reloadData(objectTypes.PRODUCT_SHEET));
                    requests.push(getAllItems(objectTypes.FILE_CATEGORY));
                    break;
                case navigationTypes.GENERIC_STATIC_SQL_SCENE:
                    requests.push(reloadData(objectTypes.STATIC_SQL));
                    break;
                case navigationTypes.GENERIC_UNITS_SCENE:
                    requests.push(reloadData(objectTypes.UNIT));
                    break;
                case navigationTypes.MASTER_BASES_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_BASE));
                    break;
                case navigationTypes.MASTER_COLORS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.MASTER_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.MASTER_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.SPECTRO_TYPE));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    break;
                case navigationTypes.MASTER_COLORANTS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_COLORANT));
                    break;
                case navigationTypes.MASTER_FANDECKS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_FANDECK));
                    break;
                case navigationTypes.MASTER_FORMULAS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.MASTER_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.MASTER_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    break;
                case navigationTypes.MASTER_FORMULA_NOTES_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_FORMULA_NOTE));
                    break;
                case navigationTypes.MASTER_PRODUCTS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_PRODUCT));
                    requests.push(getAllItems(objectTypes.MASTER_BASE, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FILE_CATEGORY));
                    break;
                case navigationTypes.MASTER_REDLIKE_OPTIONS_SCENE:
                    requests.push(getAllItems(objectTypes.MASTER_REDLIKE_OPTION));
                    break;
                case navigationTypes.MASTER_REDLIKE_USERS_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_REDLIKE_USER));
                    requests.push(
                        getAllItems(objectTypes.REDLIKE_USER_GROUP, {
                            orderBy: propertyConstants.PROPERTY_PRIORITY,
                            asc: false
                        })
                    );
                    requests.push(getAllItems(objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS));
                    break;
                case navigationTypes.MASTER_REDLIKE_PRIVILEGES_SCENE:
                    requests.push(reloadData(objectTypes.MASTER_REDLIKE_USER_GROUP));
                    break;
                case navigationTypes.SYSTEM_BASES_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_BASE));
                    break;
                case navigationTypes.SYSTEM_COLORANTS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_COLORANT));
                    requests.push(getAllItems(objectTypes.CURRENCY));
                    requests.push(getAllItems(objectTypes.SYSTEM_CURRENCY));
                    requests.push(getAllItems(objectTypes.SYSTEM_PRICE_OPTION));
                    requests.push(getAllItems(objectTypes.UNIT));
                    break;
                case navigationTypes.SYSTEM_COLORS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.SYSTEM_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.SYSTEM_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    // requests.push(getAllItems(objectTypes.SPECTRO_TYPE));
                    break;
                case navigationTypes.SYSTEM_FANDECKS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_FANDECK));
                    break;
                case navigationTypes.SYSTEM_FORMULAS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.SYSTEM_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.SYSTEM_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    break;
                case navigationTypes.SYSTEM_FORMULA_NOTES_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_FORMULA_NOTE));
                    break;
                case navigationTypes.SYSTEM_PRICES_SCENE:
                    requests.push(getAllItems(objectTypes.CURRENCY));
                    requests.push(getAllItems(objectTypes.SYSTEM_CURRENCY));
                    requests.push(getAllItems(objectTypes.SYSTEM_PACKAGE));
                    requests.push(getAllItems(objectTypes.SYSTEM_PRICE_MARGIN));
                    break;
                case navigationTypes.SYSTEM_PRODUCT_GROUPS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_PRODUCT_GROUP));
                    break;
                case navigationTypes.SYSTEM_PRODUCTS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_PRODUCT));
                    requests.push(getAllItems(objectTypes.CURRENCY));
                    requests.push(getAllItems(objectTypes.FILE_CATEGORY));
                    requests.push(getAllItems(objectTypes.SYSTEM_CURRENCY));
                    requests.push(getAllItems(objectTypes.SYSTEM_PRICE_OPTION));
                    requests.push(getAllItems(objectTypes.UNIT));
                    break;
                case navigationTypes.SYSTEM_REDLIKE_OPTIONS_SCENE:
                    requests.push(getAllItems(objectTypes.SYSTEM_REDLIKE_OPTION));
                    break;
                case navigationTypes.SYSTEM_REDLIKE_USERS_SCENE:
                    requests.push(reloadData(objectTypes.SYSTEM_REDLIKE_USER));
                    requests.push(
                        getAllItems(objectTypes.REDLIKE_USER_GROUP, {
                            orderBy: propertyConstants.PROPERTY_PRIORITY,
                            asc: false
                        })
                    );
                    requests.push(getAllItems(objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS));
                    break;
                case navigationTypes.USER_ACTION_HISTORY_SCENE:
                    requests.push(reloadData(objectTypes.USER_ACTION_HISTORY));
                    break;
                case navigationTypes.USERS_SCENE:
                    requests.push(reloadData(objectTypes.USER));
                    requests.push(reloadData(objectTypes.USER_GROUP));
                    requests.push(getAllItems(objectTypes.USER_GROUP));
                    requests.push(getAllItems(objectTypes.COMPANY));
                    break;
                case navigationTypes.GLOBAL_OPTIONS_SCENE:
                    requests.push(getAllItems(objectTypes.GLOBAL_OPTION));
                    break;
                case navigationTypes.USER_OPTIONS_SCENE:
                    requests.push(getAllItems(objectTypes.USER_OPTION));
                    break;
                case navigationTypes.ZONE_BASES_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_BASE));
                    break;
                case navigationTypes.ZONE_COLORANTS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_COLORANT));
                    requests.push(getAllItems(objectTypes.UNIT));
                    requests.push(getAllItems(objectTypes.ZONE_PRICE_OPTION));
                    break;
                case navigationTypes.ZONE_COLORS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.ZONE_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.ZONE_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    // requests.push(getAllItems(objectTypes.SPECTRO_TYPE));
                    break;
                case navigationTypes.ZONE_FANDECKS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_FANDECK));
                    break;
                case navigationTypes.ZONE_FORMULAS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_FANDECK_IN_COLOR));
                    requests.push(getAllItems(objectTypes.ZONE_PRODUCT_IN_COLOR, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.ZONE_COLORANT, { simpleData: true }));
                    requests.push(getAllItems(objectTypes.FORMULA_COLORANT_UNIT));
                    break;
                case navigationTypes.ZONE_FORMULA_NOTES_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_FORMULA_NOTE));
                    break;
                case navigationTypes.ZONE_PRICES_SCENE: {
                    const activeZoneLoading = state$.value.server.requests.some(
                        (request: ServerRequest) =>
                            request.method === methods.METHOD_SET_ACTIVE_ZONE ||
                            request.method === methods.METHOD_SET_ACTIVE_SYSTEM
                    );

                    if (state$.value.currency.zoneCurrency && !activeZoneLoading && state$.value.zone.active) {
                        requests.push(
                            getAllItems(objectTypes.ZONE_PRICE_GENERIC),
                            getAllItems(objectTypes.ZONE_PRICE_GROUP),
                            getAllItems(objectTypes.ZONE_PRICE_MARGIN),
                            getAllItems(objectTypes.ZONE_PRICE_OPTION),
                            getAllItems(objectTypes.ZONE_PACKAGE)
                        );
                    }
                    break;
                }
                case navigationTypes.ZONE_PRODUCTS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_PRODUCT));
                    requests.push(getAllItems(objectTypes.FILE_CATEGORY));
                    requests.push(getAllItems(objectTypes.UNIT));
                    requests.push(getAllItems(objectTypes.ZONE_PRICE_OPTION));
                    break;
                case navigationTypes.ZONE_PRODUCT_GROUPS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_PRODUCT_GROUP));
                    break;
                case navigationTypes.ZONE_REDLIKE_OPTIONS_SCENE:
                    requests.push(getAllItems(objectTypes.ZONE_REDLIKE_OPTION));
                    break;
                case navigationTypes.ZONE_REDLIKE_USERS_SCENE:
                    requests.push(reloadData(objectTypes.ZONE_REDLIKE_USER));
                    requests.push(
                        getAllItems(objectTypes.REDLIKE_USER_GROUP, {
                            orderBy: propertyConstants.PROPERTY_PRIORITY,
                            asc: false
                        })
                    );
                    requests.push(getAllItems(objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS));
                    break;
                default:
                    break;
            }

            return requests;
        })
    );

export const loadDataForWizards: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.NAVIGATION_SET_WIZARD),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.wizard) {
                case navigationTypes.ADD_SYSTEM_BASE_WIZARD:
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_BASE));
                    requests.push(reloadData(objectTypes.ZONE));
                    break;
                case navigationTypes.ADD_SYSTEM_COLORANT_WIZARD:
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_COLORANT));
                    requests.push(reloadData(objectTypes.PACKAGE));
                    requests.push(reloadData(objectTypes.ZONE));
                    break;
                case navigationTypes.ADD_SYSTEM_COLORANT_PACKAGES_WIZARD:
                    requests.push(reloadData(objectTypes.ZONE));
                    break;
                case navigationTypes.ADD_SYSTEM_FANDECK_WIZARD:
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_FANDECK));
                    requests.push(reloadData(objectTypes.ZONE));
                    break;
                case navigationTypes.ADD_SYSTEM_PRODUCT_WIZARD: {
                    requests.push(reloadData(objectTypes.MASTER_BASE_IN_PRODUCT));
                    requests.push(reloadData(objectTypes.MASTER_PRODUCT));
                    requests.push(reloadData(objectTypes.SYSTEM_BASE));
                    requests.push(reloadData(objectTypes.ZONE));
                    requests.push(reloadData(objectTypes.PACKAGE));
                    requests.push(reloadData(objectTypes.UNIT));
                    break;
                }
                default:
                    break;
            }

            return requests;
        })
    );

export const loadDataForModals: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.NAVIGATION_MODAL_OPEN),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.type) {
                case modalTypes.MODAL_BASE_IN_PRODUCT_EDIT: {
                    if (payload.params) {
                        switch (payload.params.objectType) {
                            case objectTypes.MASTER_BASE_IN_PRODUCT:
                            case objectTypes.SYSTEM_BASE_IN_PRODUCT:
                            case objectTypes.ZONE_BASE_IN_PRODUCT:
                                requests.push(
                                    fileActions.updateFileList(
                                        objectTypes.PRODUCT_FILE,
                                        payload.params.data?.[propertyConstants.PROPERTY_FILES] || []
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                }
                case modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT: {
                    if (payload.params) {
                        switch (payload.params.objectType) {
                            case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
                            case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE:
                                requests.push(
                                    fileActions.updateFileList(
                                        objectTypes.PRODUCT_FILE,
                                        payload.params.data?.[propertyConstants.PROPERTY_FILES] || []
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                }
                case modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE:
                case modalTypes.MODAL_DATA_EXPORT:
                case modalTypes.MODAL_DATA_EXPORT_ALL:
                    requests.push(reloadData(objectTypes.WEB_ZONE));
                    break;
                case modalTypes.MODAL_FILE_CATEGORIES_TABLE:
                    requests.push(reloadData(objectTypes.FILE_CATEGORY));
                    break;
                case modalTypes.MODAL_FILES_TABLE:
                    requests.push(reloadData(objectTypes.FILE));
                    break;
                case modalTypes.MODAL_FORMULA_NOTES_TABLE:
                    requests.push(reloadData(objectTypes.MASTER_FORMULA_NOTE));
                    break;
                case modalTypes.MODAL_ICONS_TABLE:
                    requests.push(reloadData(objectTypes.ICON));
                    break;
                case modalTypes.MODAL_IMAGE_CATEGORIES_TABLE:
                    requests.push(reloadData(objectTypes.IMAGE_CATEGORY));
                    break;
                case modalTypes.MODAL_IMAGES_TABLE:
                    requests.push(reloadData(objectTypes.IMAGE));
                    requests.push(getAllItems(objectTypes.IMAGE_CATEGORY));
                    break;
                case modalTypes.MODAL_IMPORT_TASK_ADD:
                    requests.push(getAllItems(objectTypes.COMPANY));
                    requests.push(
                        getAllItems(objectTypes.STATIC_SQL, {
                            fileTypeIds: state$.value.file.staticSqlFileTypeList.map((item: FileType) => item.id)
                        })
                    );
                    break;
                case modalTypes.MODAL_PRODUCTS_DUPLICATE: {
                    const duplicateProductList: Array<DuplicateProduct> = [];
                    let duplicateProduct: DuplicateProduct | null = null;

                    requests.push(
                        fileActions.updateFileList(
                            objectTypes.PRODUCT_FILE,
                            payload.params.data?.[propertyConstants.PROPERTY_FILES] || []
                        )
                    );

                    if (
                        state$.value.navigation.activeScene === navigationTypes.SYSTEM_PRODUCTS_SCENE &&
                        state$.value.product.systemActiveList.length
                    ) {
                        for (const system of state$.value.system.allList) {
                            duplicateProduct = mapDuplicateProduct(system, state$.value.product.systemActiveList[0]);

                            if (duplicateProduct) {
                                duplicateProductList.push(duplicateProduct);
                            }
                        }
                        requests.push(saveItemsNoParams(objectTypes.SYSTEM_PRODUCT_DUPLICATE, duplicateProductList));
                    }

                    if (
                        state$.value.navigation.activeScene === navigationTypes.ZONE_PRODUCTS_SCENE &&
                        state$.value.product.zoneActiveList.length
                    ) {
                        for (const zone of state$.value.zone.allList) {
                            duplicateProduct = mapDuplicateProduct(zone, state$.value.product.zoneActiveList[0]);

                            if (duplicateProduct) {
                                duplicateProductList.push(duplicateProduct);
                            }
                        }
                        requests.push(saveItemsNoParams(objectTypes.ZONE_PRODUCT_DUPLICATE, duplicateProductList));
                    }
                    break;
                }
                case modalTypes.MODAL_PRODUCTS_EDIT: {
                    if (payload.params) {
                        switch (payload.params.objectType) {
                            case objectTypes.MASTER_PRODUCT:
                            case objectTypes.SYSTEM_PRODUCT:
                            case objectTypes.ZONE_PRODUCT:
                                requests.push(
                                    fileActions.updateFileList(
                                        objectTypes.PRODUCT_FILE,
                                        payload.params.data?.[propertyConstants.PROPERTY_FILES] || []
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                }
                case modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_BASE_IN_PRODUCTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_BASES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_BASE));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_COLORANT_PACKAGES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_COLORANTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_COLORANT));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_FANDECKS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_FANDECK));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS));
                    requests.push(reloadData(objectTypes.SYSTEM_MASTER_PRODUCT_GROUP_WITH_PRODUCTS));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_PRODUCT));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS));
                    requests.push(reloadData(objectTypes.SYSTEM_MASTER_PRODUCT_WITH_PRODUCT_GROUPS));
                    break;
                }
                case modalTypes.MODAL_ZONE_BASE_IN_PRODUCT_PACKAGES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE));
                    break;
                }
                case modalTypes.MODAL_ZONE_BASE_IN_PRODUCTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT));
                    break;
                }
                case modalTypes.MODAL_ZONE_BASES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_BASE));
                    break;
                }
                case modalTypes.MODAL_ZONE_COLORANT_PACKAGES_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE));
                    break;
                }
                case modalTypes.MODAL_ZONE_COLORANTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_COLORANT));
                    break;
                }
                case modalTypes.MODAL_ZONE_FANDECKS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_FANDECK));
                    break;
                }
                case modalTypes.MODAL_ZONE_PRODUCT_GROUPS_ADD_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_PRODUCT_GROUP));
                    break;
                }
                case modalTypes.MODAL_ZONE_PRODUCT_GROUPS_EDIT_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS));
                    requests.push(reloadData(objectTypes.ZONE_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS));
                    break;
                }
                case modalTypes.MODAL_ZONE_PRODUCTS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_PRODUCT));
                    break;
                }
                case modalTypes.MODAL_ZONE_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS));
                    requests.push(reloadData(objectTypes.ZONE_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS));
                    break;
                }
                case modalTypes.MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE: {
                    requests.push(getAllItems(objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE: {
                    requests.push(getAllItems(objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE: {
                    requests.push(getAllItems(objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE: {
                    requests.push(reloadData(objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP));
                    requests.push(reloadData(objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE: {
                    requests.push(reloadData(objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP));
                    requests.push(reloadData(objectTypes.SYSTEM_MASTER_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE: {
                    requests.push(reloadData(objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP));
                    requests.push(reloadData(objectTypes.ZONE_SYSTEM_REDLIKE_USER_GROUP));
                    break;
                }
                case modalTypes.MODAL_ZONES_ADD:
                case modalTypes.MODAL_ZONES_EDIT: {
                    requests.push(reloadData(objectTypes.WEB_ZONE));
                    break;
                }
                default:
                    break;
            }

            return requests;
        })
    );
