import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Currency } from "../../../types/currency";
import { Database } from "../../../types/database";
import { File } from "../../../types/file";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { System } from "../../../types/system";
import { SystemZoneScene } from "../../../components/scenes/data/SystemZoneScene";
import { Zone } from "../../../types/zone";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    privileges: any;
    currencyList: Array<Currency>;
    databaseActiveList: Array<Database>;
    sceneLayout: string;
    staticSqlList: Array<File>;
    systemTableConstant: string;
    systemList: Array<System>;
    systemActiveList: Array<System>;
    systemLoading: boolean;
    systemButtonLoading: boolean;
    systemColumnOrder: Array<propertyConstants.Property>;
    systemColumnVisibility: Record<propertyConstants.Property, boolean>;
    systemColumnWidth: Record<propertyConstants.Property, number>;
    systemOffset: number;
    systemPage: number;
    systemRowCount: number;
    systemRowCountCustom: boolean;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemShowFilterRow: boolean;
    systemShowGlobalSearch: boolean;
    systemSortingAsc: boolean;
    systemSortingCriterion: propertyConstants.Property | null;
    systemTotalCount: number | null;
    systemZoneSceneTableSize: Record<string, any>;
    zoneTableConstant: string;
    zoneList: Array<Zone>;
    zoneActiveList: Array<Zone>;
    zoneLoading: boolean;
    zoneButtonLoading: boolean;
    zoneColumnOrder: Array<propertyConstants.Property>;
    zoneColumnVisibility: Record<propertyConstants.Property, boolean>;
    zoneColumnWidth: Record<propertyConstants.Property, number>;
    zoneOffset: number;
    zonePage: number;
    zoneRowCount: number;
    zoneRowCountCustom: boolean;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneShowFilterRow: boolean;
    zoneShowGlobalSearch: boolean;
    zoneSortingAsc: boolean;
    zoneSortingCriterion: propertyConstants.Property | null;
    zoneTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS
    ),
    privileges: state.login.privileges,
    currencyList: state.currency.allList,
    databaseActiveList: state.database.active ? [state.database.active] : [],
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS
    ),
    staticSqlList: state.file.staticSqlAllList,
    systemTableConstant: menuConstants.TABLE_GLOBAL_SYSTEMS,
    systemList: state.system.list,
    systemActiveList: state.system.activeList,
    systemLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEMS || request.method === methods.METHOD_DELETE_SYSTEM
    ),
    systemButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_SYSTEM
    ),
    systemColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemOffset: state.system.offset,
    systemPage: state.system.page,
    systemRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemSearch: state.system.search,
    systemSearchParams: state.system.searchParams,
    systemShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GLOBAL_SYSTEMS
    ),
    systemSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GLOBAL_SYSTEMS
        )
    ),
    systemSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GLOBAL_SYSTEMS
        )
    ),
    systemTotalCount: state.system.count,
    systemZoneSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS
    ),
    zoneTableConstant: menuConstants.TABLE_GLOBAL_ZONES,
    zoneList: state.zone.list,
    zoneActiveList: state.zone.activeList,
    zoneLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_SYSTEMS ||
            request.method === methods.METHOD_GET_ALL_ZONES_BY_SYSTEM ||
            request.method === methods.METHOD_DELETE_ZONE
    ),
    zoneButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_ZONE
    ),
    zoneColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneOffset: state.zone.offset,
    zonePage: state.zone.page,
    zoneRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneSearch: state.zone.search,
    zoneSearchParams: state.zone.searchParams,
    zoneShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    zoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GLOBAL_ZONES
        )
    ),
    zoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GLOBAL_ZONES
        )
    ),
    zoneTotalCount: state.zone.count
});

export const SystemZoneSceneContainer = connect(mapStateToProps)(SystemZoneScene);
