import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as keyCodes from "../../../../constants/keyCodes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as validationHelper from "../../../../helpers/validationHelper";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import ContentEditable from "react-contenteditable";
import { Dispatch } from "redux";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    content: string;
    isActive: boolean;
    isEditable: boolean;
    isSourceChanged: boolean;
    callbackBlur: (event: any, value: any) => any;
    callbackChange: (isValid: boolean, value: any) => any;
    callbackFocus: (value: any) => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellPercent extends Component<Props> {
    handleCellChange = (event: any): any => {
        const content = validationHelper.formatStringFromHtml(event.target.value);
        const isValid = validationHelper.isDecimalValueValid(content);

        this.props.callbackChange(isValid, content);
    };

    handleCellKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        property: propertyConstants.Property,
        originValue: any
    ): any => {
        switch (event.key) {
            case keyCodes.ENTER: {
                event.currentTarget.blur();
                break;
            }
            case keyCodes.ESCAPE: {
                this.props.callbackChange(true, originValue ?? "");
                break;
            }
            default: {
                break;
            }
        }
    };

    handleCellBlur = (event: React.FocusEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): any => {
        let value: any = validationHelper.formatNumber(
            this.props.objectType,
            this.props.heading[tableConstants.TABLE_HEADING_NAME],
            this.props.content
        );

        value = formattingHelper.formatFromPercent(value);
        value = value.trim() !== "" ? value : null;
        value = !isNaN(value) && value !== null ? Number(value) : null;

        this.props.callbackBlur(event, value);
    };

    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (property: propertyConstants.Property): string => {
        return `td td-edit ${property}`;
    };

    getContentClassname = (): string => {
        let className = "";

        if (this.props.isSourceChanged) {
            className += " dotted";
        }

        return className;
    };

    getContentEditableClassname = (): string => {
        let className = "td-edit";

        if (this.isDisabled()) {
            className += " edit-disabled";
        }

        return className;
    };

    getCellContent = (value: any): string => {
        if (value === null) {
            return "";
        }

        return formattingHelper.formatPercent(value);
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const formattedValue = formattingHelper.formatPercentWithoutSign(formattingHelper.formatPercent(value));

        const content = this.getCellContent(
            this.props.isActive ? this.props.content : formattingHelper.formatPercent(value)
        );
        const editableHtml = `<span class="${this.getContentClassname()}">${content}</span>`;

        return (
            <div className={this.getClassname(property)} key={property}>
                <ContentEditable
                    className={this.getContentEditableClassname()}
                    disabled={this.isDisabled()}
                    html={editableHtml}
                    key={property}
                    tagName={"span"}
                    onFocus={(): void => this.props.callbackFocus(formattedValue)}
                    onChange={(event: any): void => this.handleCellChange(event)}
                    onBlur={(event: React.FocusEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void =>
                        this.handleCellBlur(event)
                    }
                    onKeyDown={(event): void => this.handleCellKeyDown(event, property, formattedValue)}
                />
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellPercentContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellPercent);
