import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { UserGroup, mapRedlikeUserGroup } from "../../types/userGroup";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data.data) {
                userGroup = mapRedlikeUserGroup(item);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_REDLIKE_USER_GROUP,
                    userGroupList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_GET_ALL_REDLIKE_USER_GROUPS_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data.data) {
                userGroup = mapRedlikeUserGroup(item);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.REDLIKE_USER_GROUP, userGroupList)];
        }
        case methods.METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data) {
                userGroup = mapRedlikeUserGroup(item);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [
                universalObjectActions.saveItemsNoParams(objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP, userGroupList)
            ];
        }
        case methods.METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data) {
                userGroup = mapRedlikeUserGroup(item);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [
                universalObjectActions.saveItemsNoParams(objectTypes.MASTER_DEFAULT_REDLIKE_USER_GROUP, userGroupList)
            ];
        }
        case methods.METHOD_ADD_REDLIKE_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_USER_GROUPS_ADD)
            ];
        }
        case methods.METHOD_EDIT_REDLIKE_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (
                state.navigation.modalList.some(
                    (modal: Modal) => modal.type === modalTypes.MODAL_REDLIKE_USER_GROUPS_EDIT
                )
            ) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER_GROUP));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_USER_GROUPS_EDIT));
            } else {
                const userGroup = mapRedlikeUserGroup(response.data);

                if (userGroup) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.MASTER_REDLIKE_USER_GROUP, [userGroup])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_REDLIKE_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER_GROUP),
                universalObjectActions.setActiveItems(objectTypes.MASTER_REDLIKE_USER_GROUP, [])
            ];
        }
        case methods.METHOD_DUPLICATE_REDLIKE_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_REDLIKE_USER_GROUPS_DUPLICATE)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
