import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterColorantBatchTable } from "./tables/MasterColorantBatchTable";
import { MasterColorantTable } from "./tables/MasterColorantTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterColorantSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

export const MasterColorantScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableColorants, setTableColorants] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.colorantTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableColorants(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.colorantTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.colorantTableConstant]);

    const [tableBatches, setTableBatches] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.colorantBatchesTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableBatches(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.colorantBatchesTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.colorantBatchesTableConstant]);

    const getColorantsTable = (): JSX.Element | null => {
        if (tableColorants) {
            return (
                <MasterColorantTable
                    tableConstant={props.colorantTableConstant}
                    objectType={objectTypes.MASTER_COLORANT}
                    dataPrivileges={tableColorants}
                    loading={props.colorantLoading}
                    buttonLoading={props.colorantButtonLoading}
                    allList={props.colorantList}
                    activeList={props.colorantActiveList}
                    columnOrder={props.colorantColumnOrder}
                    columnVisibility={props.colorantColumnVisibility}
                    columnWidth={props.colorantColumnWidth}
                    offset={props.colorantOffset}
                    page={props.colorantPage}
                    rowCount={props.colorantRowCount}
                    rowCountCustom={props.colorantRowCountCustom}
                    search={props.colorantSearch}
                    searchParams={props.colorantSearchParams}
                    showFilterRow={props.colorantShowFilterRow}
                    showGlobalSearch={props.colorantShowGlobalSearch}
                    sortingAsc={props.colorantSortingAsc}
                    sortingCriterion={props.colorantSortingCriterion}
                    totalCount={props.colorantTotalCount}
                    useTmcNames={props.optionUseTmcNames || false}
                />
            );
        }

        return null;
    };

    const getColorantBatchesTable = (): JSX.Element | null => {
        if (tableBatches) {
            return (
                <MasterColorantBatchTable
                    tableConstant={props.colorantBatchesTableConstant}
                    objectType={objectTypes.MASTER_COLORANT_BATCH}
                    dataPrivileges={tableBatches}
                    loading={props.colorantBatchesLoading}
                    buttonLoading={props.colorantBatchesButtonLoading}
                    allList={props.colorantBatchesList}
                    activeList={props.colorantBatchesActiveList}
                    colorantActiveList={props.colorantActiveList}
                    columnOrder={props.colorantBatchesColumnOrder}
                    columnVisibility={props.colorantBatchesColumnVisibility}
                    columnWidth={props.colorantBatchesColumnWidth}
                    offset={props.colorantBatchesOffset}
                    page={props.colorantBatchesPage}
                    rowCount={props.colorantBatchesRowCount}
                    rowCountCustom={props.colorantBatchesRowCountCustom}
                    search={props.colorantBatchesSearch}
                    searchParams={props.colorantBatchesSearchParams}
                    showFilterRow={props.colorantBatchesShowFilterRow}
                    showGlobalSearch={props.colorantBatchesShowGlobalSearch}
                    sortingAsc={props.colorantBatchesSortingAsc}
                    sortingCriterion={props.colorantBatchesSortingCriterion}
                    totalCount={props.colorantBatchesTotalCount}
                />
            );
        }
        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={
                [getColorantsTable(), getColorantBatchesTable()].filter((item) => item !== null) as Array<JSX.Element>
            }
            layout={props.sceneLayout}
            sceneTableSize={props.colorantSceneTableSize}
            dataPrivileges={tableColorants}
            sceneConstant={menuConstants.PAGE_MASTER_COLORANTS}
        />
    );
};
