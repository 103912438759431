import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Company } from "../../../types/company";
import { Database } from "../../../types/database";
import { DatabaseScene } from "../../../components/scenes/data/DatabaseScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    privileges: any;
    companyList: Array<Company>;
    databaseTableConstant: string;
    databaseList: Array<Database>;
    databaseActiveList: Array<Database>;
    databaseLoading: boolean;
    databaseButtonLoading: boolean;
    databaseColumnOrder: Array<propertyConstants.Property>;
    databaseColumnVisibility: Record<propertyConstants.Property, boolean>;
    databaseColumnWidth: Record<propertyConstants.Property, number>;
    databaseOffset: number;
    databasePage: number;
    databaseRowCount: number;
    databaseRowCountCustom: boolean;
    databaseSearch: string | null;
    databaseSearchParams: Record<propertyConstants.Property, string>;
    databaseShowFilterRow: boolean;
    databaseShowGlobalSearch: boolean;
    databaseSortingAsc: boolean;
    databaseSortingCriterion: propertyConstants.Property | null;
    databaseTotalCount: number | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_MASTER_SETTINGS
    ),
    privileges: state.login.privileges,
    companyList: state.company.allList,
    databaseTableConstant: menuConstants.TABLE_DATA_DATABASES,
    databaseList: state.database.list,
    databaseActiveList: state.database.activeList,
    databaseLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_DATABASES || request.method === methods.METHOD_DELETE_DATABASE
    ),
    databaseButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_DATABASE
    ),
    databaseColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseOffset: state.database.offset,
    databasePage: state.database.page,
    databaseRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseSearch: state.database.search,
    databaseSearchParams: state.database.searchParams,
    databaseShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_DATA_DATABASES
    ),
    databaseSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_DATABASES
        )
    ),
    databaseSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_DATA_DATABASES
        )
    ),
    databaseTotalCount: state.database.count
});

export const DatabaseSceneContainer = connect(mapStateToProps)(DatabaseScene);
