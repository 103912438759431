import * as fileTypeConstants from "../constants/fileTypes";
import * as formattingHelper from "../helpers/formattingHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { FileType } from "./fileType";
import { ImageCategory } from "./imageCategory";
import { TableHeading } from "./tableHeading";

export class Image {
    id: number;
    imageId: number | null;
    name: string;
    path: string;
    src: string | null;
    fileTypeId: number;
    imageCategoryId: number | null;
    mimeType: string | null;
    dataLen: string | null;
    height: number | null;
    width: number | null;
    uploadedDate: Date | null;
    moreInfo: string | null;
    url: string | null;

    constructor(
        id: number,
        imageId: number | null,
        name: string,
        path: string,
        src: string | null,
        fileTypeId: number,
        imageCategoryId: number | null,
        mimeType: string | null,
        dataLen: string | null,
        height: number | null,
        width: number | null,
        uploadedDate: Date | null,
        moreInfo: string | null,
        url: string | null
    ) {
        this.id = id;
        this.imageId = imageId;
        this.name = name;
        this.path = path;
        this.src = src;
        this.fileTypeId = fileTypeId;
        this.imageCategoryId = imageCategoryId;
        this.mimeType = mimeType;
        this.dataLen = dataLen;
        this.height = height;
        this.width = width;
        this.uploadedDate = uploadedDate;
        this.moreInfo = moreInfo;
        this.url = url;
    }
}

export function mapImage(data: Record<string, any>): Image | null {
    try {
        return new Image(
            data.id,
            data.id,
            data.name,
            data.path,
            null,
            data.fileTypeId,
            data.imageCategoryId,
            data.mimeType,
            formattingHelper.formatFileSize(data.dataLen),
            data.height,
            data.width,
            data.uploaded,
            data.moreInfo,
            data.url
        );
    } catch (e) {
        return null;
    }
}

export function updateImage(oldImage: Image | null, updatedData: Record<string, any>): Image | null {
    try {
        if (oldImage === null) {
            return null;
        }

        return new Image(
            oldImage.id,
            oldImage.id,
            updatedData.name ? updatedData.name : oldImage.name,
            oldImage.path,
            oldImage.src,
            updatedData.fileTypeId ? updatedData.fileTypeId : oldImage.fileTypeId,
            updatedData.imageCategoryId ? updatedData.imageCategoryId : oldImage.imageCategoryId,
            oldImage.mimeType,
            formattingHelper.formatFileSize(Number(oldImage.dataLen)),
            oldImage.height,
            oldImage.width,
            oldImage.uploadedDate,
            updatedData.moreInfo ? updatedData.moreInfo : oldImage.moreInfo,
            updatedData.url ? updatedData.url : oldImage.url
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyImage(isIcon?: boolean): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_IMAGE_ID]: null,
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PATH]: "",
        [propertyConstants.PROPERTY_SRC]: null,
        [propertyConstants.PROPERTY_FILE_TYPE_ID]: isIcon
            ? fileTypeConstants.FILE_TYPE_ICON
            : fileTypeConstants.FILE_TYPE_IMAGE,
        [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: "",
        [propertyConstants.PROPERTY_MIME_TYPE]: "",
        [propertyConstants.PROPERTY_LENGTH]: "",
        [propertyConstants.PROPERTY_HEIGHT]: "",
        [propertyConstants.PROPERTY_WIDTH]: "",
        [propertyConstants.PROPERTY_DATE_UPLOADED]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_URL]: ""
    };
}

export function generateImageData(imageList: Array<Image>): Array<any> {
    const data: Array<any> = [];
    let item: Image;

    for (item of imageList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.id,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_PATH]: item.path,
            [propertyConstants.PROPERTY_SRC]: item.src,
            [propertyConstants.PROPERTY_FILE_TYPE_ID]: item.fileTypeId,
            [propertyConstants.PROPERTY_IMAGE_CATEGORY_ID]: item.imageCategoryId,
            [propertyConstants.PROPERTY_MIME_TYPE]: item.mimeType,
            [propertyConstants.PROPERTY_LENGTH]: item.dataLen,
            [propertyConstants.PROPERTY_HEIGHT]: item.height,
            [propertyConstants.PROPERTY_WIDTH]: item.width,
            [propertyConstants.PROPERTY_DATE_UPLOADED]: item.uploadedDate,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_URL]: item.url
        });
    }
    return data;
}

export function generateImageHeadings(
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalType | null,
    categoryList: Array<ImageCategory> = [],
    fileTypeList: Array<FileType> = [],
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_FILE_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILE_TYPE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_FILE_TYPE_ID),
            fileTypeList,
            widthOption?.[propertyConstants.PROPERTY_FILE_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_HEIGHT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_HEIGHT),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_HEIGHT),
            editableList.includes(propertyConstants.PROPERTY_HEIGHT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_HEIGHT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_HEIGHT),
            requiredList.includes(propertyConstants.PROPERTY_HEIGHT),
            [],
            widthOption?.[propertyConstants.PROPERTY_HEIGHT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_CATEGORY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_CATEGORY_ID),
            categoryList,
            widthOption?.[propertyConstants.PROPERTY_IMAGE_CATEGORY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            objectType === objectTypes.GENERIC_IMAGE && modalType === null
                ? tableConstants.TABLE_TYPE_IMAGE_SECTION
                : tableConstants.TABLE_TYPE_IMAGE,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LENGTH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LENGTH),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LENGTH),
            editableList.includes(propertyConstants.PROPERTY_LENGTH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LENGTH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LENGTH),
            requiredList.includes(propertyConstants.PROPERTY_LENGTH),
            [],
            widthOption?.[propertyConstants.PROPERTY_LENGTH] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_URL,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_URL),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_URL),
            editableList.includes(propertyConstants.PROPERTY_URL),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_URL),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_URL),
            requiredList.includes(propertyConstants.PROPERTY_URL),
            [],
            widthOption?.[propertyConstants.PROPERTY_URL] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_WIDTH,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_WIDTH),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_WIDTH),
            editableList.includes(propertyConstants.PROPERTY_WIDTH),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_WIDTH),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_WIDTH),
            requiredList.includes(propertyConstants.PROPERTY_WIDTH),
            [],
            widthOption?.[propertyConstants.PROPERTY_WIDTH] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
