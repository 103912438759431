import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Color, updateFormulaList } from "../../types/color";
import { Formula, mapFormula } from "../../types/formula";
import { FormulaColorant, mapFormulaColorant } from "../../types/formulaColorant";
import { Unit, mapUnit } from "../../types/unit";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processWebsocketResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_FORMULA_UNITS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const unitList: Array<Unit> = [];
            let unit: Unit | null;

            for (const item of response.data) {
                unit = mapUnit(item);

                if (unit) {
                    unitList.push(unit);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.FORMULA_COLORANT_UNIT, unitList)];
        }
        case methods.METHOD_GET_MAIN_FORMULAS_FOR_COMBINATION: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const formulaList: Array<Formula> = [];
            let formula: Formula | null;
            let color: Color | null;

            for (const item of response.data.data) {
                const formulaColorantList: Array<FormulaColorant> = [];

                for (const colorant of state.colorant.masterList) {
                    const formulaColorant: FormulaColorant | null = mapFormulaColorant(
                        colorant,
                        item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            ? item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            : null
                    );

                    if (formulaColorant) {
                        formulaColorantList.push(formulaColorant);
                    }
                }

                formula = mapFormula(item, formulaColorantList);

                if (formula) {
                    formulaList.push(formula);
                }
            }

            if (state.color.masterActiveList.length) {
                let formulaId = null;
                let baseInProductId = null;

                // Keeping ID of last active formula, so that after reload is still active
                // Automatically selected first formula in the list
                if (state.navigation.activeScene !== navigationTypes.MASTER_COLORS_SCENE) {
                    const oldFormulaId =
                        state.color.masterActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID] || null;
                    const oldFormula =
                        formulaList.find((item: Formula) => item[propertyConstants.PROPERTY_ID] === oldFormulaId) ||
                        null;
                    formulaId =
                        oldFormula?.[propertyConstants.PROPERTY_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_ID] ||
                        null;
                    baseInProductId =
                        oldFormula?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        null;
                }

                color = updateFormulaList(state.color.masterActiveList[0], formulaList, formulaId, baseInProductId);
                requests.push(universalObjectActions.setActiveItems(objectTypes.MASTER_FORMULA, [color]));
            }

            return requests;
        }
        case methods.METHOD_GET_MASTER_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = updateFormulaList(
                state.color.masterActiveList[0] || null,
                state.color.masterActiveList[0]?.formulaList || [],
                response.data.id,
                response.data.baseInProductId
            );

            return [
                universalObjectActions.setActiveItems(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_SYSTEM_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = updateFormulaList(
                state.color.systemActiveList[0] || null,
                state.color.systemActiveList[0]?.formulaList || [],
                response.data.id,
                response.data.baseInProductId
            );

            return [
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_SYSTEM_MAIN_FORMULAS_FOR_COMBINATION: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const formulaList: Array<Formula> = [];
            let formula: Formula | null;
            let color: Color | null;

            for (const item of response.data.data) {
                const formulaColorantList: Array<FormulaColorant> = [];

                for (const colorant of state.colorant.systemList) {
                    const formulaColorant: FormulaColorant | null = mapFormulaColorant(
                        colorant,
                        item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            ? item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            : null
                    );

                    if (formulaColorant) {
                        formulaColorantList.push(formulaColorant);
                    }
                }

                formula = mapFormula(item, formulaColorantList);

                if (formula) {
                    formulaList.push(formula);
                }
            }

            if (state.color.systemActiveList.length) {
                let formulaId = null;
                let baseInProductId = null;

                // Keeping ID of last active formula, so that after reload is still active
                // Automatically selected first formula in the list
                if (state.navigation.activeScene !== navigationTypes.SYSTEM_COLORS_SCENE) {
                    const oldFormulaId =
                        state.color.systemActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID] || null;
                    const oldFormula =
                        formulaList.find((item: Formula) => item[propertyConstants.PROPERTY_ID] === oldFormulaId) ||
                        null;
                    formulaId =
                        oldFormula?.[propertyConstants.PROPERTY_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_ID] ||
                        null;
                    baseInProductId =
                        oldFormula?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        null;
                }

                color = updateFormulaList(state.color.systemActiveList[0], formulaList, formulaId, baseInProductId);
                requests.push(universalObjectActions.setActiveItems(objectTypes.SYSTEM_FORMULA, [color]));
            }

            return requests;
        }
        case methods.METHOD_GET_ZONE_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = updateFormulaList(
                state.color.zoneActiveList[0] || null,
                state.color.zoneActiveList[0]?.formulaList || [],
                response.data.id,
                response.data.baseInProductId
            );

            return [
                universalObjectActions.setActiveItems(objectTypes.ZONE_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const formulaList: Array<Formula> = [];
            let formula: Formula | null;
            let color: Color | null;

            for (const item of response.data.data) {
                const formulaColorantList: Array<FormulaColorant> = [];

                for (const colorant of state.colorant.zoneList) {
                    const formulaColorant: FormulaColorant | null = mapFormulaColorant(
                        colorant,
                        item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            ? item.colorants[colorant[propertyConstants.PROPERTY_ID]]
                            : null
                    );

                    if (formulaColorant) {
                        formulaColorantList.push(formulaColorant);
                    }
                }

                formula = mapFormula(item, formulaColorantList);

                if (formula) {
                    formulaList.push(formula);
                }
            }

            if (state.color.zoneActiveList.length) {
                let formulaId = null;
                let baseInProductId = null;

                // Keeping ID of last active formula, so that after reload is still active
                // Automatically selected first formula in the list
                if (state.navigation.activeScene !== navigationTypes.ZONE_COLORS_SCENE) {
                    const oldFormulaId = state.color.zoneActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID] || null;
                    const oldFormula =
                        formulaList.find((item: Formula) => item[propertyConstants.PROPERTY_ID] === oldFormulaId) ||
                        null;
                    formulaId =
                        oldFormula?.[propertyConstants.PROPERTY_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_ID] ||
                        null;
                    baseInProductId =
                        oldFormula?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        formulaList[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ||
                        null;
                }

                color = updateFormulaList(state.color.zoneActiveList[0], formulaList, formulaId, baseInProductId);
                requests.push(universalObjectActions.setActiveItems(objectTypes.ZONE_FORMULA, [color]));
            }

            return requests;
        }
        case methods.METHOD_ADD_MASTER_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULAS_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULAS_EDIT)
            ];
        }
        case methods.METHOD_DELETE_MASTER_FORMULA: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO)
            ];
        }
        case methods.METHOD_SET_MASTER_FORMULA_POSITION: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_FORMULA)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
