import * as itemTypeConstants from "../../constants/itemTypeConstants";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Barcode, mapBarcode, updateBarcodeWithItem } from "../../types/barcode";
import { BaseInProductPackage, mapSimpleBaseInProductPackage } from "../../types/baseInProductPackage";
import { Color, mapSimpleColor } from "../../types/color";
import { ColorantPackage, mapSimpleColorantPackage } from "../../types/colorantPackage";
import { Fandeck, mapSimpleFandeck } from "../../types/fandeck";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_BARCODES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const barcodeList: Array<Barcode> = [];
            let barcode: Barcode | null;

            for (const item of response.data.data) {
                barcode = mapBarcode(item);

                if (barcode) {
                    barcodeList.push(barcode);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.BARCODE, barcodeList, response.data.count)];
        }
        case methods.METHOD_GET_ITEM_BY_BARCODE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            let baseInProductPackage: BaseInProductPackage | null = null;
            let colorInFandeck: Color | null = null;
            let colorantPackage: ColorantPackage | null = null;
            let fandeck: Fandeck | null = null;

            switch (response.data.itemType) {
                case itemTypeConstants.ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE:
                    baseInProductPackage = mapSimpleBaseInProductPackage(response.data.item);
                    break;
                case itemTypeConstants.ITEM_TYPE_COLOR_IN_FANDECK:
                    colorInFandeck = mapSimpleColor(response.data.item);
                    break;
                case itemTypeConstants.ITEM_TYPE_COLORANT_PACKAGE:
                    colorantPackage = mapSimpleColorantPackage(response.data.item);
                    break;
                case itemTypeConstants.ITEM_TYPE_FANDECK:
                    fandeck = mapSimpleFandeck(response.data.item);
                    break;
                default:
                    break;
            }

            const barcode = updateBarcodeWithItem(
                state.barcode.activeList[0],
                baseInProductPackage,
                colorantPackage,
                colorInFandeck,
                fandeck
            );

            if (barcode) {
                requests.push(universalObjectActions.updateItem(objectTypes.BARCODE, [barcode]));
            }

            return requests;
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
