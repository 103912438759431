import * as bookmarkConstants from "../../../../constants/bookmarkConstants";
import * as dataTypeConstants from "../../../../constants/dataTypeConstants";
import * as generalHelper from "../../../../helpers/generalHelper";
import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import { System, createEmptySystem, generateSystemData, generateSystemHeadings } from "../../../../types/system";

import { Bookmark } from "../../../../types/bookmark";
import { Database } from "../../../../types/database";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    privileges: any;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    databaseActiveList: Array<Database>;
    allList: Array<System>;
    activeList: Array<System>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class SystemTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        isDuplicatedBookmark: boolean = false
    ): Array<TableHeading> => {
        const { privileges } = this.props;

        const propertyList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        let editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (
            modalType !== modalTypes.MODAL_SYSTEMS_DUPLICATE &&
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_VIEW])
        ) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_UUID);
        }

        if (
            modalType !== null &&
            modalType !== modalTypes.MODAL_SYSTEMS_DUPLICATE &&
            optionHelper.isPrivilegeAvailable(privileges?.[privilegeConstants.PRIVILEGE_SYSTEM_UUID_UPDATE])
        ) {
            editableList.push(propertyConstants.PROPERTY_UUID);
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        if (isDuplicatedBookmark) {
            visibleList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
            editableList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
        }

        return generateSystemHeadings(
            this.props.objectType,
            generalHelper.generateDataTypes(this.props.objectType, dataTypeConstants.DATA_TYPE_LIST),
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateBookmarks = (modalType: modalTypes.ModalType): Array<Bookmark> => {
        return [
            new Bookmark(
                bookmarkConstants.BOOKMARK_GENERAL,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.general"),
                this.generateTableHeadings(modalType),
                this.props.dataPrivileges,
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_DUPLICATE,
                bookmarkConstants.BOOKMARK_TYPE_FORM_TABLE,
                translate("general.duplicate"),
                this.generateTableHeadings(modalType, true),
                this.props.dataPrivileges,
                false
            )
        ];
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.ADD_MODAL,
            this.props.objectType,
            translate("system.createNewSystem"),
            createEmptySystem(this.props.databaseActiveList?.[0]?.[propertyConstants.PROPERTY_ID] ?? null),
            this.generateTableHeadings(modalTypes.MODAL_SYSTEMS_ADD)
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateSystemData(this.props.objectType, this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("system.editSystem"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_SYSTEMS_EDIT)
            );
        }

        return null;
    };

    generateDuplicateModalParams = (): ModalParams | null => {
        const activeData = generateSystemData(objectTypes.SYSTEM_DUPLICATE, this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.DUPLICATE_MODAL,
                objectTypes.SYSTEM_DUPLICATE,
                translate("system.duplicateSystem"),
                activeData[0],
                undefined,
                this.generateBookmarks(modalTypes.MODAL_SYSTEMS_DUPLICATE),
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges,
                undefined,
                undefined
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_SYSTEMS_ADD,
            addModalParams: this.generateAddModalParams(),
            duplicateModalType: modalTypes.MODAL_SYSTEMS_DUPLICATE,
            duplicateModalParams: this.generateDuplicateModalParams(),
            editModalType: modalTypes.MODAL_SYSTEMS_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100 system">
                <TableContainer
                    className={"table-with-filter system-settings-table"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    headings={this.generateTableHeadings()}
                    data={generateSystemData(this.props.objectType, this.props.allList)}
                    activeData={this.props.activeList}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
