export const DATA_TYPE_BARCODE = 7;
export const DATA_TYPE_BASE = 1;
export const DATA_TYPE_BASE_IN_PRODUCT = 8;
export const DATA_TYPE_BASE_IN_PRODUCT_PACKAGE = 9;
export const DATA_TYPE_COLORANT = 2;
export const DATA_TYPE_COLORANT_PACKAGE = 10;
export const DATA_TYPE_FANDECK = 3;
export const DATA_TYPE_FILE = 11;
export const DATA_TYPE_FORMULA = 16;
export const DATA_TYPE_FORMULA_NOTE = 4;
export const DATA_TYPE_IMAGE = 12;
export const DATA_TYPE_OPTION = 13;
export const DATA_TYPE_PRICE = 14;
export const DATA_TYPE_PRIVILEGE = 15;
export const DATA_TYPE_PRODUCT = 5;
export const DATA_TYPE_USER = 6;

export const DATA_TYPE_LIST = [
    DATA_TYPE_COLORANT,
    DATA_TYPE_COLORANT_PACKAGE,
    DATA_TYPE_PRODUCT,
    DATA_TYPE_BASE,
    DATA_TYPE_BASE_IN_PRODUCT,
    DATA_TYPE_BASE_IN_PRODUCT_PACKAGE,
    DATA_TYPE_FANDECK,
    DATA_TYPE_FORMULA_NOTE,
    DATA_TYPE_OPTION,
    DATA_TYPE_USER,
    DATA_TYPE_BARCODE,
    DATA_TYPE_IMAGE,
    DATA_TYPE_FILE,
    DATA_TYPE_PRICE
];

export const MASTER_PRODUCT_DATA_TYPE_LIST = [
    DATA_TYPE_PRODUCT,
    DATA_TYPE_BASE_IN_PRODUCT,
    DATA_TYPE_FORMULA,
    DATA_TYPE_FILE,
    DATA_TYPE_IMAGE
];

export const SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST = [
    DATA_TYPE_PRODUCT,
    DATA_TYPE_BASE,
    DATA_TYPE_BASE_IN_PRODUCT,
    DATA_TYPE_BASE_IN_PRODUCT_PACKAGE,
    DATA_TYPE_BARCODE,
    DATA_TYPE_FILE,
    DATA_TYPE_IMAGE,
    DATA_TYPE_PRICE
];
