import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericUnitSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { UnitTable } from "./tables/UnitTable";

type Props = PropsType;

type State = {
    tableUnits: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericUnitScene extends Component<Props, State> {
    state: State = {
        tableUnits:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.unitTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableUnits:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.unitTableConstant)
                        : null) || null
            });
        }
    }

    getUnitsTable = (): JSX.Element | null => {
        const { unitTableConstant } = this.props;
        const { tableUnits } = this.state;

        if (tableUnits) {
            return (
                <UnitTable
                    tableConstant={unitTableConstant}
                    objectType={objectTypes.UNIT}
                    dataPrivileges={tableUnits}
                    allList={this.props.unitList}
                    activeList={this.props.unitActiveList}
                    loading={this.props.unitLoading}
                    buttonLoading={this.props.unitButtonLoading}
                    columnOrder={this.props.unitColumnOrder}
                    columnVisibility={this.props.unitColumnVisibility}
                    columnWidth={this.props.unitColumnWidth}
                    offset={this.props.unitOffset}
                    page={this.props.unitPage}
                    rowCount={this.props.unitRowCount}
                    rowCountCustom={this.props.unitRowCountCustom}
                    search={this.props.unitSearch}
                    searchParams={this.props.unitSearchParams}
                    showFilterRow={this.props.unitShowFilterRow}
                    showGlobalSearch={this.props.unitShowGlobalSearch}
                    sortingAsc={this.props.unitSortingAsc}
                    sortingCriterion={this.props.unitSortingCriterion}
                    totalCount={this.props.unitTotalCount}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getUnitsTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.unitSceneTableSize}
                dataPrivileges={this.state.tableUnits}
                sceneConstant={menuConstants.PAGE_GENERIC_UNITS}
            />
        );
    }
}
