import * as defaultConstants from "../../constants/default";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";
import * as translationHelper from "../../helpers/translationHelper";

import { TableHeading } from "../tableHeading";

export function generateOneTimeAccessPasswordData(): Record<string, any> {
    return {
        [propertyConstants.PROPERTY_COMPUTER_ID]: "",
        [propertyConstants.PROPERTY_USERNAME]: defaultConstants.DEFAULT_REDLIKE_USERNAME,
        [propertyConstants.PROPERTY_DATE]: new Date().toString(),
        [propertyConstants.PROPERTY_PASSWORD]: ""
    };
}

export function generateOneTimeAccessPasswordHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property> = [],
    alwaysVisibleList: Array<propertyConstants.Property> = [],
    custom: string | null
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_COMPUTER_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPUTER_ID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            editableList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            requiredList.includes(propertyConstants.PROPERTY_COMPUTER_ID),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            tableConstants.TABLE_TYPE_BUTTON,
            visibleList.includes(propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            editableList.includes(propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            requiredList.includes(propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION),
            [],
            null,
            "action table-button"
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATE),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_DATE),
            editableList.includes(propertyConstants.PROPERTY_DATE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATE),
            requiredList.includes(propertyConstants.PROPERTY_DATE),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            tableConstants.TABLE_TYPE_BUTTON,
            visibleList.includes(propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            editableList.includes(propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            requiredList.includes(propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION),
            [],
            null,
            "action table-button"
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DESCRIPTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DESCRIPTION, null, custom),
            tableConstants.TABLE_TYPE_ADDITIONAL_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            editableList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DESCRIPTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            requiredList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_PASSWORD),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USERNAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USERNAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USERNAME),
            editableList.includes(propertyConstants.PROPERTY_USERNAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USERNAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USERNAME),
            requiredList.includes(propertyConstants.PROPERTY_USERNAME),
            [],
            null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
