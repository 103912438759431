import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { FormulaNote, mapFormulaNote } from "../../types/formulaNote";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_FORMULA_NOTES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const formulaNotesList: Array<FormulaNote> = [];
            let formulaNote: FormulaNote | null;

            for (const item of response.data.data) {
                formulaNote = mapFormulaNote(item);

                if (formulaNote) {
                    formulaNotesList.push(formulaNote);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_FORMULA_NOTE, formulaNotesList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_MASTER_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_FORMULA_NOTE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_FORMULA_NOTE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_FORMULA_NOTES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_FORMULA_NOTE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_EDIT));
            } else {
                const activeFormulaNote = mapFormulaNote(response.data);

                if (activeFormulaNote) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.MASTER_FORMULA_NOTE, [activeFormulaNote])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_FORMULA_NOTE),
                universalObjectActions.setActiveItems(objectTypes.MASTER_FORMULA_NOTE, [])
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_FORMULA_NOTES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const formulaNotesList: Array<FormulaNote> = [];
            let formulaNote: FormulaNote | null;

            for (const item of response.data.data) {
                formulaNote = mapFormulaNote(item);

                if (formulaNote) {
                    formulaNotesList.push(formulaNote);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.SYSTEM_FORMULA_NOTE, formulaNotesList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_FORMULA_NOTE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_ADD)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_FORMULA_NOTE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_FORMULA_NOTES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_FORMULA_NOTE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_EDIT));
            } else {
                const activeFormulaNote = mapFormulaNote(response.data);

                if (activeFormulaNote) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.SYSTEM_FORMULA_NOTE, [activeFormulaNote])
                    );
                }
            }

            return requests;
        }
        case methods.METHOD_GET_ALL_ZONE_FORMULA_NOTES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const formulaNotesList: Array<FormulaNote> = [];
            let formulaNote: FormulaNote | null;

            for (const item of response.data.data) {
                formulaNote = mapFormulaNote(item);

                if (formulaNote) {
                    formulaNotesList.push(formulaNote);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.ZONE_FORMULA_NOTE, formulaNotesList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_ZONE_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_FORMULA_NOTE),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_ADD)
            ];
        }
        case methods.METHOD_EDIT_ZONE_FORMULA_NOTE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_FORMULA_NOTE)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_FORMULA_NOTES_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_FORMULA_NOTE));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FORMULA_NOTES_EDIT));
            } else {
                const activeFormulaNote = mapFormulaNote(response.data);

                if (activeFormulaNote) {
                    requests.push(
                        universalObjectActions.editItemSuccess(objectTypes.ZONE_FORMULA_NOTE, [activeFormulaNote])
                    );
                }
            }

            return requests;
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
