import * as generalHelper from "../helpers/generalHelper";
import * as translationConstants from "../constants/translationConstants";

import { Language } from "../types/language";
import { de } from "./de";
import { en } from "./en";

export const getAllLanguages = (): Array<Language> => {
    return [new Language(translationConstants.ENGLISH), new Language(translationConstants.GERMAN)];
};

export const getLanguageTranslations = (language: string): any => {
    switch (language) {
        case translationConstants.ENGLISH:
            return en;
        case translationConstants.GERMAN:
            return de;
        default:
            return generalHelper.cloneObject(en);
    }
};
