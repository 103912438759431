import * as designConstants from "../../constants/designConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as importExportActions from "../../actions/importExportActions";
import * as inputConstants from "../../constants/inputConstants";
import * as menuConstants from "../../constants/menuConstants";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as translationHelper from "../../helpers/translationHelper";
import * as validationConstants from "../../constants/validationConstants";
import * as validationHelper from "../../helpers/validationHelper";

import React, { Component } from "react";
import { AppState } from "../../reducers";
import { DataItem } from "../../types/dataItem";
import { Database } from "../../types/database";
import { Dispatch } from "redux";
import { ExportType } from "../../types/exportType";
import { FooterAlertProperty } from "./general/FooterAlertProperty";
import { MenuItem } from "../../types/menu";
import { ModalLabelInputButtonContainer } from "../general/modal/ModalLabelInputButton";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { OptionItem } from "../../types/optionItem";
import { PrivilegeItem } from "../../types/privilegeItem";
import { ReactSVG } from "react-svg";
import { ServerRequest } from "../../types/serverRequest";
import { SystemZone } from "../../types/systemZone";
import { SystemZoneTable } from "../scenes/data/tables/SystemZoneTable";
import { Validation } from "../../types/validation";
import { WebZone } from "../../types/web/webZone";
import { connect } from "react-redux";

import imgClose from "../../resources/img/icons/close.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};
type OwnProps = PropsType & DispatchType;

type Props = ContainerProps & OwnProps;

type State = {
    exportTaskTypeId: number | null;
    tableDatabaseSystemZones: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    generalValidation: Validation | null;
};

class ExportAllModal extends Component<Props, State> {
    state: State = {
        exportTaskTypeId: null,
        tableDatabaseSystemZones:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === menuConstants.TABLE_DATA_EXPORT)
                : null) || null,
        generalValidation: null
    };

    componentDidMount() {
        const { exportTypesList } = this.props;

        if (exportTypesList.length) {
            this.setState({
                exportTaskTypeId: exportTypesList[0][propertyConstants.PROPERTY_ID]
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableDatabaseSystemZones:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === menuConstants.TABLE_DATA_EXPORT)
                        : null) || null
            });
        }
    }

    handleExportTypeChange = (value: number | null): void => {
        this.setState({
            exportTaskTypeId: value
        });
    };

    handleOkButtonClick = (): void => {
        const { params } = this.props;
        const { exportTaskTypeId } = this.state;
        const finalSystemZoneList: Array<SystemZone> = [];
        const filterSystemZoneList = this.getFilteredSystemZoneListForExport();

        if (exportTaskTypeId) {
            for (const item of filterSystemZoneList) {
                const additionalProperties = {
                    [propertyConstants.PROPERTY_WEB_ZONE_LIST]: this.props.webZoneList,
                    [propertyConstants.PROPERTY_EXPORT_TYPE_LIST]: this.props.exportTypesList,
                    [propertyConstants.PROPERTY_EXPORT_TASK_TYPE]:
                        this.props.exportTypesList.find(
                            (item: ExportType) => item[propertyConstants.PROPERTY_ID] === this.state.exportTaskTypeId
                        ) ?? null
                };

                const { data, validationList } = validationHelper.validateItem(
                    params.objectType,
                    modalTypes.ADD_MODAL,
                    this.props.type,
                    [],
                    [],
                    item,
                    Object.keys(item).map((item) => item),
                    [],
                    additionalProperties
                );

                if (validationList.length > 0) {
                    const validation = validationList.find(
                        (validation: Validation) =>
                            validation[propertyConstants.PROPERTY_TYPE] === validationConstants.GENERAL_VALIDATION
                    );

                    if (validation) {
                        this.setState({
                            generalValidation: validation
                        });
                    }
                } else {
                    this.setState({
                        generalValidation: null
                    });

                    finalSystemZoneList.push({
                        ...data[propertyConstants.PROPERTY_DATA],
                        [propertyConstants.PROPERTY_UUID]: undefined
                    });
                }
            }

            if (finalSystemZoneList.length === filterSystemZoneList.length) {
                this.props.insertExportTasks(Number(exportTaskTypeId), finalSystemZoneList);
            }
        }
    };

    getFilteredSystemZoneListForExport = (): Array<SystemZone> => {
        return this.props.systemZoneList.filter((item: any) => item[propertyConstants.PROPERTY_IS_CHECKED]);
    };

    getExportTypeSelectbox = (): JSX.Element => {
        const { params } = this.props;

        let options: Array<{
            key: string;
            value: string;
        }> = generalHelper.getOptionsForSelectbox(
            params.objectType,
            this.props.exportTypesList.filter(
                (exportType) =>
                    exportType?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS]?.[
                        propertyConstants.PROPERTY_ZONE_ID
                    ] ?? true
            )
        );

        // TODO ked bude v less subor validation.less - pridat .modal-content triedu aby sa aplikovali triedy
        return (
            <div className="row">
                <ModalLabelInputButtonContainer
                    modalId={this.props.id}
                    editable={true}
                    required={true}
                    type={tableConstants.TABLE_TYPE_SELECT}
                    objectType={params.objectType}
                    labelClassName={"form-label"}
                    labelCaption={translate("general.type")}
                    inputName={propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID}
                    inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                    inputClassName={"modal-select"}
                    inputOptions={options}
                    inputValue={this.state.exportTaskTypeId ?? ""}
                    inputCallbackChange={(value: string): void => {
                        const typeId = value !== null && value !== "" && !isNaN(Number(value)) ? Number(value) : null;
                        this.handleExportTypeChange(typeId);
                    }}
                />
            </div>
        );
    };

    getDatabasesTable(): JSX.Element | null {
        return (
            <div className="check-table">
                <SystemZoneTable
                    tableConstant={menuConstants.TABLE_MODAL_EXPORT_TASKS}
                    objectType={this.props.params.objectType}
                    dataPrivileges={this.state.tableDatabaseSystemZones}
                    loading={this.props.systemZoneLoading}
                    allList={this.props.systemZoneList}
                    activeList={this.props.systemZoneActiveList}
                    columnOrder={this.props.systemZoneColumnOrder}
                    columnVisibility={this.props.systemZoneColumnVisibility}
                    columnWidth={this.props.systemZoneColumnWidth}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={this.props.systemZoneRowCount}
                    search={this.props.systemZoneSearch}
                    searchParams={this.props.systemZoneSearchParams}
                    showFilterRow={this.props.systemZoneShowFilterRow}
                    showGlobalSearch={this.props.systemZoneShowGlobalSearch}
                    sortingAsc={this.props.systemZoneSortingAsc}
                    sortingCriterion={this.props.systemZoneSortingCriterion}
                    totalCount={tableConstants.DEFAULT_TOTAL_COUNT}
                    modalType={this.props.type}
                    exportTaskType={
                        this.props.exportTypesList.find(
                            (exportType: ExportType) =>
                                exportType && exportType[propertyConstants.PROPERTY_ID] === this.state.exportTaskTypeId
                        ) ?? null
                    }
                />
            </div>
        );
    }

    getFooterAlert = (): JSX.Element | null => {
        const { generalValidation } = this.state;

        if (generalValidation) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_VALIDATION}
                    caption={generalValidation[propertyConstants.PROPERTY_CAPTION]}
                />
            );
        }

        if (this.props.exportLoading) {
            return (
                <FooterAlertProperty
                    modalId={this.props.id}
                    alertTypeId={designConstants.ALERT_VALIDATION}
                    caption={translationHelper.getAlertLoaderTranslations(this.props.params.objectType)}
                />
            );
        }

        return null;
    };

    getOkButton = (): JSX.Element => {
        if (
            this.props.exportLoading ||
            this.props.systemZoneLoading ||
            this.getFilteredSystemZoneListForExport().length === 0 ||
            this.state.exportTaskTypeId === null
        ) {
            return (
                <button className="btn-info pull-right disabled" autoFocus={true} disabled>
                    {translate("general.ok")}
                </button>
            );
        }

        return (
            <button className="btn-info pull-right" autoFocus={true} onClick={(): void => this.handleOkButtonClick()}>
                {translate("general.ok")}
            </button>
        );
    };

    getCancelButton = (): JSX.Element => {
        const { type, closeModal } = this.props;

        if (this.props.exportLoading) {
            return (
                <button className="btn-info pull-right disabled" autoFocus={true} disabled>
                    {translate("general.cancel")}
                </button>
            );
        }

        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    closeModal(type);
                }}
                autoFocus={true}
            >
                {translate("general.cancel")}
            </button>
        );
    };

    render(): JSX.Element {
        const { id, type, closeModal, index } = this.props;

        return (
            <div id={`modal-${id}`} className="modal simple-modal export-modal" style={{ zIndex: index }}>
                <header className="modal-header">
                    <div className="title">{translate("export.exportData")}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content">
                    <form
                        onSubmit={(event): void => {
                            event.preventDefault();
                        }}
                    >
                        {this.getExportTypeSelectbox()}
                        {this.getDatabasesTable()}
                    </form>
                </div>
                <footer className="modal-footer">
                    {this.getFooterAlert()}
                    {this.getOkButton()}
                    {this.getCancelButton()}
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    activeDatabase: Database | null;
    exportTypesList: Array<ExportType>;
    exportLoading: boolean;
    systemZoneList: Array<SystemZone>;
    systemZoneActiveList: Array<SystemZone>;
    systemZoneLoading: boolean;
    systemZoneColumnOrder: Array<propertyConstants.Property>;
    systemZoneColumnVisibility: Record<propertyConstants.Property, boolean>;
    systemZoneColumnWidth: Record<propertyConstants.Property, number>;
    systemZoneRowCount: number;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneShowFilterRow: boolean;
    systemZoneShowGlobalSearch: boolean;
    systemZoneSortingAsc: boolean;
    systemZoneSortingCriterion: propertyConstants.Property;
    webZoneList: Array<WebZone>;
}>;

type DispatchType = {
    insertExportTasks(exportTaskTypeId: number, zonesData: Array<Record<string, any>>): any;
};

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_DATA_EXPORT
    ),
    activeDatabase: state.database.active,
    exportTypesList: state.export.exportTypes,
    exportLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_INSERT_EXPORT_TASKS
    ),
    systemZoneList: state.databaseSystemZone.systemZoneList,
    systemZoneActiveList: state.databaseSystemZone.systemZoneActiveList,
    systemZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES
    ),
    systemZoneColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneSearch: state.databaseSystemZone.systemZoneSearch,
    systemZoneSearchParams: state.databaseSystemZone.systemZoneSearchParams,
    systemZoneShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MODAL_EXPORT_TASKS
    ),
    systemZoneSortingAsc: state.databaseSystemZone.systemZoneSortingAsc,
    systemZoneSortingCriterion: state.databaseSystemZone.systemZoneSortingCriterion,
    webZoneList: state.zone.webAllList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    insertExportTasks: (exportTaskTypeId: number, zoneData: Array<Record<string, any>>): any =>
        dispatch(importExportActions.insertExportTasks(exportTaskTypeId, zoneData))
});

export const ExportAllModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExportAllModal);
