import * as defaultConstants from "../constants/default";

import React, { Component } from "react";
import { ReactSVG } from "react-svg";

import imgCheck from "../resources/img/icons/check.svg";
import imgClose from "../resources/img/icons/close.svg";
import imgError from "../resources/img/icons/plus.svg";
import imgInfo from "../resources/img/icons/error.svg";
import imgWarning from "../resources/img/icons/warning.svg";

type Props = {
    index: number;
    type: string;
    text: string;
    deleteFlashMessage: (id: number) => void;
};

class FlashMessage extends Component<Props> {
    getMessageType = (): JSX.Element => {
        const { type } = this.props;

        switch (type) {
            case defaultConstants.FLASH_MESSAGE_SUCCESS:
                return (
                    <div className="flash-message-type success">
                        <ReactSVG src={imgCheck} className="type-img" />
                    </div>
                );
            case defaultConstants.FLASH_MESSAGE_WARNING:
                return (
                    <div className="flash-message-type warning">
                        <ReactSVG src={imgWarning} className="type-img" />
                    </div>
                );
            case defaultConstants.FLASH_MESSAGE_ERROR:
                return (
                    <div className="flash-message-type error">
                        <ReactSVG src={imgError} className="type-img" />
                    </div>
                );
            default:
                return (
                    <div className="flash-message-type info">
                        <ReactSVG src={imgInfo} className="type-img" />
                    </div>
                );
        }
    };

    render(): JSX.Element {
        const { index } = this.props;

        return (
            <div className="flash-message">
                {this.getMessageType()}
                <div key={index} className="flash-message-content">
                    {this.props.text}
                </div>
                <div className="flash-message-close">
                    <ReactSVG
                        src={imgClose}
                        className="close-img"
                        onClick={(): void => this.props.deleteFlashMessage(index)}
                    />
                </div>
            </div>
        );
    }
}

export default FlashMessage;
