import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Image, mapImage } from "../../types/image";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_IMAGES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const imageList: Array<Image> = [];
            let image: Image | null;

            for (const item of response.data.data) {
                image = mapImage(item);

                if (image) {
                    imageList.push(image);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.IMAGE, imageList, response.data.count)];
        }
        case methods.METHOD_ADD_IMAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                websocketActions.serverDataCommit(),
                universalObjectActions.dataChanged(false),
                universalObjectActions.reloadData(objectTypes.GENERIC_IMAGE),
                universalObjectActions.setActiveItems(objectTypes.GENERIC_IMAGE, []),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGES_ADD),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ICONS_ADD),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGES_EDIT)
            ];
        }
        case methods.METHOD_EDIT_IMAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [websocketActions.serverDataCommit(), universalObjectActions.dataChanged(false)];

            if (state.navigation.modalList.length > 0) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.GENERIC_IMAGE),
                    universalObjectActions.setActiveItems(objectTypes.GENERIC_IMAGE, []),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGES_ADD),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_ICONS_ADD),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGES_EDIT)
                );
            } else {
                const image = mapImage(response.data);

                if (image) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.GENERIC_IMAGE, [image]));
                }
            }
            return requests;
        }
        case methods.METHOD_DELETE_IMAGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                websocketActions.serverDataCommit(),
                universalObjectActions.dataChanged(false),
                universalObjectActions.reloadData(objectTypes.GENERIC_IMAGE),
                universalObjectActions.setActiveItems(objectTypes.GENERIC_IMAGE, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
