import * as dataTypeConstants from "../constants/dataTypeConstants";
import * as defaultConstants from "../constants/default";
import * as fileTypeConstants from "../constants/fileTypes";
import * as historyDatabaseConstants from "../constants/historyDatabaseConstants";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as itemTypeConstants from "../constants/itemTypeConstants";
import * as license from "../constants/license";
import * as menuConstants from "../constants/menuConstants";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as redlikeOptionConstants from "../constants/entityConstants/redlikeOptionConstants";
import * as serverConstants from "../constants/serverConstants";
import * as tableConstants from "../constants/tableConstants";
import * as translationConstants from "../constants/translationConstants";
import * as userGroupPrivilegeConstants from "../constants/userGroupPrivilegeConstants";

import { DataItem } from "../types/dataItem";
import { WebZone } from "../types/web/webZone";
import sha from "sha256";
import { t as translate } from "react-i18nify";

// TODO check while file

export const getHeaderTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.DATABASE:
            return translate("database.master");
        case objectTypes.SYSTEM:
            return translate("system.system");
        case objectTypes.ZONE:
            return translate("zone.zone");
        default:
            return "";
    }
};

export const getMenuSectionTranslation = (key: string, defaultText: string): string => {
    switch (key) {
        case menuConstants.HEADER_GENERIC:
            return translate("general.genericData");
        case menuConstants.HEADER_MASTER:
            return translate("database.master");
        case menuConstants.HEADER_SYSTEM:
            return translate("system.system");
        case menuConstants.HEADER_ZONE:
            return translate("zone.zone");
        default:
            return defaultText;
    }
};

export const getMenuTranslation = (constantText: string, defaultText: string, custom: any): string => {
    const replacements = {
        software: getSoftwareName(custom)
    };

    // TODO missing PAGE_DATA_SYSTEM_ZONE_SETTINGS? a dalsi jako BIP, BIPP? check it all
    // TODO zbavit se nazvu sheet v product sheetech -> vsechno na file
    switch (constantText) {
        case menuConstants.PAGE_GENERIC_BARCODES:
        case menuConstants.TABLE_GENERIC_BARCODES:
            return translate("barcode.barcodes");
        case menuConstants.TABLE_SYSTEM_PRODUCTS_BASE_PACKAGES:
            return translate("product.baseInProductPackages");
        case menuConstants.PAGE_MASTER_BASES:
        case menuConstants.PAGE_SYSTEM_BASES:
        case menuConstants.PAGE_ZONE_BASES:
        case menuConstants.TABLE_MASTER_BASES:
            return translate("base.bases");
        case menuConstants.PAGE_MASTER_COLORANTS:
        case menuConstants.PAGE_SYSTEM_COLORANTS:
        case menuConstants.PAGE_ZONE_COLORANTS:
        case menuConstants.TABLE_MASTER_COLORANTS:
        case menuConstants.TABLE_ZONE_COLORANTS:
            return translate("colorant.colorants");
        case menuConstants.PAGE_MASTER_COLORS:
        case menuConstants.PAGE_SYSTEM_COLORS:
        case menuConstants.PAGE_ZONE_COLORS:
        case menuConstants.TABLE_MASTER_COLORS:
        case menuConstants.TABLE_SYSTEM_COLORS:
        case menuConstants.TABLE_ZONE_COLORS:
            return translate("color.colors");
        case menuConstants.TABLE_COMPANIES:
            return translate("company.companies");
        case menuConstants.PAGE_DATA_EXPORT:
            return translate("export.dataExport");
        case menuConstants.PAGE_DATA_IMPORT:
            return translate("import.dataImport");
        case menuConstants.PAGE_DATA_MASTER_SETTINGS:
            return translate("general.dataMasterSettings");
        case menuConstants.PAGE_DATA_SERVER_SCRIPTS:
            return translate("general.serverScripts");
        case menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS:
            return translate("general.systemZoneSettings");
        case menuConstants.TABLE_OPTIONS_GLOBAL_SETTINGS_ENVIRONMENTAL:
        case menuConstants.TABLE_OPTIONS_USER_SETTINGS_ENVIRONMENTAL:
            return translate("options.environmentalSettings");
        case menuConstants.PAGE_MASTER_FANDECKS:
        case menuConstants.PAGE_SYSTEM_FANDECKS:
        case menuConstants.PAGE_ZONE_FANDECKS:
        case menuConstants.TABLE_MASTER_FANDECKS:
        case menuConstants.TABLE_SYSTEM_FANDECKS:
        case menuConstants.TABLE_ZONE_FANDECKS:
            return translate("fandeck.fandecks");
        case menuConstants.PAGE_MASTER_FORMULAS:
        case menuConstants.PAGE_SYSTEM_FORMULAS:
        case menuConstants.PAGE_ZONE_FORMULAS:
            return translate("formula.formulas");
        case menuConstants.PAGE_MASTER_FORMULA_NOTES:
        case menuConstants.PAGE_SYSTEM_FORMULA_NOTES:
        case menuConstants.PAGE_ZONE_FORMULA_NOTES:
            return translate("formulaNote.formulaNotes");
        case menuConstants.PAGE_GENERIC_CURRENCIES:
            return translate("currency.currencies");
        case menuConstants.PAGE_OPTIONS_GLOBAL_SETTINGS:
            return translate("general.globalSettings");
        case menuConstants.PAGE_GENERIC_IMAGES:
        case menuConstants.TABLE_GENERIC_IMAGES:
            return translate("image.images");
        case menuConstants.PAGE_GENERIC_STATIC_SQL:
            return translate("general.staticSql");
        case menuConstants.PAGE_OPTIONS_LICENSE:
            return translate("license.license");
        case menuConstants.HEADER_MASTER:
            return translate("database.master");
        case menuConstants.HEADER_OPTIONS:
            return translate("general.options");
        case menuConstants.PAGE_GENERIC_PACKAGES:
            return translate("package.packages");
        case menuConstants.PAGE_SYSTEM_PRICES:
        case menuConstants.PAGE_ZONE_PRICES:
            return translate("prices.prices");
        case menuConstants.PAGE_MASTER_PRODUCTS:
        case menuConstants.TABLE_MASTER_PRODUCTS_PRODUCTS:
            return `${translate("product.products")} (${translate("product.productSources")})`;
        case menuConstants.PAGE_SYSTEM_PRODUCTS:
        case menuConstants.PAGE_ZONE_PRODUCTS:
        case menuConstants.TABLE_SYSTEM_PRODUCTS_PRODUCTS:
            return translate("product.products");
        case menuConstants.TABLE_SYSTEM_PRODUCTS_BASES:
            return translate("general.productBase");
        case menuConstants.TABLE_GENERIC_PRODUCT_SHEETS:
        case menuConstants.PAGE_GENERIC_PRODUCT_SHEETS:
            return translate("product.productFiles");
        case menuConstants.PAGE_SYSTEM_PRODUCT_GROUPS:
        case menuConstants.PAGE_ZONE_PRODUCT_GROUPS:
            return translate("productGroup.productGroups");
        case menuConstants.PAGE_MASTER_REDLIKE_OPTIONS:
        case menuConstants.PAGE_SYSTEM_REDLIKE_OPTIONS:
        case menuConstants.PAGE_ZONE_REDLIKE_OPTIONS:
        case menuConstants.TABLE_MASTER_REDLIKE_OPTIONS:
        case menuConstants.TABLE_SYSTEM_REDLIKE_OPTIONS:
        case menuConstants.TABLE_ZONE_REDLIKE_OPTIONS:
            return translate("general.redlikeOptions", replacements);
        case menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES:
            return translate("general.redlikePrivileges", replacements);
        case menuConstants.PAGE_MASTER_REDLIKE_USERS:
        case menuConstants.PAGE_SYSTEM_REDLIKE_USERS:
        case menuConstants.PAGE_ZONE_REDLIKE_USERS:
            return translate("general.redlikeUsers", replacements);
        case menuConstants.HEADER_SYSTEM:
            return translate("system.system");
        case menuConstants.PAGE_GENERIC_UNITS:
            return translate("unit.units");
        case menuConstants.TABLE_USER_GROUPS:
            return translate("user.userGroups");
        case menuConstants.PAGE_OPTIONS_USER_SETTINGS:
            return translate("user.userSettings");
        case menuConstants.PAGE_USER_ACTIONS_HISTORY:
        case menuConstants.TABLE_USER_ACTIONS_HISTORY:
            return translate("user.userActionsHistory");
        case menuConstants.TABLE_USERS:
            return translate("user.users");
        case menuConstants.HEADER_ZONE:
            return translate("zone.zone");
        case menuConstants.TABLE_GLOBAL_ZONES:
            return translate("zone.zones");
        default:
            return defaultText;
    }
};

export const getPropertyTranslation = (
    objectType: objectTypes.ObjectType,
    key = "",
    modalType: modalTypes.ModalType | null = null,
    custom: string | null = null
): string => {
    const replacements = {
        redlikeWeb: translationConstants.REDLIKE_WEB,
        redlikeWebWebsite: translationConstants.REDLIKE_WEB_WEBSITE,
        software: getSoftwareName(custom),
        username: defaultConstants.DEFAULT_REDLIKE_USERNAME
    };

    switch (key) {
        case propertyConstants.PROPERTY_ACTION:
            if (objectType === objectTypes.PRODUCT_FILE || objectType === objectTypes.FILE) {
                return translate("general.preview");
            }
            if (objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
                return translate("oneTimeAccessPassword.generatePassword");
            }
            return translate("general.action");
        case propertyConstants.PROPERTY_ACTION_METHOD:
            return translate("general.actionMethod");
        case propertyConstants.PROPERTY_ACTION_TYPE:
            return translate("general.action");
        case propertyConstants.PROPERTY_ACTION_TYPE_ID:
            return translate("general.actionType");
        case propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES:
            return translate("general.additionalProperties");
        case propertyConstants.PROPERTY_ALTERNATIVE_BARCODES:
            return translate("barcode.alternativeBarcode");
        case propertyConstants.PROPERTY_AMOUNT:
        case propertyConstants.PROPERTY_BASE_AMOUNT:
            return translate("general.amount");
        case propertyConstants.PROPERTY_AMOUNT_COEFFICIENT:
            return translate("general.amountCoef");
        case propertyConstants.PROPERTY_AUTOLOGIN:
            return translate("user.autoLogin");
        case propertyConstants.PROPERTY_BARCODE:
        case propertyConstants.PROPERTY_BARCODES:
            return translate("barcode.barcode");
        case propertyConstants.PROPERTY_BASE_ID:
            return translate("base.base");
        case propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID:
            return translate("base.base");
        case propertyConstants.PROPERTY_BATCH_NUMBER:
            return translate("batch.batchNumber");
        case propertyConstants.PROPERTY_BEGIN_VALUE:
            return translate("general.beginValue");
        case propertyConstants.PROPERTY_BFS:
            return translate("general.bfs");
        case propertyConstants.PROPERTY_FILE_CATEGORY_ID:
        case propertyConstants.PROPERTY_IMAGE_CATEGORY_ID:
            return translate("general.category");
        case propertyConstants.PROPERTY_CHANGELOG:
            return translate("general.changelog");
        case propertyConstants.PROPERTY_CITY:
            return translate("license.city");
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
            return translate("product.colorantMaxAmount");
        case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
            return translate("product.colorantMinAmount");
        case propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS:
            if (objectType === objectTypes.SYSTEM_ZONE) {
                return translate("export.deleteColorsWithoutFormulas");
            }
            return translate("export.colorsWithoutFormulas");
        case propertyConstants.PROPERTY_CODE:
        case propertyConstants.PROPERTY_COLOR_CODE:
            return translate("general.code");
        case propertyConstants.PROPERTY_COEFFICIENT:
            return translate("general.coefficient");
        case propertyConstants.PROPERTY_COLOR_BLUE:
            return translate("general.colorBlue");
        case propertyConstants.PROPERTY_COLOR_DATA:
            return translate("color.colorData");
        case propertyConstants.PROPERTY_COLOR_GREEN:
            return translate("general.colorGreen");
        case propertyConstants.PROPERTY_COLOR_NAME:
            if (objectType === objectTypes.BARCODE) {
                return translate("color.color");
            }

            return translate("general.name");
        case propertyConstants.PROPERTY_COLOR_RED:
            return translate("general.colorRed");
        case propertyConstants.PROPERTY_COLORANT_NAME:
            return translate("colorant.colorant");
        case propertyConstants.PROPERTY_COMPANY_ID:
        case propertyConstants.PROPERTY_POINT_OF_SALE:
            return translate("company.company");
        case propertyConstants.PROPERTY_COMPANY_NAME:
            return translate("company.companyName");
        case propertyConstants.PROPERTY_COMPUTER_ID:
            return translate("general.computerId");
        case propertyConstants.PROPERTY_CONTACT_PERSON:
            return translate("license.contactPerson");
        case propertyConstants.PROPERTY_COUNTRY:
            return translate("license.country");
        case propertyConstants.PROPERTY_CURRENCY_ID:
            return translate("currency.currency");
        case propertyConstants.PROPERTY_CUSTOM_FORMULA:
            return translate("product.customFormula");
        case propertyConstants.PROPERTY_DATA:
            return translate("general.parameters");
        case propertyConstants.PROPERTY_DATE:
            if (objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
                return translate("oneTimeAccessPassword.validForOneDay");
            }
            return translate("general.date");
        case propertyConstants.PROPERTY_DATE_CREATED:
            return translate("general.created");
        case propertyConstants.PROPERTY_DATE_FROM:
            if (modalType === modalTypes.MODAL_EXPORT_TASKS_DELETE) {
                return translate("export.exportDate");
            }
            if (modalType === modalTypes.MODAL_IMPORT_TASKS_DELETE) {
                return translate("import.importDate");
            }
            return translate("general.date");
        case propertyConstants.PROPERTY_DATE_UPDATED:
            return translate("general.updated");
        case propertyConstants.PROPERTY_DEFAULT_DATA:
            return translate("general.defaultData");
        case propertyConstants.PROPERTY_DENSITY:
            return translate("general.density");
        case propertyConstants.PROPERTY_DESCRIPTION:
            if (objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
                return translate("oneTimeAccessPassword.hint", replacements);
            }
            return translate("general.description");
        case propertyConstants.PROPERTY_EMAIL:
            return translate("license.email");
        case propertyConstants.PROPERTY_END_VALUE:
            return translate("general.endValue");
        case propertyConstants.PROPERTY_EXPIRATION:
            return translate("license.expiration");
        case propertyConstants.PROPERTY_FANDECK_NAME:
            return translate("fandeck.fandeck");
        case propertyConstants.PROPERTY_FANDECK_PAGE:
            return translate("color.fandeckPage");
        case propertyConstants.PROPERTY_FANDECK_POSITION:
            return translate("color.fandeckPosition");
        case propertyConstants.PROPERTY_FILE:
            return translate("file.file");
        case propertyConstants.PROPERTY_FILE_TYPE:
        case propertyConstants.PROPERTY_FILE_TYPE_ID:
            return translate("file.fileType");
        case propertyConstants.PROPERTY_FILES:
            return translate("file.files");
        case propertyConstants.PROPERTY_FILL_PROC:
            return translate("general.fillProc");
        case propertyConstants.PROPERTY_FILL_VOLUME:
        case propertyConstants.PROPERTY_FILL_VOLUME_ML:
            return translate("general.fillVolume");
        case propertyConstants.PROPERTY_DATE_FINISHED:
            return translate("general.finished");
        case propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT:
            return translate("formula.formulaFor");
        case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
            return translate("product.forcedFormulaBaseAmountType");
        case propertyConstants.PROPERTY_IS_GRAVIMETRIC:
            return translate("general.gravimetric");
        case propertyConstants.PROPERTY_IS_GRAVIMETRIC_FILL:
            return translate("general.gravimetricFill");
        case propertyConstants.PROPERTY_PRODUCT_GROUP_LIST:
            return translate("general.groups");
        case propertyConstants.PROPERTY_HEIGHT:
            return translate("general.height");
        case propertyConstants.PROPERTY_ICON_ID:
            return translate("image.icon");
        case propertyConstants.PROPERTY_COLORANT_ID:
        case propertyConstants.PROPERTY_ID:
            return translate("general.id");
        case propertyConstants.PROPERTY_IMAGE_ID:
            return translate("image.image");
        case propertyConstants.PROPERTY_INTERVAL:
            return translate("general.interval");
        case propertyConstants.PROPERTY_ITEM_TYPE_ID:
            return translate("general.section");
        case propertyConstants.PROPERTY_COMPANY_KEY:
        case propertyConstants.PROPERTY_KEY:
            return translate("general.key");
        case propertyConstants.PROPERTY_DATABASE_ID:
        case propertyConstants.PROPERTY_DATABASE_NAME:
            return translate("database.master");
        case propertyConstants.PROPERTY_BASE_NAME_MASTER:
        case propertyConstants.PROPERTY_NAME_MASTER:
        case propertyConstants.PROPERTY_PACKAGE_NAME_MASTER:
            return translate("database.masterName");
        case propertyConstants.PROPERTY_MASTER_ONLY:
            if (modalType === modalTypes.MODAL_DATABASES_DUPLICATE) {
                return translate("database.masterOnlyDuplicate");
            }
            return translate("database.masterOnly");
        case propertyConstants.PROPERTY_LANGUAGE:
            return translate("options.language");
        case propertyConstants.PROPERTY_LARGO_NAME:
            return translate("general.largoName");
        case propertyConstants.PROPERTY_LAST_STATUS_CHANGE:
            return translate("batch.lastStatusChange");
        case propertyConstants.PROPERTY_LEVEL:
            return translate("general.level");
        case propertyConstants.PROPERTY_LICENSE_IDENTIFICATION:
            return translate("license.licenseIdentification");
        case propertyConstants.PROPERTY_LICENSE_STATUS:
            return translate("license.licenseStatus");
        case propertyConstants.PROPERTY_MIN_DOSAGE:
            return translate("general.minDosage");
        case propertyConstants.PROPERTY_COLOR_MORE_INFO:
        case propertyConstants.PROPERTY_MORE_INFO:
            return translate("general.moreInfo");
        case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
            return translate("general.moreInfoInternal");
        case propertyConstants.PROPERTY_MORE_INFO_PRINT:
            return translate("general.moreInfoPrint");
        case propertyConstants.PROPERTY_BASE_NAME:
            if (objectType === objectTypes.BARCODE || objectTypeHelper.isObjectTypeFormula(objectType)) {
                return translate("base.base");
            }
            return translate("general.name");
        case propertyConstants.PROPERTY_PERSON_NAME:
        case propertyConstants.PROPERTY_USERNAME:
            if (objectType === objectTypes.USER_ACTION_HISTORY || objectType === objectTypes.ONE_TIME_ACCESS_PASSWORD) {
                return translate("user.username");
            }
            return translate("general.name");
        case propertyConstants.PROPERTY_NAME: {
            if (
                objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
                objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE
            ) {
                return translate("product.packageBaseName");
            }
            if (objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE) {
                return translate("system.systemName");
            }
            if (objectType === objectTypes.ZONE_PRODUCT_DUPLICATE) {
                return translate("zone.zoneName");
            }
            return translate("general.name");
        }
        case propertyConstants.PROPERTY_PACKAGE_NAME: {
            if (objectType === objectTypes.BARCODE) {
                return translate("package.package");
            }
            if (
                objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
                objectType === objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE
            ) {
                return translate("package.packageName");
            }
            return translate("general.name");
        }
        case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
        case propertyConstants.PROPERTY_PACKAGE_NOMINAL_AMOUNT:
            return translate("general.nominalAmount");
        case priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST:
            return translate("prices.optionCompanyCost");
        case priceConstants.PRICE_OPTION_PRICE_DEFINITION:
            return translate("prices.optionDefinition");
        case priceConstants.PRICE_OPTION_PRICE_CALCULATION:
            return translate("prices.optionPriceCalculation");
        case priceConstants.PRICE_OPTION_USE_DISCOUNT:
            return translate("prices.optionPriceCalculationDiscount");
        case priceConstants.PRICE_OPTION_USE_MARGIN:
            return translate("prices.optionPriceCalculationMargin");
        case priceConstants.PRICE_OPTION_USE_MARKUP:
            return translate("prices.optionPriceCalculationMarkup");
        case priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS:
            return translate("prices.optionPriceGroups");
        case priceConstants.PRICE_OPTION_NUMBER_OF_PRICE_GROUPS:
            return translate("prices.optionPriceGroupsNumber");
        case propertyConstants.PROPERTY_PASSWORD:
        case propertyConstants.PROPERTY_ZONE_PASSWORD:
            return translate("general.password");
        case propertyConstants.PROPERTY_PATH:
            return translate("general.path");
        case propertyConstants.PROPERTY_PHONE:
            return translate("license.phone");
        case propertyConstants.PROPERTY_PROC_PRODUCT:
            return translate("prices.percentValueBase");
        case propertyConstants.PROPERTY_PROC_COLORANT:
            return translate("prices.percentValueColorant");
        case propertyConstants.PROPERTY_COLOR_PRIORITY:
        case propertyConstants.PROPERTY_PRIORITY:
            return translate("general.priority");
        case propertyConstants.PROPERTY_PRODUCT_ID:
            if (objectType === objectTypes.MASTER_FORMULA) {
                return translate("product.product");
            }
            if (objectType === objectTypes.LICENSE) {
                return translate("license.versionTypes");
            }
            return translate("product.productId");
        case propertyConstants.PROPERTY_PRODUCT_NAME: {
            if (objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE) {
                return translate("product.productNameInSystem");
            }
            if (objectType === objectTypes.ZONE_PRODUCT_DUPLICATE) {
                return translate("product.productNameInZone");
            }
            return translate("product.product");
        }
        case propertyConstants.PROPERTY_PRICE_COST:
        case propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM:
        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_COST_PER_UNIT:
            return translate("prices.propertyCompanyCostPrice");
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER:
            return translate("prices.propertyPurchasePriceGroup");
        case propertyConstants.PROPERTY_PRICE_PURCHASE:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT:
            return translate("prices.propertyPurchasePrice");
        case propertyConstants.PROPERTY_PRICE_SELL:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT:
            return translate("prices.propertySellPrice");
        case propertyConstants.PROPERTY_PRICE_SURCHARGE:
            return translate("prices.propertySurcharge");
        case propertyConstants.PROPERTY_PRICE_VAT:
            return translate("prices.propertyVatPrice");
        case propertyConstants.PROPERTY_PRINT_NAME:
            return translate("general.printName");
        case propertyConstants.PROPERTY_PRODUCTION_DATE:
            return translate("batch.productionDate");
        case propertyConstants.PROPERTY_RANGE:
            return translate("prices.rangeFrom");
        case propertyConstants.PROPERTY_REFLECTANCE_DATA:
            return translate("color.reflectance");
        case propertyConstants.PROPERTY_REGION:
            return translate("license.region");
        case propertyConstants.PROPERTY_COLOR_RGB_HEX:
            return translate("general.rgb");
        case propertyConstants.PROPERTY_ROUNDING:
            return translate("general.rounding");
        case propertyConstants.PROPERTY_COLOR_SEARCH_CODE:
        case propertyConstants.PROPERTY_SEARCH_CODE:
            return translate("general.searchCode");
        case propertyConstants.PROPERTY_OVERWRITE:
            return translate("general.lock");
        case propertyConstants.PROPERTY_COLOR_SEARCH_NAME:
        case propertyConstants.PROPERTY_SEARCH_NAME:
            return translate("general.searchName");
        case propertyConstants.PROPERTY_LENGTH:
            if (objectType === objectTypes.DATABASE_MONITORING) {
                return translate("file.fileSize");
            }

            return translate("general.size");
        case propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES:
            return translate("zone.importDataFromRedlikeWeb", replacements);
        case propertyConstants.PROPERTY_STATIC_SQL:
            return translate("general.staticSql");
        case propertyConstants.PROPERTY_ENABLED:
        case propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID:
        case propertyConstants.PROPERTY_FORMULAS_STATUS:
        case propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID:
        case propertyConstants.PROPERTY_STATUS:
        case propertyConstants.PROPERTY_STATUS_ID:
        case propertyConstants.PROPERTY_STATUS_LIST:
            return translate("general.status");
        case propertyConstants.PROPERTY_STREET:
            return translate("license.street");
        case propertyConstants.PROPERTY_SYMBOL:
            return translate("general.symbol");
        case propertyConstants.PROPERTY_SYSTEM_ID:
        case propertyConstants.PROPERTY_SYSTEM_NAME:
            return translate("system.system");
        case propertyConstants.PROPERTY_BASE_NAME_SYSTEM:
        case propertyConstants.PROPERTY_NAME_SYSTEM:
        case propertyConstants.PROPERTY_PACKAGE_NAME_SYSTEM:
            return translate("system.systemName");
        case propertyConstants.PROPERTY_VALUE: {
            if (objectTypeHelper.isObjectTypeFormulaNote(objectType)) {
                return translate("general.text");
            }

            if (objectTypeHelper.isObjectTypePriceGroup(objectType)) {
                return translate("prices.rangeTo");
            }

            return translate("general.value");
        }
        case propertyConstants.PROPERTY_VALUE_MASTER:
            return translate("general.textMaster");
        case propertyConstants.PROPERTY_VALUE_SYSTEM:
            return translate("general.textSystem");
        case propertyConstants.PROPERTY_TMC_NAME:
            return translate("general.tmcName");
        case propertyConstants.PROPERTY_MARGIN_TYPE:
        case propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID:
        case propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID:
        case propertyConstants.PROPERTY_TYPE_ID:
            return translate("general.type");
        case propertyConstants.PROPERTY_UFI_CODE:
            return translate("general.ufiCode");
        case propertyConstants.PROPERTY_UNIT_ID:
            return translate("unit.unit");
        case propertyConstants.PROPERTY_UNIVERSAL_VALUE:
            return translate("general.value");
        case propertyConstants.PROPERTY_UPDATE_ONLY:
            return translate("formula.onlyReplaceExistingData");
        case propertyConstants.PROPERTY_URL:
            return translate("general.url");
        case propertyConstants.PROPERTY_USER_GROUP_LIST:
            return translate("user.userGroups");
        case propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_ADMINS:
            return translate("user.userGroupAdmins");
        case propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_GUESTS:
            return translate("user.userGroupGuests");
        case propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_MANAGERS:
            return translate("user.userGroupManagers");
        case propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_SUPERADMINS:
            return translate("user.userGroupSuperadmins");
        case propertyConstants.PROPERTY_USER_GROUP_PRIVILEGE_USERS:
            return translate("user.userGroupUsers");
        case propertyConstants.PROPERTY_UUID:
            return translate("general.uuid");
        case propertyConstants.PROPERTY_SYSTEM_UUID:
            return translate("system.uuid"); // TODO unify with "general.uuid" and "zone.uuid"
        case propertyConstants.PROPERTY_ZONE_UUID:
            return translate("zone.uuid");
        case propertyConstants.PROPERTY_VERSION:
            return translate("general.version");
        case propertyConstants.PROPERTY_VERSION_NAME:
            return translate("general.versionName");
        case propertyConstants.PROPERTY_WAVELENGTH:
            return translate("color.wavelength");
        case propertyConstants.PROPERTY_WIDTH:
            return translate("general.width");
        case propertyConstants.PROPERTY_ZIP:
            return translate("license.zip");
        case propertyConstants.PROPERTY_ZONE_ID:
        case propertyConstants.PROPERTY_ZONE_NAME:
            return translate("zone.zone");
        default:
            return "";
    }
};

export const getEmptyPropertyTranslation = (property: propertyConstants.Property): string => {
    switch (property) {
        case propertyConstants.PROPERTY_BARCODES:
            return translate("barcode.noBarcode");
        case propertyConstants.PROPERTY_BASE_NAME:
            return translate("base.noBase");
        case propertyConstants.PROPERTY_CODE:
        case propertyConstants.PROPERTY_COLOR_CODE:
            return translate("general.noCode");
        case propertyConstants.PROPERTY_COLOR_PRIORITY:
            return translate("general.noPriority");
        case propertyConstants.PROPERTY_COLOR_SEARCH_CODE:
            return translate("general.noSearchCode");
        case propertyConstants.PROPERTY_COLOR_SEARCH_NAME:
            return translate("general.noSearchName");
        case propertyConstants.PROPERTY_FANDECK_PAGE:
            return translate("color.noFandeckPage");
        case propertyConstants.PROPERTY_FANDECK_POSITION:
            return translate("color.noFandeckPosition");
        case propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT:
            return translate("formula.noFormulaFor");
        case propertyConstants.PROPERTY_IMAGE_ID:
            return translate("image.noImage");
        case propertyConstants.PROPERTY_LARGO_NAME:
            return translate("general.noLargoName");
        case propertyConstants.PROPERTY_MORE_INFO:
        case propertyConstants.PROPERTY_COLOR_MORE_INFO:
            return translate("general.noMoreInfo");
        case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
            return translate("general.noMoreInfoInternal");
        case propertyConstants.PROPERTY_MORE_INFO_PRINT:
            return translate("general.noMoreInfoPrint");
        case propertyConstants.PROPERTY_NAME:
        case propertyConstants.PROPERTY_COLOR_NAME:
            return translate("general.noName");
        case propertyConstants.PROPERTY_STATUS:
            return translate("general.noStatus");
        case propertyConstants.PROPERTY_TMC_NAME:
            return translate("general.noTmcName");
        case propertyConstants.PROPERTY_UUID:
            return translate("general.noUuid");
        default:
            return "";
    }
};

export const getObjectTypeTranslation = (objectType: objectTypes.ObjectType, plural = false): string => {
    switch (objectType) {
        case objectTypes.MASTER_BASE:
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_MASTER_BASE:
        case objectTypes.SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
        case objectTypes.ZONE_SYSTEM_BASE:
        case objectTypes.ZONE_BASE: {
            if (plural) {
                return translate("base.bases");
            }
            return translate("base.base");
        }
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            if (plural) {
                return translate("product.basesInProduct");
            }
            return translate("product.baseInProduct");
        }
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_MASTER_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            if (plural) {
                return translate("product.baseInProductPackages");
            }
            return translate("product.baseInProductPackage");
        }
        case objectTypes.MASTER_COLORANT:
        case objectTypes.MASTER_SYSTEM_COLORANT:
        case objectTypes.SYSTEM_MASTER_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
        case objectTypes.ZONE_SYSTEM_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            if (plural) {
                return translate("colorant.colorants");
            }
            return translate("colorant.colorant");
        }
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_MASTER_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
        case objectTypes.ZONE_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            if (plural) {
                return translate("colorant.colorantPackages");
            }
            return translate("colorant.colorantPackage");
        }
        case objectTypes.MASTER_FANDECK:
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_MASTER_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
        case objectTypes.ZONE_SYSTEM_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            if (plural) {
                return translate("fandeck.fandecks");
            }
            return translate("fandeck.fandeck");
        }
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_MASTER_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
        case objectTypes.ZONE_SYSTEM_PRODUCT:
        case objectTypes.ZONE_PRODUCT: {
            if (plural) {
                return translate("product.products");
            }
            return translate("product.product");
        }
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.SYSTEM_MASTER_PRODUCT_GROUP:
        case objectTypes.SYSTEM_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
        case objectTypes.ZONE_SYSTEM_PRODUCT_GROUP:
        case objectTypes.ZONE_PRODUCT_GROUP: {
            if (plural) {
                return translate("productGroup.productGroups");
            }
            return translate("productGroup.productGroup");
        }
        default:
            return "";
    }
};

export const getTitleSectionTranslation = (
    objectType: objectTypes.ObjectType,
    custom: string | null = null
): string => {
    const replacements = {
        thisSoftware: getThisSoftwareName(custom),
        software: getSoftwareName(custom)
    };

    switch (objectType) {
        case objectTypes.BARCODE: {
            return translate("barcode.barcodes");
        }
        case objectTypes.MASTER_BASE:
        case objectTypes.MASTER_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_BASE:
        case objectTypes.ZONE_BASE: {
            return translate("base.bases");
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            return translate("product.basesInProduct");
        }
        case objectTypes.COMPANY: {
            return translate("company.companies");
        }
        case objectTypes.CURRENCY: {
            return translate("currency.currencies");
        }
        case objectTypes.DATABASE: {
            return translate("database.databases");
        }
        case objectTypes.DATABASE_MONITORING: {
            return translate("database.masterDatabases");
        }
        case objectTypes.EXPORT_TASK: {
            return translate("export.exportList");
        }
        case objectTypes.GENERIC_IMAGE: {
            return translate("image.images");
        }
        case objectTypes.IMPORT_TASK: {
            return translate("import.importTaskList");
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.PACKAGE: {
            return translate("package.packages");
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            return translate("product.baseInProductPackages");
        }
        case objectTypes.MASTER_COLORANT:
        case objectTypes.SYSTEM_COLORANT:
        case objectTypes.ZONE_COLORANT: {
            return translate("colorant.colorants");
        }
        case objectTypes.MASTER_COLORANT_BATCH: {
            return translate("colorant.colorantBatches");
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE:
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            return translate("colorant.colorantPackages");
        }
        case objectTypes.MASTER_FANDECK:
        case objectTypes.SYSTEM_FANDECK:
        case objectTypes.ZONE_FANDECK: {
            return translate("fandeck.fandecks");
        }
        case objectTypes.MASTER_FORMULA_NOTE:
        case objectTypes.SYSTEM_FORMULA_NOTE:
        case objectTypes.ZONE_FORMULA_NOTE: {
            return translate("formulaNote.formulaNotes");
        }
        case objectTypes.SYSTEM_PRICE_GENERIC:
        case objectTypes.ZONE_PRICE_GENERIC: {
            return translate("prices.defaultPrices");
        }

        case objectTypes.SYSTEM_PRICE_GROUP:
        case objectTypes.ZONE_PRICE_GROUP: {
            return translate("prices.priceGroups");
        }
        case objectTypes.MASTER_PRODUCT:
        case objectTypes.SYSTEM_PRODUCT:
        case objectTypes.ZONE_PRODUCT: {
            return translate("product.products");
        }
        case objectTypes.SYSTEM_PRODUCT_GROUP:
        case objectTypes.ZONE_PRODUCT_GROUP: {
            return translate("productGroup.productGroups");
        }
        case objectTypes.PRODUCT_SHEET: {
            return translate("product.productFiles");
        }
        case objectTypes.MASTER_REDLIKE_OPTION:
        case objectTypes.SYSTEM_REDLIKE_OPTION:
        case objectTypes.ZONE_REDLIKE_OPTION: {
            return translate("general.redlikeOptions", replacements);
        }
        case objectTypes.MASTER_REDLIKE_USER:
        case objectTypes.SYSTEM_REDLIKE_USER:
        case objectTypes.ZONE_REDLIKE_USER: {
            return translate("general.redlikeUsers", replacements);
        }
        case objectTypes.MASTER_REDLIKE_USER_PRIVILEGES: {
            return translate("general.redlikePrivileges", replacements);
        }
        case objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS:
        case objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS:
        case objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS: {
            return translate("user.availableUsersAndGroups");
        }
        case objectTypes.STATIC_SQL: {
            return translate("general.staticSql");
        }
        case objectTypes.SYSTEM: {
            return translate("system.systems");
        }
        case objectTypes.ZONE: {
            return translate("zone.zones");
        }
        case objectTypes.UNIT: {
            return translate("unit.units");
        }
        case objectTypes.USER: {
            return translate("user.users");
        }
        case objectTypes.USER_ACTION_HISTORY: {
            return translate("user.userActionsHistory");
        }
        case objectTypes.USER_OPTION:
        case objectTypes.GLOBAL_OPTION: {
            return translate("general.redlikeManagerSettings", replacements);
        }
        case objectTypes.MASTER_REDLIKE_USER_GROUP:
        case objectTypes.USER_GROUP: {
            return translate("user.userGroups");
        }
        case objectTypes.USER_GROUP_PRIVILEGE: {
            return translate("privileges.privileges");
        }
        default:
            return "";
    }
};

// TODO doplnit vic modal titles do nasledujich metod
export const getAddItemTranslation = (objectType: objectTypes.ObjectType, value = ""): string => {
    switch (objectType) {
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
            return translate("base.addBase");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
            return translate("product.addBaseInProduct");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.addBaseInProductPackage");
        case objectTypes.MASTER_SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
            return translate("colorant.addColorant");
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
            return translate("colorant.addColorantPackage");
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
            return translate("fandeck.addFandeck");
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
            return translate("product.addProduct");
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
            return translate("productGroup.addProductGroup");
        case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            return translate("productGroup.productGroupForProduct", { name: value });
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
            return translate("user.addUser");
        default:
            return "";
    }
};

export const getEditItemTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
            return translate("user.editUser");
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            return translate("productGroup.editProductGroup");
        default:
            return "";
    }
};

export const getSelectFromItemTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
            return translate("user.selectFromUserGroups");
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
            return translate("base.selectFromBases");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
            return translate("product.selectFromBaseInProducts");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.selectFromBaseInProductPackages");
        case objectTypes.MASTER_SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
            return translate("colorant.selectFromColorants");
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
            return translate("colorant.selectFromColorantPackages");
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
            return translate("fandeck.selectFromFandecks");
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_MASTER_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            return translate("product.selectFromProducts");
        case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            return translate("productGroup.selectFromProductGroups");
        default:
            return "";
    }
};

export const getSelectedItemsTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
        case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
        case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
            return translate("user.selectedUserGroups");
        case objectTypes.MASTER_SYSTEM_BASE:
        case objectTypes.SYSTEM_ZONE_BASE:
            return translate("base.selectedBases");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT:
            return translate("product.selectedBaseInProducts");
        case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.selectedBaseInProductPackages");
        case objectTypes.MASTER_SYSTEM_COLORANT:
        case objectTypes.SYSTEM_ZONE_COLORANT:
            return translate("colorant.selectedColorants");
        case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE:
        case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE:
            return translate("colorant.selectedColorantPackages");
        case objectTypes.MASTER_SYSTEM_FANDECK:
        case objectTypes.SYSTEM_ZONE_FANDECK:
            return translate("fandeck.selectedFandecks");
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
        case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS:
        case objectTypes.MASTER_SYSTEM_PRODUCT:
        case objectTypes.SYSTEM_MASTER_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP_WITH_PRODUCTS:
            return translate("product.selectedProducts");
        case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS:
        case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP:
        case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS:
            return translate("productGroup.selectedProductGroups");
        default:
            return "";
    }
};

export const getInvalidCaptionByProperty = (
    objectType: objectTypes.ObjectType,
    property: propertyConstants.Property
): string => {
    const replacements = {
        redlikeWeb: translationConstants.REDLIKE_WEB
    };

    switch (property) {
        case propertyConstants.PROPERTY_BFS: {
            switch (objectType) {
                case objectTypes.ZONE_COLORANT:
                case objectTypes.SYSTEM_COLORANT:
                case objectTypes.MASTER_COLORANT: {
                    return translate("validation.bfsColorant");
                }
                case objectTypes.ZONE_BASE_IN_PRODUCT:
                case objectTypes.SYSTEM_BASE_IN_PRODUCT:
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    return translate("validation.bfsProduct");
                }
                default:
                    break;
            }
            return "";
        }
        case propertyConstants.PROPERTY_COLOR_RED:
        case propertyConstants.PROPERTY_COLOR_GREEN:
        case propertyConstants.PROPERTY_COLOR_BLUE: {
            return translate("validation.rgbValue");
        }
        case propertyConstants.PROPERTY_COLORANT_MAX_FILL: {
            return translate("validation.maxFill");
        }
        case propertyConstants.PROPERTY_COMPUTER_ID: {
            return translate("validation.computerId");
        }
        case propertyConstants.PROPERTY_EMAIL: {
            return translate("validation.email");
        }
        case propertyConstants.PROPERTY_NAME: {
            switch (objectType) {
                case objectTypes.ZONE: {
                    return translate("validation.zoneName");
                }
                default:
                    break;
            }

            return "";
        }
        case propertyConstants.PROPERTY_PASSWORD: {
            return translate("validation.passwordValue");
        }
        case propertyConstants.PROPERTY_PASSWORD_NEW: {
            return translate("validation.passwordConfirmation");
        }
        case propertyConstants.PROPERTY_PHONE: {
            return translate("validation.phone");
        }
        case propertyConstants.PROPERTY_UFI_CODE: {
            return translate("validation.ufiCode");
        }
        case propertyConstants.PROPERTY_USERNAME: {
            switch (objectType) {
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP:
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP:
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP:
                    return translate("validation.username");
                default:
                    return translate("validation.invalidInput");
            }
        }
        case propertyConstants.PROPERTY_UUID: {
            return translate("validation.uuid");
        }
        case propertyConstants.PROPERTY_VERSION: {
            return translate("validation.versionValue");
        }
        case propertyConstants.PROPERTY_VERSION_NAME: {
            return translate("validation.versionNameValue");
        }
        case propertyConstants.PROPERTY_ZONE_UUID: {
            return translate("validation.zoneInvalidUuid", replacements);
        }
        default:
            return translate("validation.invalidInput");
    }
};

export const getInvalidCaptionForModalTable = (property: propertyConstants.Property): string => {
    switch (property) {
        case propertyConstants.PROPERTY_PASSWORD:
            return translate("validation.tableValidation", { item: translate("general.password") });
        case propertyConstants.PROPERTY_VERSION:
            return translate("validation.tableValidation", { item: translate("general.version") });
        case propertyConstants.PROPERTY_VERSION_NAME:
            return translate("validation.tableValidation", { item: translate("general.versionName") });
        case propertyConstants.PROPERTY_UUID:
            return translate("validation.zoneInvalidUuid", { redlikeWeb: translationConstants.REDLIKE_WEB });
        default:
            return translate("validation.invalidInput");
    }
};

export const getInputCaptionTranslation = (
    type: tableConstants.TableType,
    property: propertyConstants.Property
): string | undefined => {
    switch (type) {
        case tableConstants.TABLE_TYPE_BOOLEAN: {
            switch (property) {
                case propertyConstants.PROPERTY_AUTOLOGIN:
                    return translate("user.autologinCaption");
                case propertyConstants.PROPERTY_CUSTOM_FORMULA:
                    return translate("product.customFormulaCaption");
                case priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST:
                    return translate("prices.optionCompanyCostCaption");
                case priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS:
                    return translate("prices.optionPriceGroupsCaption");
                case propertyConstants.PROPERTY_OVERWRITE:
                    return translate("options.lockCaption");
                case propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS:
                    return translate("export.colorsWithoutFormulasCaption");
                default:
                    return undefined;
            }
        }
        case tableConstants.TABLE_TYPE_BUTTON: {
            switch (property) {
                case propertyConstants.PROPERTY_GENERATE_PASSWORD_ACTION:
                    return translate("oneTimeAccessPassword.generatePassword");
                case propertyConstants.PROPERTY_COPY_TO_CLIPBOARD_ACTION:
                    return translate("oneTimeAccessPassword.copyToClipboard");
                default:
                    return undefined;
            }
        }
        case tableConstants.TABLE_TYPE_BUTTON_SECTION: {
            switch (property) {
                case propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES:
                    return translate("zone.showAvailableZones");
                default:
                    return undefined;
            }
        }
        case tableConstants.TABLE_TYPE_SELECT: {
            switch (property) {
                case propertyConstants.PROPERTY_COMPANY_ID:
                    return translate("company.allCompanies");
                case propertyConstants.PROPERTY_ZONE_ID:
                case propertyConstants.PROPERTY_SYSTEM_ID:
                case propertyConstants.PROPERTY_DATABASE_ID:
                    return translate("general.all");
                default:
                    return undefined;
            }
        }
        default:
            return undefined;
    }
};

// TODO should be according to key instead of ID
export const getExportStatusTranslation = (id: number): string => {
    switch (id) {
        case importExportConstants.EXPORT_STATUS_ALL:
            return translate("general.all");
        case importExportConstants.EXPORT_STATUS_ENQUEUED:
            return translate("export.statuses.enqueued");
        case importExportConstants.EXPORT_STATUS_READY_TO_BE_PROCESSES:
            return translate("export.statuses.readyToBeProcessed");
        case importExportConstants.EXPORT_STATUS_PROCESSING_DATA:
            return translate("export.statuses.processingData");
        case importExportConstants.EXPORT_STATUS_ADDING_INDEXES:
            return translate("export.statuses.addingIndexes");
        case importExportConstants.EXPORT_STATUS_ADDING_META_DATA:
            return translate("export.statuses.addingMetaData");
        case importExportConstants.EXPORT_STATUS_FINALIZING_DATABASE:
            return translate("export.statuses.finalizingDatabase");
        case importExportConstants.EXPORT_STATUS_EXPORTING_DATABASE:
            return translate("export.statuses.exportingDatabase");
        case importExportConstants.EXPORT_STATUS_OK:
            return translate("export.exportCompleted");
        case importExportConstants.EXPORT_STATUS_ERROR:
            return translate("export.exportError");
        case importExportConstants.EXPORT_STATUS_ABORTING:
            return translate("export.statuses.aborting");
        case importExportConstants.EXPORT_STATUS_ABORTED:
            return translate("export.statuses.aborted");
        case importExportConstants.EXPORT_STATUS_DELETED:
            return translate("export.statuses.deleted");
        default:
            return "";
    }
};

export const getExportTypeTranslation = (key: string): string => {
    switch (key) {
        case importExportConstants.EXPORT_TYPE_ARCHIVE_LINUX_KEY:
            return translate("export.types.archiveLinux");
        case importExportConstants.EXPORT_TYPE_ARCHIVE_WINDOWS_KEY:
            return translate("export.types.archiveWindows");
        case importExportConstants.EXPORT_TYPE_DESO_MASTER_EXCEL_KEY:
            return translate("export.types.desoMasterExcel");
        case importExportConstants.EXPORT_TYPE_DESO_ZONE_EXCEL_KEY:
            return translate("export.types.desoZoneExcel");
        case importExportConstants.EXPORT_TYPE_EMPTY_DATABASE_KEY:
            return translate("export.types.emptyDatabase");
        case importExportConstants.EXPORT_TYPE_INNO_SETUP_KEY:
            return translate("export.types.innoSetup");
        case importExportConstants.EXPORT_TYPE_LARGO_TINT_2000_KEY:
            return translate("export.types.largoTint2000");
        case importExportConstants.EXPORT_TYPE_ASC_PHB_KEY:
            return translate("export.types.ascPhb");
        case importExportConstants.EXPORT_TYPE_ONLY_DATABASE_KEY:
            return translate("export.types.onlyDatabase");
        case importExportConstants.EXPORT_TYPE_ONLY_DATABASE_TO_WEB_KEY:
            return translate("export.types.onlyDatabaseToWeb", { redlikeWeb: translationConstants.REDLIKE_WEB });
        case importExportConstants.EXPORT_TYPE_TMC_PRODUCTS_EXCEL_KEY:
            return translate("export.types.tmcProductsExcel");
        default:
            return key;
    }
};

export const getImportStatusTranslation = (key: string): string => {
    switch (key) {
        case importExportConstants.IMPORT_STATUS_ABORTED:
            return translate("import.statuses.aborted");
        case importExportConstants.IMPORT_STATUS_ABORTING:
            return translate("import.statuses.aborting");
        case importExportConstants.IMPORT_STATUS_ALL:
            return translate("general.all");
        case importExportConstants.IMPORT_STATUS_ANALYZING_FILE:
            return translate("import.statuses.analyzingFile");
        case importExportConstants.IMPORT_STATUS_CONNECTING_TO_DATABASE:
            return translate("import.statuses.connectingToDatabase");
        case importExportConstants.IMPORT_STATUS_DELETED:
            return translate("import.statuses.deleted");
        case importExportConstants.IMPORT_STATUS_ENQUEUED:
            return translate("import.statuses.enqueued");
        case importExportConstants.IMPORT_STATUS_OPENING_FILE:
            return translate("import.statuses.openingFile");
        case importExportConstants.IMPORT_STATUS_PREPARING_FOR_IMPORT:
            return translate("import.statuses.preparingForImport");
        case importExportConstants.IMPORT_STATUS_PROCESSING_BASE_IN_PRODUCTS:
            return translate("import.statuses.processingBaseInProducts");
        case importExportConstants.IMPORT_STATUS_PROCESSING_BASE_PACKAGES:
            return translate("import.statuses.processingBasePackages");
        case importExportConstants.IMPORT_STATUS_PROCESSING_BASES:
            return translate("import.statuses.processingBases");
        case importExportConstants.IMPORT_STATUS_PROCESSING_COLOR_BARCODES:
            return translate("import.statuses.processingColorBarcodes");
        case importExportConstants.IMPORT_STATUS_PROCESSING_COLORANT_PACKAGES:
            return translate("import.statuses.processingColorantPackages");
        case importExportConstants.IMPORT_STATUS_PROCESSING_COLORANTS:
            return translate("import.statuses.processingColorants");
        case importExportConstants.IMPORT_STATUS_PROCESSING_COLORS:
            return translate("import.statuses.processingColors");
        case importExportConstants.IMPORT_STATUS_PROCESSING_COMPONENTS:
            return translate("import.statuses.processingComponents");
        case importExportConstants.IMPORT_STATUS_PROCESSING_FANDECKS:
            return translate("import.statuses.processingFandecks");
        case importExportConstants.IMPORT_STATUS_PROCESSING_FORMULAS:
            return translate("import.statuses.processingFormulas");
        case importExportConstants.IMPORT_STATUS_PROCESSING_NOTES:
            return translate("import.statuses.processingNotes");
        case importExportConstants.IMPORT_STATUS_PROCESSING_OPTIONS:
            return translate("import.statuses.processingOptions");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PACKAGES:
            return translate("import.statuses.processingPackages");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PRICES:
            return translate("import.statuses.processingPrices");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PRODUCT_BARCODES:
            return translate("import.statuses.processingProductBarcodes");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PRODUCT_GROUPS:
            return translate("import.statuses.processingProductGroups");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PRODUCT_SOURCES:
            return translate("import.statuses.processingProductSources");
        case importExportConstants.IMPORT_STATUS_PROCESSING_PRODUCTS:
            return translate("import.statuses.processingProducts");
        case importExportConstants.IMPORT_STATUS_PROCESSING_SETTINGS:
            return translate("import.statuses.processingSettings");
        case importExportConstants.IMPORT_STATUS_PROCESSING_SPECTRO:
            return translate("import.statuses.processingSpectro");
        case importExportConstants.IMPORT_STATUS_PROCESSING_STATIC_DATA:
            return translate("import.statuses.processingStaticData");
        case importExportConstants.IMPORT_STATUS_PROCESSING_USER_GROUPS:
            return translate("import.statuses.processingUserGroups");
        case importExportConstants.IMPORT_STATUS_PROCESSING_USERS:
            return translate("import.statuses.processingUsers");
        case importExportConstants.IMPORT_STATUS_REMOVING_DATA:
            return translate("import.statuses.removingData");
        case importExportConstants.IMPORT_STATUS_REMOVING_FORMULAS:
            return translate("import.statuses.removingFormulas");
        case importExportConstants.IMPORT_STATUS_RENAMING_BASES:
            return translate("import.statuses.renamingBases");
        case importExportConstants.IMPORT_STATUS_RENAMING_COLORANTS:
            return translate("import.statuses.renamingColorants");
        case importExportConstants.IMPORT_STATUS_RENAMING_COLORS:
            return translate("import.statuses.renamingColors");
        case importExportConstants.IMPORT_STATUS_RENAMING_FANDECKS:
            return translate("import.statuses.renamingFandecks");
        case importExportConstants.IMPORT_STATUS_RENAMING_PRODUCT_GROUPS:
            return translate("import.statuses.renamingProductGroups");
        case importExportConstants.IMPORT_STATUS_RENAMING_PRODUCTS:
            return translate("import.statuses.renamingProducts");
        case importExportConstants.IMPORT_STATUS_RESULT_ERROR:
            return translate("import.statuses.resultError");
        case importExportConstants.IMPORT_STATUS_RESULT_OK:
            return translate("import.statuses.resultOk");
        default:
            return key;
    }
};

export const getItemTypeTranslation = (key: number | null): string => {
    switch (key) {
        case itemTypeConstants.ITEM_TYPE_BASE_IN_PRODUCT:
            return translate("product.baseInProduct");
        case itemTypeConstants.ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.baseInProductPackage");
        case itemTypeConstants.ITEM_TYPE_COLOR_IN_FANDECK:
            return translate("color.color");
        case itemTypeConstants.ITEM_TYPE_COLORANT:
            return translate("colorant.colorant");
        case itemTypeConstants.ITEM_TYPE_COLORANT_PACKAGE:
            return translate("colorant.colorantPackage");
        case itemTypeConstants.ITEM_TYPE_FANDECK:
            return translate("fandeck.fandeck");
        case itemTypeConstants.ITEM_TYPE_FORMULA_NOTE:
            return translate("formulaNote.formulaNote");
        case itemTypeConstants.ITEM_TYPE_PACKAGE:
            return translate("package.package");
        case itemTypeConstants.ITEM_TYPE_PRODUCT:
            return translate("product.product");
        case itemTypeConstants.ITEM_TYPE_PRODUCT_GROUP:
            return translate("productGroup.productGroup");
        case itemTypeConstants.ITEM_TYPE_USER:
            return translate("user.user");
        case itemTypeConstants.ITEM_TYPE_ZONE:
            return translate("zone.zone");
        default:
            return "";
    }
};

export const getPriceOptionTranslation = (option: string, value?: any, param?: any): string => {
    switch (option) {
        case priceConstants.PRICE_OPTION_ENABLE_COMPANY_COST: {
            switch (value) {
                case false:
                    return translate("prices.optionCompanyCostNo");
                case true:
                    return translate("prices.optionCompanyCostYes");
                default:
                    return "";
            }
        }
        case priceConstants.PRICE_OPTION_ENABLE_PRICE_GROUPS: {
            switch (value) {
                case false:
                    return translate("prices.optionPriceGroupsNo");
                case true:
                    return translate("prices.optionPriceGroupsYes", { count: param });
                default:
                    return "";
            }
        }
        case priceConstants.PRICE_OPTION_PRICE_CALCULATION: {
            if (
                value[priceConstants.PRICE_OPTION_USE_DISCOUNT] &&
                value[priceConstants.PRICE_OPTION_USE_MARGIN] &&
                value[priceConstants.PRICE_OPTION_USE_MARKUP]
            ) {
                return translate("prices.optionPriceCalculationDiscountMarginMarkup");
            }

            if (value[priceConstants.PRICE_OPTION_USE_MARGIN] && value[priceConstants.PRICE_OPTION_USE_MARKUP]) {
                return translate("prices.optionPriceCalculationMarginMarkup");
            }

            if (value[priceConstants.PRICE_OPTION_USE_MARGIN] && value[priceConstants.PRICE_OPTION_USE_DISCOUNT]) {
                return translate("prices.optionPriceCalculationMarginDiscount");
            }

            if (value[priceConstants.PRICE_OPTION_USE_MARKUP] && value[priceConstants.PRICE_OPTION_USE_DISCOUNT]) {
                return translate("prices.optionPriceCalculationMarkupDiscount");
            }

            if (value[priceConstants.PRICE_OPTION_USE_DISCOUNT]) {
                return translate("prices.optionPriceCalculationDiscount");
            }

            if (value[priceConstants.PRICE_OPTION_USE_MARGIN]) {
                return translate("prices.optionPriceCalculationMargin");
            }

            if (value[priceConstants.PRICE_OPTION_USE_MARKUP]) {
                return translate("prices.optionPriceCalculationMarkup");
            }

            return translate("prices.optionPriceCalculationNothing");
        }
        case priceConstants.PRICE_OPTION_PRICE_DEFINITION: {
            switch (value) {
                case priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY:
                    return translate("prices.optionDefinitionBothPurchase");
                case priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY:
                    return translate("prices.optionDefinitionBothSell");
                case priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY:
                    return translate("prices.optionDefinitionPurchase");
                case priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY:
                    return translate("prices.optionDefinitionSell");
                default:
                    return "";
            }
        }
        default:
            return "";
    }
};

export const getPriceGenericTranslation = (key: string): string => {
    switch (key) {
        case priceConstants.PRICE_GENERIC_COLORANT_COST_PER_LITER:
            return translate("prices.defaultPriceColorantCost");
        case priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER:
            return translate("prices.defaultPriceColorantPurchase");
        case priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER:
            return translate("prices.defaultPriceColorantSell");
        case priceConstants.PRICE_GENERIC_COLORANT_VAT:
            return translate("prices.defaultPriceColorantVat");
        case priceConstants.PRICE_GENERIC_PACKAGE_SURCHARGE:
            return translate("prices.defaultPricePackageSurcharge");
        case priceConstants.PRICE_GENERIC_PRODUCT_COST_PER_LITER:
            return translate("prices.defaultPriceProductCost");
        case priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER:
            return translate("prices.defaultPriceProductPurchase");
        case priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER:
            return translate("prices.defaultPriceProductSell");
        case priceConstants.PRICE_GENERIC_PRODUCT_VAT:
            return translate("prices.defaultPriceProductVAT");
        default:
            return key;
    }
};

export const getSystemPriceInheritanceTranslation = (key: number): string => {
    switch (key) {
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_COLORANT:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_PACKAGE:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_PRODUCT:
            return translate("prices.priceSourceInheritedDefault");
        case priceConstants.PRICE_SOURCE_SYSTEM_PACKAGE:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("prices.surcharge").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_SYSTEM_PRODUCT:
            return translate("prices.priceSourceInheritedProduct");
        case priceConstants.PRICE_SOURCE_SYSTEM_PRODUCT_BASE:
            return translate("prices.priceSourceInheritedBase");
        default:
            return "";
    }
};

export const getZonePriceInheritanceTranslation = (key: number): string => {
    switch (key) {
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_COLORANT:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_PACKAGE:
        case priceConstants.PRICE_SOURCE_SYSTEM_GENERIC_PRODUCT:
            return translate("prices.priceSourceInheritedSystemDefault");
        case priceConstants.PRICE_SOURCE_SYSTEM_COLORANT:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("colorant.colorant").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_SYSTEM_PACKAGE:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("prices.surcharge").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_SYSTEM_PRODUCT:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("product.product").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_SYSTEM_PRODUCT_BASE:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("base.base").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_SYSTEM_PRODUCT_PACKAGE:
            return translate("prices.priceSourceInheritedSystemItem", {
                item: translate("package.package").toLowerCase()
            });
        case priceConstants.PRICE_SOURCE_ZONE_GENERIC:
        case priceConstants.PRICE_SOURCE_ZONE_GENERIC_COLORANT:
        case priceConstants.PRICE_SOURCE_ZONE_GENERIC_PACKAGE:
        case priceConstants.PRICE_SOURCE_ZONE_GENERIC_PRODUCT:
            return translate("prices.priceSourceInheritedDefault");
        case priceConstants.PRICE_SOURCE_ZONE_PRODUCT:
            return translate("prices.priceSourceInheritedProduct");
        case priceConstants.PRICE_SOURCE_ZONE_PRODUCT_BASE:
            return translate("prices.priceSourceInheritedBase");
        default:
            return "";
    }
};

export const getFileTypeTranslation = (id: number, key: string): string => {
    switch (id) {
        case fileTypeConstants.FILE_TYPE_INSTRUCTIONS:
            return translate("file.instructions");
        case fileTypeConstants.FILE_TYPE_SAFETY_SHEET:
            return translate("file.safetySheet");
        case fileTypeConstants.FILE_TYPE_TECHNICAL_SHEET:
            return translate("file.technicalSheet");
        case fileTypeConstants.FILE_TYPE_ICON:
            return translate("image.icon");
        case fileTypeConstants.FILE_TYPE_IMAGE:
            return translate("image.image");
        case fileTypeConstants.FILE_TYPE_STATIC_SQL:
            return translate("general.staticSql");
        default:
            return key;
    }
};

export const getCellTooltipTranslation = (
    objectType: objectTypes.ObjectType | null,
    property: propertyConstants.Property,
    value: any
): string | null => {
    switch (objectType) {
        case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
            switch (property) {
                case propertyConstants.PROPERTY_VALUE:
                    switch (value) {
                        case privilegeConstants.PRIVILEGE_STATUS_AVAILABLE:
                            return translate("privileges.statusAvailable");
                        case privilegeConstants.PRIVILEGE_STATUS_HIDDEN:
                            return translate("privileges.statusHidden");
                        case privilegeConstants.PRIVILEGE_STATUS_NOT_AVAILABLE:
                            return translate("privileges.statusNotAvailable");
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        }
        default:
            return null;
    }
};

// TODO check this method
export const getHeadingTooltipTranslation = (
    objectType: objectTypes.ObjectType | null,
    property: propertyConstants.Property,
    custom: string | null
): string => {
    const replacements = {
        redlikeHelper: translationConstants.REDLIKE_HELPER,
        redlikeWeb: translationConstants.REDLIKE_WEB,
        thisSoftware: getThisSoftwareName(custom),
        software: getSoftwareName(custom)
    };

    // TODO check whether all properties are here
    switch (objectType) {
        case objectTypes.CURRENCY: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.currencyCode", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.currencyName", replacements);
                case propertyConstants.PROPERTY_ROUNDING:
                    return translate("propertyHint.currencyRounding", replacements);
                case propertyConstants.PROPERTY_SYMBOL:
                    return translate("propertyHint.currencySymbol", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.DATABASE: {
            switch (property) {
                case propertyConstants.PROPERTY_COMPANY_ID:
                    return translate("propertyHint.databaseCompany", replacements);
                case propertyConstants.PROPERTY_DEFAULT_DATA:
                    return translate("propertyHint.databaseDefaultData", replacements);
                case propertyConstants.PROPERTY_MASTER_ONLY:
                    return translate("propertyHint.databaseDefaultData", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.databaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.databaseName", replacements);
                case propertyConstants.PROPERTY_PASSWORD:
                    return translate("propertyHint.databasePassword", replacements);
                case propertyConstants.PROPERTY_UUID:
                    return translate("propertyHint.databaseUuid", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.EXPORT_TASK: {
            switch (property) {
                case propertyConstants.PROPERTY_ACTION:
                    return translate("propertyHint.exportAction", replacements);
                case propertyConstants.PROPERTY_CHANGELOG:
                    return translate("propertyHint.exportChangelog", replacements);
                case propertyConstants.PROPERTY_DATABASE_NAME:
                    return translate("propertyHint.exportDatabase", replacements);
                case propertyConstants.PROPERTY_DATE_CREATED:
                    return translate("propertyHint.exportDateCreated", replacements);
                case propertyConstants.PROPERTY_DATE_FINISHED:
                    return translate("propertyHint.exportDateFinished", replacements);
                case propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID:
                    return translate("propertyHint.exportStatus", replacements);
                case propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID:
                    return translate("propertyHint.exportType", replacements);
                case propertyConstants.PROPERTY_SYSTEM_NAME:
                    return translate("propertyHint.exportSystemName", replacements);
                case propertyConstants.PROPERTY_SYSTEM_UUID:
                    return translate("propertyHint.exportSystemUuid", replacements);
                case propertyConstants.PROPERTY_VERSION:
                    return translate("propertyHint.exportVersion", replacements);
                case propertyConstants.PROPERTY_VERSION_NAME:
                    return translate("propertyHint.exportVersionName", replacements);
                case propertyConstants.PROPERTY_ZONE_NAME:
                    return translate("propertyHint.exportZoneName", replacements);
                case propertyConstants.PROPERTY_ZONE_UUID:
                    return translate("propertyHint.exportZoneUuid", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.GENERIC_IMAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_FILE_TYPE_ID:
                    return translate("propertyHint.imageFileType", replacements);
                case propertyConstants.PROPERTY_HEIGHT:
                    return translate("propertyHint.imageHeight", replacements);
                case propertyConstants.PROPERTY_LENGTH:
                    return translate("propertyHint.imageSize", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.imageName", replacements);
                case propertyConstants.PROPERTY_URL:
                    return translate("propertyHint.imageUrl", replacements);
                case propertyConstants.PROPERTY_WIDTH:
                    return translate("propertyHint.imageWidth", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.IMPORT_TASK: {
            switch (property) {
                case propertyConstants.PROPERTY_ACTION:
                    return translate("propertyHint.importAction", replacements);
                case propertyConstants.PROPERTY_DATABASE_NAME:
                    return translate("propertyHint.importDatabaseName", replacements);
                case propertyConstants.PROPERTY_DATE_CREATED:
                    return translate("propertyHint.importDateCreated", replacements);
                case propertyConstants.PROPERTY_DATE_FINISHED:
                    return translate("propertyHint.importDateFinished", replacements);
                case propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID:
                    return translate("propertyHint.importStatus", replacements);
                case propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID:
                    return translate("propertyHint.importType", replacements);
                case propertyConstants.PROPERTY_SYSTEM_NAME:
                    return translate("propertyHint.importSystemName", replacements);
                case propertyConstants.PROPERTY_SYSTEM_UUID:
                    return translate("propertyHint.importSystemUuid", replacements);
                case propertyConstants.PROPERTY_ZONE_NAME:
                    return translate("propertyHint.importZoneName", replacements);
                case propertyConstants.PROPERTY_ZONE_UUID:
                    return translate("propertyHint.importZoneUuid", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_BASE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterBaseCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.masterBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.masterBaseName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.masterBasePrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.masterBasePriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.masterBaseSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.masterBaseSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.masterBaseTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_BASE_IN_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.masterProductBaseBfs", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterProductBaseCode", replacements);
                case propertyConstants.PROPERTY_COEFFICIENT:
                    return translate("propertyHint.masterProductBaseCoefficient", replacements);
                case propertyConstants.PROPERTY_CUSTOM_FORMULA:
                    return translate("propertyHint.masterProductBaseCustomFormula", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.masterProductBaseDensity", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.masterProductBaseFiles", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.masterProductBaseFillProc", replacements);
                case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
                    return translate("propertyHint.masterProductBaseForcedFormulaBaseAmountType", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.masterProductBaseImage", replacements);
                case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
                    return translate("propertyHint.masterProductBaseMaxColorantsAmount", replacements);
                case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
                    return translate("propertyHint.masterProductBaseMinColorantsAmount", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.masterProductBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_BASE_NAME:
                    return translate("propertyHint.masterProductBaseName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.masterProductBasePriority", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.masterProductBaseUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_COLOR: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.masterColorBarcode", replacements);
                case propertyConstants.PROPERTY_COLOR_BLUE:
                    return translate("propertyHint.masterColorBlue", replacements);
                case propertyConstants.PROPERTY_COLOR_CODE:
                    return translate("propertyHint.masterColorCode", replacements);
                case propertyConstants.PROPERTY_COLOR_GREEN:
                    return translate("propertyHint.masterColorGreen", replacements);
                case propertyConstants.PROPERTY_COLOR_MORE_INFO:
                    return translate("propertyHint.masterColorMoreInfo", replacements);
                case propertyConstants.PROPERTY_COLOR_NAME:
                    return translate("propertyHint.masterColorName", replacements);
                case propertyConstants.PROPERTY_COLOR_PRIORITY:
                    return translate("propertyHint.masterColorPriority", replacements);
                case propertyConstants.PROPERTY_COLOR_RED:
                    return translate("propertyHint.masterColorRed", replacements);
                case propertyConstants.PROPERTY_COLOR_RGB_HEX:
                    return translate("propertyHint.masterColorRgbHex", replacements);
                case propertyConstants.PROPERTY_COLOR_SEARCH_CODE:
                    return translate("propertyHint.masterColorSearchCode", replacements);
                case propertyConstants.PROPERTY_COLOR_SEARCH_NAME:
                    return translate("propertyHint.masterColorSearchName", replacements);
                case propertyConstants.PROPERTY_FANDECK_PAGE:
                    return translate("propertyHint.masterColorFandeckPage", replacements);
                case propertyConstants.PROPERTY_FANDECK_POSITION:
                    return translate("propertyHint.masterColorFandeckPosition", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.masterColorTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_COLOR_WITH_FORMULA_INFO: {
            switch (property) {
                case propertyConstants.PROPERTY_COLOR_CODE:
                    return translate("propertyHint.masterColorWithFormulaInfoCode", replacements);
                case propertyConstants.PROPERTY_COLOR_NAME:
                    return translate("propertyHint.masterColorWithFormulaInfoName", replacements);
                case propertyConstants.PROPERTY_FORMULAS_STATUS:
                    return translate("propertyHint.masterColorWithFormulaInfoStatus", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_COLOR_SPECTRO_TYPE: {
            switch (property) {
                case propertyConstants.PROPERTY_REFLECTANCE_DATA:
                    return translate("propertyHint.masterColorReflectanceData", replacements);
                case propertyConstants.PROPERTY_WAVELENGTH:
                    return translate("propertyHint.masterColorWavelength", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_COLORANT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.masterColorantBfs", replacements);
                case propertyConstants.PROPERTY_COLOR_BLUE:
                    return translate("propertyHint.masterColorantBlue", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterColorantCode", replacements);
                case propertyConstants.PROPERTY_COLOR_RGB_HEX:
                    return translate("propertyHint.masterColorantRgb", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.masterColorantDensity", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.masterColorantFiles", replacements);
                case propertyConstants.PROPERTY_COLOR_GREEN:
                    return translate("propertyHint.masterColorantGreen", replacements);
                case propertyConstants.PROPERTY_MIN_DOSAGE:
                    return translate("propertyHint.masterColorantMinDosage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.masterColorantMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.masterColorantName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.masterColorantPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.masterColorantPriority", replacements);
                case propertyConstants.PROPERTY_COLOR_RED:
                    return translate("propertyHint.masterColorantRed", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.masterColorantSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.masterColorantSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.masterColorantTmcName", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.masterColorantUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_FANDECK: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.masterFandeckBarcode", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterFandeckCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.masterFandeckMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.masterFandeckName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.masterFandeckPriority", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.masterFandeckPrintName", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.masterFandeckSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.masterFandeckSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.masterFandeckTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_FORMULA_NOTE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterFormulaNoteCode", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.masterFormulaNoteImage", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.masterFormulaNoteText", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.MASTER_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.masterProductCode", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.masterProductFiles", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.masterProductImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.masterProductMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.masterProductName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.masterProductPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.masterProductPriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.masterProductSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.masterProductSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.masterProductTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_IS_GRAVIMETRIC:
                    return translate("propertyHint.packageGravimetric", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.packageMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.packageName", replacements);
                case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
                    return translate("propertyHint.packageNominalAmount", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.packageTmcName", replacements);
                case propertyConstants.PROPERTY_UNIT_ID:
                    return translate("propertyHint.packageUnit", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.PRODUCT_SHEET: {
            switch (property) {
                case propertyConstants.PROPERTY_ACTION:
                    return translate("propertyHint.productFileAction", replacements);
                case propertyConstants.PROPERTY_FILE_CATEGORY_ID:
                    return translate("propertyHint.productFileCategory", replacements);
                case propertyConstants.PROPERTY_FILE_TYPE_ID:
                    return translate("propertyHint.productFileType", replacements);
                case propertyConstants.PROPERTY_LENGTH:
                    return translate("propertyHint.productFileSize", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.productFileMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.productFileName", replacements);
                case propertyConstants.PROPERTY_URL:
                    return translate("propertyHint.productFileUrl", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.STATIC_SQL: {
            switch (property) {
                case propertyConstants.PROPERTY_ACTION:
                    return translate("propertyHint.staticSqlAction", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.staticSqlName", replacements);
                case propertyConstants.PROPERTY_URL:
                    return translate("propertyHint.staticSqlUrl", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemName", replacements);
                case propertyConstants.PROPERTY_UUID:
                    return translate("propertyHint.systemUuid", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_BASE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemBaseCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemBaseName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.systemBaseMasterName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.systemBasePrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemBasePriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.systemBaseSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.systemBaseSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.systemBaseTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.systemProductBaseBfs", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemProductBaseCode", replacements);
                case propertyConstants.PROPERTY_COEFFICIENT:
                    return translate("propertyHint.systemProductBaseCoefficient", replacements);
                case propertyConstants.PROPERTY_CUSTOM_FORMULA:
                    return translate("propertyHint.systemProductBaseCustomFormula", replacements);
                case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
                    return translate("propertyHint.systemProductBaseMaxColorantsAmount", replacements);
                case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
                    return translate("propertyHint.systemProductBaseMinColorantsAmount", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.systemProductBaseDensity", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.systemProductBaseFiles", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.systemProductBaseFillProc", replacements);
                case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
                    return translate("propertyHint.systemProductBaseFormulaBaseAmountType", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.systemProductBaseImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemProductBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemProductBaseName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.systemProductBasePriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.systemProductBasePricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.systemProductBasePriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.systemProductBasePriceVat", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemProductBasePriority", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.systemProductBaseUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.systemProductPackageBarcode", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemProductPackageCode", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.systemProductPackageFiles", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.systemProductPackageFillProc", replacements);
                case propertyConstants.PROPERTY_FILL_VOLUME:
                    return translate("propertyHint.systemProductPackageFillVolume", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.systemProductPackageImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemProductPackageMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemProductPackageName", replacements);
                case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
                    return translate("propertyHint.systemProductPackageNominalAmount", replacements);
                case propertyConstants.PROPERTY_PRICE_COST:
                case propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_COST_PER_UNIT:
                    return translate("propertyHint.systemProductPackagePriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT:
                    return translate("propertyHint.systemProductPackagePricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT:
                    return translate("propertyHint.systemProductPackagePriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                    return translate("propertyHint.systemProductPackagePriceSurcharge", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.systemProductPackagePriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.systemProductPackagePrintName", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.systemProductPackageSearchName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_COLORANT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.systemColorantBfs", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemColorantCode", replacements);
                case propertyConstants.PROPERTY_COLOR_RGB_HEX:
                    return translate("propertyHint.systemColorantRgb", replacements);
                case propertyConstants.PROPERTY_MIN_DOSAGE:
                    return translate("propertyHint.systemColorantMinDosage", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.systemColorantDensity", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemColorantMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemColorantName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.systemColorantMasterName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.systemColorantPriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.systemColorantPricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER:
                    return translate("propertyHint.systemColorantPricePurchasePriceGroup", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.systemColorantPriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.systemColorantPriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.systemColorantPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemColorantPriority", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.systemColorantTmcName", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.systemColorantUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_COLORANT_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.systemColorantPackageBarcode", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.systemColorantPackageFillProc", replacements);
                case propertyConstants.PROPERTY_FILL_VOLUME:
                    return translate("propertyHint.systemColorantPackageFillVolume", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.systemColorantPackageImage", replacements);
                case propertyConstants.PROPERTY_IS_GRAVIMETRIC:
                    return translate("propertyHint.systemColorantPackageGravimetric", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemColorantPackageMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemColorantPackageName", replacements);
                case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
                    return translate("propertyHint.systemColorantPackageNominalAmount", replacements);
                case propertyConstants.PROPERTY_UNIT_ID:
                    return translate("propertyHint.systemColorantPackageUnit", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_FANDECK: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.systemFandeckBarcode", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemFandeckCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemFandeckMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemFandeckName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.systemFandeckMasterName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.systemFandeckPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemFandeckPriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.systemFandeckSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.systemFandeckSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.systemFandeckTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_FORMULA_NOTE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemFormulaNoteCode", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.systemFormulaNoteImage", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.systemFormulaNoteText", replacements);
                case propertyConstants.PROPERTY_VALUE_MASTER:
                    return translate("propertyHint.systemFormulaNoteTextMaster", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemPackageName", replacements);
                case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                    return translate("propertyHint.systemPackageSurcharge", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PRICE_GENERIC: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemPriceDefaultName", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.systemPriceDefaultValue", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PRICE_GROUP: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemPriceGroupName", replacements);
                case propertyConstants.PROPERTY_RANGE:
                    return translate("propertyHint.systemPriceGroupRangeFrom", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.systemPriceGroupRangeTo", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PRICE_MARGIN: {
            switch (property) {
                case propertyConstants.PROPERTY_MARGIN_TYPE:
                    return translate("propertyHint.systemPriceMarginType", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemPriceMarginName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemPriceMarginPriority", replacements);
                case propertyConstants.PROPERTY_PROC_PRODUCT:
                    return translate("propertyHint.systemPriceMarginValueBase", replacements);
                case propertyConstants.PROPERTY_PROC_COLORANT:
                    return translate("propertyHint.systemPriceMarginValueColorant", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.systemProductCode", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.systemProductFiles", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.systemProductImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemProductMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemProductName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.systemProductMasterName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.systemProductPriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.systemProductPricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.systemProductPriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.systemProductPriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.systemProductPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemProductPriority", replacements);
                case propertyConstants.PROPERTY_PRODUCT_GROUP_LIST:
                    return translate("propertyHint.systemProductGroups", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.systemProductSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.systemProductSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.systemProductTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_PRODUCT_GROUP: {
            switch (property) {
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.systemProductGroupMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.systemProductGroupName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.systemProductGroupPriority", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.SYSTEM_ZONE: {
            switch (property) {
                case propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS:
                    return translate("export.deleteColorsWithoutFormulas", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE: {
            switch (property) {
                case propertyConstants.PROPERTY_CHANGELOG:
                    return translate("propertyHint.zoneChangelog", replacements);
                case propertyConstants.PROPERTY_CURRENCY_ID:
                    return translate("propertyHint.zoneCurrency", replacements);
                case propertyConstants.PROPERTY_ICON_ID:
                    return translate("propertyHint.zoneIcon", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneImage", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneName", replacements);
                case propertyConstants.PROPERTY_PASSWORD:
                    return translate("propertyHint.zonePassword", replacements);
                case propertyConstants.PROPERTY_STATIC_SQL:
                    return translate("propertyHint.zoneStaticSql", replacements);
                case propertyConstants.PROPERTY_UUID:
                    return translate("propertyHint.zoneUuid", replacements);
                case propertyConstants.PROPERTY_VERSION:
                    return translate("propertyHint.zoneVersion", replacements);
                case propertyConstants.PROPERTY_VERSION_NAME:
                    return translate("propertyHint.zoneVersionName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.UNIT: {
            switch (property) {
                case propertyConstants.PROPERTY_AMOUNT_COEFFICIENT:
                    return translate("propertyHint.unitCoefficient", replacements);
                case propertyConstants.PROPERTY_IS_GRAVIMETRIC:
                    return translate("propertyHint.unitGravimetric", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.unitName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.unitPriority", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.WAVELENGTH: {
            switch (property) {
                case propertyConstants.PROPERTY_INTERVAL:
                    return translate("propertyHint.wavelengthInterval", replacements);
                case propertyConstants.PROPERTY_BEGIN_VALUE:
                    return translate("propertyHint.wavelengthBeginValue", replacements);
                case propertyConstants.PROPERTY_END_VALUE:
                    return translate("propertyHint.wavelengthEndValue", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_BASE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneBaseCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneBaseName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.zoneBaseMasterName", replacements);
                case propertyConstants.PROPERTY_NAME_SYSTEM:
                    return translate("propertyHint.zoneBaseSystemName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.zoneBasePrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneBasePriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.zoneBaseSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.zoneBaseSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.zoneBaseTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.zoneProductBaseBfs", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneProductBaseCode", replacements);
                case propertyConstants.PROPERTY_COEFFICIENT:
                    return translate("propertyHint.zoneProductBaseCoefficient", replacements);
                case propertyConstants.PROPERTY_COLORANT_MAX_FILL:
                    return translate("propertyHint.zoneProductBaseMaxColorantsAmount", replacements);
                case propertyConstants.PROPERTY_COLORANT_MIN_FILL:
                    return translate("propertyHint.zoneProductBaseMinColorantsAmount", replacements);
                case propertyConstants.PROPERTY_CUSTOM_FORMULA:
                    return translate("propertyHint.zoneProductBaseMinCustomFormula", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.zoneProductBaseDensity", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.zoneProductBaseFiles", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.zoneProductBaseFillProc", replacements);
                case propertyConstants.PROPERTY_FORCED_FORMULA_BASE_AMOUNT_TYPE_ID:
                    return translate("propertyHint.zoneProductBaseForcedFormulasBaseAmountType", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneProductBaseImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneProductBaseMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneProductBaseName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.zoneProductBasePriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.zoneProductBasePricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.zoneProductBasePriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.zoneProductBasePriceVat", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneProductBasePriority", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.zoneProductBaseUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_BASE_IN_PRODUCT_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.zoneProductPackageBarcode", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneProductPackageCode", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.zoneProductPackageFiles", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.zoneProductPackageFillProc", replacements);
                case propertyConstants.PROPERTY_FILL_VOLUME:
                    return translate("propertyHint.zoneProductPackageFillVolume", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneProductPackageImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneProductPackageMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneProductPackageName", replacements);
                case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
                    return translate("propertyHint.zoneProductPackageNominalAmount", replacements);
                case propertyConstants.PROPERTY_PRICE_COST:
                case propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_COST_PER_UNIT:
                    return translate("propertyHint.zoneProductPackagePriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT:
                    return translate("propertyHint.zoneProductPackagePricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                case propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT:
                    return translate("propertyHint.zoneProductPackagePriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                    return translate("propertyHint.zoneProductPackagePriceSurcharge", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.zoneProductPackagePriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.zoneProductPackagePrintName", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.zoneProductPackageSearchName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_COLORANT: {
            switch (property) {
                case propertyConstants.PROPERTY_BFS:
                    return translate("propertyHint.zoneColorantBfs", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneColorantCode", replacements);
                case propertyConstants.PROPERTY_COLOR_RGB_HEX:
                    return translate("propertyHint.zoneColorantRgb", replacements);
                case propertyConstants.PROPERTY_DENSITY:
                    return translate("propertyHint.zoneColorantDensity", replacements);
                case propertyConstants.PROPERTY_MIN_DOSAGE:
                    return translate("propertyHint.zoneColorantMinDosage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneColorantMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneColorantName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.zoneColorantMasterName", replacements);
                case propertyConstants.PROPERTY_NAME_SYSTEM:
                    return translate("propertyHint.zoneColorantSystemName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.zoneColorantPriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.zoneColorantPricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER:
                    return translate("propertyHint.zoneColorantPricePurchasePriceGroup", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.zoneColorantPriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.zoneColorantPriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.zoneColorantPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneColorantPriority", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.zoneColorantTmcName", replacements);
                case propertyConstants.PROPERTY_UFI_CODE:
                    return translate("propertyHint.zoneColorantUfiCode", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_COLORANT_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.zoneColorantPackageBarcode", replacements);
                case propertyConstants.PROPERTY_FILL_PROC:
                    return translate("propertyHint.zoneColorantPackageFillProc", replacements);
                case propertyConstants.PROPERTY_FILL_VOLUME:
                    return translate("propertyHint.zoneColorantPackageFillVolume", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneColorantPackageImage", replacements);
                case propertyConstants.PROPERTY_IS_GRAVIMETRIC:
                    return translate("propertyHint.zoneColorantPackageGravimetric", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneColorantPackageMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneColorantPackageName", replacements);
                case propertyConstants.PROPERTY_NOMINAL_AMOUNT:
                    return translate("propertyHint.zoneColorantPackageNominalAmount", replacements);
                case propertyConstants.PROPERTY_UNIT_ID:
                    return translate("propertyHint.zoneColorantPackageUnit", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_FANDECK: {
            switch (property) {
                case propertyConstants.PROPERTY_BARCODES:
                    return translate("propertyHint.zoneFandeckBarcode", replacements);
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneFandeckCode", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneFandeckMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneFandecktName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.zoneFandeckMasterName", replacements);
                case propertyConstants.PROPERTY_NAME_SYSTEM:
                    return translate("propertyHint.zoneFandeckSystemName", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.zoneFandeckPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneFandeckPriority", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.zoneFandeckSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.zoneFandeckSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.zoneFandeckTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_FORMULA_NOTE: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneFormulaNoteCode", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneFormulaNoteImage", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.zoneFormulaNoteText", replacements);
                case propertyConstants.PROPERTY_VALUE_MASTER:
                    return translate("propertyHint.zoneFormulaNoteMasterText", replacements);
                case propertyConstants.PROPERTY_VALUE_SYSTEM:
                    return translate("propertyHint.zoneFormulaNoteSystemText", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PACKAGE: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zonePackageName", replacements);
                case propertyConstants.PROPERTY_PRICE_SURCHARGE:
                    return translate("propertyHint.zonePackageSurcharge", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PRICE_GENERIC: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zonePriceDefaultName", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.zonePriceDefaultValue", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PRICE_GROUP: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zonePriceGroupName", replacements);
                case propertyConstants.PROPERTY_RANGE:
                    return translate("propertyHint.zonePriceGroupRangeFrom", replacements);
                case propertyConstants.PROPERTY_VALUE:
                    return translate("propertyHint.zonePriceGroupRangeTo", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PRICE_MARGIN: {
            switch (property) {
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zonePriceMarginName", replacements);
                case propertyConstants.PROPERTY_MARGIN_TYPE:
                    return translate("propertyHint.zonePriceMarginType", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zonePriceMarginPriority", replacements);
                case propertyConstants.PROPERTY_PROC_PRODUCT:
                    return translate("propertyHint.zonePriceMarginValueBase", replacements);
                case propertyConstants.PROPERTY_PROC_COLORANT:
                    return translate("propertyHint.zonePriceMarginValueColorant", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PRODUCT: {
            switch (property) {
                case propertyConstants.PROPERTY_CODE:
                    return translate("propertyHint.zoneProductCode", replacements);
                case propertyConstants.PROPERTY_FILES:
                    return translate("propertyHint.zoneProductFiles", replacements);
                case propertyConstants.PROPERTY_IMAGE_ID:
                    return translate("propertyHint.zoneProductImage", replacements);
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneProductMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneProductName", replacements);
                case propertyConstants.PROPERTY_NAME_MASTER:
                    return translate("propertyHint.zoneProductMasterName", replacements);
                case propertyConstants.PROPERTY_NAME_SYSTEM:
                    return translate("propertyHint.zoneProductSystemName", replacements);
                case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
                    return translate("propertyHint.zoneProductPriceCost", replacements);
                case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
                    return translate("propertyHint.zoneProductPricePurchase", replacements);
                case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
                    return translate("propertyHint.zoneProductPriceSell", replacements);
                case propertyConstants.PROPERTY_PRICE_VAT:
                    return translate("propertyHint.zoneProductPriceVat", replacements);
                case propertyConstants.PROPERTY_PRINT_NAME:
                    return translate("propertyHint.zoneProductPrintName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneProductPriority", replacements);
                case propertyConstants.PROPERTY_PRODUCT_GROUP_LIST:
                    return translate("propertyHint.zoneProductGroups", replacements);
                case propertyConstants.PROPERTY_SEARCH_CODE:
                    return translate("propertyHint.zoneProductSearchCode", replacements);
                case propertyConstants.PROPERTY_SEARCH_NAME:
                    return translate("propertyHint.zoneProductSearchName", replacements);
                case propertyConstants.PROPERTY_TMC_NAME:
                    return translate("propertyHint.zoneProductTmcName", replacements);
                default:
                    return "";
            }
        }
        case objectTypes.ZONE_PRODUCT_GROUP: {
            switch (property) {
                case propertyConstants.PROPERTY_MORE_INFO:
                    return translate("propertyHint.zoneProductGroupMoreInfo", replacements);
                case propertyConstants.PROPERTY_NAME:
                    return translate("propertyHint.zoneProductGroupName", replacements);
                case propertyConstants.PROPERTY_NAME_SYSTEM:
                    return translate("propertyHint.zoneProductGroupSystemName", replacements);
                case propertyConstants.PROPERTY_PRIORITY:
                    return translate("propertyHint.zoneProductGroupPriority", replacements);
                default:
                    return "";
            }
        }
        default:
            return "";
    }
};

export const getOptionTranslation = (key: string): string => {
    switch (key) {
        case optionsConstants.OPTION_DELETE_CONFIRMATION:
            return translate("options.deleteConfirmation");
        case optionsConstants.OPTION_LANGUAGE:
            return translate("options.environmentLanguage");
        case optionsConstants.OPTION_SHOW_FILTER_ROW:
            return translate("options.showFilterRow");
        case optionsConstants.OPTION_SHOW_GLOBAL_SEARCH:
            return translate("options.showGlobalSearch");
        case optionsConstants.OPTION_USE_LARGO_NAMES:
            return translate("options.useLargoNames");
        case optionsConstants.OPTION_USE_SOURCES:
            return translate("options.useSources");
        case optionsConstants.OPTION_USE_TMC_NAMES:
            return translate("options.useTmcNames");
        default:
            return "";
    }
};

// Forced formula base amount type
export const getForcedFormulaBaseAmountTypeOptionTranslation = (id: number): string => {
    switch (id) {
        case serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_DEFAULT:
            return translate("forcedFormulaBaseAmountType.default");
        case serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_VOLUMETRIC:
            return translate("forcedFormulaBaseAmountType.volumetric");
        case serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_GRAVIMETRIC:
            return translate("forcedFormulaBaseAmountType.gravimetric");
        case serverConstants.FORCED_FORMULA_BASE_AMOUNT_TYPE_USE_AS_IT_IS:
            return translate("forcedFormulaBaseAmountType.useAsItIs");
        default:
            return id.toString();
    }
};

// get option description
export const getRedlikeOptionDescriptionTranslation = (key: string, custom: string | null): string | null => {
    const replacements = {
        thisSoftware: getThisSoftwareName(custom),
        software: getSoftwareName(custom)
    };

    switch (key) {
        case redlikeOptionConstants.ADDITIONAL_TAB_TYPE:
            return translate("options.description.additionalTabType");
        case redlikeOptionConstants.AUTO_CLOSE_TAB:
            return translate("options.description.autoCloseTab");
        case redlikeOptionConstants.AUTOMATIC_CLIENT_START:
            return translate("options.description.automaticClientStart");
        case redlikeOptionConstants.AUTOMATIC_LOG_OUT:
            return translate("options.description.automaticLogOut");
        case redlikeOptionConstants.AUTOMATIC_LOG_OUT_TIME_OUT:
            return translate("options.description.automaticLogOutTimeOut");
        case redlikeOptionConstants.AUTOMATIC_PRINT:
            return translate("options.description.automaticPrint");
        case redlikeOptionConstants.BARCODES_ENABLED:
            return translate("options.description.barcodesEnabled");
        case redlikeOptionConstants.COLORANT_PACKAGE_PROC:
            return translate("options.description.colorantPackageProc");
        case redlikeOptionConstants.COLORANT_PANEL_ORDER_BY:
            return translate("options.description.colorantPanelOrderBy");
        case redlikeOptionConstants.COLOR_PREVIEW_RESIZABLE:
            return translate("options.description.colorPreviewResizable");
        case redlikeOptionConstants.COLOR_TAB_COMPATIBLE_ONLY:
            return translate("options.description.colorTabCompatibleOnly");
        case redlikeOptionConstants.COLOR_TAB_FORMULA_CODE:
            return translate("options.description.colorTabFormulaCode");
        case redlikeOptionConstants.COLOR_TAB_FORMULA_NAME:
            return translate("options.description.colorTabFormulaName");
        case redlikeOptionConstants.COLOR_TAB_NOTE_ICONS:
            return translate("options.description.colorTabNoteIcons");
        case redlikeOptionConstants.COLOR_TAB_ORDER_BY:
            return translate("options.description.colorTabOrderBy");
        case redlikeOptionConstants.COLOR_TAB_ROW_COUNT:
            return translate("options.description.colorTabRowCount");
        case redlikeOptionConstants.COLOR_TAB_WARNING_ICON:
            return translate("options.description.colorTabWarningIcon");
        case redlikeOptionConstants.COMPETITIVE_COLOR_SEARCH:
            return translate("options.description.competitiveColorSearch");
        case redlikeOptionConstants.CURRENCY_CODE:
            return translate("options.description.currencyCode");
        case redlikeOptionConstants.CUSTOM_BANNER_POSITION:
            return translate("options.description.customBannerPosition");
        case redlikeOptionConstants.CUSTOM_CAN_OPTION:
            return translate("options.description.customCanOption");
        case redlikeOptionConstants.CUSTOM_CAN_SIZE:
            return translate("options.description.customCanSize");
        case redlikeOptionConstants.CUSTOM_CAN_UNITS:
            return translate("options.description.customCanUnits");
        case redlikeOptionConstants.CUSTOMERS_ENABLED:
            return translate("options.description.customersEnabled");
        case redlikeOptionConstants.CUSTOM_FORMULAS:
            return translate("options.description.customFormulas");
        case redlikeOptionConstants.DELETE_CONFIRMATION:
            return translate("options.description.deleteConfirmation");
        case redlikeOptionConstants.DISPENSING_ENABLED:
            return translate("options.description.dispensingEnabled");
        case redlikeOptionConstants.DISPENSING_NOTES:
            return translate("options.description.dispensingNotes");
        case redlikeOptionConstants.DO_NOT_SHOW_AGAIN_CLOSE_REDLIKE:
            return translate("options.description.doNotShowAgainCloseRedlike", replacements);
        case redlikeOptionConstants.EDIT_COLORANT_ALLOWANCE:
            return translate("options.description.editColorantAllowance");
        case redlikeOptionConstants.FORMULA_AUTOMATIC_NAMING:
            return translate("options.description.formulaAutomaticNaming");
        case redlikeOptionConstants.FORMULA_CODE:
            return translate("options.description.formulaCode");
        case redlikeOptionConstants.FORMULA_CODE_PRIMARY:
            return translate("options.description.formulaCodePrimary");
        case redlikeOptionConstants.FORMULA_COLOR_PREVIEW:
            return translate("options.description.formulaColorPreview");
        case redlikeOptionConstants.FORMULA_NAME:
            return translate("options.description.formulaName");
        case redlikeOptionConstants.FORMULA_NOTES:
            return translate("options.description.formulaNotes");
        case redlikeOptionConstants.FORMULA_PREVIEW:
            return translate("options.description.formulaPreview");
        case redlikeOptionConstants.FORMULA_STATUS_PANEL_ENABLED:
            return translate("options.description.formulaStatusPanelEnabled");
        case redlikeOptionConstants.FORMULA_UNITS:
            return translate("options.description.formulaUnits");
        case redlikeOptionConstants.FTS:
            return translate("options.description.fts");
        case redlikeOptionConstants.GENERATE_COLOR_PREVIEW:
            return translate("options.description.generateColorPreview");
        case redlikeOptionConstants.HELP:
            return translate("options.description.help");
        case redlikeOptionConstants.HELP_LINK:
            return translate("options.description.helpLink");
        case redlikeOptionConstants.CHECK_MIN_DOSABLE_AMOUNT:
            return translate("options.description.checkMinDosableAmount", replacements);
        case redlikeOptionConstants.CHECK_OVERFILL:
            return translate("options.description.checkOverfill");
        case redlikeOptionConstants.CHECK_TOTAL_CNT_AMOUNTS:
            return translate("options.description.checkTotalCntAmounts", replacements);
        case redlikeOptionConstants.INFO_PANEL_RESIZABLE:
            return translate("options.description.infoPanelResizable");
        case redlikeOptionConstants.INFO_PANEL_TAB:
            return translate("options.description.infoPanelTab");
        case redlikeOptionConstants.INFO_TYPES:
            return translate("options.description.infoTypes");
        case redlikeOptionConstants.INIT_TAB_TYPE:
            return translate("options.description.initTabType");
        case redlikeOptionConstants.KEYBOARD:
            return translate("options.description.keyboard");
        case redlikeOptionConstants.KEYBOARD_SHORTCUTS:
            return translate("options.description.keyboardShortcuts");
        case redlikeOptionConstants.KIOSK:
            return translate("options.description.kiosk");
        case redlikeOptionConstants.LANGUAGE:
            return translate("options.description.language");
        case redlikeOptionConstants.LIGHT_ENABLED:
            return translate("options.description.lightEnabled", { software: getSoftwareName(custom) });
        case redlikeOptionConstants.LOCK_PC:
            return translate("options.description.lockPC", { software: getSoftwareName(custom) });
        case redlikeOptionConstants.MARGIN_MARKUP:
            return translate("options.description.marginMarkup");
        case redlikeOptionConstants.MAX_TOLERANCE_FOR_COMPETITIVE_COLORS:
            return translate("options.description.maxToleranceForCompetitiveColors");
        case redlikeOptionConstants.NAVIGATION_ITEMS_ORDERED:
            return translate("options.description.navigationItemsOrdered");
        case redlikeOptionConstants.NUMBER_OF_COMPETITIVE_COLORS:
            return translate("options.description.numberOfCompetitiveColors");
        case redlikeOptionConstants.NUMBER_OF_TABS:
            return translate("options.description.numberOfTabs");
        case redlikeOptionConstants.ORDERING_ENABLED:
            return translate("options.description.orderingEnabled");
        case redlikeOptionConstants.ORDER_MODAL_ENABLED:
            return translate("options.description.orderModalEnabled");
        case redlikeOptionConstants.ORDER_SEARCH_ITEMS:
            return translate("options.description.orderSearchItems");
        case redlikeOptionConstants.OVERWRITE_LAST_TAB:
            return translate("options.description.overwriteLastTab");
        case redlikeOptionConstants.OVERWRITE_LOGO:
            return translate("options.description.overwriteLogo");
        case redlikeOptionConstants.PRICE_TYPES:
            return translate("options.description.priceTypes");
        case redlikeOptionConstants.PRICE_VISIBILITY_TYPES:
            return translate("options.description.priceVisibilityTypes");
        case redlikeOptionConstants.PRICING_ENABLED:
            return translate("options.description.pricingEnabled");
        case redlikeOptionConstants.PRINT_AFTER_DISPENSING:
            return translate("options.description.printAfterDispensing");
        case redlikeOptionConstants.PRINT_AS_IMAGE:
            return translate("options.description.printAsImage");
        case redlikeOptionConstants.PRINT_BEFORE_DISPENSING:
            return translate("options.description.printBeforeDispensing");
        case redlikeOptionConstants.PRINT_ENABLED:
            return translate("options.description.printEnabled");
        case redlikeOptionConstants.PRINT_TASKS_AFTER_DISPENSING:
            return translate("options.description.printTasksAfterDispensing");
        case redlikeOptionConstants.PRINT_TASKS_BEFORE_DISPENSING:
            return translate("options.description.printTasksBeforeDispensing");
        case redlikeOptionConstants.PRINTER:
            return translate("options.description.printer");
        case redlikeOptionConstants.PRINTER_TEMPLATE:
            return translate("options.description.printerTemplate");
        case redlikeOptionConstants.PRINTER_TEMPLATE_QUICK_TINT:
            return translate("options.description.printerTemplateQuickTint");
        case redlikeOptionConstants.PRINT_ON_REQUEST:
            return translate("options.description.printOnRequest");
        case redlikeOptionConstants.PRODUCT_CODE:
            return translate("options.description.productCode");
        case redlikeOptionConstants.PRODUCT_IMAGE_PREVIEW:
            return translate("options.description.productImagePreview");
        case redlikeOptionConstants.QUICK_TINT_CLOSE_AFTER_DISPENSING:
            return translate("options.description.quickTintCloseAfterDispensing");
        case redlikeOptionConstants.QUICK_TINT_SORTING_TYPE:
            return translate("options.description.quickTintSortingType");
        case redlikeOptionConstants.ROUNDING:
            return translate("options.description.rounding");
        case redlikeOptionConstants.SCREENSAVER_TIMEOUT:
            return translate("options.description.screensaverTimeout");
        case redlikeOptionConstants.SEARCH_PANEL_BASE:
            return translate("options.description.searchPanelBase");
        case redlikeOptionConstants.SEARCH_PANEL_BASE_IN_PRODUCT_PACKAGE:
            return translate("options.description.searchPanelBaseInProductPackage");
        case redlikeOptionConstants.SEARCH_PANEL_ORDER:
            return translate("options.description.searchPanelOrder");
        case redlikeOptionConstants.SEARCH_PANEL_PRODUCT:
            return translate("options.description.searchPanelProduct");
        case redlikeOptionConstants.SEARCH_PANEL_WITHOUT_CONFIRMATION:
            return translate("options.description.searchPanelWithoutConfirmation");
        case redlikeOptionConstants.SETTINGS_ACTIVE_FORMULA_UNIT:
            return translate("options.description.settingsActiveFormulaUnit", { software: getSoftwareName(custom) });
        case redlikeOptionConstants.SETTINGS_BLEND_FORMULA_AND_CODE:
            return translate("options.description.settingsBlendFormulaAndCode");
        case redlikeOptionConstants.SETTINGS_CLEANING_TIME:
            return translate("options.description.settingsCleaningTime");
        case redlikeOptionConstants.SETTINGS_CUSTOM_HOME_BUTTON:
            return translate("options.description.settingsCustomHomeButton");
        case redlikeOptionConstants.SETTINGS_INFO_ITEM_IN_RGB_PREVIEW:
            return translate("options.description.settingsInfoItemInRgbPreview");
        case redlikeOptionConstants.SETTINGS_MANUAL_ADDITION_UNIT:
            return translate("options.description.settingsManualAdditionUnit", { software: getSoftwareName(custom) });
        case redlikeOptionConstants.SETTINGS_RECIRCULATION_SPEED:
            return translate("options.description.settingsRecirculationSpeed");
        case redlikeOptionConstants.SETTINGS_RECIRCULATION_TIME:
            return translate("options.description.settingsRecirculationTime");
        case redlikeOptionConstants.SETTINGS_STIRRING_SPEED:
            return translate("options.description.settingsStirringSpeed");
        case redlikeOptionConstants.SETTINGS_STIRRING_TIME:
            return translate("options.description.settingsStirringTime");
        case redlikeOptionConstants.SHOTS_COEF:
            return translate("options.description.shotsCoef");
        case redlikeOptionConstants.SHOTS_NAME:
            return translate("options.description.shotsName");
        case redlikeOptionConstants.SOFTWARE_NAME:
            return translate("options.description.softwareName");
        case redlikeOptionConstants.SOFTWARE_NAME_LIGHT:
            return translate("options.description.softwareNameLight");
        case redlikeOptionConstants.SOUND_ENABLED:
            return translate("options.description.soundEnabled");
        case redlikeOptionConstants.SPECTRO_ENABLED:
            return translate("options.description.spectroEnabled");
        case redlikeOptionConstants.STYLE:
            return translate("options.description.style");
        case redlikeOptionConstants.TABLE_BASE:
            return translate("options.description.tableBase");
        case redlikeOptionConstants.TABLE_CUSTOMER:
            return translate("options.description.tableCustomer");
        case redlikeOptionConstants.TABLE_DATE:
            return translate("options.description.tableDate");
        case redlikeOptionConstants.TABLE_FANDECK:
            return translate("options.description.tableFandeck");
        case redlikeOptionConstants.TABLE_FORMULA_CODE:
            return translate("options.description.tableFormulaCode");
        case redlikeOptionConstants.TABLE_FORMULA_NAME:
            return translate("options.description.tableFormulaName");
        case redlikeOptionConstants.TABLE_MORE_INFO:
            return translate("options.description.tableMoreInfo");
        case redlikeOptionConstants.TABLE_PACKAGE:
            return translate("options.description.tablePackage");
        case redlikeOptionConstants.TABLE_PRODUCT:
            return translate("options.description.tableProduct");
        case redlikeOptionConstants.TABLE_QUANTITY:
            return translate("options.description.tableQuantity");
        case redlikeOptionConstants.TABS_ENABLED:
            return translate("options.description.tabsEnabled");
        case redlikeOptionConstants.UFI_CODE_BASE_VALIDATION:
            return translate("options.description.ufiCodeBaseValidation");
        case redlikeOptionConstants.UFI_CODE_LOGIC:
            return translate("options.description.ufiCodeLogic", replacements);
        case redlikeOptionConstants.UFI_CODE_MIN_AMOUNT_GRAV_PERCENT:
            return translate("options.description.ufiCodeMinAmountGravPercent");
        case redlikeOptionConstants.UFI_CODE_MIN_GRAV_PERCENT:
            return translate("options.description.ufiCodeMinGravPercent");
        case redlikeOptionConstants.USER_IDENTIFICATION:
            return translate("options.description.userIdentification");
        case redlikeOptionConstants.VISIBLE_UNITS:
            return translate("options.description.visibleUnits");
        case redlikeOptionConstants.VIRTUAL_KEYBOARD_ENABLED:
            return translate("options.description.virtualKeyboardEnabled");
        case redlikeOptionConstants.ZOOM:
            return translate("options.description.zoom");
        default:
            return null;
    }
};

// Get custom software type name
export const getSoftwareName = (custom: string | null): string => {
    switch (custom) {
        case defaultConstants.COLORIS_SOFTWARE:
            return translationConstants.COLORIS_SOFTWARE;
        default:
            return translationConstants.REDLIKE;
    }
};

export const getThisSoftwareName = (custom: string | null): string => {
    switch (custom) {
        case defaultConstants.COLORIS_SOFTWARE:
            return translationConstants.COLORIS_MANAGER;
        default:
            return translationConstants.REDLIKE_MANAGER;
    }
};

export const getUserGroupPrivilegeValueTranslations = (privilege: DataItem | null) => {
    if (privilege?.canUpdate) {
        return translate("general.edit");
    }

    if (privilege?.canView) {
        return translate("general.readOnly");
    }

    return translate("general.hide");
};

export const getUserGroupPrivilegeKeyTranslations = (key: string) => {
    switch (key) {
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_DATABASE:
            return translate("user.userGroupPrivileges.dataDatabase");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_SYSTEM_ZONE:
            return translate("user.userGroupPrivileges.dataSystemZone");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_IMPORT:
            return translate("user.userGroupPrivileges.dataImport");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_DATA_EXPORT:
            return translate("user.userGroupPrivileges.dataExport");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_COMPANY:
            return translate("user.userGroupPrivileges.headerCompany");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_MASTER:
            return translate("user.userGroupPrivileges.headerMaster");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_SYSTEM:
            return translate("user.userGroupPrivileges.headerSystem");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_USER:
            return translate("user.userGroupPrivileges.headerUser");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_HEADER_ZONE:
            return translate("user.userGroupPrivileges.headerZone");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_CURRENCIES:
            return translate("user.userGroupPrivileges.genericCurrencies");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_IMAGES:
            return translate("user.userGroupPrivileges.genericImages");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_PACKAGES:
            return translate("user.userGroupPrivileges.genericPackages");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_PRODUCT_FILES:
            return translate("user.userGroupPrivileges.genericProductFiles");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_STATIC_SQL:
            return translate("user.userGroupPrivileges.genericStaticSql");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_GENERIC_UNITS:
            return translate("user.userGroupPrivileges.genericUnits");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_IMPORT_EXPORT_DELETE:
            return translate("user.userGroupPrivileges.taskDelete");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_IMPORT_EXPORT_HIDE:
            return translate("user.userGroupPrivileges.taskHide");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_UUID_MASTER_SYSTEM:
            return translate("user.userGroupPrivileges.uuidMasterSystem");
        case userGroupPrivilegeConstants.USER_GROUP_PRIVILEGE_UUID_ZONE:
            return translate("user.userGroupPrivileges.uuidZone");
        default:
            return "";
    }
};

export const getModulesTranslation = (key: string): string => {
    switch (key) {
        case importExportConstants.IMPORT_MODULE_MASTER_CD_COLOR:
            return translate("import.modules.importerMasterCdColor");
        case importExportConstants.IMPORT_MODULE_MASTER_COROB_GDATA:
            return translate("import.modules.importerMasterCorobGdata");
        case importExportConstants.IMPORT_MODULE_MASTER_DATACOLOR_CSV:
            return translate("import.modules.importerMasterDatacolorCsv");
        case importExportConstants.IMPORT_MODULE_MASTER_DATACOLOR_XML:
            return translate("import.modules.importerMasterDatacolorXml");
        case importExportConstants.IMPORT_MODULE_MASTER_FLUGGER:
            return translate("import.modules.importerMasterFlugger");
        case importExportConstants.IMPORT_MODULE_MASTER_INNOVATINT_3:
            return translate("import.modules.importerMasterInnovatint3");
        case importExportConstants.IMPORT_MODULE_MASTER_MEFFERT_CSV:
            return translate("import.modules.importerMasterMeffertCsv");
        case importExportConstants.IMPORT_MODULE_MASTER_MEFFERT_SQL:
            return translate("import.modules.importerMasterMeffertSql");
        case importExportConstants.IMPORT_MODULE_ZONE_MEFFERT:
            return translate("import.modules.importerZoneMeffert");
        default:
            return key;
    }
};

export const getLicenseVersionTypeTranslation = (key: number | null): string => {
    switch (key) {
        case license.PRODUCT_REDMAN:
            return translate("license.productRedlikeManagerStandard");
        case license.PRODUCT_REDMAN_BASIC:
            return translate("license.productRedlikeManagerBasic");
        default:
            return "";
    }
};

export const getDataTypeTranslation = (id: number, objectType: objectTypes.ObjectType): string => {
    switch (id) {
        case dataTypeConstants.DATA_TYPE_BARCODE:
            if (
                objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE ||
                objectType === objectTypes.ZONE_PRODUCT_DUPLICATE
            ) {
                return translate("barcode.barcodesProductDuplicate");
            }

            return translate("barcode.barcodes");
        case dataTypeConstants.DATA_TYPE_BASE:
            return translate("base.bases");
        case dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT:
            return translate("product.basesInProduct");
        case dataTypeConstants.DATA_TYPE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.baseInProductPackages");
        case dataTypeConstants.DATA_TYPE_COLORANT:
            return translate("colorant.colorants");
        case dataTypeConstants.DATA_TYPE_COLORANT_PACKAGE:
            return translate("colorant.colorantPackages");
        case dataTypeConstants.DATA_TYPE_FANDECK:
            return translate("fandeck.fandecks");
        case dataTypeConstants.DATA_TYPE_FILE:
            return translate("product.productFiles");
        case dataTypeConstants.DATA_TYPE_FORMULA:
            return translate("formula.formulas");
        case dataTypeConstants.DATA_TYPE_FORMULA_NOTE:
            return translate("formulaNote.formulaNotes");
        case dataTypeConstants.DATA_TYPE_IMAGE:
            return translate("image.images");
        case dataTypeConstants.DATA_TYPE_OPTION:
            return translate("general.options");
        case dataTypeConstants.DATA_TYPE_PRICE:
            return translate("prices.prices");
        case dataTypeConstants.DATA_TYPE_PRODUCT:
            return translate("product.products");
        case dataTypeConstants.DATA_TYPE_USER:
            return translate("user.users");
        default:
            return "";
    }
};

export const getFormulaAmountTranslation = (nominal: boolean): string => {
    if (nominal) {
        return translate("general.nominalAmount");
    }
    return translate("base.baseVolume");
};

export const getInheritDataPropagateModalTranslations = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.MASTER_SYSTEM_PRODUCT:
            return translate("product.inheritProductSystem");
        case objectTypes.SYSTEM_ZONE_PRODUCT:
            return translate("product.inheritProductZone");
        default:
            return "";
    }
};

export const getAlertLoaderTranslations = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.EXPORT_TASK:
            return translate("export.exportLoading");
        default:
            return translate("general.pleaseWait");
    }
};

export const getPasswordHashComparisonTranslation = (
    webZoneList: Array<WebZone>,
    password: string,
    uuid: string
): string => {
    const replacements = {
        redlikeWebAbbreviation: translationConstants.REDLIKE_WEB_ABBREVIATION
    };

    for (const webZone of webZoneList) {
        if (webZone[propertyConstants.PROPERTY_UUID] === uuid) {
            if (!webZone[propertyConstants.PROPERTY_HAS_META_DATA]) {
                return translate("validation.noMetaData", replacements);
            }
            if (webZone[propertyConstants.PROPERTY_PASSWORD_HASH] === "") {
                return translate("validation.noPassword", replacements);
            } else if (
                webZone[propertyConstants.PROPERTY_PASSWORD_HASH]?.toUpperCase() !== sha(password).toUpperCase()
            ) {
                return translate("validation.notMatchingPassword", replacements);
            } else {
                return translate("validation.matchingPassword", replacements);
            }
        }
    }

    return translate("validation.noUuid", replacements);
};

export const getBookmarkDuplicationTableHeader = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.SYSTEM_DUPLICATE:
            return translate("zone.selectZonesToBeDuplicate");
        case objectTypes.SYSTEM_PRODUCT_DUPLICATE:
            return translate("system.saveInSystems");
        case objectTypes.ZONE_PRODUCT_DUPLICATE:
            return translate("zone.saveInZones");
        default:
            return "";
    }
};

export const getHistoricalActionSectionTranslation = (
    itemTypeId: historyDatabaseConstants.HistoricalItemType,
    custom: any
): string => {
    const replacements = {
        software: getSoftwareName(custom)
    };

    switch (itemTypeId) {
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_BARCODE:
            return translate("barcode.barcode");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_BASE:
            return translate("base.base");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT:
            return translate("product.baseInProduct");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_BASE_IN_PRODUCT_PACKAGE:
            return translate("product.baseInProductPackage");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_COLOR:
            return translate("color.color");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_COLOR_IN_FANDECK:
            return translate("color.colorInFandeck");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_COLORANT:
            return translate("colorant.colorant");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_COLORANT_PACKAGE:
            return translate("colorant.colorantPackage");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_CURRENCY:
            return translate("currency.currency");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_FANDECK:
            return translate("fandeck.fandeck");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_FILE:
            return translate("file.file");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_FILE_CATEGORY:
            return translate("file.fileCategory");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_FORMULA:
            return translate("formula.formula");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_FORMULA_NOTE:
            return translate("formulaNote.formulaNote");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_IMAGE:
            return translate("image.image");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_IMAGE_CATEGORY:
            return translate("image.imageCategory");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_COMPANY:
            return translate("company.company");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_DATABASE:
            return translate("database.database");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_EXPORT_TASK:
            return translate("export.exportTask");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_IMPORT_TASK:
            return translate("import.importTask");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_OPTION:
            return translate("general.option");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_SCRIPT:
            return translate("serverScripts.script");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_USER:
            return translate("user.user");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_USER_GROUP:
            return translate("user.userGroup");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_LAB_USER_GROUP_PRIVILEGE:
            return translate("privileges.privileges");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_MARGIN:
            return translate("prices.margin");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_OPTION:
            return translate("general.redlikeOptions", replacements);
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_PACKAGE:
            return translate("package.package");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_PRICE:
            return translate("prices.price");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_PRICE_OPTIONS:
            return translate("prices.priceOption");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_PRODUCT:
            return translate("product.product");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_PRODUCT_GROUP:
            return translate("productGroup.productGroup");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_SPECTRO_TYPE:
            return translate("spectro.spectroType");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_SYSTEM:
            return translate("system.system");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_UNIT:
            return translate("unit.unit");
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_USER:
            return translate("general.redlikeUsers", replacements);
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_USER_GROUP:
            return getSoftwareName(custom) + " " + translate("user.userGroup").toLowerCase();
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_USER_GROUP_PRIVILEGE:
            return translate("general.redlikePrivileges", replacements);
        case historyDatabaseConstants.HISTORICAL_ITEM_TYPE_ZONE:
            return translate("zone.zone");
        default:
            return "";
    }
};

export const getHistoricalActionTypeTranslation = (
    actionTypeId: historyDatabaseConstants.HistoricalActionType
): string => {
    switch (actionTypeId) {
        case historyDatabaseConstants.HISTORICAL_ACTION_TYPE_DELETE:
            return translate("general.delete");
        case historyDatabaseConstants.HISTORICAL_ACTION_TYPE_DUPLICATE:
            return translate("general.duplicate");
        case historyDatabaseConstants.HISTORICAL_ACTION_TYPE_INSERT:
            return translate("general.add");
        case historyDatabaseConstants.HISTORICAL_ACTION_TYPE_SET:
            return translate("general.set");
        case historyDatabaseConstants.HISTORICAL_ACTION_TYPE_UPDATE:
            return translate("general.edit");
        default:
            return "";
    }
};

export const getCancelButtonTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.ONE_TIME_ACCESS_PASSWORD:
            return translate("general.close");
        default:
            return translate("general.cancel");
    }
};

export const getImportFormatTranslation = (
    key: importExportConstants.ImportFormatType,
    defaultText: string
): string => {
    switch (key) {
        case importExportConstants.IMPORT_FORMAT_AKZO_MASTER_ZONE:
            return translate("import.formats.akzoMasterZone");
        case importExportConstants.IMPORT_FORMAT_BARIL_EXCEL_MASTER:
            return translate("import.formats.barilExcelMaster");
        case importExportConstants.IMPORT_FORMAT_DATACOLOR_BARIL_CSV_MASTER:
            return translate("import.formats.datacolorBarilCSVMaster");
        case importExportConstants.IMPORT_FORMAT_DATACOLOR_CSV_MASTER:
            return translate("import.formats.datacolorCSVMaster");
        case importExportConstants.IMPORT_FORMAT_DATACOLOR_XML_MASTER:
            return translate("import.formats.datacolorXMLMaster");
        case importExportConstants.IMPORT_FORMAT_DESO_MASTER:
            return translate("import.formats.desoMaster");
        case importExportConstants.IMPORT_FORMAT_DESO_PRICE_GROUP_BARCODES_ZONE:
            return translate("import.formats.desoPriceGroupBarcodesZone");
        case importExportConstants.IMPORT_FORMAT_DESO_ZONE:
            return translate("import.formats.desoZone");
        default:
            return defaultText;
    }
};

export const getAdditionalButtonTranslation = (objectType: objectTypes.ObjectType): string => {
    switch (objectType) {
        case objectTypes.EXPORT_TASK_DELETE:
        case objectTypes.IMPORT_TASK_DELETE:
            return translate("general.deleteAll");
        default:
            return "";
    }
};
