import * as browserStorageConstants from "../../constants/browserStorageConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as loginActions from "../../actions/loginActions";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as optionHelper from "../../helpers/optionHelper";
import * as optionsConstants from "../../constants/optionsConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as serverConstants from "../../constants/serverConstants";
import * as serverEventsActions from "../../actions/serverEventsActions";
import * as websocketActions from "../../actions/websocketActions";

import { Option, mapOption } from "../../types/option";
import { mapAllDataPrivilege, mapAllPrivilege } from "../../types/dataItem";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";
import { mapMenu } from "../../types/menu";
import { mapUser } from "../../types/user";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_IS_USER_LOGGED_IN: {
            if (response.result !== serverConstants.RESULT_OK) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            if (response.data === true) {
                return [loginActions.loginGetLoggedUser()];
            }

            return [];
        }
        case methods.METHOD_LOGIN: {
            sessionStorage.removeItem(browserStorageConstants.SESSION_STORAGE_PATH);

            if (response.result === serverConstants.RESULT_LOGIN_FAILED) {
                return [loginActions.loginFailure(true)];
            }

            if (!responseValidationHelper.isLoggedUserFormatValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            if (response.result === serverConstants.RESULT_OK) {
                const user = mapUser(
                    response.data,
                    null,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS),
                    response.data?.groups ?? []
                );

                if (user) {
                    return [loginActions.loginSuccess(user)];
                }
            }

            return [messageHelper.getInvalidFormatMessage(response), loginActions.loginFailure(false)];
        }
        case methods.METHOD_LOGOUT: {
            if (response.result !== serverConstants.RESULT_OK) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [loginActions.logoutSuccess()];
        }
        case methods.METHOD_GET_LOGGED_USER: {
            if (!responseValidationHelper.isGetLoggedUserFormatValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            if (response.data) {
                const user = mapUser(
                    response.data,
                    null,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS),
                    response.data?.groups ?? []
                );

                if (user) {
                    let item: any;
                    let newOption: Option | null;
                    const optionList: Array<Option> = [];

                    for (item of Object.values(response.data.options)) {
                        newOption = mapOption({
                            ...item,
                            value: optionHelper.getUpdatedOptions(item.key, item.value)
                        });
                        if (newOption) {
                            optionList.push(newOption);
                        }
                    }

                    return [
                        loginActions.loginSuccess(user),
                        serverEventsActions.saveDataPrivileges(mapAllDataPrivilege(response.data.dataPrivileges)),
                        serverEventsActions.saveMenu(mapMenu(response.data.menu, state.software.custom)),
                        serverEventsActions.saveOptions(optionList),
                        serverEventsActions.savePrivileges(mapAllPrivilege(response.data.privileges))
                    ];
                }
            }

            return [loginActions.loginFailure(false)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
