import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/zone/ZoneColorantSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { ZoneColorantPackageTable } from "./tables/ZoneColorantPackageTable";
import { ZoneColorantTable } from "./tables/ZoneColorantTable";

type Props = PropsType;

export const ZoneColorantScene: FunctionComponent<Props> = ({ ...props }) => {
    const [tableColorants, setTableColorants] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.colorantTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableColorants(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.colorantTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.colorantTableConstant]);

    const [tablePackages, setTablePackages] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.colorantPackageTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePackages(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.colorantPackageTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.colorantPackageTableConstant]);

    const getColorantsTable = (): JSX.Element | null => {
        if (tableColorants) {
            return (
                <ZoneColorantTable
                    tableConstant={props.colorantTableConstant}
                    objectType={objectTypes.ZONE_COLORANT}
                    dataPrivileges={tableColorants}
                    priceDataPrivileges={props.priceDataPrivileges}
                    loading={props.colorantLoading}
                    buttonLoading={props.colorantButtonLoading}
                    allList={props.colorantList}
                    activeList={props.colorantActiveList}
                    availableCount={props.colorantAvailableCount}
                    columnOrder={props.colorantColumnOrder}
                    columnVisibility={props.colorantColumnVisibility}
                    columnWidth={props.colorantColumnWidth}
                    offset={props.colorantOffset}
                    page={props.colorantPage}
                    rowCount={props.colorantRowCount}
                    rowCountCustom={props.colorantRowCountCustom}
                    search={props.colorantSearch}
                    searchParams={props.colorantSearchParams}
                    showFilterRow={props.colorantShowFilterRow}
                    showGlobalSearch={props.colorantShowGlobalSearch}
                    sortingAsc={props.colorantSortingAsc}
                    sortingCriterion={props.colorantSortingCriterion}
                    totalCount={props.colorantTotalCount}
                    useTmcNames={props.optionUseTmcNames || false}
                    currency={props.currency}
                    optionPriceDefinition={props.optionPriceDefinition?.[propertyConstants.PROPERTY_VALUE] || null}
                    optionPriceEnableCompanyCost={
                        props.optionPriceEnableCompanyCost?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                    optionPriceEnablePriceGroups={
                        props.optionPriceEnablePriceGroups?.[propertyConstants.PROPERTY_VALUE] || false
                    }
                />
            );
        }
        return null;
    };

    const getColorantPackagesTable = (): JSX.Element | null => {
        if (tablePackages) {
            return (
                <ZoneColorantPackageTable
                    tableConstant={props.colorantPackageTableConstant}
                    objectType={objectTypes.ZONE_COLORANT_PACKAGE}
                    dataPrivileges={tablePackages}
                    loading={props.colorantPackageLoading}
                    buttonLoading={props.colorantPackageButtonLoading}
                    unitList={props.unitList}
                    allList={props.colorantPackageList}
                    activeList={props.colorantPackageActiveList}
                    availableCount={props.colorantPackageAvailableCount}
                    columnOrder={props.colorantPackageColumnOrder}
                    columnVisibility={props.colorantPackageColumnVisibility}
                    columnWidth={props.colorantPackageColumnWidth}
                    offset={props.colorantPackageOffset}
                    page={props.colorantPackagePage}
                    rowCount={props.colorantPackageRowCount}
                    rowCountCustom={props.colorantPackageRowCountCustom}
                    search={props.colorantPackageSearch}
                    searchParams={props.colorantPackageSearchParams}
                    showFilterRow={props.colorantPackageShowFilterRow}
                    showGlobalSearch={props.colorantPackageShowGlobalSearch}
                    sortingAsc={props.colorantPackageSortingAsc}
                    sortingCriterion={props.colorantPackageSortingCriterion}
                    totalCount={props.colorantPackageTotalCount}
                    useTmcNames={props.optionUseTmcNames || false}
                />
            );
        }
        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={
                [getColorantsTable(), getColorantPackagesTable()].filter((item) => item !== null) as Array<JSX.Element>
            }
            layout={props.sceneLayout}
            dataPrivileges={tableColorants}
            sceneTableSize={props.colorantSceneTableSize}
            sceneConstant={menuConstants.PAGE_ZONE_COLORANTS}
        />
    );
};
