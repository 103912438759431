import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as objectTypes from "../constants/objectTypes";

import { ServerRequest } from "./websocketActions";

export function setGlobalOption(key: string, value: Record<string, any>, locked = false): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_GLOBAL_OPTION,
            params: {
                key,
                value,
                locked
            }
        },
        method: methods.METHOD_SET_GLOBAL_OPTION
    };
}

export function setGlobalOptions(options: any): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_GLOBAL_OPTIONS,
            params: {
                options: options
            }
        },
        method: methods.METHOD_SET_GLOBAL_OPTIONS
    };
}

export function setUserOption(key: string, value: Record<string, any>): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_USER_OPTION,
            params: {
                key,
                value
            }
        },
        method: methods.METHOD_SET_USER_OPTION
    };
}

export function setUserOptions(options: any): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_SET_USER_OPTIONS,
            params: {
                options: options
            }
        },
        method: methods.METHOD_SET_USER_OPTIONS
    };
}

export interface SetDictionaryOption {
    type: typeof actionConstants.SET_DICTIONARY_OPTION;
    payload: {
        key: string;
        object: string;
        value: any;
    };
}

export function setDictionaryOption(key: string, object: string, value: any): SetDictionaryOption {
    return {
        type: actionConstants.SET_DICTIONARY_OPTION,
        payload: {
            key,
            object,
            value
        }
    };
}

export interface SetDictionaryOptions {
    type: typeof actionConstants.SET_DICTIONARY_OPTIONS;
    payload: {
        objectType: objectTypes.ObjectType | null;
        options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>;
        save: boolean;
    };
}

export function setDictionaryOptions(
    objectType: objectTypes.ObjectType | null,
    options: Array<{ key: string; object?: string; value?: any; locked?: boolean }>,
    save: boolean
): SetDictionaryOptions {
    return {
        type: actionConstants.SET_DICTIONARY_OPTIONS,
        payload: {
            objectType,
            options,
            save
        }
    };
}
