import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import { FormulaNote, generateFormulaNoteData, generateFormulaNoteHeadings } from "../../../../types/formulaNote";
import React, { Component } from "react";

import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<FormulaNote>;
    activeList: Array<FormulaNote>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class ZoneFormulaNoteTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_CODE,
            propertyConstants.PROPERTY_VALUE
        ];
        const requiredList: Array<propertyConstants.Property> = [...propertyList];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_CODE];
        const editableList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_VALUE];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        visibleList.splice(2, 0, propertyConstants.PROPERTY_IMAGE_ID);

        if (modalType === null) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_VALUE_SYSTEM);
            visibleList.splice(1, 0, propertyConstants.PROPERTY_VALUE_MASTER);
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateFormulaNoteHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateFormulaNoteData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("formulaNote.editFormulaNote"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_FORMULA_NOTES_EDIT)
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            editModalType: modalTypes.MODAL_FORMULA_NOTES_EDIT,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    headings={this.generateTableHeadings()}
                    data={generateFormulaNoteData(this.props.allList)}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
