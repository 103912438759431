import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Margin, updateMargin } from "../types/margin";
import { Price, updatePrice } from "../types/price";

import { MarginType } from "../types/marginType";
import { PriceOption } from "../types/priceOption";

export type PriceState = Readonly<{
    systemOptionList: Array<PriceOption>;
    systemGenericList: Array<Price>;
    systemGenericActiveList: Array<Price>;
    systemPriceGroupList: Array<Price>;
    systemPriceGroupActiveList: Array<Price>;
    systemMarginTypeList: Array<MarginType>;
    systemMarginList: Array<Margin>;
    systemMarginActiveList: Array<Margin>;
    systemMarginSearch: string | null;
    systemMarginSearchParams: Record<propertyConstants.Property, string>;
    zoneOptionList: Array<PriceOption>;
    zoneGenericList: Array<Price>;
    zoneGenericActiveList: Array<Price>;
    zonePriceGroupList: Array<Price>;
    zonePriceGroupActiveList: Array<Price>;
    zoneMarginTypeList: Array<MarginType>;
    zoneMarginList: Array<Margin>;
    zoneMarginActiveList: Array<Margin>;
    zoneMarginSearch: string | null;
    zoneMarginSearchParams: Record<propertyConstants.Property, string>;
}>;

const initialState: PriceState = {
    systemOptionList: [],
    systemGenericList: [],
    systemGenericActiveList: [],
    systemPriceGroupList: [],
    systemPriceGroupActiveList: [],
    systemMarginTypeList: [],
    systemMarginList: [],
    systemMarginActiveList: [],
    systemMarginSearch: null,
    systemMarginSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOptionList: [],
    zoneGenericList: [],
    zoneGenericActiveList: [],
    zonePriceGroupList: [],
    zonePriceGroupActiveList: [],
    zoneMarginTypeList: [],
    zoneMarginList: [],
    zoneMarginActiveList: [],
    zoneMarginSearch: null,
    zoneMarginSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS
};

export default function prices(state: PriceState = initialState, action: Record<string, any>): PriceState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_MARGIN_TYPE: {
                    return {
                        ...state,
                        systemMarginTypeList: action.payload.list
                    };
                }
                case objectTypes.SYSTEM_PRICE_GENERIC: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.systemGenericActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        systemGenericList: action.payload.list,
                        systemGenericActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRICE_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.systemPriceGroupActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        systemPriceGroupList: action.payload.list,
                        systemPriceGroupActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.systemMarginActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        systemMarginList: action.payload.list,
                        systemMarginActiveList: newActiveList
                    };
                }
                case objectTypes.SYSTEM_PRICE_OPTION: {
                    return {
                        ...state,
                        systemOptionList: action.payload.list
                    };
                }
                case objectTypes.ZONE_MARGIN_TYPE: {
                    return {
                        ...state,
                        zoneMarginTypeList: action.payload.list
                    };
                }
                case objectTypes.ZONE_PRICE_GENERIC: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.zoneGenericActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        zoneGenericList: action.payload.list,
                        zoneGenericActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRICE_GROUP: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.zonePriceGroupActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        zonePriceGroupList: action.payload.list,
                        zonePriceGroupActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.zoneMarginActiveList,
                        action.payload.list
                    );
                    return {
                        ...state,
                        zoneMarginList: action.payload.list,
                        zoneMarginActiveList: newActiveList
                    };
                }
                case objectTypes.ZONE_PRICE_OPTION: {
                    return {
                        ...state,
                        zoneOptionList: action.payload.list
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_PRICE_GENERIC: {
                    return {
                        ...state,
                        systemGenericActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_PRICE_GROUP: {
                    return {
                        ...state,
                        systemPriceGroupActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    return {
                        ...state,
                        systemMarginActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_PRICE_GENERIC: {
                    return {
                        ...state,
                        zoneGenericActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_PRICE_GROUP: {
                    return {
                        ...state,
                        zonePriceGroupActiveList: action.payload.items
                    };
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    return {
                        ...state,
                        zoneMarginActiveList: action.payload.items
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_MARGIN_SEARCH,
                        tableConstants.SYSTEM_MARGIN_SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_MARGIN_SEARCH,
                        tableConstants.ZONE_MARGIN_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_PRICE_GENERIC: {
                        const updatedPriceGenericList = state.systemGenericList.map((item) =>
                            item[propertyConstants.PROPERTY_ID] ===
                            action.payload.params[propertyConstants.PROPERTY_KEY]
                                ? action.payload.params[propertyConstants.PROPERTY_DATA][
                                      action.payload.params[propertyConstants.PROPERTY_KEY]
                                  ]
                                : item
                        );

                        if (updatedPriceGenericList.length) {
                            return {
                                ...state,
                                systemGenericList: updatedPriceGenericList
                            };
                        }
                        return state;
                    }
                    case objectTypes.SYSTEM_PRICE_GROUP: {
                        const updatedPriceGroupList = generalHelper.updateItem(
                            state.systemPriceGroupList,
                            action.payload.params[propertyConstants.PROPERTY_PRICE_GROUP_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA][
                                action.payload.params[propertyConstants.PROPERTY_PRICE_GROUP_ID]
                            ],
                            updatePrice
                        );

                        if (updatedPriceGroupList.length) {
                            return {
                                ...state,
                                systemPriceGroupList: updatedPriceGroupList
                            };
                        }
                        return state;
                    }
                    case objectTypes.SYSTEM_PRICE_MARGIN: {
                        const updatedPriceMarginList = generalHelper.updateItem(
                            state.systemMarginList,
                            action.payload.params[propertyConstants.PROPERTY_MARGIN_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateMargin
                        );

                        if (updatedPriceMarginList.length) {
                            return {
                                ...state,
                                systemMarginList: updatedPriceMarginList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_PRICE_GENERIC: {
                        const updatedPriceGenericList = state.zoneGenericList.map((item) =>
                            item[propertyConstants.PROPERTY_ID] ===
                            action.payload.params[propertyConstants.PROPERTY_KEY]
                                ? action.payload.params[propertyConstants.PROPERTY_DATA][
                                      action.payload.params[propertyConstants.PROPERTY_KEY]
                                  ]
                                : item
                        );

                        if (updatedPriceGenericList.length) {
                            return {
                                ...state,
                                zoneGenericList: updatedPriceGenericList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_PRICE_GROUP: {
                        const updatedPriceGroupList = generalHelper.updateItem(
                            state.zonePriceGroupList,
                            action.payload.params[propertyConstants.PROPERTY_PRICE_GROUP_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA][
                                action.payload.params[propertyConstants.PROPERTY_PRICE_GROUP_ID]
                            ],
                            updatePrice
                        );

                        if (updatedPriceGroupList.length) {
                            return {
                                ...state,
                                zonePriceGroupList: updatedPriceGroupList
                            };
                        }
                        return state;
                    }
                    case objectTypes.ZONE_PRICE_MARGIN: {
                        const updatedPriceMarginList = generalHelper.updateItem(
                            state.zoneMarginList,
                            action.payload.params[propertyConstants.PROPERTY_MARGIN_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateMargin
                        );

                        if (updatedPriceMarginList.length) {
                            return {
                                ...state,
                                zoneMarginList: updatedPriceMarginList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.SYSTEM_PRICE_GENERIC: {
                        return {
                            ...state,
                            systemGenericList: generalHelper.updateLists(state.systemGenericList, action.payload.items),
                            systemGenericActiveList: generalHelper.updateActiveLists(
                                state.systemGenericActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_PRICE_GROUP: {
                        return {
                            ...state,
                            systemPriceGroupList: generalHelper.updateLists(
                                state.systemPriceGroupList,
                                action.payload.items
                            ),
                            systemPriceGroupActiveList: generalHelper.updateActiveLists(
                                state.systemPriceGroupActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_PRICE_MARGIN: {
                        return {
                            ...state,
                            systemMarginList: generalHelper.updateLists(state.systemMarginList, action.payload.items),
                            systemMarginActiveList: generalHelper.updateActiveLists(
                                state.systemMarginActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_PRICE_GENERIC: {
                        return {
                            ...state,
                            zoneGenericList: generalHelper.updateLists(state.zoneGenericList, action.payload.items),
                            zoneGenericActiveList: generalHelper.updateActiveLists(
                                state.zoneGenericActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_PRICE_GROUP: {
                        return {
                            ...state,
                            zonePriceGroupList: generalHelper.updateLists(
                                state.zonePriceGroupList,
                                action.payload.items
                            ),
                            zonePriceGroupActiveList: generalHelper.updateActiveLists(
                                state.zonePriceGroupActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.ZONE_PRICE_MARGIN: {
                        return {
                            ...state,
                            zoneMarginList: generalHelper.updateLists(state.zoneMarginList, action.payload.items),
                            zoneMarginActiveList: generalHelper.updateActiveLists(
                                state.zoneMarginActiveList,
                                action.payload.items
                            )
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return { ...initialState, systemOptionList: state.systemOptionList, zoneOptionList: state.zoneOptionList };
        }
        case actionConstants.EVENT_SAVE_DATABASES:
        case actionConstants.EVENT_SAVE_SYSTEMS: {
            return initialState;
        }
        // TODO chybi event save zones?
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
