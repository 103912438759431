import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { File, updateFile } from "../types/file";
import { FileType, getDefaultProductSheetFileTypes, getDefaultStaticSqlFileTypes } from "../types/fileType";

import { StaticSqlResult } from "../types/staticSqlResult";

export type FileState = Readonly<{
    fileFileTypeList: Array<FileType>;
    fileList: Array<File>;
    fileActiveList: Array<File>;
    fileCount: number | null;
    fileSortingAsc: boolean;
    fileSortingCriterion: propertyConstants.Property;
    fileSearch: string | null;
    fileSearchParams: Record<propertyConstants.Property, string>;
    fileOffset: number;
    filePage: number;
    productSheetFileTypeList: Array<FileType>;
    productSheetList: Array<File>;
    productSheetActiveList: Array<File>;
    productSheetCount: number | null;
    productSheetSortingAsc: boolean;
    productSheetSortingCriterion: propertyConstants.Property;
    productSheetSearch: string | null;
    productSheetSearchParams: Record<propertyConstants.Property, string>;
    productSheetOffset: number;
    productSheetPage: number;
    staticSqlFileTypeList: Array<FileType>;
    staticSqlAllList: Array<File>;
    staticSqlList: Array<File>;
    staticSqlActiveList: Array<File>;
    staticSqlCount: number | null;
    staticSqlSearch: string | null;
    staticSqlSearchParams: Record<propertyConstants.Property, string>;
    staticSqlOffset: number;
    staticSqlPage: number;
    staticSqlResult: StaticSqlResult | null;
}>;

const initialState: FileState = {
    fileFileTypeList: [],
    fileList: [],
    fileActiveList: [],
    fileCount: null,
    fileSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    fileSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    fileSearch: null,
    fileSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    fileOffset: tableConstants.DEFAULT_OFFSET,
    filePage: tableConstants.DEFAULT_PAGE,
    productSheetFileTypeList: [],
    productSheetList: [],
    productSheetActiveList: [],
    productSheetCount: null,
    productSheetSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    productSheetSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    productSheetSearch: null,
    productSheetSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    productSheetOffset: tableConstants.DEFAULT_OFFSET,
    productSheetPage: tableConstants.DEFAULT_PAGE,
    staticSqlFileTypeList: [],
    staticSqlAllList: [],
    staticSqlList: [],
    staticSqlActiveList: [],
    staticSqlCount: null,
    staticSqlSearch: null,
    staticSqlSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    staticSqlOffset: tableConstants.DEFAULT_OFFSET,
    staticSqlPage: tableConstants.DEFAULT_PAGE,
    staticSqlResult: null
};

export default function files(state: FileState = initialState, action: Record<string, any>): FileState {
    switch (action.type) {
        case actionConstants.DATA_LOAD: {
            // TODO vyresit s nacitanim prekladu
            return {
                ...state,
                fileFileTypeList: getDefaultProductSheetFileTypes(),
                productSheetFileTypeList: getDefaultProductSheetFileTypes(),
                staticSqlFileTypeList: getDefaultStaticSqlFileTypes()
            };
        }
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.STATIC_SQL) {
                return {
                    ...state,
                    staticSqlAllList: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.FILE: {
                    const newActiveList = generalHelper.updateActiveLists(state.fileActiveList, action.payload.list);

                    return {
                        ...state,
                        fileList: action.payload.list,
                        fileActiveList: newActiveList,
                        fileCount: action.payload.totalCount
                    };
                }
                case objectTypes.PRODUCT_SHEET: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.productSheetActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        productSheetList: action.payload.list,
                        productSheetActiveList: newActiveList,
                        productSheetCount: action.payload.totalCount
                    };
                }
                case objectTypes.STATIC_SQL: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.staticSqlActiveList,
                        action.payload.list
                    );

                    return {
                        ...state,
                        staticSqlList: action.payload.list,
                        staticSqlActiveList: newActiveList,
                        staticSqlCount: action.payload.totalCount
                    };
                }
                case objectTypes.STATIC_SQL_RESULT: {
                    const result = action.payload.list?.[0] ?? null;

                    return {
                        ...state,
                        staticSqlResult: result
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.FILE: {
                    return {
                        ...state,
                        fileActiveList: action.payload.items
                    };
                }
                case objectTypes.PRODUCT_FILE:
                case objectTypes.PRODUCT_SHEET: {
                    return {
                        ...state,
                        productSheetActiveList: action.payload.items
                    };
                }
                case objectTypes.STATIC_SQL: {
                    return {
                        ...state,
                        staticSqlActiveList: action.payload.items
                    };
                }

                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.FILE: {
                    return {
                        ...state,
                        fileOffset: action.payload.offset,
                        filePage: action.payload.page
                    };
                }
                case objectTypes.PRODUCT_SHEET: {
                    return {
                        ...state,
                        productSheetOffset: action.payload.offset,
                        productSheetPage: action.payload.page
                    };
                }
                case objectTypes.STATIC_SQL: {
                    return {
                        ...state,
                        staticSqlOffset: action.payload.offset,
                        staticSqlPage: action.payload.page
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.FILE: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.FILE_SEARCH,
                        tableConstants.FILE_SEARCH_PARAMS
                    );
                }
                case objectTypes.PRODUCT_SHEET: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.PRODUCT_SHEET_SEARCH,
                        tableConstants.PRODUCT_SHEET_SEARCH_PARAMS
                    );
                }
                case objectTypes.STATIC_SQL: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.STATIC_SQL_SEARCH,
                        tableConstants.STATIC_SQL_SEARCH_PARAMS
                    );
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.SET_SORTING: {
            switch (action.payload.objectType) {
                case objectTypes.FILE: {
                    return {
                        ...state,
                        fileSortingAsc: action.payload.asc,
                        fileSortingCriterion: action.payload.criterion
                    };
                }
                case objectTypes.PRODUCT_SHEET: {
                    return {
                        ...state,
                        productSheetSortingAsc: action.payload.asc,
                        productSheetSortingCriterion: action.payload.criterion
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.PRODUCT_SHEET: {
                        const updatedProductSheetList = generalHelper.updateItem(
                            state.productSheetList,
                            action.payload.params[propertyConstants.PROPERTY_FILE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateFile
                        );

                        if (updatedProductSheetList.length) {
                            return {
                                ...state,
                                productSheetList: updatedProductSheetList
                            };
                        }

                        return state;
                    }
                    case objectTypes.STATIC_SQL: {
                        const updatedStaticSqlList = generalHelper.updateItem(
                            state.staticSqlList,
                            action.payload.params[propertyConstants.PROPERTY_FILE_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateFile
                        );

                        if (updatedStaticSqlList.length) {
                            return {
                                ...state,
                                staticSqlList: updatedStaticSqlList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.PRODUCT_SHEET: {
                        return {
                            ...state,
                            productSheetList: generalHelper.updateLists(state.productSheetList, action.payload.items),
                            productSheetActiveList: generalHelper.updateActiveLists(
                                state.productSheetActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.STATIC_SQL: {
                        return {
                            ...state,
                            staticSqlList: generalHelper.updateLists(state.staticSqlList, action.payload.items),
                            staticSqlActiveList: generalHelper.updateActiveLists(
                                state.staticSqlActiveList,
                                action.payload.items
                            )
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.UPDATE_FILE_LIST: {
            switch (action.payload.objectType) {
                case objectTypes.PRODUCT_FILE: {
                    const newActiveList = generalHelper.updateActiveLists(
                        state.productSheetActiveList,
                        action.payload.fileList
                    );
                    return {
                        ...state,
                        productSheetList: action.payload.fileList,
                        productSheetActiveList: newActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.DELETE: {
            switch (action.payload.objectType) {
                case objectTypes.PRODUCT_FILE: {
                    const updatedList = state.productSheetList.filter(
                        (item: any) => item.id !== action.payload.params.fileId
                    );
                    const newActiveList = generalHelper.updateActiveLists(state.productSheetActiveList, updatedList);
                    return {
                        ...state,
                        productSheetList: updatedList,
                        productSheetActiveList: newActiveList
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.NAVIGATION_MODAL_OPEN: {
            const newFileTypeList = [];

            for (const fileType of state.fileFileTypeList) {
                const file =
                    action.payload.params?.files?.find((file: File) => file.fileTypeId === fileType.id) || null;

                if (!file) {
                    newFileTypeList.push(fileType);
                }
            }

            return {
                ...state,
                fileFileTypeList: newFileTypeList
            };
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            switch (action.payload.type) {
                case modalTypes.MODAL_FILES_TABLE: {
                    return {
                        ...state,
                        fileFileTypeList: getDefaultProductSheetFileTypes(),
                        fileList: initialState.fileList,
                        fileActiveList: initialState.fileActiveList,
                        fileSearch: initialState.fileSearch,
                        fileSearchParams: initialState.fileSearchParams
                    };
                }
                case modalTypes.MODAL_PRODUCTS_ADD:
                case modalTypes.MODAL_PRODUCTS_EDIT:
                case modalTypes.MODAL_BASE_IN_PRODUCT_ADD:
                case modalTypes.MODAL_BASE_IN_PRODUCT_EDIT:
                case modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_ADD:
                case modalTypes.MODAL_BASE_IN_PRODUCT_PACKAGE_EDIT: {
                    return {
                        ...state,
                        fileFileTypeList: getDefaultProductSheetFileTypes(),
                        productSheetList: initialState.productSheetList,
                        productSheetActiveList: initialState.productSheetActiveList
                    };
                }
                case modalTypes.MODAL_FILE_RUN: {
                    return {
                        ...state,
                        staticSqlResult: null
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                fileFileTypeList: getDefaultProductSheetFileTypes(),
                staticSqlResult: null
            };
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return {
                ...initialState,
                fileFileTypeList: state.fileFileTypeList,
                productSheetFileTypeList: state.productSheetFileTypeList,
                staticSqlFileTypeList: state.staticSqlFileTypeList
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
