import * as generalHelper from "../../../../helpers/generalHelper";
import * as importExportActions from "../../../../actions/importExportActions";
import * as importExportConstants from "../../../../constants/entityConstants/importExportConstants";
import * as modalTypes from "../../../../constants/modalTypes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as serverRestMethods from "../../../../constants/serverRestMethods";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ModalType } from "../../../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

import imgCancel from "../../../../resources/img/icons/cancel.svg";
import imgInfo from "../../../../resources/img/icons/info.svg";
import imgSave from "../../../../resources/img/icons/download.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    heading: TableHeading;
    item: any;
    callbackMouseEnter: (event: any, value: string) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellButton extends Component<Props> {
    handleButtonClick = (type: any): void => {
        const { item, sessionUuid, urlRest, openModal } = this.props;
        const errorModalParams = {
            title: translate("general.errorDetail"),
            type: modalTypes.MODAL_DISPLAY,
            objectType: objectTypes.DATA_ERROR,
            headings: [],
            displayText: item.errorText,
            classNameTable: "simple-table"
        };
        const warningModalParams = {
            title: translate("confirmationModal.warningDetail"),
            type: modalTypes.MODAL_DISPLAY,
            objectType: objectTypes.DATA_ERROR,
            headings: [],
            displayText: item.warningText,
            classNameTable: "simple-table"
        };

        const filePreviewModalParams = {
            title: translate("file.filePreview", {
                name: item?.[propertyConstants.PROPERTY_NAME] || ""
            }),
            type: modalTypes.MODAL_FILE_PREVIEW,
            objectType: objectTypes.FILE,
            headings: [],
            fileId: item?.[propertyConstants.PROPERTY_ID] ?? null,
            classNameTable: "simple-table"
        };

        // Handle files
        if (this.props.objectType === objectTypes.FILE || this.props.objectType === objectTypes.PRODUCT_FILE) {
            openModal(modalTypes.MODAL_FILE_PREVIEW, filePreviewModalParams);
        }

        if (type === tableConstants.FILE_DOWNLOAD && sessionUuid) {
            const activeUrl = `${urlRest}${serverRestMethods.METHOD_DOWNLOAD_FILE}?file_id=${
                item[propertyConstants.PROPERTY_ID]
            }&as_attachment=True&session_uuid=${sessionUuid}`;

            if (activeUrl) {
                window.open(activeUrl);
            }
        }

        // Handle export tasks
        if (type === tableConstants.EXPORT_STATUS_EVENT_ABORT) {
            this.props.abortExportTask(item[propertyConstants.PROPERTY_ID]);
        }

        if (type === tableConstants.EXPORT_STATUS_EVENT_DOWNLOAD && sessionUuid) {
            const activeUrl = `${urlRest}${serverRestMethods.METHOD_GET_EXPORT_TASK_FILE}?export_task_id=${
                item[propertyConstants.PROPERTY_ID]
            }&session_uuid=${sessionUuid}`;

            if (activeUrl) {
                window.open(activeUrl);
            }
        }

        if (type === tableConstants.EXPORT_STATUS_EVENT_ERROR) {
            openModal(modalTypes.MODAL_DISPLAY, errorModalParams);
        }

        // Handle import tasks
        if (type === tableConstants.IMPORT_STATUS_EVENT_ABORT) {
            this.props.abortImportTask(item[propertyConstants.PROPERTY_ID]);
        }

        if (type === tableConstants.IMPORT_STATUS_EVENT_ERROR) {
            openModal(modalTypes.MODAL_DISPLAY, errorModalParams);
        }

        if (type === tableConstants.IMPORT_STATUS_EVENT_WARNING) {
            openModal(modalTypes.MODAL_DISPLAY, warningModalParams);
        }
    };

    getCellClassname = (property: propertyConstants.Property): string => {
        return `td ${property} table-button`;
    };

    getButtonProperties = (option: any): { caption: string; className: string; icon: string | null; type: any } => {
        const { objectType, item } = this.props;
        let caption = "";
        let className = "btn btn-link";
        let icon = null;
        let type = null;

        switch (objectType) {
            case objectTypes.EXPORT_TASK: {
                if (!importExportConstants.EXPORT_IGNORE_ACTION_BUTTON.includes(option?.PROPERTY_ID)) {
                    if (!option?.[propertyConstants.PROPERTY_FINISHED]) {
                        caption = translate("export.abortExport");
                        className += " cancel-process-button";
                        icon = imgCancel;
                        type = tableConstants.EXPORT_STATUS_EVENT_ABORT;
                    }

                    if (
                        option?.[propertyConstants.PROPERTY_FINISHED] &&
                        option?.[propertyConstants.PROPERTY_RESULT] &&
                        item[propertyConstants.PROPERTY_DELETED] !== importExportConstants.TASK_DELETED
                    ) {
                        caption = translate("general.download");
                        icon = imgSave;
                        type = tableConstants.EXPORT_STATUS_EVENT_DOWNLOAD;
                    }

                    if (
                        option?.[propertyConstants.PROPERTY_FINISHED] &&
                        !option?.[propertyConstants.PROPERTY_RESULT] &&
                        item[propertyConstants.PROPERTY_ERROR_TEXT]
                    ) {
                        caption = translate("general.errorDetail");
                        icon = imgInfo;
                        type = tableConstants.EXPORT_STATUS_EVENT_ERROR;
                    }
                }
                break;
            }
            case objectTypes.IMPORT_TASK: {
                if (!importExportConstants.IMPORT_IGNORE_ACTION_BUTTON.includes(option?.PROPERTY_KEY)) {
                    if (!option?.[propertyConstants.PROPERTY_FINISHED]) {
                        caption = translate("import.abortImport");
                        className += " cancel-process-button";
                        icon = imgCancel;
                        type = tableConstants.IMPORT_STATUS_EVENT_ABORT;
                    }

                    if (
                        option?.[propertyConstants.PROPERTY_FINISHED] &&
                        option?.[propertyConstants.PROPERTY_RESULT] &&
                        item[propertyConstants.PROPERTY_WARNING_TEXT]
                    ) {
                        caption = translate("confirmationModal.warningDetail");
                        icon = imgInfo;
                        type = tableConstants.IMPORT_STATUS_EVENT_WARNING;
                    }

                    if (
                        option?.[propertyConstants.PROPERTY_FINISHED] &&
                        !option?.[propertyConstants.PROPERTY_RESULT] &&
                        item[propertyConstants.PROPERTY_ERROR_TEXT]
                    ) {
                        caption = translate("general.errorDetail");
                        icon = imgInfo;
                        type = tableConstants.IMPORT_STATUS_EVENT_ERROR;
                    }
                }
                break;
            }
            case objectTypes.FILE:
            case objectTypes.PRODUCT_FILE: {
                caption = translate("general.preview");
                icon = imgInfo;
                type = tableConstants.TABLE_TYPE_FILE;
                break;
            }
            case objectTypes.PRODUCT_SHEET:
            case objectTypes.STATIC_SQL: {
                caption = translate("general.download");
                icon = imgSave;
                type = tableConstants.FILE_DOWNLOAD;
                break;
            }
            default: {
                break;
            }
        }

        return {
            caption: caption,
            className: className,
            icon: icon,
            type: type
        };
    };

    getButton = (option: any): JSX.Element | null => {
        if (
            !option &&
            (this.props.objectType === objectTypes.EXPORT_TASK || this.props.objectType === objectTypes.IMPORT_TASK)
        ) {
            return null;
        }

        const buttonProperties = this.getButtonProperties(option);

        if (buttonProperties.caption && buttonProperties.className && buttonProperties.icon && buttonProperties.type) {
            return (
                <button
                    className={buttonProperties.className}
                    onClick={(): void => this.handleButtonClick(buttonProperties.type)}
                >
                    <ReactSVG className={"svg-icon"} src={buttonProperties.icon} />
                    <span
                        onMouseEnter={(event: any): void =>
                            this.props.callbackMouseEnter(event, buttonProperties.caption)
                        }
                        data-tip
                        data-for={"table-cell-tooltip"}
                    >
                        {buttonProperties.caption}
                    </span>
                </button>
            );
        }

        return null;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const option =
            value !== null ? generalHelper.getObjectById(heading[tableConstants.TABLE_HEADING_ENUM], value) : null;

        return (
            <div
                className={this.getCellClassname(property)}
                key={property}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
            >
                {this.getButton(option)}
            </div>
        );
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

export type DispatchType = Readonly<{
    abortExportTask(taskId: number): any;
    abortImportTask(taskId: number): any;
    openModal(type: ModalType, params: any): any;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    abortExportTask: (taskId: number): any => dispatch(importExportActions.abortExportTask(taskId)),
    abortImportTask: (taskId: number): any => dispatch(importExportActions.abortImportTask(taskId)),
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TableCellButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellButton);
