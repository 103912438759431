import * as serverConstants from "../constants/serverConstants";

import { ServerResponse } from "../types/serverResponse";

export function isResponseValid(response: ServerResponse): boolean {
    if (response.result !== serverConstants.RESULT_OK) {
        return false;
    }

    if (response.data === undefined || response.data === null) {
        return false;
    }

    return true;
}

export function isArrayResponseValid(response: ServerResponse): boolean {
    if (response.result !== serverConstants.RESULT_OK) {
        return false;
    }

    if (response.data === undefined || response.data === null) {
        return false;
    }

    if (response.data.count === undefined || !Array.isArray(response.data.data)) {
        return false;
    }

    return true;
}

export function isEventValid(response: ServerResponse): boolean {
    if (response.data === undefined || response.data === null) {
        return false;
    }

    return true;
}

export function isLoggedUserFormatValid(response: ServerResponse): boolean {
    if (!isResponseValid(response)) {
        return false;
    }

    if (typeof response.data.id !== "number" || typeof response.data.username !== "string") {
        return false;
    }

    return true;
}

export function isGetLoggedUserFormatValid(response: ServerResponse): boolean {
    if (!isResponseValid(response)) {
        return false;
    }

    if (typeof response.data.id !== "number" || typeof response.data.username !== "string") {
        return false;
    }

    if (
        !Array.isArray(response.data.menu) ||
        !Array.isArray(response.data.groups) ||
        typeof response.data.privileges !== "object" ||
        typeof response.data.dataPrivileges !== "object" ||
        typeof response.data.options !== "object"
    ) {
        return false;
    }

    return true;
}
