import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import {
    ProductGroup,
    createEmptyProductGroup,
    generateProductGroupData,
    generateProductGroupHeadings
} from "../../../../types/productGroup";
import React, { Component } from "react";

import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { generateProductHeadings } from "../../../../types/product";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<ProductGroup>;
    activeList: Array<ProductGroup>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class SystemProductGroupTable extends Component<Props> {
    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        objectType: objectTypes.ObjectType = this.props.objectType
    ): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_PRIORITY
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateProductGroupHeadings(
            objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.PROPAGATE_MODAL,
            objectTypes.MASTER_SYSTEM_PRODUCT_GROUP,
            "",
            createEmptyProductGroup(),
            generateProductHeadings(
                objectTypes.SYSTEM_PRODUCT,
                [],
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                [],
                [],
                [],
                {} as Record<propertyConstants.Property, number>
            ),
            undefined,
            generateProductHeadings(
                objectTypes.SYSTEM_PRODUCT,
                [],
                [propertyConstants.PROPERTY_NAME],
                [propertyConstants.PROPERTY_NAME],
                [],
                [],
                [],
                {} as Record<propertyConstants.Property, number>
            ),
            this.generateTableHeadings(
                modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE,
                objectTypes.MASTER_SYSTEM_PRODUCT_GROUP
            ),
            undefined,
            this.props.dataPrivileges,
            undefined,
            "simple-table"
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateProductGroupData(this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.PROPAGATE_EDIT_MODAL,
                objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS,
                "",
                activeData[0],
                generateProductHeadings(
                    objectTypes.SYSTEM_PRODUCT,
                    [],
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    [],
                    [],
                    [],
                    {} as Record<propertyConstants.Property, number>
                ),
                undefined,
                generateProductHeadings(
                    objectTypes.SYSTEM_PRODUCT,
                    [],
                    [propertyConstants.PROPERTY_NAME],
                    [propertyConstants.PROPERTY_NAME],
                    [],
                    [],
                    [],
                    {} as Record<propertyConstants.Property, number>
                ),
                this.generateTableHeadings(
                    modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE,
                    objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS
                ),
                undefined,
                this.props.dataPrivileges,
                undefined,
                "simple-table"
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_ADD_PROPAGATE,
            addModalParams: this.generateAddModalParams(),
            editModalType: modalTypes.MODAL_SYSTEM_PRODUCT_GROUPS_EDIT_PROPAGATE,
            editModalParams: this.generateEditModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    headings={this.generateTableHeadings()}
                    data={generateProductGroupData(this.props.allList)}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
