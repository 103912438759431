import * as actionConstants from "../constants/actionTypes";
import * as navigationTypes from "../constants/navigationTypes";
import * as reducerHelper from "../helpers/reducerHelper";

import { DataItem } from "../types/dataItem";
import { FlashMessage } from "../types/flashMessage";
import { MenuItem } from "../types/menu";
import { Modal } from "../types/modal";
import { OptionItem } from "../types/optionItem";
import { PrivilegeItem } from "../types/privilegeItem";

export type NavigationState = Readonly<{
    activeBookmark: string | null;
    activeModalBookmark: string | null;
    activeNotificationPanel: boolean;
    activeScene: navigationTypes.Scene;
    activeWizard: navigationTypes.Wizard | null;
    dataChanged: boolean;
    flashMessages: Array<FlashMessage>;
    isJumping: boolean;
    menuList: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>;
    modalList: Array<Modal>;
    nextModal: Modal | null;
    wizardStep: number;
}>;

const initialState: NavigationState = {
    activeBookmark: null,
    activeModalBookmark: null,
    activeNotificationPanel: false,
    activeScene: navigationTypes.HOMEPAGE,
    activeWizard: null,
    dataChanged: false,
    flashMessages: [],
    isJumping: false,
    menuList: [],
    modalList: [],
    nextModal: null,
    wizardStep: 0
};

export default function navigation(
    state: NavigationState = initialState,
    action: Record<string, any>
): NavigationState {
    switch (action.type) {
        case actionConstants.DATA_CHANGED: {
            return {
                ...state,
                dataChanged: action.payload.dataChanged
            };
        }
        case actionConstants.JUMP: {
            return {
                ...state,
                isJumping: true
            };
        }
        case actionConstants.NAVIGATION_SET_ACTIVE_BOOKMARK: {
            return {
                ...state,
                activeBookmark: action.payload.key
            };
        }
        case actionConstants.NAVIGATION_SET_ACTIVE_MODAL_BOOKMARK: {
            return {
                ...state,
                activeModalBookmark: action.payload.key
            };
        }
        case actionConstants.NAVIGATION_SET_NOTIFICATION_PANEL: {
            return {
                ...state,
                activeNotificationPanel: action.payload.activeNotificationPanel
            };
        }
        case actionConstants.NAVIGATION_SET_JUMP:
        case actionConstants.NAVIGATION_SET_SCENE: {
            return {
                ...state,
                activeBookmark: null,
                activeModalBookmark: null,
                activeScene: action.payload.scene,
                activeWizard: null,
                isJumping: false,
                wizardStep: 0
            };
        }
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...state,
                activeWizard: action.payload.wizard,
                wizardStep: action.payload.wizard === null ? 0 : state.wizardStep
            };
        }
        case actionConstants.NAVIGATION_SET_WIZARD_STEP: {
            return {
                ...state,
                wizardStep: action.payload.step
            };
        }
        case actionConstants.NAVIGATION_FLASH_MESSAGE_SAVE: {
            return {
                ...state,
                flashMessages: state.flashMessages.concat([action.payload.flashMessage])
            };
        }
        case actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE: {
            return {
                ...state,
                flashMessages: state.flashMessages.filter(
                    (item) => state.flashMessages.indexOf(item) !== action.payload.id
                )
            };
        }
        case actionConstants.NAVIGATION_FLASH_MESSAGE_DELETE_ALL: {
            return {
                ...state,
                flashMessages: []
            };
        }
        case actionConstants.NAVIGATION_SET_NEXT_MODAL: {
            return {
                ...state,
                nextModal: action.payload.modal
            };
        }
        case actionConstants.NAVIGATION_MODAL_OPEN: {
            return {
                ...state,
                activeModalBookmark: action.payload.params?.bookmarks?.[0]?.key ?? null,
                modalList: [
                    ...state.modalList,
                    new Modal(
                        reducerHelper.getNewModalId(state.modalList),
                        action.payload.type,
                        action.payload.params,
                        action.payload.nextAction
                    )
                ]
            };
        }
        case actionConstants.NAVIGATION_MODAL_CLOSE: {
            const modals = [...state.modalList];
            const lastModal = modals.reverse().find((modal) => modal.type === action.payload.type);
            // TODO bookmark by se mela ukladat primo do Modal typu, co kdyz bude vice modalu s bookmarkama na sobe?
            return {
                ...state,
                activeModalBookmark: null,
                modalList: state.modalList.filter((modal) => modal !== lastModal)
            };
        }
        case actionConstants.NAVIGATION_CLOSE_ALL_MODALS: {
            return {
                ...state,
                activeModalBookmark: null,
                modalList: []
            };
        }
        case actionConstants.EVENT_SAVE_MENU: {
            return {
                ...state,
                menuList: action.payload.menuList
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        // TODO logout smazat? v ostatnich reducerech neni -> sjednotit
        case actionConstants.LOGOUT_SUCCESS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
