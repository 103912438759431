import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/data/SystemZoneSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { SystemTable } from "./tables/SystemTable";
import { ZoneTable } from "./tables/ZoneTable";

import { t as translate } from "react-i18nify";

type Props = PropsType;

type State = {
    tableSystems: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    tableZones: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class SystemZoneScene extends Component<Props, State> {
    state: State = {
        tableSystems:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.systemTableConstant)
                : null) || null,
        tableZones:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.zoneTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableSystems:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.systemTableConstant)
                        : null) || null,
                tableZones:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.zoneTableConstant)
                        : null) || null
            });
        }
    }

    getSystemsTable = (): JSX.Element | null => {
        const { systemTableConstant } = this.props;
        const { tableSystems } = this.state;

        if (tableSystems) {
            return (
                <SystemTable
                    tableConstant={systemTableConstant}
                    objectType={objectTypes.SYSTEM}
                    privileges={this.props.privileges}
                    dataPrivileges={tableSystems}
                    loading={this.props.systemLoading}
                    buttonLoading={this.props.systemButtonLoading}
                    databaseActiveList={this.props.databaseActiveList}
                    allList={this.props.systemList}
                    activeList={this.props.systemActiveList}
                    columnOrder={this.props.systemColumnOrder}
                    columnVisibility={this.props.systemColumnVisibility}
                    columnWidth={this.props.systemColumnWidth}
                    offset={this.props.systemOffset}
                    page={this.props.systemPage}
                    rowCount={this.props.systemRowCount}
                    rowCountCustom={this.props.systemRowCountCustom}
                    search={this.props.systemSearch}
                    searchParams={this.props.systemSearchParams}
                    showFilterRow={this.props.systemShowFilterRow}
                    showGlobalSearch={this.props.systemShowGlobalSearch}
                    sortingAsc={this.props.systemSortingAsc}
                    sortingCriterion={this.props.systemSortingCriterion}
                    totalCount={this.props.systemTotalCount}
                />
            );
        }

        return null;
    };

    getZonesTable = (): JSX.Element | null => {
        const { zoneTableConstant } = this.props;
        const { tableZones } = this.state;

        if (tableZones !== null) {
            return (
                <ZoneTable
                    tableConstant={zoneTableConstant}
                    objectType={objectTypes.ZONE}
                    dataPrivileges={tableZones}
                    privileges={this.props.privileges}
                    loading={this.props.zoneLoading}
                    buttonLoading={this.props.zoneButtonLoading}
                    currencyList={this.props.currencyList}
                    staticSqlList={this.props.staticSqlList}
                    systemActiveList={this.props.systemActiveList}
                    allList={this.props.zoneList}
                    activeList={this.props.zoneActiveList}
                    columnOrder={this.props.zoneColumnOrder}
                    columnVisibility={this.props.zoneColumnVisibility}
                    columnWidth={this.props.zoneColumnWidth}
                    offset={this.props.zoneOffset}
                    page={this.props.zonePage}
                    rowCount={this.props.zoneRowCount}
                    rowCountCustom={this.props.zoneRowCountCustom}
                    search={this.props.zoneSearch}
                    searchParams={this.props.zoneSearchParams}
                    showFilterRow={this.props.zoneShowFilterRow}
                    showGlobalSearch={this.props.zoneShowGlobalSearch}
                    sortingAsc={this.props.zoneSortingAsc}
                    sortingCriterion={this.props.zoneSortingCriterion}
                    totalCount={this.props.zoneTotalCount}
                />
            );
        }

        return null;
    };

    render(): JSX.Element | null {
        const { tableSystems } = this.state;

        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getSystemsTable(), this.getZonesTable()].filter((item) => item !== null) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.systemZoneSceneTableSize}
                dataPrivileges={tableSystems}
                sceneConstant={menuConstants.PAGE_DATA_SYSTEM_ZONE_SETTINGS}
                title={translate("general.systemZoneSettings")}
            />
        );
    }
}
