import * as objectTypes from "../../../../constants/objectTypes";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import imgAvailable from "../../../../resources/img/icons/success-transparent.svg";
import imgHidden from "../../../../resources/img/icons/stop-icon.svg";
import imgNotAvailable from "../../../../resources/img/icons/cancel.svg";

type Props = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    isEditable: boolean;
    isSourceChanged: boolean;
    className: string;
    callbackBlur: (event: any, value: number, rowId?: number | string, headingId?: propertyConstants.Property) => any;
    callbackMouseEnter: (event: any, value: string) => any;
    callbackMouseLeave: () => any;
};

export class TableCellRadioIcon extends Component<Props> {
    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (item: number, selected: number): string => {
        let className = "icon-radio-button";

        if (item === selected) {
            className += " active";
        }

        return className;
    };

    getIcon = (item: privilegeConstants.PrivilegeStatusType): string => {
        if (this.props.objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) {
            switch (item) {
                case privilegeConstants.PRIVILEGE_STATUS_AVAILABLE:
                    return imgAvailable;
                case privilegeConstants.PRIVILEGE_STATUS_HIDDEN:
                    return imgHidden;
                case privilegeConstants.PRIVILEGE_STATUS_NOT_AVAILABLE:
                    return imgNotAvailable;
                default:
                    break;
            }
        }

        return "";
    };

    getIconClassname = (item: number): string => {
        let className = "svg-icon";

        if (this.props.objectType === objectTypes.MASTER_REDLIKE_PRIVILEGE) {
            switch (item) {
                case privilegeConstants.PRIVILEGE_STATUS_AVAILABLE: {
                    className += " success";
                    break;
                }
                case privilegeConstants.PRIVILEGE_STATUS_NOT_AVAILABLE: {
                    className += " not-available";
                    break;
                }
                case privilegeConstants.PRIVILEGE_STATUS_HIDDEN: {
                    className += " error";
                    break;
                }
                default:
                    break;
            }
        }

        return className;
    };

    render(): JSX.Element {
        const { heading, item, isEditable, isSourceChanged } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;
        const items = heading[tableConstants.TABLE_HEADING_ENUM];

        const radioButtons = [];

        for (const radioItem of items) {
            radioButtons.push(
                <div
                    key={radioItem}
                    className={this.getClassname(radioItem, value)}
                    data-tip
                    data-for={"table-cell-tooltip"}
                    onClick={(event): void =>
                        this.props.callbackBlur(event, radioItem, item[propertyConstants.PROPERTY_ID], property)
                    }
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, radioItem)}
                    onMouseLeave={(): void => this.props.callbackMouseLeave()}
                >
                    <ReactSVG
                        disabled={!isEditable}
                        className={this.getIconClassname(radioItem)}
                        src={this.getIcon(radioItem)}
                    />
                </div>
            );
        }

        return (
            <div className={`td ${property} ${this.props.className}`} key={property}>
                <div className={isSourceChanged ? "radio-container dotted" : "radio-container"}>{radioButtons}</div>
            </div>
        );
    }
}
