export class Option {
    key: string;
    value: any;
    userId: number;
    locked: boolean;

    constructor(key: string, value: any, userId: number, locked: boolean) {
        this.key = key;
        this.value = value;
        this.userId = userId;
        this.locked = locked;
    }
}

export function mapOption(data: Record<string, any>): Option | null {
    try {
        return new Option(data.key, data.value, data.userId, data.locked);
    } catch (e) {
        return null;
    }
}
