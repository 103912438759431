import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Fandeck, mapFandeck } from "../../types/fandeck";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_FANDECKS:
        case methods.METHOD_GET_ALL_MASTER_SIMPLE_FANDECKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeckList: Array<Fandeck> = [];
            let fandeck: Fandeck | null;

            for (const item of response.data.data) {
                fandeck = mapFandeck(item);

                if (fandeck) {
                    fandeckList.push(fandeck);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_FANDECK, fandeckList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeckList: Array<Fandeck> = [];
            let fandeck: Fandeck | null;

            for (const item of response.data.data) {
                fandeck = mapFandeck(item);

                if (fandeck) {
                    fandeckList.push(fandeck);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.MASTER_SYSTEM_FANDECK, fandeckList, response.data.count)
            ];
        }
        case methods.METHOD_GET_MASTER_FANDECK_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeck = mapFandeck(response.data);
            const requests: Array<any> = [];

            if (fandeck && state.navigation.nextModal) {
                requests.push(
                    navigationActions.navigationOpenModal(state.navigation.nextModal[propertyConstants.PROPERTY_TYPE], {
                        ...state.navigation.nextModal.params,
                        data: fandeck
                    }),
                    navigationActions.setNextModal(null)
                );
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(
                    state.navigation.activeScene === navigationTypes.MASTER_FANDECKS_SCENE
                        ? objectTypes.MASTER_FANDECK
                        : objectTypes.MASTER_FANDECK_IN_COLOR,
                    fandeck ? [fandeck] : []
                )
            ];
        }
        case methods.METHOD_ADD_MASTER_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_FANDECK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FANDECKS_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.MASTER_FANDECK)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_FANDECKS_EDIT)) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_FANDECK));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FANDECKS_EDIT));
            } else {
                const fandeck = mapFandeck(response.data);

                if (fandeck) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_FANDECK, [fandeck]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_MASTER_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_FANDECK),
                universalObjectActions.setActiveItems(objectTypes.MASTER_FANDECK, [])
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_FANDECKS:
        case methods.METHOD_GET_ALL_SYSTEM_SIMPLE_FANDECKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeckList: Array<Fandeck> = [];
            let fandeck: Fandeck | null;

            for (const item of response.data.data) {
                fandeck = mapFandeck(item);

                if (fandeck) {
                    fandeckList.push(fandeck);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_FANDECK,
                    fandeckList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeckList: Array<Fandeck> = [];
            let fandeck: Fandeck | null;

            for (const item of response.data.data) {
                fandeck = mapFandeck(item);

                if (fandeck) {
                    fandeckList.push(fandeck);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.SYSTEM_ZONE_FANDECK, fandeckList, response.data.count)
            ];
        }
        case methods.METHOD_GET_SYSTEM_FANDECK_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeck = mapFandeck(response.data);
            const requests: Array<any> = [];

            if (fandeck && state.navigation.nextModal) {
                requests.push(
                    navigationActions.navigationOpenModal(state.navigation.nextModal[propertyConstants.PROPERTY_TYPE], {
                        ...state.navigation.nextModal.params,
                        data: fandeck
                    }),
                    navigationActions.setNextModal(null)
                );
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.SYSTEM_FANDECK, fandeck ? [fandeck] : [])
            ];
        }
        case methods.METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_FANDECK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_FANDECKS_PROPAGATE)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.SYSTEM_FANDECK)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.length > 0) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_FANDECK));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FANDECKS_EDIT));
            } else {
                const fandeck = mapFandeck(response.data);

                if (fandeck) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_FANDECK, [fandeck]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_SYSTEM_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_FANDECK),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_FANDECK, [])
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_FANDECKS:
        case methods.METHOD_GET_ALL_ZONE_SIMPLE_FANDECKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeckList: Array<Fandeck> = [];
            let fandeck: Fandeck | null;

            for (const item of response.data.data) {
                fandeck = mapFandeck(item);

                if (fandeck) {
                    fandeckList.push(fandeck);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.ZONE_FANDECK,
                    fandeckList,
                    response.data.count,
                    response.data.availableCount
                )
            ];
        }
        case methods.METHOD_GET_ZONE_FANDECK_BY_ID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const fandeck = mapFandeck(response.data);
            const requests: Array<any> = [];

            if (fandeck && state.navigation.nextModal) {
                requests.push(
                    navigationActions.navigationOpenModal(state.navigation.nextModal[propertyConstants.PROPERTY_TYPE], {
                        ...state.navigation.nextModal.params,
                        data: fandeck
                    }),
                    navigationActions.setNextModal(null)
                );
            }

            return [
                ...requests,
                universalObjectActions.updateActiveItems(objectTypes.ZONE_FANDECK, fandeck ? [fandeck] : [])
            ];
        }
        case methods.METHOD_ADD_ZONE_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_FANDECK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_FANDECKS_ADD)
            ];
        }
        case methods.METHOD_ADD_ZONE_FANDECK_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_FANDECK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_FANDECKS_PROPAGATE)
            ];
        }
        case methods.METHOD_EDIT_ZONE_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [
                    messageHelper.getInvalidFormatMessage(response),
                    universalObjectActions.reloadData(objectTypes.ZONE_FANDECK)
                ];
            }

            const requests = [universalObjectActions.dataChanged(true)];

            if (state.navigation.modalList.length > 0) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_FANDECK));
                requests.push(navigationActions.navigationCloseModal(modalTypes.MODAL_FANDECKS_EDIT));
            } else {
                const fandeck = mapFandeck(response.data);

                if (fandeck) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_FANDECK, [fandeck]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_ZONE_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_FANDECK),
                universalObjectActions.setActiveItems(objectTypes.ZONE_FANDECK, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
