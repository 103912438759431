import * as actionConstants from "../constants/actionTypes";
import * as messageHelper from "../helpers/messageHelper";
import * as requestHelper from "../helpers/requestHelper";
import * as serverActions from "../actions/serverActions";

export interface ServerRestResponse {
    type: typeof actionConstants.SERVER_REST_RESPONSE;
    payload: {
        data: any;
    };
}

export function serverRestResponse(event: Record<string, any>): ServerRestResponse {
    return {
        type: actionConstants.SERVER_REST_RESPONSE,
        payload: {
            data: event.data
        }
    };
}

export function serverProcessRestRequest(payload: Record<string, any>, method: string): any {
    return async (dispatch: any, getState: any): Promise<any> => {
        const urlRest: string = getState().server.urlRest;
        const sequence: number = getState().server.sequence + 1;
        const sessionUuid: string | null = getState().software.sessionUuid;

        try {
            const params = {
                ...payload.params,
                sequence: sequence,
                sessionUuid: sessionUuid
            };

            dispatch(serverActions.serverSaveRequest(sequence, method));

            const response = await fetch(
                `${urlRest}/api/v1/${payload.type}`,
                requestHelper.getRestServerFetchInit(params)
            );

            if (response.ok) {
                const json = await response.json();

                dispatch(
                    serverRestResponse({
                        data: JSON.stringify({ ...json })
                    })
                );
            } else {
                dispatch(messageHelper.getFlashMessage({}), serverActions.serverRemoveRequest(sequence));
            }
        } catch {
            dispatch(messageHelper.getFlashMessage({}), serverActions.serverRemoveRequest(sequence));
        }
    };
}
