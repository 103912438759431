import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { ExportTask, updateExportTask } from "../types/exportTask";

import { ExportStatus } from "../types/exportStatus";
import { ExportType } from "../types/exportType";

export type ExportState = Readonly<{
    exportStatuses: Array<ExportStatus>;
    exportTypes: Array<ExportType>;
    list: Array<ExportTask>;
    activeList: Array<ExportTask>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: ExportState = {
    exportStatuses: [],
    exportTypes: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function exports(state: ExportState = initialState, action: Record<string, any>): ExportState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.EXPORT_STATUS) {
                return {
                    ...state,
                    exportStatuses: action.payload.list
                };
            }

            if (action.payload.objectType === objectTypes.EXPORT_TYPE) {
                return {
                    ...state,
                    exportTypes: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.EXPORT_TASK) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.EXPORT_TASK) {
                const list = generalHelper.updateLists(state.list, action.payload.items);
                const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                return {
                    ...state,
                    list: list,
                    activeList: newActiveList
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.EXPORT_TASK) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.EXPORT_TASK) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.CHECK_ALL: {
            if (action.payload.objectType === objectTypes.EXPORT_TASK) {
                const updatedExportTaskList: Array<ExportTask> = [];

                for (const item of state.list) {
                    if (item[propertyConstants.PROPERTY_DELETED] !== importExportConstants.TASK_DELETED) {
                        const updatedExportTask = updateExportTask(item, {
                            [propertyConstants.PROPERTY_IS_CHECKED]: action.payload.checkAll
                        });

                        if (updatedExportTask) {
                            updatedExportTaskList.push(updatedExportTask);
                        }
                    } else {
                        updatedExportTaskList.push(item);
                    }
                }

                return {
                    ...state,
                    list: updatedExportTaskList
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                exportStatuses: state.exportStatuses,
                exportTypes: state.exportTypes
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
