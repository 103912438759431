import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { FileType } from "../../../types/fileType";
import { GenericImageScene } from "../../../components/scenes/generic/GenericImageScene";
import { Image } from "../../../types/image";
import { ImageCategory } from "../../../types/imageCategory";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    categoryList: Array<ImageCategory>;
    fileTypeList: Array<FileType>;
    imageTableConstant: string;
    imageList: Array<Image>;
    imageActiveList: Array<Image>;
    imageLoading: boolean;
    imageButtonLoading: boolean;
    imageColumnOrder: Array<propertyConstants.Property>;
    imageColumnVisibility: Record<propertyConstants.Property, boolean>;
    imageColumnWidth: Record<propertyConstants.Property, number>;
    imageOffset: number;
    imagePage: number;
    imageRowCount: number;
    imageRowCountCustom: boolean;
    imageSceneTableSize: Record<string, any>;
    imageSearch: string | null;
    imageSearchParams: Record<propertyConstants.Property, string>;
    imageShowFilterRow: boolean;
    imageShowGlobalSearch: boolean;
    imageSortingAsc: boolean;
    imageSortingCriterion: propertyConstants.Property | null;
    imageTotalCount: number | null;
    sceneLayout: string;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_IMAGES
    ),
    categoryList: state.imageCategory.allList,
    fileTypeList: state.image.imageFileTypeList,
    imageTableConstant: menuConstants.TABLE_GENERIC_IMAGES,
    imageList: state.image.list,
    imageActiveList: state.image.activeList,
    imageLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_IMAGES ||
            request.method === methods.METHOD_GET_ALL_IMAGE_CATEGORIES ||
            request.method === methods.METHOD_DELETE_IMAGE
    ),
    imageButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_IMAGE
    ),
    imageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageOffset: state.image.offset,
    imagePage: state.image.page,
    imageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_IMAGES
    ),
    imageSearch: state.image.search,
    imageSearchParams: state.image.searchParams,
    imageShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_IMAGES
        )
    ),
    imageSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_IMAGES
        )
    ),
    imageTotalCount: state.image.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_IMAGES
    )
});

export const GenericImageSceneContainer = connect(mapStateToProps)(GenericImageScene);
