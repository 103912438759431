import * as inputConstants from "../../../constants/inputConstants";
import * as modalTypes from "../../../constants/modalTypes";
import * as navigationTypes from "../../../constants/navigationTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as privilegeConstants from "../../../constants/privilegeConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";
import * as translationHelper from "../../../helpers/translationHelper";

import { DispatchType, PropsType } from "../../../containers/scenes/header/OptionsSceneContainer";
import React, { Component } from "react";
import {
    createEmptyTranslationManagement,
    generateTranslationManagementHeadings
} from "../../../types/translationManagement";

import { DataItem } from "../../../types/dataItem";
import { ModalCheckbox } from "../../general/inputs/ModalCheckbox";
import { ModalIcon } from "../../general/inputs/ModalIcon";
import { ModalParams } from "../../../types/modalParams";
import { ModalSelectbox } from "../../general/inputs/ModalSelectbox";
import { Option } from "../../../types/option";
import { Scene } from "../../../constants/navigationTypes";
import { SceneSwitchButtons } from "../../general/scene/buttons/SceneSwitchButtons";
import { SettingsReadOnlyInput } from "../../general/inputs/SettingsReadonlyInput";
import { TableHeading } from "../../../types/tableHeading";

import { translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    globalOptionPrivilege: DataItem | null;
    userOptionPrivilege: DataItem | null;
};
// TODO not in use, need to be checked
export class OptionsScene extends Component<Props> {
    state: State = {
        globalOptionPrivilege:
            (this.props.dataPrivileges
                ? this.props.dataPrivileges.find((item: DataItem) => item.key === this.props.globalOptionConstant)
                : null) || null,
        userOptionPrivilege:
            (this.props.dataPrivileges
                ? this.props.dataPrivileges.find((item: DataItem) => item.key === this.props.userOptionConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.dataPrivileges) !== JSON.stringify(prevProps.dataPrivileges)) {
            this.setState({
                globalOptionPrivilege:
                    (this.props.dataPrivileges
                        ? this.props.dataPrivileges.find(
                              (item: DataItem) => item.key === this.props.globalOptionConstant
                          )
                        : null) || null,
                userOptionPrivilege:
                    (this.props.dataPrivileges
                        ? this.props.dataPrivileges.find((item: DataItem) => item.key === this.props.userOptionConstant)
                        : null) || null
            });
        }
    }

    generateTranslationModalHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_ACTION_TYPE,
            propertyConstants.PROPERTY_FILE_TYPE,
            propertyConstants.PROPERTY_LANGUAGE,
            propertyConstants.PROPERTY_VALUE
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_ACTION_TYPE,
            propertyConstants.PROPERTY_FILE_TYPE,
            propertyConstants.PROPERTY_LANGUAGE
        ];

        return generateTranslationManagementHeadings(
            objectTypes.TRANSLATION,
            propertyList,
            propertyList,
            propertyList,
            requiredList,
            propertyList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    handleOptionChange = (newValue: any, inputType: string, item: Option): void => {
        let key: string | null = null;
        let value: any = null;
        let locked: boolean = item.locked;

        switch (inputType) {
            case inputConstants.PARAMETER_TYPE_CHECK_BOX:
            case inputConstants.PARAMETER_TYPE_SELECT_BOX: {
                key = item.key;
                value = newValue;
                break;
            }
            case inputConstants.PARAMETER_TYPE_LOCK: {
                key = item.key;
                value = item.value;
                locked = newValue;
                break;
            }
            default:
                break;
        }

        const objectType =
            this.props.scene === navigationTypes.GLOBAL_OPTIONS_SCENE
                ? objectTypes.GLOBAL_OPTION
                : objectTypes.USER_OPTION;

        // Save option on server
        if (key !== null && key !== undefined && value !== null && value !== undefined) {
            const options = [
                {
                    key: key,
                    value: value,
                    locked: locked
                }
            ];
            this.props.setOptions(objectType, options, true);
        }
    };

    handleOpenTranslationModal = (): any => {
        this.props.openModal(
            modalTypes.MODAL_TRANSLATION_MANAGEMENT,
            new ModalParams(
                modalTypes.TRANSLATION_MODAL,
                objectTypes.TRANSLATION,
                translate("options.translations.manageTranslations"),
                createEmptyTranslationManagement(),
                this.generateTranslationModalHeadings()
            )
        );
    };

    getManageTranslationButton = (option: Option): JSX.Element | null => {
        if (
            optionHelper.isPrivilegeAvailable(
                this.props.privileges?.[privilegeConstants.PRIVILEGE_SETTINGS_MANAGE_TRANSLATIONS]
            ) &&
            option.key === optionsConstants.OPTION_LANGUAGE &&
            this.props.scene === navigationTypes.GLOBAL_OPTIONS_SCENE
        ) {
            return (
                <button className={"btn btn-info btn-md"} onClick={() => this.handleOpenTranslationModal()}>
                    {translate("options.translations.manageTranslations")}
                </button>
            );
        }
        return null;
    };

    getLockedOptionButton = (item: Option): JSX.Element | null => {
        if (this.props.scene === navigationTypes.GLOBAL_OPTIONS_SCENE) {
            return (
                <ModalIcon
                    objectType={objectTypes.GLOBAL_OPTION}
                    editable={true}
                    property={propertyConstants.PROPERTY_OVERWRITE}
                    value={!item.locked}
                    callback={(event: any, value: boolean): void => {
                        this.handleOptionChange(!value, inputConstants.PARAMETER_TYPE_LOCK, item);
                    }}
                />
            );
        }

        return null;
    };

    getLabel = (item: Option): JSX.Element | null => {
        const type: string = optionHelper.getOptionType(item.key);
        switch (type) {
            case inputConstants.PARAMETER_TYPE_SELECT_BOX:
            case inputConstants.PARAMETER_TYPE_TEXT_AREA:
            case inputConstants.PARAMETER_TYPE_TEXT_INPUT: {
                return (
                    <div className="setting-name">
                        <span>{translationHelper.getOptionTranslation(item.key)}</span>
                    </div>
                );
            }
            default:
                return null;
        }
    };

    getValueForSelectbox = (value: string, options: any): any => {
        for (const option of options) {
            if (option.key === value) {
                return option.value;
            }
        }
    };

    getOption = (item: Option): JSX.Element | null => {
        const type: string = optionHelper.getOptionType(item.key);
        const options = optionHelper.getOptionListForOptions(item.key);

        if (this.props.scene === navigationTypes.USER_OPTIONS_SCENE && item.locked) {
            const value =
                type === inputConstants.PARAMETER_TYPE_SELECT_BOX
                    ? this.getValueForSelectbox(item.value, options)
                    : item.value;
            return (
                <SettingsReadOnlyInput
                    className={"readonly-settings-container"}
                    objectType={objectTypes.USER_OPTION}
                    type={type}
                    value={value}
                    text={translationHelper.getOptionTranslation(item.key)}
                />
            );
        }
        switch (type) {
            case inputConstants.PARAMETER_TYPE_CHECK_BOX: {
                return (
                    <ModalCheckbox
                        className={"checkbox-with-title"}
                        editable={true}
                        required={true}
                        text={translationHelper.getOptionTranslation(item.key)}
                        value={item.value}
                        callback={(value: boolean): void => this.handleOptionChange(value, type, item)}
                    />
                );
            }
            case inputConstants.PARAMETER_TYPE_SELECT_BOX:
                return (
                    <ModalSelectbox
                        className={"scene-select"}
                        editable={true}
                        required={true}
                        value={item.value}
                        defaultCaption={null}
                        options={options}
                        autoselect={false}
                        callback={(event: any, value: string): void => this.handleOptionChange(value, type, item)}
                    />
                );
            default:
                return null;
        }
    };

    getOptions = (options: Array<Option>): JSX.Element => {
        const elements: Array<JSX.Element> = [];

        for (const item of options) {
            elements.push(
                <div className="settings-row" key={item.key}>
                    {this.getLockedOptionButton(item)}
                    {this.getLabel(item)}
                    {this.getOption(item)}
                    {this.getManageTranslationButton(item)}
                </div>
            );
        }

        return <div className="settings-container">{elements}</div>;
    };

    getGlobalOrUserData = (): Array<Option> | null => {
        const { scene, globalOptionList, userOptionList } = this.props;
        const data = [];

        switch (scene) {
            case navigationTypes.GLOBAL_OPTIONS_SCENE:
                for (const item of optionsConstants.VISIBLE_GLOBAL_OPTIONS) {
                    const option = globalOptionList.find((option) => option.key === item);
                    if (option) {
                        data.push(option);
                    }
                }
                return data;
            case navigationTypes.USER_OPTIONS_SCENE:
                for (const item of optionsConstants.VISIBLE_USER_OPTIONS) {
                    const option = userOptionList.find((option) => option.key === item);
                    if (option) {
                        data.push(option);
                    }
                }
                return data;
            default:
                return null;
        }
    };

    getSceneSwitch = (): JSX.Element => {
        const { scene } = this.props;
        const { globalOptionPrivilege, userOptionPrivilege } = this.state;

        const scenes: Array<{ scene: Scene; title: string; dataPrivileges: DataItem | null }> = [
            {
                scene: navigationTypes.USER_OPTIONS_SCENE,
                title: translate("options.userSettings"),
                dataPrivileges: userOptionPrivilege
            },
            {
                scene: navigationTypes.GLOBAL_OPTIONS_SCENE,
                title: translate("options.globalSettings"),
                dataPrivileges: globalOptionPrivilege
            }
        ];

        return (
            <SceneSwitchButtons
                scenes={scenes}
                activeScene={scene}
                setActiveSceneCallback={(scene: Scene): void => {
                    this.props.setActiveScene(scene);
                }}
            />
        );
    };

    render(): JSX.Element | null {
        const data = this.getGlobalOrUserData();

        if (data === null || data.length < 1) {
            return null;
        }

        return (
            <div className="content-layout">
                <div className="height-100">
                    <div className="scene-title">
                        {translationHelper.getTitleSectionTranslation(objectTypes.USER_OPTION, this.props.custom)}
                    </div>
                    {this.getSceneSwitch()}
                    {this.getOptions(data)}
                </div>
            </div>
        );
    }
}
