import * as constants from "../../constants/inputConstants";
import * as designHelper from "../../helpers/designHelper";
import * as formattingHelper from "../../helpers/formattingHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as objectTypeHelper from "../../helpers/objectTypeHelper";
import * as objectTypes from "../../constants/objectTypes";
import * as priceConstants from "../../constants/entityConstants/priceConstants";
import * as priceHelper from "../../helpers/priceHelper";
import * as propertyConstants from "../../constants/propertyConstants";
import * as propertyHelper from "../../helpers/propertyHelper";
import * as tableConstants from "../../constants/tableConstants";
import * as universalObjectActions from "../../actions/universalObjectActions";

import React, { Component } from "react";

import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { ModalInputButton } from "../general/modal/ModalInputButton";
import { ModalParams } from "../../types/modalParams";
import { ModalReadOnlyInput } from "../general/inputs/ModalReadOnlyInput";
import { ModalType } from "../../constants/modalTypes";
import { Price } from "../../types/price";
import { ReactSVG } from "react-svg";
import { Scene } from "../../constants/navigationTypes";

import { connect } from "react-redux";

import imgClose from "../../resources/img/icons/close.svg";

import { t as translate } from "react-i18nify";

type StateProps = {
    activeScene: Scene;
};

type DispatchProps = {
    editItem(objectType: objectTypes.ObjectType, params: any): any;
};

type ContainerProps = StateProps & DispatchProps;

type OwnProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type Props = ContainerProps & OwnProps;

type State = {
    object: Array<Price>;
};
// TODO check whole file
class PriceModal extends Component<Props, State> {
    state: State = {
        object: []
    };

    componentDidMount(): void {
        this.setState({ object: this.props.params.data || [] });

        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    handleModalKeyDown = (event: Record<string, any>): void => {
        const { closeModal, type } = this.props;

        if (event.key === keyCodes.ENTER && event.target.type !== "textarea") {
            this.handleConfirmClick();
        }

        if (event.key === keyCodes.ESCAPE) {
            closeModal(type);
        }
    };

    handleConfirmClick = (): void => {
        const data: any = {};

        for (const item of this.state.object) {
            data[item.key] = {
                ...item,
                [propertyConstants.PROPERTY_OVERWRITE]: !item[propertyConstants.PROPERTY_OVERWRITE]
            };
        }

        this.props.editItem(this.props.params.objectType, { data: data });
    };

    handleUpdatePrice = (item: Price, value: Price): void => {
        const { object } = this.state;
        const priceIndex = object.indexOf(item);

        if (priceIndex >= 0) {
            const newObject = [...object];
            newObject[priceIndex] = value;
            this.setState({ object: newObject });
        }
    };

    getModalClassName = (): string => {
        let className = "modal simple-modal price-modal";

        className += designHelper.getModalClassType(this.props.activeScene);
        if (objectTypeHelper.isObjectTypePriceGroup(this.props.params.objectType)) {
            className += " price-groups";
        }
        return className;
    };

    getInput = (property: propertyConstants.Property, type: string): JSX.Element | null => {
        const { objectType } = this.props.params;
        const { object } = this.state;
        const item = generalHelper.getObjectByKey(object, property);

        if (item === null) {
            return null;
        }

        const editable = objectTypeHelper.isObjectTypePriceGroup(objectType) ? item.canUpdate : true;
        const unit = priceHelper.getPriceUnit(
            item[propertyConstants.PROPERTY_KEY],
            this.props.params.currency?.[propertyConstants.PROPERTY_SYMBOL] || ""
        );
        const priceIndex = object.indexOf(item);

        let previousValue = priceIndex >= 0 ? object[priceIndex - 1]?.value || null : null;

        if (objectTypeHelper.isObjectTypePriceGroup(objectType)) {
            if (priceIndex === 0) {
                previousValue = 0;
            }
        }

        let value = item?.[propertyConstants.PROPERTY_VALUE] || null;

        if (propertyHelper.isPropertyVat(property)) {
            value = formattingHelper.formatPercentWithoutSign(formattingHelper.formatPercent(value));
        }

        if (item.id === `priceGroup_${object.length}`) {
            value = `[${translate("prices.maxValue")}]`;
        }

        switch (type) {
            case constants.PARAMETER_TYPE_BUTTON_INPUT: {
                return (
                    <ModalInputButton
                        objectType={objectType}
                        className={"form-input price-input"}
                        type={tableConstants.TABLE_TYPE_PRICE}
                        property={property}
                        editable={editable}
                        required={false}
                        value={value ? value.toString() : ""}
                        unit={unit}
                        object={item}
                        callbackInputChange={(value: Price): void => {
                            this.handleUpdatePrice(item, value);
                        }}
                        callbackInputBlur={(value: Price): void => {
                            this.handleUpdatePrice(item, value);
                        }}
                        callbackButton={(value: Price): void => {
                            this.handleUpdatePrice(item, value);
                        }}
                    />
                );
            }
            case constants.PARAMETER_TYPE_TEXT_READONLY: {
                return (
                    <ModalReadOnlyInput
                        className={"form-input"}
                        objectType={objectType}
                        value={previousValue?.toString() || ""}
                        type={tableConstants.TABLE_TYPE_STRING}
                        property={propertyConstants.PROPERTY_RANGE}
                        unit={unit}
                    />
                );
            }
            default:
                return null;
        }
    };

    getHeader = (): JSX.Element => {
        const { optionPriceCompanyCost, optionPriceDefinition, objectType } = this.props.params;
        const headerItems: Array<JSX.Element> = [];

        if (objectTypeHelper.isObjectTypePriceGroup(objectType)) {
            headerItems.push(
                <React.Fragment key="price-group-header">
                    <div className="header-item">{translate("prices.rangeFrom")}</div>
                    <div className="header-item">{translate("prices.rangeTo")}</div>
                </React.Fragment>
            );
        }

        if (objectType === objectTypes.SYSTEM_PRICE_GENERIC || objectType === objectTypes.ZONE_PRICE_GENERIC) {
            if (
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
            ) {
                headerItems.push(
                    <div className="header-item" key="purchase-header">
                        {translate("prices.propertyPurchasePrice")}
                    </div>
                );
            }

            if (
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
            ) {
                headerItems.push(
                    <div className="header-item" key="sell-header">
                        {translate("prices.propertySellPrice")}
                    </div>
                );
            }

            if (optionPriceCompanyCost) {
                headerItems.push(
                    <div className="header-item" key="cost-header">
                        {translate("prices.optionCompanyCost")}
                    </div>
                );
            }
        }

        return <div className="price-modal-header">{headerItems}</div>;
    };

    getContent = (): JSX.Element => {
        const { matrix, objectType, data } = this.props.params;
        const form = [];

        if (
            (objectType === objectTypes.SYSTEM_PRICE_GENERIC || objectType === objectTypes.ZONE_PRICE_GENERIC) &&
            matrix
        ) {
            for (const [row, columns] of Object.entries(matrix)) {
                const inputs = [];

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for (const column of columns) {
                    inputs.push(this.getInput(column, constants.PARAMETER_TYPE_BUTTON_INPUT));
                }

                const className = inputs.length > 1 ? "price-columns" : "price-column";
                form.push(
                    <div key={row} className="row">
                        <div className="form-label">
                            <label>{translate("prices." + row)}</label>
                        </div>
                        <div className={className}>{inputs}</div>
                    </div>
                );
            }
        }

        if (objectTypeHelper.isObjectTypePriceGroup(objectType)) {
            for (const item of data) {
                form.push(
                    <div key={item.key} className="row">
                        <div className="form-label">
                            <label>{item.name}</label>
                        </div>
                        <div className="price-columns">
                            {this.getInput(item.key, constants.PARAMETER_TYPE_TEXT_READONLY)}
                            <div className="form-input">
                                {this.getInput(item.key, constants.PARAMETER_TYPE_BUTTON_INPUT)}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <form
                onSubmit={(event): void => {
                    event.preventDefault();
                }}
            >
                {form}
            </form>
        );
    };

    getCancelButton = (): JSX.Element => {
        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    this.props.closeModal(this.props.type);
                }}
            >
                {translate("general.cancel")}
            </button>
        );
    };

    getConfirmButton = (): JSX.Element => {
        return (
            <button className="btn-info pull-right" onClick={(): void => this.handleConfirmClick()} autoFocus={true}>
                {translate("general.ok")}
            </button>
        );
    };

    render(): JSX.Element | null {
        const { type, closeModal, index } = this.props;

        return (
            <div className={this.getModalClassName()} style={{ zIndex: index }}>
                <header className="modal-header">
                    <div className="title">{this.props.params.title}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">
                    {this.getHeader()}
                    {this.getContent()}
                </div>
                <footer className="modal-footer">
                    {this.getConfirmButton()}
                    {this.getCancelButton()}
                </footer>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    activeScene: state.navigation.activeScene
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    editItem: (objectType: objectTypes.ObjectType, params: any): any =>
        dispatch(universalObjectActions.editItem(objectType, true, params))
});

export const PriceModalContainer = connect(mapStateToProps, mapDispatchToProps)(PriceModal);
