import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";

import { ModalLabelInputButtonContainer } from "../../general/modal/ModalLabelInputButton";

type Props = {
    modalId: number;
    objectType: objectTypes.ObjectType;
    property: propertyConstants.Property;
    editable: boolean;
    required: boolean;
    caption: string;
    value: any;
    type: tableConstants.TableType;
    callback?: (property: propertyConstants.Property, value: boolean) => any;
};

export class GeneralPropertyModal extends Component<Props> {
    getClassName = (): string => {
        let className = "modal-general-property";

        if (this.props.value) {
            className += " checked";
        }

        if (this.props.type === tableConstants.TABLE_TYPE_ALERT) {
            className += " alert";
        }

        return className;
    };

    getInputType = (): inputConstants.InputType | string => {
        switch (this.props.type) {
            case tableConstants.TABLE_TYPE_BOOLEAN:
                return inputConstants.PARAMETER_TYPE_CHECK_BOX;
            case tableConstants.TABLE_TYPE_ALERT:
                return inputConstants.PARAMETER_TYPE_TEXT_READONLY;
            default:
                return "";
        }
    };

    getInputClassName = (): string => {
        switch (this.props.type) {
            case tableConstants.TABLE_TYPE_BOOLEAN:
                return "checkbox-with-title";
            case tableConstants.TABLE_TYPE_ALERT:
                return "alert-container";
            default:
                return "";
        }
    };

    render(): JSX.Element | null {
        const { caption, editable, modalId, objectType, property, required, type, value, callback } = this.props;

        return (
            <div className={this.getClassName()}>
                <ModalLabelInputButtonContainer
                    modalId={modalId}
                    type={type}
                    objectType={objectType}
                    editable={editable}
                    required={required}
                    labelCaption={""}
                    inputClassName={this.getInputClassName()}
                    inputType={this.getInputType()}
                    inputName={property}
                    inputValue={value}
                    inputCaption={caption}
                    inputCallbackChange={(value: boolean): void =>
                        callback
                            ? callback(property, value)
                            : (): void => {
                                  return;
                              }
                    }
                />
            </div>
        );
    }
}
