import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { Barcode } from "../types/barcode";

export type BarcodeState = Readonly<{
    list: Array<Barcode>;
    activeList: Array<Barcode>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: BarcodeState = {
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function barcodes(state: BarcodeState = initialState, action: Record<string, any>): BarcodeState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.BARCODE) {
                return {
                    ...state,
                    list: action.payload.list,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.BARCODE) {
                const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.list);

                return {
                    ...state,
                    activeList: newActiveList
                };
            }

            return state;
        }
        case actionConstants.UPDATE_ITEM: {
            if (action.payload.objectType === objectTypes.BARCODE) {
                return {
                    ...state,
                    activeList: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.BARCODE) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.BARCODE) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.DATA_ROLLBACK:
        case actionConstants.EVENT_SAVE_DATABASES:
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
