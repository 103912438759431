import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as modalTypes from "../constants/modalTypes";

import { Epic, ofType } from "redux-observable";

import { navigationOpenModal } from "../actions/navigationActions";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";
import { t as translate } from "react-i18nify";

export const addItem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.RUN_STATIC_SQL),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            requests.push(
                navigationOpenModal(modalTypes.MODAL_FILE_RUN, { title: translate("file.runningStaticSql") })
            );
            requests.push(
                serverRequest(methods.METHOD_RUN_STATIC_SQL, methods.METHOD_RUN_STATIC_SQL, {
                    fileId: payload.params.fileId
                })
            );

            return requests;
        })
    );
