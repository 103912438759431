import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Barcode, mapBarcodes } from "./barcode";
import { Formula, mapFormulas } from "./formula";
import { FormulasStatus, mapFormulaStatus } from "./formulasStatus";

import { ColorData } from "./colorData";
import { TableHeading } from "./tableHeading";

export class Color {
    id: number;
    colorName: string;
    fandeckId: number;
    fandeckName: string | null;
    imageId: number | null;
    formulaList: Array<Formula>;
    spectroTypeId: number | null;
    data: Array<ColorData>;
    colorSearchName?: string | null;
    tmcName?: string | null;
    largoName?: string | null;
    colorCode?: string;
    colorSearchCode?: string | null;
    colorMoreInfo?: string | null;
    colorPriority?: number | null;
    r?: number | null;
    g?: number | null;
    b?: number | null;
    rgbHex?: string | null;
    fandeckPage?: number | null;
    fandeckPosition?: number | null;
    uuid?: string | null;
    barcodes?: Array<Barcode>;
    formulasStatus?: FormulasStatus | null;
    formulaId?: number | null;
    baseInProductId?: number | null;

    constructor(
        id: number,
        colorName: string,
        fandeckId: number,
        fandeckName: string | null,
        imageId: number | null,
        formulaList: Array<Formula>,
        spectroTypeId: number | null,
        data: Array<ColorData>,
        colorSearchName?: string | null,
        tmcName?: string | null,
        largoName?: string | null,
        colorCode?: string,
        colorSearchCode?: string | null,
        colorMoreInfo?: string | null,
        colorPriority?: number | null,
        r?: number | null,
        g?: number | null,
        b?: number | null,
        rgbHex?: string | null,
        fandeckPage?: number | null,
        fandeckPosition?: number | null,
        uuid?: string | null,
        barcodes?: Array<Barcode>,
        formulasStatus?: FormulasStatus | null,
        formulaId?: number | null,
        baseInProductId?: number | null
    ) {
        this.id = id;
        this.colorName = colorName;
        this.fandeckId = fandeckId;
        this.fandeckName = fandeckName;
        this.imageId = imageId;
        this.formulaList = formulaList;
        this.spectroTypeId = spectroTypeId;
        this.data = data;
        this.colorSearchName = colorSearchName;
        this.tmcName = tmcName;
        this.largoName = largoName;
        this.colorCode = colorCode;
        this.colorSearchCode = colorSearchCode;
        this.colorMoreInfo = colorMoreInfo;
        this.colorPriority = colorPriority;
        this.r = r;
        this.g = g;
        this.b = b;
        this.rgbHex = rgbHex;
        this.fandeckPage = fandeckPage;
        this.fandeckPosition = fandeckPosition;
        this.uuid = uuid;
        this.barcodes = barcodes;
        this.formulasStatus = formulasStatus;
        this.formulaId = formulaId;
        this.baseInProductId = baseInProductId;
    }
}

export function mapSimpleColor(data: Record<string, any>): Color | null {
    try {
        return new Color(
            data.color ? data.color.id : null,
            data.color ? data.color.name : null,
            data.fandeck ? data.fandeck.id : null,
            data.fandeck ? data.fandeck.name : null,
            null,
            [],
            null,
            []
        );
    } catch (e) {
        return null;
    }
}

export function mapColor(
    data: Record<string, any>,
    formulaId: number | null,
    baseInProductId: number | null
): Color | null {
    try {
        return new Color(
            data.id,
            data.color ? data.color.name : null,
            data.fandeckId,
            data.fandeck ? data.fandeck.name : null,
            data.imageId,
            [],
            null,
            [],
            data.color ? data.color.searchName : null,
            data.color ? data.color.tmcName : null,
            data.color && data.color.jsonAttributes && data.color.jsonAttributes.largoName
                ? data.color.jsonAttributes.largoName
                : null,
            data.color ? data.color.code : null,
            data.color ? data.color.searchCode : null,
            data.color ? data.color.moreInfo : null,
            data.color ? data.color.priority : null,
            data.r,
            data.g,
            data.b,
            data.rgb,
            data.page,
            data.pagePosition,
            data.uuid,
            mapBarcodes(data.barcodes),
            mapFormulaStatus(data.formulasStatus),
            formulaId ?? null,
            baseInProductId ?? null
        );
    } catch (e) {
        return null;
    }
}

export function cloneColor(
    data: Record<string, any>,
    colorData: Array<any> = [],
    spectroTypeId: number | null = null
): Color | null {
    try {
        return new Color(
            data.id,
            data.colorName,
            data.fandeckId,
            data.fandeckName,
            data.imageId,
            mapFormulas(data.formulaList),
            spectroTypeId,
            colorData,
            data.colorSearchName,
            data.tmcName,
            data.largoName,
            data.colorCode,
            data.colorSearchCode,
            data.colorMoreInfo,
            data.colorPriority,
            data.r,
            data.g,
            data.b,
            data.rgbHex,
            data.fandeckPage,
            data.fandeckPosition,
            data.uuid,
            mapBarcodes(data.barcodes),
            mapFormulaStatus(data.formulasStatus),
            data.formulaId,
            data.baseInProductId
        );
    } catch (e) {
        return null;
    }
}

export function updateColorRgb(data: Record<string, any>, rgb: Record<string, any>): any {
    try {
        return new Color(
            data.id,
            data.colorName,
            data.fandeckId,
            data.fandeckName,
            data.imageId,
            data.formulaList,
            data.spectroTypeId,
            data.data,
            data.colorSearchName,
            data.tmcName,
            data.largoName,
            data.colorCode,
            data.colorSearchCode,
            data.colorMoreInfo,
            data.colorPriority,
            rgb ? rgb.r : data.r,
            rgb ? rgb.g : data.g,
            rgb ? rgb.b : data.b,
            rgb ? rgb.hex : data.rgbHex,
            data.fandeckPage,
            data.fandeckPosition,
            data.uuid,
            data.barcodes,
            data.formulasStatus,
            data.formulaId,
            data.baseInProductId
        );
    } catch (e) {
        return null;
    }
}

export function updateFormulaList(
    data: Record<string, any>,
    formulaList: Array<Formula>,
    formulaId: number | null,
    baseInProductId: number | null
): any {
    try {
        return new Color(
            data.id,
            data.colorName,
            data.fandeckId,
            data.fandeckName,
            data.imageId,
            formulaList,
            data.spectroTypeId,
            data.data,
            data.colorSearchName,
            data.tmcName,
            data.largoName,
            data.colorCode,
            data.colorSearchCode,
            data.colorMoreInfo,
            data.colorPriority,
            data.r,
            data.g,
            data.b,
            data.rgbHex,
            data.fandeckPage,
            data.fandeckPosition,
            data.uuid,
            data.barcodes,
            data.formulasStatus,
            formulaId,
            baseInProductId
        );
    } catch (e) {
        return null;
    }
}

export function updateFormulaInList(color: Color | null, formula: Formula | null): Color | null {
    if (!color || !color.formulaList || !formula) {
        return color;
    }

    const newFormulaList = [...color.formulaList];
    const formulaIndex = newFormulaList.findIndex((item) => item.id === formula.id);

    if (formulaIndex !== -1 && newFormulaList[formulaIndex]) {
        newFormulaList[formulaIndex] = formula;
    } else {
        newFormulaList[newFormulaList.length] = formula;
    }

    try {
        return new Color(
            color.id,
            color.colorName,
            color.fandeckId,
            color.fandeckName,
            color.imageId,
            newFormulaList,
            color.spectroTypeId,
            color.data,
            color.colorSearchName,
            color.tmcName,
            color.largoName,
            color.colorCode,
            color.colorSearchCode,
            color.colorMoreInfo,
            color.colorPriority,
            color.r,
            color.g,
            color.b,
            color.rgbHex,
            color.fandeckPage,
            color.fandeckPosition,
            color.uuid,
            color.barcodes,
            color.formulasStatus,
            color.formulaId,
            color.baseInProductId
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyColor(fandeckId: number | null): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_FANDECK_ID]: fandeckId,
        [propertyConstants.PROPERTY_COLOR_NAME]: "",
        [propertyConstants.PROPERTY_COLOR_SEARCH_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_LARGO_NAME]: [],
        [propertyConstants.PROPERTY_COLOR_CODE]: "",
        [propertyConstants.PROPERTY_COLOR_SEARCH_CODE]: "",
        [propertyConstants.PROPERTY_COLOR_MORE_INFO]: "",
        [propertyConstants.PROPERTY_COLOR_PRIORITY]: "",
        [propertyConstants.PROPERTY_COLOR_RED]: "",
        [propertyConstants.PROPERTY_COLOR_GREEN]: "",
        [propertyConstants.PROPERTY_COLOR_BLUE]: "",
        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: "",
        [propertyConstants.PROPERTY_FANDECK_PAGE]: "",
        [propertyConstants.PROPERTY_FANDECK_POSITION]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_BARCODES]: [],
        [propertyConstants.PROPERTY_FORMULAS_STATUS]: "",
        [propertyConstants.PROPERTY_FORMULA_ID]: null,
        [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: null,
        [propertyConstants.PROPERTY_IMAGE_ID]: "",
        [propertyConstants.PROPERTY_FORMULA_LIST]: [],
        [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]: "",
        [propertyConstants.PROPERTY_DATA]: []
    };
}

export function generateColorData(list: Array<Color>): Array<any> {
    const data: Array<any> = [];
    let item: Color;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_FANDECK_ID]: item.fandeckId,
            [propertyConstants.PROPERTY_COLOR_NAME]: item.colorName,
            [propertyConstants.PROPERTY_COLOR_SEARCH_NAME]: item.colorSearchName,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_LARGO_NAME]: item.largoName,
            [propertyConstants.PROPERTY_COLOR_CODE]: item.colorCode,
            [propertyConstants.PROPERTY_COLOR_SEARCH_CODE]: item.colorSearchCode,
            [propertyConstants.PROPERTY_COLOR_MORE_INFO]: item.colorMoreInfo,
            [propertyConstants.PROPERTY_COLOR_PRIORITY]: item.colorPriority,
            [propertyConstants.PROPERTY_COLOR_RED]: item.r,
            [propertyConstants.PROPERTY_COLOR_GREEN]: item.g,
            [propertyConstants.PROPERTY_COLOR_BLUE]: item.b,
            [propertyConstants.PROPERTY_COLOR_RGB_HEX]: item.rgbHex,
            [propertyConstants.PROPERTY_FANDECK_PAGE]: item.fandeckPage,
            [propertyConstants.PROPERTY_FANDECK_POSITION]: item.fandeckPosition,
            [propertyConstants.PROPERTY_UUID]: item.uuid,
            [propertyConstants.PROPERTY_BARCODES]: item.barcodes,
            [propertyConstants.PROPERTY_FORMULAS_STATUS]: item.formulasStatus,
            [propertyConstants.PROPERTY_FORMULA_ID]: item.formulaId,
            [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]: item.baseInProductId,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.imageId,
            [propertyConstants.PROPERTY_FORMULA_LIST]: item.formulaList,
            [propertyConstants.PROPERTY_SPECTRO_TYPE_ID]: item.spectroTypeId,
            [propertyConstants.PROPERTY_DATA]: item.data
        });
    }

    return data;
}

export function generateColorHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            editableList.includes(propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            requiredList.includes(propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES),
            [],
            widthOption?.[propertyConstants.PROPERTY_ADDITIONAL_PROPERTIES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BARCODES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODES),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BARCODES),
            editableList.includes(propertyConstants.PROPERTY_BARCODES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BARCODES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BARCODES),
            requiredList.includes(propertyConstants.PROPERTY_BARCODES),
            [],
            widthOption?.[propertyConstants.PROPERTY_BARCODES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_BLUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_BLUE),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            editableList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_BLUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_BLUE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_CODE),
            editableList.includes(propertyConstants.PROPERTY_COLOR_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_CODE),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_DATA,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_DATA),
            tableConstants.TABLE_TYPE_LABEL,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_DATA),
            editableList.includes(propertyConstants.PROPERTY_COLOR_DATA),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_DATA),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_DATA),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_DATA),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_DATA] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_GREEN,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_GREEN),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            editableList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_GREEN),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_GREEN] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_COLOR_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_COLOR_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_RED,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_RED),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_RED),
            editableList.includes(propertyConstants.PROPERTY_COLOR_RED),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_RED),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_RED),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_RED),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_RED] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_RGB_HEX,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_RGB_HEX),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            editableList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_RGB_HEX),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_RGB_HEX] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_SEARCH_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            editableList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_SEARCH_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_SEARCH_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_SEARCH_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_SEARCH_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FANDECK_PAGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FANDECK_PAGE),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_FANDECK_PAGE),
            editableList.includes(propertyConstants.PROPERTY_FANDECK_PAGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FANDECK_PAGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FANDECK_PAGE),
            requiredList.includes(propertyConstants.PROPERTY_FANDECK_PAGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_FANDECK_PAGE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FANDECK_POSITION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FANDECK_POSITION),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_FANDECK_POSITION),
            editableList.includes(propertyConstants.PROPERTY_FANDECK_POSITION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FANDECK_POSITION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FANDECK_POSITION),
            requiredList.includes(propertyConstants.PROPERTY_FANDECK_POSITION),
            [],
            widthOption?.[propertyConstants.PROPERTY_FANDECK_POSITION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FORMULAS_STATUS,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FORMULAS_STATUS),
            tableConstants.TABLE_TYPE_STATUS,
            visibleList.includes(propertyConstants.PROPERTY_FORMULAS_STATUS),
            editableList.includes(propertyConstants.PROPERTY_FORMULAS_STATUS),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FORMULAS_STATUS),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FORMULAS_STATUS),
            requiredList.includes(propertyConstants.PROPERTY_FORMULAS_STATUS),
            [],
            widthOption?.[propertyConstants.PROPERTY_FORMULAS_STATUS] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LARGO_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            editableList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LARGO_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            requiredList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_LARGO_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_TMC_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            editableList.includes(propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_TMC_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            requiredList.includes(propertyConstants.PROPERTY_TMC_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_TMC_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
