import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";

import { Color, generateColorData, generateColorHeadings } from "../../../../types/color";
import React, { Component } from "react";

import { Fandeck } from "../../../../types/fandeck";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";

import { TableHeading } from "../../../../types/tableHeading";

type Props = {
    tableConstant: string;
    fandeckActiveList: Array<Fandeck>;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<Color>;
    activeList: Array<Color>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    useTmcNames: boolean;
};

export class ZoneColorTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLOR_CODE,
            propertyConstants.PROPERTY_COLOR_NAME
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_COLOR_NAME];
        let editableList: Array<propertyConstants.Property> = [];
        let visibleList: Array<propertyConstants.Property> = [];

        // Table properties
        if (modalType === null) {
            visibleList = [propertyConstants.PROPERTY_COLOR_CODE, propertyConstants.PROPERTY_COLOR_NAME];
            editableList = [];
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateColorHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {};
        const section: any = document.querySelector(".panel-section");
        const panelLayout = section ? section.offsetHeight + 39 : 0;

        return (
            <div className="height-100" style={{ height: "calc(100% - " + panelLayout + "px)" }}>
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings()}
                    data={generateColorData(this.props.allList)}
                    activeData={this.props.activeList}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
