import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/system/SystemRedlikeUserSceneContainer";
import { RedlikeUsersWithUserGroupsTable } from "../data/tables/RedlikeUsersWithUserGroupsTable";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { ZoneRedlikeUserTable } from "./tables/ZoneRedlikeUserTable";

type Props = PropsType;

export const ZoneRedlikeUserScene: FunctionComponent<Props> = ({ ...props }) => {
    // Table users
    const [tableUsers, setTableUsers] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.userTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableUsers(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.userTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.userTableConstant]);

    // Table users with user group
    const [tableUsersWithUserGroups, setTableUsersWithUserGroups] = useState<
        MenuItem | DataItem | OptionItem | PrivilegeItem | null
    >(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                      item.key === props.usersWithUserGroupsTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableUsersWithUserGroups(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.usersWithUserGroupsTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.usersWithUserGroupsTableConstant]);

    const getUsersTable = (): JSX.Element | null => {
        if (tableUsers) {
            return (
                <ZoneRedlikeUserTable
                    tableConstant={props.userTableConstant}
                    objectType={objectTypes.ZONE_REDLIKE_USER}
                    dataPrivileges={tableUsers}
                    loading={props.userLoading}
                    buttonLoading={props.userButtonLoading}
                    allList={props.userList}
                    activeList={props.userActiveList}
                    columnOrder={props.userColumnOrder}
                    columnVisibility={props.userColumnVisibility}
                    columnWidth={props.userColumnWidth}
                    offset={props.userOffset}
                    page={props.userPage}
                    rowCount={props.userRowCount}
                    rowCountCustom={props.userRowCountCustom}
                    search={props.userSearch}
                    searchParams={props.userSearchParams}
                    showFilterRow={props.userShowFilterRow}
                    showGlobalSearch={props.userShowGlobalSearch}
                    sortingAsc={props.userSortingAsc}
                    sortingCriterion={props.userSortingCriterion}
                    totalCount={props.userTotalCount}
                    custom={props.custom}
                />
            );
        }

        return null;
    };

    const getUsersWithUserGroupsTable = (): JSX.Element | null => {
        if (tableUsersWithUserGroups) {
            return (
                <RedlikeUsersWithUserGroupsTable
                    tableConstant={props.usersWithUserGroupsTableConstant}
                    objectType={objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS}
                    dataPrivileges={tableUsersWithUserGroups}
                    userDataPrivileges={tableUsers}
                    layout={props.sceneLayout}
                    loading={props.usersWithUserGroupsLoading}
                    allList={props.usersWithUserGroupsAllList}
                    userGroupList={props.userGroupList}
                    columnOrder={props.usersWithUserGroupsColumnOrder}
                    columnVisibility={props.usersWithUserGroupsColumnVisibility}
                    columnWidth={props.usersWithUserGroupsColumnWidth}
                />
            );
        }

        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={
                [getUsersTable(), getUsersWithUserGroupsTable()].filter((item) => item !== null) as Array<JSX.Element>
            }
            layout={props.sceneLayout}
            dataPrivileges={tableUsers}
            sceneTableSize={props.userSceneTableSize}
            sceneConstant={menuConstants.PAGE_ZONE_REDLIKE_USERS}
        />
    );
};
