import * as designHelper from "../../helpers/designHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as menuConstants from "../../constants/menuConstants";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";
import * as tableHelper from "../../helpers/tableHelper";

import React, { Component } from "react";
import { AppState } from "../../reducers";
import { Color } from "../../types/color";
import { ColorData } from "../../types/colorData";
import { DataItem } from "../../types/dataItem";
import { Dispatch } from "redux";
import { File } from "../../types/file";
import { FileCategory } from "../../types/fileCategory";
import { FileCategoryTable } from "./tables/FileCategoryTable";
import { FileTable } from "./tables/FileTable";
import { FileType } from "../../types/fileType";
import { FormulaNote } from "../../types/formulaNote";
import { FormulaNoteTable } from "./tables/FormulaNoteTable";
import { Image } from "../../types/image";
import { ImageCategory } from "../../types/imageCategory";
import { ImageCategoryTable } from "./tables/ImageCategoryTable";
import { ImageTable } from "./tables/ImageTable";
import { MenuItem } from "../../types/menu";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { OptionItem } from "../../types/optionItem";
import { PrivilegeItem } from "../../types/privilegeItem";
import { ReactSVG } from "react-svg";
import { Scene } from "../../constants/navigationTypes";
import { ServerRequest } from "../../types/serverRequest";
import { SpectroType } from "../../types/spectroType";
import { SpectroTypeTable } from "./tables/SpectroTypeTable";
import { WebZone } from "../../types/web/webZone";
import { WebZoneTable } from "./tables/WebZoneTable";

import { connect } from "react-redux";
import imgClose from "../../resources/img/icons/close.svg";
import { t as translate } from "react-i18nify";

type ContainerProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type OwnProps = PropsType & DispatchType;

type Props = ContainerProps & OwnProps;

class TableModal extends Component<Props> {
    handleConfirmClick = (): void => {
        const { type, params } = this.props;

        if (params.returnAction) {
            switch (type) {
                case modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE: {
                    params.returnAction(this.props.webZoneActiveList?.[0] || null);
                    break;
                }
                case modalTypes.MODAL_FILE_CATEGORIES_TABLE: {
                    params.returnAction(
                        this.props.fileCategoryActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || null,
                        this.props.fileCategoryAllList
                    );
                    break;
                }
                case modalTypes.MODAL_FILES_TABLE: {
                    params.returnAction(this.props.fileActiveList?.[0] || null);
                    break;
                }
                case modalTypes.MODAL_FORMULA_NOTES_TABLE: {
                    params.returnAction(
                        this.props.formulaNoteActiveList?.[0]?.[propertyConstants.PROPERTY_CODE] || null
                    );
                    break;
                }
                case modalTypes.MODAL_IMAGE_CATEGORIES_TABLE: {
                    params.returnAction(
                        this.props.imageCategoryActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || null,
                        this.props.imageCategoryAllList
                    );
                    break;
                }
                case modalTypes.MODAL_ICONS_TABLE:
                case modalTypes.MODAL_IMAGES_TABLE: {
                    params.returnAction(this.props.imageActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || null);
                    break;
                }
                case modalTypes.MODAL_SPECTRO_TYPES_TABLE: {
                    params.returnAction(
                        this.props.spectroTypeActiveList.length ? this.props.spectroTypeActiveList[0] : null
                    );
                    break;
                }
                default: {
                    break;
                }
            }
        }

        this.props.closeModal(type);
    };

    getModalClassName = (): string => {
        const { activeScene, type } = this.props;
        let className = "modal";

        if (type === modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE) {
            className += " simple-modal web-zone-modal";
        } else {
            className += " table-modal simple-modal";
        }

        className += designHelper.getModalClassType(activeScene);

        if (
            type === modalTypes.MODAL_FILE_CATEGORIES_TABLE ||
            type === modalTypes.MODAL_IMAGE_CATEGORIES_TABLE ||
            type === modalTypes.MODAL_SPECTRO_TYPES_TABLE
        ) {
            className += " category-modal";
        }

        return className;
    };

    getModalTitle = (): string => {
        switch (this.props.type) {
            case modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE:
                return translate("zone.availableZoneDatabase");
            case modalTypes.MODAL_FILE_CATEGORIES_TABLE:
                return translate("file.fileCategories");
            case modalTypes.MODAL_FILES_TABLE:
                return translate("file.selectProductFile");
            case modalTypes.MODAL_FORMULA_NOTES_TABLE:
                return translate("formulaNote.selectCode");
            case modalTypes.MODAL_ICONS_TABLE:
                return translate("image.selectIconFromExisting");
            case modalTypes.MODAL_IMAGE_CATEGORIES_TABLE:
                return translate("image.imageCategories");
            case modalTypes.MODAL_IMAGES_TABLE:
                return translate("image.selectImageFromExisting");
            case modalTypes.MODAL_SPECTRO_TYPES_TABLE:
                return translate("spectro.measurementTypes");
            default:
                return "";
        }
    };

    getTable = (): JSX.Element | null => {
        switch (this.props.type) {
            case modalTypes.MODAL_AVAILABLE_WEB_ZONES_TABLE:
                return this.getAvailableWebZonesTable();
            case modalTypes.MODAL_FILE_CATEGORIES_TABLE:
                return this.getFileCategoryTable();
            case modalTypes.MODAL_FILES_TABLE:
                return this.getFileTable();
            case modalTypes.MODAL_FORMULA_NOTES_TABLE:
                return this.getFormulaNotesTable();
            case modalTypes.MODAL_ICONS_TABLE:
                return this.getImageTable(true);
            case modalTypes.MODAL_IMAGE_CATEGORIES_TABLE:
                return this.getImageCategoryTable();
            case modalTypes.MODAL_IMAGES_TABLE:
                return this.getImageTable(false);
            case modalTypes.MODAL_SPECTRO_TYPES_TABLE:
                return this.getSpectroTypeTable();
            default:
                return null;
        }
    };

    getAvailableWebZonesTable = (): JSX.Element | null => {
        return (
            <WebZoneTable
                tableConstant={menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES}
                objectType={objectTypes.WEB_ZONE}
                dataPrivileges={this.props.webZoneDataPrivileges}
                loading={this.props.webZoneLoading}
                allList={this.props.webZoneList}
                activeList={this.props.webZoneActiveList}
                columnOrder={this.props.webZoneColumnOrder}
                columnVisibility={this.props.webZoneColumnVisibility}
                columnWidth={this.props.webZoneColumnWidth}
                showFilterRow={this.props.webZoneShowFilterRow}
                showGlobalSearch={this.props.webZoneShowGlobalSearch}
                search={this.props.webZoneSearch}
                searchParams={this.props.webZoneSearchParams}
                sortingAsc={this.props.webZoneSortingAsc}
                sortingCriterion={this.props.webZoneSortingCriterion}
            />
        );
    };

    getImageTable = (isIcon?: boolean): JSX.Element | null => {
        return (
            <ImageTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_IMAGES}
                objectType={isIcon ? objectTypes.ICON : objectTypes.IMAGE}
                dataPrivileges={this.props.imageDataPrivileges}
                loading={this.props.imageLoading}
                categoryList={isIcon ? [] : this.props.imageCategoryAllList}
                fileTypeList={this.props.imageTypeList}
                allList={this.props.imageList}
                activeList={this.props.imageActiveList}
                columnOrder={isIcon ? this.props.iconColumnOrder : this.props.imageColumnOrder}
                columnVisibility={isIcon ? this.props.iconColumnVisibility : this.props.imageColumnVisibility}
                columnWidth={isIcon ? this.props.iconColumnWidth : this.props.imageColumnWidth}
                offset={this.props.imageOffset}
                page={this.props.imagePage}
                rowCount={isIcon ? this.props.iconRowCount : this.props.imageRowCount}
                search={this.props.imageSearch}
                searchParams={this.props.imageSearchParams}
                sortingAsc={this.props.imageSortingAsc}
                sortingCriterion={this.props.imageSortingCriterion}
                totalCount={this.props.imageTotalCount}
            />
        );
    };

    getImageCategoryTable = (): JSX.Element | null => {
        return (
            <ImageCategoryTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_IMAGE_CATEGORIES}
                objectType={objectTypes.IMAGE_CATEGORY}
                dataPrivileges={this.props.imageCategoryDataPrivileges}
                loading={this.props.imageCategoryLoading}
                allList={this.props.imageCategoryList}
                activeList={this.props.imageCategoryActiveList}
                columnOrder={this.props.imageCategoryColumnOrder}
                columnVisibility={this.props.imageCategoryColumnVisibility}
                columnWidth={this.props.imageCategoryColumnWidth}
                offset={this.props.imageCategoryOffset}
                page={this.props.imageCategoryPage}
                rowCount={this.props.imageCategoryRowCount}
                search={this.props.imageCategorySearch}
                searchParams={this.props.imageCategorySearchParams}
                sortingAsc={this.props.imageCategorySortingAsc}
                sortingCriterion={this.props.imageCategorySortingCriterion}
                totalCount={this.props.imageCategoryTotalCount}
            />
        );
    };

    getFileTable = (): JSX.Element | null => {
        return (
            <FileTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_FILES}
                objectType={objectTypes.FILE}
                dataPrivileges={this.props.fileDataPrivileges}
                loading={this.props.fileLoading}
                categoryList={this.props.fileCategoryAllList}
                fileTypeList={this.props.fileTypeList}
                allList={this.props.fileList}
                activeList={this.props.fileActiveList}
                columnOrder={this.props.fileColumnOrder}
                columnVisibility={this.props.fileColumnVisibility}
                columnWidth={this.props.fileColumnWidth}
                offset={this.props.fileOffset}
                page={this.props.filePage}
                rowCount={this.props.fileRowCount}
                search={this.props.fileSearch}
                searchParams={this.props.fileSearchParams}
                sortingAsc={this.props.fileSortingAsc}
                sortingCriterion={this.props.fileSortingCriterion}
                totalCount={this.props.fileTotalCount}
                returnAction={(): void => {
                    return;
                }}
            />
        );
    };

    getSpectroTypeTable = (): JSX.Element | null => {
        const activeColorData = this.props.masterColorModalActiveList?.[0]?.[propertyConstants.PROPERTY_DATA] || [];
        const filteredSpectroTypeList = this.props.spectroTypeList.filter(
            (spectroType: SpectroType) =>
                !activeColorData.some(
                    (colorData: ColorData) =>
                        spectroType[propertyConstants.PROPERTY_ID] ===
                        colorData[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
                )
        );

        return (
            <SpectroTypeTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_SPECTRO_TYPES}
                objectType={objectTypes.SPECTRO_TYPE}
                dataPrivileges={this.props.spectroTypeDataPrivileges}
                loading={this.props.spectroTypeLoading}
                allList={filteredSpectroTypeList}
                activeList={this.props.spectroTypeActiveList}
                columnOrder={this.props.spectroTypeColumnOrder}
                columnVisibility={this.props.spectroTypeColumnVisibility}
                columnWidth={this.props.spectroTypeColumnWidth}
                offset={tableConstants.DEFAULT_OFFSET}
                page={tableConstants.DEFAULT_PAGE}
                rowCount={filteredSpectroTypeList.length}
                search={this.props.spectroTypeSearch}
                searchParams={this.props.spectroTypeSearchParams}
                sortingAsc={this.props.spectroTypeSortingAsc}
                sortingCriterion={this.props.spectroTypeSortingCriterion}
                totalCount={filteredSpectroTypeList.length}
            />
        );
    };

    getFileCategoryTable = (): JSX.Element | null => {
        return (
            <FileCategoryTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_FILE_CATEGORIES}
                objectType={objectTypes.FILE_CATEGORY}
                dataPrivileges={this.props.fileCategoryDataPrivileges}
                loading={this.props.fileCategoryLoading}
                allList={this.props.fileCategoryList}
                activeList={this.props.fileCategoryActiveList}
                columnOrder={this.props.fileCategoryColumnOrder}
                columnVisibility={this.props.fileCategoryColumnVisibility}
                columnWidth={this.props.fileCategoryColumnWidth}
                offset={this.props.fileCategoryOffset}
                page={this.props.fileCategoryPage}
                rowCount={this.props.fileCategoryRowCount}
                search={this.props.fileCategorySearch}
                searchParams={this.props.fileCategorySearchParams}
                sortingAsc={this.props.fileCategorySortingAsc}
                sortingCriterion={this.props.fileCategorySortingCriterion}
                totalCount={this.props.fileCategoryTotalCount}
            />
        );
    };

    getFormulaNotesTable = (): JSX.Element => {
        return (
            <FormulaNoteTable
                modalId={this.props.id}
                tableConstant={menuConstants.TABLE_MODAL_FORMULA_NOTES}
                objectType={objectTypes.MASTER_FORMULA_NOTE}
                dataPrivileges={this.props.formulaNoteDataPrivilege}
                loading={this.props.formulaNoteLoading}
                allList={this.props.formulaNoteList}
                activeList={this.props.formulaNoteActiveList}
                columnOrder={this.props.formulaNoteColumnOrder}
                columnVisibility={this.props.formulaNoteColumnVisibility}
                columnWidth={this.props.formulaNoteColumnWidth}
                offset={this.props.formulaNoteOffset}
                page={this.props.formulaNotePage}
                rowCount={this.props.formulaNoteRowCount}
                search={this.props.formulaNoteSearch}
                searchParams={this.props.formulaNoteSearchParams}
                sortingAsc={this.props.formulaNoteSortingAsc}
                sortingCriterion={this.props.formulaNoteSortingCriterion}
                totalCount={this.props.formulaNoteTotalCount}
            />
        );
    };

    render(): JSX.Element {
        const { type, closeModal } = this.props;

        return (
            <div
                className={this.getModalClassName()}
                id={`modal-${this.props.id}`}
                style={{ zIndex: this.props.index }}
            >
                <header className="modal-header">
                    <div className="title">{this.getModalTitle()}</div>
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">{this.getTable()}</div>
                <footer className="modal-footer">
                    <button className="btn-info pull-right" onClick={this.handleConfirmClick}>
                        {translate("general.ok")}
                    </button>
                    <button
                        className="btn-info pull-right"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        {translate("general.cancel")}
                    </button>
                </footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    activeScene: Scene;
    // color
    masterColorModalActiveList: Array<Color>;
    // image
    imageDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    imageLoading: boolean;
    imageCategoryAllList: Array<ImageCategory>;
    imageTypeList: Array<FileType>;
    imageList: Array<Image>;
    imageActiveList: Array<Image>;
    imageColumnOrder: Array<propertyConstants.Property>;
    imageColumnVisibility: Record<propertyConstants.Property, boolean>;
    imageColumnWidth: Record<propertyConstants.Property, number>;
    imageOffset: number;
    imagePage: number;
    imageRowCount: number;
    imageSearch: string | null;
    imageSearchParams: Record<propertyConstants.Property, string>;
    imageSortingAsc: boolean;
    imageSortingCriterion: propertyConstants.Property;
    imageTotalCount: number | null;
    // icon
    iconColumnOrder: Array<propertyConstants.Property>;
    iconColumnVisibility: Record<propertyConstants.Property, boolean>;
    iconColumnWidth: Record<propertyConstants.Property, number>;
    iconRowCount: number;
    // image category
    imageCategoryDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    imageCategoryLoading: boolean;
    imageCategoryList: Array<ImageCategory>;
    imageCategoryActiveList: Array<ImageCategory>;
    imageCategoryColumnOrder: Array<propertyConstants.Property>;
    imageCategoryColumnVisibility: Record<propertyConstants.Property, boolean>;
    imageCategoryColumnWidth: Record<propertyConstants.Property, number>;
    imageCategoryOffset: number;
    imageCategoryPage: number;
    imageCategoryRowCount: number;
    imageCategorySearch: string | null;
    imageCategorySearchParams: Record<propertyConstants.Property, string>;
    imageCategorySortingAsc: boolean;
    imageCategorySortingCriterion: propertyConstants.Property;
    imageCategoryTotalCount: number | null;
    // file
    fileDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    fileLoading: boolean;
    fileCategoryAllList: Array<FileCategory>;
    fileTypeList: Array<FileType>;
    fileList: Array<File>;
    fileActiveList: Array<File>;
    fileColumnOrder: Array<propertyConstants.Property>;
    fileColumnVisibility: Record<propertyConstants.Property, boolean>;
    fileColumnWidth: Record<propertyConstants.Property, number>;
    fileOffset: number;
    filePage: number;
    fileRowCount: number;
    fileSearch: string | null;
    fileSearchParams: Record<propertyConstants.Property, string>;
    fileSortingAsc: boolean;
    fileSortingCriterion: propertyConstants.Property;
    fileTotalCount: number | null;
    // file category
    fileCategoryDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    fileCategoryLoading: boolean;
    fileCategoryList: Array<FileCategory>;
    fileCategoryActiveList: Array<FileCategory>;
    fileCategoryColumnOrder: Array<propertyConstants.Property>;
    fileCategoryColumnVisibility: Record<propertyConstants.Property, boolean>;
    fileCategoryColumnWidth: Record<propertyConstants.Property, number>;
    fileCategoryOffset: number;
    fileCategoryPage: number;
    fileCategoryRowCount: number;
    fileCategorySearch: string | null;
    fileCategorySearchParams: Record<propertyConstants.Property, string>;
    fileCategorySortingAsc: boolean;
    fileCategorySortingCriterion: propertyConstants.Property;
    fileCategoryTotalCount: number | null;
    // formulaNote
    formulaNoteLoading: boolean;
    formulaNoteDataPrivilege: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    formulaNoteList: Array<FormulaNote>;
    formulaNoteActiveList: Array<FormulaNote>;
    formulaNoteColumnOrder: Array<propertyConstants.Property>;
    formulaNoteColumnVisibility: Record<propertyConstants.Property, boolean>;
    formulaNoteColumnWidth: Record<propertyConstants.Property, number>;
    formulaNoteOffset: number;
    formulaNotePage: number;
    formulaNoteRowCount: number;
    formulaNoteSearch: string | null;
    formulaNoteSearchParams: Record<propertyConstants.Property, string>;
    formulaNoteSortingAsc: boolean;
    formulaNoteSortingCriterion: propertyConstants.Property | null;
    formulaNoteTotalCount: number | null;
    // spectro
    spectroTypeDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    spectroTypeLoading: boolean;
    spectroTypeList: Array<SpectroType>;
    spectroTypeActiveList: Array<SpectroType>;
    spectroTypeColumnOrder: Array<propertyConstants.Property>;
    spectroTypeColumnVisibility: Record<propertyConstants.Property, boolean>;
    spectroTypeColumnWidth: Record<propertyConstants.Property, number>;
    spectroTypeOffset: number;
    spectroTypePage: number;
    spectroTypeRowCount: number;
    spectroTypeSearch: string | null;
    spectroTypeSearchParams: Record<propertyConstants.Property, string>;
    spectroTypeSortingAsc: boolean;
    spectroTypeSortingCriterion: propertyConstants.Property;
    spectroTypeTotalCount: number | null;
    // web zone
    webZoneDataPrivileges: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
    webZoneLoading: boolean;
    webZoneList: Array<WebZone>;
    webZoneActiveList: Array<WebZone>;
    webZoneSearch: string | null;
    webZoneSearchParams: Record<propertyConstants.Property, string>;
    webZoneSortingAsc: boolean;
    webZoneSortingCriterion: propertyConstants.Property | null;
    webZoneColumnOrder: Array<propertyConstants.Property>;
    webZoneColumnVisibility: Record<propertyConstants.Property, boolean>;
    webZoneColumnWidth: Record<propertyConstants.Property, number>;
    webZoneShowFilterRow: boolean;
    webZoneShowGlobalSearch: boolean;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene,
    // color
    masterColorModalActiveList: state.color.masterModalActiveList,
    // image
    imageDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_IMAGES
    ),
    imageCategoryAllList: state.imageCategory.allList,
    imageTypeList: state.image.imageFileTypeList,
    imageList: state.image.list,
    imageActiveList: state.image.activeList,
    imageTotalCount: state.image.count,
    imageOffset: state.image.offset,
    imagePage: state.image.page,
    imageSearch: state.image.search,
    imageSearchParams: state.image.searchParams,
    imageSortingAsc: state.image.sortingAsc,
    imageSortingCriterion: state.image.sortingCriterion,
    imageRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_IMAGES
    ),
    imageColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_IMAGES
    ),
    imageColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_IMAGES
    ),
    imageColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_IMAGES
    ),
    // icon
    iconRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_ICONS
    ),
    iconColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_ICONS
    ),
    iconColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_ICONS
    ),
    iconColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_ICONS
    ),
    // image category
    imageCategoryDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_GENERIC_IMAGES
    ),
    imageCategoryLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_IMAGE_CATEGORIES
    ),
    imageCategoryList: state.imageCategory.list,
    imageCategoryActiveList: state.imageCategory.activeList,
    imageCategoryTotalCount: state.imageCategory.count,
    imageCategoryOffset: state.imageCategory.offset,
    imageCategoryPage: state.imageCategory.page,
    imageCategorySearch: state.imageCategory.search,
    imageCategorySearchParams: state.imageCategory.searchParams,
    imageCategorySortingAsc: state.imageCategory.sortingAsc,
    imageCategorySortingCriterion: state.imageCategory.sortingCriterion,
    imageCategoryRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_IMAGE_CATEGORIES
    ),
    imageCategoryColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_IMAGE_CATEGORIES
    ),
    imageCategoryColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_IMAGE_CATEGORIES
    ),
    imageCategoryColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_IMAGE_CATEGORIES
    ),
    // file
    fileDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    fileLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_PRODUCT_SHEETS
    ),
    fileCategoryAllList: state.fileCategory.allList,
    fileTypeList: state.file.productSheetFileTypeList,
    fileList: state.file.fileList,
    fileActiveList: state.file.fileActiveList,
    fileTotalCount: state.file.fileCount,
    fileOffset: state.file.fileOffset,
    filePage: state.file.filePage,
    fileSearch: state.file.fileSearch,
    fileSearchParams: state.file.fileSearchParams,
    fileSortingAsc: state.file.fileSortingAsc,
    fileSortingCriterion: state.file.fileSortingCriterion,
    fileRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_FILES
    ),
    fileColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_FILES
    ),
    fileColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_FILES
    ),
    fileColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_FILES
    ),
    // file category
    fileCategoryDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_GENERIC_PRODUCT_SHEETS
    ),
    fileCategoryLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_FILE_CATEGORIES
    ),
    fileCategoryList: state.fileCategory.list,
    fileCategoryActiveList: state.fileCategory.activeList,
    fileCategoryTotalCount: state.fileCategory.count,
    fileCategoryOffset: state.fileCategory.offset,
    fileCategoryPage: state.fileCategory.page,
    fileCategorySearch: state.fileCategory.search,
    fileCategorySearchParams: state.fileCategory.searchParams,
    fileCategorySortingAsc: state.fileCategory.sortingAsc,
    fileCategorySortingCriterion: state.fileCategory.sortingCriterion,
    fileCategoryRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_FILE_CATEGORIES
    ),
    fileCategoryColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_FILE_CATEGORIES
    ),
    fileCategoryColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_FILE_CATEGORIES
    ),
    fileCategoryColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_FILE_CATEGORIES
    ),
    // formula note
    formulaNoteDataPrivilege: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_MASTER_FORMULA_NOTES
    ),
    formulaNoteLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_MASTER_FORMULA_NOTES
    ),
    formulaNoteList: state.formulaNote.masterList,
    formulaNoteActiveList: state.formulaNote.masterActiveList,
    formulaNoteTotalCount: state.formulaNote.masterCount,
    formulaNoteOffset: state.formulaNote.masterOffset,
    formulaNotePage: state.formulaNote.masterPage,
    formulaNoteSearch: state.formulaNote.masterSearch,
    formulaNoteSearchParams: state.formulaNote.masterSearchParams,
    formulaNoteSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_FORMULA_NOTES
        )
    ),
    formulaNoteSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_FORMULA_NOTES
        )
    ),
    formulaNoteColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_FORMULA_NOTES
    ),
    formulaNoteColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_FORMULA_NOTES
    ),
    formulaNoteColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_FORMULA_NOTES
    ),
    formulaNoteRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_FORMULA_NOTES
    ),
    // spectro type
    spectroTypeDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_MASTER_COLORS
    ),
    spectroTypeLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SPECTRO_TYPES
    ),
    spectroTypeList: state.spectroType.allList,
    spectroTypeActiveList: state.spectroType.activeList,
    spectroTypeTotalCount: state.spectroType.count,
    spectroTypeOffset: state.spectroType.offset,
    spectroTypePage: state.spectroType.page,
    spectroTypeSearch: state.spectroType.search,
    spectroTypeSearchParams: state.spectroType.searchParams,
    spectroTypeSortingAsc: state.spectroType.sortingAsc,
    spectroTypeSortingCriterion: state.spectroType.sortingCriterion,
    spectroTypeRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MODAL_SPECTRO_TYPES
    ),
    spectroTypeColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_SPECTRO_TYPES
    ),
    spectroTypeColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_SPECTRO_TYPES
    ),
    spectroTypeColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_SPECTRO_TYPES
    ),
    // web zone
    webZoneDataPrivileges: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_DATA_ITEM,
        menuConstants.TABLE_GLOBAL_ZONES
    ),
    webZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY_NO_PARAMS
    ),
    webZoneList: state.zone.webAllList,
    webZoneActiveList: state.zone.webActiveList,
    webZoneSearch: state.zone.webSearch,
    webZoneSearchParams: state.zone.webSearchParams,
    webZoneSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
        )
    ),
    webZoneSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
        )
    ),
    webZoneColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
    ),
    webZoneColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
    ),
    webZoneColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
    ),
    webZoneShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
    ),
    webZoneShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MODAL_AVAILABLE_WEB_ZONES
    )
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableModalContainer = connect(mapStateToProps, mapDispatchToProps)(TableModal);
