import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class ProductGroup {
    id: number;
    parentId: number;
    name: string;
    nameSystem: string;
    nameSource: number;
    priority: number;
    prioritySystem: number;
    prioritySource: number;
    moreInfo: string;
    moreInfoSystem: string;
    moreInfoSource: number;

    constructor(
        id: number,
        parentId: number,
        name: string,
        nameSystem: string,
        nameSource: number,
        priority: number,
        prioritySystem: number,
        prioritySource: number,
        moreInfo: string,
        moreInfoSystem: string,
        moreInfoSource: number
    ) {
        this.id = id;
        this.parentId = parentId;
        this.name = name;
        this.nameSystem = nameSystem;
        this.nameSource = nameSource;
        this.priority = priority;
        this.prioritySystem = prioritySystem;
        this.prioritySource = prioritySource;
        this.moreInfo = moreInfo;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
    }
}

export function mapProductGroup(data: Record<string, any>): ProductGroup | null {
    try {
        return new ProductGroup(
            data.id,
            data.parentId,
            data.name,
            data.nameSystem,
            data.nameSource,
            data.priority,
            data.prioritySystem,
            data.prioritySource,
            data.moreInfo,
            data.moreInfoSystem,
            data.moreInfoSource
        );
    } catch (e) {
        return null;
    }
}

export function updateProductGroup(
    oldProductGroup: ProductGroup | null,
    updatedData: Record<string, any>
): ProductGroup | null {
    try {
        if (oldProductGroup === null) {
            return null;
        }

        return new ProductGroup(
            oldProductGroup.id,
            oldProductGroup.parentId,
            updatedData.name ? updatedData.name : oldProductGroup.name,
            oldProductGroup.nameSystem,
            oldProductGroup.nameSource,
            updatedData.priority ? updatedData.priority : oldProductGroup.priority,
            oldProductGroup.prioritySystem,
            oldProductGroup.prioritySource,
            updatedData.moreInfo ? updatedData.moreInfo : oldProductGroup.moreInfo,
            oldProductGroup.moreInfoSystem,
            oldProductGroup.moreInfoSource
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyProductGroup(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: ""
    };
}

export function generateProductGroupData(list: Array<ProductGroup>): Array<any> {
    const data: Array<any> = [];
    let item: ProductGroup;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_NAME_SYSTEM]: item.nameSystem,
            [propertyConstants.PROPERTY_NAME_SOURCE]: item.nameSource,
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_PRIORITY_SYSTEM]: item.prioritySystem,
            [propertyConstants.PROPERTY_PRIORITY_SOURCE]: item.prioritySource,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource
        });
    }

    return data;
}

export function generateProductGroupHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
