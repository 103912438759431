import * as defaultConstants from "../constants/default";
import * as licenseConstants from "../constants/license";
import * as notificationActions from "../actions/notificationActions";
import * as serverConstants from "../constants/serverConstants";

import { FlashMessage } from "../types/flashMessage";
import { NavigationSaveFlashMessage } from "../actions/notificationActions";
import { t as translate } from "react-i18nify";

// TODO check while file

// TODO check is response is {} or null or undef
const getServerResponseMessage = (response: Record<string, any>): string => {
    if (response && response.msg) {
        return `${translate("flashMessage.serverError")}: ${response.msg}`;
    }

    return translate("flashMessage.defaultMessage");
};

export const getFlashMessage = (response: Record<string, any>): NavigationSaveFlashMessage => {
    let componentType: string;
    let flashMessage: string;

    switch (response?.result || null) {
        case serverConstants.RESULT_INVALID_JSON_INPUT:
            flashMessage = translate("flashMessage.invalidJsonInput", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_COMMAND_TYPE_MISSING:
            flashMessage = translate("flashMessage.missingCommandType", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_PARAMETER_VALIDATION_FAILED:
            flashMessage = translate("flashMessage.failedParameterValidation", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_UNKNOWN_COMMAND:
            flashMessage = translate("flashMessage.unknownCommand", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_BASE64_INPUT:
            flashMessage = translate("flashMessage.base64Input", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_CLIENT_CONNECTION_REJECTED:
            flashMessage = translate("flashMessage.clientConnectionRejected", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_CLIENT_NOT_LOGGED_IN:
            flashMessage = translate("flashMessage.clientNotLoggedIn", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_CLIENT_SESSION_NOT_INITIALIZED:
            flashMessage = translate("flashMessage.clientSessionNotInitialized", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_INVALID_LICENSE:
            flashMessage = translate("flashMessage.invalidLicenseKey", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_LICENSE_IDENTIFICATION_KEYS:
            flashMessage = translate("flashMessage.invalidLicenseIdentificationKeys", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_LICENSE_WEB_API_ERROR:
            flashMessage = translate("flashMessage.licenseWebApiError", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_DATABASE_CONFIG_FILE_MISSING:
            flashMessage = translate("flashMessage.missingDatabaseConfigFile", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_LOGIN_FAILED:
            flashMessage = translate("flashMessage.loginError", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_LOGOUT_FAILED:
            flashMessage = translate("flashMessage.logoutError", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_USER_ALREADY_LOGGED_IN:
            flashMessage = translate("flashMessage.userAlreadyLoggedIn", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_DUPLICATE_ENTRY:
            flashMessage = translate("flashMessage.duplicateEntry", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_EMAIL:
            flashMessage = translate("flashMessage.invalidEmail", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_ITEM_NOT_FOUND:
            flashMessage = translate("flashMessage.itemNotFound", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_UNSUPPORTED_TYPE:
            flashMessage = translate("flashMessage.unsupportedType", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_USER_CHANGE_PASSWORD_FAILED:
            flashMessage = translate("flashMessage.userChangePasswordFailed", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_DATABASE_AUTOLOGIN_FAILED:
            flashMessage = translate("flashMessage.databaseAutologinFailed", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_BARCODE_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundBarcode", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_DATABASE_NAME:
            flashMessage = translate("flashMessage.invalidDatabaseName", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_REDLIKE_DATA_NOT_AVAILABLE:
            flashMessage = translate("flashMessage.dataNotAvailable", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_DATABASE_NOT_COMPATIBLE:
            flashMessage = translate("flashMessage.databaseNotCompatible", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_DATABASE_ERROR:
            flashMessage = translate("flashMessage.databaseError", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_SERVER_ALREADY_DEFINED:
            flashMessage = translate("flashMessage.serverAlreadyDefined", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_CANNOT_CONNECT_TO_SERVER:
            flashMessage = translate("flashMessage.cannotConnectToServer", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_INVALID_SERVER:
            flashMessage = translate("flashMessage.invalidServer", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_SHORT_USER_PASSWORD:
            flashMessage = translate("flashMessage.shortUserPassword", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_USER_PASSWORD:
            flashMessage = translate("flashMessage.invalidUserPassword", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_DUPLICATE_BARCODE:
            flashMessage = translate("flashMessage.duplicateBarcode", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_ITEM_NOT_EDITABLE:
            flashMessage = translate("flashMessage.itemNotEditable", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_ITEM:
            flashMessage = translate("flashMessage.invalidItem", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_VALUE:
            flashMessage = translate("flashMessage.invalidValue", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_FORMULA_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundFormula", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_MALFORMED_FORMULA:
            flashMessage = translate("flashMessage.malformedFormula", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_BIPP_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundBipp", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_CUSTOMER_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundCustomer", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_INVALID_NAME:
            flashMessage = translate("flashMessage.invalidName", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_ITEM_NOT_DELETABLE:
            flashMessage = translate("flashMessage.itemNotDeletable", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_ORDER_HAS_ALREADY_BEEN_ACQUIRED:
            flashMessage = translate("flashMessage.orderAlreadyAcquired", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_ITEM_ALREADY_EXISTS:
            flashMessage = translate("flashMessage.itemAlreadyExists", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_FILE_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundFile", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_DIRECTORY_NOT_FOUND:
            flashMessage = translate("flashMessage.notFoundDirectory", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_METHOD:
            flashMessage = translate("flashMessage.invalidMethod", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_FILE_ID:
            flashMessage = translate("flashMessage.invalidFileId", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_IMAGE:
            flashMessage = translate("flashMessage.invalidImage", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_IMPORT_GENERIC:
            flashMessage = translate("flashMessage.importErrorGeneric", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_FILE_MISSING:
            flashMessage = translate("flashMessage.importErrorFileMissing", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_DATA_MISSING:
            flashMessage = translate("flashMessage.importErrorDataMissing", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_UNKNOWN_FORMAT:
            flashMessage = translate("flashMessage.importErrorGeneric", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_ABORTED:
            flashMessage = translate("flashMessage.importErrorAborted", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_UNDER_PROGRESS:
            flashMessage = translate("flashMessage.importErrorUnderProgress", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_HEADER_MISSING:
            flashMessage = translate("flashMessage.importErrorHeaderMissing", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_UNKNOWN_ERROR:
            flashMessage = getServerResponseMessage(response);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_DATABASE_NOT_FOUND:
            flashMessage = translate("flashMessage.databaseNotFound", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_ERROR_ON_OPENING_DATABASE:
            flashMessage = translate("flashMessage.databaseErrorOnOpening", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_UNKNOWN_OPTION:
            flashMessage = translate("flashMessage.unknownOption", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_NO_DATABASE_SELECTED:
            flashMessage = translate("flashMessage.noDatabaseSelected", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_NO_SYSTEM_SELECTED:
            flashMessage = translate("flashMessage.noSystemSelected", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_NO_ZONE_SELECTED:
            flashMessage = translate("flashMessage.noZoneSelected", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_INVALID_FILE_UPLOAD_UUID:
            flashMessage = translate("flashMessage.invalidFileUploadUuid", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case serverConstants.RESULT_IMPORT_TASK_MANDATORY_FILE:
            flashMessage = translate("flashMessage.importTaskMandatoryFile", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        case serverConstants.RESULT_IMPORT_TASK_MANDATORY_OPTION:
            flashMessage = translate("flashMessage.importTaskMandatoryOption", response.msgParams);
            componentType = defaultConstants.FLASH_MESSAGE_WARNING;
            break;
        default:
            flashMessage = getServerResponseMessage(response);
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
    }

    return notificationActions.saveFlashMessage(new FlashMessage(componentType, flashMessage));
};

export const getInfoMessageAction = (messageKey: string): NavigationSaveFlashMessage => {
    return notificationActions.saveFlashMessage(
        new FlashMessage(defaultConstants.FLASH_MESSAGE_INFO, translate(messageKey))
    );
};

export const getSuccessMessageAction = (messageKey: string): NavigationSaveFlashMessage => {
    return notificationActions.saveFlashMessage(
        new FlashMessage(defaultConstants.FLASH_MESSAGE_SUCCESS, translate(messageKey))
    );
};

export const getWarningMessageAction = (messageKey: string): NavigationSaveFlashMessage => {
    return notificationActions.saveFlashMessage(
        new FlashMessage(defaultConstants.FLASH_MESSAGE_WARNING, translate(messageKey))
    );
};

export const getErrorMessageAction = (messageKey: string): NavigationSaveFlashMessage => {
    return notificationActions.saveFlashMessage(
        new FlashMessage(defaultConstants.FLASH_MESSAGE_ERROR, translate(messageKey))
    );
};

export const getInvalidFormatMessage = (response: Record<string, any>): NavigationSaveFlashMessage => {
    if (response && response.result === serverConstants.RESULT_OK) {
        return notificationActions.saveFlashMessage(
            new FlashMessage(defaultConstants.FLASH_MESSAGE_ERROR, translate("flashMessage.invalidServerResponse"))
        );
    }

    return getFlashMessage(response);
};

export const getUpdateLicenseMessage = (response: Record<string, any>): NavigationSaveFlashMessage => {
    let componentType: string;
    let flashMessage: string;

    switch (response.data) {
        case licenseConstants.LICENSE_MESSAGE_ERROR_ON_SAVING_LICENSE:
            flashMessage = translate("flashMessage.licenseErrorOnSavingLicense");
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case licenseConstants.LICENSE_MESSAGE_INVALID:
            flashMessage = translate("flashMessage.licenseInvalid");
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case licenseConstants.LICENSE_MESSAGE_NO_CONNECTION_TO_LICENSE_SERVER:
            flashMessage = translate("flashMessage.licenseNoConnectionToLicenseServer");
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        case licenseConstants.LICENSE_MESSAGE_UPDATED:
            flashMessage = translate("flashMessage.licenseUpdated");
            componentType = defaultConstants.FLASH_MESSAGE_SUCCESS;
            break;
        case licenseConstants.LICENSE_MESSAGE_VALID_NO_UPDATE_NEEDED:
            flashMessage = translate("flashMessage.licenseValidNoUpdateNeeded");
            componentType = defaultConstants.FLASH_MESSAGE_INFO;
            break;
        case licenseConstants.LICENSE_MESSAGE_VERIFICATION_FAILED:
            flashMessage = translate("flashMessage.licenseVerificationFailed");
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
        default:
            flashMessage = translate("flashMessage.defaultMessage");
            componentType = defaultConstants.FLASH_MESSAGE_ERROR;
            break;
    }

    return notificationActions.saveFlashMessage(new FlashMessage(componentType, flashMessage));
};
