import * as menuConstants from "../constants/menuConstants";
import * as translationHelper from "../helpers/translationHelper";

import { DataItem, mapDataItem } from "./dataItem";
import { OptionItem, mapOptionItem } from "./optionItem";
import { PrivilegeItem, mapPrivilegeItem } from "./privilegeItem";

export class MenuItem {
    type: string;
    translateText: string;
    key: string;
    items: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>;

    constructor(
        type: string,
        defaultText: string,
        translateText: string,
        items: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>,
        custom: any
    ) {
        this.type = type;
        this.translateText = translationHelper.getMenuTranslation(translateText, defaultText, custom);
        this.key = translateText;
        this.items = items;
    }
}

export function mapMenu(
    data: Array<any>,
    custom: string | null = null
): Array<MenuItem | DataItem | OptionItem | PrivilegeItem> {
    const menu = [];

    for (const item of data) {
        let menuItem = null;

        if (
            item.type === menuConstants.MENU_HEADER_ITEM ||
            item.type === menuConstants.MENU_MAIN_PAGE ||
            item.type === menuConstants.MENU_SUBMENU_ITEM
        ) {
            menuItem = mapMenuItem(item, custom);
        }

        if (item.type === menuConstants.MENU_DATA_ITEM) {
            menuItem = mapDataItem(item);
        }

        if (item.type === menuConstants.MENU_OPTION_ITEM || item.type === menuConstants.MENU_USER_OPTION_ITEM) {
            menuItem = mapOptionItem(item);
        }

        if (item.type === menuConstants.MENU_PRIVILEGE_ITEM) {
            menuItem = mapPrivilegeItem(item);
        }

        if (menuItem !== null) {
            menu.push(menuItem);
        }
    }

    return menu;
}

export function mapMenuItem(data: Record<string, any>, custom: string | null): MenuItem | null {
    try {
        return new MenuItem(data.type, data.defaultText, data.translateText, mapMenu(data.items, custom), custom);
    } catch (e) {
        return null;
    }
}
