import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import { SystemZone, generateSystemZoneData, generateSystemZoneHeadings } from "../../../../types/systemZone";

import { ExportType } from "../../../../types/exportType";
import { ExportTypeOptions } from "../../../../types/export/exportTypeOptions";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<SystemZone>;
    activeList: Array<SystemZone>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property;
    totalCount: number | null;
    modalType: modalTypes.ModalType | null;
    exportTaskType: ExportType | null;
};

export class SystemZoneTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        let visibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_CHECKED,
            propertyConstants.PROPERTY_SYSTEM_NAME,
            propertyConstants.PROPERTY_ZONE_NAME,
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME,
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            propertyConstants.PROPERTY_CHANGELOG,
            propertyConstants.PROPERTY_UUID
        ];
        let editableList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_CHECKED,
            propertyConstants.PROPERTY_COLORS_WITHOUT_FORMULAS,
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME,
            propertyConstants.PROPERTY_ZONE_PASSWORD,
            propertyConstants.PROPERTY_CHANGELOG
        ];
        const requiredList: Array<propertyConstants.Property> = [];
        const alwaysVisibleList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_IS_CHECKED,
            propertyConstants.PROPERTY_SYSTEM_NAME,
            propertyConstants.PROPERTY_ZONE_NAME
        ];

        // Remove properties which should not be visible according to export task type options
        for (const [property, visibility] of Object.entries(
            this.props.exportTaskType?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? {}
        )) {
            if (!visibility) {
                visibleList = visibleList.filter((item) => item !== property);
                editableList = editableList.filter((item) => item !== property);
            }
        }

        let orderList = [...visibleList];

        if (modalType === null || modalType === modalTypes.MODAL_DATA_EXPORT_ALL) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateSystemZoneHeadings(
            this.props.objectType,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateVersionEditModalHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_VERSION,
            propertyConstants.PROPERTY_VERSION_NAME
        ];

        return generateSystemZoneHeadings(
            this.props.objectType,
            propertyList,
            propertyList,
            propertyList,
            propertyList,
            propertyList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateVersionEditModalParams = (): ModalParams | null => {
        const activeData = this.props.allList.find((item: SystemZone) => item[propertyConstants.PROPERTY_IS_CHECKED]);

        if (activeData) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                objectTypes.VERSION,
                translate("zone.editVersion"),
                activeData,
                this.generateVersionEditModalHeadings(),
                undefined,
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges
            );
        }

        return null;
    };

    generateChangelogEditModalHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_CHANGELOG];

        return generateSystemZoneHeadings(
            this.props.objectType,
            propertyList,
            propertyList,
            propertyList,
            propertyList,
            propertyList,
            tableConstants.EMPTY_COLUMN_WIDTH
        );
    };

    generateChangelogEditModalParams = (): ModalParams | null => {
        const activeData = this.props.allList.find((item: SystemZone) => item[propertyConstants.PROPERTY_IS_CHECKED]);

        if (activeData) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                objectTypes.CHANGELOG,
                translate("zone.editChangelog"),
                activeData,
                this.generateChangelogEditModalHeadings(),
                undefined,
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges
            );
        }

        return null;
    };

    getModalProperties = (): ModalProperties => {
        let modalProperties: ModalProperties = {};

        const exportTypeOptions: ExportTypeOptions | null =
            this.props.exportTaskType?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? null;

        if (
            exportTypeOptions?.[propertyConstants.PROPERTY_VERSION] ||
            exportTypeOptions?.[propertyConstants.PROPERTY_VERSION_NAME]
        ) {
            modalProperties = {
                ...modalProperties,
                editSecondModalType: modalTypes.MODAL_VERSION_EDIT,
                editSecondModalParams: this.generateVersionEditModalParams()
            };
        }

        if (exportTypeOptions?.[propertyConstants.PROPERTY_CHANGELOG]) {
            modalProperties = {
                ...modalProperties,
                editModalType: modalTypes.MODAL_CHANGELOG_EDIT,
                editModalParams: this.generateChangelogEditModalParams()
            };
        }

        return modalProperties;
    };

    render(): JSX.Element {
        const additionalData = {
            [propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS]:
                this.props.exportTaskType?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? null
        } as Record<propertyConstants.Property, any>;

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings(this.props.modalType ?? null)}
                    data={generateSystemZoneData(this.props.allList)}
                    activeData={this.props.activeList}
                    additionalData={additionalData}
                    disableColumnResizing={true}
                    modalProperties={this.getModalProperties()}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={false}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
