export class ImportFile {
    key: string;
    name: string;
    fileType: string;
    fileExtensions: Array<string>;
    dialogLabel: string;
    caption: string | null;
    mandatory: boolean;
    files: any;

    constructor(
        key: string,
        name: string,
        fileType: string,
        fileExtensions: Array<string>,
        dialogLabel: string,
        caption: string | null,
        mandatory: boolean,
        files: any
    ) {
        this.key = key;
        this.name = name;
        this.fileType = fileType;
        this.fileExtensions = fileExtensions;
        this.dialogLabel = dialogLabel;
        this.caption = caption;
        this.mandatory = mandatory;
        this.files = files;
    }
}

export function mapImportFile(data: Record<string, any>): ImportFile | null {
    try {
        return new ImportFile(
            data.key,
            data.name,
            data.fileType,
            data.fileExtensions,
            data.dialogLabel,
            data.caption,
            data.mandatory,
            null
        );
    } catch (e) {
        return null;
    }
}

export function mapImportFiles(data: Record<string, any>): Array<ImportFile> {
    const files = [];

    for (const item of Object.values(data)) {
        const file = mapImportFile(item);

        if (file !== null) {
            files.push(file);
        }
    }

    return files;
}
