import * as generalHelper from "../../../helpers/generalHelper";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as serverConstants from "../../../constants/serverConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { TableHeading, updateUniversalValueTableType } from "../../../types/tableHeading";

import { AppState } from "../../../reducers";
import { Currency } from "../../../types/currency";
import { Dispatch } from "redux";
import { ModalProperties } from "../../../types/modalProperties";
import { Price } from "../../../types/price";
import { System } from "../../../types/system";
import { TableCellButtonContainer as TableCellButton } from "./cells/TableCellButton";
import { TableCellCheckboxContainer as TableCellCheckbox } from "./cells/TableCellCheckbox";
import { TableCellDateContainer as TableCellDate } from "./cells/TableCellDate";
import { TableCellFileContainer as TableCellFile } from "./cells/TableCellFile";
import { TableCellIcon } from "./cells/TableCellIcon";
import { TableCellImagePreviewContainer as TableCellImagePreview } from "./cells/TableCellImagePreview";
import { TableCellImageSectionContainer as TableCellImageSection } from "./cells/TableCellImageSection";
import { TableCellNumberContainer as TableCellNumber } from "./cells/TableCellNumber";
import { TableCellPercentContainer as TableCellPercent } from "./cells/TableCellPercent";
import { TableCellPriceContainer as TableCellPrice } from "./cells/TableCellPrice";
import { TableCellRadioIcon } from "./cells/TableCellRadioIcon";
import { TableCellSelectContainer as TableCellSelect } from "./cells/TableCellSelect";
import { TableCellStatusContainer as TableCellStatus } from "./cells/TableCellStatus";
import { TableCellTextContainer as TableCellText } from "./cells/TableCellText";
import { Zone } from "../../../types/zone";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    key: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    activeContent: string;
    currency?: Currency | null;
    modalProperties: ModalProperties;
    isCellActive: boolean;
    isRowFirst: boolean;
    isRowFocused: boolean;
    isRowLast: boolean;
    isSourceChanged: boolean;
    callbackBlur: (
        event: any,
        value: any,
        rowId?: number | string,
        headingId?: propertyConstants.Property,
        price?: Price
    ) => any;
    callbackChange: (isValid: boolean, value: string) => any;
    callbackFocus: (value: string) => any;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCell extends Component<Props> {
    isCellEditable = (): boolean => {
        const { objectType, item } = this.props;
        const property = this.props.heading[tableConstants.TABLE_HEADING_NAME];
        let isEditable = true;

        if (
            objectType === objectTypes.SYSTEM_PRICE_GROUP ||
            objectType === objectTypes.UNIT ||
            objectType === objectTypes.MASTER_REDLIKE_USER_GROUP ||
            objectType === objectTypes.USER_GROUP ||
            objectType === objectTypes.ZONE_PRICE_GROUP ||
            objectTypeHelper.isObjectTypeRedlikeUser(objectType)
        ) {
            isEditable = item[propertyConstants.PROPERTY_CAN_UPDATE];
        }

        if (
            objectTypeHelper.isObjectTypeExportImport(objectType) &&
            property === propertyConstants.PROPERTY_IS_CHECKED
        ) {
            isEditable = item?.[propertyConstants.PROPERTY_CAN_DELETE] ?? false;
        }

        if (item[propertyConstants.PROPERTY_DISABLED]) {
            isEditable = false;
        }

        if (
            objectType === objectTypes.SYSTEM_PRODUCT_DUPLICATE &&
            this.props.heading[tableConstants.TABLE_HEADING_TYPE] !== tableConstants.TABLE_TYPE_BOOLEAN &&
            this.props.activeSystem &&
            item[propertyConstants.PROPERTY_ID] === this.props.activeSystem[propertyConstants.PROPERTY_ID]
        ) {
            isEditable = false;
        }

        if (
            objectType === objectTypes.ZONE_PRODUCT_DUPLICATE &&
            this.props.heading[tableConstants.TABLE_HEADING_TYPE] !== tableConstants.TABLE_TYPE_BOOLEAN &&
            this.props.activeZone &&
            item[propertyConstants.PROPERTY_ID] === this.props.activeZone[propertyConstants.PROPERTY_ID]
        ) {
            isEditable = false;
        }

        if (objectType === objectTypes.PACKAGE && property === propertyConstants.PROPERTY_IS_GRAVIMETRIC) {
            isEditable = false;
        }

        return isEditable;
    };

    //  Method that helps to decide TABLE_TYPE_UNIVERSAL types and updates necessary parameters - used for Redlike options
    getHeading = (): TableHeading | null => {
        const { item, heading } = this.props;

        if (heading[tableConstants.TABLE_HEADING_TYPE] !== tableConstants.TABLE_TYPE_UNIVERSAL) {
            return heading;
        }

        const newDataType = generalHelper.getDataType(item, false);
        const newEnum =
            newDataType === tableConstants.TABLE_TYPE_SELECT && item[propertyConstants.PROPERTY_SETTINGS]
                ? item[propertyConstants.PROPERTY_SETTINGS]
                : [];

        return updateUniversalValueTableType(
            heading,
            newDataType ? newDataType : heading[tableConstants.TABLE_HEADING_TYPE],
            item[propertyConstants.PROPERTY_DATA_TYPE] === serverConstants.DATA_TYPE_DICTIONARY ||
                serverConstants.DATA_TYPES_LIST.includes(item[propertyConstants.PROPERTY_DATA_TYPE])
                ? false
                : heading[tableConstants.TABLE_HEADING_EDITING],
            newEnum
        );
    };

    render(): JSX.Element | null {
        const {
            objectType,
            dataPrivileges,
            heading,
            item,
            activeContent,
            currency,
            modalProperties,
            isCellActive,
            isRowFirst,
            isRowFocused,
            isRowLast,
            isSourceChanged,
            callbackBlur,
            callbackChange,
            callbackFocus,
            callbackMouseEnter,
            callbackMouseLeave
        } = this.props;

        const updatedHeading = this.getHeading();
        const editable = this.isCellEditable();

        if (updatedHeading === null) {
            return null;
        }

        switch (updatedHeading[tableConstants.TABLE_HEADING_TYPE]) {
            case tableConstants.TABLE_TYPE_BOOLEAN: {
                return (
                    <TableCellCheckbox
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        isEditable={editable}
                        callbackBlur={(
                            event: any,
                            value: any,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property
                        ): void => callbackBlur(event, value, rowId, headingId)}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_BUTTON: {
                return (
                    <TableCellButton
                        objectType={objectType}
                        heading={updatedHeading}
                        item={item}
                        callbackMouseEnter={(event: any, value: string): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_DATE: {
                return (
                    <TableCellDate
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        content={activeContent}
                        isActive={isCellActive && isRowFocused}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_INTEGER:
            case tableConstants.TABLE_TYPE_NUMBER: {
                return (
                    <TableCellNumber
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        content={activeContent}
                        isActive={isCellActive && isRowFocused}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        currency={currency}
                        callbackBlur={(event: any, value: number | null): void => callbackBlur(event, value)}
                        callbackChange={(isValid: boolean, value: string): void => callbackChange(isValid, value)}
                        callbackFocus={(value: string): void => callbackFocus(value)}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_FILE: {
                return (
                    <TableCellFile
                        heading={updatedHeading}
                        item={item}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_ICON: {
                return (
                    <TableCellIcon
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        className={"td-center"}
                        callbackBlur={(
                            event: any,
                            value: boolean,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property
                        ): void => callbackBlur(event, value, rowId, headingId)}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_IMAGE: {
                return <TableCellImagePreview heading={updatedHeading} item={item} />;
            }
            case tableConstants.TABLE_TYPE_IMAGE_SECTION: {
                return (
                    <TableCellImageSection
                        heading={updatedHeading}
                        item={item}
                        callbackMouseEnter={(event: any, value: JSX.Element | null): void =>
                            callbackMouseEnter(event, value)
                        }
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_PERCENT: {
                return (
                    <TableCellPercent
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        content={activeContent}
                        isActive={isCellActive && isRowFocused}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        callbackBlur={(event: any, value: string | null): void => callbackBlur(event, value)}
                        callbackChange={(isValid: boolean, value: any): void => callbackChange(isValid, value)}
                        callbackFocus={(value: any): void => callbackFocus(value)}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_PRICE: {
                return (
                    <TableCellPrice
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        content={activeContent}
                        currency={currency}
                        isActive={isCellActive && isRowFocused}
                        isEditable={editable}
                        isRowFirst={isRowFirst}
                        isRowLast={isRowLast}
                        isSourceChanged={isSourceChanged}
                        callbackBlur={(
                            event: any,
                            value: any,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property,
                            price?: Price
                        ): void => callbackBlur(event, value, rowId, headingId, price)}
                        callbackChange={(isValid: boolean, value: any): void => callbackChange(isValid, value)}
                        callbackFocus={(value: any): void => callbackFocus(value)}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_RADIO_ICON: {
                return (
                    <TableCellRadioIcon
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={heading}
                        item={item}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        className="radio-icon-container"
                        callbackBlur={(
                            event: any,
                            value: number,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property
                        ): void => callbackBlur(event, value, rowId, headingId)}
                        callbackMouseEnter={(event: any, value: string): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_SELECT: {
                return (
                    <TableCellSelect
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        callbackBlur={(
                            event: any,
                            value: any,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property
                        ): void => callbackBlur(event, value, rowId, headingId)}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_STATUS: {
                return (
                    <TableCellStatus
                        heading={heading}
                        item={item}
                        callbackMouseEnter={(event: any, value: string): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_STRING:
            case tableConstants.TABLE_TYPE_TEXT: {
                return (
                    <TableCellText
                        objectType={objectType}
                        dataPrivileges={dataPrivileges}
                        heading={updatedHeading}
                        item={item}
                        content={activeContent}
                        modalProperties={modalProperties}
                        isActive={isCellActive && isRowFocused}
                        isEditable={editable}
                        isSourceChanged={isSourceChanged}
                        callbackBlur={(event: any, value: string | null): void => callbackBlur(event, value)}
                        callbackChange={(isValid: boolean, value: string): void => callbackChange(isValid, value)}
                        callbackFocus={(value: string): void => callbackFocus(value)}
                        callbackMouseEnter={(event: any, value: any): void => callbackMouseEnter(event, value)}
                        callbackMouseLeave={(): void => callbackMouseLeave()}
                    />
                );
            }
            default: {
                const property = updatedHeading[tableConstants.TABLE_HEADING_NAME];

                return <div className={`td td-edit ${property}`} key={property}></div>;
            }
        }
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{
    activeSystem: System | null;
    activeZone: Zone | null;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    activeSystem: state.system.active,
    activeZone: state.zone.active
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellContainer = connect(mapStateToProps, mapDispatchToProps)(TableCell);
