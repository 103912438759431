import * as licenseActions from "../actions/licenseActions";
import * as methods from "../constants/serverMethods";

import { AppState } from "../reducers";
import { Dispatch } from "redux";
import { License } from "../components/License";
import { LicenseProduct } from "../types/licensing/licenseProduct";
import { License as LicenseType } from "../types/licensing/license";
import { ServerRequest } from "../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    license: LicenseType | null;
    loading: boolean;
    onlineActivation: boolean;
    processStep: number;
    productList: Array<LicenseProduct>;
}>;

export type DispatchType = Readonly<{
    setProcessStep(step: number): void;
    getLicenseOnlineActivationAvailability(): void;
    updateLicense(params: Record<string, any>): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    license: state.license.activeLicense,
    loading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_ACTIVATE_ONLINE_LICENSE
    ),
    onlineActivation: state.license.onlineActivation,
    processStep: state.license.processStep,
    productList: state.license.productList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setProcessStep: (step: number): any => dispatch(licenseActions.setStep(step)),
    getLicenseOnlineActivationAvailability: (): any =>
        dispatch(licenseActions.getLicenseOnlineActivationAvailability()),
    updateLicense: (params: Record<string, any>): any => dispatch(licenseActions.updateLicense(params))
});

export const LicenseContainer = connect(mapStateToProps, mapDispatchToProps)(License);
