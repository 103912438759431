import * as objectTypes from "../constants/objectTypes";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Option } from "./option";
import { TableHeading } from "./tableHeading";
import { User } from "./user";

export class Company {
    id: number;
    name: string;
    companyKey: string;

    constructor(id: number, name: string, companyKey: string) {
        this.id = id;
        this.name = name;
        this.companyKey = companyKey;
    }
}

export function mapCompany(
    data: Record<string, any>,
    loggedUser: User | null,
    optionCompaniesForUsers: Option | null
): Company | null {
    try {
        const userId = loggedUser?.[propertyConstants.PROPERTY_ID] || null;
        const companyId = userId
            ? optionCompaniesForUsers?.[propertyConstants.PROPERTY_VALUE]?.[userId.toString()] || null
            : null;

        if (companyId !== null && companyId !== data.id && !privilegesHelper.isSuperadmin(loggedUser)) {
            return null;
        }

        return new Company(data.id, data.name, data.companyKey);
    } catch (e) {
        return null;
    }
}

export function updateCompany(oldCompany: Company | null, updatedData: Record<string, any>): Company | null {
    try {
        if (oldCompany === null) {
            return null;
        }

        return new Company(
            oldCompany.id,
            updatedData.name ? updatedData.name : oldCompany.name,
            updatedData.companyKey ? updatedData.companyKey : oldCompany.companyKey
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyCompany(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_COMPANY_KEY]: ""
    };
}

export function generateCompanyData(companyList: Array<Company>): Array<any> {
    const data: Array<any> = [];
    let company: Company;

    for (company of companyList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: company.id,
            [propertyConstants.PROPERTY_NAME]: company.name,
            [propertyConstants.PROPERTY_COMPANY_KEY]: company.companyKey
        });
    }

    return data;
}

export function generateCompanyHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_COMPANY_KEY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPANY_KEY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            editableList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            requiredList.includes(propertyConstants.PROPERTY_COMPANY_KEY),
            [],
            widthOption?.[propertyConstants.PROPERTY_COMPANY_KEY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
