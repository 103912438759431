import * as databaseActions from "../../../../actions/databaseActions";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as privilegeConstants from "../../../../constants/privilegeConstants";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as systemActions from "../../../../actions/systemActions";
import * as zoneActions from "../../../../actions/zoneActions";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
    dataPrivileges: any;
};

type Props = OwnProps & ContainerProps;

export class TableLoadDataButton extends Component<Props> {
    isVisible = (): boolean => {
        const { objectType, activeList } = this.props;

        if (objectType === objectTypes.ZONE || objectType === objectTypes.DATABASE) {
            return true;
        }

        if (
            objectType === objectTypes.STATIC_SQL_PREVIEW &&
            activeList.length &&
            optionHelper.isPrivilegeAvailable(this.props.privileges?.[privilegeConstants.PRIVILEGE_STATIC_SQL_RUN])
        ) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { systemActiveList, zoneActiveList, activeList } = this.props;

        if (
            (this.props.objectType === objectTypes.ZONE && systemActiveList.length > 0 && zoneActiveList.length > 0) ||
            activeList.length
        ) {
            return true;
        }

        return false;
    };

    handleLoadDataClick = (): void => {
        const { zoneActiveList, selectZone, objectType, activeList, selectDatabase, dataChanged, runStaticSqlFile } =
            this.props;

        switch (objectType) {
            case objectTypes.DATABASE: {
                selectDatabase(activeList[0][propertyConstants.PROPERTY_ID], !dataChanged);
                break;
            }
            case objectTypes.STATIC_SQL_PREVIEW: {
                runStaticSqlFile(activeList[0][propertyConstants.PROPERTY_ID]);
                break;
            }
            case objectTypes.ZONE: {
                selectZone(zoneActiveList[0][propertyConstants.PROPERTY_ID], !dataChanged);

                break;
            }
            default:
                break;
        }
    };

    getLoadButtonCaption = (): string => {
        const { objectType } = this.props;

        switch (objectType) {
            case objectTypes.DATABASE:
                return translate("database.loadDatabase");
            case objectTypes.STATIC_SQL_PREVIEW:
                return translate("general.run");
            case objectTypes.ZONE:
                return translate("general.reloadData");
            default:
                return "";
        }
    };

    getClassName = (): string => {
        let className = "btn-md btn-info";

        if (privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges)) {
            className += " margin-left";
        }

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className={this.getClassName()} onClick={(): void => this.handleLoadDataClick()}>
                    {this.getLoadButtonCaption()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getClassName()}>{this.getLoadButtonCaption()}</button>;
        }

        return null;
    }
}

export type PropsType = Readonly<{
    privileges: Record<string, number>;
    systemActiveList: Array<System>;
    zoneActiveList: Array<Zone>;
    dataChanged: boolean;
}>;

export type DispatchType = Readonly<{
    runStaticSqlFile(fileId: number): void;
    selectDatabase(id: number, confirmed: boolean): void;
    selectSystem(id: number, confirmed: boolean): void;
    selectZone(id: number, confirmed: boolean): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    privileges: state.login.privileges,
    dataChanged: state.navigation.dataChanged,
    systemActiveList: state.system.activeList,
    zoneActiveList: state.zone.activeList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    runStaticSqlFile: (fileId: number): any => dispatch(databaseActions.runStaticSqlFile(fileId)),
    selectDatabase: (id: number, confirmed: boolean): any => dispatch(databaseActions.setActiveDatabase(id, confirmed)),
    selectSystem: (id: number, confirmed: boolean): any => dispatch(systemActions.setActiveSystem(id, confirmed)),
    selectZone: (id: number, confirmed: boolean): any => dispatch(zoneActions.setActiveZone(id, confirmed))
});

export const TableLoadDataButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableLoadDataButton);
