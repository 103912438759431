import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import {
    HistoricalAction,
    generateHistoricalActionData,
    generateHistoricalActionHeadings,
    generateHistoricalActionSections,
    generateHistoricalActionTypes
} from "../../../../types/historicalAction";
import React, { Component } from "react";

import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    allList: Array<HistoricalAction>;
    activeList: Array<HistoricalAction>;
    loading: boolean;
    dateFrom: Date | null;
    dateTo: Date | null;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    custom: any;
};

export class UserActionsHistoryTable extends Component<Props> {
    generateTableHeadings = (modalType: modalTypes.ModalType | null = null): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_ACTION_METHOD,
            propertyConstants.PROPERTY_ACTION_TYPE_ID,
            propertyConstants.PROPERTY_ITEM_TYPE_ID,
            propertyConstants.PROPERTY_LEVEL,
            propertyConstants.PROPERTY_USERNAME,
            propertyConstants.PROPERTY_DATE
        ];
        const requiredList: Array<propertyConstants.Property> = [...propertyList];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_ITEM_TYPE_ID];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateHistoricalActionHeadings(
            this.props.objectType,
            generateHistoricalActionSections(this.props.custom),
            generateHistoricalActionTypes(),
            orderList,
            visibleList,
            [],
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateDisplayModalParams = (): ModalParams | null => {
        const activeData = generateHistoricalActionData(this.props.activeList, this.props.custom);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.PREVIEW_MODAL,
                this.props.objectType,
                translate("user.userActionDetail"),
                activeData[0],
                this.generateTableHeadings(modalTypes.MODAL_USER_ACTION_HISTORY)
            );
        }

        return null;
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            previewModalType: modalTypes.MODAL_USER_ACTION_HISTORY,
            previewModalParams: this.generateDisplayModalParams()
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    headings={this.generateTableHeadings()}
                    data={generateHistoricalActionData(this.props.allList, this.props.custom)}
                    activeData={this.props.activeList}
                    dateFrom={this.props.dateFrom}
                    dateTo={this.props.dateTo}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    modalProperties={modalProperties}
                />
            </div>
        );
    }
}
