import * as actionConstants from "../constants/actionTypes";
import * as eventResponseService from "../services/serverEventResponseService";
import * as licenseActions from "../actions/licenseActions";
import * as loginActions from "../actions/loginActions";
import * as responseService from "../services/serverResponseService";
import * as serverActions from "../actions/serverActions";
import * as serverConstants from "../constants/serverConstants";
import * as websocketActions from "../actions/websocketActions";

import { Epic, ofType } from "redux-observable";

import { ServerRequest } from "../types/serverRequest";
import { mergeMap } from "rxjs/operators";

export const websocketResponse: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.SERVER_WEBSOCKET_RESPONSE),
        mergeMap(({ payload }) => {
            let response = null;
            try {
                response = JSON.parse(payload.data);
            } catch (e) {
                response = null;
            }

            if (!response) {
                return [websocketActions.serverUnknown()];
            }

            if (response.sequence === undefined || isNaN(response.sequence)) {
                if (response.event) {
                    return eventResponseService.processServerResponse(response, state$.value);
                }

                return [websocketActions.serverUnknown()];
            }

            if (response.result === undefined || isNaN(response.result)) {
                return [websocketActions.serverUnknown()];
            }

            if (response.result === serverConstants.RESULT_INVALID_LICENSE) {
                return [licenseActions.getLicenseStatus()];
            }

            if (response.result === serverConstants.RESULT_CLIENT_NOT_LOGGED_IN) {
                return [loginActions.logoutSuccess()];
            }

            const sequence = Number(response.sequence);

            const requests: Array<any> = [serverActions.serverRemoveRequest(sequence)];

            const storeRequests: Array<ServerRequest> = state$.value.server.requests;
            const request: ServerRequest | undefined = storeRequests.find((req) => req.id === sequence);

            if (!request || request.skip) {
                return requests;
            }

            return requests.concat(responseService.processServerResponse(request.method, response, state$.value));
        })
    );
