import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";

import { Epic, ofType } from "redux-observable";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const hideItem: Epic<any, any> = (action$) =>
    action$.pipe(
        ofType(actionConstants.HIDE),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // export task
                case objectTypes.EXPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_HIDE_EXPORT_TASK, methods.METHOD_HIDE_EXPORT_TASK, payload.params)
                    );
                    break;
                }
                // import task
                case objectTypes.IMPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_HIDE_IMPORT_TASK, methods.METHOD_HIDE_IMPORT_TASK, payload.params)
                    );
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
