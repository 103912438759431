import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ImageCategory, mapImageCategory } from "../../types/imageCategory";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_IMAGE_CATEGORIES_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const imageCategoryList: Array<ImageCategory> = [];
            let imageCategory: ImageCategory | null;

            for (const item of response.data.data) {
                imageCategory = mapImageCategory(item);

                if (imageCategory) {
                    imageCategoryList.push(imageCategory);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.IMAGE_CATEGORY, imageCategoryList)];
        }
        case methods.METHOD_GET_ALL_IMAGE_CATEGORIES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const imageCategoryList: Array<ImageCategory> = [];
            let imageCategory: ImageCategory | null;

            for (const item of response.data.data) {
                imageCategory = mapImageCategory(item);

                if (imageCategory) {
                    imageCategoryList.push(imageCategory);
                }
            }

            return [
                universalObjectActions.saveItems(objectTypes.IMAGE_CATEGORY, imageCategoryList, response.data.count)
            ];
        }
        case methods.METHOD_ADD_IMAGE_CATEGORY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.GENERIC_IMAGE),
                universalObjectActions.reloadData(objectTypes.IMAGE_CATEGORY),
                universalObjectActions.getAllItems(objectTypes.IMAGE_CATEGORY),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGE_CATEGORIES_ADD)
            ];
        }
        case methods.METHOD_EDIT_IMAGE_CATEGORY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.IMAGE_CATEGORY),
                universalObjectActions.getAllItems(objectTypes.IMAGE_CATEGORY),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGE_CATEGORIES_EDIT)
            ];
        }
        case methods.METHOD_DELETE_IMAGE_CATEGORY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.IMAGE_CATEGORY),
                universalObjectActions.getAllItems(objectTypes.IMAGE_CATEGORY),
                universalObjectActions.setActiveItems(objectTypes.IMAGE_CATEGORY, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
