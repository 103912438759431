import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { CurrencyTable } from "./tables/CurrencyTable";
import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericCurrencySceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableCurrencies: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericCurrencyScene extends Component<Props, State> {
    state: State = {
        tableCurrencies:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.currencyTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableCurrencies:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.currencyTableConstant)
                        : null) || null
            });
        }
    }

    getCurrenciesTable(): JSX.Element | null {
        const { currencyTableConstant } = this.props;
        const { tableCurrencies } = this.state;

        if (tableCurrencies) {
            return (
                <CurrencyTable
                    tableConstant={currencyTableConstant}
                    objectType={objectTypes.CURRENCY}
                    dataPrivileges={tableCurrencies}
                    loading={this.props.currencyLoading}
                    buttonLoading={this.props.currencyButtonLoading}
                    allList={this.props.currencyList}
                    activeList={this.props.currencyActiveList}
                    columnOrder={this.props.currencyColumnOrder}
                    columnVisibility={this.props.currencyColumnVisibility}
                    columnWidth={this.props.currencyColumnWidth}
                    offset={this.props.currencyOffset}
                    page={this.props.currencyPage}
                    rowCount={this.props.currencyRowCount}
                    rowCountCustom={this.props.currencyRowCountCustom}
                    search={this.props.currencySearch}
                    searchParams={this.props.currencySearchParams}
                    showFilterRow={this.props.currencyShowFilterRow}
                    showGlobalSearch={this.props.currencyShowGlobalSearch}
                    sortingAsc={this.props.currencySortingAsc}
                    sortingCriterion={this.props.currencySortingCriterion}
                    totalCount={this.props.currencyTotalCount}
                />
            );
        }

        return null;
    }
    // TODO check jestli SceneSaveButtonContainer je vsude kde ma byt
    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getCurrenciesTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.currencySceneTableSize}
                dataPrivileges={this.state.tableCurrencies}
                sceneConstant={menuConstants.PAGE_GENERIC_CURRENCIES}
            />
        );
    }
}
