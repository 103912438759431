import * as objectTypes from "../../../../constants/objectTypes";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";

import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    list: Array<any>;
    dataPrivileges: any;
    editable: boolean;
    checkAll: boolean;
};

type Props = OwnProps & ContainerProps;

export class TableCheckAllButton extends Component<Props> {
    isVisible = (): boolean => {
        const { dataPrivileges, objectType } = this.props;

        if (dataPrivileges?.canUpdate && objectTypes.CHECK_ALL_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { list, editable } = this.props;

        if (list.length && editable) {
            return true;
        }

        return false;
    };

    handleHideItemClick = (): void => {
        this.props.checkAllItems(this.props.objectType, this.props.checkAll);
    };

    getButtonClassname = (): string => {
        let className = "btn-md btn-info btn-long";

        if (!this.isEnabled()) {
            className += " disabled";
        }

        return className;
    };

    getButtonTitle = (): string => {
        return this.props.checkAll ? translate("general.checkAll") : translate("general.uncheckAll");
    };

    render(): JSX.Element | null {
        if (this.isVisible() && this.isEnabled()) {
            return (
                <button className={this.getButtonClassname()} onClick={(): void => this.handleHideItemClick()}>
                    {this.getButtonTitle()}
                </button>
            );
        }

        if (this.isVisible()) {
            return <button className={this.getButtonClassname()}>{this.getButtonTitle()}</button>;
        }

        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    checkAllItems(objectType: objectTypes.ObjectType, checkAll: boolean): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    checkAllItems: (objectType: objectTypes.ObjectType, checkAll: boolean): any =>
        dispatch(universalObjectActions.checkAllItems(objectType, checkAll))
});

export const TableCheckAllButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableCheckAllButton);
