import * as translationHelper from "../helpers/translationHelper";

import { ExportTypeOptions, mapExportTypeOptions } from "./export/exportTypeOptions";

export class ExportType {
    id: number;
    key: string;
    name: string;
    exportTypeOptions: ExportTypeOptions;

    constructor(id: number, key: string, exportTypeOptions: ExportTypeOptions) {
        this.id = id;
        this.key = key;
        this.name = translationHelper.getExportTypeTranslation(key);
        this.exportTypeOptions = exportTypeOptions;
    }
}

export function mapExportType(data: Record<string, any>): ExportType | null {
    try {
        return new ExportType(data.id, data.name, mapExportTypeOptions(data.options));
    } catch (e) {
        return null;
    }
}
