import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as generalHelper from "../../../../helpers/generalHelper";
import * as keyCodes from "../../../../constants/keyCodes";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as validationHelper from "../../../../helpers/validationHelper";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import ContentEditable from "react-contenteditable";
import { Currency } from "../../../../types/currency";
import { Dispatch } from "redux";
import { TableHeading } from "../../../../types/tableHeading";
import { Unit } from "../../../../types/unit";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    content: string;
    currency?: Currency | null;
    isActive: boolean;
    isEditable: boolean;
    isSourceChanged: boolean;
    callbackBlur: (event: any, value: number | null) => any;
    callbackChange: (isValid: boolean, value: string) => any;
    callbackFocus: (value: string) => any;
    callbackMouseEnter: (event: any, value: any) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellNumber extends Component<Props> {
    handleCellFocus = (content: string): any => {
        this.props.callbackFocus(content);
    };

    handleCellChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
        const headingType = this.props.heading[tableConstants.TABLE_HEADING_TYPE];
        const content = validationHelper.formatStringFromHtml(event.target.value);
        let isValid = false;

        if (headingType === tableConstants.TABLE_TYPE_DECIMAL) {
            isValid = validationHelper.isDecimalValueValid(content);
        }

        if (headingType === tableConstants.TABLE_TYPE_INTEGER) {
            isValid = validationHelper.isIntegerValueValid(content);
        }

        if (headingType === tableConstants.TABLE_TYPE_NUMBER) {
            isValid = validationHelper.isNumberValueValid(content);
        }

        this.props.callbackChange(isValid, content);
    };

    handleCellKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        property: propertyConstants.Property,
        originValue: any
    ): any => {
        switch (event.key) {
            case keyCodes.ENTER: {
                event.currentTarget.blur();
                break;
            }
            case keyCodes.ESCAPE: {
                this.props.callbackChange(true, originValue ?? "");
                break;
            }
            default: {
                break;
            }
        }
    };

    handleCellBlur = (event: React.FocusEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): any => {
        let value: any = validationHelper.formatNumber(
            this.props.objectType,
            this.props.heading[tableConstants.TABLE_HEADING_NAME],
            this.props.content
        );

        if (value !== null && value.trim() !== "") {
            const valueNum = Number(value);
            value = !isNaN(valueNum) ? valueNum : null;
        } else {
            value = null;
        }

        if (objectTypeHelper.isObjectTypeFormulaColorant(this.props.objectType)) {
            value = generalHelper.recalculateFormulaColorantToDefaultValue(
                this.props.activeUnit,
                value,
                this.props.item[propertyConstants.PROPERTY_DENSITY]
            );
        }

        this.props.callbackBlur(event, value);
    };

    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getClassname = (): string => {
        let className = "td-edit";

        if (this.isDisabled()) {
            className += " edit-disabled";
        }

        return className;
    };

    getCellContent = (value: number | null, property: propertyConstants.Property): string => {
        let content = "";

        content = value !== null ? formattingHelper.formatDecimal(value) : "";

        // TODO: neposielat html ako string + ked bude viac jednotiek prerobit to
        if (
            content !== "" &&
            (property === propertyConstants.PROPERTY_FILL_VOLUME_ML || property === propertyConstants.PROPERTY_AMOUNT)
        ) {
            const unit =
                objectTypeHelper.isObjectTypeFormulaColorant(this.props.objectType) && this.props.activeUnit
                    ? this.props.activeUnit[propertyConstants.PROPERTY_UNIT]
                    : optionsConstants.DEFAULT_FORMULA_COLORANT_UNIT;
            content += `<span class="cell-unit">${unit}</span>`;
        }

        return content;
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        let value = item && item[property] !== undefined ? item[property] : null;
        if (objectTypeHelper.isObjectTypeFormulaColorant(this.props.objectType)) {
            value = generalHelper.recalculateFormulaColorantFromDefaultValue(
                this.props.activeUnit,
                value,
                this.props.item[propertyConstants.PROPERTY_DENSITY]
            );
        }
        const content = this.props.isActive ? this.props.content : this.getCellContent(value, property);
        const editableHtml = `<span class="${this.props.isSourceChanged ? "dotted" : ""}">${content}</span>`;

        return (
            <div
                className={`td td-edit ${property}`}
                key={property}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
            >
                <ContentEditable
                    className={this.getClassname()}
                    disabled={this.isDisabled()}
                    html={editableHtml}
                    key={property}
                    tagName={"div"}
                    onFocus={(): void => this.handleCellFocus(value?.toString() ?? "")}
                    onChange={(event: any): void => this.handleCellChange(event)}
                    onBlur={(event): void => this.handleCellBlur(event)}
                    onKeyDown={(event): void => this.handleCellKeyDown(event, property, value)}
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, content)}
                    data-tip
                    data-for={"table-cell-tooltip"}
                />
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{
    activeUnit: Unit | null;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    activeUnit:
        state.color.unitAllList.find(
            (item: Unit) =>
                optionHelper.getValueFromOptionDictionaryByKey(
                    state.login.options,
                    optionsConstants.OPTION_FORMULA_COLORANT_UNIT
                ) === item[propertyConstants.PROPERTY_NAME]
        ) ?? null
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellNumberContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellNumber);
