import * as privilegeConstants from "../constants/privilegeConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { DataItem } from "../types/dataItem";
import { User } from "../types/user";

export const getTableDataPrivileges = (dataPrivileges: Array<DataItem>, tableConstant: string) => {
    const privilege =
        dataPrivileges?.find((item: DataItem) => item[propertyConstants.PROPERTY_KEY] === tableConstant) || null;

    return privilege?.canView ? privilege : null;
};

export const isSuperadmin = (user: User | null): boolean => {
    return (user?.[propertyConstants.PROPERTY_USER_GROUP_LIST] || []).some(
        (item) => item[propertyConstants.PROPERTY_KEY] === privilegeConstants.USER_GROUP_SUPERADMINS
    );
};

export const isAdmin = (user: User | null): boolean => {
    if (!isSuperadmin(user)) {
        return (user?.[propertyConstants.PROPERTY_USER_GROUP_LIST] || []).some(
            (item) => item[propertyConstants.PROPERTY_KEY] === privilegeConstants.USER_GROUP_ADMINS
        );
    }

    return false;
};

export const isDataPrivilegesReadOnly = (dataPrivileges: any): boolean => {
    if (!dataPrivileges?.canInsert && !dataPrivileges?.canUpdate && !dataPrivileges?.canDelete) {
        return true;
    }

    return false;
};

export const isDataPrivilegesCanUpdate = (dataPrivileges: any): boolean => {
    if (dataPrivileges?.canUpdate) {
        return true;
    }

    return false;
};

export const isDataPrivilegesCanDelete = (dataPrivileges: any): boolean => {
    if (dataPrivileges?.canDelete) {
        return true;
    }

    return false;
};
