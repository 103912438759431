import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { Preview } from "../../general/scene/Preview";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { ProductSheetTable } from "./tables/ProductSheetTable";
import { PropsType } from "../../../containers/scenes/generic/GenericProductSheetSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableProductSheets: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};
// TODO zbavit se nazvoslovi product sheets a nechat product files?
export class GenericProductSheetScene extends Component<Props, State> {
    state: State = {
        tableProductSheets:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.productSheetTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableProductSheets:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.productSheetTableConstant)
                        : null) || null
            });
        }
    }

    getProductSheetsTable = (): JSX.Element | null => {
        const { tableProductSheets } = this.state;
        const { productSheetTableConstant } = this.props;

        if (tableProductSheets) {
            return (
                <ProductSheetTable
                    tableConstant={productSheetTableConstant}
                    objectType={objectTypes.PRODUCT_SHEET}
                    dataPrivileges={tableProductSheets}
                    loading={this.props.productSheetLoading}
                    buttonLoading={this.props.productSheetButtonLoading}
                    categoryList={this.props.categoryList}
                    fileTypeList={this.props.fileTypeList}
                    allList={this.props.productSheetList}
                    activeList={this.props.productSheetActiveList}
                    columnOrder={this.props.productSheetColumnOrder}
                    columnVisibility={this.props.productSheetColumnVisibility}
                    columnWidth={this.props.productSheetColumnWidth}
                    offset={this.props.productSheetOffset}
                    page={this.props.productSheetPage}
                    rowCount={this.props.productSheetRowCount}
                    rowCountCustom={this.props.productSheetRowCountCustom}
                    search={this.props.productSheetSearch}
                    searchParams={this.props.productSheetSearchParams}
                    showFilterRow={this.props.productSheetShowFilterRow}
                    showGlobalSearch={this.props.productSheetShowGlobalSearch}
                    sortingAsc={this.props.productSheetSortingAsc}
                    sortingCriterion={this.props.productSheetSortingCriterion}
                    totalCount={this.props.productSheetTotalCount}
                />
            );
        }

        return null;
    };

    getFilePreview = (): JSX.Element | null => {
        const { tableProductSheets } = this.state;
        const { productSheetActiveList } = this.props;
        // TODO: pvytvorit komponentu s privilages kde sa bude anstavovat trieda - dat aj do StaticSQL
        return (
            <Preview
                tableConstant={menuConstants.TABLE_GENERIC_PREVIEW}
                objectType={objectTypes.PRODUCT_SHEET_PREVIEW}
                activeList={this.props.productSheetActiveList}
                fileId={productSheetActiveList.length ? productSheetActiveList[0][propertyConstants.PROPERTY_ID] : null}
                dataPrivileges={tableProductSheets}
            />
        );
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getProductSheetsTable(), this.getFilePreview()].filter(
                        (item) => item !== null
                    ) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.productSheetSceneTableSize}
                dataPrivileges={this.state.tableProductSheets}
                sceneConstant={menuConstants.PAGE_GENERIC_PRODUCT_SHEETS}
            />
        );
    }
}
