import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionHelper from "../../../helpers/optionHelper";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Colorant } from "../../../types/colorant";
import { ColorantBatch } from "../../../types/colorantBatch";
import { MasterColorantScene } from "../../../components/scenes/master/MasterColorantScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    colorantTableConstant: string;
    colorantList: Array<Colorant>;
    colorantActiveList: Array<Colorant>;
    colorantLoading: boolean;
    colorantButtonLoading: boolean;
    colorantColumnOrder: Array<propertyConstants.Property>;
    colorantColumnVisibility: Record<propertyConstants.Property, boolean>;
    colorantColumnWidth: Record<propertyConstants.Property, number>;
    colorantOffset: number;
    colorantPage: number;
    colorantRowCount: number;
    colorantRowCountCustom: boolean;
    colorantSceneTableSize: Record<string, any> | null;
    colorantSearch: string | null;
    colorantSearchParams: Record<propertyConstants.Property, string>;
    colorantShowFilterRow: boolean;
    colorantShowGlobalSearch: boolean;
    colorantSortingAsc: boolean;
    colorantSortingCriterion: propertyConstants.Property | null;
    colorantTotalCount: number | null;
    colorantBatchesTableConstant: string;
    colorantBatchesList: Array<ColorantBatch>;
    colorantBatchesActiveList: Array<ColorantBatch>;
    colorantBatchesLoading: boolean;
    colorantBatchesButtonLoading: boolean;
    colorantBatchesColumnOrder: Array<propertyConstants.Property>;
    colorantBatchesColumnVisibility: Record<propertyConstants.Property, boolean>;
    colorantBatchesColumnWidth: Record<propertyConstants.Property, number>;
    colorantBatchesOffset: number;
    colorantBatchesPage: number;
    colorantBatchesRowCount: number;
    colorantBatchesRowCountCustom: boolean;
    colorantBatchesSearch: string | null;
    colorantBatchesSearchParams: Record<propertyConstants.Property, string>;
    colorantBatchesShowFilterRow: boolean;
    colorantBatchesShowGlobalSearch: boolean;
    colorantBatchesSortingAsc: boolean;
    colorantBatchesSortingCriterion: propertyConstants.Property | null;
    colorantBatchesTotalCount: number | null;
    optionUseTmcNames: boolean | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_MASTER_COLORANTS
    ),
    colorantTableConstant: menuConstants.TABLE_MASTER_COLORANTS,
    colorantList: state.colorant.masterList,
    colorantActiveList: state.colorant.masterActiveList,
    colorantLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_MASTER_COLORANTS ||
            request.method === methods.METHOD_DELETE_MASTER_COLORANT
    ),
    colorantButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_MASTER_COLORANT
    ),
    colorantColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantOffset: state.colorant.masterOffset,
    colorantPage: state.colorant.masterPage,
    colorantRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_MASTER_COLORANTS
    ),
    colorantSearch: state.colorant.masterSearch,
    colorantSearchParams: state.colorant.masterSearchParams,
    colorantShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_COLORANTS
    ),
    colorantSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_COLORANTS
        )
    ),
    colorantSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_COLORANTS
        )
    ),
    colorantTotalCount: state.colorant.masterCount,

    colorantBatchesTableConstant: menuConstants.TABLE_MASTER_COLORANT_BATCHES,
    colorantBatchesList: state.colorantBatch.masterList,
    colorantBatchesActiveList: state.colorantBatch.masterActiveList,
    colorantBatchesLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT ||
            request.method === methods.METHOD_DELETE_MASTER_COLORANT_BATCH
    ),
    colorantBatchesButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_MASTER_COLORANT_BATCH
    ),
    colorantBatchesColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesOffset: state.colorantBatch.masterOffset,
    colorantBatchesPage: state.colorantBatch.masterPage,
    colorantBatchesRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesSearch: state.colorantBatch.masterSearch,
    colorantBatchesSearchParams: state.colorantBatch.masterSearchParams,
    colorantBatchesShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MASTER_COLORANT_BATCHES
    ),
    colorantBatchesSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_COLORANT_BATCHES
        )
    ),
    colorantBatchesSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MASTER_COLORANT_BATCHES
        )
    ),
    colorantBatchesTotalCount: state.colorantBatch.masterCount,

    optionUseTmcNames: optionHelper.getValueFromOptionDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_USE_TMC_NAMES
    ),
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_MASTER_COLORANTS
    )
});

export const MasterColorantSceneContainer = connect(mapStateToProps)(MasterColorantScene);
