import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { DatabaseTable } from "./tables/DatabaseTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/data/DatabaseSceneContainer";

type Props = PropsType;

type State = {
    tableDatabases: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class DatabaseScene extends Component<Props, State> {
    state: State = {
        tableDatabases:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.databaseTableConstant)
                : null) || null
    };
    // TODO tady je CDU na privilege, ale v jinych scenes neni -- melo by byt hotove zkontrolovat
    // jak se privilege nacitaji postupne tak ne vsechny sceny se pekne vykresluji - napr zone prices
    // zkontrolovat vic ty ceny, tam bude CDU spatne
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableDatabases:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.databaseTableConstant)
                        : null) || null
            });
        }
    }

    getDatabasesTable(): JSX.Element | null {
        const { databaseTableConstant } = this.props;
        const { tableDatabases } = this.state;

        if (tableDatabases) {
            return (
                <div className="layout-item" key={databaseTableConstant}>
                    <DatabaseTable
                        tableConstant={databaseTableConstant}
                        objectType={objectTypes.DATABASE}
                        privileges={this.props.privileges}
                        dataPrivileges={tableDatabases}
                        loading={this.props.databaseLoading}
                        buttonLoading={this.props.databaseButtonLoading}
                        companyList={this.props.companyList}
                        allList={this.props.databaseList}
                        activeList={this.props.databaseActiveList}
                        columnOrder={this.props.databaseColumnOrder}
                        columnVisibility={this.props.databaseColumnVisibility}
                        columnWidth={this.props.databaseColumnWidth}
                        offset={this.props.databaseOffset}
                        page={this.props.databasePage}
                        rowCount={this.props.databaseRowCount}
                        rowCountCustom={this.props.databaseRowCountCustom}
                        search={this.props.databaseSearch}
                        searchParams={this.props.databaseSearchParams}
                        showFilterRow={this.props.databaseShowFilterRow}
                        showGlobalSearch={this.props.databaseShowGlobalSearch}
                        sortingAsc={this.props.databaseSortingAsc}
                        sortingCriterion={this.props.databaseSortingCriterion}
                        totalCount={this.props.databaseTotalCount}
                    />
                </div>
            );
        }

        return null;
    }

    render(): JSX.Element {
        return (
            <div className="height-100">
                <div className="content-layout">{this.getDatabasesTable()}</div>
            </div>
        );
    }
}
