export const SERVER_CONNECTION_TYPE = process.env.REACT_APP_SERVER_CONNECTION_TYPE || "rest";

export const SERVER_REST_PROTOCOL = process.env.REACT_APP_SERVER_REST_PROTOCOL || "http";
export const SERVER_REST_HOST = process.env.REACT_APP_SERVER_REST_HOST || "localhost";
export const SERVER_REST_PORT = process.env.REACT_APP_SERVER_REST_PORT || 9960;
export const SERVER_REST_PING_INTERVAL = process.env.REACT_APP_SERVER_REST_PING_INTERVAL || null;

export const SERVER_WEBSOCKET_PROTOCOL = process.env.REACT_APP_SERVER_WEBSOCKET_PROTOCOL || "ws";
export const SERVER_WEBSOCKET_HOST = process.env.REACT_APP_SERVER_WEBSOCKET_HOST || "localhost";
export const SERVER_WEBSOCKET_PORT = process.env.REACT_APP_SERVER_WEBSOCKET_PORT || 9950;

export const CLIENT_PROTOCOL = process.env.REACT_APP_CLIENT_PROTOCOL || "http";
export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST || "localhost:3010";

export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || "";

export const CUSTOM = process.env.REACT_APP_CUSTOM || null;

export const QUICK_INIT = process.env.REACT_APP_QUICK_INIT || true;
