import * as databaseActions from "../../../actions/databaseActions";
import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as navigationActions from "../../../actions/navigationActions";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Company } from "../../../types/company";
import { DataItem } from "../../../types/dataItem";
import { DataSize } from "../../../types/monitoring/dataSize";
import { Database } from "../../../types/database";
import { Dispatch } from "redux";
import { ExportStatus } from "../../../types/exportStatus";
import { ImportStatus } from "../../../types/importStatus";
import { ModalType } from "../../../constants/modalTypes";
import { ServerMonitoringScene } from "../../../components/scenes/header/ServerMonitoringScene";
import { ServerRequest } from "../../../types/serverRequest";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    dataPrivileges: Array<DataItem>;
    companyList: Array<Company>;
    exportStatusList: Array<ExportStatus>;
    importStatusList: Array<ImportStatus>;
    databaseSystemZoneList: Array<Database>;
    databaseSystemZoneLoading: boolean;
    databaseTableConstant: string;
    databaseList: Array<Database>;
    databaseActiveList: Array<Database>;
    databaseLoading: boolean;
    databaseColumnOrder: Array<propertyConstants.Property>;
    databaseColumnVisibility: Record<propertyConstants.Property, boolean>;
    databaseColumnWidth: Record<propertyConstants.Property, number>;
    databaseOffset: number;
    databasePage: number;
    databaseRowCount: number;
    databaseRowCountCustom: boolean;
    databaseSearch: string | null;
    databaseSearchParams: Record<propertyConstants.Property, string>;
    databaseShowFilterRow: boolean;
    databaseShowGlobalSearch: boolean;
    databaseSortingAsc: boolean;
    databaseSortingCriterion: propertyConstants.Property | null;
    databaseTotalCount: number | null;
    dataSize: DataSize | null;
}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any, nextAction?: any): any;
    vacuumAllDatabases(): void;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    dataPrivileges: state.login.dataPrivileges,
    companyList: state.company.allList,
    exportStatusList: state.export.exportStatuses,
    importStatusList: state.import.importStatuses,
    databaseSystemZoneList: state.databaseSystemZone.list,
    databaseSystemZoneLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES
    ),
    databaseTableConstant: menuConstants.TABLE_MONITORING_DATABASES,
    databaseList: state.database.list,
    databaseActiveList: state.database.activeList,
    databaseLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE
    ),
    databaseColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseOffset: state.database.offset,
    databasePage: state.database.page,
    databaseRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseSearch: state.database.search,
    databaseSearchParams: state.database.searchParams,
    databaseShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_MONITORING_DATABASES
    ),
    databaseSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MONITORING_DATABASES
        )
    ),
    databaseSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_MONITORING_DATABASES
        )
    ),
    databaseTotalCount: state.database.count,
    dataSize: state.serverMonitoring.dataSize
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any, nextAction?: any): any =>
        dispatch(navigationActions.navigationOpenModal(type, params, nextAction)),
    vacuumAllDatabases: (): any => dispatch(databaseActions.vacuumAllDatabases())
});

export const ServerMonitoringSceneContainer = connect(mapStateToProps, mapDispatchToProps)(ServerMonitoringScene);
