import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { ReactSVG } from "react-svg";
import imgInfo from "../../../resources/img/icons/info.svg";

type Props = {
    className: string;
    objectType: objectTypes.ObjectType;
    labelCaption: string;
};

export class ModalSubHeading extends Component<Props> {
    getIcon = (): JSX.Element | null => {
        switch (this.props.objectType) {
            case objectTypes.ONE_TIME_ACCESS_PASSWORD:
            case objectTypes.IMPORT_TASK:
                return <ReactSVG src={imgInfo} className="svg-icon" />;
            default:
                return null;
        }
    };

    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                {this.getIcon()}
                {this.props.labelCaption}
            </div>
        );
    }
}
