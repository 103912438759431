// data
export const DATA_CHANGED = "DATA_CHANGED";
export const DATA_COMMIT = "DATA_COMMIT";
export const DATA_LOAD = "DATA_LOAD";
export const DATA_ROLLBACK = "DATA_ROLLBACK";

// color
export const UPDATE_COLOR_DATA = "UPDATE_COLOR_DATA";

// events
export const EVENT_SAVE_CURRENCIES = "EVENT_SAVE_CURRENCIES";
export const EVENT_SAVE_DATA_PRIVILEGES = "EVENT_SAVE_DATA_PRIVILEGES";
export const EVENT_SAVE_DATABASES = "EVENT_SAVE_DATABASES";
export const EVENT_SAVE_MENU = "EVENT_SAVE_MENU";
export const EVENT_SAVE_GLOBAL_OPTIONS = "EVENT_SAVE_GLOBAL_OPTIONS";
export const EVENT_SAVE_OPTIONS = "EVENT_SAVE_OPTIONS";
export const EVENT_SAVE_PRIVILEGES = "EVENT_SAVE_PRIVILEGES";
export const EVENT_SAVE_SESSION_INFO = "EVENT_SAVE_SESSION_INFO";
export const EVENT_SAVE_SYSTEMS = "EVENT_SAVE_SYSTEMS";
export const EVENT_SAVE_ZONES = "EVENT_SAVE_ZONES";

// file
export const UPDATE_FILE_LIST = "UPDATE_FILE_LIST";
export const RUN_STATIC_SQL = "RUN_STATIC_SQL";

// formula
export const UPDATE_FORMULA = "UPDATE_FORMULA";

// navigation
export const NAVIGATION_SET_JUMP = "NAVIGATION_SET_JUMP";
export const NAVIGATION_SET_NEXT_MODAL = "SET_NEXT_MODAL";
export const NAVIGATION_SET_SCENE = "NAVIGATION_SET_SCENE";
export const NAVIGATION_SET_WIZARD = "NAVIGATION_SET_WIZARD";
export const NAVIGATION_SET_WIZARD_STEP = "NAVIGATION_SET_WIZARD_STEP";
export const NAVIGATION_MODAL_OPEN = "NAVIGATION_MODAL_OPEN";
export const NAVIGATION_MODAL_CLOSE = "NAVIGATION_CLOSE_MODAL";
export const NAVIGATION_CLOSE_ALL_MODALS = "NAVIGATION_CLOSE_ALL_MODALS";
export const NAVIGATION_SET_ACTIVE_BOOKMARK = "NAVIGATION_SET_ACTIVE_BOOKMARK";
export const NAVIGATION_SET_ACTIVE_MODAL_BOOKMARK = "NAVIGATION_SET_ACTIVE_MODAL_BOOKMARK";

// notifications
export const NAVIGATION_FLASH_MESSAGE_SAVE = "NAVIGATION_FLASH_MESSAGE_SAVE";
export const NAVIGATION_FLASH_MESSAGE_DELETE = "NAVIGATION_FLASH_MESSAGE_DELETE";
export const NAVIGATION_FLASH_MESSAGE_DELETE_ALL = "NAVIGATION_FLASH_MESSAGE_DELETE_ALL";
export const NAVIGATION_SET_NOTIFICATION_PANEL = "NAVIGATION_SET_NOTIFICATION_PANEL";

// license
export const LICENSE_SET_PROCESS_STEP = "LICENSE_SET_PROCESS_STEP";
export const LICENSE_SET_ONLINE_ACTIVATION = "LICENSE_SET_ONLINE_ACTIVATION";
export const LICENSE_SUCCESS = "LICENSE_SUCCESS";
export const LICENSE_UPDATE = "LICENSE_UPDATE";

// login
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// option
export const RESET_ALL_VALUES = "RESET_ALL_VALUES";
export const RESET_VALUE = "RESET_VALUE";

// redlike
export const SAVE_REDLIKE_PASSWORD = "SAVE_REDLIKE_PASSWORD";

// routing
export const ROUTING_PUSH = "ROUTING_PUSH";

// server
export const SERVER_CONNECT = "SERVER_CONNECT";
export const SERVER_DISCONNECT = "SERVER_DISCONNECT";
export const SERVER_REQUEST = "SERVER_REQUEST";
export const SERVER_SAVE_REQUEST = "SERVER_SAVE_REQUEST";
export const SERVER_REMOVE_REQUEST = "SERVER_REMOVE_REQUEST";

export const SAVE_SQLITE_INFO = "SAVE_SQLITE_INFO";
export const SAVE_VERSION = "SAVE_VERSION";

// server rest
export const SERVER_REST_RESPONSE = "SERVER_REST_RESPONSE";

// server websocket
export const SERVER_WEBSOCKET_CONNECTING = "SERVER_WEBSOCKET_CONNECTING";
export const SERVER_WEBSOCKET_OPENED = "SERVER_WEBSOCKET_OPENED";
export const SERVER_WEBSOCKET_CLOSED = "SERVER_WEBSOCKET_CLOSED";
export const SERVER_WEBSOCKET_REQUEST = "SERVER_WEBSOCKET_REQUEST";
export const SERVER_WEBSOCKET_RESPONSE = "SERVER_WEBSOCKET_RESPONSE";

// universal
export const ADD = "ADD";
export const ADD_INDEX_DATA = "ADD_INDEX_DATA";
export const CHECK_ALL = "CHECK_ALL";
export const DELETE = "DELETE";
export const DUPLICATE = "DUPLICATE";
export const EDIT = "EDIT";
export const EDIT_SUCCESS = "EDIT_SUCCESS";
export const GET = "GET";
export const GET_ALL = "GET_ALL";
export const GET_DETAIL = "GET_DETAIL";
export const HIDE = "HIDE";
export const JUMP = "JUMP";
export const LIST_SAVE = "LIST_SAVE";
export const LIST_SAVE_NO_PARAMS = "LIST_SAVE_NO_PARAMS";
export const LOAD_ACTIVE_ITEM = "LOAD_ACTIVE_ITEM";
export const RELOAD_DATA = "RELOAD_DATA";
export const RUN = "RUN";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_DATE = "SET_DATE";
export const SET_DICTIONARY_OPTION = "SET_DICTIONARY_OPTION";
export const SET_DICTIONARY_OPTIONS = "SET_DICTIONARY_OPTIONS";
export const SET_OFFSET = "SET_OFFSET";
export const SET_POSITION = "SET_POSITION";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SHOW_SPECIFIC_VALUES = "SET_SHOW_SPECIFIC_VALUES";
export const SET_SORTING = "SET_SORTING";
export const SHOW = "SHOW";
export const UPDATE_ACTIVE = "UPDATE_ACTIVE";
export const UPDATE_ITEM = "UPDATE_ITEM";

// unknown
export const UNKNOWN_ACTION = "UNKNOWN_ACTION";

// user
export const SAVE_USER_COMPANY_ID = "SAVE_USER_COMPANY_ID";
export const SAVE_USER_GROUPS_FOR_USER = "SAVE_USER_GROUPS_FOR_USER";

// wizard
export const WIZARD_SAVE_DATA = "WIZARD_SAVE_DATA";
