export const en = {
    barcode: {
        addBarcode: "Add another barcode",
        alternativeBarcode: "Alternative barcode",
        barcode: "Barcode",
        barcodes: "Barcodes",
        barcodesProductDuplicate:
            "Barcodes (copied barcodes may be stored as alternative barcodes in case duplicities)",
        noBarcode: "No barcode"
    },
    base: {
        addAnotherBase: "Add another base",
        addBase: "Add base",
        allBases: "All bases",
        amount: "Base amount",
        base: "Base",
        baseVolume: "Base volume",
        bases: "Bases",
        editBase: "Edit base",
        loadBases: "Please wait, bases are loading...",
        noBase: "No base",
        noBaseAvailable: "There is no base available",
        noBaseVolume: "No base volume",
        selectBase: "Select base",
        selectBases: "Select bases",
        selectFromBases: "Select from bases",
        selectedBases: "Selected bases"
    },
    batch: {
        batchNumber: "Batch number",
        lastStatusChange: "Last status change",
        productionDate: "Production date"
    },
    color: {
        addColor: "Add color",
        calculateLabFromReflectance: "Calculate LAB from Reflectance",
        calculateRgb: "Calculate RGB",
        calculateRgbFromLab: "Calculate RGB from LAB",
        color: "Color",
        colorInFandeck: "Color in fandeck",
        colorData: "Color data",
        colors: "Colors",
        editColor: "Edit color",
        fandeckPage: "Fandeck page",
        fandeckPosition: "Fandeck position",
        jumpToColor: "Jump to color",
        noColorInFandeck: "There is no color in selected fandeck",
        noFandeckPage: "No fandeck page",
        noFandeckPosition: "No fandeck position",
        noSpectralData: "No spectral data",
        reflectance: "Reflectance",
        successfullRgbCalculation: "The RGB was successfully calculated and changed in the General tab",
        wavelength: "Wavelength"
    },
    colorant: {
        addColorant: "Add colorant",
        addColorantBatch: "Add colorant batch",
        addColorantPackage: "Add colorant package",
        colorant: "Colorant",
        colorantBatches: "Colorant batches",
        colorantPackage: "Colorant package",
        colorantPackages: "Colorant packages",
        colorants: "Colorants",
        editColorant: "Edit colorant",
        editColorantBatch: "Edit colorant batch",
        editColorantPackage: "Edit colorant package",
        selectColorants: "Select colorants",
        selectFromColorantPackages: "Select from colorant packages",
        selectFromColorants: "Select from colorants",
        selectedColorantPackages: "Selected colorant packages",
        selectedColorants: "Selected colorants"
    },
    company: {
        addCompany: "Add company",
        allCompanies: "All companies",
        companies: "Companies",
        company: "Company",
        companyName: "Company name",
        companySettings: "Company settings",
        editCompany: "Edit company"
    },
    confirmationModal: {
        changesText: "You have made changes, do you wish to save them?",
        databaseUpdate: "Database update",
        databaseUpdateText: "Database %{databaseName} needs to be updated. Do you wish to proceed with it now?",
        deleteContinue: "I'm sure, continue with deletion",
        deleteItem: "Confirm deletion",
        deleteItemText: "Do you really want to delete the selected item?",
        deleteText: "Deleting this item can remove linked formulas and inherited items on the system and zone levels.",
        deletionWarning: "Deletion warning",
        saveData: "Confirm saving",
        saveDataText: "Do you want to save your changes?",
        warningDetail: "Warning detail",
        warningDataSize: "Warning - running out of space",
        warningDataSizeInfo:
            "Please check your data and remove unnecessary older databases. If you need to increase disk space, contact DESO company."
    },
    currency: {
        addCurrency: "Add currency",
        currencies: "Currencies",
        currency: "Currency",
        editCurrency: "Edit currency",
        selectCurrencies: "Select currencies"
    },
    database: {
        addDatabase: "Add database",
        addEmptyDatabase: "Add empty database",
        backupSelectedDatabase: "Backup selected database",
        createNewDatabase: "Create new database",
        database: "Database",
        databases: "Databases",
        defaultDataEmpty: "Empty database",
        defaultDataSystemZoneCurrency: "Add system, zone and currency Euro",
        duplicateDatabase: "Duplicate database %{name}",
        duplicateSelectedDatabase: "Duplicate selected database",
        editDatabase: "Edit database",
        importDatabase: "Import Redlike database",
        loadDatabase: "Load database",
        master: "Master",
        masterDatabases: "Master databases",
        masterName: "Master name",
        masterOnlyDuplicate: "Duplicate data",
        masterOnlyDuplicateFalse: "Duplicate with System/Zone data",
        masterOnly: "Import data",
        masterOnlyFalse: "Import with System/Zone data",
        masterOnlyTrue: "Delete existing System/Zone data",
        vacuumDatabasesDescription:
            "The VACUUM command rebuilds the database file, repacking it into a minimal amount of disk space.",
        vacuumMasterDatabases: "Vacuum master databases",
        valueInMaster: "Value in master data"
    },
    duplicate: {
        selectDataToBeCopied: "Select data to be copied"
    },
    export: {
        abortExport: "Abort export",
        colorsWithoutFormulas: "Colors without formulas",
        colorsWithoutFormulasCaption: "Delete colors without formula in the exported database",
        currentR2WVersion: "Current %{redlikeWebAbbreviation} version",
        dataExport: "Export",
        deleteColorsWithoutFormulas: "Delete colors without formulas",
        deleteExports: "Delete exports",
        exportCompleted: "Export completed",
        exportData: "Export data",
        exportDate: "Export date",
        exportError: "Export error",
        exportList: "List of exports",
        exportLoading: "Please wait, export is preparing...",
        exportTask: "Export task",
        noR2WVersionAvailable: "No %{redlikeWebAbbreviation} version available",
        prepareBulkExport: "Prepare bulk export",
        r2wVersion: " %{redlikeWebAbbreviation} version",
        setNextVersion: "Set next version",
        showR2WVersion: "Show %{redlikeWebAbbreviation} version",
        startNewTask: "Start a new task for export data",
        statuses: {
            aborted: "Aborted",
            aborting: "Aborting",
            addingIndexes: "Adding indexes",
            addingMetaData: "Adding metadata",
            deleted: "Deleted",
            enqueued: "Enqueued",
            exportingDatabase: "Exporting database",
            finalizingDatabase: "Finalizing database",
            processingData: "Processing data",
            readyToBeProcessed: "Ready to be processed"
        },
        types: {
            archiveWindows: "Archive Windows",
            archiveLinux: "Archive Linux",
            ascPhb: "ASC/PHB",
            desoMasterExcel: "DESO Master Excel",
            desoZoneExcel: "DESO Zone Excel",
            emptyDatabase: "Empty database",
            innoSetup: "Windows Installation package",
            largoTint2000: "Largo Tint 2000",
            onlyDatabase: "Only database",
            onlyDatabaseToWeb: "Export database to %{redlikeWeb}",
            tmcProductsExcel: "TMC Products Excel"
        }
    },
    flashMessage: {
        base64Input: "Error on decoding Base64 data",
        cannotConnectToServer: "Cannot connect to the server.",
        clientConnectionRejected: "Connection has been rejected.",
        clientError: "Client error",
        clientNotLoggedIn: "Client is not logged in.",
        clientSessionNotInitialized: "Client session not initialized",
        dataNotAvailable: "Server cannot connect to the database.",
        databaseAutologinFailed: "Database autologin failed.",
        databaseClosed: "Database closed.",
        databaseError: "Database error, selected database cannot be used, check log files for more info.",
        databaseErrorOnOpening: "Error on opening database, invalid file or wrong password.",
        databaseNotCompatible: "Database version is not compatible",
        databaseNotFound: "Database file not found.",
        databaseUpdated: "Database updated.",
        defaultMessage: "An unexpected error occurred during the last server action.",
        duplicateBarcode: "Given barcode is already in use.",
        duplicateEntry: "Duplicate entity already exists.",
        failedParameterValidation: "Parameter validation failed, invalid value for parameter: %{0}.",
        fileUploadError: "An error %{0} occurred during the file uploading.",
        importErrorAborted: "Import process has been aborted",
        importErrorDataMissing: "Could not import data, invalid %{0} data.",
        importErrorFileMissing: "Could not import data, %{0} file %{1} is missing.",
        importErrorGeneric: "Could not import data, check log files for more info",
        importErrorHeaderMissing: "Could not import data, error in list: %{0}, missing header: %{1}",
        importErrorUnderProgress:
            "Could not import data as another import is currently active, wait until it's processed",
        importTaskMandatoryFile: "Mandatory file %{0} for import task missing or is invalid",
        importTaskMandatoryOption: "Mandatory options %{0} for import task missing or is invalid",
        invalidDatabaseName: "Database name is invalid.",
        invalidEmail: "Your email is invalid.",
        invalidFileId: "Invalid file ID: %{0}.",
        invalidFileTypeUploading: "Only %{validFormats} formats are uploaded.",
        invalidFileUploadUuid: "Invalid file upload UUID.",
        invalidImage: "Invalid image: %{0}.",
        invalidItem: "Invalid %{0}.",
        invalidJsonInput: "Error on parsing JSON command: %{0}",
        invalidLicenseIdentificationKeys: "Your license identification keys are invalid.",
        invalidLicenseKey: "Your license is invalid.",
        invalidMethod: "Method: %{0} not available.",
        invalidName: "Invalid name",
        invalidValue: "Invalid value: %{0} for %{1}.",
        invalidServer: "Invalid Redlike server",
        invalidServerResponse: "An unexpected error occurred during processing of the last server response.",
        invalidUserPassword: "Invalid password",
        itemAlreadyExists: "Item %{0} already exists.",
        itemNotDeletable: "%{0} cannot be deleted.",
        itemNotEditable: "Value for %{0} cannot be updated.",
        itemNotFound: "The item %{0} was not found.",
        licenseActivationIsNotPossible: "At this moment the activation is not possible",
        licenseErrorOnSavingLicense: "A problem occurred with saving of the license",
        licenseInvalid: "License is invalid",
        licenseNoConnectionToLicenseServer: "There is no connection to the license server",
        licenseUpdated: "License was updated",
        licenseValidNoUpdateNeeded: "License is valid, no update is needed.",
        licenseVerificationFailed: "License verification has failed",
        licenseWebApiError: "Licensing web api error",
        loginError: "An unexpected error occurred during logging in.",
        logoutError: "An unexpected error occurred during logging out.",
        missingCommandType: "Request is missing command type.",
        missingDatabaseConfigFile: "Database configuration file is missing",
        malformedFormula: "Formula is malformed",
        noDatabaseSelected: "No database selected",
        noSystemSelected: "No system selected",
        noZoneSelected: "No zone selected",
        notFoundBarcode: "The barcode was not found.",
        notFoundBipp: "Base in product package not found, compatible base: %{0}, available packages: %{1}.",
        notFoundCustomer: "Customer not found",
        notFoundDirectory: "Directory: %{0} not found.",
        notFoundFile: "File: %{0} not found.",
        notFoundFormula: "Formula not found",
        orderAlreadyAcquired: "Order has already been acquired, it has to be released first",
        serverAlreadyDefined: "Redlike server is already defined",
        serverError: "Server error",
        shortUserPassword: "User password is too short",
        unknownCommand: "Unknown command type: %{0}",
        unknownOption: "Unknown option",
        unsupportedType: "Unsupported type",
        userAlreadyLoggedIn: "You are already logged in.",
        userChangePasswordFailed: "Old password verification failed"
    },
    fandeck: {
        addFandeck: "Add fandeck",
        editFandeck: "Edit fandeck",
        fandeck: "Fandeck",
        fandeckNotSelected: "Fandeck is not selected",
        fandecks: "Fandecks",
        selectFandecks: "Select fandecks",
        selectFromFandecks: "Select from fandecks",
        selectedFandecks: "Selected fandecks"
    },
    file: {
        addFile: "Add file",
        addFileCategory: "Add file category",
        editFile: "Edit file",
        editFileCategory: "Edit file category",
        errorOccured: "Some errors occured running the SQL script on lines",
        file: "File",
        fileCategories: "File categories",
        fileCategory: "File category",
        fileSize: "File size",
        fileType: "File type",
        filePreview: "File %{name}",
        fileCount: "Number of files",
        files: "Files",
        noFilePreview: "No file to preview",
        selectDirectory: "Select directory",
        selectFile: "Select file",
        selectFiles: "Select files",
        staticSqlPreview: "Static SQL %{name} preview",
        addProductFile: "Add product file",
        allProductFilesAdded: "All product files were added",
        editProductFile: "Edit product file",
        technicalSheet: "Technical sheet",
        instructions: "Instructions",
        safetySheet: "Safety sheet",
        runningStaticSql: "Running static SQL",
        selectProductFile: "Select product file",
        uploading: "Please wait, your files are uploading...",
        utf8File: "File must be in utf-8 encoding"
    },
    formula: {
        addFormula: "Add formula",
        allStatuses: "All statuses",
        colorsWithSameFormula: "Colors with the same formula",
        colorsWithThisFormula: "Colors with this formula",
        createNewFormula: "Create new formula",
        currentFormula: "Current formula",
        editFormula: "Edit formula %{colorName}",
        formula: "Formula",
        formulaFor: "Formula for",
        formulaForBaseVolume: "For base volume",
        formulaForNominalAmount: "For nominal amount",
        formulas: "Formulas",
        historicalFormula: "Historical formula",
        jumpToFormula: "Jump to formula",
        noFormula: "There is no formula for color %{colorName}",
        noFormulaFor: "No formula for",
        noOtherFormula: "There is no other recipe available",
        noValidFormula: "There is no valid recipe",
        onlyReplaceExistingData: "Save this data as a new version of the recipe and save previous as a historical one"
    },
    formulaNote: {
        addCode: "Add code",
        addFormulaNote: "Add formula note",
        editFormulaNote: "Edit formula note",
        formulaNote: "Formula note",
        formulaNotes: "Formula notes",
        selectCode: "Select Formula notes code"
    },
    forcedFormulaBaseAmountType: {
        default: "Default",
        gravimetric: "Gravimetric",
        volumetric: "Volumetric",
        useAsItIs: "Use as it is"
    },
    general: {
        accepted: "Accepted",
        action: "Action",
        actionMethod: "Action method",
        actionType: "Action type",
        add: "Add",
        additionalProperties: "Additional properties",
        all: "All",
        allItemsAlreadyAdded: "All %{objectType} were already added",
        amount: "Amount",
        amountCoef: "Amount coefficient",
        back: "Back",
        backup: "Backup",
        beginValue: "Begin value",
        bfs: "BFS",
        canDelete: "Can delete",
        canUpdate: "Can update",
        cancel: "Cancel",
        canceled: "Canceled",
        changelog: "Changelog",
        changePassword: "Change password",
        checkAll: "Check all",
        checked: "Checked",
        category: "Category",
        close: "Close",
        code: "Code",
        coefficient: "Coefficient",
        colorBlue: "B",
        colorGreen: "G",
        colorRed: "R",
        computerId: "Computer ID",
        confirm: "Confirm",
        continue: "Continue",
        createNew: "Create new",
        created: "Created",
        dataMasterSettings: "Master settings",
        date: "Date",
        dateFilter: "Date filter",
        debugInfo: "Debug information",
        defaultData: "Default data",
        delete: "Delete",
        deleteAll: "Delete all",
        density: "Density",
        description: "Description",
        discard: "Discard",
        download: "Download",
        duplicate: "Duplicate",
        edit: "Edit",
        editMetadata: "Edit metadata",
        endValue: "End value",
        errorDetail: "Error detail",
        export: "Export",
        exportImportData: "Export/Import data",
        fillVolume: "Filling volume",
        fillProc: "Filling percent",
        filter: "Filter",
        finished: "Finished",
        fitToHeight: "Fit to height",
        general: "General",
        genericData: "Generic data",
        globalSettings: "Global settings",
        globalTableSearch: "Global table search...",
        gravimetric: "Gravimetric",
        gravimetricFill: "Gravimetric fill",
        groups: "Groups",
        height: "Height",
        hide: "Hide",
        hideShow: "Hide/Show",
        historical: "Historical",
        history: "History",
        id: "ID",
        import: "Import",
        importExport: "Import/export",
        inherited: "Inherited",
        interval: "Interval",
        invalid: "Invalid",
        itemsCount: "Items count",
        itemsOnPage: "Items on page",
        key: "Key",
        largoName: "Largo name",
        layout: "Layout",
        length: "Length",
        level: "Level",
        line: "Line",
        lock: "Lock",
        manager: "Manager",
        metaData: "Metadata",
        minDosage: "Minimal dosage",
        missingData: "Some data are missing",
        moreInfo: "More info",
        moreInfoInternal: "Internal more info",
        moreInfoPrint: "Print more info",
        name: "Name",
        next: "Next",
        no: "No",
        noCode: "No code",
        noLargoName: "No Largo name",
        noMoreInfo: "No more info",
        noMoreInfoInternal: "No internal more info",
        noMoreInfoPrint: "No print more info",
        noName: "No name",
        noPriority: "No priority",
        noSearchCode: "No search code",
        noSearchName: "No search name",
        noStatus: "No status",
        noTmcName: "No TMC name",
        noUuid: "No UUID",
        note: "Note",
        noPreviewAvailable: "No preview available",
        notAvailable: "Not available",
        nothingToAdd: "Nothing available to add",
        noResult: "No results found",
        nominalAmount: "Nominal amount",
        ok: "OK",
        options: "Options",
        operationDuration: "This operation may take several minutes.",
        option: "Option",
        parameters: "Parameters",
        password: "Password",
        passwordOld: "Old password",
        passwordNew: "New password",
        passwordNewRepeat: "Repeat new password",
        passwordChange: "Password change",
        path: "Path",
        pleaseWait: "Please wait...",
        preview: "Preview",
        previous: "Previous",
        printName: "Print name",
        primary: "Primary",
        priority: "Priority",
        properties: "Properties",
        range: "Range",
        readOnly: "Read only",
        redlikeManagerSettings: "%{thisSoftware} settings",
        redlikeOptions: "%{software} options",
        redlikePrivileges: "%{software} privileges",
        redlikeUsers: "%{software} users",
        rejected: "Rejected",
        reloadData: "Reload data",
        restoreAllValues: "Restore all values",
        restoreSelectedValue: "Restore selected value",
        rgb: "RGB",
        rounding: "Rounding",
        run: "Run",
        save: "Save",
        saveAll: "Save all",
        saving: "Saving data",
        search: "Search...",
        searchCode: "Search code",
        searchName: "Search name",
        section: "Section",
        select: "Select",
        selectFile: "Select file from disk...",
        serverMonitoring: "Server monitoring",
        serverScripts: "Server scripts",
        setAllToDefaultValues: "Set all to default values",
        set: "Set",
        settings: "Settings",
        show: "Show",
        showAll: "Show all",
        showHideColumns: "Show/hide columns",
        showingItemsOfTotal: "Showing %{from} to %{to} of %{total} entries",
        signOut: "Sign out",
        size: "Size",
        sizeByte: "Byte",
        sizeBytes: "Bytes",
        sizeGb: "GB",
        sizeKb: "kB",
        sizeMb: "MB",
        sizeTB: "TB",
        showDeletedValues: "Show deleted values",
        showHiddenValues: "Show hidden values",
        spectralData: "Spectral data",
        status: "Status",
        staticSql: "Static SQL",
        summary: "Summary",
        symbol: "Symbol",
        systemZoneSettings: "System & Zone settings",
        text: "Text",
        textMaster: "Master text",
        textSystem: "System text",
        tmcName: "TMC name",
        type: "Type",
        typeHereFilter: "Type here to filter ...",
        ufiCode: "UFI code",
        uncheckAll: "Uncheck all",
        update: "Update",
        updated: "Updated",
        url: "Url",
        userAccessControl: "User access control",
        uuid: "UUID",
        valid: "Valid",
        value: "Value",
        verified: "Verified",
        version: "Version",
        versionName: "Version name",
        warning: "Warning",
        width: "Width",
        yes: "Yes"
    },
    help: {
        createTicket: "Create ticket",
        documentationPage: "Documentation page",
        fastAnswer: "For faster reply, please",
        helpNeed: "Need some help?",
        hotline: "Hotline",
        hoursAvailable: "Available from Monday to Friday, 8 AM to 4 PM CET.",
        orVisit: "Or visit"
    },
    image: {
        addIcon: "Add icon",
        addImage: "Add image",
        addImageCategory: "Add image category",
        addImageMore: "Add images",
        clearImage: "Clear image",
        editImage: "Edit image",
        editImageCategory: "Edit image category",
        icon: "Icon",
        image: "Image",
        imageCategories: "Image categories",
        imageCategory: "Image category",
        images: "Images",
        loadImage: "Load new image from disk...",
        noImage: "No image",
        noImageAvailable: "No image available",
        noImagePreview: "No image to preview",
        selectIcon: "Select icon from disk...",
        selectIconFromExisting: "Select icon",
        selectImage: "Select image from disk...",
        selectImageFromExisting: "Select image"
    },
    import: {
        abortImport: "Abort import",
        dataImport: "Import",
        deleteImports: "Delete imports",
        downloadSourceFilesForTask: "Download source files for task",
        formats: {
            akzoMasterZone:
                "Import format that allows to import data from Akzo CSV files. Formulas and system / zone data is processed.",
            barilExcelMaster:
                "Import format that allows to import data from Baril Redlike master Excel format. Only formulas are imported.",
            datacolorBarilCSVMaster:
                "Import format that allows to import data from Datacolor CSV files specially designed for Baril. Only formulas are imported.",
            datacolorCSVMaster:
                "Import format that allows to import data from Datacolor CSV files. Only formulas are imported.",
            datacolorXMLMaster:
                "Import format that allows to import data from Datacolor XML files. Only formulas are imported.",
            desoMaster:
                "Import format that allows to import data from Deso Redlike master Excel format. Only formulas are imported.",
            desoPriceGroupBarcodesZone:
                "Import format that allows to import data from Deso price group barcodes file. Only price group barcodes are imported.",
            desoZone: "Import format that allows to import data from Deso Redlike zone Excel format."
        },
        importData: "Import data",
        importDate: "Import date",
        importTask: "Import task",
        importTaskList: "List of imports",
        keepOldFormulas: {
            keepEverything: "Merge with existing formulas",
            removeAll: "Delete all data (except metadata)",
            removeFormulas:
                "Delete existing formulas and create new ones (Bases, colors, products and their packages remain)"
        },
        modules: {
            importerMasterAlfa: "",
            importerMasterCdColor: "CD Color CSV master format",
            importerMasterCdColor2: "",
            importerMasterColibri: "",
            importerMasterColibriXml: "",
            importerMasterColorComposer: "",
            importerMasterCorobGdata: "Corob GData master zone format",
            importerMasterDatacolor: "",
            importerMasterDatacolorCsv: "Datacolor CSV master format",
            importerMasterDatacolorXml: "Datacolor XML master format",
            importerMasterFlugger: "Flugger Boomi API format",
            importerMasterFlugger2: "",
            importerMasterInnovatintText: "",
            importerMasterInnovatint1: "",
            importerMasterInnovatint3: "Innovatint 3 master zone format",
            importerMasterLargo2000: "",
            importerMasterLargoT3: "",
            importerMasterMeffertCsv: "Meffert CSV master format",
            importerMasterMeffertSql: "Meffert SQL master format",
            importerMasterPpf: "",
            importerZoneMeffert: "Meffert zone format"
        },
        optionOldFormulas: "Currently stored data",
        optionStrict: "Strict mode",
        optionStrictText: "Stop data processing when an error occurs",
        sectionDataFiles: "Data files",
        sectionOptions: "Input options and parameters",
        startNewImportTask: "Start a new task for import data",
        statuses: {
            aborted: "Aborted",
            aborting: "Aborting",
            analyzingFile: "Analyzing file",
            connectingToDatabase: "Connecting to database",
            deleted: "Deleted",
            enqueued: "Enqueued",
            openingFile: "Opening file",
            preparingForImport: "Preparing for import",
            processingBaseInProducts: "Processing base in products",
            processingBasePackages: "Processing base packages",
            processingBases: "Processing bases",
            processingColorBarcodes: "Processing color barcodes",
            processingColorantPackages: "Processing colorant packages",
            processingColorants: "Processing colorants",
            processingColors: "Processing colors",
            processingComponents: "Processing components",
            processingFandecks: "Processing fandecks",
            processingFormulas: "Processing formulas",
            processingNotes: "Processing notes",
            processingOptions: "Processing options",
            processingPackages: "Processing packages",
            processingPrices: "Processing prices",
            processingProductBarcodes: "Processing product barcodes",
            processingProductGroups: "Processing product groups",
            processingProductSources: "Processing product sources",
            processingProducts: "Processing products",
            processingSettings: "Processing settings",
            processingSpectro: "Processing spectro",
            processingStaticData: "Processing static data",
            processingUserGroups: "Processing user groups",
            processingUsers: "Processing users",
            removingData: "Removing data",
            removingFormulas: "Removing formulas",
            renamingBases: "Renaming bases",
            renamingColorants: "Renaming colorants",
            renamingColors: "Renaming colors",
            renamingFandecks: "Renaming fandecks",
            renamingProductGroups: "Renaming product groups",
            renamingProducts: "Renaming products",
            resultError: "Import error",
            resultOk: "Import completed"
        }
    },
    license: {
        activateNewLicense: "Activate new license",
        activateLicense: "Activate license",
        activatingLicense: "Please wait, your new license is activating...",
        address: "Address",
        asteriskMark: "Items marked with an asterisk must be filled in",
        city: "City",
        company: "Company",
        companyId: "Company ID",
        contactPerson: "Contact person",
        copy: "Copy",
        country: "Country",
        email: "E-mail",
        expiration: "Expiration",
        enterKeys: "Please, enter your identification keys",
        fillData: "Please, fill out the registration data",
        insertKey: "Please, insert generated key for offline activation",
        license: "License",
        licenseIdentification: "License identification",
        licenseInformation: "License information",
        licenseStatus: "License status",
        licenseWillExpire: "Your license expires in %{dayCount} days",
        modules: "Modules",
        noLicenseAvailable: "No license available",
        phone: "Phone",
        productNotLicensed: "Product is not licensed",
        productRedlikeManagerStandard: "Redlike Manager Standard",
        productRedlikeManagerBasic: "Redlike Manager Basic",
        refresh: "Refresh",
        region: "Region",
        registerLicense: "Please, take a copy of the activation request and navigate to",
        removeLicense: "Remove license",
        reset: "Reset",
        selectProduct: "Select product",
        street: "Street",
        updateLicense: "Update license",
        userId: "User ID",
        uuid: "License uuid",
        versionTypes: "Version types",
        zip: "ZIP code"
    },
    login: {
        incorrectLogin: "Username or password is incorrect",
        loading: "Securely logging you in...",
        login: "Log in"
    },
    margin: {
        addMargin: "Add Margin/Discount",
        editMargin: "Edit Margin/Discount"
    },
    navigation: {
        deleteAll: "Delete all",
        noNotifications: "No new notifications",
        showLess: "Show less",
        showAllMessages: "Show all messages"
    },
    oneTimeAccessPassword: {
        copyToClipboard: "Copy to clipboard",
        generateOneTimeAccessPassword: "Generate one time %{software} access password",
        generatePassword: "Generate password",
        hint: "To log in %{software} use standard login dialog. In the username field enter %{username} and in the password field enter the generated password below.",
        oneTimeAccessPassword: "One-time access password",
        validForOneDay: "Valid for one day"
    },
    options: {
        description: {
            additionalTabType: "Defines the type of the new tab when clicking on the plus button",
            autoCloseTab: "Defines whether the formula tab closes after dispensing has been finished",
            automaticClientStart: "Defines whether the application starts immediately after PC is ready (booted)",
            automaticLogOut: "Defines whether the logged in user is be logged out due to inactivity.",
            automaticLogOutTimeOut:
                "Defines how many minutes must pass before the user will be logged out automatically.",
            automaticPrint: "Defines whether printing is automatic during dispensing",
            barcodesEnabled: "Defines whether barcode validations and barcode test environment are allowed",
            colorantPackageProc: "Defines the default value of colorant package filling in percent",
            colorantPanelOrderBy:
                "Defines the parameter based on which the colorants are ordered when a formula is opened.",
            colorPreviewResizable:
                "Defines whether the color preview can be streched out. On focus means that the expansion is available only as long as the cursor hovers over it.",
            colorTabCompatibleOnly: "Defines whether non-existent formulas are displayed",
            colorTabFormulaCode: "Defines whether the formula code is visible on each tile in Color panel",
            colorTabFormulaName: "Defines whether the formula name is visible on each tile in Color panel",
            colorTabNoteIcons: "Defines whether on Color Palette, note icons are visible.",
            colorTabOrderBy: "Defines according to what the tiles in Color panel are ordered",
            colorTabRowCount: "Defines how many tiles there are displayed on Color Panel",
            colorTabWarningIcon: "Defines whether the warning icon is visible on tiles in Color panel",
            competitiveColorSearch: "Defines whether competitive color search in Color panel is allowed",
            currencyCode:
                "Defines the default currency for price calculation (Add-on to setting the currency in the zone metadata)",
            customBannerPosition: "Defines where banner is displayed if used",
            customCanOption: "Defines which units are allowed to be used when using custom packages",
            customCanSize: "Defines whether custom package is displayed and can be selected on Formula panel",
            customCanUnits:
                "Defines which measurement units are allowed for custom packages whereas , only applicable if customCanOption is set to customUnits",
            customersEnabled: "Defines whether the customers panel on Formula panel is visible",
            customFormulas:
                "Defines whether buttons for custom formulas are displayed on Formula panel and global search",
            deleteConfirmation: "Defines whether pop-up to confirm deletion of items will be displayed",
            dispensingEnabled: "Defines whether the Dispense button is visible",
            dispensingNotes: "Defines whether dispensing note is displayed on Formula panel",
            doNotShowAgainCloseRedlike:
                "Defines whether the pop-up window with message “Do you really want to close %{software}? All %{software} panels will be closed, unsaved changes will be lost.” appears or not",
            editColorantAllowance: "Defines whether the button Editing colorants is visible when a formula is selected",
            formulaAutomaticNaming:
                "Defines whether formula name is changed automatically after colorants modification",
            formulaCode:
                "Defines whether formula code is displayed when searching for a formula, must be true if formulaName is false",
            formulaCodePrimary:
                "Defines whether the formula code is shown as first in the row in case formula name and code are blended into one searching field.",
            formulaColorPreview: "Defines whether formula color is displayed when searching for a formula",
            formulaName:
                "Defines whether formula name is displayed when searching for a formula must be true if formulaCode is false",
            formulaNotes: "Defines whether formula note is displayed on Formula panel",
            formulaPreview: "Defines what is displayed in detail when formula is selected",
            formulaStatusPanelEnabled:
                "Defines whether on Formula panel, the formula status panel containing formula status and creation date is available.",
            formulaUnits: "Defines used measurement unit by default, it is",
            fts: "Defines way of text searching. If true, searching is quicker but not full-text.",
            generateColorPreview:
                "Defines whether automatically generated RGB values are enabled. The calculated RGB values are only indicative!",
            help: "Defines what values are displayed in Help section",
            helpLink: "Defines custom help link in Help section",
            checkMinDosableAmount:
                "Defines whether it is checked for the minimal tintable amount. The option refers to Minimal dosage item for colorants in %{thisSoftware}.",
            checkOverfill: "Defines whether it is checked for colorant overfill within a formula.",
            checkTotalCntAmounts:
                "Defines whether it is checked for minimal and maximal colorant levels within a formula. The option refers to Minimum colorant amount and Maximum colorant amount item for colorants in %{thisSoftware}.",
            infoPanelResizable:
                "Defines whether the info panel can be streched out. On focus means that the expansion is available only as long as the cursor hovers over it.",
            infoPanelTab:
                "Defines tab type in Info panel in Formula detail when formulaPreview is set to image/more info + color – it changes with the use of",
            infoTypes: "Defines which values are displayed and their order in Info panel in Formula detail",
            initTabType: "Defines which tab type is opened when the software starts",
            keyboard: "Defines selected keyboard, used for displaying virtual keyboard",
            keyboardShortcuts: "Defines the list of used keyboard shortcuts",
            kiosk: "Defines whether the application is in kiosk mode",
            language: "Defines which language is used",
            lightEnabled: "Defines the possibility to switch %{software} to Light version",
            lockPC: "Defines whether the computer should be locked, i.e. system operations are unavailable, requires kiosk mode on and automatic %{software}",
            marginMarkup: "Defines whether Margin settings are displayed only in margins, markups or in both values",
            maxToleranceForCompetitiveColors: "Defines the maximum tolerance (CIA 2000) for competitive color search",
            navigationItemsOrdered:
                "Defines which values are displayed and their order in Navigation panel, limit is set to 6",
            numberOfCompetitiveColors:
                "Defines number of competitive colors which appear after searching in Color panel",
            numberOfTabs: "Defines maximum number of Formula panels which can be opened simultaneously",
            orderingEnabled:
                "Defines whether all functions connected to ordering are enabled (Order button, My Orders, To Dispense, Client/Server Connection)",
            orderModalEnabled: "Defines whether Order pop-up shows up when new order is received",
            orderSearchItems: "Defines which values are used in Order search in Light",
            overwriteLastTab:
                "Defines whether after searching with global search, the last opened formula tab overwrites with the new search when the formula tab limit has been reached. If not, a message saying “The limit has been reached, please close a formula tab.” appears",
            overwriteLogo: "Defines whether the logo changes with the change of database",
            priceTypes: "Defines types of available prices which will be visible on Formula panel",
            priceVisibilityTypes: "Defines which prices are visible: with VAT, without VAT or both",
            pricingEnabled: "Defines whether the whole pricing system is allowed",
            printAfterDispensing:
                "Defines whether automatic printing after dispensing should take place, must also set pages",
            printAsImage:
                "Defines whether the template should be printed as image, helps with printing troubleshooting on Linux",
            printBeforeDispensing:
                "Defines whether automatic printing after dispensing should take place, must also set pages",
            printEnabled: "Defines whether printing options are allowed",
            printer: "Defines which printer is used, the printer must be plugged in, installed and working",
            printerTemplate:
                "Defines which template is used for print, the template must be available in templates folder",
            printerTemplateQuickTint:
                "Defines which template is used for print for Quick Tint, the template must be available in templates folder",
            printOnRequest: "Defines whether the Print button is displayed on Formula panel",
            printTasksAfterDispensing: "Defines the print tasks set after dispensing",
            printTasksBeforeDispensing: "Defines the print tasks set before dispensing",
            productCode: "Defines whether product code is displayed when searching for a formula",
            productImagePreview: "Defines whether product image is displayed in a pop-up window during dispensing",
            quickTintCloseAfterDispensing: "Defines whether Quick Tint pop-up window closes before or after dispensing",
            quickTintSortingType:
                "Defines how colorants are sorted in Quick Tint window (alphabetically or by canister ID)",
            rounding: "Defines number of decimal places for colorant rounding",
            screensaverTimeout: "Defines after how many minutes the screensaver will appear",
            searchPanelBase: "Defines whether base can be used for searching",
            searchPanelBaseInProductPackage: "Defines whether product package can be used for searching",
            searchPanelOrder: "Defines order of search panel inputs",
            searchPanelProduct: "Defines whether product can be used for searching",
            searchPanelWithoutConfirmation:
                "Defines whether formula loads automatically after having all information (no need to click “load” button)",
            settingsActiveFormulaUnit:
                "Defines the last used unit of colorants on Formula panel, it is prioritised to formulaUnit – it changes with the use of %{software} (it remembers the last setting)",
            settingsBlendFormulaAndCode:
                "Defines whether formula name and formula code are merged into one search field, applicable only if “formulaName” and “formulaCode” are both true",
            settingsCleaningTime: "Defines the time of cleaning (service action) in seconds",
            settingsCustomHomeButton:
                "Defines home scene for Light (what is displayed after clicking on the Home button)",
            settingsInfoItemInRgbPreview: "Defines which information is visible in the color preview on Formula panel",
            settingsManualAdditionUnit:
                "Defines the last used unit in manual addition pop-up window – it changes with the use of %{software} (it remembers the last setting)",
            settingsRecirculationSpeed: "Defines the default speed of recirculation (service action) in percent",
            settingsRecirculationTime: "Defines the default time of recirculation (service action) in seconds",
            settingsStirringSpeed: "Defines the default speed of recirculation (service action) in percent",
            settingsStirringTime: "Defines the default time of stirring (service action) in seconds",
            shotsCoef: "Defines shots coefficient used for recalculation of ml to shots",
            shotsName: "Defines name of shots visible on multiple places throughout the software",
            softwareName: "Defines name of the software",
            softwareNameLight: "Defines name of the Light version of the software",
            soundEnabled: "Defines whether sound is enabled when new order is received",
            spectroEnabled: "Defines whether spectro is enabled, influences the entire spectro section and settings",
            style: "Defines the custom style definition",
            tableBase:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Base is shown",
            tableCustomer:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Customer is shown",
            tableDate:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Date is shown",
            tableFandeck:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Fandeck is shown",
            tableFormulaCode:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Formula code is shown",
            tableFormulaName:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Formula name is shown",
            tableMoreInfo:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Note is shown",
            tablePackage:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Package is shown",
            tableProduct:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Product is shown",
            tableQuantity:
                "Defines whether in the tables in Dispensing History, My orders, and To Dispense, the column Quantity is shown",
            tabsEnabled: "Defines whether work with tabs is allowed or if only one tab is possible at a time",
            ufiCodeBaseValidation: "Defines whether base UFI code is requested when dispensing",
            ufiCodeLogic:
                "Defines whether UFI codes are enabled. And if so, which UFI codes are used when printing and shown in %{software}",
            ufiCodeMinAmountGravPercent:
                "Defines the threshold for colorant amount. After exceeding this limit, UFI codes are printed on the label",
            ufiCodeMinGravPercent:
                "Defines the threshold for colorant amount. After exceeding this limit, UFI codes are printed on the label including their percentage",
            userIdentification:
                "Defines whether the user has to be authorised (typing the user password) before dispensing",
            visibleUnits: "Defines which units are visible for colorants on Formula panel",
            virtualKeyboardEnabled:
                "Defines whether the virtual keyboard is enabled, used in Light and manual barcode insertion",
            zoom: "Defines zoom of the software, helps customise the software based on screen resolution"
        },
        deleteConfirmation: "Show confirmation dialog before deleting items",
        editOption: "Edit option",
        environmentLanguage: "Language of the environment",
        environmentalSettings: "Environmental settings",
        globalSettings: "Global default setting",
        languages: {
            english: "English",
            german: "German"
        },
        language: "Language",
        useLargoNames: "Show Largo names",
        lockCaption: "Unlock the item",
        translations: {
            fileTypeCsv: "CSV",
            fileTypeCsvNew: "CSV - only new",
            manageTranslations: "Manage translations"
        },
        useSources: "Use sources",
        useTmcNames: "Show TMC names",
        userSettings: "Custom user settings",
        setAllToDefaultValues: "Set all to default values",
        showDefaultValues: "Show default values",
        showFilterRow: "Show/hide filter row",
        showGlobalSearch: "Show/hide global search"
    },
    package: {
        addPackage: "Add package",
        editPackage: "Edit package",
        selectPackages: "Select packages",
        package: "Package",
        packageName: "Package name",
        packages: "Packages"
    },
    prices: {
        changeMainSettings: "Change main settings",
        colorant: "Colorant",
        defaultPrices: "Default prices",
        defaultPriceColorantCost: "Colorant - Company cost",
        defaultPriceColorantPurchase: "Colorant - Purchase price",
        defaultPriceColorantSell: "Colorant - Sell price",
        defaultPriceColorantVat: "Colorant VAT",
        defaultPricePackageSurcharge: "Product package surcharge",
        defaultPriceProductCost: "Product - Company cost",
        defaultPriceProductPurchase: "Product - Purchase price",
        defaultPriceProductSell: "Product - Sell price",
        defaultPriceProductVAT: "Product VAT",
        discount: "Discount",
        maxValue: "Max value",
        margin: "Margin",
        markup: "Markup",
        optionCompanyCost: "Company cost",
        optionCompanyCostYes: "Show company cost",
        optionCompanyCostNo: "Company cost is hidden",
        optionCompanyCostCaption: "Enable edit company cost",
        optionDefinition: "System of price editing",
        optionDefinitionBothPurchase: "Show purchase and sell price (primary purchase)",
        optionDefinitionBothSell: "Show purchase and sell price (primary sell)",
        optionDefinitionPurchase: "Show only purchase price",
        optionDefinitionSell: "Show only sell price",
        optionPriceCalculation: "Price calculation",
        optionPriceCalculationDiscount: "Show discount",
        optionPriceCalculationDiscountMarginMarkup: "Show margin, markup and discount",
        optionPriceCalculationMarginDiscount: "Show margin and discount",
        optionPriceCalculationMarginMarkup: "Show margin and markup",
        optionPriceCalculationMargin: "Show margin",
        optionPriceCalculationMarkupDiscount: "Show markup and discount",
        optionPriceCalculationMarkup: "Show markup",
        optionPriceCalculationNothing: "Disabled",
        optionPriceGroups: "Price groups",
        optionPriceGroupsNumber: "Number of groups",
        optionPriceGroupsYes: "Using price groups is enabled (%{count} groups)",
        optionPriceGroupsNo: "Price groups are disabled",
        optionPriceGroupsCaption: "Using price groups",
        packageSurcharge: "Package surcharge",
        percentValueBase: "Value base",
        percentValueColorant: "Value colorant",
        priceGroupNumber: "Price group %{number}",
        priceGroups: "Price groups",
        priceSettings: "Price settings",
        priceSourceCustomValue: "Custom item value",
        priceSourceInheritedBase: "Inherited from base",
        priceSourceInheritedDefault: "Inherited from default",
        priceSourceInheritedProduct: "Inherited from product",
        priceSourceInheritedSystemDefault: "Inherited from system default",
        priceSourceInheritedSystemItem: "Inherited from system %{item}",
        price: "Price",
        priceOption: "Price option",
        prices: "Prices",
        product: "Product",
        propertyCompanyCostPrice: "Company cost",
        propertyPurchasePrice: "Purchase price",
        propertyPurchasePriceGroup: "Price group price",
        propertySellPrice: "Sell price",
        propertySurcharge: "Package surcharge",
        propertyVatPrice: "VAT",
        rangeFrom: "Range from",
        rangeTo: "Range to",
        surcharge: "Surcharge"
    },
    product: {
        addProduct: "Add product",
        addBaseInProduct: "Add base in product",
        addBaseInProductPackage: "Add base in product package",
        baseInProductPackages: "Packages for base in product",
        baseInProductPackage: "Package for base in product",
        baseInProduct: "Base in product",
        basesInProduct: "Bases in product",
        colorantMaxAmount: "Maximum colorants amount",
        colorantMinAmount: "Minimum colorants amount",
        customFormula: "Custom formula",
        customFormulaCaption: "Available for custom formulas",
        duplicateProduct: "Duplicate product",
        editBaseInProduct: "Edit base in product",
        editBaseInProductPackage: "Edit base in product package %{packageName}",
        editProduct: "Edit product",
        inheritProductSystem: "Inherit bases specified for product in master",
        inheritProductZone: "Inherit bases and packages specified for product in system",
        noProductAvailable: "There is no product available",
        productNotSelected: "Product is not selected",
        forcedFormulaBaseAmountType: "Forced formula base amount type",
        checkColorantsFilling: "Check colorants filling",
        packageBaseName: "Package base name",
        product: "Product",
        productId: "Product ID",
        productNameInSystem: "Product name in system",
        productNameInZone: "Product name in zone",
        productSources: "Product sources",
        products: "Products",
        productFiles: "Product files",
        selectFromBaseInProducts: "Select from base in products",
        selectFromBaseInProductPackages: "Select from base in product packages",
        selectFromProducts: "Select from products",
        selectProduct: "Select product",
        selectProducts: "Select products",
        selectedBaseInProducts: "Selected base in products",
        selectedBaseInProductPackages: "Selected base in product packages",
        selectedProducts: "Selected products"
    },
    productGroup: {
        addProductGroup: "Add product group",
        editProductGroup: "Edit product group",
        productGroup: "Product group",
        productGroups: "Product groups",
        productGroupForProduct: "Product groups for product %{name}",
        selectFromProductGroups: "Select from groups",
        selectProductGroups: "Select product groups",
        selectedProductGroups: "Selected groups"
    },
    propertyHint: {
        databaseCompany:
            "What company the master database belongs to. %{software} licensed under other companies cannot read this database. Not shown in %{software}.",
        databaseDefaultData: "",
        databaseImportData: "",
        databaseMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        databaseName:
            "Name of the master database which is used to create various systems (System & Zone settings). Not shown in %{software}.",
        databasePassword:
            "The same password must be always used when working with one master database. The password cannot be changed. Not shown in %{software}.",
        databaseUuid:
            "Master UUID is automatically generated on %{thisSoftware} to distinguish individual master databases. Not shown in %{software}.",
        systemName:
            "Name of the system which can contain various zones (System & Zone settings). Not shown in %{software}.",
        systemUuid:
            "System UUID is automatically generated by %{thisSoftware} to distinguish individual systems. Not shown in %{software}.",
        zoneCurrency:
            "Primary currency of the zone database. Currencies are created in the section Currencies (Generic data). Shown in %{software} (prices, currency).",
        zoneChangelog: "List of changes compared to the previous zone database. Shown in %{software} (databases).",
        zoneIcon: "Icon shown in %{redlikeHelper}.",
        zoneImage: "Image shown in %{software} when switching databases.",
        zoneName: "Name of the zone to which the zone database belongs. Shown in %{software} (databases).",
        zonePassword:
            "Password for %{software} to open the database. It must be always the same and without mistakes for all databases of the same zone. Not shown in %{software}.",
        zoneStaticSql:
            "Provides additional settings to the zone database and is administrated in the section Static SQL (Generic data). Used in %{software}.",
        zoneUuid:
            "Zone UUID (identificator) generated on %{redlikeWeb}. It must always be the same and without mistakes to perform online updates successfully. Avoid white spaces. Not shown in %{software}.",
        zoneVersion:
            "Use four numbers divided by dots, e.g. 1.0.0.0 and increase versioning incrementally. Shown in %{software} (databases).",
        zoneVersionName:
            "Provides additional sorting, can be used for example for date of the database creation, official/testing databases, etc. Shown in %{software} (databases).",
        importAction: "",
        importDatabaseName: "Selected during the import task creation.",
        importDateCreated:
            "Time and date when the zone database started being created. Shown in %{software} (databases).",
        importDateFinished:
            "Time and date when the export of the zone database was finished. Not shown in %{software}.",
        importStatus: "Import processing status for all past and running database imports.",
        importSystemName: "Selected during the import task creation.",
        importSystemUuid: "Selected based on the zone.",
        importType: "",
        importZoneName: "Selected during the import task creation.",
        importZoneUuid: "Selected based on the zone.",
        exportStatus: "Export processing status for all past and running database exports.",
        exportAction: "Actions available based on the export result.",
        exportDatabase: "Master selected based on the system choice for the export task. Not shown in %{software}.",
        exportSystemName: "System under which the exported zone database belongs. Not shown in %{software}.",
        exportSystemUuid: "The correct system UUID is generated based on the chosen system. Not shown in %{software}.",
        exportZoneName: "Zone for which the zone database is generated. Shown in %{software} (databases).",
        exportZoneUuid: "The correct zone UUID is generated based on the chosen zone. Not shown in %{software}.",
        exportType:
            "Export type based on further steps required for the database. Archives include main.db as well as local.db and db.ini.",
        exportVersion:
            "Version defined for the given zone database (System & Zone settings) or defined while creating the export task. Shown in %{software} (databases).",
        exportVersionName:
            "The user identification of the database version. This text is shown in %{software} (databases).",
        exportChangelog:
            "Changelog written for the given zone database (System & Zone settings) or defined while creating the export task. Shown in %{software} (databases).",
        exportDateCreated:
            "Time and date when the zone database started being created. Shown in %{software} (databases).",
        exportDateFinished:
            "Time and date when the export of the zone database was finished. Not shown in %{software}.",
        masterBaseName: "Master name of the base, can be changed for systems and zones.",
        masterBasePrintName:
            "The base name will be printed out on the label instead of the name defined in the column Name. Can be changed for systems and zones.",
        masterBaseSearchCode:
            "Master search code of the base can be changed for systems and zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        masterBaseSearchName:
            "Master search name of the base can be changed for systems and zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        masterBaseTmcName: "Master base name shown in TMC, can be changed for systems and zones.",
        masterBaseCode: "Master code of the base, can be changed for systems and zones.",
        masterBaseMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        masterBasePriority:
            "Priority sets the order in which the bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. Can be changed for systems and zones.",
        masterColorantBfs: "German quality value, can be changed for systems and zones.",
        masterColorantBlue: "",
        masterColorantCode: "Master code of the colorant, can be changed for systems and zones.",
        masterColorantDensity:
            "Density (specific weight) of the colorant is used to calculate gravimetric units. Can be changed for systems and zones.",
        masterColorantFiles: "",
        masterColorantGreen: "",
        masterColorantMinDosage:
            "The minimal dispensing amount allowed for the colorant. Can be changed for systems and zones.",
        masterColorantMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        masterColorantName: "Master name of the colorant, can be changed for systems and zones.",
        masterColorantPrintName:
            "The colorant name will be printed out on the label instead of the name defined in the column Name. Can be changed for systems and zones.",
        masterColorantPriority:
            "Priority sets the order in which the colorants are shown in %{software}. If not set, colorants are ordered based on their position in formula. The higher number, the higher priority. Can be changed for systems and zones.",
        masterColorantRed: "",
        masterColorantRgb:
            "Long RGB computed automatically from the given R, G, B values. Can be changed for systems and zones.",
        masterColorantSearchCode: "",
        masterColorantSearchName: "",
        masterColorantTmcName: "Master colorant name shown in TMC, can be changed for systems and zones.",
        masterColorantUfiCode: "",
        masterColorBarcode: "",
        masterColorBlue: "",
        masterColorCode: "Master code of the color, can be changed for systems and zones.",
        masterColorFandeckPage: "",
        masterColorFandeckPosition: "",
        masterColorGreen: "",
        masterColorMoreInfo: "",
        masterColorName: "Master name of the color, can be changed for systems and zones.",
        masterColorPriority: "",
        masterColorRed: "",
        masterColorReflectanceData: "",
        masterColorRgbHex: "",
        masterColorSearchCode: "",
        masterColorSearchName: "",
        masterColorTmcName: "Master color name shown in TMC, can be changed for systems and zones.",
        masterColorWavelength: "",
        masterColorWithFormulaInfoCode: "Master code of the formula, can be changed for systems and zones.",
        masterColorWithFormulaInfoName: "Master name of the formula, can be changed for systems and zones.",
        masterColorWithFormulaInfoStatus:
            "Current status of the formula. Possible statuses are accepted, removed, and historical.",
        masterProductName: "Master name of the product, can be changed for systems and zones.",
        masterProductSearchName:
            "Master search name of the product, can be changed for systems and zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        masterProductTmcName: "Master product name shown in TMC, can be changed for systems and zones.",
        masterProductCode: "Master code of the product, can be changed for systems and zones.",
        masterProductFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for system and zones and for specific packages.",
        masterProductImage:
            "General images of products. Images are administered in the section Images (Generic data) and can be changed for system and zones and for specific packages.",
        masterProductMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        masterProductPrintName:
            "The product name will be printed out on the label instead of the name defined in the column Name. Can be changed for systems and zones.",
        masterProductPriority:
            "Priority sets the order in which the products are shown in %{software}. If not set, products are ordered alphabetically. The higher number, the higher priority. Can be changed for systems and zones.",
        masterProductSearchCode:
            "Master search code of the product, can be changed for systems and zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        masterProductBaseBfs: "German quality value. Can be changed for systems and zones.",
        masterProductBaseCode: "Master code of the product base, can be changed for systems and zones.",
        masterProductBaseCoefficient:
            "If not set, automatically set to 1. Base coefficient for formula multiplication. Can be changed for systems and zones.",
        masterProductBaseCustomFormula:
            "Definition whether for the product base, custom formulas can be created in %{software}. Can be changed for systems and zones.",
        masterProductBaseDensity: "Density (specific weight) of the base can be changed for systems and zones.",
        masterProductBaseFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for system and zones and for specific packages.",
        masterProductBaseFillProc:
            "Per cent value of how much base from nominal amount is actually filled in. Can be changed for systems and zones.",
        masterProductBaseForcedFormulaBaseAmountType: "",
        masterProductBaseImage:
            "General images of bases. Images are administered in the section Images (Generic data) and can be changed for systems and zones, and for specific base packages.",
        masterProductBaseMaxColorantsAmount:
            "The maximum colorant amount allowed for the product base. Based on %{software} settings, dispensing above this amount might be forbidden. Can be changed for systems and zones.",
        masterProductBaseMinColorantsAmount:
            "The minimum colorant amount allowed for the product base. Based on %{software} settings, dispensing below this amount might be forbidden. Can be changed for systems and zones.",
        masterProductBaseMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        masterProductBaseName:
            "Master name of the product base. Master bases are administered in the section Bases (Master) and can be changed for systems and zones.",
        masterProductBasePriority:
            "Priority sets the order in which the product bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. Can be changed for systems and zones.",
        masterProductBaseUfiCode: "",
        masterFandeckBarcode:
            "Barcode to search for fandeck in %{software} by quick barcode scanning, can be changed for systems and zones.",
        masterFandeckCode: "Master code of the fandeck, can be changed for systems and zones.",
        masterFandeckMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        masterFandeckName: "Master name of the fandeck, can be changed for systems and zones.",
        masterFandeckPrintName:
            "The fandeck name will be printed out on the label instead of the name defined in the column Name. Can be changed for systems and zones.",
        masterFandeckPriority:
            "Priority sets the order in which the fandecks are shown in %{software}. If not set, fandecks are ordered alphabetically. The higher number, the higher priority. Can be changed for systems and zones.",
        masterFandeckSearchCode:
            "Master search code of the fandeck can be changed for systems and zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        masterFandeckSearchName:
            "Master search name of the fandeck can be changed for systems and zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        masterFandeckTmcName: "Master fandeck name shown in TMC, can be changed for systems and zones.",
        masterFormulaNoteCode:
            "Code for simple recognition of the formula note. One formula can contain multiple formula notes.",
        masterFormulaNoteText:
            "Full formula note text to be written in %{software} when choosing a formula. Can be changed for systems and zones.",
        masterFormulaNoteImage:
            "Image to be shown in %{software} as representation of a certain formula note. Images are administered in the section Images (Generic data) and can be changed for systems and zones.",
        systemBaseCode: "System code of the base, can be changed for zones.",
        systemBaseMasterName: "Master base name taken from the Bases data (Master).",
        systemBaseMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        systemBaseName: "System name of the base, can be changed zones.",
        systemBasePrintName:
            "The base name will be printed out on the label instead of the name defined in the column Name. Can be changed for zones.",
        systemBasePriority:
            "Priority sets the order in which the bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. Can be changed for zones.",
        systemBaseSearchCode:
            "System search code of the base can be changed for zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        systemBaseSearchName:
            "System search name of the base can be changed for zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        systemBaseTmcName: "System  base name shown in TMC, can be changed for zones.",
        systemColorantBfs: "German quality value. Can be changed for zones.",
        systemColorantCode: "System code of the colorant, can be changed for zones.",
        systemColorantDensity:
            "Density (specific weight) of the colorant is used to calculate gravimetric units. Can be changed for zones.",
        systemColorantMasterName: "Master colorant name taken from the Colorants data (Master).",
        systemColorantMinDosage: "The minimal dispensing amount allowed for the colorant. Can be changed for zones.",
        systemColorantMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        systemColorantName: "System name of the colorant, can be changed for zones.",
        systemColorantPriceCost:
            "Manufacturing cost of the colorant, can be changed for zones. General prices are administered in the section Prices (System). Not shown in %{software}.",
        systemColorantPricePurchase:
            "POS purchase price of the colorant including the manufacturer margin, can be changed for zones. General prices are administered in the section Prices (System). Shown in %{software}.",
        systemColorantPricePurchasePriceGroup:
            "Colorant price used for price group calculation, can be changed for zones. Price group range is administered in the section Prices (System). Shown and used in %{software}.",
        systemColorantPriceSell:
            "POS sell price of the colorant including the POS margin, can be changed for zones. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemColorantPriceVat:
            "VAT set on the colorant, can be changed for zones. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemColorantPrintName:
            "The colorant name will be printed out on the label instead of the name defined in the column Name. Can be changed for zones.",
        systemColorantPriority:
            "Priority sets the order in which the colorants are shown in %{software}.  If not set, colorants are ordered based on their position in formula. The higher number, the higher priority. Can be changed for zones.",
        systemColorantRgb: "Long RGB computed automatically from the given R, G, B values. Can be changed for zones.",
        systemColorantTmcName: "System  colorant name shown in TMC, can be changed for zones.",
        systemColorantUfiCode: "",
        systemColorantPackageName:
            "Name of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        systemColorantPackageUnit:
            "Unit of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        systemColorantPackageNominalAmount:
            "Nominal amount of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        systemColorantPackageGravimetric:
            "Indication whether the measurement unit of the colorant package is gravimetric or volumetric taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        systemColorantPackageMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        systemColorantPackageFillProc:
            "Per cent value of how much colorant from nominal amount of the colorant package is actually refilled. Can be changed for zones.",
        systemColorantPackageFillVolume:
            "Volume calculated based on the filling percent. Shown in %{software} (refill).",
        systemColorantPackageBarcode:
            "Barcode of the colorant package for refill validation. Can be changed for zones.",
        systemColorantPackageImage:
            "Image of the colorant package shown in %{software} during refill. Images are administered in the section Images (Generic data) and can be changed for zones.",
        systemProductMasterName: "Master product name taken from the Products data (Master).",
        systemProductName: "System name of the product, can be changed zones.",
        systemProductSearchName:
            "System search name of the product, can be changed for zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        systemProductTmcName: "System  product name shown in TMC, can be changed for zones.",
        systemProductCode: "System code of the product, can be changed for zones.",
        systemProductSearchCode:
            "System search code of the product, can be changed for zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        systemProductMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        systemProductPrintName:
            "The product name will be printed out on the label instead of the name defined in the column Name. Can be changed for zones.",
        systemProductPriority:
            "Priority sets the order in which the products are shown in %{software}. If not set, products are ordered alphabetically. The higher number, the higher priority. Can be changed for zones.",
        systemProductImage:
            "General images of products. Images are administered in the section Images (Generic data) and can be changed for zones, and for specific base packages.",
        systemProductGroups:
            "Product groups to which the product belongs. Product groups are administered in the section Product groups (System) and can be changed for zones.",
        systemProductFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for zones and for specific bases and packages.",
        systemProductPriceCost:
            "Manufacturing cost of the product, can be changed for zones and for specific bases and packages. General prices are administered in the section Prices (System). Not shown in %{software}.",
        systemProductPricePurchase:
            "POS purchase price of the product including the manufacturer margin, can be changed for zones and for specific bases and packages. General prices are administered in the section Prices (System). Shown in %{software}.",
        systemProductPriceSell:
            "POS sell price of the product including the POS margin, can be changed for zones and for specific bases and packages. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductPriceVat:
            "VAT set on the product, can be changed for zones and for specific bases and packages. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductBaseName:
            "System name of the product base. System bases are administered in the section Bases (Master) and can be changed for systems and zones.",
        systemProductBaseCode: "System code of the product base, can be changed for zones.",
        systemProductBaseCoefficient:
            "If not set, automatically set to 1. Base coefficient for formula multiplication. Can be changed for zones.",
        systemProductBaseCustomFormula:
            "Definition whether for the product base, custom formulas can be created in %{software}. Can be changed for zones.",
        systemProductBaseDensity: "Density (specific weight) of the base can be changed for zones.",
        systemProductBaseFillProc:
            "Per cent value of how much base from nominal amount is actually filled in. Can be changed for zones.",
        systemProductBaseFormulaBaseAmountType: "",
        systemProductBaseBfs: "German quality value. Can be changed for zones.",
        systemProductBaseMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        systemProductBasePriority:
            "Priority sets the order in which the product bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. Can be changed for zones.",
        systemProductBaseUfiCode: "",
        systemProductBaseImage:
            "General images of bases. Images are administered in the section Images (Generic data) and can be changed for zones, and for specific base packages.",
        systemProductBaseFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for zones and for specific packages.",
        systemProductBaseMaxColorantsAmount:
            "The maximum colorant amount allowed for the product base. Based on %{software} settings, dispensing above this amount might be forbidden. Can be changed for zones.",
        systemProductBaseMinColorantsAmount:
            "The minimum colorant amount allowed for the product base. Based on %{software} settings, dispensing below this amount might be forbidden. Can be changed for zones.",
        systemProductBasePriceCost:
            "Manufacturing cost of the base, can be changed for zones and for specific packages. General prices are administered in the section Prices (System). Not shown in %{software}.",
        systemProductBasePricePurchase:
            "POS purchase price of the base including the manufacturer margin, can be changed for zones and for specific packages. General prices are administered in the section Prices (System). Shown in %{software}.",
        systemProductBasePriceSell:
            "POS sell price of the base including the POS margin, can be changed for zones and for specific packages. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductBasePriceVat:
            "VAT set on the base, can be changed for zones and for specific packages. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductPackageCode: "System code of the  product package, can be changed for zones.",
        systemProductPackageMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        systemProductPackageName:
            "Name of the product package taken from the defined Packages (Generic data). Shown in %{software} (packages).",
        systemProductPackageNominalAmount:
            "Nominal amount of the product package taken from the defined Packages (Generic data). Shown in %{software} (formula data).",
        systemProductPackageFillVolume:
            "Volume calculated based on the filling percent. Shown in %{software} (formula data).",
        systemProductPackageFillProc:
            "Per cent value of how much base from nominal amount of the product package is actually refilled. Can be changed for zones.",
        systemProductPackageBarcode: "Barcode of the product package for base validation. Can be changed for zones.",
        systemProductPackageImage:
            "Image of the product package shown in %{software} (package preview). Images are administered in the section Images (Generic data) and can be changed for zones.",
        systemProductPackageFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for zones.",
        systemProductPackagePriceCost:
            "Manufacturing cost for the package, can be changed for zones. General prices are administered in the section Prices (System). Not shown in %{software}.",
        systemProductPackagePricePurchase:
            "POS purchase price for the package including the manufacturer margin, can be changed for zones. General prices are administered in the section Prices (System). Shown in %{software}.",
        systemProductPackagePriceSell:
            "POS sell price for the package including the POS margin, can be changed for zones. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductPackagePriceSurcharge:
            "This value in the selected currency defines an additional manipulation fee charged for the given base in product package. The package surcharge can be changed for zone.",
        systemProductPackagePriceVat:
            "VAT set on the package, can be changed for zones. General prices are administered in the section Prices (System). Shown and used in %{software}.",
        systemProductPackagePrintName:
            "The product package name will be printed out on the label instead of the name defined in the column Name. Can be changed for zones.",
        systemProductPackageSearchName:
            "System search name of the product package, can be changed for zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        systemProductGroupName:
            "Product groups serve to organisational purposes. Various products can belong to various product groups. Can be changed for zones.",
        systemProductGroupPriority:
            "Priority sets the order in which the product groups are shown in %{software}. If not set, product groups are ordered alphabetically. The higher number, the higher priority. Can be changed for zones.",
        systemProductGroupMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        systemFandeckMasterName: "Master fandeck taken from the Fandecks data (Master).",
        systemFandeckName: "System name of the fandeck, can be changed for zones.",
        systemFandeckSearchName:
            "System search name of the fandeck can be changed for zones. Search name is only visible in %{software} after searching for it, otherwise only name is visible.",
        systemFandeckCode: "System code of the fandeck, can be changed for zones.",
        systemFandeckSearchCode:
            "System search code of the fandeck can be changed for zones. Search code is only visible in %{software} after searching for it, otherwise only code is visible.",
        systemFandeckTmcName: "System  fandeck name shown in TMC, can be changed for zones.",
        systemFandeckMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        systemFandeckBarcode:
            "Barcode to search for fandeck in %{software} by quick barcode scanning, can be changed for zones.",
        systemFandeckPrintName:
            "The fandeck name will be printed out on the label instead of the name defined in the column Name. Can be changed for zones.",
        systemFandeckPriority:
            "Priority sets the order in which the fandecks are shown in %{software}. If not set, fandecks are ordered alphabetically. The higher number, the higher priority. Can be changed for zones.",
        systemFormulaNoteCode: "Master formula code taken from the Formula notes data (Master).",
        systemFormulaNoteMasterText: "Master text taken from the Formula notes data (Master).",
        systemFormulaNoteText:
            "Full formula note text to be written in %{software} when choosing a formula. Can be changed for zones.",
        systemFormulaNoteImage:
            "Image to be shown in %{software} as representation of a certain formula note. Images are administered in the section Images (Generic data) and can be changed for zones.",
        systemPackageName:
            "General packages. Packages are administered in the section Packages (Generic data) and can be changed for zone.",
        systemPackageSurcharge:
            "This value in the selected currency defines and additional manipulation fee charged for the given package type. The package surcharge can be changed for zone.",
        systemPriceDefaultName:
            "General pricing of all items in all available price types. Prices can be locked which removes the possibility to edit the prices directly in %{software}.",
        systemPriceDefaultValue:
            "The price value is calculated for 1 L in the selected currency. VAT is given in % and product package surcharge counts for quantity. The prices can be changed for individual items and for zone.",
        systemPriceGroupName:
            "The ordinal number of the price group. Typically, the lower the group, the lower prices required. Price group prices can be changed for individual colorants and for zone.",
        systemPriceGroupRangeFrom:
            "The minimal price of colorants which must be achieved to classify for this price group. The minimal boundary can be changed for zone.",
        systemPriceGroupRangeTo:
            "The maximal price of colorants which might be achieved to classify for this price group. Anything higher will belong to the following price group. The maximal boundary can be changed for zone.",
        systemPriceMarginName: "The name of the item which can be changed for zone.",
        systemPriceMarginType: "Margin and markup add to the price whereas discount substracts from it.",
        systemPriceMarginValueBase:
            "How much % of the product base price should be added/substracted. The value can be changed for zone.",
        systemPriceMarginValueColorant:
            "How much % of the product colorant price should be added/substracted. The value can be changed for zone.",
        systemPriceMarginPriority:
            "Priority sets the order in which the margins/markups/discounts are shown in %{software}. If not set, they are ordered chronologically. The higher number, the higher priority. Can be changed for zones.",
        wavelengthBeginValue: "",
        wavelengthEndValue: "",
        wavelengthInterval: "",
        zoneColorantMasterName: "Master colorant name taken from the Colorants data (Master).",
        zoneColorantSystemName: "System colorant name taken from the Colorants data (System).",
        zoneColorantName: "Zone colorant name. This is the final colorant name. Shown in %{software}.",
        zoneColorantCode: "Zone code of the colorant. This is the final colorant code. Not shown in %{software}.",
        zoneColorantTmcName: "Zone  colorant name shown in TMC. This is the final TMC name. Shown in TMC.",
        zoneColorantUfiCode: "",
        zoneColorantRgb:
            "Long RGB computed automatically from the given R, G, B values. This is the final RGB. Shown in %{software} (formula data).",
        zoneColorantDensity:
            "Density (specific weight) of the colorant is used to calculate gravimetric units. This is the final density. Used in %{software}.",
        zoneColorantBfs: "German quality value. This is the final BFS. Shown in %{software} (formula data).",
        zoneColorantMinDosage:
            "The minimal dispensing amount allowed for the colorant. This is the final colorant minimal dosage amount. Used in %{software} (dispensing).",
        zoneColorantMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        zoneColorantPrintName:
            "The colorant name will be printed out on the label instead of the name defined in the column Name. This is the final colorant print name. Used and shown in %{software} (print).",
        zoneColorantPriority:
            "Priority sets the order in which the colorants are shown in %{software}.  If not set, colorants are ordered based on their position in formula. The higher number, the higher priority. This is the final colorants priority. Used in %{software}.",
        zoneColorantPriceCost:
            "Manufacturing cost of the colorant. General prices are administered in the section Prices (Zone). This is the final colorant company cost. Not shown in %{software}.",
        zoneColorantPricePurchase:
            "POS purchase price of the colorant including the manufacturer margin. General prices are administered in the section Prices (Zone). This is the final colorant purchase price. Shown in %{software}.",
        zoneColorantPricePurchasePriceGroup:
            "Colorant price used for price group calculation. Price group range is administered in the section Prices (Zone). This is the final colorant price group price. Shown and used in %{software}.",
        zoneColorantPriceSell:
            "POS sell price of the colorant including the POS margin. General prices are administered in the section Prices (Zone). This is the final colorant sell price. Shown and used in %{software}.",
        zoneColorantPriceVat:
            "VAT set on the colorant. General prices are administered in the section Prices (Zone). This is the final colorant VAT. Shown and used in %{software}.",
        zoneColorantPackageName:
            "Name of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        zoneColorantPackageUnit:
            "Unit of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        zoneColorantPackageNominalAmount:
            "Nominal amount of the colorant package taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        zoneColorantPackageGravimetric:
            "Indication whether the measurement unit of the colorant package is gravimetric or volumetric taken from the defined Packages (Generic data). Shown in %{software} (refill).",
        zoneColorantPackageMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        zoneColorantPackageFillProc:
            "Per cent value of how much colorant from nominal amount of the colorant package is actually refilled. This is the final filling setting. Used in %{software} (refill).",
        zoneColorantPackageFillVolume:
            "Volume calculated based on the filling percent. This is the final volume filling. Shown in %{software} (refill).",
        zoneColorantPackageBarcode:
            "Barcode of the colorant package for refill validation. Required in %{software} (refill).",
        zoneColorantPackageImage:
            "Image of the colorant package. This is the final colorant package image. Shown in %{software} (refill).",
        zoneBaseMasterName: "Master base name taken from the Bases data (Master).",
        zoneBaseSystemName: "System base name taken from the Bases data (System).",
        zonemBaseName: "Zone base name. This is the final base name. Shown in %{software} (base selection).",
        zoneBaseTmcName: "Zone base name shown in TMC. This is the final TMC name. Shown in TMC.",
        zoneBaseCode: "Zone code of the base. This is the final base code. Not shown in %{software}.",
        zoneBaseMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        zoneBasePriority:
            "Priority sets the order in which the bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. This is the final base priority. Used in %{software}.",
        zoneBasePrintName:
            "The base name will be printed out on the label instead of the name defined in the column Name. This is the final base print name. Used and shown in %{software} (print).",
        zoneBaseSearchCode:
            "Zone search code of the base. Search code is only visible in %{software} after searching for it, otherwise only code is visible. This is the final base search code. Used and shown in %{software} (base selection).",
        zoneBaseSearchName:
            "Zone search name of the base. Search name is only visible in %{software} after searching for it, otherwise only name is visible. This is the final base search name. Used and shown in %{software} (base selection).",
        zoneProductMasterName: "Master product name taken from the Products data (Master).",
        zoneProductSystemName: "System product name taken from the Products data (System).",
        zoneProductName: "Zone product name. This is the final product name. Shown in %{software} (product selection).",
        zoneProductSearchName:
            "Zone search name of the product. Search name is only visible in %{software} after searching for it, otherwise only name is visible. This is the final product search name. Used and shown in %{software}.",
        zoneProductTmcName: "Zone product name shown in TMC. This is the final TMC name. Shown in TMC.",
        zoneProductCode: "Zone code of the product. This is the final product code. Not shown in %{software}.",
        zoneProductSearchCode:
            "Zone search code of the product. Search code is only visible in %{software} after searching for it, otherwise only code is visible. This is the final product search code. Used and shown in %{software}.",
        zoneProductMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        zoneProductPrintName:
            "The product name will be printed out on the label instead of the name defined in the column Name. This is the final product print name. Used and shown in %{software} (print).",
        zoneProductPriority:
            "Priority sets the order in which the products are shown in %{software}. If not set, products are ordered alphabetically. The higher number, the higher priority. This is the final product priority. Used in %{software}.",
        zoneProductImage:
            "General images of products. Images are administered in the section Images (Generic data) and for specific base packages.",
        zoneProductGroups:
            "Product groups to which the product belongs. Product groups are administered in the section Product groups (Zone). This is the final product group. Shown in %{software}.",
        zoneProductFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for specific bases and packages.",
        zoneProductPriceCost:
            "Manufacturing cost of the product and can be changed for specific bases and packages. General prices are administered in the section Prices (Zone).",
        zoneProductPricePurchase:
            "POS purchase price of the product including the manufacturer margin and can be changed for specific bases and packages. General prices are administered in the section Prices (Zone).",
        zoneProductPriceSell:
            "POS sell price of the product including the POS margin. General prices are administered in the section Prices (Zone) and can be changed for specific bases and packages.",
        zoneProductPriceVat:
            "VAT set on the product. General prices are administered in the section Prices (Zone) and can be changed for specific bases and packages.",
        zoneProductBaseName:
            "Zone name of the product base. Zone bases are administered in the section Bases (Zone). This is the final product base name. Shown in %{software} (base selection).",
        zoneProductBaseCoefficient:
            "If not set, automatically set to 1. Base coefficient for formula multiplication. This is the final base coefficient. Used in %{software}.",
        zoneProductBaseDensity:
            "Density (specific weight) of the base for zone. This is the final product base density. Used in %{software}.",
        zoneProductBaseFillProc:
            "Per cent value of how much base from nominal amount is actually filled in. This is the final base filling. Used in %{software}.",
        zoneProductBaseForcedFormulasBaseAmountType: "",
        zoneProductBaseBfs: "German quality value. This is the final BFS. Shown in %{software} (formula data).",
        zoneProductBaseCode: "Zone code of the product base. Shown in %{software} (base selection).",
        zoneProductBaseMaxColorantsAmount:
            "The maximum colorant amount allowed for the product base. Based on %{software} settings, dispensing above this amount might be forbidden. This is the minimum colorants amount used in %{software} (dispensing).",
        zoneProductBaseMinColorantsAmount:
            "The minimum colorant amount allowed for the product base. Based on %{software} settings, dispensing below this amount might be forbidden. This is the final minimum colorants amount used in %{software} (dispensing).",
        zoneProductBaseMinCustomFormula:
            "Definition whether for the product base, custom formulas can be created in %{software}. This is the final custom formula definition. Used in %{software} (custom formula).",
        zoneProductBaseMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        zoneProductBasePriority:
            "Priority sets the order in which the product bases are shown in %{software}. If not set, bases are ordered alphabetically. The higher number, the higher priority. This is the final product base priority. Used in %{software}.",
        zoneProductBaseUfiCode: "",
        zoneProductBaseImage:
            "General images of bases. Images are administered in the section Images (Generic data) and can be changed for specific base packages.",
        zoneProductBaseFiles:
            "General product files. Product files are administered in the section Product files (Generic data) and can be changed for specific package.",
        zoneProductBasePriceCost:
            "Manufacturing cost of the base. General prices are administered in the section Prices (Zone) and can be changed for specific packages.",
        zoneProductBasePricePurchase:
            "POS purchase price of the base including the manufacturer margin. General prices are administered in the section Prices (Zone) and can be changed for specific packages.",
        zoneProductBasePriceSell:
            "POS sell price of the base including the POS margin. General prices are administered in the section Prices (Zone) and can be changed for specific packages.",
        zoneProductBasePriceVat:
            "VAT set on the base. General prices are administered in the section Prices (Zone) and can be changed for specific packages.",
        zoneProductPackageCode: "System code of the  product package, can be changed for zones.",
        zoneProductPackageMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        zoneProductPackageName:
            "Name of the product package taken from the defined Packages (Generic data). Shown in %{software} (packages).",
        zoneProductPackageNominalAmount:
            "Nominal amount of the product package taken from the defined Packages (Generic data). Shown in %{software} (formula data).",
        zoneProductPackageFillVolume:
            "Volume calculated based on the filling percent. Shown in %{software} (formula data).",
        zoneProductPackageFillProc:
            "Per cent value of how much base from nominal amount of the product package is actually refilled. This is the final product package filling. Used in %{software}.",
        zoneProductPackageBarcode:
            "Barcode of the product package for base validation. This is the final package barcode. Used in %{software}.",
        zoneProductPackageImage:
            "Image of the product package shown in %{software} (package preview). Images are administered in the section Images (Generic data). This is the final package image. Used in %{software}.",
        zoneProductPackageFiles:
            "General product files. Product files are administered in the section Product files (Generic data). These are the final files assigned to the product package. Shown in %{software}.",
        zoneProductPackagePriceCost:
            "Manufacturing cost for the package. General prices are administered in the section Prices (Zone). This is the final company cost for the product base package. Not shown in %{software}.",
        zoneProductPackagePricePurchase:
            "POS purchase price for the package including the manufacturer margin. General prices are administered in the section Prices (Zone). This is the final purchase price for the product base package. Shown and used in %{software}.",
        zoneProductPackagePriceSell:
            "POS sell price of the package including the POS margin. General prices are administered in the section Prices (Zone). This is the final sell price for the product base package. Shown and used in %{software}.",
        zoneProductPackagePriceSurcharge:
            "This value in the selected currency defines an additional manipulation fee charged for the given base in product package. This is the final package surcharge. Used and shown in %{software} (prices).",
        zoneProductPackagePriceVat:
            "VAT set on the package. General prices are administered in the section Prices (Zone). This is the final VAT for the product base package. Shown and used in %{software}.",
        zoneProductPackagePrintName:
            "The base in product package name will be printed out on the label instead of the name defined in the column Package base name. This is the final base in product package name. Used in %{software} (print).",
        zoneProductPackageSearchName:
            "Zone search name of the base in product package. Search name is only visible in %{software} after searching for it, otherwise only name is visible. This is the final base in product package. Used and shown in %{software} (base selection).",
        zoneProductGroupName: "System product group name taken from the Product groups data (System).",
        zoneProductGroupSystemName:
            "Product groups serve to organisational purposes. Various products can belong to various product groups. This is the final product group name. Shown in %{software}.",
        zoneProductGroupPriority:
            "Priority sets the order in which the product groups are shown in %{software}. If not set, product groups are ordered alphabetically. The higher number, the higher priority. This is the final product group priority. Used in %{software}.",
        zoneProductGroupMoreInfo:
            "Any additional information for the database administrator. Not shown in %{software}.",
        zoneFandeckMasterName: "Master fandeck name taken from the Fandecks data (Master).",
        zoneFandeckSystemName: "System fandeck name taken from the Fandecks data (System).",
        zoneFandeckName: "Zone fandeck name. This is the final fandeck name. Shown in %{software}.",
        zoneFandeckSearchName:
            "Zone search name of the fandeck. Search name is only visible in %{software} after searching for it, otherwise only name is visible. This is the final fandeck search name. Used and shown in %{software}.",
        zoneFandeckCode: "Zone code of the fandeck. This is the final fandeck code. Not shown in %{software}.",
        zoneFandeckPrintName:
            "The fandeck name will be printed out on the label instead of the name defined in the column Name. This is the final fandeck print name. Used and shown in %{software} (print).",
        zoneFandeckSearchCode:
            "Zone search code of the fandeck. Search code is only visible in %{software} after searching for it, otherwise only code is visible. This is the final fandeck search code. Used and shown in %{software}.",
        zoneFandeckTmcName: "Zone  fandeck name shown in TMC. This is the final TMC name. Shown in TMC.",
        zoneFandeckMoreInfo: "Any additional information for the database administrator. Not shown in %{software}.",
        zoneFandeckBarcode:
            "Barcode to search for fandeck in %{software} by quick barcode scanning. This is the final fandeck barcode. Used in %{software}.",
        zoneFandeckPriority:
            "Priority sets the order in which the fandecks are shown in %{software}. If not set, fandecks are ordered alphabetically. The higher number, the higher priority. This is the final fandeck priority. Used in %{software}.",
        zoneFormulaNoteCode: "Master formula code taken from the Formula notes data (Master).",
        zoneFormulaNoteMasterText: "Master text taken from the Formula notes data (Master).",
        zoneFormulaNoteSystemText: "System text taken from the Formula notes data (System).",
        zoneFormulaNoteText:
            "Full formula note text to be written in %{software} when choosing a formula. This is the final formula note text. Shown in %{software}.",
        zoneFormulaNoteImage:
            "Image to be shown in %{software} as representation of a certain formula note. Images are administered in the section Images (Generic data). This is the final formula note image. Shown in %{software}.",
        zonePackageName:
            "General packages. Packages are administered in the section Packages (Generic data). These are the final packages used in zone. Shown and used in %{software}.",
        zonePackageSurcharge:
            "This value in the selected currency defines and additional manipulation fee charged for the given package type. This is the final package surcharge. Shown and used in %{software}.",
        zonePriceDefaultName:
            "General pricing of all items in all available price types. Prices can be locked which removes the possibility to edit the prices directly in %{software}.",
        zonePriceDefaultValue:
            "The price value is calculated for 1 L in the selected currency. VAT is given in % and product package surcharge counts for quantity. The prices can be changed for individual items.",
        zonePriceGroupName:
            "The price value is calculated for 1 L in the selected currency. VAT is given in % and product package surcharge counts for quantity. The prices can be changed for individual items.",
        zonePriceGroupRangeFrom:
            "The minimal price of colorants which must be achieved to classify for this price group. This is the final minimal price boundary. Shown and used in %{software}.",
        zonePriceGroupRangeTo:
            "The maximal price of colorants which might be achieved to classify for this price group. Anything higher will belong to the following price group. This is the final maximal price boundary. Shown and used in %{software}.",
        zonePriceMarginName: "The name of the item. This is the final name. Shown and used in %{software}.",
        zonePriceMarginType: "Margin and markup add to the price whereas discount substracts from it.",
        zonePriceMarginValueBase:
            "How much % of the product base price should be added/substracted. This is the final base margin/markup/discount value. Shown and used in %{software}.",
        zonePriceMarginValueColorant:
            "How much % of the product base price should be added/substracted. This is the final colorant margin/markup/discount value. Shown and used in %{software}.",
        zonePriceMarginPriority:
            "Priority sets the order in which the margins/markups/discounts are shown in %{software}. If not set, they are ordered chronologically. The higher number, the higher priority. This is the final margin/markup/discount priority. Used in %{software}.",
        currencyName: "Name of the currency, e.g. Euro. Used in %{software} (currency).",
        currencyCode:
            "Currency code has to be in the official three-letter form, e.g. EUR. Used in %{software} (prices, currency).",
        currencySymbol:
            "Official currency symbol shown in %{software}, e.g. € for Euro. Used in %{software} (prices, currency).",
        currencyRounding:
            "Rounding sets the smallest possible payable value of the currency, e.g. Euro has rounding 0.01 because the smallest possible value is 1 cent. Used in %{software} (prices, currency).",
        imageFileType: "Images are shown overall in %{software}. Icons are visible only in %{redlikeHelper}.",
        imageHeight: "height of the image in pixels.",
        imageName: "Name of the image to be easily recognised when assigning it to an item.",
        imageSize: "Size of the image file.",
        imageUrl:
            "Url where the up-to-date image is available online. If accessible, this file is used prior to the data stored directly in the final database. Used in %{software} (formula).",
        imageWidth: "Width of the image in pixels.",
        packageName:
            "Name of the package as it will be shown in %{software}. Valid for product packages as well as colorant packages. Shown in %{software} (product package, refill).",
        packageTmcName: "Name of the package shown in TMC.",
        packageNominalAmount: "Nominal amount of the package considering the used Unit.",
        packageUnit:
            "Defines the measurement unit of the package. Units are created in the section Units (Generic data).",
        packageGravimetric:
            "Indication whether the package is gravimetric or volumetric based on the used Unit (Generic data).",
        packageMoreInfo:
            "Any additional information for the database administrator which will not be shown in %{software}.",
        productFileAction: "The possibility to download an uploaded file.",
        productFileCategory:
            "Category to which the file belongs. Used to ensure organised structure. Not shown in %{software}.",
        productFileMoreInfo:
            "Any additional information for the database administrator which will not be shown in %{software}.",
        productFileName:
            "Name of the file to be easily recognised when assigning it to an item and when the end user works with it. Shown in %{software}",
        productFileSize: "Size of the file.",
        productFileType: "File type so %{software} would recognise the purpose of the file. Used in %{software}.",
        productFileUrl:
            "Url where the up-to-date product file is available online. If accessible, this file is used prior to the data stored directly in the final database. Used in Redlike (formula).",
        staticSqlAction: "The possibility to download the uploaded SQL file.",
        staticSqlName:
            "Name of the SQL file to be easily recognised when assigning int to a zone. Not shown in %{software}.",
        staticSqlUrl: "Url where the SQL file is available online. Not shown in %{software}.",
        unitName: "Measurement unit name abbreviation as it will be shown in %{software}.",
        unitGravimetric: "Indication whether the measurement unit is gravimetric or volumetric.",
        unitAmountCoefficient: "",
        unitPriority:
            "Priority sets the order in which the units are shown in %{software}. If not set, units are ordered based on %{software} default settings. The higher number, the higher priority. This is the final units priority. Used in %{software}."
    },
    privileges: {
        privileges: "Privileges",
        showOnlyNonDefaultValues: "Show only non default values",
        statusAvailable: "Available - user can see and edit",
        statusHidden: "Hidden - user cannot see",
        statusNotAvailable: "Not available - user can see but not edit",
        description: {
            menu: "Refers to the entire left menu",
            menuBugReport: "Refers to the Bug Report menu item in the section LICENSE",
            menuDatabase: "Refers to the entire DATABASE section in the left menu",
            menuDatabaseConnection: "Refers to the Database Connection menu item in the section DATABASE",
            menuDevices: "Refers to the entire DEVICES section in the left menu, valid for Redlike Pricing",
            menuFileTransfer: "Refers to the File Transfer menu item in the section DATABASE",
            menuImportCustomData: "Refers to the Data Import & Export menu item in the section DATABASE",
            menuLicense: "Refers to the entire LICENSE section in the left menu",
            menuLicenseData: "Refers to the License Data menu item in the section LICENSE",
            menuMachineAndDevices: "Refers to the entire MACHINE & DEVICES section in the left menu",
            menuMachineManagement: "Refers to the Machine Management menu item in the section MACHINE & DEVICES",
            menuMachineSettings: "Refers to the Machine Settings menu item in the section MACHINE & DEVICES",
            menuMaintenance: "Refers to the entire MAINTENANCE section in the left menu",
            menuPrintSettings: "Refers to the Print Settings menu item in the section MACHINE & DEVICES",
            menuRccConnection: "Refers to the RCC Connection menu item in the section MACHINE & DEVICES",
            menuServerConnection: "Refers to the Client/Server Connection menu item in the section DATABASE",
            menuServerPlugins:
                "Refers to the Server Plugins menu item in the section DATABASE, valid only with plugins used",
            menuServiceActions: "Refers to the Service Actions menu item in the section MAINTENANCE",
            menuSettings: "Refers to the entire SETTINGS section in the left menu",
            menuSimplifiers: "Refers to the entire SIMPLIFIERS section in the left menu",
            menuSpectroSettings: "Refers to the Spectro Settings menu item in the section MACHINE & DEVICES",
            menuStatistics: "Refers to the Statistics menu item in the section MAINTENANCE",
            menuUpdate: "Refers to the Update Options menu item in the section LICENSE",
            submenuAutotasks: "Refers to the Autotasks submenu item under the Service Actions menu item",
            submenuCanisterSettings:
                "Refers to the Canister Settings submenu item under the Machine Settings menu item",
            submenuDispensingSettings:
                "Refers to the Dispensing Settings submenu item under the Machine Settings menu item",
            submenuDriverSettings: "Refers to the Driver Settings submenu item under the Machine Settings menu item",
            submenuLicenseStatus: "Refers to the License Status submenu item under the License Data menu item",
            submenuMachineInfo: "Refers to the Machine Info submenu item under the Machine Management menu item",
            submenuMachineManagement:
                "Refers to the Machine Management submenu item under the Machine Management menu item",
            submenuPosInfo: "Refers to the POS Info submenu item under the License Data menu item",
            submenuPurgeStatistics: "Refers to the Colorant Consumption submenu item under the Statistics menu item",
            submenuRefillHistory: "Refers to the Colorant Refill History submenu item under the Statistics menu item",
            submenuRegistration: "Refers to the Registration submenu item under the License Data menu item",
            submenuSingleActions: "Refers to the Single Actions submenu item under the Service Actions menu item",
            submenuSmartPurgeRules: "Refers to the Smart Purge Rules submenu item under the Service Actions menu item",
            contentAutotasks: "Refers to the entire content of the Autotask submenu item under Service Actions",
            contentBugReport: "Refers to the entire content of the Bug Report menu item",
            contentCanisterSettings:
                "Refers to the entire content of the Canister Settings submenu item under Machine Settings",
            contentDatabaseConnection: "Refers to the entire content of the Database Connection menu item",
            contentDriverSettings: "Refers to the entire content of the Driver Settings submenu item",
            contentDispensingSettings:
                "Refers to the entire content of the Dispensing Settings submenu item under Machine Settings",
            contentFileTransfer: "Refers to the entire content of the File Transfer menu item",
            contentImportCustomData: "Refers to the entire content of the Data Import & Export menu item",
            contentLicenseStatus: "Refers to the entire content of the License Status submenu item under License Data",
            contentMachineInfo:
                "Refers to the entire content of the Machine Info submenu item under Machine Management",
            contentMachineManagement:
                "Refers to the entire content of the Machine Management submenu item under Machine Management",
            contentPosInfo: "Refers to the entire content of the POS Info submenu item under License Data",
            contentPrintSettings: "Refers to the entire content of the Print Settings menu item",
            contentPurgeStatistics:
                "Refers to the entire content of the Colorant Consumption submenu item under Statistics",
            contentRccConnection: "Refers to the entire content of the RCC Connection menu item",
            contentRefillHistory:
                "Refers to the entire content of the Colorant Refill History submenu item under Statistics",
            contentRegistration: "Refers to the entire content of the Registration submenu item under License Data",
            contentServerConnection: "Refers to the entire content of the Client/Server Connection menu item",
            contentServerPlugins: "Refers to the entire content of the Server Plugins menu item",
            contentSingleActions:
                "Refers to the entire content of the Single Actions submenu item under Service Actions",
            contentSmartPurgeRules:
                "Refers to the entire content of the Smart Purge Rules submenu item under Service Actions",
            contentSpectroSettings: "Refers to the entire content of the Spectro Settings menu item",
            contentUpdateOptions: "Refers to the entire content of the Update Options menu item",
            contentItemColorantPricing:
                "Refers to all settings in the Colorant Pricing submenu item under Price Settings",
            contentItemCustomData:
                "Refers to all settings in the Custom Data section in Data Import & Export menu item",
            contentItemDefaultPriceValues:
                "Refers to all settings in the Default Values submenu item under Price Settings",
            contentItemFileTransferExport: "Refers to all settings in the Export section in File Transfer menu item",
            contentItemFileTransferImport: "Refers to all settings in the Import section in File Transfer menu item",
            contentItemLightAtStartup:
                "Refers to the configuration item Run Light at startup in Interface Settings - Environmental Settings - Software Options",
            contentItemLocalData: "Refers to all settings in Local Data section in Data Import & Export menu item",
            contentItemMachineCanOptions:
                "Refers to all settings in the Can Handling Options section in Dispensing Settings submenu item under Machine Settings",
            contentItemMachineDispensingOptions:
                "Refers to all settings in the Dispensing Options section in Dispensing Settings submenu item under Machine Settings",
            contentItemMachineOptions:
                "Refers to all settings in the Machine Options section in Machine Management submenu item under Machine Management",
            contentItemMachineRefillOptions:
                "Refers to all settings in the Refill Options section in Dispensing Settings submenu item under Machine Settings",
            contentItemCurrencySettings:
                "Refers to all settings in the Currency section in Pricing Options submenu item under Price Settings",
            contentItemMarginSettings:
                "Refers to all settings in the Margin and Discount submenu item under Price Settings",
            contentItemOneTimeImport:
                "Refers to all settings in the One Time Import section in File Transfer menu item",
            contentItemPackagePricing:
                "Refers to all settings in the Package Surcharge submenu item under Price Settings",
            contentItemPriceImport:
                "Refers to the configuration item Import Price in Pricing Settings - Pricing Options",
            contentItemPriceExport:
                "Refers to the configuration item Export Price in Pricing Settings - Pricing Options",
            contentItemProductPricing:
                "Refers to all settings in the Product Pricing submenu item under Price Settings",
            contentItemSingleActionsCanisters:
                "Refers to all actions in the Canister Levels section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsCap:
                "Refers to all actions in the Cap section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsClean:
                "Refers to all actions in the Clean section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsComputer:
                "Refers to all actions in the Computer section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsGeneral:
                "Refers to all actions in the General section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsPlugger:
                "Refers to all actions in the Plugger section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsPuncher:
                "Refers to all actions in the Puncher section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsPurge:
                "Refers to all actions in the Purge section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsRecirculate:
                "Refers to all actions in the Recirculate section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsShelf:
                "Refers to all actions in the Shelf section in Single Actions submenu item under Service Actions",
            contentItemSingleActionsStir:
                "Refers to all actions in the Stir section in Single Actions submenu item under Service Actions",
            contentItemPrintOptions: "Refers to all settings in the Print Options section in Print Settings menu item",
            contentItemPrinterSettings:
                "Refers to all settings in the Printer Settings section in Print Settings menu item",
            contentItemTemplateSettings:
                "Refers to all settings in the Template Settings section in Print Settings menu item",
            contentItemTmc:
                "Refers to all settings in the TMC section in Machine Management submenu item under Machine Management",
            contentItemTranslations:
                "Refers to the button Export Translation Keys in Advanced Settings - Language Settings",
            contentItemUserManagement: "Refers to all settings in the User Management menu item",
            setupDatabase: "Refers to the entire step Database in the initial setup wizard after installation",
            setupEnvironment: "Refers to the entire step Environment in the initial setup wizard after installation",
            setupEnvironmentFormula:
                "Refers to the section Formula Settings in the step Environment in the initial setup wizard after installation",
            setupEnvironmentFormulaData:
                "Refers to the section User Preferences in the step Environment in the initial setup wizard after installation",
            setupEnvironmentImport:
                "Refers to the section Custom Data in the step Environment in the initial setup wizard after installation",
            setupEnvironmentLayout:
                "Refers to the section Software Options in the step Environment in the initial setup wizard after installation",
            setupEnvironmentNavigation:
                "Refers to the section Navigation Items in the step Environment in the initial setup wizard after installation",
            setupEnvironmentUpdate:
                "Refers to the section Update Options in the step Environment in the initial setup wizard after installation",
            setupLanguage: "Refers to the entire step Language in the initial setup wizard after installation",
            setupLicense: "Refers to the entire step License in the initial setup wizard after installation",
            setupLogin: "Refers to the entire step Log In in the initial setup wizard after installation",
            setupMachine: "Refers to the entire step Machine in the initial setup wizard after installation",
            setupMachineDriver:
                "Refers to the section Machine Type in the step Machine in the initial setup wizard after installation",
            setupMachineSettings:
                "Refers to the section Machine Settings in the step Machine in the initial setup wizard after installation",
            setupPrinter: "Refers to the entire step Print in the initial setup wizard after installation",
            setupPrinterOptions:
                "Refers to the section Print Options in the step Print in the initial setup wizard after installation",
            setupPrinterSettings:
                "Refers to the section Printer in the step Print in the initial setup wizard after installation",
            setupPrinterTemplateSettings:
                "Refers to the section Template in the step Print in the initial setup wizard after installation",
            setupSpectro: "Refers to the entire step Spectro in the initial setup wizard after installation",
            additionalTabType:
                "Refers to the configuration item Additional Formula Tab in Interface Settings - Environmental Settings - Tabs & Pop-Ups",
            automaticLogout:
                "Refers to the configuration item Automatic Logout in Interface Settings - Environmental Settings - Layout",
            automaticLogoutTimeout:
                "Refers to the configuration item Automatic Logout Timeout in Interface Settings - Environmental Settings - Layout",
            autoCloseTab:
                "Refers to the configuration item Close Formula Tab Automatically in Interface Settings - Environmental Settings - Tabs & PopUps",
            automaticClientStart:
                "Refers to the configuration item Run Redlike at computer startup in Interface Settings - Environmental Settings - Layout",
            automaticPrint: "Refers to the configuration item Automatic Print in Print Settings",
            barcodesEnabled:
                "Refers to the configuration item Allow Barcodes in Interface Settings - Environmental Settings - Software Options",
            colorPreviewAsImage:
                "Refers to the configuration item Color Preview As Image in Interface Settings - Formula Settings - Color Representation & Data",
            colorPreviewResizable:
                "Refers to the configuration item Resizable Color Preview in Interface Settings - Formula Settings - Color Representation & Data",
            customBannerPosition:
                "Refers to the configuration item Custom Banner in Interface Settings - Environmental Settings - Layout",
            customCanSize:
                "Refers to the configuration item Allow Custom Package Size in Interface Settings - Formula Settings - Formula Panels",
            customCanOption:
                "Refers to the configuration item Units for Custom Package Size in Interface Settings - Formula Settings - Formula Panels",
            customCanSettingsUnitGrav:
                "Refers to the configuration item Custom Package Settings Gravimetric Unit in Interface Settings - Formula Settings - Formula Panels",
            customCanSettingsUnitVol:
                "Refers to the configuration item Custom Package Settings Volumetric Unit in Interface Settings - Formula Settings - Formula Panels",
            customCanUnits:
                "Refers to the configuration item Choose Custom Units in Units for Custom Package Size in Interface Settings - Formula Settings - Formula Panels",
            customFormatCurrency:
                "Refers to the configuration item Currency Format in Advanced Settings - Language Settings",
            customFormatDate: "Refers to the configuration item Date Format in Advanced Settings - Language Settings",
            customFormatNumber:
                "Refers to the configuration item Number Format in Advanced Settings - Language Settings",
            customFormatTime: "Refers to the configuration item Time Format in Advanced Settings - Language Settings",
            customFormulas:
                "Refers to the configuration item Allow Custom Formulas in Interface Settings - Formula Settings - Formula Panels",
            customersEnabled:
                "Refers to the configuration item Allow Customers in Interface Settings - Formula Settings - Formula Panels",
            deleteConfirmation:
                "Refers to the configuration item Allow Delete Confirmation in Interface Settings - Environmental Settings - Tabs & Pop-Ups",
            dispensingEnabled:
                "Refers to the configuration item Allow Dispensing in Interface Settings - Environmental Settings - Software Options",
            dispensingNotes:
                "Refers to the configuration item Allow Dispensing Notes in Interface Settings - Formula Settings - Formula Panels",
            dispensingWithErrorsEnabled:
                "Refers to the configuration item Allow Dispensing Despite The Validation Errors in Interface Settings - Formula Settings - Colorant",
            doNotShowAgainCloseRedlike: "",
            editColorantAllowance:
                "Refers to the configuration item Allow Editing Colorants in Interface Settings - Formula Settings - Colorant",
            formulaAutomaticNaming:
                "Refers to the configuration item Allow Automatic Naming in Interface Settings - Formula Settings - Color",
            formulaCode:
                "Refers to the configuration item Use Formula Code in Interface Settings - Formula Settings - Color",
            formulaCodePrimary:
                "Refers to the configuration item Formula Code As Primary in Interface Settings - Formula Settings - Color",
            formulaColorPreview:
                "Refers to the configuration item Show Color Preview in Interface Settings - Formula Settings - Color",
            formulaName:
                "Refers to the configuration item Use Formula Name in Interface Settings - Formula Settings - Color",
            formulaNotes:
                "Refers to the configuration item Allow Formula Notes in Interface Settings - Formula Settings - Formula Panels",
            formulaPreview:
                "Refers to the configuration item Formula Preview in Interface Settings - Formula Settings - Color Representation & Data",
            formulaStatusPanelEnabled:
                "Refers to the configuration item Allow Formula Status Panel in Interface Settings - Formula Settings - Formula Panels",
            formulaUnits:
                "Refers to the configuration item Formula Unit in Interface Settings - Environmental Settings - Software Options",
            fts: "Refers to the configuration item Use Fulltext Search in Advanced Settings - Search Settings",
            generateColorPreview:
                "Refers to the configuration item Generate Color Preview in Interface Settings - Formula Settings - Colorant",
            help: "Refers to all settings in the Help Settings submenu item under Advanced Settings, except Custom Help Center Link",
            helpLink: "Refers to the configuration item Custom Help Center Link in Advanced Settings - Help Settings",
            imagesSource:
                "Refers to the configuration item Images in Interface Settings - Formula Settings - Formula Panels",
            infoPanelResizable:
                "Refers to the configuration item Resizable More Info Panel in Interface Settings - Formula Settings - Color Representation & Data",
            infoPanelTab: "",
            infoTypes:
                "Refers to the configuration item More info in Interface Settings - Formula Settings - Color Representation & Data",
            infoTypesRounded: "",
            initTabType:
                "Refers to the configuration item Initial Formula Tab in Interface Settings - Environmental Settings - Tabs & Pop-Ups",
            keyboard: "",
            keyboardShortcuts: "Refers to all settings in the Keyboard Shortcuts submenu item under Advanced Settings",
            kiosk: "Refers to the configuration item Turn On Kiosk Mode in Interface Settings - Environmental Settings - Layout",
            language: "Refers to the all settings in Language section in Advanced Settings - Language Settings",
            lightEnabled:
                "Refers to the configuration item Allow Light in Interface Settings - Environmental Settings - Software Options",
            locale: "Refers to the all settings in Language section in Advanced Settings - Language Settings. Deprecated privilege",
            lockPc: "Refers to the configuration item Lock Your Computer in Interface Settings - Environmental Settings - Layout",
            marginMarkup:
                "Refers to the all settings in Margin and Discount section in Margin and Discount under Price Settings",
            masterProductName: "",
            masterProductSiblingsTable:
                "Refers to the link on Custom formula panel which opens Sibling Products By Master Product pop-up window (see privilege componentFormulaMasterProduct)",
            moreInfoInternal:
                "Refers to the configuration item Allow Internal Notes in Interface Settings - Environmental Settings - Formula Panels",
            navigationItemsOrdered:
                "Refers to the all settings and their sorting in Navigation Items section in Interface Settings - Environmental Settings - Navigation Items",
            numberOfTabs:
                "Refers to the configuration item Maximum Number of Formula Tabs in Interface Settings - Environmental Settings - Tabs & Pop-Ups",
            orderSearchItems:
                "Refers to all settings in Order Search section in Search Settings under Advanced Settings",
            orderingEnabled:
                "Refers to the configuration item Allow Ordering in Interface Settings - Environmental Settings - Software Options",
            orderModalEnabled:
                "Refers to the configuration item Show New Order Notifications in Advanced Settings - Ordering Settings",
            overwriteLastTab:
                "Refers to the configuration item Overwrite Last Formula Tab in Interface Settings - Environmental Settings - Tabs & Pop-Ups",
            overwriteLogo: "",
            pricingEnabled:
                "Refers to the configuration item Allow Pricing in Interface Settings - Environmental Settings - Software Options",
            priceTypes:
                "Refers to all settings and their sorting in Available Prices section in Interface Settings - Formula Settings - Available Prices",
            priceVisibilityTypes:
                "Refers to the configuration item VAT Visibility in Interface Settings - Formula Settings - Available Prices",
            printAfterDispensing: "Refers to the configuration item Allow Print After Dispensing in Print Settings",
            printAsImage: "Refers to the configuration item Print As Image in Print Settings",
            printBeforeDispensing: "Refers to the configuration item Allow Print Before Dispensing in Print Settings",
            printEnabled:
                "Refers to the configuration item Allow Print in Interface Settings - Environmental Settings - Software Options",
            printOnRequest: "Refers to the configuration item Print On Request in Print Settings",
            printTasksAfterDispensing:
                "Refers to table below the configuration item Allow Print After Dispensing in Print Settings",
            printTasksBeforeDispensing:
                "Refers to table below the configuration item Allow Print Before Dispensing in Print Settings",
            printer: "Refers to the configuration item Default Printer in Print Settings",
            printerTemplate: "Refers to the configuration item Default Template in Print Settings",
            printerTemplateQuicktint: "Refers to the configuration item Quick Tint Template in Print Settings",
            productCode:
                "Refers to the configuration item Use Product Code in Interface Settings - Formula Settings - Color",
            productGroups:
                "Refers to the configuration item Use Product Groups in Interface Settings - Formula Settings - Color",
            productFilesSource:
                "Refers to the configuration item Product Files in Interface Settings - Formula Settings - Formula Panels",
            productImagePreview:
                "Refers to the configuration item Images in Interface Settings - Formula Settings - Formula Panels",
            quickTintSortingType:
                "Refers to the configuration item Sorting in Interface Settings - Quick Tint Settings",
            quickTintCloseAfterDispensing:
                "Refers to the configuration item Closing in Interface Settings - Quick Tint Settings",
            rounding:
                "Refers to the configuration item Set Number Of Decimal Places in Interface Settings - Formula Settings - Colorant",
            screensaverTimeout:
                "Refers to the configuration item Screensaver Timeout in Interface Settings - Environmental Settings - Layout",
            searchCheaperColors:
                "Refers to the configuration item Allow Searching For Cheaper Colors in Interface Settings - Formula Settings - Formula Panels",
            searchPanelBase:
                "Refers to the configuration item Use Selection Item For Base in Interface Settings - Formula Settings - Color",
            searchPanelBaseSize:
                "Refers to the configuration item Set Size Of Base Panel in Interface Settings - Formula Settings - Color",
            searchPanelBipp:
                "Refers to the configuration item Use Selection Item For Base In Product Package in Interface Settings - Formula Settings - Color",
            searchPanelBippSize:
                "Refers to the configuration item Set Size Of Base In Product Package Panel in Interface Settings - Formula Settings - Color",
            searchPanelFandeckSize:
                "Refers to the configuration item Set Size Of Fandeck Panel in Interface Settings - Formula Settings - Color",
            searchPanelFormulaSize:
                "Refers to the configuration item Set Size Of Formula Panel in Interface Settings - Formula Settings - Color",
            searchPanelOrder: "Refers to the configuration item for selection items sorting",
            searchPanelProduct:
                "Refers to the configuration item Use Selection Item For Product in Interface Settings - Formula Settings - Color",
            searchPanelProductSize:
                "Refers to the configuration item Set Size Of Product Panel in Interface Settings - Formula Settings - Color",
            searchPanelWithoutConfirmation:
                "Refers to the configuration item Quick Select in Interface Settings - Formula Settings - Color",
            settingsActiveFormulaUnit: "",
            settingsBlendFormulaAndCode:
                "Refers to the configuration item Blend Formula Name And Formula Code in Interface Settings - Formula Settings - Color",
            settingsCustomHomeButton:
                "Refers to the configuration item Home Button Action in Interface Settings - Environmental Settings - Software Options",
            settingsInfoItemInRgbPreview:
                "Refers to the configuration item Data In Color Preview in Interface Settings - Formula Settings - Color Representation & Data",
            settingsManualAdditionUnit: "",
            shotsCoef: "",
            shotsName: "",
            softwareName: "",
            softwareNameLight: "",
            soundEnabled:
                "Refers to the configuration item Allow Sound Order Notification in Advanced Settings - Ordering Settings",
            spectroEnabled:
                "Refers to the configuration item Allow Spectro in Interface Settings - Environmental Settings - Software Options",
            style: "",
            tableBase: "",
            tableBipp: "Refers to the checkbox Base In Product And Package for table column in Dispensing History",
            tableCustomer:
                "Refers to the checkbox Customer for table column in Dispensing History and Orders To Dispense",
            tableDate: "Refers to the checkbox Date for table column in Dispensing History and Orders To Dispense",
            tableFandeck:
                "Refers to the checkbox Fandeck for table column in Dispensing History and Orders To Dispense",
            tableFormulaCode:
                "Refers to the checkbox Formula Code for table column in Dispensing History and Orders To Dispense",
            tableFormulaName:
                "Refers to the checkbox Formula Name for table column in Dispensing History and Orders To Dispense",
            tableMoreInfo: "Refers to the checkbox Note for table column in Dispensing History and Orders To Dispense",
            tableMoreInfoInternal: "Refers to the checkbox Internal Note for table column in Dispensing History",
            tablePackage:
                "Refers to the checkbox Package for table column in Dispensing History and Orders To Dispense",
            tableProduct:
                "Refers to the checkbox Product for table column in Dispensing History and Orders To Dispense",
            tableQuantity:
                "Refers to the checkbox Quantity for table column in Dispensing History and Orders To Dispense",
            tabsEnabled:
                "Refers to the configuration item Allow Tabs in Interface Settings - Environmental Settings - Software Options",
            userIdentification:
                "Refers to the configuration item User Identification in Machine Settings - Dispensing Settings",
            checkMinDosableAmount:
                "Refers to the configuration item Check The Minimal Tintable Amount Of Colorants in Interface Settings - Formula Settings - Colorant",
            checkOverfill:
                "Refers to the configuration item Check Can Overfill in Interface Settings - Formula Settings - Colorant",
            checkTotalCntAmounts:
                "Refers to the configuration item Check Total Colorants Amount For Base Paint in Interface Settings - Formula Settings - Colorant",
            virtualKeyboardEnabled:
                "Refers to the configuration item Allow Virtual Keyboard in Interface Settings - Environmental Settings - Layout",
            visibleUnits:
                "Refers to the configuration item Visible Units in Interface Settings - Environmental Settings - Software Options",
            zoom: "Refers to the configuration item Zoom in Interface Settings - Environmental Settings - Layout",
            enableCompanyCost: "",
            enablePriceGroups: "Refers to the configuration item Enable Price Groups in Price Settings - Price Options",
            enableLocalPrices:
                "Refers to the configuration item Show Suggested Prices in Price Settings - Price Options",
            numPriceGroups: "Refers to the configuration item Number Of Price Groups in Price Settings - Price Options",
            priceDefinition: "Refers to the configuration item Price Definition in Price Settings - Price Options",
            useMargin: "Refers to the configuration item Show Margin in Price Settings - Price Options",
            useMarkup: "Refers to the configuration item Show Markup in Price Settings - Price Options",
            useDiscount: "Refers to the configuration item Show Discount in Price Settings - Price Options",
            colorTabCompatibleOnly: "Refers to the checkbox Non-Existent Formula in Color Palette",
            colorTabFormulaCode: "Refers to the checkbox Formula Code in Color Palette",
            colorTabFormulaName: "Refers to the checkbox Formula Name in Color Palette",
            colorTabNoteIcons: "Refers to the checkbox More Info Icons in Color Palette",
            colorTabOrderBy: "Refers to the configuration item Order By in Color Palette",
            colorTabRowCount: "Refers to the configuration item Select Your Grid in Color Palette",
            colorTabWarningIcon: "Refers to the checkbox Non-Existent Formula Warning Icon in Color Palette",
            colorantPanelOrderBy:
                "Refers to the configuration item Colorant Panel Sorting in Interface Settings - Formula Settings - Colorant",
            competitiveColorSearch:
                "Refers to the configuration item Allow Competitive Color Search in Interface Settings - Environmental Settings - Software Options",
            numberOfCompetitiveColors:
                "Refers to the configuration item Set Number Of Competitive Colors in Interface Settings - Color Palette Settings",
            maxToleranceForCompetitiveColors:
                "Refers to the configuration item Set Max Tolerance For Competitive Color Search in Interface Settings - Color Palette Settings",
            ufiCodeBaseValidation:
                "Refers to the configuration item Base UFI Code Validation in Machine Settings - Dispensing Settings",
            ufiCodeLogic:
                "Refers to the configuration item Enable UFI Codes and additional settings in Machine Settings - Dispensing Settings",
            ufiCodeMinAmontGravPercent:
                "Refers to the configuration item Print Percentage If Amount Of Colorant Is Over in Machine Settings - Dispensing Settings",
            ufiCodeMinGravPercent:
                "Refers to the configuration item Print If Amount Of Colorant Is Over in Machine Settings - Dispensing Settings",
            fileTransfer: "Refers to the configuration item Allow Automatic File Transfer in File Transfer",
            fileTransferInTasks: "Refers to the table for File Transfer Import in File Transfer",
            fileTransferOutTasks: "Refers to the table for File Transfer Export in File Transfer",
            colorantPackageProc: "",
            currencyCode: "",
            machineActionInitialize: "Refers to the action button Initialize in Service Actions - Single Actions",
            machineActionReset: "Refers to the action button Start Reset in Service Actions - Single Actions",
            machineActionHardReset: "Refers to the action button Start Hard Reset in Service Actions - Single Actions",
            machineActionErrorReset:
                "Refers to the action button Start Error Reset in Service Actions - Single Actions",
            machineActionShowServiceWindow:
                "Refers to the action button Show Service Window in Service Actions - Single Actions",
            machineActionDisconnect: "Refers to the action button Disconnect in Service Actions - Single Actions",
            machineActionPurge: "Refers to the action button Purge Channels in Service Actions - Single Actions",
            machineActionPurgeAll: "Refers to the action button Purge All Channels in Service Actions - Single Actions",
            machineActionSmartPurge:
                "Refers to the action button Smart Purge Channels in Service Actions - Single Actions",
            machineActionSmartPurgeAll:
                "Refers to the action button Smart Purge All Channels in Service Actions - Single Actions",
            machineActionCleanChannels:
                "Refers to the action button Clean Channels in Service Actions - Single Actions",
            machineActionCleanAllChannels:
                "Refers to the action button Clean All Channels in Service Actions - Single Actions",
            machineActionRecirculateChannels:
                "Refers to the action button Recirculate Channels in Service Actions - Single Actions",
            machineActionRecirculateAllChannels:
                "Refers to the action button Recirculate All in Service Actions - Single Actions",
            machineActionStirCanisters:
                "Refers to the action button Stir Canisters in Service Actions - Single Actions",
            machineActionStirAllCanisters: "Refers to the action button Stir All in Service Actions - Single Actions",
            machineActionCapOpen: "Refers to the action button Open (Cap) in Service Actions - Single Actions",
            machineCapClose: "Refers to the action button Close (Cap) in Service Actions - Single Actions",
            machineActionShelfUp: "Refers to the action button Shelf Up in Service Actions - Single Actions",
            machineActionShelfDown: "Refers to the action button Shelf Down in Service Actions - Single Actions",
            machineActionPunch: "Refers to the action button Punch in Service Actions - Single Actions",
            machineActionPlug: "Refers to the action button Plug in Service Actions - Single Actions",
            settingsCleaningTime: "Refers to the configuration item Cleaning Time in Clean Channels pop-up window",
            settingsRecirculationTime:
                "Refers to the configuration item Recirculating Time in Recirculate Channels pop-up window",
            settingsRecirculationSpeed:
                "Refers to the configuration item Recirculating Speed in Recirculate Channels pop-up window",
            settingsStirringTime: "Refers to the configuration item Stirring Time in Stir Canisters pop-up window",
            settingsStirringSpeed: "Refers to the configuration item Stirring Speed in Stir Canisters pop-up window",
            machineSettingBarcodeRefill:
                "Refers to the configuration item Refill Barcode Validation in Machine Settings - Dispensing Settings",
            machineSettingDispenseInvalidColorants:
                "Refers to the configuration item Dispense Invalid Colorants in Machine Settings - Dispensing Settings",
            machineSettingMachineUnit:
                "Refers to the configuration item Machine Unit in Machine Management - Machine Management",
            machineSettingCheckCanBeforeDispensing:
                "Refers to the configuration item Check Can Before Dispensing in Machine Settings - Dispensing Settings",
            machineSettingPlaceCanSenzor:
                "Refers to the configuration item Place Can Sensor in Machine Settings - Dispensing Settings",
            machineSettingPlaceCanSenzorDelay:
                "Refers to the configuration item Place Can Sensor Delay in Machine Settings - Dispensing Settings",
            machineSettingRemoveCanSenzor:
                "Refers to the configuration item Remove Can Sensor in Machine Settings - Dispensing Settings",
            machineSettingRemoveCanSenzorDelay:
                "Refers to the configuration item Remove Can Sensor Delay in Machine Settings - Dispensing Settings",
            machineSettingsValidateBarcodeCan:
                "Refers to the configuration item Base Can Barcode Validation in Machine Settings - Dispensing Settings",
            machineSettingsCheckOverfill:
                "Refers to the configuration item Check Overfill in Machine Settings - Dispensing Settings",
            machineSettingsMinimalDispenseAmount:
                "Refers to the configuration item Minimal Dispense Amount in Machine Settings - Dispensing Settings",
            machineSettingsManualAddition:
                "Refers to the configuration item Allow Manual Colorant Addition in Machine Settings - Dispensing Settings",
            machineSettingsSplitFormula:
                "Refers to the configuration item Split Formula in Machine Settings - Dispensing Settings",
            machineSettingsEnableTmc:
                "Refers to the configuration item Enable TMC in Machine Management - Machine Management",
            machineSettingsSkipPlaceCanDialog:
                "Refers to the configuration item Skip Place Can Dialog in Machine Settings - Dispensing Settings",
            machineSettingsSkipRemoveCanDialog:
                "Refers to the configuration item Skip Remove Can Dialog in Machine Settings - Dispensing Settings",
            machineSettingsValidateColorantsDuringInit:
                "Refers to the configuration item Validate Colorants During Initialization in Machine Management - Machine Management",
            componentActiveServer: "Refers to the information Active Server in Client/Server Connections",
            componentAutotasksSettings:
                "Refers to the configuration item Add Autotask and Autotask table in Service Actions - Autotasks",
            componentBarcodeScanModal: "Refers to the item Barcode Scan in the upper menu",
            componentBarcodeTestModal: "Refers to the button Barcode Testing in Database Connection",
            componentBugReport:
                "Refers to the button Open Bug Report in Bug Report as well as Options in the bottom of the software",
            componentCanisterBarcodes:
                "Refers to the configuration item Canister Barcodes in Machine Settings - Canister Settings",
            componentCanisterBarcodesModal: "Refers to the item Canister Barcodes in the upper menu",
            componentCanisterFilling: "Refers to the item Canister Levels in the upper menu",
            componentCanistersTab: "Refers to the item Canisters in the upper menu",
            componentChangelog: "Refers to the item Changelog in the bottom of the software",
            componentClientIdentifier: "Refers to the configuration item Client Identifier in Client/Server Connection",
            componentClientServerConnection:
                "Refers to all settings in the Other Available Servers section in Client/Server Connection",
            componentColorTab: "Refers to the item Color Palette in the upper menu",
            componentColorantConsumption:
                "Refers to all settings in Colorant Consumption section in Statistics - Colorant Consumption",
            componentColorantPanel: "Refers to the colorant overview table on Formula panel",
            componentColorantRefillHistory:
                "Refers to all settings in Colorant Refill History in Statistics - Colorant Refill History as well as the item Colorant Refill History in the upper menu",
            componentCustomFormulaTab: "Refers to the item Custom Formula in the upper menu",
            componentCustomersTab: "Refers to the item Customers in the upper menu",
            componentDatabaseList: "Refers to the button for databases in the bottom of the software",
            componentDatabaseModal: "Refers to the button Available Databases in Database Connection",
            componentDebugModal: "",
            componentDeleteImportedData: "Refers to the button Delete Imported Data in Data Import & Export",
            componentDispensingLogic:
                "Refers to the configuration item Dispensing Logic in Machine Settings - Canister Settings",
            componentDispensingSettings:
                "Refers to the entire content of the Dispensing Settings submenu item under Machine Settings",
            componentDriverSettings:
                "Refers to the entire content of the Driver Settings submenu item under Machine Settings",
            componentExportSettings: "Refers to the button Export Local Settings in Data Import & Export",
            componentExportStaticSql: "Refers to the button Export Static SQL in Data Import & Export",
            componentFavoritesTab: "Refers to the item Favorites in the upper menu and the first tab",
            componentFormulaMasterProduct:
                "Refers to the checkbox on Custom formula panel which allows to include the custom formula into all products inherited from the same master product as well as the link to connected pop-up window",
            componentFormulaTab: "Refers to Formula panel",
            componentHideItemsModal: "Refers to the item Hide Products/Fandecks in the upper menu",
            componentHistoricalFormulas: "Refers to the button History on Formula panel",
            componentHistoryOfOrdersTab: "Refers to the item History of Orders in Redlike Light menu",
            componentCheaperColorsModal: "Refers to the pop-up window Search For Cheaper",
            componentImportData:
                "Refers to the configuration item Import Custom Data including the Import button in Data Import & Export",
            componentImportSettings: "Refers to the button Import Local Settings in Data Import & Export",
            componentLicenseIdentification:
                "Refers to the information available in License Identification section in License Data - License Status",
            componentLicenseStatus:
                "Refers to the information available in License Version section in License Data - License Status",
            componentLicenseSupplier:
                "Refers to the information available in License Supplier section in License Data - License Status",
            componentMachineDialog: "Refers to the machine activity panel in the bottom part of the software",
            componentMachineInfo:
                "Refers to the information available in Currently Selected Machine section in Machine Management - Machine Info",
            componentMachineRegistration:
                "Refers to the Machine Information section in License Data - Registration as well as License Data - POS Info",
            componentMachineSettings: "",
            componentMyOrdersTab: "Refers to the item My Orders in the upper menu",
            componentOrderNotificationModal: "Refers to the pop-up window You Have Received A New Order!",
            componentPosInfo: "Refers to the form available in License Data - POS Info",
            componentPricePanel: "Refers to the price panel available on Formula panel",
            componentQuickTintModal: "Refers to the item Quick Tint in the upper menu",
            componentRefillingLogic:
                "Refers to the configuration item Refilling Logic in Machine Settings - Canister Settings",
            componentRegistration: "Refers to the form available in License Data - Registration",
            componentRestoreSettings: "Refers to the button Restore Local Settings in Data Import & Export",
            componentRccConnection: "Refers to RCC Url section in RCC Connection",
            componentServerPlugins:
                "Refers to the information available in The Currently Active Plugins section in Server Plugins",
            componentSmartPurgeRules:
                "Refers to the configuration item Smart Purge Rules in Service Actions - Smart Purge Rules",
            componentSpectroDialog: "Refers to the spectro activity panel in the bottom part of the software",
            componentSpectroSettings: "Refers to all settings Spectro Settings section in Spectro Settings",
            componentSpectroTab: "Refers to the item Spectro in the upper menu",
            componentStatisticsTab: "Refers to the item Dispensing History in the upper menu",
            componentTabBarPanel: "Refers to the space where tabs are created",
            componentTemplateHint: "Refers to the information bubble in print preview pop-up window",
            componentToDispenseTab: "Refers to the item Orders To Dispense in the upper menu",
            componentUpdateOptions: "Refers to all configuration items in Update Options",
            functionAddCanisterLevel:
                "Refers to the button Add (ml) on Canister panel and in Canister Levels pop-up window",
            functionBackupMachineConfiguration:
                "Refers to the button Dispenser Configuration Backup in Machine Management - Machine Info",
            functionCloseRedlike: "Refers to the X button in the upper right corner to close the software",
            functionCreateTemplate: "Refers to the button Create Template on print preview pop-up window",
            functionDeleteOrder: "",
            functionDisconnectServer: "Refers to the button Disconnect in Client/Server Connection",
            functionDispensing: "Refers to the button Dispensing on Formula panel and Orders To Dispense panel",
            functionEditTemplate: "Refers to the button Edit Template on print preview pop-up window",
            functionExportColorantConsumption: "Refers to the button Export Data in Statistics - Colorant Consumption",
            functionExportHistory: "Refers to the button Download in Dispensing History",
            functionExportPrintLabelToImg: "Refers to the button Save as in print preview pop-up window",
            functionExportPrintLabelToPdf: "Refers to the button Export PDF in print preview pop-up window",
            functionExportProductFileToPdf: "Refers to the button Download in Product Files Viewer pop-up window",
            functionFillCanisterToMax:
                "Refers to the button Fill To Max on Canister panel and in Canister Levels pop-up window",
            functionFindInSettings: "Refers to the item Global Settings Search in Global Settings Search",
            functionLogOut: "Refers to the button Log Out on the bottom of the software",
            functionOrder: "Refers to the button Order on Formula panel",
            functionPrint: "Refers to the button Print on Formula panel and Orders To Dispense panel",
            functionReactivateLicense: "Refers to the button Reactivate in License Data - License Status",
            functionRebootPc: "Refers to the button Computer Reboot in Service Actions - Single Actions",
            functionRefillByColorantPackage: "Refers to the buttons for individual colorant packages on Canister panel",
            functionResetFormulaTab: "",
            functionRestartRedlike: "",
            functionRestartServer: "",
            functionSaveToHistory: "Refers to the button Save To History on Formula panel, valid for manual machines",
            functionSetCanisterBarcode:
                "Refers to the right to edit Canister Barcode table in Machine Settings - Canister Settings or in the upper menu",
            functionSetCanisterDispenseLogic:
                "Refers to the right to edit Dispensing Logic table in Machine Settings - Canister Settings",
            functionSetCanisterLevel:
                "Refers to the right to edit Current Level of individual canisters on Canister panel",
            functionSetCanisterUfiCode:
                "Refers to the right to edit UFI Code of individual canisters on Canister panel",
            functionSetMaxCanisterDispenseAmount:
                "Refers to the right to edit Max Dispense Amount of individual canisters on Canister panel",
            functionShutdownPc: "Refers to the button Computer Shutdown in Service Actions - Single Actions",
            functionSwitchToIntergamma: "",
            functionSwitchToLight: "Refers to the menu item Switch To Light",
            functionSwitchToRedlike: "Refers to the menu item Switch To Redlike (from Redlike Light)",
            functionTriggerRefillDialog:
                "Refers to the button Refill on Canister panel and Canister Levels pop-up window"
        }
    },
    server: {
        connect: "Connect",
        noServerConnection: "No server connection, please try to reconnect.",
        notAvailable: "Not available",
        reconnect: "Reconnect",
        serverVersion: "Server version",
        sqlcipherVersion: "SQLCipher version",
        sqliteVersion: "SQLite version"
    },
    serverScripts: {
        lastResult: "Last result",
        runScript: "Run script",
        script: "Script",
        scriptRunning: "Script is running, please wait..."
    },
    spectro: {
        addSpectroType: "Add measurement type",
        editSpectroType: "Edit measurement type",
        measurementTypes: "Measurement types",
        noGraphPreview: "No graph to preview",
        noMeasurementType: "There is no measurement type",
        spectroType: "Spectro type",
        wavelengthSettings: "Wavelength settings"
    },
    system: {
        createNewSystem: "Create new system",
        duplicateSystem: "Duplicate system",
        editSystem: "Edit system",
        selectSystem: "Select system",
        valueInSystem: "Value in system data",
        uuid: "System UUID",
        saveInSystems: "Save in systems",
        system: "System",
        systemName: "System name",
        systemOverview: "System overview",
        systems: "Systems"
    },
    unit: {
        addUnit: "Add unit",
        editUnit: "Edit unit",
        unit: "Unit",
        units: "Units"
    },
    user: {
        addUser: "Add user",
        addUserGroup: "Add user group",
        autoLogin: "Auto login",
        autologinCaption: "Automatically logs users in after starting Redlike",
        availableUsersAndGroups: "Available users and their group of privileges",
        duplicateUserGroup: "Duplicate user group",
        editUser: "Edit user",
        editUserGroup: "Edit user group",
        selectFromUserGroups: "Select from user groups",
        selectedUserGroups: "Selected user groups",
        userActionsHistory: "User´s actions history",
        userGroupAdmins: "Admins",
        userGroupGuests: "Guests",
        userGroupManagers: "Managers",
        userGroupSuperadmins: "Superadmins",
        userGroupUsers: "Users",
        userGroupPrivileges: {
            dataDatabase: "Master settings",
            dataSystemZone: "System & Zone settings",
            dataImport: "Import",
            dataExport: "Export",
            headerCompany: "Company section",
            headerMaster: "Master data",
            headerSystem: "System data",
            headerUser: "User section",
            headerZone: "Zone data",
            genericCurrencies: "Generic - Currencies",
            genericImages: "Generic - Images",
            genericPackages: "Generic - Packages",
            genericProductFiles: "Generic - Product files",
            genericStaticSql: "Generic - Static SQL",
            genericUnits: "Generic - Units",
            taskDelete: "Import / export - Delete task",
            taskHide: "Import / export - Hide task",
            uuidMasterSystem: "Master / System UUID add / edit dialog",
            uuidZone: "Zone UUID"
        },
        user: "User",
        userActionDetail: "Action detail",
        userGroup: "User group",
        userGroups: "User groups",
        userSettings: "User settings",
        username: "Username",
        users: "Users"
    },
    validation: {
        bfsColorant: "Bfs of colorant must be a number in range 1-3",
        bfsProduct: "Bfs of product must be a letter in capitals",
        bookmarksValidation: "Required items are missing, please check all of the bookmarks",
        computerId: "Invalid Computer ID format",
        duplicateNamesProduct: "Every product must have an unique name",
        email: "Invalid email format",
        invalidInput: "Invalid input",
        matchingPassword: "The entered password matches the password on %{redlikeWebAbbreviation}",
        maxFill: "Maximal amount must be greater than minimal",
        notMatchingPassword: "The entered password doesn't match the password on %{redlikeWebAbbreviation}",
        noMetaData: "No metadata on %{redlikeWebAbbreviation}",
        noPassword: "No password on %{redlikeWebAbbreviation}",
        noUuid: "Not the same UUID on %{redlikeWebAbbreviation}",
        numberValue: "Inserted value must be a number",
        rgbValue: "Inserted value must be a number in range 0-255",
        passwordConfirmation: "Passwords have to be identical",
        passwordValue: "Pasword must be at least 8 characters long",
        percentageValue: "Inserted value must be a number in range 0-100",
        phone: "Invalid phone format",
        requiredField: "This field must be filled in",
        requiredFieldName: "The field %{fieldName} must be filled in",
        tableValidation: "%{item} must be filled for all selected export tasks",
        ufiCode: "Invalid format UFI code",
        username: "This username cannot be used",
        uuid: "Invalid UUID format",
        validateR2W: "Validate %{redlikeWebAbbreviation}",
        versionNameValue: "Version Name must be at least 3 characters long",
        versionValue: "Version must be up to 4 numbers separated by dot",
        zoneInvalidUuid:
            "The specified UUID of the selected zone database does not match the UUID of the zone databases stored on the %{redlikeWeb}",
        zoneName:
            "Name of the zone must consist only of characters, numbers or underscores (at least 3 characters long)"
    },
    zone: {
        addZone: "Add zone",
        availableZoneDatabase: "Available zone database",
        createNewZone: "Create new zone",
        duplicateZone: "Duplicate zone",
        editChangelog: "Edit changelog",
        editVersion: "Edit version",
        editZone: "Edit zone",
        importDataFromRedlikeWeb: "Import data from %{redlikeWeb} %{redlikeWebWebsite}",
        saveInZones: "Save in zones",
        selectZone: "Select zone",
        selectZones: "Select zones",
        selectedZones: "Selected zones",
        selectZonesToBeDuplicate: "Select zones to be duplicated",
        showAvailableZones: "Show available zones",
        uuid: "Zone UUID",
        zone: "Zone",
        zoneName: "Zone name",
        zones: "Zones"
    }
};
