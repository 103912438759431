import * as objectTypes from "../../../../constants/objectTypes";
import * as optionsConstants from "../../../../constants/optionsConstants";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import React, { Component } from "react";
import {
    User,
    generateRedlikeUserWithUserGroupHeadings,
    generateRedlikeUsersWithUserGroupsData
} from "../../../../types/user";

import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";
import { UserGroup } from "../../../../types/userGroup";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    userDataPrivileges: any;
    layout: string | null;
    loading: boolean;
    allList: Array<User>;
    userGroupList: Array<UserGroup>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
};

export class RedlikeUsersWithUserGroupsTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_USERNAME,
            propertyConstants.PROPERTY_GENERATED
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_USERNAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_USERNAME];
        const editableList: Array<propertyConstants.Property> = [];
        const visibleList: Array<propertyConstants.Property> = [...propertyList];
        const orderList = [...visibleList];

        return generateRedlikeUserWithUserGroupHeadings(
            this.props.objectType,
            this.props.userGroupList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    render(): JSX.Element {
        const { userDataPrivileges } = this.props;
        const isUserTableActionRow =
            !privilegesHelper.isDataPrivilegesReadOnly(userDataPrivileges) &&
            this.props.layout !== optionsConstants.LAYOUT_COLUMN;

        return (
            <div className="height-100">
                <TableContainer
                    className={`table-with-filter no-action-row ${isUserTableActionRow ? "" : " no-tables-gap"}`}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"title"}
                    headings={this.generateTableHeadings()}
                    data={generateRedlikeUsersWithUserGroupsData(this.props.allList)}
                    activeData={[]}
                    disableColumnResizing={true}
                    modalProperties={{}}
                    offset={tableConstants.DEFAULT_OFFSET}
                    page={tableConstants.DEFAULT_PAGE}
                    rowCount={this.props.allList.length}
                    rowCountCustom={tableConstants.DEFAULT_ROW_COUNT_CUSTOM}
                    search={null}
                    searchParams={tableConstants.DEFAULT_SEARCH_PARAMS}
                    showFilterRow={false}
                    showGlobalSearch={false}
                    sortingAsc={true}
                    sortingCriterion={null}
                    totalCount={null}
                />
            </div>
        );
    }
}
