import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/system/SystemBaseSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { SystemBaseTable } from "./tables/SystemBaseTable";

type Props = PropsType;

type State = {
    tableBases: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class SystemBaseScene extends Component<Props, State> {
    state: State = {
        tableBases:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.baseTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableBases:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.baseTableConstant)
                        : null) || null
            });
        }
    }

    getBasesTable = (): JSX.Element | null => {
        const { baseTableConstant } = this.props;
        const { tableBases } = this.state;

        if (tableBases) {
            return (
                <SystemBaseTable
                    tableConstant={baseTableConstant}
                    objectType={objectTypes.SYSTEM_BASE}
                    dataPrivileges={tableBases}
                    loading={this.props.baseLoading}
                    buttonLoading={this.props.baseButtonLoading}
                    allList={this.props.baseList}
                    activeList={this.props.baseActiveList}
                    availableCount={this.props.baseAvailableCount}
                    columnOrder={this.props.baseColumnOrder}
                    columnVisibility={this.props.baseColumnVisibility}
                    columnWidth={this.props.baseColumnWidth}
                    offset={this.props.baseOffset}
                    page={this.props.basePage}
                    rowCount={this.props.baseRowCount}
                    rowCountCustom={this.props.baseRowCountCustom}
                    search={this.props.baseSearch}
                    searchParams={this.props.baseSearchParams}
                    showFilterRow={this.props.baseShowFilterRow}
                    showGlobalSearch={this.props.baseShowGlobalSearch}
                    sortingAsc={this.props.baseSortingAsc}
                    sortingCriterion={this.props.baseSortingCriterion}
                    totalCount={this.props.baseTotalCount}
                    useTmcNames={this.props.optionUseTmcNames || false}
                />
            );
        }

        return null;
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={[this.getBasesTable()].filter((item) => item !== null) as Array<JSX.Element>}
                layout={this.props.sceneLayout}
                dataPrivileges={this.state.tableBases}
                sceneTableSize={this.props.baseSceneTableSize}
                sceneConstant={menuConstants.PAGE_SYSTEM_BASES}
            />
        );
    }
}
