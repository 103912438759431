import * as propertyConstants from "../constants/propertyConstants";

import { Modal } from "../types/modal";

export const getNewModalId = (modalList: Array<Modal>): number => {
    let id = 0;

    modalList.forEach((modal) => {
        if (modal[propertyConstants.PROPERTY_ID] > id) {
            id = modal[propertyConstants.PROPERTY_ID];
        }
    });

    return id + 1;
};

export const getSearchState = (
    payload: Record<string, any>,
    state: Record<string, any>,
    searchName: string,
    searchParamsName: string
): any => {
    if (payload.searchColumn === undefined) {
        return {
            ...state,
            [searchName]: payload.search
        };
    }

    if (payload.search === null) {
        return {
            ...state,
            [searchParamsName]: {
                ...state[searchParamsName],
                [payload.searchColumn]: undefined
            }
        };
    }

    return {
        ...state,
        [searchParamsName]: {
            ...state[searchParamsName],
            [payload.searchColumn]: payload.search
        }
    };
};
