// barcodes
export const METHOD_GET_ALL_BARCODES = "dataGetAllBarcodes";
export const METHOD_GET_ITEM_BY_BARCODE = "dataGetItemByBarcode";

// bases
export const METHOD_GET_ALL_MASTER_BASES = "dataGetAllMasterBases";
export const METHOD_GET_ALL_MASTER_SIMPLE_BASES = "dataGetAllMasterSimpleBases";
export const METHOD_GET_ALL_MASTER_BASES_FOR_SYSTEM = "dataGetAllMasterBasesAvailableForSystem";
export const METHOD_ADD_MASTER_BASE = "dataInsertMasterBase";
export const METHOD_EDIT_MASTER_BASE = "dataUpdateMasterBase";
export const METHOD_DELETE_MASTER_BASE = "dataDeleteMasterBase";
export const METHOD_GET_ALL_SYSTEM_BASES = "dataGetAllSystemBases";
export const METHOD_GET_ALL_SYSTEM_BASES_FOR_ZONE = "dataGetAllSystemBasesAvailableForZone";
export const METHOD_ADD_SYSTEM_BASE = "dataInsertSystemBase";
export const METHOD_ADD_SYSTEM_BASE_WITH_PARAMS = "dataInsertSystemBaseWithParams";
export const METHOD_EDIT_SYSTEM_BASE = "dataUpdateSystemBase";
export const METHOD_DELETE_SYSTEM_BASE = "dataDeleteSystemBase";
export const METHOD_GET_ALL_ZONE_BASES = "dataGetAllZoneBases";
export const METHOD_ADD_ZONE_BASE = "dataInsertZoneBase";
export const METHOD_ADD_ZONE_BASE_WITH_PARAMS = "dataInsertZoneBaseWithParams";
export const METHOD_EDIT_ZONE_BASE = "dataUpdateZoneBase";
export const METHOD_DELETE_ZONE_BASE = "dataDeleteZoneBase";

// colors
export const METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_BY_FANDECK = "dataGetColorsInFandecksByFandeck";
export const METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_WITH_FORMULA_INFO = "dataGetAllColorsInFandecksWithFormulaInfo";
export const METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_BY_FANDECK = "dataGetSystemColorsInFandecksByFandeck";
export const METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_WITH_FORMULA_INFO =
    "dataGetAllSystemColorsInFandecksWithFormulaInfo";
export const METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_BY_FANDECK = "dataGetZoneColorsInFandecksByFandeck";
export const METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO = "dataGetAllZoneColorsInFandecksWithFormulaInfo";
export const METHOD_GET_COLOR_IN_FANDECK = "dataGetColorInFandeckById";
export const METHOD_GET_MASTER_COLOR_IN_FANDECK = "METHOD_GET_MASTER_COLOR_IN_FANDECK";
export const METHOD_GET_SYSTEM_COLOR_IN_FANDECK = "METHOD_GET_SYSTEM_COLOR_IN_FANDECK";
export const METHOD_GET_ZONE_COLOR_IN_FANDECK = "METHOD_GET_ZONE_COLOR_IN_FANDECK";
export const METHOD_GET_DATA_FOR_COLOR_IN_FANDECK = "dataGetDataForColorInFandeck";
export const METHOD_GET_DATA_FOR_SYSTEM_COLOR_IN_FANDECK = "METHOD_GET_DATA_FOR_SYSTEM_COLOR_IN_FANDECK";
export const METHOD_GET_DATA_FOR_ZONE_COLOR_IN_FANDECK = "METHOD_GET_DATA_FOR_ZONE_COLOR_IN_FANDECK";
export const METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS = "dataInsertColorInFandeckWithParams";
export const METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS = "dataUpdateColorInFandeckWithParams";
export const METHOD_DELETE_MASTER_COLOR_IN_FANDECK = "dataDeleteColorInFandeck";
export const METHOD_COLOR_CONVERT_LAB_TO_RGB = "utilColorConvertLabToRgb";
export const METHOD_COLOR_CONVERT_REFL_TO_LAB = "utilColorConvertReflToLab";

// colorants
export const METHOD_GET_ALL_MASTER_COLORANTS = "dataGetAllMasterColorants";
export const METHOD_GET_ALL_MASTER_SIMPLE_COLORANTS = "dataGetAllMasterSimpleColorants";
export const METHOD_GET_ALL_MASTER_COLORANTS_FOR_SYSTEM = "dataGetAllMasterColorantsAvailableForSystem";
export const METHOD_ADD_MASTER_COLORANT = "dataInsertMasterColorant";
export const METHOD_EDIT_MASTER_COLORANT = "dataUpdateMasterColorant";
export const METHOD_DELETE_MASTER_COLORANT = "dataDeleteMasterColorant";
export const METHOD_GET_ALL_SYSTEM_COLORANTS = "dataGetAllSystemColorants";
export const METHOD_GET_ALL_SYSTEM_SIMPLE_COLORANTS = "dataGetAllSystemSimpleColorants";
export const METHOD_GET_ALL_SYSTEM_COLORANTS_FOR_ZONE = "dataGetAllSystemColorantsAvailableForZone";
export const METHOD_GET_SYSTEM_COLORANT_BY_ID = "dataGetSystemColorantById";
export const METHOD_ADD_SYSTEM_COLORANT = "dataInsertSystemColorant";
export const METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS = "dataInsertSystemColorantWithParams";
export const METHOD_EDIT_SYSTEM_COLORANT = "dataUpdateSystemColorant";
export const METHOD_DELETE_SYSTEM_COLORANT = "dataDeleteSystemColorant";
export const METHOD_GET_ALL_ZONE_COLORANTS = "dataGetAllZoneColorants";
export const METHOD_GET_ALL_ZONE_SIMPLE_COLORANTS = "dataGetAllZoneSimpleColorants";
export const METHOD_GET_ZONE_COLORANT_BY_ID = "dataGetZoneColorantById";
export const METHOD_ADD_ZONE_COLORANT = "dataInsertZoneColorant";
export const METHOD_ADD_ZONE_COLORANT_WITH_PARAMS = "dataInsertZoneColorantWithParams";
export const METHOD_EDIT_ZONE_COLORANT = "dataUpdateZoneColorant";
export const METHOD_DELETE_ZONE_COLORANT = "dataDeleteZoneColorant";

// colorant batch
export const METHOD_GET_ALL_MASTER_BATCH_BY_COLORANT = "dataGetAllMasterBatchesByColorant";
export const METHOD_ADD_MASTER_COLORANT_BATCH = "dataInsertMasterColorantBatch";
export const METHOD_EDIT_MASTER_COLORANT_BATCH = "dataUpdateMasterColorantBatch";
export const METHOD_DELETE_MASTER_COLORANT_BATCH = "dataDeleteMasterBatch";

// colorant packages
export const METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT = "dataGetAllSystemColorantPackagesByColorant";
export const METHOD_GET_ALL_SYSTEM_COLORANT_PACKAGES_BY_COLORANT_FOR_SYSTEM =
    "dataGetAllColorantPackagesByColorantAvailableForSystem";
export const METHOD_GET_SYSTEM_COLORANT_PACKAGE_BY_ID = "dataGetSystemColorantPackageById";
export const METHOD_ADD_SYSTEM_COLORANT_PACKAGE = "dataInsertSystemColorantPackage";
export const METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS = "dataInsertSystemColorantPackageWithParams";
export const METHOD_EDIT_SYSTEM_COLORANT_PACKAGE = "dataUpdateSystemColorantPackage";
export const METHOD_DELETE_SYSTEM_COLORANT_PACKAGE = "dataDeleteSystemColorantPackage";
export const METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT = "dataGetAllZoneColorantPackagesByColorant";
export const METHOD_GET_ALL_ZONE_COLORANT_PACKAGES_BY_COLORANT_FOR_ZONE =
    "dataGetAllSystemColorantPackagesByColorantAvailableForZone";
export const METHOD_GET_ZONE_COLORANT_PACKAGE_BY_ID = "dataGetZoneColorantPackageById";
export const METHOD_ADD_ZONE_COLORANT_PACKAGE = "dataInsertZoneColorantPackage";
export const METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS = "dataInsertZoneColorantPackageWithParams";
export const METHOD_EDIT_ZONE_COLORANT_PACKAGE = "dataUpdateZoneColorantPackage";
export const METHOD_DELETE_ZONE_COLORANT_PACKAGE = "dataDeleteZoneColorantPackage";

// company
export const METHOD_GET_ALL_COMPANIES = "getAllCompanies";
export const METHOD_GET_ALL_COMPANIES_NO_PARAMS = "METHOD_GET_ALL_COMPANIES_NO_PARAMS";
export const METHOD_ADD_COMPANY = "insertCompany";
export const METHOD_EDIT_COMPANY = "updateCompany";
export const METHOD_DELETE_COMPANY = "deleteCompany";

// currencies
export const METHOD_GET_ALL_CURRENCIES = "dataGetAllCurrencies";
export const METHOD_GET_ALL_CURRENCIES_NO_PARAMS = "METHOD_GET_ALL_CURRENCIES_NO_PARAMS";
export const METHOD_ADD_CURRENCY = "dataInsertCurrency";
export const METHOD_EDIT_CURRENCY = "dataUpdateCurrency";
export const METHOD_DELETE_CURRENCY = "dataDeleteCurrency";
export const METHOD_GET_ACTIVE_SYSTEM_CURRENCY = "dataGetActiveSystemCurrency";
export const METHOD_SET_ACTIVE_SYSTEM_CURRENCY = "dataSetActiveSystemCurrency";
export const METHOD_GET_ACTIVE_ZONE_CURRENCY = "dataGetActiveZoneCurrency";

// data
export const METHOD_SERVER_INIT = "init";
export const METHOD_DATA_COMMIT = "dataCommit";
export const METHOD_DATA_ROLLBACK = "dataRollback";
export const METHOD_GET_SESSION_UUID = "getSessionUuid";
export const METHOD_GET_SQLITE_INFO = "sqliteInfo";
export const METHOD_GET_VERSION = "getVersion";

// database
export const METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES = "getAllDatabasesWithSystemsAndZones";
export const METHOD_GET_ALL_DATABASES = "getAllDatabases";
export const METHOD_GET_ALL_DATABASES_NO_PARAMS = "METHOD_GET_ALL_DATABASES_NO_PARAMS";
export const METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE = "METHOD_GET_ALL_DATABASES_FOR_MONITORING_SCENE";
export const METHOD_ADD_DATABASE = "insertDatabase";
export const METHOD_ADD_EMPTY_DATABASE = "insertEmptyDatabase";
export const METHOD_DUPLICATE_DATABASE = "duplicateDatabase";
export const METHOD_EDIT_DATABASE = "updateDatabase";
export const METHOD_DELETE_DATABASE = "deleteDatabase";
export const METHOD_GET_ACTIVE_DATABASE = "getActiveDatabase";
export const METHOD_SET_ACTIVE_DATABASE = "setActiveDatabase";
export const METHOD_UPDATE_ACTIVE_DATABASE_STRUCTURE = "updateActiveDatabaseStructure";
export const METHOD_CLOSE_ACTIVE_DATABASE = "closeActiveDatabase";
export const METHOD_VACUUM_ALL_DATABASES = "vacuumAllDatabases";

// exports
export const METHOD_GET_ALL_EXPORT_TASK_STATUSES = "getAllExportTaskStatuses";
export const METHOD_GET_ALL_EXPORT_TASK_TYPES = "getAllExportTaskTypes";
export const METHOD_GET_ALL_EXPORT_TASKS = "getAllExportTasks";
export const METHOD_INSERT_EXPORT_TASK = "insertExportTask";
export const METHOD_INSERT_EXPORT_TASKS = "insertExportTasks";
export const METHOD_ABORT_EXPORT_TASK = "abortExportTask";
export const METHOD_DELETE_EXPORT_TASK = "deleteExportTask";
export const METHOD_DELETE_EXPORT_TASKS = "deleteExportTasks";
export const METHOD_HIDE_EXPORT_TASK = "hideExportTask";
export const METHOD_SHOW_EXPORT_TASK = "showExportTask";

// fandecks
export const METHOD_GET_ALL_MASTER_FANDECKS = "dataGetAllMasterFandecks";
export const METHOD_GET_ALL_MASTER_SIMPLE_FANDECKS = "dataGetAllMasterSimpleFandecks";
export const METHOD_GET_ALL_MASTER_FANDECKS_FOR_SYSTEM = "dataGetAllMasterFandecksAvailableForSystem";
export const METHOD_GET_MASTER_FANDECK_BY_ID = "dataGetMasterFandeckById";
export const METHOD_ADD_MASTER_FANDECK = "dataInsertMasterFandeck";
export const METHOD_EDIT_MASTER_FANDECK = "dataUpdateMasterFandeck";
export const METHOD_DELETE_MASTER_FANDECK = "dataDeleteMasterFandeck";
export const METHOD_GET_ALL_SYSTEM_FANDECKS = "dataGetAllSystemFandecks";
export const METHOD_GET_ALL_SYSTEM_FANDECKS_FOR_ZONE = "dataGetAllSystemFandecksAvailableForZone";
export const METHOD_GET_SYSTEM_FANDECK_BY_ID = "dataGetSystemFandeckById";
export const METHOD_ADD_SYSTEM_FANDECK = "dataInsertSystemFandeck"; // TODO why not used
export const METHOD_GET_ALL_SYSTEM_SIMPLE_FANDECKS = "dataGetAllSystemSimpleFandecks";
export const METHOD_GET_SYSTEM_FANDECK = "dataGetSystemFandeckById";
export const METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS = "dataInsertSystemFandeckWithParams";
export const METHOD_EDIT_SYSTEM_FANDECK = "dataUpdateSystemFandeck";
export const METHOD_DELETE_SYSTEM_FANDECK = "dataDeleteSystemFandeck";
export const METHOD_GET_ALL_ZONE_FANDECKS = "dataGetAllZoneFandecks";
export const METHOD_GET_ZONE_FANDECK = "dataGetZoneFandeckById";
export const METHOD_GET_ZONE_FANDECK_BY_ID = "dataGetZoneFandeckById";
export const METHOD_ADD_ZONE_FANDECK = "dataInsertZoneFandeck";
export const METHOD_GET_ALL_ZONE_SIMPLE_FANDECKS = "dataGetAllZoneSimpleFandecks";
export const METHOD_ADD_ZONE_FANDECK_WITH_PARAMS = "dataInsertZoneFandeckWithParams";
export const METHOD_EDIT_ZONE_FANDECK = "dataUpdateZoneFandeck";
export const METHOD_DELETE_ZONE_FANDECK = "dataDeleteZoneFandeck";

// files
export const METHOD_GET_ALL_FILES = "dataGetAllFiles";
export const METHOD_GET_ALL_PRODUCT_SHEETS = "METHOD_GET_ALL_PRODUCT_SHEETS";
export const METHOD_GET_ALL_STATIC_SQL = "METHOD_GET_ALL_STATIC_SQL";
export const METHOD_GET_ALL_STATIC_SQL_NO_PARAMS = "METHOD_GET_ALL_STATIC_SQL_NO_PARAMS";
export const METHOD_ADD_FILE = "dataInsertFile";
export const METHOD_ADD_PRODUCT_SHEET = "METHOD_ADD_PRODUCT_SHEET";
export const METHOD_ADD_STATIC_SQL = "METHOD_ADD_STATIC_SQL";
export const METHOD_EDIT_FILE = "dataUpdateFile";
export const METHOD_EDIT_PRODUCT_SHEET = "METHOD_EDIT_PRODUCT_SHEET";
export const METHOD_EDIT_STATIC_SQL = "METHOD_EDIT_STATIC_SQL";
export const METHOD_DELETE_FILE = "dataDeleteFile";
export const METHOD_DELETE_PRODUCT_SHEET = "METHOD_DELETE_PRODUCT_SHEET";
export const METHOD_DELETE_STATIC_SQL = "METHOD_DELETE_STATIC_SQL";
export const METHOD_DELETE_UPLOADED_FILE = "deleteUploadedFile";
export const METHOD_RUN_STATIC_SQL = "runStaticSql";

// file categories
export const METHOD_GET_ALL_FILE_CATEGORIES = "dataGetAllFileCategories";
export const METHOD_GET_ALL_FILE_CATEGORIES_NO_PARAMS = "METHOD_GET_ALL_FILE_CATEGORIES_NO_PARAMS";
export const METHOD_ADD_FILE_CATEGORY = "dataInsertFileCategory";
export const METHOD_EDIT_FILE_CATEGORY = "dataUpdateFileCategory";
export const METHOD_DELETE_FILE_CATEGORY = "dataDeleteFileCategory";

// formula notes
export const METHOD_GET_ALL_MASTER_FORMULA_NOTES = "dataGetAllMasterFormulaNotes";
export const METHOD_ADD_MASTER_FORMULA_NOTE = "dataInsertMasterFormulaNote";
export const METHOD_EDIT_MASTER_FORMULA_NOTE = "dataUpdateMasterFormulaNote";
export const METHOD_DELETE_MASTER_FORMULA_NOTE = "dataDeleteMasterFormulaNote";
export const METHOD_GET_ALL_SYSTEM_FORMULA_NOTES = "dataGetAllSystemFormulaNotes";
export const METHOD_ADD_SYSTEM_FORMULA_NOTE = "dataInsertSystemFormulaNote";
export const METHOD_EDIT_SYSTEM_FORMULA_NOTE = "dataUpdateSystemFormulaNote";
export const METHOD_GET_ALL_ZONE_FORMULA_NOTES = "dataGetAllZoneFormulaNotes";
export const METHOD_ADD_ZONE_FORMULA_NOTE = "dataInsertZoneFormulaNote";
export const METHOD_EDIT_ZONE_FORMULA_NOTE = "dataUpdateZoneFormulaNote";

// formulas
export const METHOD_GET_MAIN_FORMULAS_FOR_COMBINATION = "dataGetMainFormulasForCombination";
export const METHOD_GET_FORMULA = "dataGetMainFormulaCombinationByIds";
export const METHOD_GET_MASTER_FORMULA = "METHOD_GET_MASTER_FORMULA";
export const METHOD_ADD_MASTER_FORMULA = "dataInsertMainFormulaCombinationWithParams";
export const METHOD_EDIT_MASTER_FORMULA = "dataUpdateMainFormulaCombinationWithParams";
export const METHOD_DELETE_MASTER_FORMULA = "dataDeleteMainFormulaCombinationByIds";
export const METHOD_SET_MASTER_FORMULA_POSITION = "dataSetMainFormulaCombinationPositionById";
export const METHOD_GET_SYSTEM_FORMULA = "METHOD_GET_SYSTEM_FORMULA";
export const METHOD_GET_SYSTEM_MAIN_FORMULAS_FOR_COMBINATION = "dataGetSystemMainFormulasForCombination";
export const METHOD_GET_ZONE_FORMULA = "METHOD_GET_ZONE_FORMULA";
export const METHOD_GET_ZONE_MAIN_FORMULAS_FOR_COMBINATION = "dataGetZoneMainFormulasForCombination";

// formula units
export const METHOD_GET_ALL_FORMULA_UNITS = "dataGetAllFormulaUnits";

// images
export const METHOD_GET_ALL_IMAGES = "dataGetAllImages";
export const METHOD_ADD_IMAGE = "dataInsertImage";
export const METHOD_EDIT_IMAGE = "dataUpdateImage";
export const METHOD_DELETE_IMAGE = "dataDeleteImage";

// image categories
export const METHOD_GET_ALL_IMAGE_CATEGORIES = "dataGetAllImageCategories";
export const METHOD_GET_ALL_IMAGE_CATEGORIES_NO_PARAMS = "METHOD_GET_ALL_IMAGE_CATEGORIES_NO_PARAMS";
export const METHOD_ADD_IMAGE_CATEGORY = "dataInsertImageCategory";
export const METHOD_EDIT_IMAGE_CATEGORY = "dataUpdateImageCategory";
export const METHOD_DELETE_IMAGE_CATEGORY = "dataDeleteImageCategory";

// import
export const METHOD_GET_ALL_IMPORT_TASK_STATUSES = "getAllImportTaskStatuses";
export const METHOD_GET_ALL_IMPORT_TASK_TYPES = "getAllImportTaskTypes";
export const METHOD_GET_ALL_IMPORT_TASKS = "getAllImportTasks";
export const METHOD_ADD_IMPORT_TASK = "insertImportTask";
export const METHOD_ABORT_IMPORT_TASK = "abortImportTask";
export const METHOD_DELETE_IMPORT_TASK = "deleteImportTask";
export const METHOD_DELETE_IMPORT_TASKS = "deleteImportTasks";
export const METHOD_HIDE_IMPORT_TASK = "hideImportTask";
export const METHOD_SHOW_IMPORT_TASK = "showImportTask";

// license
export const METHOD_ACTIVATE_ONLINE_LICENSE = "licensingOnlineActivate";
export const METHOD_GET_LICENSE_AVAILABLE_PRODUCTS = "licensingGetAvailableProducts";
export const METHOD_GET_LICENSE_INFO = "licensingGetInfo";
export const METHOD_GET_LICENSE_ONLINE_ACTIVATION_AVAILABILITY = "licensingOnlineActivationAvailable";
export const METHOD_GET_LICENSE_ONLINE_COUNTS = "licensingOnlineLicenseCounts";
export const METHOD_GET_LICENSE_STATUS = "licensingGetLicenseStatus";
export const METHOD_GET_LICENSE_UUID = "licensingGetLicenseUuid";
export const METHOD_DELETE_LICENSE = "licensingRemoveLicense";
export const METHOD_UPDATE_ONLINE_LICENSE = "licensingOnlineUpdateLicense";

// login
export const METHOD_LOGIN = "userLogin";
export const METHOD_LOGOUT = "userLogout";
export const METHOD_IS_USER_LOGGED_IN = "isUserLoggedIn";
export const METHOD_GET_LOGGED_USER = "getLoggedUser";

// monitoring
export const METHOD_GET_DATA_SIZE = "getDataSize";

// options
export const METHOD_GET_ALL_GLOBAL_OPTIONS = "getAllGlobalOptions";
export const METHOD_GET_ALL_MASTER_REDLIKE_OPTIONS = "dataGetMasterOptions";
export const METHOD_GET_ALL_SYSTEM_REDLIKE_OPTIONS = "dataGetSystemOptions";
export const METHOD_GET_ALL_ZONE_REDLIKE_OPTIONS = "dataGetZoneOptions";
export const METHOD_GET_ALL_USER_OPTIONS = "getAllUserOptions";
export const METHOD_SET_GLOBAL_OPTION = "setGlobalOption";
export const METHOD_SET_GLOBAL_OPTIONS = "setGlobalOptions";
export const METHOD_SET_MASTER_REDLIKE_OPTION = "dataSetSingleMasterOptionValue";
export const METHOD_SET_MASTER_REDLIKE_OPTION_OVERWRITE = "dataSetSingleMasterOptionOverwrite";
export const METHOD_SET_SYSTEM_REDLIKE_OPTION = "dataSetSingleSystemOptionValue";
export const METHOD_SET_SYSTEM_REDLIKE_OPTION_OVERWRITE = "dataSetSingleSystemOptionOverwrite";
export const METHOD_SET_ZONE_REDLIKE_OPTION = "dataSetSingleZoneOptionValue";
export const METHOD_SET_ZONE_REDLIKE_OPTION_OVERWRITE = "dataSetSingleZoneOptionOverwrite";
export const METHOD_SET_USER_OPTION = "setUserOption";
export const METHOD_SET_USER_OPTIONS = "setUserOptions";
export const METHOD_RESET_ALL_MASTER_REDLIKE_OPTIONS = "dataResetAllMasterOptions";
export const METHOD_RESET_MASTER_REDLIKE_OPTION = "dataResetSingleMasterOption";
export const METHOD_RESET_ALL_SYSTEM_REDLIKE_OPTIONS = "dataResetAllSystemOptions";
export const METHOD_RESET_SYSTEM_REDLIKE_OPTION = "dataResetSingleSystemOption";
export const METHOD_RESET_ALL_ZONE_REDLIKE_OPTIONS = "dataResetAllZoneOptions";
export const METHOD_RESET_ZONE_REDLIKE_OPTION = "dataResetSingleZoneOption";

// prices
export const METHOD_GET_ALL_SYSTEM_PRICE_MARGINS = "dataGetAllSystemMargins";
export const METHOD_ADD_SYSTEM_PRICE_MARGIN = "dataInsertSystemMargin";
export const METHOD_EDIT_SYSTEM_PRICE_MARGIN = "dataUpdateSystemMargin";
export const METHOD_DELETE_SYSTEM_PRICE_MARGIN = "dataDeleteSystemMargin";
export const METHOD_GET_SYSTEM_PRICE_OPTIONS = "dataGetSystemPriceOptions";
export const METHOD_SET_SYSTEM_PRICE_OPTIONS = "dataSetSystemPriceOptions";
export const METHOD_GET_SYSTEM_GENERIC_PRICE_DATA = "dataGetSystemGenericPriceData";
export const METHOD_SET_SYSTEM_GENERIC_PRICE_DATA = "dataSetSystemGenericPriceData";
export const METHOD_SET_SINGLE_SYSTEM_GENERIC_PRICE_DATA = "dataSetSingleSystemGenericPriceData";
export const METHOD_GET_SYSTEM_PRICE_GROUP_DATA = "dataGetSystemPriceGroupPriceData";
export const METHOD_SET_SYSTEM_PRICE_GROUP_DATA = "dataSetSystemPriceGroupPriceData";
export const METHOD_SET_SINGLE_SYSTEM_PRICE_GROUP_DATA = "dataSetSingleSystemPriceGroupPriceData";
export const METHOD_GET_ALL_ZONE_PRICE_MARGINS = "dataGetAllZoneMargins";
export const METHOD_ADD_ZONE_PRICE_MARGIN = "dataInsertZoneMargin";
export const METHOD_EDIT_ZONE_PRICE_MARGIN = "dataUpdateZoneMargin";
export const METHOD_DELETE_ZONE_PRICE_MARGIN = "dataDeleteZoneMargin";
export const METHOD_GET_ZONE_PRICE_OPTIONS = "dataGetZonePriceOptions";
export const METHOD_SET_ZONE_PRICE_OPTIONS = "dataSetZonePriceOptions";
export const METHOD_GET_ZONE_GENERIC_PRICE_DATA = "dataGetZoneGenericPriceData";
export const METHOD_SET_ZONE_GENERIC_PRICE_DATA = "dataSetZoneGenericPriceData";
export const METHOD_SET_SINGLE_ZONE_GENERIC_PRICE_DATA = "dataSetSingleZoneGenericPriceData";
export const METHOD_GET_ZONE_PRICE_GROUP_DATA = "dataGetZonePriceGroupPriceData";
export const METHOD_SET_ZONE_PRICE_GROUP_DATA = "dataSetZonePriceGroupPriceData";
export const METHOD_SET_SINGLE_ZONE_PRICE_GROUP_DATA = "dataSetSingleZonePriceGroupPriceData";

// packages
export const METHOD_GET_ALL_PACKAGES = "dataGetAllPackages";
export const METHOD_ADD_PACKAGE = "dataInsertPackage";
export const METHOD_EDIT_PACKAGE = "dataUpdatePackage";
export const METHOD_DELETE_PACKAGE = "dataDeletePackage";
// packages for prices
export const METHOD_GET_ALL_SYSTEM_PACKAGES = "dataGetAllSystemPackages";
export const METHOD_EDIT_SYSTEM_PACKAGE = "dataUpdateSystemPackage";
export const METHOD_GET_ALL_ZONE_PACKAGES = "dataGetAllZonePackages";
export const METHOD_EDIT_ZONE_PACKAGE = "dataUpdateZonePackage";

// product groups
export const METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS = "dataGetAllSystemProductGroups";
export const METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_ZONE = "dataGetAllSystemProductGroupsAvailableForZone";
export const METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT =
    "dataGetAllSystemProductGroupsAvailableForProduct";
export const METHOD_GET_ALL_SYSTEM_PRODUCT_GROUPS_FOR_PRODUCT = "dataGetAllSystemProductGroupsForProduct";
export const METHOD_ADD_SYSTEM_PRODUCT_GROUP = "dataInsertSystemProductGroup";
export const METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS = "dataInsertSystemProductGroupWithParams";
export const METHOD_EDIT_SYSTEM_PRODUCT_GROUP = "dataUpdateSystemProductGroup";
export const METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS = "dataUpdateSystemProductGroupWithParams";
export const METHOD_DELETE_SYSTEM_PRODUCT_GROUP = "dataDeleteSystemProductGroup";
export const METHOD_GET_ALL_ZONE_PRODUCT_GROUPS = "dataGetAllZoneProductGroups";
export const METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_AVAILABLE_FOR_PRODUCT =
    "dataGetAllZoneProductGroupsAvailableForProduct";
export const METHOD_GET_ALL_ZONE_PRODUCT_GROUPS_FOR_PRODUCT = "dataGetAllZoneProductGroupsForProduct";
export const METHOD_ADD_ZONE_PRODUCT_GROUP = "dataInsertZoneProductGroup";
export const METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS = "dataInsertZoneProductGroupsWithParams";
export const METHOD_EDIT_ZONE_PRODUCT_GROUP = "dataUpdateZoneProductGroup";
export const METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS = "dataUpdateZoneProductGroupWithParams";
export const METHOD_DELETE_ZONE_PRODUCT_GROUP = "dataDeleteZoneProductGroup";

// products
export const METHOD_GET_ALL_MASTER_PRODUCTS = "dataGetAllMasterProducts";
export const METHOD_GET_ALL_MASTER_SIMPLE_PRODUCTS = "dataGetAllMasterSimpleProducts";
export const METHOD_GET_ALL_MASTER_PRODUCTS_FOR_SYSTEM = "METHOD_GET_ALL_MASTER_PRODUCTS_AVAILABLE_FOR_SYSTEM";
export const METHOD_GET_MASTER_PRODUCT_BY_ID = "dataGetMasterProductById";
export const METHOD_ADD_MASTER_PRODUCT = "dataInsertMasterProduct";
export const METHOD_ADD_MASTER_PRODUCT_WITH_INDEX_DATA = "METHOD_ADD_MASTER_PRODUCT_WITH_INDEX_DATA";
export const METHOD_DUPLICATE_MASTER_PRODUCT = "dataDuplicateMasterProduct";
export const METHOD_EDIT_MASTER_PRODUCT = "dataUpdateMasterProduct";
export const METHOD_DELETE_MASTER_PRODUCT = "dataDeleteMasterProduct";
export const METHOD_GET_SYSTEM_PRODUCT = "METHOD_GET_SYSTEM_PRODUCT";
export const METHOD_GET_ALL_SYSTEM_PRODUCTS = "dataGetAllSystemProducts";
export const METHOD_GET_ALL_SYSTEM_SIMPLE_PRODUCTS = "dataGetAllSystemSimpleProducts";
export const METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE = "METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_ZONE";
export const METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS = "METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUPS";
export const METHOD_GET_ALL_SYSTEM_PRODUCTS_FOR_GROUP = "dataGetAllSystemProductsForGroup";
export const METHOD_GET_ALL_SYSTEM_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP =
    "dataGetAllSystemProductsAvailableForProductGroup";
export const METHOD_GET_SYSTEM_PRODUCT_BY_ID = "dataGetSystemProductById";
export const METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS = "dataInsertSystemProductWithParams";
export const METHOD_EDIT_SYSTEM_PRODUCT = "dataUpdateSystemProduct";
export const METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS = "dataUpdateSystemProductWithParams";
export const METHOD_DELETE_SYSTEM_PRODUCT = "dataDeleteSystemProduct";
export const METHOD_DUPLICATE_SYSTEM_PRODUCT = "dataDuplicateSystemProduct";
export const METHOD_GET_ZONE_PRODUCT = "METHOD_GET_ZONE_PRODUCT";
export const METHOD_GET_ALL_ZONE_PRODUCTS = "dataGetAllZoneProducts";
export const METHOD_GET_ALL_ZONE_SIMPLE_PRODUCTS = "dataGetAllZoneSimpleProducts";
export const METHOD_GET_ALL_ZONE_PRODUCTS_FOR_GROUP = "dataGetAllZoneProductsForGroup";
export const METHOD_GET_ALL_ZONE_PRODUCTS_FOR_PRODUCT_GROUP = "dataGetAllZoneProductsAvailableForProductGroup"; // TODO why not in use
export const METHOD_GET_ALL_ZONE_PRODUCTS_AVAILABLE_FOR_PRODUCT_GROUP =
    "dataGetAllZoneProductsAvailableForProductGroup";
export const METHOD_GET_ZONE_PRODUCT_BY_ID = "dataGetZoneProductById";
export const METHOD_ADD_ZONE_PRODUCT = "dataInsertZoneProduct";
export const METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS = "dataInsertZoneProductWithParams";
export const METHOD_EDIT_ZONE_PRODUCT = "dataUpdateZoneProduct";
export const METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS = "dataUpdateZoneProductWithParams";
export const METHOD_DELETE_ZONE_PRODUCT = "dataDeleteZoneProduct";
export const METHOD_DUPLICATE_ZONE_PRODUCT = "dataDuplicateZoneProduct";

// base in product
export const METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS =
    "METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS";
export const METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT = "dataGetAllMasterBasesInProductsByProduct";
export const METHOD_GET_ALL_MASTER_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT =
    "dataGetAllMasterSimpleBasesInProductsByProduct";
export const METHOD_GET_ALL_MASTER_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_SYSTEM =
    "dataGetAllMasterBasesInProductsByProductAvailableForSystem";
export const METHOD_GET_MASTER_BASE_IN_PRODUCT_BY_ID = "dataGetMasterBaseInProductById";
export const METHOD_ADD_MASTER_BASE_IN_PRODUCT = "dataInsertMasterBaseInProduct";
export const METHOD_EDIT_MASTER_BASE_IN_PRODUCT = "dataUpdateMasterBaseInProduct";
export const METHOD_DELETE_MASTER_BASE_IN_PRODUCT = "dataDeleteMasterBaseInProduct";
export const METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS =
    "METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS";
export const METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT = "dataGetAllSystemBasesInProductByProduct";
export const METHOD_GET_ALL_SYSTEM_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT =
    "dataGetAllSystemSimpleBasesInProductsByProduct";
export const METHOD_GET_ALL_SYSTEM_BASES_IN_PRODUCTS_BY_PRODUCT_FOR_ZONE =
    "dataGetAllSystemBasesInProductsByProductAvailableForZone";
export const METHOD_GET_SYSTEM_BASE_IN_PRODUCT_BY_ID = "dataGetSystemBaseInProductById";
export const METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS = "dataInsertSystemBaseInProductWithParams";
export const METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT = "dataUpdateSystemBaseInProduct";
export const METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT = "dataDeleteSystemBaseInProduct";
export const METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT = "dataGetAllZoneBasesInProductsByProduct";
export const METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS =
    "METHOD_GET_ALL_ZONE_BASES_IN_PRODUCTS_BY_PRODUCT_NO_PARAMS";
export const METHOD_GET_ALL_ZONE_SIMPLE_BASES_IN_PRODUCTS_BY_PRODUCT = "dataGetAllZoneSimpleBasesInProductsByProduct";
export const METHOD_GET_ZONE_BASE_IN_PRODUCT_BY_ID = "dataGetZoneBaseInProductById";
export const METHOD_ADD_ZONE_BASE_IN_PRODUCT = "dataInsertZoneBaseInProduct";
export const METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS = "dataInsertZoneBaseInProductWithParams";
export const METHOD_EDIT_ZONE_BASE_IN_PRODUCT = "dataUpdateZoneBaseInProduct";
export const METHOD_DELETE_ZONE_BASE_IN_PRODUCT = "dataDeleteZoneBaseInProduct";

// base in product package
export const METHOD_GET_ALL_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_SYSTEM =
    "dataGetAllBaseInProductPackagesByBaseInProductAvailableForSystem";
export const METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT =
    "dataGetAllSystemBaseInProductPackagesByBaseInProduct";
export const METHOD_GET_ALL_SYSTEM_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT_FOR_ZONE =
    "dataGetAllSystemBaseInProductPackagesByBaseInProductAvailableForZone";
export const METHOD_GET_SYSTEM_BASE_IN_PRODUCT_PACKAGE_BY_ID = "dataGetSystemBaseInProductPackageById";
export const METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS = "dataInsertSystemBaseInProductPackageWithParams";
export const METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE = "dataUpdateSystemBaseInProductPackage";
export const METHOD_DELETE_SYSTEM_BASE_IN_PRODUCT_PACKAGE = "dataDeleteSystemBaseInProductPackage";
export const METHOD_GET_ALL_ZONE_BASE_IN_PRODUCT_PACKAGES_BY_BASE_IN_PRODUCT =
    "dataGetAllZoneBaseInProductPackagesByBaseInProduct";
export const METHOD_GET_ZONE_BASE_IN_PRODUCT_PACKAGE_BY_ID = "dataGetZoneBaseInProductPackageById";
export const METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS = "dataInsertZoneBaseInProductPackageWithParams";
export const METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE = "dataUpdateZoneBaseInProductPackage";
export const METHOD_DELETE_ZONE_BASE_IN_PRODUCT_PACKAGE = "dataDeleteZoneBaseInProductPackage";

// scripts
export const METHOD_GET_ALL_SCRIPTS = "getAllScripts";
export const METHOD_GET_ALL_SCRIPTS_NO_PARAMS = "METHOD_GET_ALL_SCRIPTS_NO_PARAMS";
export const METHOD_START_RUN_SCRIPT = "startRunScript";

// spectro type
export const METHOD_GET_ALL_SPECTRO_TYPES = "dataGetAllSpectroTypes";
export const METHOD_GET_ALL_SPECTRO_TYPES_NO_PARAMS = "METHOD_GET_ALL_SPECTRO_TYPES_NO_PARAMS";
export const METHOD_ADD_SPECTRO_TYPE = "dataInsertSpectroType";
export const METHOD_EDIT_SPECTRO_TYPE = "dataUpdateSpectroType";
export const METHOD_DELETE_SPECTRO_TYPE = "dataDeleteSpectroType";

// system
export const METHOD_GET_ALL_SYSTEMS = "dataGetAllSystems";
export const METHOD_GET_ALL_SYSTEMS_NO_PARAMS = "METHOD_GET_ALL_SYSTEMS_NO_PARAMS";
export const METHOD_ADD_SYSTEM = "dataInsertSystem";
export const METHOD_DUPLICATE_SYSTEM = "dataDuplicateSystem";
export const METHOD_EDIT_SYSTEM = "dataUpdateSystem";
export const METHOD_DELETE_SYSTEM = "dataDeleteSystem";
export const METHOD_SET_ACTIVE_SYSTEM = "dataSetActiveSystem";

// units
export const METHOD_GET_ALL_UNITS = "dataGetAllUnits";
export const METHOD_GET_ALL_UNITS_NO_PARAMS = "METHOD_GET_ALL_UNITS_NO_PARAMS";
export const METHOD_ADD_UNIT = "dataInsertUnit";
export const METHOD_EDIT_UNIT = "dataUpdateUnit";
export const METHOD_DELETE_UNIT = "dataDeleteUnit";

// redlike password
export const METHOD_GET_GENERATE_REDLIKE_ONE_TIME_ACCESS_PASSWORD = "data_generate_deso_superadmin_password";

// redlike privileges
export const METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP = "dataGetAllPrivilegesForUserGroup";
export const METHOD_SET_REDLIKE_PRIVILEGE = "dataSetSinglePrivilege";
export const METHOD_RESET_ALL_REDLIKE_PRIVILEGES = "dataResetAllPrivileges";

// redlike users
export const METHOD_GET_ALL_MASTER_REDLIKE_USERS = "dataGetAllMasterUsers";
export const METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS = "dataGetAllMasterUsersWithGroups";
export const METHOD_ADD_MASTER_REDLIKE_USER_WITH_PARAMS = "dataInsertMasterUserWithUserGroups";
export const METHOD_EDIT_MASTER_REDLIKE_USER = "dataUpdateMasterUser";
export const METHOD_EDIT_MASTER_REDLIKE_USER_WITH_PARAMS = "dataUpdateMasterUserWithUserGroups";
export const METHOD_DELETE_MASTER_REDLIKE_USER = "dataDeleteMasterUser";
export const METHOD_GET_ALL_SYSTEM_REDLIKE_USERS = "dataGetAllSystemUsers";
export const METHOD_GET_ALL_SYSTEM_REDLIKE_USERS_WITH_GROUPS = "dataGetAllSystemUsersWithGroups";
export const METHOD_ADD_SYSTEM_REDLIKE_USER_WITH_PARAMS = "dataInsertSystemUserWithUserGroups";
export const METHOD_EDIT_SYSTEM_REDLIKE_USER = "dataUpdateSystemUser";
export const METHOD_EDIT_SYSTEM_REDLIKE_USER_WITH_PARAMS = "dataUpdateSystemUserWithUserGroups";
export const METHOD_DELETE_SYSTEM_REDLIKE_USER = "dataDeleteSystemUser";
export const METHOD_GET_ALL_ZONE_REDLIKE_USERS = "dataGetAllZoneUsers";
export const METHOD_GET_ALL_ZONE_REDLIKE_USERS_WITH_GROUPS = "dataGetAllZoneUsersWithGroups";
export const METHOD_ADD_ZONE_REDLIKE_USER_WITH_PARAMS = "dataInsertZoneUserWithUserGroups";
export const METHOD_EDIT_ZONE_REDLIKE_USER = "dataUpdateZoneUser";
export const METHOD_EDIT_ZONE_REDLIKE_USER_WITH_PARAMS = "dataUpdateZoneUserWithUserGroups";
export const METHOD_DELETE_ZONE_REDLIKE_USER = "dataDeleteZoneUser";

// redlike user groups
export const METHOD_GET_ALL_REDLIKE_USER_GROUPS = "dataGetAllUserGroups";
export const METHOD_GET_ALL_REDLIKE_USER_GROUPS_NO_PARAMS = "METHOD_GET_ALL_REDLIKE_USER_GROUPS_NO_PARAMS";
export const METHOD_GET_REDLIKE_USER_GROUPS_FOR_USER = "dataGetUserGroupsForUser";
export const METHOD_GET_REDLIKE_USER_GROUPS_AVAILABLE_FOR_USER = "dataGetUserGroupsAvailableForUser";
export const METHOD_ADD_REDLIKE_USER_GROUP = "dataInsertUserGroup";
export const METHOD_EDIT_REDLIKE_USER_GROUP = "dataUpdateUserGroup";
export const METHOD_DELETE_REDLIKE_USER_GROUP = "dataDeleteUserGroup";
export const METHOD_DUPLICATE_REDLIKE_USER_GROUP = "dataDuplicateUserGroup";

// user actions history
export const METHOD_GET_ALL_USER_HISTORY_ACTIONS = "dataGetAllHistoryActions";

// users
export const METHOD_GET_ALL_USERS_WITH_GROUPS = "getAllUsersWithGroups";
export const METHOD_ADD_USER = "insertUser";
export const METHOD_ADD_USER_WITH_USER_GROUPS = "insertUserWithUserGroups";
export const METHOD_EDIT_USER = "updateUser";
export const METHOD_EDIT_USER_WITH_USER_GROUPS = "updateUserWithUserGroups";
export const METHOD_DELETE_USER = "deleteUser";
export const METHOD_CHANGE_USER_PASSWORD = "changeUserPassword";

// user groups
export const METHOD_GET_ALL_USER_GROUPS = "getAllUserGroups";
export const METHOD_GET_ALL_USER_GROUPS_NO_PARAMS = "METHOD_GET_ALL_USER_GROUPS_NO_PARAMS";
export const METHOD_ADD_USER_GROUP = "insertUserGroup";
export const METHOD_EDIT_USER_GROUP = "updateUserGroup";
export const METHOD_DELETE_USER_GROUP = "deleteUserGroup";

// zones
export const METHOD_GET_ALL_ZONES_BY_SYSTEM = "dataGetAllZonesBySystem";
export const METHOD_GET_ALL_ZONES_NO_PARAMS = "METHOD_GET_ALL_ZONES_NO_PARAMS";
export const METHOD_GET_ALL_DUPLICATE_ZONES_NO_PARAMS = "METHOD_GET_ALL_DUPLICATE_ZONES_NO_PARAMS";
export const METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY = "dataGetZonesAvailableForCompany";
export const METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY_NO_PARAMS =
    "METHOD_GET_ALL_ZONES_AVAILABLE_FOR_COMPANY_NO_PARAMS";
export const METHOD_ADD_ZONE = "dataInsertZone";
export const METHOD_DUPLICATE_ZONE = "dataDuplicateZone";
export const METHOD_EDIT_ZONE = "dataUpdateZone";
export const METHOD_DELETE_ZONE = "dataDeleteZone";
export const METHOD_SET_ACTIVE_ZONE = "dataSetActiveZone";

// saving SimpleObjectModal
export const SAVING_MODAL = [
    METHOD_ADD_COMPANY,
    METHOD_EDIT_COMPANY,
    METHOD_ADD_CURRENCY,
    METHOD_EDIT_CURRENCY,
    METHOD_ADD_DATABASE,
    METHOD_ADD_EMPTY_DATABASE,
    METHOD_DUPLICATE_DATABASE,
    METHOD_EDIT_DATABASE,
    METHOD_DELETE_EXPORT_TASKS,
    METHOD_INSERT_EXPORT_TASK,
    METHOD_ADD_IMAGE,
    METHOD_EDIT_IMAGE,
    METHOD_DELETE_IMPORT_TASKS,
    METHOD_ADD_MASTER_BASE,
    METHOD_EDIT_MASTER_BASE,
    METHOD_ADD_MASTER_BASE_IN_PRODUCT,
    METHOD_EDIT_MASTER_BASE_IN_PRODUCT,
    METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
    METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
    METHOD_ADD_MASTER_COLORANT,
    METHOD_EDIT_MASTER_COLORANT,
    METHOD_ADD_MASTER_FANDECK,
    METHOD_EDIT_MASTER_FANDECK,
    METHOD_ADD_MASTER_FORMULA,
    METHOD_EDIT_MASTER_FORMULA,
    METHOD_ADD_MASTER_FORMULA_NOTE,
    METHOD_EDIT_MASTER_FORMULA_NOTE,
    METHOD_ADD_MASTER_PRODUCT,
    METHOD_EDIT_MASTER_PRODUCT,
    METHOD_DUPLICATE_MASTER_PRODUCT,
    METHOD_SET_MASTER_REDLIKE_OPTION,
    METHOD_ADD_PACKAGE,
    METHOD_EDIT_PACKAGE,
    METHOD_ADD_PRODUCT_SHEET,
    METHOD_EDIT_PRODUCT_SHEET,
    METHOD_DUPLICATE_REDLIKE_USER_GROUP,
    METHOD_ADD_STATIC_SQL,
    METHOD_EDIT_STATIC_SQL,
    METHOD_ADD_SYSTEM,
    METHOD_DUPLICATE_SYSTEM,
    METHOD_EDIT_SYSTEM,
    METHOD_EDIT_SYSTEM_BASE,
    METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT,
    METHOD_EDIT_SYSTEM_BASE_IN_PRODUCT_PACKAGE,
    METHOD_EDIT_SYSTEM_COLORANT,
    METHOD_EDIT_SYSTEM_COLORANT_PACKAGE,
    METHOD_EDIT_SYSTEM_FANDECK,
    METHOD_EDIT_SYSTEM_FORMULA_NOTE,
    METHOD_ADD_SYSTEM_PRICE_MARGIN,
    METHOD_EDIT_SYSTEM_PRICE_MARGIN,
    METHOD_DUPLICATE_SYSTEM_PRODUCT,
    METHOD_EDIT_SYSTEM_PRODUCT,
    METHOD_EDIT_SYSTEM_PRODUCT_GROUP,
    METHOD_SET_SYSTEM_REDLIKE_OPTION,
    METHOD_ADD_UNIT,
    METHOD_EDIT_UNIT,
    METHOD_ADD_USER,
    METHOD_ADD_USER_WITH_USER_GROUPS,
    METHOD_EDIT_USER,
    METHOD_ADD_USER_GROUP,
    METHOD_EDIT_USER_GROUP,
    METHOD_ADD_ZONE,
    METHOD_DUPLICATE_ZONE,
    METHOD_EDIT_ZONE,
    METHOD_EDIT_ZONE_BASE,
    METHOD_EDIT_ZONE_BASE_IN_PRODUCT,
    METHOD_EDIT_ZONE_BASE_IN_PRODUCT_PACKAGE,
    METHOD_EDIT_ZONE_COLORANT,
    METHOD_EDIT_ZONE_COLORANT_PACKAGE,
    METHOD_EDIT_ZONE_FANDECK,
    METHOD_EDIT_ZONE_FORMULA_NOTE,
    METHOD_ADD_ZONE_PRICE_MARGIN,
    METHOD_EDIT_ZONE_PRICE_MARGIN,
    METHOD_DUPLICATE_ZONE_PRODUCT,
    METHOD_EDIT_ZONE_PRODUCT,
    METHOD_EDIT_ZONE_PRODUCT_GROUP,
    METHOD_SET_ZONE_REDLIKE_OPTION
];

export const LOADING_ADD_EDIT_PROPAGATE_MODAL = [
    METHOD_ADD_SYSTEM_BASE_WITH_PARAMS,
    METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS,
    METHOD_ADD_SYSTEM_BASE_IN_PRODUCT_WITH_PARAMS,
    METHOD_ADD_SYSTEM_COLORANT_PACKAGE_WITH_PARAMS,
    METHOD_ADD_SYSTEM_COLORANT_WITH_PARAMS,
    METHOD_ADD_SYSTEM_FANDECK_WITH_PARAMS,
    METHOD_ADD_SYSTEM_PRODUCT_GROUP_WITH_PARAMS,
    METHOD_ADD_SYSTEM_PRODUCT_WITH_PARAMS,
    METHOD_ADD_ZONE_BASE_WITH_PARAMS,
    METHOD_ADD_ZONE_BASE_IN_PRODUCT_PACKAGE_WITH_PARAMS,
    METHOD_ADD_ZONE_BASE_IN_PRODUCT_WITH_PARAMS,
    METHOD_ADD_ZONE_COLORANT_PACKAGE_WITH_PARAMS,
    METHOD_ADD_ZONE_COLORANT_WITH_PARAMS,
    METHOD_ADD_ZONE_FANDECK_WITH_PARAMS,
    METHOD_ADD_ZONE_PRODUCT_GROUP_WITH_PARAMS,
    METHOD_ADD_ZONE_PRODUCT_WITH_PARAMS,
    METHOD_EDIT_SYSTEM_PRODUCT_GROUP_WITH_PARAMS,
    METHOD_EDIT_SYSTEM_PRODUCT_WITH_PARAMS,
    METHOD_EDIT_ZONE_PRODUCT_GROUP_WITH_PARAMS,
    METHOD_EDIT_ZONE_PRODUCT_WITH_PARAMS
];
