import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as serverRestMethods from "../../../../constants/serverRestMethods";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";

import { connect } from "react-redux";
import { t as translate } from "react-i18nify";

type OwnProps = {
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
};

type Props = OwnProps & PropsType;

export class TableDownloadButton extends Component<Props> {
    isVisible = (): boolean => {
        const { objectType, activeList } = this.props;

        if (objectType === objectTypes.IMPORT_TASK) {
            return true;
        }

        if (
            (objectType === objectTypes.PRODUCT_SHEET_PREVIEW || objectType === objectTypes.STATIC_SQL_PREVIEW) &&
            activeList.length
        ) {
            return true;
        }

        return false;
    };

    isEnabled = (): boolean => {
        const { objectType, activeList } = this.props;

        if (activeList.length) {
            if (objectType === objectTypes.IMPORT_TASK && !activeList[0][propertyConstants.PROPERTY_CAN_DOWNLOAD]) {
                return false;
            }

            return true;
        }

        return false;
    };

    getButtonTitle = (): string => {
        if (this.props.objectType === objectTypes.IMPORT_TASK) {
            return translate("import.downloadSourceFilesForTask");
        }

        return translate("general.download");
    };

    getActiveButton = (): JSX.Element | null => {
        const { sessionUuid, activeList } = this.props;
        let buttonHref = "";

        switch (this.props.objectType) {
            case objectTypes.IMPORT_TASK: {
                buttonHref = `${this.props.urlRest}${
                    serverRestMethods.METHOD_DOWNLOAD_IMPORT_TASK_FILES
                }?import_task_id=${
                    activeList[0][propertyConstants.PROPERTY_ID]
                }&as_attachment=True&session_uuid=${sessionUuid}`;
                break;
            }
            default: {
                buttonHref = `${this.props.urlRest}${serverRestMethods.METHOD_DOWNLOAD_FILE}?file_id=${
                    activeList[0][propertyConstants.PROPERTY_ID]
                }&as_attachment=True&session_uuid=${sessionUuid}`;
                break;
            }
        }

        if (buttonHref) {
            return (
                <a download target={"_blank"} rel={"noreferrer"} href={buttonHref} className="link-button-container">
                    <button className="btn-info btn-md">{this.getButtonTitle()}</button>
                </a>
            );
        }

        return <button className="btn-long btn-info disabled">{this.getButtonTitle()}</button>;
    };

    render(): JSX.Element | null {
        const { sessionUuid, activeList } = this.props;

        if (this.isVisible() && this.isEnabled() && sessionUuid && activeList.length) {
            return this.getActiveButton();
        }

        if (this.isVisible()) {
            return <button className="btn-long btn-info disabled">{this.getButtonTitle()}</button>;
        }

        return null;
    }
}

export type PropsType = Readonly<{
    sessionUuid: string | null;
    urlRest: string;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

export const TableDownloadButtonContainer = connect(mapStateToProps)(TableDownloadButton);
