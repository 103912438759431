import * as bookmarkConstants from "../../../../constants/bookmarkConstants";
import * as dataTypeConstants from "../../../../constants/dataTypeConstants";
import * as generalHelper from "../../../../helpers/generalHelper";
import * as modalTypes from "../../../../constants/modalTypes";
import * as objectTypes from "../../../../constants/objectTypes";
import * as optionHelper from "../../../../helpers/optionHelper";
import * as priceHelper from "../../../../helpers/priceHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as translationHelper from "../../../../helpers/translationHelper";

import { Product, generateProductData, generateProductHeadings } from "../../../../types/product";
import React, { Component } from "react";

import { Bookmark } from "../../../../types/bookmark";
import { Currency } from "../../../../types/currency";
import { MenuItem } from "../../../../types/menu";
import { ModalParams } from "../../../../types/modalParams";
import { ModalProperties } from "../../../../types/modalProperties";
import { TableContainer } from "../../../Table";
import { TableHeading } from "../../../../types/tableHeading";

import { generateProductGroupHeadings } from "../../../../types/productGroup";
import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    priceDataPrivileges: MenuItem | null;
    loading: boolean;
    buttonLoading: boolean;
    allList: Array<Product>;
    activeList: Array<Product>;
    availableCount: number | null;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    rowCountCustom: boolean;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    showFilterRow: boolean;
    showGlobalSearch: boolean;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
    useTmcNames: boolean;
    currency: Currency | null;
    optionPriceDefinition: string | null;
    optionPriceEnableCompanyCost: boolean;
    optionPriceEnablePriceGroups: boolean;
};

export class SystemProductTable extends Component<Props> {
    generateProductGroupHeadings = (): Array<TableHeading> => {
        return generateProductGroupHeadings(
            objectTypes.SYSTEM_PRODUCT,
            [propertyConstants.PROPERTY_NAME],
            [propertyConstants.PROPERTY_NAME],
            [],
            [],
            [],
            {} as Record<propertyConstants.Property, number>
        );
    };

    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        objectType: objectTypes.ObjectType = this.props.objectType,
        isPropagating: boolean = false,
        isPriceBookmark: boolean = false,
        isDuplicateBookmark: boolean = false
    ): Array<TableHeading> => {
        const pricePropertyList = priceHelper.getPriceProperties(
            objectType,
            modalType,
            this.props.priceDataPrivileges,
            this.props.optionPriceEnableCompanyCost,
            this.props.optionPriceEnablePriceGroups,
            this.props.optionPriceDefinition
        );

        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_SEARCH_NAME,
            propertyConstants.PROPERTY_PRINT_NAME,
            propertyConstants.PROPERTY_CODE,
            propertyConstants.PROPERTY_SEARCH_CODE,
            propertyConstants.PROPERTY_MORE_INFO,
            propertyConstants.PROPERTY_PRIORITY
        ];
        const requiredList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        const alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_NAME];
        let editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        visibleList.splice(6, 0, propertyConstants.PROPERTY_IMAGE_ID);

        if (modalType === null) {
            visibleList.splice(7, 0, propertyConstants.PROPERTY_FILES);
            visibleList.splice(5, 0, propertyConstants.PROPERTY_PRODUCT_GROUP_LIST);
            visibleList.splice(7, 0, propertyConstants.PROPERTY_UUID);
            editableList.push(propertyConstants.PROPERTY_UUID);

            visibleList = visibleList.concat(pricePropertyList);
            editableList = editableList.concat(pricePropertyList);
        }

        if (modalType !== null) {
            if (modalType !== modalTypes.MODAL_PRODUCTS_DUPLICATE) {
                visibleList.splice(7, 0, propertyConstants.PROPERTY_UUID);
                editableList.push(propertyConstants.PROPERTY_UUID);
            }

            editableList.push(propertyConstants.PROPERTY_IMAGE_ID);
        }

        if (this.props.useTmcNames) {
            visibleList.splice(1, 0, propertyConstants.PROPERTY_TMC_NAME);
            editableList.push(propertyConstants.PROPERTY_TMC_NAME);
        }

        if (modalType === null) {
            visibleList.splice(0, 0, propertyConstants.PROPERTY_NAME_MASTER);
        }

        if (isPriceBookmark) {
            visibleList = pricePropertyList;
            editableList = pricePropertyList;

            if (
                this.props.activeList.length &&
                this.props.activeList[0][propertyConstants.PROPERTY_PRICE_GROUP_LIST]?.length
            ) {
                visibleList.splice(pricePropertyList.length, 0, propertyConstants.PROPERTY_DIVIDING_LINE);
                visibleList.splice(pricePropertyList.length, 0, propertyConstants.PROPERTY_PRICE_GROUP_LIST);
                editableList.splice(pricePropertyList.length, 0, propertyConstants.PROPERTY_PRICE_GROUP_LIST);
            }
        }

        if (modalType === modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE) {
            visibleList = [propertyConstants.PROPERTY_NAME_MASTER, propertyConstants.PROPERTY_NAME];
            editableList = [propertyConstants.PROPERTY_NAME];
        }

        if (isPropagating) {
            visibleList = [propertyConstants.PROPERTY_NAME_MASTER];
            editableList = [];
        }

        if (isDuplicateBookmark) {
            visibleList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
            editableList = [propertyConstants.PROPERTY_DATA_TYPE_LIST];
        }

        let orderList = [...visibleList];

        if (modalType === null) {
            orderList = optionHelper.handleTableHeadingOrder(visibleList, this.props.columnOrder);
            visibleList = optionHelper.handleTableHeadingVisibility(visibleList, this.props.columnVisibility);
        }

        return generateProductHeadings(
            this.props.objectType,
            generalHelper.generateDataTypes(objectType, dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST),
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.columnWidth
        );
    };

    generateBookmarks = (modalType: modalTypes.ModalType, isDuplicatedModal: boolean = false): Array<Bookmark> => {
        const bookmarks = [
            new Bookmark(
                bookmarkConstants.BOOKMARK_GENERAL,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("general.general"),
                this.generateTableHeadings(modalType),
                this.props.dataPrivileges, // TODO own privilege
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_PRICES,
                bookmarkConstants.BOOKMARK_TYPE_FORM,
                translate("prices.prices"),
                this.generateTableHeadings(modalType, this.props.objectType, false, true),
                this.props.dataPrivileges, // TODO own privilege
                false
            ),
            new Bookmark(
                bookmarkConstants.BOOKMARK_FILES,
                bookmarkConstants.BOOKMARK_TYPE_TABLE,
                translate("product.productFiles"),
                [],
                this.props.dataPrivileges, // TODO own privilege
                false
            )
        ];

        if (isDuplicatedModal) {
            bookmarks.push(
                new Bookmark(
                    bookmarkConstants.BOOKMARK_DUPLICATE,
                    bookmarkConstants.BOOKMARK_TYPE_FORM_TABLE,
                    translate("general.duplicate"),
                    this.generateTableHeadings(modalType, objectTypes.SYSTEM_PRODUCT_DUPLICATE, false, false, true),
                    this.props.dataPrivileges, // TODO own privilege
                    false
                )
            );
        }

        return bookmarks;
    };

    generateAddModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.PROPAGATE_MODAL,
            objectTypes.MASTER_SYSTEM_PRODUCT,
            "",
            [],
            this.generateTableHeadings(modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE, objectTypes.MASTER_SYSTEM_PRODUCT),
            undefined,
            this.generateTableHeadings(modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE, objectTypes.MASTER_PRODUCT, true),
            undefined,
            undefined,
            this.props.dataPrivileges,
            undefined,
            "simple-table",
            undefined
        );
    };

    generateEditModalParams = (): ModalParams | null => {
        const activeData = generateProductData(this.props.objectType, this.props.activeList);

        if (activeData.length) {
            return new ModalParams(
                modalTypes.EDIT_MODAL,
                this.props.objectType,
                translate("product.editProduct"),
                activeData[0],
                undefined,
                this.generateBookmarks(modalTypes.MODAL_PRODUCTS_EDIT),
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges,
                undefined,
                undefined,
                this.props.currency
            );
        }

        return null;
    };

    generateDuplicateModalParams = (): ModalParams | null => {
        const activeData = generateProductData(
            objectTypes.SYSTEM_PRODUCT_DUPLICATE,
            this.props.activeList,
            dataTypeConstants.SYSTEM_ZONE_PRODUCT_DATA_TYPE_LIST
        );

        if (activeData.length) {
            return new ModalParams(
                modalTypes.DUPLICATE_MODAL,
                objectTypes.SYSTEM_PRODUCT_DUPLICATE,
                translate("product.duplicateProduct"),
                activeData[0],
                undefined,
                this.generateBookmarks(modalTypes.MODAL_PRODUCTS_DUPLICATE, true),
                undefined,
                undefined,
                undefined,
                this.props.dataPrivileges,
                undefined,
                undefined,
                this.props.currency
            );
        }

        return null;
    };

    generateProductGroupModalParams = (): ModalParams => {
        return new ModalParams(
            modalTypes.PROPAGATE_MODAL,
            objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS,
            "",
            [],
            this.generateProductGroupHeadings(),
            undefined,
            this.generateProductGroupHeadings(),
            undefined,
            undefined,
            this.props.dataPrivileges,
            undefined,
            "simple-table"
        );
    };

    render(): JSX.Element {
        const modalProperties: ModalProperties = {
            addModalType: modalTypes.MODAL_SYSTEM_PRODUCTS_PROPAGATE,
            addModalParams: this.generateAddModalParams(),
            duplicateModalType: modalTypes.MODAL_PRODUCTS_DUPLICATE,
            duplicateModalParams: this.generateDuplicateModalParams(),
            editModalType: modalTypes.MODAL_PRODUCTS_EDIT,
            editModalParams: this.generateEditModalParams(),
            productGroupsModalParams: this.generateProductGroupModalParams(),
            productGroupsModalType: modalTypes.MODAL_SYSTEM_PRODUCTS_WITH_PRODUCT_GROUPS_PROPAGATE
        };

        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    buttonLoading={this.props.buttonLoading}
                    title={translationHelper.getTitleSectionTranslation(this.props.objectType)}
                    titleClassName={"main-title"}
                    headings={this.generateTableHeadings()}
                    data={generateProductData(this.props.objectType, this.props.allList)}
                    activeData={this.props.activeList}
                    availableCount={this.props.availableCount}
                    modalProperties={modalProperties}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    rowCountCustom={this.props.rowCountCustom}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={this.props.showFilterRow}
                    showGlobalSearch={this.props.showGlobalSearch}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                    currency={this.props.currency}
                />
            </div>
        );
    }
}
