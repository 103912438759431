import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as serverMethods from "../constants/serverMethods";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";

import { Option } from "../types/option";
import { saveOptions } from "../actions/serverEventsActions";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

// TODO may be removed?
export const dataSave: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.SET_DICTIONARY_OPTION),
        switchMap(({ payload }) => {
            const option = generalHelper.getObjectByKey(state$.value.login.options, payload.key);
            const requests = [];

            if (option) {
                const newValue = {
                    ...option.value,
                    [payload.object]: payload.value
                };

                const newOption = option
                    ? new Option(option.key, generalHelper.sortObjectKeys(newValue), option.userId, option.locked)
                    : null;
                const optionIndex = state$.value.login.options.indexOf(option);
                const updatedList = [...state$.value.login.options];

                if (option && optionIndex >= 0) {
                    updatedList[optionIndex] = newOption;
                }

                requests.push(saveOptions(updatedList));

                // Set user options, except of those which are saved after table menu is closed
                if (
                    payload.key !== optionsConstants.OPTION_TABLE_COLUMNS_ORDER &&
                    payload.key !== optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY &&
                    payload.key !== optionsConstants.OPTION_SHOW_FILTER_ROW &&
                    payload.key !== optionsConstants.OPTION_SHOW_GLOBAL_SEARCH &&
                    payload.key !== optionsConstants.OPTION_COMPANIES_FOR_USERS
                ) {
                    requests.push(
                        serverRequest(serverMethods.METHOD_SET_USER_OPTION, serverMethods.METHOD_SET_USER_OPTION, {
                            key: option.key,
                            value: newValue
                        })
                    );
                }

                // Set global options
                if (payload.key === optionsConstants.OPTION_COMPANIES_FOR_USERS) {
                    requests.push(
                        serverRequest(serverMethods.METHOD_SET_GLOBAL_OPTION, serverMethods.METHOD_SET_GLOBAL_OPTION, {
                            key: option.key,
                            value: newValue,
                            locked: true
                        })
                    );
                }
            }

            return requests;
        })
    );

export const optionsSave: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.SET_DICTIONARY_OPTIONS),
        switchMap(({ payload }) => {
            const requests = [];
            let newOptions: Record<string, any> = {};

            switch (payload.objectType) {
                case objectTypes.GLOBAL_OPTION: {
                    const updatedList = [...state$.value.options.globalOptionList];

                    for (const item of payload.options) {
                        const option = generalHelper.getObjectByKey(state$.value.options.globalOptionList, item.key);

                        if (option) {
                            const newValue = item.value !== undefined ? item.value : option.value;

                            const newOption = option
                                ? new Option(option.key, newValue, option.userId, item.locked)
                                : null;
                            const optionIndex = state$.value.options.globalOptionList.indexOf(option);

                            if (option && optionIndex >= 0) {
                                updatedList[optionIndex] = newOption;
                            }
                        }
                    }
                    requests.push(universalObjectActions.saveItems(payload.objectType, updatedList));

                    if (payload.save && updatedList.length) {
                        requests.push(
                            serverRequest(
                                serverMethods.METHOD_SET_GLOBAL_OPTIONS,
                                serverMethods.METHOD_SET_GLOBAL_OPTIONS,
                                {
                                    options: updatedList
                                }
                            )
                        );
                    }

                    return requests;
                }
                case objectTypes.USER_OPTION: {
                    const updatedList = [...state$.value.options.userOptionList];
                    for (const item of payload.options) {
                        const option = generalHelper.getObjectByKey(state$.value.options.userOptionList, item.key);

                        if (option) {
                            const newValue = item.value !== undefined ? item.value : option.value;

                            // Saving all options to store immediately
                            const newOption = option
                                ? new Option(option.key, newValue, option.userId, option.locked)
                                : null;
                            const optionIndex = state$.value.options.userOptionList.indexOf(option);

                            if (option && optionIndex >= 0) {
                                updatedList[optionIndex] = newOption;
                            }

                            // Setting changed user options (if item.value is undefined, settings item.key option is forced)
                            if (
                                item.value === undefined ||
                                (typeof newValue !== "object" && newValue !== undefined) ||
                                (typeof newValue === "object" &&
                                    Object.keys(newValue).length &&
                                    JSON.stringify(newValue) !==
                                        JSON.stringify(generalHelper.sortObjectKeys(option.value)))
                            ) {
                                newOptions = { ...newOptions, [option.key]: newValue };
                            }
                        }
                    }
                    requests.push(universalObjectActions.saveItems(payload.objectType, updatedList));

                    if (Object.keys(newOptions).length && payload.save) {
                        requests.push(
                            serverRequest(
                                serverMethods.METHOD_SET_USER_OPTIONS,
                                serverMethods.METHOD_SET_USER_OPTIONS,
                                {
                                    options: newOptions
                                }
                            )
                        );
                    }

                    return requests;
                }
                default: {
                    const updatedList = [...state$.value.login.options];
                    for (const item of payload.options) {
                        const option = generalHelper.getObjectByKey(state$.value.login.options, item.key);
                        if (option) {
                            let newValue = item.value !== undefined ? item.value : option.value;

                            if (typeof option.value === "object") {
                                newValue = item.object
                                    ? {
                                          ...option.value,
                                          [item.object]: item.value
                                      }
                                    : option.value;
                                newValue = generalHelper.sortObjectKeys(newValue);
                            }

                            // Saving all options to store immediately
                            const newOption = option
                                ? new Option(option.key, newValue, option.userId, option.locked)
                                : null;
                            const optionIndex = state$.value.login.options.indexOf(option);

                            if (option && optionIndex >= 0) {
                                updatedList[optionIndex] = newOption;
                            }

                            // Setting changed user options (if item.value is undefined, settings item.key option is forced)
                            if (
                                item.value === undefined ||
                                (Object.keys(newValue).length &&
                                    JSON.stringify(newValue) !==
                                        JSON.stringify(generalHelper.sortObjectKeys(option.value)))
                            ) {
                                newOptions = { ...newOptions, [option.key]: newValue };
                            }
                        }
                    }

                    requests.push(saveOptions(updatedList));

                    if (Object.keys(newOptions).length && payload.save) {
                        if (payload.objectType) {
                            const tableConstant = objectTypeHelper.getTableConstantFromObjectType(payload.objectType);

                            const reloadData = generalHelper.updateDataBasedOnColumnVisibility(
                                payload.objectType,
                                state$.value,
                                newOptions[optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY][tableConstant]
                            );

                            if (reloadData) {
                                requests.push(universalObjectActions.reloadData(payload.objectType));
                            }
                        }

                        requests.push(
                            serverRequest(
                                serverMethods.METHOD_SET_USER_OPTIONS,
                                serverMethods.METHOD_SET_USER_OPTIONS,
                                {
                                    options: newOptions
                                }
                            )
                        );
                    }

                    return requests;
                }
            }
        })
    );

export const updateData: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.EVENT_SAVE_OPTIONS),
        switchMap(({ payload }) => {
            const requests = [];

            if (payload.reloadData && payload.objectType) {
                requests.push(universalObjectActions.reloadData(payload.objectType));
            }

            return requests;
        })
    );
