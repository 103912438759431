import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { TableHeading } from "./tableHeading";

export class FormulaNote {
    id: number;
    code: string;
    value: string;
    valueMaster: string | null;
    valueSystem: string | null;
    valueSource: number;
    imageId: number | null;

    constructor(
        id: number,
        code: string,
        value: string,
        valueMaster: string | null,
        valueSystem: string | null,
        valueSource: number,
        imageId: number | null
    ) {
        this.id = id;
        this.code = code;
        this.value = value;
        this.valueMaster = valueMaster;
        this.valueSystem = valueSystem;
        this.valueSource = valueSource;
        this.imageId = imageId;
    }
}

export function mapFormulaNote(data: Record<string, any>): FormulaNote | null {
    try {
        return new FormulaNote(
            data.id,
            data.code,
            data.value,
            data.valueMaster,
            data.valueSystem,
            data.valueSource,
            data.imageId
        );
    } catch (e) {
        return null;
    }
}

export function updateFormulaNote(oldData: Record<string, any>, newData: Record<string, any>): FormulaNote | null {
    try {
        return new FormulaNote(
            newData?.id ? newData.id : oldData.id,
            newData?.code ? newData.code : oldData.code,
            newData?.value ? newData.value : oldData.value,
            newData?.valueMaster ? newData.valueMaster : oldData.valueMaster,
            newData?.valueSystem ? newData.valueSystem : oldData.valueSystem,
            newData?.valueSource ? newData.valueSource : oldData.valueSource,
            newData?.imageId ? newData.imageId : oldData.imageId
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyFormulaNote(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_VALUE]: "",
        [propertyConstants.PROPERTY_IMAGE_ID]: null
    };
}

export function generateFormulaNoteData(formulaNotesList: Array<FormulaNote>): Array<any> {
    const data: Array<any> = [];
    let formulaNote: FormulaNote;

    for (formulaNote of formulaNotesList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: formulaNote.id,
            [propertyConstants.PROPERTY_CODE]: formulaNote.code,
            [propertyConstants.PROPERTY_VALUE]: formulaNote.value,
            [propertyConstants.PROPERTY_VALUE_MASTER]: formulaNote.valueMaster,
            [propertyConstants.PROPERTY_VALUE_SYSTEM]: formulaNote.valueSystem,
            [propertyConstants.PROPERTY_VALUE_SOURCE]: formulaNote.valueSource,
            [propertyConstants.PROPERTY_IMAGE_ID]: formulaNote.imageId
        });
    }

    return data;
}

export function generateFormulaNoteHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_VALUE),
            editableList.includes(propertyConstants.PROPERTY_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_VALUE),
            [],
            widthOption?.[propertyConstants.PROPERTY_VALUE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE_MASTER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE_MASTER),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_VALUE_MASTER),
            editableList.includes(propertyConstants.PROPERTY_VALUE_MASTER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE_MASTER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE_MASTER),
            requiredList.includes(propertyConstants.PROPERTY_VALUE_MASTER),
            [],
            widthOption?.[propertyConstants.PROPERTY_VALUE_MASTER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE_SYSTEM),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_VALUE_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_VALUE_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_VALUE_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_VALUE_SYSTEM] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
