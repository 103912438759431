import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as privilegesHelper from "../../../helpers/privilegesHelper";

import React, { FunctionComponent, useMemo } from "react";

import { DataItem } from "../../../types/dataItem";
import { PropsType } from "../../../containers/scenes/header/UserSceneContainer";
import { SceneLayoutButtonsContainer } from "../../general/scene/buttons/SceneLayoutButtons";
import { SceneSaveButtonContainer } from "../../general/scene/buttons/SceneSaveButton";
import { UserGroupPrivilegeTable } from "./tables/UserGroupPrivilegeTable";
import { UserGroupTable } from "./tables/UserGroupTable";
import { UserTable } from "./tables/UserTable";

type Props = PropsType;

export const UserScene: FunctionComponent<Props> = ({ ...props }) => {
    const privilegesJson = JSON.stringify(props.dataPrivileges);

    const tableUserGroupPrivileges = useMemo(
        () => privilegesHelper.getTableDataPrivileges(props.dataPrivileges, props.userTableConstant),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [privilegesJson, props.userTableConstant]
    );

    const tableUserGroups = useMemo(
        () => privilegesHelper.getTableDataPrivileges(props.dataPrivileges, props.userGroupTableConstant),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [privilegesJson, props.userGroupTableConstant]
    );

    const tableUsers = useMemo(
        () => privilegesHelper.getTableDataPrivileges(props.dataPrivileges, props.userTableConstant),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [privilegesJson, props.userTableConstant]
    );

    const tablesCount = generalHelper.getTablesCount(tableUsers, tableUserGroups, tableUserGroupPrivileges);

    const getUsersTable = (): JSX.Element | null => {
        if (tableUsers !== null) {
            return (
                <div className="layout-item" key={props.userTableConstant}>
                    <UserTable
                        tableConstant={props.userTableConstant}
                        objectType={objectTypes.USER}
                        dataPrivileges={tableUsers}
                        loading={props.userLoading}
                        buttonLoading={props.userDetailLoading}
                        companyList={props.companyList}
                        loggedUser={props.loggedUser}
                        userGroupList={props.userGroupAllList}
                        allList={props.userList}
                        activeList={props.userActiveList}
                        columnOrder={props.userColumnOrder}
                        columnVisibility={props.userColumnVisibility}
                        columnWidth={props.userColumnWidth}
                        offset={props.userOffset}
                        page={props.userPage}
                        rowCount={props.userRowCount}
                        search={props.userSearch}
                        searchParams={props.userSearchParams}
                        showFilterRow={props.userShowFilterRow}
                        showGlobalSearch={props.userShowGlobalSearch}
                        sortingAsc={props.userSortingAsc}
                        sortingCriterion={props.userSortingCriterion}
                        totalCount={props.userTotalCount}
                    />
                </div>
            );
        }

        return null;
    };

    const getUserGroupsTable = (): JSX.Element | null => {
        if (tableUserGroups !== null) {
            return (
                <div className="layout-item" key={props.userGroupTableConstant}>
                    <UserGroupTable
                        tableConstant={props.userGroupTableConstant}
                        objectType={objectTypes.USER_GROUP}
                        dataPrivileges={tableUserGroups}
                        allList={props.userGroupList}
                        activeList={props.userGroupActiveList}
                        loading={props.userGroupLoading}
                        columnOrder={props.userGroupColumnOrder}
                        columnVisibility={props.userGroupColumnVisibility}
                        columnWidth={props.userGroupColumnWidth}
                        offset={props.userGroupOffset}
                        page={props.userGroupPage}
                        rowCount={props.userGroupRowCount}
                        search={props.userGroupSearch}
                        searchParams={props.userGroupSearchParams}
                        showFilterRow={props.userGroupShowFilterRow}
                        showGlobalSearch={props.userGroupShowGlobalSearch}
                        sortingAsc={props.userGroupSortingAsc}
                        sortingCriterion={props.userGroupSortingCriterion}
                        totalCount={props.userGroupTotalCount}
                    />
                </div>
            );
        }

        return null;
    };

    const getUserGroupPrivilegesTable = (): JSX.Element | null => {
        if (tableUserGroupPrivileges !== null) {
            const dataPrivilege = new DataItem(
                props.userGroupPrivilegeTableConstant,
                "",
                true,
                false,
                false,
                false,
                true,
                false
            );

            return (
                <div className="layout-item" key={props.userGroupPrivilegeTableConstant}>
                    <UserGroupPrivilegeTable
                        tableConstant={props.userGroupPrivilegeTableConstant}
                        objectType={objectTypes.USER_GROUP_PRIVILEGE}
                        dataPrivileges={dataPrivilege}
                        loggedUser={props.loggedUser}
                    />
                </div>
            );
        }

        return null;
    };

    if (tableUsers !== null && tableUserGroups !== null && tableUserGroupPrivileges !== null) {
        return (
            <div className="height-100">
                <SceneSaveButtonContainer />
                <SceneLayoutButtonsContainer
                    sceneConstant={menuConstants.PAGE_USERS}
                    dataPrivileges={tableUserGroupPrivileges}
                    tablesCount={tablesCount}
                />
                <div
                    className={optionHelper.getLayoutClassName(props.sceneLayout, tableUserGroups) + " subtitle-layout"}
                >
                    {getUserGroupsTable()}
                    {getUsersTable()}
                    {getUserGroupPrivilegesTable()}
                </div>
            </div>
        );
    }

    if (tableUsers !== null && tableUserGroupPrivileges !== null) {
        return (
            <div className="height-100">
                <SceneSaveButtonContainer />
                <SceneLayoutButtonsContainer
                    sceneConstant={menuConstants.PAGE_USERS}
                    dataPrivileges={tableUserGroupPrivileges}
                    tablesCount={tablesCount}
                />
                <div className={optionHelper.getLayoutClassName(props.sceneLayout, tableUsers) + " subtitle-layout"}>
                    {getUsersTable()}
                    {getUserGroupPrivilegesTable()}
                </div>
            </div>
        );
    }

    if (tableUserGroups !== null) {
        return (
            <div className="height-100">
                <SceneSaveButtonContainer />
                <div className="content-layout">{getUserGroupsTable()}</div>
            </div>
        );
    }

    if (tableUsers !== null) {
        return (
            <div className="height-100">
                <SceneSaveButtonContainer />
                <div className="content-layout">{getUsersTable()}</div>
            </div>
        );
    }

    return null;
};
