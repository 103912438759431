import * as modalTypes from "../constants/modalTypes";
import * as objectTypeHelper from "../helpers/objectTypeHelper";
import * as objectTypes from "../constants/objectTypes";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";

import { MarginType, mapMarginType } from "../types/marginType";

import { Margin } from "../types/margin";
import { MenuItem } from "../types/menu";
import { PriceOption } from "../types/priceOption";

import { translate } from "react-i18nify";

// TODO check while file

// TODO check this method
export const isPricePropertyValidForObjectType = (
    property: propertyConstants.Property,
    objectType: objectTypes.ObjectType,
    systemOptionPriceDefinition: string | null,
    systemOptionPriceEnableCompanyCost: boolean,
    systemOptionPriceEnablePriceGroups: boolean,
    zoneOptionPriceDefinition: string | null,
    zoneOptionPriceEnableCompanyCost: boolean,
    zoneOptionPriceEnablePriceGroups: boolean
): boolean => {
    const isMasterObjectType = objectTypes.MASTER_OBJECT_TYPES.includes(objectType);
    const isSystemObjectType = objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType);
    const isZoneObjectType = objectTypes.ZONE_OBJECT_TYPES.includes(objectType);

    switch (property) {
        case propertyConstants.PROPERTY_PRICE_COST:
        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER: {
            if (isSystemObjectType && systemOptionPriceEnableCompanyCost) {
                return true;
            }

            if (isZoneObjectType && zoneOptionPriceEnableCompanyCost) {
                return true;
            }

            return false;
        }
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER: {
            if (isSystemObjectType && systemOptionPriceEnablePriceGroups) {
                return true;
            }

            if (isZoneObjectType && zoneOptionPriceEnablePriceGroups) {
                return true;
            }

            return false;
        }
        case propertyConstants.PROPERTY_PRICE_PURCHASE:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER: {
            if (isSystemObjectType && systemOptionPriceDefinition !== priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
                return true;
            }

            if (isZoneObjectType && zoneOptionPriceDefinition !== priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
                return true;
            }

            return false;
        }
        case propertyConstants.PROPERTY_PRICE_SELL:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER: {
            if (
                isSystemObjectType &&
                systemOptionPriceDefinition !== priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY
            ) {
                return true;
            }

            if (isZoneObjectType && zoneOptionPriceDefinition !== priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
                return true;
            }

            return false;
        }
        default: {
            if (isMasterObjectType) {
                return false;
            }

            return true;
        }
    }
};

// TODO check this method
export const getPriceProperties = (
    objectType: objectTypes.ObjectType,
    modalType: modalTypes.ModalType | null,
    dataPrivileges: MenuItem | null,
    optionEnableCompanyCost: boolean,
    optionPriceEnablePriceGroups: boolean,
    optionPriceDefinition: string | null
): Array<propertyConstants.Property> => {
    const properties: Array<propertyConstants.Property> = [];

    if (dataPrivileges) {
        if (optionEnableCompanyCost) {
            if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
                properties.push(propertyConstants.PROPERTY_PRICE_COST);

                if (modalType) {
                    properties.push(propertyConstants.PROPERTY_PRICE_COST_PER_UNIT);
                }
            } else {
                properties.push(propertyConstants.PROPERTY_PRICE_COST_PER_LITER);
            }
        }

        if (optionPriceEnablePriceGroups) {
            if (objectTypeHelper.isObjectTypeColorant(objectType)) {
                properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER);
            }
        }

        if (
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
        ) {
            if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
                properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE);
                properties.push(propertyConstants.PROPERTY_PRICE_SELL);

                if (modalType) {
                    properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT);
                    properties.push(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT);
                }
            } else {
                properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER);
                properties.push(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER);
            }
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
            if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
                properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE);

                if (modalType) {
                    properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_UNIT);
                }
            } else {
                properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER);
            }
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
            if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
                properties.push(propertyConstants.PROPERTY_PRICE_SELL);

                if (modalType) {
                    properties.push(propertyConstants.PROPERTY_PRICE_SELL_PER_UNIT);
                }
            } else {
                properties.push(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER);
            }
        }

        if (objectTypeHelper.isObjectTypeBaseInProductPackage(objectType)) {
            properties.push(propertyConstants.PROPERTY_PRICE_SURCHARGE);
        }

        properties.push(propertyConstants.PROPERTY_PRICE_VAT);
    }

    return properties;
};

// TODO check this method
// TODO explain difference getPriceProperties vs getPriceHeadingProperties
/* export const getPriceHeadingProperties = (
    objectType: objectTypes.ObjectType,
    dataPrivileges: MenuItem | null,
    optionEnableCompanyCost: boolean,
    optionPriceEnablePriceGroups: boolean,
    optionPriceDefinition: string | null
): Array<propertyConstants.Property> => {
    const properties: Array<propertyConstants.Property> = [];

    if (dataPrivileges) {
        properties.push(propertyConstants.PROPERTY_PRICE_VAT);

        if (optionEnableCompanyCost) {
            properties.push(propertyConstants.PROPERTY_PRICE_COST);
        }

        if (optionPriceEnablePriceGroups) {
            properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER);
        }
        if (
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
        ) {
            properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE);
            properties.push(propertyConstants.PROPERTY_PRICE_SELL);
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
            properties.push(propertyConstants.PROPERTY_PRICE_PURCHASE);
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
            properties.push(propertyConstants.PROPERTY_PRICE_SELL);
        }
    }
    return properties;
};*/

// TODO check this method
export const getPriceSettingsKeys = (
    dataPrivileges: MenuItem | null,
    optionEnableCompanyCost: boolean,
    optionPriceDefinition: string | null,
    type?: string
): Array<string> => {
    const keys: Array<string> = [];

    if (dataPrivileges) {
        keys.push(
            priceConstants.PRICE_GENERIC_PRODUCT_VAT,
            priceConstants.PRICE_GENERIC_COLORANT_VAT,
            priceConstants.PRICE_GENERIC_PACKAGE_SURCHARGE
        );

        if (optionEnableCompanyCost) {
            keys.push(
                priceConstants.PRICE_GENERIC_PRODUCT_COST_PER_LITER,
                priceConstants.PRICE_GENERIC_COLORANT_COST_PER_LITER
            );
        }

        if (
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
            optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
        ) {
            keys.push(
                priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER,
                priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER,
                priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER,
                priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER
            );
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
            keys.push(
                priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER,
                priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER
            );
        }

        if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
            keys.push(
                priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER,
                priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER
            );
        }
    }
    if (!dataPrivileges && type) {
        if (type === priceConstants.COLORANT) {
            if (
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
            ) {
                keys.push(
                    priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER,
                    priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER
                );
            }

            if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
                keys.push(priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER);
            }

            if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
                keys.push(priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER);
            }

            if (optionEnableCompanyCost) {
                keys.push(priceConstants.PRICE_GENERIC_COLORANT_COST_PER_LITER);
            }
        }

        if (type === priceConstants.PRODUCT) {
            if (
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_PURCHASE_PRIMARY ||
                optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_BOTH_SELL_PRIMARY
            ) {
                keys.push(
                    priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER,
                    priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER
                );
            }

            if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_PURCHASE_ONLY) {
                keys.push(priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER);
            }

            if (optionPriceDefinition === priceConstants.PRICE_DEFINITION_TYPE_SELL_ONLY) {
                keys.push(priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER);
            }

            if (optionEnableCompanyCost) {
                keys.push(priceConstants.PRICE_GENERIC_PRODUCT_COST_PER_LITER);
            }
        }
    }

    return keys;
};

// TODO key: Property | string? is string necessary?
export const getPriceUnit = (key: string, currencySymbol = ""): string => {
    if (key.startsWith("priceGroup")) {
        return ` ${currencySymbol}`;
    }

    switch (key) {
        case priceConstants.PRICE_GENERIC_PACKAGE_SURCHARGE:
        case propertyConstants.PROPERTY_PRICE_COST:
        case propertyConstants.PROPERTY_PRICE_PURCHASE:
        case propertyConstants.PROPERTY_PRICE_SELL:
        case propertyConstants.PROPERTY_PRICE_SURCHARGE:
            return ` ${currencySymbol}`;
        case propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM:
            return ` ${currencySymbol} / 1 Kg`;
        case priceConstants.PRICE_GENERIC_COLORANT_COST_PER_LITER:
        case priceConstants.PRICE_GENERIC_COLORANT_PURCHASE_PER_LITER:
        case priceConstants.PRICE_GENERIC_COLORANT_SELL_PER_LITER:
        case priceConstants.PRICE_GENERIC_PRODUCT_COST_PER_LITER:
        case priceConstants.PRICE_GENERIC_PRODUCT_PURCHASE_PER_LITER:
        case priceConstants.PRICE_GENERIC_PRODUCT_SELL_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_COST_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_PURCHASE_PRICE_GROUP_PER_LITER:
        case propertyConstants.PROPERTY_PRICE_SELL_PER_LITER:
            return ` ${currencySymbol} / 1 L`;
        case priceConstants.PRICE_GENERIC_COLORANT_VAT:
        case priceConstants.PRICE_GENERIC_PRODUCT_VAT:
        case propertyConstants.PROPERTY_PRICE_VAT:
            return " %";
        default:
            return "";
    }
};

export const getMarginTypes = (option: PriceOption | null): MarginType | null => {
    if (option) {
        if (
            option[propertyConstants.PROPERTY_KEY] === priceConstants.PRICE_OPTION_USE_DISCOUNT &&
            option[propertyConstants.PROPERTY_VALUE]
        ) {
            return mapMarginType(priceConstants.PRICE_TYPE_DISCOUNT, translate("prices.discount"));
        }

        if (
            option[propertyConstants.PROPERTY_KEY] === priceConstants.PRICE_OPTION_USE_MARGIN &&
            option[propertyConstants.PROPERTY_VALUE]
        ) {
            return mapMarginType(priceConstants.PRICE_TYPE_MARGIN, translate("prices.margin"));
        }

        if (
            option[propertyConstants.PROPERTY_KEY] === priceConstants.PRICE_OPTION_USE_MARKUP &&
            option[propertyConstants.PROPERTY_VALUE]
        ) {
            return mapMarginType(priceConstants.PRICE_TYPE_MARKUP, translate("prices.markup"));
        }
    }

    return null;
};

export const getMarginTitle = (visibleMargin: boolean, visibleMarkup: boolean, visibleDiscount: boolean): string => {
    const marginTitle: Array<string> = [];

    if (visibleMargin) {
        marginTitle.push(translate("prices.margin"));
    }

    if (visibleMarkup) {
        marginTitle.push(translate("prices.markup"));
    }

    if (visibleDiscount) {
        marginTitle.push(translate("prices.discount"));
    }

    return marginTitle.join(", ");
};

export const getFilteredMarginList = (
    marginList: Array<Margin>,
    visibleMargin: boolean,
    visibleMarkup: boolean,
    visibleDiscount: boolean
): Array<Margin> => {
    const updatedMarginList: Array<Margin> = [];

    for (const item of marginList) {
        if (visibleMargin && item[propertyConstants.PROPERTY_MARGIN_TYPE] === priceConstants.PRICE_TYPE_MARGIN) {
            updatedMarginList.push(item);
        }

        if (visibleMarkup && item[propertyConstants.PROPERTY_MARGIN_TYPE] === priceConstants.PRICE_TYPE_MARKUP) {
            updatedMarginList.push(item);
        }

        if (visibleDiscount && item[propertyConstants.PROPERTY_MARGIN_TYPE] === priceConstants.PRICE_TYPE_DISCOUNT) {
            updatedMarginList.push(item);
        }
    }

    return updatedMarginList;
};

export const calculateTotalPrice = (pricePerUnit: number, fillVolumeMl: number | null, density: number): string => {
    if (!fillVolumeMl) {
        return (pricePerUnit * density).toString();
    }

    return (pricePerUnit * ((fillVolumeMl / 1000) * density)).toString();
};

export const calculateUnitPrice = (totalPrice: number, fillVolumeMl: number | null, density: number): string => {
    if (!fillVolumeMl) {
        return (totalPrice / density).toString();
    }

    return (totalPrice / ((fillVolumeMl / 1000) * density)).toString();
};

export const hasCustomDensity = (property: propertyConstants.Property): boolean => {
    if (
        property === propertyConstants.PROPERTY_PRICE_SELL_PER_KILOGRAM ||
        property === propertyConstants.PROPERTY_PRICE_PURCHASE_PER_KILOGRAM ||
        property === propertyConstants.PROPERTY_PRICE_COST_PER_KILOGRAM
    ) {
        return true;
    }

    return false;
};
