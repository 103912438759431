import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { ImageTable } from "./tables/ImageTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { Preview } from "../../general/scene/Preview";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/generic/GenericImageSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

type State = {
    tableImages: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class GenericImageScene extends Component<Props, State> {
    state: State = {
        tableImages:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.imageTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableImages:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.imageTableConstant)
                        : null) || null
            });
        }
    }

    getImagesTable = (): JSX.Element | null => {
        const { imageTableConstant } = this.props;
        const { tableImages } = this.state;

        if (tableImages) {
            return (
                <ImageTable
                    tableConstant={imageTableConstant}
                    objectType={objectTypes.GENERIC_IMAGE}
                    dataPrivileges={tableImages}
                    loading={this.props.imageLoading}
                    buttonLoading={this.props.imageButtonLoading}
                    categoryList={this.props.categoryList}
                    fileTypeList={this.props.fileTypeList}
                    allList={this.props.imageList}
                    activeList={this.props.imageActiveList}
                    columnOrder={this.props.imageColumnOrder}
                    columnVisibility={this.props.imageColumnVisibility}
                    columnWidth={this.props.imageColumnWidth}
                    offset={this.props.imageOffset}
                    page={this.props.imagePage}
                    rowCount={this.props.imageRowCount}
                    rowCountCustom={this.props.imageRowCountCustom}
                    search={this.props.imageSearch}
                    searchParams={this.props.imageSearchParams}
                    showFilterRow={this.props.imageShowFilterRow}
                    showGlobalSearch={this.props.imageShowGlobalSearch}
                    sortingAsc={this.props.imageSortingAsc}
                    sortingCriterion={this.props.imageSortingCriterion}
                    totalCount={this.props.imageTotalCount}
                />
            );
        }

        return null;
    };

    getImagePreview = (): JSX.Element => {
        const { tableImages } = this.state;
        return (
            <Preview
                tableConstant={menuConstants.TABLE_GENERIC_PREVIEW}
                objectType={objectTypes.GENERIC_IMAGE}
                dataPrivileges={tableImages}
                activeList={this.props.imageActiveList}
                fileId={null}
            />
        );
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getImagesTable(), this.getImagePreview()].filter(
                        (item) => item !== null
                    ) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.imageSceneTableSize}
                dataPrivileges={this.state.tableImages}
                sceneConstant={menuConstants.PAGE_GENERIC_IMAGES}
            />
        );
    }
}
