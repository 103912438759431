export class PrivilegeItem {
    key: string;
    type: string;
    privilegeKey: string;
    visible: boolean;
    editable: boolean;

    constructor(key: string, type: string, privilegeKey: string, visible: boolean, editable: boolean) {
        this.key = key;
        this.type = type;
        this.privilegeKey = privilegeKey;
        this.visible = visible;
        this.editable = editable;
    }
}

export function mapPrivilegeItem(data: Record<string, any>): PrivilegeItem | null {
    try {
        return new PrivilegeItem(data.key, data.type, data.privilegeKey, data.visible, data.editable);
    } catch (e) {
        return null;
    }
}
