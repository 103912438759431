import * as generalHelper from "../helpers/generalHelper";
import * as menuConstants from "../constants/menuConstants";
import * as navigationConstants from "../constants/navigationTypes";
import * as paths from "../constants/paths";
import * as routingHelper from "../helpers/routingHelper";
import * as translationHelper from "../helpers/translationHelper";

import { DispatchType, PropsType } from "../containers/Navigation";
import React, { Component } from "react";
import { DataItem } from "../types/dataItem";
import { MenuItem } from "../types/menu";
import { OptionItem } from "../types/optionItem";
import { PrivilegeItem } from "../types/privilegeItem";
import { ReactSVG } from "react-svg";
import { Scrollbars } from "react-custom-scrollbars";

import icon from "../resources/img/icons/icon.svg";
import imgBarcode from "../resources/img/icons/barcode.svg";
import imgBase from "../resources/img/icons/base.svg";
import imgColorants from "../resources/img/icons/canisters.svg";
import imgColors from "../resources/img/icons/colors.svg";
import imgCompany from "../resources/img/icons/company.svg";
import imgCurrency from "../resources/img/icons/currency.svg";
import imgDatabases from "../resources/img/icons/databases.svg";
import imgExport from "../resources/img/icons/export.svg";
import imgFandeck from "../resources/img/icons/fandeck.svg";
import imgFiles from "../resources/img/icons/product-files.svg";
import imgFormulaNotes from "../resources/img/icons/formula-notes.svg";
import imgFormulas from "../resources/img/icons/formulas.svg";
import imgImages from "../resources/img/icons/image.svg";
import imgImport from "../resources/img/icons/import.svg";
import imgPackage from "../resources/img/icons/package.svg";
import imgPrices from "../resources/img/icons/prices.svg";
import imgProduct from "../resources/img/icons/products.svg";
import imgProductGroups from "../resources/img/icons/product-groups.svg";
import imgRedlikePrivilege from "../resources/img/icons/redlike-privilege.svg";
import imgRedlikeUser from "../resources/img/icons/redlike-user.svg";
import imgScripts from "../resources/img/icons/scripts.svg";
import imgSettings from "../resources/img/icons/settings.svg";
import imgSettingsUser from "../resources/img/icons/settings-user.svg";
import imgSysZoneSettings from "../resources/img/icons/sz-settings.svg";
import imgUnits from "../resources/img/icons/units.svg";
import imgUser from "../resources/img/icons/users.svg";

type Props = PropsType & DispatchType;

type State = {
    dataPrivilegesMasterSettings: any;
    dataPrivilegesSystemSettings: any;
    dataPrivilegesZoneSettings: any;
};

export class Navigation extends Component<Props, State> {
    state: State = {
        dataPrivilegesMasterSettings: generalHelper.getMenuItemByKey(
            this.props.menuList,
            menuConstants.MENU_DATA_ITEM,
            menuConstants.TABLE_DATA_DATABASES
        ),
        dataPrivilegesSystemSettings: generalHelper.getMenuItemByKey(
            this.props.menuList,
            menuConstants.MENU_DATA_ITEM,
            menuConstants.TABLE_GLOBAL_SYSTEMS
        ),
        dataPrivilegesZoneSettings: generalHelper.getMenuItemByKey(
            this.props.menuList,
            menuConstants.MENU_DATA_ITEM,
            menuConstants.TABLE_GLOBAL_ZONES
        )
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuList) !== JSON.stringify(prevProps.menuList)) {
            this.setState({
                dataPrivilegesMasterSettings: generalHelper.getMenuItemByKey(
                    this.props.menuList,
                    menuConstants.MENU_DATA_ITEM,
                    menuConstants.TABLE_DATA_DATABASES
                ),
                dataPrivilegesSystemSettings: generalHelper.getMenuItemByKey(
                    this.props.menuList,
                    menuConstants.MENU_DATA_ITEM,
                    menuConstants.TABLE_GLOBAL_SYSTEMS
                ),
                dataPrivilegesZoneSettings: generalHelper.getMenuItemByKey(
                    this.props.menuList,
                    menuConstants.MENU_DATA_ITEM,
                    menuConstants.TABLE_GLOBAL_ZONES
                )
            });
        }
    }

    handleNavigateClick = (path: paths.Paths): any => {
        this.props.routingPush(path, !this.props.dataChanged);
    };

    handleMenuSectionClick = (key: string): void => {
        this.props.setMenuVisibility(key, !this.props.menuVisibility?.[key] || false);
    };

    getMenuSectionClassName = (key: string): string => {
        const { menuVisibility } = this.props;
        let className = "menu-list";

        if (menuVisibility === null) {
            return className;
        }

        switch (key) {
            case menuConstants.HEADER_MASTER: {
                className += " master";

                if (menuVisibility[menuConstants.HEADER_MASTER]) {
                    className += " section-open";
                }

                break;
            }
            case menuConstants.HEADER_SYSTEM: {
                className += " system";

                if (menuVisibility[menuConstants.HEADER_SYSTEM]) {
                    className += " section-open";
                }

                break;
            }
            case menuConstants.HEADER_ZONE: {
                className += " zone";

                if (menuVisibility[menuConstants.HEADER_ZONE]) {
                    className += " section-open";
                }

                break;
            }
            case menuConstants.HEADER_GENERIC: {
                className += " generic";

                if (menuVisibility[menuConstants.HEADER_GENERIC]) {
                    className += " section-open";
                }

                break;
            }
            default:
                break;
        }

        return className;
    };

    getMenuItemIcon = (scene: navigationConstants.Scene): JSX.Element => {
        switch (scene) {
            case navigationConstants.DATA_EXPORT_SCENE: {
                return <ReactSVG src={imgExport} />;
            }
            case navigationConstants.DATA_IMPORT_SCENE: {
                return <ReactSVG src={imgImport} />;
            }
            case navigationConstants.DATA_SERVER_SCRIPTS_SCENE: {
                return <ReactSVG src={imgScripts} />;
            }
            case navigationConstants.DATA_MASTER_SETTINGS_SCENE: {
                return <ReactSVG src={imgDatabases} />;
            }
            case navigationConstants.DATA_SYSTEM_ZONE_SETTINGS_SCENE: {
                return <ReactSVG src={imgSysZoneSettings} />;
            }
            case navigationConstants.GENERIC_BARCODES_SCENE: {
                return <ReactSVG src={imgBarcode} />;
            }
            case navigationConstants.GENERIC_CURRENCIES_SCENE: {
                return <ReactSVG src={imgCurrency} />;
            }
            case navigationConstants.GENERIC_PRODUCT_SHEETS_SCENE: {
                return <ReactSVG src={imgFiles} />;
            }
            case navigationConstants.GENERIC_IMAGES_SCENE: {
                return <ReactSVG src={imgImages} />;
            }
            case navigationConstants.GENERIC_PACKAGES_SCENE: {
                return <ReactSVG src={imgPackage} />;
            }
            case navigationConstants.GENERIC_STATIC_SQL_SCENE:
            case navigationConstants.MASTER_REDLIKE_OPTIONS_SCENE:
            case navigationConstants.SYSTEM_REDLIKE_OPTIONS_SCENE:
            case navigationConstants.ZONE_REDLIKE_OPTIONS_SCENE: {
                return <ReactSVG src={imgSettings} />;
            }
            case navigationConstants.GENERIC_UNITS_SCENE: {
                return <ReactSVG src={imgUnits} />;
            }
            case navigationConstants.COMPANY_SCENE: {
                return <ReactSVG src={imgCompany} />;
            }
            case navigationConstants.GLOBAL_OPTIONS_SCENE: {
                return <ReactSVG src={imgSettings} />;
            }
            case navigationConstants.USERS_SCENE: {
                return <ReactSVG src={imgUser} />;
            }
            case navigationConstants.USER_OPTIONS_SCENE: {
                return <ReactSVG src={imgSettingsUser} />;
            }
            case navigationConstants.MASTER_BASES_SCENE:
            case navigationConstants.SYSTEM_BASES_SCENE:
            case navigationConstants.ZONE_BASES_SCENE: {
                return <ReactSVG src={imgBase} />;
            }
            case navigationConstants.MASTER_COLORANTS_SCENE:
            case navigationConstants.SYSTEM_COLORANTS_SCENE:
            case navigationConstants.ZONE_COLORANTS_SCENE: {
                return <ReactSVG src={imgColorants} />;
            }
            case navigationConstants.MASTER_COLORS_SCENE:
            case navigationConstants.SYSTEM_COLORS_SCENE:
            case navigationConstants.ZONE_COLORS_SCENE: {
                return <ReactSVG src={imgColors} />;
            }
            case navigationConstants.MASTER_FANDECKS_SCENE:
            case navigationConstants.SYSTEM_FANDECKS_SCENE:
            case navigationConstants.ZONE_FANDECKS_SCENE: {
                return <ReactSVG src={imgFandeck} />;
            }
            case navigationConstants.MASTER_FORMULAS_SCENE:
            case navigationConstants.SYSTEM_FORMULAS_SCENE:
            case navigationConstants.ZONE_FORMULAS_SCENE: {
                return <ReactSVG src={imgFormulas} />;
            }
            case navigationConstants.MASTER_FORMULA_NOTES_SCENE:
            case navigationConstants.SYSTEM_FORMULA_NOTES_SCENE:
            case navigationConstants.ZONE_FORMULA_NOTES_SCENE: {
                return <ReactSVG src={imgFormulaNotes} />;
            }
            case navigationConstants.SYSTEM_PRICES_SCENE:
            case navigationConstants.ZONE_PRICES_SCENE: {
                return <ReactSVG src={imgPrices} />;
            }
            case navigationConstants.MASTER_PRODUCTS_SCENE:
            case navigationConstants.SYSTEM_PRODUCTS_SCENE:
            case navigationConstants.ZONE_PRODUCTS_SCENE: {
                return <ReactSVG src={imgProduct} />;
            }
            case navigationConstants.SYSTEM_PRODUCT_GROUPS_SCENE:
            case navigationConstants.ZONE_PRODUCT_GROUPS_SCENE: {
                return <ReactSVG src={imgProductGroups} />;
            }
            case navigationConstants.MASTER_REDLIKE_PRIVILEGES_SCENE: {
                return <ReactSVG src={imgRedlikePrivilege} />;
            }
            case navigationConstants.MASTER_REDLIKE_USERS_SCENE:
            case navigationConstants.SYSTEM_REDLIKE_USERS_SCENE:
            case navigationConstants.ZONE_REDLIKE_USERS_SCENE: {
                return <ReactSVG src={imgRedlikeUser} />;
            }
            default:
                return <ReactSVG src={icon} />;
        }
    };

    getMenuItemClassName = (scene: navigationConstants.Scene): string => {
        let className = "menu-list-item";

        if (navigationConstants.GLOBAL_SCENES_LIST.includes(scene)) {
            className += " menu-global-item";
        }

        if (this.props.activeScene === scene) {
            className += " active";
        }

        return className;
    };

    getMenuItem = (item: MenuItem, scene: navigationConstants.Scene, path: paths.Paths): JSX.Element => {
        return (
            <div
                key={item.key}
                className={this.getMenuItemClassName(scene)}
                onClick={(): void => this.handleNavigateClick(path)}
            >
                <span className="menu-item-icon">{this.getMenuItemIcon(scene)}</span>
                <div className="menu-item-text">
                    <span>{translationHelper.getMenuTranslation(item.key, item.translateText, this.props.custom)}</span>
                </div>
            </div>
        );
    };

    getMenuSection = (item: MenuItem): Array<JSX.Element> => {
        const { menuVisibility } = this.props;
        const section: Array<JSX.Element> = [];

        let className = "menu-items";

        if (item.key === menuConstants.HEADER_GENERIC || item.key === menuConstants.HEADER_OPTIONS) {
            className += " options-items";
        }

        if (menuVisibility === null) {
            return section;
        }

        for (const [activeKey, activeValue] of Object.entries(menuVisibility)) {
            if (activeKey === item.key && activeValue) {
                return [
                    <div className={className} key={item.key}>
                        {this.getMenuItems(item.items)}
                    </div>
                ];
            }
        }

        return section;
    };

    getMenuSectionIcon = (item: MenuItem): JSX.Element | null => {
        const path = routingHelper.getPathByMenuItem(item.key);
        let isVisible = false;

        switch (item.key) {
            case menuConstants.HEADER_MASTER: {
                if (this.state.dataPrivilegesMasterSettings?.canView) {
                    isVisible = true;
                }

                break;
            }
            case menuConstants.HEADER_SYSTEM: {
                if (this.state.dataPrivilegesSystemSettings?.canView) {
                    isVisible = true;
                }

                break;
            }
            case menuConstants.HEADER_ZONE: {
                if (this.state.dataPrivilegesZoneSettings?.canView) {
                    isVisible = true;
                }

                break;
            }
            default:
                break;
        }

        if (path && isVisible) {
            return (
                <div onClick={(event: any) => event.stopPropagation()}>
                    <ReactSVG
                        src={imgSettings}
                        className="system-zone-settings-icon"
                        onClick={(): void => this.handleNavigateClick(path)}
                    />
                </div>
            );
        }

        return null;
    };

    getMenuItems = (list: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>): Array<JSX.Element> => {
        const items: Array<JSX.Element> = [];

        for (const item of list) {
            if (item instanceof MenuItem && item.type === menuConstants.MENU_HEADER_ITEM) {
                const section = this.getMenuSection(item);
                const sectionIcon = this.getMenuSectionIcon(item);

                items.push(
                    <div className={this.getMenuSectionClassName(item.key)} key={item.key}>
                        <div className="menu-title" onClick={(): void => this.handleMenuSectionClick(item.key)}>
                            <div className="menu-title-container">
                                <span className="ellipsis">
                                    {translationHelper.getMenuSectionTranslation(item.key, item.translateText)}
                                </span>
                                {sectionIcon}
                            </div>
                            <span className="caret"></span>
                        </div>
                        {section}
                    </div>
                );
            }

            if (
                item instanceof MenuItem &&
                item.type === menuConstants.MENU_MAIN_PAGE &&
                (item.key !== menuConstants.PAGE_DATA_SERVER_SCRIPTS || this.props.scriptList.length)
            ) {
                const scene = routingHelper.getSceneByMenuItem(item.key);
                const path = routingHelper.getPathByMenuItem(item.key);

                if (scene && path) {
                    items.push(this.getMenuItem(item, scene, path));
                }
            }
        }

        return items;
    };

    getMenu = (): JSX.Element | null => {
        const { menuList } = this.props;

        if (menuList.length === 0) {
            return null;
        }

        return (
            <div className="menu-lists">
                <Scrollbars
                    autoHide
                    renderThumbVertical={(props): JSX.Element => <div {...props} className="thumb-vertical" />}
                    renderTrackHorizontal={(props): JSX.Element => (
                        <div {...props} style={{ display: "none" }} className="track-horizontal" />
                    )}
                    renderView={(props): JSX.Element => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "100%",
                                overflowX: "hidden",
                                right: "-10%"
                            }}
                        />
                    )}
                >
                    {this.getMenuItems(menuList)}
                </Scrollbars>
            </div>
        );
    };

    getLogo = (): JSX.Element => {
        const homePage = routingHelper.getHomePagePath(this.props.activeUser?.username || "");

        return (
            <div className="lab-logo">
                <ReactSVG
                    className="logo-icon"
                    src={generalHelper.getSoftwareLogo(this.props.custom)}
                    onClick={(): void => this.handleNavigateClick(homePage)}
                />
            </div>
        );
    };

    render(): JSX.Element {
        return (
            <nav className="left-menu" role="navigation">
                {this.getLogo()}
                {this.getMenu()}
            </nav>
        );
    }
}

export default Navigation;
