import * as actionConstants from "../constants/actionTypes";
import * as colorActions from "../actions/colorActions";
import * as colorHelper from "../helpers/colorHelper";
import * as itemsWithParamsActions from "../actions/itemsWithParamsActions";
import * as methods from "../constants/serverMethods";
import * as modalTypes from "../constants/modalTypes";
import * as navigationActions from "../actions/navigationActions";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as optionsConstants from "../constants/optionsConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as settingsActions from "../actions/optionActions";
import * as userActions from "../actions/userActions";

import { ColorData, updateColorDataReflData } from "../types/colorData";
import { Epic, ofType } from "redux-observable";
import { ReflectanceData, mapReflectanceData } from "../types/reflectanceData";

import { BaseInProduct } from "../types/baseInProduct";
import { Colorant } from "../types/colorant";
import { Product } from "../types/product";
import { ProductGroup } from "../types/productGroup";
import { UserGroup } from "../types/userGroup";
import { reloadData } from "../actions/universalObjectActions";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

// TODO nekde by mela byt kontrola ze add jde provest jen pokud je active DB/system/zone --- uz tlacitka by mely byt disablovany
export const addItem: Epic<any, any> = (action$, state$) =>
    action$.pipe(
        ofType(actionConstants.ADD),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // base
                case objectTypes.MASTER_BASE: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_MASTER_BASE, methods.METHOD_ADD_MASTER_BASE, payload.params)
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE: {
                    requests.push(
                        itemsWithParamsActions.addSystemBaseWithParams(state$.value.base.systemMasterList, [])
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE: {
                    requests.push(itemsWithParamsActions.addZoneBaseWithParams(state$.value.base.zoneSystemList));
                    break;
                }
                // color
                case objectTypes.MASTER_COLOR: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
                            methods.METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS,
                            payload.params
                        )
                    );
                    break;
                }
                // colorant
                case objectTypes.MASTER_COLORANT: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_COLORANT,
                            methods.METHOD_ADD_MASTER_COLORANT,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_COLORANT: {
                    requests.push(
                        itemsWithParamsActions.addSystemColorantWithParams(
                            state$.value.colorant.systemMasterList,
                            [],
                            []
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT: {
                    requests.push(
                        itemsWithParamsActions.addZoneColorantWithParams(state$.value.colorant.zoneSystemList, [], [])
                    );
                    break;
                }
                // colorant batch
                case objectTypes.MASTER_COLORANT_BATCH: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_COLORANT_BATCH,
                            methods.METHOD_ADD_MASTER_COLORANT_BATCH,
                            payload.params
                        )
                    );
                    break;
                }
                // colorant package
                case objectTypes.MASTER_SYSTEM_COLORANT_PACKAGE: {
                    const colorantIds = state$.value.colorant.systemActiveList.map((item: Colorant) => item.id);

                    requests.push(
                        itemsWithParamsActions.addSystemColorantPackageWithParams(
                            state$.value.colorantPackage.systemMasterList,
                            colorantIds,
                            []
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_COLORANT_PACKAGE: {
                    const colorantIds = state$.value.colorant.zoneActiveList.map((item: Colorant) => item.id);

                    requests.push(
                        itemsWithParamsActions.addZoneColorantPackageWithParams(
                            state$.value.colorantPackage.zoneSystemList,
                            colorantIds
                        )
                    );
                    break;
                }
                // company
                case objectTypes.COMPANY: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_COMPANY, methods.METHOD_ADD_COMPANY, payload.params)
                    );
                    break;
                }
                // currency
                case objectTypes.CURRENCY: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_CURRENCY, methods.METHOD_ADD_CURRENCY, payload.params)
                    );
                    break;
                }
                // database
                case objectTypes.DATABASE: {
                    if (payload.params.data.fileUuid !== null) {
                        requests.push(
                            serverRequest(methods.METHOD_ADD_DATABASE, methods.METHOD_ADD_DATABASE, payload.params)
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_ADD_EMPTY_DATABASE,
                                methods.METHOD_ADD_EMPTY_DATABASE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // export
                case objectTypes.EXPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_INSERT_EXPORT_TASK, methods.METHOD_INSERT_EXPORT_TASK, {
                            ...payload.params,
                            [propertyConstants.PROPERTY_DATA]: {
                                ...payload.params.data,
                                [propertyConstants.PROPERTY_ZONE_UUID]: undefined
                            }
                        })
                    );
                    break;
                }
                // fandeck
                case objectTypes.MASTER_FANDECK: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_FANDECK,
                            methods.METHOD_ADD_MASTER_FANDECK,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_FANDECK: {
                    requests.push(
                        itemsWithParamsActions.addSystemFandeckWithParams(state$.value.fandeck.systemMasterList, [])
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_FANDECK: {
                    requests.push(
                        itemsWithParamsActions.addZoneFandeckWithParams(state$.value.fandeck.zoneSystemList, [])
                    );
                    break;
                }
                // file category
                case objectTypes.FILE_CATEGORY: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_FILE_CATEGORY,
                            methods.METHOD_ADD_FILE_CATEGORY,
                            payload.params.data
                        )
                    );
                    break;
                }
                // formula
                case objectTypes.MASTER_FORMULA: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_MASTER_FORMULA, methods.METHOD_ADD_MASTER_FORMULA, {
                            ...payload.params,
                            [propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]:
                                payload.params.data?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] || null,
                            [propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID]:
                                payload.params.data?.[propertyConstants.PROPERTY_COLOR_IN_FANDECK_ID] || null
                        })
                    );
                    break;
                }
                // formula note
                case objectTypes.MASTER_FORMULA_NOTE: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_FORMULA_NOTE,
                            methods.METHOD_ADD_MASTER_FORMULA_NOTE,
                            payload.params
                        )
                    );
                    break;
                }
                // image
                case objectTypes.GENERIC_IMAGE:
                case objectTypes.ICON:
                case objectTypes.IMAGE: {
                    if (
                        payload.params?.data?.[propertyConstants.PROPERTY_SRC] &&
                        payload.params?.[propertyConstants.PROPERTY_IMAGE_ID]
                    ) {
                        requests.push(
                            serverRequest(methods.METHOD_EDIT_IMAGE, methods.METHOD_EDIT_IMAGE, payload.params)
                        );
                    } else if (!payload.params?.data?.[propertyConstants.PROPERTY_SRC]) {
                        requests.push(
                            serverRequest(methods.METHOD_ADD_IMAGE, methods.METHOD_ADD_IMAGE, payload.params)
                        );
                    }
                    break;
                }
                case objectTypes.IMAGE_DIRECTORY: {
                    requests.push(
                        navigationActions.navigationCloseModal(modalTypes.MODAL_IMAGES_ADD_MORE),
                        reloadData(objectTypes.GENERIC_IMAGE)
                    );
                    break;
                }
                // image category
                case objectTypes.IMAGE_CATEGORY:
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_IMAGE_CATEGORY,
                            methods.METHOD_ADD_IMAGE_CATEGORY,
                            payload.params.data
                        )
                    );
                    break;
                // import
                case objectTypes.IMPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_IMPORT_TASK, methods.METHOD_ADD_IMPORT_TASK, payload.params)
                    );
                    break;
                }
                // package
                case objectTypes.PACKAGE: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_PACKAGE, methods.METHOD_ADD_PACKAGE, payload.params)
                    );
                    break;
                }
                // price
                case objectTypes.SYSTEM_PRICE_MARGIN: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_SYSTEM_PRICE_MARGIN,
                            methods.METHOD_ADD_SYSTEM_PRICE_MARGIN,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.ZONE_PRICE_MARGIN: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_ZONE_PRICE_MARGIN,
                            methods.METHOD_ADD_ZONE_PRICE_MARGIN,
                            payload.params
                        )
                    );
                    break;
                }
                // product
                case objectTypes.MASTER_PRODUCT: {
                    if (payload.params.indexData === undefined) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_ADD_MASTER_PRODUCT,
                                methods.METHOD_ADD_MASTER_PRODUCT,
                                payload.params
                            )
                        );
                    } else {
                        requests.push(
                            serverRequest(
                                methods.METHOD_ADD_MASTER_PRODUCT,
                                methods.METHOD_ADD_MASTER_PRODUCT_WITH_INDEX_DATA,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT: {
                    requests.push(
                        itemsWithParamsActions.addSystemProductWithParams(
                            state$.value.product.systemMasterList,
                            [],
                            [],
                            [],
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT: {
                    requests.push(
                        itemsWithParamsActions.addZoneProductWithParams(
                            state$.value.product.zoneSystemList,
                            [],
                            [],
                            payload.params
                        )
                    );
                    break;
                }
                // product base
                case objectTypes.MASTER_BASE_IN_PRODUCT: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_MASTER_BASE_IN_PRODUCT,
                            methods.METHOD_ADD_MASTER_BASE_IN_PRODUCT,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT: {
                    const baseInProductIds = state$.value.baseInProduct.systemMasterList.map(
                        (item: BaseInProduct) => item.id
                    );
                    const productIds = state$.value.product.systemActiveList.map((item: Product) => item.id);

                    requests.push(
                        itemsWithParamsActions.addSystemBaseInProductWithParams(baseInProductIds, productIds)
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT: {
                    const baseInProductIds = state$.value.baseInProduct.zoneSystemList.map(
                        (item: BaseInProduct) => item.id
                    );
                    const productIds = state$.value.product.zoneActiveList.map((item: Product) => item.id);

                    requests.push(itemsWithParamsActions.addZoneBaseInProductWithParams(baseInProductIds, productIds));
                    break;
                }
                // product base package
                case objectTypes.MASTER_SYSTEM_BASE_IN_PRODUCT_PACKAGE: {
                    const baseInProductIds = state$.value.baseInProduct.systemActiveList.map(
                        (item: BaseInProduct) => item.id
                    );

                    requests.push(
                        itemsWithParamsActions.addSystemBaseInProductPackageWithParams(
                            state$.value.baseInProductPackage.systemMasterList,
                            baseInProductIds,
                            []
                        )
                    );
                    break;
                }
                case objectTypes.SYSTEM_ZONE_BASE_IN_PRODUCT_PACKAGE: {
                    const baseInProductIds = state$.value.baseInProduct.zoneActiveList.map(
                        (item: BaseInProduct) => item.id
                    );

                    requests.push(
                        itemsWithParamsActions.addZoneBaseInProductPackageWithParams(
                            state$.value.baseInProductPackage.zoneSystemList,
                            baseInProductIds
                        )
                    );
                    break;
                }
                // product groups
                case objectTypes.MASTER_SYSTEM_PRODUCT_WITH_PRODUCT_GROUPS: {
                    const productGroupIds = state$.value.productGroup.systemMasterList.map(
                        (item: ProductGroup) => item.id
                    );
                    const productData = state$.value.product.systemActiveList[0];

                    requests.push(itemsWithParamsActions.editSystemProductWithParams(productData, productGroupIds));
                    break;
                }
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP:
                case objectTypes.MASTER_SYSTEM_PRODUCT_GROUP_WITH_PRODUCTS: {
                    const productIds = state$.value.product.systemMasterList.map((item: Product) => item.id);

                    requests.push(itemsWithParamsActions.addSystemProductGroupWithParams(productIds, payload.params));
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_WITH_PRODUCT_GROUPS: {
                    const productGroupIds = state$.value.productGroup.zoneSystemList.map(
                        (item: ProductGroup) => item.id
                    );
                    const productData = state$.value.product.zoneActiveList[0];

                    requests.push(itemsWithParamsActions.editZoneProductWithParams(productData, productGroupIds));
                    break;
                }
                case objectTypes.SYSTEM_ZONE_PRODUCT_GROUP: {
                    requests.push(
                        itemsWithParamsActions.addZoneProductGroupWithParams(state$.value.productGroup.zoneSystemList)
                    );
                    break;
                }
                // product sheet
                case objectTypes.PRODUCT_SHEET: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_ADD_FILE, methods.METHOD_ADD_PRODUCT_SHEET, payload.params)
                        );
                    }
                    break;
                }
                // spectro type
                case objectTypes.SPECTRO_TYPE: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(
                                methods.METHOD_ADD_SPECTRO_TYPE,
                                methods.METHOD_ADD_SPECTRO_TYPE,
                                payload.params
                            )
                        );
                    }
                    break;
                }
                // static sql
                case objectTypes.STATIC_SQL: {
                    if (payload.params && payload.params.data) {
                        requests.push(
                            serverRequest(methods.METHOD_ADD_FILE, methods.METHOD_ADD_STATIC_SQL, payload.params)
                        );
                    }
                    break;
                }
                // system
                case objectTypes.SYSTEM: {
                    requests.push(serverRequest(methods.METHOD_ADD_SYSTEM, methods.METHOD_ADD_SYSTEM, payload.params));
                    break;
                }
                // unit
                case objectTypes.UNIT: {
                    requests.push(serverRequest(methods.METHOD_ADD_UNIT, methods.METHOD_ADD_UNIT, payload.params));
                    break;
                }
                // user
                case objectTypes.DEFAULT_MASTER_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map((item: UserGroup) => item.id);

                    requests.push(itemsWithParamsActions.addMasterRedlikeUserWithParams(userGroupIds, payload.params));
                    break;
                }
                case objectTypes.MASTER_SYSTEM_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map((item: UserGroup) => item.id);

                    requests.push(itemsWithParamsActions.addSystemRedlikeUserWithParams(userGroupIds, payload.params));
                    break;
                }
                case objectTypes.SYSTEM_ZONE_REDLIKE_USER_GROUP: {
                    const userGroupIds = state$.value.userGroup.selectedList.map((item: UserGroup) => item.id);

                    requests.push(itemsWithParamsActions.addZoneRedlikeUserWithParams(userGroupIds, payload.params));
                    break;
                }
                case objectTypes.USER: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_USER_WITH_USER_GROUPS,
                            methods.METHOD_ADD_USER_WITH_USER_GROUPS,
                            payload.params
                        )
                    );

                    requests.push(
                        userActions.saveUserCompanyId(
                            payload.params?.data?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null
                        )
                    );

                    break;
                }
                case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                    requests.push(
                        serverRequest(
                            methods.METHOD_ADD_REDLIKE_USER_GROUP,
                            methods.METHOD_ADD_REDLIKE_USER_GROUP,
                            payload.params
                        )
                    );
                    break;
                }
                case objectTypes.USER_GROUP: {
                    requests.push(
                        serverRequest(methods.METHOD_ADD_USER_GROUP, methods.METHOD_ADD_USER_GROUP, payload.params)
                    );
                    break;
                }
                // wavelength
                case objectTypes.WAVELENGTH: {
                    const updatedColorDataList: Array<ColorData> = [];

                    // generates wavelength list base on the payload data
                    const wavelengthList = colorHelper.getWavelengthList(
                        payload.params[propertyConstants.PROPERTY_INTERVAL],
                        payload.params.begin_value,
                        payload.params.end_value
                    );

                    if (state$.value.color.masterModalActiveList.length) {
                        const reflDataList: Array<ReflectanceData> = [];
                        let reflData: ReflectanceData | null;
                        let selectedColorData: ColorData | null = null;

                        for (const wavelengthItem of wavelengthList) {
                            let foundItem: any = null;

                            // finds active color data in the modal
                            selectedColorData =
                                state$.value.color.masterModalActiveList[0][propertyConstants.PROPERTY_DATA].find(
                                    (item: ColorData) =>
                                        item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                                        state$.value.color.masterModalActiveList[0][
                                            propertyConstants.PROPERTY_SPECTRO_TYPE_ID
                                        ]
                                ) || null;

                            if (selectedColorData) {
                                /* finds reflData on the specific wavelength in selected color data,
                                 if it finds some specific value then it stores it in foundItem
                                 */
                                foundItem = selectedColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].find(
                                    (reflItem: ReflectanceData) =>
                                        reflItem[propertyConstants.PROPERTY_WAVELENGTH] === wavelengthItem
                                );
                            }

                            reflData = mapReflectanceData(
                                wavelengthItem,
                                foundItem && foundItem[propertyConstants.PROPERTY_VALUE] !== null
                                    ? foundItem[propertyConstants.PROPERTY_VALUE]
                                    : null
                            );

                            if (reflData) {
                                reflDataList.push(reflData);
                            }
                        }

                        for (const item of state$.value.color.masterModalActiveList[0][
                            propertyConstants.PROPERTY_DATA
                        ]) {
                            if (
                                selectedColorData &&
                                item[propertyConstants.PROPERTY_ID] === selectedColorData[propertyConstants.PROPERTY_ID]
                            ) {
                                updatedColorDataList.push(updateColorDataReflData(selectedColorData, reflDataList));
                            } else {
                                updatedColorDataList.push(item);
                            }
                        }
                    }

                    requests.push(
                        colorActions.updateColorData(
                            objectTypes.MASTER_COLOR_MODAL,
                            updatedColorDataList,
                            state$.value.color.masterModalActiveList.length
                                ? state$.value.color.masterModalActiveList[0][
                                      propertyConstants.PROPERTY_SPECTRO_TYPE_ID
                                  ]
                                : null
                        ),
                        settingsActions.setUserOption(optionsConstants.OPTION_WAVELENGTH, payload.params),
                        navigationActions.navigationCloseModal(modalTypes.MODAL_WAVELENGTH_SETTINGS)
                    );
                    break;
                }
                // zone
                case objectTypes.ZONE: {
                    requests.push(serverRequest(methods.METHOD_ADD_ZONE, methods.METHOD_ADD_ZONE, payload.params));
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
