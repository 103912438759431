/**
 * Language definition
 */

export type Language = typeof ENGLISH | typeof GERMAN;

export const ENGLISH = "en";
export const GERMAN = "de";

export const NAME_ENGLISH = "English";
export const NAME_GERMAN = "Deutsch";

/**
 * Constants for translation management
 */

export type TranslationActionType = typeof TRANSLATION_ACTION_TYPE_IMPORT | typeof TRANSLATION_ACTION_TYPE_EXPORT;
export const TRANSLATION_ACTION_TYPE_IMPORT = "import";
export const TRANSLATION_ACTION_TYPE_EXPORT = "export";

export type TranslationFileType = typeof TRANSLATION_FILE_TYPE_CSV | typeof TRANSLATION_FILE_TYPE_CSV_NEW;
export const TRANSLATION_FILE_TYPE_CSV = "csv";
export const TRANSLATION_FILE_TYPE_CSV_NEW = "csv_new";

/**
 * Hard-coded translation constants
 */

export const COLORIS_MANAGER = "CAMa.T MANAGER";
export const COLORIS_SOFTWARE = "CAMa.T";
export const REDLIKE = "Redlike";
export const REDLIKE_HELPER = "Redlike Helper";
export const REDLIKE_MANAGER = "Redlike Manager";
export const REDLIKE_WEB = "Redlike Web";
export const REDLIKE_WEB_ABBREVIATION = "R2W";
export const REDLIKE_WEB_WEBSITE = "system.on-redlike.com";
