import * as itemTypeConstants from "../constants/itemTypeConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { BaseInProductPackage } from "./baseInProductPackage";
import { Color } from "./color";
import { ColorantPackage } from "./colorantPackage";
import { Fandeck } from "./fandeck";
import { System } from "./system";
import { SystemZone } from "./systemZone";
import { TableHeading } from "./tableHeading";

export class Barcode {
    id: number | null;
    itemId: number | null;
    item2Id: number | null;
    item3Id: number | null;
    itemTypeId: number | null;
    systemId: number | null;
    zoneId: number | null;
    barcode: string | null;
    additional: boolean;
    inherited: boolean;
    isPrimary: boolean;
    canDelete: boolean;
    baseInProductPackage?: BaseInProductPackage | null;
    colorantPackage?: ColorantPackage | null;
    colorInFandeck?: Color | null;
    fandeck?: Fandeck | null;

    constructor(
        id: number | null,
        itemId: number | null,
        item2Id: number | null,
        item3Id: number | null,
        itemTypeId: number | null,
        systemId: number | null,
        zoneId: number | null,
        barcode: string | null,
        additional: boolean,
        inherited: boolean,
        isPrimary: boolean,
        canDelete: boolean,
        baseInProductPackage?: BaseInProductPackage | null,
        colorantPackage?: ColorantPackage | null,
        colorInFandeck?: Color | null,
        fandeck?: Fandeck | null
    ) {
        this.id = id;
        this.itemId = itemId;
        this.item2Id = item2Id;
        this.item3Id = item3Id;
        this.itemTypeId = itemTypeId;
        this.systemId = systemId;
        this.zoneId = zoneId;
        this.barcode = barcode;
        this.additional = additional;
        this.inherited = inherited;
        this.isPrimary = isPrimary;
        this.canDelete = canDelete;
        this.baseInProductPackage = baseInProductPackage;
        this.colorantPackage = colorantPackage;
        this.colorInFandeck = colorInFandeck;
        this.fandeck = fandeck;
    }
}

export function mapBarcode(data: Record<string, any>): Barcode | null {
    try {
        return new Barcode(
            data.id,
            data.itemId,
            data.item2Id,
            data.item3Id,
            data.itemTypeId,
            data.systemId,
            data.zoneId,
            data.barcode,
            data.additional,
            data.inherited,
            data.isPrimary,
            data.canDelete
        );
    } catch (e) {
        return null;
    }
}

export function updateBarcodeWithItem(
    data: Barcode,
    baseInProductPackage: BaseInProductPackage | null,
    colorantPackage: ColorantPackage | null,
    colorInFandeck: Color | null,
    fandeck: Fandeck | null
): Barcode | null {
    try {
        return new Barcode(
            data.id,
            data.itemId,
            data.item2Id,
            data.item3Id,
            data.itemTypeId,
            data.systemId,
            data.zoneId,
            data.barcode,
            data.additional,
            data.inherited,
            data.isPrimary,
            data.canDelete,
            baseInProductPackage,
            colorantPackage,
            colorInFandeck,
            fandeck
        );
    } catch (e) {
        return null;
    }
}

export function mapBarcodes(data: Array<any>): Array<Barcode> {
    if (!data) {
        return [];
    }

    const barcodes = [];
    let barcode;

    for (const item of data) {
        barcode = mapBarcode(item);

        if (barcode !== null) {
            barcodes.push(barcode);
        }
    }

    return barcodes;
}

export function createBarcode(value = "", additional: boolean = false): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_BARCODE]: value,
        [propertyConstants.PROPERTY_ADDITIONAL]: additional,
        [propertyConstants.PROPERTY_INHERITED]: false,
        [propertyConstants.PROPERTY_IS_PRIMARY]: false,
        [propertyConstants.PROPERTY_CAN_DELETE]: true
    };
}

export function generateBarcodeData(barcodeList: Array<Barcode>): Array<any> {
    const data: Array<any> = [];
    let barcode: Barcode;

    for (barcode of barcodeList) {
        data.push({
            [propertyConstants.PROPERTY_BARCODE]: barcode.barcode,
            [propertyConstants.PROPERTY_BASE_NAME]: barcode.baseInProductPackage
                ? barcode.baseInProductPackage[propertyConstants.PROPERTY_BASE_NAME]
                : undefined,
            [propertyConstants.PROPERTY_COLOR_NAME]: barcode.colorInFandeck
                ? barcode.colorInFandeck[propertyConstants.PROPERTY_COLOR_NAME]
                : undefined,
            [propertyConstants.PROPERTY_COLORANT_NAME]: barcode.colorantPackage
                ? barcode.colorantPackage[propertyConstants.PROPERTY_COLORANT_NAME]
                : undefined,
            [propertyConstants.PROPERTY_FANDECK_NAME]: barcode.fandeck
                ? barcode.fandeck[propertyConstants.PROPERTY_NAME]
                : barcode.colorInFandeck
                ? barcode.colorInFandeck[propertyConstants.PROPERTY_FANDECK_NAME]
                : undefined,
            [propertyConstants.PROPERTY_ID]: barcode.id,
            [propertyConstants.PROPERTY_ITEM_TYPE_ID]: barcode.itemTypeId,
            [propertyConstants.PROPERTY_PACKAGE_NAME]: barcode.baseInProductPackage
                ? barcode.baseInProductPackage[propertyConstants.PROPERTY_NAME]
                : barcode.colorantPackage
                ? barcode.colorantPackage[propertyConstants.PROPERTY_NAME]
                : undefined,
            [propertyConstants.PROPERTY_PRODUCT_NAME]: barcode.baseInProductPackage
                ? barcode.baseInProductPackage[propertyConstants.PROPERTY_PRODUCT_NAME]
                : undefined,
            [propertyConstants.PROPERTY_SYSTEM_ID]: barcode.systemId,
            [propertyConstants.PROPERTY_ZONE_ID]: barcode.zoneId
        });
    }

    return data;
}

export function generateBarcodeTypes(): Array<any> {
    return itemTypeConstants.BARCODE_TYPES.map((item: number) => {
        return {
            [propertyConstants.PROPERTY_ID]: item,
            [propertyConstants.PROPERTY_NAME]: translationHelper.getItemTypeTranslation(item)
        };
    });
}

export function generateBarcodeHeadings(
    objectType: objectTypes.ObjectType,
    barcodeList: Array<Record<string, any>>,
    systemList: Array<System>,
    zoneList: Array<SystemZone>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_BARCODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BARCODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BARCODE),
            editableList.includes(propertyConstants.PROPERTY_BARCODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BARCODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BARCODE),
            requiredList.includes(propertyConstants.PROPERTY_BARCODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_BARCODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BASE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            editableList.includes(propertyConstants.PROPERTY_BASE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BASE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BASE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_BASE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_BASE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLOR_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLOR_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLOR_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLOR_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLOR_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COLORANT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            editableList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_COLORANT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_COLORANT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FANDECK_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FANDECK_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_FANDECK_NAME),
            editableList.includes(propertyConstants.PROPERTY_FANDECK_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FANDECK_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FANDECK_NAME),
            requiredList.includes(propertyConstants.PROPERTY_FANDECK_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_FANDECK_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ITEM_TYPE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ITEM_TYPE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            editableList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ITEM_TYPE_ID),
            barcodeList,
            widthOption?.[propertyConstants.PROPERTY_ITEM_TYPE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PACKAGE_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PACKAGE_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            editableList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PACKAGE_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PACKAGE_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SYSTEM_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            editableList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            requiredList.includes(propertyConstants.PROPERTY_SYSTEM_ID),
            systemList,
            widthOption?.[propertyConstants.PROPERTY_SYSTEM_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ZONE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            editableList.includes(propertyConstants.PROPERTY_ZONE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ZONE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ZONE_ID),
            requiredList.includes(propertyConstants.PROPERTY_ZONE_ID),
            zoneList,
            widthOption?.[propertyConstants.PROPERTY_ZONE_ID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
