import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as serverActions from "../../actions/serverActions";
import * as serverEventAction from "../../actions/serverEventsActions";
import * as websocketActions from "../../actions/websocketActions";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_SERVER_INIT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverEventAction.saveSessionInfo(response.data)];
        }
        case methods.METHOD_GET_SESSION_UUID: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_GET_SQLITE_INFO: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                serverActions.saveSqliteInfo(
                    response.data.sqlite_version ? response.data.sqlite_version : null,
                    response.data.version ? response.data.version : null
                )
            ];
        }
        case methods.METHOD_GET_VERSION: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [serverActions.saveVersion(response.data ? response.data : null)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
