import * as bookmarkConstants from "../../../constants/bookmarkConstants";
import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";

import { DispatchType, PropsType } from "../../../containers/scenes/zone/ZoneFormulaSceneContainer";
import { Formula, generateFormulaHeadings } from "../../../types/formula";
import React, { Component } from "react";

import { Bookmark } from "../../../types/bookmark";
import { DataItem } from "../../../types/dataItem";
import { Fandeck } from "../../../types/fandeck";
import { FormulaColorsBookmark } from "../../bookmarks/FormulaColorsBookmark";
import { FormulaHistoryBookmark } from "../../bookmarks/FormulaHistoryBookmark";
import { FormulaPropertiesBookmark } from "../../bookmarks/FormulaPropertiesBookmark";
import { MenuItem } from "../../../types/menu";
import { ModalProperties } from "../../../types/modalProperties";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { Product } from "../../../types/product";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";
import { SceneDetail } from "../../general/scene/SceneDetail";
import { SceneFilterTable } from "../../general/scene/SceneFilterTable";
import { TableHeading } from "../../../types/tableHeading";

import { t as translate } from "react-i18nify";

type Props = PropsType & DispatchType;

type State = {
    tableColorWithFormulaInfo: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class ZoneFormulaScene extends Component<Props, State> {
    state: State = {
        tableColorWithFormulaInfo:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.colorWithFormulaInfoTableConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableColorWithFormulaInfo:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find(
                              (item) => item.key === this.props.colorWithFormulaInfoTableConstant
                          )
                        : null) || null
            });
        }
    }

    generateTableHeadings = (
        modalType: modalTypes.ModalType | null = null,
        isFormulaNotAvailable: boolean = false
    ): Array<TableHeading> => {
        const propertyList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_NAME,
            propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID,
            propertyConstants.PROPERTY_STATUS,
            propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_BASE_AMOUNT,
            propertyConstants.PROPERTY_UUID,
            propertyConstants.PROPERTY_IS_GRAVIMETRIC,
            propertyConstants.PROPERTY_COLORANT_LIST
        ];
        const requiredList: Array<propertyConstants.Property> = [
            propertyConstants.PROPERTY_COLORANT_LIST,
            propertyConstants.PROPERTY_PRODUCT_ID,
            propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID,
            propertyConstants.PROPERTY_BASE_AMOUNT,
            propertyConstants.PROPERTY_STATUS,
            propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
            propertyConstants.PROPERTY_IS_GRAVIMETRIC
        ];
        const alwaysVisibleList: Array<propertyConstants.Property> = [];
        let editableList: Array<propertyConstants.Property> = [...propertyList];
        let visibleList: Array<propertyConstants.Property> = [...propertyList];

        if (modalType === null) {
            if (!isFormulaNotAvailable) {
                visibleList = [
                    propertyConstants.PROPERTY_NAME,
                    propertyConstants.PROPERTY_BASE_NAME,
                    propertyConstants.PROPERTY_STATUS,
                    propertyConstants.PROPERTY_FOR_NOMINAL_AMOUNT,
                    propertyConstants.PROPERTY_BASE_AMOUNT,
                    propertyConstants.PROPERTY_MORE_INFO,
                    propertyConstants.PROPERTY_MORE_INFO_INTERNAL,
                    propertyConstants.PROPERTY_MORE_INFO_PRINT,
                    propertyConstants.PROPERTY_UUID,
                    propertyConstants.PROPERTY_DATE_CREATED
                ];
            } else {
                visibleList = [
                    propertyConstants.PROPERTY_NAME,
                    propertyConstants.PROPERTY_BASE_NAME,
                    propertyConstants.PROPERTY_STATUS,
                    propertyConstants.PROPERTY_DATE_CREATED
                ];
            }
            editableList = [];
        }

        const orderList = [...visibleList];

        return generateFormulaHeadings(
            objectTypes.ZONE_FORMULA,
            this.props.productList,
            this.props.baseInProductList,
            orderList,
            visibleList,
            editableList,
            requiredList,
            alwaysVisibleList,
            this.props.colorColumnWidth
        );
    };

    generateJumpModalParams = (): any => {
        const activeColor = this.props.colorActiveList?.[0] || null;
        const activeProduct = this.props.productActiveList?.[0] || null;

        return {
            count: this.props.colorTotalCount,
            sortingAsc: this.props.colorSortingAsc,
            sortingCriterion: this.props.colorSortingCriterion,
            search: this.props.colorSearch,
            searchParams: this.props.colorSearchParams,
            offset: this.props.colorOffset,
            page: this.props.colorPage,
            rowCount: this.props.colorRowCount,
            baseInProductId: activeColor?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] || null,
            colorInFandeckId: activeColor?.[propertyConstants.PROPERTY_ID] || null,
            fandeckId: activeColor?.[propertyConstants.PROPERTY_FANDECK_ID] || null,
            formulaId: activeColor?.[propertyConstants.PROPERTY_FORMULA_ID] || null,
            productId: activeProduct?.[propertyConstants.PROPERTY_ID] || null
        };
    };

    getPropertiesBookmark = (activeFormula: Formula | null): JSX.Element => {
        return (
            <FormulaPropertiesBookmark
                menuScene={this.props.menuScene}
                tableConstant={this.props.formulaColorantTableConstant}
                headings={this.generateTableHeadings(
                    null,
                    activeFormula?.[propertyConstants.PROPERTY_NOT_AVAILABLE] ?? false
                )}
                activeFormula={activeFormula}
                columnOrder={this.props.formulaColorantColumnOrder}
                columnVisibility={this.props.formulaColorantColumnVisibility}
                columnWidth={this.props.formulaColorantColumnWidth}
                activeUnit={this.props.activeUnit}
                unitList={this.props.unitList}
                setOptions={this.props.setOptions}
            />
        );
    };

    getHistoryFormulaBookmark = (): JSX.Element | null => {
        if (this.props.colorActiveList.length) {
            return (
                <FormulaHistoryBookmark
                    menuScene={this.props.menuScene}
                    tableConstant={this.props.formulaColorantTableConstant}
                    list={this.props.colorActiveList[0][propertyConstants.PROPERTY_FORMULA_LIST]}
                    activeUnit={this.props.activeUnit}
                    unitList={this.props.unitList}
                    columnOrder={this.props.formulaColorantColumnOrder}
                    columnVisibility={this.props.formulaColorantColumnVisibility}
                    columnWidth={this.props.formulaColorantColumnWidth}
                    setOptions={this.props.setOptions}
                />
            );
        }

        return null;
    };

    getColorsBookmark = (): JSX.Element | null => {
        if (this.props.colorActiveList.length) {
            return (
                <FormulaColorsBookmark
                    menuScene={this.props.menuScene}
                    tableConstant={this.props.formulaColorantTableConstant}
                    list={this.props.colorActiveList[0][propertyConstants.PROPERTY_FORMULA_LIST]}
                    columnOrder={this.props.formulaColorantColumnOrder}
                    columnVisibility={this.props.formulaColorantColumnVisibility}
                    columnWidth={this.props.formulaColorantColumnWidth}
                />
            );
        }

        return null;
    };

    // TODO: Create new type for input?
    // TODO: generate inputs in different method
    getSceneFilterTable = (): JSX.Element | null => {
        const { tableColorWithFormulaInfo } = this.state;
        const inputs = [];

        const fandeckInput = {
            labelCaption: translate("fandeck.fandeck"),
            filterClassName: "scene-select scene-title-select",
            filterValue: this.props.fandeckActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || "",
            filterLoading: this.props.fandeckLoading,
            filterOptions: generalHelper.getOptionsForSelectbox(objectTypes.ZONE_COLOR, this.props.fandeckList),
            filterValueCallbackClick: (id: number): void => {
                this.props.setActive(
                    objectTypes.ZONE_FANDECK_IN_COLOR,
                    this.props.fandeckList.filter((item: Fandeck) => item[propertyConstants.PROPERTY_ID] === Number(id))
                );
            },
            filterValueCallbackChange: (value: string): void => {
                this.props.setSearch(objectTypes.ZONE_FANDECK_IN_COLOR, value, propertyConstants.PROPERTY_NAME);
            }
        };

        const productInput = {
            labelCaption: translate("product.product"),
            filterClassName: "scene-select scene-title-select",
            filterValue: this.props.productActiveList?.[0]?.[propertyConstants.PROPERTY_ID] || "",
            filterLoading: this.props.productLoading,
            filterOptions: generalHelper.getOptionsForSelectbox(objectTypes.ZONE_COLOR, this.props.productList),
            filterValueCallbackClick: (id: number): void => {
                this.props.setActive(
                    objectTypes.ZONE_PRODUCT_IN_COLOR,
                    (this.props.productList || []).filter(
                        (item: Product) => item[propertyConstants.PROPERTY_ID] === Number(id)
                    )
                );
            },
            filterValueCallbackChange: (value: string): void => {
                this.props.setSearch(objectTypes.ZONE_PRODUCT_IN_COLOR, value, propertyConstants.PROPERTY_NAME);
            }
        };

        inputs.push(fandeckInput, productInput);

        if (tableColorWithFormulaInfo) {
            return (
                <SceneFilterTable
                    title={translate("formula.formulas")}
                    tableConstant={this.props.colorWithFormulaInfoTableConstant}
                    objectType={objectTypes.ZONE_COLOR_WITH_FORMULA_INFO}
                    dataPrivileges={tableColorWithFormulaInfo}
                    loading={this.props.colorLoading}
                    allList={this.props.colorList}
                    activeList={this.props.colorActiveList}
                    columnOrder={this.props.colorColumnOrder}
                    columnVisibility={this.props.colorColumnVisibility}
                    columnWidth={this.props.colorColumnWidth}
                    offset={this.props.colorOffset}
                    page={this.props.colorPage}
                    rowCount={this.props.colorRowCount}
                    rowCountCustom={this.props.colorRowCountCustom}
                    search={this.props.colorSearch}
                    searchParams={this.props.colorSearchParams}
                    showFilterRow={this.props.colorShowFilterRow}
                    showGlobalSearch={this.props.colorShowGlobalSearch}
                    sortingAsc={this.props.colorSortingAsc}
                    sortingCriterion={this.props.colorSortingCriterion}
                    totalCount={this.props.colorTotalCount}
                    useLargoNames={false}
                    useTmcNames={this.props.optionUseTmcNames || false}
                    fandeckActiveList={this.props.fandeckActiveList}
                    inputs={inputs}
                />
            );
        }

        return null;
    };

    getSceneDetail = (): JSX.Element => {
        let bookmarks: Array<Bookmark> = [];

        if (this.props.colorActiveList.length > 0) {
            const activeColor = this.props.colorActiveList[0];
            const activeFormula =
                activeColor[propertyConstants.PROPERTY_FORMULA_LIST].find(
                    (item: any) =>
                        item[propertyConstants.PROPERTY_ID] === activeColor[propertyConstants.PROPERTY_FORMULA_ID] &&
                        item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ===
                            activeColor[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]
                ) ?? null;

            bookmarks = [
                new Bookmark(
                    bookmarkConstants.BOOKMARK_PROPERTIES,
                    "",
                    translate("general.properties"),
                    this.generateTableHeadings(),
                    this.state.tableColorWithFormulaInfo, // TODO own privilege
                    this.props.formulasLoading && activeFormula === null,
                    this.getPropertiesBookmark(activeFormula)
                ),
                new Bookmark(
                    bookmarkConstants.BOOKMARK_FORMULA_HISTORY,
                    "",
                    translate("general.history"),
                    [],
                    this.state.tableColorWithFormulaInfo, // TODO own privilege
                    this.props.formulasLoading,
                    this.getHistoryFormulaBookmark()
                )
                // new Bookmark(bookmarkConstants.BOOKMARK_COLORS, "", translate("color.colors"), [], this.getColorsBookmark()),
            ];
        }

        const modalProperties: ModalProperties = {};

        return (
            <SceneDetail
                tableConstant={this.props.colorWithFormulaInfoTableDetailConstant}
                objectType={objectTypes.ZONE_FORMULA}
                dataPrivileges={this.state.tableColorWithFormulaInfo}
                activeBookmark={this.props.activeBookmark}
                bookmarks={bookmarks}
                activeList={this.props.colorActiveList}
                fandeckList={this.props.fandeckActiveList}
                productList={this.props.productActiveList}
                modalProperties={modalProperties}
                setActiveBookmarkCallback={(key: string): void => {
                    this.props.setActiveBookmark(key);
                }}
                callback={(list: Array<Formula>, value: number | null, otherValue: number | null): void => {
                    this.props.updateFormula(objectTypes.ZONE_FORMULA, list, value, otherValue);
                }}
            />
        );
    };

    render(): JSX.Element {
        return (
            <ResizingLayoutSceneContainer
                tables={
                    [this.getSceneFilterTable(), this.getSceneDetail()].filter(
                        (item) => item !== null
                    ) as Array<JSX.Element>
                }
                layout={this.props.sceneLayout}
                sceneTableSize={this.props.formulaSceneTableSize}
                dataPrivileges={this.state.tableColorWithFormulaInfo}
                sceneConstant={menuConstants.PAGE_ZONE_FORMULAS}
            />
        );
    }
}
