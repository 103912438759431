import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { HistoricalAction } from "../types/historicalAction";

export type ActionHistoryState = Readonly<{
    list: Array<HistoricalAction>;
    activeList: Array<HistoricalAction>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
    dateFrom: Date | null;
    dateTo: Date | null;
}>;

const initialState: ActionHistoryState = {
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE,
    dateFrom: null,
    dateTo: null
};

export default function actionsHistory(
    state: ActionHistoryState = initialState,
    action: Record<string, any>
): ActionHistoryState {
    switch (action.type) {
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.USER_ACTION_HISTORY) {
                return {
                    ...state,
                    list: action.payload.list,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.USER_ACTION_HISTORY) {
                const newActiveList = generalHelper.updateActiveLists(action.payload.items, state.list);

                return {
                    ...state,
                    activeList: newActiveList
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.USER_ACTION_HISTORY) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_DATE: {
            if (action.payload.objectType === objectTypes.USER_ACTION_HISTORY) {
                switch (action.payload.property) {
                    case propertyConstants.PROPERTY_DATE_FROM:
                        return {
                            ...state,
                            dateFrom: action.payload.date
                        };
                    case propertyConstants.PROPERTY_DATE_TO:
                        return {
                            ...state,
                            dateTo: action.payload.date
                        };
                    default:
                        return state;
                }
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.USER_ACTION_HISTORY) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.DATA_ROLLBACK:
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
