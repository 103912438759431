import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";
import { User } from "../types/user";

export interface Login {
    type: typeof actionConstants.LOGIN;
    payload: {
        login: string;
        password: string;
    };
}

export function login(username: string, password: string): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_LOGIN,
            params: {
                username,
                password
            }
        },
        method: methods.METHOD_LOGIN
    };
}

export interface LoginSuccess {
    type: typeof actionConstants.LOGIN_SUCCESS;
    payload: {
        loggedUser: User;
    };
}

export function loginSuccess(loggedUser: User): LoginSuccess {
    return {
        type: actionConstants.LOGIN_SUCCESS,
        payload: {
            loggedUser
        }
    };
}

export interface LoginFailure {
    type: typeof actionConstants.LOGIN_FAILURE;
    payload: {
        wrongLogin: boolean;
    };
}

export function loginFailure(wrongLogin: boolean): LoginFailure {
    return {
        type: actionConstants.LOGIN_FAILURE,
        payload: {
            wrongLogin
        }
    };
}

export function loginGetLoggedUser(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_LOGGED_USER
        },
        method: methods.METHOD_GET_LOGGED_USER
    };
}

export function loginIsUserLoggedIn(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_IS_USER_LOGGED_IN
        },
        method: methods.METHOD_IS_USER_LOGGED_IN
    };
}

export interface Logout {
    type: typeof actionConstants.LOGOUT;
}

export function logout(): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_LOGOUT
        },
        method: methods.METHOD_LOGOUT
    };
}

export interface LogoutSuccess {
    type: typeof actionConstants.LOGOUT_SUCCESS;
}

export function logoutSuccess(): LogoutSuccess {
    return {
        type: actionConstants.LOGOUT_SUCCESS
    };
}
