import * as colorHelper from "../../../../helpers/colorHelper";
import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as generalHelper from "../../../../helpers/generalHelper";
import * as importExportConstants from "../../../../constants/entityConstants/importExportConstants";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as tableConstants from "../../../../constants/tableConstants";

import React, { Component } from "react";
import { AppState } from "../../../../reducers";
import { Dispatch } from "redux";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";

import { connect } from "react-redux";

import imgError from "../../../../resources/img/icons/error-circle.svg";
import imgLoader2 from "../../../../resources/img/mini-loader.svg";
import imgSuccess from "../../../../resources/img/icons/success.svg";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    heading: TableHeading;
    item: any;
    callbackMouseEnter: (event: any, value: string) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableCellStatus extends Component<Props> {
    getIcon = (option: any): JSX.Element | null => {
        if (option) {
            if (
                importExportConstants.EXPORT_STATUSES_PROGRESS.includes(option[propertyConstants.PROPERTY_ID]) ||
                importExportConstants.IMPORT_STATUSES_PROGRESS.includes(option[propertyConstants.PROPERTY_ID])
            ) {
                return <ReactSVG className={"svg-icon mini-loader"} src={imgLoader2} />;
            }

            // TODO unify id and key
            if (
                importExportConstants.EXPORT_STATUSES_SUCCESS.includes(option[propertyConstants.PROPERTY_ID]) ||
                importExportConstants.IMPORT_STATUSES_SUCCESS.includes(option[propertyConstants.PROPERTY_KEY])
            ) {
                return <ReactSVG className={"svg-icon success"} src={imgSuccess} />;
            }

            // TODO unify id and key
            if (
                importExportConstants.EXPORT_STATUSES_FAILURE.includes(option[propertyConstants.PROPERTY_ID]) ||
                importExportConstants.IMPORT_STATUSES_FAILURE.includes(option[propertyConstants.PROPERTY_KEY])
            ) {
                return <ReactSVG className={"svg-icon error"} src={imgError} />;
            }
        }

        return null;
    };

    getProgress = (option: any): string => {
        const { item } = this.props;

        const progress = item?.[propertyConstants.PROPERTY_PROGRESS]
            ? ` (${formattingHelper.formatPercent(item[propertyConstants.PROPERTY_PROGRESS])})`
            : "";

        if (
            option &&
            (importExportConstants.EXPORT_STATUSES_PROGRESS.includes(option[propertyConstants.PROPERTY_ID]) ||
                importExportConstants.IMPORT_STATUSES_PROGRESS.includes(option[propertyConstants.PROPERTY_KEY]))
        ) {
            return progress;
        }

        return "";
    };

    getImportExportStatus = (property: propertyConstants.Property, value: any): JSX.Element => {
        const option = value
            ? generalHelper.getObjectById(this.props.heading[tableConstants.TABLE_HEADING_ENUM], value)
            : null;
        const content = `${option?.[propertyConstants.PROPERTY_NAME] || ""}${this.getProgress(option)}`;

        if (option === null) {
            return (
                <div
                    className={`td ${property}`}
                    key={property}
                    onMouseLeave={(): void => this.props.callbackMouseLeave()}
                >
                    {this.getIcon(value)}
                    <span
                        data-tip
                        data-for={"table-cell-tooltip"}
                        onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, content)}
                    >
                        {content}
                    </span>
                </div>
            );
        }
        return (
            <div className={`td ${property}`} key={property} onMouseLeave={(): void => this.props.callbackMouseLeave()}>
                {this.getIcon(option)}
                <span
                    data-tip
                    data-for={"table-cell-tooltip"}
                    onMouseEnter={(event: any): void => this.props.callbackMouseEnter(event, content)}
                >
                    {content}
                </span>
            </div>
        );
    };

    // Color methods
    getFormulasCount = (formulasCount: number): JSX.Element | null => {
        if (formulasCount > 1) {
            return <div className="icon-notification">{formulasCount}</div>;
        }
        return null;
    };

    getFormulaStatus = (property: propertyConstants.Property, value: any): JSX.Element => {
        return (
            <div key={property} className={`td ${property}`}>
                <div className="icon-container">
                    {colorHelper.getFormulaStatus(value[propertyConstants.PROPERTY_STATUS], false, false)}
                    {this.getFormulasCount(value[propertyConstants.PROPERTY_FORMULAS_COUNT])}
                </div>
            </div>
        );
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const value = item && item[property] !== undefined ? item[property] : null;

        if (property === propertyConstants.PROPERTY_FORMULAS_STATUS && value) {
            return this.getFormulaStatus(property, value);
        } else {
            return this.getImportExportStatus(property, value);
        }
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableCellStatusContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellStatus);
