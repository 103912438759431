import * as menuConstants from "../../../constants/menuConstants";
import * as objectTypes from "../../../constants/objectTypes";

import React, { FunctionComponent, useEffect, useState } from "react";

import { DataItem } from "../../../types/dataItem";
import { MasterRedlikePrivilegeTable } from "./tables/MasterRedlikePrivilegeTable";
import { MasterRedlikeUserGroupTable } from "./tables/MasterRedlikeUserGroupTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/master/MasterRedlikePrivilegeSceneContainer";
import { ResizingLayoutSceneContainer } from "../ResizingLayoutScene";

type Props = PropsType;

export const MasterRedlikePrivilegeScene: FunctionComponent<Props> = ({ ...props }) => {
    // Table user groups
    const [tableUserGroups, setTableUserGroups] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.userGroupTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTableUserGroups(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.userGroupTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.userGroupTableConstant]);

    // Table privileges
    const [tablePrivileges, setTablePrivileges] = useState<MenuItem | DataItem | OptionItem | PrivilegeItem | null>(
        (props.menuScene
            ? props.menuScene.items.find(
                  (item: MenuItem | DataItem | OptionItem | PrivilegeItem) => item.key === props.privilegeTableConstant
              )
            : null) ?? null
    );
    useEffect(() => {
        setTablePrivileges(
            (props.menuScene
                ? props.menuScene.items.find(
                      (item: MenuItem | DataItem | OptionItem | PrivilegeItem) =>
                          item.key === props.privilegeTableConstant
                  )
                : null) ?? null
        );
    }, [props.menuScene, props.privilegeTableConstant]);

    const getUserGroupsTable = (): JSX.Element | null => {
        if (tableUserGroups) {
            return (
                <MasterRedlikeUserGroupTable
                    tableConstant={props.userGroupTableConstant}
                    objectType={objectTypes.MASTER_REDLIKE_USER_GROUP}
                    dataPrivileges={tableUserGroups}
                    loading={props.userGroupLoading}
                    buttonLoading={props.userGroupButtonLoading}
                    allList={props.userGroupList}
                    activeList={props.userGroupActiveList}
                    columnOrder={props.userGroupColumnOrder}
                    columnVisibility={props.userGroupColumnVisibility}
                    columnWidth={props.userGroupColumnWidth}
                    search={props.userGroupSearch}
                    searchParams={props.userGroupSearchParams}
                    sortingAsc={props.userGroupSortingAsc}
                    sortingCriterion={props.userGroupSortingCriterion}
                    totalCount={props.userGroupTotalCount}
                    custom={props.custom}
                />
            );
        }

        return null;
    };

    const getPrivilegesTable = (): JSX.Element | null => {
        if (tablePrivileges) {
            return (
                <MasterRedlikePrivilegeTable
                    tableConstant={props.privilegeTableConstant}
                    objectType={objectTypes.MASTER_REDLIKE_PRIVILEGE}
                    dataPrivileges={tablePrivileges}
                    loading={props.privilegeLoading}
                    buttonLoading={props.privilegeButtonLoading}
                    allList={props.privilegeList}
                    activeList={props.privilegeActiveList}
                    columnOrder={props.privilegeColumnOrder}
                    columnVisibility={props.privilegeColumnVisibility}
                    columnWidth={props.privilegeColumnWidth}
                    offset={props.privilegeOffset}
                    page={props.privilegePage}
                    rowCount={props.privilegeRowCount}
                    rowCountCustom={props.privilegeRowCountCustom}
                    search={props.privilegeSearch}
                    searchParams={props.privilegeSearchParams}
                    showOnlyNonDefaultValues={props.privilegeShowOnlyNonDefaultValues}
                    showGlobalSearch={props.privilegeShowGlobalSearch}
                    sortingAsc={props.privilegeSortingAsc}
                    sortingCriterion={props.privilegeSortingCriterion}
                    totalCount={props.privilegeTotalCount}
                    custom={props.custom}
                />
            );
        }

        return null;
    };

    return (
        <ResizingLayoutSceneContainer
            tables={[getUserGroupsTable(), getPrivilegesTable()].filter((item) => item !== null) as Array<JSX.Element>}
            layout={props.sceneLayout}
            sceneTableSize={props.privilegeSceneTableSize}
            dataPrivileges={tableUserGroups}
            sceneConstant={menuConstants.PAGE_MASTER_REDLIKE_PRIVILEGES}
        />
    );
};
