import * as formattingHelper from "../../../helpers/formattingHelper";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as validationHelper from "../../../helpers/validationHelper";

import React, { Component } from "react";
import { Color } from "../../../types/color";
import { ColorData } from "../../../types/colorData";
import { Line } from "react-chartjs-2";
import { ReactSVG } from "react-svg";
import { ReflectanceData } from "../../../types/reflectanceData";

import imgInfo from "../../../resources/img/icons/error.svg";
import { t as translate } from "react-i18nify";

type Props = {
    activeColor: Color;
};

type State = {
    height: number | null;
    width: number | null;
    init: boolean;
};

export class LineGraph extends Component<Props> {
    state: State = {
        height: null,
        width: null,
        init: false
    };

    componentDidMount(): void {
        this.setState({ init: true });
    }

    getColorScheme = (activeColorData: ColorData): Array<any> => {
        const gradientArray: Array<any> = [];

        const darkPurplePart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 360 && item[propertyConstants.PROPERTY_WAVELENGTH] < 390
        );

        if (darkPurplePart.length !== 0) {
            gradientArray.push({ color: "107,0,122", length: darkPurplePart.length }); // #6b007a
        }

        const purplePart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 390 && item[propertyConstants.PROPERTY_WAVELENGTH] < 430
        );

        if (purplePart.length !== 0) {
            gradientArray.push({ color: "179,0,204", length: purplePart.length }); // #B300CC
        }

        const bluePart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 430 && item[propertyConstants.PROPERTY_WAVELENGTH] < 460
        );

        if (bluePart.length !== 0) {
            gradientArray.push({ color: "0,12,207", length: bluePart.length }); // #000CCF
        }

        const cyanPart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 460 && item[propertyConstants.PROPERTY_WAVELENGTH] < 520
        );

        if (cyanPart.length !== 0) {
            gradientArray.push({ color: "2,205,246", length: cyanPart.length }); // #02CDF6
        }
        const greenPart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 520 && item[propertyConstants.PROPERTY_WAVELENGTH] < 600
        );

        if (greenPart.length !== 0) {
            gradientArray.push({ color: "57,245,10", length: greenPart.length }); // #39F50A
        }

        const yellowPart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 600 && item[propertyConstants.PROPERTY_WAVELENGTH] < 620
        );

        if (yellowPart.length !== 0) {
            gradientArray.push({ color: "242,254,0", length: yellowPart.length }); // #F2FE00
        }

        const orangePart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 620 && item[propertyConstants.PROPERTY_WAVELENGTH] < 640
        );

        if (orangePart.length !== 0) {
            gradientArray.push({ color: "225,165,0", length: orangePart.length }); // #FFA500
        }

        const redPart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 640 && item[propertyConstants.PROPERTY_WAVELENGTH] < 720
        );

        if (redPart.length !== 0) {
            gradientArray.push({ color: "254,2,0", length: redPart.length }); // #FE0200
        }

        const darkRedPart = activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA].filter(
            (item: ReflectanceData) =>
                item[propertyConstants.PROPERTY_WAVELENGTH] >= 720 && item[propertyConstants.PROPERTY_WAVELENGTH] <= 780
        );

        if (darkRedPart.length !== 0) {
            gradientArray.push({ color: "125,0,0", length: darkRedPart.length }); // #7D0000
        }

        return gradientArray;
    };

    getGraphOptions = (): any => {
        const options = {
            legend: {
                display: false
            },
            maintainAspectRatio: true,
            responsive: true,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            max: 100
                        }
                    }
                ]
            },
            onResize: function (chart: any, size: any) {
                updateGradient(size);
            }
        };

        const updateGradient = (size: any) => {
            this.setState({
                height: size.height,
                width: size.width
            });
        };

        return options;
    };

    getGraphData = (activeColorData: ColorData): any => {
        const wavelengthList: Array<number> = [];
        const reflectanceList: Array<number | null> = [];
        const gradientArray = this.getColorScheme(activeColorData);

        for (const item of activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA]) {
            const formatPercent = Number(
                formattingHelper.formatPercentWithoutSign(
                    formattingHelper.formatPercent(item[propertyConstants.PROPERTY_VALUE])
                ) || 0
            );
            wavelengthList.push(item[propertyConstants.PROPERTY_WAVELENGTH]);
            reflectanceList.push(formatPercent);
        }

        const data = (canvas: any) => {
            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
            const backgroundGradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

            let pom = 0;
            for (const color of gradientArray) {
                const size = 1 / reflectanceList.length;
                if (gradientArray.indexOf(color) === 0) {
                    gradient.addColorStop(0, "rgb(" + color.color + ")");
                    backgroundGradient.addColorStop(0, "rgba(" + color.color + ", 0.8)");
                    pom += size * color.length;
                } else if (gradientArray.indexOf(color) === gradientArray.length - 1) {
                    gradient.addColorStop(1, "rgb(" + color.color + ")");
                    backgroundGradient.addColorStop(1, "rgba(" + color.color + ", 0.8)");
                } else {
                    gradient.addColorStop(pom + size * color.length, "rgb(" + color.color + ")");
                    backgroundGradient.addColorStop(pom + size * color.length, "rgba(" + color.color + ", 0.8)");
                    pom += size * color.length;
                }
            }

            return {
                labels: wavelengthList,
                datasets: [
                    {
                        backgroundColor: backgroundGradient,
                        borderColor: "#38393b",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderWidth: 1.5,
                        data: reflectanceList,
                        fill: "start",
                        lineTension: 0.1,
                        pointBackgroundColor: gradient,
                        pointBorderColor: gradient,
                        pointBorderWidth: 5,
                        pointHitRadius: 10,
                        pointHoverBackgroundColor: gradient,
                        pointHoverBorderColor: "#38393b",
                        pointHoverBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointRadius: 0
                    }
                ]
            };
        };

        return data;
    };

    render(): JSX.Element | null {
        const { activeColor } = this.props;

        const activeColorData =
            activeColor[propertyConstants.PROPERTY_DATA].find(
                (item: ColorData) =>
                    item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                    activeColor[propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
            ) || null;

        if (
            activeColorData &&
            validationHelper.areReflectanceDataValid(activeColorData[propertyConstants.PROPERTY_REFLECTANCE_DATA])
        ) {
            return (
                <div className="card">
                    <div className="card-content">
                        <Line data={this.getGraphData(activeColorData)} options={this.getGraphOptions()} />
                    </div>
                </div>
            );
        }

        return (
            <div className="card no-data">
                <div className="card-content">
                    <div className="no-preview">{translate("spectro.noGraphPreview")}</div>
                </div>
                <div className="card-title">
                    <ReactSVG className="svg-icon" src={imgInfo} />
                    {translate("general.missingData")}
                </div>
            </div>
        );
    }
}
