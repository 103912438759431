import * as inputConstants from "../../../constants/inputConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";
import { ModalLabelInputButtonContainer } from "./ModalLabelInputButton";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    modalId: number;
    objectType: objectTypes.ObjectType;
    properties: Array<propertyConstants.Property>;
    labelProperty: propertyConstants.Property;
    headings: Array<TableHeading>;
    object: any;
    callbackChange: (property: propertyConstants.Property, value: any) => any;
    callbackBlur: (property: propertyConstants.Property, value: any) => any;
    callbackClick: (heading: TableHeading, property: propertyConstants.Property, editable: boolean) => any;
};

export class ModalMultipleInputsRow extends Component<Props> {
    getLabel = (): JSX.Element => {
        const labelHeading =
            this.props.headings?.find((item) => item[tableConstants.TABLE_HEADING_NAME] === this.props.labelProperty) ||
            null;

        return (
            <div className="form-label">
                <label>
                    {labelHeading?.[tableConstants.TABLE_HEADING_TRANSLATION] || ""}
                    {labelHeading?.[tableConstants.TABLE_HEADING_REQUIRED] ? "*" : ""}
                </label>
            </div>
        );
    };

    getInput = (property: propertyConstants.Property): JSX.Element | null => {
        const { object } = this.props;
        const heading =
            this.props.headings?.find((item) => item[tableConstants.TABLE_HEADING_NAME] === property) || null;

        // TODO inputClassName, inputType (use ModalItemRow switch values)
        if (heading) {
            const type = heading[tableConstants.TABLE_HEADING_TYPE];
            const editable = heading[tableConstants.TABLE_HEADING_EDITING];
            const required = heading[tableConstants.TABLE_HEADING_REQUIRED];
            const value = object && object[property] !== null && object[property] !== undefined ? object[property] : "";

            switch (type) {
                case tableConstants.TABLE_TYPE_DATE_PICKER:
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={this.props.modalId}
                            type={type}
                            objectType={this.props.objectType}
                            editable={editable}
                            required={required}
                            labelCaption={""}
                            inputClassName={"type-input"}
                            inputType={inputConstants.PARAMETER_TYPE_DATE_PICKER}
                            inputName={property}
                            inputValue={value}
                            inputCallbackChange={(value: string | null) => this.props.callbackChange(property, value)}
                        />
                    );
                default:
                    return (
                        <ModalLabelInputButtonContainer
                            modalId={this.props.modalId}
                            type={type}
                            objectType={this.props.objectType}
                            editable={editable}
                            required={required}
                            labelCaption={""}
                            inputClassName={"type-input"}
                            inputType={inputConstants.PARAMETER_TYPE_TEXT_INPUT}
                            inputName={property}
                            inputValue={value}
                            inputCallbackChange={(value: any) => this.props.callbackChange(property, value)}
                            inputCallbackBlur={(value: any) => this.props.callbackBlur(property, value)}
                        />
                    );
            }
        }

        return null;
    };

    getMultipleInputRowClassName = (item: any): string => {
        let className = "multiple-row-inputs";

        if (propertyConstants.COLOR_COMPONENTS.includes(item)) {
            className += " color-components-inputs";
        }

        return className;
    };

    generateInputs = (): Array<JSX.Element> => {
        const inputList: Array<JSX.Element> = [];

        this.props.properties.forEach((item: any) => {
            const input = this.getInput(item);

            if (input) {
                inputList.push(
                    <div key={item} className={this.getMultipleInputRowClassName(item)}>
                        {input}
                    </div>
                );
            }
        });

        return inputList;
    };

    render(): JSX.Element {
        return (
            <React.Fragment>
                {this.getLabel()}
                <div className="form-input flex">{this.generateInputs()}</div>
            </React.Fragment>
        );
    }
}
