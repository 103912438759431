import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { UserGroup, mapUserGroup } from "../../types/userGroup";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_USER_GROUPS_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data.data) {
                userGroup = mapUserGroup(item, state.login.loggedUser);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.USER_GROUP, userGroupList)];
        }
        case methods.METHOD_GET_ALL_USER_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userGroupList: Array<UserGroup> = [];
            let userGroup: UserGroup | null;

            for (const item of response.data.data) {
                userGroup = mapUserGroup(item, state.login.loggedUser);

                if (userGroup) {
                    userGroupList.push(userGroup);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.USER_GROUP, userGroupList, response.data.count)];
        }
        case methods.METHOD_ADD_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.USER_GROUP),
                universalObjectActions.getAllItems(objectTypes.USER_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_USER_GROUPS_ADD)
            ];
        }
        case methods.METHOD_EDIT_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.USER_GROUP),
                universalObjectActions.getAllItems(objectTypes.USER_GROUP),
                navigationActions.navigationCloseModal(modalTypes.MODAL_USER_GROUPS_EDIT)
            ];
        }
        case methods.METHOD_DELETE_USER_GROUP: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.USER_GROUP),
                universalObjectActions.getAllItems(objectTypes.USER_GROUP),
                universalObjectActions.setActiveItems(objectTypes.USER_GROUP, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
