import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypes from "../../../constants/objectTypes";

import React, { Component } from "react";

import { DataItem } from "../../../types/dataItem";
import { ImportTaskTable } from "./tables/ImportTaskTable";
import { MenuItem } from "../../../types/menu";
import { OptionItem } from "../../../types/optionItem";
import { PrivilegeItem } from "../../../types/privilegeItem";
import { PropsType } from "../../../containers/scenes/data/ImportSceneContainer";
import { TableAddButtonContainer } from "../../general/table/buttons/TableAddButton";

import { t as translate } from "react-i18nify";

type Props = PropsType;

type State = {
    tableImportData: MenuItem | DataItem | OptionItem | PrivilegeItem | null;
};

export class ImportScene extends Component<Props, State> {
    state: State = {
        tableImportData:
            (this.props.menuScene
                ? this.props.menuScene.items.find((item) => item.key === this.props.importDataConstant)
                : null) || null
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (JSON.stringify(this.props.menuScene) !== JSON.stringify(prevProps.menuScene)) {
            this.setState({
                tableImportData:
                    (this.props.menuScene
                        ? this.props.menuScene.items.find((item) => item.key === this.props.importDataConstant)
                        : null) || null
            });
        }
    }

    getNewImportButton = (): JSX.Element => {
        return (
            <TableAddButtonContainer
                dataPrivileges={this.state.tableImportData}
                objectType={objectTypes.IMPORT_TASK}
                modalType={modalTypes.MODAL_IMPORT_TASK_ADD}
                modalParams={{
                    type: modalTypes.ADD_MODAL,
                    objectType: objectTypes.IMPORT_TASK,
                    title: translate("import.importData")
                }}
                disabled={this.props.importLoading}
            />
        );
    };

    getImportDataTable = (): JSX.Element | null => {
        const { importDataConstant } = this.props;
        const { tableImportData } = this.state;

        if (tableImportData) {
            return (
                <div className="layout-item item-scene-row check-table" key={importDataConstant}>
                    <ImportTaskTable
                        tableConstant={importDataConstant}
                        objectType={objectTypes.IMPORT_TASK}
                        dataPrivileges={tableImportData}
                        privileges={this.props.privileges}
                        loading={this.props.importLoading}
                        databaseSystemZoneList={this.props.databaseSystemZoneList}
                        importStatusList={this.props.importStatusList}
                        importTypeList={this.props.importTypeList}
                        allList={this.props.importList}
                        activeList={this.props.importActiveList}
                        columnOrder={this.props.importColumnOrder}
                        columnVisibility={this.props.importColumnVisibility}
                        columnWidth={this.props.importColumnWidth}
                        offset={this.props.importOffset}
                        page={this.props.importPage}
                        rowCount={this.props.importRowCount}
                        rowCountCustom={this.props.importRowCountCustom}
                        search={this.props.importSearch}
                        searchParams={this.props.importSearchParams}
                        showDeletedValues={this.props.importShowDeletedValues}
                        showFilterRow={this.props.importShowFilterRow}
                        showGlobalSearch={this.props.importShowGlobalSearch}
                        showHiddenValues={this.props.importShowHiddenValues}
                        sortingAsc={this.props.importSortingAsc}
                        sortingCriterion={this.props.importSortingCriterion}
                        totalCount={this.props.importTotalCount}
                    />
                </div>
            );
        }

        return null;
    };

    render(): JSX.Element | null {
        return (
            <div className="content-layout">
                <div className="layout-item">
                    <div className="height-100">
                        <div className="main-title">{translate("import.importData")}</div>
                        <div className="scene-btn-row">{this.getNewImportButton()}</div>
                        {this.getImportDataTable()}
                    </div>
                </div>
            </div>
        );
    }
}
