import * as propertyConstants from "../constants/propertyConstants";
import * as validationConstants from "../constants/validationConstants";

export class Validation {
    name: propertyConstants.Property;
    bookmarkKey: string | null;
    type: validationConstants.ValidationType;
    caption: string;

    constructor(
        name: propertyConstants.Property,
        bookmarkKey: string | null,
        type: validationConstants.ValidationType,
        caption: string
    ) {
        this.name = name;
        this.bookmarkKey = bookmarkKey;
        this.type = type;
        this.caption = caption;
    }
}
