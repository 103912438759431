import * as colorHelper from "../../helpers/colorHelper";
import * as formulaConstants from "../../constants/entityConstants/formulaConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as inputConstants from "../../constants/inputConstants";
import * as objectTypes from "../../constants/objectTypes";
import * as optionConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";

import { Color, updateFormulaList } from "../../types/color";
import React, { Component } from "react";

import { Formula } from "../../types/formula";
import { FormulaColorantTable } from "../general/table/FormulaColorantTable";
import { ModalLabelInputButtonContainer } from "../general/modal/ModalLabelInputButton";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { Product } from "../../types/product";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../types/tableHeading";
import { TableJumpButtonContainer } from "../general/table/buttons/TableJumpButton";
import { TextPreview } from "../general/scene/TextPreview";
import { Unit } from "../../types/unit";

import imgLoader from "../../resources/img/mini-loader.svg";

import { t as translate } from "react-i18nify";

type Props = {
    tableConstant: string;
    dataPrivileges: any;
    headings: Array<TableHeading>;
    colorActiveList: Array<Color>;
    productList: Array<Product>;
    productActiveList: Array<Product>;
    formulaList: Array<Formula>;
    formulasLoading: boolean;
    unitList: Array<Unit>;
    activeUnit: Unit | null;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    count: number | null;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    setActive(objectType: objectTypes.ObjectType, items: Array<any>): any;
    setOptions(options: Array<any>, locked: boolean): any;
    setSearch(objectType: objectTypes.ObjectType, search: string, column?: string): any;
};

type State = {
    colorObjectType: objectTypes.ObjectType | null;
    formulaObjectType: objectTypes.ObjectType | null;
    formulaColorantObjectType: objectTypes.ObjectType | null;
    productObjectType: objectTypes.ObjectType | null;
};

export class ColorFormulaBookmark extends Component<Props, State> {
    state: State = {
        colorObjectType: colorHelper.getColorObjectType(formulaConstants.COLOR, this.props.tableConstant),
        formulaObjectType: colorHelper.getColorObjectType(formulaConstants.FORMULA, this.props.tableConstant),
        formulaColorantObjectType: colorHelper.getColorObjectType(
            formulaConstants.FORMULA_COLORANT,
            this.props.tableConstant
        ),
        productObjectType: colorHelper.getColorObjectType(formulaConstants.PRODUCT_IN_COLOR, this.props.tableConstant)
    };
    handleBaseChange = (value: string): any => {
        const baseId = value !== "" && value !== null && !isNaN(Number(value)) ? Number(value) : null;
        const oldColor = this.props.colorActiveList[0];
        const formulaList = oldColor?.[propertyConstants.PROPERTY_FORMULA_LIST] || [];
        const formula =
            formulaList.find(
                (item) =>
                    item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] === baseId &&
                    [
                        formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED,
                        formulaConstants.FORMULA_STATUS_ACCEPTED
                    ].includes(item[propertyConstants.PROPERTY_STATUS])
            ) || null;

        const color = updateFormulaList(
            oldColor,
            formulaList,
            formula?.[propertyConstants.PROPERTY_ID] ?? null,
            baseId
        );

        if (this.state.formulaObjectType !== null) {
            this.props.setActive(this.state.formulaObjectType, [color]);
        }
    };

    getEmptyCaption = (
        productLength: number,
        productId: number | null,
        baseLength: number,
        baseId: number | null,
        activeFormula: Formula | null
    ): JSX.Element | null => {
        if (this.props.formulasLoading) {
            return (
                <div className="row no-data loading">
                    <ReactSVG src={imgLoader} className="svg-icon" />
                    {translate("base.loadBases")}
                </div>
            );
        }
        if (!productLength) {
            return <div className="row no-data">{translate("product.noProductAvailable")}</div>;
        }

        if (productId === null) {
            return <div className="row no-data">[{translate("product.selectProduct")}]</div>;
        }

        if (!baseLength) {
            return <div className="row no-data">[{translate("base.noBaseAvailable")}]</div>;
        }

        if (baseId === null) {
            return <div className="row no-data">[{translate("base.selectBase")}]</div>;
        }

        if (
            activeFormula === null ||
            (activeFormula?.[propertyConstants.PROPERTY_STATUS] !== formulaConstants.FORMULA_STATUS_ACCEPTED_VERIFIED &&
                activeFormula?.[propertyConstants.PROPERTY_STATUS] !== formulaConstants.FORMULA_STATUS_ACCEPTED)
        ) {
            return <div className="row no-data">[{translate("formula.noValidFormula")}]</div>;
        }

        return null;
    };

    getBaseSelectOptions = (): Array<{ key: string; value: string }> => {
        const options: Array<{ key: string; value: string }> = [];

        for (const item of this.props.formulaList) {
            if (options.every((option) => option.value !== item[propertyConstants.PROPERTY_BASE_NAME])) {
                options.push({
                    key: item[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID]?.toString() || "",
                    value: item[propertyConstants.PROPERTY_BASE_NAME] ?? ""
                });
            }
        }

        return options;
    };

    getJumpButton = (productId: number | null, baseId: number | null, formulaId: number | null): JSX.Element | null => {
        const color = this.props.colorActiveList[0] || null;
        const jumpModalParams = {
            count: this.props.count,
            offset: this.props.offset,
            page: this.props.page,
            search: this.props.search,
            searchParams: this.props.searchParams,
            sortingAsc: this.props.sortingAsc,
            sortingCriterion: this.props.sortingCriterion,
            rowCount: this.props.rowCount,
            baseInProductId: baseId,
            colorInFandeckId: color?.[propertyConstants.PROPERTY_ID] ?? null,
            fandeckId: color?.[propertyConstants.PROPERTY_FANDECK_ID] ?? null,
            formulaId: formulaId,
            productId: productId
        };

        if (this.state.colorObjectType === null) {
            return null;
        }

        return (
            <div className="row formula-button-row">
                <TableJumpButtonContainer objectType={this.state.colorObjectType} modalParams={jumpModalParams} />
            </div>
        );
    };

    getFormulaFilters = (productId: number | null, baseId: number | null): JSX.Element | null => {
        const { colorObjectType, productObjectType } = this.state;
        const className = this.props.formulaList.length ? "row" : "row disabled";

        if (!colorObjectType || !productObjectType) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="row">
                    <ModalLabelInputButtonContainer
                        className={"scene-select"}
                        editable={true}
                        required={true}
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={colorObjectType}
                        labelCaption={translate("product.product")}
                        inputClassName={"button-select"}
                        inputType={inputConstants.PARAMETER_TYPE_FILTER_SELECT_BOX}
                        inputName={propertyConstants.PROPERTY_PRODUCT_ID}
                        inputValue={productId ?? ""}
                        inputOptions={generalHelper.getOptionsForSelectbox(colorObjectType, this.props.productList)}
                        inputAutoselect={true}
                        inputCallbackClick={(value: number): void =>
                            this.props.setActive(
                                productObjectType,
                                (this.props.productList || []).filter(
                                    (item: Product) => item[propertyConstants.PROPERTY_ID] === Number(value)
                                )
                            )
                        }
                        inputCallbackChange={(value: string): void =>
                            this.props.setSearch(productObjectType, value, propertyConstants.PROPERTY_NAME)
                        }
                    />
                </div>
                <div className={className}>
                    <ModalLabelInputButtonContainer
                        className={"scene-select"}
                        editable={true}
                        required={true}
                        type={tableConstants.TABLE_TYPE_SELECT}
                        objectType={colorObjectType}
                        labelCaption={translate("base.base")}
                        inputClassName={"button-select"}
                        inputType={inputConstants.PARAMETER_TYPE_SELECT_BOX}
                        inputName={propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID}
                        inputValue={baseId ?? ""}
                        inputOptions={this.getBaseSelectOptions()}
                        inputAutoselect={true}
                        inputCallbackChange={(value: string) => this.handleBaseChange(value)}
                    />
                </div>
            </React.Fragment>
        );
    };

    getFormulaColorant = (
        activeFormula: Formula | null,
        productId: number | null,
        baseId: number | null
    ): JSX.Element | null => {
        if (!productId || !baseId || !activeFormula || this.state.formulaColorantObjectType === null) {
            return null;
        }

        return (
            <div className="data-color-container colorant-table-container">
                <div className="unit-select-container">
                    <ModalSelectbox
                        className={"scene-select"}
                        editable={true}
                        required={true}
                        value={
                            this.props.activeUnit
                                ? this.props.activeUnit[propertyConstants.PROPERTY_NAME]
                                : optionConstants.DEFAULT_FORMULA_COLORANT_UNIT
                        }
                        options={generalHelper.getOptionsForSelectbox(
                            objectTypes.FORMULA_COLORANT_UNIT,
                            this.props.unitList
                        )}
                        autoselect={false}
                        callback={(event: any, key: string): void => {
                            this.props.setOptions(
                                [
                                    {
                                        key: optionConstants.OPTION_FORMULA_COLORANT_UNIT,
                                        value: key,
                                        locked: false
                                    }
                                ],
                                true
                            );
                        }}
                    />
                </div>
                <FormulaColorantTable
                    tableConstant={this.props.tableConstant}
                    objectType={this.state.formulaColorantObjectType}
                    dataPrivileges={this.props.dataPrivileges}
                    formulaId={activeFormula?.[propertyConstants.PROPERTY_ID] ?? undefined}
                    allList={activeFormula ? activeFormula[propertyConstants.PROPERTY_COLORANT_LIST] : []}
                    isEditable={false}
                    columnOrder={this.props.columnOrder}
                    columnVisibility={this.props.columnVisibility}
                    columnWidth={this.props.columnWidth}
                    showFooterRow={true}
                    sortingAsc={activeFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ?? true}
                    sortingCriterion={
                        (activeFormula?.[
                            propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION
                        ] as propertyConstants.Property) ?? null
                    }
                />
            </div>
        );
    };

    getContent = (
        activeFormula: Formula | null,
        productId: number | null,
        baseId: number | null
    ): JSX.Element | null => {
        if (productId && baseId && activeFormula && this.state.formulaObjectType) {
            return (
                <TextPreview
                    className="formula-content in-color-container"
                    objectType={this.state.formulaObjectType}
                    headings={this.props.headings}
                    data={activeFormula}
                />
            );
        }

        return this.getEmptyCaption(
            this.props.productList.length,
            productId,
            this.getBaseSelectOptions().length,
            baseId,
            activeFormula
        );
    };

    render(): JSX.Element | null {
        const productId = this.props.productActiveList[0]?.[propertyConstants.PROPERTY_ID] || null;
        const baseId = this.props.colorActiveList[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] || null;
        const formulaId = this.props.colorActiveList[0]?.[propertyConstants.PROPERTY_FORMULA_ID] || null;
        const activeFormula =
            this.props.formulaList.find((item) => item[propertyConstants.PROPERTY_ID] === formulaId) || null;

        return (
            <div className="color-properties" key="color-properties">
                <div className="data-color-container">
                    {this.getJumpButton(productId, baseId, formulaId)}
                    {this.getFormulaFilters(productId, baseId)}
                    {this.getContent(activeFormula, productId, baseId)}
                </div>
                {this.getFormulaColorant(activeFormula, productId, baseId)}
            </div>
        );
    }
}
