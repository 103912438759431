import * as generalHelper from "../helpers/generalHelper";
import * as objectTypes from "../constants/objectTypes";
import * as priceConstants from "../constants/entityConstants/priceConstants";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { DataType, mapDataType } from "./duplication/dataType";
import { File, mapFiles } from "./file";
import { Price, generateLastPriceGroup, mapPrice } from "./price";
import { ProductGroup, mapProductGroup } from "./productGroup";

import { TableHeading } from "./tableHeading";

export class Product {
    id: number;
    name: string;
    nameMaster: string;
    nameSystem: string;
    parentId?: number;
    nameSource?: number;
    searchName?: string | null;
    searchNameMaster?: string | null;
    searchNameSystem?: string | null;
    searchNameSource?: number;
    printName?: string | null;
    printNameMaster?: string | null;
    printNameSystem?: string | null;
    printNameSource?: number;
    tmcName?: string;
    tmcNameMaster?: string;
    tmcNameSystem?: string;
    tmcNameSource?: number;
    largoName?: string | null;
    code?: string;
    codeMaster?: string;
    codeSystem?: string;
    codeSource?: number;
    searchCode?: string | null;
    searchCodeMaster?: string | null;
    searchCodeSystem?: string | null;
    searchCodeSource?: number;
    imageId?: number | null;
    imageIdMaster?: number | null;
    imageIdSystem?: number | null;
    imageIdSource?: number;
    priority?: number;
    priorityMaster?: number;
    prioritySystem?: number;
    prioritySource?: number;
    moreInfo?: string;
    moreInfoMaster?: string;
    moreInfoSystem?: string;
    moreInfoSource?: number;
    uuid?: string | null;
    productGroupList?: Array<ProductGroup>;
    files?: Array<File>;
    priceGroupList?: Array<Price>;
    // eslint-disable-next-line camelcase
    price_cost_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_purchase_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_sell_per_liter?: Price | null;
    // eslint-disable-next-line camelcase
    price_vat?: Price | null;

    constructor(
        id: number,
        name: string,
        nameMaster: string,
        nameSystem: string,
        parentId?: number,
        nameSource?: number,
        searchName?: string | null,
        searchNameMaster?: string | null,
        searchNameSystem?: string | null,
        searchNameSource?: number,
        printName?: string | null,
        printNameMaster?: string | null,
        printNameSystem?: string | null,
        printNameSource?: number,
        tmcName?: string,
        tmcNameMaster?: string,
        tmcNameSystem?: string,
        tmcNameSource?: number,
        largoName?: string | null,
        code?: string,
        codeMaster?: string,
        codeSystem?: string,
        codeSource?: number,
        searchCode?: string | null,
        searchCodeMaster?: string | null,
        searchCodeSystem?: string | null,
        searchCodeSource?: number,
        imageId?: number | null,
        imageIdMaster?: number | null,
        imageIdSystem?: number | null,
        imageIdSource?: number,
        priority?: number,
        priorityMaster?: number,
        prioritySystem?: number,
        prioritySource?: number,
        moreInfo?: string,
        moreInfoMaster?: string,
        moreInfoSystem?: string,
        moreInfoSource?: number,
        uuid?: string | null,
        productGroupList?: Array<ProductGroup>,
        files?: Array<File>,
        priceGroupList?: Array<Price>,
        // eslint-disable-next-line camelcase
        price_cost_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_purchase_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_sell_per_liter?: Price | null,
        // eslint-disable-next-line camelcase
        price_vat?: Price | null
    ) {
        this.id = id;
        this.name = name;
        this.nameMaster = nameMaster;
        this.nameSystem = nameSystem;
        this.parentId = parentId;
        this.nameSource = nameSource;
        this.searchName = searchName;
        this.searchNameMaster = searchNameMaster;
        this.searchNameSystem = searchNameSystem;
        this.searchNameSource = searchNameSource;
        this.printName = printName;
        this.printNameMaster = printNameMaster;
        this.printNameSystem = printNameSystem;
        this.printNameSource = printNameSource;
        this.tmcName = tmcName;
        this.tmcNameMaster = tmcNameMaster;
        this.tmcNameSystem = tmcNameSystem;
        this.tmcNameSource = tmcNameSource;
        this.largoName = largoName;
        this.code = code;
        this.codeMaster = codeMaster;
        this.codeSystem = codeSystem;
        this.codeSource = codeSource;
        this.searchCode = searchCode;
        this.searchCodeMaster = searchCodeMaster;
        this.searchCodeSystem = searchCodeSystem;
        this.searchCodeSource = searchCodeSource;
        this.imageId = imageId;
        this.imageIdMaster = imageIdMaster;
        this.imageIdSystem = imageIdSystem;
        this.imageIdSource = imageIdSource;
        this.priority = priority;
        this.priorityMaster = priorityMaster;
        this.prioritySystem = prioritySystem;
        this.prioritySource = prioritySource;
        this.moreInfo = moreInfo;
        this.moreInfoMaster = moreInfoMaster;
        this.moreInfoSystem = moreInfoSystem;
        this.moreInfoSource = moreInfoSource;
        this.productGroupList = productGroupList;
        this.uuid = uuid;
        this.files = files;
        this.priceGroupList = priceGroupList;
        // eslint-disable-next-line camelcase
        this.price_cost_per_liter = price_cost_per_liter;
        // eslint-disable-next-line camelcase
        this.price_purchase_per_liter = price_purchase_per_liter;
        // eslint-disable-next-line camelcase
        this.price_sell_per_liter = price_sell_per_liter;
        // eslint-disable-next-line camelcase
        this.price_vat = price_vat;
    }
}
// TODO check mapProduct and mapParentChildProduct
export function mapProduct(data: Record<string, any>): Product | null {
    try {
        let productGroupList: Array<ProductGroup> | undefined = [];
        let priceGroupList: Array<Price> = [];
        let priceGroup: Price | null;
        let productGroup: ProductGroup | null;

        if (data.prices) {
            for (const key of Object.keys(data.prices)) {
                if (
                    data.prices[key] &&
                    data.prices[key][propertyConstants.PROPERTY_PRICE_TYPE_ID] === priceConstants.PRICE_TYPE_PRICE_GROUP
                ) {
                    priceGroup = mapPrice(key, data.prices[key]);

                    if (priceGroup) {
                        priceGroupList.push(priceGroup);
                    }
                }
            }
            priceGroupList = generalHelper.sortItemsByProperty(
                priceGroupList,
                propertyConstants.PROPERTY_PRICE_GROUP,
                true
            );

            if (priceGroupList.length) {
                const lastPriceGroup = generateLastPriceGroup(
                    priceGroupList[priceGroupList.length - 1],
                    propertyConstants.PROPERTY_PRICE_PRICE_GROUP
                );

                if (lastPriceGroup) {
                    priceGroupList.push(lastPriceGroup);
                }
            }
        }

        if (data.productGroups) {
            for (const item of data.productGroups) {
                productGroup = mapProductGroup(item);

                if (productGroup) {
                    productGroupList.push(productGroup);
                }
            }
        } else {
            productGroupList = undefined;
        }

        return new Product(
            data.id,
            data.name,
            data.nameMaster,
            data.nameSystem,
            data.parentId,
            data.nameSource,
            data.searchName,
            data.searchNameMaster,
            data.searchNameSystem,
            data.searchNameSource,
            data.printName,
            data.printNameMaster,
            data.printNameSystem,
            data.printNameSource,
            data.tmcName,
            data.tmcNameMaster,
            data.tmcNameSystem,
            data.tmcNameSource,
            data.jsonAttributes && data.jsonAttributes.largoName ? data.jsonAttributes.largoName : null,
            data.code,
            data.codeMaster,
            data.codeSystem,
            data.codeSource,
            data.searchCode,
            data.searchCodeMaster,
            data.searchCodeSystem,
            data.searchCodeSource,
            data.imageId,
            data.imageIdMaster,
            data.imageIdSystem,
            data.imageIdSource,
            data.priority,
            data.priorityMaster,
            data.prioritySystem,
            data.prioritySource,
            data.moreInfo,
            data.moreInfoMaster,
            data.moreInfoSystem,
            data.moreInfoSource,
            data.uuid,
            productGroupList,
            data.files ? mapFiles(data.files) : undefined,
            priceGroupList,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                      data.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                  )
                : undefined,
            data.prices && data.prices[propertyConstants.PROPERTY_PRICE_VAT]
                ? mapPrice(propertyConstants.PROPERTY_PRICE_VAT, data.prices[propertyConstants.PROPERTY_PRICE_VAT])
                : undefined
        );
    } catch (e) {
        return null;
    }
}

export function mapParentChildProduct(parent: Record<string, any>): Product | null {
    try {
        return new Product(
            parent.id,
            parent.name,
            parent.nameMaster,
            parent.nameSystem,
            parent.parentId,
            parent.nameSource,
            parent.searchName,
            parent.searchNameMaster,
            parent.searchNameSystem,
            parent.searchNameSource,
            parent.printName,
            parent.printNameMaster,
            parent.printNameSystem,
            parent.printNameSource,
            parent.tmcName,
            parent.tmcNameMaster,
            parent.tmcNameSystem,
            parent.tmcNameSource,
            parent.largoName,
            parent.code,
            parent.codeMaster,
            parent.codeSystem,
            parent.codeSource,
            parent.searchCode,
            parent.searchCodeMaster,
            parent.searchCodeSystem,
            parent.searchCodeSource,
            parent.imageId,
            parent.imageIdMaster,
            parent.imageIdSystem,
            parent.imageIdSource,
            parent.priority,
            parent.priorityMaster,
            parent.prioritySystem,
            parent.prioritySource,
            parent.moreInfo,
            parent.moreInfoMaster,
            parent.moreInfoSystem,
            parent.moreInfoSource,
            parent.uuid,
            parent.productGroupList,
            parent.files && parent.files.length ? mapFiles(parent.files) : undefined,
            parent.priceGroupList && parent.priceGroupList.length ? parent.priceGroupList : undefined,
            parent.prices && parent.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
                      parent.prices[propertyConstants.PROPERTY_PRICE_COST_PER_LITER]
                  )
                : undefined,
            parent.prices && parent.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
                      parent.prices[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]
                  )
                : undefined,
            parent.prices && parent.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                ? mapPrice(
                      propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
                      parent.prices[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]
                  )
                : undefined,
            parent.prices && parent.prices[propertyConstants.PROPERTY_PRICE_VAT]
                ? mapPrice(propertyConstants.PROPERTY_PRICE_VAT, parent.prices[propertyConstants.PROPERTY_PRICE_VAT])
                : undefined
        );
    } catch (e) {
        return null;
    }
}

export function updateProduct(oldProduct: Product | null, updatedData: Record<string, any>): Product | null {
    try {
        if (oldProduct === null) {
            return null;
        }

        return new Product(
            oldProduct.id,
            updatedData.name ? updatedData.name : updatedData.productName ? updatedData.productName : oldProduct.name,
            oldProduct.nameMaster,
            oldProduct.nameSystem,
            oldProduct.parentId,
            oldProduct.nameSource,
            updatedData.searchName ? updatedData.searchName : oldProduct.searchName,
            oldProduct.searchNameMaster,
            oldProduct.searchNameSystem,
            oldProduct.searchNameSource,
            updatedData.printName ? updatedData.printName : oldProduct.printName,
            oldProduct.printNameMaster,
            oldProduct.printNameSystem,
            oldProduct.printNameSource,
            updatedData.tmcName ? updatedData.tmcName : oldProduct.tmcName,
            oldProduct.tmcNameMaster,
            oldProduct.tmcNameSystem,
            oldProduct.tmcNameSource,
            oldProduct.largoName,
            updatedData.code ? updatedData.code : oldProduct.code,
            oldProduct.codeMaster,
            oldProduct.codeSystem,
            oldProduct.codeSource,
            updatedData.searchCode ? updatedData.searchCode : oldProduct.searchCode,
            oldProduct.searchCodeMaster,
            oldProduct.searchCodeSystem,
            oldProduct.searchCodeSource,
            oldProduct.imageId,
            oldProduct.imageIdMaster,
            oldProduct.imageIdSystem,
            oldProduct.imageIdSource,
            updatedData.priority ? updatedData.priority : oldProduct.priority,
            oldProduct.priorityMaster,
            oldProduct.prioritySystem,
            oldProduct.prioritySource,
            updatedData.moreInfo ? updatedData.moreInfo : oldProduct.moreInfo,
            oldProduct.moreInfoMaster,
            oldProduct.moreInfoSystem,
            oldProduct.moreInfoSource,
            updatedData.uuid ? updatedData.uuid : oldProduct.uuid,
            oldProduct.productGroupList,
            oldProduct.files,
            oldProduct.priceGroupList,
            updatedData.price_cost_per_liter ? updatedData.price_cost_per_liter : oldProduct.price_cost_per_liter,
            updatedData.price_purchase_per_liter
                ? updatedData.price_purchase_per_liter
                : oldProduct.price_purchase_per_liter,
            updatedData.price_sell_per_liter ? updatedData.price_sell_per_liter : oldProduct.price_sell_per_liter,
            updatedData.price_vat ? updatedData.price_vat : oldProduct.price_vat
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyProduct(): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_NAME]: "",
        [propertyConstants.PROPERTY_PARENT_ID]: "",
        [propertyConstants.PROPERTY_SEARCH_NAME]: "",
        [propertyConstants.PROPERTY_PRINT_NAME]: "",
        [propertyConstants.PROPERTY_TMC_NAME]: "",
        [propertyConstants.PROPERTY_LARGO_NAME]: "",
        [propertyConstants.PROPERTY_CODE]: "",
        [propertyConstants.PROPERTY_SEARCH_CODE]: "",
        [propertyConstants.PROPERTY_PRIORITY]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_UUID]: "",
        [propertyConstants.PROPERTY_IMAGE_ID]: "",
        [propertyConstants.PROPERTY_FILES]: [],
        [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: [],
        [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: "",
        [propertyConstants.PROPERTY_PRICE_VAT]: ""
    };
}

export function generateProductData(
    objectType: objectTypes.ObjectType,
    list: Array<Product>,
    dataTypeList: Array<number> = [],
    isUuidEmpty: boolean = false
): Array<any> {
    const data: Array<any> = [];
    const dataTypesList = [];
    let item: Product;
    let dataType: DataType | null = null;

    for (const dataTypeId of dataTypeList) {
        dataType = mapDataType(dataTypeId, objectType);

        if (dataType) {
            dataTypesList.push(dataType);
        }
    }

    for (item of list) {
        let productGroupList = undefined;

        if (Array.isArray(item.productGroupList)) {
            if (item.productGroupList.length) {
                productGroupList = item.productGroupList.map((item) => item.name).join(", ");
            } else {
                productGroupList = "";
            }
        }

        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_NAME]: item.name,
            [propertyConstants.PROPERTY_NAME_MASTER]: item.nameMaster,
            [propertyConstants.PROPERTY_NAME_SYSTEM]: item.nameSystem,
            [propertyConstants.PROPERTY_PARENT_ID]: item.parentId,
            [propertyConstants.PROPERTY_NAME_SOURCE]: item.nameSource,
            [propertyConstants.PROPERTY_SEARCH_NAME]: item.searchName,
            [propertyConstants.PROPERTY_SEARCH_NAME_MASTER]: item.searchNameMaster,
            [propertyConstants.PROPERTY_SEARCH_NAME_SYSTEM]: item.searchNameSystem,
            [propertyConstants.PROPERTY_SEARCH_NAME_SOURCE]: item.searchNameSource,
            [propertyConstants.PROPERTY_PRINT_NAME]: item.printName,
            [propertyConstants.PROPERTY_PRINT_NAME_MASTER]: item.printNameMaster,
            [propertyConstants.PROPERTY_PRINT_NAME_SYSTEM]: item.printNameSystem,
            [propertyConstants.PROPERTY_PRINT_NAME_SOURCE]: item.printNameSource,
            [propertyConstants.PROPERTY_CODE]: item.code,
            [propertyConstants.PROPERTY_CODE_MASTER]: item.codeMaster,
            [propertyConstants.PROPERTY_CODE_SYSTEM]: item.codeSystem,
            [propertyConstants.PROPERTY_CODE_SOURCE]: item.codeSource,
            [propertyConstants.PROPERTY_SEARCH_CODE]: item.searchCode,
            [propertyConstants.PROPERTY_SEARCH_CODE_MASTER]: item.searchCodeMaster,
            [propertyConstants.PROPERTY_SEARCH_CODE_SYSTEM]: item.searchCodeSystem,
            [propertyConstants.PROPERTY_SEARCH_CODE_SOURCE]: item.searchCodeSource,
            [propertyConstants.PROPERTY_IMAGE_ID]: item.imageId,
            [propertyConstants.PROPERTY_IMAGE_ID_MASTER]: item.imageIdMaster,
            [propertyConstants.PROPERTY_IMAGE_ID_SYSTEM]: item.imageIdSystem,
            [propertyConstants.PROPERTY_IMAGE_ID_SOURCE]: item.imageIdSource,
            [propertyConstants.PROPERTY_TMC_NAME]: item.tmcName,
            [propertyConstants.PROPERTY_TMC_NAME_MASTER]: item.tmcNameMaster,
            [propertyConstants.PROPERTY_TMC_NAME_SYSTEM]: item.tmcNameSystem,
            [propertyConstants.PROPERTY_TMC_NAME_SOURCE]: item.tmcNameSource,
            [propertyConstants.PROPERTY_LARGO_NAME]: item.largoName,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_MORE_INFO_MASTER]: item.moreInfoMaster,
            [propertyConstants.PROPERTY_MORE_INFO_SYSTEM]: item.moreInfoSystem,
            [propertyConstants.PROPERTY_MORE_INFO_SOURCE]: item.moreInfoSource,
            [propertyConstants.PROPERTY_UUID]: !isUuidEmpty ? item.uuid : "",
            [propertyConstants.PROPERTY_PRIORITY]: item.priority,
            [propertyConstants.PROPERTY_PRIORITY_MASTER]: item.priorityMaster,
            [propertyConstants.PROPERTY_PRIORITY_SYSTEM]: item.prioritySystem,
            [propertyConstants.PROPERTY_PRIORITY_SOURCE]: item.prioritySource,
            [propertyConstants.PROPERTY_PRODUCT_GROUP_LIST]: productGroupList,
            [propertyConstants.PROPERTY_FILES]: item.files,
            [propertyConstants.PROPERTY_PRICE_GROUP_LIST]: item.priceGroupList,
            [propertyConstants.PROPERTY_PRICE_COST_PER_LITER]: item.price_cost_per_liter,
            [propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER]: item.price_purchase_per_liter,
            [propertyConstants.PROPERTY_PRICE_SELL_PER_LITER]: item.price_sell_per_liter,
            [propertyConstants.PROPERTY_PRICE_VAT]: item.price_vat,
            [propertyConstants.PROPERTY_DATA_TYPE_LIST]: dataTypesList
        });
    }

    return data;
}

export function generateProductHeadings(
    objectType: objectTypes.ObjectType,
    dataTypeList: Array<DataType>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_CODE),
            editableList.includes(propertyConstants.PROPERTY_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_CODE),
            requiredList.includes(propertyConstants.PROPERTY_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DATA_TYPE_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.TABLE_TYPE_LIST,
            visibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            editableList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            requiredList.includes(propertyConstants.PROPERTY_DATA_TYPE_LIST),
            dataTypeList,
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_DIVIDING_LINE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.TABLE_TYPE_LINE,
            visibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            editableList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            requiredList.includes(propertyConstants.PROPERTY_DIVIDING_LINE),
            [],
            null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_FILES,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_FILES),
            tableConstants.TABLE_TYPE_FILE,
            visibleList.includes(propertyConstants.PROPERTY_FILES),
            editableList.includes(propertyConstants.PROPERTY_FILES),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_FILES),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_FILES),
            requiredList.includes(propertyConstants.PROPERTY_FILES),
            [],
            widthOption?.[propertyConstants.PROPERTY_FILES] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_IMAGE_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.TABLE_TYPE_IMAGE_SECTION,
            visibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            editableList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_IMAGE_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            requiredList.includes(propertyConstants.PROPERTY_IMAGE_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_IMAGE_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LARGO_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            editableList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LARGO_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            requiredList.includes(propertyConstants.PROPERTY_LARGO_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_LARGO_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME),
            editableList.includes(propertyConstants.PROPERTY_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME),
            requiredList.includes(propertyConstants.PROPERTY_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_MASTER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            editableList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_MASTER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            requiredList.includes(propertyConstants.PROPERTY_NAME_MASTER),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_MASTER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_NAME_SYSTEM,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            editableList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            requiredList.includes(propertyConstants.PROPERTY_NAME_SYSTEM),
            [],
            widthOption?.[propertyConstants.PROPERTY_NAME_SYSTEM] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_COST_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_COST_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_COST_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_GROUP_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            editableList.includes(propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_GROUP_LIST),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_GROUP_LIST] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_PURCHASE_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_SELL_PER_LITER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            editableList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_SELL_PER_LITER),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_SELL_PER_LITER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRICE_VAT,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.TABLE_TYPE_PRICE,
            visibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            editableList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRICE_VAT),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            requiredList.includes(propertyConstants.PROPERTY_PRICE_VAT),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRICE_VAT] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRINT_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            editableList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRINT_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            requiredList.includes(propertyConstants.PROPERTY_PRINT_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRINT_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRIORITY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRIORITY),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            editableList.includes(propertyConstants.PROPERTY_PRIORITY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRIORITY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRIORITY),
            requiredList.includes(propertyConstants.PROPERTY_PRIORITY),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRIORITY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCT_GROUP_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            editableList.includes(propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCT_GROUP_LIST),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCT_GROUP_LIST] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SEARCH_CODE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SEARCH_CODE),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            editableList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            requiredList.includes(propertyConstants.PROPERTY_SEARCH_CODE),
            [],
            widthOption?.[propertyConstants.PROPERTY_SEARCH_CODE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_SEARCH_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            editableList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            requiredList.includes(propertyConstants.PROPERTY_SEARCH_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_SEARCH_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_TMC_NAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            editableList.includes(propertyConstants.PROPERTY_TMC_NAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_TMC_NAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_TMC_NAME),
            requiredList.includes(propertyConstants.PROPERTY_TMC_NAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_TMC_NAME] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_UUID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_UUID),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_UUID),
            editableList.includes(propertyConstants.PROPERTY_UUID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_UUID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_UUID),
            requiredList.includes(propertyConstants.PROPERTY_UUID),
            [],
            widthOption?.[propertyConstants.PROPERTY_UUID] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
