import * as bookmarkConstants from "../../constants/bookmarkConstants";
import * as colorHelper from "../../helpers/colorHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Color, cloneColor, mapColor, updateColorRgb } from "../../types/color";
import { ColorData, mapColorData, updateColorDataLab } from "../../types/colorData";
import { ReflectanceData, mapReflectanceData } from "../../types/reflectanceData";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";
import { t as translate } from "react-i18nify";

export const processWebsocketResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_BY_FANDECK: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(item, null, null);

                if (color) {
                    colorList.push(color);
                }
            }

            /* when the active color is updated by closing the Color Modal,
            than with this if statement we make sure that also color data related to the active color are being updated */
            if (state.color.masterActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_COLOR_DATA));
            }

            requests.push(universalObjectActions.saveItems(objectTypes.MASTER_COLOR, colorList, response.data.count));

            return requests;
        }

        case methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_BY_FANDECK: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(item, null, null);

                if (color) {
                    colorList.push(color);
                }
            }

            /* when the active color is updated by closing the Color Modal,
            than with this if statement we make sure that also color data related to the active color are being updated */
            if (state.color.systemActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_COLOR_DATA));
            }

            requests.push(universalObjectActions.saveItems(objectTypes.SYSTEM_COLOR, colorList, response.data.count));

            return requests;
        }

        case methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_BY_FANDECK: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }
            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(item, null, null);

                if (color) {
                    colorList.push(color);
                }
            }

            /* when the active color is updated by closing the Color Modal,
            than with this if statement we make sure that also color data related to the active color are being updated */
            if (state.color.zoneActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_COLOR_DATA));
            }

            requests.push(universalObjectActions.saveItems(objectTypes.ZONE_COLOR, colorList, response.data.count));

            return requests;
        }

        case methods.METHOD_GET_ALL_MASTER_COLORS_IN_FANDECKS_WITH_FORMULA_INFO: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(
                    item,
                    state.color.masterActiveList?.[0]?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null,
                    state.color.masterActiveList?.[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null
                );

                if (color) {
                    colorList.push(color);
                }
            }

            if (state.color.masterActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.MASTER_FORMULA));
            }

            requests.push(
                universalObjectActions.saveItems(
                    objectTypes.MASTER_COLOR_WITH_FORMULA_INFO,
                    colorList,
                    response.data.count
                )
            );

            return requests;
        }

        case methods.METHOD_GET_ALL_SYSTEM_COLORS_IN_FANDECKS_WITH_FORMULA_INFO: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(
                    item,
                    state.color.systemActiveList?.[0]?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null,
                    state.color.systemActiveList?.[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null
                );

                if (color) {
                    colorList.push(color);
                }
            }

            if (state.color.systemActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.SYSTEM_FORMULA));
            }

            requests.push(
                universalObjectActions.saveItems(
                    objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO,
                    colorList,
                    response.data.count
                )
            );

            return requests;
        }

        case methods.METHOD_GET_ALL_ZONE_COLORS_IN_FANDECKS_WITH_FORMULA_INFO: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests: Array<any> = [];
            const colorList: Array<Color> = [];
            let color: Color | null;

            for (const item of response.data.data) {
                color = mapColor(
                    item,
                    state.color.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_FORMULA_ID] ?? null,
                    state.color.zoneActiveList?.[0]?.[propertyConstants.PROPERTY_BASE_IN_PRODUCT_ID] ?? null
                );

                if (color) {
                    colorList.push(color);
                }
            }

            if (state.color.zoneActiveList.length) {
                requests.push(universalObjectActions.reloadData(objectTypes.ZONE_FORMULA));
            }

            requests.push(
                universalObjectActions.saveItems(
                    objectTypes.ZONE_COLOR_WITH_FORMULA_INFO,
                    colorList,
                    response.data.count
                )
            );

            return requests;
        }
        case methods.METHOD_GET_MASTER_COLOR_IN_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = mapColor(response.data, null, null);

            return [
                universalObjectActions.setActiveItems(objectTypes.MASTER_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_SYSTEM_COLOR_IN_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = mapColor(response.data, null, null);

            return [
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_ZONE_COLOR_IN_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const color = mapColor(response.data, null, null);

            return [
                universalObjectActions.setActiveItems(objectTypes.ZONE_COLOR_WITH_FORMULA_INFO, color ? [color] : [])
            ];
        }
        case methods.METHOD_GET_DATA_FOR_COLOR_IN_FANDECK: {
            // TODO validation
            const colorDataList: Array<ColorData> = [];
            const colorActiveList: Array<Color> = [];
            let color: Color | null;
            let colorData: ColorData | null;

            // decides whether data are going to be saved into masterModalActiveList or masterActiveList
            const objectType =
                state.navigation.activeModalBookmark === bookmarkConstants.BOOKMARK_SPECTRAL_DATA
                    ? objectTypes.MASTER_COLOR_MODAL
                    : objectTypes.MASTER_COLOR_DATA;
            const activeItem: Color | null =
                state.navigation.activeModalBookmark === bookmarkConstants.BOOKMARK_SPECTRAL_DATA
                    ? state.color.masterModalActiveList?.[0] || null
                    : state.color.masterActiveList?.[0] || null;

            if (activeItem) {
                for (const item of response.data) {
                    // TODO: Change to const
                    let foundItem: Array<number> = [];
                    const reflDataList: Array<ReflectanceData> = [];
                    const wavelengthOption = generalHelper.getObjectByKey(
                        state.login.options,
                        optionsConstants.OPTION_WAVELENGTH
                    );

                    if (wavelengthOption && wavelengthOption[propertyConstants.PROPERTY_VALUE]) {
                        // generate waveLength list based on the wavelength options
                        const wavelengthList = colorHelper.getWavelengthList(
                            wavelengthOption[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_INTERVAL],
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].begin_value,
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].end_value
                        );

                        for (const wavelengthItem of wavelengthList) {
                            // if the item has already reflData, then they need to be synchronized with the wavelength list
                            if (
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA] &&
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA].length
                            ) {
                                foundItem =
                                    item[propertyConstants.PROPERTY_REFLECTANCE_DATA].find(
                                        (reflItem: any) => reflItem[0] === wavelengthItem
                                    ) || null;
                            }

                            // mapping reflectanceData from the server to the specific wavelength
                            const reflData = mapReflectanceData(
                                wavelengthItem,
                                foundItem && foundItem.length === 2 ? foundItem[1] : null
                            );

                            if (reflData) {
                                reflDataList.push(reflData);
                            }
                        }

                        // mapping colorData + calculating LCH paramaters
                        colorData = mapColorData(
                            item,
                            reflDataList,
                            colorHelper.calculateLchFromLab(
                                item[propertyConstants.PROPERTY_SPECTRO_A],
                                item[propertyConstants.PROPERTY_SPECTRO_B]
                            )
                        );

                        if (colorData) {
                            colorDataList.push(colorData);
                        }
                    }
                }
                // adding the list of colorData into the active color
                color = cloneColor(
                    activeItem,
                    colorDataList,
                    colorDataList.length ? colorDataList[0][propertyConstants.PROPERTY_SPECTRO_TYPE_ID] : null
                );
                if (color) {
                    colorActiveList.push(color);
                }
            }

            return [universalObjectActions.setActiveItems(objectType, colorActiveList)];
        }
        case methods.METHOD_GET_DATA_FOR_SYSTEM_COLOR_IN_FANDECK: {
            // TODO validation
            const colorDataList: Array<ColorData> = [];
            const colorActiveList: Array<Color> = [];
            let color: Color | null;
            let colorData: ColorData | null;

            // decides whether data are going to be saved into masterModalActiveList or masterActiveList
            const objectType = objectTypes.SYSTEM_COLOR_DATA;
            const activeItem: Color | null = state.color.systemActiveList?.[0] || null;

            if (activeItem) {
                for (const item of response.data) {
                    // TODO: Change to const
                    let foundItem: Array<number> = [];
                    const reflDataList: Array<ReflectanceData> = [];
                    const wavelengthOption = generalHelper.getObjectByKey(
                        state.login.options,
                        optionsConstants.OPTION_WAVELENGTH
                    );

                    if (wavelengthOption && wavelengthOption[propertyConstants.PROPERTY_VALUE]) {
                        // generate waveLength list based on the wavelength options
                        const wavelengthList = colorHelper.getWavelengthList(
                            wavelengthOption[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_INTERVAL],
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].begin_value,
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].end_value
                        );

                        for (const wavelengthItem of wavelengthList) {
                            // if the item has already reflData, then they need to be synchronized with the wavelength list
                            if (
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA] &&
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA].length
                            ) {
                                foundItem =
                                    item[propertyConstants.PROPERTY_REFLECTANCE_DATA].find(
                                        (reflItem: any) => reflItem[0] === wavelengthItem
                                    ) || null;
                            }

                            // mapping reflectanceData from the server to the specific wavelength
                            const reflData = mapReflectanceData(
                                wavelengthItem,
                                foundItem && foundItem.length === 2 ? foundItem[1] : null
                            );

                            if (reflData) {
                                reflDataList.push(reflData);
                            }
                        }

                        // mapping colorData + calculating LCH paramaters
                        colorData = mapColorData(
                            item,
                            reflDataList,
                            colorHelper.calculateLchFromLab(
                                item[propertyConstants.PROPERTY_SPECTRO_A],
                                item[propertyConstants.PROPERTY_SPECTRO_B]
                            )
                        );

                        if (colorData) {
                            colorDataList.push(colorData);
                        }
                    }
                }
                // adding the list of colorData into the active color
                color = cloneColor(
                    activeItem,
                    colorDataList,
                    colorDataList.length ? colorDataList[0][propertyConstants.PROPERTY_SPECTRO_TYPE_ID] : null
                );
                if (color) {
                    colorActiveList.push(color);
                }
            }

            return [universalObjectActions.setActiveItems(objectType, colorActiveList)];
        }
        case methods.METHOD_GET_DATA_FOR_ZONE_COLOR_IN_FANDECK: {
            // TODO validation
            const colorDataList: Array<ColorData> = [];
            const colorActiveList: Array<Color> = [];
            let color: Color | null;
            let colorData: ColorData | null;

            // decides whether data are going to be saved into masterModalActiveList or masterActiveList
            const objectType = objectTypes.ZONE_COLOR_DATA;
            const activeItem: Color | null = state.color.zoneActiveList?.[0] || null;

            if (activeItem) {
                for (const item of response.data) {
                    // TODO: Change to const
                    let foundItem: Array<number> = [];
                    const reflDataList: Array<ReflectanceData> = [];
                    const wavelengthOption = generalHelper.getObjectByKey(
                        state.login.options,
                        optionsConstants.OPTION_WAVELENGTH
                    );

                    if (wavelengthOption && wavelengthOption[propertyConstants.PROPERTY_VALUE]) {
                        // generate waveLength list based on the wavelength options
                        const wavelengthList = colorHelper.getWavelengthList(
                            wavelengthOption[propertyConstants.PROPERTY_VALUE][propertyConstants.PROPERTY_INTERVAL],
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].begin_value,
                            wavelengthOption[propertyConstants.PROPERTY_VALUE].end_value
                        );

                        for (const wavelengthItem of wavelengthList) {
                            // if the item has already reflData, then they need to be synchronized with the wavelength list
                            if (
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA] &&
                                item[propertyConstants.PROPERTY_REFLECTANCE_DATA].length
                            ) {
                                foundItem =
                                    item[propertyConstants.PROPERTY_REFLECTANCE_DATA].find(
                                        (reflItem: any) => reflItem[0] === wavelengthItem
                                    ) || null;
                            }

                            // mapping reflectanceData from the server to the specific wavelength
                            const reflData = mapReflectanceData(
                                wavelengthItem,
                                foundItem && foundItem.length === 2 ? foundItem[1] : null
                            );

                            if (reflData) {
                                reflDataList.push(reflData);
                            }
                        }

                        // mapping colorData + calculating LCH paramaters
                        colorData = mapColorData(
                            item,
                            reflDataList,
                            colorHelper.calculateLchFromLab(
                                item[propertyConstants.PROPERTY_SPECTRO_A],
                                item[propertyConstants.PROPERTY_SPECTRO_B]
                            )
                        );

                        if (colorData) {
                            colorDataList.push(colorData);
                        }
                    }
                }
                // adding the list of colorData into the active color
                color = cloneColor(
                    activeItem,
                    colorDataList,
                    colorDataList.length ? colorDataList[0][propertyConstants.PROPERTY_SPECTRO_TYPE_ID] : null
                );
                if (color) {
                    colorActiveList.push(color);
                }
            }

            return [universalObjectActions.setActiveItems(objectType, colorActiveList)];
        }
        case methods.METHOD_ADD_MASTER_COLOR_IN_FANDECK_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORS_ADD)
            ];
        }
        case methods.METHOD_EDIT_MASTER_COLOR_IN_FANDECK_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COLORS_EDIT)
            ];
        }
        case methods.METHOD_DELETE_MASTER_COLOR_IN_FANDECK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_COLOR),
                universalObjectActions.setActiveItems(objectTypes.MASTER_COLOR, [])
            ];
        }
        case methods.METHOD_COLOR_CONVERT_LAB_TO_RGB: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];
            const updatedColor: Array<Color> = [];

            if (state.color.masterModalActiveList.length) {
                updatedColor.push(updateColorRgb(state.color.masterModalActiveList[0], response.data));

                const params = {
                    title: translate("color.calculateRgb"),
                    objectType: objectTypes.MASTER_COLOR_SPECTRO_TYPE,
                    data: {
                        [propertyConstants.PROPERTY_COLOR_RED]: response.data?.r ?? "",
                        [propertyConstants.PROPERTY_COLOR_GREEN]: response.data?.g ?? "",
                        [propertyConstants.PROPERTY_COLOR_BLUE]: response.data?.b ?? "",
                        [propertyConstants.PROPERTY_COLOR_RGB_HEX]: response.data?.hex ?? ""
                    },
                    activeScene: state.navigation.activeScene
                };

                requests.push(navigationActions.navigationOpenModal(modalTypes.MODAL_RGB_CALCULATE, params));
            }

            requests.push(universalObjectActions.setActiveItems(objectTypes.MASTER_COLOR_MODAL, updatedColor));

            return requests;
        }
        case methods.METHOD_COLOR_CONVERT_REFL_TO_LAB: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const updatedColor: Array<Color> = [];
            const updatedColorDataList: Array<ColorData> = [];
            let colorData: ColorData;
            let selectedColorData: any = null;

            for (const item of state.color.masterModalActiveList?.[0]?.[propertyConstants.PROPERTY_DATA] || []) {
                if (
                    item[propertyConstants.PROPERTY_SPECTRO_TYPE_ID] ===
                    state.color.masterModalActiveList[0][propertyConstants.PROPERTY_SPECTRO_TYPE_ID]
                ) {
                    selectedColorData = item;
                }
            }

            if (selectedColorData) {
                colorData = updateColorDataLab(
                    selectedColorData,
                    response.data,
                    colorHelper.calculateLchFromLab(response.data[1], response.data[2])
                );

                for (const item of state.color.masterModalActiveList?.[0]?.[propertyConstants.PROPERTY_DATA] || []) {
                    if (item[propertyConstants.PROPERTY_ID] === colorData[propertyConstants.PROPERTY_ID]) {
                        updatedColorDataList.push(colorData);
                    } else {
                        updatedColorDataList.push(item);
                    }
                }

                updatedColor.push({
                    ...state.color.masterModalActiveList[0],
                    [propertyConstants.PROPERTY_DATA]: updatedColorDataList
                });
            }

            return [universalObjectActions.setActiveItems(objectTypes.MASTER_COLOR_MODAL, updatedColor)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
