import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { UserGroup, mapRedlikeUserGroups, mapUserGroup } from "./userGroup";

import { Company } from "./company";
import { Option } from "./option";
import { TableHeading } from "./tableHeading";

import { t as translate } from "react-i18nify";

export class User {
    id: number;
    username: string;
    companyId: number | null;
    password: string | null;
    autoLogin: boolean | null;
    canUpdate: boolean | null;
    canDelete: boolean | null;
    userGroupList: Array<UserGroup>;

    constructor(
        id: number,
        username: string,
        companyId: number | null,
        password: string | null = null,
        autoLogin: boolean | null = null,
        canUpdate: boolean | null = null,
        canDelete: boolean | null = null,
        userGroupList: Array<UserGroup> = []
    ) {
        this.id = id;
        this.username = username;
        this.companyId = companyId;
        this.password = password;
        this.autoLogin = autoLogin;
        this.canUpdate = canUpdate;
        this.canDelete = canDelete;
        this.userGroupList = userGroupList;
    }
}

export function mapUser(
    user: Record<string, any>,
    loggedUser: User | null,
    optionCompaniesForUsers: Option | null,
    userGroups: Array<Record<string, any>> = []
): User | null {
    try {
        let companyId = null;
        const loggedUserId = loggedUser?.[propertyConstants.PROPERTY_ID] ?? null;
        const loggedUserCompanyId = loggedUserId
            ? optionCompaniesForUsers?.[propertyConstants.PROPERTY_VALUE]?.[loggedUserId] ?? null
            : null;
        const userCompanyId = optionCompaniesForUsers?.[propertyConstants.PROPERTY_VALUE]?.[user.id] ?? null;

        // Superadmins know companies of all users
        if (privilegesHelper.isSuperadmin(loggedUser)) {
            companyId = userCompanyId;
        }

        // User cannot see users from different companies, expect for superadmins
        if (
            !privilegesHelper.isSuperadmin(loggedUser) &&
            userCompanyId !== loggedUserCompanyId &&
            loggedUserCompanyId !== null
        ) {
            return null;
        }

        // Only superadmin can see others superadmins
        if (
            loggedUser &&
            !privilegesHelper.isSuperadmin(loggedUser) &&
            userGroups.some(
                (group: Record<string, any>) => group.translation === privilegeConstants.USER_GROUP_SUPERADMINS
            )
        ) {
            return null;
        }

        const userGroupList: Array<UserGroup> = [];
        let userGroup: UserGroup | null = null;

        for (const item of userGroups) {
            userGroup = mapUserGroup(item, loggedUser);

            if (userGroup) {
                userGroupList.push(userGroup);
            }
        }

        return new User(user.id, user.username, companyId, user.password, null, null, null, userGroupList);
    } catch (e) {
        return null;
    }
}

export function mapRedlikeUser(data: Record<string, any>): User | null {
    try {
        return new User(data.id, data.username, null, data.password, data.autoLogin, data.canEdit, data.canDelete);
    } catch (e) {
        return null;
    }
}

export function mapRedlikeUserWithUserGroups(data: Record<string, any>): User | null {
    try {
        return new User(
            data.user ? data.user.id : null,
            data.user ? data.user.username : null,
            null,
            data.user ? data.user.password : null,
            data.user ? data.user.autoLogin : null,
            data.user ? data.user.canEdit : null,
            data.user ? data.user.canDelete : null,
            mapRedlikeUserGroups(data.groups ?? [])
        );
    } catch (e) {
        return null;
    }
}

export function cloneUser(data: User | null): User | null {
    if (data === null) {
        return null;
    }

    try {
        return new User(
            data.id,
            data.username,
            data.companyId,
            data.password,
            data.autoLogin,
            data.canUpdate,
            data.canDelete,
            data.userGroupList
        );
    } catch (e) {
        return null;
    }
}

export function updateUser(oldUser: User | null, updatedData: Record<string, any>): User | null {
    if (oldUser === null) {
        return null;
    }

    try {
        return new User(
            oldUser.id,
            updatedData.username ? updatedData.username : oldUser.username,
            oldUser.companyId ? updatedData.companyId : oldUser.companyId,
            oldUser.password ? oldUser.password : null,
            updatedData.autoLogin ? updatedData.autoLogin : oldUser.autoLogin,
            oldUser.canUpdate ? oldUser.canUpdate : null,
            oldUser.canDelete ? oldUser.canDelete : null,
            oldUser.userGroupList ? oldUser.userGroupList : []
        );
    } catch (e) {
        return null;
    }
}

export function updateLoggedUser(user: User | null, optionCompaniesForUsers: Option | null): User | null {
    if (user === null) {
        return null;
    }

    try {
        const companyId =
            optionCompaniesForUsers?.[propertyConstants.PROPERTY_VALUE]?.[
                user?.[propertyConstants.PROPERTY_ID]?.toString()
            ] || null;

        return new User(
            user.id,
            user.username,
            companyId,
            user.password,
            user.autoLogin,
            user.canUpdate,
            user.canDelete,
            user.userGroupList
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyUser(companyId: string | number = "", userGroupList: Array<UserGroup>): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_USERNAME]: "",
        [propertyConstants.PROPERTY_COMPANY_ID]: companyId,
        [propertyConstants.PROPERTY_PASSWORD]: "",
        [propertyConstants.PROPERTY_AUTOLOGIN]: "",
        [propertyConstants.PROPERTY_CAN_UPDATE]: "",
        [propertyConstants.PROPERTY_CAN_DELETE]: "",
        [propertyConstants.PROPERTY_USER_GROUP_LIST]: userGroupList?.length ? [userGroupList[0]] : []
    };
}

export function generateUserData(userList: Array<User>): Array<any> {
    const data: Array<any> = [];
    let user: User;

    for (user of userList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: user.id,
            [propertyConstants.PROPERTY_USERNAME]: user.username,
            [propertyConstants.PROPERTY_COMPANY_ID]: user.companyId,
            [propertyConstants.PROPERTY_PASSWORD]: user.password,
            [propertyConstants.PROPERTY_AUTOLOGIN]: user.autoLogin,
            [propertyConstants.PROPERTY_CAN_UPDATE]: user.canUpdate,
            [propertyConstants.PROPERTY_CAN_DELETE]: user.canDelete,
            [propertyConstants.PROPERTY_USER_GROUP_LIST]: user.userGroupList
        });
    }

    return data;
}

export function generateUserHeadings(
    objectType: objectTypes.ObjectType,
    companyList: Array<Company>,
    userGroupList: Array<UserGroup>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_AUTOLOGIN,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_AUTOLOGIN),
            tableConstants.TABLE_TYPE_BOOLEAN,
            visibleList.includes(propertyConstants.PROPERTY_AUTOLOGIN),
            editableList.includes(propertyConstants.PROPERTY_AUTOLOGIN),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_AUTOLOGIN),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_AUTOLOGIN),
            requiredList.includes(propertyConstants.PROPERTY_AUTOLOGIN),
            [],
            widthOption?.[propertyConstants.PROPERTY_AUTOLOGIN] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_COMPANY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_COMPANY_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            editableList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_COMPANY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            requiredList.includes(propertyConstants.PROPERTY_COMPANY_ID),
            companyList,
            widthOption?.[propertyConstants.PROPERTY_COMPANY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PASSWORD,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PASSWORD),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            editableList.includes(propertyConstants.PROPERTY_PASSWORD),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PASSWORD),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PASSWORD),
            requiredList.includes(propertyConstants.PROPERTY_PASSWORD),
            [],
            widthOption?.[propertyConstants.PROPERTY_PASSWORD] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USER_GROUP_LIST,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USER_GROUP_LIST),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_USER_GROUP_LIST),
            editableList.includes(propertyConstants.PROPERTY_USER_GROUP_LIST),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USER_GROUP_LIST),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USER_GROUP_LIST),
            requiredList.includes(propertyConstants.PROPERTY_USER_GROUP_LIST),
            userGroupList,
            widthOption?.[propertyConstants.PROPERTY_USER_GROUP_LIST] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_USERNAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USERNAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USERNAME),
            editableList.includes(propertyConstants.PROPERTY_USERNAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USERNAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USERNAME),
            requiredList.includes(propertyConstants.PROPERTY_USERNAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_USERNAME] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}

// redlike users
export function generateRedlikeUsersWithUserGroupsData(userList: Array<User>): Array<any> {
    const data: Array<any> = [];
    let user: User;

    for (user of userList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: user.id,
            [propertyConstants.PROPERTY_USERNAME]: user.username,
            [propertyConstants.PROPERTY_USER_GROUP_LIST]: user.userGroupList
        });
    }

    return data;
}

export function generateRedlikeUserWithUserGroupHeadings(
    objectType: objectTypes.ObjectType,
    userGroupList: Array<UserGroup>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list: Array<TableHeading> = [];

    list.push(
        new TableHeading(
            propertyConstants.PROPERTY_USERNAME,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_USERNAME),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_USERNAME),
            editableList.includes(propertyConstants.PROPERTY_USERNAME),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_USERNAME),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_USERNAME),
            requiredList.includes(propertyConstants.PROPERTY_USERNAME),
            [],
            widthOption?.[propertyConstants.PROPERTY_USERNAME] ?? null
        )
    );

    for (const item of userGroupList) {
        list.push(
            new TableHeading(
                propertyConstants.PROPERTY_GENERATED,
                item[propertyConstants.PROPERTY_NAME],
                tableConstants.TABLE_TYPE_ICON,
                visibleList.includes(propertyConstants.PROPERTY_GENERATED),
                editableList.includes(propertyConstants.PROPERTY_GENERATED),
                tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_GENERATED),
                alwaysVisibleList.includes(propertyConstants.PROPERTY_GENERATED),
                requiredList.includes(propertyConstants.PROPERTY_GENERATED),
                [],
                widthOption?.[propertyConstants.PROPERTY_GENERATED] ?? null,
                "",
                item[propertyConstants.PROPERTY_ID] ?? null
            )
        );
    }

    return tableHelper.sortTableHeadings(list, orderList);
}

// TODO check whole
export function generateChangePasswordModalParams(loggedUser: User | null): any {
    return {
        title: translate("general.passwordChange"),
        type: modalTypes.EDIT_MODAL,
        objectType: objectTypes.USER_PASSWORD,
        data: {
            [propertyConstants.PROPERTY_PASSWORD_OLD]: "",
            [propertyConstants.PROPERTY_PASSWORD]: "",
            [propertyConstants.PROPERTY_PASSWORD_NEW]: ""
        },
        headings: [
            {
                [tableConstants.TABLE_HEADING_NAME]: propertyConstants.PROPERTY_PASSWORD_OLD,
                [tableConstants.TABLE_HEADING_TRANSLATION]: translate("general.passwordOld"),
                [tableConstants.TABLE_HEADING_TYPE]: tableConstants.TABLE_TYPE_STRING,
                [tableConstants.TABLE_HEADING_VISIBILITY]: true,
                [tableConstants.TABLE_HEADING_EDITING]: true,
                [tableConstants.TABLE_HEADING_REQUIRED]: true,
                [tableConstants.TABLE_HEADING_ENUM]: []
            },
            {
                [tableConstants.TABLE_HEADING_NAME]: propertyConstants.PROPERTY_PASSWORD,
                [tableConstants.TABLE_HEADING_TRANSLATION]: translate("general.passwordNew"),
                [tableConstants.TABLE_HEADING_TYPE]: tableConstants.TABLE_TYPE_STRING,
                [tableConstants.TABLE_HEADING_VISIBILITY]: true,
                [tableConstants.TABLE_HEADING_EDITING]: true,
                [tableConstants.TABLE_HEADING_REQUIRED]: true,
                [tableConstants.TABLE_HEADING_ENUM]: []
            },
            {
                [tableConstants.TABLE_HEADING_NAME]: propertyConstants.PROPERTY_PASSWORD_NEW,
                [tableConstants.TABLE_HEADING_TRANSLATION]: translate("general.passwordNewRepeat"),
                [tableConstants.TABLE_HEADING_TYPE]: tableConstants.TABLE_TYPE_STRING,
                [tableConstants.TABLE_HEADING_VISIBILITY]: true,
                [tableConstants.TABLE_HEADING_EDITING]: true,
                [tableConstants.TABLE_HEADING_REQUIRED]: true,
                [tableConstants.TABLE_HEADING_ENUM]: []
            }
        ]
    };
}
