// user group keys
export const USER_GROUP_ADMINS = "default_group_admin";
export const USER_GROUP_SUPERADMINS = "default_group_superadmin";
export const USER_GROUP_GUESTS = "default_group_guest";

// server privilege statuses
export type PrivilegeStatusType =
    | typeof PRIVILEGE_STATUS_HIDDEN
    | typeof PRIVILEGE_STATUS_NOT_AVAILABLE
    | typeof PRIVILEGE_STATUS_AVAILABLE;

export const PRIVILEGE_STATUS_HIDDEN = -1;
export const PRIVILEGE_STATUS_NOT_AVAILABLE = 0;
export const PRIVILEGE_STATUS_AVAILABLE = 1;

// privileges
export const PRIVILEGE_DATABASE_UUID_INSERT = "database_uuid_insert";
export const PRIVILEGE_DATABASE_UUID_UPDATE = "database_uuid_update";
export const PRIVILEGE_DATABASE_UUID_VIEW = "database_uuid_view";
export const PRIVILEGE_EXPORT_DELETE_TASK_SHOW = "export_delete_task_show";
export const PRIVILEGE_EXPORT_DELETE_TASK_UPDATE = "export_delete_task_update";
export const PRIVILEGE_EXPORT_HIDE_TASK_SHOW = "export_hide_task_show";
export const PRIVILEGE_EXPORT_HIDE_TASK_UPDATE = "export_hide_task_update";
export const PRIVILEGE_IMPORT_DELETE_TASK_SHOW = "import_delete_task_show";
export const PRIVILEGE_IMPORT_DELETE_TASK_UPDATE = "import_delete_task_update";
export const PRIVILEGE_IMPORT_HIDE_TASK_SHOW = "import_hide_task_show";
export const PRIVILEGE_IMPORT_HIDE_TASK_UPDATE = "import_hide_task_update";
export const PRIVILEGE_LICENSE_DELETE = "license_delete";
export const PRIVILEGE_ONE_TIME_ACCESS_PASSWORD = "one_time_access_password";
export const PRIVILEGE_SETTINGS_MANAGE_TRANSLATIONS = "settings_manage_translations";
export const PRIVILEGE_STATIC_SQL_RUN = "static_sql_run";
export const PRIVILEGE_SYSTEM_UUID_INSERT = "system_uuid_insert";
export const PRIVILEGE_SYSTEM_UUID_UPDATE = "system_uuid_update";
export const PRIVILEGE_SYSTEM_UUID_VIEW = "system_uuid_view";
export const PRIVILEGE_ZONE_REDLIKE_WEB_SHOW = "zone_redlike_web_show";
export const PRIVILEGE_ZONE_UUID_UPDATE = "zone_uuid_update";
