import * as propertyConstants from "../../../constants/propertyConstants";

import React, { Component } from "react";

import { findDOMNode } from "react-dom";

type Props = {
    modalId?: number;
    className: string;
    editable: boolean;
    required: boolean;
    value: number | boolean | string;
    options: Array<{ key: string; value: string }>;
    autoselect: boolean;
    defaultCaption?: string | null;
    readonlyCaption?: string | null;
    callback: (event: any, value: string) => any;
};

type State = {
    selectOn: boolean;
};

export class ModalSelectbox extends Component<Props, State> {
    _selectRef = React.createRef<HTMLDivElement>();
    state: State = {
        selectOn: false
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeSelect);

        if (this.props.autoselect && !this.state.selectOn && this.props.options.length) {
            if (!this.props.value) {
                const newOption = this.props.options[0];
                this.props.callback("", newOption ? newOption[propertyConstants.PROPERTY_KEY] : "");
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            this.props.value !== prevProps.value ||
            JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)
        ) {
            if (this.props.autoselect && !this.state.selectOn && this.props.options.length) {
                if (!this.props.value) {
                    const newOption = this.props.options[0];
                    this.props.callback("", newOption ? newOption[propertyConstants.PROPERTY_KEY] : "");
                }
            }
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeSelect);
    }

    closeSelect = (event: Record<string, any>): void => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                selectOn: false
            });
        }
    };

    handleOptionClick = (event: React.MouseEvent, key: string): void => {
        this.props.callback(event, key);
        this.setState({
            selectOn: false
        });
    };

    handleSelectClick = (): void => {
        this.setState({ selectOn: !this.state.selectOn });
    };

    getClassName = (): string => {
        let newClassName = "input-type-select";

        if (!this.props.editable) {
            newClassName += " disabled";
        }
        if (this.state.selectOn) {
            newClassName += " active";
        }

        return newClassName;
    };

    getDefaultOption = (): string => {
        if (this.props.defaultCaption) {
            return this.props.defaultCaption;
        }

        return "";
    };

    getOptions = (): JSX.Element | null => {
        const optionList = [];

        if (!this.props.required) {
            optionList.push(
                <div className="select-option" key="" onClick={(event: any): void => this.handleOptionClick(event, "")}>
                    {this.getDefaultOption()}
                </div>
            );
        }

        for (const option of this.props.options) {
            const className = option.key === this.props.value?.toString() ? "select-option active" : "select-option";

            optionList.push(
                <div
                    key={option.key}
                    className={className}
                    onClick={(event: any): void => this.handleOptionClick(event, option.key)}
                >
                    {option.value}
                </div>
            );
        }

        // max possible height for options in modals and tables
        const modal = document.getElementById(`modal-${this.props.modalId || ""}`);
        const modalEl: any =
            modal?.querySelector(".bookmarks-content") !== null
                ? modal?.querySelector(".bookmarks-content")
                : modal?.querySelector(".modal-content");
        const tableEl: any = document.querySelector(".table");
        const tableModalEl: any = modal?.querySelector("form") === null ? modal?.querySelector(".table") : null;
        const containerHeight = tableModalEl
            ? tableModalEl.offsetHeight - 71 // 56 + 15
            : modalEl
            ? modalEl.offsetHeight
            : tableEl
            ? tableEl.offsetHeight - 30
            : null;
        const maxHeight =
            containerHeight !== null && this._selectRef.current !== null
                ? containerHeight - this._selectRef.current.offsetTop
                : 100;

        if (this.state.selectOn) {
            return (
                <div className="select-dropdown">
                    <div className="slim-scroll select-options" style={{ maxHeight: maxHeight + "px" }}>
                        {optionList}
                    </div>
                </div>
            );
        }

        return null;
    };

    getNameForSelectBox = (): string => {
        if (!this.props.required && this.props.value === "") {
            return this.getDefaultOption();
        }

        for (const option of this.props.options) {
            if (option.key === this.props.value?.toString()) {
                return option.value;
            }
        }

        // used for empty select boxes with no data
        if (!this.props.editable && this.props.readonlyCaption) {
            return this.props.readonlyCaption;
        }

        return "";
    };

    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <div className={this.getClassName()} ref={this._selectRef} onClick={this.handleSelectClick}>
                    <span className="selected-value">{this.getNameForSelectBox()}</span>
                    {this.getOptions()}
                </div>
            </div>
        );
    }
}
