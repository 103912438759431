import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as serverConstants from "../../constants/serverConstants";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as userActions from "../../actions/userActions";
import * as websocketActions from "../../actions/websocketActions";

import { User, mapRedlikeUser, mapRedlikeUserWithUserGroups } from "../../types/user";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUser(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.MASTER_REDLIKE_USER, userList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_MASTER_REDLIKE_USERS_WITH_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUserWithUserGroups(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [
                universalObjectActions.saveItemsNoParams(objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS, userList)
            ];
        }
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUser(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SYSTEM_REDLIKE_USER, userList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_SYSTEM_REDLIKE_USERS_WITH_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUserWithUserGroups(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [
                universalObjectActions.saveItemsNoParams(objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS, userList)
            ];
        }
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUser(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.ZONE_REDLIKE_USER, userList, response.data.count)];
        }
        case methods.METHOD_GET_ALL_ZONE_REDLIKE_USERS_WITH_GROUPS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const userList: Array<User> = [];
            let user: User | null;

            for (const item of response.data.data) {
                user = mapRedlikeUserWithUserGroups(item);

                if (user) {
                    userList.push(user);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS, userList)];
        }
        case methods.METHOD_ADD_MASTER_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_MASTER_REDLIKE_USERS_ADD_PROPAGATE)
            ];
        }
        case methods.METHOD_ADD_SYSTEM_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_REDLIKE_USERS_ADD_PROPAGATE)
            ];
        }
        case methods.METHOD_ADD_ZONE_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_REDLIKE_USERS_ADD_PROPAGATE)
            ];
        }
        case methods.METHOD_EDIT_MASTER_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];
            const redlikeUser = mapRedlikeUser(response.data);

            if (redlikeUser) {
                requests.push(universalObjectActions.editItemSuccess(objectTypes.MASTER_REDLIKE_USER, [redlikeUser]));
            }

            return requests;
        }
        case methods.METHOD_EDIT_MASTER_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_MASTER_REDLIKE_USERS_EDIT_PROPAGATE)
            ];
        }
        case methods.METHOD_EDIT_SYSTEM_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];
            const redlikeUser = mapRedlikeUser(response.data);

            if (redlikeUser) {
                requests.push(universalObjectActions.editItemSuccess(objectTypes.SYSTEM_REDLIKE_USER, [redlikeUser]));
            }

            return requests;
        }
        case methods.METHOD_EDIT_SYSTEM_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_SYSTEM_REDLIKE_USERS_EDIT_PROPAGATE)
            ];
        }
        case methods.METHOD_EDIT_ZONE_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [universalObjectActions.dataChanged(true)];
            const redlikeUser = mapRedlikeUser(response.data);

            if (redlikeUser) {
                requests.push(universalObjectActions.editItemSuccess(objectTypes.ZONE_REDLIKE_USER, [redlikeUser]));
            }

            return requests;
        }
        case methods.METHOD_EDIT_ZONE_REDLIKE_USER_WITH_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS),
                navigationActions.navigationCloseModal(modalTypes.MODAL_ZONE_REDLIKE_USERS_EDIT_PROPAGATE)
            ];
        }
        case methods.METHOD_DELETE_MASTER_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS),
                universalObjectActions.setActiveItems(objectTypes.MASTER_REDLIKE_USER, [])
            ];
        }
        case methods.METHOD_DELETE_SYSTEM_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.SYSTEM_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS),
                universalObjectActions.setActiveItems(objectTypes.SYSTEM_REDLIKE_USER, [])
            ];
        }
        case methods.METHOD_DELETE_ZONE_REDLIKE_USER: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.dataChanged(true),
                universalObjectActions.reloadData(objectTypes.ZONE_REDLIKE_USER),
                universalObjectActions.getAllItems(objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS),
                universalObjectActions.setActiveItems(objectTypes.ZONE_REDLIKE_USER, [])
            ];
        }
        case methods.METHOD_GET_GENERATE_REDLIKE_ONE_TIME_ACCESS_PASSWORD: {
            if (response.result !== serverConstants.RESULT_OK) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [userActions.savePassword(response.data)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
