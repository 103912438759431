import * as designHelper from "../../helpers/designHelper";
import * as generalHelper from "../../helpers/generalHelper";
import * as menuConstants from "../../constants/menuConstants";
import * as navigationConstants from "../../constants/navigationTypes";
import * as optionActions from "../../actions/optionActions";
import * as optionHelper from "../../helpers/optionHelper";
import * as optionsConstants from "../../constants/optionsConstants";
import * as privilegesHelper from "../../helpers/privilegesHelper";
import * as propertyConstants from "../../constants/propertyConstants";
import * as tableConstants from "../../constants/tableConstants";

import React, { Component } from "react";

import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { SceneCurrencyButtonsContainer } from "../general/scene/buttons/SceneCurrencyButtons";
import { SceneLayoutButtonsContainer } from "../general/scene/buttons/SceneLayoutButtons";
import { SceneSaveButtonContainer } from "../general/scene/buttons/SceneSaveButton";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    tables: Array<JSX.Element>;
    layout: string | null;
    dataPrivileges: any;
    sceneTableSize: Record<string, Record<string, any>> | null;
    sceneConstant: string;
    title?: string;
};
type Props = OwnProps & ContainerProps;

type State = {
    isResizing: boolean;
    resizeItemFirst: string | null;
    resizeItemSecond: string | null;
    resizeStartPosition: number | null;
    firstIndex: number | null;
};

export class ResizingLayoutScene extends Component<Props, State> {
    state: State = {
        isResizing: false,
        resizeItemFirst: null,
        resizeItemSecond: null,
        resizeStartPosition: null,
        firstIndex: null
    };

    componentDidMount() {
        this.checkAndSetSceneTablesSize();
        document.addEventListener("mousemove", this.handleItemResizingMouseMove);
        document.addEventListener("mouseup", this.handleItemResizingMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleItemResizingMouseMove);
        document.removeEventListener("mouseup", this.handleItemResizingMouseUp);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            prevProps.layout !== this.props.layout ||
            JSON.stringify(prevProps.sceneTableSize) !== JSON.stringify(this.props.sceneTableSize)
        ) {
            this.checkAndSetSceneTablesSize();
        }
    }

    handleItemResizingMouseOver = (event: any) => {
        event.currentTarget.classList.add("active");
    };

    handleItemResizingMouseOut = (event: any) => {
        if (!this.state.isResizing) {
            event?.currentTarget.classList.remove("active");
        }
    };

    handleItemResizingMouseDown = (event: any, key: string, secondKey: string, index: number) => {
        this.setState({
            isResizing: true,
            resizeItemFirst: key,
            resizeItemSecond: secondKey,
            firstIndex: index,
            resizeStartPosition: designHelper.getMousePositionSceneResizing(event, this.props.layout, index)
        });
    };

    handleItemResizingMouseMove = (event: any) => {
        if (this.state.isResizing) {
            this.recalculateItems(event);
        }
    };

    handleItemResizingMouseUp = (event: any) => {
        const resizeItem = document.querySelector(".table-resizing.active");
        resizeItem?.classList.remove("active");

        const tableItems = this.recalculateItems(event);
        if (tableItems !== null && JSON.stringify(this.props.sceneTableSize) !== JSON.stringify(tableItems)) {
            const newOptions: Array<{ key: string; object: string; value: any }> = [
                { key: optionsConstants.OPTION_SCENE_TABLE_SIZE, object: this.props.sceneConstant, value: tableItems }
            ];
            this.props.setOptions(newOptions, true);
        }

        this.setState({
            isResizing: false,
            resizeStartPosition: null,
            resizeItemFirst: null,
            resizeItemSecond: null,
            firstIndex: null
        });
    };

    handleItemResizingDoubleClick = () => {
        const defaultSceneTableSize: Record<string, any> =
            optionsConstants.DEFAULT_SCENE_TABLE_SIZE[this.props.sceneConstant];

        if (this.props.layout && this.props.sceneTableSize) {
            const newSceneTableSize = {
                ...this.props.sceneTableSize,
                [this.props.layout]: defaultSceneTableSize[this.props.layout]
            };

            const newOptions: Array<{ key: string; object: string; value: any }> = [
                {
                    key: optionsConstants.OPTION_SCENE_TABLE_SIZE,
                    object: this.props.sceneConstant,
                    value: newSceneTableSize
                }
            ];
            this.props.setOptions(newOptions, true);

            this.setItemsSize(newSceneTableSize[this.props.layout]);
        }
    };

    getThirdItemKey = (item: HTMLElement): string | null => {
        if (item.classList.contains("small-items")) {
            const smallItems = document.querySelectorAll(".small-items");

            for (const smallItem of smallItems) {
                if (smallItem.id !== "" && smallItem.id !== this.state.resizeItemSecond) {
                    return smallItem.id;
                }
            }
        }

        return null;
    };

    getRecalculatedMaxOrMin = (size: number): number => {
        if (size < tableConstants.MINIMAL_TABLE_SIZE) {
            return tableConstants.MINIMAL_TABLE_SIZE;
        }
        if (size > tableConstants.MAXIMAL_TABLE_SIZE) {
            return tableConstants.MAXIMAL_TABLE_SIZE;
        }
        return size;
    };

    // setting new size of items, mouse move calculate into percent according to the size of content, it is different for layout -
    // if height or width is changing, always 2 items changing in the same time, in case of 3 tables and the firstIndex of 0
    // there is need to change the third item the same as the second
    recalculateItems = (event: any): any => {
        const { resizeStartPosition, resizeItemFirst, resizeItemSecond, firstIndex } = this.state;

        if (this.props.sceneTableSize !== null && this.props.layout !== null) {
            let items: Record<string, any> = generalHelper.cloneObject(this.props.sceneTableSize);

            const endPosition = designHelper.getMousePositionSceneResizing(event, this.props.layout, firstIndex);
            const property: propertyConstants.Property | null = designHelper.getSizePropertySceneResizing(
                this.props.layout,
                firstIndex
            );

            const content = document.querySelector(".content-layout");
            const contentSize = designHelper.getElementSizeForLayout(content, this.props.layout, firstIndex);

            if (
                this.state.isResizing &&
                resizeStartPosition !== null &&
                resizeItemFirst &&
                resizeItemSecond &&
                contentSize !== null &&
                property !== null &&
                endPosition !== null
            ) {
                const positionDiff = resizeStartPosition - endPosition;
                const positionDiffPercent = positionDiff / contentSize;
                const secondItem = document.getElementById(resizeItemSecond);

                if (
                    items?.[this.props.layout]?.[resizeItemFirst]?.[property] &&
                    items?.[this.props.layout]?.[resizeItemSecond]?.[property]
                ) {
                    const thirdItem = secondItem !== null ? this.getThirdItemKey(secondItem) : null;

                    // getting actual size from the scene tables size with the new percental change
                    const newFirstItemSize = this.getRecalculatedMaxOrMin(
                        items[this.props.layout][resizeItemFirst][property] - positionDiffPercent
                    );
                    const newSecondItemSize = this.getRecalculatedMaxOrMin(
                        items[this.props.layout][resizeItemSecond][property] + positionDiffPercent
                    );

                    items = {
                        ...items,
                        [this.props.layout]: {
                            ...items[this.props.layout],
                            [resizeItemFirst]: {
                                ...items[this.props.layout][resizeItemFirst],
                                [property]: newFirstItemSize
                            },
                            [resizeItemSecond]: {
                                ...items[this.props.layout][resizeItemSecond],
                                [property]: newSecondItemSize
                            }
                        }
                    };
                    if (thirdItem !== null && firstIndex === 0 && items?.[this.props.layout]?.[thirdItem]?.[property]) {
                        items = {
                            ...items,
                            [this.props.layout]: {
                                ...items[this.props.layout],
                                [thirdItem]: {
                                    ...items[this.props.layout][thirdItem],
                                    [property]: newSecondItemSize
                                }
                            }
                        };
                    }
                }

                if (items[this.props.layout]) {
                    this.setItemsSize(items[this.props.layout]);
                }

                return items;
            }
        }

        return null;
    };

    // size of tables for current layout as input
    setItemsSize = (items: Record<string, Record<string, any>>) => {
        let index = 0;
        for (const [tableKey, tableSize] of Object.entries(items)) {
            this.setItemSize(tableKey, tableSize, index);
            index++;
        }
    };

    // setting size to style of table element
    setItemSize = (key: string, size: Record<string, number>, index: number) => {
        const tableElement = document.getElementById(key);

        if (tableElement) {
            // set maxWidth for small items of bigTop layout
            if (index !== 0 && this.props.layout === optionsConstants.LAYOUT_BIG_TOP) {
                tableElement.style.maxWidth = `${size[propertyConstants.PROPERTY_WIDTH] * 100}%`;
            } else {
                tableElement.style.maxWidth = "unset";
            }

            // set readonly or normal height
            if (
                privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges) &&
                ((this.props.layout === optionsConstants.LAYOUT_BIG_TOP && index === 0) ||
                    (this.props.layout === optionsConstants.LAYOUT_BIG_LEFT && index !== 0))
            ) {
                tableElement.style.height = `calc(${size[propertyConstants.PROPERTY_HEIGHT] * 100}% - 10px)`;
            } else {
                tableElement.style.height = `${size[propertyConstants.PROPERTY_HEIGHT] * 100}%`;
            }

            // set width and resize size
            this.setResizeSize(index, size);
            tableElement.style.width = `${size[propertyConstants.PROPERTY_WIDTH] * 100}%`;
        }
    };

    // setting resizing-item size according to scene table sizes - opposite of property
    setResizeSize = (index: number, size: Record<string, number>) => {
        if (this.props.layout !== null) {
            const item = document.getElementById("table-resizing-" + index.toString());
            const property = designHelper.getSizePropertySceneResizing(this.props.layout, index);
            if (item instanceof HTMLElement) {
                if (property === propertyConstants.PROPERTY_WIDTH) {
                    item.style.height = `${size[propertyConstants.PROPERTY_HEIGHT] * 100}%`;
                } else {
                    item.style.width = `${size[propertyConstants.PROPERTY_WIDTH] * 100}%`;
                }
            }
        }
    };

    // after CDM or CDU setting size of scene tables
    checkAndSetSceneTablesSize = () => {
        const defaultSceneSize: Record<string, any> = optionsConstants.DEFAULT_SCENE_TABLE_SIZE;

        if (
            this.props.sceneTableSize !== null &&
            this.props.layout !== null &&
            this.props.sceneTableSize[this.props.layout]
        ) {
            let valid = true;

            for (const tableValue of Object.values(Object.values(this.props.sceneTableSize[this.props.layout]))) {
                if (
                    tableValue[propertyConstants.PROPERTY_HEIGHT] < tableConstants.MINIMAL_TABLE_SIZE ||
                    tableValue[propertyConstants.PROPERTY_HEIGHT] > 1 ||
                    tableValue[propertyConstants.PROPERTY_WIDTH] < tableConstants.MINIMAL_TABLE_SIZE ||
                    tableValue[propertyConstants.PROPERTY_WIDTH] > 1
                ) {
                    valid = false;
                }
            }

            const newSceneTableSize = valid ? this.props.sceneTableSize : defaultSceneSize[this.props.sceneConstant];

            if (!valid) {
                const newOptions: Array<{ key: string; object: string; value: any }> = [
                    {
                        key: optionsConstants.OPTION_SCENE_TABLE_SIZE,
                        object: this.props.sceneConstant,
                        value: newSceneTableSize
                    }
                ];
                this.props.setOptions(newOptions, true);
            }

            this.setItemsSize(newSceneTableSize[this.props.layout]);
        }
    };

    // layout classNames
    getTableClassName = (index: number, constant: string): string => {
        const className = "layout-item";

        if (constant === menuConstants.TABLE_GENERIC_PREVIEW) {
            return className + " preview-item";
        }

        switch (this.props.layout) {
            case optionsConstants.LAYOUT_PANEL: {
                if (index === 0) {
                    return "left-panel";
                }
                return "right-panel center";
            }
            case optionsConstants.LAYOUT_BIG_LEFT:
            case optionsConstants.LAYOUT_BIG_TOP: {
                if (index === 0) {
                    return className + " layout-big-item";
                }
                return className + " layout-small-items small-items";
            }
            default:
                return className;
        }
    };

    getTables = (): Array<JSX.Element | null> => {
        const tables: Array<JSX.Element | null> = [];

        this.props.tables.forEach((item: JSX.Element, index: number) =>
            tables.push(this.getTable(item, index), this.getItemResizing(index, item.props.tableConstant))
        );

        return tables;
    };

    getTable = (item: JSX.Element, index: number): JSX.Element => {
        return (
            <div
                className={this.getTableClassName(index, item.props.tableConstant)}
                id={item.props.tableConstant}
                key={item.props.tableConstant}
            >
                {item}
            </div>
        );
    };

    getItemResizing = (index: number, key: string): JSX.Element | null => {
        if (
            this.props.tables.length - index > 1 &&
            this.props.layout &&
            this.props.sceneTableSize &&
            this.props.sceneTableSize[this.props.layout]
        ) {
            const secondKey = this.props.tables[index + 1]?.props.tableConstant;
            let className = "table-resizing";

            if (
                this.props.layout === optionsConstants.LAYOUT_BIG_TOP ||
                this.props.layout === optionsConstants.LAYOUT_BIG_LEFT
            ) {
                if (index === 0) {
                    className += " big-table";
                }
            }

            return (
                <div
                    key={index}
                    id={"table-resizing-" + index.toString()}
                    className={className}
                    onDoubleClick={() => this.handleItemResizingDoubleClick()}
                    onMouseDown={(event) => this.handleItemResizingMouseDown(event, key, secondKey, index)}
                    onMouseOver={(event) => this.handleItemResizingMouseOver(event)}
                    onMouseOut={(event) => this.handleItemResizingMouseOut(event)}
                />
            );
        }

        return null;
    };

    getSceneButtons = (): JSX.Element | null => {
        if (
            this.props.tables.length > 1 &&
            navigationConstants.CURRENCY_BUTTONS_SCENES_LIST.includes(this.props.activeScene) &&
            !navigationConstants.NO_LAYOUT_BUTTONS_SCENE_LIST.includes(this.props.activeScene)
        ) {
            return (
                <div className="scene-buttons-container">
                    <SceneCurrencyButtonsContainer dataPrivileges={this.props.dataPrivileges} />
                    <SceneLayoutButtonsContainer
                        sceneConstant={this.props.sceneConstant}
                        dataPrivileges={this.props.dataPrivileges}
                        tablesCount={this.props.tables.length}
                    />
                </div>
            );
        }

        if (
            this.props.tables.length > 1 &&
            !navigationConstants.NO_LAYOUT_BUTTONS_SCENE_LIST.includes(this.props.activeScene)
        ) {
            return (
                <SceneLayoutButtonsContainer
                    sceneConstant={this.props.sceneConstant}
                    dataPrivileges={this.props.dataPrivileges}
                    tablesCount={this.props.tables.length}
                />
            );
        }

        if (navigationConstants.CURRENCY_BUTTONS_SCENES_LIST.includes(this.props.activeScene)) {
            return <SceneCurrencyButtonsContainer dataPrivileges={this.props.dataPrivileges} />;
        }

        return null;
    };

    getLayoutClassName = (): string => {
        let className = optionHelper.getLayoutClassName(this.props.layout, this.props.dataPrivileges);
        className += designHelper.getSceneClassType(this.props.activeScene);

        return className;
    };

    getTitle = (): JSX.Element | null => {
        if (this.props.title) {
            return <div className="scene-title">{this.props.title}</div>;
        }
        return null;
    };

    getContent = (): JSX.Element => {
        if (this.props.title) {
            return (
                <div className={"content-layout"}>
                    {this.getTitle()}
                    <div className={this.getLayoutClassName()}>{this.getTables()}</div>
                </div>
            );
        }
        return <div className={this.getLayoutClassName()}>{this.getTables()}</div>;
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <SceneSaveButtonContainer />
                {this.getSceneButtons()}
                {this.getContent()}
            </div>
        );
    }
}
export type PropsType = Readonly<{
    activeScene: navigationConstants.Scene;
}>;

export type DispatchType = Readonly<{
    setOptions(options: Array<{ key: string; object?: string; value?: any }>, save: boolean): any;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    activeScene: state.navigation.activeScene
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    setOptions: (options: Array<{ key: string; object?: string; value?: any }>, save: boolean): any =>
        dispatch(optionActions.setDictionaryOptions(null, options, save))
});

export const ResizingLayoutSceneContainer = connect(mapStateToProps, mapDispatchToProps)(ResizingLayoutScene);
