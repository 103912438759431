import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { MenuItem } from "../../../types/menu";
import { ProductGroup } from "../../../types/productGroup";
import { ServerRequest } from "../../../types/serverRequest";
import { ZoneProductGroupScene } from "../../../components/scenes/zone/ZoneProductGroupScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    productGroupTableConstant: string;
    productGroupList: Array<ProductGroup>;
    productGroupActiveList: Array<ProductGroup>;
    productGroupLoading: boolean;
    productGroupButtonLoading: boolean;
    productGroupAvailableCount: number | null;
    productGroupColumnOrder: Array<propertyConstants.Property>;
    productGroupColumnVisibility: Record<propertyConstants.Property, boolean>;
    productGroupColumnWidth: Record<propertyConstants.Property, number>;
    productGroupOffset: number;
    productGroupPage: number;
    productGroupRowCount: number;
    productGroupRowCountCustom: boolean;
    productGroupSceneTableSize: Record<string, any> | null;
    productGroupSearch: string | null;
    productGroupSearchParams: Record<propertyConstants.Property, string>;
    productGroupShowFilterRow: boolean;
    productGroupShowGlobalSearch: boolean;
    productGroupSortingAsc: boolean;
    productGroupSortingCriterion: propertyConstants.Property | null;
    productGroupTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_ZONE_PRODUCT_GROUPS
    ),
    productGroupTableConstant: menuConstants.TABLE_ZONE_PRODUCT_GROUPS,
    productGroupList: state.productGroup.zoneList,
    productGroupActiveList: state.productGroup.zoneActiveList,
    productGroupLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_ZONE_PRODUCT_GROUPS ||
            request.method === methods.METHOD_DELETE_ZONE_PRODUCT_GROUP
    ),
    productGroupButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_ZONE_PRODUCT_GROUP
    ),
    productGroupAvailableCount: state.productGroup.zoneCountAvailable,
    productGroupColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
    ),
    productGroupColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
    ),
    productGroupColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
    ),
    productGroupOffset: state.productGroup.zoneOffset,
    productGroupPage: state.productGroup.zonePage,
    productGroupRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
    ),
    productGroupRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_ZONE_PRODUCT_GROUPS
    ),
    productGroupSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_ZONE_PRODUCT_GROUPS
    ),
    productGroupSearch: state.productGroup.zoneSearch,
    productGroupSearchParams: state.productGroup.zoneSearchParams,
    productGroupShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.PAGE_ZONE_PRODUCT_GROUPS
    ),
    productGroupShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.PAGE_ZONE_PRODUCT_GROUPS
    ),
    productGroupSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRODUCT_GROUPS
        )
    ),
    productGroupSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_ZONE_PRODUCT_GROUPS
        )
    ),
    productGroupTotalCount: state.productGroup.zoneCountTotal,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_ZONE_PRODUCT_GROUPS
    )
});

export const ZoneProductGroupSceneContainer = connect(mapStateToProps)(ZoneProductGroupScene);
