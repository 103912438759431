import * as objectTypes from "../../../constants/objectTypes";
import * as optionHelper from "../../../helpers/optionHelper";
import * as propertyConstants from "../../../constants/propertyConstants";

import { FormulaNote, generateFormulaNoteData, generateFormulaNoteHeadings } from "../../../types/formulaNote";
import React, { Component } from "react";
import { TableContainer } from "../../Table";
import { TableHeading } from "../../../types/tableHeading";

type Props = {
    modalId?: number;
    tableConstant: string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    loading: boolean;
    allList: Array<FormulaNote>;
    activeList: Array<FormulaNote>;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    offset: number;
    page: number;
    rowCount: number;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    sortingAsc: boolean;
    sortingCriterion: propertyConstants.Property | null;
    totalCount: number | null;
};

export class FormulaNoteTable extends Component<Props> {
    generateTableHeadings = (): Array<TableHeading> => {
        const tableVisibleColumns = optionHelper.handleTableHeadingVisibility(
            [propertyConstants.PROPERTY_CODE, propertyConstants.PROPERTY_VALUE],
            this.props.columnVisibility
        );

        return generateFormulaNoteHeadings(
            this.props.objectType,
            tableVisibleColumns,
            tableVisibleColumns,
            [],
            tableVisibleColumns,
            [],
            {} as Record<propertyConstants.Property, number>
        );
    };

    render(): JSX.Element {
        return (
            <div className="height-100">
                <TableContainer
                    className={"table-with-filter"}
                    tableConstant={this.props.tableConstant}
                    objectType={this.props.objectType}
                    modalId={this.props.modalId}
                    dataPrivileges={this.props.dataPrivileges}
                    loading={this.props.loading}
                    headings={this.generateTableHeadings()}
                    data={generateFormulaNoteData(this.props.allList)}
                    activeData={this.props.activeList}
                    disableColumnResizing={true}
                    modalProperties={{}}
                    offset={this.props.offset}
                    page={this.props.page}
                    rowCount={this.props.rowCount}
                    search={this.props.search}
                    searchParams={this.props.searchParams}
                    showFilterRow={true}
                    showGlobalSearch={false}
                    sortingAsc={this.props.sortingAsc}
                    sortingCriterion={this.props.sortingCriterion}
                    totalCount={this.props.totalCount}
                />
            </div>
        );
    }
}
