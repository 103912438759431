import * as defaultConstants from "../../constants/default";
import * as designHelper from "../../helpers/designHelper";
import * as formattingHelper from "../../helpers/formattingHelper";
import * as keyCodes from "../../constants/keyCodes";
import * as modalTypes from "../../constants/modalTypes";
import * as propertyConstants from "../../constants/propertyConstants";

import React, { Component } from "react";

import { AppState } from "../../reducers";
import { Dispatch } from "redux";
import { ModalParams } from "../../types/modalParams";
import { ModalType } from "../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { StaticSqlResult } from "../../types/staticSqlResult";

import { connect } from "react-redux";
import imgClose from "../../resources/img/icons/close.svg";
import imgLoader from "../../resources/img/mini-loader.svg";
import imgSuccess from "../../resources/img/icons/success.svg";
import imgWarning from "../../resources/img/icons/warning.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    id: number;
    type: ModalType;
    params: ModalParams;
    index: number;
    closeModal(type: ModalType): void;
};

type Props = ContainerProps & OwnProps;

// TODO unify handleModalKeyDown and params.title, params.displayText/description with e.g.ConfirmationModal: generate title in modal components
class DisplayModal extends Component<Props> {
    componentDidMount(): void {
        document.addEventListener("keydown", this.handleModalKeyDown, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleModalKeyDown);
    }

    handleModalKeyDown = (event: Record<string, any>): void => {
        const { closeModal, type } = this.props;

        switch (event.key) {
            case keyCodes.ENTER: {
                if (event.target.type !== "textarea") {
                    closeModal(type);
                }

                break;
            }
            case keyCodes.ESCAPE: {
                closeModal(type);
                break;
            }
            default: {
                break;
            }
        }
    };

    getModalClassName = (): string => {
        const { type } = this.props;
        const { activeScene } = this.props.params;
        let className = "modal simple-modal";

        if (activeScene) {
            className += designHelper.getModalClassType(activeScene);
        }

        if (type === modalTypes.MODAL_CHANGELOG) {
            className += " changelog-modal";
        }

        if (type === modalTypes.MODAL_WARNING) {
            className += " warning-modal";
        }

        return className;
    };

    getTitle = (): JSX.Element => {
        const { params, type } = this.props;

        if (type === modalTypes.MODAL_WARNING) {
            return (
                <div className="title-container">
                    <ReactSVG src={imgWarning} className="svg-icon" />
                    <div className="title">{params.title ?? ""}</div>
                </div>
            );
        }

        return <div className="title">{params.title ?? ""}</div>;
    };

    getContent = (): JSX.Element => {
        const { type, params, staticSqlResult } = this.props;

        switch (type) {
            case modalTypes.MODAL_FILE_RUN: {
                if (staticSqlResult === null) {
                    return (
                        <div className="row loading">
                            <ReactSVG className={"svg-icon"} src={imgLoader} />
                            {translate("file.runningStaticSql")}
                        </div>
                    );
                }

                if (Object.keys(staticSqlResult?.[propertyConstants.PROPERTY_ERROR_LIST])?.length === 0) {
                    return (
                        <div className="row">
                            <ReactSVG className={"svg-icon success"} src={imgSuccess} />
                            {translate("general.finished")}
                        </div>
                    );
                }
                const items = [];
                for (const [line, value] of Object.entries(staticSqlResult?.[propertyConstants.PROPERTY_ERROR_LIST])) {
                    const lineNumber = Number(line) + 1;

                    items.push(
                        <div key={lineNumber} className="error-list">
                            <span className="line-text">
                                {translate("general.line")} {lineNumber}:{" "}
                            </span>
                            {value}
                        </div>
                    );
                }
                return (
                    <div className="error-list-container">
                        <div className="title">{translate("file.errorOccured")}:</div>
                        {items}
                    </div>
                );
            }
            case modalTypes.MODAL_WARNING: {
                const items = [];

                for (const item in params.data || []) {
                    if (params.data[item].used && params.data[item].available) {
                        items.push(
                            <div className="row" key={item}>
                                <div className="form-label">{translate(params.data[item].title) ?? ""}:</div>
                                <div className="form-input">
                                    <span className="warning-text">
                                        {formattingHelper.formatFileSize(
                                            params.data[item].used,
                                            defaultConstants.SIZE_UNIT_GB
                                        )}
                                    </span>
                                    {" / "}
                                    <span>
                                        {formattingHelper.formatFileSize(
                                            params.data[item].available,
                                            defaultConstants.SIZE_UNIT_GB
                                        )}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                }

                return (
                    <div>
                        <div className="row text">{params.displayText ?? ""}</div>
                        {items}
                    </div>
                );
            }
            default: {
                return <span style={{ whiteSpace: "pre-wrap" }}>{params.displayText ?? ""}</span>;
            }
        }
    };

    getOkButton = (): JSX.Element => {
        const { staticSqlResult, type, closeModal } = this.props;

        if (type === modalTypes.MODAL_FILE_RUN && staticSqlResult === null) {
            return (
                <button className="btn-info pull-right disabled" disabled>
                    {translate("general.ok")}
                </button>
            );
        }

        return (
            <button
                className="btn-info pull-right"
                onClick={(): void => {
                    closeModal(type);
                }}
                autoFocus={true}
            >
                {translate("general.ok")}
            </button>
        );
    };

    render(): JSX.Element | null {
        const { type, closeModal, index } = this.props;

        return (
            <div className={this.getModalClassName()} style={{ zIndex: index }}>
                <header className="modal-header">
                    {this.getTitle()}
                    <button
                        className="modal-close btn-without-style"
                        onClick={(): void => {
                            closeModal(type);
                        }}
                    >
                        <ReactSVG src={imgClose} className="close-img" />
                    </button>
                </header>
                <div className="modal-content slim-scroll">{this.getContent()}</div>
                <footer className="modal-footer">{this.getOkButton()}</footer>
            </div>
        );
    }
}

export type PropsType = Readonly<{
    staticSqlResult: StaticSqlResult | null;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState): PropsType => ({
    staticSqlResult: state.file.staticSqlResult
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const DisplayModalContainer = connect(mapStateToProps, mapDispatchToProps)(DisplayModal);
