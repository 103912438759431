import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as navigationTypes from "../../constants/navigationTypes";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { ImportStatus, mapImportStatus } from "../../types/importStatus";
import { ImportTask, mapImportTask } from "../../types/importTask";
import { ImportType, mapImportType } from "../../types/importType";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_IMPORT_TASK_STATUSES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const importStatuses: Array<ImportStatus> = [];
            let importStatus: ImportStatus | null;

            for (const item of response.data.data) {
                importStatus = mapImportStatus(item);

                if (importStatus) {
                    importStatuses.push(importStatus);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.IMPORT_STATUS, importStatuses)];
        }
        case methods.METHOD_GET_ALL_IMPORT_TASK_TYPES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const importTypes: Array<ImportType> = [];
            let importType: ImportType | null;

            for (const item of response.data.data) {
                importType = mapImportType(item);

                if (importType) {
                    importTypes.push(importType);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.IMPORT_TYPE, importTypes)];
        }
        case methods.METHOD_GET_ALL_IMPORT_TASKS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const importTasks: Array<ImportTask> = [];
            let importTask: ImportTask | null;

            for (const item of response.data.data) {
                importTask = mapImportTask(item);

                if (importTask) {
                    importTasks.push(importTask);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.IMPORT_TASK, importTasks, response.data.count)];
        }
        case methods.METHOD_ADD_IMPORT_TASK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.IMPORT_TASK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMPORT_TASK_ADD)
            ];
        }
        case methods.METHOD_DELETE_IMPORT_TASK:
        case methods.METHOD_HIDE_IMPORT_TASK:
        case methods.METHOD_SHOW_IMPORT_TASK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [universalObjectActions.reloadData(objectTypes.IMPORT_TASK)];
        }
        case methods.METHOD_ABORT_IMPORT_TASK: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [];
        }
        case methods.METHOD_DELETE_IMPORT_TASKS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];

            if (state.navigation.activeScene === navigationTypes.SERVER_MONITORING_SCENE) {
                requests.push(universalObjectActions.getAllItems(objectTypes.DATA_SIZE));
            }

            return [
                ...requests,
                universalObjectActions.reloadData(objectTypes.IMPORT_TASK),
                navigationActions.navigationCloseModal(modalTypes.MODAL_IMPORT_TASKS_DELETE)
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
