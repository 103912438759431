import * as formattingHelper from "./formattingHelper";
import * as generalHelper from "./generalHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as translationConstants from "../constants/translationConstants";

import { getAllLanguages, getLanguageTranslations } from "../lang/translations";
import { setHandleMissingTranslation, setLocale, setTranslations } from "react-i18nify";

import { Language } from "../constants/translationConstants";

// Initialization and language setting

/**
 * Initializes translation keys for all languages, and calls set language method
 * @param selectedLanguage
 */
export const initializeTranslations = (selectedLanguage: string) => {
    setTranslations(getAllTranslations(), true);
    return setRedlikeTranslations(selectedLanguage);
};

/**
 * Validates and sets language
 * @param selectedLanguage
 */
export const setRedlikeTranslations = (selectedLanguage: string): Language => {
    const validatedLanguage = validateLanguage(selectedLanguage);

    setLocale(validatedLanguage, true);
    setHandleMissingTranslation((text) => text);

    return validatedLanguage;
};

/**
 * Finds correct translation function according to selected action type, file type and language
 * @param objectData
 */
export const callTranslationFunction = (objectData: any): string => {
    let text = "";

    // Import types
    if (objectData?.[propertyConstants.PROPERTY_ACTION_TYPE] === translationConstants.TRANSLATION_ACTION_TYPE_IMPORT) {
        if (objectData?.[propertyConstants.PROPERTY_FILE_TYPE] === translationConstants.TRANSLATION_FILE_TYPE_CSV) {
            text = importTranslationsToCsv(
                objectData?.[propertyConstants.PROPERTY_LANGUAGE],
                objectData?.[propertyConstants.PROPERTY_VALUE]
            );
        }
    }

    // Export types
    if (objectData?.[propertyConstants.PROPERTY_ACTION_TYPE] === translationConstants.TRANSLATION_ACTION_TYPE_EXPORT) {
        if (objectData?.[propertyConstants.PROPERTY_FILE_TYPE] === translationConstants.TRANSLATION_FILE_TYPE_CSV) {
            text = exportTranslationsToCsv(objectData?.[propertyConstants.PROPERTY_LANGUAGE]);
        }
        if (objectData?.[propertyConstants.PROPERTY_FILE_TYPE] === translationConstants.TRANSLATION_FILE_TYPE_CSV_NEW) {
            text = exportTranslationsToCsvNew(objectData?.[propertyConstants.PROPERTY_LANGUAGE]);
        }
    }

    return text;
};

// Export specific functions

/**
 * Exports translation keys according to selected language to *.csv format.
 * @param language
 */
export const exportTranslationsToCsv = (language: Language): string => {
    let result: Record<string, any> = {};
    const englishTranslations = getLanguageTranslations(translationConstants.ENGLISH);
    const defaultTranslations = getLanguageTranslations(language);
    const translations = mergeDefaultTranslations(englishTranslations, defaultTranslations);

    let key;
    for (key of Object.keys(translations)) {
        result = getChildKeys(result, translations, key, key);
    }

    return stringifyCsvExport(sortTranslationKeys(result) as Record<string, any>);
};

/**
 * Exports new translation keys according to selected language to *.csv format.
 * @param language
 */
export const exportTranslationsToCsvNew = (language: Language): string => {
    let result: Record<string, any> = {};
    const englishTranslations = getLanguageTranslations(translationConstants.ENGLISH);
    const defaultTranslations = getLanguageTranslations(language);
    const translations = getNewTranslations(englishTranslations, defaultTranslations);

    let key;
    for (key of Object.keys(translations)) {
        result = getChildKeys(result, translations, key, key);
    }

    return stringifyCsvExport(sortTranslationKeys(result) as Record<string, any>);
};

function stringifyCsvExport(translation: Record<string, any>): string {
    return Object.keys(translation)
        .map((key) => key + ";" + translation[key])
        .join("\n");
}

// Import specific functions

/**
 * Reads *.csv string and creates JSON object with translation keys.
 * @param language
 * @param value
 */
export const importTranslationsToCsv = (language: Language, value: string): string => {
    const rows = value.split("\n");

    rows.sort(function (a, b) {
        if (a < b) {
            return -1;
        }

        if (a > b) {
            return 1;
        }

        return 0;
    });

    let result: Record<string, any> = {};

    for (const row of rows) {
        if (row.includes('"')) {
            return `Invalid sign " found at line ${row}`;
        }

        const keyValue = row.split(";");

        if (keyValue.length !== 2) {
            return `Invalid line ${row}, wrong definition of key;value, no empty lines are allowed`;
        }

        if (!isValueWithParametersValid(keyValue[1])) {
            return `Wrong parameters defined at line ${row}`;
        }

        const keys = keyValue[0].split("_");

        if (keys[0] !== "") {
            result = createRecursiveKeys(result, keyValue[0].split("_"), keyValue[1]);
        }
    }

    const defaultTranslations = getLanguageTranslations(translationConstants.ENGLISH);
    const translations = getLanguageTranslations(language);
    const defaultResult = mergeDefaultTranslations(defaultTranslations, translations);
    const finalResult = mergeDefaultTranslations(defaultResult, result);
    const validation = validateTranslationParams(defaultTranslations, finalResult);

    if (validation !== null) {
        return validation;
    }

    return formattingHelper.formatJson(finalResult);
};

function createRecursiveKeys(result: Record<string, any>, keys: Array<string>, value: string): Record<string, any> {
    if (keys.length === 1) {
        try {
            result[keys[0]] = value;
        } catch (e) {
            return {};
        }

        return result;
    }

    if (!result[keys[0]]) {
        result[keys[0]] = createRecursiveKeys({}, keys.slice(1, keys.length), value);
        return result;
    }

    result[keys[0]] = createRecursiveKeys(result[keys[0]], keys.slice(1, keys.length), value);
    return result;
}

function isValueWithParametersValid(value: string): boolean {
    if (value.match(/[%{}]/) === null) {
        return true;
    }

    const parameters = value.match(/{/g);

    if (parameters === null) {
        return true;
    }

    const validParameters = value.match(/%{[a-zA-Z0-9]+}/g);

    if (validParameters === null || validParameters.length === parameters.length) {
        return true;
    }

    return false;
}

function areParametersValid(key: string, defaultTranslation: string, translation: string) {
    if (defaultTranslation.match(/[%{}]/) === null) {
        return null;
    }

    const validParameters = defaultTranslation.match(/%{[a-zA-Z0-9]+}/g);

    if (validParameters === null) {
        return null;
    }

    const parameters = translation.match(/%{[a-zA-Z0-9]+}/g);

    if (parameters === null || validParameters.length !== parameters.length) {
        return `There is wrong number of parameters in translation ${key}`;
    }

    for (let i = 0; i < validParameters.length; i++) {
        if (validParameters[i] !== parameters[i]) {
            return `There is wrong name of parameter in translation ${key}`;
        }
    }

    return null;
}

function validateParams(
    defaultTranslation: Record<string, any>,
    translation: Record<string, any>,
    key: string
): null | string {
    if (typeof defaultTranslation[key] === "string") {
        if (translation && translation[key]) {
            return areParametersValid(key, defaultTranslation[key], translation[key]);
        }

        return null;
    }

    if (translation[key] === undefined) {
        return null;
    }

    let result = null;

    for (const k of Object.keys(defaultTranslation[key])) {
        result = validateParams(defaultTranslation[key], translation[key], k);

        if (result !== null) {
            return result;
        }
    }

    return null;
}

function validateTranslationParams(defaultTranslation: Record<string, any>, translations: Record<string, any>) {
    let result = null;

    for (const key of Object.keys(defaultTranslation)) {
        result = validateParams(defaultTranslation, translations, key);

        if (result !== null) {
            return result;
        }
    }

    return null;
}

// Generic translation functions

function validateLanguage(language: string): Language {
    const allLanguages = getAllLanguages();
    const item = allLanguages.find((item) => item[propertyConstants.PROPERTY_CODE] === language) ?? null;

    return item?.[propertyConstants.PROPERTY_CODE] ?? translationConstants.ENGLISH;
}

function getAllTranslations(): Record<string, any> {
    const allLanguages = getAllLanguages();
    const englishTranslations = getLanguageTranslations(translationConstants.ENGLISH);
    const allTranslations: Record<string, any> = {};

    for (const language of allLanguages) {
        const languageTranslations = getLanguageTranslations(language.code);
        allTranslations[language.code] = mergeDefaultTranslations(englishTranslations, languageTranslations);
    }

    return allTranslations;
}

function mergeDefaultTranslations(
    defaultTranslation: Record<string, any>,
    translation: Record<string, any>
): Record<string, any> {
    const result: Record<string, any> = {};

    let key;
    for (key of Object.keys(defaultTranslation)) {
        result[key] = getChildValues(key, defaultTranslation, translation);
    }

    return sortTranslationKeys(result) as Record<string, any>;
}

function getNewTranslations(
    defaultTranslation: Record<string, any>,
    translation: Record<string, any>
): Record<string, any> {
    const result: Record<string, any> = {};

    let newTranslations;
    for (const key of Object.keys(defaultTranslation)) {
        newTranslations = getUntranslatedValues(defaultTranslation, translation, key) as Record<string, any>;

        if (Object.keys(newTranslations).length > 0) {
            result[key] = newTranslations;
        }
    }

    return sortTranslationKeys(result) as Record<string, any>;
}

function getUntranslatedValues(
    defaultTranslation: Record<string, any>,
    translation: Record<string, any>,
    key: string
): string | Record<string, any> | null {
    // Check string values
    if (typeof defaultTranslation[key] === "string") {
        if (translation && translation[key] && translation[key] !== defaultTranslation[key]) {
            return null;
        }

        return defaultTranslation[key];
    }

    // Check undefined object values
    if (translation[key] === undefined) {
        return sortTranslationKeys(defaultTranslation[key]);
    }

    // Recursive check of objects
    const result: Record<string, any> = {};

    for (const k of Object.keys(defaultTranslation[key])) {
        const newTranslation = getUntranslatedValues(defaultTranslation[key], translation[key], k);

        if (
            newTranslation !== null &&
            (typeof defaultTranslation[key] === "string" || Object.keys(newTranslation).length > 0)
        ) {
            result[k] = newTranslation;
        }
    }

    return sortTranslationKeys(result);
}

function getChildValues(
    key: string,
    defaultTranslation: Record<string, any>,
    translation: Record<string, any>
): string | Record<string, any> {
    // Key not found in translations, replace it by English default translations
    if (translation[key] === undefined) {
        return sortTranslationKeys(defaultTranslation[key]);
    }

    // Key is defined as string, not as another object
    if (typeof defaultTranslation[key] === "string") {
        return translation?.[key] ?? defaultTranslation[key];
    }

    // Key is defined in translations but it is a nested object
    const result = generalHelper.cloneObject(defaultTranslation[key] as Record<string, any>);

    for (const nestedKey of Object.keys(defaultTranslation[key])) {
        result[nestedKey] = getChildValues(nestedKey, defaultTranslation[key], translation[key]);
    }

    return sortTranslationKeys(result);
}

function getChildKeys(
    result: Record<string, any>,
    translations: Record<string, any>,
    parentKey: string,
    key: string
): Record<string, any> {
    if (typeof translations[key] === "string") {
        result[parentKey] = translations[key];

        return result;
    }

    for (const k of Object.keys(translations[key])) {
        getChildKeys(result, translations[key], `${parentKey}_${k}`, k);
    }

    return result;
}

function sortTranslationKeys(translation: string | Record<string, any>): string | Record<string, any> {
    if (typeof translation === "string") {
        return translation;
    }

    const translationKeys = Object.keys(translation);

    translationKeys.sort(function (a, b) {
        if (a < b) {
            return -1;
        }

        if (a > b) {
            return 1;
        }

        return 0;
    });

    const result: Record<string, string | Record<string, any>> = {};

    for (const key of translationKeys) {
        result[key] = translation[key];
    }

    return result;
}
