import * as propertyConstants from "./propertyConstants";

// heading properties
export const TABLE_HEADINGS = "headings";
export const TABLE_HEADING_ALWAYS_VISIBLE = "table_heading_always_visible";
export const TABLE_HEADING_CLASS = "table_heading_class";
export const TABLE_HEADING_EDITING = "table_heading_editing";
export const TABLE_HEADING_ENUM = "table_heading_enum";
export const TABLE_HEADING_FILTERABLE = "table_heading_filterable";
export const TABLE_HEADING_NAME = "table_heading_name";
export const TABLE_HEADING_REQUIRED = "table_heading_required";
export const TABLE_HEADING_TRANSLATION = "table_heading_translation";
export const TABLE_HEADING_TYPE = "table_heading_type";
export const TABLE_HEADING_VISIBILITY = "table_heading_visibility";
export const TABLE_HEADING_WIDTH = "table_heading_width";
export const TABLE_HEADING_GENERATED_KEY = "table_heading_generated_key";

/**
 * Cell types
 * TABLE_TYPE_DECIMAL: 0.1, 0.2, 0.3, ...
 * TABLE_TYPE_INTEGER: ..., -2, -1, 0, 1, 2, ...
 * TABLE_TYPE_NUMBER: 0, 1, 2, ...
 * TABLE_TYPE_REAL_NUMBER: ..., -0.2, -0.1, 0, 0.1, 0.2, ...
 */

export const TABLE_TYPE_ADDITIONAL_LABEL = "table_type_additional_label";
export const TABLE_TYPE_ALERT = "table_type_alert";
export const TABLE_TYPE_BOOLEAN = "table_type_boolean";
export const TABLE_TYPE_BUTTON = "table_type_button";
export const TABLE_TYPE_BUTTON_SECTION = "table_type_button_section";
export const TABLE_TYPE_DATE = "table_type_date";
export const TABLE_TYPE_DATE_PICKER = "table_type_date_picker";
export const TABLE_TYPE_DECIMAL = "table_type_decimal";
export const TABLE_TYPE_FILE = "table_type_file";
export const TABLE_TYPE_ICON = "table_type_icon";
export const TABLE_TYPE_IMAGE = "table_type_image";
export const TABLE_TYPE_IMAGE_SECTION = "table_type_image_section";
export const TABLE_TYPE_INTEGER = "table_type_integer";
export const TABLE_TYPE_NUMBER = "table_type_number";
export const TABLE_TYPE_LABEL = "table_type_label";
export const TABLE_TYPE_LINE = "table_type_line";
export const TABLE_TYPE_LIST = "table_type_list";
export const TABLE_TYPE_PERCENT = "table_type_percent";
export const TABLE_TYPE_PRICE = "table_type_price";
export const TABLE_TYPE_RADIO_ICON = "table_type_radio_icon";
export const TABLE_TYPE_REAL_NUMBER = "table_type_real_number";
export const TABLE_TYPE_SELECT = "table_type_select";
export const TABLE_TYPE_SPACE = "table_type_space";
export const TABLE_TYPE_STATUS = "table_type_status";
export const TABLE_TYPE_STRING = "table_type_string";
export const TABLE_TYPE_TEXT = "table_type_text";
export const TABLE_TYPE_UNIVERSAL = "table_type_universal";

export type TableType =
    | typeof TABLE_TYPE_ADDITIONAL_LABEL
    | typeof TABLE_TYPE_ALERT
    | typeof TABLE_TYPE_BOOLEAN
    | typeof TABLE_TYPE_BUTTON
    | typeof TABLE_TYPE_BUTTON_SECTION
    | typeof TABLE_TYPE_DATE
    | typeof TABLE_TYPE_DATE_PICKER
    | typeof TABLE_TYPE_DECIMAL
    | typeof TABLE_TYPE_FILE
    | typeof TABLE_TYPE_ICON
    | typeof TABLE_TYPE_IMAGE
    | typeof TABLE_TYPE_IMAGE_SECTION
    | typeof TABLE_TYPE_INTEGER
    | typeof TABLE_TYPE_LABEL
    | typeof TABLE_TYPE_LINE
    | typeof TABLE_TYPE_LIST
    | typeof TABLE_TYPE_NUMBER
    | typeof TABLE_TYPE_PERCENT
    | typeof TABLE_TYPE_PRICE
    | typeof TABLE_TYPE_RADIO_ICON
    | typeof TABLE_TYPE_REAL_NUMBER
    | typeof TABLE_TYPE_SELECT
    | typeof TABLE_TYPE_SPACE
    | typeof TABLE_TYPE_STATUS
    | typeof TABLE_TYPE_STRING
    | typeof TABLE_TYPE_TEXT
    | typeof TABLE_TYPE_UNIVERSAL;

// default values for table options
export const DEFAULT_COLUMN_WIDTH = 50;
export const DEFAULT_CHANGED_ONLY = false;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_ROW_COUNT = 10;
export const DEFAULT_ROW_COUNT_CUSTOM = false;
export const DEFAULT_SEARCH = null;
export const DEFAULT_SEARCH_PARAMS = {} as Record<propertyConstants.Property, string>;
export const DEFAULT_SHOW_DEFAULT_VALUES = true;
export const DEFAULT_SHOW_FILTER_ROW = true;
export const DEFAULT_SHOW_GLOBAL_SEARCH = true;
export const DEFAULT_SORTING_ASC = true;
export const DEFAULT_SORTING_COLUMN = propertyConstants.PROPERTY_NAME;
export const DEFAULT_TOTAL_COUNT = null;
export const EMPTY_COLUMN_WIDTH = {} as Record<propertyConstants.Property, number>;
export const MINIMAL_COLUMN_WIDTH = 30;
export const MINIMAL_TABLE_SIZE = 0.2;
export const MAXIMAL_TABLE_SIZE = 0.8;
export const ROWS_IN_TABLE = [10, 25, 50, 100];

// search constants
export const FILE_SEARCH = "fileSearch";
export const FILE_SEARCH_PARAMS = "fileSearchParams";
export const MASTER_SEARCH = "masterSearch";
export const MASTER_SEARCH_PARAMS = "masterSearchParams";
export const MASTER_SYSTEM_SEARCH = "masterSystemSearch";
export const MASTER_SYSTEM_SEARCH_PARAMS = "masterSystemSearchParams";
export const PRODUCT_SHEET_SEARCH = "productSheetSearch";
export const PRODUCT_SHEET_SEARCH_PARAMS = "productSheetSearchParams";
export const SEARCH = "search";
export const SEARCH_PARAMS = "searchParams";
export const STATIC_SQL_SEARCH = "staticSqlSearch";
export const STATIC_SQL_SEARCH_PARAMS = "staticSqlSearchParams";
export const SYSTEM_MARGIN_SEARCH = "systemMarginSearch";
export const SYSTEM_MARGIN_SEARCH_PARAMS = "systemMarginSearchParams";
export const SYSTEM_MASTER_SEARCH = "systemMasterSearch";
export const SYSTEM_MASTER_SEARCH_PARAMS = "systemMasterSearchParams";
export const SYSTEM_SEARCH = "systemSearch";
export const SYSTEM_SEARCH_PARAMS = "systemSearchParams";
export const SYSTEM_ZONE_SEARCH = "systemZoneSearch";
export const SYSTEM_ZONE_SEARCH_PARAMS = "systemZoneSearchParams";
export const WEB_SEARCH = "webSearch";
export const WEB_SEARCH_PARAMS = "webSearchParams";
export const ZONE_MARGIN_SEARCH = "zoneMarginSearch";
export const ZONE_MARGIN_SEARCH_PARAMS = "zoneMarginSearchParams";
export const ZONE_SEARCH = "zoneSearch";
export const ZONE_SEARCH_PARAMS = "zoneSearchParams";
export const ZONE_SYSTEM_SEARCH = "zoneSystemSearch";
export const ZONE_SYSTEM_SEARCH_PARAMS = "zoneSystemSearchParams";

// table action events
export const EXPORT_STATUS_EVENT_ABORT = "export_abort";
export const EXPORT_STATUS_EVENT_DOWNLOAD = "export_download";
export const EXPORT_STATUS_EVENT_ERROR = "export_error";
export const FILE_DOWNLOAD = "file_download";
export const IMPORT_STATUS_EVENT_ABORT = "import_abort";
export const IMPORT_STATUS_EVENT_ERROR = "import_error";
export const IMPORT_STATUS_EVENT_WARNING = "import_warning";

// properties for filtering
export const FILTERABLE_COLUMNS = [
    propertyConstants.PROPERTY_ACTION_TYPE_ID,
    propertyConstants.PROPERTY_BARCODE,
    propertyConstants.PROPERTY_BASE_ID,
    propertyConstants.PROPERTY_BASE_NAME,
    propertyConstants.PROPERTY_BATCH_NUMBER,
    propertyConstants.PROPERTY_BFS,
    propertyConstants.PROPERTY_CHANGELOG,
    propertyConstants.PROPERTY_CODE,
    propertyConstants.PROPERTY_COEFFICIENT,
    propertyConstants.PROPERTY_COLOR_CODE,
    propertyConstants.PROPERTY_COLOR_NAME,
    propertyConstants.PROPERTY_COMPANY_KEY,
    propertyConstants.PROPERTY_DATABASE_NAME,
    propertyConstants.PROPERTY_DENSITY,
    propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID,
    propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID,
    propertyConstants.PROPERTY_FILE_CATEGORY_ID,
    propertyConstants.PROPERTY_FILE_TYPE_ID,
    propertyConstants.PROPERTY_IMAGE_CATEGORY_ID,
    propertyConstants.PROPERTY_IMPORT_TASK_STATUS_ID,
    propertyConstants.PROPERTY_IMPORT_TASK_TYPE_ID,
    propertyConstants.PROPERTY_ITEM_TYPE_ID,
    propertyConstants.PROPERTY_MORE_INFO,
    propertyConstants.PROPERTY_NAME,
    propertyConstants.PROPERTY_NAME_MASTER,
    propertyConstants.PROPERTY_NAME_SYSTEM,
    propertyConstants.PROPERTY_NOMINAL_AMOUNT,
    propertyConstants.PROPERTY_PACKAGE_NAME,
    propertyConstants.PROPERTY_PACKAGE_NOMINAL_AMOUNT,
    propertyConstants.PROPERTY_PATH,
    propertyConstants.PROPERTY_PRIORITY,
    propertyConstants.PROPERTY_PRODUCT_ID,
    propertyConstants.PROPERTY_PRODUCT_NAME,
    propertyConstants.PROPERTY_SEARCH_CODE,
    propertyConstants.PROPERTY_SEARCH_NAME,
    propertyConstants.PROPERTY_STATUS_ID,
    propertyConstants.PROPERTY_SYSTEM_ID,
    propertyConstants.PROPERTY_SYSTEM_NAME,
    propertyConstants.PROPERTY_SYSTEM_UUID,
    propertyConstants.PROPERTY_TYPE_ID,
    propertyConstants.PROPERTY_USERNAME,
    propertyConstants.PROPERTY_UUID,
    propertyConstants.PROPERTY_VALUE,
    propertyConstants.PROPERTY_VALUE_MASTER,
    propertyConstants.PROPERTY_VALUE_SYSTEM,
    propertyConstants.PROPERTY_VERSION_NAME,
    propertyConstants.PROPERTY_ZONE_ID,
    propertyConstants.PROPERTY_ZONE_NAME,
    propertyConstants.PROPERTY_ZONE_UUID
];

// properties for sorting
export const SORTABLE_COLUMNS = [
    propertyConstants.PROPERTY_AMOUNT,
    propertyConstants.PROPERTY_AMOUNT_COEFFICIENT,
    propertyConstants.PROPERTY_BASE_NAME,
    propertyConstants.PROPERTY_BATCH_NUMBER,
    propertyConstants.PROPERTY_BFS,
    propertyConstants.PROPERTY_CHANGELOG,
    propertyConstants.PROPERTY_CODE,
    propertyConstants.PROPERTY_COLOR_CODE,
    propertyConstants.PROPERTY_COLOR_NAME,
    propertyConstants.PROPERTY_COLORANT_NAME,
    propertyConstants.PROPERTY_COMPANY_KEY,
    propertyConstants.PROPERTY_DATABASE_NAME,
    propertyConstants.PROPERTY_DATE_CREATED,
    propertyConstants.PROPERTY_DATE_FINISHED,
    propertyConstants.PROPERTY_DENSITY,
    propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID,
    propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID,
    propertyConstants.PROPERTY_FILL_PROC,
    propertyConstants.PROPERTY_FILL_VOLUME,
    propertyConstants.PROPERTY_HEIGHT,
    propertyConstants.PROPERTY_ID,
    propertyConstants.PROPERTY_KEY,
    propertyConstants.PROPERTY_LAST_STATUS_CHANGE,
    propertyConstants.PROPERTY_LENGTH,
    propertyConstants.PROPERTY_MORE_INFO,
    propertyConstants.PROPERTY_NAME,
    propertyConstants.PROPERTY_NAME_MASTER,
    propertyConstants.PROPERTY_NAME_SYSTEM,
    propertyConstants.PROPERTY_NOMINAL_AMOUNT,
    propertyConstants.PROPERTY_PACKAGE_NOMINAL_AMOUNT,
    propertyConstants.PROPERTY_PATH,
    propertyConstants.PROPERTY_PRIORITY,
    propertyConstants.PROPERTY_PRODUCTION_DATE,
    propertyConstants.PROPERTY_SYSTEM_NAME,
    propertyConstants.PROPERTY_SYSTEM_UUID,
    propertyConstants.PROPERTY_TYPE_ID,
    propertyConstants.PROPERTY_USERNAME,
    propertyConstants.PROPERTY_UUID,
    propertyConstants.PROPERTY_VALUE,
    propertyConstants.PROPERTY_VALUE_MASTER,
    propertyConstants.PROPERTY_VALUE_SYSTEM,
    propertyConstants.PROPERTY_VERSION,
    propertyConstants.PROPERTY_VERSION_NAME,
    propertyConstants.PROPERTY_WIDTH,
    propertyConstants.PROPERTY_ZONE_NAME,
    propertyConstants.PROPERTY_ZONE_UUID
];

// properties displayed as parent properties with different color
export const MASTER_PROPERTIES = [
    propertyConstants.PROPERTY_BASE_NAME_MASTER,
    propertyConstants.PROPERTY_NAME_MASTER,
    propertyConstants.PROPERTY_PACKAGE_NAME_MASTER,
    propertyConstants.PROPERTY_VALUE_MASTER
];

export const SYSTEM_PROPERTIES = [
    propertyConstants.PROPERTY_BASE_NAME_SYSTEM,
    propertyConstants.PROPERTY_NAME_SYSTEM,
    propertyConstants.PROPERTY_PACKAGE_NAME_SYSTEM,
    propertyConstants.PROPERTY_VALUE_SYSTEM
];
