import * as objectTypes from "../constants/objectTypes";
import * as privilegeConstants from "../constants/privilegeConstants";
import * as privilegesHelper from "../helpers/privilegesHelper";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";
import * as validationHelper from "../helpers/validationHelper";

import { TableHeading } from "./tableHeading";
import { User } from "./user";

import { t as translate } from "react-i18nify";

export class Privilege {
    id: string;
    key: string;
    value: privilegeConstants.PrivilegeStatusType;
    valueSource: number;
    description: string;

    constructor(
        id: string,
        key: string,
        value: privilegeConstants.PrivilegeStatusType,
        valueSource: number,
        description: string
    ) {
        this.id = id;
        this.key = key;
        this.value = value;
        this.valueSource = valueSource;
        this.description = description;
    }
}

export function mapRedlikePrivilege(data: Record<string, any>, loggedUser: User | null): Privilege | null {
    try {
        if (data.description === null && !privilegesHelper.isSuperadmin(loggedUser)) {
            return null;
        }
        const descriptionTranslation = translate(
            "privileges.description." + validationHelper.formatTranslationKey(data.key)
        );
        const description =
            descriptionTranslation !== validationHelper.formatTranslationKey(data.key) && descriptionTranslation !== ""
                ? descriptionTranslation
                : data.description;

        return new Privilege(data.key, data.key, data.value, data.valueSource, description);
    } catch (e) {
        return null;
    }
}

export function updateRedlikePrivilege(
    oldPrivilege: Privilege | null,
    updatedData: Record<string, any>
): Privilege | null {
    if (oldPrivilege === null) {
        return null;
    }

    try {
        return new Privilege(
            oldPrivilege.id,
            oldPrivilege.key,
            updatedData.value ? updatedData.value : oldPrivilege.value,
            oldPrivilege.valueSource,
            oldPrivilege.description
        );
    } catch (e) {
        return null;
    }
}

export function generatePrivilegeData(privilegeList: Array<Privilege>): Array<any> {
    const data: Array<any> = [];

    let privilege: Privilege;

    for (privilege of privilegeList) {
        data.push({
            [propertyConstants.PROPERTY_ID]: privilege.id,
            [propertyConstants.PROPERTY_KEY]: privilege.key,
            [propertyConstants.PROPERTY_VALUE]: privilege.value,
            [propertyConstants.PROPERTY_VALUE_SOURCE]: privilege.valueSource,
            [propertyConstants.PROPERTY_DESCRIPTION]: privilege.description
        });
    }

    return data;
}

export function generatePrivilegeHeadings(
    objectType: objectTypes.ObjectType,
    privilegeStatuses: Array<any>,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property>,
    alwaysVisibleList: Array<propertyConstants.Property>,
    widthOption: Record<propertyConstants.Property, number>
): Array<TableHeading> {
    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_DESCRIPTION,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_DESCRIPTION),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            editableList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_DESCRIPTION),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            requiredList.includes(propertyConstants.PROPERTY_DESCRIPTION),
            [],
            widthOption?.[propertyConstants.PROPERTY_DESCRIPTION] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_KEY,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_KEY),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_KEY),
            editableList.includes(propertyConstants.PROPERTY_KEY),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_KEY),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_KEY),
            requiredList.includes(propertyConstants.PROPERTY_KEY),
            [],
            widthOption?.[propertyConstants.PROPERTY_KEY] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_VALUE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_VALUE),
            tableConstants.TABLE_TYPE_RADIO_ICON,
            visibleList.includes(propertyConstants.PROPERTY_VALUE),
            editableList.includes(propertyConstants.PROPERTY_VALUE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_VALUE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_VALUE),
            requiredList.includes(propertyConstants.PROPERTY_VALUE),
            privilegeStatuses,
            widthOption?.[propertyConstants.PROPERTY_VALUE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
