import * as actionConstants from "../constants/actionTypes";
import * as objectTypes from "../constants/objectTypes";
import * as serverMethods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export function deleteUploadedFile(fileUuid: string): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: serverMethods.METHOD_DELETE_UPLOADED_FILE,
            params: {
                fileUuid: fileUuid
            }
        },
        method: serverMethods.METHOD_DELETE_UPLOADED_FILE
    };
}

export interface FileListUpdate {
    type: typeof actionConstants.UPDATE_FILE_LIST;
    payload: {
        objectType: objectTypes.ObjectType;
        fileList: Array<File>;
    };
}

export function updateFileList(objectType: objectTypes.ObjectType, fileList: Array<File>): FileListUpdate {
    return {
        type: actionConstants.UPDATE_FILE_LIST,
        payload: {
            objectType,
            fileList
        }
    };
}
