import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as importExportConstants from "../constants/entityConstants/importExportConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { ImportTask, updateImportTaskIsChecked } from "../types/importTask";

import { ImportStatus } from "../types/importStatus";
import { ImportType } from "../types/importType";

export type ImportState = Readonly<{
    importStatuses: Array<ImportStatus>;
    importTypes: Array<ImportType>;
    list: Array<ImportTask>;
    activeList: Array<ImportTask>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
}>;

const initialState: ImportState = {
    importStatuses: [],
    importTypes: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE
};

export default function imports(state: ImportState = initialState, action: Record<string, any>): ImportState {
    switch (action.type) {
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            if (action.payload.objectType === objectTypes.IMPORT_STATUS) {
                return {
                    ...state,
                    importStatuses: action.payload.list
                };
            }

            if (action.payload.objectType === objectTypes.IMPORT_TYPE) {
                return {
                    ...state,
                    importTypes: action.payload.list
                };
            }

            return state;
        }
        case actionConstants.LIST_SAVE: {
            if (action.payload.objectType === objectTypes.IMPORT_TASK) {
                const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                return {
                    ...state,
                    list: action.payload.list,
                    activeList: newActiveList,
                    count: action.payload.totalCount
                };
            }

            return state;
        }
        case actionConstants.SET_ACTIVE: {
            if (action.payload.objectType === objectTypes.IMPORT_TASK) {
                const list = generalHelper.updateLists(state.list, action.payload.items);
                const newActiveList = generalHelper.updateActiveLists(action.payload.items, list);

                return {
                    ...state,
                    list: list,
                    activeList: newActiveList
                };
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            if (action.payload.objectType === objectTypes.IMPORT_TASK) {
                return {
                    ...state,
                    offset: action.payload.offset,
                    page: action.payload.page
                };
            }

            return state;
        }
        case actionConstants.SET_SEARCH: {
            if (action.payload.objectType === objectTypes.IMPORT_TASK) {
                return reducerHelper.getSearchState(
                    action.payload,
                    state,
                    tableConstants.SEARCH,
                    tableConstants.SEARCH_PARAMS
                );
            }

            return state;
        }
        case actionConstants.CHECK_ALL: {
            if (action.payload.objectType === objectTypes.IMPORT_TASK) {
                const updatedImportTaskList: Array<ImportTask> = [];

                for (const item of state.list) {
                    if (item[propertyConstants.PROPERTY_DELETED] !== importExportConstants.TASK_DELETED) {
                        const updatedImportTask = updateImportTaskIsChecked(item, action.payload.checkAll);

                        if (updatedImportTask) {
                            updatedImportTaskList.push(updatedImportTask);
                        }
                    } else {
                        updatedImportTaskList.push(item);
                    }
                }

                return {
                    ...state,
                    list: updatedImportTaskList
                };
            }

            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return {
                ...initialState,
                importStatuses: state.importStatuses,
                importTypes: state.importTypes
            };
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
