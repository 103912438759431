import * as translationConstants from "../constants/translationConstants";

import { t as translate } from "react-i18nify";

export class Language {
    code: translationConstants.Language;
    name: string;
    nativeName: string;

    constructor(code: translationConstants.Language) {
        this.code = code;
        this.name = this.getName(code);
        this.nativeName = this.getNativeName(code);
    }

    getName = (code: translationConstants.Language): string => {
        switch (code) {
            case translationConstants.ENGLISH:
                return translate("options.languages.english");
            case translationConstants.GERMAN:
                return translate("options.languages.german");
            default:
                return "";
        }
    };

    getNativeName = (code: translationConstants.Language): string => {
        switch (code) {
            case translationConstants.ENGLISH:
                return translationConstants.NAME_ENGLISH;
            case translationConstants.GERMAN:
                return translationConstants.NAME_GERMAN;
            default:
                return "";
        }
    };
}
