import * as actionConstants from "../constants/actionTypes";
import * as generalHelper from "../helpers/generalHelper";
import * as modalTypes from "../constants/modalTypes";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as reducerHelper from "../helpers/reducerHelper";
import * as tableConstants from "../constants/tableConstants";

import { User, cloneUser, updateUser } from "../types/user";

export type UserState = Readonly<{
    masterList: Array<User>;
    masterActiveList: Array<User>;
    masterCountTotal: number | null;
    masterSearch: string | null;
    masterSearchParams: Record<propertyConstants.Property, string>;
    masterOffset: number;
    masterPage: number;
    masterSystemList: Array<User>;
    masterSystemActiveList: Array<User>;
    masterSystemSearch: string | null;
    masterSystemSearchParams: Record<propertyConstants.Property, string>;
    masterSystemOffset: number;
    masterSystemPage: number;
    systemMasterList: Array<User>;
    systemMasterActiveList: Array<User>;
    systemMasterSortingAsc: boolean;
    systemMasterSortingCriterion: propertyConstants.Property;
    systemMasterSearch: string | null;
    systemMasterSearchParams: Record<propertyConstants.Property, string>;
    systemMasterOffset: number;
    systemMasterPage: number;
    systemList: Array<User>;
    systemActiveList: Array<User>;
    systemCountAvailable: number | null;
    systemCountTotal: number | null;
    systemSearch: string | null;
    systemSearchParams: Record<propertyConstants.Property, string>;
    systemOffset: number;
    systemPage: number;
    systemZoneList: Array<User>;
    systemZoneActiveList: Array<User>;
    systemZoneSearch: string | null;
    systemZoneSearchParams: Record<propertyConstants.Property, string>;
    systemZoneOffset: number;
    systemZonePage: number;
    zoneSystemList: Array<User>;
    zoneSystemActiveList: Array<User>;
    zoneSystemSortingAsc: boolean;
    zoneSystemSortingCriterion: propertyConstants.Property;
    zoneSystemSearch: string | null;
    zoneSystemSearchParams: Record<propertyConstants.Property, string>;
    zoneSystemOffset: number;
    zoneSystemPage: number;
    zoneList: Array<User>;
    zoneActiveList: Array<User>;
    zoneCountAvailable: number | null;
    zoneCountTotal: number | null;
    zoneSearch: string | null;
    zoneSearchParams: Record<propertyConstants.Property, string>;
    zoneOffset: number;
    zonePage: number;
    allList: Array<User>;
    list: Array<User>;
    activeList: Array<User>;
    count: number | null;
    search: string | null;
    searchParams: Record<propertyConstants.Property, string>;
    offset: number;
    page: number;
    companyId: number | null; // to be deleted when user + company mapping is reworked, saves selected companyId for newly added user
    redlikePassword: string | null; // stores password for the one time Redlike access control
}>;

const initialState: UserState = {
    masterList: [],
    masterActiveList: [],
    masterCountTotal: null,
    masterSearch: null,
    masterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterOffset: tableConstants.DEFAULT_OFFSET,
    masterPage: tableConstants.DEFAULT_PAGE,
    masterSystemList: [],
    masterSystemActiveList: [],
    masterSystemSearch: null,
    masterSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    masterSystemOffset: tableConstants.DEFAULT_OFFSET,
    masterSystemPage: tableConstants.DEFAULT_PAGE,
    systemMasterList: [],
    systemMasterActiveList: [],
    systemMasterSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    systemMasterSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    systemMasterSearch: null,
    systemMasterSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemMasterOffset: tableConstants.DEFAULT_OFFSET,
    systemMasterPage: tableConstants.DEFAULT_PAGE,
    systemList: [],
    systemActiveList: [],
    systemCountAvailable: null,
    systemCountTotal: null,
    systemSearch: null,
    systemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemOffset: tableConstants.DEFAULT_OFFSET,
    systemPage: tableConstants.DEFAULT_PAGE,
    systemZoneList: [],
    systemZoneActiveList: [],
    systemZoneSearch: null,
    systemZoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    systemZoneOffset: tableConstants.DEFAULT_OFFSET,
    systemZonePage: tableConstants.DEFAULT_PAGE,
    zoneSystemList: [],
    zoneSystemActiveList: [],
    zoneSystemSortingAsc: tableConstants.DEFAULT_SORTING_ASC,
    zoneSystemSortingCriterion: tableConstants.DEFAULT_SORTING_COLUMN,
    zoneSystemSearch: null,
    zoneSystemSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneSystemOffset: tableConstants.DEFAULT_OFFSET,
    zoneSystemPage: tableConstants.DEFAULT_PAGE,
    zoneList: [],
    zoneActiveList: [],
    zoneCountAvailable: null,
    zoneCountTotal: null,
    zoneSearch: null,
    zoneSearchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    zoneOffset: tableConstants.DEFAULT_OFFSET,
    zonePage: tableConstants.DEFAULT_PAGE,
    allList: [],
    list: [],
    activeList: [],
    count: null,
    search: null,
    searchParams: tableConstants.DEFAULT_SEARCH_PARAMS,
    offset: tableConstants.DEFAULT_OFFSET,
    page: tableConstants.DEFAULT_PAGE,
    companyId: null,
    redlikePassword: null
};

export default function users(state: UserState = initialState, action: Record<string, any>): UserState {
    switch (action.type) {
        case actionConstants.SAVE_USER_COMPANY_ID: {
            return {
                ...state,
                companyId: action.payload.companyId
            };
        }
        case actionConstants.LIST_SAVE_NO_PARAMS: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER_WITH_USER_GROUPS:
                case objectTypes.SYSTEM_REDLIKE_USER_WITH_USER_GROUPS:
                case objectTypes.ZONE_REDLIKE_USER_WITH_USER_GROUPS: {
                    return {
                        ...state,
                        allList: action.payload.list
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.LIST_SAVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER: {
                    const newActiveList = generalHelper.updateActiveLists(state.masterActiveList, action.payload.list);

                    return {
                        ...state,
                        masterList: action.payload.list,
                        masterActiveList: newActiveList,
                        masterCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    const newActiveList = generalHelper.updateActiveLists(state.systemActiveList, action.payload.list);

                    return {
                        ...state,
                        systemList: action.payload.list,
                        systemActiveList: newActiveList,
                        systemCountTotal: action.payload.totalCount
                    };
                }
                case objectTypes.USER: {
                    const newActiveList = generalHelper.updateActiveLists(state.activeList, action.payload.list);

                    return {
                        ...state,
                        list: action.payload.list,
                        activeList: newActiveList
                        // count: action.payload.totalCount
                    };
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    const newActiveList = generalHelper.updateActiveLists(state.zoneActiveList, action.payload.list);

                    return {
                        ...state,
                        zoneList: action.payload.list,
                        zoneActiveList: newActiveList,
                        zoneCountTotal: action.payload.totalCount
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_ACTIVE: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER: {
                    return {
                        ...state,
                        masterActiveList: action.payload.items
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    return {
                        ...state,
                        systemActiveList: action.payload.items
                    };
                }
                case objectTypes.USER: {
                    return {
                        ...state,
                        activeList: action.payload.items
                    };
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    return {
                        ...state,
                        zoneActiveList: action.payload.items
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SAVE_REDLIKE_PASSWORD: {
            return {
                ...state,
                redlikePassword: action.payload.password
            };
        }
        case actionConstants.SAVE_USER_GROUPS_FOR_USER: {
            if (!action.payload.loggedUser) {
                const newUser = cloneUser(state.activeList[0]);

                if (newUser) {
                    newUser[propertyConstants.PROPERTY_USER_GROUP_LIST] = action.payload.userGroupList;

                    return {
                        ...state,
                        activeList: [newUser]
                    };
                }
            }

            return state;
        }
        case actionConstants.SET_OFFSET: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER: {
                    return {
                        ...state,
                        masterOffset: action.payload.offset,
                        masterPage: action.payload.page
                    };
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    return {
                        ...state,
                        systemOffset: action.payload.offset,
                        systemPage: action.payload.page
                    };
                }
                case objectTypes.USER: {
                    return {
                        ...state,
                        offset: action.payload.offset,
                        page: action.payload.page
                    };
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    return {
                        ...state,
                        zoneOffset: action.payload.offset,
                        zonePage: action.payload.page
                    };
                }
                default:
                    return state;
            }
        }
        case actionConstants.SET_SEARCH: {
            switch (action.payload.objectType) {
                case objectTypes.MASTER_REDLIKE_USER: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.MASTER_SEARCH,
                        tableConstants.MASTER_SEARCH_PARAMS
                    );
                }
                case objectTypes.SYSTEM_REDLIKE_USER: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SYSTEM_SEARCH,
                        tableConstants.SYSTEM_SEARCH_PARAMS
                    );
                }
                case objectTypes.USER: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.SEARCH,
                        tableConstants.SEARCH_PARAMS
                    );
                }
                case objectTypes.ZONE_REDLIKE_USER: {
                    return reducerHelper.getSearchState(
                        action.payload,
                        state,
                        tableConstants.ZONE_SEARCH,
                        tableConstants.ZONE_SEARCH_PARAMS
                    );
                }
                default:
                    return state;
            }
        }
        case actionConstants.EDIT: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_USER: {
                        const updatedUserList = generalHelper.updateItem(
                            state.masterList,
                            action.payload.params[propertyConstants.PROPERTY_USER_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateUser
                        );

                        if (updatedUserList.length) {
                            return {
                                ...state,
                                masterList: updatedUserList
                            };
                        }

                        return state;
                    }
                    case objectTypes.SYSTEM_REDLIKE_USER: {
                        const updatedUserList = generalHelper.updateItem(
                            state.systemList,
                            action.payload.params[propertyConstants.PROPERTY_USER_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateUser
                        );

                        if (updatedUserList.length) {
                            return {
                                ...state,
                                systemList: updatedUserList
                            };
                        }

                        return state;
                    }
                    case objectTypes.USER: {
                        const updatedUserList = generalHelper.updateItem(
                            state.list,
                            action.payload.params[propertyConstants.PROPERTY_USER_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateUser
                        );

                        if (updatedUserList.length) {
                            return {
                                ...state,
                                list: updatedUserList
                            };
                        }

                        return state;
                    }
                    case objectTypes.ZONE_REDLIKE_USER: {
                        const updatedUserList = generalHelper.updateItem(
                            state.zoneList,
                            action.payload.params[propertyConstants.PROPERTY_USER_ID],
                            action.payload.params[propertyConstants.PROPERTY_DATA],
                            updateUser
                        );

                        if (updatedUserList.length) {
                            return {
                                ...state,
                                zoneList: updatedUserList
                            };
                        }

                        return state;
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.EDIT_SUCCESS: {
            if (!action.payload.isModal) {
                switch (action.payload.objectType) {
                    case objectTypes.MASTER_REDLIKE_USER: {
                        return {
                            ...state,
                            masterList: generalHelper.updateLists(state.masterList, action.payload.items),
                            masterActiveList: generalHelper.updateActiveLists(
                                state.masterActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.SYSTEM_REDLIKE_USER: {
                        return {
                            ...state,
                            systemList: generalHelper.updateLists(state.systemList, action.payload.items),
                            systemActiveList: generalHelper.updateActiveLists(
                                state.systemActiveList,
                                action.payload.items
                            )
                        };
                    }
                    case objectTypes.USER: {
                        return {
                            ...state,
                            list: generalHelper.updateLists(state.list, action.payload.items),
                            activeList: generalHelper.updateActiveLists(state.activeList, action.payload.items)
                        };
                    }
                    case objectTypes.ZONE_REDLIKE_USER: {
                        return {
                            ...state,
                            zoneList: generalHelper.updateLists(state.zoneList, action.payload.items),
                            zoneActiveList: generalHelper.updateActiveLists(state.zoneActiveList, action.payload.items)
                        };
                    }
                    default: {
                        return state;
                    }
                }
            }
            return state;
        }
        case actionConstants.NAVIGATION_SET_SCENE:
        case actionConstants.NAVIGATION_SET_WIZARD: {
            return initialState;
        }
        case actionConstants.NAVIGATION_MODAL_OPEN: {
            if (action.payload.type === modalTypes.MODAL_USERS_ADD) {
                return { ...state, companyId: initialState.companyId };
            }
            return state;
        }
        case actionConstants.EVENT_SAVE_DATABASES: {
            return initialState;
        }
        case actionConstants.DATA_ROLLBACK: {
            return initialState;
        }
        case actionConstants.SERVER_WEBSOCKET_CLOSED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
