import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";
import * as notificationActions from "../actions/notificationActions";
import * as objectTypes from "../constants/objectTypes";
import * as universalObjectActions from "../actions/universalObjectActions";

import { Epic, ofType } from "redux-observable";
import { serverRequest } from "../actions/websocketActions";
import { switchMap } from "rxjs/operators";

export const showItem: Epic<any, any> = (action$) =>
    action$.pipe(
        ofType(actionConstants.SHOW),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // export task
                case objectTypes.EXPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_SHOW_EXPORT_TASK, methods.METHOD_SHOW_EXPORT_TASK, payload.params)
                    );
                    break;
                }
                // import task
                case objectTypes.IMPORT_TASK: {
                    requests.push(
                        serverRequest(methods.METHOD_SHOW_IMPORT_TASK, methods.METHOD_SHOW_IMPORT_TASK, payload.params)
                    );
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );

export const showSpecificValues: Epic<any, any> = (action$) =>
    action$.pipe(
        ofType(actionConstants.SET_SHOW_SPECIFIC_VALUES),
        switchMap(({ payload }) => {
            const requests: Array<any> = [];

            switch (payload.objectType) {
                // redlike options
                case objectTypes.MASTER_REDLIKE_OPTION:
                case objectTypes.SYSTEM_REDLIKE_OPTION:
                case objectTypes.ZONE_REDLIKE_OPTION:
                    break;
                // redlike privileges
                case objectTypes.MASTER_REDLIKE_PRIVILEGE: {
                    requests.push(universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_PRIVILEGE));
                    break;
                }
                default: {
                    return [notificationActions.saveClientFlashMessage()];
                }
            }

            return requests;
        })
    );
