import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Script, mapScript } from "../../types/script";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_SCRIPTS_NO_PARAMS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const scripts: Array<Script> = [];
            let script: Script | null;

            for (const item of response.data) {
                script = mapScript(item);

                if (script) {
                    scripts.push(script);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.SERVER_SCRIPTS, scripts)];
        }
        case methods.METHOD_GET_ALL_SCRIPTS: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const scripts: Array<Script> = [];
            let script: Script | null;

            for (const item of response.data) {
                script = mapScript(item);

                if (script) {
                    scripts.push(script);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.SERVER_SCRIPTS, scripts, response.data.count)];
        }
        case methods.METHOD_START_RUN_SCRIPT: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [universalObjectActions.getAllItems(objectTypes.SERVER_SCRIPTS)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
