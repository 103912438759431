import * as actionConstants from "../constants/actionTypes";

import { Currency } from "../types/currency";
import { DataItem } from "../types/dataItem";
import { Database } from "../types/database";
import { MenuItem } from "../types/menu";
import { Option } from "../types/option";
import { OptionItem } from "../types/optionItem";
import { PrivilegeItem } from "../types/privilegeItem";
import { System } from "../types/system";
import { Zone } from "../types/zone";

export interface EventSaveSessionInfo {
    type: typeof actionConstants.EVENT_SAVE_SESSION_INFO;
    payload: {
        uuid: string;
    };
}

export function saveSessionInfo(uuid: string): EventSaveSessionInfo {
    return {
        type: actionConstants.EVENT_SAVE_SESSION_INFO,
        payload: {
            uuid
        }
    };
}

export interface EventSaveDatabases {
    type: typeof actionConstants.EVENT_SAVE_DATABASES;
    payload: {
        active: Database | null;
        databaseList: Array<Database>;
    };
}

export function saveDatabases(active: Database | null, databaseList: Array<Database>): EventSaveDatabases {
    return {
        type: actionConstants.EVENT_SAVE_DATABASES,
        payload: {
            active,
            databaseList
        }
    };
}

export interface EventSaveZones {
    type: typeof actionConstants.EVENT_SAVE_ZONES;
    payload: {
        active: Zone | null;
        zoneList: Array<Zone>;
    };
}

export function saveZones(active: Zone | null, zoneList: Array<Zone>): EventSaveZones {
    return {
        type: actionConstants.EVENT_SAVE_ZONES,
        payload: {
            active,
            zoneList
        }
    };
}

export interface EventSaveSystems {
    type: typeof actionConstants.EVENT_SAVE_SYSTEMS;
    payload: {
        active: System | null;
        systemList: Array<System>;
    };
}

export function saveSystems(active: System | null, systemList: Array<System>): EventSaveSystems {
    return {
        type: actionConstants.EVENT_SAVE_SYSTEMS,
        payload: {
            active,
            systemList
        }
    };
}

export interface EventSaveCurrencies {
    type: typeof actionConstants.EVENT_SAVE_CURRENCIES;
    payload: {
        currencyList?: Array<Currency>;
        systemCurrency?: Currency | null;
        zoneCurrency?: Currency | null;
    };
}

export function saveCurrencies(
    currencyList?: Array<Currency>,
    systemCurrency?: Currency | null,
    zoneCurrency?: Currency | null
): EventSaveCurrencies {
    return {
        type: actionConstants.EVENT_SAVE_CURRENCIES,
        payload: {
            currencyList,
            systemCurrency,
            zoneCurrency
        }
    };
}

export interface EventSaveDataPrivileges {
    type: typeof actionConstants.EVENT_SAVE_DATA_PRIVILEGES;
    payload: {
        dataPrivilegesList: Array<DataItem>;
    };
}

export function saveDataPrivileges(dataPrivilegesList: Array<DataItem>): EventSaveDataPrivileges {
    return {
        type: actionConstants.EVENT_SAVE_DATA_PRIVILEGES,
        payload: {
            dataPrivilegesList
        }
    };
}

export interface EventSavePrivileges {
    type: typeof actionConstants.EVENT_SAVE_PRIVILEGES;
    payload: {
        privilegesList: Array<any>;
    };
}

export function savePrivileges(privilegesList: Array<any>): EventSavePrivileges {
    return {
        type: actionConstants.EVENT_SAVE_PRIVILEGES,
        payload: {
            privilegesList
        }
    };
}

export interface EventSaveMenu {
    type: typeof actionConstants.EVENT_SAVE_MENU;
    payload: {
        menuList: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>;
    };
}

export function saveMenu(menuList: Array<MenuItem | DataItem | OptionItem | PrivilegeItem>): EventSaveMenu {
    return {
        type: actionConstants.EVENT_SAVE_MENU,
        payload: {
            menuList
        }
    };
}

export interface EventSaveOptions {
    type: typeof actionConstants.EVENT_SAVE_OPTIONS;
    payload: {
        optionList: Array<Option>;
    };
}

export function saveOptions(optionList: Array<Option>): EventSaveOptions {
    return {
        type: actionConstants.EVENT_SAVE_OPTIONS,
        payload: {
            optionList
        }
    };
}
