import * as formattingHelper from "../../../../helpers/formattingHelper";
import * as keyCodes from "../../../../constants/keyCodes";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypes from "../../../../constants/objectTypes";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as propertyHelper from "../../../../helpers/propertyHelper";
import * as tableConstants from "../../../../constants/tableConstants";
import * as validationHelper from "../../../../helpers/validationHelper";

import React, { Component } from "react";

import { AppState } from "../../../../reducers";
import ContentEditable from "react-contenteditable";
import { Dispatch } from "redux";
import { ModalProperties } from "../../../../types/modalProperties";
import { ModalType } from "../../../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { TableHeading } from "../../../../types/tableHeading";
import { connect } from "react-redux";

import imgEye from "../../../../resources/img/icons/eye.svg";
import imgEyeNo from "../../../../resources/img/icons/eye-no.svg";
import imgLoader from "../../../../resources/img/mini-loader.svg";

import { translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    heading: TableHeading;
    item: any;
    content: string;
    modalProperties: ModalProperties;
    isActive: boolean;
    isEditable: boolean;
    isSourceChanged: boolean;
    callbackBlur: (event: any, value: string | null) => any;
    callbackChange: (isValid: boolean, value: string) => any;
    callbackFocus: (value: string) => any;
    callbackMouseEnter: (event: any, value: string) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

type State = {
    showPassword: boolean;
};

export class TableCellText extends Component<Props> {
    state: State = {
        showPassword: false
    };

    componentDidMount() {
        document.addEventListener("paste", () => this.handlePaste);
    }

    componentWillUnmount() {
        document.removeEventListener("paste", () => this.handlePaste);
    }

    handlePaste = (event: any) => {
        event.preventDefault();

        this.props.callbackChange(true, event.clipboardData.getData("text/plain"));
    };

    handleCellFocus = (property: propertyConstants.Property): any => {
        this.props.callbackFocus(this.getCellInlineContent(property, this.props.item[property]));
    };

    handleCellChange = (event: React.ChangeEvent<HTMLInputElement>, property: propertyConstants.Property): any => {
        const content = validationHelper.formatStringFromHtml(event.target.value);
        let isValid = true;

        if (propertyHelper.isParentPropertyBfs(property)) {
            isValid = validationHelper.isBfsValid(this.props.objectType, content);
        }

        this.props.callbackChange(isValid, content);
    };

    handleShowPasswordClick = (): void => {
        const { showPassword } = this.state;

        this.setState({
            showPassword: !showPassword
        });
    };

    handleCellKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        property: propertyConstants.Property,
        originValue: any
    ): any => {
        switch (event.key) {
            case keyCodes.ENTER: {
                event.currentTarget.blur();
                break;
            }
            case keyCodes.ESCAPE: {
                this.props.callbackChange(true, originValue ?? "");
                break;
            }
            default: {
                break;
            }
        }
    };

    handleCellBlur = (
        event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
        property: propertyConstants.Property,
        originValue: any
    ): any => {
        const { heading } = this.props;
        const headingType = heading[tableConstants.TABLE_HEADING_TYPE];
        let value: string | null = this.props.content;

        if (headingType === tableConstants.TABLE_TYPE_STRING) {
            value = validationHelper.formatStringFromHtml(value);
        }

        value = value?.trim() || null;
        value = value?.length ? value : null;
        value = value ? value.replace(/\s/g, " ") : null;
        value = value ? value.replace(/\u00a0/g, " ") : null;
        value = value ? value.replace(/&nbsp;/g, " ") : null;

        // BFS
        if (propertyHelper.isParentPropertyBfs(property)) {
            value = value?.toUpperCase() || null;
        }

        // Version
        if (propertyHelper.isPropertyVersion(property)) {
            value = formattingHelper.formatVersion(value);
        }

        // UFI code
        if (property === propertyConstants.PROPERTY_UFI_CODE && !validationHelper.isUfiCodeValid(value)) {
            value = originValue;
        }

        this.props.callbackBlur(event, value);
    };

    handleCellDoubleClick = (property: propertyConstants.Property): any => {
        const { openModal } = this.props;
        const {
            imageCategoriesModalType,
            imageCategoriesModalParams,
            productGroupsModalType,
            productGroupsModalParams
        } = this.props.modalProperties;

        if (
            property === propertyConstants.PROPERTY_PRODUCT_GROUP_LIST &&
            productGroupsModalType &&
            productGroupsModalParams
        ) {
            openModal(productGroupsModalType, productGroupsModalParams);
        }

        if (
            property === propertyConstants.PROPERTY_IMAGE_CATEGORY_ID &&
            imageCategoriesModalType &&
            imageCategoriesModalParams
        ) {
            openModal(imageCategoriesModalType, imageCategoriesModalParams);
        }
    };

    isDisabled = (): boolean => {
        if (
            !this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE] ||
            !this.props.heading[tableConstants.TABLE_HEADING_EDITING] ||
            !this.props.isEditable
        ) {
            return true;
        }

        return false;
    };

    getCellClassname = (property: propertyConstants.Property): string => {
        let className = `td td-edit ${property}`;

        if (tableConstants.MASTER_PROPERTIES.includes(property)) {
            className += " master-cell";
        }

        if (tableConstants.SYSTEM_PROPERTIES.includes(property)) {
            className += " system-cell";
        }

        if (
            this.props.objectType === objectTypes.MASTER_FORMULA_COLORANT &&
            this.props.item[propertyConstants.PROPERTY_AMOUNT] === null
        ) {
            className += " disabled";
        }

        return className;
    };

    getClassname = (property: propertyConstants.Property): string => {
        let className = "td-edit";

        if (this.isDisabled()) {
            className += " edit-disabled";
        }

        if (this.props.isSourceChanged) {
            className += " dotted";
        }

        if (
            property === propertyConstants.PROPERTY_PASSWORD &&
            this.props.heading[tableConstants.TABLE_HEADING_EDITING] &&
            !this.state.showPassword
        ) {
            className += " password";
        }

        return className;
    };

    getEyeIcon = (property: propertyConstants.Property): JSX.Element | null => {
        if (
            property === propertyConstants.PROPERTY_PASSWORD &&
            this.props.heading[tableConstants.TABLE_HEADING_EDITING]
        ) {
            return (
                <button
                    className="btn-without-style password-button"
                    type="button"
                    onClick={this.handleShowPasswordClick}
                >
                    <ReactSVG className="btn-icon" src={this.state.showPassword ? imgEyeNo : imgEye} />
                </button>
            );
        }

        return null;
    };

    getLevelColor = (item: any, property: propertyConstants.Property): string => {
        let className = "color-square";

        if (item && item[property] !== null) {
            if (item[propertyConstants.PROPERTY_DATABASE_NAME] !== null) {
                if (
                    item[propertyConstants.PROPERTY_SYSTEM_NAME] !== null &&
                    item[propertyConstants.PROPERTY_ZONE_NAME] !== null
                ) {
                    className += " zone";
                } else if (item[propertyConstants.PROPERTY_SYSTEM_NAME] !== null) {
                    className += " system";
                } else {
                    className += " master";
                }
            }
        } else {
            className += " no-color";
        }

        return className;
    };

    getColorPreview = (property: propertyConstants.Property, item: any): JSX.Element | null => {
        if (property === propertyConstants.PROPERTY_COLOR_RGB_HEX) {
            return (
                <div
                    className={item && item[property] !== null ? "color-square" : "color-square no-color"}
                    style={item && item[property] !== null ? { backgroundColor: item[property] } : {}}
                ></div>
            );
        }

        if (property === propertyConstants.PROPERTY_LEVEL) {
            return <div className={this.getLevelColor(item, property)}></div>;
        }

        if (
            property === propertyConstants.PROPERTY_COLOR_NAME ||
            property === propertyConstants.PROPERTY_COLORANT_NAME
        ) {
            return (
                <div
                    className={
                        item && item[propertyConstants.PROPERTY_COLOR_RGB_HEX] !== null
                            ? "color-square"
                            : "color-square no-color"
                    }
                    style={
                        item && item[propertyConstants.PROPERTY_COLOR_RGB_HEX] !== null
                            ? {
                                  backgroundColor: item[propertyConstants.PROPERTY_COLOR_RGB_HEX]
                              }
                            : {}
                    }
                ></div>
            );
        }

        return null;
    };

    getCellContent = (property: propertyConstants.Property, value: any): string => {
        if (Array.isArray(value) && propertyHelper.isPropertyBarcodes(property)) {
            return value.map((item) => item[propertyConstants.PROPERTY_BARCODE]).join(", ");
        }

        // Replaces spaces with &nbsp; so that multiple spaces are displayed
        return value !== null ? value.replace(/\s/g, "\u00a0") : "";
    };

    getCellInlineContent = (property: propertyConstants.Property, value: any): string => {
        if (Array.isArray(value) && propertyHelper.isPropertyBarcodes(property)) {
            const barcodes = [];

            for (const barcode of value) {
                if (barcode[propertyConstants.PROPERTY_CAN_DELETE] && !barcode[propertyConstants.PROPERTY_INHERITED]) {
                    barcodes.push(barcode[propertyConstants.PROPERTY_BARCODE]);
                }
            }

            return barcodes.join(", ");
        }

        return value || "";
    };

    getCellTooltipContent = (property: propertyConstants.Property, value: any): string => {
        if (Array.isArray(value) && propertyHelper.isPropertyBarcodes(property)) {
            const barcodes = [];

            for (const barcode of value) {
                if (barcode[propertyConstants.PROPERTY_IS_PRIMARY] && barcode[propertyConstants.PROPERTY_INHERITED]) {
                    barcodes.push(
                        `${barcode[propertyConstants.PROPERTY_BARCODE]} (${translate(
                            "general.primary"
                        ).toLowerCase()}, ${translate("general.inherited").toLowerCase()})`
                    );
                }
                if (barcode[propertyConstants.PROPERTY_IS_PRIMARY] && !barcode[propertyConstants.PROPERTY_INHERITED]) {
                    barcodes.push(
                        `${barcode[propertyConstants.PROPERTY_BARCODE]} (${translate("general.primary").toLowerCase()})`
                    );
                }
                if (!barcode[propertyConstants.PROPERTY_IS_PRIMARY] && barcode[propertyConstants.PROPERTY_INHERITED]) {
                    barcodes.push(
                        `${barcode[propertyConstants.PROPERTY_BARCODE]} (${translate(
                            "general.inherited"
                        ).toLowerCase()})`
                    );
                }
                if (!barcode[propertyConstants.PROPERTY_IS_PRIMARY] && !barcode[propertyConstants.PROPERTY_INHERITED]) {
                    barcodes.push(barcode[propertyConstants.PROPERTY_BARCODE]);
                }
            }

            return barcodes.join("<br/>");
        }

        return value !== null ? value.replace(/\s/g, "\u00a0") : "";
    };

    getCellInput = (property: propertyConstants.Property, item: any): JSX.Element => {
        const value = item && item[property] !== undefined ? item[property] : null;
        const content = this.props.isActive ? this.props.content : this.getCellContent(property, value);
        const inlineContent = this.props.isActive ? this.props.content : this.getCellInlineContent(property, value);
        const editableHtml = `<span class="${this.props.isSourceChanged ? "dotted" : ""}">${content}</span>`;

        if (item[property] === undefined) {
            return <ReactSVG src={imgLoader} className="svg-icon" />;
        }

        return (
            <ContentEditable
                className={this.getClassname(property)}
                disabled={this.isDisabled()}
                html={editableHtml}
                key={property}
                spellCheck={false}
                tagName={"div"}
                onFocus={(): void => this.handleCellFocus(property)}
                onChange={(event: any): void => this.handleCellChange(event, property)}
                onBlur={(event: any): void => this.handleCellBlur(event, property, inlineContent)}
                onKeyDown={(event: any): void => this.handleCellKeyDown(event, property, inlineContent)}
                onMouseEnter={(event: any): void => {
                    this.props.callbackMouseEnter(event, this.getCellTooltipContent(property, value));
                }}
                onDoubleClick={(): void => {
                    this.handleCellDoubleClick(property);
                }}
                data-tip
                data-for={"table-cell-tooltip"}
            />
        );
    };

    render(): JSX.Element {
        const { heading, item } = this.props;
        const property = heading[tableConstants.TABLE_HEADING_NAME];

        return (
            <div
                className={this.getCellClassname(property)}
                key={property}
                onMouseLeave={(): void => this.props.callbackMouseLeave()}
            >
                {this.getColorPreview(property, item)}
                {this.getCellInput(property, item)}
                {this.getEyeIcon(property)}
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

export type DispatchType = Readonly<{
    openModal(type: ModalType, params: any): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    openModal: (type: ModalType, params: any): any => dispatch(navigationActions.navigationOpenModal(type, params))
});

export const TableCellTextContainer = connect(mapStateToProps, mapDispatchToProps)(TableCellText);
