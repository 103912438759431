import * as generalHelper from "../../helpers/generalHelper";
import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as modalTypes from "../../constants/modalTypes";
import * as navigationActions from "../../actions/navigationActions";
import * as objectTypes from "../../constants/objectTypes";
import * as optionsConstants from "../../constants/optionsConstants";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Company, mapCompany } from "../../types/company";

import { AppState } from "../../reducers";
import { Modal } from "../../types/modal";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_COMPANIES_NO_PARAMS: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const companyList: Array<Company> = [];
            let company: Company | null;

            for (const item of response.data.data) {
                company = mapCompany(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (company) {
                    companyList.push(company);
                }
            }

            return [universalObjectActions.saveItemsNoParams(objectTypes.COMPANY, companyList)];
        }
        case methods.METHOD_GET_ALL_COMPANIES: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const companyList: Array<Company> = [];
            let company: Company | null;

            for (const item of response.data.data) {
                company = mapCompany(
                    item,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (company) {
                    companyList.push(company);
                }
            }

            return [universalObjectActions.saveItems(objectTypes.COMPANY, companyList, response.data.count)];
        }
        case methods.METHOD_ADD_COMPANY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.COMPANY),
                navigationActions.navigationCloseModal(modalTypes.MODAL_COMPANIES_ADD)
            ];
        }
        case methods.METHOD_EDIT_COMPANY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const requests = [];

            if (state.navigation.modalList.some((modal: Modal) => modal.type === modalTypes.MODAL_COMPANIES_EDIT)) {
                requests.push(
                    universalObjectActions.reloadData(objectTypes.COMPANY),
                    navigationActions.navigationCloseModal(modalTypes.MODAL_COMPANIES_EDIT)
                );
            } else {
                const activeCompany = mapCompany(
                    response.data,
                    state.login.loggedUser,
                    generalHelper.getObjectByKey(state.login.options, optionsConstants.OPTION_COMPANIES_FOR_USERS)
                );

                if (activeCompany) {
                    requests.push(universalObjectActions.editItemSuccess(objectTypes.COMPANY, [activeCompany]));
                }
            }

            return requests;
        }
        case methods.METHOD_DELETE_COMPANY: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [
                universalObjectActions.reloadData(objectTypes.COMPANY),
                universalObjectActions.setActiveItems(objectTypes.COMPANY, [])
            ];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
