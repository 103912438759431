import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { FormulaNote } from "../../../types/formulaNote";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { SystemFormulaNoteScene } from "../../../components/scenes/system/SystemFormulaNoteScene";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    formulaNoteTableConstant: string;
    formulaNoteList: Array<FormulaNote>;
    formulaNoteActiveList: Array<FormulaNote>;
    formulaNoteLoading: boolean;
    formulaNoteButtonLoading: boolean;
    formulaNoteColumnOrder: Array<propertyConstants.Property>;
    formulaNoteColumnVisibility: Record<propertyConstants.Property, boolean>;
    formulaNoteColumnWidth: Record<propertyConstants.Property, number>;
    formulaNoteOffset: number;
    formulaNotePage: number;
    formulaNoteRowCount: number;
    formulaNoteRowCountCustom: boolean;
    formulaNoteSceneTableSize: Record<string, any> | null;
    formulaNoteSearch: string | null;
    formulaNoteSearchParams: Record<propertyConstants.Property, string>;
    formulaNoteShowFilterRow: boolean;
    formulaNoteShowGlobalSearch: boolean;
    formulaNoteSortingAsc: boolean;
    formulaNoteSortingCriterion: propertyConstants.Property | null;
    formulaNoteTotalCount: number | null;
    sceneLayout: string | null;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteTableConstant: menuConstants.TABLE_SYSTEM_FORMULA_NOTES,
    formulaNoteList: state.formulaNote.systemList,
    formulaNoteActiveList: state.formulaNote.systemActiveList,
    formulaNoteLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_GET_ALL_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteButtonLoading: state.server.requests.some(
        (request: ServerRequest) => request.method === methods.METHOD_EDIT_SYSTEM_FORMULA_NOTE
    ),
    formulaNoteColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteOffset: state.formulaNote.systemOffset,
    formulaNotePage: state.formulaNote.systemPage,
    formulaNoteRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteSearch: state.formulaNote.systemSearch,
    formulaNoteSearchParams: state.formulaNote.systemSearchParams,
    formulaNoteShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_SYSTEM_FORMULA_NOTES
    ),
    formulaNoteSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_FORMULA_NOTES
        )
    ),
    formulaNoteSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_SYSTEM_FORMULA_NOTES
        )
    ),
    formulaNoteTotalCount: state.formulaNote.systemCountTotal,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_SYSTEM_FORMULA_NOTES
    )
});

export const SystemFormulaNoteSceneContainer = connect(mapStateToProps)(SystemFormulaNoteScene);
