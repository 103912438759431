import * as batchConstants from "../constants/entityConstants/batchConstants";
import * as objectTypes from "../constants/objectTypes";
import * as propertyConstants from "../constants/propertyConstants";
import * as tableConstants from "../constants/tableConstants";
import * as tableHelper from "../helpers/tableHelper";
import * as translationHelper from "../helpers/translationHelper";

import { Colorant } from "./colorant";
import { TableHeading } from "./tableHeading";
import { t as translate } from "react-i18nify";

export class ColorantBatch {
    id: number;
    itemId: number;
    itemTypeId: number;
    statusId: number;
    batchNumber: string;
    systemId?: number | null;
    zoneId?: number | null;
    moreInfo?: string | null;
    productionDate: Date | null;
    lastStatusChange: Date | null;

    constructor(
        id: number,
        itemId: number,
        itemTypeId: number,
        statusId: number,
        batchNumber: string,
        systemId: number | null,
        zoneId: number | null,
        moreInfo: string | null,
        productionDate: Date | null,
        lastStatusChange: Date | null
    ) {
        this.id = id;
        this.itemId = itemId;
        this.itemTypeId = itemTypeId;
        this.statusId = statusId;
        this.batchNumber = batchNumber;
        this.systemId = systemId;
        this.zoneId = zoneId;
        this.moreInfo = moreInfo;
        this.productionDate = productionDate;
        this.lastStatusChange = lastStatusChange;
    }
}

export function mapColorantBatch(data: Record<string, any>): ColorantBatch | null {
    try {
        return new ColorantBatch(
            data.id,
            data.itemId,
            data.itemTypeId,
            data.statusId,
            data.batchNumber,
            data.systemId,
            data.zoneId,
            data.moreInfo,
            data.productionDate,
            data.lastStatusChange
        );
    } catch (e) {
        return null;
    }
}

export function updateColorantBatch(
    oldColorantBatch: ColorantBatch | null,
    updatedData: Record<string, any>
): ColorantBatch | null {
    try {
        if (oldColorantBatch === null) {
            return null;
        }

        return new ColorantBatch(
            oldColorantBatch.id,
            updatedData.itemId ? updatedData.itemId : oldColorantBatch.itemId,
            updatedData.itemTypeId ? updatedData.itemTypeId : oldColorantBatch.itemTypeId,
            updatedData.statusId ? updatedData.statusId : oldColorantBatch.statusId,
            updatedData.batchNumber ? updatedData.batchNumber : oldColorantBatch.batchNumber,
            updatedData.systemId ? updatedData.systemId : oldColorantBatch.systemId,
            updatedData.zoneId ? updatedData.zoneId : oldColorantBatch.zoneId,
            updatedData.moreInfo ? updatedData.moreInfo : oldColorantBatch.moreInfo,
            updatedData.productionDate ? updatedData.productionDate : oldColorantBatch.productionDate,
            updatedData.lastStatusChange ? updatedData.lastStatusChange : oldColorantBatch.lastStatusChange
        );
    } catch (e) {
        return null;
    }
}

export function createEmptyColorantBatch(colorant: Colorant): any {
    return {
        [propertyConstants.PROPERTY_ID]: "",
        [propertyConstants.PROPERTY_ITEM_ID]: colorant[propertyConstants.PROPERTY_ID],
        [propertyConstants.PROPERTY_ITEM_TYPE_ID]: "",
        [propertyConstants.PROPERTY_STATUS_ID]: "",
        [propertyConstants.PROPERTY_BATCH_NUMBER]: "",
        [propertyConstants.PROPERTY_SYSTEM_ID]: "",
        [propertyConstants.PROPERTY_ZONE_ID]: "",
        [propertyConstants.PROPERTY_MORE_INFO]: "",
        [propertyConstants.PROPERTY_PRODUCTION_DATE]: "",
        [propertyConstants.PROPERTY_LAST_STATUS_CHANGE]: ""
    };
}

export function generateColorantBatchData(list: Array<ColorantBatch>): Array<any> {
    const data: Array<any> = [];
    let item: ColorantBatch;

    for (item of list) {
        data.push({
            [propertyConstants.PROPERTY_ID]: item.id,
            [propertyConstants.PROPERTY_ITEM_ID]: item.itemId,
            [propertyConstants.PROPERTY_ITEM_TYPE_ID]: item.itemTypeId,
            [propertyConstants.PROPERTY_STATUS_ID]: item.statusId,
            [propertyConstants.PROPERTY_BATCH_NUMBER]: item.batchNumber,
            [propertyConstants.PROPERTY_SYSTEM_ID]: item.statusId,
            [propertyConstants.PROPERTY_ZONE_ID]: item.zoneId,
            [propertyConstants.PROPERTY_MORE_INFO]: item.moreInfo,
            [propertyConstants.PROPERTY_PRODUCTION_DATE]: item.productionDate,
            [propertyConstants.PROPERTY_LAST_STATUS_CHANGE]: item.lastStatusChange
        });
    }

    return data;
}

export function generateColorantBatchHeadings(
    objectType: objectTypes.ObjectType,
    orderList: Array<propertyConstants.Property>,
    visibleList: Array<propertyConstants.Property>,
    editableList: Array<propertyConstants.Property>,
    requiredList: Array<propertyConstants.Property> = [
        propertyConstants.PROPERTY_BATCH_NUMBER,
        propertyConstants.PROPERTY_STATUS_ID
    ],
    alwaysVisibleList: Array<propertyConstants.Property> = [propertyConstants.PROPERTY_BATCH_NUMBER],
    widthOption: Record<string, number> = {}
): Array<TableHeading> {
    const statusList = [
        {
            [propertyConstants.PROPERTY_ID]: batchConstants.BATCH_STATUS_VALID,
            [propertyConstants.PROPERTY_NAME]: translate("general.valid")
        },
        {
            [propertyConstants.PROPERTY_ID]: batchConstants.BATCH_STATUS_INVALID,
            [propertyConstants.PROPERTY_NAME]: translate("general.invalid")
        },
        {
            [propertyConstants.PROPERTY_ID]: batchConstants.BATCH_STATUS_WARNING,
            [propertyConstants.PROPERTY_NAME]: translate("general.warning")
        }
    ];

    const list = [
        new TableHeading(
            propertyConstants.PROPERTY_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_ID),
            tableConstants.TABLE_TYPE_NUMBER,
            visibleList.includes(propertyConstants.PROPERTY_ID),
            editableList.includes(propertyConstants.PROPERTY_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_ID),
            requiredList.includes(propertyConstants.PROPERTY_ID),
            [],
            widthOption?.[propertyConstants.PROPERTY_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_STATUS_ID,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_STATUS_ID),
            tableConstants.TABLE_TYPE_SELECT,
            visibleList.includes(propertyConstants.PROPERTY_STATUS_ID),
            editableList.includes(propertyConstants.PROPERTY_STATUS_ID),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_STATUS_ID),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_STATUS_ID),
            requiredList.includes(propertyConstants.PROPERTY_STATUS_ID),
            statusList,
            widthOption?.[propertyConstants.PROPERTY_STATUS_ID] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_BATCH_NUMBER,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_BATCH_NUMBER),
            tableConstants.TABLE_TYPE_STRING,
            visibleList.includes(propertyConstants.PROPERTY_BATCH_NUMBER),
            editableList.includes(propertyConstants.PROPERTY_BATCH_NUMBER),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_BATCH_NUMBER),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_BATCH_NUMBER),
            requiredList.includes(propertyConstants.PROPERTY_BATCH_NUMBER),
            [],
            widthOption?.[propertyConstants.PROPERTY_BATCH_NUMBER] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_MORE_INFO,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.TABLE_TYPE_TEXT,
            visibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            editableList.includes(propertyConstants.PROPERTY_MORE_INFO),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_MORE_INFO),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_MORE_INFO),
            requiredList.includes(propertyConstants.PROPERTY_MORE_INFO),
            [],
            widthOption?.[propertyConstants.PROPERTY_MORE_INFO] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_PRODUCTION_DATE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_PRODUCTION_DATE),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_PRODUCTION_DATE),
            editableList.includes(propertyConstants.PROPERTY_PRODUCTION_DATE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_PRODUCTION_DATE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_PRODUCTION_DATE),
            requiredList.includes(propertyConstants.PROPERTY_PRODUCTION_DATE),
            [],
            widthOption?.[propertyConstants.PROPERTY_PRODUCTION_DATE] ?? null
        ),
        new TableHeading(
            propertyConstants.PROPERTY_LAST_STATUS_CHANGE,
            translationHelper.getPropertyTranslation(objectType, propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            tableConstants.TABLE_TYPE_DATE,
            visibleList.includes(propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            editableList.includes(propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            tableConstants.FILTERABLE_COLUMNS.includes(propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            alwaysVisibleList.includes(propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            requiredList.includes(propertyConstants.PROPERTY_LAST_STATUS_CHANGE),
            [],
            widthOption?.[propertyConstants.PROPERTY_LAST_STATUS_CHANGE] ?? null
        )
    ];

    return tableHelper.sortTableHeadings(list, orderList);
}
