// export statuses
// TODO refactor - unify according to keys and not ids
export const EXPORT_STATUS_ALL = 0; // helper status
export const EXPORT_STATUS_ENQUEUED = 1;
export const EXPORT_STATUS_READY_TO_BE_PROCESSES = 2;
export const EXPORT_STATUS_PROCESSING_DATA = 3;
export const EXPORT_STATUS_ADDING_INDEXES = 4;
export const EXPORT_STATUS_ADDING_META_DATA = 5;
export const EXPORT_STATUS_FINALIZING_DATABASE = 6;
export const EXPORT_STATUS_EXPORTING_DATABASE = 7;
export const EXPORT_STATUS_OK = 8;
export const EXPORT_STATUS_ERROR = 9;
export const EXPORT_STATUS_ABORTING = 10;
export const EXPORT_STATUS_ABORTED = 11;
export const EXPORT_STATUS_DELETED = 12;

export const EXPORT_STATUSES_WAITING = [
    EXPORT_STATUS_ENQUEUED,
    EXPORT_STATUS_READY_TO_BE_PROCESSES,
    EXPORT_STATUS_ABORTING,
    EXPORT_STATUS_ABORTED
];
export const EXPORT_STATUSES_PROGRESS = [
    EXPORT_STATUS_PROCESSING_DATA,
    EXPORT_STATUS_ADDING_INDEXES,
    EXPORT_STATUS_ADDING_META_DATA,
    EXPORT_STATUS_FINALIZING_DATABASE,
    EXPORT_STATUS_EXPORTING_DATABASE
];

export const EXPORT_STATUSES_FAILURE = [EXPORT_STATUS_ERROR];
export const EXPORT_STATUSES_SUCCESS = [EXPORT_STATUS_OK];
export const EXPORT_IGNORE_ACTION_BUTTON = [EXPORT_STATUS_ABORTING];

// export types
export const EXPORT_TYPE_ONLY_DATABASE = 1;
export const EXPORT_TYPE_ARCHIVE_WINDOWS = 2;
export const EXPORT_TYPE_ARCHIVE_LINUX = 3;
export const EXPORT_TYPE_LARGO_TINT_2000 = 4;
export const EXPORT_TYPE_ONLY_DATABASE_TO_WEB = 5;
export const EXPORT_TYPE_ASC_PHB = 6;
export const EXPORT_TYPE_INNO_SETUP = 7;
export const EXPORT_TYPE_DESO_MASTER_EXCEL = 100;
export const EXPORT_TYPE_DESO_ZONE_EXCEL = 101;
export const EXPORT_TYPE_TMC_PRODUCTS_EXCEL = 102;
export const EXPORT_TYPE_EMPTY_DATABASE = 103;

// export type keys
// TODO unify
export const EXPORT_TYPE_ARCHIVE_WINDOWS_KEY = "archive_windows";
export const EXPORT_TYPE_ARCHIVE_LINUX_KEY = "archive_linux";
export const EXPORT_TYPE_ASC_PHB_KEY = "asc_phb";
export const EXPORT_TYPE_DESO_MASTER_EXCEL_KEY = "deso_master_excel";
export const EXPORT_TYPE_DESO_ZONE_EXCEL_KEY = "deso_zone_excel";
export const EXPORT_TYPE_EMPTY_DATABASE_KEY = "empty_db";
export const EXPORT_TYPE_INNO_SETUP_KEY = "inno_setup";
export const EXPORT_TYPE_LARGO_TINT_2000_KEY = "largo_tint_2000";
export const EXPORT_TYPE_ONLY_DATABASE_KEY = "db_only";
export const EXPORT_TYPE_ONLY_DATABASE_TO_WEB_KEY = "db_only_to_web";
export const EXPORT_TYPE_TMC_PRODUCTS_EXCEL_KEY = "tmc_products_excel";

// import task statuses
export const IMPORT_STATUS_ALL = "all";
export const IMPORT_STATUS_ABORTED = "aborted";
export const IMPORT_STATUS_ABORTING = "aborting";
export const IMPORT_STATUS_ANALYZING_FILE = "analyzing_file";
export const IMPORT_STATUS_CONNECTING_TO_DATABASE = "connecting_to_database";
export const IMPORT_STATUS_DELETED = "deleted";
export const IMPORT_STATUS_ENQUEUED = "enqueued";
export const IMPORT_STATUS_OPENING_FILE = "opening_file";
export const IMPORT_STATUS_PREPARING_FOR_IMPORT = "preparing_for_import";
export const IMPORT_STATUS_PROCESSING_BASE_IN_PRODUCTS = "processing_base_in_products";
export const IMPORT_STATUS_PROCESSING_BASE_PACKAGES = "processing_base_packages";
export const IMPORT_STATUS_PROCESSING_BASES = "processing_bases";
export const IMPORT_STATUS_PROCESSING_COLOR_BARCODES = "processing_color_barcodes";
export const IMPORT_STATUS_PROCESSING_COLORANT_PACKAGES = "processing_colorant_packages";
export const IMPORT_STATUS_PROCESSING_COLORANTS = "processing_colorants";
export const IMPORT_STATUS_PROCESSING_COLORS = "processing_colors";
export const IMPORT_STATUS_PROCESSING_COMPONENTS = "processing_components";
export const IMPORT_STATUS_PROCESSING_FANDECKS = "processing_fandecks";
export const IMPORT_STATUS_PROCESSING_FORMULAS = "processing_formulas";
export const IMPORT_STATUS_PROCESSING_NOTES = "processing_notes";
export const IMPORT_STATUS_PROCESSING_OPTIONS = "processing_options";
export const IMPORT_STATUS_PROCESSING_PACKAGES = "processing_packages";
export const IMPORT_STATUS_PROCESSING_PRICES = "processing_prices";
export const IMPORT_STATUS_PROCESSING_PRODUCT_BARCODES = "processing_product_barcodes";
export const IMPORT_STATUS_PROCESSING_PRODUCT_GROUPS = "processing_product_groups";
export const IMPORT_STATUS_PROCESSING_PRODUCT_SOURCES = "processing_product_sources";
export const IMPORT_STATUS_PROCESSING_PRODUCTS = "processing_products";
export const IMPORT_STATUS_PROCESSING_SETTINGS = "processing_settings";
export const IMPORT_STATUS_PROCESSING_SPECTRO = "processing_spectro";
export const IMPORT_STATUS_PROCESSING_STATIC_DATA = "processing_static_data";
export const IMPORT_STATUS_PROCESSING_USER_GROUPS = "processing_user_groups";
export const IMPORT_STATUS_PROCESSING_USERS = "processing_users";
export const IMPORT_STATUS_REMOVING_DATA = "removing_data";
export const IMPORT_STATUS_REMOVING_FORMULAS = "removing_formulas";
export const IMPORT_STATUS_RENAMING_BASES = "renaming_bases";
export const IMPORT_STATUS_RENAMING_COLORANTS = "renaming_colorants";
export const IMPORT_STATUS_RENAMING_COLORS = "renaming_colors";
export const IMPORT_STATUS_RENAMING_FANDECKS = "renaming_fandecks";
export const IMPORT_STATUS_RENAMING_PRODUCT_GROUPS = "renaming_product_groups";
export const IMPORT_STATUS_RENAMING_PRODUCTS = "renaming_products";
export const IMPORT_STATUS_RESULT_ERROR = "result_error";
export const IMPORT_STATUS_RESULT_OK = "result_ok";

export const IMPORT_STATUSES_WAITING = [IMPORT_STATUS_ABORTED, IMPORT_STATUS_ABORTING, IMPORT_STATUS_ENQUEUED];
export const IMPORT_STATUSES_PROGRESS = [
    IMPORT_STATUS_ANALYZING_FILE,
    IMPORT_STATUS_CONNECTING_TO_DATABASE,
    IMPORT_STATUS_OPENING_FILE,
    IMPORT_STATUS_PREPARING_FOR_IMPORT,
    IMPORT_STATUS_PROCESSING_BASE_IN_PRODUCTS,
    IMPORT_STATUS_PROCESSING_BASE_PACKAGES,
    IMPORT_STATUS_PROCESSING_BASES,
    IMPORT_STATUS_PROCESSING_COLOR_BARCODES,
    IMPORT_STATUS_PROCESSING_COLORANT_PACKAGES,
    IMPORT_STATUS_PROCESSING_COLORANTS,
    IMPORT_STATUS_PROCESSING_COLORS,
    IMPORT_STATUS_PROCESSING_COMPONENTS,
    IMPORT_STATUS_PROCESSING_FANDECKS,
    IMPORT_STATUS_PROCESSING_FORMULAS,
    IMPORT_STATUS_PROCESSING_NOTES,
    IMPORT_STATUS_PROCESSING_OPTIONS,
    IMPORT_STATUS_PROCESSING_PACKAGES,
    IMPORT_STATUS_PROCESSING_PRICES,
    IMPORT_STATUS_PROCESSING_PRODUCT_BARCODES,
    IMPORT_STATUS_PROCESSING_PRODUCT_GROUPS,
    IMPORT_STATUS_PROCESSING_PRODUCT_SOURCES,
    IMPORT_STATUS_PROCESSING_PRODUCTS,
    IMPORT_STATUS_PROCESSING_SETTINGS,
    IMPORT_STATUS_PROCESSING_SPECTRO,
    IMPORT_STATUS_PROCESSING_STATIC_DATA,
    IMPORT_STATUS_PROCESSING_USER_GROUPS,
    IMPORT_STATUS_PROCESSING_USERS,
    IMPORT_STATUS_REMOVING_DATA,
    IMPORT_STATUS_REMOVING_FORMULAS,
    IMPORT_STATUS_RENAMING_BASES,
    IMPORT_STATUS_RENAMING_COLORANTS,
    IMPORT_STATUS_RENAMING_COLORS,
    IMPORT_STATUS_RENAMING_FANDECKS,
    IMPORT_STATUS_RENAMING_PRODUCT_GROUPS,
    IMPORT_STATUS_RENAMING_PRODUCTS
];
export const IMPORT_STATUSES_FAILURE = [IMPORT_STATUS_RESULT_ERROR];
export const IMPORT_STATUSES_SUCCESS = [IMPORT_STATUS_RESULT_OK];
export const IMPORT_IGNORE_ACTION_BUTTON = [IMPORT_STATUS_ABORTING];

// import task data types
export type ImportDataType = typeof IMPORT_MASTER_ONLY | typeof IMPORT_ZONE_ONLY | typeof IMPORT_MASTER_AND_ZONE;
export const IMPORT_MASTER_ONLY = "master_only";
export const IMPORT_ZONE_ONLY = "zone_only";
export const IMPORT_MASTER_AND_ZONE = "master_and_zone";

// import task keep old formulas options
export type KeepOldFormulas =
    | typeof KEEP_OLD_FORMULAS_REMOVE_FORMULAS
    | typeof KEEP_OLD_FORMULAS_KEEP_EVERYTHING
    | typeof KEEP_OLD_FORMULAS_REMOVE_ALL;
export const KEEP_OLD_FORMULAS_REMOVE_FORMULAS = 0;
export const KEEP_OLD_FORMULAS_KEEP_EVERYTHING = 1;
export const KEEP_OLD_FORMULAS_REMOVE_ALL = 2;
export const DEFAULT_KEEP_OLD_FORMULAS = KEEP_OLD_FORMULAS_KEEP_EVERYTHING;

// import and export deleted parameter
export type TaskDeletedType = typeof TASK_VISIBLE | typeof TASK_DELETED | typeof TASK_HIDDEN;
export const TASK_VISIBLE = 0;
export const TASK_DELETED = 1;
export const TASK_HIDDEN = 2;

// import task formats
export type ImportFormatType =
    | typeof IMPORT_FORMAT_AKZO_MASTER_ZONE
    | typeof IMPORT_FORMAT_BARIL_EXCEL_MASTER
    | typeof IMPORT_FORMAT_DATACOLOR_BARIL_CSV_MASTER
    | typeof IMPORT_FORMAT_DATACOLOR_CSV_MASTER
    | typeof IMPORT_FORMAT_DATACOLOR_XML_MASTER
    | typeof IMPORT_FORMAT_DESO_MASTER
    | typeof IMPORT_FORMAT_DESO_PRICE_GROUP_BARCODES_ZONE
    | typeof IMPORT_FORMAT_DESO_ZONE;

export const IMPORT_FORMAT_AKZO_MASTER_ZONE = "akzo_master_zone";
export const IMPORT_FORMAT_BARIL_EXCEL_MASTER = "baril_excel_master";
export const IMPORT_FORMAT_DATACOLOR_BARIL_CSV_MASTER = "baril_datacolor_csv_master";
export const IMPORT_FORMAT_DATACOLOR_CSV_MASTER = "datacolor_csv_master";
export const IMPORT_FORMAT_DATACOLOR_XML_MASTER = "datacolor_xml_master";
export const IMPORT_FORMAT_DESO_MASTER = "deso_master";
export const IMPORT_FORMAT_DESO_PRICE_GROUP_BARCODES_ZONE = "deso_price_group_barcodes_zone";
export const IMPORT_FORMAT_DESO_ZONE = "deso_zone";

// import task modules
export const IMPORT_MODULE_MASTER_ALFA = "importer_master_alfa";
export const IMPORT_MODULE_MASTER_CD_COLOR = "importer_master_cd_color";
export const IMPORT_MODULE_MASTER_COLOR_COMPOSER = "importer_master_colour_composer";
export const IMPORT_MODULE_MASTER_COROB_GDATA = "importer_master_corob_gdata";
export const IMPORT_MODULE_MASTER_FLUGGER = "importer_master_flugger";
export const IMPORT_MODULE_MASTER_INNOVATINT_TEXT = "importer_master_innovatint_text";
export const IMPORT_MODULE_MASTER_INNOVATINT_1 = "importer_master_innovatint1";
export const IMPORT_MODULE_MASTER_INNOVATINT_3 = "importer_master_innovatint3";
export const IMPORT_MODULE_MASTER_LARGO_2000 = "importer_master_largo_2000";
export const IMPORT_MODULE_MASTER_LARGO_T3 = "importer_master_largo_t3";
export const IMPORT_MODULE_MASTER_MEFFERT_CSV = "importer_master_meffert_csv";
export const IMPORT_MODULE_MASTER_MEFFERT_SQL = "importer_master_meffert_sql";
export const IMPORT_MODULE_MASTER_PPF = "importer_master_ppf";
export const IMPORT_MODULE_ZONE_MEFFERT = "importer_zone_meffert";
export const IMPORT_MODULE_MASTER_CD_COLOR_2 = "importer_master_cd_color2";
export const IMPORT_MODULE_MASTER_COLIBRI = "importer_master_colibri";
export const IMPORT_MODULE_MASTER_COLIBRI_XML = "importer_master_colibri_xml";
export const IMPORT_MODULE_MASTER_DATACOLOR = "importer_master_datacolor";
export const IMPORT_MODULE_MASTER_DATACOLOR_XML = "importer_master_datacolor_xml";
export const IMPORT_MODULE_MASTER_FLUGGER_2 = "importer_master_flugger2";
export const IMPORT_MODULE_MASTER_DATACOLOR_CSV = "importer_master_datacolor_csv";

// export colors without formulas
export const DEFAULT_COLORS_WITHOUT_FORMULAS = true;

// export default
export const EXPORT_DEFAULT_VERSION = "0.0.0.0";
export const EXPORT_DEFAULT_VERSION_NAME = "000";
