import * as messageHelper from "../../helpers/messageHelper";
import * as methods from "../../constants/serverMethods";
import * as objectTypes from "../../constants/objectTypes";
import * as responseValidationHelper from "../../helpers/responseValidationHelper";
import * as universalObjectActions from "../../actions/universalObjectActions";
import * as websocketActions from "../../actions/websocketActions";

import { Privilege, mapRedlikePrivilege } from "../../types/privilege";

import { AppState } from "../../reducers";
import { ServerResponse } from "../../types/serverResponse";

export const processServerResponse = (method: string, response: ServerResponse, state: AppState): Array<any> => {
    switch (method) {
        case methods.METHOD_GET_ALL_REDLIKE_PRIVILEGES_FOR_USER_GROUP: {
            if (!responseValidationHelper.isArrayResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const privilegeList: Array<Privilege> = [];
            let privilege: Privilege | null;

            for (const item of response.data.data) {
                privilege = mapRedlikePrivilege(item, state.login.loggedUser);

                if (privilege) {
                    privilegeList.push(privilege);
                }
            }

            return [
                universalObjectActions.saveItems(
                    objectTypes.MASTER_REDLIKE_PRIVILEGE,
                    privilegeList,
                    response.data.count
                )
            ];
        }
        case methods.METHOD_RESET_ALL_REDLIKE_PRIVILEGES: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            return [universalObjectActions.reloadData(objectTypes.MASTER_REDLIKE_PRIVILEGE)];
        }
        case methods.METHOD_SET_REDLIKE_PRIVILEGE: {
            if (!responseValidationHelper.isResponseValid(response)) {
                return [messageHelper.getInvalidFormatMessage(response)];
            }

            const privilegeList: Array<Privilege> = [];
            const privilege = mapRedlikePrivilege(response.data, state.login.loggedUser);

            if (privilege) {
                privilegeList.push(privilege);
            }

            return [universalObjectActions.editItemSuccess(objectTypes.MASTER_REDLIKE_PRIVILEGE, privilegeList)];
        }
        default: {
            return [websocketActions.serverUnknown()];
        }
    }
};
