import * as generalHelper from "../../../helpers/generalHelper";
import * as menuConstants from "../../../constants/menuConstants";
import * as methods from "../../../constants/serverMethods";
import * as optionsConstants from "../../../constants/optionsConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import { AppState } from "../../../reducers";
import { Barcode } from "../../../types/barcode";
import { GenericBarcodeScene } from "../../../components/scenes/generic/GenericBarcodeScene";
import { MenuItem } from "../../../types/menu";
import { ServerRequest } from "../../../types/serverRequest";
import { System } from "../../../types/system";
import { SystemZone } from "../../../types/systemZone";

import { connect } from "react-redux";

export type PropsType = Readonly<{
    menuScene: MenuItem | null;
    barcodeTableConstant: string;
    barcodeList: Array<Barcode>;
    barcodeActiveList: Array<Barcode>;
    barcodeLoading: boolean;
    barcodeColumnOrder: Array<propertyConstants.Property>;
    barcodeColumnVisibility: Record<propertyConstants.Property, boolean>;
    barcodeColumnWidth: Record<propertyConstants.Property, number>;
    barcodeOffset: number;
    barcodePage: number;
    barcodeRowCount: number;
    barcodeRowCountCustom: boolean;
    barcodeSceneTableSize: Record<string, any>;
    barcodeSearch: string | null;
    barcodeSearchParams: Record<propertyConstants.Property, string>;
    barcodeShowFilterRow: boolean;
    barcodeShowGlobalSearch: boolean;
    barcodeSortingAsc: boolean;
    barcodeSortingCriterion: propertyConstants.Property | null;
    barcodeTotalCount: number | null;
    sceneLayout: string | null;
    systemList: Array<System>;
    zoneList: Array<SystemZone>;
}>;

const mapStateToProps = (state: AppState): PropsType => ({
    menuScene: generalHelper.getMenuItemByKey(
        state.navigation.menuList,
        menuConstants.MENU_MAIN_PAGE,
        menuConstants.PAGE_GENERIC_BARCODES
    ),
    barcodeTableConstant: menuConstants.TABLE_GENERIC_BARCODES,
    barcodeList: state.barcode.list,
    barcodeActiveList: state.barcode.activeList,
    barcodeLoading: state.server.requests.some(
        (request: ServerRequest) =>
            request.method === methods.METHOD_GET_ALL_BARCODES ||
            request.method === methods.METHOD_GET_ALL_DATABASES_SYSTEMS_ZONES
    ),
    barcodeColumnOrder: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_ORDER,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeColumnVisibility: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_VISIBILITY,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeColumnWidth: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_COLUMNS_WIDTH,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeOffset: state.barcode.offset,
    barcodePage: state.barcode.page,
    barcodeRowCount: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeRowCountCustom: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_TABLE_ROW_COUNT_CUSTOM,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeSceneTableSize: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_TABLE_SIZE,
        menuConstants.PAGE_GENERIC_BARCODES
    ),
    barcodeSearch: state.barcode.search,
    barcodeSearchParams: state.barcode.searchParams,
    barcodeShowFilterRow: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_FILTER_ROW,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeShowGlobalSearch: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SHOW_GLOBAL_SEARCH,
        menuConstants.TABLE_GENERIC_BARCODES
    ),
    barcodeSortingAsc: tableHelper.getSortingObjectValue(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_BARCODES
        )
    ),
    barcodeSortingCriterion: tableHelper.getSortingObjectKey(
        generalHelper.getObjectFromDictionaryByKey(
            state.login.options,
            optionsConstants.OPTION_TABLE_SORTING,
            menuConstants.TABLE_GENERIC_BARCODES
        )
    ),
    barcodeTotalCount: state.barcode.count,
    sceneLayout: generalHelper.getObjectFromDictionaryByKey(
        state.login.options,
        optionsConstants.OPTION_SCENE_LAYOUT,
        menuConstants.PAGE_GENERIC_BARCODES
    ),
    systemList: state.system.allList,
    zoneList: state.databaseSystemZone.systemZoneAllList
});

export const GenericBarcodeSceneContainer = connect(mapStateToProps)(GenericBarcodeScene);
