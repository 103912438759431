import * as bookmarkConstants from "../../../constants/bookmarkConstants";
import * as formattingHelper from "../../../helpers/formattingHelper";
import * as importExportConstants from "../../../constants/entityConstants/importExportConstants";
import * as inputConstants from "../../../constants/inputConstants";
import * as modalHelper from "../../../helpers/modalHelper";
import * as modalTypes from "../../../constants/modalTypes";
import * as objectTypeHelper from "../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../constants/objectTypes";
import * as priceConstants from "../../../constants/entityConstants/priceConstants";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as propertyHelper from "../../../helpers/propertyHelper";
import * as serverRestMethods from "../../../constants/serverRestMethods";
import * as tableConstants from "../../../constants/tableConstants";

import React, { Component } from "react";

import { Currency } from "../../../types/currency";
import { ExportType } from "../../../types/exportType";
import { ExportTypeOptions } from "../../../types/export/exportTypeOptions";
import { ModalItemRow } from "../../general/modal/ModalItemRow";
import { ModalMoreRows } from "../../general/modal/ModalMoreRows";
import { ModalMultipleInputs } from "../../general/modal/ModalMultipleInputs";
import { ModalMultipleInputsRow } from "../../general/modal/ModalMultipleInputsRow";
import { ModalPriceGroup } from "../../general/inputs/ModalPriceGroup";
import { ModalReadOnlyInput } from "../../general/inputs/ModalReadOnlyInput";
import { TableHeading } from "../../../types/tableHeading";
import { t as translate } from "react-i18nify";

type Props = {
    modalId: number;
    modalType: modalTypes.ModalFunctionType;
    sessionUuid: string | null;
    urlRest: string;
    objectType: objectTypes.ObjectType;
    headings: Array<TableHeading>;
    objectData: any;
    bookmarkKey?: string;
    currency?: Currency | null;
    callbackClick?: (heading: TableHeading, property: propertyConstants.Property, editable: boolean) => any;
    callbackChange: (property: propertyConstants.Property, value: any) => any;
    callbackBlur: (property: propertyConstants.Property, value: any) => any;
    confirm?: (property: propertyConstants.Property, id: string | number | null) => any;
    setUploadState?: (isUploading: boolean) => void;
};

export class Form extends Component<Props> {
    getPropertyRowClassName = (
        property: propertyConstants.Property,
        parentProperty: propertyConstants.Property | null
    ): string => {
        const { modalType, objectType, objectData } = this.props;
        let className = "row";

        if (
            modalType === modalTypes.EDIT_MODAL &&
            (objectTypes.COMPARE_DATA_OBJECT_TYPES.includes(objectType) ||
                (objectType === objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE &&
                    propertyConstants.PRICE_PROPERTIES.includes(property)))
        ) {
            className += " master-data";
        }

        if (
            parentProperty &&
            objectData?.[parentProperty] !== undefined &&
            objectData?.[property] !== objectData?.[parentProperty]
        ) {
            className += " restore-button-row";
        }

        const modal = this.props.modalId !== 0 ? document.getElementById(`modal-${this.props.modalId}`) : document;
        const items = modal?.getElementsByClassName(property);

        let elemFound;
        if (items?.length) {
            const childrenNodeArr = Array.from(items);
            elemFound = childrenNodeArr.find((e) => e.classList.contains("visible"));
        }

        if (elemFound) {
            className += " visible-message wrap-row";
        }

        if (propertyConstants.PRICE_PROPERTIES.includes(property)) {
            className += " price-data";
        }

        if (property === propertyConstants.PROPERTY_SHOW_AVAILABLE_ZONES) {
            className += " button-section";
        }

        return className;
    };

    getParentValueInputClassName = (type: tableConstants.TableType): string => {
        let className = "form-input form-input-readonly";

        if (type === tableConstants.TABLE_TYPE_IMAGE_SECTION) {
            className += " image-section";
        }

        return className;
    };

    /*
    Changing heading visibility in special cases
     */
    getUpdatedHeadings = (): Array<TableHeading> => {
        const { headings } = this.props;
        let updatedHeadings = [...headings];

        // Export task type has some properties not visible according to server options attribute
        if (this.props.objectType === objectTypes.EXPORT_TASK && this.props.objectData) {
            const exportTaskTypeIdHeading =
                headings.find(
                    (heading: TableHeading) =>
                        heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID
                ) ?? null;

            const selectedExportTaskType =
                exportTaskTypeIdHeading?.[tableConstants.TABLE_HEADING_ENUM].find(
                    (exportType: ExportType) =>
                        exportType[propertyConstants.PROPERTY_ID] ===
                        this.props.objectData[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID]
                ) ?? null;

            updatedHeadings = headings.map((heading) => {
                const exportTypeOptions: ExportTypeOptions | null =
                    selectedExportTaskType?.[propertyConstants.PROPERTY_EXPORT_TYPE_OPTIONS] ?? null;
                for (const [property, visibility] of Object.entries(exportTypeOptions ?? {})) {
                    if (property === heading[tableConstants.TABLE_HEADING_NAME]) {
                        heading[tableConstants.TABLE_HEADING_VISIBILITY] = visibility;
                    }
                }

                return heading;
            });

            if (
                [
                    importExportConstants.EXPORT_TYPE_DESO_MASTER_EXCEL,
                    importExportConstants.EXPORT_TYPE_DESO_ZONE_EXCEL,
                    importExportConstants.EXPORT_TYPE_TMC_PRODUCTS_EXCEL,
                    importExportConstants.EXPORT_TYPE_EMPTY_DATABASE,
                    importExportConstants.EXPORT_TYPE_ASC_PHB
                ].includes(this.props.objectData?.[propertyConstants.PROPERTY_EXPORT_TASK_TYPE_ID])
            ) {
                updatedHeadings.push(
                    new TableHeading(
                        propertyConstants.PROPERTY_SPACE_TWO,
                        "",
                        tableConstants.TABLE_TYPE_SPACE,
                        true,
                        false,
                        false,
                        false,
                        false,
                        []
                    )
                );
            }
        }

        // Formula has some properties not visible according to its not available status
        if (
            objectTypeHelper.isObjectTypeFormula(this.props.objectType) &&
            this.props.bookmarkKey !== bookmarkConstants.BOOKMARK_NOTES &&
            this.props.objectData
        ) {
            updatedHeadings = headings.map((heading) => {
                if (
                    propertyHelper.isPropertyDisabledForFormulaNotAvailableModal(
                        heading[tableConstants.TABLE_HEADING_NAME]
                    )
                ) {
                    heading[tableConstants.TABLE_HEADING_VISIBILITY] =
                        !this.props.objectData[propertyConstants.PROPERTY_NOT_AVAILABLE];
                }
                return heading;
            });
        }

        return updatedHeadings;
    };

    /*
    Edit modals in system and zone sections (when they have parent items) display parent values for comparison
     */
    getParentValuesHeading = (): JSX.Element | null => {
        const { modalType, objectType } = this.props;

        if (modalType === modalTypes.EDIT_MODAL && objectTypes.COMPARE_DATA_OBJECT_TYPES.includes(objectType)) {
            if (objectTypes.SYSTEM_OBJECT_TYPES.includes(objectType)) {
                return (
                    <div className="form-readonly-header" key="master-data">
                        <div className="header-item">
                            <span>{translate("database.valueInMaster")}</span>
                        </div>
                    </div>
                );
            }

            if (objectTypes.ZONE_OBJECT_TYPES.includes(objectType)) {
                return (
                    <div className="form-readonly-header" key="system-data">
                        <div className="header-item">
                            <span>{translate("system.valueInSystem")}</span>
                        </div>
                    </div>
                );
            }
        }

        return null;
    };

    /*
    Parent values can be inserted as the current values, then the current values are inherited from theirs parents
     */
    getButtonRestore = (
        property: propertyConstants.Property,
        parentProperty: propertyConstants.Property | null
    ): JSX.Element | null => {
        const { objectData } = this.props;

        if (
            parentProperty &&
            objectData?.[parentProperty] !== undefined &&
            objectData?.[property] !== objectData?.[parentProperty]
        ) {
            return (
                <button
                    className="btn btn-info icon-btn"
                    onClick={(): void => this.props.callbackBlur(property, this.props.objectData[parentProperty])}
                >
                    <span className="caret" />
                </button>
            );
        }

        return null;
    };

    /*
    Input does not pass the validation (required value, special text validation)
     */
    getInvalidInputMessage = (property: propertyConstants.Property): JSX.Element => {
        return <div className={`${property} invalid-input-message`}></div>;
    };

    /*
    Parent values are displayed as the second column in Edit modals, when there are parent values in system and zone sections
    Exception for system BIPP price bookmark where parent values should be displayed
     */
    getParentValueInput = (heading: TableHeading, listItem: any = null): JSX.Element | null => {
        const { modalType, objectType, headings, objectData } = this.props;
        const { sessionUuid, urlRest } = this.props;

        const property = heading[tableConstants.TABLE_HEADING_NAME];
        const propertyType = heading[tableConstants.TABLE_HEADING_TYPE];
        const parentProperty = modalHelper.getParentProperty(heading, { type: modalType, objectType: objectType });

        if (
            modalType !== modalTypes.EDIT_MODAL ||
            (!objectTypes.COMPARE_DATA_OBJECT_TYPES.includes(objectType) &&
                (objectType !== objectTypes.SYSTEM_BASE_IN_PRODUCT_PACKAGE ||
                    propertyType !== tableConstants.TABLE_TYPE_PRICE)) ||
            parentProperty === null
        ) {
            return null;
        }

        switch (propertyType) {
            case tableConstants.TABLE_TYPE_IMAGE_SECTION:
                return (
                    <ModalReadOnlyInput
                        className={this.getParentValueInputClassName(propertyType)}
                        objectType={objectType}
                        value={parentProperty !== null ? objectData?.[parentProperty] : null}
                        type={propertyType}
                        property={property}
                        src={
                            parentProperty && sessionUuid
                                ? `${urlRest}${serverRestMethods.METHOD_GET_THUMBNAIL_BY_ID}?session_uuid=${sessionUuid}&image_id=${objectData?.[parentProperty]}&height=1000&width=1000`
                                : null
                        }
                    />
                );
            case tableConstants.TABLE_TYPE_PERCENT:
                return (
                    <ModalReadOnlyInput
                        className={this.getParentValueInputClassName(propertyType)}
                        objectType={objectType}
                        value={formattingHelper.formatPercentWithoutSign(
                            formattingHelper.formatPercent(objectData?.[parentProperty])
                        )}
                        type={propertyType}
                        property={property}
                        unit={"%"}
                    />
                );
            case tableConstants.TABLE_TYPE_PRICE: {
                // TODO classname pres metodu getParentValueInputClassName
                const activeObject = listItem !== null ? listItem : objectData?.[property];

                return (
                    <ModalReadOnlyInput
                        className={
                            activeObject?.[propertyConstants.PROPERTY_INHERITED]
                                ? "form-input form-input-readonly "
                                : "form-input form-input-readonly highlighted-readonly-input"
                        }
                        objectType={objectType}
                        value={activeObject?.[propertyConstants.PROPERTY_INHERITED] || false}
                        valueSource={activeObject?.[propertyConstants.PROPERTY_VALUE_SOURCE] || null}
                        type={propertyType}
                        property={property}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_SELECT: {
                // TODO nicer
                const propertyHeading =
                    headings.find((heading: TableHeading) => heading[tableConstants.TABLE_HEADING_NAME] === property) ||
                    null;
                const valueItem =
                    propertyHeading?.[tableConstants.TABLE_HEADING_ENUM].find(
                        (item: any) => item[propertyConstants.PROPERTY_ID] === (objectData?.[parentProperty] || null)
                    ) || null;

                return (
                    <ModalReadOnlyInput
                        className={this.getParentValueInputClassName(propertyType)}
                        objectType={objectType}
                        value={valueItem?.[propertyConstants.PROPERTY_NAME] || ""}
                        type={propertyType}
                        property={property}
                    />
                );
            }
            case tableConstants.TABLE_TYPE_DECIMAL:
            case tableConstants.TABLE_TYPE_NUMBER:
            case tableConstants.TABLE_TYPE_STRING:
            case tableConstants.TABLE_TYPE_TEXT:
            default:
                return (
                    <ModalReadOnlyInput
                        className={this.getParentValueInputClassName(propertyType)}
                        objectType={objectType}
                        property={property}
                        value={parentProperty !== null ? objectData?.[parentProperty] : ""}
                        type={propertyType}
                    />
                );
        }
    };

    /*
    Some properties can be displayed in multiple rows (barcodes, PARAMETER_TYPE_LIST)
     */
    getMoreInputs = (
        heading: TableHeading,
        property: propertyConstants.Property,
        innerProperty: propertyConstants.Property,
        parameterType: inputConstants.InputType
    ): JSX.Element => {
        const { objectType } = this.props;
        const parentProperty = modalHelper.getParentProperty(heading, {
            type: this.props.modalType,
            objectType: objectType
        });
        // TODO property and heading[tableConstants.TABLE_HEADING_NAME] can differ?

        return (
            <ModalMoreRows
                modalId={this.props.modalId}
                className={this.getPropertyRowClassName(heading[tableConstants.TABLE_HEADING_NAME], parentProperty)}
                parameterType={parameterType}
                objectType={objectType}
                heading={heading}
                property={property}
                innerProperty={innerProperty}
                object={this.props.objectData}
                callbackChange={(value: any): void => {
                    this.props.callbackChange(property, value);
                }}
            />
        );
    };

    getMultipleInputs = (
        properties: Array<propertyConstants.Property>,
        label: propertyConstants.Property
    ): JSX.Element => {
        return (
            <ModalMultipleInputs
                modalId={this.props.modalId}
                objectType={this.props.objectType}
                labelProperty={label}
                properties={properties}
                headings={this.props.headings}
                object={this.props.objectData}
                callbackChange={(property: propertyConstants.Property, value: any) =>
                    this.props.callbackChange(property, value)
                }
                callbackBlur={(property: propertyConstants.Property, value: any) =>
                    this.props.callbackBlur(property, value)
                }
                callbackClick={(heading: TableHeading, property: propertyConstants.Property, editable: boolean) =>
                    this.props.callbackClick
                        ? this.props.callbackClick(heading, property, editable)
                        : (): void => {
                              return;
                          }
                }
            />
        );
    };

    getMultipleInputsRow = (
        properties: Array<propertyConstants.Property>,
        label: propertyConstants.Property
    ): JSX.Element => {
        return (
            <ModalMultipleInputsRow
                modalId={this.props.modalId}
                objectType={this.props.objectType}
                labelProperty={label}
                properties={properties}
                headings={this.props.headings}
                object={this.props.objectData}
                callbackChange={(property: propertyConstants.Property, value: any) =>
                    this.props.callbackChange(property, value)
                }
                callbackBlur={(property: propertyConstants.Property, value: any) =>
                    this.props.callbackBlur(property, value)
                }
                callbackClick={(heading: TableHeading, property: propertyConstants.Property, editable: boolean) =>
                    this.props.callbackClick
                        ? this.props.callbackClick(heading, property, editable)
                        : (): void => {
                              return;
                          }
                }
            />
        );
    };

    getItemRow = (
        heading: TableHeading,
        property: propertyConstants.Property,
        parentProperty: propertyConstants.Property | null
    ): JSX.Element => {
        return (
            <div className={this.getPropertyRowClassName(property, parentProperty)} key={property}>
                <ModalItemRow
                    modalId={this.props.modalId}
                    objectType={this.props.objectType}
                    heading={heading}
                    object={this.props.objectData}
                    currency={this.props.currency || null}
                    callbackClick={(heading: TableHeading, property: propertyConstants.Property, editable: boolean) =>
                        this.props.callbackClick
                            ? this.props.callbackClick(heading, property, editable)
                            : (): void => {
                                  return;
                              }
                    }
                    callbackChange={(property: propertyConstants.Property, value: any) =>
                        this.props.callbackChange(property, value)
                    }
                    callbackBlur={(property: propertyConstants.Property, value: any) =>
                        this.props.callbackBlur(property, value)
                    }
                    confirm={(property: propertyConstants.Property, id: string | number | null) =>
                        this.props.confirm
                            ? this.props.confirm(property, id)
                            : (): void => {
                                  return;
                              }
                    }
                    setUploadState={(isUploading: boolean) =>
                        this.props.setUploadState
                            ? this.props.setUploadState(isUploading)
                            : () => {
                                  return;
                              }
                    }
                />
                {this.getButtonRestore(property, parentProperty)}
                {this.getParentValueInput(heading)}
                {this.getInvalidInputMessage(property)}
            </div>
        );
    };

    getEmptyItemRows = (property: propertyConstants.Property): Array<JSX.Element> => {
        switch (property) {
            case propertyConstants.PROPERTY_SPACE_TWO:
                return [<div className="row" key={`${property}-1`} />, <div className="row" key={`${property}-2`} />];
            case propertyConstants.PROPERTY_SPACE_ONE:
            default: {
                return [<div className="row" key={property} />];
            }
        }
    };

    getForm = (): Array<JSX.Element> => {
        const { bookmarkKey } = this.props;
        const form = [];
        const updatedHeadings = this.getUpdatedHeadings();

        if (
            bookmarkKey === bookmarkConstants.BOOKMARK_GENERAL ||
            bookmarkKey === bookmarkConstants.BOOKMARK_COLORANTS_AMOUNT
        ) {
            const parentHeading = this.getParentValuesHeading();

            if (parentHeading) {
                form.push(parentHeading);
            }
        }

        for (const heading of updatedHeadings) {
            const property = heading[tableConstants.TABLE_HEADING_NAME];

            // TODO improve getParentProperty function
            const parentProperty = modalHelper.getParentProperty(heading, {
                type: this.props.modalType,
                objectType: this.props.objectType
            });
            const isMultiPropertyRow =
                this.props.objectType === objectTypes.MASTER_FORMULA &&
                property === propertyConstants.PROPERTY_IS_GRAVIMETRIC;
            const classname = this.getPropertyRowClassName(heading[tableConstants.TABLE_HEADING_NAME], parentProperty);

            // Property row should be displayed
            if (
                heading[tableConstants.TABLE_HEADING_VISIBILITY] &&
                !propertyConstants.SKIP_MODAL_PROPERTIES.includes(property) &&
                !isMultiPropertyRow
            ) {
                switch (property) {
                    case propertyConstants.PROPERTY_ALTERNATIVE_BARCODES:
                        form.push(
                            <div key={property} className="row">
                                {this.getMoreInputs(
                                    heading,
                                    propertyConstants.PROPERTY_ALTERNATIVE_BARCODES,
                                    propertyConstants.PROPERTY_BARCODE,
                                    inputConstants.PARAMETER_TYPE_BARCODE
                                )}{" "}
                                {this.getParentValueInput(heading)}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_BARCODES:
                        form.push(
                            <div key={property} className="row">
                                {this.getMoreInputs(
                                    heading,
                                    propertyConstants.PROPERTY_BARCODES,
                                    propertyConstants.PROPERTY_BARCODE,
                                    inputConstants.PARAMETER_TYPE_BARCODE
                                )}{" "}
                                {this.getParentValueInput(heading)}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_DIVIDING_LINE: {
                        form.push(<div className="modal-line" key={property} />);
                        break;
                    }
                    case priceConstants.PRICE_OPTION_PRICE_CALCULATION:
                        form.push(
                            <div key={property} className="row">
                                {this.getMoreInputs(
                                    heading,
                                    priceConstants.PRICE_OPTION_PRICE_CALCULATION,
                                    propertyConstants.PROPERTY_VALUE,
                                    inputConstants.PARAMETER_TYPE_LIST
                                )}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST:
                        form.push(
                            <div key={property} className="row">
                                {this.getMoreInputs(
                                    heading,
                                    propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST,
                                    propertyConstants.PROPERTY_PRICE_GROUP_BARCODE_LIST,
                                    inputConstants.PARAMETER_TYPE_TEXT_INPUT
                                )}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_PRICE_GROUP_LIST:
                        for (const item of this.props.objectData[property]) {
                            const parentInput: JSX.Element | null = this.getParentValueInput(heading, item);
                            const className = parentInput !== null ? "row price-data master-data" : "row price-data";
                            form.push(
                                <div key={item.name} className={className}>
                                    <ModalPriceGroup
                                        objectType={this.props.objectType}
                                        heading={heading}
                                        objectData={this.props.objectData}
                                        item={item}
                                        property={property}
                                        currency={this.props.currency}
                                        callbackChange={this.props.callbackChange}
                                    />
                                    {parentInput}
                                </div>
                            );
                        }
                        break;
                    case propertyConstants.PROPERTY_DATA_TYPE_LIST:
                    case propertyConstants.PROPERTY_STATUS_LIST:
                        form.push(
                            <div key={property} className="row">
                                {this.getMoreInputs(
                                    heading,
                                    property,
                                    propertyConstants.PROPERTY_VALUE_ENUM,
                                    inputConstants.PARAMETER_TYPE_LIST
                                )}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_BASE_AMOUNT:
                        form.push(
                            <div key={property} className="row">
                                {this.getMultipleInputs(
                                    [propertyConstants.PROPERTY_BASE_AMOUNT, propertyConstants.PROPERTY_IS_GRAVIMETRIC],
                                    property
                                )}
                            </div>
                        );
                        break;
                    case propertyConstants.PROPERTY_MORE_INFO:
                    case propertyConstants.PROPERTY_MORE_INFO_INTERNAL:
                    case propertyConstants.PROPERTY_MORE_INFO_PRINT: {
                        const secondProperty = propertyHelper.getFormattedMoreInfoProperty(property);

                        if (
                            this.props.bookmarkKey &&
                            this.props.bookmarkKey === bookmarkConstants.BOOKMARK_NOTES &&
                            secondProperty !== null
                        ) {
                            form.push(
                                <div key={property} className="row">
                                    {this.getMultipleInputs([property, secondProperty], property)}
                                </div>
                            );
                            break;
                        } else {
                            form.push(this.getItemRow(heading, property, parentProperty));
                            break;
                        }
                    }
                    case propertyConstants.PROPERTY_COLOR_RGB_HEX: {
                        form.push(
                            <div key={property} className={classname}>
                                {this.getMultipleInputsRow(
                                    [
                                        propertyConstants.PROPERTY_COLOR_RED,
                                        propertyConstants.PROPERTY_COLOR_GREEN,
                                        propertyConstants.PROPERTY_COLOR_BLUE,
                                        propertyConstants.PROPERTY_COLOR_RGB_HEX
                                    ],
                                    property
                                )}
                                {this.getButtonRestore(property, parentProperty)}
                                {this.getParentValueInput(heading)}
                            </div>
                        );
                        break;
                    }
                    case propertyConstants.PROPERTY_DATE_FROM: {
                        form.push(
                            <div key={property} className={classname}>
                                {this.getMultipleInputsRow(
                                    [propertyConstants.PROPERTY_DATE_FROM, propertyConstants.PROPERTY_DATE_TO],
                                    property
                                )}
                            </div>
                        );
                        break;
                    }
                    case propertyConstants.PROPERTY_SPACE_ONE:
                    case propertyConstants.PROPERTY_SPACE_TWO:
                        this.getEmptyItemRows(property).map((row) => form.push(row));
                        break;
                    default:
                        form.push(this.getItemRow(heading, property, parentProperty));
                        break;
                }
            }
        }

        return form;
    };

    render(): JSX.Element {
        const className =
            this.props.bookmarkKey === bookmarkConstants.BOOKMARK_GENERAL ||
            this.props.bookmarkKey === bookmarkConstants.BOOKMARK_PROPERTIES
                ? "slim-scroll"
                : undefined;
        return (
            <form
                onSubmit={(event): void => {
                    event.preventDefault();
                }}
                className={className}
            >
                {this.getForm()}
            </form>
        );
    }
}
