import * as actionConstants from "../constants/actionTypes";
import * as methods from "../constants/serverMethods";

import { ServerRequest } from "./websocketActions";

export interface SaveUserCompanyId {
    type: typeof actionConstants.SAVE_USER_COMPANY_ID;
    payload: {
        companyId: number | null;
    };
}

export function saveUserCompanyId(companyId: number | null): SaveUserCompanyId {
    return {
        type: actionConstants.SAVE_USER_COMPANY_ID,
        payload: {
            companyId
        }
    };
}

// redlike
export function generateOneTimeAccessPassword(computerId: string, date: string | null): ServerRequest {
    return {
        type: actionConstants.SERVER_REQUEST,
        payload: {
            type: methods.METHOD_GET_GENERATE_REDLIKE_ONE_TIME_ACCESS_PASSWORD,
            params: {
                computerId: computerId,
                dateUtc: date
            }
        },
        method: methods.METHOD_GET_GENERATE_REDLIKE_ONE_TIME_ACCESS_PASSWORD
    };
}

export interface SavePassword {
    type: typeof actionConstants.SAVE_REDLIKE_PASSWORD;
    payload: {
        password: string | null;
    };
}

export function savePassword(password: string | null): SavePassword {
    return {
        type: actionConstants.SAVE_REDLIKE_PASSWORD,
        payload: {
            password
        }
    };
}
