import * as defaultConstants from "../../../constants/default";
import * as importExportConstants from "../../../constants/entityConstants/importExportConstants";
import * as objectTypes from "../../../constants/objectTypes";
import * as propertyConstants from "../../../constants/propertyConstants";
import * as tableConstants from "../../../constants/tableConstants";
import * as tableHelper from "../../../helpers/tableHelper";

import React, { Component } from "react";
import { AppState } from "../../../reducers";
import { Currency } from "../../../types/currency";
import { Dispatch } from "redux";
import { ModalProperties } from "../../../types/modalProperties";
import { Price } from "../../../types/price";
import { TableCellContainer as TableCell } from "./TableCell";
import { TableHeading } from "../../../types/tableHeading";

import { connect } from "react-redux";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    key: number | string;
    objectType: objectTypes.ObjectType;
    dataPrivileges: any;
    headings: Array<TableHeading>;
    item: any;
    activeContent: string;
    activeProperty: propertyConstants.Property | null;
    currency?: Currency | null;
    modalProperties: ModalProperties;
    isRowActive: boolean;
    isRowFirst: boolean;
    isRowFocused: boolean;
    isRowLast: boolean;
    callbackBlur: (
        event: any,
        value: any,
        rowId?: number | string,
        headingId?: propertyConstants.Property,
        price?: Price
    ) => any;
    callbackChange: (isValid: boolean, value: string) => any;
    callbackClick: (event: any) => any;
    callbackDoubleClick: (event: any) => any;
    callbackFocus: (property: propertyConstants.Property, itemId: number | string, value: string) => any;
    callbackMouseEnter: (event: any, heading: TableHeading, value: any, isSourceChanged: boolean) => any;
    callbackMouseLeave: () => any;
};

type Props = OwnProps & ContainerProps;

export class TableItemRow extends Component<Props> {
    getRowClassname = (): string => {
        const { item } = this.props;
        let className = "tr";

        if (this.props.isRowActive) {
            className += " active";
        }

        if (this.props.isRowFocused) {
            className += " tr-focused";
        }

        if (item[propertyConstants.PROPERTY_DISABLED]) {
            className += " disabled";
        }

        if (item[propertyConstants.PROPERTY_DELETED] === importExportConstants.TASK_DELETED) {
            className += " deleted-row";
        }

        if (item[propertyConstants.PROPERTY_DELETED] === importExportConstants.TASK_HIDDEN) {
            className += " hidden-row";
        }

        if (!this.props.dataPrivileges?.[propertyConstants.PROPERTY_CAN_UPDATE]) {
            className += " read-only";
        }

        // TODO je to nutne? nemelo by to byt i u importu?
        if (item[propertyConstants.PROPERTY_EXPORT_TASK_STATUS_ID] === importExportConstants.EXPORT_STATUS_ERROR) {
            className += " export-error";
        }

        return className;
    };

    getHeadingKey = (heading: TableHeading): string => {
        if (heading[tableConstants.TABLE_HEADING_NAME] === propertyConstants.PROPERTY_GENERATED) {
            return (
                heading[tableConstants.TABLE_HEADING_NAME] + "_" + heading[tableConstants.TABLE_HEADING_GENERATED_KEY]
            );
        }

        return heading[tableConstants.TABLE_HEADING_NAME];
    };

    getRowCells = (itemId: number | string): Array<JSX.Element> => {
        const cells: Array<JSX.Element> = [];

        for (const heading of this.props.headings) {
            if (heading[tableConstants.TABLE_HEADING_VISIBILITY]) {
                const isSourceChanged = tableHelper.isPropertySourceChanged(
                    this.props.objectType,
                    heading[tableConstants.TABLE_HEADING_NAME],
                    this.props.item
                );

                const cell = (
                    <TableCell
                        key={this.getHeadingKey(heading)}
                        objectType={this.props.objectType}
                        dataPrivileges={this.props.dataPrivileges}
                        heading={heading}
                        item={this.props.item}
                        activeContent={this.props.activeContent}
                        currency={this.props.currency}
                        modalProperties={this.props.modalProperties}
                        isCellActive={this.props.activeProperty === heading[tableConstants.TABLE_HEADING_NAME]}
                        isRowFirst={this.props.isRowFirst}
                        isRowFocused={this.props.isRowFocused}
                        isRowLast={this.props.isRowLast}
                        isSourceChanged={isSourceChanged}
                        callbackBlur={(
                            event: any,
                            value: any,
                            rowId?: number | string,
                            headingId?: propertyConstants.Property,
                            price?: Price
                        ): void => this.props.callbackBlur(event, value, rowId, headingId, price)}
                        callbackChange={(isValid: boolean, value: string): void =>
                            this.props.callbackChange(isValid, value)
                        }
                        callbackFocus={(value: string): void =>
                            this.props.callbackFocus(heading[tableConstants.TABLE_HEADING_NAME], itemId, value)
                        }
                        callbackMouseEnter={(event: any, value: any): void =>
                            this.props.callbackMouseEnter(event, heading, value, isSourceChanged)
                        }
                        callbackMouseLeave={(): void => this.props.callbackMouseLeave()}
                    />
                );

                if (cell) {
                    cells.push(cell);
                }
            }
        }

        return cells;
    };

    render(): JSX.Element | null {
        const { item } = this.props;

        const itemId =
            item && item[propertyConstants.PROPERTY_ID] !== undefined
                ? item[propertyConstants.PROPERTY_ID]
                : defaultConstants.ID_UNKNOWN;

        if (!item) {
            return null;
        }

        if (item[propertyConstants.PROPERTY_DISABLED]) {
            return (
                <div key={itemId} className={this.getRowClassname()}>
                    {this.getRowCells(itemId)}
                </div>
            );
        }

        // TODO onFocus, ref
        return (
            <div
                key={itemId}
                className={this.getRowClassname()}
                onClick={(event: any): any => {
                    this.props.callbackClick(event);
                }}
                onDoubleClick={(event: any): any => this.props.callbackDoubleClick(event)}
            >
                {this.getRowCells(itemId)}
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsType = Readonly<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DispatchType = Readonly<{}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({});

export const TableItemRowContainer = connect(mapStateToProps, mapDispatchToProps)(TableItemRow);
