import * as generalHelper from "../../../../helpers/generalHelper";
import * as importExportTaskHelper from "../../../../helpers/importExportTaskHelper";
import * as navigationActions from "../../../../actions/navigationActions";
import * as objectTypeHelper from "../../../../helpers/objectTypeHelper";
import * as objectTypes from "../../../../constants/objectTypes";
import * as privilegesHelper from "../../../../helpers/privilegesHelper";
import * as propertyConstants from "../../../../constants/propertyConstants";
import * as requestHelper from "../../../../helpers/requestHelper";
import * as serverRestMethods from "../../../../constants/serverRestMethods";
import * as universalObjectActions from "../../../../actions/universalObjectActions";

import React, { Component } from "react";

import { AppState } from "../../../../reducers";
import { Database } from "../../../../types/database";
import { Dispatch } from "redux";
import { Modal } from "../../../../types/modal";
import { ModalProperties } from "../../../../types/modalProperties";
import { ModalType } from "../../../../constants/modalTypes";
import { ReactSVG } from "react-svg";
import { System } from "../../../../types/system";
import { Zone } from "../../../../types/zone";

import { connect } from "react-redux";
import { findDOMNode } from "react-dom";

import imgMenu from "../../../../resources/img/icons/menu.svg";

import { t as translate } from "react-i18nify";

type ContainerProps = PropsType & DispatchType;

type OwnProps = {
    dataPrivileges: any;
    objectType: objectTypes.ObjectType;
    activeList: Array<any>;
    tableConstant: string;
    modalProperties: ModalProperties;
    editable: boolean;
};

type Props = OwnProps & ContainerProps;

type State = {
    menuIsOpened: boolean;
};

export class TableMoreActionsButton extends Component<Props, State> {
    _dropdownRef = React.createRef<HTMLDivElement>();

    state: State = {
        menuIsOpened: false
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeDropdownMenu);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeDropdownMenu);
    }

    handleTableMenuClick = (on?: boolean): void => {
        this.setState({
            menuIsOpened: on !== undefined ? on : true
        });
    };

    handleItemClick = (): void => {
        const { objectType, urlRest, sessionUuid, activeList } = this.props;

        if (objectType === objectTypes.DATABASE) {
            if (activeList.length > 0) {
                const activeUrl = `${urlRest}${serverRestMethods.METHOD_BACKUP_DATABASE}?database_id=${
                    activeList[0][propertyConstants.PROPERTY_ID]
                }&as_attachment=True&session_uuid=${sessionUuid}`;

                if (activeUrl) {
                    window.open(activeUrl);
                }
            }
        }
    };

    handleOpenModal = (modalType: any, modalParams: any): void => {
        if (modalType) {
            this.props.openModal(modalType, modalParams);
        }
    };

    handleGetItemDetail = (
        objectType: objectTypes.ObjectType,
        params: Record<string, any>,
        modal: Modal | null
    ): void => {
        this.props.getItemDetail(objectType, params, modal);
    };

    /**
     Button is visible if
     * object type is defined in constants
     * for Redlike option object types the table is editable (canUpdate attribute) for logged in user according to data privileges
     * for import and export tasks the logged in user has data privileges to delete the records (canDelete attribute) and also privilege is available
     */
    isVisible = (): boolean => {
        const { dataPrivileges, objectType, privileges } = this.props;

        if (objectTypes.VISIBLE_MORE_ACTION_BUTTON_OBJECT_TYPES.includes(objectType)) {
            return true;
        }

        if (
            objectTypeHelper.isObjectTypeRedlikeOption(objectType) &&
            privilegesHelper.isDataPrivilegesCanUpdate(dataPrivileges)
        ) {
            return true;
        }

        if (
            objectTypeHelper.isObjectTypeExportImport(objectType) &&
            privilegesHelper.isDataPrivilegesCanDelete(dataPrivileges) &&
            importExportTaskHelper.isDeletingImportExportTasksEnabled(objectType, privileges, [])
        ) {
            return true;
        }

        return false;
    };

    /**
     Button is enabled if
     * data are not loading
     * if database, system and zone are selected when working with data in master, system or zone section
     * for object types export task, import task, generic image and product sheet, the button is always enabled
     * if data privileges are set that logged in user can update the table
     */
    isEnabled = (): boolean => {
        const { dataPrivileges, objectType } = this.props;

        if (!this.props.editable) {
            return false;
        }

        if (
            !objectTypeHelper.isObjectTypeEnabledByHeader(
                this.props.objectType,
                this.props.activeDatabase ?? null,
                this.props.activeSystem ?? null,
                this.props.activeZone ?? null
            )
        ) {
            return false;
        }

        if (
            [
                objectTypes.EXPORT_TASK,
                objectTypes.GENERIC_IMAGE,
                objectTypes.IMPORT_TASK,
                objectTypes.PRODUCT_SHEET
            ].includes(objectType)
        ) {
            return true;
        }

        if (dataPrivileges?.canView && dataPrivileges?.canUpdate) {
            return true;
        }

        return false;
    };

    preventParentClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
    };

    canDuplicateAllItems = (items: Array<any>): boolean => {
        return !items.some((item) => item[propertyConstants.PROPERTY_CAN_UPDATE] === false);
    };

    closeDropdownMenu = (event: Record<string, any>): void => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.handleTableMenuClick(false);
        }
    };

    getDropdownOptionTranslation = (iSecond: boolean = false): string => {
        const { objectType } = this.props;

        switch (objectType) {
            case objectTypes.DATABASE:
                if (iSecond) {
                    return translate("database.duplicateSelectedDatabase");
                }

                return translate("database.backupSelectedDatabase");
            case objectTypes.EXPORT_TASK:
                return translate("export.deleteExports");
            case objectTypes.GENERIC_IMAGE:
                return translate("image.imageCategories");
            case objectTypes.IMPORT_TASK:
                return translate("import.deleteImports");
            case objectTypes.MASTER_REDLIKE_USER_GROUP:
                return translate("user.duplicateUserGroup");
            case objectTypes.MASTER_PRODUCT:
                return translate("product.duplicateProduct");
            case objectTypes.SYSTEM_PRODUCT:
            case objectTypes.ZONE_PRODUCT:
                if (iSecond) {
                    return translate("product.duplicateProduct");
                }

                return translate("productGroup.selectProductGroups");
            case objectTypes.PRODUCT_SHEET:
                return translate("file.fileCategories");
            case objectTypes.MASTER_REDLIKE_OPTION:
            case objectTypes.SYSTEM_REDLIKE_OPTION:
            case objectTypes.ZONE_REDLIKE_OPTION:
                return translate("general.restoreAllValues");
            case objectTypes.SYSTEM:
                return translate("system.duplicateSystem");
            case objectTypes.ZONE:
                return translate("zone.duplicateZone");
            default:
                return "";
        }
    };

    getDropdownOptionMethod = (isSecond: boolean = false): any => {
        const { modalProperties, objectType } = this.props;

        switch (objectType) {
            case objectTypes.DATABASE: {
                if (isSecond) {
                    return (): void =>
                        this.handleOpenModal(modalProperties.duplicateModalType, modalProperties.duplicateModalParams);
                }

                return (): void => this.handleItemClick();
            }
            case objectTypes.EXPORT_TASK:
            case objectTypes.IMPORT_TASK: {
                return (): void =>
                    this.handleOpenModal(modalProperties.deleteModalType, modalProperties.deleteModalParams);
            }
            case objectTypes.GENERIC_IMAGE: {
                return (): void =>
                    this.handleOpenModal(
                        modalProperties.imageCategoriesModalType,
                        modalProperties.imageCategoriesModalParams
                    );
            }
            case objectTypes.MASTER_REDLIKE_USER_GROUP: {
                return (): void =>
                    this.handleOpenModal(modalProperties.duplicateModalType, modalProperties.duplicateModalParams);
            }
            case objectTypes.MASTER_PRODUCT: {
                if (modalProperties.duplicateModalParams && modalProperties.duplicateModalType) {
                    if (
                        generalHelper.updateActiveDataInTable(
                            modalProperties.duplicateModalParams.objectType,
                            modalProperties.duplicateModalParams.data
                        )
                    ) {
                        const modal = new Modal(
                            0,
                            modalProperties.duplicateModalType,
                            modalProperties.duplicateModalParams,
                            null
                        );

                        return (): void =>
                            this.handleGetItemDetail(
                                modalProperties.duplicateModalParams.objectType,
                                {
                                    [requestHelper.getObjectIdProperty(
                                        modalProperties.duplicateModalParams.objectType
                                    )]: modalProperties.duplicateModalParams.data[propertyConstants.PROPERTY_ID]
                                },
                                modal
                            );
                    } else {
                        return (): void =>
                            this.handleOpenModal(
                                modalProperties.duplicateModalType,
                                modalProperties.duplicateModalParams
                            );
                    }
                }

                return (): void => {
                    return;
                };
            }
            case objectTypes.SYSTEM_PRODUCT:
            case objectTypes.ZONE_PRODUCT: {
                if (isSecond) {
                    if (modalProperties.duplicateModalParams && modalProperties.duplicateModalType) {
                        if (
                            generalHelper.updateActiveDataInTable(
                                modalProperties.duplicateModalParams.objectType,
                                modalProperties.duplicateModalParams.data
                            )
                        ) {
                            const modal = new Modal(
                                0,
                                modalProperties.duplicateModalType,
                                modalProperties.duplicateModalParams,
                                null
                            );

                            return (): void =>
                                this.handleGetItemDetail(
                                    modalProperties.duplicateModalParams.objectType,
                                    {
                                        [requestHelper.getObjectIdProperty(
                                            modalProperties.duplicateModalParams.objectType
                                        )]: modalProperties.duplicateModalParams.data[propertyConstants.PROPERTY_ID]
                                    },
                                    modal
                                );
                        } else {
                            return (): void =>
                                this.handleOpenModal(
                                    modalProperties.duplicateModalType,
                                    modalProperties.duplicateModalParams
                                );
                        }
                    }

                    return (): void => {
                        return;
                    };
                }
                return (): void =>
                    this.handleOpenModal(
                        modalProperties.productGroupsModalType,
                        modalProperties.productGroupsModalParams
                    );
            }
            case objectTypes.PRODUCT_SHEET: {
                return (): void =>
                    this.handleOpenModal(
                        modalProperties.fileCategoriesModalType,
                        modalProperties.fileCategoriesModalParams
                    );
            }
            case objectTypes.MASTER_REDLIKE_OPTION:
            case objectTypes.SYSTEM_REDLIKE_OPTION:
            case objectTypes.ZONE_REDLIKE_OPTION: {
                return (): void => this.props.resetAllValues(objectType);
            }
            case objectTypes.SYSTEM: {
                return (): void =>
                    this.handleOpenModal(modalProperties.duplicateModalType, modalProperties.duplicateModalParams);
            }
            case objectTypes.ZONE: {
                return (): void =>
                    this.handleOpenModal(modalProperties.duplicateModalType, modalProperties.duplicateModalParams);
            }
            default: {
                return (): void => {
                    return;
                };
            }
        }
    };

    getDropdownOption = (isSecond: boolean = false): JSX.Element => {
        return (
            <li
                className="dropdown-item"
                key={this.getDropdownOptionTranslation(isSecond)}
                onClick={this.getDropdownOptionMethod(isSecond)}
            >
                <span className="item-title">{this.getDropdownOptionTranslation(isSecond)}</span>
            </li>
        );
    };

    getTableMenuDropdownOption = (): Array<JSX.Element> => {
        const { objectType } = this.props;
        const menuList: Array<JSX.Element> = [];

        switch (objectType) {
            case objectTypes.DATABASE:
            case objectTypes.SYSTEM_PRODUCT:
            case objectTypes.ZONE_PRODUCT: {
                menuList.push(this.getDropdownOption(), this.getDropdownOption(true));
                break;
            }
            case objectTypes.EXPORT_TASK:
            case objectTypes.GENERIC_IMAGE:
            case objectTypes.IMPORT_TASK:
            case objectTypes.MASTER_REDLIKE_USER_GROUP:
            case objectTypes.MASTER_PRODUCT:
            case objectTypes.PRODUCT_SHEET:
            case objectTypes.MASTER_REDLIKE_OPTION:
            case objectTypes.SYSTEM_REDLIKE_OPTION:
            case objectTypes.ZONE_REDLIKE_OPTION:
            case objectTypes.SYSTEM:
            case objectTypes.ZONE: {
                menuList.push(this.getDropdownOption());
                break;
            }
            default:
                break;
        }

        return menuList;
    };

    getTableMenuDropdown = (): JSX.Element | null => {
        const { menuIsOpened } = this.state;

        if (menuIsOpened) {
            return (
                <ul id="main-table-dropdown" className="table-dropdown-container">
                    <div className="table-dropdown-content slim-scroll">{this.getTableMenuDropdownOption()}</div>
                </ul>
            );
        }

        return null;
    };

    getIcon = (): JSX.Element => {
        if (objectTypeHelper.isObjectTypeRedlikeOption(this.props.objectType)) {
            return <span className="caret caret-down"></span>;
        }

        return <ReactSVG className="table-options-icon menu-icon" src={imgMenu} />;
    };

    getClassName = (): string => {
        let className = "table-menu";

        if (
            privilegesHelper.isDataPrivilegesReadOnly(this.props.dataPrivileges) &&
            this.props.objectType !== objectTypes.DATABASE
        ) {
            className += " margin-left";
        }

        if (objectTypeHelper.isObjectTypeRedlikeOption(this.props.objectType)) {
            className += " restore-options";
        }

        return className;
    };

    render(): JSX.Element | null {
        const { menuIsOpened } = this.state;

        if (this.isVisible() && this.isEnabled()) {
            return (
                <div className={this.getClassName()} ref={this._dropdownRef}>
                    <button
                        className="btn-md btn-info btn-option"
                        onClick={(): void => {
                            this.handleTableMenuClick(!menuIsOpened);
                        }}
                    >
                        {this.getIcon()}
                    </button>
                    {this.getTableMenuDropdown()}
                </div>
            );
        }

        if (this.isVisible()) {
            return (
                <div className={this.getClassName()} ref={this._dropdownRef}>
                    <button className="btn-md btn-info btn-option disabled">{this.getIcon()}</button>
                </div>
            );
        }

        return null;
    }
}

export type PropsType = Readonly<{
    activeDatabase: Database | null;
    activeSystem: System | null;
    activeZone: Zone | null;
    privileges: Record<string, number>;
    sessionUuid: string | null;
    urlRest: string;
}>;

export type DispatchType = Readonly<{
    getItemDetail(objectType: objectTypes.ObjectType, params: Record<string, any>, modal: Modal | null): void;
    openModal(type: ModalType, params: Record<string, any>): void;
    resetAllValues(objectType: objectTypes.ObjectType): void;
}>;

const mapStateToProps = (state: AppState, ownProps: OwnProps): PropsType => ({
    activeDatabase: state.database.active,
    activeSystem: state.system.active,
    activeZone: state.zone.active,
    privileges: state.login.privileges,
    sessionUuid: state.software.sessionUuid,
    urlRest: state.server.urlRest
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchType => ({
    getItemDetail: (objectType: objectTypes.ObjectType, params: Record<string, any>, modal: Modal | null): any =>
        dispatch(universalObjectActions.getItemDetail(objectType, params, modal)),
    openModal: (type: ModalType, params: Record<string, any>): any =>
        dispatch(navigationActions.navigationOpenModal(type, params)),
    resetAllValues: (objectType: objectTypes.ObjectType): any =>
        dispatch(universalObjectActions.resetAllValues(objectType))
});

export const TableMoreActionsButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableMoreActionsButton);
