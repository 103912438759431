import * as colorHelper from "../../helpers/colorHelper";
import * as formulaConstants from "../../constants/entityConstants/formulaConstants";
import * as generalHelper from "../../helpers/generalHelper";
import * as objectTypes from "../../constants/objectTypes";
import * as optionConstants from "../../constants/optionsConstants";
import * as propertyConstants from "../../constants/propertyConstants";

import React, { Component } from "react";

import { Formula } from "../../types/formula";
import { FormulaColorantTable } from "../general/table/FormulaColorantTable";
import { MenuItem } from "../../types/menu";
import { ModalSelectbox } from "../general/inputs/ModalSelectbox";
import { TableHeading } from "../../types/tableHeading";
import { TextPreview } from "../general/scene/TextPreview";
import { Unit } from "../../types/unit";

type Props = {
    menuScene: MenuItem | null;
    tableConstant: string;
    headings: Array<TableHeading>;
    activeFormula: Formula | null;
    columnOrder: Array<propertyConstants.Property>;
    columnVisibility: Record<propertyConstants.Property, boolean>;
    columnWidth: Record<propertyConstants.Property, number>;
    activeUnit: Unit | null;
    unitList: Array<Unit>;
    setOptions(options: Array<any>, locked: boolean): any;
};

export class FormulaPropertiesBookmark extends Component<Props> {
    getColorantTable(activeFormula: Formula) {
        const { menuScene, tableConstant } = this.props;
        const tableObjectType = colorHelper.getColorObjectType(formulaConstants.FORMULA_COLORANT, tableConstant);

        if (tableObjectType === null) {
            return null;
        }

        if (activeFormula.status !== formulaConstants.FORMULA_STATUS_NOT_AVAILABLE) {
            return (
                <div className="data-color-container colorant-table-container">
                    <div className="unit-select-container">
                        <ModalSelectbox
                            className={"scene-select"}
                            editable={true}
                            required={true}
                            value={
                                this.props.activeUnit
                                    ? this.props.activeUnit[propertyConstants.PROPERTY_NAME]
                                    : optionConstants.DEFAULT_FORMULA_COLORANT_UNIT
                            }
                            options={generalHelper.getOptionsForSelectbox(
                                objectTypes.FORMULA_COLORANT_UNIT,
                                this.props.unitList
                            )}
                            autoselect={false}
                            callback={(event: any, key: string) => {
                                this.props.setOptions(
                                    [
                                        {
                                            key: optionConstants.OPTION_FORMULA_COLORANT_UNIT,
                                            value: key,
                                            locked: false
                                        }
                                    ],
                                    true
                                );
                            }}
                        />
                    </div>
                    <FormulaColorantTable
                        tableConstant={tableConstant}
                        objectType={tableObjectType}
                        dataPrivileges={
                            (menuScene ? menuScene.items.find((item) => item.key === tableConstant) : null) || null
                        }
                        formulaId={activeFormula?.[propertyConstants.PROPERTY_ID] ?? undefined}
                        allList={activeFormula[propertyConstants.PROPERTY_COLORANT_LIST]}
                        isEditable={false}
                        columnOrder={this.props.columnOrder}
                        columnVisibility={this.props.columnVisibility}
                        columnWidth={this.props.columnWidth}
                        showFooterRow={true}
                        sortingAsc={activeFormula?.[propertyConstants.PROPERTY_COLORANT_SORTING_ASC] ?? true}
                        sortingCriterion={
                            (activeFormula?.[
                                propertyConstants.PROPERTY_COLORANT_SORTING_CRITERION
                            ] as propertyConstants.Property) ?? null
                        }
                    />
                </div>
            );
        }

        return null;
    }

    render(): JSX.Element | null {
        const { tableConstant, activeFormula } = this.props;
        const textPreviewObjectType = colorHelper.getColorObjectType(formulaConstants.FORMULA, tableConstant);

        if (activeFormula === null || textPreviewObjectType === null) {
            return null;
        }

        return (
            <div className="color-properties" key="color-properties">
                <div className="data-color-container">
                    <TextPreview
                        className="formula-content"
                        objectType={textPreviewObjectType}
                        headings={this.props.headings}
                        data={activeFormula}
                    />
                </div>
                {this.getColorantTable(activeFormula)}
            </div>
        );
    }
}
